import {Inject, Injectable, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class TitleService  {

  private baseTitle = 'АКРО Машрутизатор'

  constructor(
    private titleService: Title
  ) {
  }

  // Установка title
  public setTitle(title: string): void {
    this.titleService.setTitle(title + ' - ' + this.baseTitle);
  }

}
