import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-optimization',
  templateUrl: './optimization.component.html',
  styleUrls: ['./optimization.component.css']
})
export class OptimizationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
