export const MkadAreaConstAvoid =
  [
    [
      37.78172492980957,
      55.61415906821968
    ],
    [
      37.78627395629883,
      55.61630398423523
    ],
    [
      37.7884840965271,
      55.61773386305892
    ],
    [
      37.79144525527954,
      55.62060558077328
    ],
    [
      37.79470682144165,
      55.62276225997318
    ],
    [
      37.798826694488525,
      55.62457959413943
    ],
    [
      37.800822257995605,
      55.62599705629628
    ],
    [
      37.80307531356811,
      55.62794749734039
    ],
    [
      37.8068733215332,
      55.63017645396335
    ],
    [
      37.8104567527771,
      55.63240528381025
    ],
    [
      37.81468391418457,
      55.63511847077854
    ],
    [
      37.81775236129761,
      55.63723801737373
    ],
    [
      37.82024145126343,
      55.63888512868791
    ],
    [
      37.82328844070434,
      55.63867924356084
    ],
    [
      37.82575607299804,
      55.640193079516486
    ],
    [
      37.8249192237854,
      55.641876396403255
    ],
    [
      37.83024072647095,
      55.645654513345
    ],
    [
      37.83309459686279,
      55.647313379034955
    ],
    [
      37.8359055519104,
      55.64923854381944
    ],
    [
      37.83792257308959,
      55.65098200761429
    ],
    [
      37.840003967285156,
      55.653597057759505
    ],
    [
      37.842257022857666,
      55.65540085629906
    ],
    [
      37.843286991119385,
      55.65782193175223
    ],
    [
      37.84232139587402,
      55.66042442087282
    ],
    [
      37.841956615448,
      55.66248208047731
    ],
    [
      37.841012477874756,
      55.665290006104094
    ],
    [
      37.84024000167847,
      55.667795183479335
    ],
    [
      37.83899545669556,
      55.670953656809296
    ],
    [
      37.83749341964722,
      55.67475316809726
    ],
    [
      37.8363561630249,
      55.678709591787296
    ],
    [
      37.834811210632324,
      55.68278658834732
    ],
    [
      37.836291790008545,
      55.68549628566729
    ],
    [
      37.836291790008545,
      55.68751633963143
    ],
    [
      37.83339500427246,
      55.68937905140352
    ],
    [
      37.83161401748657,
      55.690673220973956
    ],
    [
      37.831549644470215,
      55.69436197392705
    ],
    [
      37.832300662994385,
      55.69707086918485
    ],
    [
      37.83341646194458,
      55.69992468076165
    ],
    [
      37.8348970413208,
      55.70271782844435
    ],
    [
      37.83624887466431,
      55.705208519036425
    ],
    [
      37.83916711807251,
      55.7059218429237
    ],
    [
      37.84118413925171,
      55.705220609380284
    ],
    [
      37.842514514923096,
      55.7060911043075
    ],
    [
      37.84032583236694,
      55.70825516738589
    ],
    [
      37.837772369384766,
      55.709331109445756
    ],
    [
      37.83843755722046,
      55.71069715049408
    ],
    [
      37.84260034561157,
      55.71049164294613
    ],
    [
      37.84420967102051,
      55.71170049535244
    ],
    [
      37.84197807312012,
      55.71496421001796
    ],
    [
      37.84232139587402,
      55.717079434998176
    ],
    [
      37.843995094299316,
      55.71792549291848
    ],
    [
      37.84369468688965,
      55.719230803474424
    ],
    [
      37.84036874771118,
      55.72077778171819
    ],
    [
      37.8421926498413,
      55.724270343180486
    ],
    [
      37.84283638000488,
      55.7274242565637
    ],
    [
      37.84324407577515,
      55.72976837962446
    ],
    [
      37.84408092498779,
      55.73046917259266
    ],
    [
      37.84412384033203,
      55.731629078139484
    ],
    [
      37.84245014190674,
      55.73306682988756
    ],
    [
      37.843050956726074,
      55.73551934301118
    ],
    [
      37.843265533447266,
      55.73893810663089
    ],
    [
      37.843844890594475,
      55.74148688393296
    ],
    [
      37.84622669219971,
      55.74254983191257
    ],
    [
      37.84781455993652,
      55.743202081110844
    ],
    [
      37.847900390625,
      55.744917202899764
    ],
    [
      37.84489631652832,
      55.74668055937608
    ],
    [
      37.844660282135,
      55.748431759192954
    ],
    [
      37.844295501708984,
      55.75142673255722
    ],
    [
      37.844552993774414,
      55.754167897761
    ],
    [
      37.84564733505248,
      55.75705376220598
    ],
    [
      37.846012115478516,
      55.76114673509295
    ],
    [
      37.8468918800354,
      55.76276448792348
    ],
    [
      37.84695625305176,
      55.76598772070707
    ],
    [
      37.84721374511719,
      55.768124323663194
    ],
    [
      37.84714937210083,
      55.77011596672778
    ],
    [
      37.8458833694458,
      55.77186611451349
    ],
    [
      37.84592628479004,
      55.77469032521712
    ],
    [
      37.846999168395996,
      55.776235106027315
    ],
    [
      37.84710645675659,
      55.77714022258461
    ],
    [
      37.845776081085205,
      55.77881764968991
    ],
    [
      37.84463882446289,
      55.78096561637983
    ],
    [
      37.84468173980713,
      55.7849233572631
    ],
    [
      37.843801975250244,
      55.78830159848985
    ],
    [
      37.8439736366272,
      55.79233097453859
    ],
    [
      37.84322261810303,
      55.795491450863395
    ],
    [
      37.84315824508667,
      55.799013511600265
    ],
    [
      37.84234285354614,
      55.80313825990466
    ],
    [
      37.84298658370972,
      55.80705757195671
    ],
    [
      37.84290075302124,
      55.81003598523193
    ],
    [
      37.84270763397217,
      55.813038284548746
    ],
    [
      37.84463882446289,
      55.81399077288618
    ],
    [
      37.84371614456177,
      55.81588362354975
    ],
    [
      37.84015417098999,
      55.817113324326016
    ],
    [
      37.84011125564575,
      55.820440555126325
    ],
    [
      37.83923149108887,
      55.823068384146026
    ],
    [
      37.83667802810669,
      55.825334442788744
    ],
    [
      37.834296226501465,
      55.82802220290983
    ],
    [
      37.832815647125244,
      55.829516661827334
    ],
    [
      37.8319787979126,
      55.83027592137693
    ],
    [
      37.82923221588135,
      55.8316738843179
    ],
    [
      37.823824882507324,
      55.83384303767789
    ],
    [
      37.81893253326416,
      55.83679530201123
    ],
    [
      37.813053131103516,
      55.83957866023964
    ],
    [
      37.80863285064697,
      55.84191604525772
    ],
    [
      37.80365467071533,
      55.84433762069213
    ],
    [
      37.791595458984375,
      55.850842598778385
    ],
    [
      37.77172565460205,
      55.86105562660984
    ],
    [
      37.7461051940918,
      55.87413112679483
    ],
    [
      37.73623466491699,
      55.879018258911266
    ],
    [
      37.73301601409912,
      55.88111255579644
    ],
    [
      37.73357391357422,
      55.8844824515321
    ],
    [
      37.731685638427734,
      55.88619135828008
    ],
    [
      37.72773742675781,
      55.88546929418562
    ],
    [
      37.72378921508789,
      55.88520453398526
    ],
    [
      37.71988391876221,
      55.88672086341192
    ],
    [
      37.709026336669915,
      55.89201551742265
    ],
    [
      37.70503520965576,
      55.89350751671809
    ],
    [
      37.69782543182373,
      55.895480717997344
    ],
    [
      37.68417835235596,
      55.896058221339956
    ],
    [
      37.676753997802734,
      55.8962025958325
    ],
    [
      37.67353534698486,
      55.898079415346444
    ],
    [
      37.67070293426513,
      55.89815159889918
    ],
    [
      37.66911506652832,
      55.89615447106136
    ],
    [
      37.64915943145752,
      55.89788692521582
    ],
    [
      37.63461112976074,
      55.89964336225276
    ],
    [
      37.62113571166992,
      55.90241019079089
    ],
    [
      37.61547088623046,
      55.90366121368496
    ],
    [
      37.60298252105713,
      55.90686076174227
    ],
    [
      37.593584060668945,
      55.90965112951672
    ],
    [
      37.59169578552246,
      55.91198430015494
    ],
    [
      37.59221076965332,
      55.913331221347164
    ],
    [
      37.59092330932617,
      55.91402871571852
    ],
    [
      37.58847713470459,
      55.91260966224158
    ],
    [
      37.58671760559082,
      55.9111424506494
    ],
    [
      37.579636573791504,
      55.91183998439491
    ],
    [
      37.5712251663208,
      55.91157540410647
    ],
    [
      37.56980895996094,
      55.91217672033277
    ],
    [
      37.568392753601074,
      55.91229698245899
    ],
    [
      37.56856441497803,
      55.911190556827805
    ],
    [
      37.55671977996826,
      55.90984356127279
    ],
    [
      37.5517201423645,
      55.90914599161358
    ],
    [
      37.546870708465576,
      55.90880922935591
    ],
    [
      37.5452184677124,
      55.90984356127279
    ],
    [
      37.54309415817261,
      55.9101923413968
    ],
    [
      37.54105567932129,
      55.909458696805665
    ],
    [
      37.538394927978516,
      55.908412327226316
    ],
    [
      37.535240650177,
      55.90815975102883
    ],
    [
      37.52871751785278,
      55.9065961474792
    ],
    [
      37.52313852310181,
      55.9049603101376
    ],
    [
      37.515156269073486,
      55.901471897140866
    ],
    [
      37.507281303405755,
      55.89806738474127
    ],
    [
      37.50028610229492,
      55.89519196310252
    ],
    [
      37.49631643295288,
      55.89317061866593
    ],
    [
      37.49410629272461,
      55.89317061866593
    ],
    [
      37.49189615249634,
      55.8915703129715
    ],
    [
      37.48865604400635,
      55.890186536796925
    ],
    [
      37.48391389846802,
      55.89089648030252
    ],
    [
      37.48206853866577,
      55.890908513131365
    ],
    [
      37.48146772384643,
      55.88892304589016
    ],
    [
      37.47762680053711,
      55.88699764731035
    ],
    [
      37.47217655181885,
      55.88558963913422
    ],
    [
      37.467284202575684,
      55.88441024254795
    ],
    [
      37.45844364166259,
      55.883832565838766
    ],
    [
      37.454538345336914,
      55.883700180382014
    ],
    [
      37.44960308074951,
      55.88321877491499
    ],
    [
      37.44565486907959,
      55.88327895092488
    ],
    [
      37.44383096694946,
      55.88449448634973
    ],
    [
      37.44159936904907,
      55.88428989394252
    ],
    [
      37.439796924591064,
      55.88315859881184
    ],
    [
      37.439002990722656,
      55.88137734390881
    ],
    [
      37.434561252593994,
      55.88005338528567
    ],
    [
      37.43014097213745,
      55.8783080981104
    ],
    [
      37.423574924468994,
      55.8773210733898
    ],
    [
      37.41964817047119,
      55.874853401805595
    ],
    [
      37.41286754608154,
      55.87236149623725
    ],
    [
      37.40887641906738,
      55.87110946400208
    ],
    [
      37.405056953430176,
      55.86838856247084
    ],
    [
      37.4003791809082,
      55.86510155551395
    ],
    [
      37.397053241729736,
      55.86171793548261
    ],
    [
      37.39452123641968,
      55.85809316145419
    ],
    [
      37.39278316497803,
      55.854407828558266
    ],
    [
      37.39076614379883,
      55.85269752265769
    ],
    [
      37.389628887176514,
      55.850637829027846
    ],
    [
      37.389628887176514,
      55.848084139163866
    ],
    [
      37.39014387130737,
      55.84539776544575
    ],
    [
      37.391688823699944,
      55.84269915829394
    ],
    [
      37.393126487731934,
      55.84066303158799
    ],
    [
      37.39404916763306,
      55.83856655302774
    ],
    [
      37.39449977874756,
      55.83622896663599
    ],
    [
      37.39372730255127,
      55.8350842210108
    ],
    [
      37.391581535339355,
      55.83214387781303
    ],
    [
      37.39145278930664,
      55.82949255810683
    ],
    [
      37.39125967025757,
      55.82660000320063
    ],
    [
      37.3904013633728,
      55.82308043799864
    ],
    [
      37.38877058029175,
      55.818162156045666
    ],
    [
      37.3879337310791,
      55.81465388391405
    ],
    [
      37.38690376281738,
      55.810904143775346
    ],
    [
      37.38529443740845,
      55.809529550472526
    ],
    [
      37.38422155380249,
      55.80782932844525
    ],
    [
      37.38469362258911,
      55.80598432265665
    ],
    [
      37.38297700881957,
      55.803210620009835
    ],
    [
      37.38029479980469,
      55.80026786726073
    ],
    [
      37.37849235534668,
      55.79859136358851
    ],
    [
      37.374136447906494,
      55.79415250174672
    ],
    [
      37.370617389678955,
      55.79169161035352
    ],
    [
      37.36784934997558,
      55.789121984291626
    ],
    [
      37.3679780960083,
      55.78676936109304
    ],
    [
      37.367634773254395,
      55.78453725389843
    ],
    [
      37.36731290817261,
      55.78101388393398
    ],
    [
      37.367677688598626,
      55.777695350339535
    ],
    [
      37.36731290817261,
      55.77473860054427
    ],
    [
      37.368128299713135,
      55.772481665055466
    ],
    [
      37.365360260009766,
      55.77243338693289
    ],
    [
      37.36375093460083,
      55.77204715979961
    ],
    [
      37.36402988433838,
      55.77055049351072
    ],
    [
      37.36557483673096,
      55.76939174465907
    ],
    [
      37.367184162139885,
      55.76807604014383
    ],
    [
      37.36583232879639,
      55.7664705790737
    ],
    [
      37.365360260009766,
      55.765263421944255
    ],
    [
      37.36426591873169,
      55.764720189041945
    ],
    [
      37.36493110656738,
      55.76350090541429
    ],
    [
      37.3674201965332,
      55.76201598376261
    ],
    [
      37.36699104309082,
      55.759371958892174
    ],
    [
      37.36724853515625,
      55.75684849856711
    ],
    [
      37.36699104309082,
      55.753914317876514
    ],
    [
      37.36769914627075,
      55.75058140046257
    ],
    [
      37.36735582351684,
      55.745980057440235
    ],
    [
      37.3689866065979,
      55.742622304584096
    ],
    [
      37.37020969390869,
      55.739155544364515
    ],
    [
      37.3714542388916,
      55.73657037289405
    ],
    [
      37.372848987579346,
      55.73339303509882
    ],
    [
      37.37220525741577,
      55.73216068999776
    ],
    [
      37.370402812957764,
      55.73119411759799
    ],
    [
      37.3699951171875,
      55.72976837962446
    ],
    [
      37.37428665161133,
      55.728922578254796
    ],
    [
      37.37617492675781,
      55.72695299833912
    ],
    [
      37.37827777862549,
      55.725297507695814
    ],
    [
      37.378342151641846,
      55.723944061738784
    ],
    [
      37.378127574920654,
      55.72278392786625
    ],
    [
      37.38033771514892,
      55.72116451670484
    ],
    [
      37.38222599029541,
      55.71764750448048
    ],
    [
      37.383105754852295,
      55.715483961627775
    ],
    [
      37.381861209869385,
      55.71368293232678
    ],
    [
      37.38033771514892,
      55.71335656242558
    ],
    [
      37.381088733673096,
      55.712244466732166
    ],
    [
      37.38404989242554,
      55.71147081627349
    ],
    [
      37.386925220489495,
      55.71026195676035
    ],
    [
      37.3895001411438,
      55.7070824776892
    ],
    [
      37.39123821258545,
      55.70575258080676
    ],
    [
      37.39346981048584,
      55.702681555078506
    ],
    [
      37.394821643829346,
      55.7003841733193
    ],
    [
      37.39810466766357,
      55.6992233396008
    ],
    [
      37.40226745605469,
      55.69707086918485
    ],
    [
      37.40823268890381,
      55.69246321669408
    ],
    [
      37.41027116775512,
      55.69016523428713
    ],
    [
      37.411837577819824,
      55.68643979691343
    ],
    [
      37.41344690322876,
      55.68317369945937
    ],
    [
      37.41351127624512,
      55.68198815957743
    ],
    [
      37.41417646408081,
      55.680850975311294
    ],
    [
      37.41994857788086,
      55.67603572236134
    ],
    [
      37.4278450012207,
      55.66441860246834
    ],
    [
      37.425785064697266,
      55.66112645807155
    ],
    [
      37.42810249328613,
      55.65909293642748
    ],
    [
      37.43393898010253,
      55.65739825435643
    ],
    [
      37.45041847229004,
      55.64364440675071
    ],
    [
      37.45436668395996,
      55.63996298022151
    ],
    [
      37.45445251464844,
      55.637783025107105
    ],
    [
      37.45668411254883,
      55.6350215744791
    ],
    [
      37.460289001464844,
      55.63526381477839
    ],
    [
      37.474365234375,
      55.623779977487104
    ],
    [
      37.4879264831543,
      55.6117595315955
    ],
    [
      37.48741149902344,
      55.6090447272758
    ],
    [
      37.48878479003906,
      55.606038831839555
    ],
    [
      37.49462127685546,
      55.606281251302114
    ],
    [
      37.50989913940429,
      55.59493441459873
    ],
    [
      37.52663612365723,
      55.590423862954935
    ],
    [
      37.527923583984375,
      55.589162755782766
    ],
    [
      37.531700134277344,
      55.58969630607037
    ],
    [
      37.56852149963379,
      55.58101308636897
    ],
    [
      37.57375717163086,
      55.57829615388176
    ],
    [
      37.580881118774414,
      55.577810967581165
    ],
    [
      37.59246826171875,
      55.57499676880407
    ],
    [
      37.596330642700195,
      55.57155152675772
    ],
    [
      37.605085372924805,
      55.572667624180205
    ],
    [
      37.610321044921875,
      55.573735165947696
    ],
    [
      37.64516830444336,
      55.57130889268606
    ],
    [
      37.65787124633789,
      55.570678037084264
    ],
    [
      37.675209045410156,
      55.570289813215624
    ],
    [
      37.684736251831055,
      55.57208531643891
    ],
    [
      37.69228935241699,
      55.57218236832872
    ],
    [
      37.69452095031738,
      55.57460858762682
    ],
    [
      37.69529342651367,
      55.57693761713125
    ],
    [
      37.727394104003906,
      55.587174004443845
    ],
    [
      37.73323059082031,
      55.58911425085148
    ],
    [
      37.74627685546875,
      55.59648631248359
    ],
    [
      37.75923728942871,
      55.60070522458015
    ],
    [
      37.7724552154541,
      55.608705363521366
    ],
    [
      37.78172492980957,
      55.61415906821968
    ]
  ]

export const MkadAreaConst = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              37.78172492980957,
              55.61415906821968
            ],
            [
              37.78627395629883,
              55.61630398423523
            ],
            [
              37.7884840965271,
              55.61773386305892
            ],
            [
              37.79144525527954,
              55.62060558077328
            ],
            [
              37.79470682144165,
              55.62276225997318
            ],
            [
              37.798826694488525,
              55.62457959413943
            ],
            [
              37.800822257995605,
              55.62599705629628
            ],
            [
              37.80307531356811,
              55.62794749734039
            ],
            [
              37.8068733215332,
              55.63017645396335
            ],
            [
              37.8104567527771,
              55.63240528381025
            ],
            [
              37.81468391418457,
              55.63511847077854
            ],
            [
              37.81775236129761,
              55.63723801737373
            ],
            [
              37.82024145126343,
              55.63888512868791
            ],
            [
              37.82328844070434,
              55.63867924356084
            ],
            [
              37.82575607299804,
              55.640193079516486
            ],
            [
              37.8249192237854,
              55.641876396403255
            ],
            [
              37.83024072647095,
              55.645654513345
            ],
            [
              37.83309459686279,
              55.647313379034955
            ],
            [
              37.8359055519104,
              55.64923854381944
            ],
            [
              37.83792257308959,
              55.65098200761429
            ],
            [
              37.840003967285156,
              55.653597057759505
            ],
            [
              37.842257022857666,
              55.65540085629906
            ],
            [
              37.843286991119385,
              55.65782193175223
            ],
            [
              37.84232139587402,
              55.66042442087282
            ],
            [
              37.841956615448,
              55.66248208047731
            ],
            [
              37.841012477874756,
              55.665290006104094
            ],
            [
              37.84024000167847,
              55.667795183479335
            ],
            [
              37.83899545669556,
              55.670953656809296
            ],
            [
              37.83749341964722,
              55.67475316809726
            ],
            [
              37.8363561630249,
              55.678709591787296
            ],
            [
              37.834811210632324,
              55.68278658834732
            ],
            [
              37.836291790008545,
              55.68549628566729
            ],
            [
              37.836291790008545,
              55.68751633963143
            ],
            [
              37.83339500427246,
              55.68937905140352
            ],
            [
              37.83161401748657,
              55.690673220973956
            ],
            [
              37.831549644470215,
              55.69436197392705
            ],
            [
              37.832300662994385,
              55.69707086918485
            ],
            [
              37.83341646194458,
              55.69992468076165
            ],
            [
              37.8348970413208,
              55.70271782844435
            ],
            [
              37.83624887466431,
              55.705208519036425
            ],
            [
              37.83916711807251,
              55.7059218429237
            ],
            [
              37.84118413925171,
              55.705220609380284
            ],
            [
              37.842514514923096,
              55.7060911043075
            ],
            [
              37.84032583236694,
              55.70825516738589
            ],
            [
              37.837772369384766,
              55.709331109445756
            ],
            [
              37.83843755722046,
              55.71069715049408
            ],
            [
              37.84260034561157,
              55.71049164294613
            ],
            [
              37.84420967102051,
              55.71170049535244
            ],
            [
              37.84197807312012,
              55.71496421001796
            ],
            [
              37.84232139587402,
              55.717079434998176
            ],
            [
              37.843995094299316,
              55.71792549291848
            ],
            [
              37.84369468688965,
              55.719230803474424
            ],
            [
              37.84036874771118,
              55.72077778171819
            ],
            [
              37.8421926498413,
              55.724270343180486
            ],
            [
              37.84283638000488,
              55.7274242565637
            ],
            [
              37.84324407577515,
              55.72976837962446
            ],
            [
              37.84408092498779,
              55.73046917259266
            ],
            [
              37.84412384033203,
              55.731629078139484
            ],
            [
              37.84245014190674,
              55.73306682988756
            ],
            [
              37.843050956726074,
              55.73551934301118
            ],
            [
              37.843265533447266,
              55.73893810663089
            ],
            [
              37.843844890594475,
              55.74148688393296
            ],
            [
              37.84622669219971,
              55.74254983191257
            ],
            [
              37.84781455993652,
              55.743202081110844
            ],
            [
              37.847900390625,
              55.744917202899764
            ],
            [
              37.84489631652832,
              55.74668055937608
            ],
            [
              37.844660282135,
              55.748431759192954
            ],
            [
              37.844295501708984,
              55.75142673255722
            ],
            [
              37.844552993774414,
              55.754167897761
            ],
            [
              37.84564733505248,
              55.75705376220598
            ],
            [
              37.846012115478516,
              55.76114673509295
            ],
            [
              37.8468918800354,
              55.76276448792348
            ],
            [
              37.84695625305176,
              55.76598772070707
            ],
            [
              37.84721374511719,
              55.768124323663194
            ],
            [
              37.84714937210083,
              55.77011596672778
            ],
            [
              37.8458833694458,
              55.77186611451349
            ],
            [
              37.84592628479004,
              55.77469032521712
            ],
            [
              37.846999168395996,
              55.776235106027315
            ],
            [
              37.84710645675659,
              55.77714022258461
            ],
            [
              37.845776081085205,
              55.77881764968991
            ],
            [
              37.84463882446289,
              55.78096561637983
            ],
            [
              37.84468173980713,
              55.7849233572631
            ],
            [
              37.843801975250244,
              55.78830159848985
            ],
            [
              37.8439736366272,
              55.79233097453859
            ],
            [
              37.84322261810303,
              55.795491450863395
            ],
            [
              37.84315824508667,
              55.799013511600265
            ],
            [
              37.84234285354614,
              55.80313825990466
            ],
            [
              37.84298658370972,
              55.80705757195671
            ],
            [
              37.84290075302124,
              55.81003598523193
            ],
            [
              37.84270763397217,
              55.813038284548746
            ],
            [
              37.84463882446289,
              55.81399077288618
            ],
            [
              37.84371614456177,
              55.81588362354975
            ],
            [
              37.84015417098999,
              55.817113324326016
            ],
            [
              37.84011125564575,
              55.820440555126325
            ],
            [
              37.83923149108887,
              55.823068384146026
            ],
            [
              37.83667802810669,
              55.825334442788744
            ],
            [
              37.834296226501465,
              55.82802220290983
            ],
            [
              37.832815647125244,
              55.829516661827334
            ],
            [
              37.8319787979126,
              55.83027592137693
            ],
            [
              37.82923221588135,
              55.8316738843179
            ],
            [
              37.823824882507324,
              55.83384303767789
            ],
            [
              37.81893253326416,
              55.83679530201123
            ],
            [
              37.813053131103516,
              55.83957866023964
            ],
            [
              37.80863285064697,
              55.84191604525772
            ],
            [
              37.80365467071533,
              55.84433762069213
            ],
            [
              37.791595458984375,
              55.850842598778385
            ],
            [
              37.77172565460205,
              55.86105562660984
            ],
            [
              37.7461051940918,
              55.87413112679483
            ],
            [
              37.73623466491699,
              55.879018258911266
            ],
            [
              37.73301601409912,
              55.88111255579644
            ],
            [
              37.73357391357422,
              55.8844824515321
            ],
            [
              37.731685638427734,
              55.88619135828008
            ],
            [
              37.72773742675781,
              55.88546929418562
            ],
            [
              37.72378921508789,
              55.88520453398526
            ],
            [
              37.71988391876221,
              55.88672086341192
            ],
            [
              37.709026336669915,
              55.89201551742265
            ],
            [
              37.70503520965576,
              55.89350751671809
            ],
            [
              37.69782543182373,
              55.895480717997344
            ],
            [
              37.68417835235596,
              55.896058221339956
            ],
            [
              37.676753997802734,
              55.8962025958325
            ],
            [
              37.67353534698486,
              55.898079415346444
            ],
            [
              37.67070293426513,
              55.89815159889918
            ],
            [
              37.66911506652832,
              55.89615447106136
            ],
            [
              37.64915943145752,
              55.89788692521582
            ],
            [
              37.63461112976074,
              55.89964336225276
            ],
            [
              37.62113571166992,
              55.90241019079089
            ],
            [
              37.61547088623046,
              55.90366121368496
            ],
            [
              37.60298252105713,
              55.90686076174227
            ],
            [
              37.593584060668945,
              55.90965112951672
            ],
            [
              37.59169578552246,
              55.91198430015494
            ],
            [
              37.59221076965332,
              55.913331221347164
            ],
            [
              37.59092330932617,
              55.91402871571852
            ],
            [
              37.58847713470459,
              55.91260966224158
            ],
            [
              37.58671760559082,
              55.9111424506494
            ],
            [
              37.579636573791504,
              55.91183998439491
            ],
            [
              37.5712251663208,
              55.91157540410647
            ],
            [
              37.56980895996094,
              55.91217672033277
            ],
            [
              37.568392753601074,
              55.91229698245899
            ],
            [
              37.56856441497803,
              55.911190556827805
            ],
            [
              37.55671977996826,
              55.90984356127279
            ],
            [
              37.5517201423645,
              55.90914599161358
            ],
            [
              37.546870708465576,
              55.90880922935591
            ],
            [
              37.5452184677124,
              55.90984356127279
            ],
            [
              37.54309415817261,
              55.9101923413968
            ],
            [
              37.54105567932129,
              55.909458696805665
            ],
            [
              37.538394927978516,
              55.908412327226316
            ],
            [
              37.535240650177,
              55.90815975102883
            ],
            [
              37.52871751785278,
              55.9065961474792
            ],
            [
              37.52313852310181,
              55.9049603101376
            ],
            [
              37.515156269073486,
              55.901471897140866
            ],
            [
              37.507281303405755,
              55.89806738474127
            ],
            [
              37.50028610229492,
              55.89519196310252
            ],
            [
              37.49631643295288,
              55.89317061866593
            ],
            [
              37.49410629272461,
              55.89317061866593
            ],
            [
              37.49189615249634,
              55.8915703129715
            ],
            [
              37.48865604400635,
              55.890186536796925
            ],
            [
              37.48391389846802,
              55.89089648030252
            ],
            [
              37.48206853866577,
              55.890908513131365
            ],
            [
              37.48146772384643,
              55.88892304589016
            ],
            [
              37.47762680053711,
              55.88699764731035
            ],
            [
              37.47217655181885,
              55.88558963913422
            ],
            [
              37.467284202575684,
              55.88441024254795
            ],
            [
              37.45844364166259,
              55.883832565838766
            ],
            [
              37.454538345336914,
              55.883700180382014
            ],
            [
              37.44960308074951,
              55.88321877491499
            ],
            [
              37.44565486907959,
              55.88327895092488
            ],
            [
              37.44383096694946,
              55.88449448634973
            ],
            [
              37.44159936904907,
              55.88428989394252
            ],
            [
              37.439796924591064,
              55.88315859881184
            ],
            [
              37.439002990722656,
              55.88137734390881
            ],
            [
              37.434561252593994,
              55.88005338528567
            ],
            [
              37.43014097213745,
              55.8783080981104
            ],
            [
              37.423574924468994,
              55.8773210733898
            ],
            [
              37.41964817047119,
              55.874853401805595
            ],
            [
              37.41286754608154,
              55.87236149623725
            ],
            [
              37.40887641906738,
              55.87110946400208
            ],
            [
              37.405056953430176,
              55.86838856247084
            ],
            [
              37.4003791809082,
              55.86510155551395
            ],
            [
              37.397053241729736,
              55.86171793548261
            ],
            [
              37.39452123641968,
              55.85809316145419
            ],
            [
              37.39278316497803,
              55.854407828558266
            ],
            [
              37.39076614379883,
              55.85269752265769
            ],
            [
              37.389628887176514,
              55.850637829027846
            ],
            [
              37.389628887176514,
              55.848084139163866
            ],
            [
              37.39014387130737,
              55.84539776544575
            ],
            [
              37.391688823699944,
              55.84269915829394
            ],
            [
              37.393126487731934,
              55.84066303158799
            ],
            [
              37.39404916763306,
              55.83856655302774
            ],
            [
              37.39449977874756,
              55.83622896663599
            ],
            [
              37.39372730255127,
              55.8350842210108
            ],
            [
              37.391581535339355,
              55.83214387781303
            ],
            [
              37.39145278930664,
              55.82949255810683
            ],
            [
              37.39125967025757,
              55.82660000320063
            ],
            [
              37.3904013633728,
              55.82308043799864
            ],
            [
              37.38877058029175,
              55.818162156045666
            ],
            [
              37.3879337310791,
              55.81465388391405
            ],
            [
              37.38690376281738,
              55.810904143775346
            ],
            [
              37.38529443740845,
              55.809529550472526
            ],
            [
              37.38422155380249,
              55.80782932844525
            ],
            [
              37.38469362258911,
              55.80598432265665
            ],
            [
              37.38297700881957,
              55.803210620009835
            ],
            [
              37.38029479980469,
              55.80026786726073
            ],
            [
              37.37849235534668,
              55.79859136358851
            ],
            [
              37.374136447906494,
              55.79415250174672
            ],
            [
              37.370617389678955,
              55.79169161035352
            ],
            [
              37.36784934997558,
              55.789121984291626
            ],
            [
              37.3679780960083,
              55.78676936109304
            ],
            [
              37.367634773254395,
              55.78453725389843
            ],
            [
              37.36731290817261,
              55.78101388393398
            ],
            [
              37.367677688598626,
              55.777695350339535
            ],
            [
              37.36731290817261,
              55.77473860054427
            ],
            [
              37.368128299713135,
              55.772481665055466
            ],
            [
              37.365360260009766,
              55.77243338693289
            ],
            [
              37.36375093460083,
              55.77204715979961
            ],
            [
              37.36402988433838,
              55.77055049351072
            ],
            [
              37.36557483673096,
              55.76939174465907
            ],
            [
              37.367184162139885,
              55.76807604014383
            ],
            [
              37.36583232879639,
              55.7664705790737
            ],
            [
              37.365360260009766,
              55.765263421944255
            ],
            [
              37.36426591873169,
              55.764720189041945
            ],
            [
              37.36493110656738,
              55.76350090541429
            ],
            [
              37.3674201965332,
              55.76201598376261
            ],
            [
              37.36699104309082,
              55.759371958892174
            ],
            [
              37.36724853515625,
              55.75684849856711
            ],
            [
              37.36699104309082,
              55.753914317876514
            ],
            [
              37.36769914627075,
              55.75058140046257
            ],
            [
              37.36735582351684,
              55.745980057440235
            ],
            [
              37.3689866065979,
              55.742622304584096
            ],
            [
              37.37020969390869,
              55.739155544364515
            ],
            [
              37.3714542388916,
              55.73657037289405
            ],
            [
              37.372848987579346,
              55.73339303509882
            ],
            [
              37.37220525741577,
              55.73216068999776
            ],
            [
              37.370402812957764,
              55.73119411759799
            ],
            [
              37.3699951171875,
              55.72976837962446
            ],
            [
              37.37428665161133,
              55.728922578254796
            ],
            [
              37.37617492675781,
              55.72695299833912
            ],
            [
              37.37827777862549,
              55.725297507695814
            ],
            [
              37.378342151641846,
              55.723944061738784
            ],
            [
              37.378127574920654,
              55.72278392786625
            ],
            [
              37.38033771514892,
              55.72116451670484
            ],
            [
              37.38222599029541,
              55.71764750448048
            ],
            [
              37.383105754852295,
              55.715483961627775
            ],
            [
              37.381861209869385,
              55.71368293232678
            ],
            [
              37.38033771514892,
              55.71335656242558
            ],
            [
              37.381088733673096,
              55.712244466732166
            ],
            [
              37.38404989242554,
              55.71147081627349
            ],
            [
              37.386925220489495,
              55.71026195676035
            ],
            [
              37.3895001411438,
              55.7070824776892
            ],
            [
              37.39123821258545,
              55.70575258080676
            ],
            [
              37.39346981048584,
              55.702681555078506
            ],
            [
              37.394821643829346,
              55.7003841733193
            ],
            [
              37.39810466766357,
              55.6992233396008
            ],
            [
              37.40226745605469,
              55.69707086918485
            ],
            [
              37.40823268890381,
              55.69246321669408
            ],
            [
              37.41027116775512,
              55.69016523428713
            ],
            [
              37.411837577819824,
              55.68643979691343
            ],
            [
              37.41344690322876,
              55.68317369945937
            ],
            [
              37.41351127624512,
              55.68198815957743
            ],
            [
              37.41417646408081,
              55.680850975311294
            ],
            [
              37.41994857788086,
              55.67603572236134
            ],
            [
              37.4278450012207,
              55.66441860246834
            ],
            [
              37.425785064697266,
              55.66112645807155
            ],
            [
              37.42810249328613,
              55.65909293642748
            ],
            [
              37.43393898010253,
              55.65739825435643
            ],
            [
              37.45041847229004,
              55.64364440675071
            ],
            [
              37.45436668395996,
              55.63996298022151
            ],
            [
              37.45445251464844,
              55.637783025107105
            ],
            [
              37.45668411254883,
              55.6350215744791
            ],
            [
              37.460289001464844,
              55.63526381477839
            ],
            [
              37.474365234375,
              55.623779977487104
            ],
            [
              37.4879264831543,
              55.6117595315955
            ],
            [
              37.48741149902344,
              55.6090447272758
            ],
            [
              37.48878479003906,
              55.606038831839555
            ],
            [
              37.49462127685546,
              55.606281251302114
            ],
            [
              37.50989913940429,
              55.59493441459873
            ],
            [
              37.52663612365723,
              55.590423862954935
            ],
            [
              37.527923583984375,
              55.589162755782766
            ],
            [
              37.531700134277344,
              55.58969630607037
            ],
            [
              37.56852149963379,
              55.58101308636897
            ],
            [
              37.57375717163086,
              55.57829615388176
            ],
            [
              37.580881118774414,
              55.577810967581165
            ],
            [
              37.59246826171875,
              55.57499676880407
            ],
            [
              37.596330642700195,
              55.57155152675772
            ],
            [
              37.605085372924805,
              55.572667624180205
            ],
            [
              37.610321044921875,
              55.573735165947696
            ],
            [
              37.64516830444336,
              55.57130889268606
            ],
            [
              37.65787124633789,
              55.570678037084264
            ],
            [
              37.675209045410156,
              55.570289813215624
            ],
            [
              37.684736251831055,
              55.57208531643891
            ],
            [
              37.69228935241699,
              55.57218236832872
            ],
            [
              37.69452095031738,
              55.57460858762682
            ],
            [
              37.69529342651367,
              55.57693761713125
            ],
            [
              37.727394104003906,
              55.587174004443845
            ],
            [
              37.73323059082031,
              55.58911425085148
            ],
            [
              37.74627685546875,
              55.59648631248359
            ],
            [
              37.75923728942871,
              55.60070522458015
            ],
            [
              37.7724552154541,
              55.608705363521366
            ],
            [
              37.78172492980957,
              55.61415906821968
            ]
          ]
        ]
      }
    }
  ]
}
