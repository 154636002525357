import {Component, AfterViewInit, OnInit, OnDestroy} from '@angular/core';
import * as L from 'leaflet';
@Component({
  selector: 'app-get-route',
  templateUrl: './get-route.component.html',
  styleUrls: ['./get-route.component.css']
})
export class GetRouteComponent implements  AfterViewInit, OnDestroy {


  private getRouteMap;
  public activeTab: string = 'info';

  private initMap(): void {

    this.getRouteMap = new L.Map('getRouteMap', {
      center: [ 39.8282, -98.5795 ],
      zoom: 3
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="https://tk-akro.ru">AKRO Routing</a>'
    });

    tiles.addTo(this.getRouteMap);
  }


  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnDestroy(): void {
    this.getRouteMap.remove();
    this.getRouteMap = undefined
  }

  public setTab(value: string): void {
    this.activeTab = value;
  };

}
