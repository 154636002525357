import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import * as L from 'leaflet';
import {ToastrService} from 'ngx-toastr';
import {MapService} from '../services/map.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ClipboardService} from 'ngx-clipboard';
import {ActivatedRoute} from '@angular/router';
import {LeafletClickLatLng, MapResponseDistance} from '../distance/distance.component';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {MkadAreaConst, MkadAreaConstAvoid} from '../constants/mkad.area.constant';

@Component({
  selector: 'app-nearest',
  templateUrl: './nearest.component.html',
  styleUrls: ['./nearest.component.css']
})
export class NearestComponent implements OnInit, AfterViewInit, OnDestroy {

  modalRefNearestCoords: BsModalRef | null;
  modalNearestCoodrsText: string = ""
  modalNearestErr: boolean = false
  modalNearestSuccess: boolean = false

  private nearestMap;
  private markerNearest;
  private markerSource;
  private markerDestination;
  private routeSource;
  private routeDestination;
  private userLat: number = 55.73259745865431;
  private userLon: number = 37.616146011947784;
  public isLoading: boolean = false
  public address: string = ""
  public lat: string = ""
  public lon: string = ""
  public sourceLat: string = ""
  public sourceLon: string = ""
  public destinationLat: string = ""
  public destinationLon: string = ""
  public calculate: MapResponseDistance = {}
  public googleOptions = {
    types: [],
    componentRestrictions: { country: 'RU' }
  }

  public source: boolean = true
  public destination: boolean = true
  public route: boolean = true
  public polygon: string = 'mkad'
  public tollRoad: boolean = false

  constructor(
    private toastr: ToastrService,
    private mapService: MapService,
    private modalService: BsModalService,
    private clipboard: ClipboardService,
    private router: ActivatedRoute
  ) { }

  ngOnInit(): void {
    L.Icon.Default.imagePath = "/leaflet/"
    // Получить координаты пользователя
    this.getUserLocation();
  }

  ngAfterViewInit(): void {
    // Инициализация карты
    this.initMap();
  }

  ngOnDestroy(): void {
  }

  // ✅ Получить геоданные пользователя
  public getUserLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.userLat = position.coords.latitude;
        this.userLon = position.coords.longitude;
        // Переопределить карту
        this.nearestMap.panTo(new L.LatLng(this.userLat, this.userLon), 20);
      });
    }
  }

  // ✅ Первичаная инициализация карты
  private initMap(): void {
    // Дефолтные настройки карты
    this.setMap();
    // Тайлы
    this.setTiles();
    // Полигоны
    this.setMkadPoly();
    // Прослушиватель клика по карте
    this.setClickListener();
  }

  // ✅ Установка полигона МКАД
  public setMkadPoly(): void {
    let poly = []
    for (let key of MkadAreaConstAvoid) {
      poly.push([key[1], key[0]])
    }
    new L.Polygon(poly).bindPopup("Полигон внутри МКАД").addTo(this.nearestMap);
  }

  // ✅ Установка click listener
  public setClickListener(): void {
    this.nearestMap.on('click', (e) => {
      let coords: LeafletClickLatLng = e.latlng;
      this.lat = String(coords.lat);
      this.lon = String(coords.lng);
      this.setMarker(coords, true);
    });
  }

  //  ✅ Функция получения данных от гугла
  public async handleAddressChange(address: Address) {
    this.isLoading = true
    await this.getLatLonForPlaceRequest(address.place_id)
    this.isLoading = false
  }

  // Реверсный геокодинг
  public async reverseGeocode(): Promise<any> {
    let reverseGeoCode = await this.mapService.reverseGeoCode(this.lat, this.lon)
    if(!reverseGeoCode) {
      this.toastr.clear()
      this.toastr.error('Невозможно получить информацию о координатах', 'Ошибка', {
        timeOut: 3000,
      });
      this.isLoading = false
      this.address = ""
      return false
    }
    this.address = reverseGeoCode.formattedAddress.replace('Unnamed Road, ', '').replace('Null ', '')
  }

  // ✅ Установить маркер
  public async setMarker(coords: LeafletClickLatLng, needGeo: boolean): Promise<boolean> {
    this.isLoading = true
    if (this.markerNearest) {
      this.nearestMap.removeLayer(this.markerNearest)
      this.isLoading = false
    }


    // Проверить что точка находится не в пределах мкад
    let inMkad = await this.mapService.inside([coords.lng, coords.lat], MkadAreaConst.features[0].geometry.coordinates[0])
    if (inMkad) {
      this.toastr.clear()
      this.toastr.error('Нельзя переместить точку, так как она внутри полигона', 'Невозможно', {
        timeOut: 3000,
      });
      let latLngOld: LeafletClickLatLng = {lat: Number(this.lat), lng: Number(this.lon)}
      await this.setMarker(latLngOld, true)
      this.isLoading = false
      return false
    }

    // Устанавливаем маркер
    this.markerNearest = new L.Marker([coords.lat, coords.lng], {draggable:true}).addTo(this.nearestMap);


    // Устанавливаем слушитель
    this.markerNearest.on('dragend', async (e) => {
      this.isLoading = true
      // Проверка что точка не в полигоне
      let latLng: LeafletClickLatLng = e.target.getLatLng()
      let point = [latLng.lng, latLng.lat]
      let inMkad = await this.mapService.inside(point, MkadAreaConstAvoid)
      if (inMkad) {
        this.toastr.clear()
        this.toastr.error('Нельзя переместить точку, так как она внутри полигона', 'Невозможно', {
          timeOut: 3000,
        });
        let latLngOld: LeafletClickLatLng = {lat: Number(this.lat), lng: Number(this.lon)}
        await this.setMarker(latLngOld, true)
        this.isLoading = false
        return false
      }
      this.lat = String(latLng.lat)
      this.lon = String(latLng.lng)
      await this.setMarker(latLng, true)
    });


    if (needGeo) {
      await this.reverseGeocode()
    }
    await this.onChange()
    this.isLoading = false
    return true
  }

  // ✅ Узнать координаты адреса
  public async getLatLonForPlaceRequest(place_id: any): Promise<boolean | any> {
    this.isLoading = true
    let req = await this.mapService.getLatLonForPlace(place_id)
    if (req) {
      let coords: LeafletClickLatLng = {
        lat: req.lat,
        lng: req.lon
      }
      let setMarker = await this.setMarker(coords, false)
      if (setMarker) {
        this.nearestMap.setView(new L.LatLng(Number(this.lat), Number(this.lon)),12);
      }
    }
    this.isLoading = false
  }

  // ✅ Установка карты
  public setMap(): void {
    this.nearestMap = new L.Map('nearestMap', {
      center: [this.userLat, this.userLon,],
      zoom: 10
    });
  }

  // ✅ Установка тайлов
  public setTiles(): void {
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="https://tk-akro.ru">AKRO Routing</a>'
    });
    tiles.addTo(this.nearestMap);
  }

  // Открыть модальное окно ошибки
  public openBugModal(template: TemplateRef<any>) {
    this.modalRefNearestCoords = this.modalService.show(template, {id: 2, class: 'modal-sm modal-dialog modal-dialog-centered'});
  }

  // ✅ Удалить адрес и все составляющие
  public removeAll(): void {
    this.isLoading = true
    this.address = ""
    this.lat = ""
    this.lon = ""
    this.nearestMap.removeLayer(this.markerNearest)
    this.calculate = {}
    this.isLoading = false
  }

  // Смена модели из модального окна
  public changeCoordsModal() {
    let lat = this.modalNearestCoodrsText.split(',')[0]
    let lon = this.modalNearestCoodrsText.split(',')[1]
    let err = false
    if (lat.split('.')[0].replace(' ', '').length <= 2 && lon.split('.')[0].replace(' ', '').length <= 2) {
      if (lat.split('.')[1].replace(' ', '').length > 0 && lon.split('.')[1].replace(' ', '').length >0 ) {
        let latOne = lat.split('.')[0].replace(' ', '')
        let latSecond = lat.split('.')[1].replace(' ', '')
        let lonOne = lon.split('.')[0].replace(' ', '')
        let lonSecond = lon.split('.')[1].replace(' ', '')
        if (Number.isInteger(Number(latOne)) && Number.isInteger(Number(latSecond)) && Number.isInteger(Number(lonOne)) && Number.isInteger(Number(lonSecond))) {

        } else {
          err = true
        }
      } else {
        err = true
      }
    } else if (this.modalNearestCoodrsText == '') {
      err = true
    } else {
      err = true
    }
    if (err) {
      this.modalNearestSuccess = false
      this.modalNearestErr = true
      return false
    }
    this.modalNearestSuccess = true
    this.modalNearestErr = false
  }

  // Установка координат из модального окна
  public async setCoordsModal() {
    if (this.modalNearestSuccess && !this.modalNearestErr) {
      let latLng: LeafletClickLatLng = {
        lat: Number(this.modalNearestCoodrsText.split(',')[0].replace(' ', '')),
        lng: Number(this.modalNearestCoodrsText.split(',')[1].replace(' ', ''))
      }
      let inMkad = await this.mapService.inside([latLng.lat, latLng.lng], MkadAreaConst.features[0].geometry.coordinates[0])
      if (inMkad) {
        this.toastr.clear()
        this.toastr.error('Нельзя переместить точку, так как она внутри полигона', 'Невозможно', {
          timeOut: 3000,
        });
        return false
      } else {
        this.lat = String(latLng.lat)
        this.lon = String(latLng.lng)
        await this.setMarker(latLng, true)
        this.nearestMap.setView(new L.LatLng(Number(this.lat), Number(this.lon)),12);
        this.modalNearestCoodrsText = ''
        this.modalRefNearestCoords.hide()
        this.modalNearestSuccess = false
        this.modalNearestErr = false
        return true
      }
    }
    return false
  }

  // Пересобрать точки
  private async rebuildMarkers() {

    const greenIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    const redIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    if (this.markerSource) {
      this.nearestMap.removeLayer(this.markerSource)
    }
    if (this.markerDestination) {
      this.nearestMap.removeLayer(this.markerDestination)
    }
    if (this.sourceLat && this.sourceLon) {
      // Устанавливаем маркер
      this.markerSource = new L.Marker([Number(this.sourceLat), Number(this.sourceLon)], {draggable:false, icon: greenIcon}).addTo(this.nearestMap);
    }
    if (this.destinationLat && this.destinationLon) {
      // Устанавливаем маркер
      this.markerDestination = new L.Marker([Number(this.destinationLat), Number(this.destinationLon)], {draggable:false, icon: redIcon}).addTo(this.nearestMap);
    }
    return true
  }

  // Получить точки
  private async getNearest() {
    try {
      let request = await this.mapService.getNearest(this.tollRoad, this.source, this.destination, this.route, this.lat, this.lon, this.polygon)
      if (request.source) {
        this.sourceLat = request.source.lat
        this.sourceLon = request.source.lon
      } else {
        this.sourceLat = ""
        this.sourceLon = ""
      }
      if (request.destination) {
        this.destinationLat = request.destination.lat
        this.destinationLon = request.destination.lon
      } else {
        this.destinationLat = ""
        this.destinationLon = ""
      }
      if (this.route) {
        if (request?.destination?.route) {
          if (this.routeDestination) {
            this.nearestMap.removeLayer(this.routeDestination)
          }
          let poly = []
          for (let key of request?.destination?.route) {
            poly.push([key[1], key[0]])
          }
          this.routeDestination = new L.Polyline(poly, {color: 'red',}).bindPopup(`<b>Перепробег</b>`).addTo(this.nearestMap);

        } else {
          this.nearestMap.removeLayer(this.routeDestination)
        }
        if (request?.source?.route) {
          if (this.routeSource) {
            this.nearestMap.removeLayer(this.routeSource)
          }
          let poly = []
          for (let key of request?.source?.route) {
            poly.push([key[1], key[0]])
          }
          this.routeSource = new L.Polyline(poly, {color: 'green',}).bindPopup(`<b>Перепробег</b>`).addTo(this.nearestMap);

        } else {
          this.nearestMap.removeLayer(this.routeSource)
        }
      } else {
        this.nearestMap.removeLayer(this.routeSource)
        this.nearestMap.removeLayer(this.routeDestination)
        this.routeSource = null
        this.routeDestination = null
      }
    } catch(err) {
      return false
    }
  }

  // При изменении модели
  public async onChange() {
    try {
      let getNearest = await this.getNearest()
      await this.rebuildMarkers()
      return true
    } catch(err) {
      return false
    }
  }
}
