import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {Routes, RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {ClipboardModule} from 'ngx-clipboard';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {ModalModule} from 'ngx-bootstrap/modal';


import {AppComponent} from './app.component';
import {GetRouteComponent} from './get-route/get-route.component';
import {HeaderComponent} from './header/header.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {RouterComponent} from './router/router.component';
import {OptimizationComponent} from './optimization/optimization.component';
import {PolygonComponent} from './polygon/polygon.component';
import {DistanceComponent} from './distance/distance.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {HttpClientModule} from '@angular/common/http';
import {CoordsComponent} from './coords/coords.component';
import {IsochroneComponent} from './isochrone/isochrone.component';
import { MatrixComponent } from './matrix/matrix.component';
import {PolygonGuard} from './guards/polygon.guard';
import { MatchingComponent } from './matching/matching.component';
import { NearestComponent } from './nearest/nearest.component';

// Определение маршрутов
const appRoutes: Routes = [
  {path: 'get-route', component: GetRouteComponent, canActivate: [PolygonGuard], data: {title: 'Маршрут'}},
  {path: 'isochrone', component: IsochroneComponent, canActivate: [PolygonGuard], data: {title: 'Доступность'}},
  {path: 'distance', component: DistanceComponent, canActivate: [PolygonGuard], data: {title: 'Расстояние'}},
  {path: 'polygon', component: PolygonComponent, canActivate: [PolygonGuard], data: {title: 'Полигоны'}},
  {path: 'router', component: RouterComponent, data: {title: 'Маршрутизатор'}},
  {path: 'optimization', component: OptimizationComponent, canActivate: [PolygonGuard], data: {title: 'Оптимизация'}},
  {path: 'coords', component: CoordsComponent, canActivate: [PolygonGuard], data: {title: 'Координаты'}},
  {path: 'matching', component: MatchingComponent, canActivate: [PolygonGuard], data: {title: 'Сопоставление'}},
  {path: 'nearest', component: NearestComponent, canActivate: [PolygonGuard], data: {title: 'Ближайшая точка'}},
  {path: '', redirectTo: '/distance', pathMatch: 'full'},
  {path: '**', component: NotFoundComponent, data: {title: '404'}}
];


@NgModule({
  declarations: [
    AppComponent,
    GetRouteComponent,
    HeaderComponent,
    NotFoundComponent,
    RouterComponent,
    OptimizationComponent,
    PolygonComponent,
    DistanceComponent,
    CoordsComponent,
    IsochroneComponent,
    MatrixComponent,
    MatchingComponent,
    NearestComponent
  ],
  imports: [
    BrowserModule,
    LeafletModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ClipboardModule,
    GooglePlaceModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    RouterModule.forRoot(appRoutes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
