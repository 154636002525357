export const Mo150AreaAvoid = [
    [
      35.121276,
      55.503858
    ],
    [
      35.122971,
      55.503722
    ],
    [
      35.131001,
      55.50294
    ],
    [
      35.132784,
      55.502763
    ],
    [
      35.136518,
      55.502516
    ],
    [
      35.143829,
      55.497407
    ],
    [
      35.148881,
      55.501354
    ],
    [
      35.15375790013799,
      55.501662832932965
    ],
    [
      35.153705,
      55.500935
    ],
    [
      35.154903,
      55.500848
    ],
    [
      35.15696239145845,
      55.50115847617128
    ],
    [
      35.156907,
      55.500638
    ],
    [
      35.157437,
      55.500582
    ],
    [
      35.160622000856705,
      55.50028974389384
    ],
    [
      35.163427,
      55.499333
    ],
    [
      35.165146,
      55.499059
    ],
    [
      35.173568,
      55.497288
    ],
    [
      35.177019099421784,
      55.488925859620934
    ],
    [
      35.177258,
      55.487902
    ],
    [
      35.17729577675373,
      55.487864267741855
    ],
    [
      35.175512,
      55.48462
    ],
    [
      35.174377,
      55.481035
    ],
    [
      35.174354,
      55.480363
    ],
    [
      35.17442,
      55.479592
    ],
    [
      35.178007,
      55.479898
    ],
    [
      35.189362,
      55.479021
    ],
    [
      35.191476,
      55.481935
    ],
    [
      35.20013712504998,
      55.48978725419728
    ],
    [
      35.223463,
      55.482024
    ],
    [
      35.224728,
      55.472798
    ],
    [
      35.227467,
      55.468441
    ],
    [
      35.230346,
      55.461129
    ],
    [
      35.22833520058676,
      55.450063142428256
    ],
    [
      35.226012,
      55.447119
    ],
    [
      35.216806,
      55.445536
    ],
    [
      35.213682,
      55.448075
    ],
    [
      35.212499,
      55.448994
    ],
    [
      35.210291,
      55.44615
    ],
    [
      35.206845,
      55.442065
    ],
    [
      35.20751,
      55.441402
    ],
    [
      35.210493,
      55.440702
    ],
    [
      35.219154,
      55.434615
    ],
    [
      35.221081,
      55.432819
    ],
    [
      35.223007,
      55.431023
    ],
    [
      35.225462,
      55.433656
    ],
    [
      35.23100009291659,
      55.441093173915235
    ],
    [
      35.235285,
      55.441394
    ],
    [
      35.242823,
      55.440926
    ],
    [
      35.24282303616304,
      55.44092658266271
    ],
    [
      35.245267,
      55.440775
    ],
    [
      35.249419,
      55.440476
    ],
    [
      35.253459,
      55.44019
    ],
    [
      35.256148,
      55.439827
    ],
    [
      35.256629,
      55.443395
    ],
    [
      35.25394,
      55.443757
    ],
    [
      35.249677,
      55.444067
    ],
    [
      35.245525,
      55.444365
    ],
    [
      35.24304583601137,
      55.444519175635726
    ],
    [
      35.235105,
      55.453024
    ],
    [
      35.235968,
      55.456771
    ],
    [
      35.23336,
      55.463325
    ],
    [
      35.231319,
      55.468513
    ],
    [
      35.23529917459179,
      55.470191804422655
    ],
    [
      35.242444,
      55.470453
    ],
    [
      35.250556,
      55.47181
    ],
    [
      35.25055602045507,
      55.471810608242436
    ],
    [
      35.251082,
      55.471793
    ],
    [
      35.25527,
      55.47175
    ],
    [
      35.259446,
      55.471707
    ],
    [
      35.264887,
      55.471028
    ],
    [
      35.269882,
      55.469815
    ],
    [
      35.273962,
      55.469433
    ],
    [
      35.279939,
      55.472243
    ],
    [
      35.283836,
      55.47369
    ],
    [
      35.282584,
      55.477065
    ],
    [
      35.280590066798354,
      55.47632482007625
    ],
    [
      35.28059,
      55.476325
    ],
    [
      35.27769,
      55.475249
    ],
    [
      35.274789,
      55.474172
    ],
    [
      35.268069,
      55.473953
    ],
    [
      35.263415,
      55.475097
    ],
    [
      35.259507,
      55.475307
    ],
    [
      35.255307,
      55.47535
    ],
    [
      35.251119,
      55.475393
    ],
    [
      35.25067699630047,
      55.47540788999247
    ],
    [
      35.250677,
      55.475408
    ],
    [
      35.24726,
      55.475523
    ],
    [
      35.245168989909146,
      55.476024515900775
    ],
    [
      35.244459,
      55.478879
    ],
    [
      35.244214,
      55.478851
    ],
    [
      35.238329214667516,
      55.486409393882944
    ],
    [
      35.245956,
      55.491474
    ],
    [
      35.24596364374007,
      55.491479983705986
    ],
    [
      35.246858,
      55.492062
    ],
    [
      35.251953,
      55.495377
    ],
    [
      35.258429,
      55.495855
    ],
    [
      35.264449,
      55.495187
    ],
    [
      35.27229624903329,
      55.49430264810971
    ],
    [
      35.276752,
      55.4938
    ],
    [
      35.27676775499054,
      55.49379867132012
    ],
    [
      35.280232,
      55.493408
    ],
    [
      35.289505,
      55.49237
    ],
    [
      35.29407522042141,
      55.49193811317275
    ],
    [
      35.298358,
      55.491453
    ],
    [
      35.307929,
      55.490368
    ],
    [
      35.31249139795665,
      55.48998241822454
    ],
    [
      35.312483,
      55.489874
    ],
    [
      35.31295,
      55.489838
    ],
    [
      35.315064,
      55.489765
    ],
    [
      35.316483,
      55.489695
    ],
    [
      35.321244,
      55.489687
    ],
    [
      35.322383485650356,
      55.48968508369031
    ],
    [
      35.32405,
      55.489682
    ],
    [
      35.32413334738909,
      55.48968214089151
    ],
    [
      35.326001,
      55.489679
    ],
    [
      35.338809,
      55.489633
    ],
    [
      35.35193827474078,
      55.48958718399044
    ],
    [
      35.353661,
      55.480142
    ],
    [
      35.353613,
      55.476543
    ],
    [
      35.3561937074301,
      55.47464877648233
    ],
    [
      35.356161,
      55.474156
    ],
    [
      35.354963424984774,
      55.465994381260685
    ],
    [
      35.345617,
      55.459153
    ],
    [
      35.341609,
      55.457419
    ],
    [
      35.337398,
      55.455438
    ],
    [
      35.334264,
      55.453579
    ],
    [
      35.329791,
      55.444463
    ],
    [
      35.325028,
      55.443519
    ],
    [
      35.32247,
      55.44314
    ],
    [
      35.32267405844198,
      55.44176636651814
    ],
    [
      35.319734,
      55.440395
    ],
    [
      35.319793,
      55.433321
    ],
    [
      35.324815,
      55.435568
    ],
    [
      35.330559,
      55.440946
    ],
    [
      35.334660539535726,
      55.44268384226168
    ],
    [
      35.334795,
      55.441574
    ],
    [
      35.337145,
      55.441858
    ],
    [
      35.337406841234504,
      55.441901134550974
    ],
    [
      35.337408,
      55.441892
    ],
    [
      35.339556,
      55.442164
    ],
    [
      35.341704,
      55.442437
    ],
    [
      35.345096,
      55.442781
    ],
    [
      35.34918,
      55.443001
    ],
    [
      35.353336,
      55.444174
    ],
    [
      35.357888,
      55.445682
    ],
    [
      35.362413,
      55.446857
    ],
    [
      35.366086,
      55.449978
    ],
    [
      35.362856,
      55.453731
    ],
    [
      35.36164028927192,
      55.45493675920358
    ],
    [
      35.358617,
      55.466099
    ],
    [
      35.35897812888404,
      55.468561183238194
    ],
    [
      35.360756,
      55.468193
    ],
    [
      35.361273,
      55.470689
    ],
    [
      35.360919,
      55.481899
    ],
    [
      35.367752,
      55.489534
    ],
    [
      35.378579,
      55.489544
    ],
    [
      35.38104095066968,
      55.48959139729012
    ],
    [
      35.381041,
      55.489547
    ],
    [
      35.38181796388233,
      55.48959134120964
    ],
    [
      35.381818,
      55.489548
    ],
    [
      35.389344145534345,
      55.4895554993621
    ],
    [
      35.389405,
      55.489555
    ],
    [
      35.39515974904791,
      55.489478677068334
    ],
    [
      35.39940107873355,
      55.48906348867292
    ],
    [
      35.405046,
      55.488267
    ],
    [
      35.40859818599212,
      55.487504919258974
    ],
    [
      35.41209,
      55.486678
    ],
    [
      35.418568,
      55.485077
    ],
    [
      35.42918716914487,
      55.47778185614026
    ],
    [
      35.428676,
      55.474268
    ],
    [
      35.427952,
      55.4733
    ],
    [
      35.42855735683667,
      55.47284729471389
    ],
    [
      35.428431,
      55.47192
    ],
    [
      35.42920527670136,
      55.47224869432418
    ],
    [
      35.429688,
      55.470407
    ],
    [
      35.43080600043881,
      55.47116568680331
    ],
    [
      35.430835,
      55.471144
    ],
    [
      35.437921,
      55.475994
    ],
    [
      35.448682,
      55.478053
    ],
    [
      35.45374,
      55.477372
    ],
    [
      35.457661,
      55.476854
    ],
    [
      35.462758,
      55.476166
    ],
    [
      35.46557346959563,
      55.475786441991325
    ],
    [
      35.468535,
      55.475387
    ],
    [
      35.480263,
      55.47387
    ],
    [
      35.491988,
      55.472353
    ],
    [
      35.501666,
      55.471089
    ],
    [
      35.511349,
      55.469824
    ],
    [
      35.51965334761786,
      55.462894246116576
    ],
    [
      35.511067,
      55.454166
    ],
    [
      35.507925,
      55.452144
    ],
    [
      35.5080163086921,
      55.446474247062326
    ],
    [
      35.50697892147445,
      55.441608579742784
    ],
    [
      35.498917,
      55.436776
    ],
    [
      35.495764,
      55.435061
    ],
    [
      35.492601,
      55.43334
    ],
    [
      35.489138,
      55.431426
    ],
    [
      35.482604,
      55.429627
    ],
    [
      35.479075,
      55.429043
    ],
    [
      35.479075074856965,
      55.42904254780282
    ],
    [
      35.47752,
      55.428785
    ],
    [
      35.471824,
      55.427814
    ],
    [
      35.465505,
      55.426737
    ],
    [
      35.455965,
      55.425139
    ],
    [
      35.453644871240954,
      55.42475426978806
    ],
    [
      35.453642,
      55.424755
    ],
    [
      35.45361449014064,
      55.4247492319094
    ],
    [
      35.451225,
      55.424353
    ],
    [
      35.45042976108855,
      55.4240814783793
    ],
    [
      35.449445,
      55.423875
    ],
    [
      35.44642148485994,
      55.42271291650168
    ],
    [
      35.446246,
      55.422653
    ],
    [
      35.44542269533566,
      55.422329033249675
    ],
    [
      35.444257,
      55.421881
    ],
    [
      35.4415809920694,
      55.42081736130734
    ],
    [
      35.425948,
      55.414681
    ],
    [
      35.425727257865745,
      55.41459441424573
    ],
    [
      35.42014367125455,
      55.41240795823154
    ],
    [
      35.413493,
      55.409804
    ],
    [
      35.413495870493186,
      55.40979667182548
    ],
    [
      35.413099,
      55.409641
    ],
    [
      35.408281,
      55.409216
    ],
    [
      35.395363,
      55.408482
    ],
    [
      35.38242,
      55.407747
    ],
    [
      35.377213,
      55.417571
    ],
    [
      35.371047,
      55.418596
    ],
    [
      35.3706177433137,
      55.417717755961974
    ],
    [
      35.37059,
      55.417731
    ],
    [
      35.369176,
      55.414768
    ],
    [
      35.367752,
      55.410861
    ],
    [
      35.35639,
      55.407963
    ],
    [
      35.353774,
      55.409648
    ],
    [
      35.351179,
      55.407153
    ],
    [
      35.351800804789484,
      55.40149557753253
    ],
    [
      35.35113,
      55.401429
    ],
    [
      35.345804,
      55.40365
    ],
    [
      35.33793,
      55.40645
    ],
    [
      35.330352,
      55.413704
    ],
    [
      35.324247,
      55.411777
    ],
    [
      35.328822,
      55.404047
    ],
    [
      35.334081,
      55.4032
    ],
    [
      35.344503,
      55.400294
    ],
    [
      35.34939231016902,
      55.39825613708238
    ],
    [
      35.3547497956394,
      55.39323870946143
    ],
    [
      35.356748,
      55.39019
    ],
    [
      35.356754852281455,
      55.39019237744549
    ],
    [
      35.35734,
      55.388525
    ],
    [
      35.360719,
      55.38433
    ],
    [
      35.355736,
      55.374694
    ],
    [
      35.351492,
      55.375133
    ],
    [
      35.350846,
      55.375424
    ],
    [
      35.350544,
      55.375561
    ],
    [
      35.350177,
      55.375293
    ],
    [
      35.349708,
      55.37496
    ],
    [
      35.349259,
      55.373694
    ],
    [
      35.349102203146536,
      55.372060395573214
    ],
    [
      35.34184,
      55.365751
    ],
    [
      35.333741,
      55.365813
    ],
    [
      35.331589,
      55.365888
    ],
    [
      35.329436,
      55.365962
    ],
    [
      35.329390470125475,
      55.3659334686084
    ],
    [
      35.329076,
      55.36597
    ],
    [
      35.328275,
      55.365937
    ],
    [
      35.327108,
      55.365804
    ],
    [
      35.319639,
      55.36016
    ],
    [
      35.319639003713775,
      55.36015996345401
    ],
    [
      35.319403,
      55.360136
    ],
    [
      35.31940314535653,
      55.36013456959588
    ],
    [
      35.316138,
      55.359802
    ],
    [
      35.310163,
      55.359503
    ],
    [
      35.306234,
      55.359312
    ],
    [
      35.296700559479326,
      55.360814590511914
    ],
    [
      35.292081,
      55.362592
    ],
    [
      35.29086319744056,
      55.36204145821528
    ],
    [
      35.281162,
      55.362569
    ],
    [
      35.279119,
      55.362721
    ],
    [
      35.278372,
      55.362792
    ],
    [
      35.278035,
      55.359207
    ],
    [
      35.278781,
      55.359137
    ],
    [
      35.283208,
      55.3586
    ],
    [
      35.28688,
      55.358109
    ],
    [
      35.297702,
      55.356201
    ],
    [
      35.303023,
      55.355479
    ],
    [
      35.306781,
      55.355754
    ],
    [
      35.310328,
      55.355906
    ],
    [
      35.316344,
      55.356208
    ],
    [
      35.31976688003645,
      55.356555180520466
    ],
    [
      35.319767,
      55.356554
    ],
    [
      35.320002999417454,
      55.3565780057326
    ],
    [
      35.320003,
      55.356578
    ],
    [
      35.32064496940015,
      55.35664330112263
    ],
    [
      35.320645,
      55.356643
    ],
    [
      35.3236343336609,
      55.356946870527466
    ],
    [
      35.333878,
      55.357569
    ],
    [
      35.335894,
      55.360476
    ],
    [
      35.341881,
      55.362151
    ],
    [
      35.346753,
      55.353292
    ],
    [
      35.342751,
      55.347541
    ],
    [
      35.33807,
      55.346068
    ],
    [
      35.332879,
      55.344611
    ],
    [
      35.324848,
      55.340436
    ],
    [
      35.32184,
      55.338198
    ],
    [
      35.316079,
      55.336811
    ],
    [
      35.312422,
      55.335744
    ],
    [
      35.310462,
      55.332745
    ],
    [
      35.303906,
      55.327051
    ],
    [
      35.296728,
      55.327409
    ],
    [
      35.296549,
      55.323813
    ],
    [
      35.303727,
      55.323456
    ],
    [
      35.310905,
      55.323098
    ],
    [
      35.312988,
      55.324162
    ],
    [
      35.31852834981886,
      55.33164734934673
    ],
    [
      35.323467,
      55.334987
    ],
    [
      35.330466236946286,
      55.33925540597587
    ],
    [
      35.336627,
      55.341911
    ],
    [
      35.341605,
      55.343511
    ],
    [
      35.345586,
      55.344516
    ],
    [
      35.34836320498837,
      55.345959027004035
    ],
    [
      35.348619,
      55.34535
    ],
    [
      35.349274,
      55.345625
    ],
    [
      35.34946147924548,
      55.34581304056058
    ],
    [
      35.349512,
      55.345746
    ],
    [
      35.350094,
      55.346098
    ],
    [
      35.350944,
      55.3473
    ],
    [
      35.35876141751296,
      55.35512024581162
    ],
    [
      35.36012811872602,
      55.35508681834219
    ],
    [
      35.3667116000501,
      55.35133663985592
    ],
    [
      35.36837448037846,
      55.349390608101494
    ],
    [
      35.369579,
      55.347681
    ],
    [
      35.37481734578682,
      55.35112950764331
    ],
    [
      35.380352906790336,
      55.350603543124286
    ],
    [
      35.383454,
      55.347796
    ],
    [
      35.38599173705126,
      55.346760021351706
    ],
    [
      35.386041,
      55.346712
    ],
    [
      35.38841403549023,
      55.345710963033575
    ],
    [
      35.39418563531028,
      55.34280678223241
    ],
    [
      35.394675921245046,
      55.34252455423324
    ],
    [
      35.396789,
      55.341299
    ],
    [
      35.39678906434567,
      55.34129911098381
    ],
    [
      35.397022,
      55.341164
    ],
    [
      35.3970221807506,
      55.34116431175975
    ],
    [
      35.398073,
      55.340555
    ],
    [
      35.403913,
      55.337304
    ],
    [
      35.409104,
      55.328252
    ],
    [
      35.409376,
      55.325791
    ],
    [
      35.409386856244694,
      55.3257922015296
    ],
    [
      35.409418,
      55.325516
    ],
    [
      35.409731,
      55.324288
    ],
    [
      35.41044,
      55.322679
    ],
    [
      35.41044807982221,
      55.32268255914451
    ],
    [
      35.410489,
      55.32257
    ],
    [
      35.41291491113461,
      55.31752120687804
    ],
    [
      35.412922,
      55.317505
    ],
    [
      35.415367,
      55.312417
    ],
    [
      35.417501,
      55.308613
    ],
    [
      35.422631,
      55.301499
    ],
    [
      35.425551,
      55.303605
    ],
    [
      35.420421,
      55.310719
    ],
    [
      35.418589,
      55.314023
    ],
    [
      35.41758142997852,
      55.31612009350878
    ],
    [
      35.416159530118925,
      55.31908072533595
    ],
    [
      35.41354702741284,
      55.32493021410035
    ],
    [
      35.412687,
      55.328611
    ],
    [
      35.415216,
      55.337836
    ],
    [
      35.423719,
      55.341239
    ],
    [
      35.42402583492679,
      55.341482017346344
    ],
    [
      35.426602,
      55.341728
    ],
    [
      35.431812,
      55.350091
    ],
    [
      35.438105,
      55.34971
    ],
    [
      35.443061,
      55.349622
    ],
    [
      35.448018,
      55.349535
    ],
    [
      35.448081,
      55.353134
    ],
    [
      35.439457,
      55.358957
    ],
    [
      35.436051,
      55.360123
    ],
    [
      35.428182,
      55.353179
    ],
    [
      35.42789509408347,
      55.353091939715654
    ],
    [
      35.427889,
      55.353121
    ],
    [
      35.426056,
      55.352736
    ],
    [
      35.422936,
      55.352484
    ],
    [
      35.422493,
      55.352429
    ],
    [
      35.421622,
      55.350264
    ],
    [
      35.417234059289534,
      55.34440038819536
    ],
    [
      35.416435797061226,
      55.343703123364435
    ],
    [
      35.405664,
      55.34045
    ],
    [
      35.401811,
      55.342595
    ],
    [
      35.39662136036115,
      55.34555868162089
    ],
    [
      35.395907,
      55.345973
    ],
    [
      35.393218,
      55.347532
    ],
    [
      35.392822,
      55.347743
    ],
    [
      35.39104124674788,
      55.34852700095116
    ],
    [
      35.390742,
      55.348686
    ],
    [
      35.3899277661006,
      55.34901722602053
    ],
    [
      35.389181,
      55.349346
    ],
    [
      35.38732662926144,
      55.35010314791401
    ],
    [
      35.380986,
      55.358485
    ],
    [
      35.37632001664428,
      55.36235605844636
    ],
    [
      35.37283,
      55.369575
    ],
    [
      35.379595,
      55.375207
    ],
    [
      35.387297,
      55.376308
    ],
    [
      35.395643,
      55.377632
    ],
    [
      35.396474234635875,
      55.37813824946419
    ],
    [
      35.397185,
      55.37828
    ],
    [
      35.400003,
      55.37604
    ],
    [
      35.406787,
      55.376829
    ],
    [
      35.407641,
      55.380326
    ],
    [
      35.403919,
      55.384614
    ],
    [
      35.401174,
      55.386944
    ],
    [
      35.40038,
      55.386008
    ],
    [
      35.40002612651172,
      55.384904475800475
    ],
    [
      35.399703,
      55.385163
    ],
    [
      35.386788,
      55.379872
    ],
    [
      35.377019,
      55.378884
    ],
    [
      35.367482,
      55.382035
    ],
    [
      35.363437,
      55.386691
    ],
    [
      35.360572,
      55.390111
    ],
    [
      35.36581924978574,
      55.400059173398475
    ],
    [
      35.37499,
      55.402626
    ],
    [
      35.382675,
      55.404156
    ],
    [
      35.38414796401279,
      55.40423963401003
    ],
    [
      35.384148,
      55.404239
    ],
    [
      35.3961493801382,
      55.4049204819357
    ],
    [
      35.410287,
      55.405709
    ],
    [
      35.414091,
      55.406181
    ],
    [
      35.41480311044524,
      55.406459376837425
    ],
    [
      35.414806,
      55.406452
    ],
    [
      35.416213890653616,
      55.4070032791539
    ],
    [
      35.416214,
      55.407003
    ],
    [
      35.422072428121346,
      55.40929727092804
    ],
    [
      35.427537,
      55.411437
    ],
    [
      35.440269,
      55.416422
    ],
    [
      35.442932,
      55.41748
    ],
    [
      35.44673898926572,
      55.41897789941442
    ],
    [
      35.4477403836748,
      55.41936290039541
    ],
    [
      35.45146871595688,
      55.420653444723214
    ],
    [
      35.45212057843841,
      55.42078322646338
    ],
    [
      35.45788563077389,
      55.41823337258363
    ],
    [
      35.461819,
      55.415039
    ],
    [
      35.46313220534138,
      55.41557232517712
    ],
    [
      35.464199,
      55.414922
    ],
    [
      35.468056,
      55.417572
    ],
    [
      35.470571,
      55.421055
    ],
    [
      35.478125,
      55.425236
    ],
    [
      35.488005,
      55.420222
    ],
    [
      35.489236,
      55.418233
    ],
    [
      35.489289672937275,
      55.41825222953004
    ],
    [
      35.489556,
      55.417833
    ],
    [
      35.493052,
      55.416187
    ],
    [
      35.50093,
      55.413383
    ],
    [
      35.505871,
      55.411496
    ],
    [
      35.51045,
      55.405727
    ],
    [
      35.510923,
      55.405021
    ],
    [
      35.517867,
      55.397448
    ],
    [
      35.51920851336375,
      55.39598477675446
    ],
    [
      35.51922,
      55.395972
    ],
    [
      35.519632824712325,
      55.3955219694166
    ],
    [
      35.523844766209294,
      55.39092789613059
    ],
    [
      35.525636,
      55.388974
    ],
    [
      35.52563787224235,
      55.38897571633972
    ],
    [
      35.527453,
      55.386997
    ],
    [
      35.530036,
      55.377482
    ],
    [
      35.530222,
      55.374602
    ],
    [
      35.533815,
      55.374834
    ],
    [
      35.533629,
      55.377713
    ],
    [
      35.533443,
      55.380593
    ],
    [
      35.532293,
      55.385062
    ],
    [
      35.530235,
      55.389282
    ],
    [
      35.52828863650782,
      55.391405750046545
    ],
    [
      35.52829,
      55.391407
    ],
    [
      35.52746489843164,
      55.392306906889296
    ],
    [
      35.527465,
      55.392307
    ],
    [
      35.521872542636714,
      55.39840683756762
    ],
    [
      35.513468,
      55.409004
    ],
    [
      35.511421,
      55.413487
    ],
    [
      35.506905,
      55.414945
    ],
    [
      35.502385,
      55.417684
    ],
    [
      35.498304,
      55.418584
    ],
    [
      35.49398,
      55.419666
    ],
    [
      35.49399244243042,
      55.4200266933755
    ],
    [
      35.494221,
      55.420148
    ],
    [
      35.4940103184213,
      55.42054490013049
    ],
    [
      35.49420622544603,
      55.42622404509247
    ],
    [
      35.497485,
      55.431899
    ],
    [
      35.500637,
      55.433614
    ],
    [
      35.508921,
      55.437183
    ],
    [
      35.512282,
      55.440856
    ],
    [
      35.513766,
      55.444293
    ],
    [
      35.519162,
      55.453367
    ],
    [
      35.52324,
      55.459295
    ],
    [
      35.52336511549122,
      55.45946005096532
    ],
    [
      35.525022,
      55.460939
    ],
    [
      35.52544531381453,
      55.46193939416901
    ],
    [
      35.525664,
      55.462187
    ],
    [
      35.52781617685002,
      55.46497582769914
    ],
    [
      35.529096,
      55.466426
    ],
    [
      35.539077,
      55.467742
    ],
    [
      35.54812,
      55.466441
    ],
    [
      35.55415066794146,
      55.46569548319932
    ],
    [
      35.556899028435765,
      55.46424266053504
    ],
    [
      35.562096,
      55.457938
    ],
    [
      35.564059,
      55.451018
    ],
    [
      35.56492692698189,
      55.44976471190132
    ],
    [
      35.565123,
      55.448846
    ],
    [
      35.56547790538532,
      55.44896909811115
    ],
    [
      35.566318,
      55.447756
    ],
    [
      35.57018370671354,
      55.45060129440594
    ],
    [
      35.5705,
      55.450711
    ],
    [
      35.570504268180564,
      55.4508372387795
    ],
    [
      35.573228,
      55.452842
    ],
    [
      35.57107095219055,
      55.454243364788915
    ],
    [
      35.571103,
      55.454334
    ],
    [
      35.570689,
      55.456301
    ],
    [
      35.575416,
      55.465942
    ],
    [
      35.57790100005113,
      55.465813000983
    ],
    [
      35.577901,
      55.465813
    ],
    [
      35.585789,
      55.465403
    ],
    [
      35.593678,
      55.464993
    ],
    [
      35.601671,
      55.46459
    ],
    [
      35.60737,
      55.464291
    ],
    [
      35.618855,
      55.463642
    ],
    [
      35.630703,
      55.463064
    ],
    [
      35.63209392455461,
      55.463050430004344
    ],
    [
      35.632137,
      55.462994
    ],
    [
      35.63260563776682,
      55.46304276749103
    ],
    [
      35.642551,
      55.462485
    ],
    [
      35.645421,
      55.462433
    ],
    [
      35.649508,
      55.462148
    ],
    [
      35.660675,
      55.461639
    ],
    [
      35.66067879857303,
      55.461722313824396
    ],
    [
      35.66122302927588,
      55.46169681032198
    ],
    [
      35.671356926098944,
      55.45998168710514
    ],
    [
      35.67280039653909,
      55.45953494880834
    ],
    [
      35.672557571666935,
      55.456052155331584
    ],
    [
      35.66984902393516,
      55.45037664113962
    ],
    [
      35.668757,
      55.449421
    ],
    [
      35.668651612960254,
      55.44938358845684
    ],
    [
      35.662631,
      55.448129
    ],
    [
      35.65995060237388,
      55.44852609594461
    ],
    [
      35.65932088531148,
      55.44863725054729
    ],
    [
      35.657395,
      55.449558
    ],
    [
      35.655842,
      55.44631
    ],
    [
      35.656152566737,
      55.44467952463078
    ],
    [
      35.65592853365534,
      55.44455389413936
    ],
    [
      35.646189530024756,
      55.4434241852602
    ],
    [
      35.645012807051636,
      55.4437143156889
    ],
    [
      35.64276449210608,
      55.444378313783474
    ],
    [
      35.64105640430546,
      55.44498518201528
    ],
    [
      35.637765614423174,
      55.44625889300141
    ],
    [
      35.63765549243444,
      55.44630318596983
    ],
    [
      35.63336791076278,
      55.44806721957189
    ],
    [
      35.633340338588845,
      55.44807882441978
    ],
    [
      35.628824,
      55.450073
    ],
    [
      35.62836667642484,
      55.45012940194135
    ],
    [
      35.627927,
      55.450308
    ],
    [
      35.62755304022013,
      55.45022974809448
    ],
    [
      35.625305,
      55.450507
    ],
    [
      35.615916,
      55.449699
    ],
    [
      35.615704730216905,
      55.44961929838789
    ],
    [
      35.614457,
      55.449719
    ],
    [
      35.613337,
      55.44902
    ],
    [
      35.61348548196937,
      55.44878208607846
    ],
    [
      35.61225,
      55.448316
    ],
    [
      35.609292,
      55.445449
    ],
    [
      35.602115,
      55.442662
    ],
    [
      35.596509,
      55.442784
    ],
    [
      35.592872,
      55.443101
    ],
    [
      35.588151,
      55.443952
    ],
    [
      35.584078,
      55.444877
    ],
    [
      35.582903,
      55.44526
    ],
    [
      35.581786,
      55.441838
    ],
    [
      35.582961,
      55.441454
    ],
    [
      35.590158,
      55.432217
    ],
    [
      35.589673,
      55.42865
    ],
    [
      35.591614,
      55.428303
    ],
    [
      35.599784,
      55.426187
    ],
    [
      35.600253,
      55.426002
    ],
    [
      35.600948,
      55.425755
    ],
    [
      35.608709,
      55.431561
    ],
    [
      35.611272,
      55.434055
    ],
    [
      35.614529,
      55.445529
    ],
    [
      35.624938,
      55.446925
    ],
    [
      35.629753,
      55.445686
    ],
    [
      35.631914636391116,
      55.444774340330895
    ],
    [
      35.631951,
      55.444758
    ],
    [
      35.63204913162646,
      55.44471761761737
    ],
    [
      35.634085,
      55.443859
    ],
    [
      35.63631980741563,
      55.44296018150819
    ],
    [
      35.636381,
      55.442935
    ],
    [
      35.63641843949203,
      55.442920512616126
    ],
    [
      35.63865,
      55.442023
    ],
    [
      35.63974884604764,
      55.44163179646107
    ],
    [
      35.640919,
      55.441179
    ],
    [
      35.641534961802954,
      55.44099591593357
    ],
    [
      35.642633,
      55.440605
    ],
    [
      35.64286527019385,
      55.44054470066413
    ],
    [
      35.642864,
      55.44054
    ],
    [
      35.645111,
      55.439933
    ],
    [
      35.655499,
      55.435754
    ],
    [
      35.657111,
      55.434938
    ],
    [
      35.658737,
      55.438149
    ],
    [
      35.66096186740717,
      55.441383475340224
    ],
    [
      35.66417,
      55.441181
    ],
    [
      35.67148168320607,
      55.437829811863885
    ],
    [
      35.67084128607216,
      55.43376451219548
    ],
    [
      35.67082958273278,
      55.43373451468875
    ],
    [
      35.668672187663134,
      55.43018699571526
    ],
    [
      35.66357,
      55.427375
    ],
    [
      35.66219714151201,
      55.42131166097406
    ],
    [
      35.66029578658628,
      55.416758828695905
    ],
    [
      35.66026869857521,
      55.41671598261914
    ],
    [
      35.652999,
      55.409127
    ],
    [
      35.64433815420174,
      55.40643368819688
    ],
    [
      35.635037,
      55.405374
    ],
    [
      35.631206,
      55.404302
    ],
    [
      35.627917,
      55.402745
    ],
    [
      35.621911,
      55.401239
    ],
    [
      35.619922,
      55.40091
    ],
    [
      35.617932,
      55.400581
    ],
    [
      35.61852,
      55.397029
    ],
    [
      35.620509,
      55.397358
    ],
    [
      35.622499,
      55.397687
    ],
    [
      35.623996,
      55.397898
    ],
    [
      35.6311420848664,
      55.388779204363
    ],
    [
      35.629511,
      55.385484
    ],
    [
      35.62950845338395,
      55.38547891306361
    ],
    [
      35.628033,
      55.382545
    ],
    [
      35.62613,
      55.378718
    ],
    [
      35.62419,
      55.3749
    ],
    [
      35.614967,
      55.369265
    ],
    [
      35.607232,
      55.368002
    ],
    [
      35.601757,
      55.367099
    ],
    [
      35.599656,
      55.367009
    ],
    [
      35.59981,
      55.363412
    ],
    [
      35.60191,
      55.363502
    ],
    [
      35.604011,
      55.363592
    ],
    [
      35.608044,
      55.364495
    ],
    [
      35.61246072431543,
      55.36112531770998
    ],
    [
      35.615677,
      55.357709
    ],
    [
      35.613065,
      55.352476
    ],
    [
      35.611002,
      55.348255
    ],
    [
      35.604465,
      55.341772
    ],
    [
      35.601257,
      55.341203
    ],
    [
      35.60126835681982,
      55.34113901181325
    ],
    [
      35.598415,
      55.340574
    ],
    [
      35.592228,
      55.340591
    ],
    [
      35.589025,
      55.340632
    ],
    [
      35.586744,
      55.340403
    ],
    [
      35.58658487361235,
      55.34038703157635
    ],
    [
      35.586584,
      55.340387
    ],
    [
      35.58657868675587,
      55.34038641072178
    ],
    [
      35.584462,
      55.340174
    ],
    [
      35.58446421492605,
      55.340151900097204
    ],
    [
      35.58222,
      55.339903
    ],
    [
      35.577975,
      55.338883
    ],
    [
      35.570032,
      55.338736
    ],
    [
      35.570772,
      55.334695
    ],
    [
      35.576439,
      55.334563
    ],
    [
      35.586768,
      55.331927
    ],
    [
      35.59184,
      55.333506
    ],
    [
      35.600461,
      55.334269
    ],
    [
      35.601208,
      55.322315
    ],
    [
      35.597985,
      55.320742
    ],
    [
      35.593644,
      55.318688
    ],
    [
      35.590397,
      55.317287
    ],
    [
      35.584776,
      55.315709
    ],
    [
      35.579168,
      55.314134
    ],
    [
      35.573359,
      55.312525
    ],
    [
      35.569558,
      55.311433
    ],
    [
      35.558025,
      55.310135
    ],
    [
      35.550337,
      55.31759
    ],
    [
      35.546805,
      55.31776
    ],
    [
      35.540254182463215,
      55.31273658626832
    ],
    [
      35.535973,
      55.312291
    ],
    [
      35.533969,
      55.313989
    ],
    [
      35.533958273265874,
      55.31397634180838
    ],
    [
      35.533638,
      55.314258
    ],
    [
      35.530257,
      55.316592
    ],
    [
      35.526636,
      55.319066
    ],
    [
      35.523196,
      55.320718
    ],
    [
      35.51927,
      55.322163
    ],
    [
      35.517333,
      55.322615
    ],
    [
      35.515561,
      55.322635
    ],
    [
      35.515521,
      55.319035
    ],
    [
      35.520025,
      55.31805
    ],
    [
      35.52507,
      55.315824
    ],
    [
      35.528212,
      55.31363
    ],
    [
      35.53235546455824,
      55.31051439525488
    ],
    [
      35.536967,
      55.305805
    ],
    [
      35.541011,
      55.303798
    ],
    [
      35.545689,
      55.303226
    ],
    [
      35.54823983623533,
      55.30082908746459
    ],
    [
      35.54885100014724,
      55.29955592319558
    ],
    [
      35.546935151472965,
      55.29120566019781
    ],
    [
      35.543444,
      55.290218
    ],
    [
      35.543449812411225,
      55.29019743391983
    ],
    [
      35.542926,
      55.290046
    ],
    [
      35.531114,
      55.289692
    ],
    [
      35.523216,
      55.287972
    ],
    [
      35.518732,
      55.289618
    ],
    [
      35.51456,
      55.29014
    ],
    [
      35.510578,
      55.29029
    ],
    [
      35.510442,
      55.286693
    ],
    [
      35.512433,
      55.286617
    ],
    [
      35.519808,
      55.285392
    ],
    [
      35.52214783237072,
      55.28455757448544
    ],
    [
      35.52214,
      55.284535
    ],
    [
      35.524704,
      55.283646
    ],
    [
      35.528323,
      55.283749
    ],
    [
      35.536762,
      55.284304
    ],
    [
      35.540251,
      55.284929
    ],
    [
      35.547914,
      55.287741
    ],
    [
      35.54793747677251,
      55.28774798359688
    ],
    [
      35.548033,
      55.287775
    ],
    [
      35.551642,
      55.288795
    ],
    [
      35.55218,
      55.28901
    ],
    [
      35.552724963585966,
      55.28971190218125
    ],
    [
      35.552804,
      55.289431
    ],
    [
      35.554267584151496,
      55.291698766565744
    ],
    [
      35.554576,
      55.292096
    ],
    [
      35.55492197020531,
      55.292712712236316
    ],
    [
      35.555958,
      55.294318
    ],
    [
      35.55602642403566,
      55.294681466138144
    ],
    [
      35.556319,
      55.295203
    ],
    [
      35.55709450008552,
      55.29846612149571
    ],
    [
      35.559226360796636,
      55.30052252764999
    ],
    [
      35.559788,
      55.300717
    ],
    [
      35.56299214379293,
      55.30404038644298
    ],
    [
      35.570785,
      55.308049
    ],
    [
      35.574331,
      55.309059
    ],
    [
      35.580116,
      55.310661
    ],
    [
      35.585749,
      55.312243
    ],
    [
      35.594227,
      55.311759
    ],
    [
      35.59572567085681,
      55.313040723410666
    ],
    [
      35.597196,
      55.313191
    ],
    [
      35.60278577900201,
      55.319078767220724
    ],
    [
      35.603446993644305,
      55.319401445866575
    ],
    [
      35.606029,
      55.320661
    ],
    [
      35.61215710257826,
      55.32297586587364
    ],
    [
      35.614304248242554,
      55.32077823356738
    ],
    [
      35.61414,
      55.319674
    ],
    [
      35.614734,
      55.319294
    ],
    [
      35.61606235070384,
      55.318440623360885
    ],
    [
      35.616048,
      55.318419
    ],
    [
      35.617948,
      55.317159
    ],
    [
      35.619847,
      55.315899
    ],
    [
      35.621474,
      55.314964
    ],
    [
      35.625022,
      55.314121
    ],
    [
      35.630624,
      55.313318
    ],
    [
      35.634487,
      55.311983
    ],
    [
      35.639777,
      55.310235
    ],
    [
      35.64071417740823,
      55.31002278547322
    ],
    [
      35.640714,
      55.310022
    ],
    [
      35.644741,
      55.309111
    ],
    [
      35.64517766265572,
      55.30901232530005
    ],
    [
      35.65087,
      55.307725
    ],
    [
      35.656388,
      55.297653
    ],
    [
      35.655068,
      55.295471
    ],
    [
      35.655068011579715,
      55.29547099299805
    ],
    [
      35.654552,
      55.294618
    ],
    [
      35.652002,
      55.290408
    ],
    [
      35.647533,
      55.284759
    ],
    [
      35.64717604441444,
      55.28391332669996
    ],
    [
      35.646864,
      55.283466
    ],
    [
      35.6467701898249,
      55.282951805424084
    ],
    [
      35.64649,
      55.282288
    ],
    [
      35.646174,
      55.279904
    ],
    [
      35.64621319713059,
      55.27989880374362
    ],
    [
      35.646145,
      55.279525
    ],
    [
      35.64466173106478,
      55.273684557529776
    ],
    [
      35.638422,
      55.266845
    ],
    [
      35.6359,
      55.264398
    ],
    [
      35.632308,
      55.261297
    ],
    [
      35.628818,
      55.257261
    ],
    [
      35.625288,
      55.253763
    ],
    [
      35.618574,
      55.247652
    ],
    [
      35.617437,
      55.246566
    ],
    [
      35.614922,
      55.243843
    ],
    [
      35.61491509705669,
      55.24383553187135
    ],
    [
      35.614905,
      55.243825
    ],
    [
      35.61488445146862,
      55.2438023771453
    ],
    [
      35.612406,
      55.241121
    ],
    [
      35.61242934300076,
      55.2410994315617
    ],
    [
      35.612161,
      55.240804
    ],
    [
      35.607383,
      55.230334
    ],
    [
      35.606473,
      55.229853
    ],
    [
      35.60492,
      55.228501
    ],
    [
      35.607282,
      55.225785
    ],
    [
      35.616052,
      55.223646
    ],
    [
      35.61865,
      55.224032
    ],
    [
      35.621099,
      55.225876
    ],
    [
      35.6192619645227,
      55.229170654312775
    ],
    [
      35.619767,
      55.229691
    ],
    [
      35.617632455917324,
      55.23303102401425
    ],
    [
      35.61754900412325,
      55.24138158740904
    ],
    [
      35.617559945344915,
      55.24139342923781
    ],
    [
      35.61756701824444,
      55.2414008148297
    ],
    [
      35.624524,
      55.246968
    ],
    [
      35.627794,
      55.250305
    ],
    [
      35.63018,
      55.253497
    ],
    [
      35.633264,
      55.2569
    ],
    [
      35.636636,
      55.260052
    ],
    [
      35.64346,
      55.265292
    ],
    [
      35.645232900390575,
      55.26639851225825
    ],
    [
      35.645378,
      55.266155
    ],
    [
      35.646671,
      55.266926
    ],
    [
      35.647605,
      55.267879
    ],
    [
      35.648934,
      55.271991
    ],
    [
      35.649784,
      55.27559
    ],
    [
      35.65197162797747,
      55.277864544455866
    ],
    [
      35.657734,
      55.279592
    ],
    [
      35.66037,
      55.280811
    ],
    [
      35.660371922686736,
      55.280833660236496
    ],
    [
      35.661789,
      55.280906
    ],
    [
      35.660068474922824,
      55.28274617250923
    ],
    [
      35.659891,
      55.283447
    ],
    [
      35.65763,
      55.292751
    ],
    [
      35.66195,
      55.298203
    ],
    [
      35.66311743035268,
      55.30015870810045
    ],
    [
      35.663279,
      55.300364
    ],
    [
      35.66879347156172,
      55.303324753891005
    ],
    [
      35.67512250072083,
      55.30501955667838
    ],
    [
      35.679746,
      55.304524
    ],
    [
      35.687711,
      55.303002
    ],
    [
      35.692055,
      55.303301
    ],
    [
      35.699375,
      55.303847
    ],
    [
      35.702983,
      55.304097
    ],
    [
      35.706991,
      55.293389
    ],
    [
      35.704452,
      55.290436
    ],
    [
      35.70607465428517,
      55.27943757010128
    ],
    [
      35.70538,
      55.276744
    ],
    [
      35.70535783778301,
      55.276650090014456
    ],
    [
      35.704636,
      55.274025
    ],
    [
      35.704451497362705,
      55.27280957099865
    ],
    [
      35.70423,
      55.271871
    ],
    [
      35.699128,
      55.262713
    ],
    [
      35.698802,
      55.253672
    ],
    [
      35.697985,
      55.250043
    ],
    [
      35.697629,
      55.248213
    ],
    [
      35.69736462869788,
      55.24685327007252
    ],
    [
      35.697159,
      55.245796
    ],
    [
      35.69701591854129,
      55.245115096000994
    ],
    [
      35.691317,
      55.238964
    ],
    [
      35.68882,
      55.238254
    ],
    [
      35.686322,
      55.237543
    ],
    [
      35.687307,
      55.234081
    ],
    [
      35.69458861811517,
      55.233331490553354
    ],
    [
      35.693251,
      55.226989
    ],
    [
      35.692024,
      55.220667
    ],
    [
      35.68416850325698,
      55.214454517276735
    ],
    [
      35.681784730655366,
      55.21368571295979
    ],
    [
      35.678055,
      55.21404
    ],
    [
      35.676966,
      55.214128
    ],
    [
      35.67693364466677,
      55.21372618930462
    ],
    [
      35.672209,
      55.212404
    ],
    [
      35.674566,
      55.209683
    ],
    [
      35.674706,
      55.200383
    ],
    [
      35.664548,
      55.194516
    ],
    [
      35.661041,
      55.194377
    ],
    [
      35.656813,
      55.194593
    ],
    [
      35.652035,
      55.193596
    ],
    [
      35.646832,
      55.193495
    ],
    [
      35.643089,
      55.191499
    ],
    [
      35.641372,
      55.18682
    ],
    [
      35.639883,
      55.182988
    ],
    [
      35.6396577042084,
      55.182407802773255
    ],
    [
      35.63886854088385,
      55.180375886801066
    ],
    [
      35.638332,
      55.178995
    ],
    [
      35.63833219703344,
      55.17899492344112
    ],
    [
      35.638293,
      55.178894
    ],
    [
      35.635849,
      55.172666
    ],
    [
      35.63584589728599,
      55.172658090174835
    ],
    [
      35.635818,
      55.172587
    ],
    [
      35.63578694060942,
      55.17250779046887
    ],
    [
      35.633406,
      55.166438
    ],
    [
      35.63340675708982,
      55.16643770290268
    ],
    [
      35.633321,
      55.166219
    ],
    [
      35.631482,
      55.161287
    ],
    [
      35.63049,
      55.158772
    ],
    [
      35.62885883292969,
      55.15454824277619
    ],
    [
      35.628842,
      55.154505
    ],
    [
      35.627199,
      55.150252
    ],
    [
      35.626609,
      55.145978
    ],
    [
      35.629095,
      55.142929
    ],
    [
      35.632032,
      55.139421
    ],
    [
      35.638449,
      55.131786
    ],
    [
      35.641656,
      55.127903
    ],
    [
      35.64543883672613,
      55.123444406738436
    ],
    [
      35.649273,
      55.116005
    ],
    [
      35.65291,
      55.113438
    ],
    [
      35.655868,
      55.111385
    ],
    [
      35.659534,
      55.109016
    ],
    [
      35.663391,
      55.107097
    ],
    [
      35.666455,
      55.106272
    ],
    [
      35.669146,
      55.105676
    ],
    [
      35.669201172466046,
      55.1056637964248
    ],
    [
      35.676836,
      55.101434
    ],
    [
      35.680588,
      55.098309
    ],
    [
      35.683744,
      55.095767
    ],
    [
      35.68842,
      55.092723
    ],
    [
      35.691513,
      55.09313
    ],
    [
      35.691646,
      55.096727
    ],
    [
      35.68444,
      55.099828
    ],
    [
      35.680887,
      55.102803
    ],
    [
      35.680819315512224,
      55.10285714378131
    ],
    [
      35.68082,
      55.102858
    ],
    [
      35.679076,
      55.104252
    ],
    [
      35.677333,
      55.105646
    ],
    [
      35.674427,
      55.10795
    ],
    [
      35.67265986543553,
      55.10843833419778
    ],
    [
      35.672691,
      55.108579
    ],
    [
      35.66997779168027,
      55.10917950488279
    ],
    [
      35.669976,
      55.10918
    ],
    [
      35.660374,
      55.112799
    ],
    [
      35.6576079677125,
      55.1146375678569
    ],
    [
      35.650778,
      55.122518
    ],
    [
      35.65048233927049,
      55.122897146658424
    ],
    [
      35.650557,
      55.122949
    ],
    [
      35.650031,
      55.123707
    ],
    [
      35.64983657319772,
      55.1239327068241
    ],
    [
      35.649841,
      55.123937
    ],
    [
      35.648129,
      55.125915
    ],
    [
      35.644376,
      55.130261
    ],
    [
      35.641173,
      55.13414
    ],
    [
      35.638381,
      55.137521
    ],
    [
      35.638245215153106,
      55.137680330917306
    ],
    [
      35.638246,
      55.137681
    ],
    [
      35.636509,
      55.139719
    ],
    [
      35.634771,
      55.141757
    ],
    [
      35.63220004891847,
      55.153207782121584
    ],
    [
      35.632216562428155,
      55.153250542337325
    ],
    [
      35.634831,
      55.159966
    ],
    [
      35.636717,
      55.165026
    ],
    [
      35.63675533578754,
      55.165123653070545
    ],
    [
      35.636757,
      55.165123
    ],
    [
      35.639196897285586,
      55.17134309017382
    ],
    [
      35.641644,
      55.177579
    ],
    [
      35.641687582544286,
      55.17769116220568
    ],
    [
      35.641688,
      55.177691
    ],
    [
      35.64318704175241,
      55.181550046300785
    ],
    [
      35.650494,
      55.189653
    ],
    [
      35.652222513448976,
      55.19003861667982
    ],
    [
      35.652808,
      55.19008
    ],
    [
      35.65616793367334,
      55.19068941636814
    ],
    [
      35.659076,
      55.19088
    ],
    [
      35.65995253488556,
      55.19088831723652
    ],
    [
      35.661917,
      55.190808
    ],
    [
      35.666319,
      55.190807
    ],
    [
      35.6665626539195,
      55.190814459374494
    ],
    [
      35.669492,
      55.19067
    ],
    [
      35.669930013853744,
      55.19091754985157
    ],
    [
      35.670598,
      55.190938
    ],
    [
      35.672492390880265,
      55.19236571421248
    ],
    [
      35.672776,
      55.192526
    ],
    [
      35.6731460592352,
      55.19285835361985
    ],
    [
      35.67443,
      55.193826
    ],
    [
      35.67507502196463,
      55.194590772877895
    ],
    [
      35.675632,
      55.195091
    ],
    [
      35.686261,
      55.197964
    ],
    [
      35.68998,
      55.195622
    ],
    [
      35.697963,
      55.187905
    ],
    [
      35.69831225560934,
      55.1876593241767
    ],
    [
      35.699347,
      55.186208
    ],
    [
      35.704012367721205,
      55.18097440185496
    ],
    [
      35.70397363988189,
      55.18003902428628
    ],
    [
      35.70286573362873,
      55.17327032773647
    ],
    [
      35.701416,
      55.170918
    ],
    [
      35.701311794458356,
      55.17067003003166
    ],
    [
      35.700138,
      55.168797
    ],
    [
      35.698966,
      55.163374
    ],
    [
      35.69945499360195,
      55.162505121253346
    ],
    [
      35.699441,
      55.162347
    ],
    [
      35.703089,
      55.151832
    ],
    [
      35.707482,
      55.150881
    ],
    [
      35.711542,
      55.150777
    ],
    [
      35.71224,
      55.150812
    ],
    [
      35.712056,
      55.154408
    ],
    [
      35.705131,
      55.159793
    ],
    [
      35.705857,
      55.171144
    ],
    [
      35.706878,
      55.172923
    ],
    [
      35.708763,
      55.176462
    ],
    [
      35.71895,
      55.180511
    ],
    [
      35.724121,
      55.180976
    ],
    [
      35.732286,
      55.180636
    ],
    [
      35.736837,
      55.181098
    ],
    [
      35.745791,
      55.18158
    ],
    [
      35.749576,
      55.182285
    ],
    [
      35.753138,
      55.182787
    ],
    [
      35.753352,
      55.187116
    ],
    [
      35.75338417015363,
      55.18808433631394
    ],
    [
      35.755042,
      55.186394
    ],
    [
      35.755041627849764,
      55.18639993596397
    ],
    [
      35.755837,
      55.18559
    ],
    [
      35.75482,
      55.189935
    ],
    [
      35.765204,
      55.194932
    ],
    [
      35.768687,
      55.192982
    ],
    [
      35.775053,
      55.188809
    ],
    [
      35.783868,
      55.180738
    ],
    [
      35.787388,
      55.17728
    ],
    [
      35.789939,
      55.174901
    ],
    [
      35.789949303394295,
      55.174912041748286
    ],
    [
      35.791084,
      55.173863
    ],
    [
      35.79335208173927,
      55.173439995005914
    ],
    [
      35.793686,
      55.173225
    ],
    [
      35.79747,
      55.172264
    ],
    [
      35.799773,
      55.174818
    ],
    [
      35.799689,
      55.174945
    ],
    [
      35.798212,
      55.175787
    ],
    [
      35.790004806617276,
      55.18043484706201
    ],
    [
      35.785812,
      55.186135
    ],
    [
      35.78286,
      55.188146
    ],
    [
      35.77778,
      55.191731
    ],
    [
      35.773945,
      55.194812
    ],
    [
      35.772917,
      55.205987
    ],
    [
      35.7747219617638,
      55.20627423973485
    ],
    [
      35.774722,
      55.206274
    ],
    [
      35.77517756913721,
      55.20634674461765
    ],
    [
      35.77812,
      55.206815
    ],
    [
      35.78451697793521,
      55.20942275173294
    ],
    [
      35.793348,
      55.210106
    ],
    [
      35.798921,
      55.211285
    ],
    [
      35.808646,
      55.212227
    ],
    [
      35.81509,
      55.211853
    ],
    [
      35.817804063872565,
      55.21172868069612
    ],
    [
      35.819101,
      55.211669
    ],
    [
      35.822579,
      55.211509
    ],
    [
      35.822777008838315,
      55.21150679544816
    ],
    [
      35.822777,
      55.211506
    ],
    [
      35.82458244452574,
      55.211486439387585
    ],
    [
      35.828863,
      55.211399
    ],
    [
      35.832582,
      55.211311
    ],
    [
      35.836163,
      55.211458
    ],
    [
      35.836876988432046,
      55.211467832892964
    ],
    [
      35.836877,
      55.211467
    ],
    [
      35.840103,
      55.211512
    ],
    [
      35.84893,
      55.210554
    ],
    [
      35.856926,
      55.213051
    ],
    [
      35.861464,
      55.212996
    ],
    [
      35.867726716339654,
      55.21293751332206
    ],
    [
      35.876664,
      55.206785
    ],
    [
      35.880499,
      55.202608
    ],
    [
      35.885571,
      55.201107
    ],
    [
      35.88899043531975,
      55.19910698709505
    ],
    [
      35.893419555068945,
      55.196213890273086
    ],
    [
      35.892759873318845,
      55.19247632411896
    ],
    [
      35.892044,
      55.190896
    ],
    [
      35.887064,
      55.182912
    ],
    [
      35.879717,
      55.179434
    ],
    [
      35.877398,
      55.17668
    ],
    [
      35.877578,
      55.175183
    ],
    [
      35.878677,
      55.174697
    ],
    [
      35.881282,
      55.173716
    ],
    [
      35.889029,
      55.179895
    ],
    [
      35.897167,
      55.18031
    ],
    [
      35.901385,
      55.17779
    ],
    [
      35.90518132046444,
      55.175648637988026
    ],
    [
      35.90522507978953,
      55.175623948490184
    ],
    [
      35.913489,
      55.170289
    ],
    [
      35.915837,
      55.167155
    ],
    [
      35.918476,
      55.163715
    ],
    [
      35.919149,
      55.163178
    ],
    [
      35.924613,
      55.159789
    ],
    [
      35.92540069764654,
      55.159300293004804
    ],
    [
      35.925409,
      55.159295
    ],
    [
      35.92640154337667,
      55.15867934369566
    ],
    [
      35.930077,
      55.156399
    ],
    [
      35.93007723557023,
      55.15639937979185
    ],
    [
      35.931661,
      55.155417
    ],
    [
      35.934876,
      55.1534
    ],
    [
      35.93491969289832,
      55.15337280310728
    ],
    [
      35.93794907042575,
      55.151473040055485
    ],
    [
      35.944854,
      55.143576
    ],
    [
      35.937938066748124,
      55.13496881670301
    ],
    [
      35.937892505930336,
      55.13496877952062
    ],
    [
      35.93516214656001,
      55.13521162398384
    ],
    [
      35.929969,
      55.137522
    ],
    [
      35.926731,
      55.139815
    ],
    [
      35.922068,
      55.140371
    ],
    [
      35.918281,
      55.14141
    ],
    [
      35.914018,
      55.141241
    ],
    [
      35.909041,
      55.141689
    ],
    [
      35.904514,
      55.142493
    ],
    [
      35.90315,
      55.142518
    ],
    [
      35.899014,
      55.142518
    ],
    [
      35.899014,
      55.14244923342175
    ],
    [
      35.898482,
      55.142445
    ],
    [
      35.89044668729599,
      55.13932551015176
    ],
    [
      35.887832626081355,
      55.139566416239276
    ],
    [
      35.88166,
      55.142729
    ],
    [
      35.878841,
      55.14494
    ],
    [
      35.871329,
      55.149172
    ],
    [
      35.867686,
      55.151422
    ],
    [
      35.863494,
      55.1534
    ],
    [
      35.855163,
      55.15752
    ],
    [
      35.852643,
      55.15495
    ],
    [
      35.853551,
      55.154059
    ],
    [
      35.854454,
      55.153166
    ],
    [
      35.85576282918501,
      55.15262452343801
    ],
    [
      35.855366,
      55.152391
    ],
    [
      35.859472,
      55.15109
    ],
    [
      35.863608,
      55.149413
    ],
    [
      35.86683882697057,
      55.14773554771162
    ],
    [
      35.86709823041857,
      55.14757757981766
    ],
    [
      35.870537,
      55.145182
    ],
    [
      35.878409,
      55.141182
    ],
    [
      35.875582,
      55.133234
    ],
    [
      35.87253,
      55.129631
    ],
    [
      35.869326,
      55.120793
    ],
    [
      35.869808,
      55.116928
    ],
    [
      35.863431,
      55.109152
    ],
    [
      35.858327,
      55.110853
    ],
    [
      35.851952,
      55.120362
    ],
    [
      35.850295,
      55.124449
    ],
    [
      35.845583,
      55.126338
    ],
    [
      35.8395,
      55.125659
    ],
    [
      35.834925,
      55.125386
    ],
    [
      35.825727,
      55.13191
    ],
    [
      35.831335,
      55.139057
    ],
    [
      35.833965,
      55.141614
    ],
    [
      35.832995,
      55.143927
    ],
    [
      35.832801,
      55.144463
    ],
    [
      35.825326,
      55.14307
    ],
    [
      35.82181,
      55.141825
    ],
    [
      35.820379006568224,
      55.13893021099474
    ],
    [
      35.820235,
      55.138983
    ],
    [
      35.819711,
      55.138366
    ],
    [
      35.819367,
      55.136883
    ],
    [
      35.818406,
      55.13019
    ],
    [
      35.808022,
      55.124627
    ],
    [
      35.80554,
      55.12632
    ],
    [
      35.803173,
      55.127865
    ],
    [
      35.80314856416393,
      55.12782757641773
    ],
    [
      35.802784,
      55.128067
    ],
    [
      35.799713,
      55.132871
    ],
    [
      35.796607,
      55.134692
    ],
    [
      35.7918548069839,
      55.13362955228086
    ],
    [
      35.790939,
      55.134548
    ],
    [
      35.78619,
      55.13614
    ],
    [
      35.779473,
      55.139172
    ],
    [
      35.775565,
      55.139191
    ],
    [
      35.769103,
      55.140793
    ],
    [
      35.766013,
      55.138945
    ],
    [
      35.766376,
      55.138339
    ],
    [
      35.772003,
      55.135631
    ],
    [
      35.78255,
      55.133056
    ],
    [
      35.789713,
      55.1283
    ],
    [
      35.79485,
      55.126878
    ],
    [
      35.79565154520781,
      55.12652252443212
    ],
    [
      35.794422,
      55.125078
    ],
    [
      35.803572,
      55.123305
    ],
    [
      35.808445,
      55.11998
    ],
    [
      35.812051,
      55.119207
    ],
    [
      35.816263,
      55.119634
    ],
    [
      35.820324,
      55.120051
    ],
    [
      35.824059,
      55.120934
    ],
    [
      35.834691,
      55.121794
    ],
    [
      35.839704,
      55.122064
    ],
    [
      35.84852782885077,
      55.115933118916125
    ],
    [
      35.848539,
      55.112262
    ],
    [
      35.850599,
      55.109561
    ],
    [
      35.857236,
      55.107423
    ],
    [
      35.862292,
      55.105737
    ],
    [
      35.86605,
      55.104337
    ],
    [
      35.869865,
      55.102917
    ],
    [
      35.873457,
      55.100911
    ],
    [
      35.882905,
      55.097077
    ],
    [
      35.888646,
      55.095279
    ],
    [
      35.894795,
      55.093307
    ],
    [
      35.902011,
      55.092599
    ],
    [
      35.902522973191886,
      55.09241875255744
    ],
    [
      35.90998531132164,
      55.08798673123565
    ],
    [
      35.913474,
      55.083392
    ],
    [
      35.915851,
      55.078806
    ],
    [
      35.9146010362067,
      55.070709709422246
    ],
    [
      35.914032770567246,
      55.07011045625885
    ],
    [
      35.90625910761408,
      55.06554125346302
    ],
    [
      35.90525162118837,
      55.0653026738719
    ],
    [
      35.901314,
      55.064521
    ],
    [
      35.893285,
      55.064456
    ],
    [
      35.888337,
      55.064609
    ],
    [
      35.877234,
      55.067357
    ],
    [
      35.874219,
      55.069886
    ],
    [
      35.869072,
      55.070869
    ],
    [
      35.868428,
      55.070803
    ],
    [
      35.865713,
      55.067948
    ],
    [
      35.86570885245525,
      55.06793719276519
    ],
    [
      35.86536,
      55.068022
    ],
    [
      35.860983,
      55.068722
    ],
    [
      35.85384581698527,
      55.06999269846615
    ],
    [
      35.853532,
      55.070076
    ],
    [
      35.849778,
      55.071304
    ],
    [
      35.845643,
      55.072247
    ],
    [
      35.8449283303713,
      55.0722569055852
    ],
    [
      35.844224,
      55.072439
    ],
    [
      35.838292,
      55.072977
    ],
    [
      35.827042,
      55.075333
    ],
    [
      35.825709,
      55.075844
    ],
    [
      35.818351,
      55.076934
    ],
    [
      35.818350872364555,
      55.07693313918595
    ],
    [
      35.817014,
      55.077131
    ],
    [
      35.81006554128129,
      55.07941703641849
    ],
    [
      35.80528,
      55.082527
    ],
    [
      35.80147,
      55.084843
    ],
    [
      35.79188,
      55.078704
    ],
    [
      35.782268,
      55.076998
    ],
    [
      35.77788,
      55.077055
    ],
    [
      35.77763,
      55.073464
    ],
    [
      35.779647,
      55.073324
    ],
    [
      35.784931,
      55.073479
    ],
    [
      35.789139,
      55.073948
    ],
    [
      35.796826,
      55.074151
    ],
    [
      35.79699705214037,
      55.07413557887114
    ],
    [
      35.796997,
      55.074135
    ],
    [
      35.80032,
      55.073836
    ],
    [
      35.808716,
      55.074604
    ],
    [
      35.81642,
      55.073581
    ],
    [
      35.81782300092368,
      55.073373006229595
    ],
    [
      35.817823,
      55.073373
    ],
    [
      35.81786505716144,
      55.07336677142582
    ],
    [
      35.820818,
      55.072929
    ],
    [
      35.825182,
      55.072283
    ],
    [
      35.832555,
      55.069651
    ],
    [
      35.840972,
      55.062617
    ],
    [
      35.846468,
      55.057223
    ],
    [
      35.850456,
      55.051335
    ],
    [
      35.853744,
      55.052803
    ],
    [
      35.852304,
      55.056026
    ],
    [
      35.858429949994495,
      55.06508269604535
    ],
    [
      35.85930267999338,
      55.06511642582007
    ],
    [
      35.862918,
      55.064716
    ],
    [
      35.864435653776574,
      55.064055563377735
    ],
    [
      35.86407,
      55.06136
    ],
    [
      35.864796,
      55.061262
    ],
    [
      35.865515,
      55.061229
    ],
    [
      35.869062,
      55.060921
    ],
    [
      35.876557,
      55.0583
    ],
    [
      35.880797,
      55.058869
    ],
    [
      35.888893,
      55.061052
    ],
    [
      35.892429,
      55.060959
    ],
    [
      35.89720361154395,
      55.05360954328015
    ],
    [
      35.89746,
      55.049339
    ],
    [
      35.894562,
      55.045416
    ],
    [
      35.894548320657364,
      55.04538578345041
    ],
    [
      35.893198,
      55.043669
    ],
    [
      35.891179,
      55.04066
    ],
    [
      35.88863241464037,
      55.03608631674056
    ],
    [
      35.88855007107145,
      55.035946374052074
    ],
    [
      35.885203,
      55.03029
    ],
    [
      35.88195074994269,
      55.024504955773416
    ],
    [
      35.874430172365024,
      55.02435713311598
    ],
    [
      35.871204,
      55.025116
    ],
    [
      35.868668715028825,
      55.025053780979036
    ],
    [
      35.868219,
      55.025139
    ],
    [
      35.864822846827515,
      55.02543029819199
    ],
    [
      35.86236,
      55.025994
    ],
    [
      35.858286,
      55.025996
    ],
    [
      35.856035,
      55.034638
    ],
    [
      35.858073,
      55.037943
    ],
    [
      35.859427,
      55.042516
    ],
    [
      35.855682,
      55.047483
    ],
    [
      35.85221,
      55.046532
    ],
    [
      35.852874,
      55.044108
    ],
    [
      35.852979,
      55.036541
    ],
    [
      35.850672,
      55.032032
    ],
    [
      35.85013,
      55.028253
    ],
    [
      35.84182827113412,
      55.023734578105824
    ],
    [
      35.832992,
      55.026403
    ],
    [
      35.832900094286465,
      55.02645471405942
    ],
    [
      35.830052,
      55.028058
    ],
    [
      35.83005169939291,
      55.0280574660224
    ],
    [
      35.827616,
      55.029428
    ],
    [
      35.823746,
      55.030391
    ],
    [
      35.818433,
      55.032201
    ],
    [
      35.813729,
      55.032848
    ],
    [
      35.812228,
      55.032545
    ],
    [
      35.809259272454796,
      55.031831430948024
    ],
    [
      35.803697,
      55.030505
    ],
    [
      35.80001181865481,
      55.02986831009893
    ],
    [
      35.795425771978074,
      55.02923337137744
    ],
    [
      35.787896,
      55.032805
    ],
    [
      35.783984,
      55.036899
    ],
    [
      35.781381,
      55.034412
    ],
    [
      35.77670636872191,
      55.025166297951806
    ],
    [
      35.771297,
      55.025515
    ],
    [
      35.76713,
      55.02609
    ],
    [
      35.759505,
      55.026501
    ],
    [
      35.758469,
      55.026476
    ],
    [
      35.758557,
      55.022877
    ],
    [
      35.759432959479014,
      55.02269576700434
    ],
    [
      35.759585,
      55.022643
    ],
    [
      35.761022,
      55.022367
    ],
    [
      35.76532,
      55.022411
    ],
    [
      35.772943,
      55.020698
    ],
    [
      35.776663,
      55.020578
    ],
    [
      35.77678605295183,
      55.02060655856357
    ],
    [
      35.776788,
      55.020595
    ],
    [
      35.777891,
      55.020781
    ],
    [
      35.788061624162424,
      55.01727850365578
    ],
    [
      35.78814193824305,
      55.016921289138104
    ],
    [
      35.789147,
      55.011736
    ],
    [
      35.788838,
      55.009718
    ],
    [
      35.788529,
      55.007701
    ],
    [
      35.788530706597555,
      55.00770073866376
    ],
    [
      35.788503,
      55.007528
    ],
    [
      35.78163633612674,
      55.00120334899814
    ],
    [
      35.779138,
      55.000766
    ],
    [
      35.776036,
      55.001255
    ],
    [
      35.77599635008889,
      55.00126126320324
    ],
    [
      35.775983,
      55.001264
    ],
    [
      35.771207,
      55.002193
    ],
    [
      35.766892,
      55.003896
    ],
    [
      35.759719,
      55.007909
    ],
    [
      35.757021,
      55.010161
    ],
    [
      35.754359,
      55.012699
    ],
    [
      35.750827,
      55.013949
    ],
    [
      35.746507715222926,
      55.015718068765075
    ],
    [
      35.744714,
      55.016514
    ],
    [
      35.74462936199308,
      55.01632308589391
    ],
    [
      35.737714,
      55.018549
    ],
    [
      35.737464,
      55.018617
    ],
    [
      35.736116,
      55.018147
    ],
    [
      35.73579820289586,
      55.017859792991445
    ],
    [
      35.730985,
      55.020011
    ],
    [
      35.723647,
      55.021623
    ],
    [
      35.722874,
      55.018107
    ],
    [
      35.731039,
      55.011258
    ],
    [
      35.732673,
      55.00805
    ],
    [
      35.73318,
      55.008309
    ],
    [
      35.734186,
      55.009367
    ],
    [
      35.74328989189119,
      55.01320751730482
    ],
    [
      35.747005,
      55.011559
    ],
    [
      35.754529,
      55.007557
    ],
    [
      35.757751,
      55.004895
    ],
    [
      35.75984675281008,
      55.002980648748945
    ],
    [
      35.759648,
      55.002715
    ],
    [
      35.76003,
      55.00243
    ],
    [
      35.760905,
      55.002014
    ],
    [
      35.76111894551172,
      55.001946372519654
    ],
    [
      35.761118,
      55.001943
    ],
    [
      35.76121994914685,
      55.00191444559913
    ],
    [
      35.765701,
      55.000498
    ],
    [
      35.7703,
      54.998709
    ],
    [
      35.77239058013198,
      54.9983004348473
    ],
    [
      35.772373,
      54.998189
    ],
    [
      35.77542513997181,
      54.997706876019926
    ],
    [
      35.782339,
      54.995232
    ],
    [
      35.78548,
      54.99324
    ],
    [
      35.785551091400876,
      54.99320201117785
    ],
    [
      35.78555,
      54.9932
    ],
    [
      35.786547,
      54.992659
    ],
    [
      35.789612,
      54.991032
    ],
    [
      35.793236,
      54.986753
    ],
    [
      35.79323753626706,
      54.98039669506068
    ],
    [
      35.788691,
      54.973154
    ],
    [
      35.785431252651314,
      54.9736567513646
    ],
    [
      35.777947,
      54.976875
    ],
    [
      35.771546,
      54.975777
    ],
    [
      35.77140846576226,
      54.9756929996586
    ],
    [
      35.770169,
      54.975994
    ],
    [
      35.761858,
      54.977099
    ],
    [
      35.754987,
      54.984503
    ],
    [
      35.754391,
      54.986174
    ],
    [
      35.75406,
      54.986891
    ],
    [
      35.75371106428578,
      54.986729928114784
    ],
    [
      35.752953,
      54.988053
    ],
    [
      35.749267,
      54.989503
    ],
    [
      35.745411,
      54.99176
    ],
    [
      35.744091,
      54.992384
    ],
    [
      35.742312,
      54.99304
    ],
    [
      35.741067,
      54.989663
    ],
    [
      35.739957,
      54.987431
    ],
    [
      35.740873,
      54.983949
    ],
    [
      35.74361152568235,
      54.97575244049242
    ],
    [
      35.742711,
      54.97483
    ],
    [
      35.74194428769781,
      54.972161180862
    ],
    [
      35.73599,
      54.970865
    ],
    [
      35.731775,
      54.972871
    ],
    [
      35.731075,
      54.972038
    ],
    [
      35.730529,
      54.970567
    ],
    [
      35.734806,
      54.967465
    ],
    [
      35.740961,
      54.969834
    ],
    [
      35.749968,
      54.962174
    ],
    [
      35.750385,
      54.958598
    ],
    [
      35.743929,
      54.948686
    ],
    [
      35.73423,
      54.950534
    ],
    [
      35.726399,
      54.950678
    ],
    [
      35.725329,
      54.951116
    ],
    [
      35.723967,
      54.947783
    ],
    [
      35.725037,
      54.947346
    ],
    [
      35.730391,
      54.940863
    ],
    [
      35.726513,
      54.933274
    ],
    [
      35.718682,
      54.930888
    ],
    [
      35.716719,
      54.931115
    ],
    [
      35.715977,
      54.930506
    ],
    [
      35.716307,
      54.927538
    ],
    [
      35.713997,
      54.917047
    ],
    [
      35.715175,
      54.913645
    ],
    [
      35.715873,
      54.913887
    ],
    [
      35.722938,
      54.916278
    ],
    [
      35.724460907451984,
      54.916808265762874
    ],
    [
      35.724461,
      54.916808
    ],
    [
      35.728312,
      54.918149
    ],
    [
      35.732162,
      54.91949
    ],
    [
      35.734440090536914,
      54.92025299856981
    ],
    [
      35.735784,
      54.920703
    ],
    [
      35.738429,
      54.921589
    ],
    [
      35.738549834673734,
      54.92163048196569
    ],
    [
      35.73855,
      54.92163
    ],
    [
      35.746545,
      54.920288
    ],
    [
      35.748273,
      54.923446
    ],
    [
      35.75914915406727,
      54.92263332374182
    ],
    [
      35.760145,
      54.922145
    ],
    [
      35.761702,
      54.9211
    ],
    [
      35.763708,
      54.924089
    ],
    [
      35.76762812575632,
      54.933155906477005
    ],
    [
      35.77151567583503,
      54.93134475627007
    ],
    [
      35.774512,
      54.927856
    ],
    [
      35.776336,
      54.926006
    ],
    [
      35.778597,
      54.925077
    ],
    [
      35.780858,
      54.924149
    ],
    [
      35.78880752023861,
      54.920808494180065
    ],
    [
      35.790743,
      54.918202
    ],
    [
      35.79414439655226,
      54.91869645067478
    ],
    [
      35.796144,
      54.916952
    ],
    [
      35.79857,
      54.91029
    ],
    [
      35.801139,
      54.907769
    ],
    [
      35.80542016622204,
      54.9110715081609
    ],
    [
      35.812366,
      54.90945
    ],
    [
      35.814578,
      54.907946
    ],
    [
      35.815194,
      54.908293
    ],
    [
      35.816075,
      54.911078
    ],
    [
      35.812087,
      54.914417
    ],
    [
      35.810519458022064,
      54.918349176314386
    ],
    [
      35.812392,
      54.927526
    ],
    [
      35.81581045986327,
      54.92830433858335
    ],
    [
      35.823762,
      54.924099
    ],
    [
      35.827339,
      54.9245
    ],
    [
      35.836612,
      54.922171
    ],
    [
      35.847438,
      54.926182
    ],
    [
      35.847493,
      54.929781
    ],
    [
      35.845064,
      54.93045
    ],
    [
      35.841398,
      54.930604
    ],
    [
      35.837456,
      54.940765
    ],
    [
      35.83794,
      54.944776
    ],
    [
      35.83816719446396,
      54.94505764971858
    ],
    [
      35.838168,
      54.945057
    ],
    [
      35.842521241414225,
      54.95045486401942
    ],
    [
      35.851815,
      54.94522
    ],
    [
      35.853159,
      54.944798
    ],
    [
      35.855079,
      54.944863
    ],
    [
      35.855522148687065,
      54.94487823323612
    ],
    [
      35.858225,
      54.94497
    ],
    [
      35.862452,
      54.945114
    ],
    [
      35.86233,
      54.948712
    ],
    [
      35.858102,
      54.948568
    ],
    [
      35.853932,
      54.958495
    ],
    [
      35.859924120700825,
      54.96018813785807
    ],
    [
      35.864742,
      54.957803
    ],
    [
      35.866342,
      54.957684
    ],
    [
      35.86659692626299,
      54.95770698166645
    ],
    [
      35.874316,
      54.953994
    ],
    [
      35.876618,
      54.953777
    ],
    [
      35.87718464289555,
      54.954130212414995
    ],
    [
      35.878452,
      54.952229
    ],
    [
      35.879091,
      54.951691
    ],
    [
      35.879277,
      54.951565
    ],
    [
      35.880232,
      54.951917
    ],
    [
      35.88072694526607,
      54.95298006001073
    ],
    [
      35.884013,
      54.954335
    ],
    [
      35.905877,
      54.945586
    ],
    [
      35.90298,
      54.941754
    ],
    [
      35.8945,
      54.938091
    ],
    [
      35.891461,
      54.935977
    ],
    [
      35.891674,
      54.93212
    ],
    [
      35.883825,
      54.923773
    ],
    [
      35.88381765329976,
      54.923689891901944
    ],
    [
      35.880077,
      54.923869
    ],
    [
      35.873683,
      54.924411
    ],
    [
      35.869723,
      54.924858
    ],
    [
      35.868205,
      54.925268
    ],
    [
      35.860925,
      54.92664
    ],
    [
      35.860259,
      54.923102
    ],
    [
      35.863899,
      54.922416
    ],
    [
      35.867539,
      54.921731
    ],
    [
      35.870234,
      54.921322
    ],
    [
      35.876309,
      54.920364
    ],
    [
      35.88,
      54.920269
    ],
    [
      35.88555886206893,
      54.920005338558
    ],
    [
      35.885653,
      54.919997
    ],
    [
      35.887797,
      54.919807
    ],
    [
      35.890133,
      54.919778
    ],
    [
      35.893641,
      54.920373
    ],
    [
      35.897807,
      54.920796
    ],
    [
      35.904928,
      54.914636
    ],
    [
      35.906457,
      54.912237
    ],
    [
      35.90645905403799,
      54.91223830914476
    ],
    [
      35.906468,
      54.912221
    ],
    [
      35.905833,
      54.904917
    ],
    [
      35.89792,
      54.903092
    ],
    [
      35.889999,
      54.901265
    ],
    [
      35.885425,
      54.900485
    ],
    [
      35.881708,
      54.900467
    ],
    [
      35.879799,
      54.900541
    ],
    [
      35.875749,
      54.900685
    ],
    [
      35.87364030229237,
      54.90075945525239
    ],
    [
      35.873377,
      54.900769
    ],
    [
      35.863565,
      54.903772
    ],
    [
      35.85998,
      54.903438
    ],
    [
      35.858623889161734,
      54.9032827247095
    ],
    [
      35.853865,
      54.904955
    ],
    [
      35.85108495636897,
      54.90562209495717
    ],
    [
      35.850563,
      54.906131
    ],
    [
      35.845876,
      54.908458
    ],
    [
      35.841968,
      54.90675
    ],
    [
      35.839441,
      54.904662
    ],
    [
      35.841572,
      54.901761
    ],
    [
      35.842409570164705,
      54.90114618574387
    ],
    [
      35.843197,
      54.898512
    ],
    [
      35.84966376408282,
      54.893850540890305
    ],
    [
      35.849191,
      54.891443
    ],
    [
      35.847267,
      54.887053
    ],
    [
      35.848032,
      54.883536
    ],
    [
      35.848985,
      54.883743
    ],
    [
      35.851624,
      54.88787
    ],
    [
      35.85291,
      54.892261
    ],
    [
      35.856726605558094,
      54.896390773298435
    ],
    [
      35.86101860630987,
      54.898028946585065
    ],
    [
      35.86466,
      54.897486
    ],
    [
      35.871571090255735,
      54.89723253703219
    ],
    [
      35.871571,
      54.89723
    ],
    [
      35.87267200441321,
      54.89719112502936
    ],
    [
      35.872672,
      54.897191
    ],
    [
      35.876171,
      54.897067
    ],
    [
      35.879671,
      54.896943
    ],
    [
      35.883472,
      54.896795
    ],
    [
      35.887231,
      54.897047
    ],
    [
      35.890822,
      54.89776
    ],
    [
      35.891259991553696,
      54.89786103662501
    ],
    [
      35.89126,
      54.897861
    ],
    [
      35.898729021859204,
      54.89958379869695
    ],
    [
      35.899963,
      54.88822
    ],
    [
      35.902242,
      54.885434
    ],
    [
      35.90267490926944,
      54.88578811086637
    ],
    [
      35.902675,
      54.885788
    ],
    [
      35.90392086015272,
      54.88680727302779
    ],
    [
      35.905835,
      54.888373
    ],
    [
      35.909427,
      54.891312
    ],
    [
      35.911709,
      54.892395
    ],
    [
      35.92322,
      54.88921
    ],
    [
      35.925412,
      54.886477
    ],
    [
      35.926263,
      54.885733
    ],
    [
      35.930148,
      54.882418
    ],
    [
      35.934033,
      54.879104
    ],
    [
      35.93403325613889,
      54.87910430019872
    ],
    [
      35.93456,
      54.878655
    ],
    [
      35.93456018505876,
      54.87865521698457
    ],
    [
      35.939474,
      54.874464
    ],
    [
      35.941546,
      54.866853
    ],
    [
      35.941847,
      54.863265
    ],
    [
      35.948351,
      54.860698
    ],
    [
      35.95195,
      54.860635
    ],
    [
      35.953782,
      54.862709
    ],
    [
      35.953191,
      54.863963
    ],
    [
      35.957388,
      54.875074
    ],
    [
      35.959194,
      54.878636
    ],
    [
      35.959143,
      54.879605
    ],
    [
      35.955555,
      54.879903
    ],
    [
      35.95119339633708,
      54.8760366331795
    ],
    [
      35.941819,
      54.877194
    ],
    [
      35.93636989701551,
      54.8818428793006
    ],
    [
      35.93637,
      54.881843
    ],
    [
      35.93577067935803,
      54.88235410360461
    ],
    [
      35.932748,
      54.884933
    ],
    [
      35.928599,
      54.888472
    ],
    [
      35.926264,
      54.891133
    ],
    [
      35.92588534316109,
      54.89178561851438
    ],
    [
      35.925886,
      54.891786
    ],
    [
      35.92502861187471,
      54.89326220269796
    ],
    [
      35.924235,
      54.89463
    ],
    [
      35.92225,
      54.901953
    ],
    [
      35.929321,
      54.909413
    ],
    [
      35.935813,
      54.909862
    ],
    [
      35.941503,
      54.909232
    ],
    [
      35.944098,
      54.910499
    ],
    [
      35.94409683318681,
      54.91050139052606
    ],
    [
      35.944754,
      54.910823
    ],
    [
      35.952003,
      54.916201
    ],
    [
      35.952346,
      54.916491
    ],
    [
      35.95225100849288,
      54.91660282183421
    ],
    [
      35.952761,
      54.916996
    ],
    [
      35.961954,
      54.922429
    ],
    [
      35.973003,
      54.91805
    ],
    [
      35.975342,
      54.917361
    ],
    [
      35.977327,
      54.917067
    ],
    [
      35.979313,
      54.916773
    ],
    [
      35.979841,
      54.920334
    ],
    [
      35.972433,
      54.925918
    ],
    [
      35.969066,
      54.928521
    ],
    [
      35.96626897761914,
      54.92754259183421
    ],
    [
      35.960495,
      54.925975
    ],
    [
      35.952723,
      54.929661
    ],
    [
      35.949521,
      54.931307
    ],
    [
      35.948446,
      54.929783
    ],
    [
      35.948141,
      54.923503
    ],
    [
      35.944428,
      54.914698
    ],
    [
      35.935972,
      54.913458
    ],
    [
      35.929079,
      54.913004
    ],
    [
      35.919701,
      54.916181
    ],
    [
      35.916099,
      54.917496
    ],
    [
      35.91380956082488,
      54.92021806423304
    ],
    [
      35.91579,
      54.922992
    ],
    [
      35.919783,
      54.92286
    ],
    [
      35.920767,
      54.926323
    ],
    [
      35.918103,
      54.92708
    ],
    [
      35.91485,
      54.926467
    ],
    [
      35.909574,
      54.925254
    ],
    [
      35.905783,
      54.924885
    ],
    [
      35.90221478129425,
      54.93012933418275
    ],
    [
      35.90402212214662,
      54.93698334657594
    ],
    [
      35.907811,
      54.94153
    ],
    [
      35.911062,
      54.945358
    ],
    [
      35.913871,
      54.948667
    ],
    [
      35.922778,
      54.949881
    ],
    [
      35.926736,
      54.949798
    ],
    [
      35.931312,
      54.949459
    ],
    [
      35.936376,
      54.950068
    ],
    [
      35.938867,
      54.950318
    ],
    [
      35.941357,
      54.950569
    ],
    [
      35.94135695103044,
      54.95056948724713
    ],
    [
      35.941809,
      54.950615
    ],
    [
      35.944496,
      54.950787
    ],
    [
      35.94449399062626,
      54.95081852698625
    ],
    [
      35.945582,
      54.950901
    ],
    [
      35.945328,
      54.95461
    ],
    [
      35.958458,
      54.966171
    ],
    [
      35.966302,
      54.958438
    ],
    [
      35.970146,
      54.958177
    ],
    [
      35.981741708417225,
      54.959991436389025
    ],
    [
      35.983742,
      54.958082
    ],
    [
      35.990889,
      54.956838
    ],
    [
      35.989634,
      54.967257
    ],
    [
      35.996593,
      54.967504
    ],
    [
      35.996465,
      54.971101
    ],
    [
      36.002904,
      54.979827
    ],
    [
      36.008201,
      54.979635
    ],
    [
      36.013209,
      54.979408
    ],
    [
      36.02002719463767,
      54.97192388402068
    ],
    [
      36.005356,
      54.971412
    ],
    [
      36.005481,
      54.967814
    ],
    [
      36.024599,
      54.968481
    ],
    [
      36.03469,
      54.978615
    ],
    [
      36.04359,
      54.972746
    ],
    [
      36.043716,
      54.969148
    ],
    [
      36.047007,
      54.96756
    ],
    [
      36.04820926528628,
      54.96861153776369
    ],
    [
      36.050501,
      54.969383
    ],
    [
      36.059500086319126,
      54.966675872915594
    ],
    [
      36.060886,
      54.965682
    ],
    [
      36.063877,
      54.963411
    ],
    [
      36.06408,
      54.963237
    ],
    [
      36.065547,
      54.963146
    ],
    [
      36.065708,
      54.963221
    ],
    [
      36.074539,
      54.97021
    ],
    [
      36.082499,
      54.970496
    ],
    [
      36.087556013877055,
      54.97066593658154
    ],
    [
      36.090098,
      54.959049
    ],
    [
      36.089899229739736,
      54.95888813189495
    ],
    [
      36.089512,
      54.958639
    ],
    [
      36.085268,
      54.95514
    ],
    [
      36.08221,
      54.952497
    ],
    [
      36.079816,
      54.949618
    ],
    [
      36.079069,
      54.947716
    ],
    [
      36.08017026007048,
      54.947011963311354
    ],
    [
      36.079846,
      54.94614
    ],
    [
      36.08070750194656,
      54.94666850403086
    ],
    [
      36.082102,
      54.945777
    ],
    [
      36.082289,
      54.946069
    ],
    [
      36.0839,
      54.948627
    ],
    [
      36.094329,
      54.943406
    ],
    [
      36.093555,
      54.940787
    ],
    [
      36.0944202618251,
      54.939177765250804
    ],
    [
      36.092441,
      54.936862
    ],
    [
      36.08874,
      54.935258
    ],
    [
      36.083893,
      54.933801
    ],
    [
      36.080779,
      54.934158
    ],
    [
      36.07922,
      54.934044
    ],
    [
      36.07922783043032,
      54.933937113137446
    ],
    [
      36.075354,
      54.932351
    ],
    [
      36.06622,
      54.928288
    ],
    [
      36.06059,
      54.928849
    ],
    [
      36.054461,
      54.930848
    ],
    [
      36.050871,
      54.931119
    ],
    [
      36.05086956117636,
      54.931099957481905
    ],
    [
      36.050869,
      54.9311
    ],
    [
      36.050756,
      54.929597
    ],
    [
      36.053755,
      54.925473
    ],
    [
      36.062539,
      54.924935
    ],
    [
      36.067566,
      54.924949
    ],
    [
      36.071957,
      54.925768
    ],
    [
      36.076753,
      54.926843
    ],
    [
      36.080820069846446,
      54.9290470727948
    ],
    [
      36.085225,
      54.924412
    ],
    [
      36.087874,
      54.913391
    ],
    [
      36.088655,
      54.909877
    ],
    [
      36.090828,
      54.91036
    ],
    [
      36.097961,
      54.913777
    ],
    [
      36.103215,
      54.913896
    ],
    [
      36.113999,
      54.911571
    ],
    [
      36.116433,
      54.90841
    ],
    [
      36.11992,
      54.904193
    ],
    [
      36.12120092723994,
      54.89686896185594
    ],
    [
      36.11872614272264,
      54.89231196586297
    ],
    [
      36.116472,
      54.891706
    ],
    [
      36.115679,
      54.891506
    ],
    [
      36.116561,
      54.888016
    ],
    [
      36.117354,
      54.888216
    ],
    [
      36.11737997181015,
      54.888222119585
    ],
    [
      36.11738,
      54.888222
    ],
    [
      36.121632,
      54.889224
    ],
    [
      36.129724557561296,
      54.88359954556388
    ],
    [
      36.123815,
      54.879528
    ],
    [
      36.120639,
      54.877056
    ],
    [
      36.127409,
      54.879728
    ],
    [
      36.13070728924503,
      54.87952895560983
    ],
    [
      36.136273,
      54.878539
    ],
    [
      36.136835267218515,
      54.879284410574314
    ],
    [
      36.134599,
      54.860491
    ],
    [
      36.13413296777454,
      54.86047305431464
    ],
    [
      36.133558,
      54.860508
    ],
    [
      36.13318526121451,
      54.86043656060709
    ],
    [
      36.12628894617157,
      54.860171001503744
    ],
    [
      36.126163480959015,
      54.8601904302187
    ],
    [
      36.122815,
      54.861771
    ],
    [
      36.119236,
      54.862345
    ],
    [
      36.115304,
      54.862704
    ],
    [
      36.111617,
      54.862925
    ],
    [
      36.107598,
      54.863222
    ],
    [
      36.10368,
      54.859619
    ],
    [
      36.098918,
      54.856783
    ],
    [
      36.091085,
      54.857512
    ],
    [
      36.08966589694882,
      54.85782479453771
    ],
    [
      36.087277,
      54.858845
    ],
    [
      36.08623547450323,
      54.858846458719185
    ],
    [
      36.079821,
      54.862608
    ],
    [
      36.076209,
      54.862649
    ],
    [
      36.065082,
      54.861
    ],
    [
      36.061419,
      54.861526
    ],
    [
      36.05118531088843,
      54.863983693131786
    ],
    [
      36.050836,
      54.86409
    ],
    [
      36.05083062794099,
      54.86407232402743
    ],
    [
      36.046946,
      54.865095
    ],
    [
      36.039356,
      54.86716
    ],
    [
      36.034738,
      54.871238
    ],
    [
      36.02936,
      54.876917
    ],
    [
      36.027928,
      54.877004
    ],
    [
      36.027856093361684,
      54.87699267542331
    ],
    [
      36.020119,
      54.877382
    ],
    [
      36.019623,
      54.886282
    ],
    [
      36.019527,
      54.888773
    ],
    [
      36.018393,
      54.890738
    ],
    [
      36.017279712830124,
      54.89009530695774
    ],
    [
      36.013674,
      54.895963
    ],
    [
      36.005621,
      54.896701
    ],
    [
      36.00528068570683,
      54.89675014285822
    ],
    [
      36.000296,
      54.897471
    ],
    [
      36.00029585148736,
      54.89746997451446
    ],
    [
      35.998973,
      54.897661
    ],
    [
      35.994372,
      54.898438
    ],
    [
      35.99231,
      54.898322
    ],
    [
      35.990248,
      54.898205
    ],
    [
      35.990248851497704,
      54.89818992471555
    ],
    [
      35.990167,
      54.898185
    ],
    [
      35.983335,
      54.898368
    ],
    [
      35.981458,
      54.89804
    ],
    [
      35.978747,
      54.898064
    ],
    [
      35.978715,
      54.894464
    ],
    [
      35.981426,
      54.89444
    ],
    [
      35.983908,
      54.894813
    ],
    [
      35.98989,
      54.894595
    ],
    [
      35.99045022195364,
      54.89462477487011
    ],
    [
      35.990451,
      54.894611
    ],
    [
      35.992513,
      54.894727
    ],
    [
      35.998336,
      54.894118
    ],
    [
      35.999780133486425,
      54.89390892172895
    ],
    [
      35.99978,
      54.893908
    ],
    [
      36.00042202454169,
      54.89381516946132
    ],
    [
      36.000422,
      54.893815
    ],
    [
      36.00579774757417,
      54.893037328651495
    ],
    [
      36.0143,
      54.885402
    ],
    [
      36.014232,
      54.881243
    ],
    [
      36.014340060591394,
      54.88085762626741
    ],
    [
      36.014334,
      54.880856
    ],
    [
      36.015179,
      54.877708
    ],
    [
      36.015507,
      54.876696
    ],
    [
      36.017398,
      54.866206
    ],
    [
      36.016554,
      54.862535
    ],
    [
      36.014702,
      54.851489
    ],
    [
      36.01065676252753,
      54.848322137142375
    ],
    [
      36.005309,
      54.847499
    ],
    [
      36.001515,
      54.851679
    ],
    [
      35.998333,
      54.854262
    ],
    [
      35.995734,
      54.858064
    ],
    [
      35.994003,
      54.861407
    ],
    [
      35.991975,
      54.863859
    ],
    [
      35.99182716562252,
      54.86396744470155
    ],
    [
      35.990801,
      54.865175
    ],
    [
      35.990409734949,
      54.86499739097136
    ],
    [
      35.988773,
      54.866702
    ],
    [
      35.984372,
      54.869207
    ],
    [
      35.980185,
      54.872218
    ],
    [
      35.976673,
      54.874872
    ],
    [
      35.974167,
      54.876167
    ],
    [
      35.972515,
      54.872969
    ],
    [
      35.97502,
      54.871674
    ],
    [
      35.976479,
      54.870688
    ],
    [
      35.97935,
      54.868174
    ],
    [
      35.983809,
      54.864863
    ],
    [
      35.988026,
      54.862578
    ],
    [
      35.991442,
      54.858343
    ],
    [
      35.994194,
      54.853991
    ],
    [
      35.996374,
      54.85105
    ],
    [
      35.99549537888817,
      54.84944278216812
    ],
    [
      35.989962,
      54.843511
    ],
    [
      35.989252725316064,
      54.841913796912976
    ],
    [
      35.987256,
      54.842743
    ],
    [
      35.982651,
      54.843775
    ],
    [
      35.974185,
      54.851375
    ],
    [
      35.972043,
      54.854268
    ],
    [
      35.969718,
      54.852547
    ],
    [
      35.971019,
      54.848317
    ],
    [
      35.972686,
      54.844972
    ],
    [
      35.974962,
      54.841973
    ],
    [
      35.9799,
      54.840605
    ],
    [
      35.978662,
      54.829501
    ],
    [
      35.972654,
      54.826634
    ],
    [
      35.9651,
      54.831151
    ],
    [
      35.962163,
      54.834101
    ],
    [
      35.960324,
      54.834429
    ],
    [
      35.957309,
      54.83246
    ],
    [
      35.957802,
      54.830859
    ],
    [
      35.958071,
      54.830275
    ],
    [
      35.956099,
      54.818737
    ],
    [
      35.950573,
      54.817889
    ],
    [
      35.947106,
      54.816917
    ],
    [
      35.947844,
      54.814287
    ],
    [
      35.947848,
      54.814272
    ],
    [
      35.948531,
      54.811751
    ],
    [
      35.952006,
      54.812693
    ],
    [
      35.957602,
      54.815467
    ],
    [
      35.95806560170552,
      54.81568883221505
    ],
    [
      35.958066,
      54.815688
    ],
    [
      35.96106747634851,
      54.817125221454845
    ],
    [
      35.961462,
      54.817314
    ],
    [
      35.96146240575439,
      54.817314328730525
    ],
    [
      35.961681,
      54.817419
    ],
    [
      35.97022022956183,
      54.819485154994204
    ],
    [
      35.972182,
      54.819352
    ],
    [
      35.972193527404066,
      54.819361904007984
    ],
    [
      35.974854,
      54.81889
    ],
    [
      35.979384,
      54.819226
    ],
    [
      35.98482495348595,
      54.828362921889564
    ],
    [
      35.98533728403282,
      54.82859738264719
    ],
    [
      35.989463,
      54.830485
    ],
    [
      35.994943,
      54.832903
    ],
    [
      35.99818536873285,
      54.83435462171585
    ],
    [
      36.008971,
      54.833305
    ],
    [
      36.012705,
      54.831049
    ],
    [
      36.013646,
      54.830298
    ],
    [
      36.015938,
      54.833073
    ],
    [
      36.018567,
      54.839552
    ],
    [
      36.02638549601368,
      54.837749384622256
    ],
    [
      36.026398,
      54.837238
    ],
    [
      36.02840912510878,
      54.83728699414328
    ],
    [
      36.033189391293135,
      54.837285449506005
    ],
    [
      36.044104,
      54.836017
    ],
    [
      36.04485134105713,
      54.83577312558817
    ],
    [
      36.04485,
      54.835769
    ],
    [
      36.051963,
      54.830174
    ],
    [
      36.057482774415526,
      54.8312533140469
    ],
    [
      36.059028916930174,
      54.83135760542831
    ],
    [
      36.061416,
      54.83066
    ],
    [
      36.06377476021342,
      54.82603623123161
    ],
    [
      36.06425,
      54.823698
    ],
    [
      36.063735,
      54.820172
    ],
    [
      36.060387,
      54.813696
    ],
    [
      36.057413,
      54.811073
    ],
    [
      36.055299,
      54.80085
    ],
    [
      36.051537,
      54.799096
    ],
    [
      36.039614,
      54.798773
    ],
    [
      36.03729787691514,
      54.798824114940516
    ],
    [
      36.031256,
      54.805041
    ],
    [
      36.02924440539401,
      54.804813365678044
    ],
    [
      36.02809,
      54.805582
    ],
    [
      36.027874,
      54.805421
    ],
    [
      36.026929,
      54.804417
    ],
    [
      36.026803929169155,
      54.803968242335806
    ],
    [
      36.02286948858085,
      54.797739773454474
    ],
    [
      36.017656,
      54.79771
    ],
    [
      36.017656000876684,
      54.797709842197285
    ],
    [
      36.015237,
      54.797696
    ],
    [
      36.01523743971038,
      54.7976206210769
    ],
    [
      36.010941,
      54.797902
    ],
    [
      36.00566,
      54.797678
    ],
    [
      36.003691,
      54.797691
    ],
    [
      35.998323,
      54.798331
    ],
    [
      35.992954,
      54.798971
    ],
    [
      35.992528,
      54.795396
    ],
    [
      35.997897,
      54.794756
    ],
    [
      36.003266,
      54.794117
    ],
    [
      36.007778,
      54.794053
    ],
    [
      36.011022,
      54.782758
    ],
    [
      36.011219,
      54.779163
    ],
    [
      36.017673,
      54.779516
    ],
    [
      36.025566,
      54.778957
    ],
    [
      36.028157,
      54.779154
    ],
    [
      36.032674,
      54.780122
    ],
    [
      36.033707,
      54.780394
    ],
    [
      36.035806,
      54.780808
    ],
    [
      36.03511,
      54.78434
    ],
    [
      36.040444,
      54.793101
    ],
    [
      36.04744,
      54.790791
    ],
    [
      36.050948,
      54.788882
    ],
    [
      36.051635,
      54.779483
    ],
    [
      36.053429,
      54.777192
    ],
    [
      36.056263,
      54.779412
    ],
    [
      36.062147,
      54.782766
    ],
    [
      36.06131236250927,
      54.785854780916175
    ],
    [
      36.062244,
      54.786633
    ],
    [
      36.060678,
      54.788508
    ],
    [
      36.059111,
      54.790384
    ],
    [
      36.059820060296225,
      54.79716976763827
    ],
    [
      36.061618,
      54.797548
    ],
    [
      36.06556,
      54.798288
    ],
    [
      36.069304,
      54.799964
    ],
    [
      36.071814,
      54.802611
    ],
    [
      36.072964,
      54.806453
    ],
    [
      36.06591,
      54.810267
    ],
    [
      36.06600569061601,
      54.810914412213855
    ],
    [
      36.06601,
      54.810915
    ],
    [
      36.068841,
      54.811618
    ],
    [
      36.068563,
      54.815207
    ],
    [
      36.067297,
      54.819651
    ],
    [
      36.0748621335533,
      54.82623601225345
    ],
    [
      36.075791671724225,
      54.82650110927687
    ],
    [
      36.086062208747975,
      54.8245638413444
    ],
    [
      36.086673178564666,
      54.824238052583276
    ],
    [
      36.092111,
      54.816109
    ],
    [
      36.093481,
      54.809996
    ],
    [
      36.094039677306505,
      54.80925558664445
    ],
    [
      36.094031,
      54.809249
    ],
    [
      36.0963133378279,
      54.806242311187546
    ],
    [
      36.096343,
      54.806203
    ],
    [
      36.099084,
      54.802591
    ],
    [
      36.102596,
      54.797384
    ],
    [
      36.107961,
      54.786586
    ],
    [
      36.10917123080026,
      54.784155114934656
    ],
    [
      36.109171,
      54.784155
    ],
    [
      36.111294,
      54.77989
    ],
    [
      36.113418,
      54.775625
    ],
    [
      36.104077,
      54.769084
    ],
    [
      36.099055,
      54.769373
    ],
    [
      36.095576,
      54.769915
    ],
    [
      36.091701,
      54.769977
    ],
    [
      36.083329,
      54.763036
    ],
    [
      36.0729,
      54.761967
    ],
    [
      36.069274,
      54.761831
    ],
    [
      36.067461,
      54.761743
    ],
    [
      36.064592,
      54.761612
    ],
    [
      36.06269650237588,
      54.758210678333576
    ],
    [
      36.060963,
      54.757775
    ],
    [
      36.055664,
      54.756321
    ],
    [
      36.050962,
      54.754189
    ],
    [
      36.047462,
      54.752439
    ],
    [
      36.047464607677576,
      54.75243378464486
    ],
    [
      36.045329,
      54.751362
    ],
    [
      36.042162,
      54.748777
    ],
    [
      36.0338470508897,
      54.743033596363965
    ],
    [
      36.031094,
      54.742015
    ],
    [
      36.021582,
      54.744043
    ],
    [
      36.018518,
      54.754125
    ],
    [
      36.018701,
      54.758163
    ],
    [
      36.016939,
      54.76218
    ],
    [
      36.013345,
      54.761975
    ],
    [
      36.00781550453905,
      54.756303510701805
    ],
    [
      36.006661,
      54.756521
    ],
    [
      35.999447,
      54.757583
    ],
    [
      35.996518,
      54.757255
    ],
    [
      35.99632611400775,
      54.75724276499999
    ],
    [
      35.996171,
      54.757288
    ],
    [
      35.985364,
      54.759563
    ],
    [
      35.981471,
      54.762852
    ],
    [
      35.979342,
      54.764707
    ],
    [
      35.976977,
      54.761992
    ],
    [
      35.973674,
      54.751807
    ],
    [
      35.973655,
      54.748207
    ],
    [
      35.971661,
      54.737007
    ],
    [
      35.971656,
      54.729849
    ],
    [
      35.971986,
      54.727436
    ],
    [
      35.972247,
      54.727335
    ],
    [
      35.974139,
      54.727242
    ],
    [
      35.978614,
      54.727234
    ],
    [
      35.9814,
      54.727176
    ],
    [
      35.990431,
      54.72368
    ],
    [
      35.992198,
      54.722848
    ],
    [
      35.992668,
      54.72284
    ],
    [
      35.99864,
      54.724222
    ],
    [
      36.005323,
      54.728311
    ],
    [
      36.002156,
      54.730715
    ],
    [
      36.00186,
      54.739724
    ],
    [
      36.0062710467504,
      54.74038608595544
    ],
    [
      36.007248,
      54.739811
    ],
    [
      36.008431,
      54.739305
    ],
    [
      36.009501783526225,
      54.73907276252227
    ],
    [
      36.009573,
      54.738998
    ],
    [
      36.00978,
      54.738942
    ],
    [
      36.00993065283084,
      54.73897974696577
    ],
    [
      36.011096,
      54.738727
    ],
    [
      36.011795,
      54.738604
    ],
    [
      36.02084536099362,
      54.74044125457076
    ],
    [
      36.028455,
      54.738234
    ],
    [
      36.032636,
      54.738597
    ],
    [
      36.03499209906866,
      54.739586097756096
    ],
    [
      36.035026,
      54.739518
    ],
    [
      36.03608454576636,
      54.740044710295656
    ],
    [
      36.03609,
      54.740047
    ],
    [
      36.03615916333321,
      54.74008183844065
    ],
    [
      36.037711,
      54.740854
    ],
    [
      36.040265,
      54.74215
    ],
    [
      36.043189,
      54.74481
    ],
    [
      36.047257,
      54.748322
    ],
    [
      36.04906923205544,
      54.749224535889134
    ],
    [
      36.049072,
      54.749219
    ],
    [
      36.053443,
      54.749703
    ],
    [
      36.061867,
      54.75429
    ],
    [
      36.062450342643395,
      54.75442076004336
    ],
    [
      36.062452,
      54.754413
    ],
    [
      36.066034,
      54.755177
    ],
    [
      36.06626580500692,
      54.755236724848764
    ],
    [
      36.066266,
      54.755236
    ],
    [
      36.067439,
      54.755539
    ],
    [
      36.07127,
      54.756596
    ],
    [
      36.075064,
      54.758817
    ],
    [
      36.084102,
      54.75952
    ],
    [
      36.091195,
      54.761081
    ],
    [
      36.096461,
      54.762228
    ],
    [
      36.105815658800644,
      54.762425561578375
    ],
    [
      36.106662,
      54.762186
    ],
    [
      36.110062,
      54.761002
    ],
    [
      36.11943314187204,
      54.757646767904426
    ],
    [
      36.119684295824946,
      54.757505595056934
    ],
    [
      36.116111,
      54.751372
    ],
    [
      36.110643,
      54.745655
    ],
    [
      36.107153,
      54.744311
    ],
    [
      36.102866,
      54.743316
    ],
    [
      36.099543,
      54.742167
    ],
    [
      36.096294,
      54.740541
    ],
    [
      36.087441,
      54.740131
    ],
    [
      36.086529,
      54.739829
    ],
    [
      36.08654090137608,
      54.73979303279978
    ],
    [
      36.086384,
      54.739781
    ],
    [
      36.084591,
      54.739139
    ],
    [
      36.08460662161025,
      54.73909535479215
    ],
    [
      36.083097,
      54.738513
    ],
    [
      36.072566,
      54.73948
    ],
    [
      36.072,
      54.739634
    ],
    [
      36.071051,
      54.736162
    ],
    [
      36.075605,
      54.732616
    ],
    [
      36.084255,
      54.735104
    ],
    [
      36.08840673211303,
      54.73498958218586
    ],
    [
      36.091812,
      54.734252
    ],
    [
      36.09302237890351,
      54.734862379321555
    ],
    [
      36.093145,
      54.734859
    ],
    [
      36.103809,
      54.729766
    ],
    [
      36.103845,
      54.725117
    ],
    [
      36.102767,
      54.721311
    ],
    [
      36.104615,
      54.719729
    ],
    [
      36.11404495128304,
      54.71688782193477
    ],
    [
      36.11409382942484,
      54.71681417921501
    ],
    [
      36.112853,
      54.71536
    ],
    [
      36.10624,
      54.712151
    ],
    [
      36.1043635466535,
      54.71184327698257
    ],
    [
      36.099521,
      54.71239
    ],
    [
      36.095696,
      54.713845
    ],
    [
      36.091669,
      54.71402
    ],
    [
      36.086869,
      54.713045
    ],
    [
      36.07799655931024,
      54.71073292516415
    ],
    [
      36.074302,
      54.711712
    ],
    [
      36.069994,
      54.714414
    ],
    [
      36.06999077458735,
      54.71440885754909
    ],
    [
      36.066229,
      54.716713
    ],
    [
      36.062853,
      54.725881
    ],
    [
      36.05889,
      54.72842
    ],
    [
      36.05727,
      54.729122
    ],
    [
      36.056373,
      54.729331
    ],
    [
      36.055555,
      54.725825
    ],
    [
      36.051068,
      54.718965
    ],
    [
      36.0396687762512,
      54.720194494109435
    ],
    [
      36.039613813558795,
      54.72021164230093
    ],
    [
      36.03558,
      54.724079
    ],
    [
      36.034854,
      54.72414
    ],
    [
      36.034253,
      54.724196
    ],
    [
      36.033749,
      54.724234
    ],
    [
      36.033482,
      54.720644
    ],
    [
      36.03430314373942,
      54.719993748062564
    ],
    [
      36.034092,
      54.718748
    ],
    [
      36.038239,
      54.716877
    ],
    [
      36.04347,
      54.706665
    ],
    [
      36.041451,
      54.701835
    ],
    [
      36.040773,
      54.700426
    ],
    [
      36.036887,
      54.693555
    ],
    [
      36.030059,
      54.693397
    ],
    [
      36.028318,
      54.693106
    ],
    [
      36.027925,
      54.692082
    ],
    [
      36.027788,
      54.690603
    ],
    [
      36.028608,
      54.689518
    ],
    [
      36.03002,
      54.689632
    ],
    [
      36.036979,
      54.689956
    ],
    [
      36.043863,
      54.682582
    ],
    [
      36.049234,
      54.682567
    ],
    [
      36.049745,
      54.682791
    ],
    [
      36.050038,
      54.683014
    ],
    [
      36.058276,
      54.690531
    ],
    [
      36.062636,
      54.690681
    ],
    [
      36.067339,
      54.692363
    ],
    [
      36.074713,
      54.688782
    ],
    [
      36.08501,
      54.683696
    ],
    [
      36.0851063737752,
      54.68383575140396
    ],
    [
      36.08749,
      54.682614
    ],
    [
      36.096603,
      54.677454
    ],
    [
      36.097657,
      54.676988
    ],
    [
      36.100381,
      54.676099
    ],
    [
      36.101498,
      54.679522
    ],
    [
      36.105857,
      54.68607
    ],
    [
      36.108553,
      54.696363
    ],
    [
      36.112704,
      54.696078
    ],
    [
      36.117007,
      54.69661
    ],
    [
      36.117538,
      54.696562
    ],
    [
      36.118316,
      54.696927
    ],
    [
      36.117468,
      54.700426
    ],
    [
      36.115985652676976,
      54.7064299540468
    ],
    [
      36.117655,
      54.706035
    ],
    [
      36.120876,
      54.707642
    ],
    [
      36.124162,
      54.718403
    ],
    [
      36.127404,
      54.719979
    ],
    [
      36.136912,
      54.7231
    ],
    [
      36.144865,
      54.724407
    ],
    [
      36.149954,
      54.726214
    ],
    [
      36.14989265596243,
      54.72783301668771
    ],
    [
      36.150685,
      54.727874
    ],
    [
      36.150599,
      54.729522
    ],
    [
      36.15020191592091,
      54.730848765057914
    ],
    [
      36.150206,
      54.73085
    ],
    [
      36.149717,
      54.732469
    ],
    [
      36.1532919039363,
      54.74213913418338
    ],
    [
      36.162385,
      54.737677
    ],
    [
      36.166308,
      54.735757
    ],
    [
      36.167825,
      54.724743
    ],
    [
      36.166833,
      54.723196
    ],
    [
      36.16693613966066,
      54.72312988308787
    ],
    [
      36.165962,
      54.721362
    ],
    [
      36.16275526065105,
      54.71348856274776
    ],
    [
      36.162277,
      54.712393
    ],
    [
      36.160242,
      54.708056
    ],
    [
      36.155841,
      54.697974
    ],
    [
      36.15446341820377,
      54.694817459166
    ],
    [
      36.152183,
      54.689594
    ],
    [
      36.15218363148617,
      54.6895937244424
    ],
    [
      36.145571,
      54.674445
    ],
    [
      36.144579,
      54.672218
    ],
    [
      36.14420930052236,
      54.67138757513903
    ],
    [
      36.143443,
      54.669667
    ],
    [
      36.141747,
      54.665717
    ],
    [
      36.14005,
      54.661767
    ],
    [
      36.140050341778064,
      54.66176685318421
    ],
    [
      36.140001,
      54.661652
    ],
    [
      36.13469470354122,
      54.65679925882554
    ],
    [
      36.130341,
      54.653627
    ],
    [
      36.12338942685795,
      54.65820486523989
    ],
    [
      36.1233626036543,
      54.65823804819893
    ],
    [
      36.121394,
      54.663173
    ],
    [
      36.119508,
      54.665048
    ],
    [
      36.11906976725386,
      54.66475666015264
    ],
    [
      36.117245,
      54.666
    ],
    [
      36.117166,
      54.665898
    ],
    [
      36.116283,
      54.662904
    ],
    [
      36.118717,
      54.658992
    ],
    [
      36.12071624331195,
      54.65626447011728
    ],
    [
      36.120482,
      54.656075
    ],
    [
      36.121199,
      54.655188
    ],
    [
      36.124921,
      54.650528
    ],
    [
      36.130939,
      54.636821
    ],
    [
      36.127974,
      54.634337
    ],
    [
      36.1273123295351,
      54.632734135062876
    ],
    [
      36.126357,
      54.630871
    ],
    [
      36.12636889509935,
      54.63086489461651
    ],
    [
      36.123705,
      54.62741
    ],
    [
      36.118254,
      54.62268
    ],
    [
      36.112727,
      54.618257
    ],
    [
      36.111089,
      54.616967
    ],
    [
      36.11095927075832,
      54.61686476987064
    ],
    [
      36.107332,
      54.614008
    ],
    [
      36.101937,
      54.609758
    ],
    [
      36.104164,
      54.60693
    ],
    [
      36.109559,
      54.611179
    ],
    [
      36.114955,
      54.615429
    ],
    [
      36.118614618289705,
      54.618358798616406
    ],
    [
      36.118625,
      54.618346
    ],
    [
      36.12017989861719,
      54.619608124985156
    ],
    [
      36.12018,
      54.619608
    ],
    [
      36.120521,
      54.619885
    ],
    [
      36.126258,
      54.624871
    ],
    [
      36.129327,
      54.628812
    ],
    [
      36.13784,
      54.632632
    ],
    [
      36.144279,
      54.625257
    ],
    [
      36.142839,
      54.619952
    ],
    [
      36.144286,
      54.618512
    ],
    [
      36.145791,
      54.617847
    ],
    [
      36.154391,
      54.618512
    ],
    [
      36.156005,
      54.620677
    ],
    [
      36.164595,
      54.626589
    ],
    [
      36.166953,
      54.627292
    ],
    [
      36.169349,
      54.628293
    ],
    [
      36.169849,
      54.628993
    ],
    [
      36.170029,
      54.629521
    ],
    [
      36.170919,
      54.631696
    ],
    [
      36.17113,
      54.632949
    ],
    [
      36.187036,
      54.632134
    ],
    [
      36.188154,
      54.631779
    ],
    [
      36.197424,
      54.624676
    ],
    [
      36.201941,
      54.624487
    ],
    [
      36.204002,
      54.627439
    ],
    [
      36.210916873713735,
      54.631430334970815
    ],
    [
      36.215153,
      54.630694
    ],
    [
      36.217991,
      54.633108
    ],
    [
      36.221911,
      54.63615
    ],
    [
      36.22954075393429,
      54.62919967289398
    ],
    [
      36.227198,
      54.624441
    ],
    [
      36.224145,
      54.618855
    ],
    [
      36.224165,
      54.618444
    ],
    [
      36.224157,
      54.617031
    ],
    [
      36.230385,
      54.614099
    ],
    [
      36.237073,
      54.617314
    ],
    [
      36.241745,
      54.619888
    ],
    [
      36.247932,
      54.620852
    ],
    [
      36.250303,
      54.609323
    ],
    [
      36.251618,
      54.605972
    ],
    [
      36.258967,
      54.597614
    ],
    [
      36.260362,
      54.596214
    ],
    [
      36.260737,
      54.596117
    ],
    [
      36.269097,
      54.597282
    ],
    [
      36.273193,
      54.597358
    ],
    [
      36.275587,
      54.597509
    ],
    [
      36.285831,
      54.594629
    ],
    [
      36.29456,
      54.594827
    ],
    [
      36.297382,
      54.597062
    ],
    [
      36.297055,
      54.597475
    ],
    [
      36.297959642817396,
      54.60484023943114
    ],
    [
      36.298212,
      54.604897
    ],
    [
      36.300165,
      54.605496
    ],
    [
      36.310336,
      54.606752
    ],
    [
      36.314038,
      54.601284
    ],
    [
      36.315676,
      54.598078
    ],
    [
      36.318015,
      54.597689
    ],
    [
      36.31997,
      54.597503
    ],
    [
      36.321658,
      54.599025
    ],
    [
      36.322532,
      54.600033
    ],
    [
      36.324338,
      54.606575
    ],
    [
      36.328771,
      54.610436
    ],
    [
      36.339449,
      54.609155
    ],
    [
      36.343048,
      54.609043
    ],
    [
      36.343138,
      54.611959
    ],
    [
      36.343642,
      54.61501
    ],
    [
      36.341256,
      54.618974
    ],
    [
      36.349977276163074,
      54.62616936028813
    ],
    [
      36.350475,
      54.626022
    ],
    [
      36.35094349082646,
      54.626022
    ],
    [
      36.355463,
      54.620639
    ],
    [
      36.358235,
      54.617557
    ],
    [
      36.360336,
      54.615086
    ],
    [
      36.360505,
      54.614916
    ],
    [
      36.362478,
      54.616142
    ],
    [
      36.363053,
      54.61746
    ],
    [
      36.362883,
      54.61763
    ],
    [
      36.358566,
      54.622502
    ],
    [
      36.357731,
      54.626948
    ],
    [
      36.362337,
      54.634842
    ],
    [
      36.370401,
      54.635971
    ],
    [
      36.373094,
      54.636011
    ],
    [
      36.376123,
      54.636537
    ],
    [
      36.375508,
      54.640084
    ],
    [
      36.371135,
      54.639752
    ],
    [
      36.365986,
      54.640414
    ],
    [
      36.357674,
      54.642713
    ],
    [
      36.353933,
      54.647425
    ],
    [
      36.351114,
      54.645186
    ],
    [
      36.354854,
      54.640475
    ],
    [
      36.348673,
      54.633401
    ],
    [
      36.344712,
      54.633155
    ],
    [
      36.34208858677883,
      54.634257839613824
    ],
    [
      36.345121,
      54.636614
    ],
    [
      36.344338,
      54.640128
    ],
    [
      36.337306,
      54.639925
    ],
    [
      36.332187,
      54.640748
    ],
    [
      36.33138589672142,
      54.64105495651649
    ],
    [
      36.331414,
      54.641138
    ],
    [
      36.331429,
      54.648057
    ],
    [
      36.329674,
      54.648309
    ],
    [
      36.328332,
      54.648342
    ],
    [
      36.324757,
      54.646561
    ],
    [
      36.316846,
      54.65493
    ],
    [
      36.315958288880175,
      54.65724795863229
    ],
    [
      36.316591,
      54.657655
    ],
    [
      36.315272,
      54.659704
    ],
    [
      36.314714,
      54.660497
    ],
    [
      36.30957346348566,
      54.665907213511
    ],
    [
      36.312189,
      54.67389
    ],
    [
      36.31095,
      54.67727
    ],
    [
      36.301186,
      54.682161
    ],
    [
      36.301001733209404,
      54.68371209029302
    ],
    [
      36.301242,
      54.683746
    ],
    [
      36.301132,
      54.684529
    ],
    [
      36.300755,
      54.685789
    ],
    [
      36.300414,
      54.686854
    ],
    [
      36.299030970374545,
      54.689336069594745
    ],
    [
      36.296903,
      54.694335
    ],
    [
      36.296405,
      54.696614
    ],
    [
      36.295664,
      54.698528
    ],
    [
      36.293658,
      54.702963
    ],
    [
      36.291981905210044,
      54.71169979448766
    ],
    [
      36.292092,
      54.713948
    ],
    [
      36.29211275392398,
      54.71449327540936
    ],
    [
      36.292122,
      54.714566
    ],
    [
      36.292221,
      54.717138
    ],
    [
      36.29232,
      54.719709
    ],
    [
      36.29242885345705,
      54.72269376179223
    ],
    [
      36.29370295704809,
      54.727553836159736
    ],
    [
      36.2937030711248,
      54.72755417421984
    ],
    [
      36.295287,
      54.731434
    ],
    [
      36.298124,
      54.738138
    ],
    [
      36.298918,
      54.741802
    ],
    [
      36.304005,
      54.750243
    ],
    [
      36.309204,
      54.749391
    ],
    [
      36.312413,
      54.747545
    ],
    [
      36.31478,
      54.74441
    ],
    [
      36.320309,
      54.741814
    ],
    [
      36.324393,
      54.740898
    ],
    [
      36.320822,
      54.729464
    ],
    [
      36.321009,
      54.725869
    ],
    [
      36.32957368439269,
      54.72546125214123
    ],
    [
      36.332494,
      54.724976
    ],
    [
      36.33274022832035,
      54.72509982171282
    ],
    [
      36.335375,
      54.724794
    ],
    [
      36.33782130704623,
      54.72594463453953
    ],
    [
      36.337869,
      54.725831
    ],
    [
      36.342927,
      54.726458
    ],
    [
      36.347712544458794,
      54.72689471763083
    ],
    [
      36.352082,
      54.726368
    ],
    [
      36.355218,
      54.723884
    ],
    [
      36.361366,
      54.71702
    ],
    [
      36.36410424647797,
      54.716571344393145
    ],
    [
      36.364679,
      54.716105
    ],
    [
      36.37347146767866,
      54.712973090448166
    ],
    [
      36.375695,
      54.704703
    ],
    [
      36.370828,
      54.702606
    ],
    [
      36.366238,
      54.70012
    ],
    [
      36.363364,
      54.694288
    ],
    [
      36.363676,
      54.693479
    ],
    [
      36.36399,
      54.69297
    ],
    [
      36.36426609075525,
      54.69298146139466
    ],
    [
      36.364204,
      54.692806
    ],
    [
      36.364253,
      54.692661
    ],
    [
      36.365957341847725,
      54.69296128880174
    ],
    [
      36.36669,
      54.690381
    ],
    [
      36.36951295411718,
      54.68702490311222
    ],
    [
      36.371412,
      54.682113
    ],
    [
      36.366231,
      54.678575
    ],
    [
      36.362721,
      54.677775
    ],
    [
      36.360024,
      54.677035
    ],
    [
      36.357766,
      54.676365
    ],
    [
      36.35776699525086,
      54.67636164588796
    ],
    [
      36.357607,
      54.676316
    ],
    [
      36.352711,
      54.674425
    ],
    [
      36.34944,
      54.672284
    ],
    [
      36.347514,
      54.669242
    ],
    [
      36.35474,
      54.661463
    ],
    [
      36.354785104547396,
      54.66147151847262
    ],
    [
      36.356729,
      54.658
    ],
    [
      36.360052,
      54.656795
    ],
    [
      36.365429,
      54.656808
    ],
    [
      36.370282,
      54.656921
    ],
    [
      36.37318,
      54.657002
    ],
    [
      36.373179984939114,
      54.657002536525404
    ],
    [
      36.373232,
      54.657004
    ],
    [
      36.376705,
      54.657175
    ],
    [
      36.380178,
      54.657347
    ],
    [
      36.380001,
      54.660942
    ],
    [
      36.376528,
      54.660771
    ],
    [
      36.373067972054294,
      54.6605996917655
    ],
    [
      36.370181,
      54.660519
    ],
    [
      36.365321,
      54.660407
    ],
    [
      36.358025,
      54.664486
    ],
    [
      36.361048,
      54.673583
    ],
    [
      36.36703,
      54.675065
    ],
    [
      36.370463,
      54.675847
    ],
    [
      36.373304,
      54.678056
    ],
    [
      36.375074,
      54.68112
    ],
    [
      36.375149664098736,
      54.68118916227345
    ],
    [
      36.375151,
      54.681188
    ],
    [
      36.37516112065817,
      54.68119963436933
    ],
    [
      36.378676214082134,
      54.68441267538386
    ],
    [
      36.38400207809674,
      54.68847613312546
    ],
    [
      36.384055,
      54.688378
    ],
    [
      36.385029,
      54.688903
    ],
    [
      36.38603535916489,
      54.69002582209495
    ],
    [
      36.386039,
      54.690023
    ],
    [
      36.387305,
      54.691657
    ],
    [
      36.393558,
      54.700832
    ],
    [
      36.399275,
      54.706784
    ],
    [
      36.399846,
      54.710338
    ],
    [
      36.399654,
      54.710369
    ],
    [
      36.39704125518868,
      54.70995267964856
    ],
    [
      36.397021,
      54.710037
    ],
    [
      36.395945,
      54.709778
    ],
    [
      36.385936,
      54.706013
    ],
    [
      36.382309,
      54.712533
    ],
    [
      36.38206091846616,
      54.71263850154598
    ],
    [
      36.385937,
      54.715685
    ],
    [
      36.386038,
      54.715877
    ],
    [
      36.383929,
      54.718999
    ],
    [
      36.384733,
      54.73083
    ],
    [
      36.38497124679817,
      54.730977416788775
    ],
    [
      36.386271,
      54.730184
    ],
    [
      36.392919,
      54.727912
    ],
    [
      36.395886,
      54.731849
    ],
    [
      36.395725,
      54.73691
    ],
    [
      36.39132081255406,
      54.73995972134205
    ],
    [
      36.390849,
      54.740587
    ],
    [
      36.38863,
      54.741823
    ],
    [
      36.379663,
      54.744087
    ],
    [
      36.375443,
      54.745064
    ],
    [
      36.369699,
      54.746225
    ],
    [
      36.365432,
      54.74843
    ],
    [
      36.366434,
      54.760193
    ],
    [
      36.362243,
      54.759827
    ],
    [
      36.354606,
      54.760408
    ],
    [
      36.35349353539105,
      54.760176443471465
    ],
    [
      36.353493,
      54.760179
    ],
    [
      36.351022,
      54.759662
    ],
    [
      36.342507,
      54.759659
    ],
    [
      36.338651,
      54.759779
    ],
    [
      36.335179,
      54.757716
    ],
    [
      36.33181,
      54.755998
    ],
    [
      36.32848,
      54.754315
    ],
    [
      36.320381,
      54.750958
    ],
    [
      36.313768,
      54.75224
    ],
    [
      36.309805,
      54.75294
    ],
    [
      36.304541,
      54.753803
    ],
    [
      36.303819966366056,
      54.75391778906118
    ],
    [
      36.30382,
      54.753918
    ],
    [
      36.29998615464758,
      54.7545285314308
    ],
    [
      36.29235,
      54.759329
    ],
    [
      36.290026,
      54.765414
    ],
    [
      36.293983,
      54.776675
    ],
    [
      36.295501,
      54.780347
    ],
    [
      36.295747,
      54.786148
    ],
    [
      36.29634862287752,
      54.79544676763319
    ],
    [
      36.303404,
      54.799694
    ],
    [
      36.313099,
      54.800258
    ],
    [
      36.316503,
      54.801456
    ],
    [
      36.31671623060085,
      54.801921015140074
    ],
    [
      36.316829,
      54.801943
    ],
    [
      36.325431,
      54.803206
    ],
    [
      36.32561,
      54.803401
    ],
    [
      36.327247,
      54.804953
    ],
    [
      36.33832,
      54.807761
    ],
    [
      36.341865,
      54.807135
    ],
    [
      36.34218,
      54.808921
    ],
    [
      36.342272,
      54.809434
    ],
    [
      36.337443,
      54.812117
    ],
    [
      36.32927567390367,
      54.82329244827815
    ],
    [
      36.32925661381086,
      54.82336606809009
    ],
    [
      36.329244571285756,
      54.82486497399738
    ],
    [
      36.330105,
      54.825943
    ],
    [
      36.33452494390035,
      54.833698372265225
    ],
    [
      36.336494,
      54.835197
    ],
    [
      36.339875854163296,
      54.83989568233307
    ],
    [
      36.342177068655346,
      54.84229438612501
    ],
    [
      36.34466530345956,
      54.84165515995071
    ],
    [
      36.349711,
      54.835148
    ],
    [
      36.349555,
      54.831552
    ],
    [
      36.351286,
      54.831477
    ],
    [
      36.35564,
      54.83163
    ],
    [
      36.35663129075924,
      54.831849632332904
    ],
    [
      36.358309,
      54.829981
    ],
    [
      36.36032619990503,
      54.82628195626605
    ],
    [
      36.356764,
      54.817104
    ],
    [
      36.35715404985256,
      54.81669333212762
    ],
    [
      36.356896,
      54.815926
    ],
    [
      36.35748489970572,
      54.80410828246546
    ],
    [
      36.354967,
      54.800757
    ],
    [
      36.3571668481887,
      54.80084058619886
    ],
    [
      36.366469,
      54.800923
    ],
    [
      36.372829,
      54.80626
    ],
    [
      36.378371,
      54.804474
    ],
    [
      36.380665556954185,
      54.803702334864376
    ],
    [
      36.38350950560571,
      54.802616639888974
    ],
    [
      36.387066,
      54.796106
    ],
    [
      36.387416,
      54.790317
    ],
    [
      36.38605524157588,
      54.783014836725044
    ],
    [
      36.38349,
      54.778929
    ],
    [
      36.38536,
      54.776757448275866
    ],
    [
      36.38536,
      54.775684
    ],
    [
      36.386240389475994,
      54.7757350893002
    ],
    [
      36.386448,
      54.775494
    ],
    [
      36.3873605781283,
      54.77555845780094
    ],
    [
      36.387361,
      54.775556
    ],
    [
      36.38738566980735,
      54.77556023009243
    ],
    [
      36.392012,
      54.775887
    ],
    [
      36.400456,
      54.775802
    ],
    [
      36.404672,
      54.775494
    ],
    [
      36.408145,
      54.77618
    ],
    [
      36.411628,
      54.777044
    ],
    [
      36.412049794992335,
      54.777236761977655
    ],
    [
      36.415694,
      54.778223
    ],
    [
      36.4178557112357,
      54.779622994045404
    ],
    [
      36.422478,
      54.781315
    ],
    [
      36.42410871217117,
      54.783097942268355
    ],
    [
      36.42877058999635,
      54.78474510082506
    ],
    [
      36.433777,
      54.776604
    ],
    [
      36.432964,
      54.773097
    ],
    [
      36.429783,
      54.762721
    ],
    [
      36.429263,
      54.759159
    ],
    [
      36.430002901520275,
      54.75933228041399
    ],
    [
      36.429859,
      54.758918
    ],
    [
      36.435783206083805,
      54.75472944462811
    ],
    [
      36.437947,
      54.750505
    ],
    [
      36.441623,
      54.752976
    ],
    [
      36.441919,
      54.757119
    ],
    [
      36.43875511847604,
      54.763525160112245
    ],
    [
      36.438232196553024,
      54.765369639414
    ],
    [
      36.437943341231524,
      54.7667191458898
    ],
    [
      36.438622,
      54.771973
    ],
    [
      36.44833094067226,
      54.774493724967535
    ],
    [
      36.452808,
      54.771839
    ],
    [
      36.45280956159306,
      54.77184163412512
    ],
    [
      36.453783,
      54.771265
    ],
    [
      36.458407,
      54.77002
    ],
    [
      36.464845,
      54.768912
    ],
    [
      36.470954,
      54.765066
    ],
    [
      36.473269,
      54.759075
    ],
    [
      36.472177,
      54.749488
    ],
    [
      36.46761,
      54.746238
    ],
    [
      36.467423,
      54.745758
    ],
    [
      36.467353,
      54.745515
    ],
    [
      36.46761524168435,
      54.7454389142582
    ],
    [
      36.467639,
      54.741817
    ],
    [
      36.468265,
      54.73655
    ],
    [
      36.46855602609791,
      54.7342869968701
    ],
    [
      36.462253,
      54.724403
    ],
    [
      36.460688,
      54.713293
    ],
    [
      36.458434,
      54.70951
    ],
    [
      36.456472,
      54.7047
    ],
    [
      36.447839,
      54.697552
    ],
    [
      36.447393,
      54.694128
    ],
    [
      36.448969,
      54.690904
    ],
    [
      36.452683,
      54.689657
    ],
    [
      36.456522,
      54.691048
    ],
    [
      36.45699982881291,
      54.69306772818571
    ],
    [
      36.45757,
      54.693095
    ],
    [
      36.458523,
      54.699506
    ],
    [
      36.459838,
      54.703425
    ],
    [
      36.461746,
      54.708101
    ],
    [
      36.463568,
      54.711133
    ],
    [
      36.473319,
      54.712428
    ],
    [
      36.477247,
      54.712214
    ],
    [
      36.487124,
      54.708378
    ],
    [
      36.491844,
      54.70771
    ],
    [
      36.4923526645162,
      54.70766015252981
    ],
    [
      36.493375,
      54.70749
    ],
    [
      36.494953,
      54.707304
    ],
    [
      36.497108,
      54.707181
    ],
    [
      36.497108749239146,
      54.70719407167716
    ],
    [
      36.497385,
      54.707167
    ],
    [
      36.498476,
      54.707283
    ],
    [
      36.49959,
      54.7076
    ],
    [
      36.498604,
      54.711062
    ],
    [
      36.497403,
      54.710767
    ],
    [
      36.4973139640187,
      54.710774372248515
    ],
    [
      36.497314,
      54.710775
    ],
    [
      36.49728948104629,
      54.71077639945768
    ],
    [
      36.49502409497582,
      54.71096397518677
    ],
    [
      36.491415,
      54.712729
    ],
    [
      36.4897161654613,
      54.713604961559014
    ],
    [
      36.48826156385109,
      54.714508773134234
    ],
    [
      36.492013,
      54.724964
    ],
    [
      36.495598,
      54.724919
    ],
    [
      36.499222,
      54.724453
    ],
    [
      36.504118,
      54.725283
    ],
    [
      36.509418,
      54.726415
    ],
    [
      36.513841,
      54.727138
    ],
    [
      36.510242,
      54.730213
    ],
    [
      36.506747,
      54.729507
    ],
    [
      36.496354,
      54.729053
    ],
    [
      36.485266,
      54.730927
    ],
    [
      36.481368,
      54.732232
    ],
    [
      36.47785,
      54.733032
    ],
    [
      36.47323765202941,
      54.737843394919054
    ],
    [
      36.47193429870055,
      54.74067636051356
    ],
    [
      36.474233,
      54.746533
    ],
    [
      36.482135,
      54.747071
    ],
    [
      36.486298,
      54.745137
    ],
    [
      36.488326,
      54.744659
    ],
    [
      36.489151,
      54.748163
    ],
    [
      36.487185,
      54.748967
    ],
    [
      36.483284,
      54.750483
    ],
    [
      36.478513,
      54.754065
    ],
    [
      36.47773933810482,
      54.75665166290234
    ],
    [
      36.483194,
      54.765197
    ],
    [
      36.4907,
      54.765606
    ],
    [
      36.495399,
      54.76587
    ],
    [
      36.498901,
      54.766051
    ],
    [
      36.5024,
      54.766231
    ],
    [
      36.507507,
      54.766496
    ],
    [
      36.52244485421695,
      54.762521013364285
    ],
    [
      36.52820189163479,
      54.760958146316426
    ],
    [
      36.537016,
      54.758565
    ],
    [
      36.54781788234125,
      54.751707074699475
    ],
    [
      36.549796623958656,
      54.750428733698996
    ],
    [
      36.556278,
      54.746241
    ],
    [
      36.55627823817877,
      54.74624136879294
    ],
    [
      36.557206,
      54.745642
    ],
    [
      36.55721404276786,
      54.74565444282868
    ],
    [
      36.558452,
      54.744857
    ],
    [
      36.563322,
      54.74258
    ],
    [
      36.571564,
      54.738969
    ],
    [
      36.576553,
      54.740466
    ],
    [
      36.581744,
      54.742736
    ],
    [
      36.589694,
      54.739399
    ],
    [
      36.594457,
      54.741784
    ],
    [
      36.59301048594674,
      54.74358294573468
    ],
    [
      36.593489,
      54.74499
    ],
    [
      36.592020975463704,
      54.74548970117051
    ],
    [
      36.59194397499363,
      54.74551592653304
    ],
    [
      36.591944,
      54.745516
    ],
    [
      36.591582070977644,
      54.74563918635225
    ],
    [
      36.59127222976438,
      54.74574471425535
    ],
    [
      36.591272,
      54.745745
    ],
    [
      36.59108511111333,
      54.74580844444369
    ],
    [
      36.590646,
      54.745958
    ],
    [
      36.588671,
      54.74663
    ],
    [
      36.587908,
      54.746887
    ],
    [
      36.584356,
      54.747146
    ],
    [
      36.579944,
      54.745854
    ],
    [
      36.572044,
      54.742537
    ],
    [
      36.565082,
      54.745721
    ],
    [
      36.559979,
      54.748117
    ],
    [
      36.559152095283366,
      54.74865277074802
    ],
    [
      36.55916,
      54.748665
    ],
    [
      36.552776777709084,
      54.75278944448883
    ],
    [
      36.548791,
      54.755365
    ],
    [
      36.53935,
      54.761464
    ],
    [
      36.538038,
      54.762017
    ],
    [
      36.5325969012238,
      54.763494636109726
    ],
    [
      36.532597,
      54.763495
    ],
    [
      36.527445098634495,
      54.764893708218
    ],
    [
      36.523434,
      54.765983
    ],
    [
      36.52340413883026,
      54.76599080291872
    ],
    [
      36.520733,
      54.766716
    ],
    [
      36.508868,
      54.769938
    ],
    [
      36.507713,
      54.770091
    ],
    [
      36.502221,
      54.769827
    ],
    [
      36.498716,
      54.769646
    ],
    [
      36.495214,
      54.769465
    ],
    [
      36.490498,
      54.7692
    ],
    [
      36.483009,
      54.768792
    ],
    [
      36.474361,
      54.770103
    ],
    [
      36.469213,
      54.771802
    ],
    [
      36.465464,
      54.772459
    ],
    [
      36.459007,
      54.77357
    ],
    [
      36.455428,
      54.774468
    ],
    [
      36.454642830146945,
      54.774934026669435
    ],
    [
      36.454644,
      54.774936
    ],
    [
      36.45006223920192,
      54.77765280893539
    ],
    [
      36.449727546684336,
      54.777851575218335
    ],
    [
      36.442577,
      54.786925
    ],
    [
      36.441873,
      54.790455
    ],
    [
      36.437138,
      54.790056
    ],
    [
      36.428237,
      54.790552
    ],
    [
      36.423494,
      54.799772
    ],
    [
      36.423483,
      54.803374
    ],
    [
      36.42002,
      54.80748
    ],
    [
      36.417881,
      54.810435
    ],
    [
      36.408472,
      54.806503
    ],
    [
      36.406099735165625,
      54.80607859783676
    ],
    [
      36.404411,
      54.808349
    ],
    [
      36.40354762974428,
      54.805622021890464
    ],
    [
      36.403173,
      54.805555
    ],
    [
      36.401896372816495,
      54.804198856154464
    ],
    [
      36.39267646926073,
      54.80270441411379
    ],
    [
      36.390489,
      54.80367
    ],
    [
      36.384837,
      54.806086
    ],
    [
      36.381462,
      54.807247
    ],
    [
      36.377493,
      54.808554
    ],
    [
      36.37621303507496,
      54.80881412477043
    ],
    [
      36.372637,
      54.809817
    ],
    [
      36.370051,
      54.820919
    ],
    [
      36.368581,
      54.824205
    ],
    [
      36.37248166336558,
      54.82924688672042
    ],
    [
      36.37425921264805,
      54.8306083969294
    ],
    [
      36.375725,
      54.831147
    ],
    [
      36.37662780232793,
      54.83202886240312
    ],
    [
      36.384731,
      54.830771
    ],
    [
      36.386277,
      54.829899
    ],
    [
      36.386571,
      54.829787
    ],
    [
      36.38685739797946,
      54.830537121810686
    ],
    [
      36.388886,
      54.830314
    ],
    [
      36.394595075117124,
      54.83210838308261
    ],
    [
      36.400054,
      54.832429
    ],
    [
      36.40375,
      54.832204
    ],
    [
      36.406630609354714,
      54.83205626410426
    ],
    [
      36.407104,
      54.832025
    ],
    [
      36.411688,
      54.831674
    ],
    [
      36.412463,
      54.831703
    ],
    [
      36.413423,
      54.834828
    ],
    [
      36.41813802426732,
      54.844387613120254
    ],
    [
      36.421229,
      54.841851
    ],
    [
      36.424155,
      54.840614
    ],
    [
      36.42716991260971,
      54.8404993980587
    ],
    [
      36.428386,
      54.840453
    ],
    [
      36.431224,
      54.840368
    ],
    [
      36.437511,
      54.849005
    ],
    [
      36.442411,
      54.849085
    ],
    [
      36.451986,
      54.843623
    ],
    [
      36.452845,
      54.840203
    ],
    [
      36.452919,
      54.836463
    ],
    [
      36.45415,
      54.832505
    ],
    [
      36.458054,
      54.830449
    ],
    [
      36.465796,
      54.828166
    ],
    [
      36.470612,
      54.826789
    ],
    [
      36.477844,
      54.820131
    ],
    [
      36.478767,
      54.815731
    ],
    [
      36.474964,
      54.805294
    ],
    [
      36.473098,
      54.801347
    ],
    [
      36.474724,
      54.79155
    ],
    [
      36.476233,
      54.78902
    ],
    [
      36.477743,
      54.786491
    ],
    [
      36.480834,
      54.788336
    ],
    [
      36.479542,
      54.798805
    ],
    [
      36.482017,
      54.806985
    ],
    [
      36.48286,
      54.811031
    ],
    [
      36.48398153014997,
      54.81689585945139
    ],
    [
      36.49328650405321,
      54.82119984550776
    ],
    [
      36.496251,
      54.820532
    ],
    [
      36.502135,
      54.816357
    ],
    [
      36.50471,
      54.813462
    ],
    [
      36.507863,
      54.811012
    ],
    [
      36.508275343326424,
      54.811468552985964
    ],
    [
      36.509607,
      54.810724
    ],
    [
      36.50909134902,
      54.81182224206105
    ],
    [
      36.513719,
      54.812321
    ],
    [
      36.514806,
      54.815753
    ],
    [
      36.512466,
      54.816494
    ],
    [
      36.510127,
      54.817235
    ],
    [
      36.507693,
      54.8181
    ],
    [
      36.503064534975444,
      54.820905966867585
    ],
    [
      36.502193,
      54.822047
    ],
    [
      36.498304,
      54.823792
    ],
    [
      36.495080046419865,
      54.82448179139259
    ],
    [
      36.492877,
      54.824982
    ],
    [
      36.487961,
      54.825871
    ],
    [
      36.48500189335049,
      54.826423245141356
    ],
    [
      36.478305,
      54.828489
    ],
    [
      36.474828,
      54.829495
    ],
    [
      36.471137,
      54.830351
    ],
    [
      36.467179,
      54.831669
    ],
    [
      36.463247,
      54.83295
    ],
    [
      36.456064,
      54.842287
    ],
    [
      36.454882558220596,
      54.846738896519
    ],
    [
      36.454883,
      54.846739
    ],
    [
      36.454226,
      54.849544
    ],
    [
      36.452953,
      54.851259
    ],
    [
      36.449126,
      54.852185
    ],
    [
      36.445634,
      54.852412
    ],
    [
      36.441167,
      54.85287
    ],
    [
      36.44087324319397,
      54.85284937767877
    ],
    [
      36.440872,
      54.852883
    ],
    [
      36.440606,
      54.852873
    ],
    [
      36.437207,
      54.852592
    ],
    [
      36.433005,
      54.851904
    ],
    [
      36.428144,
      54.85161
    ],
    [
      36.424426,
      54.852232
    ],
    [
      36.420342,
      54.852903
    ],
    [
      36.41920313136237,
      54.85309152632491
    ],
    [
      36.419201,
      54.853095
    ],
    [
      36.418104,
      54.854038
    ],
    [
      36.412311,
      54.860963
    ],
    [
      36.408966,
      54.860159
    ],
    [
      36.40455378991594,
      54.85625917130444
    ],
    [
      36.400904,
      54.856367
    ],
    [
      36.39894491350008,
      54.85683622241098
    ],
    [
      36.393312,
      54.866138
    ],
    [
      36.395266,
      54.872275
    ],
    [
      36.398125,
      54.883345
    ],
    [
      36.400986,
      54.88604
    ],
    [
      36.408221,
      54.888836
    ],
    [
      36.41494723580885,
      54.89011533444502
    ],
    [
      36.416679,
      54.889975
    ],
    [
      36.416932873238366,
      54.89049300398131
    ],
    [
      36.418763100471196,
      54.890841114396665
    ],
    [
      36.425246,
      54.885253
    ],
    [
      36.433427,
      54.879687
    ],
    [
      36.435688,
      54.882489
    ],
    [
      36.438016,
      54.886659
    ],
    [
      36.43676175689444,
      54.88871907805421
    ],
    [
      36.437002,
      54.890113
    ],
    [
      36.432404,
      54.89663
    ],
    [
      36.435096,
      54.907762
    ],
    [
      36.43570628985256,
      54.90877331202708
    ],
    [
      36.437208,
      54.910544
    ],
    [
      36.440141,
      54.912666
    ],
    [
      36.445017,
      54.915215
    ],
    [
      36.456604,
      54.915752
    ],
    [
      36.46112068389971,
      54.914393326197526
    ],
    [
      36.461771,
      54.913903
    ],
    [
      36.46440777229431,
      54.91314911871173
    ],
    [
      36.465023,
      54.912716
    ],
    [
      36.46733943282565,
      54.91248969767261
    ],
    [
      36.47295068175927,
      54.90954759437767
    ],
    [
      36.477631,
      54.906079
    ],
    [
      36.485448,
      54.898456
    ],
    [
      36.48646,
      54.892318
    ],
    [
      36.487597,
      54.891078
    ],
    [
      36.48867,
      54.890463
    ],
    [
      36.488996,
      54.890298
    ],
    [
      36.489642,
      54.890633
    ],
    [
      36.490041,
      54.891387
    ],
    [
      36.490661,
      54.893055
    ],
    [
      36.49064087366352,
      54.89347351900784
    ],
    [
      36.490787,
      54.893443
    ],
    [
      36.490851,
      54.893751
    ],
    [
      36.490476,
      54.896902
    ],
    [
      36.490413235643004,
      54.89702192864969
    ],
    [
      36.490175,
      54.898802
    ],
    [
      36.49326329768325,
      54.909404658068745
    ],
    [
      36.49467830962749,
      54.90977391247003
    ],
    [
      36.49633,
      54.909265
    ],
    [
      36.502193,
      54.909242
    ],
    [
      36.502195706565615,
      54.90925416223986
    ],
    [
      36.502939,
      54.909082
    ],
    [
      36.512049,
      54.903778
    ],
    [
      36.516932,
      54.896694
    ],
    [
      36.517902,
      54.894814
    ],
    [
      36.51857377495624,
      54.89351269572911
    ],
    [
      36.519137,
      54.892421
    ],
    [
      36.519137726822365,
      54.892421375112136
    ],
    [
      36.519204,
      54.892293
    ],
    [
      36.521209,
      54.888771
    ],
    [
      36.524804,
      54.882839
    ],
    [
      36.528531,
      54.872895
    ],
    [
      36.52981431815967,
      54.87236445763691
    ],
    [
      36.528969,
      54.871258
    ],
    [
      36.529827,
      54.86963
    ],
    [
      36.531331,
      54.868476
    ],
    [
      36.532835,
      54.867321
    ],
    [
      36.542074,
      54.863525
    ],
    [
      36.550746,
      54.865016
    ],
    [
      36.562216,
      54.867878
    ],
    [
      36.56231725439318,
      54.867933207559446
    ],
    [
      36.56478245458041,
      54.86741189940369
    ],
    [
      36.570632,
      54.862134
    ],
    [
      36.571893,
      54.858762
    ],
    [
      36.572248,
      54.858326
    ],
    [
      36.57822453902412,
      54.858296117304874
    ],
    [
      36.578426,
      54.857515
    ],
    [
      36.578984,
      54.857296
    ],
    [
      36.58934,
      54.858181
    ],
    [
      36.597995,
      54.854454
    ],
    [
      36.607567,
      54.847982
    ],
    [
      36.60767,
      54.838587
    ],
    [
      36.600151,
      54.836078
    ],
    [
      36.591831,
      54.836673
    ],
    [
      36.59589270380034,
      54.83438728023188
    ],
    [
      36.59782348174612,
      54.83092537817146
    ],
    [
      36.593916,
      54.824161
    ],
    [
      36.58962,
      54.823212
    ],
    [
      36.585382,
      54.82184
    ],
    [
      36.57676420213827,
      54.820687300922955
    ],
    [
      36.573875,
      54.820729
    ],
    [
      36.567557,
      54.82101
    ],
    [
      36.563856,
      54.821125
    ],
    [
      36.557572,
      54.821028
    ],
    [
      36.553889,
      54.820977
    ],
    [
      36.547758,
      54.820832
    ],
    [
      36.54448,
      54.819984
    ],
    [
      36.540624,
      54.818536
    ],
    [
      36.54062401524767,
      54.818535959411804
    ],
    [
      36.539298,
      54.818038
    ],
    [
      36.533474,
      54.815861
    ],
    [
      36.526747,
      54.811699
    ],
    [
      36.52648380284053,
      54.811194801512436
    ],
    [
      36.526177,
      54.81099
    ],
    [
      36.522409,
      54.808064
    ],
    [
      36.5183,
      54.80186
    ],
    [
      36.515346,
      54.7989
    ],
    [
      36.517895,
      54.796357
    ],
    [
      36.526066,
      54.797614
    ],
    [
      36.529734,
      54.804513
    ],
    [
      36.531532,
      54.807789
    ],
    [
      36.540564,
      54.814667
    ],
    [
      36.5443532612817,
      54.81596843891936
    ],
    [
      36.553972,
      54.817378
    ],
    [
      36.557622,
      54.817428
    ],
    [
      36.563911,
      54.817525
    ],
    [
      36.567444,
      54.817412
    ],
    [
      36.573715,
      54.817132
    ],
    [
      36.57372090337716,
      54.81713197812336
    ],
    [
      36.576115,
      54.817025
    ],
    [
      36.58001,
      54.816851
    ],
    [
      36.58262,
      54.817099
    ],
    [
      36.586577,
      54.818444
    ],
    [
      36.590547,
      54.819733
    ],
    [
      36.594691,
      54.820646
    ],
    [
      36.598598,
      54.821547
    ],
    [
      36.60151635280919,
      54.822193908220584
    ],
    [
      36.601517,
      54.822191
    ],
    [
      36.6053469594303,
      54.821893378639615
    ],
    [
      36.610961,
      54.81616
    ],
    [
      36.609892770343194,
      54.8107878740552
    ],
    [
      36.60989155133952,
      54.810783031036095
    ],
    [
      36.606623,
      54.803724
    ],
    [
      36.60662459938245,
      54.803038436135424
    ],
    [
      36.605828,
      54.801293
    ],
    [
      36.606637,
      54.797723
    ],
    [
      36.602007,
      54.787675
    ],
    [
      36.593803,
      54.785602
    ],
    [
      36.583589,
      54.784306
    ],
    [
      36.577167,
      54.785332
    ],
    [
      36.57712862929368,
      54.78533845410181
    ],
    [
      36.575867150094744,
      54.78555502528
    ],
    [
      36.573976,
      54.78588
    ],
    [
      36.57397412126633,
      54.785869054602536
    ],
    [
      36.573047,
      54.786025
    ],
    [
      36.56259,
      54.791092
    ],
    [
      36.559031,
      54.788347
    ],
    [
      36.562285,
      54.786882
    ],
    [
      36.565557818133165,
      54.78523133710471
    ],
    [
      36.567432,
      54.784034
    ],
    [
      36.572881,
      54.782429
    ],
    [
      36.57850722202123,
      54.779164945709034
    ],
    [
      36.5815,
      54.777321
    ],
    [
      36.582684,
      54.773922
    ],
    [
      36.583643,
      54.774255
    ],
    [
      36.58436699030586,
      54.77475207118147
    ],
    [
      36.58438,
      54.774739
    ],
    [
      36.587009,
      54.776566
    ],
    [
      36.594798,
      54.782142
    ],
    [
      36.602556,
      54.784117
    ],
    [
      36.606848,
      54.785046
    ],
    [
      36.610411,
      54.786299
    ],
    [
      36.621325,
      54.790439
    ],
    [
      36.623734627593905,
      54.79088294575573
    ],
    [
      36.623833,
      54.790889
    ],
    [
      36.628607,
      54.791178
    ],
    [
      36.629795966317225,
      54.79128807171029
    ],
    [
      36.631764,
      54.791276
    ],
    [
      36.635393,
      54.791732
    ],
    [
      36.639011,
      54.792302
    ],
    [
      36.647018,
      54.792977
    ],
    [
      36.653747,
      54.783719
    ],
    [
      36.655268,
      54.77949
    ],
    [
      36.651331,
      54.77152
    ],
    [
      36.646848,
      54.765905
    ],
    [
      36.64811972334839,
      54.7641871974555
    ],
    [
      36.647602,
      54.763005
    ],
    [
      36.64853647796372,
      54.756376665982714
    ],
    [
      36.647525,
      54.753736
    ],
    [
      36.639408,
      54.746712
    ],
    [
      36.636695,
      54.746777
    ],
    [
      36.636103,
      54.746742
    ],
    [
      36.635712,
      54.745348
    ],
    [
      36.63360467093836,
      54.737173224934004
    ],
    [
      36.63299057255913,
      54.73668559806182
    ],
    [
      36.622788,
      54.734529
    ],
    [
      36.620725,
      54.735389
    ],
    [
      36.617858,
      54.73652
    ],
    [
      36.61785632855391,
      54.73651576701853
    ],
    [
      36.617471,
      54.736671
    ],
    [
      36.616809,
      54.736896
    ],
    [
      36.615653,
      54.733486
    ],
    [
      36.616315,
      54.733262
    ],
    [
      36.6165362371918,
      54.73317260069451
    ],
    [
      36.616536,
      54.733172
    ],
    [
      36.616612438169625,
      54.73314180878995
    ],
    [
      36.619424237366815,
      54.732005594334474
    ],
    [
      36.62224019750049,
      54.72764873934902
    ],
    [
      36.623676,
      54.721548
    ],
    [
      36.62368469518686,
      54.72153448159701
    ],
    [
      36.614396,
      54.715198
    ],
    [
      36.609818,
      54.714087
    ],
    [
      36.604773,
      54.714081
    ],
    [
      36.601329,
      54.713426
    ],
    [
      36.590728,
      54.715812
    ],
    [
      36.587701,
      54.717972
    ],
    [
      36.587194,
      54.718334
    ],
    [
      36.58713350702519,
      54.71837198958818
    ],
    [
      36.584496,
      54.720231
    ],
    [
      36.579727,
      54.72079
    ],
    [
      36.57686,
      54.717948
    ],
    [
      36.576639,
      54.713343
    ],
    [
      36.571028,
      54.704808
    ],
    [
      36.568263,
      54.704463
    ],
    [
      36.566634,
      54.704109
    ],
    [
      36.56704002293223,
      54.70223693096252
    ],
    [
      36.56523,
      54.70107
    ],
    [
      36.562343,
      54.696325
    ],
    [
      36.562316,
      54.696259
    ],
    [
      36.564965,
      54.694029
    ],
    [
      36.565284,
      54.694063
    ],
    [
      36.573292,
      54.696849
    ],
    [
      36.57791925502386,
      54.701995171479645
    ],
    [
      36.580099,
      54.702293
    ],
    [
      36.5801824390544,
      54.70234894119794
    ],
    [
      36.580201,
      54.702309
    ],
    [
      36.581297,
      54.70249
    ],
    [
      36.583276,
      54.704423
    ],
    [
      36.592538,
      54.710295
    ],
    [
      36.595961,
      54.708384
    ],
    [
      36.60138781763987,
      54.70735111216199
    ],
    [
      36.60691708822577,
      54.705346970255036
    ],
    [
      36.609908,
      54.699208
    ],
    [
      36.613335,
      54.697128
    ],
    [
      36.616843,
      54.696905
    ],
    [
      36.627244598888915,
      54.69661396254546
    ],
    [
      36.62911978411745,
      54.696218689959544
    ],
    [
      36.630024,
      54.696025
    ],
    [
      36.63002463393422,
      54.69602795556088
    ],
    [
      36.630162,
      54.695999
    ],
    [
      36.631441,
      54.695873
    ],
    [
      36.632211,
      54.695895
    ],
    [
      36.632109,
      54.699494
    ],
    [
      36.628034,
      54.700133
    ],
    [
      36.62383,
      54.701006
    ],
    [
      36.619735,
      54.701327
    ],
    [
      36.619905,
      54.7132
    ],
    [
      36.624148,
      54.716253
    ],
    [
      36.629739,
      54.720682
    ],
    [
      36.632671,
      54.723602
    ],
    [
      36.643129,
      54.725367
    ],
    [
      36.651154,
      54.71663
    ],
    [
      36.65144845636185,
      54.71654022671895
    ],
    [
      36.649985,
      54.710494
    ],
    [
      36.651225,
      54.707589
    ],
    [
      36.651506,
      54.70764
    ],
    [
      36.652393,
      54.707817
    ],
    [
      36.652848,
      54.708146
    ],
    [
      36.65333346247341,
      54.70829180893045
    ],
    [
      36.654707,
      54.706985
    ],
    [
      36.65493322719807,
      54.70695165757349
    ],
    [
      36.656421,
      54.705625
    ],
    [
      36.663677,
      54.696228
    ],
    [
      36.667235,
      54.691251
    ],
    [
      36.669993,
      54.688346
    ],
    [
      36.671484,
      54.687701
    ],
    [
      36.672189,
      54.687552
    ],
    [
      36.67237712263048,
      54.68844640759402
    ],
    [
      36.67561,
      54.688754
    ],
    [
      36.675568,
      54.689202
    ],
    [
      36.67288211367317,
      54.690847329919805
    ],
    [
      36.67293,
      54.691075
    ],
    [
      36.667479,
      54.699177
    ],
    [
      36.665477,
      54.703465
    ],
    [
      36.666464,
      54.711551
    ],
    [
      36.675791,
      54.704372
    ],
    [
      36.675248,
      54.703209
    ],
    [
      36.67851,
      54.701687
    ],
    [
      36.679053,
      54.702849
    ],
    [
      36.682425,
      54.706161
    ],
    [
      36.692072,
      54.700761
    ],
    [
      36.689909,
      54.696179
    ],
    [
      36.68776,
      54.693058
    ],
    [
      36.684979,
      54.690594
    ],
    [
      36.680661,
      54.68862
    ],
    [
      36.677067,
      54.687743
    ],
    [
      36.670203,
      54.68554
    ],
    [
      36.663707,
      54.68418
    ],
    [
      36.66301055737705,
      54.68415157377049
    ],
    [
      36.662484,
      54.684166
    ],
    [
      36.66243653733739,
      54.6823679144976
    ],
    [
      36.660687,
      54.681921
    ],
    [
      36.662281,
      54.673911
    ],
    [
      36.661723,
      54.664101
    ],
    [
      36.654034,
      54.657264
    ],
    [
      36.653835,
      54.65367
    ],
    [
      36.656740255073245,
      54.64950146115866
    ],
    [
      36.656516049234895,
      54.64241955516402
    ],
    [
      36.655242,
      54.639957
    ],
    [
      36.65524209439104,
      54.63995695118112
    ],
    [
      36.654989,
      54.639468
    ],
    [
      36.658187,
      54.637814
    ],
    [
      36.658439963629014,
      54.63830301881101
    ],
    [
      36.65844,
      54.638303
    ],
    [
      36.65984,
      54.641009
    ],
    [
      36.661239,
      54.643714
    ],
    [
      36.661697,
      54.646471
    ],
    [
      36.66255,
      54.658309
    ],
    [
      36.664731,
      54.662123
    ],
    [
      36.666998,
      54.665768
    ],
    [
      36.673825,
      54.670658
    ],
    [
      36.67701360606155,
      54.671697156814155
    ],
    [
      36.685564,
      54.672665
    ],
    [
      36.688647,
      54.670882
    ],
    [
      36.68894620718778,
      54.67139955205567
    ],
    [
      36.692668,
      54.670759
    ],
    [
      36.700113,
      54.668277
    ],
    [
      36.708141,
      54.668469
    ],
    [
      36.713884,
      54.666847
    ],
    [
      36.71777,
      54.665393
    ],
    [
      36.722409,
      54.663689
    ],
    [
      36.728139,
      54.662482
    ],
    [
      36.736308,
      54.657699
    ],
    [
      36.739172,
      54.65988
    ],
    [
      36.738724,
      54.660469
    ],
    [
      36.731447,
      54.665577
    ],
    [
      36.725462,
      54.666155
    ],
    [
      36.72354672472141,
      54.66704810465362
    ],
    [
      36.723574,
      54.66711
    ],
    [
      36.720654,
      54.668397
    ],
    [
      36.716661,
      54.669253
    ],
    [
      36.712473,
      54.671043
    ],
    [
      36.709063,
      54.671949
    ],
    [
      36.705068,
      54.671995
    ],
    [
      36.700333,
      54.672666
    ],
    [
      36.697939,
      54.683881
    ],
    [
      36.701485,
      54.685442
    ],
    [
      36.712592,
      54.688623
    ],
    [
      36.71259557200128,
      54.68862353255292
    ],
    [
      36.712623,
      54.688626
    ],
    [
      36.714923,
      54.688833
    ],
    [
      36.71525211200727,
      54.68890394439596
    ],
    [
      36.715255,
      54.688891
    ],
    [
      36.717042,
      54.68929
    ],
    [
      36.71883,
      54.689688
    ],
    [
      36.72053,
      54.690356
    ],
    [
      36.725168,
      54.692956
    ],
    [
      36.734894,
      54.693029
    ],
    [
      36.74467,
      54.691356
    ],
    [
      36.75038,
      54.682238
    ],
    [
      36.76033,
      54.681194
    ],
    [
      36.762398,
      54.678153
    ],
    [
      36.766187,
      54.675058
    ],
    [
      36.769485,
      54.671902
    ],
    [
      36.777722,
      54.667889
    ],
    [
      36.781792,
      54.667439
    ],
    [
      36.77938,
      54.671085
    ],
    [
      36.7789638449677,
      54.671397665084555
    ],
    [
      36.778976,
      54.671414
    ],
    [
      36.776239,
      54.681517
    ],
    [
      36.783897500250276,
      54.68866607195418
    ],
    [
      36.792402,
      54.689739
    ],
    [
      36.799382,
      54.687185
    ],
    [
      36.80373847401007,
      54.68497686207102
    ],
    [
      36.809268,
      54.681196
    ],
    [
      36.814317748359905,
      54.68125344590355
    ],
    [
      36.815985,
      54.680922
    ],
    [
      36.819891,
      54.680308
    ],
    [
      36.823399,
      54.680068
    ],
    [
      36.827143,
      54.679271
    ],
    [
      36.827307170420504,
      54.67928987993854
    ],
    [
      36.827387,
      54.679263
    ],
    [
      36.835574,
      54.677848
    ],
    [
      36.840936,
      54.672105
    ],
    [
      36.843343,
      54.668953
    ],
    [
      36.846576,
      54.670537
    ],
    [
      36.857502,
      54.670302
    ],
    [
      36.858078,
      54.666587
    ],
    [
      36.859562,
      54.659752
    ],
    [
      36.859761,
      54.655129
    ],
    [
      36.86015887024689,
      54.65523976443161
    ],
    [
      36.860501,
      54.65211
    ],
    [
      36.860829,
      54.648277
    ],
    [
      36.860933,
      54.64709
    ],
    [
      36.864519,
      54.647406
    ],
    [
      36.864415,
      54.648593
    ],
    [
      36.8654919441435,
      54.649356981140464
    ],
    [
      36.875551,
      54.646557
    ],
    [
      36.877258,
      54.644692
    ],
    [
      36.879914,
      54.647122
    ],
    [
      36.879826,
      54.656945
    ],
    [
      36.88627,
      54.65818
    ],
    [
      36.892936,
      54.668024
    ],
    [
      36.894184,
      54.66767
    ],
    [
      36.8951218632952,
      54.67066488687947
    ],
    [
      36.895434,
      54.671042
    ],
    [
      36.898326,
      54.682049
    ],
    [
      36.901846,
      54.68473
    ],
    [
      36.901932,
      54.68435
    ],
    [
      36.905444,
      54.685141
    ],
    [
      36.911144,
      54.690283
    ],
    [
      36.914879,
      54.693623
    ],
    [
      36.915918,
      54.694862
    ],
    [
      36.915672,
      54.695681
    ],
    [
      36.915814158408274,
      54.702343430369886
    ],
    [
      36.924885737247145,
      54.70686892566009
    ],
    [
      36.92563955261649,
      54.70687497554267
    ],
    [
      36.928378516595025,
      54.70680091242057
    ],
    [
      36.937238,
      54.704563
    ],
    [
      36.9483,
      54.701658
    ],
    [
      36.9503125621916,
      54.701355424508975
    ],
    [
      36.951042,
      54.701067
    ],
    [
      36.954892,
      54.700613
    ],
    [
      36.956812,
      54.703787
    ],
    [
      36.952755,
      54.704629
    ],
    [
      36.949081,
      54.705148
    ],
    [
      36.945975,
      54.706779
    ],
    [
      36.945361006235096,
      54.70671928115385
    ],
    [
      36.946031,
      54.707476
    ],
    [
      36.946021,
      54.708254
    ],
    [
      36.939988,
      54.710714
    ],
    [
      36.937261,
      54.721525
    ],
    [
      36.948328,
      54.724317
    ],
    [
      36.953656,
      54.724449
    ],
    [
      36.958389,
      54.725565
    ],
    [
      36.961921,
      54.727213
    ],
    [
      36.96212893050501,
      54.72737570633915
    ],
    [
      36.96215,
      54.727343
    ],
    [
      36.973563,
      54.728342
    ],
    [
      36.97439510510225,
      54.73140421729366
    ],
    [
      36.97509885915621,
      54.73215535853912
    ],
    [
      36.984844,
      54.737743
    ],
    [
      36.994137,
      54.733554
    ],
    [
      36.998653,
      54.732924
    ],
    [
      37.00433674502752,
      54.73252842004015
    ],
    [
      37.010526,
      54.730816
    ],
    [
      37.019844,
      54.73036
    ],
    [
      37.023663,
      54.728744
    ],
    [
      37.027161,
      54.729738
    ],
    [
      37.027907,
      54.733743
    ],
    [
      37.029181,
      54.738552
    ],
    [
      37.034072,
      54.744829
    ],
    [
      37.037593,
      54.745933
    ],
    [
      37.047917,
      54.742375
    ],
    [
      37.041199,
      54.733963
    ],
    [
      37.04538227563457,
      54.731420943737675
    ],
    [
      37.048311,
      54.728082
    ],
    [
      37.04944463565786,
      54.72759059304907
    ],
    [
      37.049824,
      54.727109
    ],
    [
      37.042221,
      54.717994
    ],
    [
      37.040318610477335,
      54.71828364065934
    ],
    [
      37.039671,
      54.719864
    ],
    [
      37.039550182076994,
      54.719814555678774
    ],
    [
      37.03955,
      54.719815
    ],
    [
      37.037919,
      54.719147
    ],
    [
      37.036579,
      54.718853
    ],
    [
      37.032285,
      54.717443
    ],
    [
      37.028873,
      54.715301
    ],
    [
      37.02831,
      54.711366
    ],
    [
      37.028916,
      54.706772
    ],
    [
      37.028962,
      54.699494
    ],
    [
      37.033167,
      54.695577
    ],
    [
      37.039366,
      54.694596
    ],
    [
      37.04035244151255,
      54.69499406782758
    ],
    [
      37.04008,
      54.693475
    ],
    [
      37.04089,
      54.69333
    ],
    [
      37.04308,
      54.693198
    ],
    [
      37.043613,
      54.693756
    ],
    [
      37.052315,
      54.699413
    ],
    [
      37.05644,
      54.700088
    ],
    [
      37.059694,
      54.701515
    ],
    [
      37.064871,
      54.702551
    ],
    [
      37.068404,
      54.703817
    ],
    [
      37.077991,
      54.699974
    ],
    [
      37.079673947472756,
      54.69655704305718
    ],
    [
      37.08189668716008,
      54.688063261062446
    ],
    [
      37.08172056688195,
      54.687647259522485
    ],
    [
      37.07564,
      54.681639
    ],
    [
      37.065196,
      54.677636
    ],
    [
      37.06519544703292,
      54.67763559779551
    ],
    [
      37.060617,
      54.676328
    ],
    [
      37.06094971162854,
      54.67516249904213
    ],
    [
      37.053384,
      54.674261
    ],
    [
      37.048904225904366,
      54.67341095710235
    ],
    [
      37.04863038903128,
      54.6733712324389
    ],
    [
      37.042179,
      54.673028
    ],
    [
      37.035596,
      54.672677
    ],
    [
      37.031337,
      54.672153
    ],
    [
      37.026612,
      54.671006
    ],
    [
      37.021989,
      54.669847
    ],
    [
      37.016057,
      54.668528
    ],
    [
      37.010143,
      54.667214
    ],
    [
      37.003639,
      54.665836
    ],
    [
      37.000784,
      54.66527
    ],
    [
      36.99953463523319,
      54.66502187747013
    ],
    [
      36.997986,
      54.664715
    ],
    [
      36.997986129637546,
      54.66471434588599
    ],
    [
      36.997929,
      54.664703
    ],
    [
      36.99792985300875,
      54.664698697180135
    ],
    [
      36.997585,
      54.66463
    ],
    [
      36.994384,
      54.663419
    ],
    [
      36.98897786791818,
      54.66001618094014
    ],
    [
      36.983674,
      54.667282
    ],
    [
      36.980075,
      54.66719
    ],
    [
      36.980139,
      54.664715
    ],
    [
      36.980202,
      54.66224
    ],
    [
      36.98021,
      54.659539
    ],
    [
      36.97587026501261,
      54.649539181779595
    ],
    [
      36.970903,
      54.645107
    ],
    [
      36.964611,
      54.639052
    ],
    [
      36.960631,
      54.636655
    ],
    [
      36.949559,
      54.641171
    ],
    [
      36.942414,
      54.640739
    ],
    [
      36.937592,
      54.642707
    ],
    [
      36.933446,
      54.645417
    ],
    [
      36.93311859190248,
      54.64500959107687
    ],
    [
      36.930766,
      54.645699
    ],
    [
      36.925434,
      54.644867
    ],
    [
      36.91488,
      54.645601
    ],
    [
      36.913019,
      54.646563
    ],
    [
      36.911159,
      54.647524
    ],
    [
      36.911145939149804,
      54.6474987237388
    ],
    [
      36.910622,
      54.647765
    ],
    [
      36.905587,
      54.651225
    ],
    [
      36.908031,
      54.645143
    ],
    [
      36.90951840617837,
      54.644349009294984
    ],
    [
      36.909506,
      54.644325
    ],
    [
      36.903485,
      54.637477
    ],
    [
      36.90048,
      54.635495
    ],
    [
      36.904885,
      54.625094
    ],
    [
      36.908672,
      54.622254
    ],
    [
      36.90912609235617,
      54.622249832485714
    ],
    [
      36.909126,
      54.622234
    ],
    [
      36.91412,
      54.622204
    ],
    [
      36.920985,
      54.622122
    ],
    [
      36.925347,
      54.622122
    ],
    [
      36.925542056348036,
      54.6221157946601
    ],
    [
      36.925542,
      54.622114
    ],
    [
      36.92889901754819,
      54.622008912431724
    ],
    [
      36.93113495986591,
      54.61085120027556
    ],
    [
      36.927811,
      54.609773
    ],
    [
      36.92781138328689,
      54.6097718176808
    ],
    [
      36.927621,
      54.60971
    ],
    [
      36.924353,
      54.608346
    ],
    [
      36.919674,
      54.606015
    ],
    [
      36.915876,
      54.603361
    ],
    [
      36.914059,
      54.601347
    ],
    [
      36.911851,
      54.598135
    ],
    [
      36.91144940948209,
      54.59755053749727
    ],
    [
      36.911449,
      54.59755
    ],
    [
      36.91137703928244,
      54.59744521213195
    ],
    [
      36.91025429952864,
      54.595811211185314
    ],
    [
      36.909527,
      54.594753
    ],
    [
      36.90952783078696,
      54.59475242886897
    ],
    [
      36.908893,
      54.593828
    ],
    [
      36.906217,
      54.590436
    ],
    [
      36.897919,
      54.586842
    ],
    [
      36.89266,
      54.58563
    ],
    [
      36.8874,
      54.584417
    ],
    [
      36.887400095845166,
      54.584416584394496
    ],
    [
      36.885125,
      54.583892
    ],
    [
      36.873303,
      54.58332
    ],
    [
      36.87330269020662,
      54.58331990325719
    ],
    [
      36.873036,
      54.584173
    ],
    [
      36.87241420501386,
      54.58397864382564
    ],
    [
      36.872485,
      54.58597
    ],
    [
      36.869994,
      54.588825
    ],
    [
      36.865361,
      54.591003
    ],
    [
      36.85912,
      54.592343
    ],
    [
      36.859834,
      54.603008
    ],
    [
      36.86328,
      54.606063
    ],
    [
      36.862361,
      54.612265
    ],
    [
      36.861065,
      54.613563
    ],
    [
      36.86088331923655,
      54.6133814618991
    ],
    [
      36.858262,
      54.615362
    ],
    [
      36.857222,
      54.623295
    ],
    [
      36.860528,
      54.629425
    ],
    [
      36.85795,
      54.631937
    ],
    [
      36.855573,
      54.629498
    ],
    [
      36.851006,
      54.621571
    ],
    [
      36.850831,
      54.616556
    ],
    [
      36.841357,
      54.610316
    ],
    [
      36.839183,
      54.611508
    ],
    [
      36.83608,
      54.61272
    ],
    [
      36.836071153354574,
      54.61269735663961
    ],
    [
      36.83583,
      54.612806
    ],
    [
      36.829105,
      54.615757
    ],
    [
      36.823907,
      54.62429
    ],
    [
      36.821869,
      54.627574
    ],
    [
      36.818642,
      54.631475
    ],
    [
      36.814729,
      54.635918
    ],
    [
      36.812347,
      54.637419
    ],
    [
      36.8109882093903,
      54.63827502096369
    ],
    [
      36.809863,
      54.638984
    ],
    [
      36.809804210263614,
      54.638890683930676
    ],
    [
      36.804885,
      54.64186
    ],
    [
      36.799745,
      54.638971
    ],
    [
      36.789217,
      54.637947
    ],
    [
      36.784458,
      54.634978
    ],
    [
      36.780785,
      54.632517
    ],
    [
      36.772988,
      54.626855
    ],
    [
      36.768541,
      54.625472
    ],
    [
      36.764478,
      54.625455
    ],
    [
      36.758784,
      54.634021
    ],
    [
      36.754329,
      54.632679
    ],
    [
      36.743758,
      54.628553
    ],
    [
      36.73923,
      54.629331
    ],
    [
      36.735576,
      54.630089
    ],
    [
      36.730229,
      54.629492
    ],
    [
      36.726757,
      54.628885
    ],
    [
      36.716457,
      54.624539
    ],
    [
      36.711952,
      54.623801
    ],
    [
      36.711456,
      54.62368
    ],
    [
      36.712308,
      54.620182
    ],
    [
      36.712493,
      54.620061
    ],
    [
      36.714338,
      54.620448
    ],
    [
      36.719367,
      54.621398
    ],
    [
      36.72278,
      54.622203
    ],
    [
      36.726631,
      54.623538
    ],
    [
      36.734845,
      54.626564
    ],
    [
      36.7385,
      54.625806
    ],
    [
      36.743147,
      54.625005
    ],
    [
      36.74821,
      54.62515
    ],
    [
      36.755728,
      54.621986
    ],
    [
      36.760756,
      54.62187
    ],
    [
      36.764492,
      54.621855
    ],
    [
      36.768556,
      54.621872
    ],
    [
      36.773423,
      54.622491
    ],
    [
      36.77424308286866,
      54.62302534961228
    ],
    [
      36.774264,
      54.623005
    ],
    [
      36.778328,
      54.625687
    ],
    [
      36.782464,
      54.627691
    ],
    [
      36.789098,
      54.633434
    ],
    [
      36.794336,
      54.634163
    ],
    [
      36.799409,
      54.634643
    ],
    [
      36.802706,
      54.636924
    ],
    [
      36.81018113169437,
      54.633926253204116
    ],
    [
      36.815438,
      54.629199
    ],
    [
      36.816332293104914,
      54.627660221162415
    ],
    [
      36.816277,
      54.627595
    ],
    [
      36.820611,
      54.620298
    ],
    [
      36.823302,
      54.615185
    ],
    [
      36.827217,
      54.612692
    ],
    [
      36.830746,
      54.610518
    ],
    [
      36.83477913134312,
      54.60936262534946
    ],
    [
      36.838164,
      54.607741
    ],
    [
      36.828505,
      54.596714
    ],
    [
      36.82295,
      54.598692
    ],
    [
      36.820952,
      54.599119
    ],
    [
      36.818955,
      54.599547
    ],
    [
      36.818201,
      54.596026
    ],
    [
      36.820661,
      54.594389
    ],
    [
      36.823981,
      54.592996
    ],
    [
      36.828303,
      54.593119
    ],
    [
      36.83325,
      54.594592
    ],
    [
      36.839387,
      54.593439
    ],
    [
      36.845467,
      54.593329
    ],
    [
      36.85157,
      54.593219
    ],
    [
      36.860688,
      54.589055
    ],
    [
      36.860839,
      54.578107
    ],
    [
      36.856282,
      54.576406
    ],
    [
      36.852276,
      54.574813
    ],
    [
      36.846216,
      54.57448
    ],
    [
      36.841358,
      54.573387
    ],
    [
      36.837636,
      54.572637
    ],
    [
      36.833458,
      54.572483
    ],
    [
      36.827168,
      54.570531
    ],
    [
      36.820095,
      54.56983
    ],
    [
      36.815783,
      54.56734
    ],
    [
      36.81509005424593,
      54.56694062941211
    ],
    [
      36.815082,
      54.566939
    ],
    [
      36.812929,
      54.565697
    ],
    [
      36.81092235499085,
      54.56453903354393
    ],
    [
      36.803443,
      54.564928
    ],
    [
      36.799701,
      54.565036
    ],
    [
      36.792246,
      54.567408
    ],
    [
      36.791781,
      54.567567
    ],
    [
      36.788843,
      54.564059
    ],
    [
      36.787963,
      54.56308
    ],
    [
      36.78453701647664,
      54.554290606660686
    ],
    [
      36.779688,
      54.553312
    ],
    [
      36.771059151316734,
      54.55219850272208
    ],
    [
      36.77105,
      54.552199
    ],
    [
      36.769852594826524,
      54.5520442112101
    ],
    [
      36.765238,
      54.552976
    ],
    [
      36.760381,
      54.550782
    ],
    [
      36.76038100937182,
      54.550781931637495
    ],
    [
      36.760323,
      54.550774
    ],
    [
      36.755362,
      54.550094
    ],
    [
      36.743627,
      54.551163
    ],
    [
      36.740859,
      54.554502
    ],
    [
      36.740279,
      54.563941
    ],
    [
      36.746643,
      54.568227
    ],
    [
      36.740719,
      54.570309
    ],
    [
      36.735492,
      54.565792
    ],
    [
      36.726588,
      54.573303
    ],
    [
      36.726568,
      54.57684
    ],
    [
      36.716193,
      54.57708
    ],
    [
      36.713812,
      54.580305
    ],
    [
      36.711713,
      54.58397
    ],
    [
      36.706626,
      54.587691
    ],
    [
      36.703327,
      54.589469
    ],
    [
      36.698462,
      54.593021
    ],
    [
      36.695175,
      54.595571
    ],
    [
      36.692888,
      54.597428
    ],
    [
      36.6906,
      54.599285
    ],
    [
      36.688331,
      54.596489
    ],
    [
      36.690619,
      54.594633
    ],
    [
      36.692907,
      54.592776
    ],
    [
      36.696256,
      54.590177
    ],
    [
      36.701732,
      54.586241
    ],
    [
      36.704916,
      54.584522
    ],
    [
      36.708695,
      54.582007
    ],
    [
      36.706037,
      54.571404
    ],
    [
      36.699205,
      54.572
    ],
    [
      36.692361,
      54.572597
    ],
    [
      36.68711,
      54.573023
    ],
    [
      36.681838,
      54.573451
    ],
    [
      36.678086,
      54.573713
    ],
    [
      36.674367,
      54.573973
    ],
    [
      36.657977,
      54.575427
    ],
    [
      36.657658,
      54.571841
    ],
    [
      36.665854,
      54.571114
    ],
    [
      36.672596,
      54.561482
    ],
    [
      36.671781,
      54.558144
    ],
    [
      36.672909,
      54.554547
    ],
    [
      36.673389,
      54.547204
    ],
    [
      36.674949,
      54.543798
    ],
    [
      36.666998,
      54.534961
    ],
    [
      36.661412,
      54.536091
    ],
    [
      36.657589,
      54.53696
    ],
    [
      36.647959,
      54.540739
    ],
    [
      36.645011,
      54.539044
    ],
    [
      36.644946,
      54.538769
    ],
    [
      36.645978,
      54.535577
    ],
    [
      36.656522,
      54.533522
    ],
    [
      36.66061,
      54.532582
    ],
    [
      36.667636,
      54.531343
    ],
    [
      36.67146,
      54.531556
    ],
    [
      36.677596,
      54.532145
    ],
    [
      36.681669,
      54.533089
    ],
    [
      36.689456,
      54.534207
    ],
    [
      36.694697,
      54.533963
    ],
    [
      36.698224,
      54.533841
    ],
    [
      36.70514,
      54.532671
    ],
    [
      36.710791,
      54.532058
    ],
    [
      36.71939,
      54.538395
    ],
    [
      36.724668,
      54.5381
    ],
    [
      36.730638,
      54.531066
    ],
    [
      36.731232,
      54.531134
    ],
    [
      36.735208,
      54.532404
    ],
    [
      36.73687,
      54.538191
    ],
    [
      36.747682,
      54.536666
    ],
    [
      36.750036,
      54.533069
    ],
    [
      36.747459,
      54.521971
    ],
    [
      36.743078,
      54.521617
    ],
    [
      36.741967,
      54.517546
    ],
    [
      36.742709,
      54.513777
    ],
    [
      36.740493,
      54.504549
    ],
    [
      36.73081,
      54.49762
    ],
    [
      36.724727,
      54.495661
    ],
    [
      36.725831,
      54.492234
    ],
    [
      36.731914,
      54.494193
    ],
    [
      36.737997,
      54.496151
    ],
    [
      36.746094,
      54.487854
    ],
    [
      36.745695,
      54.486541
    ],
    [
      36.745668,
      54.485856
    ],
    [
      36.74741,
      54.484503
    ],
    [
      36.748151,
      54.484794
    ],
    [
      36.749265,
      54.485714
    ],
    [
      36.749292,
      54.486399
    ],
    [
      36.749459,
      54.489414
    ],
    [
      36.753527,
      54.498702
    ],
    [
      36.75761,
      54.502043
    ],
    [
      36.760264,
      54.504923
    ],
    [
      36.771412,
      54.508964
    ],
    [
      36.782231,
      54.506371
    ],
    [
      36.786277,
      54.502151
    ],
    [
      36.785756,
      54.490848
    ],
    [
      36.784259,
      54.489886
    ],
    [
      36.783094,
      54.489135
    ],
    [
      36.785045,
      54.48611
    ],
    [
      36.795515,
      54.485408
    ],
    [
      36.798561,
      54.487187
    ],
    [
      36.807403,
      54.482317
    ],
    [
      36.808157,
      54.480417
    ],
    [
      36.809377,
      54.479307
    ],
    [
      36.816414,
      54.477959
    ],
    [
      36.818931,
      54.480532
    ],
    [
      36.817056,
      54.482366
    ],
    [
      36.815182,
      54.4842
    ],
    [
      36.814304,
      54.485064
    ],
    [
      36.811307,
      54.489305
    ],
    [
      36.802885,
      54.491578
    ],
    [
      36.798358,
      54.494932
    ],
    [
      36.7946,
      54.496285
    ],
    [
      36.789403,
      54.503938
    ],
    [
      36.784569,
      54.509109
    ],
    [
      36.787387,
      54.518627
    ],
    [
      36.794996,
      54.51875
    ],
    [
      36.804232,
      54.514982
    ],
    [
      36.806647,
      54.517652
    ],
    [
      36.804005,
      54.520041
    ],
    [
      36.800612,
      54.530524
    ],
    [
      36.791916,
      54.536696
    ],
    [
      36.790779,
      54.540897
    ],
    [
      36.790748346855615,
      54.540966744304164
    ],
    [
      36.790795,
      54.541016
    ],
    [
      36.790668,
      54.541435
    ],
    [
      36.79016822277355,
      54.54247895007849
    ],
    [
      36.790454,
      54.542641
    ],
    [
      36.789896,
      54.543624
    ],
    [
      36.796772,
      54.553206
    ],
    [
      36.800177,
      54.554189
    ],
    [
      36.803384,
      54.555959
    ],
    [
      36.808575,
      54.559028
    ],
    [
      36.81257492439847,
      54.56133613095866
    ],
    [
      36.812575,
      54.561336
    ],
    [
      36.820987,
      54.561235
    ],
    [
      36.82952577192653,
      54.560419397795016
    ],
    [
      36.832525,
      54.554539
    ],
    [
      36.834376,
      54.550995
    ],
    [
      36.838212,
      54.547745
    ],
    [
      36.841332,
      54.536259
    ],
    [
      36.837925,
      54.534107
    ],
    [
      36.833636,
      54.526221
    ],
    [
      36.833061,
      54.525219
    ],
    [
      36.836182,
      54.523426
    ],
    [
      36.839736,
      54.527095
    ],
    [
      36.846452,
      54.533444
    ],
    [
      36.847207013053136,
      54.53509460796383
    ],
    [
      36.847472,
      54.535018
    ],
    [
      36.84798636958666,
      54.53679843559375
    ],
    [
      36.847988,
      54.536802
    ],
    [
      36.84798775150859,
      54.536803218969496
    ],
    [
      36.848003,
      54.536856
    ],
    [
      36.848535,
      54.538695
    ],
    [
      36.847114,
      54.546583
    ],
    [
      36.844139,
      54.548544
    ],
    [
      36.836932,
      54.554788
    ],
    [
      36.83576,
      54.558421
    ],
    [
      36.83766022035019,
      54.56554350424966
    ],
    [
      36.842131,
      54.569871
    ],
    [
      36.848893,
      54.570951
    ],
    [
      36.853311,
      54.571365
    ],
    [
      36.857607,
      54.573059
    ],
    [
      36.867466,
      54.576034
    ],
    [
      36.86804098098095,
      54.576175803764116
    ],
    [
      36.868955,
      54.576401
    ],
    [
      36.873167,
      54.57744
    ],
    [
      36.885934,
      54.580384
    ],
    [
      36.89278426620496,
      54.58196409465905
    ],
    [
      36.893469,
      54.582122
    ],
    [
      36.898728,
      54.583335
    ],
    [
      36.907938,
      54.582587
    ],
    [
      36.91358,
      54.572738
    ],
    [
      36.913756965472786,
      54.5726817795496
    ],
    [
      36.91387680855603,
      54.57189915982966
    ],
    [
      36.913789,
      54.570495
    ],
    [
      36.91406287198369,
      54.5706840967347
    ],
    [
      36.914335,
      54.568907
    ],
    [
      36.914795,
      54.568833
    ],
    [
      36.91512,
      54.568811
    ],
    [
      36.915597,
      54.568822
    ],
    [
      36.92660447367492,
      54.56840935914289
    ],
    [
      36.927799,
      54.567808
    ],
    [
      36.930868,
      54.565924
    ],
    [
      36.932593,
      54.554283
    ],
    [
      36.925306,
      54.546296
    ],
    [
      36.923011,
      54.545545
    ],
    [
      36.92306955730056,
      54.545366138816796
    ],
    [
      36.922313,
      54.545189
    ],
    [
      36.912451,
      54.542946
    ],
    [
      36.907651,
      54.54376
    ],
    [
      36.902829,
      54.541467
    ],
    [
      36.899698,
      54.539865
    ],
    [
      36.897986,
      54.537901
    ],
    [
      36.90052573363532,
      54.53207008733217
    ],
    [
      36.899804,
      54.531808
    ],
    [
      36.891865,
      54.530304
    ],
    [
      36.88508548252144,
      54.52353034546536
    ],
    [
      36.880598,
      54.52014
    ],
    [
      36.872473,
      54.525084
    ],
    [
      36.870978,
      54.52772
    ],
    [
      36.868723,
      54.526927
    ],
    [
      36.868362,
      54.523503
    ],
    [
      36.867887,
      54.518486
    ],
    [
      36.867537,
      54.50916
    ],
    [
      36.86322,
      54.506701
    ],
    [
      36.858383,
      54.506282
    ],
    [
      36.854373,
      54.506437
    ],
    [
      36.852695,
      54.506252
    ],
    [
      36.85038,
      54.505908
    ],
    [
      36.850380364376385,
      54.50590554717525
    ],
    [
      36.850226,
      54.505885
    ],
    [
      36.840375,
      54.505005
    ],
    [
      36.836381,
      54.505861
    ],
    [
      36.834969,
      54.50255
    ],
    [
      36.839593,
      54.501491
    ],
    [
      36.847374,
      54.499652
    ],
    [
      36.858125,
      54.494695
    ],
    [
      36.862978,
      54.49372
    ],
    [
      36.86796,
      54.49464
    ],
    [
      36.879531,
      54.495738
    ],
    [
      36.883915,
      54.495666
    ],
    [
      36.893588,
      54.493886
    ],
    [
      36.896324,
      54.491546
    ],
    [
      36.902581,
      54.493287
    ],
    [
      36.903091,
      54.496851
    ],
    [
      36.903204,
      54.497341
    ],
    [
      36.902373,
      54.497482
    ],
    [
      36.897547,
      54.498274
    ],
    [
      36.89134,
      54.501544
    ],
    [
      36.889394,
      54.503363
    ],
    [
      36.887823,
      54.504132
    ],
    [
      36.879638,
      54.499336
    ],
    [
      36.875798,
      54.500352
    ],
    [
      36.87320555868048,
      54.508834802395945
    ],
    [
      36.874049,
      54.509846
    ],
    [
      36.882752,
      54.517255
    ],
    [
      36.889626,
      54.522388
    ],
    [
      36.900165,
      54.527505
    ],
    [
      36.903244,
      54.529612
    ],
    [
      36.904089,
      54.529934
    ],
    [
      36.908503,
      54.533
    ],
    [
      36.912499,
      54.536216
    ],
    [
      36.923804,
      54.532267
    ],
    [
      36.925191,
      54.52942
    ],
    [
      36.926125,
      54.528011
    ],
    [
      36.926256500998186,
      54.528098272829126
    ],
    [
      36.928229,
      54.525237
    ],
    [
      36.929731,
      54.520931
    ],
    [
      36.930447,
      54.517436
    ],
    [
      36.940098,
      54.51177
    ],
    [
      36.945856,
      54.506632
    ],
    [
      36.947712,
      54.505383
    ],
    [
      36.948635,
      54.504931
    ],
    [
      36.9486909765965,
      54.50494727264223
    ],
    [
      36.94889,
      54.504829
    ],
    [
      36.957135,
      54.499422
    ],
    [
      36.950163,
      54.49231
    ],
    [
      36.943762,
      54.492944
    ],
    [
      36.93725,
      54.497653
    ],
    [
      36.934674,
      54.498225
    ],
    [
      36.933894,
      54.494711
    ],
    [
      36.934596,
      54.490182
    ],
    [
      36.935966,
      54.489495
    ],
    [
      36.943861,
      54.489345
    ],
    [
      36.95138,
      54.480566
    ],
    [
      36.946997,
      54.470059
    ],
    [
      36.947492,
      54.466493
    ],
    [
      36.948993,
      54.466626
    ],
    [
      36.952781,
      54.466292
    ],
    [
      36.959613,
      54.459407
    ],
    [
      36.96226,
      54.456966
    ],
    [
      36.967841,
      54.456928
    ],
    [
      36.978616,
      54.456037
    ],
    [
      36.981421,
      54.45588
    ],
    [
      36.987759,
      54.456267
    ],
    [
      36.995692,
      54.45937
    ],
    [
      37.000912,
      54.460214
    ],
    [
      37.004476,
      54.460821
    ],
    [
      37.011968,
      54.459923
    ],
    [
      37.016162,
      54.459133
    ],
    [
      37.019602,
      54.448495
    ],
    [
      37.023026,
      54.449607
    ],
    [
      37.025358,
      54.452377
    ],
    [
      37.035284,
      54.457301
    ],
    [
      37.039973,
      54.457555
    ],
    [
      37.0430759145976,
      54.4583963900706
    ],
    [
      37.043088,
      54.458356
    ],
    [
      37.04548417354513,
      54.458307185470225
    ],
    [
      37.051923,
      54.454147
    ],
    [
      37.053393,
      54.457433
    ],
    [
      37.059924,
      54.462996
    ],
    [
      37.063884,
      54.463416
    ],
    [
      37.068164,
      54.464073
    ],
    [
      37.073502,
      54.46516
    ],
    [
      37.077642,
      54.465051
    ],
    [
      37.087081,
      54.464658
    ],
    [
      37.089344,
      54.453786
    ],
    [
      37.085085,
      54.449144
    ],
    [
      37.08096874712825,
      54.44101251829087
    ],
    [
      37.076424,
      54.438822
    ],
    [
      37.073369,
      54.436958
    ],
    [
      37.069604,
      54.433525
    ],
    [
      37.060678,
      54.435709
    ],
    [
      37.055161,
      54.433999
    ],
    [
      37.051746,
      54.432906
    ],
    [
      37.042108,
      54.426424
    ],
    [
      37.039343,
      54.42382
    ],
    [
      37.039101,
      54.423151
    ],
    [
      37.042486,
      54.421927
    ],
    [
      37.043877,
      54.423288
    ],
    [
      37.049455,
      54.427989
    ],
    [
      37.059158,
      54.427465
    ],
    [
      37.063613,
      54.426169
    ],
    [
      37.071017,
      54.429736
    ],
    [
      37.073993,
      54.432319
    ],
    [
      37.080214,
      54.434947
    ],
    [
      37.091895,
      54.434036
    ],
    [
      37.096276,
      54.43069
    ],
    [
      37.100096,
      54.432297
    ],
    [
      37.110275,
      54.435778
    ],
    [
      37.113074,
      54.431916
    ],
    [
      37.115885,
      54.428037
    ],
    [
      37.118541,
      54.424465
    ],
    [
      37.113171,
      54.415573
    ],
    [
      37.1084,
      54.415143
    ],
    [
      37.104187,
      54.414926
    ],
    [
      37.098808,
      54.414438
    ],
    [
      37.095821,
      54.412804
    ],
    [
      37.093295,
      54.408828
    ],
    [
      37.096334,
      54.406898
    ],
    [
      37.104499,
      54.411339
    ],
    [
      37.109964,
      54.411651
    ],
    [
      37.113514,
      54.41199
    ],
    [
      37.117027,
      54.412326
    ],
    [
      37.120649,
      54.412905
    ],
    [
      37.129189,
      54.409872
    ],
    [
      37.132439,
      54.399602
    ],
    [
      37.132567,
      54.396678
    ],
    [
      37.136163,
      54.396836
    ],
    [
      37.13927,
      54.39975
    ],
    [
      37.141057,
      54.40291
    ],
    [
      37.142018,
      54.40638
    ],
    [
      37.145904,
      54.416545
    ],
    [
      37.156266,
      54.414954
    ],
    [
      37.160267,
      54.409482
    ],
    [
      37.161326,
      54.408329
    ],
    [
      37.162408,
      54.407459
    ],
    [
      37.173664,
      54.405871
    ],
    [
      37.177113,
      54.40484
    ],
    [
      37.185262,
      54.406662
    ],
    [
      37.193925,
      54.407107
    ],
    [
      37.197693,
      54.407504
    ],
    [
      37.207127,
      54.405275
    ],
    [
      37.208314,
      54.408673
    ],
    [
      37.219612,
      54.409071
    ],
    [
      37.22104,
      54.40571
    ],
    [
      37.223646,
      54.398361
    ],
    [
      37.222324,
      54.392267
    ],
    [
      37.222041,
      54.391742
    ],
    [
      37.224411,
      54.389031
    ],
    [
      37.224709,
      54.389292
    ],
    [
      37.225541,
      54.390652
    ],
    [
      37.23532,
      54.397283
    ],
    [
      37.240227,
      54.396237
    ],
    [
      37.24466,
      54.392967
    ],
    [
      37.247637,
      54.394991
    ],
    [
      37.246603,
      54.396513
    ],
    [
      37.245568,
      54.398035
    ],
    [
      37.246633,
      54.408127
    ],
    [
      37.255035,
      54.409582
    ],
    [
      37.263438,
      54.411038
    ],
    [
      37.26488011530012,
      54.41161574546439
    ],
    [
      37.264893,
      54.411584
    ],
    [
      37.26695399579133,
      54.4124202651397
    ],
    [
      37.27263924430239,
      54.414726675834714
    ],
    [
      37.278962,
      54.406337
    ],
    [
      37.280131,
      54.402932
    ],
    [
      37.280658915940606,
      54.40311324484365
    ],
    [
      37.280659,
      54.403113
    ],
    [
      37.28685095068539,
      54.40523848027872
    ],
    [
      37.294675,
      54.407517
    ],
    [
      37.29698,
      54.411393
    ],
    [
      37.29999695146657,
      54.41712842110756
    ],
    [
      37.300704,
      54.41321
    ],
    [
      37.308906,
      54.404878
    ],
    [
      37.309063820394066,
      54.40488801476477
    ],
    [
      37.309268,
      54.403166
    ],
    [
      37.31666,
      54.394013
    ],
    [
      37.311744,
      54.38649
    ],
    [
      37.305239,
      54.386284
    ],
    [
      37.297983,
      54.385736
    ],
    [
      37.294132,
      54.386299
    ],
    [
      37.289982,
      54.387428
    ],
    [
      37.28856,
      54.384121
    ],
    [
      37.292488,
      54.382765
    ],
    [
      37.296786,
      54.381762
    ],
    [
      37.302149,
      54.3825
    ],
    [
      37.308629,
      54.38279
    ],
    [
      37.31213105720255,
      54.38295361380266
    ],
    [
      37.312134,
      54.382909
    ],
    [
      37.31445404025234,
      54.38306207482077
    ],
    [
      37.314566651623764,
      54.383064846192475
    ],
    [
      37.32194944044287,
      54.37905698006217
    ],
    [
      37.324133,
      54.373818
    ],
    [
      37.326381,
      54.371029
    ],
    [
      37.331817,
      54.366118
    ],
    [
      37.335466,
      54.363305
    ],
    [
      37.340972,
      54.360367
    ],
    [
      37.346273,
      54.357557
    ],
    [
      37.351975,
      54.352253
    ],
    [
      37.353753,
      54.34214
    ],
    [
      37.355754,
      54.341999
    ],
    [
      37.357382,
      54.342371
    ],
    [
      37.36684,
      54.344978
    ],
    [
      37.369453,
      54.338671
    ],
    [
      37.370801,
      54.335487
    ],
    [
      37.371399,
      54.334512
    ],
    [
      37.382792,
      54.331852
    ],
    [
      37.385971,
      54.330175
    ],
    [
      37.393232,
      54.326241
    ],
    [
      37.392466,
      54.315457
    ],
    [
      37.38771,
      54.313136
    ],
    [
      37.383811,
      54.310692
    ],
    [
      37.373978,
      54.308449
    ],
    [
      37.370481,
      54.308988
    ],
    [
      37.366704,
      54.307087
    ],
    [
      37.364505,
      54.305419
    ],
    [
      37.366335,
      54.302318
    ],
    [
      37.369611,
      54.297489
    ],
    [
      37.372121,
      54.294909
    ],
    [
      37.37663,
      54.299296
    ],
    [
      37.385735,
      54.301569
    ],
    [
      37.3887,
      54.300262
    ],
    [
      37.398855,
      54.301537
    ],
    [
      37.401861,
      54.300819
    ],
    [
      37.408784,
      54.298727
    ],
    [
      37.419982,
      54.298921
    ],
    [
      37.428708,
      54.300781
    ],
    [
      37.437646,
      54.298444
    ],
    [
      37.439264,
      54.297948
    ],
    [
      37.440318,
      54.301391
    ],
    [
      37.438625,
      54.303492
    ],
    [
      37.436965,
      54.306686
    ],
    [
      37.435295,
      54.305818
    ],
    [
      37.427651,
      54.305764
    ],
    [
      37.423318,
      54.308774
    ],
    [
      37.429222,
      54.318594
    ],
    [
      37.432786,
      54.319013
    ],
    [
      37.440055,
      54.320239
    ],
    [
      37.443692,
      54.318672
    ],
    [
      37.445718,
      54.318463
    ],
    [
      37.44712,
      54.31832
    ],
    [
      37.447486,
      54.321901
    ],
    [
      37.441419,
      54.323571
    ],
    [
      37.436964,
      54.326138
    ],
    [
      37.433977,
      54.326855
    ],
    [
      37.429556,
      54.33722
    ],
    [
      37.430378,
      54.339104
    ],
    [
      37.431010482833045,
      54.34055286274285
    ],
    [
      37.435428,
      54.347558
    ],
    [
      37.44232381437752,
      54.353743451580876
    ],
    [
      37.442469820915875,
      54.35383593822865
    ],
    [
      37.44970753255827,
      54.35833622222196
    ],
    [
      37.449874366217216,
      54.358439078702084
    ],
    [
      37.454133,
      54.361064
    ],
    [
      37.45788,
      54.363394
    ],
    [
      37.461298,
      54.365477
    ],
    [
      37.466535,
      54.36871
    ],
    [
      37.473992,
      54.373388
    ],
    [
      37.477215,
      54.37572
    ],
    [
      37.479907,
      54.377964
    ],
    [
      37.48059884047755,
      54.37864525199813
    ],
    [
      37.480616,
      54.378627
    ],
    [
      37.490672,
      54.382741
    ],
    [
      37.491402,
      54.38368
    ],
    [
      37.492572,
      54.385799
    ],
    [
      37.495551,
      54.390106
    ],
    [
      37.507383,
      54.390444
    ],
    [
      37.512559,
      54.383268
    ],
    [
      37.504838,
      54.375292
    ],
    [
      37.503007,
      54.374161
    ],
    [
      37.501458,
      54.372869
    ],
    [
      37.503763,
      54.370104
    ],
    [
      37.507217362239885,
      54.36275999949764
    ],
    [
      37.505001,
      54.359138
    ],
    [
      37.504748,
      54.359022
    ],
    [
      37.506241,
      54.355746
    ],
    [
      37.50655730404485,
      54.35583073913301
    ],
    [
      37.506696,
      54.355536
    ],
    [
      37.511952,
      54.357276
    ],
    [
      37.51195237616782,
      54.35727612970601
    ],
    [
      37.512691044531344,
      54.357471209350926
    ],
    [
      37.517696,
      54.358792
    ],
    [
      37.529298,
      54.35999
    ],
    [
      37.532311,
      54.353779
    ],
    [
      37.532878,
      54.350118
    ],
    [
      37.529759,
      54.339094
    ],
    [
      37.525591,
      54.337117
    ],
    [
      37.520447,
      54.335239
    ],
    [
      37.510628,
      54.334202
    ],
    [
      37.503277,
      54.336732
    ],
    [
      37.499816,
      54.337923
    ],
    [
      37.49445,
      54.338628
    ],
    [
      37.498652,
      54.334488
    ],
    [
      37.502043,
      54.33335
    ],
    [
      37.498639,
      54.322154
    ],
    [
      37.495314,
      54.321046
    ],
    [
      37.491702,
      54.317104
    ],
    [
      37.483833,
      54.316283
    ],
    [
      37.479143,
      54.325084
    ],
    [
      37.478808,
      54.329432
    ],
    [
      37.476006,
      54.331692
    ],
    [
      37.474431,
      54.329739
    ],
    [
      37.474115,
      54.329297
    ],
    [
      37.47512,
      54.325345
    ],
    [
      37.475145,
      54.321651
    ],
    [
      37.47632,
      54.317315
    ],
    [
      37.474445,
      54.310001
    ],
    [
      37.465619,
      54.310121
    ],
    [
      37.465294,
      54.309535
    ],
    [
      37.464458,
      54.30709
    ],
    [
      37.463621,
      54.304646
    ],
    [
      37.463453,
      54.300137
    ],
    [
      37.463538,
      54.2998
    ],
    [
      37.464797,
      54.298685
    ],
    [
      37.465503,
      54.298806
    ],
    [
      37.467027,
      54.300687
    ],
    [
      37.473623,
      54.306496
    ],
    [
      37.48292,
      54.302679
    ],
    [
      37.483791,
      54.299186
    ],
    [
      37.490907,
      54.298422
    ],
    [
      37.49646,
      54.297546
    ],
    [
      37.504407,
      54.295592
    ],
    [
      37.515609,
      54.292935
    ],
    [
      37.516004,
      54.292798
    ],
    [
      37.520367,
      54.293019
    ],
    [
      37.525762,
      54.292766
    ],
    [
      37.532344,
      54.28728
    ],
    [
      37.535075,
      54.281352
    ],
    [
      37.538579,
      54.280522
    ],
    [
      37.539009,
      54.282342
    ],
    [
      37.53944,
      54.284162
    ],
    [
      37.549286,
      54.288747
    ],
    [
      37.551777,
      54.291346
    ],
    [
      37.562675,
      54.290754
    ],
    [
      37.564455,
      54.287997
    ],
    [
      37.565028,
      54.288059
    ],
    [
      37.565192,
      54.288181
    ],
    [
      37.565518,
      54.288499
    ],
    [
      37.565979,
      54.288972
    ],
    [
      37.568924,
      54.292303
    ],
    [
      37.578745,
      54.291794
    ],
    [
      37.581425,
      54.288212
    ],
    [
      37.585349,
      54.282806
    ],
    [
      37.588262,
      54.284921
    ],
    [
      37.5863,
      54.287624
    ],
    [
      37.584339,
      54.290327
    ],
    [
      37.58165,
      54.29392
    ],
    [
      37.584642,
      54.305055
    ],
    [
      37.588431,
      54.304323
    ],
    [
      37.594423,
      54.303066
    ],
    [
      37.597329,
      54.30104
    ],
    [
      37.602626,
      54.298021
    ],
    [
      37.604764,
      54.297045
    ],
    [
      37.607529,
      54.298986
    ],
    [
      37.608676,
      54.300385
    ],
    [
      37.609549,
      54.301966
    ],
    [
      37.608038,
      54.303928
    ],
    [
      37.60554,
      54.310259
    ],
    [
      37.609297,
      54.321028
    ],
    [
      37.615598,
      54.327352
    ],
    [
      37.615178,
      54.330979
    ],
    [
      37.612931,
      54.332005
    ],
    [
      37.611509,
      54.34379
    ],
    [
      37.61589,
      54.344481
    ],
    [
      37.626811,
      54.340944
    ],
    [
      37.62948,
      54.34202
    ],
    [
      37.63317,
      54.344467
    ],
    [
      37.635979,
      54.348746
    ],
    [
      37.638177,
      54.351597
    ],
    [
      37.64127,
      54.358471
    ],
    [
      37.648565,
      54.360606
    ],
    [
      37.64897,
      54.360536
    ],
    [
      37.652189,
      54.362662
    ],
    [
      37.662025,
      54.366263
    ],
    [
      37.662128,
      54.366653
    ],
    [
      37.662409,
      54.368008
    ],
    [
      37.665685,
      54.374146
    ],
    [
      37.675898,
      54.373251
    ],
    [
      37.678394,
      54.366953
    ],
    [
      37.680509,
      54.36404
    ],
    [
      37.683846,
      54.365318
    ],
    [
      37.693106,
      54.362116
    ],
    [
      37.70188,
      54.357731
    ],
    [
      37.704497,
      54.360204
    ],
    [
      37.706487,
      54.36142
    ],
    [
      37.717685,
      54.35816
    ],
    [
      37.718472,
      54.357913
    ],
    [
      37.718986,
      54.357855
    ],
    [
      37.719383,
      54.361433
    ],
    [
      37.726089,
      54.369568
    ],
    [
      37.731091,
      54.368918
    ],
    [
      37.736105,
      54.365856
    ],
    [
      37.73878,
      54.368266
    ],
    [
      37.736806,
      54.372366
    ],
    [
      37.736011,
      54.374369
    ],
    [
      37.738179,
      54.384501
    ],
    [
      37.74242,
      54.386109
    ],
    [
      37.742153,
      54.389699
    ],
    [
      37.733058,
      54.393786
    ],
    [
      37.729099,
      54.399798
    ],
    [
      37.723986,
      54.398627
    ],
    [
      37.720607,
      54.394254
    ],
    [
      37.717383,
      54.391623
    ],
    [
      37.713525,
      54.3891
    ],
    [
      37.709262,
      54.387965
    ],
    [
      37.699295,
      54.390264
    ],
    [
      37.701103,
      54.399291
    ],
    [
      37.70110300791334,
      54.39929153369951
    ],
    [
      37.701521,
      54.401284
    ],
    [
      37.701182,
      54.404619
    ],
    [
      37.708574,
      54.412957
    ],
    [
      37.7117,
      54.415419
    ],
    [
      37.712438,
      54.418878
    ],
    [
      37.711508,
      54.428422
    ],
    [
      37.714018674754655,
      54.43036675480785
    ],
    [
      37.72326223874609,
      54.433675702752325
    ],
    [
      37.724136,
      54.433926
    ],
    [
      37.72425079963909,
      54.43407941406315
    ],
    [
      37.72855,
      54.431657
    ],
    [
      37.733314,
      54.427288
    ],
    [
      37.73377163189141,
      54.427095581414555
    ],
    [
      37.737155,
      54.42414
    ],
    [
      37.742017,
      54.423336
    ],
    [
      37.74902587626604,
      54.424431924510685
    ],
    [
      37.750676,
      54.4243
    ],
    [
      37.751299,
      54.427845
    ],
    [
      37.760655,
      54.433354
    ],
    [
      37.770656,
      54.428283
    ],
    [
      37.773091,
      54.425623
    ],
    [
      37.781638,
      54.428024
    ],
    [
      37.786291,
      54.42645
    ],
    [
      37.788666,
      54.425692
    ],
    [
      37.790281,
      54.42508
    ],
    [
      37.790281018566816,
      54.425080048977975
    ],
    [
      37.790487,
      54.425002
    ],
    [
      37.79053087324535,
      54.4251177345955
    ],
    [
      37.791297,
      54.424877
    ],
    [
      37.799164,
      54.423121
    ],
    [
      37.802857,
      54.421596
    ],
    [
      37.806712,
      54.420693
    ],
    [
      37.811283,
      54.417663
    ],
    [
      37.817843,
      54.411507
    ],
    [
      37.815246,
      54.400324
    ],
    [
      37.812849,
      54.400466
    ],
    [
      37.811415,
      54.400759
    ],
    [
      37.810695,
      54.397232
    ],
    [
      37.812129,
      54.396939
    ],
    [
      37.822969,
      54.393863
    ],
    [
      37.825274,
      54.39317
    ],
    [
      37.826309,
      54.396618
    ],
    [
      37.827605,
      54.403811
    ],
    [
      37.829604,
      54.413016
    ],
    [
      37.841494,
      54.413179
    ],
    [
      37.84538,
      54.410956
    ],
    [
      37.847605,
      54.410992
    ],
    [
      37.855694,
      54.419745
    ],
    [
      37.855634,
      54.423345
    ],
    [
      37.849795,
      54.42487
    ],
    [
      37.846032,
      54.426536
    ],
    [
      37.841239,
      54.428991
    ],
    [
      37.836467,
      54.429261
    ],
    [
      37.83127603585096,
      54.43358559106867
    ],
    [
      37.832441,
      54.440463
    ],
    [
      37.832605055219716,
      54.44080347446918
    ],
    [
      37.832637,
      54.440784
    ],
    [
      37.834041,
      54.443087
    ],
    [
      37.845896,
      54.444149
    ],
    [
      37.847234,
      54.440807
    ],
    [
      37.858479,
      54.442978
    ],
    [
      37.867999,
      54.44722
    ],
    [
      37.871813,
      54.443185
    ],
    [
      37.874515,
      54.440328
    ],
    [
      37.878544,
      54.436197
    ],
    [
      37.879088,
      54.435677
    ],
    [
      37.880021,
      54.434809
    ],
    [
      37.88005262789527,
      54.43482976401989
    ],
    [
      37.880841,
      54.434047
    ],
    [
      37.8843039032684,
      54.428932619768716
    ],
    [
      37.88430499442235,
      54.4289307673016
    ],
    [
      37.884098,
      54.420295
    ],
    [
      37.884297,
      54.419181
    ],
    [
      37.887841,
      54.419817
    ],
    [
      37.898632,
      54.419292
    ],
    [
      37.900094,
      54.422582
    ],
    [
      37.911691,
      54.423932
    ],
    [
      37.921905,
      54.423176
    ],
    [
      37.925095,
      54.425557
    ],
    [
      37.924433,
      54.429485
    ],
    [
      37.923109,
      54.438922
    ],
    [
      37.92306858730715,
      54.43991677397782
    ],
    [
      37.923161,
      54.439923
    ],
    [
      37.922927,
      54.443402
    ],
    [
      37.929217,
      54.453256
    ],
    [
      37.934345,
      54.456084
    ],
    [
      37.945042,
      54.456494
    ],
    [
      37.948671,
      54.454962
    ],
    [
      37.95264,
      54.452878
    ],
    [
      37.960905,
      54.448355
    ],
    [
      37.965239,
      54.447482
    ],
    [
      37.971191,
      54.445754
    ],
    [
      37.972771,
      54.448988
    ],
    [
      37.970719,
      54.44999
    ],
    [
      37.96595,
      54.451011
    ],
    [
      37.958471,
      54.454364
    ],
    [
      37.953916,
      54.456244
    ],
    [
      37.950676,
      54.457952
    ],
    [
      37.946752,
      54.459662
    ],
    [
      37.94459133176412,
      54.460793924831925
    ],
    [
      37.944601,
      54.460813
    ],
    [
      37.94156,
      54.462353
    ],
    [
      37.939124,
      54.463178
    ],
    [
      37.933287283262366,
      54.47238328084653
    ],
    [
      37.933146,
      54.475494
    ],
    [
      37.931207,
      54.478821
    ],
    [
      37.926489,
      54.482411
    ],
    [
      37.921203,
      54.484268
    ],
    [
      37.916611443356025,
      54.492263368313516
    ],
    [
      37.923053,
      54.496101
    ],
    [
      37.929863,
      54.499505
    ],
    [
      37.93054831190737,
      54.50264333590917
    ],
    [
      37.937443,
      54.501394
    ],
    [
      37.93839,
      54.499928
    ],
    [
      37.941415,
      54.501881
    ],
    [
      37.94884,
      54.508698
    ],
    [
      37.952443,
      54.508742
    ],
    [
      37.962468,
      54.508352
    ],
    [
      37.96246933339356,
      54.508351918749014
    ],
    [
      37.964923,
      54.507989
    ],
    [
      37.96495423220631,
      54.508200500226025
    ],
    [
      37.969459,
      54.507926
    ],
    [
      37.979085,
      54.504431
    ],
    [
      37.988073,
      54.498486
    ],
    [
      37.996677,
      54.497516
    ],
    [
      38.002034,
      54.498517
    ],
    [
      38.003606,
      54.508628
    ],
    [
      38.002093,
      54.511881
    ],
    [
      38.00185107289555,
      54.512547155648996
    ],
    [
      38.001962,
      54.512582
    ],
    [
      38.001452,
      54.514204
    ],
    [
      38.000892,
      54.515188
    ],
    [
      37.99939996329618,
      54.51719585510714
    ],
    [
      37.999418,
      54.517213
    ],
    [
      37.998442,
      54.518485
    ],
    [
      37.995582,
      54.529926
    ],
    [
      37.997793,
      54.533133
    ],
    [
      38.000742,
      54.535487
    ],
    [
      37.997576,
      54.542662
    ],
    [
      37.99538976822891,
      54.543364277717004
    ],
    [
      37.99539,
      54.543365
    ],
    [
      37.992966,
      54.544143
    ],
    [
      37.990543,
      54.54492
    ],
    [
      37.99048044466816,
      54.54493376856708
    ],
    [
      37.990482,
      54.544939
    ],
    [
      37.989648,
      54.545187
    ],
    [
      37.987608,
      54.545566
    ],
    [
      37.978123,
      54.55147
    ],
    [
      37.974709,
      54.553753
    ],
    [
      37.97202,
      54.55616
    ],
    [
      37.973878,
      54.567003
    ],
    [
      37.97859362855127,
      54.569479684316455
    ],
    [
      37.985035,
      54.570969
    ],
    [
      37.988688,
      54.571648
    ],
    [
      37.99265774763946,
      54.57246965820181
    ],
    [
      37.992677,
      54.572393
    ],
    [
      37.99477,
      54.572919
    ],
    [
      37.996862,
      54.573446
    ],
    [
      37.999211,
      54.574056
    ],
    [
      38.004464892473266,
      54.57539247311831
    ],
    [
      38.004555,
      54.575415
    ],
    [
      38.007514,
      54.576156
    ],
    [
      38.011849,
      54.581078
    ],
    [
      38.02201,
      54.583826
    ],
    [
      38.023207,
      54.584212
    ],
    [
      38.03279430292271,
      54.58041387786124
    ],
    [
      38.033786,
      54.579513
    ],
    [
      38.044734,
      54.578689
    ],
    [
      38.052997,
      54.577382
    ],
    [
      38.058128,
      54.577553
    ],
    [
      38.059467669443805,
      54.57908870253207
    ],
    [
      38.059828,
      54.579049
    ],
    [
      38.071453,
      54.579133
    ],
    [
      38.076999,
      54.572667
    ],
    [
      38.080369,
      54.573574
    ],
    [
      38.085799,
      54.576616
    ],
    [
      38.093094,
      54.573689
    ],
    [
      38.092995,
      54.56277
    ],
    [
      38.089383,
      54.560897
    ],
    [
      38.08498,
      54.559341
    ],
    [
      38.078987,
      54.552947
    ],
    [
      38.07445283440046,
      54.54442045424959
    ],
    [
      38.0723705935067,
      54.54364532145229
    ],
    [
      38.067299,
      54.543234
    ],
    [
      38.065869,
      54.543829
    ],
    [
      38.060686,
      54.544205
    ],
    [
      38.06020867225931,
      54.54423962099354
    ],
    [
      38.060206,
      54.54424
    ],
    [
      38.0585729564858,
      54.54435826087217
    ],
    [
      38.055502,
      54.544581
    ],
    [
      38.055501974917604,
      54.5445806535736
    ],
    [
      38.054572,
      54.544648
    ],
    [
      38.048985,
      54.546167
    ],
    [
      38.047752,
      54.54624
    ],
    [
      38.045433,
      54.545956
    ],
    [
      38.043113,
      54.545672
    ],
    [
      38.04355,
      54.542099
    ],
    [
      38.04587,
      54.542383
    ],
    [
      38.048335,
      54.542627
    ],
    [
      38.054254,
      54.541062
    ],
    [
      38.059772,
      54.531157
    ],
    [
      38.058661,
      54.528029
    ],
    [
      38.058669,
      54.52793
    ],
    [
      38.061838,
      54.527021
    ],
    [
      38.062256,
      54.528227
    ],
    [
      38.063278,
      54.53034
    ],
    [
      38.064221,
      54.534607
    ],
    [
      38.071400539624406,
      54.53809995029445
    ],
    [
      38.073216,
      54.537926
    ],
    [
      38.074181,
      54.537984
    ],
    [
      38.079325059890124,
      54.53840118366388
    ],
    [
      38.08485233868366,
      54.533186757121626
    ],
    [
      38.085022,
      54.529876
    ],
    [
      38.084888,
      54.529506
    ],
    [
      38.085979,
      54.52762
    ],
    [
      38.086785,
      54.52726
    ],
    [
      38.08861020316769,
      54.52782670690459
    ],
    [
      38.08953,
      54.52713
    ],
    [
      38.089739050468246,
      54.52817720243888
    ],
    [
      38.092257,
      54.528959
    ],
    [
      38.092712,
      54.529277
    ],
    [
      38.090866,
      54.540119
    ],
    [
      38.0880528982017,
      54.54136845851324
    ],
    [
      38.087888,
      54.542663
    ],
    [
      38.08710695604022,
      54.54388047320974
    ],
    [
      38.088991,
      54.544516
    ],
    [
      38.092334,
      54.545645
    ],
    [
      38.095796,
      54.546797
    ],
    [
      38.099054,
      54.54877
    ],
    [
      38.102489,
      54.551279
    ],
    [
      38.105615,
      54.553562
    ],
    [
      38.11005762236354,
      54.55678814398774
    ],
    [
      38.11006,
      54.556787
    ],
    [
      38.121004,
      54.552897
    ],
    [
      38.12115442070697,
      54.552915565779955
    ],
    [
      38.121576,
      54.547933
    ],
    [
      38.122352920795116,
      54.54527504334968
    ],
    [
      38.12233,
      54.544622
    ],
    [
      38.122690705047624,
      54.544119435331524
    ],
    [
      38.122749,
      54.54392
    ],
    [
      38.125334,
      54.540216
    ],
    [
      38.126059788609986,
      54.539343413766645
    ],
    [
      38.127168,
      54.537741
    ],
    [
      38.128172498990565,
      54.53681622756095
    ],
    [
      38.13123979855212,
      54.533545301001
    ],
    [
      38.135464,
      54.525513
    ],
    [
      38.13664930835536,
      54.525331393247875
    ],
    [
      38.135065,
      54.522959
    ],
    [
      38.13707481544046,
      54.51921028468003
    ],
    [
      38.13871,
      54.516158
    ],
    [
      38.141883,
      54.517859
    ],
    [
      38.147848925033344,
      54.52557465169959
    ],
    [
      38.155845,
      54.522571
    ],
    [
      38.162407468751425,
      54.521017512925724
    ],
    [
      38.162943,
      54.520378
    ],
    [
      38.16394657244214,
      54.51904089606373
    ],
    [
      38.163642,
      54.516733
    ],
    [
      38.160955,
      54.50543
    ],
    [
      38.157224,
      54.505491
    ],
    [
      38.151459,
      54.501367
    ],
    [
      38.147131,
      54.498082
    ],
    [
      38.150818,
      54.489237
    ],
    [
      38.151307388335304,
      54.48390909345667
    ],
    [
      38.143516,
      54.484836
    ],
    [
      38.140046,
      54.486365
    ],
    [
      38.135951,
      54.487868
    ],
    [
      38.13267,
      54.489887
    ],
    [
      38.129167,
      54.49153
    ],
    [
      38.119542,
      54.491955
    ],
    [
      38.110095,
      54.493323
    ],
    [
      38.103976,
      54.496303
    ],
    [
      38.10323,
      54.496276
    ],
    [
      38.10279,
      54.496231
    ],
    [
      38.101759,
      54.496164
    ],
    [
      38.10141775277078,
      54.494031102402225
    ],
    [
      38.100447,
      54.493972
    ],
    [
      38.093011,
      54.492649
    ],
    [
      38.083583,
      54.497917
    ],
    [
      38.080748873321184,
      54.50308527450902
    ],
    [
      38.08007,
      54.51193
    ],
    [
      38.077054,
      54.509745
    ],
    [
      38.07703387634283,
      54.50970269588285
    ],
    [
      38.075273,
      54.510009
    ],
    [
      38.075112,
      54.509083
    ],
    [
      38.075469,
      54.506413
    ],
    [
      38.07630679911836,
      54.50276982221127
    ],
    [
      38.076196,
      54.502736
    ],
    [
      38.076677,
      54.50116
    ],
    [
      38.079223,
      54.497608
    ],
    [
      38.070702,
      54.489323
    ],
    [
      38.06649,
      54.489247
    ],
    [
      38.062466,
      54.486376
    ],
    [
      38.067827,
      54.48521
    ],
    [
      38.076414,
      54.48366
    ],
    [
      38.08114257096773,
      54.48362367923484
    ],
    [
      38.081091,
      54.483477
    ],
    [
      38.086664,
      54.47405
    ],
    [
      38.089674398459195,
      54.47333757627101
    ],
    [
      38.089861,
      54.472023
    ],
    [
      38.094823,
      54.471155
    ],
    [
      38.100487,
      54.470907
    ],
    [
      38.106582,
      54.470279
    ],
    [
      38.110994,
      54.469834
    ],
    [
      38.114664,
      54.467746
    ],
    [
      38.115691,
      54.456896
    ],
    [
      38.112749,
      54.455635
    ],
    [
      38.1127491572944,
      54.455634633459745
    ],
    [
      38.112048,
      54.455334
    ],
    [
      38.10845,
      54.453012
    ],
    [
      38.097034,
      54.452281
    ],
    [
      38.093547,
      54.453279
    ],
    [
      38.085894,
      54.45598
    ],
    [
      38.08563340789641,
      54.455931290346804
    ],
    [
      38.08429,
      54.456806
    ],
    [
      38.081193,
      54.458633
    ],
    [
      38.077735,
      54.457746
    ],
    [
      38.077019,
      54.457422
    ],
    [
      38.07711077542097,
      54.45441737217838
    ],
    [
      38.06967514689636,
      54.45638516855845
    ],
    [
      38.066589,
      54.461598
    ],
    [
      38.06623,
      54.462573
    ],
    [
      38.064274,
      54.465594
    ],
    [
      38.06329,
      54.464958
    ],
    [
      38.062502,
      54.462219
    ],
    [
      38.057585,
      54.453648
    ],
    [
      38.051733,
      54.447722
    ],
    [
      38.047889,
      54.446352
    ],
    [
      38.044534,
      54.443486
    ],
    [
      38.03764,
      54.437921
    ],
    [
      38.03383,
      54.437643
    ],
    [
      38.032004,
      54.437858
    ],
    [
      38.03194848395504,
      54.43738644328558
    ],
    [
      38.027567,
      54.436789
    ],
    [
      38.022134,
      54.445292
    ],
    [
      38.020085,
      54.448252
    ],
    [
      38.009645,
      54.4497
    ],
    [
      38.002726,
      54.453247
    ],
    [
      37.997689,
      54.455716
    ],
    [
      37.993511,
      54.462358
    ],
    [
      37.989915,
      54.462194
    ],
    [
      37.990318,
      54.457498
    ],
    [
      37.993401,
      54.455639
    ],
    [
      37.996105,
      54.452483
    ],
    [
      37.991356,
      54.443571
    ],
    [
      37.98716,
      54.442563
    ],
    [
      37.983694,
      54.442045
    ],
    [
      37.982639,
      54.441669
    ],
    [
      37.982814,
      54.441145
    ],
    [
      37.983905,
      54.437798
    ],
    [
      37.982097710904306,
      54.432013123096944
    ],
    [
      37.976213,
      54.427324
    ],
    [
      37.972062,
      54.426679
    ],
    [
      37.966386,
      54.425434
    ],
    [
      37.960627,
      54.424167
    ],
    [
      37.956788,
      54.42313
    ],
    [
      37.953471,
      54.422159
    ],
    [
      37.950155,
      54.421187
    ],
    [
      37.951166,
      54.417733
    ],
    [
      37.9578,
      54.419675
    ],
    [
      37.961566,
      54.420692
    ],
    [
      37.967158,
      54.421917
    ],
    [
      37.972834,
      54.423163
    ],
    [
      37.976486,
      54.423734
    ],
    [
      37.98067107020899,
      54.42375955334592
    ],
    [
      37.99083359254186,
      54.41951820219436
    ],
    [
      37.991668,
      54.418855
    ],
    [
      37.989726,
      54.411691
    ],
    [
      37.987154,
      54.409298
    ],
    [
      37.986352,
      54.40753
    ],
    [
      37.98542,
      54.405232
    ],
    [
      37.988757,
      54.40388
    ],
    [
      37.994136,
      54.410697
    ],
    [
      37.996733,
      54.413211
    ],
    [
      37.998502,
      54.417152
    ],
    [
      38.00353877284319,
      54.42051764240792
    ],
    [
      38.008943,
      54.413712
    ],
    [
      38.019387,
      54.410557
    ],
    [
      38.0214,
      54.409701
    ],
    [
      38.022404,
      54.409645
    ],
    [
      38.034323,
      54.40913
    ],
    [
      38.037859,
      54.40737
    ],
    [
      38.041724,
      54.404694
    ],
    [
      38.04778,
      54.39713
    ],
    [
      38.048984,
      54.391648
    ],
    [
      38.054415,
      54.383581
    ],
    [
      38.057433,
      54.376059
    ],
    [
      38.055248,
      54.371574
    ],
    [
      38.049895,
      54.365342
    ],
    [
      38.051334,
      54.362042
    ],
    [
      38.059955,
      54.361828
    ],
    [
      38.063013,
      54.363727
    ],
    [
      38.071469,
      54.368615
    ],
    [
      38.072245,
      54.368902
    ],
    [
      38.073068,
      54.369183
    ],
    [
      38.081,
      54.372472
    ],
    [
      38.085944,
      54.365465
    ],
    [
      38.089262,
      54.364067
    ],
    [
      38.090042,
      54.365921
    ],
    [
      38.099379,
      54.373252
    ],
    [
      38.106992,
      54.371154
    ],
    [
      38.112002,
      54.372331
    ],
    [
      38.116364,
      54.375883
    ],
    [
      38.120049,
      54.378631
    ],
    [
      38.12404,
      54.384281
    ],
    [
      38.12863946157569,
      54.38535724188209
    ],
    [
      38.132885640106075,
      54.383127872229004
    ],
    [
      38.135216,
      54.376935
    ],
    [
      38.13835355048127,
      54.37723147723622
    ],
    [
      38.139318,
      54.375892
    ],
    [
      38.141659,
      54.375314
    ],
    [
      38.145129365640905,
      54.37826028255006
    ],
    [
      38.145322,
      54.378299
    ],
    [
      38.153064,
      54.369188
    ],
    [
      38.152967,
      54.36152
    ],
    [
      38.15287,
      54.353838
    ],
    [
      38.152864,
      54.34926
    ],
    [
      38.15301301584186,
      54.348751165418044
    ],
    [
      38.15191993170742,
      54.34272673877744
    ],
    [
      38.144777,
      54.340147
    ],
    [
      38.14467,
      54.34012
    ],
    [
      38.14285549955056,
      54.33873893071112
    ],
    [
      38.142503,
      54.338716
    ],
    [
      38.138578,
      54.338694
    ],
    [
      38.134906,
      54.338111
    ],
    [
      38.124897,
      54.338585
    ],
    [
      38.120194,
      54.339076
    ],
    [
      38.11661,
      54.338621
    ],
    [
      38.113356,
      54.338778
    ],
    [
      38.112665,
      54.338815
    ],
    [
      38.11247,
      54.33522
    ],
    [
      38.113162,
      54.335183
    ],
    [
      38.116014,
      54.334994
    ],
    [
      38.124519,
      54.335005
    ],
    [
      38.12955,
      54.334096
    ],
    [
      38.133634,
      54.334199
    ],
    [
      38.138728,
      54.335097
    ],
    [
      38.142522,
      54.335116
    ],
    [
      38.15263,
      54.328975
    ],
    [
      38.152612,
      54.323168
    ],
    [
      38.152602,
      54.319234
    ],
    [
      38.152632,
      54.31455
    ],
    [
      38.152705,
      54.313026
    ],
    [
      38.1563,
      54.313197
    ],
    [
      38.156228,
      54.314721
    ],
    [
      38.156202,
      54.319256
    ],
    [
      38.156212,
      54.323158
    ],
    [
      38.15623,
      54.328962
    ],
    [
      38.163624,
      54.333522
    ],
    [
      38.167583,
      54.333223
    ],
    [
      38.171465,
      54.33377
    ],
    [
      38.183001,
      54.336667
    ],
    [
      38.189795,
      54.336375
    ],
    [
      38.18995,
      54.339972
    ],
    [
      38.180491,
      54.341664
    ],
    [
      38.17726,
      54.343252
    ],
    [
      38.168012,
      54.336835
    ],
    [
      38.164091,
      54.337092
    ],
    [
      38.158159,
      54.337756
    ],
    [
      38.15665,
      54.348959
    ],
    [
      38.156649,
      54.350584
    ],
    [
      38.156567,
      54.361475
    ],
    [
      38.15660363438228,
      54.364371004570216
    ],
    [
      38.156604,
      54.364371
    ],
    [
      38.156664,
      54.369143
    ],
    [
      38.157021,
      54.378415
    ],
    [
      38.156964,
      54.378765
    ],
    [
      38.156328,
      54.384765
    ],
    [
      38.162695,
      54.393273
    ],
    [
      38.168526,
      54.388485
    ],
    [
      38.17205,
      54.388274
    ],
    [
      38.175163,
      54.390152
    ],
    [
      38.179584,
      54.397386
    ],
    [
      38.191114,
      54.399672
    ],
    [
      38.202659,
      54.398793
    ],
    [
      38.2083476437935,
      54.39412720358478
    ],
    [
      38.207771,
      54.386421
    ],
    [
      38.21164,
      54.382803
    ],
    [
      38.214662,
      54.380804
    ],
    [
      38.21468,
      54.384978
    ],
    [
      38.21507858481754,
      54.39152066488919
    ],
    [
      38.215488,
      54.391741
    ],
    [
      38.21772292251411,
      54.39200203032846
    ],
    [
      38.226148,
      54.389466
    ],
    [
      38.229399,
      54.387452
    ],
    [
      38.232777,
      54.385451
    ],
    [
      38.236251,
      54.382992
    ],
    [
      38.242353,
      54.377628
    ],
    [
      38.24392492919961,
      54.36924388652766
    ],
    [
      38.241397,
      54.365004
    ],
    [
      38.240051,
      54.364109
    ],
    [
      38.23947,
      54.363806
    ],
    [
      38.241131,
      54.360612
    ],
    [
      38.24148192778777,
      54.36056829442289
    ],
    [
      38.240365,
      54.358458
    ],
    [
      38.240199,
      54.357265
    ],
    [
      38.240188,
      54.355119
    ],
    [
      38.242263,
      54.350008
    ],
    [
      38.24383,
      54.349792
    ],
    [
      38.245054,
      54.349722
    ],
    [
      38.254709,
      54.347085
    ],
    [
      38.257431,
      54.344694
    ],
    [
      38.257468,
      54.344686
    ],
    [
      38.260021,
      54.344323
    ],
    [
      38.26388,
      54.345097
    ],
    [
      38.272723,
      54.34662
    ],
    [
      38.274818,
      54.347673
    ],
    [
      38.275604,
      54.348165
    ],
    [
      38.276848,
      54.349619
    ],
    [
      38.277818,
      54.353086
    ],
    [
      38.278747,
      54.356408
    ],
    [
      38.277197,
      54.359658
    ],
    [
      38.275371,
      54.360068
    ],
    [
      38.270948,
      54.37114
    ],
    [
      38.280978,
      54.371863
    ],
    [
      38.28165,
      54.37161
    ],
    [
      38.283744,
      54.37329
    ],
    [
      38.284295,
      54.374052
    ],
    [
      38.282918,
      54.374979
    ],
    [
      38.281377,
      54.37616
    ],
    [
      38.278335,
      54.385807
    ],
    [
      38.280621,
      54.389065
    ],
    [
      38.277794,
      54.391277
    ],
    [
      38.272807,
      54.394012
    ],
    [
      38.269203,
      54.394704
    ],
    [
      38.259088,
      54.392819
    ],
    [
      38.256111,
      54.390294
    ],
    [
      38.2462,
      54.393133
    ],
    [
      38.245695,
      54.398671
    ],
    [
      38.251406,
      54.407024
    ],
    [
      38.257278,
      54.407944
    ],
    [
      38.261141,
      54.408327
    ],
    [
      38.267481,
      54.409675
    ],
    [
      38.2649920657349,
      54.41900934662903
    ],
    [
      38.270491,
      54.424712
    ],
    [
      38.276825,
      54.426318
    ],
    [
      38.284886,
      54.428735
    ],
    [
      38.288323,
      54.4296
    ],
    [
      38.28857741922784,
      54.429774705518966
    ],
    [
      38.288714,
      54.429778
    ],
    [
      38.289956,
      54.430097
    ],
    [
      38.28982001948749,
      54.430627978776144
    ],
    [
      38.294218,
      54.433648
    ],
    [
      38.305695,
      54.431775
    ],
    [
      38.310835,
      54.431361
    ],
    [
      38.317135,
      54.426585
    ],
    [
      38.311834,
      54.415355
    ],
    [
      38.314175,
      54.405737
    ],
    [
      38.315704,
      54.404283
    ],
    [
      38.316055,
      54.40411
    ],
    [
      38.318258,
      54.407508
    ],
    [
      38.320195,
      54.413639
    ],
    [
      38.319767,
      54.421952
    ],
    [
      38.32007168483276,
      54.422108524490255
    ],
    [
      38.32107,
      54.420758
    ],
    [
      38.324666,
      54.423416
    ],
    [
      38.329311,
      54.426855
    ],
    [
      38.336186,
      54.428505
    ],
    [
      38.34053081002953,
      54.42847082171696
    ],
    [
      38.347233,
      54.425521
    ],
    [
      38.34621,
      54.417729
    ],
    [
      38.346977,
      54.413919
    ],
    [
      38.347457,
      54.413486
    ],
    [
      38.355458,
      54.416797
    ],
    [
      38.356739,
      54.417303
    ],
    [
      38.355417,
      54.420651
    ],
    [
      38.353102,
      54.425324
    ],
    [
      38.355076,
      54.434153
    ],
    [
      38.359483,
      54.442192
    ],
    [
      38.363679,
      54.446237
    ],
    [
      38.364243682573395,
      54.44680831370821
    ],
    [
      38.364244,
      54.446808
    ],
    [
      38.366672,
      54.449265
    ],
    [
      38.3691,
      54.451723
    ],
    [
      38.374518,
      54.4567
    ],
    [
      38.382747,
      54.455713
    ],
    [
      38.386929,
      54.457078
    ],
    [
      38.394089,
      54.463594
    ],
    [
      38.398746,
      54.465199
    ],
    [
      38.402272,
      54.466725
    ],
    [
      38.409721,
      54.469987
    ],
    [
      38.420903,
      54.474328
    ],
    [
      38.425285,
      54.474751
    ],
    [
      38.435055,
      54.481061
    ],
    [
      38.438043,
      54.484574
    ],
    [
      38.445426,
      54.491309
    ],
    [
      38.449661,
      54.491562
    ],
    [
      38.45862,
      54.486562
    ],
    [
      38.45855,
      54.482683
    ],
    [
      38.465053,
      54.479564
    ],
    [
      38.469153,
      54.477976
    ],
    [
      38.4765340674094,
      54.47274512835008
    ],
    [
      38.479981,
      54.468964
    ],
    [
      38.479516,
      54.465394
    ],
    [
      38.480117,
      54.465316
    ],
    [
      38.48417601645541,
      54.465286861331975
    ],
    [
      38.487888,
      54.461799
    ],
    [
      38.485918,
      54.451952
    ],
    [
      38.481029,
      54.451218
    ],
    [
      38.476465,
      54.448871
    ],
    [
      38.472972,
      54.4476
    ],
    [
      38.472302,
      54.447142
    ],
    [
      38.471726,
      54.446562
    ],
    [
      38.47490960526233,
      54.441476442167826
    ],
    [
      38.474429,
      54.441338
    ],
    [
      38.470904,
      54.443078
    ],
    [
      38.462475,
      54.442018
    ],
    [
      38.457081,
      54.440585
    ],
    [
      38.452189,
      54.4395
    ],
    [
      38.44978,
      54.438561
    ],
    [
      38.450003,
      54.436639
    ],
    [
      38.450534,
      54.43504
    ],
    [
      38.452168,
      54.43539
    ],
    [
      38.453667,
      54.435989
    ],
    [
      38.45795,
      54.437092
    ],
    [
      38.463503,
      54.438568
    ],
    [
      38.472836,
      54.43811
    ],
    [
      38.477237,
      54.436286
    ],
    [
      38.472067,
      54.425617
    ],
    [
      38.46263,
      54.421762
    ],
    [
      38.459127,
      54.420136
    ],
    [
      38.457044,
      54.419021
    ],
    [
      38.458743,
      54.415847
    ],
    [
      38.460827,
      54.416963
    ],
    [
      38.46405,
      54.418453
    ],
    [
      38.473429,
      54.422285
    ],
    [
      38.482962,
      54.426181
    ],
    [
      38.48457098179072,
      54.42733970805845
    ],
    [
      38.484728,
      54.427122
    ],
    [
      38.485243,
      54.427494
    ],
    [
      38.485854,
      54.427989
    ],
    [
      38.488833,
      54.430409
    ],
    [
      38.491815,
      54.432832
    ],
    [
      38.494658,
      54.434936
    ],
    [
      38.49893,
      54.437956
    ],
    [
      38.505513,
      54.44239
    ],
    [
      38.512404,
      54.445819
    ],
    [
      38.51897,
      54.453568
    ],
    [
      38.525572,
      54.45077
    ],
    [
      38.52406,
      54.441932
    ],
    [
      38.523531,
      54.436654
    ],
    [
      38.520464,
      54.432552
    ],
    [
      38.518512,
      54.427739
    ],
    [
      38.513193,
      54.419676
    ],
    [
      38.510437,
      54.415805
    ],
    [
      38.509488,
      54.412332
    ],
    [
      38.51268,
      54.406283
    ],
    [
      38.516142,
      54.405296
    ],
    [
      38.528014,
      54.4044
    ],
    [
      38.529706,
      54.404954
    ],
    [
      38.531534,
      54.408055
    ],
    [
      38.52895,
      54.409579
    ],
    [
      38.526782,
      54.410404
    ],
    [
      38.520802,
      54.418075
    ],
    [
      38.52124681632029,
      54.420079678956974
    ],
    [
      38.521954,
      54.420073
    ],
    [
      38.521986,
      54.423411
    ],
    [
      38.522884,
      54.429458
    ],
    [
      38.524536,
      54.432568
    ],
    [
      38.527891,
      54.436073
    ],
    [
      38.536813,
      54.435056
    ],
    [
      38.54003,
      54.432736
    ],
    [
      38.543155,
      54.430047
    ],
    [
      38.543304,
      54.42995
    ],
    [
      38.546616,
      54.423407
    ],
    [
      38.547755,
      54.419992
    ],
    [
      38.551933,
      54.421386
    ],
    [
      38.556112,
      54.422779
    ],
    [
      38.554973,
      54.426194
    ],
    [
      38.551665447425776,
      54.42872477886362
    ],
    [
      38.551862,
      54.429055
    ],
    [
      38.554404,
      54.440235
    ],
    [
      38.56258,
      54.436651
    ],
    [
      38.566686,
      54.434684
    ],
    [
      38.570616,
      54.432518
    ],
    [
      38.576643,
      54.431869
    ],
    [
      38.581418,
      54.430478
    ],
    [
      38.584568,
      54.43222
    ],
    [
      38.58584,
      54.435761
    ],
    [
      38.58936,
      54.438704
    ],
    [
      38.598509,
      54.43132
    ],
    [
      38.602434,
      54.432071
    ],
    [
      38.606063,
      54.433671
    ],
    [
      38.615526,
      54.43243
    ],
    [
      38.617375,
      54.42187
    ],
    [
      38.619062,
      54.41869
    ],
    [
      38.621335,
      54.419896
    ],
    [
      38.631735,
      54.418917
    ],
    [
      38.633749,
      54.421901
    ],
    [
      38.634962,
      54.427707
    ],
    [
      38.635128,
      54.429655
    ],
    [
      38.637185,
      54.440826
    ],
    [
      38.640019,
      54.441869
    ],
    [
      38.640312,
      54.44261
    ],
    [
      38.639501,
      54.446536
    ],
    [
      38.645447,
      54.456676
    ],
    [
      38.645719,
      54.456816
    ],
    [
      38.646283,
      54.457212
    ],
    [
      38.65562,
      54.462655
    ],
    [
      38.662175,
      54.460916
    ],
    [
      38.663098,
      54.464396
    ],
    [
      38.659458,
      54.475618
    ],
    [
      38.660306,
      54.481748
    ],
    [
      38.659,
      54.486001
    ],
    [
      38.665752,
      54.493299
    ],
    [
      38.668149,
      54.493765
    ],
    [
      38.671719,
      54.493304
    ],
    [
      38.671756,
      54.493589
    ],
    [
      38.667748,
      54.499743
    ],
    [
      38.666327,
      54.503051
    ],
    [
      38.659197,
      54.506402
    ],
    [
      38.655687,
      54.505605
    ],
    [
      38.652296,
      54.500784
    ],
    [
      38.646384,
      54.496602
    ],
    [
      38.640904374730454,
      54.49848743721112
    ],
    [
      38.638173,
      54.506573
    ],
    [
      38.63863,
      54.50831
    ],
    [
      38.638775,
      54.512243
    ],
    [
      38.636441,
      54.522381
    ],
    [
      38.641336,
      54.525973
    ],
    [
      38.6413,
      54.529573
    ],
    [
      38.648566,
      54.537079
    ],
    [
      38.65206,
      54.538552
    ],
    [
      38.65963296257228,
      54.53935415211878
    ],
    [
      38.655301,
      54.529396
    ],
    [
      38.654779,
      54.528962
    ],
    [
      38.654489,
      54.528628
    ],
    [
      38.657205,
      54.526266
    ],
    [
      38.661583,
      54.527872
    ],
    [
      38.667367,
      54.530378
    ],
    [
      38.665883,
      54.534643
    ],
    [
      38.671465,
      54.543861
    ],
    [
      38.674984,
      54.544869
    ],
    [
      38.681472,
      54.547029
    ],
    [
      38.687474,
      54.542252
    ],
    [
      38.69042706864294,
      54.536169277190545
    ],
    [
      38.691682,
      54.529745
    ],
    [
      38.69224,
      54.526853
    ],
    [
      38.693016,
      54.522736
    ],
    [
      38.693792,
      54.518618
    ],
    [
      38.697329,
      54.519285
    ],
    [
      38.696553,
      54.523402
    ],
    [
      38.703033,
      54.531099
    ],
    [
      38.708144,
      54.531993
    ],
    [
      38.718784,
      54.533653
    ],
    [
      38.723551,
      54.541365
    ],
    [
      38.722054,
      54.544639
    ],
    [
      38.716344,
      54.544532
    ],
    [
      38.709171,
      54.541476
    ],
    [
      38.698309,
      54.543655
    ],
    [
      38.694746,
      54.54459
    ],
    [
      38.689196,
      54.545894
    ],
    [
      38.68375,
      54.555923
    ],
    [
      38.689438,
      54.560789
    ],
    [
      38.69084,
      54.56105
    ],
    [
      38.697536,
      54.570452
    ],
    [
      38.701693,
      54.569213
    ],
    [
      38.705038,
      54.567753
    ],
    [
      38.706465,
      54.566632
    ],
    [
      38.707966,
      54.565448
    ],
    [
      38.710195,
      54.568275
    ],
    [
      38.710404,
      54.569651
    ],
    [
      38.71041501028021,
      54.569693654102956
    ],
    [
      38.712,
      54.569781
    ],
    [
      38.715406,
      54.568306
    ],
    [
      38.724403,
      54.564154
    ],
    [
      38.726111,
      54.562464
    ],
    [
      38.727457,
      54.561022
    ],
    [
      38.730089,
      54.563478
    ],
    [
      38.728743,
      54.56492
    ],
    [
      38.725848,
      54.568085
    ],
    [
      38.723368,
      54.570746
    ],
    [
      38.720354,
      54.573198
    ],
    [
      38.712961,
      54.574667
    ],
    [
      38.71224705522685,
      54.57649374882727
    ],
    [
      38.712988,
      54.578113
    ],
    [
      38.71266,
      54.581698
    ],
    [
      38.712222,
      54.581658
    ],
    [
      38.706598,
      54.581997
    ],
    [
      38.70269,
      54.582625
    ],
    [
      38.696814,
      54.585813
    ],
    [
      38.691391,
      54.589809
    ],
    [
      38.681408,
      54.593436
    ],
    [
      38.67810796780324,
      54.60466130637006
    ],
    [
      38.67844680381092,
      54.605096071115014
    ],
    [
      38.67987026894753,
      54.60688403324656
    ],
    [
      38.690927,
      54.609333
    ],
    [
      38.693348774353154,
      54.60875939083602
    ],
    [
      38.69494,
      54.608382
    ],
    [
      38.69494011244767,
      54.60838247458335
    ],
    [
      38.695035,
      54.60836
    ],
    [
      38.702503,
      54.607515
    ],
    [
      38.709604,
      54.605079
    ],
    [
      38.713146,
      54.603835
    ],
    [
      38.721396,
      54.599226
    ],
    [
      38.723759,
      54.593826
    ],
    [
      38.723956,
      54.587817
    ],
    [
      38.725072,
      54.586657
    ],
    [
      38.7271,
      54.586062
    ],
    [
      38.731194743076124,
      54.587084209654
    ],
    [
      38.735585,
      54.581337
    ],
    [
      38.734699,
      54.577182
    ],
    [
      38.737326,
      54.574875
    ],
    [
      38.738236,
      54.574843
    ],
    [
      38.740543,
      54.58179
    ],
    [
      38.743254,
      54.586419
    ],
    [
      38.754411,
      54.587523
    ],
    [
      38.757129,
      54.584448
    ],
    [
      38.759278,
      54.582111
    ],
    [
      38.759846,
      54.581386
    ],
    [
      38.762681,
      54.583605
    ],
    [
      38.762113,
      54.58433
    ],
    [
      38.759713,
      54.586955
    ],
    [
      38.7572,
      54.589799
    ],
    [
      38.764445,
      54.59812
    ],
    [
      38.767943,
      54.598679
    ],
    [
      38.773329,
      54.598279
    ],
    [
      38.776892,
      54.59846
    ],
    [
      38.779148,
      54.598309
    ],
    [
      38.779598,
      54.60188
    ],
    [
      38.786525,
      54.61127
    ],
    [
      38.787602,
      54.611699
    ],
    [
      38.789067,
      54.612284
    ],
    [
      38.79221,
      54.616007
    ],
    [
      38.799771,
      54.618437
    ],
    [
      38.800985,
      54.618848
    ],
    [
      38.799831,
      54.622258
    ],
    [
      38.789973,
      54.623635
    ],
    [
      38.786076,
      54.624693
    ],
    [
      38.782457,
      54.625978
    ],
    [
      38.775983,
      54.625628
    ],
    [
      38.77227427732974,
      54.62520859769231
    ],
    [
      38.772163,
      54.625434
    ],
    [
      38.771617,
      54.625577
    ],
    [
      38.771055,
      54.625641
    ],
    [
      38.763365,
      54.628225
    ],
    [
      38.759308,
      54.631183
    ],
    [
      38.755859,
      54.632965
    ],
    [
      38.751411,
      54.634809
    ],
    [
      38.752206,
      54.644937
    ],
    [
      38.762695,
      54.645826
    ],
    [
      38.767366,
      54.644914
    ],
    [
      38.772414,
      54.643036
    ],
    [
      38.776298,
      54.643439
    ],
    [
      38.780781,
      54.643019
    ],
    [
      38.785288,
      54.646172
    ],
    [
      38.79231,
      54.647543
    ],
    [
      38.793164,
      54.65104
    ],
    [
      38.791293,
      54.651497
    ],
    [
      38.786085,
      54.660787
    ],
    [
      38.78641158235343,
      54.663385111729276
    ],
    [
      38.787786,
      54.663728
    ],
    [
      38.78649978833561,
      54.66408683058976
    ],
    [
      38.786534,
      54.664359
    ],
    [
      38.786073,
      54.665758
    ],
    [
      38.785355,
      54.665869
    ],
    [
      38.775662,
      54.667638
    ],
    [
      38.768309,
      54.66418
    ],
    [
      38.763375,
      54.660975
    ],
    [
      38.759673,
      54.657561
    ],
    [
      38.757292,
      54.654014
    ],
    [
      38.750654,
      54.652549
    ],
    [
      38.747723696943396,
      54.66128295478581
    ],
    [
      38.747607441213184,
      54.6619008489476
    ],
    [
      38.749575,
      54.66924
    ],
    [
      38.751567,
      54.676134
    ],
    [
      38.75155661138546,
      54.676139538591315
    ],
    [
      38.75167,
      54.676306
    ],
    [
      38.7523582677584,
      54.67683754436756
    ],
    [
      38.753631,
      54.676099
    ],
    [
      38.755439688626474,
      54.67921851522684
    ],
    [
      38.759728,
      54.685606
    ],
    [
      38.769158,
      54.691199
    ],
    [
      38.773032,
      54.69249
    ],
    [
      38.779105,
      54.69448
    ],
    [
      38.787527,
      54.693099
    ],
    [
      38.789905,
      54.689233
    ],
    [
      38.793989,
      54.683044
    ],
    [
      38.794734,
      54.681953
    ],
    [
      38.794752765038886,
      54.68196581930508
    ],
    [
      38.795874,
      54.680285
    ],
    [
      38.79619,
      54.679745
    ],
    [
      38.79632230311623,
      54.679733199617125
    ],
    [
      38.796919,
      54.677748
    ],
    [
      38.800621,
      54.680044
    ],
    [
      38.810853,
      54.675998
    ],
    [
      38.813679,
      54.673768
    ],
    [
      38.814013,
      54.674191
    ],
    [
      38.815651,
      54.676818
    ],
    [
      38.818128,
      54.680311
    ],
    [
      38.817055,
      54.683676
    ],
    [
      38.81887389609496,
      54.68790753398416
    ],
    [
      38.820918,
      54.687549
    ],
    [
      38.830421,
      54.693461
    ],
    [
      38.830420782748746,
      54.69349500454476
    ],
    [
      38.830816,
      54.693479
    ],
    [
      38.840513,
      54.688457
    ],
    [
      38.840588,
      54.684857
    ],
    [
      38.841678879826006,
      54.68404259315742
    ],
    [
      38.836486,
      54.681028
    ],
    [
      38.834016,
      54.678452
    ],
    [
      38.833989,
      54.678424
    ],
    [
      38.836614,
      54.67596
    ],
    [
      38.836641,
      54.675988
    ],
    [
      38.839084,
      54.678536
    ],
    [
      38.8444790426266,
      54.68252252268407
    ],
    [
      38.848622,
      54.681283
    ],
    [
      38.85489711682108,
      54.68406337308866
    ],
    [
      38.85597280587216,
      54.683701735652946
    ],
    [
      38.859815,
      54.673511
    ],
    [
      38.854242,
      54.670558
    ],
    [
      38.852453,
      54.668464
    ],
    [
      38.852865,
      54.667232
    ],
    [
      38.853514,
      54.665024
    ],
    [
      38.854132,
      54.665215
    ],
    [
      38.864551,
      54.667852
    ],
    [
      38.86908887840931,
      54.66697561351318
    ],
    [
      38.874,
      54.665288
    ],
    [
      38.87402065450022,
      54.66529676289297
    ],
    [
      38.876808,
      54.65916
    ],
    [
      38.877062,
      54.658245
    ],
    [
      38.880531,
      54.659204
    ],
    [
      38.886126,
      54.663928
    ],
    [
      38.889022,
      54.666068
    ],
    [
      38.896237,
      54.669761
    ],
    [
      38.901078,
      54.664916
    ],
    [
      38.901743,
      54.664802
    ],
    [
      38.909341,
      54.672518
    ],
    [
      38.916401,
      54.667304
    ],
    [
      38.91697,
      54.6676
    ],
    [
      38.918937,
      54.668545
    ],
    [
      38.929686,
      54.666453
    ],
    [
      38.930603,
      54.662972
    ],
    [
      38.935504,
      54.663493
    ],
    [
      38.943313,
      54.656804
    ],
    [
      38.947754,
      54.655364
    ],
    [
      38.951122,
      54.656637
    ],
    [
      38.953443,
      54.660375
    ],
    [
      38.952121,
      54.663724
    ],
    [
      38.946974,
      54.66693
    ],
    [
      38.943968,
      54.670291
    ],
    [
      38.941055,
      54.673539
    ],
    [
      38.93764,
      54.676582
    ],
    [
      38.93801438568137,
      54.67751828814654
    ],
    [
      38.939606,
      54.677846
    ],
    [
      38.939916,
      54.678233
    ],
    [
      38.947095,
      54.686113
    ],
    [
      38.951518,
      54.685597
    ],
    [
      38.95330489199624,
      54.685388940000436
    ],
    [
      38.958678,
      54.684762
    ],
    [
      38.96293,
      54.684234
    ],
    [
      38.970263,
      54.67571
    ],
    [
      38.970568,
      54.67387
    ],
    [
      38.97412,
      54.67446
    ],
    [
      38.973814,
      54.6763
    ],
    [
      38.979657,
      54.684614
    ],
    [
      38.985777,
      54.683974
    ],
    [
      38.988548,
      54.686272
    ],
    [
      38.986563,
      54.688665
    ],
    [
      38.978972,
      54.688148
    ],
    [
      38.975821005087475,
      54.68752697421957
    ],
    [
      38.975821,
      54.687527
    ],
    [
      38.973648,
      54.687887
    ],
    [
      38.970056,
      54.688128
    ],
    [
      38.968852,
      54.688478
    ],
    [
      38.965331,
      54.689226
    ],
    [
      38.959122,
      54.688335
    ],
    [
      38.95331,
      54.689013
    ],
    [
      38.947512,
      54.689689
    ],
    [
      38.937864,
      54.695523
    ],
    [
      38.935042,
      54.697789
    ],
    [
      38.92897862854153,
      54.69864957844961
    ],
    [
      38.92981,
      54.700404
    ],
    [
      38.928947,
      54.701357
    ],
    [
      38.932665,
      54.712427
    ],
    [
      38.936394,
      54.710311
    ],
    [
      38.937071,
      54.710068
    ],
    [
      38.937711,
      54.710156
    ],
    [
      38.93826543815776,
      54.71179537595231
    ],
    [
      38.940231,
      54.71085
    ],
    [
      38.94353,
      54.707756
    ],
    [
      38.94849,
      54.705951
    ],
    [
      38.952098,
      54.704639
    ],
    [
      38.960711,
      54.702103
    ],
    [
      38.964795,
      54.70456
    ],
    [
      38.961092,
      54.705683
    ],
    [
      38.953285,
      54.708037
    ],
    [
      38.949721,
      54.709334
    ],
    [
      38.94622531685356,
      54.710606375091615
    ],
    [
      38.946239,
      54.711659
    ],
    [
      38.940998144697176,
      54.71880610902465
    ],
    [
      38.941714,
      54.720321
    ],
    [
      38.951315,
      54.724361
    ],
    [
      38.95296581122685,
      54.72449315963624
    ],
    [
      38.952966,
      54.724493
    ],
    [
      38.95297090216306,
      54.7244935672033
    ],
    [
      38.9548,
      54.72464
    ],
    [
      38.956691,
      54.724924
    ],
    [
      38.964506,
      54.721821
    ],
    [
      38.967504,
      54.719828
    ],
    [
      38.968202,
      54.720879
    ],
    [
      38.968781,
      54.724649
    ],
    [
      38.96984409725411,
      54.72745669329232
    ],
    [
      38.971702,
      54.729303
    ],
    [
      38.97801,
      54.728074
    ],
    [
      38.979368,
      54.727456
    ],
    [
      38.979838,
      54.72721
    ],
    [
      38.986137,
      54.724516
    ],
    [
      38.98637,
      54.724362
    ],
    [
      38.988109,
      54.724682
    ],
    [
      38.991906,
      54.72108
    ],
    [
      38.994677,
      54.721031
    ],
    [
      39.005621,
      54.717995
    ],
    [
      39.007928,
      54.713688
    ],
    [
      39.007991200548794,
      54.71367748243879
    ],
    [
      39.008786,
      54.71028
    ],
    [
      39.003133,
      54.703243
    ],
    [
      39.003711,
      54.699479
    ],
    [
      38.997646,
      54.691788
    ],
    [
      38.994282,
      54.69113
    ],
    [
      38.994973,
      54.687597
    ],
    [
      39.00302,
      54.687656
    ],
    [
      39.00330806257525,
      54.68794064804041
    ],
    [
      39.008906,
      54.684487
    ],
    [
      39.011071,
      54.683722
    ],
    [
      39.013235,
      54.682958
    ],
    [
      39.014434,
      54.686352
    ],
    [
      39.01996977978023,
      54.69638958916563
    ],
    [
      39.019975,
      54.696389
    ],
    [
      39.020414593872445,
      54.69668004651634
    ],
    [
      39.027632,
      54.701076
    ],
    [
      39.031531,
      54.701205
    ],
    [
      39.036427,
      54.700351
    ],
    [
      39.043225,
      54.697321
    ],
    [
      39.04919527818239,
      54.69989201320519
    ],
    [
      39.058677,
      54.698602
    ],
    [
      39.061603,
      54.6977
    ],
    [
      39.062664,
      54.70114
    ],
    [
      39.06949,
      54.710624
    ],
    [
      39.071893,
      54.710872
    ],
    [
      39.074008,
      54.714693
    ],
    [
      39.074255,
      54.716048
    ],
    [
      39.070772,
      54.716961
    ],
    [
      39.066504,
      54.716381
    ],
    [
      39.061411,
      54.71618
    ],
    [
      39.05812126972528,
      54.71628698667783
    ],
    [
      39.047071,
      54.720685
    ],
    [
      39.043054,
      54.720186
    ],
    [
      39.035825,
      54.716704
    ],
    [
      39.031668,
      54.71589
    ],
    [
      39.028033,
      54.7149
    ],
    [
      39.024309,
      54.714398
    ],
    [
      39.018507,
      54.715712
    ],
    [
      39.014689,
      54.716562
    ],
    [
      39.009696,
      54.719829
    ],
    [
      39.007542,
      54.722668
    ],
    [
      39.00603089340613,
      54.72597254952774
    ],
    [
      39.006057,
      54.725993
    ],
    [
      39.005551,
      54.727022
    ],
    [
      39.003866,
      54.737489
    ],
    [
      39.005495,
      54.741379
    ],
    [
      39.006773617888214,
      54.742790422233575
    ],
    [
      39.01528655629631,
      54.74210577013095
    ],
    [
      39.018482,
      54.740907
    ],
    [
      39.020915,
      54.738312
    ],
    [
      39.02459,
      54.735774
    ],
    [
      39.025301,
      54.739303
    ],
    [
      39.022991,
      54.742003
    ],
    [
      39.020353,
      54.749729
    ],
    [
      39.022253,
      54.757768
    ],
    [
      39.027667,
      54.760292
    ],
    [
      39.036927,
      54.76308
    ],
    [
      39.048425,
      54.760363
    ],
    [
      39.04847099126128,
      54.76037284767708
    ],
    [
      39.049081,
      54.759664
    ],
    [
      39.049298,
      54.759508
    ],
    [
      39.05046094040856,
      54.75976374016044
    ],
    [
      39.051465,
      54.759071
    ],
    [
      39.062334,
      54.757394
    ],
    [
      39.066332,
      54.756916
    ],
    [
      39.070215,
      54.756767
    ],
    [
      39.072,
      54.756629
    ],
    [
      39.07200404105912,
      54.75667559562049
    ],
    [
      39.074247,
      54.756561
    ],
    [
      39.080889513384136,
      54.75707040964763
    ],
    [
      39.088594,
      54.754464
    ],
    [
      39.09140696764235,
      54.75434567800721
    ],
    [
      39.096644,
      54.751594
    ],
    [
      39.096943,
      54.751729
    ],
    [
      39.103845,
      54.754927
    ],
    [
      39.105861,
      54.758645
    ],
    [
      39.114508,
      54.765467
    ],
    [
      39.11976,
      54.766077
    ],
    [
      39.123996,
      54.764713
    ],
    [
      39.128829,
      54.757936
    ],
    [
      39.129984,
      54.75422
    ],
    [
      39.133023,
      54.748066
    ],
    [
      39.134668,
      54.74299
    ],
    [
      39.134353663836094,
      54.73390158482622
    ],
    [
      39.12848762511315,
      54.72738984833642
    ],
    [
      39.124016,
      54.72749
    ],
    [
      39.120199,
      54.727986
    ],
    [
      39.116636,
      54.728431
    ],
    [
      39.112576,
      54.728869
    ],
    [
      39.107213,
      54.727351
    ],
    [
      39.102712,
      54.725932
    ],
    [
      39.098212,
      54.724514
    ],
    [
      39.094904,
      54.713869
    ],
    [
      39.09490415374857,
      54.713868146189355
    ],
    [
      39.086486,
      54.712352
    ],
    [
      39.087124,
      54.708809
    ],
    [
      39.099007,
      54.710949
    ],
    [
      39.110889,
      54.71309
    ],
    [
      39.116445,
      54.714083
    ],
    [
      39.12183538075118,
      54.71734351526636
    ],
    [
      39.122231,
      54.715118
    ],
    [
      39.125288,
      54.715661
    ],
    [
      39.128281,
      54.717408
    ],
    [
      39.132271,
      54.719027
    ],
    [
      39.143522,
      54.721136
    ],
    [
      39.147322,
      54.713718
    ],
    [
      39.14450380765488,
      54.703809630920155
    ],
    [
      39.142901,
      54.702097
    ],
    [
      39.142118,
      54.696791
    ],
    [
      39.141769,
      54.694208
    ],
    [
      39.141876,
      54.693278
    ],
    [
      39.146427,
      54.693188
    ],
    [
      39.151895,
      54.695997
    ],
    [
      39.160542,
      54.700586
    ],
    [
      39.169,
      54.701454
    ],
    [
      39.173858,
      54.702322
    ],
    [
      39.180071,
      54.703327
    ],
    [
      39.181056,
      54.706789
    ],
    [
      39.180164,
      54.707043
    ],
    [
      39.175873,
      54.709183
    ],
    [
      39.170116,
      54.712687
    ],
    [
      39.170130255514486,
      54.71318523982024
    ],
    [
      39.17427,
      54.720537
    ],
    [
      39.1726073172238,
      54.72287751508305
    ],
    [
      39.173807,
      54.724145
    ],
    [
      39.17216294534146,
      54.72349222944541
    ],
    [
      39.17204,
      54.723605
    ],
    [
      39.171499,
      54.723958
    ],
    [
      39.17092,
      54.72424
    ],
    [
      39.169982,
      54.723539
    ],
    [
      39.160539,
      54.72019
    ],
    [
      39.155927,
      54.71981
    ],
    [
      39.145286,
      54.724274
    ],
    [
      39.139592469494644,
      54.73302628412705
    ],
    [
      39.142609,
      54.734873
    ],
    [
      39.1411,
      54.738142
    ],
    [
      39.13814,
      54.743941
    ],
    [
      39.137294,
      54.747714
    ],
    [
      39.139545103341696,
      54.749588115917064
    ],
    [
      39.141712,
      54.749925
    ],
    [
      39.143513,
      54.750735
    ],
    [
      39.154979941742596,
      54.75222390547166
    ],
    [
      39.155857,
      54.752058
    ],
    [
      39.15923,
      54.750884
    ],
    [
      39.163317,
      54.748945
    ],
    [
      39.164418,
      54.748463
    ],
    [
      39.169503462126706,
      54.74692687072302
    ],
    [
      39.174053,
      54.745552
    ],
    [
      39.17405301296159,
      54.745552042906496
    ],
    [
      39.176634,
      54.744772
    ],
    [
      39.176637155084094,
      54.744782447238116
    ],
    [
      39.176865,
      54.744714
    ],
    [
      39.179188,
      54.744353
    ],
    [
      39.187687254240366,
      54.74371258525812
    ],
    [
      39.187726,
      54.743709
    ],
    [
      39.19006699400053,
      54.743533273005404
    ],
    [
      39.192778,
      54.743329
    ],
    [
      39.19277805787409,
      54.743329766671536
    ],
    [
      39.196172,
      54.743075
    ],
    [
      39.206654,
      54.741885
    ],
    [
      39.210399,
      54.740593
    ],
    [
      39.215946,
      54.730176
    ],
    [
      39.21244,
      54.728572
    ],
    [
      39.203782,
      54.724669
    ],
    [
      39.201947,
      54.723958
    ],
    [
      39.201667,
      54.723845
    ],
    [
      39.203017,
      54.720508
    ],
    [
      39.21014940304699,
      54.71952151564473
    ],
    [
      39.208902,
      54.709777
    ],
    [
      39.208368,
      54.709346
    ],
    [
      39.210632,
      54.706546
    ],
    [
      39.222216,
      54.707895
    ],
    [
      39.22295439686972,
      54.70847070779133
    ],
    [
      39.223149,
      54.708089
    ],
    [
      39.223876,
      54.708459
    ],
    [
      39.225148,
      54.710181
    ],
    [
      39.223999,
      54.714129
    ],
    [
      39.222097,
      54.717251
    ],
    [
      39.21829990226773,
      54.725152006852085
    ],
    [
      39.222844,
      54.729727
    ],
    [
      39.22537384950717,
      54.735665064337006
    ],
    [
      39.225374,
      54.735665
    ],
    [
      39.22556952494274,
      54.73603575587944
    ],
    [
      39.227343,
      54.739175
    ],
    [
      39.228689,
      54.742713
    ],
    [
      39.2298858040277,
      54.745593723032236
    ],
    [
      39.229895,
      54.74559
    ],
    [
      39.239464,
      54.748728
    ],
    [
      39.244634216949876,
      54.7476410634056
    ],
    [
      39.245232,
      54.738816
    ],
    [
      39.24640247217276,
      54.736951842315825
    ],
    [
      39.24457,
      54.727435
    ],
    [
      39.247005,
      54.724059
    ],
    [
      39.248817,
      54.720816
    ],
    [
      39.25187,
      54.718976
    ],
    [
      39.252244,
      54.718894
    ],
    [
      39.253018,
      54.72241
    ],
    [
      39.260779,
      54.728359
    ],
    [
      39.266815,
      54.727973
    ],
    [
      39.270603,
      54.7279
    ],
    [
      39.27530662661183,
      54.72778704236279
    ],
    [
      39.28175527965651,
      54.725384294101424
    ],
    [
      39.285193,
      54.717241
    ],
    [
      39.286414,
      54.713854
    ],
    [
      39.287171,
      54.714126
    ],
    [
      39.290838,
      54.715461
    ],
    [
      39.299939,
      54.713917
    ],
    [
      39.301457,
      54.713282
    ],
    [
      39.302043,
      54.714133
    ],
    [
      39.302931,
      54.716566
    ],
    [
      39.311888,
      54.722534
    ],
    [
      39.311711,
      54.726129
    ],
    [
      39.309324,
      54.726012
    ],
    [
      39.306938,
      54.725895
    ],
    [
      39.304849,
      54.72601
    ],
    [
      39.30005,
      54.725611
    ],
    [
      39.295982,
      54.725145
    ],
    [
      39.292701701972405,
      54.72500579916119
    ],
    [
      39.283534,
      54.729595
    ],
    [
      39.279537,
      54.731084
    ],
    [
      39.27675443441111,
      54.73142086442837
    ],
    [
      39.28041,
      54.740442
    ],
    [
      39.28383,
      54.741567
    ],
    [
      39.286642,
      54.74682
    ],
    [
      39.288601,
      54.750792
    ],
    [
      39.296151,
      54.756367
    ],
    [
      39.300019394199445,
      54.757159924582176
    ],
    [
      39.31135446884259,
      54.75662344501405
    ],
    [
      39.315939821957514,
      54.754022566638625
    ],
    [
      39.318832,
      54.752373
    ],
    [
      39.318837735535375,
      54.752383050046845
    ],
    [
      39.32086,
      54.751239
    ],
    [
      39.318279,
      54.741985
    ],
    [
      39.319306,
      54.738534
    ],
    [
      39.325612,
      54.740547
    ],
    [
      39.329656,
      54.741729
    ],
    [
      39.330143519834344,
      54.741847998311286
    ],
    [
      39.330144,
      54.741846
    ],
    [
      39.332503,
      54.74138
    ],
    [
      39.333615,
      54.741235
    ],
    [
      39.343429,
      54.741513
    ],
    [
      39.34454522324347,
      54.742196766466854
    ],
    [
      39.346371,
      54.740899
    ],
    [
      39.349586,
      54.732756
    ],
    [
      39.350532,
      54.731016
    ],
    [
      39.35058979706877,
      54.73104742932605
    ],
    [
      39.350748,
      54.730678
    ],
    [
      39.352842,
      54.727109
    ],
    [
      39.35587,
      54.723181
    ],
    [
      39.358721,
      54.725379
    ],
    [
      39.357207,
      54.727343
    ],
    [
      39.355694,
      54.729307
    ],
    [
      39.35367748361479,
      54.73272647480792
    ],
    [
      39.353695,
      54.732736
    ],
    [
      39.35363659422999,
      54.73315507942633
    ],
    [
      39.35572962031897,
      54.73955312419802
    ],
    [
      39.361981,
      54.741762
    ],
    [
      39.365137,
      54.744495
    ],
    [
      39.365219,
      54.745562
    ],
    [
      39.365013,
      54.747061
    ],
    [
      39.3650072045626,
      54.74707224525233
    ],
    [
      39.367657,
      54.747933
    ],
    [
      39.355395,
      54.760041
    ],
    [
      39.35540095776486,
      54.76004876655139
    ],
    [
      39.356853,
      54.761916
    ],
    [
      39.366256,
      54.769149
    ],
    [
      39.373253,
      54.771664
    ],
    [
      39.38356,
      54.769658
    ],
    [
      39.39330436486032,
      54.76468654816228
    ],
    [
      39.396358,
      54.763121
    ],
    [
      39.398844,
      54.761839
    ],
    [
      39.401331,
      54.760558
    ],
    [
      39.401331064018905,
      54.760558124119086
    ],
    [
      39.408457,
      54.756886
    ],
    [
      39.404128,
      54.745754
    ],
    [
      39.400244,
      54.743771
    ],
    [
      39.39971076959397,
      54.74349861865975
    ],
    [
      39.399547,
      54.743415
    ],
    [
      39.39944913224685,
      54.74336497074608
    ],
    [
      39.39636,
      54.741787
    ],
    [
      39.396360473853605,
      54.74178607197639
    ],
    [
      39.394901,
      54.74104
    ],
    [
      39.387573,
      54.740458
    ],
    [
      39.384839,
      54.739957
    ],
    [
      39.385489,
      54.736416
    ],
    [
      39.391392,
      54.733122
    ],
    [
      39.395649,
      54.733876
    ],
    [
      39.396749,
      54.73537
    ],
    [
      39.405766,
      54.742548
    ],
    [
      39.409306,
      54.744377
    ],
    [
      39.415514,
      54.747047
    ],
    [
      39.415794521007804,
      54.7471024161735
    ],
    [
      39.415795,
      54.7471
    ],
    [
      39.426608,
      54.74754
    ],
    [
      39.42722640511702,
      54.74874078663499
    ],
    [
      39.428022,
      54.748836
    ],
    [
      39.432115,
      54.744748
    ],
    [
      39.43766,
      54.743966
    ],
    [
      39.443801,
      54.738674
    ],
    [
      39.444232,
      54.738452
    ],
    [
      39.44423342252631,
      54.73845198478582
    ],
    [
      39.447638,
      54.736704
    ],
    [
      39.452058,
      54.734436
    ],
    [
      39.454852,
      54.732963
    ],
    [
      39.456531,
      54.736147
    ],
    [
      39.453737,
      54.73762
    ],
    [
      39.449282,
      54.739907
    ],
    [
      39.442987,
      54.748285
    ],
    [
      39.439723,
      54.756847
    ],
    [
      39.437926971529684,
      54.75768610097755
    ],
    [
      39.437434,
      54.758602
    ],
    [
      39.440575,
      54.769511
    ],
    [
      39.448172,
      54.769617
    ],
    [
      39.451892,
      54.769901
    ],
    [
      39.451754307318765,
      54.77169851536594
    ],
    [
      39.451844,
      54.771703
    ],
    [
      39.457781,
      54.767279
    ],
    [
      39.458806,
      54.766165
    ],
    [
      39.459881,
      54.764613
    ],
    [
      39.462487,
      54.766366
    ],
    [
      39.462841,
      54.766662
    ],
    [
      39.464329,
      54.767881
    ],
    [
      39.465497,
      54.771286
    ],
    [
      39.465081,
      54.771429
    ],
    [
      39.460012,
      54.773646
    ],
    [
      39.456263,
      54.782571
    ],
    [
      39.455338,
      54.78605
    ],
    [
      39.452602,
      54.790622
    ],
    [
      39.450109,
      54.79121
    ],
    [
      39.446951,
      54.790384
    ],
    [
      39.440470076035204,
      54.785324677292415
    ],
    [
      39.432349,
      54.788469
    ],
    [
      39.43198524123171,
      54.792690406622114
    ],
    [
      39.43199,
      54.792702
    ],
    [
      39.434341662359486,
      54.79836510563114
    ],
    [
      39.436907,
      54.802254
    ],
    [
      39.443569,
      54.810773
    ],
    [
      39.447569,
      54.808059
    ],
    [
      39.447996,
      54.807905
    ],
    [
      39.450052,
      54.807423
    ],
    [
      39.450874,
      54.810927
    ],
    [
      39.45078361746811,
      54.812719229921385
    ],
    [
      39.454654,
      54.81302
    ],
    [
      39.45878,
      54.810438
    ],
    [
      39.460862,
      54.810765
    ],
    [
      39.461607,
      54.812667
    ],
    [
      39.461969,
      54.81702
    ],
    [
      39.462271,
      54.820608
    ],
    [
      39.455518,
      54.823456
    ],
    [
      39.450575,
      54.823308
    ],
    [
      39.450102,
      54.823335
    ],
    [
      39.448707,
      54.821975
    ],
    [
      39.44593651610225,
      54.81926460602336
    ],
    [
      39.445556,
      54.819254
    ],
    [
      39.435453,
      54.812919
    ],
    [
      39.427153,
      54.816366
    ],
    [
      39.416071,
      54.815989
    ],
    [
      39.411484,
      54.817618
    ],
    [
      39.408039,
      54.819309
    ],
    [
      39.402747,
      54.822891
    ],
    [
      39.39993512848226,
      54.82511968718326
    ],
    [
      39.402598,
      54.83618
    ],
    [
      39.407001,
      54.838203
    ],
    [
      39.414192,
      54.838022
    ],
    [
      39.415563,
      54.841351
    ],
    [
      39.416653,
      54.850417
    ],
    [
      39.419414,
      54.850352
    ],
    [
      39.419748,
      54.850594
    ],
    [
      39.41941675933248,
      54.851758012202815
    ],
    [
      39.425546,
      54.854732
    ],
    [
      39.430713,
      54.856217
    ],
    [
      39.438529,
      54.858618
    ],
    [
      39.444646,
      54.861156
    ],
    [
      39.449522,
      54.863238
    ],
    [
      39.456424,
      54.857634
    ],
    [
      39.458176,
      54.85449
    ],
    [
      39.460032,
      54.855524
    ],
    [
      39.47173,
      54.855703
    ],
    [
      39.475933,
      54.860473
    ],
    [
      39.480956,
      54.864366
    ],
    [
      39.489336,
      54.865151
    ],
    [
      39.490803,
      54.865389
    ],
    [
      39.490815,
      54.866589
    ],
    [
      39.490534,
      54.868851
    ],
    [
      39.490178,
      54.869252
    ],
    [
      39.484595,
      54.873835
    ],
    [
      39.484111,
      54.873518
    ],
    [
      39.482234,
      54.872468
    ],
    [
      39.477435,
      54.867622
    ],
    [
      39.476884122864796,
      54.86752997270722
    ],
    [
      39.476683,
      54.868582
    ],
    [
      39.473671,
      54.868006
    ],
    [
      39.4654,
      54.867829
    ],
    [
      39.457235,
      54.869088
    ],
    [
      39.45693404873474,
      54.86900841959831
    ],
    [
      39.456581,
      54.873496
    ],
    [
      39.455976,
      54.873853
    ],
    [
      39.455133,
      54.874268
    ],
    [
      39.451598,
      54.874616
    ],
    [
      39.443366,
      54.87485
    ],
    [
      39.450529,
      54.88288
    ],
    [
      39.453043,
      54.882896
    ],
    [
      39.458085,
      54.883574
    ],
    [
      39.463127,
      54.884253
    ],
    [
      39.462647,
      54.88782
    ],
    [
      39.452563,
      54.886464
    ],
    [
      39.450529,
      54.88648
    ],
    [
      39.44649786792369,
      54.885470157478835
    ],
    [
      39.446441,
      54.885633
    ],
    [
      39.440451,
      54.89533
    ],
    [
      39.44788,
      54.894494
    ],
    [
      39.449445,
      54.894562
    ],
    [
      39.449779,
      54.894682
    ],
    [
      39.448561,
      54.89807
    ],
    [
      39.448441,
      54.908313
    ],
    [
      39.45115,
      54.915061
    ],
    [
      39.460265,
      54.91441
    ],
    [
      39.465685,
      54.91404
    ],
    [
      39.47039,
      54.912968
    ],
    [
      39.476215,
      54.911154
    ],
    [
      39.479489,
      54.910198
    ],
    [
      39.47951955893139,
      54.910302669640124
    ],
    [
      39.480164,
      54.910088
    ],
    [
      39.484856,
      54.910061
    ],
    [
      39.49208,
      54.908413
    ],
    [
      39.498094,
      54.908052
    ],
    [
      39.502653,
      54.908035
    ],
    [
      39.503707,
      54.908224
    ],
    [
      39.50405942217742,
      54.90931759632994
    ],
    [
      39.513824,
      54.906552
    ],
    [
      39.514219,
      54.906405
    ],
    [
      39.516027,
      54.90796
    ],
    [
      39.517236,
      54.911471
    ],
    [
      39.517978,
      54.914577
    ],
    [
      39.517165,
      54.915071
    ],
    [
      39.510887,
      54.924187
    ],
    [
      39.512899,
      54.927172
    ],
    [
      39.511256,
      54.92828
    ],
    [
      39.509613,
      54.929387
    ],
    [
      39.501862,
      54.938061
    ],
    [
      39.500991,
      54.941302
    ],
    [
      39.497514,
      54.940367
    ],
    [
      39.487589,
      54.939129
    ],
    [
      39.484489,
      54.941889
    ],
    [
      39.481157,
      54.943869
    ],
    [
      39.47787,
      54.946672
    ],
    [
      39.468039,
      54.951311
    ],
    [
      39.46268,
      54.951142
    ],
    [
      39.458467,
      54.950466
    ],
    [
      39.454362,
      54.950454
    ],
    [
      39.450475,
      54.951537
    ],
    [
      39.44642,
      54.952648
    ],
    [
      39.442281,
      54.954219
    ],
    [
      39.438949378418506,
      54.95470337854155
    ],
    [
      39.438952,
      54.954746
    ],
    [
      39.438443,
      54.954777
    ],
    [
      39.434198,
      54.95355
    ],
    [
      39.430065,
      54.952809
    ],
    [
      39.426406,
      54.951776
    ],
    [
      39.42501868068845,
      54.95127179508798
    ],
    [
      39.421444,
      54.950548
    ],
    [
      39.418146,
      54.948774
    ],
    [
      39.408778,
      54.953154
    ],
    [
      39.40507,
      54.952304
    ],
    [
      39.40489934467965,
      54.9522346865113
    ],
    [
      39.402128,
      54.956183
    ],
    [
      39.401842,
      54.968141
    ],
    [
      39.408144,
      54.972201
    ],
    [
      39.41261752742907,
      54.97603687652282
    ],
    [
      39.420049,
      54.976438
    ],
    [
      39.421812,
      54.975006
    ],
    [
      39.423575,
      54.973573
    ],
    [
      39.423587015645715,
      54.97358421728175
    ],
    [
      39.423649,
      54.973513
    ],
    [
      39.427711,
      54.970283
    ],
    [
      39.429957,
      54.973097
    ],
    [
      39.440303,
      54.97258
    ],
    [
      39.441864,
      54.971157
    ],
    [
      39.44429,
      54.973817
    ],
    [
      39.44676964019626,
      54.98052080601732
    ],
    [
      39.45104,
      54.978245
    ],
    [
      39.456987,
      54.975174
    ],
    [
      39.460492,
      54.974749
    ],
    [
      39.464087,
      54.973691
    ],
    [
      39.465532,
      54.973521
    ],
    [
      39.467253,
      54.973434
    ],
    [
      39.46725718404026,
      54.97351712601525
    ],
    [
      39.468554,
      54.973446
    ],
    [
      39.477995,
      54.974175
    ],
    [
      39.481456,
      54.97248
    ],
    [
      39.484494,
      54.975901
    ],
    [
      39.488779,
      54.986184
    ],
    [
      39.496127,
      54.98782
    ],
    [
      39.503497,
      54.988299
    ],
    [
      39.51196834540654,
      54.985659355136384
    ],
    [
      39.517452,
      54.979509
    ],
    [
      39.519772,
      54.977088
    ],
    [
      39.522838,
      54.978976
    ],
    [
      39.525621,
      54.983252
    ],
    [
      39.526341,
      54.986779
    ],
    [
      39.525376,
      54.986976
    ],
    [
      39.522548,
      54.986965
    ],
    [
      39.51144,
      54.990298
    ],
    [
      39.508161,
      54.991956
    ],
    [
      39.503678,
      54.991895
    ],
    [
      39.499469,
      54.992483
    ],
    [
      39.496033,
      54.991598
    ],
    [
      39.49104,
      54.991591
    ],
    [
      39.479535,
      54.989132
    ],
    [
      39.471358,
      54.987464
    ],
    [
      39.467165,
      54.987419
    ],
    [
      39.466655633020466,
      54.98701732125625
    ],
    [
      39.466621,
      54.987056
    ],
    [
      39.465968,
      54.986809
    ],
    [
      39.464029,
      54.984946
    ],
    [
      39.454325,
      54.981464
    ],
    [
      39.447387,
      54.984697
    ],
    [
      39.44689953701798,
      54.984728240867156
    ],
    [
      39.446899,
      54.984747
    ],
    [
      39.446695893779825,
      54.98474129209686
    ],
    [
      39.44468524708423,
      54.98487015182289
    ],
    [
      39.44369113833225,
      54.98497773380584
    ],
    [
      39.444255,
      54.99651
    ],
    [
      39.44068,
      54.999045
    ],
    [
      39.43561028789925,
      55.00812186062004
    ],
    [
      39.441555,
      55.014985
    ],
    [
      39.445907,
      55.013893
    ],
    [
      39.449878,
      55.013046
    ],
    [
      39.455361,
      55.012614
    ],
    [
      39.459235,
      55.012219
    ],
    [
      39.463139,
      55.011812
    ],
    [
      39.467182,
      55.01226
    ],
    [
      39.470692,
      55.012572
    ],
    [
      39.474333,
      55.014851
    ],
    [
      39.479685,
      55.019325
    ],
    [
      39.483107,
      55.021744
    ],
    [
      39.485952,
      55.029111
    ],
    [
      39.48553,
      55.032056
    ],
    [
      39.485176,
      55.034084
    ],
    [
      39.48163,
      55.033465
    ],
    [
      39.479388,
      55.025261
    ],
    [
      39.472465,
      55.019779
    ],
    [
      39.464619,
      55.015563
    ],
    [
      39.461026,
      55.015793
    ],
    [
      39.457336,
      55.016145
    ],
    [
      39.452264,
      55.016495
    ],
    [
      39.446077,
      55.02533
    ],
    [
      39.448285,
      55.034654
    ],
    [
      39.44813027887802,
      55.03744831011262
    ],
    [
      39.450884,
      55.036514
    ],
    [
      39.456877,
      55.036882
    ],
    [
      39.458905,
      55.037678
    ],
    [
      39.46098,
      55.038236
    ],
    [
      39.460045,
      55.041713
    ],
    [
      39.453158149275865,
      55.04756569200442
    ],
    [
      39.454445,
      55.051689
    ],
    [
      39.455458,
      55.052989
    ],
    [
      39.455142,
      55.056563
    ],
    [
      39.45674071815275,
      55.064214074425315
    ],
    [
      39.458835,
      55.065158
    ],
    [
      39.459496,
      55.065399
    ],
    [
      39.466528,
      55.073208
    ],
    [
      39.46972698752991,
      55.0754084004719
    ],
    [
      39.470492,
      55.075489
    ],
    [
      39.479708,
      55.076444
    ],
    [
      39.488928,
      55.077399
    ],
    [
      39.493686,
      55.07787
    ],
    [
      39.499247,
      55.07759
    ],
    [
      39.505077,
      55.067588
    ],
    [
      39.500262,
      55.057238
    ],
    [
      39.497811,
      55.054225
    ],
    [
      39.494223,
      55.051619
    ],
    [
      39.495533,
      55.048266
    ],
    [
      39.504959827874764,
      55.04109113100653
    ],
    [
      39.504958960749335,
      55.04025336396762
    ],
    [
      39.504956609457906,
      55.03835822307474
    ],
    [
      39.501013,
      55.030513
    ],
    [
      39.503804,
      55.027641
    ],
    [
      39.510685,
      55.028794
    ],
    [
      39.514973,
      55.029086
    ],
    [
      39.518865,
      55.029483
    ],
    [
      39.524001,
      55.03004
    ],
    [
      39.528619,
      55.030335
    ],
    [
      39.529499,
      55.033826
    ],
    [
      39.530504,
      55.042286
    ],
    [
      39.538918,
      55.042302
    ],
    [
      39.547333,
      55.042318
    ],
    [
      39.54733299933583,
      55.04231834157608
    ],
    [
      39.552354,
      55.042328
    ],
    [
      39.565518,
      55.042238
    ],
    [
      39.565542,
      55.045838
    ],
    [
      39.552378,
      55.045928
    ],
    [
      39.54143,
      55.045907
    ],
    [
      39.530497,
      55.045886
    ],
    [
      39.526516,
      55.045895
    ],
    [
      39.522527,
      55.045905
    ],
    [
      39.515853,
      55.045921
    ],
    [
      39.508616,
      55.052485
    ],
    [
      39.50866403572594,
      55.05653200991059
    ],
    [
      39.508665,
      55.056532
    ],
    [
      39.51431613871581,
      55.05972153230672
    ],
    [
      39.517319,
      55.060883
    ],
    [
      39.51732,
      55.064483
    ],
    [
      39.50866513344567,
      55.07007780295838
    ],
    [
      39.515186,
      55.077673
    ],
    [
      39.524686,
      55.080719
    ],
    [
      39.530258,
      55.076799
    ],
    [
      39.533813,
      55.075563
    ],
    [
      39.536942,
      55.077513
    ],
    [
      39.544625,
      55.085288
    ],
    [
      39.549249,
      55.081887
    ],
    [
      39.552438,
      55.079629
    ],
    [
      39.555625,
      55.070542
    ],
    [
      39.562386,
      55.06096
    ],
    [
      39.570933,
      55.060935
    ],
    [
      39.57751718795277,
      55.06091651158174
    ],
    [
      39.578391,
      55.060914
    ],
    [
      39.578391000176964,
      55.06091405791456
    ],
    [
      39.57885503265512,
      55.060912754910056
    ],
    [
      39.583553,
      55.060899
    ],
    [
      39.591289,
      55.060912
    ],
    [
      39.600435,
      55.057953
    ],
    [
      39.604696,
      55.056665
    ],
    [
      39.606107,
      55.056394
    ],
    [
      39.606787,
      55.05993
    ],
    [
      39.605375,
      55.060201
    ],
    [
      39.59946,
      55.064471
    ],
    [
      39.596263056617204,
      55.064487029678205
    ],
    [
      39.596263,
      55.064521
    ],
    [
      39.583547,
      55.064499
    ],
    [
      39.579489999714895,
      55.06451089736154
    ],
    [
      39.57949,
      55.064511
    ],
    [
      39.57866590905143,
      55.0645133140497
    ],
    [
      39.572976,
      55.06453
    ],
    [
      39.56703,
      55.068793
    ],
    [
      39.561208,
      55.07463
    ],
    [
      39.558813,
      55.078823
    ],
    [
      39.556485,
      55.081607
    ],
    [
      39.552479,
      55.087904
    ],
    [
      39.547794,
      55.093398
    ],
    [
      39.548981359556514,
      55.100849337216864
    ],
    [
      39.55192703337619,
      55.102275406428795
    ],
    [
      39.55885,
      55.101735
    ],
    [
      39.564822,
      55.101341
    ],
    [
      39.571393,
      55.10041
    ],
    [
      39.579215,
      55.102783
    ],
    [
      39.579213967615836,
      55.10280509793723
    ],
    [
      39.57942,
      55.102816
    ],
    [
      39.583431,
      55.103076
    ],
    [
      39.587058,
      55.103978
    ],
    [
      39.591788,
      55.103881
    ],
    [
      39.598367,
      55.094048
    ],
    [
      39.598338,
      55.092184
    ],
    [
      39.602691,
      55.092078
    ],
    [
      39.602888,
      55.095673
    ],
    [
      39.601518,
      55.101602
    ],
    [
      39.607453,
      55.109141
    ],
    [
      39.611604,
      55.108231
    ],
    [
      39.621444,
      55.1019
    ],
    [
      39.618659,
      55.097073
    ],
    [
      39.616843,
      55.093925
    ],
    [
      39.615027,
      55.090776
    ],
    [
      39.61502737579218,
      55.09077578336827
    ],
    [
      39.613725,
      55.088517
    ],
    [
      39.610485,
      55.082593
    ],
    [
      39.610924749258686,
      55.08235259260217
    ],
    [
      39.610239,
      55.080843
    ],
    [
      39.612701,
      55.077978
    ],
    [
      39.61923,
      55.076595
    ],
    [
      39.623246,
      55.075878
    ],
    [
      39.625354,
      55.075515
    ],
    [
      39.626115182687194,
      55.07538422252835
    ],
    [
      39.62712137016032,
      55.075211060774414
    ],
    [
      39.627934,
      55.075071
    ],
    [
      39.627934035015656,
      55.07507120366481
    ],
    [
      39.628034,
      55.075054
    ],
    [
      39.631667,
      55.074402
    ],
    [
      39.635508,
      55.073705
    ],
    [
      39.641279,
      55.072652
    ],
    [
      39.649469,
      55.071116
    ],
    [
      39.657586,
      55.063911
    ],
    [
      39.655393,
      55.059985
    ],
    [
      39.658536,
      55.058229
    ],
    [
      39.662922,
      55.066081
    ],
    [
      39.664155,
      55.06832
    ],
    [
      39.66330429243165,
      55.07097406403392
    ],
    [
      39.663677,
      55.071344
    ],
    [
      39.663272,
      55.071751
    ],
    [
      39.66297,
      55.072017
    ],
    [
      39.658316,
      55.073119
    ],
    [
      39.65384395888522,
      55.073957780927564
    ],
    [
      39.653844,
      55.073958
    ],
    [
      39.64830111533335,
      55.074997640216466
    ],
    [
      39.641943,
      55.076191
    ],
    [
      39.636155,
      55.077246
    ],
    [
      39.632304,
      55.077945
    ],
    [
      39.62867,
      55.078597
    ],
    [
      39.62854394956488,
      55.07861870664951
    ],
    [
      39.628544,
      55.078619
    ],
    [
      39.6262,
      55.079023
    ],
    [
      39.623856,
      55.079426
    ],
    [
      39.616883,
      55.086789
    ],
    [
      39.61814571899782,
      55.08897816198843
    ],
    [
      39.618146,
      55.088978
    ],
    [
      39.619015836420125,
      55.090486674958974
    ],
    [
      39.61932,
      55.091014
    ],
    [
      39.621777,
      55.095275
    ],
    [
      39.624562,
      55.1001
    ],
    [
      39.626837,
      55.107644
    ],
    [
      39.632208,
      55.111533
    ],
    [
      39.646564,
      55.109053
    ],
    [
      39.66092,
      55.106574
    ],
    [
      39.661533,
      55.110121
    ],
    [
      39.647772401594324,
      55.1124981443331
    ],
    [
      39.66107432074818,
      55.11386794672784
    ],
    [
      39.662349,
      55.109941
    ],
    [
      39.662482,
      55.106344
    ],
    [
      39.664712,
      55.105919
    ],
    [
      39.674181,
      55.104284
    ],
    [
      39.676315532844946,
      55.10391516859587
    ],
    [
      39.676318,
      55.103914
    ],
    [
      39.6813537803371,
      55.10304459689148
    ],
    [
      39.683649,
      55.102648
    ],
    [
      39.683649056760075,
      55.10264832852323
    ],
    [
      39.687943,
      55.101907
    ],
    [
      39.691777,
      55.101287
    ],
    [
      39.695596,
      55.10067
    ],
    [
      39.70028607417794,
      55.09987136423743
    ],
    [
      39.701168,
      55.099721
    ],
    [
      39.70116803110114,
      55.09972118274494
    ],
    [
      39.70870533504435,
      55.09843771466874
    ],
    [
      39.71339132990007,
      55.09763941608699
    ],
    [
      39.728882,
      55.090838
    ],
    [
      39.727615,
      55.088264
    ],
    [
      39.726329,
      55.085947
    ],
    [
      39.725044,
      55.08363
    ],
    [
      39.728192,
      55.081884
    ],
    [
      39.729477,
      55.084201
    ],
    [
      39.730763,
      55.086518
    ],
    [
      39.737930359490676,
      55.09200452936344
    ],
    [
      39.74104479137593,
      55.09301693218295
    ],
    [
      39.744171,
      55.092522
    ],
    [
      39.748332,
      55.091923
    ],
    [
      39.752493,
      55.091325
    ],
    [
      39.753005,
      55.094888
    ],
    [
      39.748844,
      55.095487
    ],
    [
      39.744684,
      55.096085
    ],
    [
      39.741607938369896,
      55.09657261073418
    ],
    [
      39.741608,
      55.096573
    ],
    [
      39.74064670564637,
      55.096724983297015
    ],
    [
      39.740123,
      55.096808
    ],
    [
      39.740122200736245,
      55.096807908974505
    ],
    [
      39.738572,
      55.097053
    ],
    [
      39.735537,
      55.097534
    ],
    [
      39.731316,
      55.095805
    ],
    [
      39.71716514953984,
      55.10020278465603
    ],
    [
      39.717239,
      55.100636
    ],
    [
      39.70672,
      55.102428
    ],
    [
      39.7001930768382,
      55.103539295691874
    ],
    [
      39.69948095776859,
      55.10366075226568
    ],
    [
      39.699481,
      55.103661
    ],
    [
      39.696201,
      55.104219
    ],
    [
      39.692351,
      55.104841
    ],
    [
      39.688517,
      55.105461
    ],
    [
      39.68426196829107,
      55.10619581647101
    ],
    [
      39.684262,
      55.106196
    ],
    [
      39.6769299942418,
      55.10746200796437
    ],
    [
      39.67330151526284,
      55.11248069265451
    ],
    [
      39.67175840198942,
      55.117291336889565
    ],
    [
      39.67185207621001,
      55.117451465629514
    ],
    [
      39.67527596875185,
      55.12137209272334
    ],
    [
      39.681638,
      55.126757
    ],
    [
      39.686306,
      55.125874
    ],
    [
      39.693108,
      55.12676
    ],
    [
      39.696102,
      55.126284
    ],
    [
      39.697853,
      55.126202
    ],
    [
      39.6978552627274,
      55.12625043314131
    ],
    [
      39.698204,
      55.126213
    ],
    [
      39.702696,
      55.126428
    ],
    [
      39.706305,
      55.126415
    ],
    [
      39.713798,
      55.128736
    ],
    [
      39.717158,
      55.129946
    ],
    [
      39.725281,
      55.131331
    ],
    [
      39.72882,
      55.131644
    ],
    [
      39.733238,
      55.132054
    ],
    [
      39.737235,
      55.132594
    ],
    [
      39.741832,
      55.134174
    ],
    [
      39.741433,
      55.137752
    ],
    [
      39.740121,
      55.137606
    ],
    [
      39.731381,
      55.135489
    ],
    [
      39.72950857688495,
      55.13525148493009
    ],
    [
      39.729493,
      55.135367
    ],
    [
      39.728339,
      55.135211
    ],
    [
      39.722177,
      55.134967
    ],
    [
      39.714886,
      55.132897
    ],
    [
      39.710959,
      55.132493
    ],
    [
      39.70682,
      55.13103
    ],
    [
      39.699113,
      55.130591
    ],
    [
      39.695394,
      55.129819
    ],
    [
      39.686975,
      55.129412
    ],
    [
      39.679487,
      55.13436
    ],
    [
      39.67485,
      55.136378
    ],
    [
      39.66599,
      55.13872
    ],
    [
      39.660337,
      55.139285
    ],
    [
      39.65656,
      55.140171
    ],
    [
      39.653553,
      55.142371
    ],
    [
      39.65538,
      55.15404
    ],
    [
      39.662384,
      55.155134
    ],
    [
      39.662935,
      55.15554
    ],
    [
      39.663106954251205,
      55.15569427101261
    ],
    [
      39.668576,
      55.157072
    ],
    [
      39.67625,
      55.160298
    ],
    [
      39.680399,
      55.16176
    ],
    [
      39.683746,
      55.163775
    ],
    [
      39.6885,
      55.165438
    ],
    [
      39.690352,
      55.168433
    ],
    [
      39.699579,
      55.174489
    ],
    [
      39.706917,
      55.17557
    ],
    [
      39.710385,
      55.17624
    ],
    [
      39.714192,
      55.17864
    ],
    [
      39.71626,
      55.181648
    ],
    [
      39.720702,
      55.192261
    ],
    [
      39.71773,
      55.194293
    ],
    [
      39.716467,
      55.192446
    ],
    [
      39.715204,
      55.190598
    ],
    [
      39.714312,
      55.185657
    ],
    [
      39.706125,
      55.179129
    ],
    [
      39.70177499577506,
      55.178745706607685
    ],
    [
      39.70168,
      55.179045
    ],
    [
      39.700337,
      55.178619
    ],
    [
      39.695388,
      55.177255
    ],
    [
      39.690174,
      55.175032
    ],
    [
      39.688111016745964,
      55.17260965837268
    ],
    [
      39.687933,
      55.172685
    ],
    [
      39.681991,
      55.166918
    ],
    [
      39.673894,
      55.163728
    ],
    [
      39.669157,
      55.162788
    ],
    [
      39.66202,
      55.159127
    ],
    [
      39.64834,
      55.154883
    ],
    [
      39.642716,
      55.158644
    ],
    [
      39.632847,
      55.160404
    ],
    [
      39.627237,
      55.1624
    ],
    [
      39.622081,
      55.172581
    ],
    [
      39.625463,
      55.176309
    ],
    [
      39.62546,
      55.179324
    ],
    [
      39.625457,
      55.182338
    ],
    [
      39.624817,
      55.192622
    ],
    [
      39.624763,
      55.196427
    ],
    [
      39.625127,
      55.203508
    ],
    [
      39.624256,
      55.20836
    ],
    [
      39.624611,
      55.213401
    ],
    [
      39.6250588006185,
      55.21405431880276
    ],
    [
      39.63347979371695,
      55.22141099062735
    ],
    [
      39.63388326739098,
      55.22154874986309
    ],
    [
      39.635658,
      55.22207
    ],
    [
      39.63559026337928,
      55.22230050471721
    ],
    [
      39.637712,
      55.223235
    ],
    [
      39.640593,
      55.227743
    ],
    [
      39.650315,
      55.232367
    ],
    [
      39.655118,
      55.229374
    ],
    [
      39.65541259332637,
      55.2292246390734
    ],
    [
      39.658273,
      55.226782
    ],
    [
      39.659377,
      55.223448
    ],
    [
      39.666363,
      55.223295
    ],
    [
      39.668267811487716,
      55.226219789642926
    ],
    [
      39.668478,
      55.226459
    ],
    [
      39.679329,
      55.231324
    ],
    [
      39.683988,
      55.22813
    ],
    [
      39.685156,
      55.226523
    ],
    [
      39.688754,
      55.226402
    ],
    [
      39.688794,
      55.2276
    ],
    [
      39.685223,
      55.238272
    ],
    [
      39.68518128293407,
      55.23928003246694
    ],
    [
      39.685286,
      55.239287
    ],
    [
      39.685147,
      55.241365
    ],
    [
      39.694064,
      55.24915
    ],
    [
      39.697604,
      55.249227
    ],
    [
      39.701247,
      55.249787
    ],
    [
      39.704029,
      55.252072
    ],
    [
      39.70440438639814,
      55.25289860431412
    ],
    [
      39.708807,
      55.252492
    ],
    [
      39.717991,
      55.244995
    ],
    [
      39.718913,
      55.235903
    ],
    [
      39.726481,
      55.22817
    ],
    [
      39.725512,
      55.217246
    ],
    [
      39.721732,
      55.209206
    ],
    [
      39.717592,
      55.205942
    ],
    [
      39.719822,
      55.203115
    ],
    [
      39.723961,
      55.206379
    ],
    [
      39.728101,
      55.209644
    ],
    [
      39.72850304165864,
      55.21125067094387
    ],
    [
      39.728798,
      55.211235
    ],
    [
      39.730789,
      55.220386
    ],
    [
      39.732636,
      55.224378
    ],
    [
      39.732161699543845,
      55.225763977978524
    ],
    [
      39.73259,
      55.225982
    ],
    [
      39.731056,
      55.228995
    ],
    [
      39.729918,
      55.232448
    ],
    [
      39.72694,
      55.235592
    ],
    [
      39.724662,
      55.240197
    ],
    [
      39.722703,
      55.24314
    ],
    [
      39.728625,
      55.252084
    ],
    [
      39.7367,
      55.251425
    ],
    [
      39.743206,
      55.251746
    ],
    [
      39.751728,
      55.250891
    ],
    [
      39.755653,
      55.249732
    ],
    [
      39.766452,
      55.245477
    ],
    [
      39.767449,
      55.245284
    ],
    [
      39.768081,
      55.24525
    ],
    [
      39.770971,
      55.24712
    ],
    [
      39.771148657232935,
      55.24737609168968
    ],
    [
      39.771987,
      55.247132
    ],
    [
      39.776309,
      55.247108
    ],
    [
      39.790514,
      55.245695
    ],
    [
      39.795327,
      55.244848
    ],
    [
      39.798568,
      55.234429
    ],
    [
      39.798598844353634,
      55.23434524419087
    ],
    [
      39.798267,
      55.232311
    ],
    [
      39.794154,
      55.230614
    ],
    [
      39.790204,
      55.229023
    ],
    [
      39.78922634957149,
      55.22831800611449
    ],
    [
      39.787724,
      55.227618
    ],
    [
      39.780255,
      55.228894
    ],
    [
      39.778668,
      55.228909
    ],
    [
      39.776815,
      55.228341
    ],
    [
      39.776885,
      55.224742
    ],
    [
      39.784964,
      55.216871
    ],
    [
      39.785536,
      55.216274
    ],
    [
      39.787898,
      55.215625
    ],
    [
      39.791208,
      55.214681
    ],
    [
      39.7918,
      55.214882
    ],
    [
      39.792031,
      55.21496
    ],
    [
      39.796029,
      55.216322
    ],
    [
      39.800019,
      55.217682
    ],
    [
      39.80204532727376,
      55.21914080196872
    ],
    [
      39.802642,
      55.218921
    ],
    [
      39.803611,
      55.218636
    ],
    [
      39.803774,
      55.218594
    ],
    [
      39.81298296985326,
      55.220830845431024
    ],
    [
      39.816893,
      55.215659
    ],
    [
      39.817284,
      55.215463
    ],
    [
      39.82623,
      55.22271
    ],
    [
      39.824858,
      55.226039
    ],
    [
      39.823595,
      55.225518
    ],
    [
      39.821178,
      55.22466
    ],
    [
      39.8137266625588,
      55.225629847382685
    ],
    [
      39.8100558824207,
      55.22889330442609
    ],
    [
      39.807007,
      55.23562
    ],
    [
      39.813377,
      55.244294
    ],
    [
      39.82046,
      55.242573
    ],
    [
      39.824929,
      55.242504
    ],
    [
      39.829397,
      55.242435
    ],
    [
      39.8293970081955,
      55.24243552685333
    ],
    [
      39.831896,
      55.242397
    ],
    [
      39.835723,
      55.242295
    ],
    [
      39.842536,
      55.242174
    ],
    [
      39.845739,
      55.242108
    ],
    [
      39.848943,
      55.242041
    ],
    [
      39.8489430304679,
      55.242042462052936
    ],
    [
      39.849891,
      55.242023
    ],
    [
      39.856653,
      55.241881
    ],
    [
      39.860399,
      55.241812
    ],
    [
      39.86149,
      55.241792
    ],
    [
      39.861557,
      55.245391
    ],
    [
      39.857791,
      55.25295
    ],
    [
      39.856669,
      55.253379
    ],
    [
      39.852861,
      55.253691
    ],
    [
      39.8482,
      55.253661
    ],
    [
      39.843467631046,
      55.253458848400086
    ],
    [
      39.843322,
      55.253664
    ],
    [
      39.840445,
      55.253648
    ],
    [
      39.836495,
      55.253161
    ],
    [
      39.827063,
      55.251914
    ],
    [
      39.82219152720246,
      55.249684807152796
    ],
    [
      39.819941,
      55.249878
    ],
    [
      39.816457,
      55.250786
    ],
    [
      39.809367,
      55.258743
    ],
    [
      39.805786,
      55.259116
    ],
    [
      39.805446,
      55.255855
    ],
    [
      39.805698,
      55.253514
    ],
    [
      39.795951,
      55.248394
    ],
    [
      39.79325798613367,
      55.24886792120191
    ],
    [
      39.793258,
      55.248868
    ],
    [
      39.790496,
      55.251208
    ],
    [
      39.786896,
      55.251193
    ],
    [
      39.776498,
      55.250703
    ],
    [
      39.77023672498061,
      55.26066695592785
    ],
    [
      39.770402,
      55.261911
    ],
    [
      39.774354,
      55.267578
    ],
    [
      39.775386,
      55.269644
    ],
    [
      39.775386,
      55.270455
    ],
    [
      39.77520400274977,
      55.270455
    ],
    [
      39.775321,
      55.270851
    ],
    [
      39.775321,
      55.271133
    ],
    [
      39.769629,
      55.281583
    ],
    [
      39.775862,
      55.283774
    ],
    [
      39.780407,
      55.286508
    ],
    [
      39.782243,
      55.287686
    ],
    [
      39.78340871006747,
      55.288434976659154
    ],
    [
      39.78749,
      55.291055
    ],
    [
      39.794572,
      55.295602
    ],
    [
      39.80225,
      55.293183
    ],
    [
      39.807328,
      55.295738
    ],
    [
      39.810572,
      55.298176
    ],
    [
      39.810853,
      55.300028
    ],
    [
      39.810723195096806,
      55.30108823128957
    ],
    [
      39.814228,
      55.30176
    ],
    [
      39.817952,
      55.302097
    ],
    [
      39.822853,
      55.302308
    ],
    [
      39.825597,
      55.302289
    ],
    [
      39.827795,
      55.30223
    ],
    [
      39.82779718403067,
      55.30231187839977
    ],
    [
      39.829227,
      55.302313
    ],
    [
      39.834754,
      55.302151
    ],
    [
      39.838786,
      55.302254
    ],
    [
      39.840402,
      55.302421
    ],
    [
      39.840848,
      55.302451
    ],
    [
      39.840608,
      55.306043
    ],
    [
      39.834398,
      55.314451
    ],
    [
      39.83432,
      55.314589
    ],
    [
      39.831915,
      55.31813
    ],
    [
      39.830358,
      55.319049
    ],
    [
      39.828066,
      55.319469
    ],
    [
      39.822047,
      55.31928
    ],
    [
      39.820749,
      55.31936
    ],
    [
      39.814136,
      55.31981
    ],
    [
      39.813239,
      55.319835
    ],
    [
      39.806004,
      55.322091
    ],
    [
      39.805772,
      55.322132
    ],
    [
      39.803548,
      55.321401
    ],
    [
      39.800483,
      55.320434
    ],
    [
      39.800323,
      55.320149
    ],
    [
      39.799711,
      55.316999
    ],
    [
      39.803469,
      55.310531
    ],
    [
      39.7994262268174,
      55.304480690332575
    ],
    [
      39.793876,
      55.302557
    ],
    [
      39.787421,
      55.302446
    ],
    [
      39.786086,
      55.302578
    ],
    [
      39.78594003498358,
      55.301105192425915
    ],
    [
      39.784802,
      55.300897
    ],
    [
      39.78509900405897,
      55.29817359669746
    ],
    [
      39.778462,
      55.289537
    ],
    [
      39.768458,
      55.284987
    ],
    [
      39.76597104945356,
      55.28413185624259
    ],
    [
      39.765971,
      55.284132
    ],
    [
      39.765099,
      55.283832
    ],
    [
      39.761626,
      55.282637
    ],
    [
      39.758055,
      55.281409
    ],
    [
      39.753452,
      55.279823
    ],
    [
      39.74885,
      55.278239
    ],
    [
      39.744214,
      55.276604
    ],
    [
      39.73959,
      55.274973
    ],
    [
      39.734457,
      55.273181
    ],
    [
      39.729141,
      55.271325
    ],
    [
      39.72500114992872,
      55.26986834997493
    ],
    [
      39.724227,
      55.269596
    ],
    [
      39.720654,
      55.268338
    ],
    [
      39.716652,
      55.266917
    ],
    [
      39.706868,
      55.266368
    ],
    [
      39.704546,
      55.269125
    ],
    [
      39.702649,
      55.272024
    ],
    [
      39.70194,
      55.273201
    ],
    [
      39.698857,
      55.271342
    ],
    [
      39.692982,
      55.261843
    ],
    [
      39.689103,
      55.260364
    ],
    [
      39.682862,
      55.270597
    ],
    [
      39.685203,
      55.273252
    ],
    [
      39.686407,
      55.274713
    ],
    [
      39.68784,
      55.276827
    ],
    [
      39.687829424115485,
      55.27683416888816
    ],
    [
      39.688006,
      55.277075
    ],
    [
      39.690816,
      55.281604
    ],
    [
      39.697707,
      55.290347
    ],
    [
      39.705555,
      55.295854
    ],
    [
      39.705572,
      55.299454
    ],
    [
      39.700287,
      55.299382
    ],
    [
      39.695471,
      55.309917
    ],
    [
      39.69131,
      55.317445
    ],
    [
      39.698113,
      55.323363
    ],
    [
      39.7025,
      55.323966
    ],
    [
      39.706115,
      55.324109
    ],
    [
      39.707316,
      55.324181
    ],
    [
      39.70880129067633,
      55.32451389705214
    ],
    [
      39.710653,
      55.324793
    ],
    [
      39.714179,
      55.325064
    ],
    [
      39.725646,
      55.327576
    ],
    [
      39.732632,
      55.323813
    ],
    [
      39.73308,
      55.323563
    ],
    [
      39.734835,
      55.326706
    ],
    [
      39.733475,
      55.3281
    ],
    [
      39.730144,
      55.330082
    ],
    [
      39.726294,
      55.331117
    ],
    [
      39.723323229817595,
      55.33186439776376
    ],
    [
      39.722972,
      55.332001
    ],
    [
      39.719191,
      55.332904
    ],
    [
      39.708685,
      55.328169
    ],
    [
      39.700498,
      55.328028
    ],
    [
      39.693172,
      55.326253
    ],
    [
      39.69290186663952,
      55.32611755837488
    ],
    [
      39.692888,
      55.326212
    ],
    [
      39.688892,
      55.325626
    ],
    [
      39.685067,
      55.320736
    ],
    [
      39.679161,
      55.315221
    ],
    [
      39.673033,
      55.317607
    ],
    [
      39.67115366550788,
      55.31953200165127
    ],
    [
      39.669807,
      55.321451
    ],
    [
      39.669806912968376,
      55.32145093895679
    ],
    [
      39.6693,
      55.322174
    ],
    [
      39.66881371171583,
      55.32192881548749
    ],
    [
      39.666572,
      55.324225
    ],
    [
      39.665149,
      55.331451
    ],
    [
      39.672106,
      55.337035
    ],
    [
      39.676495,
      55.337114
    ],
    [
      39.680883,
      55.337194
    ],
    [
      39.680818,
      55.340793
    ],
    [
      39.673151,
      55.344287
    ],
    [
      39.661335,
      55.34484
    ],
    [
      39.659937,
      55.341522
    ],
    [
      39.651444,
      55.335699
    ],
    [
      39.646975,
      55.335007
    ],
    [
      39.646321,
      55.334607
    ],
    [
      39.643864,
      55.331976
    ],
    [
      39.644022,
      55.331829
    ],
    [
      39.649073,
      55.327116
    ],
    [
      39.650053,
      55.327511
    ],
    [
      39.660033,
      55.323385
    ],
    [
      39.650891,
      55.315717
    ],
    [
      39.650347,
      55.315329
    ],
    [
      39.652439,
      55.312399
    ],
    [
      39.655666,
      55.312955
    ],
    [
      39.663811558375315,
      55.31555994937446
    ],
    [
      39.667706,
      55.314489
    ],
    [
      39.674419,
      55.312004
    ],
    [
      39.679138,
      55.311621
    ],
    [
      39.679388560873264,
      55.311610609644276
    ],
    [
      39.679388,
      55.311602
    ],
    [
      39.682436,
      55.311403
    ],
    [
      39.686318,
      55.300864
    ],
    [
      39.682773,
      55.289741
    ],
    [
      39.678785,
      55.288678
    ],
    [
      39.671612,
      55.290982
    ],
    [
      39.668285,
      55.292278
    ],
    [
      39.661471,
      55.293216
    ],
    [
      39.654249,
      55.292915
    ],
    [
      39.653179,
      55.292813
    ],
    [
      39.65298,
      55.292784
    ],
    [
      39.6535,
      55.289222
    ],
    [
      39.658059,
      55.285691
    ],
    [
      39.658793,
      55.286297
    ],
    [
      39.667157317600505,
      55.28772679788043
    ],
    [
      39.672571,
      55.285164
    ],
    [
      39.676436,
      55.284833
    ],
    [
      39.682246,
      55.275342
    ],
    [
      39.679682,
      55.272416
    ],
    [
      39.677859,
      55.269124
    ],
    [
      39.675874,
      55.26591
    ],
    [
      39.66722,
      55.260527
    ],
    [
      39.659027,
      55.257057
    ],
    [
      39.65869889988364,
      55.25727918333962
    ],
    [
      39.657714,
      55.259032
    ],
    [
      39.652445,
      55.261647
    ],
    [
      39.650370099303096,
      55.26295878325265
    ],
    [
      39.649699,
      55.264722
    ],
    [
      39.649263,
      55.265435
    ],
    [
      39.64498657736732,
      55.27308876159605
    ],
    [
      39.64492,
      55.274186
    ],
    [
      39.645227,
      55.276502
    ],
    [
      39.645149,
      55.27872
    ],
    [
      39.64471319620537,
      55.27870473838851
    ],
    [
      39.644646,
      55.280173
    ],
    [
      39.638864,
      55.286393
    ],
    [
      39.636642,
      55.283347
    ],
    [
      39.639196,
      55.279633
    ],
    [
      39.6341,
      55.270947
    ],
    [
      39.622633,
      55.273117
    ],
    [
      39.621262,
      55.276446
    ],
    [
      39.61391,
      55.270418
    ],
    [
      39.60417,
      55.27201
    ],
    [
      39.597693,
      55.271939
    ],
    [
      39.5924,
      55.271558
    ],
    [
      39.58892,
      55.271957
    ],
    [
      39.585239,
      55.272519
    ],
    [
      39.580867,
      55.273247
    ],
    [
      39.576706396771954,
      55.2800228158296
    ],
    [
      39.578453,
      55.284299
    ],
    [
      39.581049206546616,
      55.289536754345974
    ],
    [
      39.585779,
      55.29456
    ],
    [
      39.589731,
      55.296463
    ],
    [
      39.595362,
      55.296998
    ],
    [
      39.598938,
      55.297406
    ],
    [
      39.602163,
      55.299321
    ],
    [
      39.609278,
      55.300662
    ],
    [
      39.613305,
      55.302284
    ],
    [
      39.617335,
      55.303085
    ],
    [
      39.62128,
      55.304335
    ],
    [
      39.625483,
      55.305688
    ],
    [
      39.63104,
      55.305775
    ],
    [
      39.634886,
      55.306031
    ],
    [
      39.638622,
      55.306956
    ],
    [
      39.636461,
      55.31009
    ],
    [
      39.630853,
      55.30937
    ],
    [
      39.624976,
      55.309252
    ],
    [
      39.620324,
      55.31014
    ],
    [
      39.612313,
      55.305745
    ],
    [
      39.602425,
      55.303754
    ],
    [
      39.595771,
      55.300766
    ],
    [
      39.591655,
      55.30037
    ],
    [
      39.587703,
      55.299438
    ],
    [
      39.584312,
      55.297847
    ],
    [
      39.578861,
      55.296443
    ],
    [
      39.574949,
      55.295847
    ],
    [
      39.570509,
      55.294833
    ],
    [
      39.563211,
      55.294053
    ],
    [
      39.557805,
      55.293905
    ],
    [
      39.556491,
      55.304545
    ],
    [
      39.559994,
      55.307087
    ],
    [
      39.570139,
      55.311224
    ],
    [
      39.575051,
      55.311108
    ],
    [
      39.581037,
      55.311168
    ],
    [
      39.58047,
      55.314723
    ],
    [
      39.575066,
      55.314708
    ],
    [
      39.570646,
      55.314788
    ],
    [
      39.565534,
      55.316628
    ],
    [
      39.561942,
      55.318348
    ],
    [
      39.560812,
      55.31493
    ],
    [
      39.55816038893367,
      55.311186892428694
    ],
    [
      39.558004,
      55.311258
    ],
    [
      39.549563,
      55.308538
    ],
    [
      39.543029,
      55.308383
    ],
    [
      39.53542,
      55.315701
    ],
    [
      39.533068,
      55.319046
    ],
    [
      39.529746,
      55.320342
    ],
    [
      39.525243,
      55.320899
    ],
    [
      39.521257,
      55.321532
    ],
    [
      39.516394,
      55.323112
    ],
    [
      39.512699,
      55.323686
    ],
    [
      39.511864,
      55.320085
    ],
    [
      39.515862,
      55.319551
    ],
    [
      39.520271,
      55.31807
    ],
    [
      39.52311952148048,
      55.31760225732798
    ],
    [
      39.523111,
      55.317504
    ],
    [
      39.529812,
      55.311073
    ],
    [
      39.525825,
      55.304349
    ],
    [
      39.525371,
      55.300743
    ],
    [
      39.524034,
      55.296995
    ],
    [
      39.51621467958709,
      55.29471965860954
    ],
    [
      39.516265,
      55.29555
    ],
    [
      39.515145,
      55.295938
    ],
    [
      39.507194,
      55.296506
    ],
    [
      39.50190899709198,
      55.29714716879412
    ],
    [
      39.500969,
      55.297431
    ],
    [
      39.497713162976936,
      55.29849344413951
    ],
    [
      39.492187,
      55.306251
    ],
    [
      39.492061,
      55.310018
    ],
    [
      39.488575,
      55.311546
    ],
    [
      39.48178498927644,
      55.3181415812657
    ],
    [
      39.479083,
      55.323539
    ],
    [
      39.47855,
      55.327139
    ],
    [
      39.475977,
      55.321856
    ],
    [
      39.47741,
      55.317503
    ],
    [
      39.479576,
      55.314677
    ],
    [
      39.480883,
      55.310735
    ],
    [
      39.483938,
      55.308348
    ],
    [
      39.48838899471147,
      55.3012881350181
    ],
    [
      39.473971928961625,
      55.29057728981133
    ],
    [
      39.471709,
      55.291789
    ],
    [
      39.464678,
      55.298946
    ],
    [
      39.465358,
      55.302481
    ],
    [
      39.45467552875962,
      55.30186064531796
    ],
    [
      39.455617,
      55.310176
    ],
    [
      39.45361846597631,
      55.31181037598033
    ],
    [
      39.453704,
      55.312686
    ],
    [
      39.45239,
      55.312815
    ],
    [
      39.44875,
      55.31318
    ],
    [
      39.44498,
      55.313465
    ],
    [
      39.441559457788486,
      55.313719242702966
    ],
    [
      39.44156,
      55.313727
    ],
    [
      39.439733,
      55.313855
    ],
    [
      39.435831,
      55.313791
    ],
    [
      39.432241,
      55.312768
    ],
    [
      39.428586,
      55.311458
    ],
    [
      39.421416,
      55.320549
    ],
    [
      39.419589,
      55.331417
    ],
    [
      39.423227,
      55.334005
    ],
    [
      39.426841,
      55.336576
    ],
    [
      39.434196,
      55.339583
    ],
    [
      39.437337,
      55.340106
    ],
    [
      39.437757,
      55.340272
    ],
    [
      39.437545589827394,
      55.340807401859216
    ],
    [
      39.438212,
      55.341051
    ],
    [
      39.446637,
      55.342164
    ],
    [
      39.451332,
      55.34146
    ],
    [
      39.456074,
      55.341307
    ],
    [
      39.458755,
      55.344643
    ],
    [
      39.450654,
      55.350215
    ],
    [
      39.448643,
      55.35319
    ],
    [
      39.439072,
      55.35651
    ],
    [
      39.431969,
      55.361767
    ],
    [
      39.426529,
      55.362421
    ],
    [
      39.423045,
      55.36172
    ],
    [
      39.413327116830914,
      55.3616868730672
    ],
    [
      39.411589,
      55.36227
    ],
    [
      39.409582,
      55.362831
    ],
    [
      39.40552368534564,
      55.363309943685124
    ],
    [
      39.404633,
      55.364191
    ],
    [
      39.401823,
      55.364735
    ],
    [
      39.397725,
      55.364005
    ],
    [
      39.388951,
      55.362645
    ],
    [
      39.382232,
      55.369343
    ],
    [
      39.381220227646885,
      55.37066200182737
    ],
    [
      39.3813,
      55.370701
    ],
    [
      39.380952,
      55.371415
    ],
    [
      39.379128,
      55.373766
    ],
    [
      39.370295,
      55.373542
    ],
    [
      39.373586,
      55.384419
    ],
    [
      39.37562518952506,
      55.38891831759347
    ],
    [
      39.376241,
      55.388597
    ],
    [
      39.38492205872335,
      55.394307479518126
    ],
    [
      39.387808,
      55.394885
    ],
    [
      39.387908614035474,
      55.39489482999701
    ],
    [
      39.389856,
      55.39508
    ],
    [
      39.38985551880448,
      55.395085042703954
    ],
    [
      39.393069,
      55.395399
    ],
    [
      39.401788,
      55.394236
    ],
    [
      39.406274,
      55.392405
    ],
    [
      39.413387,
      55.390968
    ],
    [
      39.42065,
      55.390299
    ],
    [
      39.425325,
      55.391149
    ],
    [
      39.428961,
      55.391442
    ],
    [
      39.434329,
      55.391807
    ],
    [
      39.434889,
      55.391867
    ],
    [
      39.43487733972229,
      55.39197570779735
    ],
    [
      39.43781,
      55.392878
    ],
    [
      39.438702,
      55.396602
    ],
    [
      39.433946,
      55.395387
    ],
    [
      39.429386,
      55.395531
    ],
    [
      39.425569,
      55.394741
    ],
    [
      39.42033,
      55.393885
    ],
    [
      39.413903,
      55.394531
    ],
    [
      39.407745,
      55.395959
    ],
    [
      39.403267,
      55.397494
    ],
    [
      39.40012,
      55.39903
    ],
    [
      39.395784,
      55.399279
    ],
    [
      39.39505,
      55.411152
    ],
    [
      39.404821,
      55.41699
    ],
    [
      39.408045,
      55.419582
    ],
    [
      39.40824885471373,
      55.419854107221965
    ],
    [
      39.408249,
      55.419854
    ],
    [
      39.40826335977243,
      55.419873468713064
    ],
    [
      39.41011196401157,
      55.4223410033352
    ],
    [
      39.41212825448288,
      55.42441013916078
    ],
    [
      39.421692,
      55.428629
    ],
    [
      39.423754,
      55.428917
    ],
    [
      39.425534,
      55.429806
    ],
    [
      39.42709034111433,
      55.43079035590172
    ],
    [
      39.433335,
      55.425956
    ],
    [
      39.431912,
      55.42265
    ],
    [
      39.428497,
      55.419829
    ],
    [
      39.428951,
      55.416258
    ],
    [
      39.431027,
      55.416522
    ],
    [
      39.433104,
      55.416786
    ],
    [
      39.440129,
      55.41947
    ],
    [
      39.44252891899425,
      55.41981556261888
    ],
    [
      39.442529,
      55.419815
    ],
    [
      39.44566899432951,
      55.420267039395036
    ],
    [
      39.445669,
      55.420267
    ],
    [
      39.4502882146437,
      55.42093200569519
    ],
    [
      39.45303673533627,
      55.421004932812366
    ],
    [
      39.46284629509987,
      55.419731091536306
    ],
    [
      39.463615387489014,
      55.41945414026177
    ],
    [
      39.46528315921262,
      55.418853220999786
    ],
    [
      39.46658,
      55.412508
    ],
    [
      39.466835322198165,
      55.411360682603146
    ],
    [
      39.46575,
      55.406614
    ],
    [
      39.466052,
      55.403027
    ],
    [
      39.468039,
      55.403194
    ],
    [
      39.469436,
      55.403535
    ],
    [
      39.473201,
      55.404806
    ],
    [
      39.476664,
      55.406135
    ],
    [
      39.480187,
      55.407814
    ],
    [
      39.487206,
      55.409214
    ],
    [
      39.49522,
      55.404606
    ],
    [
      39.498836,
      55.397279
    ],
    [
      39.498242,
      55.392089
    ],
    [
      39.498042,
      55.390204
    ],
    [
      39.497841,
      55.388319
    ],
    [
      39.49784327660243,
      55.38831875834946
    ],
    [
      39.497801,
      55.387919
    ],
    [
      39.497237,
      55.383794
    ],
    [
      39.497074,
      55.38267
    ],
    [
      39.49707625068022,
      55.38266967332912
    ],
    [
      39.497062,
      55.38257
    ],
    [
      39.496902,
      55.380831
    ],
    [
      39.49814686231589,
      55.38071675740532
    ],
    [
      39.498284,
      55.377861
    ],
    [
      39.496931,
      55.366402
    ],
    [
      39.496089,
      55.362902
    ],
    [
      39.501018,
      55.360208
    ],
    [
      39.504506,
      55.361097
    ],
    [
      39.514441,
      55.362635
    ],
    [
      39.524608,
      55.357086
    ],
    [
      39.527909,
      55.349815
    ],
    [
      39.531621,
      55.348231
    ],
    [
      39.53668,
      55.346588
    ],
    [
      39.541833,
      55.345265
    ],
    [
      39.547121,
      55.343863
    ],
    [
      39.551242,
      55.343054
    ],
    [
      39.556736,
      55.341906
    ],
    [
      39.56208572506074,
      55.34091911008012
    ],
    [
      39.56221664858217,
      55.34088838039023
    ],
    [
      39.567315,
      55.334011
    ],
    [
      39.570708,
      55.331093
    ],
    [
      39.579623,
      55.331964
    ],
    [
      39.583617,
      55.331212
    ],
    [
      39.58728,
      55.33111
    ],
    [
      39.592609,
      55.331747
    ],
    [
      39.596522,
      55.33239
    ],
    [
      39.60016,
      55.333252
    ],
    [
      39.607571,
      55.336191
    ],
    [
      39.617544,
      55.341406
    ],
    [
      39.625942,
      55.341516
    ],
    [
      39.629444,
      55.340943
    ],
    [
      39.631535,
      55.34085
    ],
    [
      39.63196,
      55.340869
    ],
    [
      39.631797,
      55.344466
    ],
    [
      39.629138,
      55.344682
    ],
    [
      39.62551,
      55.34526
    ],
    [
      39.621683,
      55.34591
    ],
    [
      39.617244,
      55.344994
    ],
    [
      39.612611,
      55.34413
    ],
    [
      39.609063,
      55.343162
    ],
    [
      39.606158,
      55.340769
    ],
    [
      39.602668,
      55.338763
    ],
    [
      39.599323,
      55.337297
    ],
    [
      39.592281,
      55.335423
    ],
    [
      39.588049,
      55.33474
    ],
    [
      39.584489,
      55.334776
    ],
    [
      39.574368,
      55.340914
    ],
    [
      39.570089,
      55.34202
    ],
    [
      39.56334,
      55.344315
    ],
    [
      39.56313232184463,
      55.344354358912106
    ],
    [
      39.563136,
      55.34437
    ],
    [
      39.560698,
      55.344943
    ],
    [
      39.557272,
      55.345465
    ],
    [
      39.553675,
      55.346226
    ],
    [
      39.549755,
      55.347269
    ],
    [
      39.545345,
      55.348088
    ],
    [
      39.540138,
      55.349408
    ],
    [
      39.535023,
      55.350739
    ],
    [
      39.531955,
      55.359414
    ],
    [
      39.530025,
      55.362558
    ],
    [
      39.518672,
      55.365071
    ],
    [
      39.511923,
      55.372856
    ],
    [
      39.522045,
      55.375479
    ],
    [
      39.529523,
      55.376415
    ],
    [
      39.535582,
      55.376257
    ],
    [
      39.536566,
      55.37972
    ],
    [
      39.535277,
      55.380086
    ],
    [
      39.533759,
      55.380409
    ],
    [
      39.530024,
      55.380067
    ],
    [
      39.525699,
      55.379825
    ],
    [
      39.521238,
      55.378987
    ],
    [
      39.515622,
      55.379319
    ],
    [
      39.50847,
      55.380105
    ],
    [
      39.501605,
      55.389666
    ],
    [
      39.502106,
      55.393887
    ],
    [
      39.50818,
      55.403515
    ],
    [
      39.514889,
      55.403245
    ],
    [
      39.519434,
      55.4031
    ],
    [
      39.524058,
      55.402953
    ],
    [
      39.529003,
      55.403677
    ],
    [
      39.539543,
      55.407564
    ],
    [
      39.542036,
      55.407763
    ],
    [
      39.54453,
      55.407962
    ],
    [
      39.54452997250555,
      55.40796234502648
    ],
    [
      39.544726,
      55.407978
    ],
    [
      39.54472362021716,
      55.40800775143137
    ],
    [
      39.550449,
      55.407613
    ],
    [
      39.562206,
      55.407479
    ],
    [
      39.570833,
      55.407412
    ],
    [
      39.574868,
      55.407475
    ],
    [
      39.584463,
      55.40651
    ],
    [
      39.592315,
      55.406836
    ],
    [
      39.593399185499365,
      55.40875053037069
    ],
    [
      39.599458,
      55.406398
    ],
    [
      39.602894,
      55.403252
    ],
    [
      39.604547,
      55.399778
    ],
    [
      39.605921,
      55.396503
    ],
    [
      39.599373,
      55.387316
    ],
    [
      39.594408,
      55.386872
    ],
    [
      39.59061,
      55.386387
    ],
    [
      39.586388,
      55.386328
    ],
    [
      39.582694,
      55.386276
    ],
    [
      39.577808,
      55.386416
    ],
    [
      39.574077,
      55.38612
    ],
    [
      39.570474,
      55.386261
    ],
    [
      39.566448,
      55.386873
    ],
    [
      39.56496,
      55.3834
    ],
    [
      39.568715,
      55.382762
    ],
    [
      39.573394,
      55.382586
    ],
    [
      39.578376,
      55.382861
    ],
    [
      39.582881,
      55.382681
    ],
    [
      39.586533,
      55.382731
    ],
    [
      39.590737,
      55.382789
    ],
    [
      39.596033,
      55.383038
    ],
    [
      39.600276,
      55.383831
    ],
    [
      39.607953,
      55.375975
    ],
    [
      39.612016,
      55.369342
    ],
    [
      39.610069,
      55.36269
    ],
    [
      39.612989,
      55.360598
    ],
    [
      39.620859,
      55.359145
    ],
    [
      39.624875,
      55.360712
    ],
    [
      39.618129,
      55.363738
    ],
    [
      39.617243,
      55.37095
    ],
    [
      39.616884,
      55.374463
    ],
    [
      39.613652,
      55.385143
    ],
    [
      39.611612,
      55.392685
    ],
    [
      39.609803,
      55.396826
    ],
    [
      39.60788073203609,
      55.401139766466706
    ],
    [
      39.606501,
      55.404358
    ],
    [
      39.60607456111791,
      55.404821431549976
    ],
    [
      39.605806,
      55.405368
    ],
    [
      39.60405934167616,
      55.40701146700088
    ],
    [
      39.603792,
      55.407302
    ],
    [
      39.601896861854044,
      55.40904618906368
    ],
    [
      39.601896,
      55.409047
    ],
    [
      39.601895973592455,
      55.40904700657461
    ],
    [
      39.600013,
      55.41078
    ],
    [
      39.59425632043496,
      55.41239722166865
    ],
    [
      39.594278,
      55.412499
    ],
    [
      39.59059,
      55.414956
    ],
    [
      39.5835596460687,
      55.41316177241774
    ],
    [
      39.583518,
      55.413296
    ],
    [
      39.580900562519446,
      55.41248314356147
    ],
    [
      39.5809,
      55.412483
    ],
    [
      39.576389,
      55.411373
    ],
    [
      39.570795,
      55.411012
    ],
    [
      39.562234,
      55.411079
    ],
    [
      39.550525,
      55.411212
    ],
    [
      39.545124,
      55.411582
    ],
    [
      39.5444417101112,
      55.4115321188885
    ],
    [
      39.544439,
      55.411566
    ],
    [
      39.54424403988346,
      55.411550499504436
    ],
    [
      39.544244,
      55.411551
    ],
    [
      39.542479951219974,
      55.41141024390248
    ],
    [
      39.541848,
      55.41136
    ],
    [
      39.535435,
      55.413761
    ],
    [
      39.534444,
      55.424413
    ],
    [
      39.534152,
      55.424621
    ],
    [
      39.528261,
      55.430509
    ],
    [
      39.525433,
      55.43304
    ],
    [
      39.517457,
      55.440201
    ],
    [
      39.516437,
      55.443653
    ],
    [
      39.51794822834186,
      55.45183438259678
    ],
    [
      39.518081,
      55.451738
    ],
    [
      39.520080014226075,
      55.45359450968285
    ],
    [
      39.520616,
      55.45383
    ],
    [
      39.53178580453987,
      55.45768293257763
    ],
    [
      39.533059,
      55.457561
    ],
    [
      39.537592,
      55.45713
    ],
    [
      39.54173,
      55.456621
    ],
    [
      39.543581,
      55.456238
    ],
    [
      39.545432,
      55.455856
    ],
    [
      39.545432065398096,
      55.45585631674956
    ],
    [
      39.545831,
      55.455774
    ],
    [
      39.54583155021117,
      55.45577666414063
    ],
    [
      39.54651,
      55.455637
    ],
    [
      39.553616,
      55.448459
    ],
    [
      39.553515,
      55.445682
    ],
    [
      39.553414,
      55.442904
    ],
    [
      39.55509401530605,
      55.44284329905787
    ],
    [
      39.555166,
      55.442535
    ],
    [
      39.55537,
      55.438941
    ],
    [
      39.556307,
      55.435875
    ],
    [
      39.558064,
      55.435205
    ],
    [
      39.55818372619279,
      55.43528962866186
    ],
    [
      39.558326,
      55.435105
    ],
    [
      39.567073,
      55.429278
    ],
    [
      39.571083,
      55.430021
    ],
    [
      39.568681,
      55.435131
    ],
    [
      39.576925,
      55.441439
    ],
    [
      39.580354,
      55.440643
    ],
    [
      39.579628,
      55.444492
    ],
    [
      39.574985,
      55.445931
    ],
    [
      39.576181,
      55.456984
    ],
    [
      39.581205,
      55.458656
    ],
    [
      39.58411,
      55.45961
    ],
    [
      39.585628,
      55.460192
    ],
    [
      39.58562743850741,
      55.46019346519922
    ],
    [
      39.585675,
      55.46021
    ],
    [
      39.590027,
      55.461963
    ],
    [
      39.593753,
      55.462844
    ],
    [
      39.597073,
      55.463197
    ],
    [
      39.59707283690081,
      55.46319852852123
    ],
    [
      39.597508,
      55.463245
    ],
    [
      39.60115,
      55.464318
    ],
    [
      39.611871,
      55.469322
    ],
    [
      39.61623,
      55.470187
    ],
    [
      39.620046,
      55.470967
    ],
    [
      39.627824,
      55.471652
    ],
    [
      39.632721,
      55.471316
    ],
    [
      39.641629,
      55.470138
    ],
    [
      39.647006,
      55.468364
    ],
    [
      39.651901,
      55.467422
    ],
    [
      39.651915379026704,
      55.46741923344707
    ],
    [
      39.65945,
      55.463231
    ],
    [
      39.662216,
      55.460785
    ],
    [
      39.657872,
      55.449954
    ],
    [
      39.653773,
      55.448022
    ],
    [
      39.651147,
      55.445015
    ],
    [
      39.656597,
      55.445335
    ],
    [
      39.660066,
      55.447001
    ],
    [
      39.665865,
      55.450173
    ],
    [
      39.66863,
      55.453354
    ],
    [
      39.669102,
      55.45936
    ],
    [
      39.677392,
      55.4618
    ],
    [
      39.684015,
      55.452816
    ],
    [
      39.68221,
      55.449761
    ],
    [
      39.679095,
      55.446826
    ],
    [
      39.681662,
      55.444171
    ],
    [
      39.685296,
      55.446959
    ],
    [
      39.686995,
      55.450565
    ],
    [
      39.690384,
      55.455969
    ],
    [
      39.705531,
      55.45861
    ],
    [
      39.717731,
      55.453304
    ],
    [
      39.717943,
      55.451151
    ],
    [
      39.71798552112361,
      55.45115516664277
    ],
    [
      39.718289,
      55.448593
    ],
    [
      39.718814,
      55.444472
    ],
    [
      39.713909,
      55.435354
    ],
    [
      39.708552,
      55.434314
    ],
    [
      39.700997,
      55.430662
    ],
    [
      39.697813,
      55.428982
    ],
    [
      39.698488,
      55.425824
    ],
    [
      39.692655,
      55.423298
    ],
    [
      39.689166,
      55.422719
    ],
    [
      39.685486,
      55.421651
    ],
    [
      39.682583,
      55.421352
    ],
    [
      39.682952,
      55.417771
    ],
    [
      39.685912974141516,
      55.414040883080155
    ],
    [
      39.6826,
      55.412123
    ],
    [
      39.677357,
      55.40893
    ],
    [
      39.673074,
      55.40311
    ],
    [
      39.674964,
      55.399884
    ],
    [
      39.676665,
      55.396753
    ],
    [
      39.68265,
      55.394323
    ],
    [
      39.687523,
      55.392881
    ],
    [
      39.689305,
      55.396009
    ],
    [
      39.687957,
      55.396777
    ],
    [
      39.683408,
      55.397842
    ],
    [
      39.684422,
      55.409018
    ],
    [
      39.68844719787454,
      55.411210126542
    ],
    [
      39.694226,
      55.412491
    ],
    [
      39.696588,
      55.415588
    ],
    [
      39.700304,
      55.420055
    ],
    [
      39.70235306367886,
      55.42419498212203
    ],
    [
      39.704826,
      55.424048
    ],
    [
      39.708412,
      55.423735
    ],
    [
      39.71459,
      55.431819
    ],
    [
      39.723565,
      55.429575
    ],
    [
      39.726858,
      55.427839
    ],
    [
      39.73401,
      55.424006
    ],
    [
      39.73823,
      55.421757
    ],
    [
      39.746641,
      55.414924
    ],
    [
      39.743891,
      55.403725
    ],
    [
      39.742679,
      55.40347
    ],
    [
      39.74303251975857,
      55.401789707706236
    ],
    [
      39.742583,
      55.401611
    ],
    [
      39.739089,
      55.400294
    ],
    [
      39.736729,
      55.397328
    ],
    [
      39.746338,
      55.400266
    ],
    [
      39.749899,
      55.40064
    ],
    [
      39.755015,
      55.400519
    ],
    [
      39.75849,
      55.391599
    ],
    [
      39.761288,
      55.389334
    ],
    [
      39.76424149066944,
      55.38797865102987
    ],
    [
      39.76332430696643,
      55.380079089832705
    ],
    [
      39.757651,
      55.376259
    ],
    [
      39.758869,
      55.372288
    ],
    [
      39.759798,
      55.368324
    ],
    [
      39.76377006788222,
      55.366149731159254
    ],
    [
      39.76412,
      55.365955
    ],
    [
      39.76412135743836,
      55.36595743889269
    ],
    [
      39.764261,
      55.365881
    ],
    [
      39.76863828310183,
      55.360936234505864
    ],
    [
      39.765572,
      55.353854
    ],
    [
      39.764475,
      55.353134
    ],
    [
      39.76456941575763,
      55.352990105604825
    ],
    [
      39.763918,
      55.352734
    ],
    [
      39.758411,
      55.352222
    ],
    [
      39.756439,
      55.352759
    ],
    [
      39.755042,
      55.349442
    ],
    [
      39.757828,
      55.347904
    ],
    [
      39.7654,
      55.344766
    ],
    [
      39.768782,
      55.345999
    ],
    [
      39.778265,
      55.348403
    ],
    [
      39.788346,
      55.348632
    ],
    [
      39.790358,
      55.349015
    ],
    [
      39.790067,
      55.352603
    ],
    [
      39.784903,
      55.354804
    ],
    [
      39.777241,
      55.358758
    ],
    [
      39.7765543012386,
      55.35981000298705
    ],
    [
      39.777479,
      55.361354
    ],
    [
      39.776615,
      55.361871
    ],
    [
      39.771341,
      55.366652
    ],
    [
      39.765290236472204,
      55.37601529684881
    ],
    [
      39.768961,
      55.380995
    ],
    [
      39.767376,
      55.384862
    ],
    [
      39.76738340501586,
      55.386554663209495
    ],
    [
      39.76791,
      55.386564
    ],
    [
      39.769061,
      55.38765
    ],
    [
      39.774407,
      55.39206
    ],
    [
      39.783904777356305,
      55.390975902082566
    ],
    [
      39.78873,
      55.388028
    ],
    [
      39.792624,
      55.387342
    ],
    [
      39.808832,
      55.380332
    ],
    [
      39.810643,
      55.377293
    ],
    [
      39.810908,
      55.37686
    ],
    [
      39.81334464273426,
      55.37613336100839
    ],
    [
      39.81759,
      55.370306
    ],
    [
      39.818421061200986,
      55.368254025854874
    ],
    [
      39.820138,
      55.363789
    ],
    [
      39.82096,
      55.35563
    ],
    [
      39.821795,
      55.354847
    ],
    [
      39.82180081296457,
      55.3548479254959
    ],
    [
      39.822147,
      55.352715
    ],
    [
      39.822459,
      55.350878
    ],
    [
      39.822926,
      55.348235
    ],
    [
      39.826471,
      55.34886
    ],
    [
      39.833112,
      55.350162
    ],
    [
      39.834096,
      55.350266
    ],
    [
      39.834506,
      55.351668
    ],
    [
      39.84353,
      55.359031
    ],
    [
      39.844087,
      55.359192
    ],
    [
      39.846748,
      55.359956
    ],
    [
      39.845754,
      55.363416
    ],
    [
      39.843093,
      55.362652
    ],
    [
      39.835946,
      55.362151
    ],
    [
      39.8328899496269,
      55.362873100777705
    ],
    [
      39.832959,
      55.363136
    ],
    [
      39.832622,
      55.363279
    ],
    [
      39.8293126077053,
      55.363820410119196
    ],
    [
      39.82388273426789,
      55.370262406947845
    ],
    [
      39.824733,
      55.37144
    ],
    [
      39.824108,
      55.372706
    ],
    [
      39.817322,
      55.377792
    ],
    [
      39.81663068989183,
      55.378452796420326
    ],
    [
      39.816657,
      55.37848
    ],
    [
      39.815446,
      55.379651
    ],
    [
      39.814558,
      55.380434
    ],
    [
      39.811043,
      55.383173
    ],
    [
      39.8050582175964,
      55.38586179311667
    ],
    [
      39.805934,
      55.387128
    ],
    [
      39.805321,
      55.387552
    ],
    [
      39.796153,
      55.389298
    ],
    [
      39.791474,
      55.391625
    ],
    [
      39.7885364932279,
      55.392057955344434
    ],
    [
      39.788951,
      55.392888
    ],
    [
      39.788607,
      55.396331
    ],
    [
      39.787492,
      55.399754
    ],
    [
      39.782728,
      55.400507
    ],
    [
      39.777946,
      55.402455
    ],
    [
      39.779681,
      55.412136
    ],
    [
      39.785056,
      55.412288
    ],
    [
      39.790023,
      55.41344
    ],
    [
      39.794527,
      55.414563
    ],
    [
      39.795954,
      55.414946
    ],
    [
      39.796657,
      55.415258
    ],
    [
      39.7958087998421,
      55.41717387283436
    ],
    [
      39.796181,
      55.417932
    ],
    [
      39.802325,
      55.425273
    ],
    [
      39.80031,
      55.428256
    ],
    [
      39.799553,
      55.427745
    ],
    [
      39.79901720460866,
      55.427401388021885
    ],
    [
      39.798986,
      55.427445
    ],
    [
      39.79812,
      55.426826
    ],
    [
      39.794856,
      55.42499
    ],
    [
      39.793243,
      55.421287
    ],
    [
      39.784266,
      55.4158
    ],
    [
      39.779273,
      55.415713
    ],
    [
      39.775344275539496,
      55.41451637403344
    ],
    [
      39.775288,
      55.414642
    ],
    [
      39.774299,
      55.414198
    ],
    [
      39.76844,
      55.411066
    ],
    [
      39.758445,
      55.409893
    ],
    [
      39.757338839412036,
      55.41064376328968
    ],
    [
      39.757339,
      55.410644
    ],
    [
      39.756570886776515,
      55.41116498114289
    ],
    [
      39.754349,
      55.412673
    ],
    [
      39.74952,
      55.417085
    ],
    [
      39.744344,
      55.42212
    ],
    [
      39.74246640073964,
      55.423317905730556
    ],
    [
      39.742495,
      55.423363
    ],
    [
      39.74085,
      55.424406
    ],
    [
      39.740007,
      55.424887
    ],
    [
      39.735704,
      55.427183
    ],
    [
      39.730235,
      55.433249
    ],
    [
      39.731338,
      55.439167
    ],
    [
      39.725558,
      55.441678
    ],
    [
      39.721525,
      55.451496
    ],
    [
      39.72152430803084,
      55.45150193219398
    ],
    [
      39.721525,
      55.451502
    ],
    [
      39.721314,
      55.453656
    ],
    [
      39.720949,
      55.456434
    ],
    [
      39.718451,
      55.461908
    ],
    [
      39.717498,
      55.468926
    ],
    [
      39.716469,
      55.477606
    ],
    [
      39.727074,
      55.482207
    ],
    [
      39.732964,
      55.482163
    ],
    [
      39.73714,
      55.481887
    ],
    [
      39.740196,
      55.481874
    ],
    [
      39.740699,
      55.481904
    ],
    [
      39.74071154328753,
      55.48191782137251
    ],
    [
      39.740964,
      55.48192
    ],
    [
      39.745217,
      55.482547
    ],
    [
      39.748886,
      55.483539
    ],
    [
      39.757772,
      55.481057
    ],
    [
      39.763885,
      55.471458
    ],
    [
      39.767188,
      55.467591
    ],
    [
      39.776774,
      55.473588
    ],
    [
      39.779251,
      55.466256
    ],
    [
      39.782506,
      55.46171
    ],
    [
      39.78523,
      55.453133
    ],
    [
      39.787296,
      55.443181
    ],
    [
      39.788058,
      55.439518
    ],
    [
      39.78810525889347,
      55.439529740331864
    ],
    [
      39.788519,
      55.437663
    ],
    [
      39.788841,
      55.436367
    ],
    [
      39.78884111114406,
      55.436367027611055
    ],
    [
      39.790145,
      55.431121
    ],
    [
      39.793639,
      55.43199
    ],
    [
      39.792013,
      55.438532
    ],
    [
      39.790821,
      55.443913
    ],
    [
      39.78922188922096,
      55.451615976995676
    ],
    [
      39.789222,
      55.451616
    ],
    [
      39.78908195611627,
      55.45229004004401
    ],
    [
      39.788755,
      55.453865
    ],
    [
      39.798572,
      55.460143
    ],
    [
      39.801643,
      55.457803
    ],
    [
      39.804873,
      55.456204
    ],
    [
      39.81067,
      55.448929
    ],
    [
      39.811604,
      55.444492
    ],
    [
      39.81081,
      55.435116
    ],
    [
      39.809706,
      55.429861
    ],
    [
      39.813214,
      55.432373
    ],
    [
      39.816118,
      55.434822
    ],
    [
      39.820309,
      55.437926
    ],
    [
      39.829641711704305,
      55.44377713436076
    ],
    [
      39.841153,
      55.444849
    ],
    [
      39.843233,
      55.445557
    ],
    [
      39.844023,
      55.445963
    ],
    [
      39.842377,
      55.449165
    ],
    [
      39.832564,
      55.449334
    ],
    [
      39.827816,
      55.447138
    ],
    [
      39.819961,
      55.445805
    ],
    [
      39.813533,
      55.454895
    ],
    [
      39.80870524944912,
      55.458561791489835
    ],
    [
      39.811837,
      55.463904
    ],
    [
      39.810415,
      55.467211
    ],
    [
      39.80961,
      55.466865
    ],
    [
      39.803975,
      55.47744
    ],
    [
      39.800922,
      55.483393
    ],
    [
      39.795664,
      55.479632
    ],
    [
      39.7887,
      55.477262
    ],
    [
      39.777789,
      55.477042
    ],
    [
      39.771867,
      55.481477
    ],
    [
      39.768346,
      55.482299
    ],
    [
      39.76641580814605,
      55.484146357709605
    ],
    [
      39.76662221639323,
      55.48894263355853
    ],
    [
      39.770948,
      55.495706
    ],
    [
      39.775057,
      55.494923
    ],
    [
      39.778324,
      55.492351
    ],
    [
      39.779339,
      55.491378
    ],
    [
      39.779458,
      55.491324
    ],
    [
      39.780325,
      55.49144
    ],
    [
      39.780867,
      55.491554
    ],
    [
      39.781619821014566,
      55.492989370587026
    ],
    [
      39.78386,
      55.49255
    ],
    [
      39.786264,
      55.496779
    ],
    [
      39.788237,
      55.504531
    ],
    [
      39.787763,
      55.508404
    ],
    [
      39.78676804533238,
      55.51161297164416
    ],
    [
      39.786801,
      55.511684
    ],
    [
      39.784895,
      55.517654
    ],
    [
      39.78416278781016,
      55.518837097106356
    ],
    [
      39.784443,
      55.519029
    ],
    [
      39.783493,
      55.520416
    ],
    [
      39.782779,
      55.521073
    ],
    [
      39.778795,
      55.522998
    ],
    [
      39.772541,
      55.527734
    ],
    [
      39.770595509730136,
      55.5371446285064
    ],
    [
      39.771227,
      55.537574
    ],
    [
      39.772901,
      55.5428
    ],
    [
      39.77637,
      55.552824
    ],
    [
      39.781876,
      55.5548
    ],
    [
      39.790018,
      55.551653
    ],
    [
      39.794905,
      55.551133
    ],
    [
      39.79672286636082,
      55.55230442059476
    ],
    [
      39.797025,
      55.552232
    ],
    [
      39.79859,
      55.552755
    ],
    [
      39.799506,
      55.553792
    ],
    [
      39.799614962804085,
      55.55416806767503
    ],
    [
      39.801834,
      55.555598
    ],
    [
      39.807818,
      55.554358
    ],
    [
      39.811646,
      55.551607
    ],
    [
      39.818074,
      55.542505
    ],
    [
      39.819168,
      55.539807
    ],
    [
      39.820424,
      55.536725
    ],
    [
      39.821679,
      55.533642
    ],
    [
      39.825013,
      55.535
    ],
    [
      39.83218274020919,
      55.541448972674935
    ],
    [
      39.83262557142868,
      55.54151514285716
    ],
    [
      39.835209,
      55.541846
    ],
    [
      39.83520917483209,
      55.54184624030293
    ],
    [
      39.836121,
      55.541963
    ],
    [
      39.844238,
      55.544797
    ],
    [
      39.848316,
      55.533607
    ],
    [
      39.848707,
      55.533444
    ],
    [
      39.851219,
      55.533228
    ],
    [
      39.850878,
      55.536812
    ],
    [
      39.859376,
      55.545168
    ],
    [
      39.865115,
      55.538643
    ],
    [
      39.865574,
      55.535073
    ],
    [
      39.86899311774328,
      55.53624661991016
    ],
    [
      39.869086,
      55.535524
    ],
    [
      39.880347,
      55.532618
    ],
    [
      39.883674,
      55.533993
    ],
    [
      39.891929,
      55.538427
    ],
    [
      39.891920700858655,
      55.53849302345849
    ],
    [
      39.895826,
      55.539044
    ],
    [
      39.9032,
      55.540048
    ],
    [
      39.90320108372568,
      55.54004840919911
    ],
    [
      39.910617,
      55.541028
    ],
    [
      39.912581,
      55.545886
    ],
    [
      39.91108,
      55.549627
    ],
    [
      39.909579,
      55.553367
    ],
    [
      39.922443,
      55.561731
    ],
    [
      39.926419,
      55.562954
    ],
    [
      39.931503,
      55.563351
    ],
    [
      39.935313,
      55.563283
    ],
    [
      39.939089,
      55.563733
    ],
    [
      39.940412,
      55.564187
    ],
    [
      39.942014,
      55.564842
    ],
    [
      39.94199324396723,
      55.56489283016695
    ],
    [
      39.946243,
      55.56659
    ],
    [
      39.954795,
      55.567516
    ],
    [
      39.956703,
      55.567445
    ],
    [
      39.95701870603993,
      55.56743341749329
    ],
    [
      39.95738,
      55.56742
    ],
    [
      39.95806725333793,
      55.56739473333316
    ],
    [
      39.958542,
      55.567377
    ],
    [
      39.95854201031443,
      55.567377279032556
    ],
    [
      39.959964,
      55.567325
    ],
    [
      39.96002395414437,
      55.568946466596216
    ],
    [
      39.960428,
      55.569057
    ],
    [
      39.967576,
      55.566984
    ],
    [
      39.972337,
      55.56557
    ],
    [
      39.973959,
      55.565343
    ],
    [
      39.974897647418764,
      55.56649456214659
    ],
    [
      39.982525,
      55.565385
    ],
    [
      39.987066,
      55.565345
    ],
    [
      39.9931310134309,
      55.56513332642615
    ],
    [
      40.000443,
      55.560604
    ],
    [
      40.001156,
      55.559746
    ],
    [
      40.001318,
      55.559754
    ],
    [
      40.002293,
      55.560016
    ],
    [
      40.006163,
      55.564661
    ],
    [
      40.005929,
      55.568253
    ],
    [
      40.005649,
      55.56841
    ],
    [
      40.00225,
      55.569596
    ],
    [
      39.993153,
      55.568873
    ],
    [
      39.987097,
      55.568945
    ],
    [
      39.982557,
      55.568985
    ],
    [
      39.97518564379785,
      55.57027963562819
    ],
    [
      39.975187,
      55.570331
    ],
    [
      39.97316130823324,
      55.570384379104176
    ],
    [
      39.971139,
      55.570473
    ],
    [
      39.96254278205972,
      55.57370966292882
    ],
    [
      39.959871229985616,
      55.57546084924581
    ],
    [
      39.958546,
      55.577036
    ],
    [
      39.95854575367759,
      55.57703585745309
    ],
    [
      39.956712,
      55.579215
    ],
    [
      39.952807,
      55.58388
    ],
    [
      39.948481,
      55.589061
    ],
    [
      39.944156,
      55.594242
    ],
    [
      39.941392,
      55.591935
    ],
    [
      39.94552326577822,
      55.58698722191471
    ],
    [
      39.945523,
      55.586987
    ],
    [
      39.944854,
      55.583512
    ],
    [
      39.945945,
      55.580081
    ],
    [
      39.94517921542365,
      55.573207155714655
    ],
    [
      39.944477,
      55.573358
    ],
    [
      39.943512,
      55.573402
    ],
    [
      39.93558850439175,
      55.56817064180258
    ],
    [
      39.93173986777825,
      55.569867571526345
    ],
    [
      39.927168,
      55.576903
    ],
    [
      39.926004,
      55.576912
    ],
    [
      39.92440342280415,
      55.57697722527926
    ],
    [
      39.924397,
      55.577
    ],
    [
      39.92438716019302,
      55.57700143497185
    ],
    [
      39.924387,
      55.577002
    ],
    [
      39.923245,
      55.577168
    ],
    [
      39.920998,
      55.577116
    ],
    [
      39.919084,
      55.573868
    ],
    [
      39.91555711317899,
      55.565277704137564
    ],
    [
      39.907087,
      55.566415
    ],
    [
      39.903029,
      55.566507
    ],
    [
      39.896716,
      55.566663
    ],
    [
      39.88835,
      55.566886
    ],
    [
      39.887485,
      55.576094
    ],
    [
      39.894036,
      55.579023
    ],
    [
      39.902466,
      55.582793
    ],
    [
      39.904199,
      55.583568
    ],
    [
      39.90273,
      55.586854
    ],
    [
      39.900996,
      55.586079
    ],
    [
      39.896781,
      55.584195
    ],
    [
      39.892566,
      55.58231
    ],
    [
      39.886015,
      55.57938
    ],
    [
      39.879539,
      55.573772
    ],
    [
      39.873423,
      55.569986
    ],
    [
      39.863295,
      55.566227
    ],
    [
      39.859708,
      55.566226
    ],
    [
      39.854649,
      55.564522
    ],
    [
      39.85042874027162,
      55.56481224426154
    ],
    [
      39.850365,
      55.565337
    ],
    [
      39.847626,
      55.565005
    ],
    [
      39.843399,
      55.564538
    ],
    [
      39.838788,
      55.564107
    ],
    [
      39.834213,
      55.574328
    ],
    [
      39.834472,
      55.577919
    ],
    [
      39.826375,
      55.578504
    ],
    [
      39.818277,
      55.57909
    ],
    [
      39.812771349526784,
      55.585658929220855
    ],
    [
      39.815451,
      55.592382
    ],
    [
      39.818038,
      55.5957
    ],
    [
      39.821609,
      55.598634
    ],
    [
      39.831953,
      55.601
    ],
    [
      39.835431,
      55.601661
    ],
    [
      39.838606,
      55.604954
    ],
    [
      39.839301,
      55.608504
    ],
    [
      39.846857,
      55.616501
    ],
    [
      39.850489,
      55.616887
    ],
    [
      39.855178,
      55.618627
    ],
    [
      39.858893,
      55.619627
    ],
    [
      39.861707,
      55.623826
    ],
    [
      39.862592,
      55.627249
    ],
    [
      39.863654,
      55.631578
    ],
    [
      39.871243,
      55.638891
    ],
    [
      39.871670020548066,
      55.64106593533789
    ],
    [
      39.882446,
      55.644201
    ],
    [
      39.885912,
      55.644181
    ],
    [
      39.88591204024401,
      55.6441878989738
    ],
    [
      39.886679,
      55.644185
    ],
    [
      39.894886,
      55.644965
    ],
    [
      39.897006,
      55.645184
    ],
    [
      39.897739686269794,
      55.64526013725441
    ],
    [
      39.901751,
      55.645675
    ],
    [
      39.912676,
      55.643612
    ],
    [
      39.914022,
      55.642017
    ],
    [
      39.91412112567632,
      55.642071386567466
    ],
    [
      39.915158,
      55.640966
    ],
    [
      39.918848,
      55.63924
    ],
    [
      39.923364,
      55.63832
    ],
    [
      39.927879,
      55.6374
    ],
    [
      39.932326,
      55.644681
    ],
    [
      39.931858,
      55.646931
    ],
    [
      39.930569,
      55.647824
    ],
    [
      39.922226,
      55.648787
    ],
    [
      39.92099750339084,
      55.64888684382207
    ],
    [
      39.921023,
      55.649019
    ],
    [
      39.917575,
      55.649684
    ],
    [
      39.914128,
      55.650348
    ],
    [
      39.912518,
      55.649576
    ],
    [
      39.909705,
      55.647328
    ],
    [
      39.90138,
      55.649256
    ],
    [
      39.90153981495495,
      55.649818995013135
    ],
    [
      39.90399084426791,
      55.65430205705217
    ],
    [
      39.903997,
      55.654302
    ],
    [
      39.906733,
      55.656642
    ],
    [
      39.906049,
      55.65744
    ],
    [
      39.904144,
      55.658993
    ],
    [
      39.899979,
      55.660381
    ],
    [
      39.900581,
      55.672065
    ],
    [
      39.902244,
      55.675257
    ],
    [
      39.90139929162411,
      55.67608590732992
    ],
    [
      39.907653,
      55.677459
    ],
    [
      39.909323,
      55.680648
    ],
    [
      39.905452,
      55.689183
    ],
    [
      39.905954,
      55.692748
    ],
    [
      39.903091,
      55.693151
    ],
    [
      39.900258,
      55.693444
    ],
    [
      39.896176,
      55.694012
    ],
    [
      39.891407554561326,
      55.69379420802312
    ],
    [
      39.891412,
      55.693802
    ],
    [
      39.891177,
      55.693936
    ],
    [
      39.88557,
      55.692627
    ],
    [
      39.881219,
      55.692358
    ],
    [
      39.873924,
      55.700541
    ],
    [
      39.874208,
      55.703173
    ],
    [
      39.87420577068477,
      55.70317323981178
    ],
    [
      39.874272,
      55.703764
    ],
    [
      39.87821,
      55.712662
    ],
    [
      39.87821,
      55.716262
    ],
    [
      39.876354,
      55.716262
    ],
    [
      39.8697,
      55.715522
    ],
    [
      39.862355,
      55.720303
    ],
    [
      39.857887,
      55.725289
    ],
    [
      39.854559,
      55.726662
    ],
    [
      39.84713110301641,
      55.72497816050181
    ],
    [
      39.84699,
      55.725165
    ],
    [
      39.843506,
      55.72607
    ],
    [
      39.836405,
      55.719131
    ],
    [
      39.832919942791776,
      55.71843259523213
    ],
    [
      39.832831,
      55.71878
    ],
    [
      39.822789,
      55.721571
    ],
    [
      39.819237,
      55.722156
    ],
    [
      39.809473,
      55.716501
    ],
    [
      39.805968,
      55.719099
    ],
    [
      39.808991,
      55.72884
    ],
    [
      39.820075,
      55.732283
    ],
    [
      39.826365,
      55.732359
    ],
    [
      39.830417,
      55.732756
    ],
    [
      39.831662,
      55.732488
    ],
    [
      39.832419,
      55.736008
    ],
    [
      39.831175,
      55.736276
    ],
    [
      39.829409,
      55.73656
    ],
    [
      39.822627,
      55.74307
    ],
    [
      39.822391,
      55.746825
    ],
    [
      39.818851,
      55.746382
    ],
    [
      39.809408,
      55.743767
    ],
    [
      39.807805,
      55.740544
    ],
    [
      39.804325,
      55.730336
    ],
    [
      39.800688,
      55.726507
    ],
    [
      39.79973916909515,
      55.72231395809299
    ],
    [
      39.799461,
      55.722307
    ],
    [
      39.79861066028206,
      55.72030631125744
    ],
    [
      39.796235606248906,
      55.71787617020153
    ],
    [
      39.792879,
      55.718648
    ],
    [
      39.783663,
      55.719579
    ],
    [
      39.778214,
      55.717495
    ],
    [
      39.77691892657226,
      55.716138857514416
    ],
    [
      39.775621,
      55.726847
    ],
    [
      39.77386,
      55.727686
    ],
    [
      39.77194,
      55.72834
    ],
    [
      39.768068,
      55.728192
    ],
    [
      39.762415591069725,
      55.72567119434572
    ],
    [
      39.760167,
      55.726194
    ],
    [
      39.759771340910326,
      55.72449193770747
    ],
    [
      39.759693,
      55.724457
    ],
    [
      39.75709008812368,
      55.722068338860915
    ],
    [
      39.74924441369614,
      55.71991898034544
    ],
    [
      39.744797,
      55.7264
    ],
    [
      39.740944,
      55.725442
    ],
    [
      39.73515982482209,
      55.72384555859307
    ],
    [
      39.73449,
      55.723788
    ],
    [
      39.73268853655279,
      55.723352643767846
    ],
    [
      39.723023,
      55.723771
    ],
    [
      39.715632,
      55.727428
    ],
    [
      39.71041,
      55.730443
    ],
    [
      39.705146,
      55.734879
    ],
    [
      39.701791,
      55.735972
    ],
    [
      39.697166,
      55.737749
    ],
    [
      39.693536,
      55.73899
    ],
    [
      39.691926,
      55.750909
    ],
    [
      39.688359,
      55.750427
    ],
    [
      39.689968,
      55.738508
    ],
    [
      39.695875,
      55.734389
    ],
    [
      39.700545,
      55.732595
    ],
    [
      39.70043093552688,
      55.722304755032525
    ],
    [
      39.69759927140541,
      55.72007569711872
    ],
    [
      39.692241,
      55.717886
    ],
    [
      39.69033503297132,
      55.7175351982495
    ],
    [
      39.681473,
      55.718331
    ],
    [
      39.677303,
      55.719392
    ],
    [
      39.67178489623718,
      55.7209846391806
    ],
    [
      39.668275,
      55.722391
    ],
    [
      39.667482924645434,
      55.72254255960327
    ],
    [
      39.665987,
      55.72315
    ],
    [
      39.657812,
      55.724955
    ],
    [
      39.6553676747546,
      55.72522879690422
    ],
    [
      39.654648,
      55.725444
    ],
    [
      39.65358318743234,
      55.725428683194046
    ],
    [
      39.652393,
      55.725562
    ],
    [
      39.647777,
      55.725072
    ],
    [
      39.643542,
      55.72509
    ],
    [
      39.64228598783791,
      55.72486510690078
    ],
    [
      39.641451,
      55.724903
    ],
    [
      39.639340632358966,
      55.72433773133709
    ],
    [
      39.639169,
      55.724307
    ],
    [
      39.63916275654784,
      55.72429008673718
    ],
    [
      39.636956,
      55.723699
    ],
    [
      39.63764,
      55.720165
    ],
    [
      39.644747,
      55.721161
    ],
    [
      39.648385,
      55.721523
    ],
    [
      39.656651,
      55.721352
    ],
    [
      39.66111,
      55.720127
    ],
    [
      39.669179,
      55.717764
    ],
    [
      39.66949070213999,
      55.717615703354895
    ],
    [
      39.671669,
      55.716171
    ],
    [
      39.67263213258483,
      55.7161211239599
    ],
    [
      39.672975,
      55.715958
    ],
    [
      39.678485774922194,
      55.71494865648347
    ],
    [
      39.683092,
      55.713438
    ],
    [
      39.68479735958934,
      55.71344467390982
    ],
    [
      39.685414,
      55.713284
    ],
    [
      39.685691475883274,
      55.71331513167242
    ],
    [
      39.685693,
      55.713294
    ],
    [
      39.687649,
      55.71145
    ],
    [
      39.690029,
      55.712758
    ],
    [
      39.692922,
      55.714081
    ],
    [
      39.6931951072638,
      55.71419619010048
    ],
    [
      39.695294,
      55.714474
    ],
    [
      39.698907,
      55.714027
    ],
    [
      39.703267,
      55.713468
    ],
    [
      39.70618007861351,
      55.711690733223115
    ],
    [
      39.708664,
      55.700675
    ],
    [
      39.706606,
      55.695803
    ],
    [
      39.696339,
      55.689908
    ],
    [
      39.692328,
      55.687479
    ],
    [
      39.685065,
      55.684256
    ],
    [
      39.681724,
      55.681294
    ],
    [
      39.673406,
      55.688879
    ],
    [
      39.671031,
      55.691584
    ],
    [
      39.663334,
      55.689478
    ],
    [
      39.656159,
      55.689805
    ],
    [
      39.656998,
      55.686304
    ],
    [
      39.656278,
      55.675398
    ],
    [
      39.647827,
      55.675131
    ],
    [
      39.641902,
      55.674931
    ],
    [
      39.635988,
      55.674731
    ],
    [
      39.629226,
      55.674512
    ],
    [
      39.621719,
      55.67662
    ],
    [
      39.620887,
      55.683989
    ],
    [
      39.620056,
      55.691359
    ],
    [
      39.619431,
      55.696854
    ],
    [
      39.619073,
      55.700381
    ],
    [
      39.615148,
      55.704022
    ],
    [
      39.608307,
      55.710903
    ],
    [
      39.604826,
      55.711835
    ],
    [
      39.601141,
      55.711642
    ],
    [
      39.59808,
      55.709538
    ],
    [
      39.58902,
      55.711886
    ],
    [
      39.585287,
      55.715224
    ],
    [
      39.581303,
      55.720581
    ],
    [
      39.580600126570076,
      55.72687644489777
    ],
    [
      39.58430180719471,
      55.73167200130874
    ],
    [
      39.58744,
      55.732885
    ],
    [
      39.58757838375231,
      55.73324840588818
    ],
    [
      39.589665,
      55.733193
    ],
    [
      39.593336,
      55.732542
    ],
    [
      39.59842,
      55.734326
    ],
    [
      39.602687,
      55.735333
    ],
    [
      39.606922,
      55.737745
    ],
    [
      39.615742,
      55.745605
    ],
    [
      39.623621,
      55.749002
    ],
    [
      39.632349,
      55.750463
    ],
    [
      39.636747,
      55.75076
    ],
    [
      39.633285,
      55.754247
    ],
    [
      39.630962,
      55.76435
    ],
    [
      39.63337442211901,
      55.76715323084988
    ],
    [
      39.6374,
      55.767896
    ],
    [
      39.641196,
      55.768303
    ],
    [
      39.650232,
      55.768562
    ],
    [
      39.653863,
      55.768122
    ],
    [
      39.65453736213451,
      55.769061945273144
    ],
    [
      39.657308,
      55.768176
    ],
    [
      39.659182,
      55.771461
    ],
    [
      39.655881,
      55.775684
    ],
    [
      39.652136,
      55.777888
    ],
    [
      39.65163292521975,
      55.7780001771712
    ],
    [
      39.651635,
      55.778015
    ],
    [
      39.651450642633826,
      55.77804082310626
    ],
    [
      39.647463,
      55.77893
    ],
    [
      39.64157,
      55.784887
    ],
    [
      39.643868,
      55.793273
    ],
    [
      39.652502,
      55.787438
    ],
    [
      39.653818,
      55.787951
    ],
    [
      39.656397,
      55.788983
    ],
    [
      39.668105,
      55.787404
    ],
    [
      39.66810510345778,
      55.78740421466002
    ],
    [
      39.669095,
      55.786927
    ],
    [
      39.673694,
      55.784778
    ],
    [
      39.678774,
      55.783125
    ],
    [
      39.684567,
      55.784377
    ],
    [
      39.689847,
      55.777589
    ],
    [
      39.685541,
      55.771296
    ],
    [
      39.686749,
      55.762347
    ],
    [
      39.690317,
      55.762829
    ],
    [
      39.694009,
      55.773252
    ],
    [
      39.694986,
      55.781043
    ],
    [
      39.695121,
      55.786527
    ],
    [
      39.698773,
      55.797656
    ],
    [
      39.707252,
      55.798342
    ],
    [
      39.71399,
      55.793974
    ],
    [
      39.717923,
      55.792937
    ],
    [
      39.722028,
      55.79174
    ],
    [
      39.726865,
      55.790282
    ],
    [
      39.730821,
      55.788637
    ],
    [
      39.730378,
      55.793039
    ],
    [
      39.724862,
      55.794751
    ],
    [
      39.721303,
      55.795858
    ],
    [
      39.714679,
      55.798988
    ],
    [
      39.711619,
      55.80093
    ],
    [
      39.707845,
      55.801893
    ],
    [
      39.696125,
      55.801806
    ],
    [
      39.691005,
      55.802451
    ],
    [
      39.686263,
      55.803154
    ],
    [
      39.68116,
      55.803942
    ],
    [
      39.676662,
      55.802587
    ],
    [
      39.67504846670961,
      55.81203515180467
    ],
    [
      39.678547,
      55.812413
    ],
    [
      39.681441,
      55.812645
    ],
    [
      39.684486,
      55.812608
    ],
    [
      39.684529,
      55.816208
    ],
    [
      39.681484,
      55.816245
    ],
    [
      39.678439,
      55.816281
    ],
    [
      39.671612791461556,
      55.81522026297326
    ],
    [
      39.66994220822327,
      55.815743692866064
    ],
    [
      39.662361,
      55.820319
    ],
    [
      39.65966644830123,
      55.82125293301635
    ],
    [
      39.659881,
      55.821666
    ],
    [
      39.655486,
      55.827014
    ],
    [
      39.654509,
      55.826429
    ],
    [
      39.647419,
      55.819594
    ],
    [
      39.643973,
      55.818886
    ],
    [
      39.63677882346565,
      55.81803235384822
    ],
    [
      39.630609,
      55.823308
    ],
    [
      39.637006,
      55.830602
    ],
    [
      39.638373,
      55.833933
    ],
    [
      39.631338,
      55.840463
    ],
    [
      39.63106574712489,
      55.84047617352622
    ],
    [
      39.631384,
      55.841409
    ],
    [
      39.631672,
      55.849405
    ],
    [
      39.631985,
      55.853797
    ],
    [
      39.632227,
      55.858783
    ],
    [
      39.632485,
      55.863731
    ],
    [
      39.626821,
      55.865705
    ],
    [
      39.621634,
      55.867371
    ],
    [
      39.618613,
      55.87029
    ],
    [
      39.619617,
      55.881547
    ],
    [
      39.625483,
      55.881642
    ],
    [
      39.636229,
      55.883988
    ],
    [
      39.63764332891327,
      55.8831357413979
    ],
    [
      39.638429,
      55.881984
    ],
    [
      39.64481,
      55.874804
    ],
    [
      39.646542731415984,
      55.87266728106757
    ],
    [
      39.646555,
      55.872641
    ],
    [
      39.646619936717414,
      55.87257207529723
    ],
    [
      39.64846,
      55.870303
    ],
    [
      39.64959642601537,
      55.86941278963688
    ],
    [
      39.650984,
      55.86794
    ],
    [
      39.653769,
      55.866067
    ],
    [
      39.65378328533298,
      55.86608824667743
    ],
    [
      39.654151,
      55.865845
    ],
    [
      39.65918059160842,
      55.86074555295257
    ],
    [
      39.662048,
      55.852983
    ],
    [
      39.661769,
      55.847477
    ],
    [
      39.661593,
      55.843885
    ],
    [
      39.661443,
      55.841736
    ],
    [
      39.665034,
      55.841485
    ],
    [
      39.665184,
      55.843634
    ],
    [
      39.665365,
      55.8473
    ],
    [
      39.671645,
      55.857086
    ],
    [
      39.67769,
      55.855274
    ],
    [
      39.681076,
      55.854157
    ],
    [
      39.686031,
      55.85334
    ],
    [
      39.691371,
      55.850846
    ],
    [
      39.691676,
      55.850871
    ],
    [
      39.692287353780294,
      55.85242962051835
    ],
    [
      39.696598,
      55.851533
    ],
    [
      39.703307,
      55.851304
    ],
    [
      39.713841,
      55.849583
    ],
    [
      39.725024,
      55.848881
    ],
    [
      39.731146,
      55.842214
    ],
    [
      39.734014,
      55.83978
    ],
    [
      39.734417,
      55.839721
    ],
    [
      39.734623404801965,
      55.84112101428533
    ],
    [
      39.73552,
      55.843094
    ],
    [
      39.733787,
      55.846268
    ],
    [
      39.731373,
      55.849397
    ],
    [
      39.728422,
      55.85147
    ],
    [
      39.724879,
      55.853081
    ],
    [
      39.719153,
      55.854829
    ],
    [
      39.709264,
      55.854794
    ],
    [
      39.703441,
      55.854901
    ],
    [
      39.696722,
      55.855131
    ],
    [
      39.69412019642179,
      55.85557075302685
    ],
    [
      39.700563,
      55.860747
    ],
    [
      39.703259,
      55.863133
    ],
    [
      39.700911,
      55.865788
    ],
    [
      39.699185,
      55.867644
    ],
    [
      39.694473,
      55.86997
    ],
    [
      39.690867,
      55.872065
    ],
    [
      39.688197,
      55.876246
    ],
    [
      39.684523,
      55.87925
    ],
    [
      39.68439678718416,
      55.88147957108752
    ],
    [
      39.689858,
      55.880045
    ],
    [
      39.693131,
      55.881543
    ],
    [
      39.696619,
      55.884119
    ],
    [
      39.706629,
      55.880133
    ],
    [
      39.712982,
      55.874761
    ],
    [
      39.71572,
      55.871905
    ],
    [
      39.721551,
      55.869176
    ],
    [
      39.729812,
      55.866903
    ],
    [
      39.73328,
      55.865112
    ],
    [
      39.736909,
      55.864789
    ],
    [
      39.741412,
      55.864624
    ],
    [
      39.753156,
      55.864358
    ],
    [
      39.756714,
      55.863935
    ],
    [
      39.760575,
      55.864225
    ],
    [
      39.762393,
      55.867333
    ],
    [
      39.761408,
      55.867909
    ],
    [
      39.760247,
      55.868278
    ],
    [
      39.752593,
      55.868952
    ],
    [
      39.748364,
      55.869805
    ],
    [
      39.743205,
      55.878876
    ],
    [
      39.742349,
      55.887879
    ],
    [
      39.738956,
      55.889139
    ],
    [
      39.731482,
      55.891758
    ],
    [
      39.728482,
      55.894043
    ],
    [
      39.724598,
      55.902728
    ],
    [
      39.728862,
      55.910534
    ],
    [
      39.72971288929211,
      55.91201287815961
    ],
    [
      39.741249,
      55.915275
    ],
    [
      39.746352,
      55.912917
    ],
    [
      39.749946,
      55.912125
    ],
    [
      39.754104,
      55.910496
    ],
    [
      39.759694,
      55.908923
    ],
    [
      39.768658,
      55.901563
    ],
    [
      39.772312,
      55.898815
    ],
    [
      39.774816,
      55.897772
    ],
    [
      39.77485961434232,
      55.8978450924371
    ],
    [
      39.775879,
      55.897457
    ],
    [
      39.783661,
      55.894168
    ],
    [
      39.787608,
      55.892042
    ],
    [
      39.788708,
      55.883142
    ],
    [
      39.790073,
      55.879811
    ],
    [
      39.79268684063536,
      55.88069659077295
    ],
    [
      39.79269492810117,
      55.88069899607584
    ],
    [
      39.797234,
      55.88185
    ],
    [
      39.805978,
      55.88112
    ],
    [
      39.809954,
      55.880567
    ],
    [
      39.811262,
      55.869262
    ],
    [
      39.807285,
      55.868556
    ],
    [
      39.801755,
      55.869359
    ],
    [
      39.798077,
      55.870381
    ],
    [
      39.79631,
      55.870744
    ],
    [
      39.795299,
      55.867289
    ],
    [
      39.79625,
      55.86701
    ],
    [
      39.800287,
      55.866046
    ],
    [
      39.80887240292907,
      55.85971148018011
    ],
    [
      39.809892,
      55.858306
    ],
    [
      39.813045,
      55.860354
    ],
    [
      39.818891,
      55.868301
    ],
    [
      39.826129,
      55.870894
    ],
    [
      39.834101,
      55.868505
    ],
    [
      39.83622,
      55.86429
    ],
    [
      39.836364,
      55.85763
    ],
    [
      39.837125,
      55.855436
    ],
    [
      39.837686,
      55.85188
    ],
    [
      39.840718,
      55.852358
    ],
    [
      39.85184404371421,
      55.85309844739228
    ],
    [
      39.851721,
      55.852191
    ],
    [
      39.849201,
      55.84504
    ],
    [
      39.838512,
      55.840858
    ],
    [
      39.835296,
      55.842778
    ],
    [
      39.832068,
      55.844515
    ],
    [
      39.828519,
      55.845271
    ],
    [
      39.81918,
      55.843148
    ],
    [
      39.808691,
      55.843948
    ],
    [
      39.803088,
      55.847564
    ],
    [
      39.802492,
      55.847325
    ],
    [
      39.8034338861247,
      55.84497415352595
    ],
    [
      39.80012,
      55.846176
    ],
    [
      39.795504,
      55.846771
    ],
    [
      39.789126,
      55.849769
    ],
    [
      39.787844,
      55.849427
    ],
    [
      39.786086,
      55.845856
    ],
    [
      39.788458906864776,
      55.84345869090151
    ],
    [
      39.78837,
      55.843176
    ],
    [
      39.788906,
      55.843007
    ],
    [
      39.792806,
      55.843033
    ],
    [
      39.799827,
      55.842513
    ],
    [
      39.80683291746985,
      55.84056533828246
    ],
    [
      39.812285,
      55.83823
    ],
    [
      39.816069,
      55.837889
    ],
    [
      39.82276469734672,
      55.83918365144666
    ],
    [
      39.827288,
      55.838666
    ],
    [
      39.838043,
      55.836925
    ],
    [
      39.841571,
      55.836303
    ],
    [
      39.845317,
      55.837477
    ],
    [
      39.848926,
      55.839204
    ],
    [
      39.85156,
      55.841696
    ],
    [
      39.853672,
      55.845253
    ],
    [
      39.854948,
      55.848644
    ],
    [
      39.855405,
      55.852185
    ],
    [
      39.85542672296277,
      55.85242696776514
    ],
    [
      39.8555,
      55.852411
    ],
    [
      39.855627,
      55.852783
    ],
    [
      39.862511,
      55.858883
    ],
    [
      39.86709,
      55.858602
    ],
    [
      39.867741,
      55.858434
    ],
    [
      39.868639,
      55.861921
    ],
    [
      39.867988,
      55.862088
    ],
    [
      39.8626,
      55.862482
    ],
    [
      39.858747,
      55.862419
    ],
    [
      39.855674033084625,
      55.862887078859494
    ],
    [
      39.848698,
      55.871837
    ],
    [
      39.856372,
      55.876817
    ],
    [
      39.859942,
      55.877597
    ],
    [
      39.862972,
      55.882788
    ],
    [
      39.869886,
      55.884801
    ],
    [
      39.873788,
      55.883961
    ],
    [
      39.878974,
      55.883682
    ],
    [
      39.884886,
      55.888779
    ],
    [
      39.886578,
      55.890487
    ],
    [
      39.886626,
      55.890938
    ],
    [
      39.886624617873274,
      55.89094703565349
    ],
    [
      39.896337,
      55.89282
    ],
    [
      39.897023,
      55.892612
    ],
    [
      39.898178,
      55.89401
    ],
    [
      39.898095619113235,
      55.89558524574134
    ],
    [
      39.903853,
      55.895789
    ],
    [
      39.915071,
      55.895865
    ],
    [
      39.917515,
      55.8932
    ],
    [
      39.919572,
      55.890259
    ],
    [
      39.92248,
      55.888608
    ],
    [
      39.922689,
      55.888813
    ],
    [
      39.923347,
      55.890403
    ],
    [
      39.921575,
      55.894388
    ],
    [
      39.918471,
      55.897411
    ],
    [
      39.915211,
      55.903483
    ],
    [
      39.910761,
      55.903487
    ],
    [
      39.907504,
      55.901758
    ],
    [
      39.907798,
      55.920198
    ],
    [
      39.919031,
      55.918199
    ],
    [
      39.920697,
      55.915008
    ],
    [
      39.930446,
      55.914605
    ],
    [
      39.930446241281345,
      55.91460548062049
    ],
    [
      39.934957,
      55.912355
    ],
    [
      39.939873,
      55.911873
    ],
    [
      39.943422,
      55.911342
    ],
    [
      39.944127,
      55.914872
    ],
    [
      39.943412,
      55.915015
    ],
    [
      39.93994,
      55.915473
    ],
    [
      39.936171,
      55.915744
    ],
    [
      39.93206100145147,
      55.91782247380191
    ],
    [
      39.93209162448516,
      55.92781870694152
    ],
    [
      39.93501531546785,
      55.929926098089496
    ],
    [
      39.938613,
      55.930763
    ],
    [
      39.943676,
      55.930184
    ],
    [
      39.947382,
      55.930025
    ],
    [
      39.94726815955659,
      55.930145720379834
    ],
    [
      39.948368,
      55.930134
    ],
    [
      39.952084,
      55.931502
    ],
    [
      39.954964,
      55.933662
    ],
    [
      39.953912,
      55.935622
    ],
    [
      39.949549,
      55.936325
    ],
    [
      39.945301068852324,
      55.93707885489082
    ],
    [
      39.944608,
      55.940261
    ],
    [
      39.94215,
      55.939726
    ],
    [
      39.938713,
      55.938248
    ],
    [
      39.931392,
      55.946751
    ],
    [
      39.924681,
      55.952431
    ],
    [
      39.922397,
      55.958584
    ],
    [
      39.932914,
      55.962552
    ],
    [
      39.934958,
      55.967398
    ],
    [
      39.931515,
      55.9768
    ],
    [
      39.934211,
      55.979575
    ],
    [
      39.937747,
      55.982785
    ],
    [
      39.940345,
      55.983981
    ],
    [
      39.942008842649294,
      55.985511259653904
    ],
    [
      39.94201,
      55.985512
    ],
    [
      39.942010175821025,
      55.985512485790835
    ],
    [
      39.942724,
      55.986169
    ],
    [
      39.951555,
      55.993968
    ],
    [
      39.9516,
      55.994
    ],
    [
      39.955072717870216,
      55.99646657243235
    ],
    [
      39.959817743124496,
      55.99750821502188
    ],
    [
      39.965528,
      55.994634
    ],
    [
      39.966731,
      55.993573
    ],
    [
      39.9781673335561,
      55.99618564453922
    ],
    [
      39.980213,
      55.987769
    ],
    [
      39.980226,
      55.986473
    ],
    [
      39.98031180874786,
      55.98422734606455
    ],
    [
      39.978922,
      55.983002
    ],
    [
      39.974088,
      55.981519
    ],
    [
      39.970737,
      55.978387
    ],
    [
      39.968476,
      55.971041
    ],
    [
      39.966374,
      55.964262
    ],
    [
      39.96808,
      55.958368
    ],
    [
      39.970547,
      55.955669
    ],
    [
      39.973446,
      55.95425
    ],
    [
      39.976345,
      55.95283
    ],
    [
      39.977928,
      55.956064
    ],
    [
      39.985963,
      55.96352
    ],
    [
      39.987967,
      55.962796
    ],
    [
      39.992859,
      55.954689
    ],
    [
      39.995726,
      55.952511
    ],
    [
      40.005657,
      55.950846
    ],
    [
      40.006139,
      55.950816
    ],
    [
      40.012284,
      55.950356
    ],
    [
      40.013664,
      55.953681
    ],
    [
      40.010796,
      55.959079
    ],
    [
      40.008435,
      55.964282
    ],
    [
      40.006906,
      55.969055
    ],
    [
      40.00872680900136,
      55.97645213754456
    ],
    [
      40.00893,
      55.976426
    ],
    [
      40.010542,
      55.977121
    ],
    [
      40.021704,
      55.980342
    ],
    [
      40.02343103449914,
      55.98194595962283
    ],
    [
      40.025097,
      55.981682
    ],
    [
      40.025354,
      55.981643
    ],
    [
      40.025925,
      55.981706
    ],
    [
      40.030372112800585,
      55.9840955413173
    ],
    [
      40.03265077799685,
      55.98432968477831
    ],
    [
      40.036868,
      55.979489
    ],
    [
      40.037843,
      55.978696
    ],
    [
      40.042881,
      55.976953
    ],
    [
      40.042951365832295,
      55.97705413377161
    ],
    [
      40.043158,
      55.976761
    ],
    [
      40.040771,
      55.968321
    ],
    [
      40.037575,
      55.965464
    ],
    [
      40.044581,
      55.964932
    ],
    [
      40.051026,
      55.966921
    ],
    [
      40.057358,
      55.953682
    ],
    [
      40.056945,
      55.950083
    ],
    [
      40.056515,
      55.944659
    ],
    [
      40.056241,
      55.943068
    ],
    [
      40.056554,
      55.942995
    ],
    [
      40.058054,
      55.942738
    ],
    [
      40.060016,
      55.943244
    ],
    [
      40.060104,
      55.944381
    ],
    [
      40.060708,
      55.952365
    ],
    [
      40.06036,
      55.956632
    ],
    [
      40.06012,
      55.960726
    ],
    [
      40.059091,
      55.96419
    ],
    [
      40.061611,
      55.973483
    ],
    [
      40.072128,
      55.976057
    ],
    [
      40.075004,
      55.973396
    ],
    [
      40.077657,
      55.969832
    ],
    [
      40.081307,
      55.968196
    ],
    [
      40.084998,
      55.967053
    ],
    [
      40.091658,
      55.971369
    ],
    [
      40.092757,
      55.974797
    ],
    [
      40.092169,
      55.9751
    ],
    [
      40.088564,
      55.976312
    ],
    [
      40.083634,
      55.976313
    ],
    [
      40.079751,
      55.976224
    ],
    [
      40.074252,
      55.980264
    ],
    [
      40.070492,
      55.98075
    ],
    [
      40.066422,
      55.980141
    ],
    [
      40.06159,
      55.982793
    ],
    [
      40.0641,
      55.993084
    ],
    [
      40.075077,
      55.997399
    ],
    [
      40.079986,
      55.995
    ],
    [
      40.083994,
      55.991289
    ],
    [
      40.091305,
      55.997509
    ],
    [
      40.095613,
      55.997644
    ],
    [
      40.10489,
      55.998335
    ],
    [
      40.109124,
      55.997026
    ],
    [
      40.119784,
      55.998192
    ],
    [
      40.120225,
      56.001765
    ],
    [
      40.117882,
      56.011926
    ],
    [
      40.115576,
      56.018469
    ],
    [
      40.121645,
      56.024645
    ],
    [
      40.121222,
      56.028221
    ],
    [
      40.131482,
      56.034427
    ],
    [
      40.132358,
      56.034506
    ],
    [
      40.132033,
      56.038091
    ],
    [
      40.132789,
      56.049974
    ],
    [
      40.134963,
      56.053574
    ],
    [
      40.139372,
      56.05835
    ],
    [
      40.13949,
      56.058516
    ],
    [
      40.142142,
      56.063758
    ],
    [
      40.141217,
      56.067237
    ],
    [
      40.140019,
      56.066919
    ],
    [
      40.136399,
      56.065922
    ],
    [
      40.131982,
      56.064706
    ],
    [
      40.126617,
      56.06323
    ],
    [
      40.123543,
      56.060579
    ],
    [
      40.114214,
      56.065856
    ],
    [
      40.110952,
      56.06513
    ],
    [
      40.105307,
      56.062851
    ],
    [
      40.094197,
      56.061224
    ],
    [
      40.090839,
      56.063092
    ],
    [
      40.08154,
      56.063912
    ],
    [
      40.076663,
      56.061998
    ],
    [
      40.072253,
      56.060204
    ],
    [
      40.067043,
      56.058086
    ],
    [
      40.061291,
      56.057201
    ],
    [
      40.052529,
      56.055414
    ],
    [
      40.048605,
      56.056231
    ],
    [
      40.039668,
      56.063786
    ],
    [
      40.040897,
      56.075119
    ],
    [
      40.045281,
      56.074861
    ],
    [
      40.045493,
      56.078454
    ],
    [
      40.041109,
      56.078713
    ],
    [
      40.036726,
      56.078971
    ],
    [
      40.033612,
      56.079185
    ],
    [
      40.03009,
      56.079596
    ],
    [
      40.021704,
      56.080541
    ],
    [
      40.019387,
      56.086215
    ],
    [
      40.015745,
      56.093565
    ],
    [
      40.014568,
      56.096966
    ],
    [
      40.009913,
      56.100918
    ],
    [
      40.007021,
      56.103063
    ],
    [
      40.006269,
      56.102049
    ],
    [
      40.000644,
      56.097619
    ],
    [
      39.998195,
      56.094886
    ],
    [
      39.98801,
      56.093515
    ],
    [
      39.985423,
      56.103124
    ],
    [
      39.988612,
      56.110741
    ],
    [
      39.989603,
      56.113058
    ],
    [
      39.986293,
      56.114474
    ],
    [
      39.985302,
      56.112156
    ],
    [
      39.982102,
      56.104514
    ],
    [
      39.973594,
      56.108229
    ],
    [
      39.970145,
      56.10926
    ],
    [
      39.969638,
      56.107566
    ],
    [
      39.96789,
      56.103072
    ],
    [
      39.965398,
      56.096326
    ],
    [
      39.956994,
      56.093188
    ],
    [
      39.953145,
      56.094653
    ],
    [
      39.946484,
      56.101121
    ],
    [
      39.94585,
      56.100997
    ],
    [
      39.935456,
      56.09876
    ],
    [
      39.934319,
      56.095344
    ],
    [
      39.926816,
      56.086607
    ],
    [
      39.926626,
      56.083012
    ],
    [
      39.92761,
      56.071386
    ],
    [
      39.922912,
      56.071354
    ],
    [
      39.920033,
      56.068218
    ],
    [
      39.913536,
      56.066091
    ],
    [
      39.90705,
      56.07041
    ],
    [
      39.898555,
      56.070987
    ],
    [
      39.893749,
      56.072063
    ],
    [
      39.884438,
      56.075117
    ],
    [
      39.877643,
      56.0725
    ],
    [
      39.877106,
      56.071217
    ],
    [
      39.876675,
      56.069033
    ],
    [
      39.881824,
      56.065202
    ],
    [
      39.883788,
      56.066336
    ],
    [
      39.891174,
      56.068716
    ],
    [
      39.898353,
      56.065027
    ],
    [
      39.903976,
      56.066504
    ],
    [
      39.911488,
      56.057548
    ],
    [
      39.911372,
      56.049553
    ],
    [
      39.912042,
      56.047558
    ],
    [
      39.91622,
      56.04566
    ],
    [
      39.921361,
      56.050094
    ],
    [
      39.923735,
      56.051177
    ],
    [
      39.925022,
      56.05274
    ],
    [
      39.922243,
      56.055029
    ],
    [
      39.927383,
      56.065216
    ],
    [
      39.931281,
      56.064596
    ],
    [
      39.937307290028684,
      56.06414043525207
    ],
    [
      39.936817,
      56.063338
    ],
    [
      39.94195,
      56.053674
    ],
    [
      39.952345,
      56.048791
    ],
    [
      39.95424942648488,
      56.04913814948778
    ],
    [
      39.954205,
      56.048556
    ],
    [
      39.954247,
      56.048479
    ],
    [
      39.955971,
      56.047623
    ],
    [
      39.959879,
      56.046398
    ],
    [
      39.960489,
      56.046512
    ],
    [
      39.967371,
      56.049958
    ],
    [
      39.970825,
      56.03941
    ],
    [
      39.970285,
      56.03344
    ],
    [
      39.959123,
      56.030005
    ],
    [
      39.957804,
      56.033355
    ],
    [
      39.95293,
      56.031373
    ],
    [
      39.94359,
      56.025474
    ],
    [
      39.940952,
      56.028598
    ],
    [
      39.93712,
      56.024053
    ],
    [
      39.928059,
      56.022937
    ],
    [
      39.918334,
      56.024517
    ],
    [
      39.911426,
      56.027075
    ],
    [
      39.907909,
      56.027532
    ],
    [
      39.904404,
      56.026602
    ],
    [
      39.894423,
      56.024674
    ],
    [
      39.890414,
      56.025891
    ],
    [
      39.885706,
      56.028635
    ],
    [
      39.880343,
      56.032357
    ],
    [
      39.88002109344045,
      56.032016747108635
    ],
    [
      39.876162,
      56.033378
    ],
    [
      39.872017,
      56.034051
    ],
    [
      39.873233,
      56.030229
    ],
    [
      39.878635,
      56.029017
    ],
    [
      39.88186953778884,
      56.02677764380358
    ],
    [
      39.881777,
      56.026489
    ],
    [
      39.882727,
      56.026184
    ],
    [
      39.886274,
      56.024138
    ],
    [
      39.89155,
      56.021691
    ],
    [
      39.895251729791724,
      56.020683634700426
    ],
    [
      39.893537,
      56.013057
    ],
    [
      39.884891531478836,
      56.00912868303325
    ],
    [
      39.878253,
      56.013682
    ],
    [
      39.87512177572878,
      56.014502233770266
    ],
    [
      39.874971,
      56.014739
    ],
    [
      39.874557106858354,
      56.01473880582071
    ],
    [
      39.871406,
      56.016705
    ],
    [
      39.864261,
      56.009721
    ],
    [
      39.860725,
      56.010939
    ],
    [
      39.85533,
      56.010844
    ],
    [
      39.84825,
      56.004964
    ],
    [
      39.842195,
      56.004273
    ],
    [
      39.837305,
      56.004314
    ],
    [
      39.830839,
      56.00554
    ],
    [
      39.822991,
      56.00538
    ],
    [
      39.818556,
      56.005445
    ],
    [
      39.807276,
      56.009432
    ],
    [
      39.803693,
      56.009075
    ],
    [
      39.803781,
      56.008192
    ],
    [
      39.808103,
      56.004672
    ],
    [
      39.813072,
      56.002356
    ],
    [
      39.818228,
      56.00186
    ],
    [
      39.822934,
      56.001781
    ],
    [
      39.826545,
      56.001281
    ],
    [
      39.831637,
      56.002029
    ],
    [
      39.836673,
      56.00077
    ],
    [
      39.842291,
      56.000674
    ],
    [
      39.848524,
      56.001375
    ],
    [
      39.852678,
      56.001878
    ],
    [
      39.854272983512345,
      55.98776614587696
    ],
    [
      39.844505,
      55.985197
    ],
    [
      39.832761,
      55.982019
    ],
    [
      39.822999,
      55.982318
    ],
    [
      39.820758,
      55.985024
    ],
    [
      39.817066,
      55.989394
    ],
    [
      39.814316,
      55.98707
    ],
    [
      39.812987,
      55.976948
    ],
    [
      39.809262,
      55.975854
    ],
    [
      39.8079,
      55.975619
    ],
    [
      39.80524,
      55.974936
    ],
    [
      39.802579,
      55.974253
    ],
    [
      39.800357,
      55.973511
    ],
    [
      39.795382840333964,
      55.97235945389803
    ],
    [
      39.793834,
      55.972123
    ],
    [
      39.791713,
      55.971535
    ],
    [
      39.791392621871395,
      55.97143569575091
    ],
    [
      39.791165,
      55.971383
    ],
    [
      39.787197,
      55.970147
    ],
    [
      39.781556,
      55.968578
    ],
    [
      39.772828,
      55.967134
    ],
    [
      39.76991010973391,
      55.97870356490017
    ],
    [
      39.770324,
      55.979006
    ],
    [
      39.773615,
      55.98272
    ],
    [
      39.776192,
      55.985477
    ],
    [
      39.778659,
      55.98817
    ],
    [
      39.778739,
      55.993249
    ],
    [
      39.778556,
      55.999293
    ],
    [
      39.774803,
      56.006315
    ],
    [
      39.773401,
      56.007539
    ],
    [
      39.768747,
      56.011495
    ],
    [
      39.76874597787435,
      56.01149379773128
    ],
    [
      39.768582,
      56.011634
    ],
    [
      39.766682926909574,
      56.01311058267256
    ],
    [
      39.764498,
      56.022291
    ],
    [
      39.767574,
      56.027789
    ],
    [
      39.763346,
      56.030266
    ],
    [
      39.753834,
      56.036499
    ],
    [
      39.750103,
      56.038346
    ],
    [
      39.738795,
      56.038278
    ],
    [
      39.733418,
      56.029096
    ],
    [
      39.729619,
      56.029056
    ],
    [
      39.726043,
      56.029292
    ],
    [
      39.722476,
      56.030306
    ],
    [
      39.718937,
      56.031282
    ],
    [
      39.710922,
      56.037945
    ],
    [
      39.709899,
      56.039448
    ],
    [
      39.705043,
      56.043887
    ],
    [
      39.700322,
      56.050702
    ],
    [
      39.70063774926659,
      56.0512961587682
    ],
    [
      39.701705,
      56.051881
    ],
    [
      39.701474822740714,
      56.05287131537789
    ],
    [
      39.705717,
      56.060854
    ],
    [
      39.709774,
      56.061239
    ],
    [
      39.713581,
      56.061886
    ],
    [
      39.713527,
      56.066289
    ],
    [
      39.716437,
      56.076861
    ],
    [
      39.719887,
      56.077554
    ],
    [
      39.722846,
      56.079891
    ],
    [
      39.724165,
      56.088874
    ],
    [
      39.726856,
      56.091309
    ],
    [
      39.732293,
      56.09563
    ],
    [
      39.733782,
      56.096618
    ],
    [
      39.733508054552,
      56.097227324461926
    ],
    [
      39.73475,
      56.09886
    ],
    [
      39.735825,
      56.105024
    ],
    [
      39.735677238095235,
      56.108898952381054
    ],
    [
      39.735556,
      56.1121
    ],
    [
      39.731958,
      56.111963
    ],
    [
      39.72164,
      56.114088
    ],
    [
      39.717059,
      56.119178
    ],
    [
      39.714415,
      56.124355
    ],
    [
      39.712567,
      56.128995
    ],
    [
      39.710939,
      56.138193
    ],
    [
      39.710037,
      56.148138
    ],
    [
      39.711029,
      56.153048
    ],
    [
      39.715965,
      56.158634
    ],
    [
      39.714939,
      56.162735
    ],
    [
      39.71416846839825,
      56.16328678943946
    ],
    [
      39.712805,
      56.166818
    ],
    [
      39.709255,
      56.166216
    ],
    [
      39.709532,
      56.1645
    ],
    [
      39.70945684814201,
      56.16410282686859
    ],
    [
      39.707088,
      56.164466
    ],
    [
      39.704647,
      56.16182
    ],
    [
      39.706838,
      56.159798
    ],
    [
      39.706757,
      56.149621
    ],
    [
      39.705893,
      56.144419
    ],
    [
      39.70624724331008,
      56.14226252662015
    ],
    [
      39.70459,
      56.140797
    ],
    [
      39.69768,
      56.134538
    ],
    [
      39.694834691569305,
      56.13238336724482
    ],
    [
      39.69478,
      56.132427
    ],
    [
      39.69396,
      56.131721
    ],
    [
      39.685865,
      56.127464
    ],
    [
      39.678855,
      56.137159
    ],
    [
      39.678892,
      56.138989
    ],
    [
      39.67836,
      56.140725
    ],
    [
      39.677499,
      56.142308
    ],
    [
      39.672673,
      56.152091
    ],
    [
      39.669177,
      56.151119
    ],
    [
      39.667813,
      56.147579
    ],
    [
      39.659824,
      56.140331
    ],
    [
      39.654775,
      56.136479
    ],
    [
      39.64828,
      56.131277
    ],
    [
      39.642946,
      56.128926
    ],
    [
      39.63369,
      56.1349
    ],
    [
      39.633958,
      56.138606
    ],
    [
      39.635889,
      56.142414
    ],
    [
      39.64051,
      56.148895
    ],
    [
      39.643599,
      56.150738
    ],
    [
      39.6451,
      56.151251
    ],
    [
      39.64628267751712,
      56.15233851073443
    ],
    [
      39.651183,
      56.155261
    ],
    [
      39.655452,
      56.158774
    ],
    [
      39.658829,
      56.1626
    ],
    [
      39.662009,
      56.165174
    ],
    [
      39.667716,
      56.16714
    ],
    [
      39.672226,
      56.169622
    ],
    [
      39.677662,
      56.171389
    ],
    [
      39.684633,
      56.173462
    ],
    [
      39.692192,
      56.174627
    ],
    [
      39.693090305088106,
      56.175137757007704
    ],
    [
      39.693142,
      56.175148
    ],
    [
      39.696732,
      56.175417
    ],
    [
      39.696603,
      56.177135
    ],
    [
      39.699429,
      56.179394
    ],
    [
      39.706231,
      56.180812
    ],
    [
      39.70972060281089,
      56.18251064263811
    ],
    [
      39.71153694664937,
      56.18338233497535
    ],
    [
      39.721309,
      56.179413
    ],
    [
      39.726622,
      56.176248
    ],
    [
      39.732427,
      56.174476
    ],
    [
      39.735856,
      56.171668
    ],
    [
      39.742369,
      56.164997
    ],
    [
      39.744189,
      56.163884
    ],
    [
      39.746067,
      56.166956
    ],
    [
      39.753398,
      56.172157
    ],
    [
      39.756046,
      56.173005
    ],
    [
      39.762432,
      56.18078
    ],
    [
      39.768651830868855,
      56.183361672557496
    ],
    [
      39.772564,
      56.183628
    ],
    [
      39.775227,
      56.183757
    ],
    [
      39.775852,
      56.183811
    ],
    [
      39.776897,
      56.185258
    ],
    [
      39.776851,
      56.185592
    ],
    [
      39.773133,
      56.194235
    ],
    [
      39.772255,
      56.196942
    ],
    [
      39.771772,
      56.197492
    ],
    [
      39.765473,
      56.198676
    ],
    [
      39.755735,
      56.2019
    ],
    [
      39.75272,
      56.204225
    ],
    [
      39.750144,
      56.20171
    ],
    [
      39.746168,
      56.193257
    ],
    [
      39.739196,
      56.194361
    ],
    [
      39.732224,
      56.195464
    ],
    [
      39.726427,
      56.196376
    ],
    [
      39.720632,
      56.197288
    ],
    [
      39.715641,
      56.198046
    ],
    [
      39.707296,
      56.199364
    ],
    [
      39.700505,
      56.198141
    ],
    [
      39.688252,
      56.200714
    ],
    [
      39.6799820427545,
      56.1981909889231
    ],
    [
      39.677693629328616,
      56.20650302936738
    ],
    [
      39.678319,
      56.208237
    ],
    [
      39.684598,
      56.21313
    ],
    [
      39.689878,
      56.213398
    ],
    [
      39.68987625435231,
      56.21343230245404
    ],
    [
      39.690517,
      56.213469
    ],
    [
      39.694116,
      56.216866
    ],
    [
      39.68969727768975,
      56.21694924277402
    ],
    [
      39.689695,
      56.216994
    ],
    [
      39.681562,
      56.221434
    ],
    [
      39.67849,
      56.223311
    ],
    [
      39.668604,
      56.222987
    ],
    [
      39.665022,
      56.222633
    ],
    [
      39.665222,
      56.220603
    ],
    [
      39.665423,
      56.218574
    ],
    [
      39.665909,
      56.214263
    ],
    [
      39.667348,
      56.210552
    ],
    [
      39.660365,
      56.203435
    ],
    [
      39.65984867363199,
      56.203688406065694
    ],
    [
      39.660047,
      56.205288
    ],
    [
      39.65704,
      56.205661
    ],
    [
      39.655318,
      56.205912
    ],
    [
      39.65358297583003,
      56.20621386086572
    ],
    [
      39.653583,
      56.206214
    ],
    [
      39.650576,
      56.206737
    ],
    [
      39.647568,
      56.20726
    ],
    [
      39.645626,
      56.207427
    ],
    [
      39.638069,
      56.206749
    ],
    [
      39.636364,
      56.215624
    ],
    [
      39.637575,
      56.219015
    ],
    [
      39.628731,
      56.217683
    ],
    [
      39.62422453083399,
      56.21695061054445
    ],
    [
      39.620715,
      56.227678
    ],
    [
      39.61802,
      56.227671
    ],
    [
      39.610992,
      56.228801
    ],
    [
      39.607955,
      56.235252
    ],
    [
      39.60794298239154,
      56.23525253083243
    ],
    [
      39.607943,
      56.235253
    ],
    [
      39.60787215227675,
      56.23525565948505
    ],
    [
      39.603563,
      56.235446
    ],
    [
      39.598748,
      56.23556
    ],
    [
      39.594756,
      56.235722
    ],
    [
      39.59095,
      56.235853
    ],
    [
      39.587416,
      56.235967
    ],
    [
      39.587034997250484,
      56.23597588219648
    ],
    [
      39.587035,
      56.235976
    ],
    [
      39.584159,
      56.236043
    ],
    [
      39.581282,
      56.23611
    ],
    [
      39.577517,
      56.236278
    ],
    [
      39.572018,
      56.245987
    ],
    [
      39.5714,
      56.249534
    ],
    [
      39.570067,
      56.249302
    ],
    [
      39.564132,
      56.248261
    ],
    [
      39.55969,
      56.256241
    ],
    [
      39.553849,
      56.256108
    ],
    [
      39.55062,
      56.254468
    ],
    [
      39.54971058308663,
      56.25399991776518
    ],
    [
      39.549708,
      56.254005
    ],
    [
      39.546536,
      56.252394
    ],
    [
      39.535045,
      56.253882
    ],
    [
      39.532351,
      56.255264
    ],
    [
      39.531342,
      56.253832
    ],
    [
      39.526928,
      56.246064
    ],
    [
      39.52672053793884,
      56.24555105345529
    ],
    [
      39.524117,
      56.243457
    ],
    [
      39.516373,
      56.235596
    ],
    [
      39.51315470256145,
      56.23702119626922
    ],
    [
      39.513164,
      56.237042
    ],
    [
      39.51183,
      56.237638
    ],
    [
      39.510827,
      56.238052
    ],
    [
      39.507398,
      56.23933
    ],
    [
      39.504002,
      56.240596
    ],
    [
      39.500075,
      56.242236
    ],
    [
      39.495981,
      56.244582
    ],
    [
      39.489043,
      56.246968
    ],
    [
      39.483576876300134,
      56.252227067104535
    ],
    [
      39.483301,
      56.253311
    ],
    [
      39.473632,
      56.260239
    ],
    [
      39.470629,
      56.2628
    ],
    [
      39.466185,
      56.266757
    ],
    [
      39.470813,
      56.276693
    ],
    [
      39.471641,
      56.280196
    ],
    [
      39.46793,
      56.27984
    ],
    [
      39.459187,
      56.283991
    ],
    [
      39.464685,
      56.293727
    ],
    [
      39.469143,
      56.294312
    ],
    [
      39.47382,
      56.29414
    ],
    [
      39.477917,
      56.29297
    ],
    [
      39.481778,
      56.292348
    ],
    [
      39.485472,
      56.291855
    ],
    [
      39.490358,
      56.291476
    ],
    [
      39.494092,
      56.291446
    ],
    [
      39.498628,
      56.2907
    ],
    [
      39.499129,
      56.290815
    ],
    [
      39.50498621724704,
      56.29261095300605
    ],
    [
      39.512258,
      56.292697
    ],
    [
      39.516254,
      56.292201
    ],
    [
      39.522676,
      56.291447
    ],
    [
      39.52652,
      56.290799
    ],
    [
      39.530786,
      56.280329
    ],
    [
      39.532364,
      56.276714
    ],
    [
      39.53464,
      56.279581
    ],
    [
      39.538506863038535,
      56.28979325509741
    ],
    [
      39.53950111868944,
      56.2898300117357
    ],
    [
      39.546511,
      56.289635
    ],
    [
      39.552536,
      56.289456
    ],
    [
      39.557585,
      56.289227
    ],
    [
      39.562706,
      56.289048
    ],
    [
      39.567148,
      56.288944
    ],
    [
      39.571574,
      56.288841
    ],
    [
      39.5838025427282,
      56.288446281215734
    ],
    [
      39.587865,
      56.283066
    ],
    [
      39.583279,
      56.272613
    ],
    [
      39.579121,
      56.272006
    ],
    [
      39.573599,
      56.271279
    ],
    [
      39.573077,
      56.270882
    ],
    [
      39.575257,
      56.268017
    ],
    [
      39.579642,
      56.268444
    ],
    [
      39.583799,
      56.269051
    ],
    [
      39.590178,
      56.270747
    ],
    [
      39.59160432206878,
      56.274673744864174
    ],
    [
      39.592258,
      56.274687
    ],
    [
      39.59222294836354,
      56.276376857841925
    ],
    [
      39.592223,
      56.276377
    ],
    [
      39.603767,
      56.279161
    ],
    [
      39.609891,
      56.276795
    ],
    [
      39.611339,
      56.275968
    ],
    [
      39.611996701585134,
      56.27670671010261
    ],
    [
      39.614606,
      56.273842
    ],
    [
      39.618124,
      56.27423
    ],
    [
      39.628305,
      56.272243
    ],
    [
      39.632494,
      56.271987
    ],
    [
      39.633836,
      56.277475
    ],
    [
      39.63355036386675,
      56.27954174355651
    ],
    [
      39.635624,
      56.281449
    ],
    [
      39.64229472756773,
      56.28635239297815
    ],
    [
      39.64340364940518,
      56.286524945446104
    ],
    [
      39.64340417721788,
      56.28652492707268
    ],
    [
      39.650857,
      56.283458
    ],
    [
      39.654307,
      56.276608
    ],
    [
      39.656087,
      56.273216
    ],
    [
      39.662803,
      56.26913
    ],
    [
      39.664831,
      56.267913
    ],
    [
      39.664931383965914,
      56.26785280921965
    ],
    [
      39.668407,
      56.257074
    ],
    [
      39.672508,
      56.253962
    ],
    [
      39.674877,
      56.246316
    ],
    [
      39.668003,
      56.242194
    ],
    [
      39.664192,
      56.242396
    ],
    [
      39.658837,
      56.244184
    ],
    [
      39.655151,
      56.245557
    ],
    [
      39.651465,
      56.247611
    ],
    [
      39.648273,
      56.249077
    ],
    [
      39.644662,
      56.248601
    ],
    [
      39.649899,
      56.244277
    ],
    [
      39.653589,
      56.242313
    ],
    [
      39.657635,
      56.240791
    ],
    [
      39.662892,
      56.23886
    ],
    [
      39.667919,
      56.238595
    ],
    [
      39.674200371303954,
      56.2380986988506
    ],
    [
      39.674202,
      56.238086
    ],
    [
      39.67469809862711,
      56.23789027147062
    ],
    [
      39.680695,
      56.232871
    ],
    [
      39.686929,
      56.234143
    ],
    [
      39.689293,
      56.238168
    ],
    [
      39.700892,
      56.239839
    ],
    [
      39.701405,
      56.236276
    ],
    [
      39.705755,
      56.244962
    ],
    [
      39.712352,
      56.24211
    ],
    [
      39.717424,
      56.241915
    ],
    [
      39.723806,
      56.243088
    ],
    [
      39.723116,
      56.247115
    ],
    [
      39.720745,
      56.254008
    ],
    [
      39.720168,
      56.259408
    ],
    [
      39.721013,
      56.264025
    ],
    [
      39.72100031603573,
      56.26403511701424
    ],
    [
      39.721725,
      56.265678
    ],
    [
      39.720665,
      56.266146
    ],
    [
      39.71670724729809,
      56.26883018532064
    ],
    [
      39.713351,
      56.277461
    ],
    [
      39.709801,
      56.285033
    ],
    [
      39.717885,
      56.289391
    ],
    [
      39.721951,
      56.289054
    ],
    [
      39.725855,
      56.289022
    ],
    [
      39.729812,
      56.288742
    ],
    [
      39.734321,
      56.288941
    ],
    [
      39.7382,
      56.291146
    ],
    [
      39.747403,
      56.288201
    ],
    [
      39.758062,
      56.283651
    ],
    [
      39.758574,
      56.278173
    ],
    [
      39.75952,
      56.264295
    ],
    [
      39.756635,
      56.261732
    ],
    [
      39.754634,
      56.259701
    ],
    [
      39.757199,
      56.257175
    ],
    [
      39.760619,
      56.256413
    ],
    [
      39.761706,
      56.256378
    ],
    [
      39.761888,
      56.257327
    ],
    [
      39.763845,
      56.264541
    ],
    [
      39.762284,
      56.272665
    ],
    [
      39.762145,
      56.278635
    ],
    [
      39.770463,
      56.285003
    ],
    [
      39.778944,
      56.276563
    ],
    [
      39.781041,
      56.273676
    ],
    [
      39.783824,
      56.270431
    ],
    [
      39.786413,
      56.267082
    ],
    [
      39.792951,
      56.268288
    ],
    [
      39.795425,
      56.268884
    ],
    [
      39.7979,
      56.269481
    ],
    [
      39.797056,
      56.27298
    ],
    [
      39.792108,
      56.271788
    ],
    [
      39.786791,
      56.273352
    ],
    [
      39.785691,
      56.282561
    ],
    [
      39.782499,
      56.285158
    ],
    [
      39.776801,
      56.287695
    ],
    [
      39.77096,
      56.288568
    ],
    [
      39.767587,
      56.289511
    ],
    [
      39.762783,
      56.291548
    ],
    [
      39.758452,
      56.292332
    ],
    [
      39.754137,
      56.2984
    ],
    [
      39.756294,
      56.304137
    ],
    [
      39.76169,
      56.30888
    ],
    [
      39.765347,
      56.310332
    ],
    [
      39.769263,
      56.312079
    ],
    [
      39.779956,
      56.313713
    ],
    [
      39.790982,
      56.310429
    ],
    [
      39.791826,
      56.310138
    ],
    [
      39.793001,
      56.31354
    ],
    [
      39.787989,
      56.316423
    ],
    [
      39.78232,
      56.324266
    ],
    [
      39.780049,
      56.32706
    ],
    [
      39.779044,
      56.327045
    ],
    [
      39.776792,
      56.324236
    ],
    [
      39.774606,
      56.31873
    ],
    [
      39.765571,
      56.314403
    ],
    [
      39.761773,
      56.312855
    ],
    [
      39.75711,
      56.310918
    ],
    [
      39.753842,
      56.308245
    ],
    [
      39.747479,
      56.299174
    ],
    [
      39.745867470068696,
      56.297988410824196
    ],
    [
      39.745672,
      56.298145
    ],
    [
      39.744002,
      56.296616
    ],
    [
      39.733883,
      56.292514
    ],
    [
      39.729658,
      56.292338
    ],
    [
      39.72541,
      56.292683
    ],
    [
      39.71577,
      56.296707
    ],
    [
      39.70725,
      56.29297
    ],
    [
      39.70052,
      56.291831
    ],
    [
      39.695944,
      56.297436
    ],
    [
      39.695125,
      56.301211
    ],
    [
      39.693093,
      56.306051
    ],
    [
      39.686946,
      56.300264
    ],
    [
      39.68377419984632,
      56.300537595942615
    ],
    [
      39.67761464177565,
      56.30192529432196
    ],
    [
      39.67727,
      56.308462
    ],
    [
      39.684328,
      56.317959
    ],
    [
      39.686794,
      56.322304
    ],
    [
      39.684323,
      56.324882
    ],
    [
      39.683459569554515,
      56.32419927919977
    ],
    [
      39.681611,
      56.32525
    ],
    [
      39.679323,
      56.323004
    ],
    [
      39.679123,
      56.322643
    ],
    [
      39.677113,
      56.319181
    ],
    [
      39.670396,
      56.312497
    ],
    [
      39.670999,
      56.306541
    ],
    [
      39.67407679234076,
      56.3007153930548
    ],
    [
      39.674516,
      56.296211
    ],
    [
      39.666019,
      56.289304
    ],
    [
      39.659569,
      56.289546
    ],
    [
      39.653034,
      56.287991
    ],
    [
      39.65137540375258,
      56.288362853614224
    ],
    [
      39.651427,
      56.289848
    ],
    [
      39.643260648705336,
      56.290131339567424
    ],
    [
      39.633961,
      56.290455
    ],
    [
      39.628026,
      56.284355
    ],
    [
      39.624866,
      56.282687
    ],
    [
      39.617729,
      56.283385
    ],
    [
      39.616322493061276,
      56.28741079141699
    ],
    [
      39.627817,
      56.287047
    ],
    [
      39.627931,
      56.290646
    ],
    [
      39.615050048657494,
      56.2910528607295
    ],
    [
      39.61505,
      56.291053
    ],
    [
      39.615049995679776,
      56.291052862402864
    ],
    [
      39.612038,
      56.291148
    ],
    [
      39.61177075194273,
      56.29095705743309
    ],
    [
      39.611777,
      56.291156
    ],
    [
      39.596145,
      56.29165
    ],
    [
      39.5931,
      56.291343
    ],
    [
      39.58748494773966,
      56.29177229282147
    ],
    [
      39.58749,
      56.291929
    ],
    [
      39.577869,
      56.293356
    ],
    [
      39.57412,
      56.293453
    ],
    [
      39.567232,
      56.292543
    ],
    [
      39.56279,
      56.292647
    ],
    [
      39.555205,
      56.299855
    ],
    [
      39.548472,
      56.308498
    ],
    [
      39.54697431670151,
      56.30892597133262
    ],
    [
      39.546686,
      56.313209
    ],
    [
      39.548679,
      56.317824
    ],
    [
      39.551443,
      56.321625
    ],
    [
      39.55116403136962,
      56.322039610467385
    ],
    [
      39.553264,
      56.325602
    ],
    [
      39.553562,
      56.326181
    ],
    [
      39.550364,
      56.327833
    ],
    [
      39.54769590844126,
      56.327194025379
    ],
    [
      39.545526,
      56.330419
    ],
    [
      39.544715,
      56.333407
    ],
    [
      39.541917,
      56.334986
    ],
    [
      39.540148,
      56.331851
    ],
    [
      39.538927,
      56.32891
    ],
    [
      39.53776337647032,
      56.325304178732985
    ],
    [
      39.537258,
      56.325209
    ],
    [
      39.532462,
      56.314969
    ],
    [
      39.53152,
      56.311341
    ],
    [
      39.52997754574327,
      56.3091251249659
    ],
    [
      39.522808,
      56.304277
    ],
    [
      39.516675,
      56.295776
    ],
    [
      39.512702,
      56.29627
    ],
    [
      39.507805,
      56.296768
    ],
    [
      39.50558671231479,
      56.29649726939546
    ],
    [
      39.505586,
      56.296503
    ],
    [
      39.50296577182048,
      56.29617739708619
    ],
    [
      39.502856,
      56.296164
    ],
    [
      39.50192440397402,
      56.29604799140778
    ],
    [
      39.501844,
      56.296038
    ],
    [
      39.498102,
      56.295572
    ],
    [
      39.490553,
      56.295071
    ],
    [
      39.485815,
      56.295439
    ],
    [
      39.482317,
      56.295907
    ],
    [
      39.47826,
      56.307175
    ],
    [
      39.478281,
      56.310775
    ],
    [
      39.476614,
      56.310785
    ],
    [
      39.47465,
      56.309981
    ],
    [
      39.474223,
      56.305542
    ],
    [
      39.46870875237844,
      56.299458560947336
    ],
    [
      39.463547,
      56.297142
    ],
    [
      39.458706,
      56.295485
    ],
    [
      39.454396,
      56.293959
    ],
    [
      39.447779,
      56.291529
    ],
    [
      39.437398,
      56.294441
    ],
    [
      39.430496,
      56.300298
    ],
    [
      39.429469038048325,
      56.299880220134014
    ],
    [
      39.42943,
      56.299907
    ],
    [
      39.429368537237586,
      56.29983933525491
    ],
    [
      39.424095,
      56.297694
    ],
    [
      39.425432,
      56.294164
    ],
    [
      39.424291,
      56.289541
    ],
    [
      39.422397,
      56.286253
    ],
    [
      39.411147,
      56.286749
    ],
    [
      39.41059060158463,
      56.28895104530334
    ],
    [
      39.411298,
      56.29219
    ],
    [
      39.40867,
      56.294858
    ],
    [
      39.405904144579715,
      56.29706710648255
    ],
    [
      39.405581,
      56.297561
    ],
    [
      39.40340408964457,
      56.29914177182732
    ],
    [
      39.403046,
      56.299777
    ],
    [
      39.403632,
      56.307387
    ],
    [
      39.411562,
      56.311038
    ],
    [
      39.414773,
      56.31637
    ],
    [
      39.41470116343411,
      56.31686781479872
    ],
    [
      39.416224,
      56.317519
    ],
    [
      39.419119,
      56.325372
    ],
    [
      39.42191,
      56.335986
    ],
    [
      39.429489,
      56.340142
    ],
    [
      39.437044,
      56.344284
    ],
    [
      39.44218,
      56.346998
    ],
    [
      39.450031,
      56.35091
    ],
    [
      39.457883,
      56.354823
    ],
    [
      39.456277,
      56.358045
    ],
    [
      39.45270303303567,
      56.356263933681674
    ],
    [
      39.452703,
      56.356264
    ],
    [
      39.453884,
      56.366547
    ],
    [
      39.464841,
      56.362313
    ],
    [
      39.466428,
      56.359081
    ],
    [
      39.470349,
      56.361006
    ],
    [
      39.481725,
      56.362609
    ],
    [
      39.483788,
      56.362269
    ],
    [
      39.486678,
      56.366084
    ],
    [
      39.48667787551362,
      56.3660842573089
    ],
    [
      39.486946,
      56.366214
    ],
    [
      39.497778,
      56.371562
    ],
    [
      39.508597,
      56.376904
    ],
    [
      39.521013,
      56.382916
    ],
    [
      39.533426,
      56.388928
    ],
    [
      39.542551,
      56.391369
    ],
    [
      39.5464045100216,
      56.39041239300076
    ],
    [
      39.548756,
      56.383438
    ],
    [
      39.54278,
      56.377552
    ],
    [
      39.538282,
      56.377364
    ],
    [
      39.537926,
      56.377277
    ],
    [
      39.53795116026898,
      56.37717370114919
    ],
    [
      39.535024,
      56.37549
    ],
    [
      39.534859,
      56.373184
    ],
    [
      39.535114,
      56.371905
    ],
    [
      39.538644,
      56.372611
    ],
    [
      39.545059,
      56.374035
    ],
    [
      39.548941,
      56.374797
    ],
    [
      39.5546,
      56.384096
    ],
    [
      39.566172,
      56.387064
    ],
    [
      39.572833,
      56.383775
    ],
    [
      39.578132,
      56.374945
    ],
    [
      39.581732,
      56.374945
    ],
    [
      39.58224,
      56.374946
    ],
    [
      39.58335505666516,
      56.37720255039563
    ],
    [
      39.583386,
      56.377188
    ],
    [
      39.592234,
      56.382705
    ],
    [
      39.600954,
      56.380765
    ],
    [
      39.603974,
      56.379663
    ],
    [
      39.6063169024483,
      56.385461110442506
    ],
    [
      39.614046,
      56.383232
    ],
    [
      39.61663,
      56.380654
    ],
    [
      39.621681,
      56.380355
    ],
    [
      39.630792,
      56.381857
    ],
    [
      39.636652,
      56.382763
    ],
    [
      39.630437,
      56.389189
    ],
    [
      39.62886,
      56.392425
    ],
    [
      39.620339,
      56.392747
    ],
    [
      39.609521,
      56.389742
    ],
    [
      39.605729,
      56.38959
    ],
    [
      39.601042,
      56.389489
    ],
    [
      39.600604835703656,
      56.38945860527379
    ],
    [
      39.599904,
      56.389449
    ],
    [
      39.591396,
      56.390385
    ],
    [
      39.585763,
      56.393331
    ],
    [
      39.588976,
      56.403869
    ],
    [
      39.597308,
      56.405659
    ],
    [
      39.602193,
      56.404706
    ],
    [
      39.603686,
      56.40484
    ],
    [
      39.606103,
      56.405778
    ],
    [
      39.604801,
      56.409134
    ],
    [
      39.598132,
      56.409164
    ],
    [
      39.591859,
      56.41042
    ],
    [
      39.591736001934464,
      56.42172282223364
    ],
    [
      39.596846,
      56.426881
    ],
    [
      39.59683978330394,
      56.426887160777405
    ],
    [
      39.597146,
      56.427197
    ],
    [
      39.59799618812182,
      56.42864935502437
    ],
    [
      39.59947,
      56.43028
    ],
    [
      39.59937762277501,
      56.43100922516123
    ],
    [
      39.602137,
      56.435723
    ],
    [
      39.605095,
      56.442298
    ],
    [
      39.607971,
      56.448711
    ],
    [
      39.615585,
      56.455756
    ],
    [
      39.617483,
      56.456877
    ],
    [
      39.616571812453756,
      56.45841969874023
    ],
    [
      39.616585,
      56.458437
    ],
    [
      39.624838,
      56.463242
    ],
    [
      39.630225,
      56.464407
    ],
    [
      39.636073,
      56.467857
    ],
    [
      39.64192,
      56.471307
    ],
    [
      39.647168,
      56.474403
    ],
    [
      39.650478,
      56.47727
    ],
    [
      39.662005,
      56.476637
    ],
    [
      39.66259,
      56.47657
    ],
    [
      39.667045,
      56.478917
    ],
    [
      39.668261,
      56.481205
    ],
    [
      39.66844,
      56.481609
    ],
    [
      39.66796,
      56.484668
    ],
    [
      39.667761,
      56.485577
    ],
    [
      39.665616,
      56.48877
    ],
    [
      39.665441,
      56.488857
    ],
    [
      39.663269,
      56.489606
    ],
    [
      39.66156,
      56.489572
    ],
    [
      39.656584,
      56.482007
    ],
    [
      39.646298,
      56.479027
    ],
    [
      39.640091,
      56.474408
    ],
    [
      39.634243,
      56.470958
    ],
    [
      39.628039,
      56.478606
    ],
    [
      39.631209,
      56.484121
    ],
    [
      39.632797,
      56.485258
    ],
    [
      39.633859,
      56.486188
    ],
    [
      39.631486,
      56.488896
    ],
    [
      39.627274,
      56.488388
    ],
    [
      39.625304,
      56.48518
    ],
    [
      39.612616,
      56.484617
    ],
    [
      39.611199,
      56.484883
    ],
    [
      39.607239,
      56.481873
    ],
    [
      39.60768,
      56.479388
    ],
    [
      39.611244,
      56.478885
    ],
    [
      39.618781,
      56.480239
    ],
    [
      39.624256,
      56.473601
    ],
    [
      39.621861,
      56.468009
    ],
    [
      39.612006,
      56.461348
    ],
    [
      39.60782,
      56.454481
    ],
    [
      39.604853,
      56.450512
    ],
    [
      39.601812,
      56.443775
    ],
    [
      39.593489,
      56.437571
    ],
    [
      39.585592,
      56.438111
    ],
    [
      39.584177,
      56.4348
    ],
    [
      39.589177742307335,
      56.42425654331363
    ],
    [
      39.589091,
      56.424169
    ],
    [
      39.583894,
      56.418922
    ],
    [
      39.579021,
      56.415169
    ],
    [
      39.574864,
      56.413042
    ],
    [
      39.571856191759565,
      56.41157760613849
    ],
    [
      39.571856,
      56.411578
    ],
    [
      39.56497,
      56.408225
    ],
    [
      39.557893,
      56.409725
    ],
    [
      39.554618,
      56.408229
    ],
    [
      39.549966,
      56.401662
    ],
    [
      39.538861,
      56.40229
    ],
    [
      39.536657,
      56.412322
    ],
    [
      39.536654,
      56.412385
    ],
    [
      39.536286949563404,
      56.41274431219829
    ],
    [
      39.5427,
      56.421182
    ],
    [
      39.548279,
      56.422987
    ],
    [
      39.553847,
      56.4263
    ],
    [
      39.562608,
      56.428513
    ],
    [
      39.564652,
      56.432447
    ],
    [
      39.564436,
      56.432626
    ],
    [
      39.55894582787943,
      56.43251040879961
    ],
    [
      39.558932,
      56.432627
    ],
    [
      39.557739,
      56.432485
    ],
    [
      39.55207,
      56.429431
    ],
    [
      39.546051,
      56.425814
    ],
    [
      39.541666,
      56.42463
    ],
    [
      39.536683,
      56.423136
    ],
    [
      39.529092,
      56.428375
    ],
    [
      39.52463,
      56.428925
    ],
    [
      39.517035,
      56.432569
    ],
    [
      39.508389,
      56.436637
    ],
    [
      39.50838889012649,
      56.43663676633949
    ],
    [
      39.507737981382576,
      56.43694303713504
    ],
    [
      39.502702,
      56.446988
    ],
    [
      39.506203,
      56.448866
    ],
    [
      39.509705,
      56.450786
    ],
    [
      39.519053,
      56.452361
    ],
    [
      39.524029,
      56.45084
    ],
    [
      39.528611,
      56.449386
    ],
    [
      39.532787,
      56.447888
    ],
    [
      39.536236,
      56.446688
    ],
    [
      39.540784,
      56.44922
    ],
    [
      39.537334,
      56.450116
    ],
    [
      39.533944,
      56.451297
    ],
    [
      39.529827,
      56.452775
    ],
    [
      39.52696519723366,
      56.45368375851058
    ],
    [
      39.526969,
      56.453696
    ],
    [
      39.524962,
      56.454319
    ],
    [
      39.522955,
      56.454943
    ],
    [
      39.520093,
      56.455807
    ],
    [
      39.516624,
      56.456778
    ],
    [
      39.511629,
      56.455925
    ],
    [
      39.507742,
      56.453804
    ],
    [
      39.497752,
      56.460387
    ],
    [
      39.49612,
      56.464577
    ],
    [
      39.493952,
      56.469624
    ],
    [
      39.49271,
      56.474719
    ],
    [
      39.492502,
      56.479094
    ],
    [
      39.492501,
      56.482674
    ],
    [
      39.492781,
      56.487266
    ],
    [
      39.496316,
      56.494708
    ],
    [
      39.503576,
      56.497112
    ],
    [
      39.50444,
      56.49719
    ],
    [
      39.508288,
      56.497296
    ],
    [
      39.508189,
      56.500895
    ],
    [
      39.499053,
      56.50388
    ],
    [
      39.496731,
      56.504534
    ],
    [
      39.485232,
      56.502866
    ],
    [
      39.484619,
      56.503375
    ],
    [
      39.48314,
      56.503123
    ],
    [
      39.482319,
      56.500605
    ],
    [
      39.482764,
      56.499543
    ],
    [
      39.48437,
      56.499162
    ],
    [
      39.48904,
      56.488579
    ],
    [
      39.488926,
      56.484943
    ],
    [
      39.488857,
      56.480801
    ],
    [
      39.489005,
      56.476879
    ],
    [
      39.489493,
      56.473101
    ],
    [
      39.490736,
      56.468006
    ],
    [
      39.49157244191444,
      56.465400516367296
    ],
    [
      39.491524,
      56.465383
    ],
    [
      39.492006,
      56.46405
    ],
    [
      39.494367,
      56.459162
    ],
    [
      39.484424,
      56.452774
    ],
    [
      39.480805,
      56.453879
    ],
    [
      39.477182,
      56.452943
    ],
    [
      39.471174,
      56.452238
    ],
    [
      39.467689,
      56.451607
    ],
    [
      39.463563,
      56.451144
    ],
    [
      39.457935,
      56.450612
    ],
    [
      39.452528,
      56.450326
    ],
    [
      39.449211,
      56.446536
    ],
    [
      39.452745,
      56.446732
    ],
    [
      39.458196,
      56.447022
    ],
    [
      39.463782,
      56.447551
    ],
    [
      39.464084352257856,
      56.44758495256158
    ],
    [
      39.464085,
      56.447578
    ],
    [
      39.467069,
      56.447856
    ],
    [
      39.468288,
      56.448057
    ],
    [
      39.474746,
      56.448992
    ],
    [
      39.478425,
      56.449565
    ],
    [
      39.489015,
      56.445952
    ],
    [
      39.495335,
      56.440229
    ],
    [
      39.499302,
      56.439313
    ],
    [
      39.50234022105481,
      56.43683638550262
    ],
    [
      39.501827,
      56.435746
    ],
    [
      39.50620404370573,
      56.433686779525985
    ],
    [
      39.506205,
      56.433686
    ],
    [
      39.50623895847537,
      56.43367035355144
    ],
    [
      39.50685722726622,
      56.43337948331158
    ],
    [
      39.506857,
      56.433379
    ],
    [
      39.511179,
      56.431346
    ],
    [
      39.51533144499142,
      56.42939224725595
    ],
    [
      39.522895,
      56.422877
    ],
    [
      39.52366367879751,
      56.42224501493593
    ],
    [
      39.523623,
      56.422106
    ],
    [
      39.52375,
      56.421972
    ],
    [
      39.524094754576076,
      56.421890597129156
    ],
    [
      39.52560837778341,
      56.420646140715434
    ],
    [
      39.524759,
      56.409867
    ],
    [
      39.520145,
      56.408311
    ],
    [
      39.515802,
      56.407597
    ],
    [
      39.511899,
      56.40615
    ],
    [
      39.509566,
      56.405102
    ],
    [
      39.508071,
      56.404563
    ],
    [
      39.509291,
      56.401177
    ],
    [
      39.510786,
      56.401715
    ],
    [
      39.516555,
      56.404076
    ],
    [
      39.521133,
      56.404516
    ],
    [
      39.525615562064644,
      56.40634693273428
    ],
    [
      39.52590929037996,
      56.40635076330699
    ],
    [
      39.535636,
      56.400692
    ],
    [
      39.531863,
      56.39217
    ],
    [
      39.531167387385906,
      56.3918332000444
    ],
    [
      39.531167,
      56.391834
    ],
    [
      39.5230155396731,
      56.38788625904294
    ],
    [
      39.519444,
      56.386157
    ],
    [
      39.507028,
      56.380144
    ],
    [
      39.496184,
      56.37479
    ],
    [
      39.48508031392427,
      56.369328558947664
    ],
    [
      39.474575,
      56.370588
    ],
    [
      39.473756,
      56.370391
    ],
    [
      39.473226799768334,
      56.369357799547686
    ],
    [
      39.471432,
      56.37274
    ],
    [
      39.467575,
      56.376046
    ],
    [
      39.460472,
      56.379787
    ],
    [
      39.453385,
      56.38352
    ],
    [
      39.44958,
      56.385572
    ],
    [
      39.443592,
      56.388253
    ],
    [
      39.439002,
      56.389104
    ],
    [
      39.434412,
      56.389954
    ],
    [
      39.43404963296652,
      56.387999096141
    ],
    [
      39.433344,
      56.387971
    ],
    [
      39.42659512991045,
      56.3848657222557
    ],
    [
      39.426477,
      56.384838
    ],
    [
      39.427298,
      56.381333
    ],
    [
      39.427869,
      56.381467
    ],
    [
      39.432683,
      56.382499
    ],
    [
      39.440198,
      56.384346
    ],
    [
      39.447871,
      56.382404
    ],
    [
      39.452921580059275,
      56.37196886765025
    ],
    [
      39.449492,
      56.369168
    ],
    [
      39.448752,
      56.367747
    ],
    [
      39.443901,
      56.361939
    ],
    [
      39.436156,
      56.360927
    ],
    [
      39.432561,
      56.36111
    ],
    [
      39.427607,
      56.353888
    ],
    [
      39.423458,
      56.351635
    ],
    [
      39.416816,
      56.346666
    ],
    [
      39.411254,
      56.345401
    ],
    [
      39.402645,
      56.350439
    ],
    [
      39.39679,
      56.353525
    ],
    [
      39.391886,
      56.356108
    ],
    [
      39.383122,
      56.363559
    ],
    [
      39.38931,
      56.37063
    ],
    [
      39.393123,
      56.373513
    ],
    [
      39.396944,
      56.376402
    ],
    [
      39.400919,
      56.379442
    ],
    [
      39.404886,
      56.382477
    ],
    [
      39.408143,
      56.386763
    ],
    [
      39.415542,
      56.388773
    ],
    [
      39.417023,
      56.389955
    ],
    [
      39.416794,
      56.392148
    ],
    [
      39.416906,
      56.393553
    ],
    [
      39.415128,
      56.393782
    ],
    [
      39.412787,
      56.391047
    ],
    [
      39.402713,
      56.385346
    ],
    [
      39.398732,
      56.382301
    ],
    [
      39.394757,
      56.379262
    ],
    [
      39.390952,
      56.376385
    ],
    [
      39.387139,
      56.373502
    ],
    [
      39.387099881523945,
      56.37348631638877
    ],
    [
      39.387078,
      56.373539
    ],
    [
      39.378234,
      56.372061
    ],
    [
      39.370672,
      56.373614
    ],
    [
      39.368247,
      56.370953
    ],
    [
      39.367772595041046,
      56.36889086394262
    ],
    [
      39.367018,
      56.368802
    ],
    [
      39.368391,
      56.364535
    ],
    [
      39.36849729561585,
      56.364549848588126
    ],
    [
      39.368629,
      56.363861
    ],
    [
      39.369884,
      56.361498
    ],
    [
      39.370402,
      56.360253
    ],
    [
      39.373726,
      56.361635
    ],
    [
      39.382639,
      56.356943
    ],
    [
      39.38712190786203,
      56.354562193295614
    ],
    [
      39.386582,
      56.352423
    ],
    [
      39.387012,
      56.348849
    ],
    [
      39.386661,
      56.340992
    ],
    [
      39.377152,
      56.333809
    ],
    [
      39.37619681798079,
      56.3344247471802
    ],
    [
      39.376068,
      56.335773
    ],
    [
      39.374923,
      56.339186
    ],
    [
      39.370565,
      56.340048
    ],
    [
      39.36787978457264,
      56.34007051752979
    ],
    [
      39.366639,
      56.341022
    ],
    [
      39.364318,
      56.350413
    ],
    [
      39.35711549503869,
      56.35554343706764
    ],
    [
      39.355437,
      56.361093
    ],
    [
      39.352436,
      56.365545
    ],
    [
      39.348097,
      56.371331
    ],
    [
      39.347758,
      56.386929
    ],
    [
      39.354788,
      56.390038
    ],
    [
      39.362259,
      56.392435
    ],
    [
      39.366202,
      56.394534
    ],
    [
      39.364339,
      56.397614
    ],
    [
      39.35644,
      56.402892
    ],
    [
      39.355655,
      56.406702
    ],
    [
      39.355189,
      56.408961
    ],
    [
      39.3541652219955,
      56.40874962439385
    ],
    [
      39.353385,
      56.409822
    ],
    [
      39.357426,
      56.418854
    ],
    [
      39.363429,
      56.426065
    ],
    [
      39.370405,
      56.425305
    ],
    [
      39.374111,
      56.425131
    ],
    [
      39.37422194100136,
      56.42749161444312
    ],
    [
      39.380453,
      56.428336
    ],
    [
      39.382348,
      56.427837
    ],
    [
      39.384162,
      56.427401
    ],
    [
      39.385005,
      56.430901
    ],
    [
      39.379513,
      56.432306
    ],
    [
      39.374436,
      56.432162
    ],
    [
      39.37128229459452,
      56.431114731789876
    ],
    [
      39.370132,
      56.431108
    ],
    [
      39.360074,
      56.432676
    ],
    [
      39.357656,
      56.435343
    ],
    [
      39.35452285182125,
      56.43492041227303
    ],
    [
      39.352139,
      56.439053
    ],
    [
      39.351481,
      56.442166
    ],
    [
      39.35147567813294,
      56.44216487578959
    ],
    [
      39.351324,
      56.442912
    ],
    [
      39.352683,
      56.451478
    ],
    [
      39.351088,
      56.454705
    ],
    [
      39.346125,
      56.455154
    ],
    [
      39.34321557445828,
      56.46053422836078
    ],
    [
      39.346157,
      56.466583
    ],
    [
      39.346152208179,
      56.46664816150528
    ],
    [
      39.347308,
      56.466898
    ],
    [
      39.352923,
      56.46832
    ],
    [
      39.356959,
      56.468975
    ],
    [
      39.36312,
      56.469928
    ],
    [
      39.367073,
      56.470696
    ],
    [
      39.37238199287701,
      56.47150823631245
    ],
    [
      39.372489,
      56.471524
    ],
    [
      39.372488912420636,
      56.47152459420516
    ],
    [
      39.37257,
      56.471537
    ],
    [
      39.382582,
      56.472525
    ],
    [
      39.387691,
      56.472681
    ],
    [
      39.391558,
      56.473589
    ],
    [
      39.402328,
      56.475909
    ],
    [
      39.40698,
      56.476448
    ],
    [
      39.409506,
      56.476769
    ],
    [
      39.411229,
      56.477026
    ],
    [
      39.41122857155209,
      56.47702886705747
    ],
    [
      39.41131,
      56.47704
    ],
    [
      39.417411,
      56.479004
    ],
    [
      39.425773,
      56.483756
    ],
    [
      39.424815,
      56.487227
    ],
    [
      39.424096,
      56.487028
    ],
    [
      39.419163,
      56.484727
    ],
    [
      39.41077885823254,
      56.484466815537395
    ],
    [
      39.406043,
      56.488725
    ],
    [
      39.403326,
      56.486146
    ],
    [
      39.395034,
      56.478575
    ],
    [
      39.391353,
      56.477481
    ],
    [
      39.383136,
      56.476082
    ],
    [
      39.378605,
      56.476303
    ],
    [
      39.37195718075999,
      56.47508499542791
    ],
    [
      39.3669,
      56.47434
    ],
    [
      39.366294,
      56.474211
    ],
    [
      39.362478,
      56.473471
    ],
    [
      39.356408,
      56.472532
    ],
    [
      39.352394,
      56.471881
    ],
    [
      39.346429,
      56.470389
    ],
    [
      39.34523572205347,
      56.470124708447365
    ],
    [
      39.344355,
      56.47006
    ],
    [
      39.342668,
      56.469556
    ],
    [
      39.33951938668099,
      56.46872575553519
    ],
    [
      39.339492,
      56.468811
    ],
    [
      39.33787300170579,
      56.468291160344606
    ],
    [
      39.334165,
      56.467121
    ],
    [
      39.329789,
      56.466079
    ],
    [
      39.319562,
      56.469099
    ],
    [
      39.314892,
      56.470824
    ],
    [
      39.311313,
      56.47266
    ],
    [
      39.306724,
      56.480093
    ],
    [
      39.305344,
      56.483418
    ],
    [
      39.301829,
      56.484047
    ],
    [
      39.30077245255025,
      56.48387388215645
    ],
    [
      39.295726,
      56.488836
    ],
    [
      39.298856,
      56.499119
    ],
    [
      39.303484,
      56.500789
    ],
    [
      39.305934,
      56.50176
    ],
    [
      39.306315,
      56.501957
    ],
    [
      39.304662,
      56.505155
    ],
    [
      39.30217,
      56.50414
    ],
    [
      39.297774,
      56.502552
    ],
    [
      39.294365,
      56.501742
    ],
    [
      39.291976,
      56.498066
    ],
    [
      39.290982,
      56.492095
    ],
    [
      39.291823,
      56.488205
    ],
    [
      39.294462,
      56.484973
    ],
    [
      39.29782,
      56.481281
    ],
    [
      39.303681,
      56.474878
    ],
    [
      39.306139,
      56.47192
    ],
    [
      39.303573,
      56.461789
    ],
    [
      39.298321,
      56.461624
    ],
    [
      39.293855,
      56.461294
    ],
    [
      39.289603,
      56.460276
    ],
    [
      39.286022,
      56.459666
    ],
    [
      39.281733,
      56.458968
    ],
    [
      39.277699,
      56.458074
    ],
    [
      39.272522,
      56.456938
    ],
    [
      39.267882,
      56.455707
    ],
    [
      39.263829,
      56.454314
    ],
    [
      39.259459,
      56.452773
    ],
    [
      39.255012,
      56.451141
    ],
    [
      39.247269,
      56.448886
    ],
    [
      39.242764,
      56.448586
    ],
    [
      39.24267300036972,
      56.44858696831009
    ],
    [
      39.242673,
      56.448587
    ],
    [
      39.242671616073714,
      56.44858698304012
    ],
    [
      39.238535,
      56.448631
    ],
    [
      39.234033,
      56.448475
    ],
    [
      39.229869,
      56.447219
    ],
    [
      39.22893699257153,
      56.446954201153765
    ],
    [
      39.226703,
      56.447913
    ],
    [
      39.225472,
      56.448097
    ],
    [
      39.221508382518294,
      56.44532972253609
    ],
    [
      39.213667,
      56.445288
    ],
    [
      39.20792,
      56.439778
    ],
    [
      39.204878,
      56.437664
    ],
    [
      39.20552,
      56.433751
    ],
    [
      39.201764,
      56.422889
    ],
    [
      39.195674,
      56.419278
    ],
    [
      39.18593802606538,
      56.421716496510975
    ],
    [
      39.18438,
      56.423548
    ],
    [
      39.184046,
      56.425477
    ],
    [
      39.18394212020743,
      56.425919423289194
    ],
    [
      39.183483,
      56.43772
    ],
    [
      39.185395,
      56.44077
    ],
    [
      39.17923,
      56.444316
    ],
    [
      39.173952,
      56.44939
    ],
    [
      39.17074034344354,
      56.4506505477952
    ],
    [
      39.17075,
      56.450679
    ],
    [
      39.170143,
      56.450885
    ],
    [
      39.162034,
      56.454661
    ],
    [
      39.158636812848926,
      56.45593554277297
    ],
    [
      39.15736944700914,
      56.467460253297574
    ],
    [
      39.15876,
      56.469775
    ],
    [
      39.167618,
      56.471571
    ],
    [
      39.175204,
      56.472674
    ],
    [
      39.181136,
      56.472812
    ],
    [
      39.184926,
      56.473933
    ],
    [
      39.189748,
      56.475052
    ],
    [
      39.199651,
      56.472918
    ],
    [
      39.205105,
      56.47256
    ],
    [
      39.211725,
      56.470753
    ],
    [
      39.215824,
      56.470181
    ],
    [
      39.218661,
      56.470039
    ],
    [
      39.21866644521274,
      56.470091843649946
    ],
    [
      39.22067,
      56.470029
    ],
    [
      39.224469,
      56.469948
    ],
    [
      39.229247,
      56.469191
    ],
    [
      39.230563,
      56.468796
    ],
    [
      39.231597,
      56.472244
    ],
    [
      39.23028,
      56.472639
    ],
    [
      39.228493,
      56.473076
    ],
    [
      39.224019,
      56.47352
    ],
    [
      39.220365,
      56.473616
    ],
    [
      39.21759079428448,
      56.47376809768576
    ],
    [
      39.217514,
      56.473776
    ],
    [
      39.216407,
      56.473833
    ],
    [
      39.212317,
      56.474304
    ],
    [
      39.208516,
      56.473477
    ],
    [
      39.19942,
      56.477055
    ],
    [
      39.194778,
      56.478655
    ],
    [
      39.192224,
      56.489732
    ],
    [
      39.199676,
      56.49844
    ],
    [
      39.199543,
      56.502037
    ],
    [
      39.192867,
      56.51031
    ],
    [
      39.190334,
      56.514131
    ],
    [
      39.190234689021196,
      56.51417794569432
    ],
    [
      39.19021,
      56.514333
    ],
    [
      39.202153,
      56.522396
    ],
    [
      39.212716,
      56.530191
    ],
    [
      39.219152,
      56.529571
    ],
    [
      39.22460471879476,
      56.522287725609864
    ],
    [
      39.224881,
      56.5184
    ],
    [
      39.229063,
      56.516431
    ],
    [
      39.233743,
      56.514975
    ],
    [
      39.235206,
      56.515291
    ],
    [
      39.238078,
      56.516173
    ],
    [
      39.23812099371784,
      56.51618618853253
    ],
    [
      39.238142,
      56.516192
    ],
    [
      39.238142808463635,
      56.51619288031214
    ],
    [
      39.24095,
      56.517054
    ],
    [
      39.24044508361634,
      56.518699759652016
    ],
    [
      39.24055,
      56.518814
    ],
    [
      39.24039907377368,
      56.51884972733995
    ],
    [
      39.239894,
      56.520496
    ],
    [
      39.23708663513958,
      56.51963384964941
    ],
    [
      39.237086,
      56.519634
    ],
    [
      39.229174827930976,
      56.52422834802719
    ],
    [
      39.232864,
      56.530792
    ],
    [
      39.232482864864046,
      56.53410796494162
    ],
    [
      39.236385,
      56.536248
    ],
    [
      39.241943,
      56.53561
    ],
    [
      39.247711,
      56.537437
    ],
    [
      39.253713,
      56.53636
    ],
    [
      39.257948,
      56.535374
    ],
    [
      39.262198,
      56.534141
    ],
    [
      39.265888,
      56.534669
    ],
    [
      39.275042,
      56.529988
    ],
    [
      39.280119,
      56.530707
    ],
    [
      39.283881,
      56.533291
    ],
    [
      39.288438,
      56.535259
    ],
    [
      39.296958,
      56.541607
    ],
    [
      39.303625,
      56.541352
    ],
    [
      39.308041,
      56.541402
    ],
    [
      39.318874,
      56.543182
    ],
    [
      39.325621,
      56.542495
    ],
    [
      39.329619,
      56.546026
    ],
    [
      39.3301692796296,
      56.548035904999296
    ],
    [
      39.332879,
      56.551337
    ],
    [
      39.33566,
      56.554392
    ],
    [
      39.339157,
      56.557836
    ],
    [
      39.341759,
      56.560371
    ],
    [
      39.344362,
      56.562905
    ],
    [
      39.341851,
      56.565484
    ],
    [
      39.339248,
      56.56295
    ],
    [
      39.336645,
      56.560415
    ],
    [
      39.333134,
      56.556957
    ],
    [
      39.330035,
      56.553544
    ],
    [
      39.32860283799077,
      56.55113205158849
    ],
    [
      39.328281,
      56.55136
    ],
    [
      39.327514,
      56.551441
    ],
    [
      39.317271,
      56.547183
    ],
    [
      39.313663,
      56.54724
    ],
    [
      39.310169,
      56.546909
    ],
    [
      39.299273,
      56.545016
    ],
    [
      39.294025068113015,
      56.54486201934489
    ],
    [
      39.294025,
      56.544868
    ],
    [
      39.29238,
      56.544849
    ],
    [
      39.289739,
      56.544154
    ],
    [
      39.284585,
      56.537821
    ],
    [
      39.274485,
      56.537284
    ],
    [
      39.270416,
      56.538368
    ],
    [
      39.265843,
      56.538268
    ],
    [
      39.258014,
      56.539382
    ],
    [
      39.249892,
      56.541519
    ],
    [
      39.245969,
      56.540587
    ],
    [
      39.235521,
      56.540138
    ],
    [
      39.23067603719086,
      56.539764156572936
    ],
    [
      39.230475,
      56.540326
    ],
    [
      39.22705448117283,
      56.54160802281651
    ],
    [
      39.225855,
      56.548004
    ],
    [
      39.226983,
      56.556729
    ],
    [
      39.228131,
      56.559523
    ],
    [
      39.228100779005295,
      56.55953541138419
    ],
    [
      39.228392,
      56.560194
    ],
    [
      39.229179,
      56.563752
    ],
    [
      39.235896,
      56.569818
    ],
    [
      39.241508,
      56.564584
    ],
    [
      39.243436,
      56.562485
    ],
    [
      39.246432,
      56.564482
    ],
    [
      39.245558,
      56.565793
    ],
    [
      39.24295060446587,
      56.57394016413992
    ],
    [
      39.246169,
      56.573907
    ],
    [
      39.252457,
      56.574432
    ],
    [
      39.256448,
      56.573732
    ],
    [
      39.264214,
      56.571236
    ],
    [
      39.267974,
      56.5697
    ],
    [
      39.272259,
      56.568386
    ],
    [
      39.275614,
      56.56665
    ],
    [
      39.278858,
      56.564839
    ],
    [
      39.279716,
      56.564377
    ],
    [
      39.280377,
      56.564039
    ],
    [
      39.281817,
      56.564503
    ],
    [
      39.28183606642362,
      56.564764210003716
    ],
    [
      39.283039,
      56.564734
    ],
    [
      39.286397,
      56.565935
    ],
    [
      39.295876,
      56.572501
    ],
    [
      39.296693,
      56.572589
    ],
    [
      39.296308,
      56.576169
    ],
    [
      39.292839,
      56.583455
    ],
    [
      39.288777,
      56.584398
    ],
    [
      39.284513,
      56.585732
    ],
    [
      39.279394,
      56.586419
    ],
    [
      39.275355,
      56.587848
    ],
    [
      39.270341,
      56.588771
    ],
    [
      39.266639,
      56.589659
    ],
    [
      39.268574,
      56.601313
    ],
    [
      39.276466,
      56.603958
    ],
    [
      39.280522,
      56.603382
    ],
    [
      39.288329,
      56.602878
    ],
    [
      39.293846,
      56.603055
    ],
    [
      39.296919,
      56.603276
    ],
    [
      39.296662,
      56.606866
    ],
    [
      39.293588,
      56.606646
    ],
    [
      39.288214,
      56.606476
    ],
    [
      39.279424,
      56.607348
    ],
    [
      39.274813,
      56.607617
    ],
    [
      39.264099,
      56.608579
    ],
    [
      39.252879,
      56.609343
    ],
    [
      39.246125,
      56.611863
    ],
    [
      39.245077,
      56.61211
    ],
    [
      39.244251,
      56.608606
    ],
    [
      39.245506,
      56.607842
    ],
    [
      39.249564,
      56.606905
    ],
    [
      39.256382,
      56.608275
    ],
    [
      39.260461,
      56.599615
    ],
    [
      39.255488,
      56.594775
    ],
    [
      39.249248,
      56.590376
    ],
    [
      39.245363248193,
      56.58775763196417
    ],
    [
      39.245363,
      56.587758
    ],
    [
      39.235559,
      56.583424
    ],
    [
      39.226591,
      56.575832
    ],
    [
      39.22267,
      56.575095
    ],
    [
      39.225251,
      56.568592
    ],
    [
      39.225249,
      56.564995
    ],
    [
      39.224923,
      56.561155
    ],
    [
      39.22170082008935,
      56.55925587628056
    ],
    [
      39.217152219646835,
      56.55685603997282
    ],
    [
      39.212307,
      56.56403
    ],
    [
      39.211847,
      56.575107
    ],
    [
      39.213133,
      56.578469
    ],
    [
      39.210964,
      56.582765
    ],
    [
      39.207379,
      56.583093
    ],
    [
      39.20122083632124,
      56.58199557649985
    ],
    [
      39.195038,
      56.582572
    ],
    [
      39.19123,
      56.58342
    ],
    [
      39.184051,
      56.585097
    ],
    [
      39.176853,
      56.586778
    ],
    [
      39.17670755983546,
      56.58681737279597
    ],
    [
      39.176708,
      56.586819
    ],
    [
      39.173618,
      56.587654
    ],
    [
      39.170529,
      56.58849
    ],
    [
      39.167096,
      56.589443
    ],
    [
      39.163651,
      56.590399
    ],
    [
      39.159798,
      56.591237
    ],
    [
      39.15492,
      56.592618
    ],
    [
      39.147466,
      56.599492
    ],
    [
      39.145094,
      56.60365
    ],
    [
      39.142186,
      56.605508
    ],
    [
      39.14212302191345,
      56.60554821674922
    ],
    [
      39.14325073023895,
      56.61670254945197
    ],
    [
      39.146131,
      56.617627
    ],
    [
      39.153406,
      56.620485
    ],
    [
      39.157877,
      56.622454
    ],
    [
      39.163599,
      56.624673
    ],
    [
      39.171342,
      56.624817
    ],
    [
      39.17499,
      56.623678
    ],
    [
      39.183525,
      56.618628
    ],
    [
      39.191335,
      56.616562
    ],
    [
      39.200209,
      56.611207
    ],
    [
      39.203963,
      56.610179
    ],
    [
      39.207848,
      56.610031
    ],
    [
      39.212978,
      56.609172
    ],
    [
      39.214139,
      56.61258
    ],
    [
      39.212884,
      56.613007
    ],
    [
      39.207925,
      56.61363
    ],
    [
      39.202193,
      56.614211
    ],
    [
      39.20041435182972,
      56.61558071230278
    ],
    [
      39.199659,
      56.616228
    ],
    [
      39.198283,
      56.617408
    ],
    [
      39.195808,
      56.619128
    ],
    [
      39.191686,
      56.620145
    ],
    [
      39.193584,
      56.632096
    ],
    [
      39.197755,
      56.633223
    ],
    [
      39.20132,
      56.634911
    ],
    [
      39.211614,
      56.638111
    ],
    [
      39.214877,
      56.638462
    ],
    [
      39.214926,
      56.639404
    ],
    [
      39.214491,
      56.642041
    ],
    [
      39.213973,
      56.642876
    ],
    [
      39.210101,
      56.64199
    ],
    [
      39.204566,
      56.641016
    ],
    [
      39.201022,
      56.639745
    ],
    [
      39.192646,
      56.635572
    ],
    [
      39.186965,
      56.632471
    ],
    [
      39.183121,
      56.630702
    ],
    [
      39.174089,
      56.636368
    ],
    [
      39.173538,
      56.639925
    ],
    [
      39.166385,
      56.640166
    ],
    [
      39.157258,
      56.645846
    ],
    [
      39.15598,
      56.649212
    ],
    [
      39.153261,
      56.64818
    ],
    [
      39.150137,
      56.646841
    ],
    [
      39.146875,
      56.644975
    ],
    [
      39.138757,
      56.637994
    ],
    [
      39.134954,
      56.638427
    ],
    [
      39.134079,
      56.638398
    ],
    [
      39.133229,
      56.638302
    ],
    [
      39.13322941632224,
      56.638298350037665
    ],
    [
      39.13322,
      56.6383
    ],
    [
      39.128503,
      56.637676
    ],
    [
      39.122417,
      56.636801
    ],
    [
      39.112456,
      56.636721
    ],
    [
      39.109147,
      56.63797
    ],
    [
      39.105217,
      56.639759
    ],
    [
      39.100003,
      56.639103
    ],
    [
      39.094978,
      56.646323
    ],
    [
      39.091854,
      56.657444
    ],
    [
      39.095478,
      56.659101
    ],
    [
      39.098391,
      56.661538
    ],
    [
      39.107692,
      56.667186
    ],
    [
      39.112557,
      56.66789
    ],
    [
      39.116072,
      56.668443
    ],
    [
      39.124514,
      56.6678
    ],
    [
      39.131412,
      56.667751
    ],
    [
      39.137061,
      56.663615
    ],
    [
      39.141799,
      56.665768
    ],
    [
      39.150799,
      56.66026
    ],
    [
      39.158657,
      56.657632
    ],
    [
      39.162154,
      56.657063
    ],
    [
      39.166143,
      56.656447
    ],
    [
      39.169068,
      56.660758
    ],
    [
      39.176958,
      56.6683
    ],
    [
      39.180556,
      56.668653
    ],
    [
      39.184955,
      56.669024
    ],
    [
      39.18856,
      56.66926
    ],
    [
      39.192521,
      56.669542
    ],
    [
      39.197612,
      56.668784
    ],
    [
      39.201677,
      56.670412
    ],
    [
      39.196255,
      56.672684
    ],
    [
      39.192318,
      56.673185
    ],
    [
      39.188291,
      56.67285
    ],
    [
      39.184764,
      56.672619
    ],
    [
      39.180011,
      56.672211
    ],
    [
      39.176335,
      56.671845
    ],
    [
      39.168719,
      56.6699
    ],
    [
      39.164259,
      56.669619
    ],
    [
      39.160258,
      56.6694
    ],
    [
      39.156156,
      56.669155
    ],
    [
      39.147929,
      56.671671
    ],
    [
      39.14095,
      56.681173
    ],
    [
      39.140717,
      56.690655
    ],
    [
      39.140804060307836,
      56.69252595626803
    ],
    [
      39.142702,
      56.692575
    ],
    [
      39.142698,
      56.692746
    ],
    [
      39.140976,
      56.696221
    ],
    [
      39.13975,
      56.704953
    ],
    [
      39.148213,
      56.707346
    ],
    [
      39.152096,
      56.708212
    ],
    [
      39.156269,
      56.710335
    ],
    [
      39.164875,
      56.713229
    ],
    [
      39.169059,
      56.712081
    ],
    [
      39.173126,
      56.711213
    ],
    [
      39.176861,
      56.71115
    ],
    [
      39.181934,
      56.711512
    ],
    [
      39.186314,
      56.713746
    ],
    [
      39.194838,
      56.706742
    ],
    [
      39.192978,
      56.703578
    ],
    [
      39.193262,
      56.699864
    ],
    [
      39.197544,
      56.695509
    ],
    [
      39.203425,
      56.68725
    ],
    [
      39.204217,
      56.682936
    ],
    [
      39.205636,
      56.682992
    ],
    [
      39.207589,
      56.685705
    ],
    [
      39.208078,
      56.693363
    ],
    [
      39.216569,
      56.696404
    ],
    [
      39.221369,
      56.687744
    ],
    [
      39.221089,
      56.684051
    ],
    [
      39.221204,
      56.681272
    ],
    [
      39.221234,
      56.680958
    ],
    [
      39.224817,
      56.681309
    ],
    [
      39.233899,
      56.676849
    ],
    [
      39.238966,
      56.676005
    ],
    [
      39.24033,
      56.679337
    ],
    [
      39.24754,
      56.686958
    ],
    [
      39.253736,
      56.686325
    ],
    [
      39.254916,
      56.686409
    ],
    [
      39.25466,
      56.69
    ],
    [
      39.25348,
      56.689916
    ],
    [
      39.246583,
      56.690429
    ],
    [
      39.239403,
      56.692145
    ],
    [
      39.232859,
      56.693439
    ],
    [
      39.228252,
      56.697508
    ],
    [
      39.231656,
      56.708396
    ],
    [
      39.235963,
      56.70886
    ],
    [
      39.242776,
      56.709725
    ],
    [
      39.246494,
      56.710183
    ],
    [
      39.24988,
      56.711303
    ],
    [
      39.255501,
      56.711052
    ],
    [
      39.259207,
      56.709656
    ],
    [
      39.264302,
      56.709626
    ],
    [
      39.275832,
      56.711554
    ],
    [
      39.27942,
      56.711254
    ],
    [
      39.281687,
      56.711649
    ],
    [
      39.282234,
      56.711738
    ],
    [
      39.281655,
      56.715292
    ],
    [
      39.278727,
      56.719428
    ],
    [
      39.278024,
      56.719648
    ],
    [
      39.277643,
      56.719726
    ],
    [
      39.273952,
      56.718735
    ],
    [
      39.267271,
      56.718474
    ],
    [
      39.265281,
      56.731275
    ],
    [
      39.266186,
      56.732726
    ],
    [
      39.26629,
      56.733217
    ],
    [
      39.26537,
      56.734874
    ],
    [
      39.264469,
      56.734896
    ],
    [
      39.262767,
      56.733958
    ],
    [
      39.262664,
      56.733467
    ],
    [
      39.264381,
      56.731297
    ],
    [
      39.264303,
      56.719091
    ],
    [
      39.262119,
      56.718479
    ],
    [
      39.256456,
      56.716617
    ],
    [
      39.251109,
      56.725741
    ],
    [
      39.250362,
      56.729263
    ],
    [
      39.241686,
      56.722465
    ],
    [
      39.237266,
      56.726488
    ],
    [
      39.232677,
      56.735574
    ],
    [
      39.231192,
      56.742477
    ],
    [
      39.227997,
      56.744136
    ],
    [
      39.224509,
      56.743087
    ],
    [
      39.224333,
      56.742736
    ],
    [
      39.228151,
      56.735503
    ],
    [
      39.226333,
      56.724483
    ],
    [
      39.223245,
      56.722184
    ],
    [
      39.220544,
      56.719013
    ],
    [
      39.209253,
      56.721481
    ],
    [
      39.209503,
      56.725018
    ],
    [
      39.206183,
      56.727569
    ],
    [
      39.205767,
      56.722972
    ],
    [
      39.20293910001327,
      56.71722950665807
    ],
    [
      39.201229,
      56.718337
    ],
    [
      39.200979,
      56.71823
    ],
    [
      39.195934,
      56.714275
    ],
    [
      39.192331,
      56.714118
    ],
    [
      39.190777,
      56.726487
    ],
    [
      39.1891501441978,
      56.72742903438327
    ],
    [
      39.189069,
      56.729224
    ],
    [
      39.182847,
      56.736352
    ],
    [
      39.179489,
      56.737649
    ],
    [
      39.178569,
      56.735267
    ],
    [
      39.176415,
      56.729857
    ],
    [
      39.17125,
      56.722463
    ],
    [
      39.16756,
      56.719733
    ],
    [
      39.160124,
      56.71781
    ],
    [
      39.153857,
      56.718711
    ],
    [
      39.14856,
      56.720715
    ],
    [
      39.144078,
      56.723692
    ],
    [
      39.137352,
      56.724977
    ],
    [
      39.131368,
      56.726031
    ],
    [
      39.126281,
      56.726765
    ],
    [
      39.120973,
      56.726737
    ],
    [
      39.11898036355572,
      56.726372641787734
    ],
    [
      39.118979,
      56.72638
    ],
    [
      39.115337,
      56.725705
    ],
    [
      39.111696,
      56.725029
    ],
    [
      39.107857,
      56.724968
    ],
    [
      39.103021,
      56.725279
    ],
    [
      39.102887,
      56.736919
    ],
    [
      39.107449,
      56.739822
    ],
    [
      39.114203,
      56.744085
    ],
    [
      39.117229,
      56.745963
    ],
    [
      39.119115,
      56.748949
    ],
    [
      39.116911,
      56.752863
    ],
    [
      39.112294,
      56.747137
    ],
    [
      39.105516,
      56.742859
    ],
    [
      39.100954,
      56.739956
    ],
    [
      39.097555,
      56.738028
    ],
    [
      39.09570993024621,
      56.737024395356265
    ],
    [
      39.095597,
      56.736963
    ],
    [
      39.093777,
      56.735973
    ],
    [
      39.09087,
      56.731811
    ],
    [
      39.079553,
      56.729459
    ],
    [
      39.075966,
      56.729962
    ],
    [
      39.071984,
      56.730962
    ],
    [
      39.067571,
      56.732391
    ],
    [
      39.062562,
      56.734528
    ],
    [
      39.056971,
      56.736445
    ],
    [
      39.053214,
      56.737513
    ],
    [
      39.05281001475764,
      56.737614257462916
    ],
    [
      39.052813,
      56.737625
    ],
    [
      39.050103,
      56.738378
    ],
    [
      39.042657,
      56.746346
    ],
    [
      39.04137772635994,
      56.74701856225584
    ],
    [
      39.041779,
      56.749633
    ],
    [
      39.045709,
      56.75354
    ],
    [
      39.048211,
      56.756929
    ],
    [
      39.053815,
      56.762822
    ],
    [
      39.06227,
      56.768139
    ],
    [
      39.068085,
      56.768797
    ],
    [
      39.073913,
      56.770868
    ],
    [
      39.077055,
      56.772572
    ],
    [
      39.080989,
      56.774711
    ],
    [
      39.089249,
      56.776625
    ],
    [
      39.093551,
      56.775223
    ],
    [
      39.097309,
      56.775137
    ],
    [
      39.108625,
      56.77509
    ],
    [
      39.115544,
      56.772548
    ],
    [
      39.119751,
      56.77141
    ],
    [
      39.123577,
      56.77034
    ],
    [
      39.128794,
      56.769743
    ],
    [
      39.135752,
      56.76312
    ],
    [
      39.14359,
      56.763507
    ],
    [
      39.148293,
      56.761984
    ],
    [
      39.155666,
      56.762591
    ],
    [
      39.15566679939335,
      56.76259127727793
    ],
    [
      39.162962,
      56.763192
    ],
    [
      39.16280723427051,
      56.76506801161289
    ],
    [
      39.16296,
      56.765121
    ],
    [
      39.17054,
      56.765966
    ],
    [
      39.178471,
      56.766631
    ],
    [
      39.182685,
      56.766105
    ],
    [
      39.187295,
      56.765694
    ],
    [
      39.191086,
      56.765419
    ],
    [
      39.194796,
      56.765292
    ],
    [
      39.200028,
      56.76437
    ],
    [
      39.204476,
      56.763517
    ],
    [
      39.209128,
      56.763932
    ],
    [
      39.211929,
      56.766288
    ],
    [
      39.213216,
      56.772601
    ],
    [
      39.213353,
      56.775778
    ],
    [
      39.213611,
      56.77814
    ],
    [
      39.213869,
      56.780501
    ],
    [
      39.210291,
      56.780893
    ],
    [
      39.210032,
      56.778531
    ],
    [
      39.209774,
      56.77617
    ],
    [
      39.204302,
      56.767113
    ],
    [
      39.200787,
      56.767889
    ],
    [
      39.196465,
      56.768702
    ],
    [
      39.192071,
      56.769076
    ],
    [
      39.18761,
      56.76928
    ],
    [
      39.182461,
      56.769815
    ],
    [
      39.177818,
      56.770276
    ],
    [
      39.172602,
      56.77228
    ],
    [
      39.172418,
      56.782123
    ],
    [
      39.178437,
      56.785513
    ],
    [
      39.183458,
      56.787755
    ],
    [
      39.186734,
      56.789238
    ],
    [
      39.19036,
      56.790645
    ],
    [
      39.193692,
      56.792463
    ],
    [
      39.198167,
      56.797899
    ],
    [
      39.20628,
      56.800471
    ],
    [
      39.208099,
      56.805615
    ],
    [
      39.206289,
      56.808392
    ],
    [
      39.202626,
      56.816592
    ],
    [
      39.201975,
      56.820132
    ],
    [
      39.199318,
      56.819644
    ],
    [
      39.195976,
      56.819097
    ],
    [
      39.185345,
      56.819202
    ],
    [
      39.177479,
      56.817733
    ],
    [
      39.172712,
      56.817426
    ],
    [
      39.167084,
      56.812821
    ],
    [
      39.159184,
      56.819438
    ],
    [
      39.159152,
      56.815838
    ],
    [
      39.167597,
      56.809257
    ],
    [
      39.170335,
      56.809652
    ],
    [
      39.173797,
      56.810606
    ],
    [
      39.17921,
      56.810848
    ],
    [
      39.182799,
      56.811687
    ],
    [
      39.187141,
      56.811585
    ],
    [
      39.192366,
      56.811884
    ],
    [
      39.194828,
      56.811667
    ],
    [
      39.201232,
      56.804856
    ],
    [
      39.194247,
      56.797116
    ],
    [
      39.187447,
      56.793365
    ],
    [
      39.183732,
      56.791878
    ],
    [
      39.179984,
      56.790318
    ],
    [
      39.169113,
      56.792153
    ],
    [
      39.169227,
      56.788571
    ],
    [
      39.163823,
      56.782356
    ],
    [
      39.158059,
      56.784167
    ],
    [
      39.15590861917874,
      56.78403365760846
    ],
    [
      39.15591,
      56.784189
    ],
    [
      39.145412,
      56.788324
    ],
    [
      39.138367,
      56.785
    ],
    [
      39.130585,
      56.785706
    ],
    [
      39.124188,
      56.788714
    ],
    [
      39.117515,
      56.787649
    ],
    [
      39.110152,
      56.786664
    ],
    [
      39.10505,
      56.787868
    ],
    [
      39.104241,
      56.799373
    ],
    [
      39.107228,
      56.801706
    ],
    [
      39.10996,
      56.804172
    ],
    [
      39.116761,
      56.80754
    ],
    [
      39.125768,
      56.805788
    ],
    [
      39.129415,
      56.804291
    ],
    [
      39.129848,
      56.804926
    ],
    [
      39.130748,
      56.806537
    ],
    [
      39.12683235810744,
      56.815948229603386
    ],
    [
      39.13327,
      56.81607
    ],
    [
      39.14381,
      56.815976
    ],
    [
      39.143842,
      56.819575
    ],
    [
      39.122762,
      56.819765
    ],
    [
      39.116049,
      56.823525
    ],
    [
      39.11789,
      56.834772
    ],
    [
      39.120628,
      56.836973
    ],
    [
      39.126571,
      56.838982
    ],
    [
      39.129949,
      56.841672
    ],
    [
      39.129337,
      56.850238
    ],
    [
      39.127655,
      56.859066
    ],
    [
      39.136009,
      56.860715
    ],
    [
      39.137401,
      56.860004
    ],
    [
      39.138626,
      56.859471
    ],
    [
      39.140062,
      56.862772
    ],
    [
      39.135025,
      56.870192
    ],
    [
      39.132069,
      56.870346
    ],
    [
      39.127748,
      56.867209
    ],
    [
      39.126145,
      56.863776
    ],
    [
      39.115166,
      56.860955
    ],
    [
      39.107507,
      56.865027
    ],
    [
      39.103163,
      56.870646
    ],
    [
      39.099412,
      56.873011
    ],
    [
      39.104306,
      56.882935
    ],
    [
      39.111529,
      56.881554
    ],
    [
      39.115715,
      56.880678
    ],
    [
      39.116355,
      56.884221
    ],
    [
      39.112306,
      56.885069
    ],
    [
      39.106321,
      56.894482
    ],
    [
      39.112475,
      56.899175
    ],
    [
      39.11531,
      56.901359
    ],
    [
      39.117113,
      56.902725
    ],
    [
      39.119708,
      56.904671
    ],
    [
      39.122304,
      56.906617
    ],
    [
      39.120144,
      56.909498
    ],
    [
      39.117549,
      56.907552
    ],
    [
      39.114953,
      56.905606
    ],
    [
      39.113136,
      56.904228
    ],
    [
      39.110241,
      56.901998
    ],
    [
      39.104143,
      56.897348
    ],
    [
      39.100674,
      56.892476
    ],
    [
      39.0907,
      56.893548
    ],
    [
      39.088291,
      56.898198
    ],
    [
      39.085784,
      56.901784
    ],
    [
      39.084008,
      56.905063
    ],
    [
      39.080663,
      56.908406
    ],
    [
      39.077568,
      56.906568
    ],
    [
      39.077988,
      56.90586
    ],
    [
      39.079079,
      56.904777
    ],
    [
      39.081792,
      56.901097
    ],
    [
      39.08538,
      56.894543
    ],
    [
      39.088139,
      56.891018
    ],
    [
      39.082511,
      56.88089
    ],
    [
      39.078291,
      56.877648
    ],
    [
      39.07330055198574,
      56.87378439611544
    ],
    [
      39.07313510527555,
      56.87365646261596
    ],
    [
      39.069962100481696,
      56.871750711481056
    ],
    [
      39.059491,
      56.875537
    ],
    [
      39.058719,
      56.877538
    ],
    [
      39.058251,
      56.878486
    ],
    [
      39.058208115755555,
      56.8784648368645
    ],
    [
      39.058056,
      56.878812
    ],
    [
      39.053351,
      56.883782
    ],
    [
      39.054212,
      56.890706
    ],
    [
      39.050806,
      56.893996
    ],
    [
      39.041917,
      56.893963
    ],
    [
      39.038603,
      56.896381
    ],
    [
      39.035918,
      56.90098
    ],
    [
      39.031498,
      56.903195
    ],
    [
      39.024411,
      56.909324
    ],
    [
      39.018504,
      56.919263
    ],
    [
      39.018896,
      56.922842
    ],
    [
      39.01563,
      56.926123
    ],
    [
      39.015039,
      56.926286
    ],
    [
      39.013813,
      56.926466
    ],
    [
      39.003867,
      56.920828
    ],
    [
      38.996278,
      56.918011
    ],
    [
      38.990689,
      56.915938
    ],
    [
      38.985602,
      56.914579
    ],
    [
      38.975126,
      56.91333
    ],
    [
      38.970722,
      56.914146
    ],
    [
      38.966388,
      56.913953
    ],
    [
      38.954695,
      56.915128
    ],
    [
      38.95133323869385,
      56.91522272050316
    ],
    [
      38.95133,
      56.915284
    ],
    [
      38.942099,
      56.916812
    ],
    [
      38.938376,
      56.918417
    ],
    [
      38.932403,
      56.91958
    ],
    [
      38.926561,
      56.920318
    ],
    [
      38.925424,
      56.912599
    ],
    [
      38.926263,
      56.907876
    ],
    [
      38.926957,
      56.902414
    ],
    [
      38.927531,
      56.896454
    ],
    [
      38.928458,
      56.891191
    ],
    [
      38.929139,
      56.885271
    ],
    [
      38.930024,
      56.879033
    ],
    [
      38.930985,
      56.875579
    ],
    [
      38.93161,
      56.872118
    ],
    [
      38.932055,
      56.868001
    ],
    [
      38.932882,
      56.862731
    ],
    [
      38.933959,
      56.857647
    ],
    [
      38.930251,
      56.84889
    ],
    [
      38.918471,
      56.846995
    ],
    [
      38.918427,
      56.856101
    ],
    [
      38.919556,
      56.859519
    ],
    [
      38.917489,
      56.860351
    ],
    [
      38.91377,
      56.862325
    ],
    [
      38.909478,
      56.864006
    ],
    [
      38.901193,
      56.866439
    ],
    [
      38.895566,
      56.868911
    ],
    [
      38.886192723941896,
      56.874307762306174
    ],
    [
      38.882658,
      56.878304
    ],
    [
      38.879395,
      56.882449
    ],
    [
      38.871047,
      56.887741
    ],
    [
      38.868312,
      56.890083
    ],
    [
      38.863673,
      56.89084
    ],
    [
      38.856358,
      56.894449
    ],
    [
      38.860066,
      56.902553
    ],
    [
      38.869396,
      56.905409
    ],
    [
      38.873239,
      56.90587
    ],
    [
      38.876292,
      56.905844
    ],
    [
      38.876943,
      56.906127
    ],
    [
      38.875507,
      56.909428
    ],
    [
      38.867304,
      56.911371
    ],
    [
      38.863482,
      56.912873
    ],
    [
      38.863423,
      56.912779
    ],
    [
      38.856278,
      56.904059
    ],
    [
      38.852729,
      56.901928
    ],
    [
      38.849499,
      56.900368
    ],
    [
      38.838894,
      56.901335
    ],
    [
      38.835648,
      56.90315
    ],
    [
      38.831147,
      56.904497
    ],
    [
      38.823105,
      56.908126
    ],
    [
      38.825296,
      56.918959
    ],
    [
      38.827896,
      56.921897
    ],
    [
      38.830683,
      56.927486
    ],
    [
      38.828179,
      56.93769
    ],
    [
      38.827804,
      56.941224
    ],
    [
      38.823892,
      56.948645
    ],
    [
      38.820418,
      56.949669
    ],
    [
      38.817247,
      56.947964
    ],
    [
      38.817565,
      56.945289
    ],
    [
      38.817739,
      56.944876
    ],
    [
      38.824298,
      56.938235
    ],
    [
      38.825103,
      56.933921
    ],
    [
      38.824155,
      56.927138
    ],
    [
      38.809697,
      56.930953
    ],
    [
      38.79429,
      56.927985
    ],
    [
      38.794971,
      56.92445
    ],
    [
      38.810378,
      56.927418
    ],
    [
      38.816327,
      56.917887
    ],
    [
      38.815126,
      56.914557
    ],
    [
      38.81475,
      56.910999
    ],
    [
      38.81752,
      56.907626
    ],
    [
      38.820799,
      56.904918
    ],
    [
      38.824129,
      56.902344
    ],
    [
      38.828013,
      56.901473
    ],
    [
      38.8334,
      56.90016
    ],
    [
      38.837085,
      56.898223
    ],
    [
      38.843142,
      56.894813
    ],
    [
      38.843335,
      56.884736
    ],
    [
      38.836984,
      56.878171
    ],
    [
      38.831329,
      56.873451
    ],
    [
      38.83006,
      56.863786
    ],
    [
      38.823979,
      56.854502
    ],
    [
      38.820579,
      56.852922
    ],
    [
      38.821977,
      56.847366
    ],
    [
      38.825643,
      56.842655
    ],
    [
      38.825543,
      56.847861
    ],
    [
      38.832216,
      56.854601
    ],
    [
      38.834187,
      56.858817
    ],
    [
      38.834442,
      56.863656
    ],
    [
      38.83506,
      56.868765
    ],
    [
      38.840553,
      56.876073
    ],
    [
      38.845064,
      56.881579
    ],
    [
      38.852722,
      56.883463
    ],
    [
      38.85881,
      56.882273
    ],
    [
      38.867776,
      56.883909
    ],
    [
      38.876964,
      56.879794
    ],
    [
      38.879595,
      56.876412
    ],
    [
      38.881969,
      56.872968
    ],
    [
      38.882903837953336,
      56.872139630580044
    ],
    [
      38.882898,
      56.872133
    ],
    [
      38.879065,
      56.863209
    ],
    [
      38.879718,
      56.859668
    ],
    [
      38.88702,
      56.861016
    ],
    [
      38.894323,
      56.862364
    ],
    [
      38.90199385187996,
      56.85996040742708
    ],
    [
      38.905885,
      56.855264
    ],
    [
      38.906861,
      56.850662
    ],
    [
      38.909382,
      56.8476
    ],
    [
      38.912908,
      56.845193
    ],
    [
      38.916879463372226,
      56.83839476175979
    ],
    [
      38.914546,
      56.832833
    ],
    [
      38.910476,
      56.828654
    ],
    [
      38.90950919290125,
      56.82766040412045
    ],
    [
      38.908759,
      56.82689
    ],
    [
      38.906867,
      56.824945
    ],
    [
      38.904405,
      56.822261
    ],
    [
      38.90249,
      56.819135
    ],
    [
      38.901901241106124,
      56.81816885301468
    ],
    [
      38.901901,
      56.818169
    ],
    [
      38.900436,
      56.815765
    ],
    [
      38.898972,
      56.813362
    ],
    [
      38.895719,
      56.807915
    ],
    [
      38.89464079809665,
      56.80610979473859
    ],
    [
      38.88935,
      56.800731
    ],
    [
      38.878839,
      56.7962
    ],
    [
      38.873627,
      56.796062
    ],
    [
      38.864944,
      56.800807
    ],
    [
      38.864326,
      56.804807
    ],
    [
      38.861467,
      56.806967
    ],
    [
      38.858318,
      56.809064
    ],
    [
      38.852019594861815,
      56.81514361142398
    ],
    [
      38.853338,
      56.816066
    ],
    [
      38.856398,
      56.818209
    ],
    [
      38.84668,
      56.818574
    ],
    [
      38.84158,
      56.818032
    ],
    [
      38.835182,
      56.824285
    ],
    [
      38.832797,
      56.82145
    ],
    [
      38.830532891985335,
      56.81973420385815
    ],
    [
      38.822372,
      56.818534
    ],
    [
      38.81804,
      56.816394
    ],
    [
      38.808536,
      56.814621
    ],
    [
      38.801135,
      56.815161
    ],
    [
      38.799552,
      56.811927
    ],
    [
      38.78933766866419,
      56.80976096715956
    ],
    [
      38.786479,
      56.811528
    ],
    [
      38.784494963351534,
      56.81246925748847
    ],
    [
      38.784433,
      56.812522
    ],
    [
      38.78225,
      56.813535
    ],
    [
      38.782249734787804,
      56.81353442843709
    ],
    [
      38.78216,
      56.813577
    ],
    [
      38.774736,
      56.816958
    ],
    [
      38.77134,
      56.818617
    ],
    [
      38.768107,
      56.820448
    ],
    [
      38.762952,
      56.823158
    ],
    [
      38.758537,
      56.825012
    ],
    [
      38.752145,
      56.827782
    ],
    [
      38.749388,
      56.828944
    ],
    [
      38.74799,
      56.825626
    ],
    [
      38.750746,
      56.824465
    ],
    [
      38.755799,
      56.822158
    ],
    [
      38.7594,
      56.820747
    ],
    [
      38.763921,
      56.818585
    ],
    [
      38.769181,
      56.815736
    ],
    [
      38.773494,
      56.813579
    ],
    [
      38.776546,
      56.811854
    ],
    [
      38.780643,
      56.810312
    ],
    [
      38.78132963578214,
      56.80969055679212
    ],
    [
      38.788289,
      56.802909
    ],
    [
      38.789114,
      56.80211
    ],
    [
      38.794955,
      56.801947
    ],
    [
      38.801357,
      56.797971
    ],
    [
      38.80459,
      56.79601
    ],
    [
      38.808875,
      56.793658
    ],
    [
      38.812307,
      56.791437
    ],
    [
      38.813997482373125,
      56.790462837975575
    ],
    [
      38.813997,
      56.790462
    ],
    [
      38.81566292769646,
      56.789503103870366
    ],
    [
      38.816326,
      56.789121
    ],
    [
      38.823254,
      56.785163
    ],
    [
      38.826324,
      56.782617
    ],
    [
      38.830536,
      56.779308
    ],
    [
      38.83738491451109,
      56.77364904779913
    ],
    [
      38.840814,
      56.76785
    ],
    [
      38.844372,
      56.766354
    ],
    [
      38.848692,
      56.757117
    ],
    [
      38.84885375130627,
      56.75690494980668
    ],
    [
      38.848941,
      56.756721
    ],
    [
      38.84976267551943,
      56.754116932266015
    ],
    [
      38.848607,
      56.752956
    ],
    [
      38.848867,
      56.752444
    ],
    [
      38.8486508358884,
      56.74788222940307
    ],
    [
      38.843375,
      56.744579
    ],
    [
      38.840466,
      56.742508
    ],
    [
      38.83641,
      56.739519
    ],
    [
      38.83517183633817,
      56.73820162403993
    ],
    [
      38.833652,
      56.737614
    ],
    [
      38.829924,
      56.735833
    ],
    [
      38.82836747745565,
      56.73327555965542
    ],
    [
      38.827661,
      56.733152
    ],
    [
      38.823766,
      56.731853
    ],
    [
      38.822148,
      56.730975
    ],
    [
      38.82237180731423,
      56.72498662141763
    ],
    [
      38.820447620379376,
      56.722036621309385
    ],
    [
      38.81473510278483,
      56.718809954310736
    ],
    [
      38.805208,
      56.724304
    ],
    [
      38.801698,
      56.725377
    ],
    [
      38.79383805781083,
      56.72554137779226
    ],
    [
      38.788253,
      56.725959
    ],
    [
      38.78515948770337,
      56.72530320189001
    ],
    [
      38.782551,
      56.725205
    ],
    [
      38.780741033494145,
      56.72483736687244
    ],
    [
      38.775911,
      56.724771
    ],
    [
      38.768530442534846,
      56.72207099961554
    ],
    [
      38.765823521608674,
      56.72355892465276
    ],
    [
      38.762742,
      56.728568
    ],
    [
      38.762739,
      56.728569
    ],
    [
      38.762474703109184,
      56.727554975016204
    ],
    [
      38.761549,
      56.728768
    ],
    [
      38.757538,
      56.729483
    ],
    [
      38.754028,
      56.728638
    ],
    [
      38.744124,
      56.730628
    ],
    [
      38.74077818900113,
      56.730179474746166
    ],
    [
      38.740773,
      56.731347
    ],
    [
      38.731346,
      56.734685
    ],
    [
      38.729333,
      56.745618
    ],
    [
      38.729592,
      56.749706
    ],
    [
      38.7269,
      56.752959
    ],
    [
      38.722833631834206,
      56.75760901159665
    ],
    [
      38.718219,
      56.766713
    ],
    [
      38.716779,
      56.777195
    ],
    [
      38.716562,
      56.77838
    ],
    [
      38.71648153003595,
      56.778391828489404
    ],
    [
      38.716292,
      56.77985
    ],
    [
      38.716102913094744,
      56.780544913515165
    ],
    [
      38.716133,
      56.780768
    ],
    [
      38.714958,
      56.788823
    ],
    [
      38.711119,
      56.792637
    ],
    [
      38.7067900223787,
      56.79601022112228
    ],
    [
      38.70662382859407,
      56.79616008360597
    ],
    [
      38.701827,
      56.806001
    ],
    [
      38.698646,
      56.81058
    ],
    [
      38.693541,
      56.81352
    ],
    [
      38.696208,
      56.822593
    ],
    [
      38.69975,
      56.82331
    ],
    [
      38.704798,
      56.821788
    ],
    [
      38.709675,
      56.820179
    ],
    [
      38.708907675723175,
      56.82230917633769
    ],
    [
      38.711587,
      56.821393
    ],
    [
      38.715153,
      56.824869
    ],
    [
      38.718605,
      56.827776
    ],
    [
      38.71663719882425,
      56.83619018718509
    ],
    [
      38.720054,
      56.836016
    ],
    [
      38.72475,
      56.83528
    ],
    [
      38.732994,
      56.833976
    ],
    [
      38.736829,
      56.833508
    ],
    [
      38.735889,
      56.837184
    ],
    [
      38.730942,
      56.844507
    ],
    [
      38.727375,
      56.84631
    ],
    [
      38.723905,
      56.845413
    ],
    [
      38.719132,
      56.843188
    ],
    [
      38.715992,
      56.841636
    ],
    [
      38.71163012101451,
      56.842704983145545
    ],
    [
      38.70500509734254,
      56.84446223027805
    ],
    [
      38.706686,
      56.852657
    ],
    [
      38.703652,
      56.854837
    ],
    [
      38.698531,
      56.85711
    ],
    [
      38.690298,
      56.859807
    ],
    [
      38.685607,
      56.863355
    ],
    [
      38.680822,
      56.866524
    ],
    [
      38.673694,
      56.871069
    ],
    [
      38.672047,
      56.880971
    ],
    [
      38.680341,
      56.88437
    ],
    [
      38.68602,
      56.88259
    ],
    [
      38.693162,
      56.884668
    ],
    [
      38.697918,
      56.884747
    ],
    [
      38.702723,
      56.884828
    ],
    [
      38.710948,
      56.8851
    ],
    [
      38.716566,
      56.885304
    ],
    [
      38.720499,
      56.885434
    ],
    [
      38.725492,
      56.885634
    ],
    [
      38.729574,
      56.885731
    ],
    [
      38.733091,
      56.885776
    ],
    [
      38.736607,
      56.885821
    ],
    [
      38.736561,
      56.889421
    ],
    [
      38.733044,
      56.889376
    ],
    [
      38.729528,
      56.889331
    ],
    [
      38.725407,
      56.889233
    ],
    [
      38.720111,
      56.889013
    ],
    [
      38.710853,
      56.888699
    ],
    [
      38.702563,
      56.888424
    ],
    [
      38.697858,
      56.888346
    ],
    [
      38.693103,
      56.888267
    ],
    [
      38.689155,
      56.888202
    ],
    [
      38.680277,
      56.887969
    ],
    [
      38.675416,
      56.887883
    ],
    [
      38.665695,
      56.882572
    ],
    [
      38.661038,
      56.881068
    ],
    [
      38.654123,
      56.876883
    ],
    [
      38.648415,
      56.879939
    ],
    [
      38.641368,
      56.883284
    ],
    [
      38.638232,
      56.886674
    ],
    [
      38.635113,
      56.891279
    ],
    [
      38.629727,
      56.896591
    ],
    [
      38.623582,
      56.904735
    ],
    [
      38.618222,
      56.912012
    ],
    [
      38.616237,
      56.915153
    ],
    [
      38.613767,
      56.917686
    ],
    [
      38.615112,
      56.927947
    ],
    [
      38.612556,
      56.932123
    ],
    [
      38.609844,
      56.926447
    ],
    [
      38.607427,
      56.922395
    ],
    [
      38.607639,
      56.917019
    ],
    [
      38.613924,
      56.908497
    ],
    [
      38.615791,
      56.904747
    ],
    [
      38.622013,
      56.899729
    ],
    [
      38.624669,
      56.896229
    ],
    [
      38.627394,
      56.893849
    ],
    [
      38.631602,
      56.889627
    ],
    [
      38.636111,
      56.883766
    ],
    [
      38.638984,
      56.880587
    ],
    [
      38.643819,
      56.87488
    ],
    [
      38.65129,
      56.874461
    ],
    [
      38.66019,
      56.873557
    ],
    [
      38.666004,
      56.870536
    ],
    [
      38.669107686264944,
      56.86908082906266
    ],
    [
      38.669064,
      56.868844
    ],
    [
      38.673468,
      56.865426
    ],
    [
      38.678941,
      56.863391
    ],
    [
      38.682148,
      56.86186
    ],
    [
      38.684735,
      56.859053
    ],
    [
      38.680418,
      56.84901
    ],
    [
      38.676597,
      56.848244
    ],
    [
      38.672502,
      56.848381
    ],
    [
      38.667943,
      56.848107
    ],
    [
      38.661668,
      56.850857
    ],
    [
      38.655862,
      56.858152
    ],
    [
      38.65518437270784,
      56.858059484022625
    ],
    [
      38.652502,
      56.859557
    ],
    [
      38.65088,
      56.855776
    ],
    [
      38.654955,
      56.854008
    ],
    [
      38.659262,
      56.8462
    ],
    [
      38.664076,
      56.844472
    ],
    [
      38.667992,
      56.844508
    ],
    [
      38.672978,
      56.835783
    ],
    [
      38.662681,
      56.832065
    ],
    [
      38.653079,
      56.835645
    ],
    [
      38.648755,
      56.834982
    ],
    [
      38.638259960447925,
      56.834941800390354
    ],
    [
      38.63713752084191,
      56.83520168226571
    ],
    [
      38.634284,
      56.836016
    ],
    [
      38.63220545222919,
      56.83647229622213
    ],
    [
      38.631247,
      56.836732
    ],
    [
      38.627527,
      56.837686
    ],
    [
      38.626815785647445,
      56.8378719170745
    ],
    [
      38.62631,
      56.83801
    ],
    [
      38.6256302273231,
      56.83818183142666
    ],
    [
      38.623166,
      56.838826
    ],
    [
      38.61825,
      56.840039
    ],
    [
      38.61411,
      56.841137
    ],
    [
      38.609869,
      56.842227
    ],
    [
      38.606308,
      56.84305
    ],
    [
      38.601559,
      56.844222
    ],
    [
      38.597145,
      56.845302
    ],
    [
      38.593523,
      56.846311
    ],
    [
      38.590071,
      56.845386
    ],
    [
      38.580811,
      56.842242
    ],
    [
      38.57733,
      56.844373
    ],
    [
      38.573768,
      56.844776
    ],
    [
      38.567102,
      56.844642
    ],
    [
      38.558431,
      56.845523
    ],
    [
      38.55236,
      56.846725
    ],
    [
      38.552349032745,
      56.8466836500028
    ],
    [
      38.544565,
      56.848501
    ],
    [
      38.541005,
      56.848805
    ],
    [
      38.529569,
      56.850718
    ],
    [
      38.525899,
      56.852618
    ],
    [
      38.516728,
      56.859917
    ],
    [
      38.521152,
      56.86971
    ],
    [
      38.515388,
      56.87225
    ],
    [
      38.507622,
      56.872755
    ],
    [
      38.50391803343953,
      56.87365078269203
    ],
    [
      38.502817,
      56.87432
    ],
    [
      38.495329,
      56.877095
    ],
    [
      38.489526,
      56.876857
    ],
    [
      38.480893,
      56.883681
    ],
    [
      38.48002,
      56.889003
    ],
    [
      38.48464,
      56.895139
    ],
    [
      38.491669,
      56.903211
    ],
    [
      38.500861,
      56.900014
    ],
    [
      38.502109,
      56.894432
    ],
    [
      38.509124,
      56.897139
    ],
    [
      38.50806,
      56.904737
    ],
    [
      38.516933,
      56.912655
    ],
    [
      38.521716,
      56.913456
    ],
    [
      38.531777,
      56.915742
    ],
    [
      38.537718,
      56.915134
    ],
    [
      38.547878,
      56.91344
    ],
    [
      38.548469,
      56.916991
    ],
    [
      38.54339,
      56.917838
    ],
    [
      38.53831,
      56.918685
    ],
    [
      38.534814,
      56.919189
    ],
    [
      38.529335,
      56.919612
    ],
    [
      38.524959,
      56.919051
    ],
    [
      38.520122,
      56.916683
    ],
    [
      38.513423,
      56.911854
    ],
    [
      38.508159,
      56.913831
    ],
    [
      38.50417,
      56.912468
    ],
    [
      38.494473,
      56.919458
    ],
    [
      38.492343,
      56.922369
    ],
    [
      38.489697,
      56.924998
    ],
    [
      38.480645,
      56.924379
    ],
    [
      38.477132,
      56.924999
    ],
    [
      38.473101,
      56.926059
    ],
    [
      38.472083,
      56.926357
    ],
    [
      38.471575,
      56.926408
    ],
    [
      38.471213,
      56.922826
    ],
    [
      38.472696,
      56.922059
    ],
    [
      38.476266,
      56.921382
    ],
    [
      38.480085,
      56.920779
    ],
    [
      38.483813,
      56.920424
    ],
    [
      38.487904,
      56.910349
    ],
    [
      38.482933,
      56.903499
    ],
    [
      38.480676084944,
      56.90058101433734
    ],
    [
      38.480497,
      56.900607
    ],
    [
      38.480451,
      56.90029
    ],
    [
      38.477786,
      56.89349
    ],
    [
      38.469936,
      56.887624
    ],
    [
      38.459491,
      56.891115
    ],
    [
      38.453527,
      56.893663
    ],
    [
      38.448964,
      56.895496
    ],
    [
      38.441334,
      56.897746
    ],
    [
      38.44089,
      56.897741
    ],
    [
      38.437791422848676,
      56.89652476386287
    ],
    [
      38.437479,
      56.89653
    ],
    [
      38.430813,
      56.895104
    ],
    [
      38.423729,
      56.888462
    ],
    [
      38.420021815178075,
      56.8891000822043
    ],
    [
      38.420028,
      56.88919
    ],
    [
      38.419145,
      56.889251
    ],
    [
      38.41361,
      56.889677
    ],
    [
      38.408553,
      56.889976
    ],
    [
      38.403169,
      56.889788
    ],
    [
      38.398823,
      56.889644
    ],
    [
      38.391959,
      56.889951
    ],
    [
      38.388453,
      56.88977
    ],
    [
      38.384496,
      56.889474
    ],
    [
      38.380107,
      56.889136
    ],
    [
      38.375816,
      56.88882
    ],
    [
      38.372153,
      56.888562
    ],
    [
      38.36692,
      56.888354
    ],
    [
      38.362391,
      56.888194
    ],
    [
      38.357165723071944,
      56.89052913691063
    ],
    [
      38.350675,
      56.898513
    ],
    [
      38.34948893972384,
      56.89775243395493
    ],
    [
      38.348376,
      56.898764
    ],
    [
      38.352746,
      56.907863
    ],
    [
      38.356526,
      56.908184
    ],
    [
      38.36261,
      56.908618
    ],
    [
      38.362454,
      56.912215
    ],
    [
      38.35625,
      56.911774
    ],
    [
      38.352368,
      56.911443
    ],
    [
      38.342441,
      56.90951
    ],
    [
      38.333496,
      56.911868
    ],
    [
      38.326866,
      56.912923
    ],
    [
      38.319221,
      56.915314
    ],
    [
      38.317867,
      56.911978
    ],
    [
      38.321495,
      56.910303
    ],
    [
      38.326311,
      56.909366
    ],
    [
      38.330693,
      56.909021
    ],
    [
      38.334558,
      56.907537
    ],
    [
      38.338479,
      56.906628
    ],
    [
      38.343372,
      56.904213
    ],
    [
      38.34174,
      56.89384
    ],
    [
      38.330764,
      56.891552
    ],
    [
      38.325745,
      56.890409
    ],
    [
      38.3236780110408,
      56.887544920687674
    ],
    [
      38.323325,
      56.887591
    ],
    [
      38.321798,
      56.88694
    ],
    [
      38.319374,
      56.884409
    ],
    [
      38.31711449167671,
      56.882485399252914
    ],
    [
      38.317022,
      56.882578
    ],
    [
      38.3158609033944,
      56.881418174436114
    ],
    [
      38.315856,
      56.881414
    ],
    [
      38.31077,
      56.876739
    ],
    [
      38.305803,
      56.873953
    ],
    [
      38.302137,
      56.871842
    ],
    [
      38.298619,
      56.867332
    ],
    [
      38.29817791646127,
      56.86704417185892
    ],
    [
      38.295964,
      56.865991
    ],
    [
      38.294716,
      56.865189
    ],
    [
      38.291951842951406,
      56.86301869613999
    ],
    [
      38.28902576702585,
      56.86210260862203
    ],
    [
      38.28352945456322,
      56.861666237695026
    ],
    [
      38.282847774203084,
      56.8619587420492
    ],
    [
      38.27821,
      56.867168
    ],
    [
      38.277913,
      56.870756
    ],
    [
      38.275587981329764,
      56.871610913697374
    ],
    [
      38.27563598027566,
      56.872049905976056
    ],
    [
      38.275916,
      56.872828
    ],
    [
      38.276071,
      56.875934
    ],
    [
      38.2760607192416,
      56.875934514752856
    ],
    [
      38.276081,
      56.87612
    ],
    [
      38.27955,
      56.886203
    ],
    [
      38.27993334043335,
      56.88797534295293
    ],
    [
      38.286959,
      56.895053
    ],
    [
      38.285812,
      56.898465
    ],
    [
      38.28029245197358,
      56.89941456489187
    ],
    [
      38.285294,
      56.904142
    ],
    [
      38.290115,
      56.903776
    ],
    [
      38.295357,
      56.902386
    ],
    [
      38.297501,
      56.901794
    ],
    [
      38.301627,
      56.901365
    ],
    [
      38.305752,
      56.900937
    ],
    [
      38.306124,
      56.904517
    ],
    [
      38.301999,
      56.904946
    ],
    [
      38.291038,
      56.907255
    ],
    [
      38.28593,
      56.908063
    ],
    [
      38.281846,
      56.907203
    ],
    [
      38.274608,
      56.904672
    ],
    [
      38.268209,
      56.906388
    ],
    [
      38.272455,
      56.91663
    ],
    [
      38.277563,
      56.918235
    ],
    [
      38.283632,
      56.916224
    ],
    [
      38.293923,
      56.91378
    ],
    [
      38.295443,
      56.917159
    ],
    [
      38.294762,
      56.92689
    ],
    [
      38.297133,
      56.93037
    ],
    [
      38.300864,
      56.936475
    ],
    [
      38.303928,
      56.939217
    ],
    [
      38.303305,
      56.942896
    ],
    [
      38.29759,
      56.939637
    ],
    [
      38.293979,
      56.939349
    ],
    [
      38.291735,
      56.95086
    ],
    [
      38.29277,
      56.955712
    ],
    [
      38.300313,
      56.96185
    ],
    [
      38.309811,
      56.961467
    ],
    [
      38.318462,
      56.961128
    ],
    [
      38.323027,
      56.960958
    ],
    [
      38.32754,
      56.960791
    ],
    [
      38.335819,
      56.960981
    ],
    [
      38.343513,
      56.9596
    ],
    [
      38.347676,
      56.958905
    ],
    [
      38.351604,
      56.958313
    ],
    [
      38.355517,
      56.958419
    ],
    [
      38.358396,
      56.958923
    ],
    [
      38.361143,
      56.959654
    ],
    [
      38.360217,
      56.963133
    ],
    [
      38.35747,
      56.962402
    ],
    [
      38.350756,
      56.962152
    ],
    [
      38.345128,
      56.963095
    ],
    [
      38.340885,
      56.964045
    ],
    [
      38.336281,
      56.964803
    ],
    [
      38.331874,
      56.965594
    ],
    [
      38.327778,
      56.964384
    ],
    [
      38.323161,
      56.964556
    ],
    [
      38.318595,
      56.964725
    ],
    [
      38.314647,
      56.974078
    ],
    [
      38.316979,
      56.976799
    ],
    [
      38.320684,
      56.982589
    ],
    [
      38.327418,
      56.983813
    ],
    [
      38.33237,
      56.983643
    ],
    [
      38.334091,
      56.983785
    ],
    [
      38.338316,
      56.9844
    ],
    [
      38.34254,
      56.985016
    ],
    [
      38.34253966149844,
      56.98501832320335
    ],
    [
      38.342797,
      56.985056
    ],
    [
      38.349688,
      56.98567
    ],
    [
      38.356572,
      56.986284
    ],
    [
      38.360103,
      56.986585
    ],
    [
      38.363679,
      56.98689
    ],
    [
      38.369856,
      56.987576
    ],
    [
      38.373724,
      56.987749
    ],
    [
      38.379504,
      56.989343
    ],
    [
      38.383835,
      56.990733
    ],
    [
      38.388551,
      56.992012
    ],
    [
      38.392307,
      56.993195
    ],
    [
      38.398935,
      56.99669
    ],
    [
      38.403947,
      56.997199
    ],
    [
      38.409259,
      56.997016
    ],
    [
      38.411687,
      56.996909
    ],
    [
      38.413684,
      56.996863
    ],
    [
      38.413768,
      57.000462
    ],
    [
      38.411771,
      57.000509
    ],
    [
      38.406736,
      57.000705
    ],
    [
      38.401221,
      57.000815
    ],
    [
      38.397666,
      57.000059
    ],
    [
      38.393019,
      56.997561
    ],
    [
      38.389654,
      56.995996
    ],
    [
      38.385262,
      56.99485
    ],
    [
      38.38061,
      56.993477
    ],
    [
      38.375707,
      56.991819
    ],
    [
      38.371623,
      56.991246
    ],
    [
      38.366437,
      56.990816
    ],
    [
      38.359798,
      56.990172
    ],
    [
      38.356267,
      56.989871
    ],
    [
      38.349368,
      56.989256
    ],
    [
      38.345701,
      57.000155
    ],
    [
      38.350464,
      57.007927
    ],
    [
      38.353509,
      57.010374
    ],
    [
      38.363412,
      57.012736
    ],
    [
      38.368544,
      57.011305
    ],
    [
      38.378129,
      57.017311
    ],
    [
      38.378715,
      57.020863
    ],
    [
      38.371634,
      57.022946
    ],
    [
      38.368788,
      57.020742
    ],
    [
      38.36026,
      57.019697
    ],
    [
      38.354495,
      57.027409
    ],
    [
      38.354002,
      57.030975
    ],
    [
      38.346308,
      57.031165
    ],
    [
      38.342709,
      57.031072
    ],
    [
      38.342667,
      57.029687
    ],
    [
      38.345845,
      57.026363
    ],
    [
      38.349176,
      57.020059
    ],
    [
      38.349707,
      57.011936
    ],
    [
      38.345581,
      57.008561
    ],
    [
      38.343464,
      57.005448
    ],
    [
      38.337824,
      56.997798
    ],
    [
      38.332556,
      56.995104
    ],
    [
      38.32544,
      56.995523
    ],
    [
      38.320999,
      56.998209
    ],
    [
      38.314009,
      57.0036
    ],
    [
      38.30504,
      57.009829
    ],
    [
      38.304737,
      57.013857
    ],
    [
      38.301542,
      57.017023
    ],
    [
      38.298255,
      57.018677
    ],
    [
      38.293797,
      57.020399
    ],
    [
      38.290199,
      57.028842
    ],
    [
      38.287461,
      57.03118
    ],
    [
      38.286044,
      57.029519
    ],
    [
      38.276001,
      57.023857
    ],
    [
      38.274126,
      57.023574
    ],
    [
      38.274665,
      57.020014
    ],
    [
      38.27654,
      57.020298
    ],
    [
      38.278414,
      57.020582
    ],
    [
      38.286856,
      57.019922
    ],
    [
      38.292646,
      57.016988
    ],
    [
      38.296856,
      57.01536
    ],
    [
      38.301732,
      57.006083
    ],
    [
      38.298194,
      57.0029
    ],
    [
      38.294828,
      57.001344
    ],
    [
      38.291272,
      57.001021
    ],
    [
      38.28757,
      57.001332
    ],
    [
      38.283742,
      57.002034
    ],
    [
      38.279876,
      57.001988
    ],
    [
      38.274376,
      57.001356
    ],
    [
      38.271783,
      57.001208
    ],
    [
      38.271099,
      57.00112
    ],
    [
      38.271554,
      56.997549
    ],
    [
      38.272239,
      56.997636
    ],
    [
      38.275376,
      56.997897
    ],
    [
      38.280369,
      56.998422
    ],
    [
      38.284187,
      56.998273
    ],
    [
      38.288349,
      56.997614
    ],
    [
      38.292313,
      56.997322
    ],
    [
      38.295988,
      56.997857
    ],
    [
      38.306225,
      56.992139
    ],
    [
      38.30458,
      56.988999
    ],
    [
      38.300284,
      56.980669
    ],
    [
      38.295536,
      56.977226
    ],
    [
      38.291675,
      56.974153
    ],
    [
      38.289686,
      56.972887
    ],
    [
      38.289408,
      56.972519
    ],
    [
      38.290446,
      56.9606
    ],
    [
      38.282335,
      56.960121
    ],
    [
      38.278024,
      56.95897
    ],
    [
      38.274936,
      56.957028
    ],
    [
      38.265488,
      56.958434
    ],
    [
      38.26128,
      56.960534
    ],
    [
      38.258005,
      56.96213
    ],
    [
      38.255852,
      56.972755
    ],
    [
      38.256077,
      56.976357
    ],
    [
      38.265957,
      56.982775
    ],
    [
      38.271964,
      56.982633
    ],
    [
      38.276015,
      56.9853
    ],
    [
      38.275457,
      56.98727
    ],
    [
      38.275114,
      56.988106
    ],
    [
      38.26606,
      56.986374
    ],
    [
      38.260385,
      56.986537
    ],
    [
      38.256622,
      56.983063
    ],
    [
      38.246121,
      56.982668
    ],
    [
      38.244065,
      56.989458
    ],
    [
      38.242631,
      56.994123
    ],
    [
      38.241533,
      56.999388
    ],
    [
      38.241247,
      56.999819
    ],
    [
      38.238249,
      56.997826
    ],
    [
      38.238246,
      56.995734
    ],
    [
      38.239433,
      56.991784
    ],
    [
      38.240733,
      56.988082
    ],
    [
      38.241096,
      56.983285
    ],
    [
      38.242299,
      56.976514
    ],
    [
      38.245645,
      56.973153
    ],
    [
      38.248137,
      56.970623
    ],
    [
      38.246521,
      56.959029
    ],
    [
      38.240817,
      56.960196
    ],
    [
      38.237191,
      56.961862
    ],
    [
      38.23152,
      56.96968
    ],
    [
      38.223704,
      56.96755
    ],
    [
      38.218042,
      56.967056
    ],
    [
      38.22304,
      56.964012
    ],
    [
      38.226961,
      56.962894
    ],
    [
      38.235097,
      56.958934
    ],
    [
      38.239593,
      56.95681
    ],
    [
      38.243724,
      56.955929
    ],
    [
      38.247863,
      56.955078
    ],
    [
      38.252074,
      56.954013
    ],
    [
      38.256499,
      56.953111
    ],
    [
      38.26225056290581,
      56.947520688922424
    ],
    [
      38.262966,
      56.942254
    ],
    [
      38.263932,
      56.938668
    ],
    [
      38.265149,
      56.934374
    ],
    [
      38.264026,
      56.92507
    ],
    [
      38.255062,
      56.922605
    ],
    [
      38.249983,
      56.922524
    ],
    [
      38.245620934722865,
      56.92517637773057
    ],
    [
      38.247293,
      56.935533
    ],
    [
      38.249365,
      56.942044
    ],
    [
      38.249428,
      56.944135
    ],
    [
      38.24583,
      56.944244
    ],
    [
      38.240788,
      56.936868
    ],
    [
      38.23191,
      56.934375
    ],
    [
      38.226963,
      56.935044
    ],
    [
      38.222702,
      56.93694
    ],
    [
      38.21799,
      56.938643
    ],
    [
      38.211448,
      56.940311
    ],
    [
      38.205615,
      56.944908
    ],
    [
      38.202901,
      56.948
    ],
    [
      38.201753,
      56.957247
    ],
    [
      38.19809,
      56.963377
    ],
    [
      38.19462,
      56.964728
    ],
    [
      38.184098,
      56.960591
    ],
    [
      38.18,
      56.961761
    ],
    [
      38.175601,
      56.961859
    ],
    [
      38.171568,
      56.961815
    ],
    [
      38.167754,
      56.960036
    ],
    [
      38.16153388933509,
      56.952252775087885
    ],
    [
      38.15791845272953,
      56.95475374980848
    ],
    [
      38.155045,
      56.958578
    ],
    [
      38.15554,
      56.962144
    ],
    [
      38.153826,
      56.971614
    ],
    [
      38.154829,
      56.975347
    ],
    [
      38.152921,
      56.984546
    ],
    [
      38.162134,
      56.987913
    ],
    [
      38.166067,
      56.988295
    ],
    [
      38.172696,
      56.98761
    ],
    [
      38.178198,
      56.987278
    ],
    [
      38.182797,
      56.986947
    ],
    [
      38.182924,
      56.990545
    ],
    [
      38.178066,
      56.990875
    ],
    [
      38.172536,
      56.991414
    ],
    [
      38.166102,
      56.991968
    ],
    [
      38.160645,
      56.999282
    ],
    [
      38.162146,
      57.003536
    ],
    [
      38.162793,
      57.007098
    ],
    [
      38.163205,
      57.010957
    ],
    [
      38.171878,
      57.016828
    ],
    [
      38.177676,
      57.012744
    ],
    [
      38.180663,
      57.009999
    ],
    [
      38.186349,
      57.010017
    ],
    [
      38.189876,
      57.008855
    ],
    [
      38.188995,
      57.012479
    ],
    [
      38.185792,
      57.014839
    ],
    [
      38.182499,
      57.019635
    ],
    [
      38.184169,
      57.03096
    ],
    [
      38.188706,
      57.031702
    ],
    [
      38.19404,
      57.033008
    ],
    [
      38.19877,
      57.033928
    ],
    [
      38.203027,
      57.035057
    ],
    [
      38.20676,
      57.036162
    ],
    [
      38.210778,
      57.03798
    ],
    [
      38.216149,
      57.040399
    ],
    [
      38.219745,
      57.041668
    ],
    [
      38.226165,
      57.044
    ],
    [
      38.223279,
      57.049282
    ],
    [
      38.220396,
      57.052916
    ],
    [
      38.21728,
      57.051113
    ],
    [
      38.211266,
      57.042321
    ],
    [
      38.207345,
      57.040195
    ],
    [
      38.203839,
      57.039051
    ],
    [
      38.200264,
      57.037972
    ],
    [
      38.196251,
      57.037118
    ],
    [
      38.191136,
      57.035975
    ],
    [
      38.185828,
      57.034878
    ],
    [
      38.181659,
      57.034232
    ],
    [
      38.175614,
      57.033555
    ],
    [
      38.171949,
      57.032611
    ],
    [
      38.16668,
      57.031383
    ],
    [
      38.16659570482238,
      57.031056463099986
    ],
    [
      38.166313,
      57.0312
    ],
    [
      38.166196,
      57.031124
    ],
    [
      38.165374,
      57.029611
    ],
    [
      38.165382,
      57.029495
    ],
    [
      38.157435,
      57.021592
    ],
    [
      38.151115,
      57.026922
    ],
    [
      38.15006,
      57.031858
    ],
    [
      38.151036,
      57.036324
    ],
    [
      38.152435,
      57.040052
    ],
    [
      38.15479,
      57.043971
    ],
    [
      38.151838,
      57.046172
    ],
    [
      38.143141,
      57.05203
    ],
    [
      38.138599,
      57.059453
    ],
    [
      38.135471,
      57.063234
    ],
    [
      38.132608,
      57.065553
    ],
    [
      38.128064,
      57.068008
    ],
    [
      38.123288,
      57.070537
    ],
    [
      38.123013,
      57.070674
    ],
    [
      38.113743,
      57.071301
    ],
    [
      38.1113,
      57.070778
    ],
    [
      38.108858,
      57.070254
    ],
    [
      38.106132,
      57.069474
    ],
    [
      38.101571,
      57.066751
    ],
    [
      38.098242,
      57.062823
    ],
    [
      38.094626,
      57.059292
    ],
    [
      38.088541,
      57.055695
    ],
    [
      38.07871,
      57.055131
    ],
    [
      38.072351,
      57.054255
    ],
    [
      38.068647,
      57.05368
    ],
    [
      38.060491,
      57.053419
    ],
    [
      38.057752,
      57.059701
    ],
    [
      38.051583,
      57.060122
    ],
    [
      38.048069,
      57.060073
    ],
    [
      38.051281,
      57.052131
    ],
    [
      38.044071,
      57.044828
    ],
    [
      38.039781,
      57.042718
    ],
    [
      38.030032,
      57.039496
    ],
    [
      38.026552,
      57.040893
    ],
    [
      38.01905,
      57.031942
    ],
    [
      38.013958,
      57.03392
    ],
    [
      38.013506,
      57.04297
    ],
    [
      38.00945,
      57.044847
    ],
    [
      38.004201,
      57.04891
    ],
    [
      38.003561,
      57.058021
    ],
    [
      38.006764,
      57.060958
    ],
    [
      38.007924,
      57.064943
    ],
    [
      38.009473,
      57.069028
    ],
    [
      38.008863,
      57.072962
    ],
    [
      38.009109,
      57.080453
    ],
    [
      38.005965,
      57.082208
    ],
    [
      38.004129,
      57.077836
    ],
    [
      37.993265,
      57.072809
    ],
    [
      37.99154,
      57.076023
    ],
    [
      37.982563,
      57.081416
    ],
    [
      37.982146,
      57.084992
    ],
    [
      37.978968,
      57.086736
    ],
    [
      37.973002,
      57.087231
    ],
    [
      37.971475,
      57.083971
    ],
    [
      37.961957,
      57.077717
    ],
    [
      37.956792,
      57.079383
    ],
    [
      37.953107,
      57.080494
    ],
    [
      37.950583,
      57.081269
    ],
    [
      37.949526,
      57.077827
    ],
    [
      37.952051,
      57.077052
    ],
    [
      37.955719,
      57.075947
    ],
    [
      37.961095,
      57.074221
    ],
    [
      37.963323,
      57.073672
    ],
    [
      37.971535,
      57.071454
    ],
    [
      37.974531,
      57.06935
    ],
    [
      37.978667,
      57.066403
    ],
    [
      37.977431,
      57.055976
    ],
    [
      37.972651,
      57.055913
    ],
    [
      37.967717,
      57.055925
    ],
    [
      37.963056,
      57.056687
    ],
    [
      37.963338,
      57.052836
    ],
    [
      37.9715,
      57.047598
    ],
    [
      37.972324,
      57.043904
    ],
    [
      37.973657,
      57.033023
    ],
    [
      37.96784734912819,
      57.03227039670852
    ],
    [
      37.967393,
      57.032345
    ],
    [
      37.965690577777785,
      57.032475955555554
    ],
    [
      37.962168,
      57.033012
    ],
    [
      37.956974,
      57.03432
    ],
    [
      37.952548,
      57.035981
    ],
    [
      37.947736,
      57.038251
    ],
    [
      37.944314,
      57.040653
    ],
    [
      37.941753,
      57.04369
    ],
    [
      37.938296,
      57.050064
    ],
    [
      37.936958,
      57.05496
    ],
    [
      37.936225,
      57.059311
    ],
    [
      37.935795,
      57.061578
    ],
    [
      37.934622,
      57.066918
    ],
    [
      37.93345,
      57.072257
    ],
    [
      37.929934,
      57.071485
    ],
    [
      37.931106,
      57.066146
    ],
    [
      37.932278,
      57.060806
    ],
    [
      37.933025,
      57.056514
    ],
    [
      37.933796,
      57.052177
    ],
    [
      37.930146,
      57.045856
    ],
    [
      37.9218,
      57.045855
    ],
    [
      37.914708,
      57.045764
    ],
    [
      37.910769,
      57.045728
    ],
    [
      37.904349,
      57.047039
    ],
    [
      37.900377,
      57.048133
    ],
    [
      37.899421,
      57.044663
    ],
    [
      37.903393,
      57.043569
    ],
    [
      37.909024,
      57.034781
    ],
    [
      37.911566,
      57.034768
    ],
    [
      37.916525,
      57.035596
    ],
    [
      37.923375,
      57.032967
    ],
    [
      37.920072,
      57.022843
    ],
    [
      37.915003,
      57.021265
    ],
    [
      37.910714,
      57.019778
    ],
    [
      37.906575,
      57.017985
    ],
    [
      37.900904,
      57.016914
    ],
    [
      37.897542,
      57.016445
    ],
    [
      37.897224,
      57.016333
    ],
    [
      37.898419,
      57.012937
    ],
    [
      37.90027,
      57.013152
    ],
    [
      37.904089,
      57.013843
    ],
    [
      37.907581,
      57.014529
    ],
    [
      37.911947,
      57.016395
    ],
    [
      37.916203,
      57.017871
    ],
    [
      37.921136,
      57.019403
    ],
    [
      37.924451,
      57.020712
    ],
    [
      37.928374,
      57.022042
    ],
    [
      37.93807,
      57.018677
    ],
    [
      37.939156,
      57.01524
    ],
    [
      37.948365,
      57.020684
    ],
    [
      37.952002,
      57.022089
    ],
    [
      37.955797,
      57.02367
    ],
    [
      37.960469,
      57.025892
    ],
    [
      37.96649131778243,
      57.02846193269681
    ],
    [
      37.966804,
      57.028375
    ],
    [
      37.968292,
      57.028849
    ],
    [
      37.968533,
      57.02893
    ],
    [
      37.977497,
      57.029072
    ],
    [
      37.979462,
      57.021358
    ],
    [
      37.9778274950797,
      57.01883651765633
    ],
    [
      37.977793,
      57.01879
    ],
    [
      37.9777757006846,
      57.01875661661038
    ],
    [
      37.977739,
      57.0187
    ],
    [
      37.976632,
      57.016758
    ],
    [
      37.976646593673266,
      57.01674968874217
    ],
    [
      37.976469,
      57.016459
    ],
    [
      37.975925710164816,
      57.01518659358252
    ],
    [
      37.975373,
      57.01412
    ],
    [
      37.97532266883652,
      57.01377424679001
    ],
    [
      37.974861,
      57.012693
    ],
    [
      37.97475679116544,
      57.01009705800474
    ],
    [
      37.966235,
      56.995013
    ],
    [
      37.95890830097431,
      56.98469392839635
    ],
    [
      37.958611,
      56.984779
    ],
    [
      37.95656576142746,
      56.98393402744843
    ],
    [
      37.95402,
      56.985201
    ],
    [
      37.946028,
      56.988197
    ],
    [
      37.94255,
      56.989144
    ],
    [
      37.94243102114851,
      56.98762229248868
    ],
    [
      37.940574,
      56.987542
    ],
    [
      37.937037,
      56.987943
    ],
    [
      37.932517,
      56.989821
    ],
    [
      37.926822,
      56.989684
    ],
    [
      37.91582,
      56.993068
    ],
    [
      37.912502,
      56.994496
    ],
    [
      37.909951270723276,
      56.992360392589035
    ],
    [
      37.907876,
      56.992986
    ],
    [
      37.902706,
      56.983234
    ],
    [
      37.895599,
      56.979457
    ],
    [
      37.891678,
      56.977506
    ],
    [
      37.88911161899734,
      56.97646844439536
    ],
    [
      37.879157,
      56.977774
    ],
    [
      37.878206,
      56.977497
    ],
    [
      37.8764505182323,
      56.976077341013294
    ],
    [
      37.867536,
      56.972605
    ],
    [
      37.856171,
      56.97208
    ],
    [
      37.852462,
      56.973207
    ],
    [
      37.849107,
      56.97468
    ],
    [
      37.845898,
      56.975579
    ],
    [
      37.845334,
      56.975661
    ],
    [
      37.84525549279701,
      56.9751199532606
    ],
    [
      37.839057,
      56.975828
    ],
    [
      37.836657,
      56.976247
    ],
    [
      37.836053,
      56.975486
    ],
    [
      37.836007,
      56.97386
    ],
    [
      37.838883,
      56.971695
    ],
    [
      37.84544186933435,
      56.97135821120109
    ],
    [
      37.85225,
      56.963298
    ],
    [
      37.851853,
      56.953893
    ],
    [
      37.8504854450454,
      56.952510617922655
    ],
    [
      37.845915,
      56.94904
    ],
    [
      37.836201,
      56.942326
    ],
    [
      37.832564,
      56.942596
    ],
    [
      37.827809,
      56.943118
    ],
    [
      37.823546,
      56.94374
    ],
    [
      37.818955,
      56.944488
    ],
    [
      37.814477,
      56.945413
    ],
    [
      37.810511,
      56.946359
    ],
    [
      37.804238,
      56.954461
    ],
    [
      37.80370525290681,
      56.95439584173531
    ],
    [
      37.803069,
      56.956036
    ],
    [
      37.799228,
      56.95728
    ],
    [
      37.793358,
      56.96465
    ],
    [
      37.78716066044245,
      56.96530799410158
    ],
    [
      37.784303,
      56.973374
    ],
    [
      37.780635,
      56.97697
    ],
    [
      37.778013,
      56.978445
    ],
    [
      37.77797173535656,
      56.97837163543846
    ],
    [
      37.775556,
      56.979643
    ],
    [
      37.77209,
      56.980863
    ],
    [
      37.764974,
      56.985511
    ],
    [
      37.761648,
      56.988124
    ],
    [
      37.758265,
      56.990546
    ],
    [
      37.751276,
      56.996074
    ],
    [
      37.743078,
      57.000249
    ],
    [
      37.742587,
      56.995826
    ],
    [
      37.746337,
      56.994621
    ],
    [
      37.752962,
      56.989595
    ],
    [
      37.756994,
      56.98694
    ],
    [
      37.759989,
      56.984799
    ],
    [
      37.76498,
      56.980301
    ],
    [
      37.768614,
      56.97857
    ],
    [
      37.772517,
      56.976903
    ],
    [
      37.776238,
      56.975316
    ],
    [
      37.781153,
      56.967155
    ],
    [
      37.783238,
      56.957456
    ],
    [
      37.784983,
      56.954113
    ],
    [
      37.789465,
      56.953848
    ],
    [
      37.793515,
      56.953407
    ],
    [
      37.801005,
      56.948198
    ],
    [
      37.803594,
      56.944957
    ],
    [
      37.809071,
      56.943059
    ],
    [
      37.813929,
      56.941855
    ],
    [
      37.818031,
      56.941008
    ],
    [
      37.82506184841064,
      56.93393130995437
    ],
    [
      37.823375,
      56.925312
    ],
    [
      37.821172,
      56.922266
    ],
    [
      37.819596,
      56.918981
    ],
    [
      37.81767030171085,
      56.91491736892781
    ],
    [
      37.812449,
      56.90855
    ],
    [
      37.810201,
      56.903246
    ],
    [
      37.802558,
      56.9012
    ],
    [
      37.798197,
      56.900882
    ],
    [
      37.793226,
      56.900528
    ],
    [
      37.78946,
      56.900444
    ],
    [
      37.78794200657685,
      56.900394797747985
    ],
    [
      37.787942,
      56.900395
    ],
    [
      37.78720385568967,
      56.90037087229125
    ],
    [
      37.785079,
      56.900302
    ],
    [
      37.780723,
      56.90016
    ],
    [
      37.772219,
      56.903029
    ],
    [
      37.769081,
      56.904724
    ],
    [
      37.766078,
      56.906776
    ],
    [
      37.765971,
      56.906741
    ],
    [
      37.764678,
      56.903433
    ],
    [
      37.75845967572347,
      56.89642796620378
    ],
    [
      37.754509,
      56.89584
    ],
    [
      37.751094,
      56.895215
    ],
    [
      37.750973,
      56.895163
    ],
    [
      37.750859,
      56.894195
    ],
    [
      37.750992,
      56.893466
    ],
    [
      37.751108,
      56.891616
    ],
    [
      37.74696289117441,
      56.883189611762546
    ],
    [
      37.746380341286894,
      56.88287013578439
    ],
    [
      37.739773,
      56.884689
    ],
    [
      37.737186,
      56.887646
    ],
    [
      37.73448457104412,
      56.889931605915336
    ],
    [
      37.73326798536404,
      56.891631888806586
    ],
    [
      37.733005256245306,
      56.89201954172998
    ],
    [
      37.7305204571311,
      56.89623203766817
    ],
    [
      37.73311604348438,
      56.90623968056766
    ],
    [
      37.738743,
      56.910234
    ],
    [
      37.740084,
      56.91401
    ],
    [
      37.73761954176589,
      56.919955035685675
    ],
    [
      37.741842,
      56.927731
    ],
    [
      37.741816,
      56.931331
    ],
    [
      37.736537,
      56.931099
    ],
    [
      37.727602,
      56.925759
    ],
    [
      37.7192,
      56.92506
    ],
    [
      37.707815,
      56.926238
    ],
    [
      37.70830606680615,
      56.925238690760125
    ],
    [
      37.705655,
      56.925792
    ],
    [
      37.708644,
      56.923721
    ],
    [
      37.70900093057215,
      56.92347734443495
    ],
    [
      37.707603,
      56.922041
    ],
    [
      37.707782,
      56.921867
    ],
    [
      37.70852,
      56.92104
    ],
    [
      37.713172,
      56.92063
    ],
    [
      37.719915,
      56.921532
    ],
    [
      37.727895,
      56.922171
    ],
    [
      37.728583,
      56.911201
    ],
    [
      37.726430634402945,
      56.90973851541065
    ],
    [
      37.719642,
      56.910159
    ],
    [
      37.717005,
      56.910096
    ],
    [
      37.713302,
      56.906422
    ],
    [
      37.71328500841915,
      56.90633826090114
    ],
    [
      37.703056,
      56.906856
    ],
    [
      37.69414,
      56.907232
    ],
    [
      37.69039,
      56.907378
    ],
    [
      37.68742292589113,
      56.907482408852665
    ],
    [
      37.687112,
      56.907494
    ],
    [
      37.68711197726797,
      56.90749335087426
    ],
    [
      37.684337,
      56.907591
    ],
    [
      37.684269,
      56.903991
    ],
    [
      37.68730707720776,
      56.90388405400553
    ],
    [
      37.694001,
      56.903635
    ],
    [
      37.702904,
      56.903259
    ],
    [
      37.714563,
      56.902674
    ],
    [
      37.718208,
      56.902695
    ],
    [
      37.725707,
      56.896465
    ],
    [
      37.72604947101794,
      56.89571292776069
    ],
    [
      37.717568,
      56.888788
    ],
    [
      37.718658,
      56.885358
    ],
    [
      37.713734713429105,
      56.859239983809154
    ],
    [
      37.712933,
      56.857927
    ],
    [
      37.71351610946343,
      56.85787566887996
    ],
    [
      37.722264,
      56.823322
    ],
    [
      37.714092,
      56.821204
    ],
    [
      37.7128545334936,
      56.820807076780966
    ],
    [
      37.711644,
      56.821636
    ],
    [
      37.708845,
      56.819521
    ],
    [
      37.704272,
      56.816634
    ],
    [
      37.705194805171544,
      56.813728814383296
    ],
    [
      37.704992,
      56.813407
    ],
    [
      37.70538647547406,
      56.81312539578185
    ],
    [
      37.705446,
      56.812938
    ],
    [
      37.70564312496481,
      56.81281606657876
    ],
    [
      37.705632,
      56.812799
    ],
    [
      37.708085,
      56.811199
    ],
    [
      37.70986048884818,
      56.808371554848335
    ],
    [
      37.709495,
      56.798804
    ],
    [
      37.706754,
      56.79349
    ],
    [
      37.707883,
      56.789511
    ],
    [
      37.70472206198682,
      56.77910320409962
    ],
    [
      37.704616,
      56.779047
    ],
    [
      37.701709,
      56.777506
    ],
    [
      37.700617,
      56.776725
    ],
    [
      37.69392180471344,
      56.77249666727277
    ],
    [
      37.69127888042458,
      56.77225835950333
    ],
    [
      37.685194,
      56.774886
    ],
    [
      37.681398,
      56.778214
    ],
    [
      37.675137,
      56.781441
    ],
    [
      37.673943,
      56.782122
    ],
    [
      37.67391025405675,
      56.78206452002779
    ],
    [
      37.673394,
      56.78233
    ],
    [
      37.669949,
      56.783359
    ],
    [
      37.660123,
      56.785058
    ],
    [
      37.659262,
      56.785186
    ],
    [
      37.658732,
      56.781625
    ],
    [
      37.659594,
      56.781497
    ],
    [
      37.66317158462989,
      56.78055519392114
    ],
    [
      37.663418,
      56.780463
    ],
    [
      37.664631,
      56.780171
    ],
    [
      37.669273,
      56.779823
    ],
    [
      37.673355,
      56.778314
    ],
    [
      37.674436,
      56.766769
    ],
    [
      37.666003,
      56.766005
    ],
    [
      37.660749441127685,
      56.765361349294366
    ],
    [
      37.660733,
      56.765442
    ],
    [
      37.651877,
      56.764764
    ],
    [
      37.648125,
      56.764894
    ],
    [
      37.64252143678702,
      56.76411765303911
    ],
    [
      37.642472,
      56.764394
    ],
    [
      37.639273,
      56.763822
    ],
    [
      37.636728,
      56.763315
    ],
    [
      37.632032,
      56.763029
    ],
    [
      37.628279,
      56.763343
    ],
    [
      37.623856,
      56.763609
    ],
    [
      37.619027,
      56.763031
    ],
    [
      37.610558,
      56.771153
    ],
    [
      37.610421,
      56.776328
    ],
    [
      37.610367,
      56.778422
    ],
    [
      37.610312,
      56.780516
    ],
    [
      37.6103104088896,
      56.78051595844279
    ],
    [
      37.610299,
      56.780948
    ],
    [
      37.610092,
      56.785208
    ],
    [
      37.610068,
      56.790443
    ],
    [
      37.609946,
      56.792548
    ],
    [
      37.609825,
      56.794652
    ],
    [
      37.60982101347425,
      56.794651770392086
    ],
    [
      37.609821,
      56.794652
    ],
    [
      37.610849,
      56.802364
    ],
    [
      37.610898,
      56.805964
    ],
    [
      37.609401,
      56.805984
    ],
    [
      37.60855366248582,
      56.8059567341889
    ],
    [
      37.613252870061075,
      56.81687169818768
    ],
    [
      37.613799,
      56.81698
    ],
    [
      37.61538725925544,
      56.817295467522044
    ],
    [
      37.616384,
      56.817493
    ],
    [
      37.616383931210855,
      56.81749334709038
    ],
    [
      37.616488,
      56.817514
    ],
    [
      37.61648798057855,
      56.81751409796734
    ],
    [
      37.61758,
      56.817731
    ],
    [
      37.624844,
      56.819169
    ],
    [
      37.63066421739141,
      56.820306457486566
    ],
    [
      37.634747,
      56.820745
    ],
    [
      37.643159,
      56.822643
    ],
    [
      37.647348,
      56.822943
    ],
    [
      37.660679077748576,
      56.822159815374064
    ],
    [
      37.664339,
      56.821904
    ],
    [
      37.66433900266987,
      56.82190403820785
    ],
    [
      37.665583,
      56.821817
    ],
    [
      37.66558305154624,
      56.8218177376658
    ],
    [
      37.670861,
      56.821449
    ],
    [
      37.679627,
      56.815866
    ],
    [
      37.682235,
      56.813518
    ],
    [
      37.685831,
      56.813266
    ],
    [
      37.6881422631617,
      56.82184381298406
    ],
    [
      37.690267,
      56.823671
    ],
    [
      37.690065,
      56.824435
    ],
    [
      37.689786,
      56.825161
    ],
    [
      37.68785303711495,
      56.82441830503434
    ],
    [
      37.687944,
      56.82492
    ],
    [
      37.68609997508865,
      56.8250837584711
    ],
    [
      37.686114,
      56.825245
    ],
    [
      37.681106627854284,
      56.827720581643575
    ],
    [
      37.67754533548708,
      56.83029852630338
    ],
    [
      37.67575086056459,
      56.83585433753557
    ],
    [
      37.674463,
      56.840473
    ],
    [
      37.67396470192613,
      56.842732913556674
    ],
    [
      37.673835,
      56.843512
    ],
    [
      37.673807822668955,
      56.84344440247996
    ],
    [
      37.673669,
      56.844074
    ],
    [
      37.6734345271624,
      56.843421364942
    ],
    [
      37.672662,
      56.845636
    ],
    [
      37.670377,
      56.834911
    ],
    [
      37.66956215573676,
      56.83055951221482
    ],
    [
      37.66097873885799,
      56.8257484141379
    ],
    [
      37.657737,
      56.825975
    ],
    [
      37.650884,
      56.826453
    ],
    [
      37.646377,
      56.826506
    ],
    [
      37.636851,
      56.832152
    ],
    [
      37.63502515809276,
      56.8403574516167
    ],
    [
      37.635045,
      56.845295
    ],
    [
      37.639712,
      56.852138
    ],
    [
      37.650912,
      56.854838
    ],
    [
      37.654539,
      56.8539
    ],
    [
      37.656963,
      56.856502
    ],
    [
      37.654861,
      56.86162
    ],
    [
      37.654227091143525,
      56.86198268221881
    ],
    [
      37.653568,
      56.863303
    ],
    [
      37.651792,
      56.863847
    ],
    [
      37.650016,
      56.864392
    ],
    [
      37.643368,
      56.864959
    ],
    [
      37.639566,
      56.866365
    ],
    [
      37.640376,
      56.878003
    ],
    [
      37.644018,
      56.881257
    ],
    [
      37.646259,
      56.882412
    ],
    [
      37.645008,
      56.885788
    ],
    [
      37.644258,
      56.88551
    ],
    [
      37.640907,
      56.883707
    ],
    [
      37.637546,
      56.880227
    ],
    [
      37.629902,
      56.876347
    ],
    [
      37.626246,
      56.875711
    ],
    [
      37.620905,
      56.874679
    ],
    [
      37.612757,
      56.87506
    ],
    [
      37.606123271416905,
      56.880157558537064
    ],
    [
      37.604344,
      56.883991
    ],
    [
      37.60441,
      56.884115
    ],
    [
      37.604279218790744,
      56.88423694464107
    ],
    [
      37.604883,
      56.886663
    ],
    [
      37.604027,
      56.89016
    ],
    [
      37.6027100288684,
      56.89011928379265
    ],
    [
      37.602675,
      56.890175
    ],
    [
      37.598686,
      56.892344
    ],
    [
      37.592659088421726,
      56.90148775631327
    ],
    [
      37.592803545390304,
      56.90364478776808
    ],
    [
      37.59356667588176,
      56.906332541294496
    ],
    [
      37.596254,
      56.912083
    ],
    [
      37.59596599349304,
      56.913573447081646
    ],
    [
      37.596908,
      56.91537
    ],
    [
      37.59475204267889,
      56.9199383374731
    ],
    [
      37.59343498162335,
      56.927008513792124
    ],
    [
      37.595155,
      56.930428
    ],
    [
      37.59344160107759,
      56.9395755531948
    ],
    [
      37.59708641030263,
      56.94679540798547
    ],
    [
      37.605254,
      56.952437
    ],
    [
      37.609617,
      56.952522
    ],
    [
      37.614269,
      56.952574
    ],
    [
      37.624264,
      56.953724
    ],
    [
      37.62509425791566,
      56.954017823906575
    ],
    [
      37.625343,
      56.954051
    ],
    [
      37.629014,
      56.955405
    ],
    [
      37.636919,
      56.957233
    ],
    [
      37.642966,
      56.957098
    ],
    [
      37.642974430246376,
      56.95717576250458
    ],
    [
      37.645304,
      56.957059
    ],
    [
      37.649642,
      56.957584
    ],
    [
      37.656751,
      56.957442
    ],
    [
      37.660868,
      56.957311
    ],
    [
      37.671476,
      56.95191
    ],
    [
      37.673058,
      56.949989
    ],
    [
      37.676284,
      56.951587
    ],
    [
      37.675957,
      56.952247
    ],
    [
      37.681215,
      56.962676
    ],
    [
      37.680472,
      56.966199
    ],
    [
      37.672925,
      56.965534
    ],
    [
      37.668022,
      56.964135
    ],
    [
      37.66616801842804,
      56.96333125219842
    ],
    [
      37.655405,
      56.961247
    ],
    [
      37.655373542495006,
      56.96124653974388
    ],
    [
      37.653691,
      56.961461
    ],
    [
      37.64963,
      56.961184
    ],
    [
      37.645532480675314,
      56.96069516008964
    ],
    [
      37.64340608617376,
      56.9607721357572
    ],
    [
      37.640546,
      56.960952
    ],
    [
      37.639472391397945,
      56.960885260088695
    ],
    [
      37.638427,
      56.960911
    ],
    [
      37.63543237790438,
      56.960634116290365
    ],
    [
      37.634964,
      56.960605
    ],
    [
      37.63131925215976,
      56.962418400820546
    ],
    [
      37.626308,
      56.967201
    ],
    [
      37.626159,
      56.967635
    ],
    [
      37.62532294225801,
      56.967348456862
    ],
    [
      37.624483,
      56.969567
    ],
    [
      37.623659,
      56.973015
    ],
    [
      37.624842,
      56.980296
    ],
    [
      37.632534,
      56.983179
    ],
    [
      37.633241,
      56.98309
    ],
    [
      37.633258508395905,
      56.98322991049257
    ],
    [
      37.636846,
      56.983482
    ],
    [
      37.641126,
      56.983323
    ],
    [
      37.646009,
      56.985443
    ],
    [
      37.648792,
      56.98897
    ],
    [
      37.653837,
      56.99725
    ],
    [
      37.6568,
      56.998563
    ],
    [
      37.657598,
      56.998826
    ],
    [
      37.656473,
      57.002246
    ],
    [
      37.655675,
      57.001983
    ],
    [
      37.649688,
      56.999819
    ],
    [
      37.646282,
      56.996643
    ],
    [
      37.640172,
      56.986893
    ],
    [
      37.636479,
      56.987063
    ],
    [
      37.632982,
      56.986751
    ],
    [
      37.629244,
      56.987081
    ],
    [
      37.623753,
      56.986483
    ],
    [
      37.622127,
      56.982661
    ],
    [
      37.619997,
      56.978279
    ],
    [
      37.618417,
      56.971169
    ],
    [
      37.617381,
      56.963087
    ],
    [
      37.607114,
      56.963312
    ],
    [
      37.604698,
      56.966908
    ],
    [
      37.60441076677116,
      56.96685334691461
    ],
    [
      37.601742,
      56.969465
    ],
    [
      37.60097703913404,
      56.969617317083625
    ],
    [
      37.599404,
      56.970913
    ],
    [
      37.596682,
      56.974022
    ],
    [
      37.594478,
      56.969926
    ],
    [
      37.584408,
      56.964231
    ],
    [
      37.579411,
      56.964928
    ],
    [
      37.575445,
      56.965677
    ],
    [
      37.571248,
      56.966307
    ],
    [
      37.565764,
      56.966139
    ],
    [
      37.556468,
      56.966299
    ],
    [
      37.549572,
      56.96823
    ],
    [
      37.538095,
      56.970294
    ],
    [
      37.5335412271732,
      56.98033548643499
    ],
    [
      37.53384262089627,
      56.98400167059458
    ],
    [
      37.5361065156476,
      56.98793660773069
    ],
    [
      37.540528,
      56.992082
    ],
    [
      37.540697528180836,
      56.99217171349286
    ],
    [
      37.543549,
      56.993587
    ],
    [
      37.54530918554798,
      56.99454477157206
    ],
    [
      37.550043,
      56.996724
    ],
    [
      37.551171,
      56.997295
    ],
    [
      37.55116877553384,
      56.9972993915091
    ],
    [
      37.55124,
      56.997331
    ],
    [
      37.555457,
      57.000188
    ],
    [
      37.558868963047225,
      57.00276164529164
    ],
    [
      37.55896,
      57.002825
    ],
    [
      37.55895785585168,
      57.002828081691774
    ],
    [
      37.562307,
      57.005194
    ],
    [
      37.564777,
      57.008962
    ],
    [
      37.566891,
      57.011986
    ],
    [
      37.56821885697611,
      57.01781925240031
    ],
    [
      37.573501,
      57.026277
    ],
    [
      37.578811,
      57.034205
    ],
    [
      37.582596,
      57.035353
    ],
    [
      37.587916,
      57.037741
    ],
    [
      37.592481,
      57.040231
    ],
    [
      37.604006,
      57.03938
    ],
    [
      37.609235,
      57.037203
    ],
    [
      37.614672,
      57.034936
    ],
    [
      37.622256,
      57.029876
    ],
    [
      37.625607,
      57.028138
    ],
    [
      37.630725,
      57.027384
    ],
    [
      37.640005,
      57.025009
    ],
    [
      37.644741,
      57.023266
    ],
    [
      37.649526,
      57.021527
    ],
    [
      37.65283,
      57.023173
    ],
    [
      37.649647,
      57.034645
    ],
    [
      37.645884,
      57.035348
    ],
    [
      37.642335,
      57.035255
    ],
    [
      37.637985,
      57.034484
    ],
    [
      37.631092,
      57.030965
    ],
    [
      37.62529,
      57.03237
    ],
    [
      37.622507,
      57.034677
    ],
    [
      37.61595,
      57.038302
    ],
    [
      37.610649,
      57.040514
    ],
    [
      37.605632,
      57.049948
    ],
    [
      37.602891,
      57.052915
    ],
    [
      37.600927,
      57.049384
    ],
    [
      37.589897,
      57.046995
    ],
    [
      37.586306,
      57.046745
    ],
    [
      37.581785,
      57.03886
    ],
    [
      37.57724,
      57.037444
    ],
    [
      37.571766,
      57.037225
    ],
    [
      37.563318,
      57.044636
    ],
    [
      37.560638,
      57.046948
    ],
    [
      37.559393,
      57.043467
    ],
    [
      37.564062,
      57.036596
    ],
    [
      37.56837187639243,
      57.03306178002648
    ],
    [
      37.56584,
      57.026352
    ],
    [
      37.563861,
      57.019735
    ],
    [
      37.560018,
      57.009242
    ],
    [
      37.557077,
      57.005897
    ],
    [
      37.555067094399604,
      57.00549452014404
    ],
    [
      37.54828091629462,
      57.0044398567212
    ],
    [
      37.540129,
      57.006177
    ],
    [
      37.53586,
      57.006891
    ],
    [
      37.526045,
      57.013123
    ],
    [
      37.522448,
      57.012991
    ],
    [
      37.52110780439534,
      57.01286249866503
    ],
    [
      37.518163,
      57.022966
    ],
    [
      37.523369,
      57.027928
    ],
    [
      37.525276,
      57.031371
    ],
    [
      37.526549,
      57.035218
    ],
    [
      37.522519,
      57.041421
    ],
    [
      37.520855,
      57.046591
    ],
    [
      37.529396,
      57.054796
    ],
    [
      37.537625,
      57.057062
    ],
    [
      37.539023,
      57.05726
    ],
    [
      37.538519,
      57.060825
    ],
    [
      37.53592,
      57.060665
    ],
    [
      37.526374,
      57.058187
    ],
    [
      37.522472,
      57.056483
    ],
    [
      37.518782,
      57.053312
    ],
    [
      37.516788,
      57.050222
    ],
    [
      37.511805,
      57.041451
    ],
    [
      37.507869,
      57.038782
    ],
    [
      37.499048,
      57.035701
    ],
    [
      37.494602,
      57.035189
    ],
    [
      37.489842,
      57.03458
    ],
    [
      37.484801,
      57.034833
    ],
    [
      37.480476,
      57.034746
    ],
    [
      37.477265,
      57.032947
    ],
    [
      37.472931,
      57.0293
    ],
    [
      37.466492,
      57.03927
    ],
    [
      37.46801,
      57.040709
    ],
    [
      37.470215,
      57.04284
    ],
    [
      37.472419,
      57.044971
    ],
    [
      37.469916,
      57.047559
    ],
    [
      37.465508,
      57.043297
    ],
    [
      37.464016,
      57.041884
    ],
    [
      37.456826,
      57.034779
    ],
    [
      37.449309,
      57.035179
    ],
    [
      37.442566,
      57.030642
    ],
    [
      37.438486,
      57.029032
    ],
    [
      37.434711,
      57.025851
    ],
    [
      37.429059,
      57.016079
    ],
    [
      37.418985,
      57.010279
    ],
    [
      37.41524,
      57.010745
    ],
    [
      37.411428,
      57.011481
    ],
    [
      37.406575,
      57.013774
    ],
    [
      37.403163,
      57.014841
    ],
    [
      37.402348,
      57.011171
    ],
    [
      37.406782,
      57.002043
    ],
    [
      37.410399,
      57.002692
    ],
    [
      37.417404,
      57.000636
    ],
    [
      37.421132,
      56.994974
    ],
    [
      37.42719,
      56.992739
    ],
    [
      37.431361,
      56.993695
    ],
    [
      37.440195,
      56.991701
    ],
    [
      37.44271706913216,
      56.98982666078808
    ],
    [
      37.442478,
      56.986534
    ],
    [
      37.44633722124321,
      56.97837288213567
    ],
    [
      37.44609923414303,
      56.97706406587476
    ],
    [
      37.44563642945554,
      56.97601674739436
    ],
    [
      37.44136357110778,
      56.97205317703598
    ],
    [
      37.4358516724395,
      56.9705117551351
    ],
    [
      37.434812,
      56.970314
    ],
    [
      37.436029,
      56.964315
    ],
    [
      37.436722,
      56.953335
    ],
    [
      37.43726298549199,
      56.95279832514939
    ],
    [
      37.43723,
      56.951856
    ],
    [
      37.440738,
      56.949351
    ],
    [
      37.443815,
      56.946643
    ],
    [
      37.44855138675329,
      56.942371511828696
    ],
    [
      37.447265,
      56.941428
    ],
    [
      37.447078,
      56.937833
    ],
    [
      37.444741,
      56.928563
    ],
    [
      37.441026,
      56.925315
    ],
    [
      37.43824083218347,
      56.9235853340758
    ],
    [
      37.438226,
      56.923608
    ],
    [
      37.43725,
      56.92297
    ],
    [
      37.429163,
      56.917739
    ],
    [
      37.424117,
      56.915017
    ],
    [
      37.420408,
      56.911784
    ],
    [
      37.41087,
      56.905177
    ],
    [
      37.40239878248086,
      56.905512621949676
    ],
    [
      37.400805,
      56.906049
    ],
    [
      37.400539664729166,
      56.905089648843294
    ],
    [
      37.398469,
      56.904608
    ],
    [
      37.39853,
      56.899974
    ],
    [
      37.388028,
      56.898365
    ],
    [
      37.385165,
      56.901234
    ],
    [
      37.382523,
      56.910951
    ],
    [
      37.385305,
      56.918862
    ],
    [
      37.381695,
      56.925198
    ],
    [
      37.38067317560731,
      56.93256649776182
    ],
    [
      37.381082,
      56.935841
    ],
    [
      37.38067636801383,
      56.936668531689314
    ],
    [
      37.380923,
      56.938147
    ],
    [
      37.378798,
      56.940576
    ],
    [
      37.378773644203456,
      56.94055469092486
    ],
    [
      37.376914,
      56.944919
    ],
    [
      37.374974,
      56.950617
    ],
    [
      37.37326442011,
      56.95483452291329
    ],
    [
      37.378491,
      56.963891
    ],
    [
      37.380413,
      56.964741
    ],
    [
      37.38038436407952,
      56.96480580968119
    ],
    [
      37.382382,
      56.965771
    ],
    [
      37.386042,
      56.96774
    ],
    [
      37.387582,
      56.968858
    ],
    [
      37.389121,
      56.970085
    ],
    [
      37.39066,
      56.971313
    ],
    [
      37.388416,
      56.974127
    ],
    [
      37.386876,
      56.9729
    ],
    [
      37.380604,
      56.968902
    ],
    [
      37.37824916351218,
      56.968166005405486
    ],
    [
      37.370898,
      56.969535
    ],
    [
      37.362755,
      56.963959
    ],
    [
      37.357648,
      56.972406
    ],
    [
      37.357861,
      56.973537
    ],
    [
      37.356833,
      56.975902
    ],
    [
      37.353813,
      56.980577
    ],
    [
      37.35363775073978,
      56.980554425186305
    ],
    [
      37.353279,
      56.981826
    ],
    [
      37.350813,
      56.984949
    ],
    [
      37.349416,
      56.986408
    ],
    [
      37.346551,
      56.984229
    ],
    [
      37.346504352093824,
      56.97387506882454
    ],
    [
      37.338992,
      56.969386
    ],
    [
      37.331141,
      56.965182
    ],
    [
      37.32306951302566,
      56.958940898846656
    ],
    [
      37.316143,
      56.960289
    ],
    [
      37.310249,
      56.964945
    ],
    [
      37.306649,
      56.964945
    ],
    [
      37.306649,
      56.963676
    ],
    [
      37.298375,
      56.955107
    ],
    [
      37.294696,
      56.957575
    ],
    [
      37.294484256451774,
      56.9575763025657
    ],
    [
      37.292687,
      56.959458
    ],
    [
      37.290436,
      56.962267
    ],
    [
      37.288018,
      56.960329
    ],
    [
      37.285599,
      56.958392
    ],
    [
      37.280671,
      56.953696
    ],
    [
      37.280347126905504,
      56.95338095037122
    ],
    [
      37.280332,
      56.953387
    ],
    [
      37.270913,
      56.949708
    ],
    [
      37.27078,
      56.949417
    ],
    [
      37.270575,
      56.948302
    ],
    [
      37.259938,
      56.942956
    ],
    [
      37.25466,
      56.948294
    ],
    [
      37.251123,
      56.947624
    ],
    [
      37.251314,
      56.946612
    ],
    [
      37.249684,
      56.937367
    ],
    [
      37.245509,
      56.933692
    ],
    [
      37.234234,
      56.937708
    ],
    [
      37.233885,
      56.941291
    ],
    [
      37.229912,
      56.941391
    ],
    [
      37.225636,
      56.941416
    ],
    [
      37.217037,
      56.941977
    ],
    [
      37.211671,
      56.94459
    ],
    [
      37.203539,
      56.950747
    ],
    [
      37.20322546113704,
      56.9507949144224
    ],
    [
      37.200612,
      56.953282
    ],
    [
      37.201548,
      56.956758
    ],
    [
      37.198179,
      56.957665
    ],
    [
      37.192374,
      56.960492
    ],
    [
      37.191808795769475,
      56.96126649846882
    ],
    [
      37.195663,
      56.970704
    ],
    [
      37.198857,
      56.972399
    ],
    [
      37.202916,
      56.974603
    ],
    [
      37.208166,
      56.9773
    ],
    [
      37.21875,
      56.974008
    ],
    [
      37.223295,
      56.972925
    ],
    [
      37.225843,
      56.983515
    ],
    [
      37.23026,
      56.984862
    ],
    [
      37.237138,
      56.986959
    ],
    [
      37.243892,
      56.989023
    ],
    [
      37.247794,
      56.989928
    ],
    [
      37.253222,
      56.992034
    ],
    [
      37.257456,
      56.993615
    ],
    [
      37.263624,
      56.996449
    ],
    [
      37.26722,
      56.997981
    ],
    [
      37.275515,
      57.000278
    ],
    [
      37.282083,
      56.999878
    ],
    [
      37.287251,
      56.999932
    ],
    [
      37.291815,
      56.999628
    ],
    [
      37.30117,
      56.999704
    ],
    [
      37.302335,
      57.000486
    ],
    [
      37.300096,
      57.004557
    ],
    [
      37.295434,
      57.007531
    ],
    [
      37.291128,
      57.013999
    ],
    [
      37.289355,
      57.017132
    ],
    [
      37.28835,
      57.017373
    ],
    [
      37.28475,
      57.017437
    ],
    [
      37.281618,
      57.011478
    ],
    [
      37.274795,
      57.006996
    ],
    [
      37.263161,
      57.007984
    ],
    [
      37.257922,
      57.005955
    ],
    [
      37.25151,
      57.006191
    ],
    [
      37.249045,
      57.003567
    ],
    [
      37.246576,
      56.993315
    ],
    [
      37.24309,
      56.992533
    ],
    [
      37.236088,
      56.990403
    ],
    [
      37.22921,
      56.988305
    ],
    [
      37.224793,
      56.986958
    ],
    [
      37.219355,
      56.985372
    ],
    [
      37.215645,
      56.984035
    ],
    [
      37.206463,
      56.980471
    ],
    [
      37.201355,
      56.977847
    ],
    [
      37.19714,
      56.975563
    ],
    [
      37.193975,
      56.973884
    ],
    [
      37.18498,
      56.980144
    ],
    [
      37.187932,
      56.986082
    ],
    [
      37.190212,
      56.990679
    ],
    [
      37.191816,
      56.993909
    ],
    [
      37.195268,
      56.997124
    ],
    [
      37.200847,
      57.000599
    ],
    [
      37.209327,
      57.002791
    ],
    [
      37.212982,
      57.002104
    ],
    [
      37.218756,
      57.003457
    ],
    [
      37.219918,
      57.012631
    ],
    [
      37.223801,
      57.015088
    ],
    [
      37.227649,
      57.017523
    ],
    [
      37.232729,
      57.020436
    ],
    [
      37.236089,
      57.02193
    ],
    [
      37.240564,
      57.025825
    ],
    [
      37.246835,
      57.028157
    ],
    [
      37.248505,
      57.029084
    ],
    [
      37.251321,
      57.030653
    ],
    [
      37.249568,
      57.033798
    ],
    [
      37.246752,
      57.032229
    ],
    [
      37.245087,
      57.031303
    ],
    [
      37.236621,
      57.026412
    ],
    [
      37.232485,
      57.024286
    ],
    [
      37.228937,
      57.022492
    ],
    [
      37.225797,
      57.02061
    ],
    [
      37.221876,
      57.01813
    ],
    [
      37.217993,
      57.015673
    ],
    [
      37.214329,
      57.01339
    ],
    [
      37.208767,
      57.009894
    ],
    [
      37.201786,
      57.005439
    ],
    [
      37.196069,
      57.001851
    ],
    [
      37.190672,
      56.998515
    ],
    [
      37.18861,
      56.995547
    ],
    [
      37.177903,
      56.997922
    ],
    [
      37.174957,
      57.002505
    ],
    [
      37.172353,
      57.005505
    ],
    [
      37.164837,
      57.010564
    ],
    [
      37.16639,
      57.019735
    ],
    [
      37.171127,
      57.020841
    ],
    [
      37.176853,
      57.02192
    ],
    [
      37.181067,
      57.021925
    ],
    [
      37.184628,
      57.022477
    ],
    [
      37.193641,
      57.025402
    ],
    [
      37.195049,
      57.028715
    ],
    [
      37.1946,
      57.028906
    ],
    [
      37.191371,
      57.0297
    ],
    [
      37.186505,
      57.027977
    ],
    [
      37.178454,
      57.025623
    ],
    [
      37.173956,
      57.025102
    ],
    [
      37.170858,
      57.03616
    ],
    [
      37.169417,
      57.039459
    ],
    [
      37.168381,
      57.039006
    ],
    [
      37.164739,
      57.036904
    ],
    [
      37.16126,
      57.034852
    ],
    [
      37.152127,
      57.033285
    ],
    [
      37.148146,
      57.036527
    ],
    [
      37.14575,
      57.03881
    ],
    [
      37.143353,
      57.041093
    ],
    [
      37.14087,
      57.038486
    ],
    [
      37.143267,
      57.036203
    ],
    [
      37.145663,
      57.03392
    ],
    [
      37.148983,
      57.030954
    ],
    [
      37.146796,
      57.019921
    ],
    [
      37.141326,
      57.0196
    ],
    [
      37.137618,
      57.00963
    ],
    [
      37.136093,
      57.006457
    ],
    [
      37.127025,
      56.999258
    ],
    [
      37.124404,
      56.99963
    ],
    [
      37.121783,
      57.000001
    ],
    [
      37.121278,
      56.996437
    ],
    [
      37.123899,
      56.996066
    ],
    [
      37.126823,
      56.995528
    ],
    [
      37.131625,
      56.995193
    ],
    [
      37.140025,
      56.994668
    ],
    [
      37.144672,
      56.993309
    ],
    [
      37.149765,
      56.992698
    ],
    [
      37.149103,
      56.981636
    ],
    [
      37.143697,
      56.980034
    ],
    [
      37.140128,
      56.978897
    ],
    [
      37.133871,
      56.97598
    ],
    [
      37.127399,
      56.979188
    ],
    [
      37.119171,
      56.982075
    ],
    [
      37.111204,
      56.984869
    ],
    [
      37.103141,
      56.987694
    ],
    [
      37.099703,
      56.995001
    ],
    [
      37.101158,
      57.001149
    ],
    [
      37.101676,
      57.00516
    ],
    [
      37.102289,
      57.008861
    ],
    [
      37.098319,
      57.007873
    ],
    [
      37.088448,
      57.005785
    ],
    [
      37.083974,
      57.001642
    ],
    [
      37.081279,
      56.998847
    ],
    [
      37.07797,
      56.993249
    ],
    [
      37.069139,
      56.995478
    ],
    [
      37.070419,
      57.004749
    ],
    [
      37.069727,
      57.008282
    ],
    [
      37.068995,
      57.008139
    ],
    [
      37.0646,
      57.0071
    ],
    [
      37.056086,
      57.007775
    ],
    [
      37.051879,
      57.01002
    ],
    [
      37.050184,
      57.006843
    ],
    [
      37.054391,
      57.004599
    ],
    [
      37.057986,
      56.993936
    ],
    [
      37.049185,
      56.989595
    ],
    [
      37.045429,
      56.988688
    ],
    [
      37.040841,
      56.98838
    ],
    [
      37.039117,
      56.98522
    ],
    [
      37.039452,
      56.985037
    ],
    [
      37.04282,
      56.983999
    ],
    [
      37.046394,
      56.985219
    ],
    [
      37.051784,
      56.985811
    ],
    [
      37.055285,
      56.985835
    ],
    [
      37.059319,
      56.986724
    ],
    [
      37.063689,
      56.977204
    ],
    [
      37.060538,
      56.973132
    ],
    [
      37.057054,
      56.971046
    ],
    [
      37.051819,
      56.969736
    ],
    [
      37.048259,
      56.969102
    ],
    [
      37.048889,
      56.965558
    ],
    [
      37.050669,
      56.965875
    ],
    [
      37.052449,
      56.966191
    ],
    [
      37.056686,
      56.966969
    ],
    [
      37.060177,
      56.968474
    ],
    [
      37.064786,
      56.971767
    ],
    [
      37.068661,
      56.976186
    ],
    [
      37.076429,
      56.980289
    ],
    [
      37.07957,
      56.982466
    ],
    [
      37.089724,
      56.985415
    ],
    [
      37.093969,
      56.985164
    ],
    [
      37.098549,
      56.984389
    ],
    [
      37.110013,
      56.981472
    ],
    [
      37.11798,
      56.978678
    ],
    [
      37.126207,
      56.975791
    ],
    [
      37.12934,
      56.966978
    ],
    [
      37.123086,
      56.956915
    ],
    [
      37.127329,
      56.957232
    ],
    [
      37.132213,
      56.956235
    ],
    [
      37.13328,
      56.945315
    ],
    [
      37.123409,
      56.942755
    ],
    [
      37.116237,
      56.941205
    ],
    [
      37.109177,
      56.939709
    ],
    [
      37.10201,
      56.938186
    ],
    [
      37.09704,
      56.93611
    ],
    [
      37.093406,
      56.93447
    ],
    [
      37.083957,
      56.931802
    ],
    [
      37.078924,
      56.929832
    ],
    [
      37.074012,
      56.928094
    ],
    [
      37.06463,
      56.924959
    ],
    [
      37.057024,
      56.919533
    ],
    [
      37.053823,
      56.917975
    ],
    [
      37.044885,
      56.919686
    ],
    [
      37.041348,
      56.920359
    ],
    [
      37.036149,
      56.920768
    ],
    [
      37.035037,
      56.917344
    ],
    [
      37.032339,
      56.906063
    ],
    [
      37.034286,
      56.903035
    ],
    [
      37.038298,
      56.905615
    ],
    [
      37.042311,
      56.908194
    ],
    [
      37.046093,
      56.910767
    ],
    [
      37.055821,
      56.914786
    ],
    [
      37.065448,
      56.921385
    ],
    [
      37.072139,
      56.923626
    ],
    [
      37.083306,
      56.919744
    ],
    [
      37.081942,
      56.912269
    ],
    [
      37.08714,
      56.912856
    ],
    [
      37.094067,
      56.916223
    ],
    [
      37.097701,
      56.916973
    ],
    [
      37.10444,
      56.917742
    ],
    [
      37.109436,
      56.920279
    ],
    [
      37.103962,
      56.922033
    ],
    [
      37.100397,
      56.92507
    ],
    [
      37.102972,
      56.934717
    ],
    [
      37.109924,
      56.936187
    ],
    [
      37.116983,
      56.937683
    ],
    [
      37.124169,
      56.939236
    ],
    [
      37.132851,
      56.941207
    ],
    [
      37.136496,
      56.942927
    ],
    [
      37.140729,
      56.94441
    ],
    [
      37.145066,
      56.945376
    ],
    [
      37.14951922288427,
      56.94728460289602
    ],
    [
      37.149534,
      56.947251
    ],
    [
      37.153064896902265,
      56.94880424021824
    ],
    [
      37.153139,
      56.948836
    ],
    [
      37.1577437073694,
      56.95077712731315
    ],
    [
      37.164483,
      56.951343
    ],
    [
      37.168674,
      56.951447
    ],
    [
      37.16933790962029,
      56.95158794467469
    ],
    [
      37.16961155708714,
      56.9516017510058
    ],
    [
      37.169642,
      56.951495
    ],
    [
      37.170102,
      56.951541
    ],
    [
      37.170885,
      56.951666
    ],
    [
      37.176173,
      56.953039
    ],
    [
      37.183369584961305,
      56.95338995269284
    ],
    [
      37.191022,
      56.950511
    ],
    [
      37.19509611056139,
      56.94855423012939
    ],
    [
      37.195096,
      56.948554
    ],
    [
      37.197136,
      56.947574
    ],
    [
      37.199175,
      56.946595
    ],
    [
      37.202347,
      56.945072
    ],
    [
      37.20623,
      56.943208
    ],
    [
      37.20780436568401,
      56.94244597903234
    ],
    [
      37.207679,
      56.941256
    ],
    [
      37.207837,
      56.941069
    ],
    [
      37.215459,
      56.938741
    ],
    [
      37.21918628796987,
      56.93687638587244
    ],
    [
      37.222579,
      56.925866
    ],
    [
      37.21659254755963,
      56.92195112261764
    ],
    [
      37.212032,
      56.920196
    ],
    [
      37.206972,
      56.920469
    ],
    [
      37.201912,
      56.920741
    ],
    [
      37.20190933034333,
      56.92069154254589
    ],
    [
      37.201236,
      56.920721
    ],
    [
      37.195164,
      56.919434
    ],
    [
      37.188808,
      56.919913
    ],
    [
      37.185532,
      56.921217
    ],
    [
      37.181297,
      56.922935
    ],
    [
      37.177842,
      56.924121
    ],
    [
      37.172414,
      56.926525
    ],
    [
      37.17213316177367,
      56.92616664991741
    ],
    [
      37.170258,
      56.926896
    ],
    [
      37.166882,
      56.925425
    ],
    [
      37.162873,
      56.92477
    ],
    [
      37.160775,
      56.924705
    ],
    [
      37.159959,
      56.924621
    ],
    [
      37.15998468565819,
      56.924372404481176
    ],
    [
      37.156088,
      56.923836
    ],
    [
      37.151375,
      56.922188
    ],
    [
      37.151217,
      56.922143
    ],
    [
      37.150784,
      56.918977
    ],
    [
      37.151094,
      56.91883
    ],
    [
      37.152199,
      56.91868
    ],
    [
      37.152357,
      56.918724
    ],
    [
      37.157227,
      56.920421
    ],
    [
      37.163057,
      56.921175
    ],
    [
      37.167453,
      56.92187
    ],
    [
      37.176655,
      56.920722
    ],
    [
      37.180129,
      56.91953
    ],
    [
      37.184177,
      56.917882
    ],
    [
      37.187477,
      56.916568
    ],
    [
      37.19485,
      56.915847
    ],
    [
      37.20171808452733,
      56.91714856593411
    ],
    [
      37.201718,
      56.917147
    ],
    [
      37.20665687583053,
      56.91688053496013
    ],
    [
      37.206665,
      56.91688
    ],
    [
      37.211838,
      56.916601
    ],
    [
      37.216599,
      56.91759
    ],
    [
      37.216796688317,
      56.917636033689874
    ],
    [
      37.218198,
      56.915603
    ],
    [
      37.219147,
      56.916257
    ],
    [
      37.222371,
      56.919135
    ],
    [
      37.225401,
      56.923631
    ],
    [
      37.235781,
      56.928751
    ],
    [
      37.240164,
      56.926631
    ],
    [
      37.24639,
      56.923573
    ],
    [
      37.255061,
      56.919341
    ],
    [
      37.258635,
      56.917535
    ],
    [
      37.26288000148905,
      56.915422002990155
    ],
    [
      37.26288,
      56.915422
    ],
    [
      37.268793,
      56.912478
    ],
    [
      37.274706,
      56.909535
    ],
    [
      37.281225,
      56.906322
    ],
    [
      37.286542,
      56.903732
    ],
    [
      37.291897,
      56.901123
    ],
    [
      37.299729,
      56.897319
    ],
    [
      37.302978,
      56.895749
    ],
    [
      37.306296,
      56.894118
    ],
    [
      37.31112,
      56.892439
    ],
    [
      37.316405,
      56.886764
    ],
    [
      37.316403,
      56.878576
    ],
    [
      37.31552825082362,
      56.878015041343964
    ],
    [
      37.314072,
      56.877284
    ],
    [
      37.30776217686541,
      56.873061532695274
    ],
    [
      37.301707,
      56.874794
    ],
    [
      37.297593,
      56.874932
    ],
    [
      37.29399,
      56.874967
    ],
    [
      37.290391,
      56.874913
    ],
    [
      37.285929,
      56.874812
    ],
    [
      37.280068,
      56.875018
    ],
    [
      37.27105,
      56.871499
    ],
    [
      37.27004053629841,
      56.871041209140856
    ],
    [
      37.269682,
      56.870994
    ],
    [
      37.266047,
      56.870405
    ],
    [
      37.26619278886647,
      56.868509004690885
    ],
    [
      37.2646,
      56.866325
    ],
    [
      37.26635330030411,
      56.866421541222664
    ],
    [
      37.266441,
      56.865281
    ],
    [
      37.272934194485764,
      56.8671595832786
    ],
    [
      37.277363,
      56.867915
    ],
    [
      37.286383332908756,
      56.86816130610896
    ],
    [
      37.28818259922873,
      56.86801940503993
    ],
    [
      37.291139,
      56.857363
    ],
    [
      37.288657,
      56.854698
    ],
    [
      37.28840452734573,
      56.85143802382512
    ],
    [
      37.288224,
      56.851445
    ],
    [
      37.2881986070419,
      56.85078828167477
    ],
    [
      37.28804270150076,
      56.84676605791467
    ],
    [
      37.288042,
      56.846757
    ],
    [
      37.28804222877053,
      56.84675386189688
    ],
    [
      37.287997,
      56.845587
    ],
    [
      37.287773,
      56.839807
    ],
    [
      37.28861142180078,
      56.83894609743885
    ],
    [
      37.288668,
      56.83817
    ],
    [
      37.28354168560994,
      56.830622971779746
    ],
    [
      37.279431,
      56.830341
    ],
    [
      37.2781661333096,
      56.83003644652005
    ],
    [
      37.278166,
      56.830037
    ],
    [
      37.276386,
      56.829608
    ],
    [
      37.274605,
      56.829179
    ],
    [
      37.26791,
      56.82758
    ],
    [
      37.264102,
      56.826506
    ],
    [
      37.26385788057678,
      56.82630962054887
    ],
    [
      37.263454,
      56.826183
    ],
    [
      37.263104614830766,
      56.825840021919355
    ],
    [
      37.262798,
      56.825729
    ],
    [
      37.26146092063307,
      56.824381409872636
    ],
    [
      37.261039,
      56.824042
    ],
    [
      37.260681554547105,
      56.8235959172018
    ],
    [
      37.25613752362585,
      56.819016165723035
    ],
    [
      37.255995,
      56.819026
    ],
    [
      37.25441,
      56.819039
    ],
    [
      37.254195791814354,
      56.81852167350037
    ],
    [
      37.246635,
      56.819085
    ],
    [
      37.24373291404829,
      56.819066853664175
    ],
    [
      37.238789,
      56.828344
    ],
    [
      37.235813,
      56.83037
    ],
    [
      37.23556425885381,
      56.830041048846525
    ],
    [
      37.235563,
      56.830042
    ],
    [
      37.23342920818263,
      56.8272175216908
    ],
    [
      37.233329,
      56.827085
    ],
    [
      37.227789861914964,
      56.8231999774933
    ],
    [
      37.220271644326964,
      56.82187853753802
    ],
    [
      37.2186691136083,
      56.82253950929198
    ],
    [
      37.213758,
      56.827567
    ],
    [
      37.21149175216555,
      56.826116832898215
    ],
    [
      37.210117,
      56.826334
    ],
    [
      37.201411,
      56.820708
    ],
    [
      37.198861,
      56.820299
    ],
    [
      37.198840331552105,
      56.820232467604974
    ],
    [
      37.197362,
      56.819959
    ],
    [
      37.193997933648525,
      56.818947519803615
    ],
    [
      37.182824,
      56.818313
    ],
    [
      37.177665,
      56.818832
    ],
    [
      37.156601,
      56.82177
    ],
    [
      37.155698024263764,
      56.82189583437149
    ],
    [
      37.155661,
      56.821901
    ],
    [
      37.15566099916518,
      56.8219009940118
    ],
    [
      37.154206226961925,
      56.82210372409468
    ],
    [
      37.137452,
      56.824441
    ],
    [
      37.137451659969194,
      56.824438560261825
    ],
    [
      37.135633,
      56.824692
    ],
    [
      37.128335,
      56.831325
    ],
    [
      37.12776843420271,
      56.83874166963677
    ],
    [
      37.12999291320377,
      56.84326793895598
    ],
    [
      37.13419,
      56.845639
    ],
    [
      37.137678,
      56.846341
    ],
    [
      37.141613,
      56.846649
    ],
    [
      37.145221,
      56.847516
    ],
    [
      37.149541,
      56.850967
    ],
    [
      37.152763,
      56.854787
    ],
    [
      37.156666,
      56.85791
    ],
    [
      37.15813307642958,
      56.85919247202297
    ],
    [
      37.162375,
      56.862563
    ],
    [
      37.170466,
      56.87021
    ],
    [
      37.174253,
      56.873519
    ],
    [
      37.176356,
      56.876372
    ],
    [
      37.17907124254178,
      56.87939786766657
    ],
    [
      37.183833589772156,
      56.88195702596498
    ],
    [
      37.192759,
      56.88298
    ],
    [
      37.197393,
      56.881686
    ],
    [
      37.202573,
      56.883173
    ],
    [
      37.201644,
      56.889495
    ],
    [
      37.19979,
      56.89053
    ],
    [
      37.197347,
      56.89077
    ],
    [
      37.194903,
      56.891009
    ],
    [
      37.18453,
      56.889466
    ],
    [
      37.181117,
      56.887028
    ],
    [
      37.173044,
      56.884729
    ],
    [
      37.168531,
      56.88443
    ],
    [
      37.163934,
      56.884124
    ],
    [
      37.159095,
      56.883576
    ],
    [
      37.154332,
      56.883108
    ],
    [
      37.142955,
      56.884516
    ],
    [
      37.14234755887781,
      56.88461427391404
    ],
    [
      37.141968,
      56.884785
    ],
    [
      37.14153999884928,
      56.88474492375305
    ],
    [
      37.141385,
      56.88477
    ],
    [
      37.14069932605888,
      56.88466620665078
    ],
    [
      37.136767,
      56.884298
    ],
    [
      37.13670140779986,
      56.88428684339735
    ],
    [
      37.134155,
      56.884146
    ],
    [
      37.130682,
      56.883263
    ],
    [
      37.126388,
      56.881446
    ],
    [
      37.12201,
      56.879347
    ],
    [
      37.112676,
      56.878887
    ],
    [
      37.108913,
      56.881233
    ],
    [
      37.106464,
      56.883297
    ],
    [
      37.105963,
      56.883648
    ],
    [
      37.10593419516828,
      56.883606864189474
    ],
    [
      37.105668,
      56.883819
    ],
    [
      37.098577,
      56.886372
    ],
    [
      37.092049,
      56.888673
    ],
    [
      37.088799692554545,
      56.88802245642065
    ],
    [
      37.087742,
      56.888201
    ],
    [
      37.084442258244856,
      56.88715005485196
    ],
    [
      37.084427,
      56.887147
    ],
    [
      37.084239903720935,
      56.88708560632208
    ],
    [
      37.08307,
      56.886713
    ],
    [
      37.08247168325198,
      56.8865053833561
    ],
    [
      37.079036,
      56.885378
    ],
    [
      37.079046044145024,
      56.88531668239304
    ],
    [
      37.078168,
      56.885012
    ],
    [
      37.075244,
      56.881201
    ],
    [
      37.076393,
      56.877507
    ],
    [
      37.077226,
      56.875913
    ],
    [
      37.080417,
      56.87758
    ],
    [
      37.08872899174559,
      56.883712581386874
    ],
    [
      37.097381,
      56.882977
    ],
    [
      37.106587,
      56.878486
    ],
    [
      37.109511,
      56.876115
    ],
    [
      37.111553261024284,
      56.8755122548689
    ],
    [
      37.111709,
      56.875419
    ],
    [
      37.11224979845659,
      56.87530668146719
    ],
    [
      37.114651,
      56.874598
    ],
    [
      37.118965,
      56.873912
    ],
    [
      37.123566,
      56.876101
    ],
    [
      37.12936572891636,
      56.8788031357018
    ],
    [
      37.12985199141806,
      56.87900942135292
    ],
    [
      37.133162192063025,
      56.880075825207754
    ],
    [
      37.136955,
      56.880703
    ],
    [
      37.14050357885658,
      56.88057387734858
    ],
    [
      37.144494,
      56.879711
    ],
    [
      37.14567389965666,
      56.87969131643632
    ],
    [
      37.14602,
      56.879606
    ],
    [
      37.150848,
      56.879605
    ],
    [
      37.156136,
      56.879528
    ],
    [
      37.159656,
      56.871031
    ],
    [
      37.155935,
      56.867219
    ],
    [
      37.152146,
      56.859318
    ],
    [
      37.149509,
      56.856327
    ],
    [
      37.146784,
      56.853282
    ],
    [
      37.138383,
      56.850156
    ],
    [
      37.134453,
      56.84952
    ],
    [
      37.130716,
      56.848321
    ],
    [
      37.127188,
      56.846428
    ],
    [
      37.115960760786336,
      56.84788737668821
    ],
    [
      37.114625,
      56.848962
    ],
    [
      37.111589,
      56.851546
    ],
    [
      37.102617,
      56.852846
    ],
    [
      37.099142,
      56.860221
    ],
    [
      37.10021,
      56.863704
    ],
    [
      37.099533,
      56.864815
    ],
    [
      37.096459,
      56.862941
    ],
    [
      37.09232,
      56.858849
    ],
    [
      37.09314893577908,
      56.857050956735094
    ],
    [
      37.092228,
      56.856257
    ],
    [
      37.093611,
      56.854654
    ],
    [
      37.094993,
      56.853051
    ],
    [
      37.099294,
      56.849701
    ],
    [
      37.10346,
      56.848791
    ],
    [
      37.108934,
      56.847994
    ],
    [
      37.112836,
      56.845606
    ],
    [
      37.115061,
      56.84264
    ],
    [
      37.107992,
      56.833211
    ],
    [
      37.107132023891396,
      56.83306761352247
    ],
    [
      37.10639367327916,
      56.833272126478825
    ],
    [
      37.106129,
      56.833512
    ],
    [
      37.101802,
      56.834234
    ],
    [
      37.097409,
      56.835036
    ],
    [
      37.093069,
      56.835652
    ],
    [
      37.08974,
      56.838167
    ],
    [
      37.086759,
      56.840367
    ],
    [
      37.083026,
      56.843568
    ],
    [
      37.07971,
      56.845417
    ],
    [
      37.075204,
      56.849896
    ],
    [
      37.069794,
      56.852442
    ],
    [
      37.067449,
      56.853111
    ],
    [
      37.067422931729915,
      56.85301965551515
    ],
    [
      37.065283,
      56.853541
    ],
    [
      37.060841,
      56.85403
    ],
    [
      37.053951,
      56.853413
    ],
    [
      37.053244,
      56.849883
    ],
    [
      37.055024,
      56.849527
    ],
    [
      37.056908,
      56.849478
    ],
    [
      37.062007,
      56.850292
    ],
    [
      37.066523848631036,
      56.8496310700494
    ],
    [
      37.06667343664575,
      56.84958839440682
    ],
    [
      37.071633,
      56.847771
    ],
    [
      37.069328,
      56.836131
    ],
    [
      37.064669,
      56.837375
    ],
    [
      37.059902,
      56.837564
    ],
    [
      37.0506,
      56.837231
    ],
    [
      37.05173770534249,
      56.836009782244076
    ],
    [
      37.051423,
      56.836024
    ],
    [
      37.05225739613445,
      56.83526653185762
    ],
    [
      37.052046,
      56.835203
    ],
    [
      37.05308833563303,
      56.83382301632005
    ],
    [
      37.052396,
      56.8322
    ],
    [
      37.05263,
      56.831683
    ],
    [
      37.05514097903338,
      56.83110545174347
    ],
    [
      37.057933,
      56.827409
    ],
    [
      37.059832,
      56.824351
    ],
    [
      37.060225,
      56.824153
    ],
    [
      37.060761,
      56.824201
    ],
    [
      37.062812,
      56.825832
    ],
    [
      37.072892,
      56.831561
    ],
    [
      37.078967,
      56.829088
    ],
    [
      37.0800665808501,
      56.82885060791844
    ],
    [
      37.081173,
      56.82842
    ],
    [
      37.08225765081481,
      56.82837757070638
    ],
    [
      37.083988,
      56.828004
    ],
    [
      37.08601951844485,
      56.82823041423533
    ],
    [
      37.086388,
      56.828216
    ],
    [
      37.08831818075882,
      56.828428224800675
    ],
    [
      37.09736,
      56.822597
    ],
    [
      37.105204,
      56.824238
    ],
    [
      37.11675636807913,
      56.82622789115186
    ],
    [
      37.11677425072836,
      56.82622463887054
    ],
    [
      37.126873,
      56.824257
    ],
    [
      37.130688,
      56.822706
    ],
    [
      37.133544260529035,
      56.82166729876859
    ],
    [
      37.1335,
      56.821441
    ],
    [
      37.134942,
      56.821159
    ],
    [
      37.136955409095854,
      56.82087793528335
    ],
    [
      37.136955,
      56.820875
    ],
    [
      37.157061,
      56.818071
    ],
    [
      37.177168,
      56.815266
    ],
    [
      37.182462,
      56.814731
    ],
    [
      37.191011,
      56.81441
    ],
    [
      37.19349754379297,
      56.81103325850471
    ],
    [
      37.184921,
      56.807593
    ],
    [
      37.183693,
      56.807234
    ],
    [
      37.18369636871597,
      56.80722248438932
    ],
    [
      37.181748,
      56.806633
    ],
    [
      37.182463,
      56.803104
    ],
    [
      37.184164,
      56.803449
    ],
    [
      37.185932,
      56.804138
    ],
    [
      37.190331,
      56.80432
    ],
    [
      37.201584,
      56.801564
    ],
    [
      37.199211,
      56.791365
    ],
    [
      37.19911398155216,
      56.79132846020312
    ],
    [
      37.198644,
      56.791208
    ],
    [
      37.19752359924499,
      56.790729478795264
    ],
    [
      37.195908,
      56.790121
    ],
    [
      37.19561247521686,
      56.78991324106226
    ],
    [
      37.195153,
      56.789717
    ],
    [
      37.19077958080179,
      56.7874761902916
    ],
    [
      37.183018,
      56.78585
    ],
    [
      37.18189123683196,
      56.77516868083799
    ],
    [
      37.177448,
      56.774759
    ],
    [
      37.168322,
      56.771023
    ],
    [
      37.164927,
      56.769826
    ],
    [
      37.163775971086345,
      56.767395623764166
    ],
    [
      37.16064024571302,
      56.76736608367358
    ],
    [
      37.15175479651364,
      56.769650776905046
    ],
    [
      37.151103,
      56.771155
    ],
    [
      37.145261,
      56.773015
    ],
    [
      37.141705,
      56.772455
    ],
    [
      37.13796513525641,
      56.770717242684455
    ],
    [
      37.135247,
      56.773954
    ],
    [
      37.13427001343498,
      56.774161090802274
    ],
    [
      37.13406851671761,
      56.77436706656953
    ],
    [
      37.133796,
      56.775402
    ],
    [
      37.130462,
      56.776224
    ],
    [
      37.1301456112109,
      56.775941708029485
    ],
    [
      37.126521,
      56.776835
    ],
    [
      37.122943,
      56.777239
    ],
    [
      37.12076,
      56.776895
    ],
    [
      37.11992341601896,
      56.77614242868736
    ],
    [
      37.112343,
      56.782774
    ],
    [
      37.112406,
      56.783458
    ],
    [
      37.110611,
      56.784738
    ],
    [
      37.109362,
      56.78478
    ],
    [
      37.107294325921764,
      56.78387947304654
    ],
    [
      37.105522,
      56.783777
    ],
    [
      37.09736,
      56.784066
    ],
    [
      37.09776012423828,
      56.783343174619375
    ],
    [
      37.097757,
      56.783343
    ],
    [
      37.09813130657961,
      56.78267263284386
    ],
    [
      37.099267,
      56.780621
    ],
    [
      37.09984012622005,
      56.77961790675662
    ],
    [
      37.09991974889671,
      56.779475103907636
    ],
    [
      37.098468,
      56.778384
    ],
    [
      37.098887,
      56.777678
    ],
    [
      37.102540710536815,
      56.77390449567509
    ],
    [
      37.102353,
      56.771709
    ],
    [
      37.10489997632447,
      56.76416760394646
    ],
    [
      37.104961,
      56.763712
    ],
    [
      37.10572192533543,
      56.76220886629002
    ],
    [
      37.105542,
      56.758805
    ],
    [
      37.105701,
      56.758447
    ],
    [
      37.11438287658766,
      56.75151354317999
    ],
    [
      37.115135,
      56.750299
    ],
    [
      37.118581911482984,
      56.74193774736457
    ],
    [
      37.11881,
      56.739934
    ],
    [
      37.11961316749187,
      56.738032548667334
    ],
    [
      37.118935,
      56.736482
    ],
    [
      37.119975,
      56.73493
    ],
    [
      37.124745,
      56.726133
    ],
    [
      37.127203,
      56.715536
    ],
    [
      37.123736,
      56.710625
    ],
    [
      37.119984,
      56.707652
    ],
    [
      37.115803,
      56.705814
    ],
    [
      37.112509,
      56.704441
    ],
    [
      37.107797,
      56.702492
    ],
    [
      37.098601,
      56.708663
    ],
    [
      37.093694,
      56.715227
    ],
    [
      37.094511,
      56.721997
    ],
    [
      37.091964,
      56.724542
    ],
    [
      37.089806,
      56.722383
    ],
    [
      37.087648,
      56.720223
    ],
    [
      37.087088,
      56.718794
    ],
    [
      37.090834,
      56.713041
    ],
    [
      37.095718,
      56.706508
    ],
    [
      37.096495,
      56.697809
    ],
    [
      37.089906,
      56.695077
    ],
    [
      37.08410358893821,
      56.695826427380176
    ],
    [
      37.07496388746464,
      56.698857537989035
    ],
    [
      37.072504,
      56.700671
    ],
    [
      37.070092,
      56.705022
    ],
    [
      37.066428,
      56.709448
    ],
    [
      37.063255,
      56.707746
    ],
    [
      37.064091,
      56.706188
    ],
    [
      37.06452995916973,
      56.70571695158806
    ],
    [
      37.064495,
      56.70568
    ],
    [
      37.06455921168466,
      56.70561922365874
    ],
    [
      37.064559,
      56.705619
    ],
    [
      37.065085494324684,
      56.705120805133994
    ],
    [
      37.067333,
      56.702709
    ],
    [
      37.063386,
      56.692007
    ],
    [
      37.056489,
      56.694986
    ],
    [
      37.055479,
      56.695412
    ],
    [
      37.05547407983145,
      56.695407162539276
    ],
    [
      37.054807,
      56.695769
    ],
    [
      37.054572,
      56.695863
    ],
    [
      37.0545068175433,
      56.69570019000726
    ],
    [
      37.052406,
      56.696574
    ],
    [
      37.0491,
      56.698108
    ],
    [
      37.04797234576705,
      56.698440194733955
    ],
    [
      37.047196,
      56.698769
    ],
    [
      37.04439143291416,
      56.700073381640046
    ],
    [
      37.037944,
      56.70504
    ],
    [
      37.03598818887356,
      56.70417728720305
    ],
    [
      37.035264,
      56.70469
    ],
    [
      37.025392,
      56.711466
    ],
    [
      37.033527,
      56.71743
    ],
    [
      37.038986,
      56.719645
    ],
    [
      37.03850690402333,
      56.719770417304545
    ],
    [
      37.040404,
      56.720662
    ],
    [
      37.041049,
      56.721179
    ],
    [
      37.0388,
      56.723989
    ],
    [
      37.034894655726745,
      56.726012953309755
    ],
    [
      37.032094,
      56.730542
    ],
    [
      37.030273,
      56.732031
    ],
    [
      37.02566694240055,
      56.73556845223638
    ],
    [
      37.026392,
      56.736967
    ],
    [
      37.029598,
      56.738605
    ],
    [
      37.025867,
      56.742412
    ],
    [
      37.025132,
      56.742972
    ],
    [
      37.022949,
      56.74011
    ],
    [
      37.022872,
      56.739947
    ],
    [
      37.02164720758222,
      56.73676932195192
    ],
    [
      37.020793,
      56.736421
    ],
    [
      37.019802,
      56.731982
    ],
    [
      37.01929637480579,
      56.73038308131385
    ],
    [
      37.01902,
      56.730365
    ],
    [
      37.009987,
      56.728467
    ],
    [
      37.00874409275383,
      56.727704974081846
    ],
    [
      37.006708,
      56.733713
    ],
    [
      37.003147,
      56.734658
    ],
    [
      36.998615,
      56.735003
    ],
    [
      36.996305,
      56.733646
    ],
    [
      36.992786121415826,
      56.7289203037504
    ],
    [
      36.984749,
      56.728867
    ],
    [
      36.983585,
      56.728466
    ],
    [
      36.976267,
      56.726808
    ],
    [
      36.97562,
      56.725938
    ],
    [
      36.963911,
      56.72766
    ],
    [
      36.96483,
      56.732044
    ],
    [
      36.964877,
      56.735644
    ],
    [
      36.96186471876545,
      56.73727453596686
    ],
    [
      36.962069,
      56.743479
    ],
    [
      36.958514,
      56.748488
    ],
    [
      36.954682458115165,
      56.751410353945445
    ],
    [
      36.950246,
      56.761196
    ],
    [
      36.95028265751038,
      56.7622891384149
    ],
    [
      36.951249,
      56.763904
    ],
    [
      36.950365211491075,
      56.76475092391751
    ],
    [
      36.950374,
      56.765013
    ],
    [
      36.9495383681583,
      56.76554327795639
    ],
    [
      36.948492,
      56.766546
    ],
    [
      36.945619,
      56.768646
    ],
    [
      36.94278768235106,
      56.77028021839624
    ],
    [
      36.935400905929605,
      56.77595226840609
    ],
    [
      36.931921,
      56.781133
    ],
    [
      36.931702,
      56.781663
    ],
    [
      36.927510646837405,
      56.78147771757412
    ],
    [
      36.927239,
      56.781661
    ],
    [
      36.926619055645844,
      56.781438304007075
    ],
    [
      36.925368,
      56.781383
    ],
    [
      36.922948,
      56.780297
    ],
    [
      36.92275601338876,
      56.78005062457203
    ],
    [
      36.919912,
      56.779029
    ],
    [
      36.920232251820444,
      56.77898154166015
    ],
    [
      36.918077,
      56.778671
    ],
    [
      36.92154973144122,
      56.77830938367611
    ],
    [
      36.921414,
      56.777871
    ],
    [
      36.921312,
      56.777091
    ],
    [
      36.921486,
      56.777002
    ],
    [
      36.92376,
      56.77675
    ],
    [
      36.931457,
      56.774156
    ],
    [
      36.93438,
      56.771086
    ],
    [
      36.934516533770584,
      56.77103592750945
    ],
    [
      36.934742,
      56.770806
    ],
    [
      36.9423639717102,
      56.76640145560977
    ],
    [
      36.945319,
      56.758663
    ],
    [
      36.945846,
      56.754318
    ],
    [
      36.946307073156326,
      56.75383350095242
    ],
    [
      36.946258,
      56.753783
    ],
    [
      36.9475546454133,
      56.75252254289294
    ],
    [
      36.950491,
      56.749437
    ],
    [
      36.950504072730354,
      56.74942671726433
    ],
    [
      36.950501,
      56.749424
    ],
    [
      36.951007,
      56.748853
    ],
    [
      36.95228291316831,
      56.74794508705076
    ],
    [
      36.952619,
      56.74762
    ],
    [
      36.95620373952688,
      56.73756893837541
    ],
    [
      36.951891,
      56.730226
    ],
    [
      36.947103,
      56.72892
    ],
    [
      36.942092,
      56.728117
    ],
    [
      36.93918,
      56.727468
    ],
    [
      36.939963,
      56.723954
    ],
    [
      36.942875,
      56.724603
    ],
    [
      36.94767,
      56.725365
    ],
    [
      36.954791,
      56.720688
    ],
    [
      36.95475170941695,
      56.71931357092516
    ],
    [
      36.954729,
      56.719314
    ],
    [
      36.948188,
      56.718053
    ],
    [
      36.947339,
      56.714555
    ],
    [
      36.949666,
      56.71399
    ],
    [
      36.951994,
      56.713425
    ],
    [
      36.953957,
      56.712772
    ],
    [
      36.95805558681655,
      56.71158086267885
    ],
    [
      36.95875078902152,
      56.71072380944999
    ],
    [
      36.959660952747605,
      56.708842738836424
    ],
    [
      36.962061,
      56.699851
    ],
    [
      36.958404978641845,
      56.691543983839104
    ],
    [
      36.956239,
      56.689282
    ],
    [
      36.95623539210646,
      56.68924182016701
    ],
    [
      36.953799,
      56.689373
    ],
    [
      36.946981,
      56.689088
    ],
    [
      36.94325,
      56.688812
    ],
    [
      36.93631524195206,
      56.68718926270657
    ],
    [
      36.931089,
      56.688942
    ],
    [
      36.930908,
      56.693296
    ],
    [
      36.927407,
      56.694135
    ],
    [
      36.926912,
      56.692277
    ],
    [
      36.92221165035209,
      56.68361860480959
    ],
    [
      36.919192,
      56.682101
    ],
    [
      36.91919214345594,
      56.682100444347334
    ],
    [
      36.915223,
      56.681076
    ],
    [
      36.916122,
      56.677591
    ],
    [
      36.913681,
      56.668696
    ],
    [
      36.905315,
      56.671587
    ],
    [
      36.905032,
      56.671564
    ],
    [
      36.904261479910204,
      56.6713774937429
    ],
    [
      36.89553,
      56.673263
    ],
    [
      36.891406,
      56.674395
    ],
    [
      36.887201,
      56.675547
    ],
    [
      36.88384326032346,
      56.674637299282516
    ],
    [
      36.880271195376096,
      56.67605382818253
    ],
    [
      36.880429,
      56.676908
    ],
    [
      36.879235,
      56.677128
    ],
    [
      36.87038022007264,
      56.680545617241684
    ],
    [
      36.867789,
      56.681834
    ],
    [
      36.862878,
      56.684321
    ],
    [
      36.86284884320454,
      56.68432955606839
    ],
    [
      36.862851,
      56.684334
    ],
    [
      36.862184,
      56.684658
    ],
    [
      36.859,
      56.685459
    ],
    [
      36.855507,
      56.685972
    ],
    [
      36.85201231157339,
      56.686489311187046
    ],
    [
      36.85180990293188,
      56.686590060401244
    ],
    [
      36.844646,
      56.69202
    ],
    [
      36.842339,
      56.692759
    ],
    [
      36.840966343655275,
      56.69282655856445
    ],
    [
      36.838592,
      56.703894
    ],
    [
      36.844133,
      56.70551
    ],
    [
      36.849694,
      56.707132
    ],
    [
      36.852451,
      56.70803
    ],
    [
      36.856961,
      56.709616
    ],
    [
      36.85696087999263,
      56.70961634104186
    ],
    [
      36.857085,
      56.70966
    ],
    [
      36.85708405591138,
      56.70966268294974
    ],
    [
      36.857377,
      56.709763
    ],
    [
      36.860972,
      56.711054
    ],
    [
      36.865758,
      56.71404
    ],
    [
      36.867372,
      56.717751
    ],
    [
      36.872523,
      56.725465
    ],
    [
      36.87239109605793,
      56.72584669142421
    ],
    [
      36.875254,
      56.726505
    ],
    [
      36.880988,
      56.729099
    ],
    [
      36.881166,
      56.732694
    ],
    [
      36.88026,
      56.732739
    ],
    [
      36.878131,
      56.732391
    ],
    [
      36.874226,
      56.729955
    ],
    [
      36.871262981779225,
      56.72924128703075
    ],
    [
      36.86878,
      56.740273
    ],
    [
      36.873342,
      56.741894
    ],
    [
      36.877979,
      56.74326
    ],
    [
      36.882791,
      56.746099
    ],
    [
      36.887033,
      56.747457
    ],
    [
      36.8947,
      56.754021
    ],
    [
      36.901109,
      56.759722
    ],
    [
      36.898306,
      56.764385
    ],
    [
      36.89333,
      56.758002
    ],
    [
      36.886721,
      56.75292
    ],
    [
      36.878921,
      56.748978
    ],
    [
      36.870778,
      56.745252
    ],
    [
      36.867097,
      56.743455
    ],
    [
      36.863686,
      56.741721
    ],
    [
      36.860559,
      56.739629
    ],
    [
      36.85860388552409,
      56.73820938678543
    ],
    [
      36.84877110187538,
      56.7436598923251
    ],
    [
      36.848365,
      56.743954
    ],
    [
      36.84827561057272,
      56.74393455290815
    ],
    [
      36.848273,
      56.743936
    ],
    [
      36.845033,
      56.743851
    ],
    [
      36.8399991632606,
      56.749388220413344
    ],
    [
      36.837766,
      56.753118
    ],
    [
      36.838454,
      56.75682
    ],
    [
      36.83842858570635,
      56.75682430253253
    ],
    [
      36.838908,
      56.759506
    ],
    [
      36.839865,
      56.764544
    ],
    [
      36.840539,
      56.76785
    ],
    [
      36.837012,
      56.768569
    ],
    [
      36.836338,
      56.765263
    ],
    [
      36.835371,
      56.760178
    ],
    [
      36.831592747642595,
      56.75562529383858
    ],
    [
      36.826016,
      56.752601
    ],
    [
      36.82085341494725,
      56.7520533949436
    ],
    [
      36.8198183991933,
      56.75205926289162
    ],
    [
      36.8184307928573,
      56.752279698402454
    ],
    [
      36.815152,
      56.75459
    ],
    [
      36.814811,
      56.754603
    ],
    [
      36.807224,
      56.755342
    ],
    [
      36.80592,
      56.755601
    ],
    [
      36.795682,
      56.761731
    ],
    [
      36.79556,
      56.761813
    ],
    [
      36.794108,
      56.76207
    ],
    [
      36.79123955214397,
      56.76252712429479
    ],
    [
      36.791052255935334,
      56.76264694822111
    ],
    [
      36.790897,
      56.762892
    ],
    [
      36.787146,
      56.765146
    ],
    [
      36.777036,
      56.769009
    ],
    [
      36.776636,
      56.772586
    ],
    [
      36.776121,
      56.772529
    ],
    [
      36.773977,
      56.772095
    ],
    [
      36.76916306631166,
      56.770104075570096
    ],
    [
      36.761243,
      56.768712
    ],
    [
      36.74937,
      56.769252
    ],
    [
      36.748859,
      56.769348
    ],
    [
      36.74830774474447,
      56.767540370880106
    ],
    [
      36.747184,
      56.767862
    ],
    [
      36.741173,
      56.764477
    ],
    [
      36.741829886374106,
      56.76366552269703
    ],
    [
      36.74106,
      56.763293
    ],
    [
      36.743478,
      56.76063
    ],
    [
      36.744872,
      56.757074
    ],
    [
      36.74947391431779,
      56.75425142391769
    ],
    [
      36.749694,
      56.753981
    ],
    [
      36.752918,
      56.752139
    ],
    [
      36.75708002439147,
      56.749637998577
    ],
    [
      36.757077,
      56.749609
    ],
    [
      36.757754,
      56.749233
    ],
    [
      36.75779496003291,
      56.74922280367036
    ],
    [
      36.75888,
      56.748574
    ],
    [
      36.75895281448645,
      56.74856873256906
    ],
    [
      36.759904,
      56.748006
    ],
    [
      36.76188659107134,
      56.74820425910713
    ],
    [
      36.763615,
      56.747774
    ],
    [
      36.76645694730325,
      56.74626660744689
    ],
    [
      36.77065436239358,
      56.74316315154927
    ],
    [
      36.77193345478335,
      56.741578781207515
    ],
    [
      36.770799,
      56.740476
    ],
    [
      36.771396,
      56.736926
    ],
    [
      36.77133342156781,
      56.73242901758838
    ],
    [
      36.769367439816804,
      56.72722050388616
    ],
    [
      36.767907,
      56.723886
    ],
    [
      36.76435369592164,
      56.715189492529014
    ],
    [
      36.758509,
      56.712956
    ],
    [
      36.758323,
      56.71278
    ],
    [
      36.75839,
      56.710266
    ],
    [
      36.758399,
      56.710227
    ],
    [
      36.760588,
      56.708625
    ],
    [
      36.76113715946111,
      56.70809646266052
    ],
    [
      36.76203334243139,
      56.70581313831409
    ],
    [
      36.755143,
      56.69843
    ],
    [
      36.75468237353532,
      56.6983719576909
    ],
    [
      36.753998,
      56.698347
    ],
    [
      36.75033585549941,
      56.69783353593908
    ],
    [
      36.744894,
      56.697574
    ],
    [
      36.74052,
      56.69805
    ],
    [
      36.731545,
      56.699638
    ],
    [
      36.728085,
      56.701318
    ],
    [
      36.725275,
      56.702424
    ],
    [
      36.72405914263642,
      56.702902121845824
    ],
    [
      36.723264,
      56.703215
    ],
    [
      36.722429,
      56.713293
    ],
    [
      36.727011,
      56.723802
    ],
    [
      36.732085,
      56.724185
    ],
    [
      36.731814,
      56.727775
    ],
    [
      36.721666,
      56.727009
    ],
    [
      36.719574194944606,
      56.72884348179781
    ],
    [
      36.720679,
      56.729158
    ],
    [
      36.729462,
      56.736728
    ],
    [
      36.73167,
      56.738046
    ],
    [
      36.7316615701989,
      56.73806011512199
    ],
    [
      36.732733,
      56.738709
    ],
    [
      36.736204,
      56.740903
    ],
    [
      36.738566,
      56.743916
    ],
    [
      36.736264,
      56.747399
    ],
    [
      36.73283,
      56.749053
    ],
    [
      36.727134,
      56.753658
    ],
    [
      36.724499,
      56.756144
    ],
    [
      36.720491,
      56.757946
    ],
    [
      36.716035,
      56.767812
    ],
    [
      36.715641,
      56.769753
    ],
    [
      36.715368,
      56.770554
    ],
    [
      36.71384670949751,
      56.77095337391305
    ],
    [
      36.711257,
      56.774671
    ],
    [
      36.708507,
      56.776994
    ],
    [
      36.6989693686638,
      56.77357014518482
    ],
    [
      36.693825,
      56.773605
    ],
    [
      36.693824981085356,
      56.77360486373814
    ],
    [
      36.693644,
      56.77363
    ],
    [
      36.689932,
      56.773552
    ],
    [
      36.684556,
      56.772371
    ],
    [
      36.680188,
      56.771367
    ],
    [
      36.676099,
      56.768917
    ],
    [
      36.671592,
      56.765627
    ],
    [
      36.67376,
      56.762753
    ],
    [
      36.678188,
      56.765985
    ],
    [
      36.681406,
      56.767979
    ],
    [
      36.685352,
      56.76886
    ],
    [
      36.691602,
      56.770235
    ],
    [
      36.698164,
      56.762041
    ],
    [
      36.697587,
      56.756132
    ],
    [
      36.699714,
      56.752809
    ],
    [
      36.703223,
      56.751478
    ],
    [
      36.707244,
      56.752393
    ],
    [
      36.712353,
      56.752641
    ],
    [
      36.717914,
      56.754312
    ],
    [
      36.721835,
      56.753314
    ],
    [
      36.725026,
      56.750739
    ],
    [
      36.724819900093095,
      56.74301962166854
    ],
    [
      36.71966127769052,
      56.73863898925284
    ],
    [
      36.715322,
      56.738155
    ],
    [
      36.712809350456006,
      56.737847700737184
    ],
    [
      36.712793,
      56.737982
    ],
    [
      36.711832,
      56.737865
    ],
    [
      36.705881,
      56.736929
    ],
    [
      36.702369,
      56.732601
    ],
    [
      36.698243,
      56.723918
    ],
    [
      36.69721027951575,
      56.72345737351889
    ],
    [
      36.69721,
      56.723458
    ],
    [
      36.694656630368016,
      56.72231836403972
    ],
    [
      36.693694,
      56.721889
    ],
    [
      36.689179,
      56.719874
    ],
    [
      36.679813,
      56.719136
    ],
    [
      36.675767,
      56.715763
    ],
    [
      36.667507,
      56.70777
    ],
    [
      36.666481,
      56.707313
    ],
    [
      36.66649859173465,
      56.70727357149042
    ],
    [
      36.665793,
      56.70694
    ],
    [
      36.657357,
      56.709659
    ],
    [
      36.653763,
      56.709447
    ],
    [
      36.64622854428455,
      56.71085770300528
    ],
    [
      36.643978,
      56.71403
    ],
    [
      36.642533,
      56.718237
    ],
    [
      36.642044,
      56.719021
    ],
    [
      36.640783,
      56.720438
    ],
    [
      36.639523,
      56.721855
    ],
    [
      36.63940721098918,
      56.72175203817327
    ],
    [
      36.639029,
      56.722391
    ],
    [
      36.635038746041324,
      56.72478579613761
    ],
    [
      36.634543,
      56.725219
    ],
    [
      36.628235,
      56.732749
    ],
    [
      36.627565,
      56.73272
    ],
    [
      36.62097,
      56.732251
    ],
    [
      36.62094394002411,
      56.732186583600345
    ],
    [
      36.610847,
      56.735414
    ],
    [
      36.605624,
      56.736727
    ],
    [
      36.601898,
      56.737672
    ],
    [
      36.59572958433738,
      56.74307084715535
    ],
    [
      36.59516851829277,
      56.74453515405204
    ],
    [
      36.595444,
      56.754037
    ],
    [
      36.598044,
      56.759553
    ],
    [
      36.600279,
      56.766772
    ],
    [
      36.60008384657624,
      56.76686987993349
    ],
    [
      36.60127,
      56.770073
    ],
    [
      36.604016,
      56.777523
    ],
    [
      36.605099,
      56.78119
    ],
    [
      36.609798,
      56.788274
    ],
    [
      36.608783,
      56.795257
    ],
    [
      36.610072,
      56.804397
    ],
    [
      36.610322,
      56.808038
    ],
    [
      36.611252,
      56.815558
    ],
    [
      36.614986,
      56.822595
    ],
    [
      36.615529,
      56.827194
    ],
    [
      36.607983,
      56.827484
    ],
    [
      36.602444,
      56.829477
    ],
    [
      36.598887,
      56.830293
    ],
    [
      36.594973826127436,
      56.82994104442843
    ],
    [
      36.591108,
      56.830123
    ],
    [
      36.582061,
      56.836428
    ],
    [
      36.578558,
      56.840882
    ],
    [
      36.575136,
      56.842201
    ],
    [
      36.566511,
      56.84358
    ],
    [
      36.56,
      56.843614
    ],
    [
      36.551619,
      56.847884
    ],
    [
      36.552802,
      56.854271
    ],
    [
      36.551109,
      56.857448
    ],
    [
      36.53931,
      56.85596
    ],
    [
      36.538101,
      56.855412
    ],
    [
      36.537501,
      56.854677
    ],
    [
      36.536423,
      56.851608
    ],
    [
      36.529006,
      56.842479
    ],
    [
      36.52341,
      56.840507
    ],
    [
      36.517813,
      56.838535
    ],
    [
      36.51901,
      56.835139
    ],
    [
      36.524606,
      56.837112
    ],
    [
      36.530203,
      56.839084
    ],
    [
      36.54157,
      56.838676
    ],
    [
      36.54168320318963,
      56.83862978147192
    ],
    [
      36.542375,
      56.83563
    ],
    [
      36.544831,
      56.836196
    ],
    [
      36.54662436400305,
      56.836612407398405
    ],
    [
      36.546667,
      56.836595
    ],
    [
      36.551169,
      56.837403
    ],
    [
      36.551386799077136,
      56.837454252859395
    ],
    [
      36.551389,
      56.837445
    ],
    [
      36.555427,
      56.838405
    ],
    [
      36.559561,
      56.839633
    ],
    [
      36.567281,
      56.839724
    ],
    [
      36.572166,
      56.838727
    ],
    [
      36.579567,
      56.831867
    ],
    [
      36.583846,
      56.828243
    ],
    [
      36.588084,
      56.826911
    ],
    [
      36.591689,
      56.82644
    ],
    [
      36.592790644602786,
      56.8264253922532
    ],
    [
      36.592808,
      56.826134
    ],
    [
      36.594845,
      56.826255
    ],
    [
      36.595686,
      56.826387
    ],
    [
      36.600792,
      56.826278
    ],
    [
      36.605605,
      56.824205
    ],
    [
      36.60668,
      56.814621
    ],
    [
      36.6068,
      56.809364
    ],
    [
      36.605561,
      56.800946
    ],
    [
      36.603995,
      56.796156
    ],
    [
      36.602723,
      56.786427
    ],
    [
      36.601726,
      56.78287
    ],
    [
      36.599229,
      56.775603
    ],
    [
      36.597822,
      56.771108
    ],
    [
      36.596176522022304,
      56.76662242303407
    ],
    [
      36.596091,
      56.766452
    ],
    [
      36.595128,
      56.764532
    ],
    [
      36.593068,
      56.758405
    ],
    [
      36.591951,
      56.754906
    ],
    [
      36.590004,
      56.751723
    ],
    [
      36.579506,
      56.746085
    ],
    [
      36.57112,
      56.748915
    ],
    [
      36.566047,
      56.750595
    ],
    [
      36.561119,
      56.7523
    ],
    [
      36.5565,
      56.753803
    ],
    [
      36.552401,
      56.755211
    ],
    [
      36.551612,
      56.755381
    ],
    [
      36.55158239727377,
      56.75524316720061
    ],
    [
      36.550702,
      56.755501
    ],
    [
      36.546853,
      56.75575
    ],
    [
      36.54593298886932,
      56.7556622409485
    ],
    [
      36.545319,
      56.755683
    ],
    [
      36.543013019562395,
      56.75538370764794
    ],
    [
      36.542754,
      56.755359
    ],
    [
      36.53850605205624,
      56.75484236810268
    ],
    [
      36.533935,
      56.754383
    ],
    [
      36.530361,
      56.754033
    ],
    [
      36.522856,
      56.75184
    ],
    [
      36.515786,
      56.752402
    ],
    [
      36.511999,
      56.753137
    ],
    [
      36.508154,
      56.753882
    ],
    [
      36.50548038010039,
      56.761905010063366
    ],
    [
      36.505727,
      56.765973
    ],
    [
      36.511944,
      56.773069
    ],
    [
      36.518046,
      56.780046
    ],
    [
      36.520802,
      56.783367
    ],
    [
      36.520008,
      56.786934
    ],
    [
      36.518396,
      56.791339
    ],
    [
      36.52187534905367,
      56.80036231184179
    ],
    [
      36.52187725900988,
      56.80036546155329
    ],
    [
      36.528869,
      56.808482
    ],
    [
      36.533292,
      56.810887
    ],
    [
      36.53328826319942,
      56.81089385152359
    ],
    [
      36.534846,
      56.811744
    ],
    [
      36.538051,
      56.813497
    ],
    [
      36.541262,
      56.815253
    ],
    [
      36.544273,
      56.81764
    ],
    [
      36.544433,
      56.818235
    ],
    [
      36.54489,
      56.820957
    ],
    [
      36.545347,
      56.82368
    ],
    [
      36.541797,
      56.824276
    ],
    [
      36.536324,
      56.816656
    ],
    [
      36.533118,
      56.814903
    ],
    [
      36.52715,
      56.811645
    ],
    [
      36.523302,
      56.809484
    ],
    [
      36.52127217145011,
      56.8062148223133
    ],
    [
      36.521229,
      56.806241
    ],
    [
      36.517947,
      56.800827
    ],
    [
      36.514664,
      56.795413
    ],
    [
      36.514062,
      56.792978
    ],
    [
      36.515873,
      56.787812
    ],
    [
      36.515569,
      56.782659
    ],
    [
      36.509236,
      56.775442
    ],
    [
      36.500481,
      56.767054
    ],
    [
      36.495924,
      56.764277
    ],
    [
      36.493013,
      56.76148
    ],
    [
      36.491075,
      56.757034
    ],
    [
      36.492225656861216,
      56.755785510379134
    ],
    [
      36.491904,
      56.754847
    ],
    [
      36.49363888959683,
      56.754252119790024
    ],
    [
      36.493639,
      56.754252
    ],
    [
      36.49364357473555,
      56.754250513292234
    ],
    [
      36.494345,
      56.75401
    ],
    [
      36.496787,
      56.753173
    ],
    [
      36.499267,
      56.752423
    ],
    [
      36.504034,
      56.751017
    ],
    [
      36.507583,
      56.750328
    ],
    [
      36.511313,
      56.749603
    ],
    [
      36.5151,
      56.748868
    ],
    [
      36.524743,
      56.748261
    ],
    [
      36.534266,
      56.750798
    ],
    [
      36.540389,
      56.751413
    ],
    [
      36.545771,
      56.752112
    ],
    [
      36.555291,
      56.750412
    ],
    [
      36.560004,
      56.748877
    ],
    [
      36.56487,
      56.747193
    ],
    [
      36.569988,
      56.745498
    ],
    [
      36.575083,
      56.74381
    ],
    [
      36.58159,
      56.741549
    ],
    [
      36.585604,
      56.740174
    ],
    [
      36.590534,
      56.738168
    ],
    [
      36.595238,
      56.737868
    ],
    [
      36.597118,
      56.726552
    ],
    [
      36.600663,
      56.726191
    ],
    [
      36.604193,
      56.726653
    ],
    [
      36.607965,
      56.727946
    ],
    [
      36.61921971386786,
      56.728858907972544
    ],
    [
      36.623344,
      56.727056
    ],
    [
      36.631192591131075,
      56.72291710751714
    ],
    [
      36.63321885134784,
      56.721719551329144
    ],
    [
      36.63635476300912,
      56.71914388374656
    ],
    [
      36.63582936853665,
      56.71812862634985
    ],
    [
      36.635513,
      56.717708
    ],
    [
      36.631354156982766,
      56.7094831717431
    ],
    [
      36.629394,
      56.709836
    ],
    [
      36.627117431121526,
      56.70727052203748
    ],
    [
      36.626601,
      56.70701
    ],
    [
      36.62700264543249,
      56.704855441651
    ],
    [
      36.620994,
      56.698882
    ],
    [
      36.617334572247195,
      56.69830505842799
    ],
    [
      36.60506261834523,
      56.69650739621516
    ],
    [
      36.5979,
      56.699356
    ],
    [
      36.59398588135012,
      56.697072764120904
    ],
    [
      36.592329,
      56.697289
    ],
    [
      36.590915,
      56.695635
    ],
    [
      36.59091079453684,
      56.69550534477711
    ],
    [
      36.588287,
      56.694743
    ],
    [
      36.584695,
      56.693943
    ],
    [
      36.579891,
      56.692785
    ],
    [
      36.572848,
      56.691283
    ],
    [
      36.57105634436323,
      56.690872290289974
    ],
    [
      36.569443,
      56.690523
    ],
    [
      36.56843850610111,
      56.69027219082249
    ],
    [
      36.567033,
      56.68995
    ],
    [
      36.557762,
      56.687559
    ],
    [
      36.553955,
      56.686489
    ],
    [
      36.549491,
      56.685294
    ],
    [
      36.544916,
      56.684058
    ],
    [
      36.539124,
      56.682523
    ],
    [
      36.534966,
      56.6814
    ],
    [
      36.52976,
      56.680006
    ],
    [
      36.524525,
      56.678604
    ],
    [
      36.515456,
      56.676121
    ],
    [
      36.509754,
      56.685021
    ],
    [
      36.509954,
      56.686322
    ],
    [
      36.510175,
      56.687404
    ],
    [
      36.506647,
      56.688123
    ],
    [
      36.501441,
      56.688531
    ],
    [
      36.500967,
      56.686196
    ],
    [
      36.492053,
      56.679425
    ],
    [
      36.486575,
      56.686081
    ],
    [
      36.483038,
      56.686755
    ],
    [
      36.482851,
      56.685773
    ],
    [
      36.482215,
      56.68186
    ],
    [
      36.478847953677196,
      56.679174623264046
    ],
    [
      36.474078,
      56.680383
    ],
    [
      36.471076,
      56.682309
    ],
    [
      36.467492,
      56.684131
    ],
    [
      36.463798,
      56.686577
    ],
    [
      36.45451367507028,
      56.680558216288915
    ],
    [
      36.450039,
      56.68447
    ],
    [
      36.44877156486331,
      56.68567466122662
    ],
    [
      36.448447,
      56.686117
    ],
    [
      36.445375,
      56.688903
    ],
    [
      36.438307,
      56.691519
    ],
    [
      36.435034,
      56.69319
    ],
    [
      36.431281,
      56.694917
    ],
    [
      36.427621,
      56.695461
    ],
    [
      36.425971,
      56.692262
    ],
    [
      36.426700534026594,
      56.692103093572236
    ],
    [
      36.426672,
      56.691999
    ],
    [
      36.42896095164205,
      56.691610731495295
    ],
    [
      36.42963,
      56.691465
    ],
    [
      36.43254898187312,
      56.69036497276095
    ],
    [
      36.434948,
      56.689145
    ],
    [
      36.43669289010549,
      56.68826149768383
    ],
    [
      36.436769,
      56.68822
    ],
    [
      36.4368019657895,
      56.688206268635795
    ],
    [
      36.438183,
      56.687507
    ],
    [
      36.44392961351928,
      56.68269989993931
    ],
    [
      36.444987,
      56.68147
    ],
    [
      36.447611,
      56.678784
    ],
    [
      36.451034,
      56.677338
    ],
    [
      36.451051532209526,
      56.677330173038335
    ],
    [
      36.451071,
      56.677316
    ],
    [
      36.45399209397531,
      56.67549118711968
    ],
    [
      36.456345,
      56.673266
    ],
    [
      36.460164,
      56.671945
    ],
    [
      36.466953,
      56.669841
    ],
    [
      36.47228,
      56.670178
    ],
    [
      36.47254602858012,
      56.67008973916828
    ],
    [
      36.473416,
      56.668718
    ],
    [
      36.484876,
      56.665999
    ],
    [
      36.489085,
      56.665746
    ],
    [
      36.494209,
      56.665409
    ],
    [
      36.498618,
      56.666565
    ],
    [
      36.508189,
      56.665997
    ],
    [
      36.515051,
      56.666143
    ],
    [
      36.517908,
      56.669283
    ],
    [
      36.525513,
      56.675142
    ],
    [
      36.530691,
      56.676528
    ],
    [
      36.535897,
      56.677922
    ],
    [
      36.540063,
      56.679048
    ],
    [
      36.545795,
      56.680567
    ],
    [
      36.550432,
      56.681819
    ],
    [
      36.554816,
      56.682994
    ],
    [
      36.558735,
      56.684093
    ],
    [
      36.568361,
      56.685477
    ],
    [
      36.57177,
      56.687186
    ],
    [
      36.57875912981385,
      56.68882339916041
    ],
    [
      36.588239,
      56.68678
    ],
    [
      36.590048129222524,
      56.685683273994634
    ],
    [
      36.591861,
      56.683788
    ],
    [
      36.596213,
      56.684577
    ],
    [
      36.59911493355271,
      56.68845211841694
    ],
    [
      36.605845,
      56.692852
    ],
    [
      36.614634872671544,
      56.694235807094444
    ],
    [
      36.614635,
      56.694235
    ],
    [
      36.621554,
      56.695325
    ],
    [
      36.628473,
      56.696416
    ],
    [
      36.633519,
      56.69705
    ],
    [
      36.644338,
      56.692764
    ],
    [
      36.644424,
      56.68863
    ],
    [
      36.644453,
      56.684966
    ],
    [
      36.63714544925841,
      56.67707538536785
    ],
    [
      36.627525,
      56.675561
    ],
    [
      36.62526713834781,
      56.675635960201916
    ],
    [
      36.625007,
      56.67568
    ],
    [
      36.62486233116143,
      56.67564939965619
    ],
    [
      36.623037,
      56.67571
    ],
    [
      36.620432687492816,
      56.674712441700464
    ],
    [
      36.619816,
      56.674582
    ],
    [
      36.616141,
      56.67332
    ],
    [
      36.61623125288842,
      56.67226363637723
    ],
    [
      36.615119,
      56.671492
    ],
    [
      36.616480834456965,
      56.66934241253013
    ],
    [
      36.61652,
      56.668884
    ],
    [
      36.61696298983948,
      56.66858135441217
    ],
    [
      36.617235,
      56.668152
    ],
    [
      36.620434,
      56.66621
    ],
    [
      36.620222,
      56.656351
    ],
    [
      36.613075,
      56.65365
    ],
    [
      36.606163,
      56.653032
    ],
    [
      36.602509,
      56.651862
    ],
    [
      36.60053,
      56.648574
    ],
    [
      36.591897,
      56.644318
    ],
    [
      36.588059,
      56.645644
    ],
    [
      36.586931,
      56.641965
    ],
    [
      36.58104,
      56.633477
    ],
    [
      36.576841,
      56.633054
    ],
    [
      36.573509,
      56.630696
    ],
    [
      36.567001,
      56.623093
    ],
    [
      36.567080921936444,
      56.62269229011412
    ],
    [
      36.566963,
      56.622565
    ],
    [
      36.565482286924436,
      56.61243819518531
    ],
    [
      36.55978,
      56.611705
    ],
    [
      36.55464678170984,
      56.61016978121415
    ],
    [
      36.546392,
      56.615056
    ],
    [
      36.54389742886564,
      56.61370075046706
    ],
    [
      36.54156,
      56.615326
    ],
    [
      36.540838,
      56.608459
    ],
    [
      36.536349,
      56.600439
    ],
    [
      36.526915,
      56.597016
    ],
    [
      36.52108533634649,
      56.59533884115406
    ],
    [
      36.519208,
      56.595577
    ],
    [
      36.51893814809224,
      56.59533473324375
    ],
    [
      36.51850551754946,
      56.59535279001768
    ],
    [
      36.51713,
      56.595521
    ],
    [
      36.51706507664295,
      56.59541290994271
    ],
    [
      36.517015,
      56.595415
    ],
    [
      36.51471355761672,
      56.591541994332076
    ],
    [
      36.510932,
      56.588147
    ],
    [
      36.507509016377625,
      56.58618838170611
    ],
    [
      36.50615597991921,
      56.58681101443318
    ],
    [
      36.498734,
      56.591126
    ],
    [
      36.492594147664704,
      56.591939930324614
    ],
    [
      36.48607,
      56.597931
    ],
    [
      36.48218,
      56.60358
    ],
    [
      36.47744,
      56.610539
    ],
    [
      36.47273,
      56.61738
    ],
    [
      36.465642,
      56.627601
    ],
    [
      36.45964,
      56.636278
    ],
    [
      36.464427,
      56.639931
    ],
    [
      36.47145451084409,
      56.64329912611402
    ],
    [
      36.47566919783742,
      56.644031918486355
    ],
    [
      36.476779,
      56.643963
    ],
    [
      36.48129736371101,
      56.64452040226661
    ],
    [
      36.48478420955442,
      56.64253115016543
    ],
    [
      36.486005,
      56.641685
    ],
    [
      36.486235,
      56.641624
    ],
    [
      36.486253437634254,
      56.64169295278701
    ],
    [
      36.486497,
      56.641554
    ],
    [
      36.487261,
      56.64135
    ],
    [
      36.488112,
      56.641908
    ],
    [
      36.48831663060921,
      56.64223096332573
    ],
    [
      36.489598261282445,
      56.641766496395704
    ],
    [
      36.4928909447861,
      56.64012027438653
    ],
    [
      36.498475,
      56.634724
    ],
    [
      36.498763,
      56.634564
    ],
    [
      36.4988572568418,
      56.63456716921555
    ],
    [
      36.499632,
      56.633843
    ],
    [
      36.499812,
      56.629291
    ],
    [
      36.501446,
      56.629157
    ],
    [
      36.503647,
      56.630163
    ],
    [
      36.503023,
      56.634952
    ],
    [
      36.502216149157825,
      56.63777692792898
    ],
    [
      36.503617,
      56.640702
    ],
    [
      36.503538,
      56.640969
    ],
    [
      36.501392,
      56.646341
    ],
    [
      36.500794,
      56.646612
    ],
    [
      36.49958531157216,
      56.6462835291167
    ],
    [
      36.499157,
      56.647444
    ],
    [
      36.498634,
      56.647586
    ],
    [
      36.497803,
      56.647756
    ],
    [
      36.49167611399525,
      56.6445714170462
    ],
    [
      36.48785249038457,
      56.64549347661588
    ],
    [
      36.486714,
      56.647735
    ],
    [
      36.485795,
      56.647938
    ],
    [
      36.483942,
      56.647983
    ],
    [
      36.4801095967722,
      56.64750858379456
    ],
    [
      36.478338,
      56.647988
    ],
    [
      36.473365,
      56.650305
    ],
    [
      36.4715835358977,
      56.649955893364414
    ],
    [
      36.469994,
      56.650419
    ],
    [
      36.46616361643941,
      56.64991546115933
    ],
    [
      36.46491922199339,
      56.650070732400536
    ],
    [
      36.457575,
      56.655921
    ],
    [
      36.452531,
      56.657635
    ],
    [
      36.4525049177878,
      56.657641904114996
    ],
    [
      36.441913,
      56.662402
    ],
    [
      36.44170658210111,
      56.6625649850655
    ],
    [
      36.4417,
      56.66307
    ],
    [
      36.44116651861837,
      56.66330436866788
    ],
    [
      36.4408,
      56.663874
    ],
    [
      36.439390100314995,
      56.66439405094434
    ],
    [
      36.439147,
      56.664586
    ],
    [
      36.438755307616034,
      56.6647208154925
    ],
    [
      36.438695,
      56.664842
    ],
    [
      36.435001,
      56.666013
    ],
    [
      36.42574,
      56.670397
    ],
    [
      36.425367,
      56.670556
    ],
    [
      36.4227095895879,
      56.67151216615299
    ],
    [
      36.422708,
      56.671513
    ],
    [
      36.42270595488858,
      56.67151347395869
    ],
    [
      36.420281,
      56.672386
    ],
    [
      36.420279808984084,
      56.672385665105466
    ],
    [
      36.420051,
      56.672469
    ],
    [
      36.409225,
      56.675135
    ],
    [
      36.400277,
      56.678376
    ],
    [
      36.398799,
      56.6789
    ],
    [
      36.398771327189436,
      56.67882203806194
    ],
    [
      36.394356,
      56.680347
    ],
    [
      36.3925934443989,
      56.68100332629728
    ],
    [
      36.389592,
      56.68215
    ],
    [
      36.38694247805048,
      56.68310758775559
    ],
    [
      36.386936,
      56.68311
    ],
    [
      36.381143,
      56.68639
    ],
    [
      36.378261,
      56.686216
    ],
    [
      36.37826031272577,
      56.68621406753988
    ],
    [
      36.376659,
      56.686787
    ],
    [
      36.375167,
      56.687317
    ],
    [
      36.375135100995855,
      56.687227355077
    ],
    [
      36.370019,
      56.689139
    ],
    [
      36.368295720472766,
      56.68975765138737
    ],
    [
      36.379938,
      56.701698
    ],
    [
      36.385165,
      56.701191
    ],
    [
      36.395578,
      56.701344
    ],
    [
      36.399647,
      56.701729
    ],
    [
      36.401726,
      56.701797
    ],
    [
      36.402844,
      56.705219
    ],
    [
      36.402019,
      56.705488
    ],
    [
      36.399406,
      56.705321
    ],
    [
      36.39525,
      56.704929
    ],
    [
      36.388348,
      56.70277
    ],
    [
      36.378859419247696,
      56.70392052067317
    ],
    [
      36.379928,
      56.706685
    ],
    [
      36.379092,
      56.707008
    ],
    [
      36.372848,
      56.710298
    ],
    [
      36.369091,
      56.712294
    ],
    [
      36.365456,
      56.713048
    ],
    [
      36.361058,
      56.712862
    ],
    [
      36.35092104030191,
      56.712913754056835
    ],
    [
      36.3498347051573,
      56.71329606942962
    ],
    [
      36.34760411058338,
      56.714305230781314
    ],
    [
      36.34733688413996,
      56.71445373391747
    ],
    [
      36.343851,
      56.716743
    ],
    [
      36.338014,
      56.725396
    ],
    [
      36.337983239064975,
      56.72695152455541
    ],
    [
      36.33807007976035,
      56.72997682169891
    ],
    [
      36.345544,
      56.736763
    ],
    [
      36.349598,
      56.736428
    ],
    [
      36.355165,
      56.743702
    ],
    [
      36.363645,
      56.749906
    ],
    [
      36.370868,
      56.746424
    ],
    [
      36.376497,
      56.743574
    ],
    [
      36.380496,
      56.741372
    ],
    [
      36.382209,
      56.744538
    ],
    [
      36.378233,
      56.746728
    ],
    [
      36.374246,
      56.748923
    ],
    [
      36.37202759008099,
      56.75032388478219
    ],
    [
      36.368998,
      56.758993
    ],
    [
      36.370978,
      56.763004
    ],
    [
      36.378102,
      56.766726
    ],
    [
      36.383863862023986,
      56.76808076038987
    ],
    [
      36.384884,
      56.768234
    ],
    [
      36.388312,
      56.769086
    ],
    [
      36.387183,
      56.776007
    ],
    [
      36.384039,
      56.777761
    ],
    [
      36.383446,
      56.776699
    ],
    [
      36.373063,
      56.770976
    ],
    [
      36.37291362333417,
      56.7710086047074
    ],
    [
      36.372915,
      56.771014
    ],
    [
      36.37166294014342,
      56.77133361381307
    ],
    [
      36.370399,
      56.771663
    ],
    [
      36.366594,
      56.772388
    ],
    [
      36.360797,
      56.781855
    ],
    [
      36.366766816989035,
      56.78772801695557
    ],
    [
      36.372111,
      56.78872
    ],
    [
      36.37587,
      56.789214
    ],
    [
      36.379014,
      56.788804
    ],
    [
      36.379479,
      56.792374
    ],
    [
      36.376335,
      56.792784
    ],
    [
      36.367735,
      56.798703
    ],
    [
      36.365184,
      56.796619
    ],
    [
      36.364047,
      56.795332
    ],
    [
      36.361598746953455,
      56.79541244348637
    ],
    [
      36.353395,
      56.797256
    ],
    [
      36.35326680700832,
      56.7970950098332
    ],
    [
      36.353265,
      56.797096
    ],
    [
      36.35217252428084,
      56.79572289781648
    ],
    [
      36.349834,
      56.795848
    ],
    [
      36.349642,
      56.792254
    ],
    [
      36.353607,
      56.790318
    ],
    [
      36.35547019469464,
      56.78234657354805
    ],
    [
      36.348188,
      56.777928
    ],
    [
      36.33903758732419,
      56.783251520428635
    ],
    [
      36.33804325565376,
      56.78567579573941
    ],
    [
      36.336707,
      56.793888
    ],
    [
      36.334313,
      56.797516
    ],
    [
      36.32809511421989,
      56.79540144561294
    ],
    [
      36.326583,
      56.796332
    ],
    [
      36.326255,
      56.796376
    ],
    [
      36.314655,
      56.793475
    ],
    [
      36.310094,
      56.792672
    ],
    [
      36.30296,
      56.790833
    ],
    [
      36.293302,
      56.797429
    ],
    [
      36.290033,
      56.80163
    ],
    [
      36.287832,
      56.804713
    ],
    [
      36.286922,
      56.805123
    ],
    [
      36.286213,
      56.805317
    ],
    [
      36.277098,
      56.805109
    ],
    [
      36.271648,
      56.801953
    ],
    [
      36.267735,
      56.799916
    ],
    [
      36.264243,
      56.797591
    ],
    [
      36.25994,
      56.794805
    ],
    [
      36.2549274580537,
      56.790943817814174
    ],
    [
      36.251097755297685,
      56.791627261294
    ],
    [
      36.248626873799545,
      56.79422782354556
    ],
    [
      36.250238668431024,
      56.80247432280913
    ],
    [
      36.251897,
      56.804124
    ],
    [
      36.252748,
      56.80509
    ],
    [
      36.255752,
      56.807075
    ],
    [
      36.255218,
      56.807882
    ],
    [
      36.249616407834374,
      56.805571580377574
    ],
    [
      36.249443,
      56.80628
    ],
    [
      36.246745,
      56.805619
    ],
    [
      36.244048,
      56.804959
    ],
    [
      36.242249,
      56.803087
    ],
    [
      36.230501,
      56.80545
    ],
    [
      36.23048180996717,
      56.80544090936349
    ],
    [
      36.230454,
      56.805544
    ],
    [
      36.22779151233016,
      56.81065449993728
    ],
    [
      36.22777505986951,
      56.81068791864118
    ],
    [
      36.225679,
      56.815099
    ],
    [
      36.225064142442704,
      56.816367823480114
    ],
    [
      36.224647,
      56.817258
    ],
    [
      36.223022,
      56.820582
    ],
    [
      36.23144510246157,
      56.82870270817252
    ],
    [
      36.233039,
      56.827943
    ],
    [
      36.234299,
      56.827388
    ],
    [
      36.237178,
      56.830228
    ],
    [
      36.23717754920162,
      56.83023311570567
    ],
    [
      36.237292,
      56.830239
    ],
    [
      36.241938,
      56.831033
    ],
    [
      36.246423,
      56.831878
    ],
    [
      36.253311,
      56.833207
    ],
    [
      36.257456,
      56.833999
    ],
    [
      36.261965,
      56.834334
    ],
    [
      36.265602,
      56.834647
    ],
    [
      36.272801,
      56.828845
    ],
    [
      36.27314,
      56.821525
    ],
    [
      36.279291,
      56.820272
    ],
    [
      36.282841,
      56.819874
    ],
    [
      36.279718,
      56.823847
    ],
    [
      36.276477,
      56.829732
    ],
    [
      36.280413,
      56.836562
    ],
    [
      36.285467,
      56.837299
    ],
    [
      36.28958,
      56.837941
    ],
    [
      36.294108,
      56.838576
    ],
    [
      36.298348,
      56.839208
    ],
    [
      36.305836,
      56.840312
    ],
    [
      36.313323,
      56.841416
    ],
    [
      36.320133,
      56.8424
    ],
    [
      36.328331,
      56.842795
    ],
    [
      36.333928,
      56.842708
    ],
    [
      36.337624,
      56.842671
    ],
    [
      36.341319,
      56.842634
    ],
    [
      36.347309,
      56.842573
    ],
    [
      36.357758,
      56.842075
    ],
    [
      36.362934,
      56.841139
    ],
    [
      36.373621,
      56.837992
    ],
    [
      36.375028,
      56.837863
    ],
    [
      36.376571126897545,
      56.83813179027413
    ],
    [
      36.379632,
      56.835541
    ],
    [
      36.383189,
      56.836096
    ],
    [
      36.384562,
      56.836961
    ],
    [
      36.388023386433936,
      56.84054646307997
    ],
    [
      36.393127,
      56.84079
    ],
    [
      36.39629,
      56.837816
    ],
    [
      36.397859,
      56.83519
    ],
    [
      36.398832,
      56.83402
    ],
    [
      36.401599,
      56.836322
    ],
    [
      36.405716,
      56.847335
    ],
    [
      36.417528,
      56.849269
    ],
    [
      36.41922,
      56.849504
    ],
    [
      36.418725,
      56.85307
    ],
    [
      36.417032,
      56.852835
    ],
    [
      36.41497,
      56.851376
    ],
    [
      36.405101,
      56.850882
    ],
    [
      36.397143,
      56.84803
    ],
    [
      36.394942737107556,
      56.848299154296576
    ],
    [
      36.394824,
      56.849103
    ],
    [
      36.391903,
      56.848671
    ],
    [
      36.388057,
      56.84792
    ],
    [
      36.384339,
      56.847112
    ],
    [
      36.38423714765311,
      56.8470805412446
    ],
    [
      36.384237,
      56.847081
    ],
    [
      36.38419817319935,
      56.847068503350094
    ],
    [
      36.380685877194416,
      56.845983673552055
    ],
    [
      36.371403,
      56.845002
    ],
    [
      36.367158,
      56.844694
    ],
    [
      36.356955,
      56.849956
    ],
    [
      36.353234,
      56.850205
    ],
    [
      36.347346,
      56.846173
    ],
    [
      36.341356,
      56.846234
    ],
    [
      36.333964,
      56.846308
    ],
    [
      36.328387,
      56.846395
    ],
    [
      36.319806,
      56.845985
    ],
    [
      36.31717942026445,
      56.84560757908195
    ],
    [
      36.317177,
      56.845624
    ],
    [
      36.312872,
      56.846325
    ],
    [
      36.30531,
      56.843874
    ],
    [
      36.297823,
      56.84277
    ],
    [
      36.293577,
      56.842136
    ],
    [
      36.28908,
      56.841506
    ],
    [
      36.284912,
      56.840856
    ],
    [
      36.279893,
      56.840124
    ],
    [
      36.273183,
      56.837728
    ],
    [
      36.265294,
      56.838234
    ],
    [
      36.25983885877493,
      56.83779954624077
    ],
    [
      36.257973,
      56.837674
    ],
    [
      36.256162,
      56.837433
    ],
    [
      36.252618,
      56.836739
    ],
    [
      36.245752,
      56.835415
    ],
    [
      36.241286,
      56.834574
    ],
    [
      36.23686204204,
      56.83381352292578
    ],
    [
      36.236862,
      56.833814
    ],
    [
      36.228366,
      56.838862
    ],
    [
      36.225869,
      56.841456
    ],
    [
      36.219237716536156,
      56.84008604393231
    ],
    [
      36.220317,
      56.848036
    ],
    [
      36.222294,
      56.851212
    ],
    [
      36.225576,
      56.854166
    ],
    [
      36.22856,
      56.856539
    ],
    [
      36.232297,
      56.859439
    ],
    [
      36.23486403247321,
      56.860882833199696
    ],
    [
      36.240869,
      56.861163
    ],
    [
      36.244344,
      56.860416
    ],
    [
      36.248995,
      56.862344
    ],
    [
      36.253646,
      56.864324
    ],
    [
      36.25567,
      56.867885
    ],
    [
      36.257179,
      56.872448
    ],
    [
      36.257405,
      56.873821
    ],
    [
      36.253853,
      56.874407
    ],
    [
      36.253627,
      56.873034
    ],
    [
      36.252324,
      56.869212
    ],
    [
      36.249745,
      56.866545
    ],
    [
      36.244613,
      56.86423
    ],
    [
      36.238223076746664,
      56.869744567173704
    ],
    [
      36.236126,
      56.872277
    ],
    [
      36.239581581686444,
      56.883196917206995
    ],
    [
      36.242592,
      56.883065
    ],
    [
      36.24926,
      56.88274
    ],
    [
      36.25325,
      56.882575
    ],
    [
      36.257721,
      56.882425
    ],
    [
      36.263824,
      56.882151
    ],
    [
      36.269758,
      56.871855
    ],
    [
      36.269495,
      56.869862
    ],
    [
      36.269337,
      56.868979
    ],
    [
      36.272881,
      56.868345
    ],
    [
      36.281416,
      56.871372
    ],
    [
      36.28923594810901,
      56.87504850545925
    ],
    [
      36.293719,
      56.874846
    ],
    [
      36.298024,
      56.874651
    ],
    [
      36.306379,
      56.874274
    ],
    [
      36.314733,
      56.873896
    ],
    [
      36.320729,
      56.878207
    ],
    [
      36.320964,
      56.881799
    ],
    [
      36.319714,
      56.881881
    ],
    [
      36.318938,
      56.881925
    ],
    [
      36.313012,
      56.882211
    ],
    [
      36.307635,
      56.882442
    ],
    [
      36.303739,
      56.882589
    ],
    [
      36.298028,
      56.882322
    ],
    [
      36.29591825271471,
      56.88255033267141
    ],
    [
      36.295927,
      56.882674
    ],
    [
      36.29156,
      56.887792
    ],
    [
      36.287495479204736,
      56.89046226841416
    ],
    [
      36.289352,
      56.895188
    ],
    [
      36.291463,
      56.899985
    ],
    [
      36.291866,
      56.903738
    ],
    [
      36.288454,
      56.905069
    ],
    [
      36.282142,
      56.915218
    ],
    [
      36.273029,
      56.920104
    ],
    [
      36.268012,
      56.920323
    ],
    [
      36.262995,
      56.920541
    ],
    [
      36.259717,
      56.921533
    ],
    [
      36.252389,
      56.93092
    ],
    [
      36.248961,
      56.932019
    ],
    [
      36.248144,
      56.929472
    ],
    [
      36.246809,
      56.925518
    ],
    [
      36.245527,
      56.918893
    ],
    [
      36.243930728766685,
      56.917643938503154
    ],
    [
      36.235883,
      56.919045
    ],
    [
      36.234588,
      56.918758
    ],
    [
      36.23300056803253,
      56.91855454920321
    ],
    [
      36.229053,
      56.925571
    ],
    [
      36.228886,
      56.928543
    ],
    [
      36.225292,
      56.928342
    ],
    [
      36.218292,
      56.919519
    ],
    [
      36.206801,
      56.91723
    ],
    [
      36.19692,
      56.921272
    ],
    [
      36.194768,
      56.922352
    ],
    [
      36.191552,
      56.923179
    ],
    [
      36.186758,
      56.918857
    ],
    [
      36.185895,
      56.915362
    ],
    [
      36.18493,
      56.910975
    ],
    [
      36.186158,
      56.907591
    ],
    [
      36.195593,
      56.905189
    ],
    [
      36.204726,
      56.900255
    ],
    [
      36.207894,
      56.898545
    ],
    [
      36.20809807745767,
      56.89855685601002
    ],
    [
      36.20922,
      56.897961
    ],
    [
      36.212662,
      56.898822
    ],
    [
      36.224259,
      56.89599
    ],
    [
      36.229648,
      56.885607
    ],
    [
      36.228614362666896,
      56.87831766181887
    ],
    [
      36.228305,
      56.876292
    ],
    [
      36.229943,
      56.873609
    ],
    [
      36.230017,
      56.862225
    ],
    [
      36.22634,
      56.859372
    ],
    [
      36.222115,
      56.856083
    ],
    [
      36.211474,
      56.853648
    ],
    [
      36.202313,
      56.855885
    ],
    [
      36.195532,
      56.858887
    ],
    [
      36.19151,
      56.860958
    ],
    [
      36.19140747568309,
      56.86099647237502
    ],
    [
      36.190028,
      56.861702
    ],
    [
      36.183027,
      56.870366
    ],
    [
      36.176731,
      56.87774
    ],
    [
      36.173993,
      56.875402
    ],
    [
      36.173537045813845,
      56.87007815356036
    ],
    [
      36.171352,
      56.871554
    ],
    [
      36.168581,
      56.874862
    ],
    [
      36.166091,
      56.877934
    ],
    [
      36.163591,
      56.881021
    ],
    [
      36.160525,
      56.884727
    ],
    [
      36.163924,
      56.895722
    ],
    [
      36.164954,
      56.895951
    ],
    [
      36.164171,
      56.899465
    ],
    [
      36.16160266198106,
      56.899473328528195
    ],
    [
      36.155896,
      56.903982
    ],
    [
      36.149605,
      56.90484
    ],
    [
      36.146543,
      56.902806
    ],
    [
      36.134835,
      56.900713
    ],
    [
      36.127494,
      56.903785
    ],
    [
      36.123908,
      56.904107
    ],
    [
      36.120897,
      56.898379
    ],
    [
      36.124008,
      56.892569
    ],
    [
      36.130597,
      56.885915
    ],
    [
      36.134182,
      56.88559
    ],
    [
      36.135846,
      56.888098
    ],
    [
      36.141391,
      56.896125
    ],
    [
      36.14306172686463,
      56.896476586837615
    ],
    [
      36.143062,
      56.896474
    ],
    [
      36.144585,
      56.896635
    ],
    [
      36.152867,
      56.892421
    ],
    [
      36.15383,
      56.888952
    ],
    [
      36.157751,
      56.882432
    ],
    [
      36.160817,
      56.878726
    ],
    [
      36.163294,
      56.875668
    ],
    [
      36.165784,
      56.872596
    ],
    [
      36.168592,
      56.869243
    ],
    [
      36.172956,
      56.864934
    ],
    [
      36.17309129386582,
      56.86487343125811
    ],
    [
      36.173091,
      56.86487
    ],
    [
      36.177706055351514,
      56.8628074817371
    ],
    [
      36.17848,
      56.862461
    ],
    [
      36.181929,
      56.852961
    ],
    [
      36.18310841422663,
      56.852716121622436
    ],
    [
      36.183218,
      56.852323
    ],
    [
      36.18669733392933,
      56.851797380907584
    ],
    [
      36.18767777158334,
      56.85145526359942
    ],
    [
      36.194588,
      56.845044
    ],
    [
      36.198001,
      56.840682
    ],
    [
      36.19308066117064,
      56.833461147410894
    ],
    [
      36.19169564138415,
      56.83286427640299
    ],
    [
      36.188333,
      56.832967
    ],
    [
      36.172085,
      56.833481
    ],
    [
      36.167658,
      56.83362
    ],
    [
      36.158486,
      56.835634
    ],
    [
      36.152771,
      56.838478
    ],
    [
      36.149376,
      56.840143
    ],
    [
      36.144514,
      56.84257
    ],
    [
      36.140973,
      56.844387
    ],
    [
      36.137031,
      56.846308
    ],
    [
      36.133086,
      56.848262
    ],
    [
      36.129125,
      56.850224
    ],
    [
      36.125281,
      56.852082
    ],
    [
      36.12527897187501,
      56.85207780655714
    ],
    [
      36.125096,
      56.852166
    ],
    [
      36.118855,
      56.855781
    ],
    [
      36.116931413397445,
      56.85675642663772
    ],
    [
      36.113752,
      56.858397
    ],
    [
      36.109452,
      56.860533
    ],
    [
      36.104968,
      56.862847
    ],
    [
      36.100224,
      56.86517
    ],
    [
      36.095319,
      56.868205
    ],
    [
      36.09430150223877,
      56.86863946973677
    ],
    [
      36.094092,
      56.868775
    ],
    [
      36.089689,
      56.870609
    ],
    [
      36.08625,
      56.872188
    ],
    [
      36.082213,
      56.874436
    ],
    [
      36.078318,
      56.876605
    ],
    [
      36.077011,
      56.877288
    ],
    [
      36.076992258565966,
      56.87725213606805
    ],
    [
      36.075783,
      56.877952
    ],
    [
      36.071697,
      56.879905
    ],
    [
      36.070766,
      56.880393
    ],
    [
      36.070174,
      56.880623
    ],
    [
      36.07011246144567,
      56.88046433262143
    ],
    [
      36.068357,
      56.881084
    ],
    [
      36.060319,
      56.889386
    ],
    [
      36.059994,
      56.889511
    ],
    [
      36.056753,
      56.888615
    ],
    [
      36.047542,
      56.883042
    ],
    [
      36.0492,
      56.879846
    ],
    [
      36.050063,
      56.88011
    ],
    [
      36.059924,
      56.880858
    ],
    [
      36.064926,
      56.878799
    ],
    [
      36.068506,
      56.876895
    ],
    [
      36.072183,
      56.87564
    ],
    [
      36.076642,
      56.87335
    ],
    [
      36.080278,
      56.871139
    ],
    [
      36.083453,
      56.869488
    ],
    [
      36.08679,
      56.867845
    ],
    [
      36.090908,
      56.866233
    ],
    [
      36.094786,
      56.863963
    ],
    [
      36.098617,
      56.861949
    ],
    [
      36.103143,
      56.859744
    ],
    [
      36.106464,
      56.857973
    ],
    [
      36.109761,
      56.85633
    ],
    [
      36.113432,
      56.854495
    ],
    [
      36.117226,
      56.85257
    ],
    [
      36.12371863397884,
      56.848851581424015
    ],
    [
      36.123714,
      56.848842
    ],
    [
      36.125636,
      56.847912
    ],
    [
      36.127558,
      56.846983
    ],
    [
      36.131488,
      56.845036
    ],
    [
      36.127365,
      56.834526
    ],
    [
      36.123357,
      56.83468
    ],
    [
      36.119699,
      56.835097
    ],
    [
      36.115264,
      56.839221
    ],
    [
      36.111239,
      56.838414
    ],
    [
      36.11043877645456,
      56.83835454175459
    ],
    [
      36.108399,
      56.838255
    ],
    [
      36.101434,
      56.83764
    ],
    [
      36.091614,
      56.836359
    ],
    [
      36.084346,
      56.836825
    ],
    [
      36.078851,
      56.836385
    ],
    [
      36.067133,
      56.839366
    ],
    [
      36.060508,
      56.84105
    ],
    [
      36.053167,
      56.84686
    ],
    [
      36.049967,
      56.847753
    ],
    [
      36.043365,
      56.850276
    ],
    [
      36.04269,
      56.850617
    ],
    [
      36.04255333135594,
      56.850624321534504
    ],
    [
      36.039305,
      56.857083
    ],
    [
      36.039116,
      56.861943
    ],
    [
      36.039075,
      56.861999
    ],
    [
      36.037208,
      56.862628
    ],
    [
      36.036994,
      56.862572
    ],
    [
      36.029711,
      56.857505
    ],
    [
      36.022359,
      56.858016
    ],
    [
      36.019329,
      56.858325
    ],
    [
      36.018589,
      56.858411
    ],
    [
      36.012457,
      56.857569
    ],
    [
      36.011053,
      56.857404
    ],
    [
      36.010431,
      56.856826
    ],
    [
      36.008847,
      56.853505
    ],
    [
      36.00868,
      56.849908
    ],
    [
      36.011256,
      56.842429
    ],
    [
      36.01207,
      56.840698
    ],
    [
      36.014954,
      56.841302
    ],
    [
      36.015589,
      56.841459
    ],
    [
      36.02089,
      56.84435
    ],
    [
      36.028348,
      56.843316
    ],
    [
      36.032092377751056,
      56.84378239395672
    ],
    [
      36.032082,
      56.843593
    ],
    [
      36.032088,
      56.843187
    ],
    [
      36.032128,
      56.842652
    ],
    [
      36.033112,
      56.842294
    ],
    [
      36.03407,
      56.842064
    ],
    [
      36.0424133800446,
      56.83951591955426
    ],
    [
      36.044007,
      56.836139
    ],
    [
      36.047922,
      56.835802
    ],
    [
      36.057813,
      56.83012
    ],
    [
      36.062123,
      56.829492
    ],
    [
      36.064236,
      56.82915
    ],
    [
      36.066307,
      56.829075
    ],
    [
      36.071985,
      56.836268
    ],
    [
      36.077966,
      56.832895
    ],
    [
      36.08036,
      56.830123
    ],
    [
      36.083184,
      56.822549
    ],
    [
      36.079283,
      56.811913
    ],
    [
      36.07928616688206,
      56.811912454440986
    ],
    [
      36.078943,
      56.811479
    ],
    [
      36.071661,
      56.808625
    ],
    [
      36.071163,
      56.808266
    ],
    [
      36.070769,
      56.807754
    ],
    [
      36.070759,
      56.807741
    ],
    [
      36.070358,
      56.80723
    ],
    [
      36.062023,
      56.800363
    ],
    [
      36.058569,
      56.799152
    ],
    [
      36.04985069203615,
      56.797954743917906
    ],
    [
      36.046906,
      56.798094
    ],
    [
      36.036888,
      56.80405
    ],
    [
      36.035626,
      56.805129
    ],
    [
      36.035038,
      56.805386
    ],
    [
      36.030235,
      56.804758
    ],
    [
      36.02961407967111,
      56.803845763162926
    ],
    [
      36.02465815809997,
      56.80480070091516
    ],
    [
      36.021079,
      56.806554
    ],
    [
      36.01475764949533,
      56.80763554607617
    ],
    [
      36.009359,
      56.810861
    ],
    [
      36.00798,
      56.811022
    ],
    [
      36.007124636771096,
      56.80991260032341
    ],
    [
      36.003373,
      56.812026
    ],
    [
      35.998651,
      56.811789
    ],
    [
      35.994908498121056,
      56.81285867975686
    ],
    [
      35.993719522013095,
      56.81389731724448
    ],
    [
      35.987631,
      56.820611
    ],
    [
      35.9832964632704,
      56.82096258635447
    ],
    [
      35.978006,
      56.822404
    ],
    [
      35.97735783455204,
      56.82297335135392
    ],
    [
      35.973965,
      56.831149
    ],
    [
      35.973951,
      56.831189
    ],
    [
      35.971992172993296,
      56.83193870866617
    ],
    [
      35.972935,
      56.840428
    ],
    [
      35.973838,
      56.849925
    ],
    [
      35.973455,
      56.850415
    ],
    [
      35.969813290235166,
      56.85071941220475
    ],
    [
      35.972656,
      56.858389
    ],
    [
      35.978955,
      56.855512
    ],
    [
      35.979341,
      56.855344
    ],
    [
      35.981579,
      56.856992
    ],
    [
      35.980123,
      56.861658
    ],
    [
      35.977636,
      56.867801
    ],
    [
      35.970942,
      56.872025
    ],
    [
      35.969843,
      56.872435
    ],
    [
      35.969565,
      56.872515
    ],
    [
      35.963323,
      56.870937
    ],
    [
      35.956381,
      56.878487
    ],
    [
      35.954743,
      56.878753
    ],
    [
      35.943377,
      56.880529
    ],
    [
      35.941262,
      56.881453
    ],
    [
      35.93945,
      56.881685
    ],
    [
      35.936047,
      56.880912
    ],
    [
      35.932676,
      56.879647
    ],
    [
      35.927842,
      56.877416
    ],
    [
      35.926126,
      56.876681
    ],
    [
      35.922715,
      56.874858
    ],
    [
      35.914173,
      56.873661
    ],
    [
      35.913209,
      56.873773
    ],
    [
      35.905636,
      56.871168
    ],
    [
      35.901032,
      56.869591
    ],
    [
      35.898626,
      56.868622
    ],
    [
      35.889519,
      56.864526
    ],
    [
      35.8883,
      56.861906
    ],
    [
      35.883625,
      56.852265
    ],
    [
      35.8832,
      56.849593
    ],
    [
      35.883067,
      56.848709
    ],
    [
      35.884555,
      56.845955
    ],
    [
      35.885459,
      56.844352
    ],
    [
      35.889644,
      56.8363
    ],
    [
      35.887106,
      56.827282
    ],
    [
      35.878262,
      56.822593
    ],
    [
      35.876955,
      56.819239
    ],
    [
      35.874449,
      56.81269
    ],
    [
      35.869705,
      56.805706
    ],
    [
      35.864306,
      56.802648
    ],
    [
      35.854234,
      56.802745
    ],
    [
      35.849537,
      56.805291
    ],
    [
      35.847053,
      56.806845
    ],
    [
      35.84457,
      56.808399
    ],
    [
      35.84266,
      56.805348
    ],
    [
      35.845144,
      56.803794
    ],
    [
      35.847627,
      56.80224
    ],
    [
      35.850565,
      56.800503
    ],
    [
      35.858064,
      56.792584
    ],
    [
      35.861583,
      56.793346
    ],
    [
      35.872953,
      56.794342
    ],
    [
      35.877174,
      56.793577
    ],
    [
      35.888264,
      56.791518
    ],
    [
      35.897416,
      56.780892
    ],
    [
      35.898035,
      56.779974
    ],
    [
      35.900006619674954,
      56.7786316657658
    ],
    [
      35.899676,
      56.775342
    ],
    [
      35.899243,
      56.770566
    ],
    [
      35.901269,
      56.765895
    ],
    [
      35.90162471711197,
      56.76570759236613
    ],
    [
      35.900269,
      56.76031
    ],
    [
      35.890583,
      56.755438
    ],
    [
      35.889853,
      56.754343
    ],
    [
      35.889573,
      56.753777
    ],
    [
      35.890244,
      56.751854
    ],
    [
      35.8993,
      56.744508
    ],
    [
      35.901306,
      56.745776
    ],
    [
      35.902351,
      56.747014
    ],
    [
      35.90396,
      56.751431
    ],
    [
      35.913417,
      56.754399
    ],
    [
      35.91876,
      56.754104
    ],
    [
      35.920725,
      56.754981
    ],
    [
      35.921621,
      56.756202
    ],
    [
      35.918719,
      56.758333
    ],
    [
      35.92028139452364,
      56.76810910172083
    ],
    [
      35.924977,
      56.77115
    ],
    [
      35.929024,
      56.772746
    ],
    [
      35.939276,
      56.7745
    ],
    [
      35.943449,
      56.775239
    ],
    [
      35.948583,
      56.775218
    ],
    [
      35.953189,
      56.765374
    ],
    [
      35.956495,
      56.761032
    ],
    [
      35.958060511688835,
      56.75946558234374
    ],
    [
      35.954046,
      56.75059
    ],
    [
      35.95514079629786,
      56.747824091391664
    ],
    [
      35.954596,
      56.747118
    ],
    [
      35.955782,
      56.74552
    ],
    [
      35.956638,
      56.744416
    ],
    [
      35.960876,
      56.740967
    ],
    [
      35.963372,
      56.738955
    ],
    [
      35.96517346362098,
      56.738490830672205
    ],
    [
      35.96831,
      56.735072
    ],
    [
      35.971211,
      56.728748
    ],
    [
      35.974664,
      56.725548
    ],
    [
      35.979504,
      56.724613
    ],
    [
      35.980493,
      56.724548
    ],
    [
      35.984518,
      56.726488
    ],
    [
      35.994537,
      56.72105
    ],
    [
      35.994811,
      56.720647
    ],
    [
      35.997788,
      56.72267
    ],
    [
      36.001757,
      56.729836
    ],
    [
      36.01033,
      56.725838
    ],
    [
      36.010664,
      56.722291
    ],
    [
      36.010038,
      56.720062
    ],
    [
      36.009678,
      56.719097
    ],
    [
      36.01305,
      56.717836
    ],
    [
      36.02027797697804,
      56.70905930671237
    ],
    [
      36.020133,
      56.708987
    ],
    [
      36.018318,
      56.705674
    ],
    [
      36.016132712693654,
      56.70479920593008
    ],
    [
      36.006391,
      56.703239
    ],
    [
      36.00505874007474,
      56.698980734441996
    ],
    [
      35.998526,
      56.69405
    ],
    [
      35.99510892564995,
      56.694507928538854
    ],
    [
      35.994970790188404,
      56.69453236788974
    ],
    [
      35.991357,
      56.696193
    ],
    [
      35.990776,
      56.696135
    ],
    [
      35.987177,
      56.696047
    ],
    [
      35.98719,
      56.695529
    ],
    [
      35.989125,
      56.69309
    ],
    [
      35.992417,
      56.69156
    ],
    [
      35.994314755777545,
      56.691101242791035
    ],
    [
      35.994296,
      56.690995
    ],
    [
      35.995987,
      56.690697
    ],
    [
      36.000967,
      56.689886
    ],
    [
      36.006528,
      56.691721
    ],
    [
      36.0127521731486,
      56.69059314578609
    ],
    [
      36.017655,
      56.688156
    ],
    [
      36.01780655458698,
      56.6906837335446
    ],
    [
      36.025425,
      56.697894
    ],
    [
      36.029296,
      56.698725
    ],
    [
      36.033101,
      56.699364
    ],
    [
      36.036877,
      56.698954
    ],
    [
      36.041326,
      56.697775
    ],
    [
      36.045771,
      56.692385
    ],
    [
      36.042779,
      56.685196
    ],
    [
      36.046184,
      56.682781
    ],
    [
      36.05193,
      56.6817
    ],
    [
      36.061587,
      56.678751
    ],
    [
      36.065221,
      56.676676
    ],
    [
      36.067759971438406,
      56.67576977460288
    ],
    [
      36.073907,
      56.668703
    ],
    [
      36.07443414039328,
      56.668472418736044
    ],
    [
      36.075012,
      56.6674
    ],
    [
      36.078541,
      56.666676
    ],
    [
      36.079257120969906,
      56.66652901526112
    ],
    [
      36.081594,
      56.666049
    ],
    [
      36.08159407246127,
      56.66604935299849
    ],
    [
      36.082083,
      56.665949
    ],
    [
      36.087176,
      56.666378
    ],
    [
      36.08706273339969,
      56.66664747588675
    ],
    [
      36.087316,
      56.666683
    ],
    [
      36.08402030837556,
      56.67524267629017
    ],
    [
      36.085493,
      56.680145
    ],
    [
      36.088274987033614,
      56.683056571445924
    ],
    [
      36.090262,
      56.684728
    ],
    [
      36.09026663120211,
      56.684734330183126
    ],
    [
      36.09204,
      56.686167
    ],
    [
      36.094175,
      56.689059
    ],
    [
      36.096447,
      56.693182
    ],
    [
      36.10691706238171,
      56.692820380993744
    ],
    [
      36.112839,
      56.686958
    ],
    [
      36.112935,
      56.686883
    ],
    [
      36.119419,
      56.678338
    ],
    [
      36.120165,
      56.674816
    ],
    [
      36.125038,
      56.67581
    ],
    [
      36.128816,
      56.67658
    ],
    [
      36.132595,
      56.67735
    ],
    [
      36.138351,
      56.678538
    ],
    [
      36.143747,
      56.679684
    ],
    [
      36.14453984167524,
      56.679842789387564
    ],
    [
      36.14454,
      56.679842
    ],
    [
      36.152374,
      56.681412
    ],
    [
      36.15304090822419,
      56.68154745158307
    ],
    [
      36.153041,
      56.681547
    ],
    [
      36.157541250692454,
      56.68246124474536
    ],
    [
      36.163243,
      56.683506
    ],
    [
      36.16957,
      56.683471
    ],
    [
      36.179789,
      56.679076
    ],
    [
      36.183285,
      56.674061
    ],
    [
      36.178127,
      56.665539
    ],
    [
      36.173471,
      56.664581
    ],
    [
      36.168844,
      56.663629
    ],
    [
      36.158741,
      56.661577
    ],
    [
      36.150317,
      56.659843
    ],
    [
      36.151043,
      56.656317
    ],
    [
      36.159467,
      56.658051
    ],
    [
      36.169513,
      56.660092
    ],
    [
      36.174197,
      56.661055
    ],
    [
      36.181112,
      56.651959
    ],
    [
      36.18207920515232,
      56.650696564092584
    ],
    [
      36.180998,
      56.650148
    ],
    [
      36.183634712357815,
      56.642607031441734
    ],
    [
      36.183702120299714,
      56.6395531585954
    ],
    [
      36.18143681678668,
      56.63927977439869
    ],
    [
      36.175947,
      56.638824
    ],
    [
      36.165788,
      56.634517
    ],
    [
      36.166536352440254,
      56.634099994591956
    ],
    [
      36.166497,
      56.634082
    ],
    [
      36.171686,
      56.628067
    ],
    [
      36.170439,
      56.617411
    ],
    [
      36.166376,
      56.615827
    ],
    [
      36.165442,
      56.607785
    ],
    [
      36.162559,
      56.597088
    ],
    [
      36.160065,
      56.594611
    ],
    [
      36.158323,
      56.58716
    ],
    [
      36.155089,
      56.576062
    ],
    [
      36.15676,
      56.572715
    ],
    [
      36.157099,
      56.572178
    ],
    [
      36.160143,
      56.5741
    ],
    [
      36.16316,
      56.575101
    ],
    [
      36.162873,
      56.57869
    ],
    [
      36.16287,
      56.587534
    ],
    [
      36.166559,
      56.596217
    ],
    [
      36.171696,
      56.6028
    ],
    [
      36.17150717483156,
      56.60440512513617
    ],
    [
      36.17213,
      56.604564
    ],
    [
      36.171463,
      56.607178
    ],
    [
      36.175233,
      56.616369
    ],
    [
      36.177881,
      56.619386
    ],
    [
      36.180736,
      56.623777
    ],
    [
      36.188179,
      56.629024
    ],
    [
      36.192896,
      56.627618
    ],
    [
      36.192924274720625,
      56.62762296375463
    ],
    [
      36.1929567052649,
      56.627613388678235
    ],
    [
      36.193334,
      56.625871
    ],
    [
      36.195572,
      56.625962
    ],
    [
      36.195678,
      56.625986
    ],
    [
      36.197027,
      56.626232
    ],
    [
      36.205290798947296,
      56.625483564147345
    ],
    [
      36.205969,
      56.624732
    ],
    [
      36.207957,
      56.623054
    ],
    [
      36.208708,
      56.622565
    ],
    [
      36.213323,
      56.620284
    ],
    [
      36.216342,
      56.619437
    ],
    [
      36.21763484481703,
      56.61906712667229
    ],
    [
      36.219382,
      56.613147
    ],
    [
      36.22,
      56.6096
    ],
    [
      36.22329452207009,
      56.60920826381173
    ],
    [
      36.222074,
      56.608276
    ],
    [
      36.218039,
      56.605649
    ],
    [
      36.211098,
      56.604223
    ],
    [
      36.204156,
      56.606567
    ],
    [
      36.20276851836534,
      56.60695230708221
    ],
    [
      36.201213,
      56.607666
    ],
    [
      36.199141199384634,
      56.60795962253619
    ],
    [
      36.198254,
      56.608206
    ],
    [
      36.194143,
      56.609001
    ],
    [
      36.19397321483385,
      56.6084730020287
    ],
    [
      36.193139,
      56.608542
    ],
    [
      36.189717,
      56.607424
    ],
    [
      36.189334,
      56.603543
    ],
    [
      36.188377,
      56.600042
    ],
    [
      36.188158,
      56.599593
    ],
    [
      36.191393,
      56.598013
    ],
    [
      36.193625,
      56.599744
    ],
    [
      36.201532862073044,
      56.60270356763361
    ],
    [
      36.206306,
      56.596102
    ],
    [
      36.207411,
      56.590792
    ],
    [
      36.207895,
      56.588735
    ],
    [
      36.2101653891004,
      56.58475910069654
    ],
    [
      36.206347,
      56.584109
    ],
    [
      36.205037,
      56.583771
    ],
    [
      36.205937,
      56.580285
    ],
    [
      36.20613354763117,
      56.58007279448627
    ],
    [
      36.204415,
      56.574456
    ],
    [
      36.198283,
      56.568448
    ],
    [
      36.191671,
      56.566049
    ],
    [
      36.183196,
      56.565717
    ],
    [
      36.176925,
      56.565075
    ],
    [
      36.174793,
      56.565066
    ],
    [
      36.172662,
      56.565057
    ],
    [
      36.172677,
      56.561457
    ],
    [
      36.174808,
      56.561466
    ],
    [
      36.17694,
      56.561475
    ],
    [
      36.183557,
      56.562135
    ],
    [
      36.192493,
      56.562007
    ],
    [
      36.196439,
      56.561865
    ],
    [
      36.20195,
      56.566189
    ],
    [
      36.205665,
      56.568591
    ],
    [
      36.207809,
      56.572913
    ],
    [
      36.209741,
      56.576523
    ],
    [
      36.21553616495199,
      56.57892764434875
    ],
    [
      36.219928,
      56.579038
    ],
    [
      36.221071,
      56.578917
    ],
    [
      36.2212595281288,
      56.58070776718623
    ],
    [
      36.221641,
      56.580667
    ],
    [
      36.221081,
      56.58907
    ],
    [
      36.220548,
      56.595227
    ],
    [
      36.223256,
      56.604821
    ],
    [
      36.226931,
      56.607213
    ],
    [
      36.228395884246275,
      56.60894090717634
    ],
    [
      36.229954,
      56.609015
    ],
    [
      36.229923,
      56.609656
    ],
    [
      36.229699,
      56.610478
    ],
    [
      36.229658598859366,
      56.61492691882536
    ],
    [
      36.230404,
      56.614493
    ],
    [
      36.230892,
      56.61533
    ],
    [
      36.241593,
      56.61945
    ],
    [
      36.246285,
      56.620145
    ],
    [
      36.250465,
      56.620859
    ],
    [
      36.25073328657059,
      56.62094301947076
    ],
    [
      36.250736,
      56.620934
    ],
    [
      36.252666,
      56.621514
    ],
    [
      36.258946,
      56.623515
    ],
    [
      36.264803,
      56.625107
    ],
    [
      36.270432,
      56.626265
    ],
    [
      36.27101724645155,
      56.626629722753684
    ],
    [
      36.274672,
      56.625846
    ],
    [
      36.275593,
      56.626275
    ],
    [
      36.27924423748883,
      56.63114412213103
    ],
    [
      36.286088,
      56.633641
    ],
    [
      36.29305626943048,
      56.634245236252205
    ],
    [
      36.299288,
      56.631004
    ],
    [
      36.301823510499865,
      56.62445131458279
    ],
    [
      36.30237727647687,
      56.618967078293956
    ],
    [
      36.302086,
      56.618309
    ],
    [
      36.303912,
      56.612485
    ],
    [
      36.307826,
      56.60882
    ],
    [
      36.3114,
      56.607426
    ],
    [
      36.313597,
      56.60678
    ],
    [
      36.31565443753248,
      56.60619539355173
    ],
    [
      36.315801,
      56.606153
    ],
    [
      36.319232,
      56.597914
    ],
    [
      36.320478,
      56.594089
    ],
    [
      36.3206509729945,
      56.59428742338326
    ],
    [
      36.322468,
      56.591641
    ],
    [
      36.327215,
      56.601001
    ],
    [
      36.328350188644805,
      56.602644311225404
    ],
    [
      36.328444,
      56.602752
    ],
    [
      36.329496,
      56.604303
    ],
    [
      36.333626,
      56.609292
    ],
    [
      36.33808677730658,
      56.61112701836953
    ],
    [
      36.348362,
      56.610162
    ],
    [
      36.34899943609107,
      56.609992160743
    ],
    [
      36.353272,
      56.608155
    ],
    [
      36.3565031802629,
      56.60799285390617
    ],
    [
      36.356938,
      56.607877
    ],
    [
      36.35732731584584,
      56.6079514973879
    ],
    [
      36.359051,
      56.607865
    ],
    [
      36.36034160505313,
      56.608528295570466
    ],
    [
      36.36057,
      56.608572
    ],
    [
      36.36065257384882,
      56.608688115357495
    ],
    [
      36.363534,
      56.610169
    ],
    [
      36.36891012579576,
      56.61758915317098
    ],
    [
      36.377244,
      56.619088
    ],
    [
      36.37906251166168,
      56.61958421199417
    ],
    [
      36.379492,
      56.618909
    ],
    [
      36.379872,
      56.618865
    ],
    [
      36.381037,
      56.619106
    ],
    [
      36.388297,
      56.622104
    ],
    [
      36.38831429272617,
      56.622107552466396
    ],
    [
      36.388315,
      56.622104
    ],
    [
      36.38832596324577,
      56.62210618460765
    ],
    [
      36.388326,
      56.622106
    ],
    [
      36.391587,
      56.622756
    ],
    [
      36.393044104523234,
      56.62305979713244
    ],
    [
      36.40240335720555,
      56.62386636092126
    ],
    [
      36.402546991909766,
      56.62384190317957
    ],
    [
      36.40810773429949,
      56.62059629576701
    ],
    [
      36.41011198514141,
      56.61903865702552
    ],
    [
      36.412014,
      56.612321
    ],
    [
      36.412782032788755,
      56.61137147536408
    ],
    [
      36.412892,
      56.608676
    ],
    [
      36.414145943109695,
      56.609685262913416
    ],
    [
      36.414278,
      56.609522
    ],
    [
      36.414984,
      56.610094
    ],
    [
      36.42364611416936,
      56.61451344600478
    ],
    [
      36.426381858909,
      56.615267982053936
    ],
    [
      36.42996264768169,
      56.61556397892676
    ],
    [
      36.431237510703504,
      56.61564023241591
    ],
    [
      36.432076,
      56.61569
    ],
    [
      36.432082293541306,
      56.615690761482846
    ],
    [
      36.432103,
      56.615692
    ],
    [
      36.432108068950185,
      56.615692613858464
    ],
    [
      36.433201,
      56.615758
    ],
    [
      36.437916,
      56.61605
    ],
    [
      36.447024,
      56.613659
    ],
    [
      36.450547,
      56.608838
    ],
    [
      36.454382,
      56.603496
    ],
    [
      36.459676,
      56.596079
    ],
    [
      36.45297,
      56.589358
    ],
    [
      36.451494,
      56.580508
    ],
    [
      36.441933,
      56.576545
    ],
    [
      36.433721,
      56.576084
    ],
    [
      36.4296146877513,
      56.57192505980392
    ],
    [
      36.420676,
      56.57057
    ],
    [
      36.419488196350066,
      56.56818462457801
    ],
    [
      36.418528,
      56.566817
    ],
    [
      36.41617995340049,
      56.56188936980031
    ],
    [
      36.409935281274834,
      56.5587488992267
    ],
    [
      36.404317,
      56.558777
    ],
    [
      36.398408,
      56.558794
    ],
    [
      36.394551,
      56.557259
    ],
    [
      36.384384,
      56.554517
    ],
    [
      36.376367,
      56.55969
    ],
    [
      36.376078,
      56.563279
    ],
    [
      36.375298,
      56.563216
    ],
    [
      36.372509,
      56.562753
    ],
    [
      36.369439,
      56.55984
    ],
    [
      36.360564,
      56.559637
    ],
    [
      36.359918,
      56.559618
    ],
    [
      36.3584551119511,
      56.558978745418955
    ],
    [
      36.357641,
      56.559089
    ],
    [
      36.356497,
      56.559073
    ],
    [
      36.346407,
      56.555555
    ],
    [
      36.344251,
      56.552672
    ],
    [
      36.33788321812195,
      56.54532235677348
    ],
    [
      36.330437,
      56.5437
    ],
    [
      36.329112744204636,
      56.542977678657074
    ],
    [
      36.325644,
      56.543117
    ],
    [
      36.324573,
      56.542454
    ],
    [
      36.32544666996519,
      56.540978001799196
    ],
    [
      36.320636,
      56.538354
    ],
    [
      36.315535,
      56.537835
    ],
    [
      36.305749,
      56.533006
    ],
    [
      36.300325,
      56.531206
    ],
    [
      36.300113,
      56.530626
    ],
    [
      36.299929064266784,
      56.52996922525638
    ],
    [
      36.293484,
      56.528059
    ],
    [
      36.285072502523384,
      56.52509258786709
    ],
    [
      36.285067698073554,
      56.52509117745763
    ],
    [
      36.275712,
      56.53059
    ],
    [
      36.27476465580829,
      56.53284205287554
    ],
    [
      36.274678,
      56.533111
    ],
    [
      36.27464113979024,
      56.53313567857785
    ],
    [
      36.273692,
      56.535392
    ],
    [
      36.268565,
      56.536675
    ],
    [
      36.26354,
      56.530206
    ],
    [
      36.26195749947997,
      56.52874437049538
    ],
    [
      36.252857,
      56.524464
    ],
    [
      36.248427,
      56.523227
    ],
    [
      36.240373,
      56.524511
    ],
    [
      36.236582,
      56.527064
    ],
    [
      36.22725536875056,
      56.53397378967673
    ],
    [
      36.225542,
      56.535882
    ],
    [
      36.225017096651484,
      56.535850442011665
    ],
    [
      36.224201,
      56.536538
    ],
    [
      36.220338122520666,
      56.53556913496446
    ],
    [
      36.219288,
      56.535506
    ],
    [
      36.21922767921447,
      56.53529061986812
    ],
    [
      36.218368,
      56.535075
    ],
    [
      36.21831142571406,
      56.534902881128964
    ],
    [
      36.218211,
      56.534875
    ],
    [
      36.214779,
      56.524156
    ],
    [
      36.212334684286084,
      56.5224836346471
    ],
    [
      36.207875,
      56.520568
    ],
    [
      36.207747,
      56.519979
    ],
    [
      36.211264,
      56.51921
    ],
    [
      36.219314645262386,
      56.51504353422612
    ],
    [
      36.213952,
      56.506493
    ],
    [
      36.20943,
      56.505719
    ],
    [
      36.205947,
      56.506507
    ],
    [
      36.202424,
      56.506139
    ],
    [
      36.19685,
      56.503949
    ],
    [
      36.193026,
      56.502931
    ],
    [
      36.187933,
      56.500991
    ],
    [
      36.179204,
      56.508371
    ],
    [
      36.175616,
      56.508068
    ],
    [
      36.174744,
      56.507722
    ],
    [
      36.168123,
      56.501107
    ],
    [
      36.165303,
      56.49993
    ],
    [
      36.16538292954334,
      56.49945550187481
    ],
    [
      36.163909,
      56.499065
    ],
    [
      36.160677,
      56.496237
    ],
    [
      36.151278,
      56.496468
    ],
    [
      36.150542478452984,
      56.49676941798686
    ],
    [
      36.147885,
      56.49913
    ],
    [
      36.143865,
      56.500342
    ],
    [
      36.137882,
      56.497608
    ],
    [
      36.133702,
      56.498198
    ],
    [
      36.132225929694805,
      56.509457076415586
    ],
    [
      36.132908843936384,
      56.510044519823715
    ],
    [
      36.136886,
      56.512003
    ],
    [
      36.141361,
      56.513597
    ],
    [
      36.147049,
      56.51521
    ],
    [
      36.151516,
      56.515663
    ],
    [
      36.152173123030195,
      56.51567942807576
    ],
    [
      36.153905,
      56.515493
    ],
    [
      36.158494,
      56.51601
    ],
    [
      36.158690439302305,
      56.51603816051092
    ],
    [
      36.16121,
      56.516287
    ],
    [
      36.1611834607638,
      56.51639554702804
    ],
    [
      36.162226,
      56.516545
    ],
    [
      36.160355,
      56.519784
    ],
    [
      36.155695,
      56.51936
    ],
    [
      36.150112,
      56.519512
    ],
    [
      36.14937203769423,
      56.51936283868796
    ],
    [
      36.149363,
      56.519404
    ],
    [
      36.146947976995904,
      56.518874197480955
    ],
    [
      36.144541,
      56.518389
    ],
    [
      36.14399730734326,
      56.51821572414745
    ],
    [
      36.143994,
      56.518226
    ],
    [
      36.141523,
      56.525507
    ],
    [
      36.140085,
      56.525548
    ],
    [
      36.135829,
      56.525849
    ],
    [
      36.132345,
      56.524965
    ],
    [
      36.120865,
      56.521848
    ],
    [
      36.117093,
      56.522211
    ],
    [
      36.11631994205398,
      56.52203460700813
    ],
    [
      36.115301,
      56.523178
    ],
    [
      36.11415849663023,
      56.521541417814475
    ],
    [
      36.113267,
      56.521338
    ],
    [
      36.106648,
      56.515603
    ],
    [
      36.099992,
      56.512988
    ],
    [
      36.09343,
      56.511521
    ],
    [
      36.089433,
      56.510192
    ],
    [
      36.086636,
      56.506451
    ],
    [
      36.08642614039621,
      56.50634598074389
    ],
    [
      36.086412,
      56.506374
    ],
    [
      36.078169,
      56.502213
    ],
    [
      36.069927,
      56.498052
    ],
    [
      36.059964,
      56.495155
    ],
    [
      36.051973,
      56.493408
    ],
    [
      36.048392,
      56.492733
    ],
    [
      36.046977,
      56.491854
    ],
    [
      36.045958491682605,
      56.48959002200474
    ],
    [
      36.04572,
      56.489282
    ],
    [
      36.043961462258196,
      56.486240503905684
    ],
    [
      36.04203865021849,
      56.4840052658428
    ],
    [
      36.034738,
      56.479515
    ],
    [
      36.026233,
      56.481992
    ],
    [
      36.022502,
      56.48259
    ],
    [
      36.01888047604762,
      56.48293258974234
    ],
    [
      36.01861155991375,
      56.48296911155654
    ],
    [
      36.0134980247308,
      56.48378731167265
    ],
    [
      36.01032,
      56.484373
    ],
    [
      36.00677937817523,
      56.48536344782753
    ],
    [
      35.999274,
      56.489377
    ],
    [
      35.992117,
      56.496682
    ],
    [
      35.983871,
      56.505047
    ],
    [
      35.985284,
      56.508358
    ],
    [
      35.984161,
      56.508837
    ],
    [
      35.980215,
      56.508564
    ],
    [
      35.974914,
      56.517854
    ],
    [
      35.975547,
      56.519724
    ],
    [
      35.975503,
      56.520303
    ],
    [
      35.974894,
      56.521454
    ],
    [
      35.972623,
      56.521768
    ],
    [
      35.96904,
      56.521412
    ],
    [
      35.963023,
      56.515607
    ],
    [
      35.95520412557731,
      56.51383439274897
    ],
    [
      35.951539502173574,
      56.51693595587505
    ],
    [
      35.950167,
      56.518976
    ],
    [
      35.94897904477495,
      56.51954017005853
    ],
    [
      35.947649,
      56.521627
    ],
    [
      35.946341,
      56.520793
    ],
    [
      35.93651,
      56.517733
    ],
    [
      35.934238,
      56.520525
    ],
    [
      35.924725,
      56.521497
    ],
    [
      35.92379,
      56.518021
    ],
    [
      35.92426726255616,
      56.517014611557144
    ],
    [
      35.922842,
      56.516877
    ],
    [
      35.922668,
      56.513281
    ],
    [
      35.924843582336756,
      56.506890226885794
    ],
    [
      35.92259807303651,
      56.502234846264365
    ],
    [
      35.918194,
      56.501118
    ],
    [
      35.913024,
      56.500452
    ],
    [
      35.907457,
      56.499929
    ],
    [
      35.903647822182954,
      56.49995809115204
    ],
    [
      35.90241380038279,
      56.50009206690895
    ],
    [
      35.899352302642306,
      56.500643437386536
    ],
    [
      35.89904170875778,
      56.50072792812834
    ],
    [
      35.892181,
      56.510028
    ],
    [
      35.891411,
      56.513915
    ],
    [
      35.88877449374935,
      56.51375376023697
    ],
    [
      35.888747,
      56.513904
    ],
    [
      35.883026,
      56.514869
    ],
    [
      35.882489,
      56.512962
    ],
    [
      35.875077420052385,
      56.50897709083821
    ],
    [
      35.868126,
      56.51127
    ],
    [
      35.86756224181914,
      56.511386603723466
    ],
    [
      35.866979,
      56.511633
    ],
    [
      35.862711,
      56.51239
    ],
    [
      35.856612,
      56.518561
    ],
    [
      35.853684,
      56.522878
    ],
    [
      35.844716,
      56.530738
    ],
    [
      35.838859,
      56.532185
    ],
    [
      35.8355993986149,
      56.533073478428165
    ],
    [
      35.82935,
      56.536059
    ],
    [
      35.831057,
      56.53961
    ],
    [
      35.83135,
      56.540087
    ],
    [
      35.828282,
      56.541969
    ],
    [
      35.827989,
      56.541492
    ],
    [
      35.821561,
      56.535367
    ],
    [
      35.811989,
      56.536916
    ],
    [
      35.810501,
      56.537667
    ],
    [
      35.809721,
      56.537877
    ],
    [
      35.80882191215504,
      56.5370888826951
    ],
    [
      35.803432,
      56.539325
    ],
    [
      35.801839,
      56.540145
    ],
    [
      35.800192,
      56.536944
    ],
    [
      35.801785,
      56.536124
    ],
    [
      35.803297,
      56.53526
    ],
    [
      35.806892,
      56.533106
    ],
    [
      35.80207204983314,
      56.52312635874356
    ],
    [
      35.801011469916766,
      56.523157208635915
    ],
    [
      35.798432,
      56.523401
    ],
    [
      35.79624316728185,
      56.523295907870875
    ],
    [
      35.79624,
      56.523296
    ],
    [
      35.794079,
      56.523192
    ],
    [
      35.784848,
      56.526235
    ],
    [
      35.779238,
      56.525038
    ],
    [
      35.77923769445849,
      56.52503286861596
    ],
    [
      35.7792,
      56.52504
    ],
    [
      35.775263,
      56.525434
    ],
    [
      35.766096,
      56.531083
    ],
    [
      35.756629,
      56.529685
    ],
    [
      35.755083,
      56.526433
    ],
    [
      35.758597,
      56.524763
    ],
    [
      35.76211,
      56.523093
    ],
    [
      35.7674,
      56.524383
    ],
    [
      35.77374,
      56.522016
    ],
    [
      35.777691,
      56.521553
    ],
    [
      35.781234,
      56.521103
    ],
    [
      35.78699713321087,
      56.52007366584012
    ],
    [
      35.787019,
      56.519371
    ],
    [
      35.79034897873707,
      56.5194750037977
    ],
    [
      35.790349,
      56.519475
    ],
    [
      35.79060521625174,
      56.51948293796771
    ],
    [
      35.802029,
      56.516206
    ],
    [
      35.808072,
      56.516158
    ],
    [
      35.812563,
      56.507578
    ],
    [
      35.81553427696296,
      56.5002376346802
    ],
    [
      35.81232905216213,
      56.49542433014453
    ],
    [
      35.809402,
      56.492943
    ],
    [
      35.799462,
      56.488083
    ],
    [
      35.794845,
      56.488442
    ],
    [
      35.790855,
      56.488671
    ],
    [
      35.783338,
      56.489757
    ],
    [
      35.77426,
      56.486813
    ],
    [
      35.77427621104101,
      56.48674196285398
    ],
    [
      35.768541,
      56.485409
    ],
    [
      35.76452601778348,
      56.483903874958344
    ],
    [
      35.761633,
      56.483141
    ],
    [
      35.75895692640655,
      56.48260114944552
    ],
    [
      35.757291745150475,
      56.48241557523495
    ],
    [
      35.75338,
      56.482398
    ],
    [
      35.751271,
      56.49369
    ],
    [
      35.75127098085407,
      56.493690054778625
    ],
    [
      35.7551,
      56.495028
    ],
    [
      35.762537,
      56.497668
    ],
    [
      35.771096,
      56.501488
    ],
    [
      35.771628,
      56.503263
    ],
    [
      35.768179,
      56.504295
    ],
    [
      35.761333,
      56.501061
    ],
    [
      35.753896,
      56.498421
    ],
    [
      35.75008304133895,
      56.49708888172467
    ],
    [
      35.750083,
      56.497089
    ],
    [
      35.749240827292084,
      56.49679464073669
    ],
    [
      35.74664,
      56.495886
    ],
    [
      35.738709,
      56.493347
    ],
    [
      35.728639,
      56.499272
    ],
    [
      35.729135,
      56.502838
    ],
    [
      35.727055,
      56.503127
    ],
    [
      35.724976,
      56.503416
    ],
    [
      35.724426,
      56.503513
    ],
    [
      35.720584,
      56.502841
    ],
    [
      35.708928,
      56.505469
    ],
    [
      35.708062,
      56.505672
    ],
    [
      35.707591,
      56.505445
    ],
    [
      35.707305,
      56.505081
    ],
    [
      35.707243,
      56.502166
    ],
    [
      35.70631,
      56.497308
    ],
    [
      35.701056,
      56.486901
    ],
    [
      35.694037,
      56.485089
    ],
    [
      35.685579,
      56.485279
    ],
    [
      35.682459,
      56.485665
    ],
    [
      35.681429,
      56.485765
    ],
    [
      35.681081,
      56.482182
    ],
    [
      35.682788,
      56.479048
    ],
    [
      35.685443,
      56.480207
    ],
    [
      35.694354,
      56.479259
    ],
    [
      35.697882,
      56.477064
    ],
    [
      35.701127,
      56.475042
    ],
    [
      35.69847,
      56.46358
    ],
    [
      35.689496,
      56.462285
    ],
    [
      35.686707,
      56.464034
    ],
    [
      35.684794,
      56.460985
    ],
    [
      35.685002,
      56.458479
    ],
    [
      35.685719,
      56.454951
    ],
    [
      35.686816,
      56.453849
    ],
    [
      35.690146,
      56.453426
    ],
    [
      35.693675,
      56.452712
    ],
    [
      35.69643,
      56.455152
    ],
    [
      35.705636,
      56.461766
    ],
    [
      35.709217,
      56.4624
    ],
    [
      35.71213060015481,
      56.463212118844936
    ],
    [
      35.712401,
      56.463026
    ],
    [
      35.723502,
      56.4624
    ],
    [
      35.72405021656509,
      56.46282894507584
    ],
    [
      35.725131,
      56.462789
    ],
    [
      35.7267624926506,
      56.46464613504966
    ],
    [
      35.737664,
      56.466109
    ],
    [
      35.747902,
      56.466625
    ],
    [
      35.753138,
      56.457219
    ],
    [
      35.749616,
      56.452966
    ],
    [
      35.752525,
      56.450779
    ],
    [
      35.75937,
      56.443424
    ],
    [
      35.755582,
      56.434804
    ],
    [
      35.747819,
      56.435502
    ],
    [
      35.747683,
      56.43534
    ],
    [
      35.746633,
      56.430212
    ],
    [
      35.747859,
      56.4288
    ],
    [
      35.748168216942105,
      56.42846824690788
    ],
    [
      35.746726,
      56.427039
    ],
    [
      35.738917,
      56.425102
    ],
    [
      35.735132,
      56.425669
    ],
    [
      35.731409,
      56.426062
    ],
    [
      35.727981,
      56.425717
    ],
    [
      35.726412,
      56.425502
    ],
    [
      35.726901,
      56.421935
    ],
    [
      35.736486,
      56.421793
    ],
    [
      35.740171,
      56.421371
    ],
    [
      35.743823,
      56.421462
    ],
    [
      35.748897,
      56.424168
    ],
    [
      35.754513,
      56.429747
    ],
    [
      35.760506,
      56.433626
    ],
    [
      35.762375,
      56.437068
    ],
    [
      35.76361638913787,
      56.44831833853729
    ],
    [
      35.764038,
      56.449986
    ],
    [
      35.764735,
      56.454387
    ],
    [
      35.765180196693244,
      56.45564048943107
    ],
    [
      35.769301,
      56.461026
    ],
    [
      35.778016,
      56.467074
    ],
    [
      35.786033,
      56.466775
    ],
    [
      35.790415,
      56.466424
    ],
    [
      35.796604,
      56.467783
    ],
    [
      35.800465,
      56.468079
    ],
    [
      35.8051,
      56.468269
    ],
    [
      35.809727,
      56.468176
    ],
    [
      35.814555,
      56.468315
    ],
    [
      35.815365979772764,
      56.46835937666677
    ],
    [
      35.815366,
      56.468359
    ],
    [
      35.818959,
      56.468552
    ],
    [
      35.819873,
      56.468606
    ],
    [
      35.823614,
      56.469105
    ],
    [
      35.831382,
      56.463681
    ],
    [
      35.837829,
      56.454061
    ],
    [
      35.837942725444414,
      56.45401941067692
    ],
    [
      35.838039,
      56.453187
    ],
    [
      35.833245,
      56.445306
    ],
    [
      35.8279543397435,
      56.444711988743656
    ],
    [
      35.827548,
      56.444736
    ],
    [
      35.82733604924965,
      56.444642569900786
    ],
    [
      35.827233,
      56.444631
    ],
    [
      35.82723595253682,
      56.44459844622679
    ],
    [
      35.824093,
      56.443213
    ],
    [
      35.826351,
      56.433975
    ],
    [
      35.828402,
      56.425997
    ],
    [
      35.830967,
      56.419404
    ],
    [
      35.8340637921943,
      56.418892665672416
    ],
    [
      35.838827,
      56.4174
    ],
    [
      35.842537,
      56.416566
    ],
    [
      35.846084,
      56.414432
    ],
    [
      35.848366,
      56.40488
    ],
    [
      35.846177,
      56.40172
    ],
    [
      35.844971,
      56.399571
    ],
    [
      35.847234,
      56.396771
    ],
    [
      35.84742,
      56.396625
    ],
    [
      35.847655,
      56.396656
    ],
    [
      35.849014,
      56.39857
    ],
    [
      35.855978,
      56.408119
    ],
    [
      35.853369,
      56.411812
    ],
    [
      35.851314,
      56.415526
    ],
    [
      35.847571,
      56.417624
    ],
    [
      35.843948,
      56.419878
    ],
    [
      35.839099,
      56.42099
    ],
    [
      35.83275133486409,
      56.42648821425068
    ],
    [
      35.831084,
      56.432125
    ],
    [
      35.83562620099597,
      56.440029831541345
    ],
    [
      35.845839,
      56.441968
    ],
    [
      35.85631,
      56.445861
    ],
    [
      35.856689,
      56.449441
    ],
    [
      35.85315,
      56.457201
    ],
    [
      35.85714945250616,
      56.4650468710345
    ],
    [
      35.861287,
      56.465209
    ],
    [
      35.86494,
      56.465525
    ],
    [
      35.86890597568261,
      56.47338841132985
    ],
    [
      35.870565,
      56.473902
    ],
    [
      35.877693,
      56.475574
    ],
    [
      35.881302,
      56.476741
    ],
    [
      35.885523,
      56.477894
    ],
    [
      35.88858568709124,
      56.47869707657396
    ],
    [
      35.88967905307433,
      56.478861686764496
    ],
    [
      35.895801,
      56.479481
    ],
    [
      35.897208377526546,
      56.4796514234207
    ],
    [
      35.903766,
      56.478412
    ],
    [
      35.90805,
      56.478014
    ],
    [
      35.9100994049461,
      56.480140897638194
    ],
    [
      35.917695,
      56.48122
    ],
    [
      35.921013,
      56.47338
    ],
    [
      35.92115714945569,
      56.473162689262786
    ],
    [
      35.919184,
      56.470828
    ],
    [
      35.918052,
      56.469885
    ],
    [
      35.920356,
      56.467119
    ],
    [
      35.921487,
      56.468061
    ],
    [
      35.92431782221222,
      56.470206697547006
    ],
    [
      35.926584,
      56.469908
    ],
    [
      35.929368,
      56.472191
    ],
    [
      35.932109,
      56.479863
    ],
    [
      35.93856915249274,
      56.486319354637075
    ],
    [
      35.93921587870068,
      56.48671188434149
    ],
    [
      35.948405,
      56.488195
    ],
    [
      35.952961,
      56.487052
    ],
    [
      35.956997,
      56.487254
    ],
    [
      35.967135,
      56.481163
    ],
    [
      35.96992,
      56.477256
    ],
    [
      35.97229,
      56.479966
    ],
    [
      35.975908,
      56.489209
    ],
    [
      35.98378473555244,
      56.494219545106375
    ],
    [
      35.987759,
      56.493686
    ],
    [
      35.990941,
      56.49063
    ],
    [
      35.99510239550822,
      56.48761567443299
    ],
    [
      35.995062,
      56.487536
    ],
    [
      36.000139,
      56.483934
    ],
    [
      36.003319,
      56.481912
    ],
    [
      36.007203,
      56.481304
    ],
    [
      36.012176,
      56.480355
    ],
    [
      36.02088992385027,
      56.47765686911202
    ],
    [
      36.021,
      56.477275
    ],
    [
      36.023179,
      56.475102
    ],
    [
      36.03321353709549,
      56.469000281479154
    ],
    [
      36.034559,
      56.467764
    ],
    [
      36.039821,
      56.46293
    ],
    [
      36.046099,
      56.456748
    ],
    [
      36.054089,
      56.449119
    ],
    [
      36.054936336716516,
      56.447750116972095
    ],
    [
      36.054872,
      56.447709
    ],
    [
      36.05769887459897,
      56.44328720258693
    ],
    [
      36.057699,
      56.443287
    ],
    [
      36.058663229680185,
      56.44177875342471
    ],
    [
      36.061437732135026,
      56.436494527616155
    ],
    [
      36.05772325440546,
      56.429421147423554
    ],
    [
      36.050463,
      56.429928
    ],
    [
      36.046681,
      56.430095
    ],
    [
      36.043186,
      56.429551
    ],
    [
      36.037127,
      56.421703
    ],
    [
      36.03464159681304,
      56.42121645716833
    ],
    [
      36.03437,
      56.421175
    ],
    [
      36.030475,
      56.420597
    ],
    [
      36.026407,
      56.419809
    ],
    [
      36.02553013474667,
      56.41957724603689
    ],
    [
      36.023905,
      56.419274
    ],
    [
      36.021755,
      56.418807
    ],
    [
      36.021512,
      56.418646
    ],
    [
      36.02158570385481,
      56.41853473980469
    ],
    [
      36.021284,
      56.418455
    ],
    [
      36.017194,
      56.417611
    ],
    [
      36.012814,
      56.416655
    ],
    [
      36.007159,
      56.415299
    ],
    [
      36.004842,
      56.41441
    ],
    [
      36.002788,
      56.412267
    ],
    [
      36.002769,
      56.41112
    ],
    [
      36.00297558722614,
      56.41104664496355
    ],
    [
      36.002156,
      56.409678
    ],
    [
      35.993997,
      56.404118
    ],
    [
      35.989358,
      56.402496
    ],
    [
      35.983938,
      56.400192
    ],
    [
      35.973611,
      56.397904
    ],
    [
      35.96861,
      56.396064
    ],
    [
      35.965122,
      56.394668
    ],
    [
      35.964426,
      56.394175
    ],
    [
      35.963785,
      56.393465
    ],
    [
      35.963951658791444,
      56.393314495634826
    ],
    [
      35.962662,
      56.391823
    ],
    [
      35.953341,
      56.387154
    ],
    [
      35.949516,
      56.387609
    ],
    [
      35.948398,
      56.387602
    ],
    [
      35.94778,
      56.387486
    ],
    [
      35.947817007592974,
      56.38728881195189
    ],
    [
      35.9456,
      56.386871
    ],
    [
      35.93611,
      56.386307
    ],
    [
      35.93136,
      56.386963
    ],
    [
      35.925984,
      56.395398
    ],
    [
      35.925545,
      56.404705
    ],
    [
      35.925107,
      56.414011
    ],
    [
      35.921511,
      56.413842
    ],
    [
      35.921949,
      56.404535
    ],
    [
      35.922387,
      56.395229
    ],
    [
      35.922877,
      56.388984
    ],
    [
      35.919354,
      56.380363
    ],
    [
      35.917763,
      56.376941
    ],
    [
      35.919348,
      56.368826
    ],
    [
      35.919638741479936,
      56.364494264574176
    ],
    [
      35.918658911307844,
      56.362292629310815
    ],
    [
      35.917827,
      56.362481
    ],
    [
      35.91737440580272,
      56.36048390811359
    ],
    [
      35.917374,
      56.360484
    ],
    [
      35.916865470229126,
      56.35823860224926
    ],
    [
      35.914717,
      56.349254
    ],
    [
      35.913159,
      56.342833
    ],
    [
      35.91225123619823,
      56.33875326663358
    ],
    [
      35.912234,
      56.338757
    ],
    [
      35.911588518380036,
      56.3357761942383
    ],
    [
      35.90950647187902,
      56.332292880687305
    ],
    [
      35.90486015943702,
      56.32778870296032
    ],
    [
      35.89912600801459,
      56.32608234183988
    ],
    [
      35.89160631025516,
      56.32423179900054
    ],
    [
      35.889677,
      56.323909
    ],
    [
      35.88926180550606,
      56.32376580812294
    ],
    [
      35.889187,
      56.323752
    ],
    [
      35.88887805924474,
      56.32363346208206
    ],
    [
      35.88480693483846,
      56.32222941661912
    ],
    [
      35.877639,
      56.320321
    ],
    [
      35.87763513108443,
      56.32031541174544
    ],
    [
      35.877358,
      56.320258
    ],
    [
      35.871971,
      56.31921
    ],
    [
      35.864677,
      56.316957
    ],
    [
      35.854142,
      56.314479
    ],
    [
      35.852393,
      56.314588
    ],
    [
      35.85239223314344,
      56.314575699483854
    ],
    [
      35.852098,
      56.314592
    ],
    [
      35.84729642962841,
      56.312717231958295
    ],
    [
      35.847211,
      56.312854
    ],
    [
      35.84305790256968,
      56.3107981842986
    ],
    [
      35.839285,
      56.308983
    ],
    [
      35.83908291840366,
      56.30883053605571
    ],
    [
      35.838898,
      56.308739
    ],
    [
      35.83838091242154,
      56.308300895539745
    ],
    [
      35.836263,
      56.306703
    ],
    [
      35.826145,
      56.30108
    ],
    [
      35.82511810070288,
      56.30005421447652
    ],
    [
      35.823835,
      56.299267
    ],
    [
      35.818154,
      56.292057
    ],
    [
      35.81800044201548,
      56.29159180148766
    ],
    [
      35.81794,
      56.291615
    ],
    [
      35.817236,
      56.289783
    ],
    [
      35.817211637679236,
      56.28969261415491
    ],
    [
      35.817199,
      56.289673
    ],
    [
      35.817193802505194,
      56.28962644446221
    ],
    [
      35.816491,
      56.287019
    ],
    [
      35.808949,
      56.279528
    ],
    [
      35.803359,
      56.279138
    ],
    [
      35.802119,
      56.278931
    ],
    [
      35.801172,
      56.278603
    ],
    [
      35.80121087593127,
      56.27849095165911
    ],
    [
      35.799986,
      56.278122
    ],
    [
      35.795789,
      56.276616
    ],
    [
      35.792774,
      56.274777
    ],
    [
      35.789984,
      56.272645
    ],
    [
      35.782058483177615,
      56.269674517190595
    ],
    [
      35.77846333842363,
      56.27011029231229
    ],
    [
      35.777174,
      56.272177
    ],
    [
      35.774872,
      56.275219
    ],
    [
      35.767807,
      56.280134
    ],
    [
      35.765959,
      56.281815
    ],
    [
      35.764112,
      56.283495
    ],
    [
      35.76411032368038,
      56.283493157571094
    ],
    [
      35.763656,
      56.283906
    ],
    [
      35.760716,
      56.286921
    ],
    [
      35.755944,
      56.292719
    ],
    [
      35.755901224367385,
      56.29268921344743
    ],
    [
      35.755556,
      56.293167
    ],
    [
      35.753812,
      56.29642
    ],
    [
      35.754,
      56.306175
    ],
    [
      35.75938,
      56.310668
    ],
    [
      35.763008,
      56.311621
    ],
    [
      35.763864,
      56.314838
    ],
    [
      35.76339846719672,
      56.31657026154696
    ],
    [
      35.76342,
      56.316714
    ],
    [
      35.7611990540556,
      56.317446527914946
    ],
    [
      35.760753,
      56.318883
    ],
    [
      35.76812599941227,
      56.327775999291106
    ],
    [
      35.771722,
      56.328798
    ],
    [
      35.77172137514275,
      56.32880019906572
    ],
    [
      35.771998,
      56.328879
    ],
    [
      35.775824,
      56.331925
    ],
    [
      35.781732,
      56.334992
    ],
    [
      35.782953,
      56.335509
    ],
    [
      35.78247050528548,
      56.336648223631514
    ],
    [
      35.788515,
      56.337691
    ],
    [
      35.797791,
      56.331333
    ],
    [
      35.799369,
      56.331919
    ],
    [
      35.80832,
      56.333944
    ],
    [
      35.808572,
      56.334104
    ],
    [
      35.80696766155688,
      56.34335175158919
    ],
    [
      35.807186,
      56.346993
    ],
    [
      35.807338197453916,
      56.347520388540886
    ],
    [
      35.807867,
      56.349352
    ],
    [
      35.804409,
      56.35035
    ],
    [
      35.80425908183395,
      56.34983097638222
    ],
    [
      35.804259,
      56.349831
    ],
    [
      35.80400959090865,
      56.34896722727119
    ],
    [
      35.803803,
      56.348252
    ],
    [
      35.80075,
      56.344157
    ],
    [
      35.795172,
      56.343085
    ],
    [
      35.791118,
      56.351747
    ],
    [
      35.791304,
      56.352581
    ],
    [
      35.791399,
      56.353132
    ],
    [
      35.79131893324943,
      56.35314585987732
    ],
    [
      35.791537,
      56.354664
    ],
    [
      35.791521,
      56.355973
    ],
    [
      35.78969728313672,
      56.35595121671525
    ],
    [
      35.789524,
      56.356464
    ],
    [
      35.789242551943516,
      56.35594578520377
    ],
    [
      35.787921,
      56.35593
    ],
    [
      35.786343,
      56.350607
    ],
    [
      35.774554,
      56.349488
    ],
    [
      35.769076,
      56.352685
    ],
    [
      35.767963,
      56.353312
    ],
    [
      35.767960336357824,
      56.35330844909454
    ],
    [
      35.767647,
      56.35349
    ],
    [
      35.764704,
      56.357398
    ],
    [
      35.761377,
      56.361479
    ],
    [
      35.755681,
      56.354988
    ],
    [
      35.751214,
      56.354788
    ],
    [
      35.74478,
      56.354499
    ],
    [
      35.736903,
      56.353918
    ],
    [
      35.728053,
      56.355403
    ],
    [
      35.718243,
      56.355127
    ],
    [
      35.717453,
      56.353279
    ],
    [
      35.71711,
      56.352441
    ],
    [
      35.720684,
      56.345736
    ],
    [
      35.717381,
      56.337985
    ],
    [
      35.71717110950143,
      56.337755135028
    ],
    [
      35.717153,
      56.337774
    ],
    [
      35.716178,
      56.336838
    ],
    [
      35.706523,
      56.330377
    ],
    [
      35.701605,
      56.328904
    ],
    [
      35.698794,
      56.326556
    ],
    [
      35.701518,
      56.324202
    ],
    [
      35.70549844997427,
      56.32274906543342
    ],
    [
      35.705457,
      56.322593
    ],
    [
      35.707178,
      56.322136
    ],
    [
      35.712733,
      56.320964
    ],
    [
      35.7207033116308,
      56.318633785976246
    ],
    [
      35.721037,
      56.318468
    ],
    [
      35.723403,
      56.317294
    ],
    [
      35.725639,
      56.31629
    ],
    [
      35.730555,
      56.314528
    ],
    [
      35.735007450386796,
      56.313452640318076
    ],
    [
      35.739761,
      56.304186
    ],
    [
      35.738367,
      56.302177
    ],
    [
      35.738388,
      56.300875
    ],
    [
      35.738506192751125,
      56.30086303298395
    ],
    [
      35.738408,
      56.300603
    ],
    [
      35.738509,
      56.296744
    ],
    [
      35.741785,
      56.295251
    ],
    [
      35.752328,
      56.291573
    ],
    [
      35.754687,
      56.288224
    ],
    [
      35.758282,
      56.284269
    ],
    [
      35.761077,
      56.281394
    ],
    [
      35.755557,
      56.272388
    ],
    [
      35.75163,
      56.270045
    ],
    [
      35.757376,
      56.267689
    ],
    [
      35.75868314652747,
      56.267507724875884
    ],
    [
      35.759405,
      56.267289
    ],
    [
      35.76337098501265,
      56.25720103812213
    ],
    [
      35.76313988892395,
      56.25700888891804
    ],
    [
      35.758571,
      56.253211
    ],
    [
      35.751857156430034,
      56.24685999987793
    ],
    [
      35.75077580287797,
      56.246464248493126
    ],
    [
      35.750075,
      56.247513
    ],
    [
      35.74899,
      56.24732
    ],
    [
      35.745725,
      56.241335
    ],
    [
      35.738039617789056,
      56.23641663478087
    ],
    [
      35.731184,
      56.235977
    ],
    [
      35.724354,
      56.235548
    ],
    [
      35.721988,
      56.235412
    ],
    [
      35.722195,
      56.231818
    ],
    [
      35.72220197689202,
      56.231818401208166
    ],
    [
      35.722202,
      56.231818
    ],
    [
      35.72456,
      56.231954
    ],
    [
      35.731409,
      56.232384
    ],
    [
      35.742402,
      56.227776
    ],
    [
      35.749098,
      56.226369
    ],
    [
      35.751769,
      56.228763
    ],
    [
      35.762067,
      56.228134
    ],
    [
      35.773042,
      56.227607
    ],
    [
      35.774213374891026,
      56.227746082165396
    ],
    [
      35.774623,
      56.227733
    ],
    [
      35.77972655827459,
      56.22719105513395
    ],
    [
      35.787769,
      56.224016
    ],
    [
      35.791362,
      56.22058
    ],
    [
      35.792611023271164,
      56.219386024337446
    ],
    [
      35.792611,
      56.219386
    ],
    [
      35.788138,
      56.213583
    ],
    [
      35.788979,
      56.210082
    ],
    [
      35.79406290647792,
      56.2113043888608
    ],
    [
      35.794063,
      56.211304
    ],
    [
      35.804348,
      56.20802
    ],
    [
      35.806344222407674,
      56.20361165634701
    ],
    [
      35.80485859254715,
      56.19679263372696
    ],
    [
      35.80352227610581,
      56.194528353496146
    ],
    [
      35.798582005039265,
      56.19440220440749
    ],
    [
      35.796483,
      56.194498
    ],
    [
      35.79648297023358,
      56.19449735127255
    ],
    [
      35.793267,
      56.194644
    ],
    [
      35.78417,
      56.19624
    ],
    [
      35.779452,
      56.197485
    ],
    [
      35.777523,
      56.194445
    ],
    [
      35.77652283707517,
      56.19031969031296
    ],
    [
      35.769074,
      56.18974
    ],
    [
      35.762816,
      56.189432
    ],
    [
      35.762993,
      56.185837
    ],
    [
      35.769251,
      56.186144
    ],
    [
      35.774123,
      56.186424
    ],
    [
      35.77716556615782,
      56.18680820990007
    ],
    [
      35.777198,
      56.186647
    ],
    [
      35.777602,
      56.186728
    ],
    [
      35.778328,
      56.186955
    ],
    [
      35.78066402645348,
      56.187919351500156
    ],
    [
      35.780665,
      56.187917
    ],
    [
      35.78274552179636,
      56.18877833341161
    ],
    [
      35.78431224806553,
      56.189121588756755
    ],
    [
      35.792684,
      56.187045
    ],
    [
      35.793726,
      56.186275
    ],
    [
      35.793833,
      56.186246
    ],
    [
      35.804583,
      56.190877
    ],
    [
      35.80506598547597,
      56.19105042007238
    ],
    [
      35.805346,
      56.191084
    ],
    [
      35.808081778190456,
      56.19213326627966
    ],
    [
      35.808237,
      56.192189
    ],
    [
      35.81123304467986,
      56.19333423720255
    ],
    [
      35.812699,
      56.193733
    ],
    [
      35.81422,
      56.194476
    ],
    [
      35.816004,
      56.19516
    ],
    [
      35.81784129532643,
      56.19676514609746
    ],
    [
      35.82935,
      56.178932
    ],
    [
      35.827775283824295,
      56.1784535883438
    ],
    [
      35.827773,
      56.178461
    ],
    [
      35.825805,
      56.177855
    ],
    [
      35.815467,
      56.171781
    ],
    [
      35.81547775265,
      56.17172195211426
    ],
    [
      35.814776,
      56.171672
    ],
    [
      35.814228,
      56.168041
    ],
    [
      35.81612206908718,
      56.168183659276195
    ],
    [
      35.817138,
      56.1626
    ],
    [
      35.813863,
      56.152524
    ],
    [
      35.8138261234938,
      56.15248486084817
    ],
    [
      35.813606,
      56.152536
    ],
    [
      35.80987,
      56.148286
    ],
    [
      35.799508,
      56.146861
    ],
    [
      35.795874,
      56.150795
    ],
    [
      35.792677,
      56.153237
    ],
    [
      35.790615,
      56.154958
    ],
    [
      35.788308,
      56.152195
    ],
    [
      35.79037,
      56.150473
    ],
    [
      35.78758981938286,
      56.139612349656836
    ],
    [
      35.783329,
      56.139771
    ],
    [
      35.779067,
      56.140421
    ],
    [
      35.774082,
      56.141202
    ],
    [
      35.770642,
      56.142016
    ],
    [
      35.766874,
      56.142579
    ],
    [
      35.759380281867834,
      56.146407788653796
    ],
    [
      35.756599,
      56.149048
    ],
    [
      35.755547,
      56.153228
    ],
    [
      35.752352,
      56.151427
    ],
    [
      35.750756153803664,
      56.146594557926754
    ],
    [
      35.74272412536394,
      56.1422204375786
    ],
    [
      35.736558019424336,
      56.14278299822784
    ],
    [
      35.73655799971643,
      56.142783000399845
    ],
    [
      35.731934,
      56.149303
    ],
    [
      35.727451,
      56.153204
    ],
    [
      35.72405,
      56.157704
    ],
    [
      35.728113770057995,
      56.167114883590955
    ],
    [
      35.729009,
      56.168233
    ],
    [
      35.72900446974123,
      56.1682484664467
    ],
    [
      35.73819,
      56.171264
    ],
    [
      35.741905,
      56.171557
    ],
    [
      35.74531212569372,
      56.172584415950155
    ],
    [
      35.745907,
      56.172252
    ],
    [
      35.746996,
      56.172476
    ],
    [
      35.747112595216734,
      56.173127346053704
    ],
    [
      35.750232,
      56.174068
    ],
    [
      35.749955,
      56.17806
    ],
    [
      35.748516,
      56.185111
    ],
    [
      35.748326,
      56.188706
    ],
    [
      35.74445249800347,
      56.19835761759067
    ],
    [
      35.745048,
      56.201465
    ],
    [
      35.739529,
      56.201401
    ],
    [
      35.732932,
      56.202703
    ],
    [
      35.727612,
      56.203645
    ],
    [
      35.723788,
      56.204347
    ],
    [
      35.71921,
      56.205736
    ],
    [
      35.714959,
      56.207197
    ],
    [
      35.711414,
      56.216432
    ],
    [
      35.710838,
      56.220096
    ],
    [
      35.706453,
      56.221365
    ],
    [
      35.702032,
      56.222517
    ],
    [
      35.69859,
      56.218695
    ],
    [
      35.694099,
      56.217217
    ],
    [
      35.694907059489054,
      56.21536648208614
    ],
    [
      35.692416,
      56.213728
    ],
    [
      35.688933,
      56.21071
    ],
    [
      35.686041,
      56.208025
    ],
    [
      35.682783,
      56.204353
    ],
    [
      35.680585,
      56.202928
    ],
    [
      35.668762,
      56.202766
    ],
    [
      35.66392158855604,
      56.20828833024122
    ],
    [
      35.663613,
      56.208952
    ],
    [
      35.665290306197775,
      56.21902885301333
    ],
    [
      35.66573938435545,
      56.2198586315319
    ],
    [
      35.669128,
      56.221567
    ],
    [
      35.677792,
      56.228689
    ],
    [
      35.682456,
      56.229887
    ],
    [
      35.685793,
      56.231238
    ],
    [
      35.687368,
      56.237416
    ],
    [
      35.694501,
      56.240017
    ],
    [
      35.691745,
      56.242539
    ],
    [
      35.689246,
      56.252666
    ],
    [
      35.691359,
      56.256139
    ],
    [
      35.695156,
      56.262131
    ],
    [
      35.701999,
      56.265419
    ],
    [
      35.70197414196871,
      56.26571721323826
    ],
    [
      35.70536723824728,
      56.26750191746542
    ],
    [
      35.70683,
      56.267622
    ],
    [
      35.7099833914617,
      56.26882183577008
    ],
    [
      35.713143,
      56.269724
    ],
    [
      35.716644,
      56.271556
    ],
    [
      35.725917,
      56.274621
    ],
    [
      35.727921,
      56.274686
    ],
    [
      35.727804,
      56.278284
    ],
    [
      35.7258,
      56.278219
    ],
    [
      35.719335,
      56.279152
    ],
    [
      35.715875,
      56.277512
    ],
    [
      35.712175,
      56.273192
    ],
    [
      35.709134,
      56.269884
    ],
    [
      35.699632,
      56.276543
    ],
    [
      35.696583,
      56.281474
    ],
    [
      35.69269798512385,
      56.28656336263709
    ],
    [
      35.69210584948251,
      56.295746347755525
    ],
    [
      35.695836,
      56.30079
    ],
    [
      35.699999,
      56.302781
    ],
    [
      35.703997,
      56.304708
    ],
    [
      35.706804,
      56.305933
    ],
    [
      35.707974,
      56.306381
    ],
    [
      35.706688,
      56.309743
    ],
    [
      35.705519,
      56.309296
    ],
    [
      35.702275,
      56.30787
    ],
    [
      35.69786519037631,
      56.30948690011069
    ],
    [
      35.698283,
      56.310019
    ],
    [
      35.698477,
      56.310267
    ],
    [
      35.695644,
      56.312488
    ],
    [
      35.68989538174152,
      56.31286397758619
    ],
    [
      35.691523,
      56.31702
    ],
    [
      35.689318,
      56.319866
    ],
    [
      35.684049,
      56.316444
    ],
    [
      35.68320419302535,
      56.31594980554445
    ],
    [
      35.6832,
      56.315955
    ],
    [
      35.680346,
      56.3148
    ],
    [
      35.676876,
      56.313842
    ],
    [
      35.6773,
      56.312305
    ],
    [
      35.67743,
      56.312001
    ],
    [
      35.6781992220427,
      56.31144320711666
    ],
    [
      35.677758,
      56.310277
    ],
    [
      35.669173,
      56.302534
    ],
    [
      35.66802448404912,
      56.30347288506636
    ],
    [
      35.668378,
      56.305414
    ],
    [
      35.664339,
      56.306054
    ],
    [
      35.660393263668425,
      56.30535600580905
    ],
    [
      35.65357891907628,
      56.306690407491594
    ],
    [
      35.64950880611551,
      56.3078156632846
    ],
    [
      35.654453,
      56.318373
    ],
    [
      35.658949,
      56.318381
    ],
    [
      35.658943,
      56.321981
    ],
    [
      35.647468,
      56.324225
    ],
    [
      35.645536,
      56.321163
    ],
    [
      35.638041053287296,
      56.323770406052596
    ],
    [
      35.635181,
      56.327693
    ],
    [
      35.635278,
      56.331431
    ],
    [
      35.635223,
      56.332112
    ],
    [
      35.635116,
      56.332698
    ],
    [
      35.634222823595024,
      56.332533792759214
    ],
    [
      35.633434,
      56.336519
    ],
    [
      35.634256,
      56.342411
    ],
    [
      35.635609,
      56.346985
    ],
    [
      35.636069,
      56.349979
    ],
    [
      35.636122,
      56.350963
    ],
    [
      35.63611826491006,
      56.35096319948185
    ],
    [
      35.636124,
      56.351008
    ],
    [
      35.63628,
      56.355962
    ],
    [
      35.63627513384048,
      56.35596215282824
    ],
    [
      35.636333,
      56.357859
    ],
    [
      35.644233,
      56.361527
    ],
    [
      35.648557,
      56.36096
    ],
    [
      35.652149,
      56.361209
    ],
    [
      35.652759,
      56.362131
    ],
    [
      35.653227,
      56.365701
    ],
    [
      35.650768,
      56.366023
    ],
    [
      35.64831,
      56.366345
    ],
    [
      35.642421,
      56.364589
    ],
    [
      35.635282,
      56.370907
    ],
    [
      35.633839,
      56.374205
    ],
    [
      35.632934,
      56.37536
    ],
    [
      35.628659,
      56.374608
    ],
    [
      35.621279,
      56.369318
    ],
    [
      35.620261,
      56.368429
    ],
    [
      35.621644,
      56.365105
    ],
    [
      35.623935,
      56.364499
    ],
    [
      35.626043,
      56.364232
    ],
    [
      35.632733,
      56.357865
    ],
    [
      35.63262,
      56.354114
    ],
    [
      35.632076,
      56.347674
    ],
    [
      35.630819,
      56.34348
    ],
    [
      35.621831,
      56.336099
    ],
    [
      35.615635,
      56.343667
    ],
    [
      35.615934,
      56.347705
    ],
    [
      35.614689,
      56.351887
    ],
    [
      35.613053,
      56.355514
    ],
    [
      35.609637,
      56.357643
    ],
    [
      35.607862,
      56.360775
    ],
    [
      35.598162,
      56.358355
    ],
    [
      35.596773,
      56.357891
    ],
    [
      35.59363,
      56.352582
    ],
    [
      35.593243,
      56.351839
    ],
    [
      35.597655,
      56.348562
    ],
    [
      35.600847,
      56.348528
    ],
    [
      35.611241,
      56.343563
    ],
    [
      35.611295980348956,
      56.343114795178074
    ],
    [
      35.611058,
      56.343211
    ],
    [
      35.605313,
      56.341149
    ],
    [
      35.604092,
      56.337763
    ],
    [
      35.604743,
      56.337528
    ],
    [
      35.608061,
      56.336839
    ],
    [
      35.616178,
      56.335463
    ],
    [
      35.619818,
      56.333009
    ],
    [
      35.622446,
      56.330548
    ],
    [
      35.631583,
      56.327813
    ],
    [
      35.63134096301204,
      56.3207762890263
    ],
    [
      35.631314,
      56.320052
    ],
    [
      35.631177,
      56.316346
    ],
    [
      35.631316,
      56.314766
    ],
    [
      35.63440223111056,
      56.312097760917254
    ],
    [
      35.634279,
      56.31014
    ],
    [
      35.634651,
      56.309907
    ],
    [
      35.64094,
      56.307076
    ],
    [
      35.64314488945522,
      56.30624731302916
    ],
    [
      35.64314,
      56.306234
    ],
    [
      35.64426796873221,
      56.30356271547458
    ],
    [
      35.64231301070446,
      56.297859493064536
    ],
    [
      35.637653,
      56.29568
    ],
    [
      35.636167,
      56.294444
    ],
    [
      35.636126,
      56.294057
    ],
    [
      35.6307658230804,
      56.285669669749524
    ],
    [
      35.629081,
      56.284212
    ],
    [
      35.621255,
      56.28295
    ],
    [
      35.613534,
      56.283399
    ],
    [
      35.606415,
      56.287826
    ],
    [
      35.602796,
      56.289045
    ],
    [
      35.598924,
      56.289734
    ],
    [
      35.591546,
      56.291827
    ],
    [
      35.586532,
      56.300313
    ],
    [
      35.584114,
      56.302981
    ],
    [
      35.582337,
      56.303112
    ],
    [
      35.582041986803155,
      56.303017661979126
    ],
    [
      35.577689,
      56.308875
    ],
    [
      35.572358,
      56.308478
    ],
    [
      35.561883528880664,
      56.30964943753833
    ],
    [
      35.560155072900486,
      56.31121924296961
    ],
    [
      35.554772,
      56.318981
    ],
    [
      35.550502,
      56.318472
    ],
    [
      35.546281,
      56.318779
    ],
    [
      35.540664,
      56.319187
    ],
    [
      35.547976,
      56.310474
    ],
    [
      35.542574,
      56.301892
    ],
    [
      35.536172,
      56.301831
    ],
    [
      35.531869,
      56.30175
    ],
    [
      35.528398049604306,
      56.30164074017866
    ],
    [
      35.528398,
      56.301643
    ],
    [
      35.52830709675065,
      56.301647773912435
    ],
    [
      35.524232,
      56.311852
    ],
    [
      35.527063,
      56.315288
    ],
    [
      35.527211,
      56.316994
    ],
    [
      35.527252,
      56.317966
    ],
    [
      35.52389780756719,
      56.31958808886709
    ],
    [
      35.524533,
      56.320829
    ],
    [
      35.523639,
      56.331141
    ],
    [
      35.526926,
      56.33494
    ],
    [
      35.530172,
      56.341432
    ],
    [
      35.530423,
      56.341785
    ],
    [
      35.528876,
      56.343877
    ],
    [
      35.527898,
      56.344027
    ],
    [
      35.526032,
      56.343995
    ],
    [
      35.520919,
      56.3335
    ],
    [
      35.517725,
      56.329817
    ],
    [
      35.509676,
      56.325444
    ],
    [
      35.50288,
      56.328449
    ],
    [
      35.496789,
      56.331777
    ],
    [
      35.492188,
      56.335379
    ],
    [
      35.488425,
      56.339133
    ],
    [
      35.487462,
      56.338615
    ],
    [
      35.485469,
      56.336709
    ],
    [
      35.478873,
      56.327308
    ],
    [
      35.478489,
      56.326715
    ],
    [
      35.48151,
      56.324757
    ],
    [
      35.488006,
      56.325826
    ],
    [
      35.492875,
      56.325034
    ],
    [
      35.496896,
      56.324705
    ],
    [
      35.500989,
      56.325385
    ],
    [
      35.508795,
      56.321953
    ],
    [
      35.510996864494814,
      56.32109752149956
    ],
    [
      35.503246,
      56.312875
    ],
    [
      35.501333,
      56.312702
    ],
    [
      35.50133973105769,
      56.312627751255995
    ],
    [
      35.500216,
      56.312482
    ],
    [
      35.49704,
      56.312081
    ],
    [
      35.49749,
      56.308509
    ],
    [
      35.491537,
      56.30092
    ],
    [
      35.487563,
      56.301512
    ],
    [
      35.4833,
      56.302155
    ],
    [
      35.475913,
      56.301153
    ],
    [
      35.474879,
      56.300923
    ],
    [
      35.474046,
      56.300682
    ],
    [
      35.474154177702125,
      56.30030941185862
    ],
    [
      35.471887,
      56.299222
    ],
    [
      35.467669,
      56.296858
    ],
    [
      35.462448,
      56.295041
    ],
    [
      35.455246,
      56.293265
    ],
    [
      35.447329,
      56.296604
    ],
    [
      35.446645,
      56.296649
    ],
    [
      35.446481,
      56.296528
    ],
    [
      35.445524,
      56.293228
    ],
    [
      35.445955,
      56.293071
    ],
    [
      35.45166,
      56.290835
    ],
    [
      35.457308,
      56.289497
    ],
    [
      35.460945,
      56.290051
    ],
    [
      35.464336,
      56.291976
    ],
    [
      35.473485,
      56.295419
    ],
    [
      35.479778,
      56.297014
    ],
    [
      35.48416893736497,
      56.29756577833881
    ],
    [
      35.4906,
      56.288894
    ],
    [
      35.490956,
      56.285312
    ],
    [
      35.485718,
      56.276979
    ],
    [
      35.492722,
      56.275124
    ],
    [
      35.496223,
      56.274814
    ],
    [
      35.499726,
      56.274033
    ],
    [
      35.504866,
      56.273698
    ],
    [
      35.513832,
      56.266487
    ],
    [
      35.521602,
      56.273378
    ],
    [
      35.52466521548029,
      56.27436629727674
    ],
    [
      35.52516,
      56.274488
    ],
    [
      35.525839,
      56.274745
    ],
    [
      35.52843660852336,
      56.27769704499345
    ],
    [
      35.528467,
      56.277676
    ],
    [
      35.53234,
      56.278425
    ],
    [
      35.533204,
      56.27899
    ],
    [
      35.536946,
      56.281664
    ],
    [
      35.545226,
      56.276847
    ],
    [
      35.54522818565799,
      56.27684280802459
    ],
    [
      35.547343,
      56.272765
    ],
    [
      35.547400329829394,
      56.27267675139835
    ],
    [
      35.5477,
      56.272102
    ],
    [
      35.547842255950684,
      56.27188674601189
    ],
    [
      35.547833,
      56.271881
    ],
    [
      35.548574,
      56.270688
    ],
    [
      35.544293,
      56.259809
    ],
    [
      35.540665,
      56.259701
    ],
    [
      35.536639,
      56.259442
    ],
    [
      35.535155,
      56.258935
    ],
    [
      35.532846,
      56.257978
    ],
    [
      35.53284657571902,
      56.25797661041983
    ],
    [
      35.532801,
      56.257959
    ],
    [
      35.521846,
      56.255952
    ],
    [
      35.520799,
      56.256096
    ],
    [
      35.519213,
      56.255283
    ],
    [
      35.51830190478962,
      56.25333446355622
    ],
    [
      35.51013571478975,
      56.25195151583579
    ],
    [
      35.503137,
      56.255642
    ],
    [
      35.49958,
      56.256195
    ],
    [
      35.49362220365514,
      56.24984770881547
    ],
    [
      35.49156,
      56.249887
    ],
    [
      35.489517,
      56.251511
    ],
    [
      35.48885,
      56.252084
    ],
    [
      35.486505,
      56.249353
    ],
    [
      35.486007,
      56.24048
    ],
    [
      35.487618,
      56.23726
    ],
    [
      35.491085,
      56.238994
    ],
    [
      35.494551,
      56.240728
    ],
    [
      35.497786,
      56.244411
    ],
    [
      35.506451,
      56.242579
    ],
    [
      35.51121864263699,
      56.244009109561176
    ],
    [
      35.515923,
      56.235075
    ],
    [
      35.513989,
      56.232038
    ],
    [
      35.518143,
      56.229394
    ],
    [
      35.519795,
      56.228316
    ],
    [
      35.522951,
      56.226013
    ],
    [
      35.525596,
      56.222815
    ],
    [
      35.52247989803551,
      56.216993853691896
    ],
    [
      35.517918,
      56.215944
    ],
    [
      35.511464,
      56.21652
    ],
    [
      35.511458570537,
      56.21651316895225
    ],
    [
      35.511099,
      56.216635
    ],
    [
      35.507724,
      56.217696
    ],
    [
      35.504079,
      56.218839
    ],
    [
      35.500891,
      56.219648
    ],
    [
      35.500017,
      56.219744
    ],
    [
      35.500016192086996,
      56.21973670022041
    ],
    [
      35.499956,
      56.21975
    ],
    [
      35.496776,
      56.220004
    ],
    [
      35.49550984380105,
      56.22010473506866
    ],
    [
      35.492538,
      56.220342
    ],
    [
      35.487468,
      56.221439
    ],
    [
      35.481599,
      56.223176
    ],
    [
      35.473376,
      56.22869
    ],
    [
      35.471696406955225,
      56.22955135415301
    ],
    [
      35.469171,
      56.232142
    ],
    [
      35.458962,
      56.229725
    ],
    [
      35.455211,
      56.22866
    ],
    [
      35.45568276006428,
      56.22683068344376
    ],
    [
      35.452344,
      56.226274
    ],
    [
      35.448689,
      56.225287
    ],
    [
      35.439957,
      56.225506
    ],
    [
      35.436387,
      56.225531
    ],
    [
      35.432061,
      56.216695
    ],
    [
      35.430103,
      56.214974
    ],
    [
      35.42996135666828,
      56.21479216949044
    ],
    [
      35.42847519831009,
      56.2134453181356
    ],
    [
      35.427773,
      56.212905
    ],
    [
      35.42781654357733,
      56.21284840326828
    ],
    [
      35.427495,
      56.212557
    ],
    [
      35.423586,
      56.210875
    ],
    [
      35.412853,
      56.210132
    ],
    [
      35.410755,
      56.213127
    ],
    [
      35.408106,
      56.216342
    ],
    [
      35.407317,
      56.216666
    ],
    [
      35.406509,
      56.21685
    ],
    [
      35.405712,
      56.213339
    ],
    [
      35.401316,
      56.204744
    ],
    [
      35.40088,
      56.20117
    ],
    [
      35.399582,
      56.189808
    ],
    [
      35.39117,
      56.192931
    ],
    [
      35.385186,
      56.198273
    ],
    [
      35.384825,
      56.198891
    ],
    [
      35.38047,
      56.198152
    ],
    [
      35.379206,
      56.197909
    ],
    [
      35.379131,
      56.196457
    ],
    [
      35.379343,
      56.192863
    ],
    [
      35.380573,
      56.192838
    ],
    [
      35.386946,
      56.190508
    ],
    [
      35.386114,
      56.181413
    ],
    [
      35.385623,
      56.177847
    ],
    [
      35.389941,
      56.173818
    ],
    [
      35.392816,
      56.171639
    ],
    [
      35.395188,
      56.174457
    ],
    [
      35.399019,
      56.178922
    ],
    [
      35.406021,
      56.183076
    ],
    [
      35.415721,
      56.181539
    ],
    [
      35.41858,
      56.179278
    ],
    [
      35.423031,
      56.175422
    ],
    [
      35.426723,
      56.173428
    ],
    [
      35.430519,
      56.171416
    ],
    [
      35.433849,
      56.170335
    ],
    [
      35.43730377407842,
      56.16959841974042
    ],
    [
      35.437306,
      56.169513
    ],
    [
      35.439768,
      56.168774
    ],
    [
      35.444706,
      56.163296
    ],
    [
      35.437495,
      56.154375
    ],
    [
      35.436137,
      56.15455
    ],
    [
      35.433716,
      56.154779
    ],
    [
      35.43351531769939,
      56.15479799018465
    ],
    [
      35.433487,
      56.154801
    ],
    [
      35.43348498432079,
      56.1548008605746
    ],
    [
      35.43280687655669,
      56.154865028623355
    ],
    [
      35.431522,
      56.154987
    ],
    [
      35.43152196381794,
      56.154986617473426
    ],
    [
      35.431296,
      56.155008
    ],
    [
      35.43126186223306,
      56.15464708626333
    ],
    [
      35.42928,
      56.15451
    ],
    [
      35.425556,
      56.155183
    ],
    [
      35.421942,
      56.155599
    ],
    [
      35.41768,
      56.155543
    ],
    [
      35.4141,
      56.155382
    ],
    [
      35.410417,
      56.156339
    ],
    [
      35.406904,
      56.156665
    ],
    [
      35.40328,
      56.156954
    ],
    [
      35.399144,
      56.157121
    ],
    [
      35.395518,
      56.157016
    ],
    [
      35.389045,
      56.158013
    ],
    [
      35.383104,
      56.161571
    ],
    [
      35.380515,
      56.164072
    ],
    [
      35.371637,
      56.160775
    ],
    [
      35.370459,
      56.16073
    ],
    [
      35.368948,
      56.157462
    ],
    [
      35.373429,
      56.152196
    ],
    [
      35.375173,
      56.142652
    ],
    [
      35.378766,
      56.142433
    ],
    [
      35.381804,
      56.148398
    ],
    [
      35.388503,
      56.150477
    ],
    [
      35.38936255995555,
      56.15083378473378
    ],
    [
      35.389675,
      56.150929
    ],
    [
      35.392276,
      56.151507
    ],
    [
      35.39288657819388,
      56.15166443400293
    ],
    [
      35.392908,
      56.15161
    ],
    [
      35.393375,
      56.151748
    ],
    [
      35.40018,
      56.153545
    ],
    [
      35.405329,
      56.153189
    ],
    [
      35.412347,
      56.143625
    ],
    [
      35.417682,
      56.145473
    ],
    [
      35.424831,
      56.143727
    ],
    [
      35.426222,
      56.147248
    ],
    [
      35.437032,
      56.150805
    ],
    [
      35.440866,
      56.150195
    ],
    [
      35.444826,
      56.149203
    ],
    [
      35.447246552806014,
      56.14857234392253
    ],
    [
      35.449976,
      56.145333
    ],
    [
      35.450628,
      56.145334
    ],
    [
      35.45884551909607,
      56.146207680526835
    ],
    [
      35.46438,
      56.136121
    ],
    [
      35.460072,
      56.128319
    ],
    [
      35.45818733945846,
      56.1278335509936
    ],
    [
      35.456927,
      56.127905
    ],
    [
      35.445779,
      56.125714
    ],
    [
      35.442317,
      56.124674
    ],
    [
      35.433539,
      56.12293
    ],
    [
      35.429395,
      56.121749
    ],
    [
      35.424473,
      56.120595
    ],
    [
      35.420353,
      56.119016
    ],
    [
      35.419452,
      56.118239
    ],
    [
      35.421803,
      56.115512
    ],
    [
      35.423048,
      56.116205
    ],
    [
      35.426781,
      56.117832
    ],
    [
      35.432215,
      56.119234
    ],
    [
      35.43589,
      56.119531
    ],
    [
      35.43593173550814,
      56.119537580777546
    ],
    [
      35.435932,
      56.119535
    ],
    [
      35.43842,
      56.11979
    ],
    [
      35.440507,
      56.120259
    ],
    [
      35.447917,
      56.111903
    ],
    [
      35.452931,
      56.108265
    ],
    [
      35.45986969779815,
      56.10616898992101
    ],
    [
      35.46147,
      56.099122
    ],
    [
      35.46147,
      56.095522
    ],
    [
      35.462217,
      56.095522
    ],
    [
      35.468287,
      56.094664
    ],
    [
      35.472485,
      56.095714
    ],
    [
      35.475407,
      56.098447
    ],
    [
      35.484241,
      56.098392
    ],
    [
      35.487451,
      56.089881
    ],
    [
      35.489069,
      56.085631
    ],
    [
      35.490683,
      56.081395
    ],
    [
      35.48211924781393,
      56.075135181143764
    ],
    [
      35.477169,
      56.076161
    ],
    [
      35.477168955696044,
      56.07616078606648
    ],
    [
      35.476391,
      56.076322
    ],
    [
      35.471309,
      56.077393
    ],
    [
      35.464315,
      56.077866
    ],
    [
      35.455489,
      56.079073
    ],
    [
      35.454645,
      56.079186
    ],
    [
      35.452442,
      56.079338
    ],
    [
      35.45024,
      56.07949
    ],
    [
      35.45023982033454,
      56.079487408920954
    ],
    [
      35.450017,
      56.079505
    ],
    [
      35.44554,
      56.080396
    ],
    [
      35.439177,
      56.081133
    ],
    [
      35.435012,
      56.081479
    ],
    [
      35.42956474368491,
      56.08174887610026
    ],
    [
      35.423222,
      56.088196
    ],
    [
      35.420383,
      56.091804
    ],
    [
      35.419247,
      56.09313
    ],
    [
      35.416513,
      56.090788
    ],
    [
      35.406254,
      56.092981
    ],
    [
      35.398848,
      56.097089
    ],
    [
      35.39788463798253,
      56.09637214924669
    ],
    [
      35.3977866752095,
      56.09640850930758
    ],
    [
      35.396906,
      56.096765
    ],
    [
      35.3969326247675,
      56.09672550039629
    ],
    [
      35.395471,
      56.097268
    ],
    [
      35.39641100689274,
      56.09527560035058
    ],
    [
      35.39596,
      56.09494
    ],
    [
      35.394939,
      56.091765
    ],
    [
      35.386432,
      56.083893
    ],
    [
      35.387121,
      56.080359
    ],
    [
      35.38334189359105,
      56.07857448361488
    ],
    [
      35.383516,
      56.082702
    ],
    [
      35.38052852114834,
      56.083833386627454
    ],
    [
      35.3804,
      56.083987
    ],
    [
      35.380101994931216,
      56.08399491615864
    ],
    [
      35.379843,
      56.084093
    ],
    [
      35.37970121443708,
      56.08400556242712
    ],
    [
      35.375732,
      56.084111
    ],
    [
      35.372543,
      56.08013
    ],
    [
      35.363211,
      56.086923
    ],
    [
      35.36586149334727,
      56.091511073183405
    ],
    [
      35.367708,
      56.093454
    ],
    [
      35.368305,
      56.095183
    ],
    [
      35.36829944821092,
      56.09528015630885
    ],
    [
      35.368349,
      56.095609
    ],
    [
      35.362455,
      56.098309
    ],
    [
      35.361519,
      56.09882
    ],
    [
      35.35963780711651,
      56.0968572459638
    ],
    [
      35.359584,
      56.096838
    ],
    [
      35.35957852550296,
      56.09679539413175
    ],
    [
      35.359028,
      56.096221
    ],
    [
      35.35937568415741,
      56.090618961225154
    ],
    [
      35.347952,
      56.08896
    ],
    [
      35.34703543277122,
      56.090093089632106
    ],
    [
      35.346701,
      56.090533
    ],
    [
      35.34644178022344,
      56.090826981839726
    ],
    [
      35.345192,
      56.092372
    ],
    [
      35.34497059409124,
      56.09249545795463
    ],
    [
      35.344188,
      56.093383
    ],
    [
      35.335109,
      56.095892
    ],
    [
      35.327547,
      56.101316
    ],
    [
      35.324792,
      56.103633
    ],
    [
      35.32275877418751,
      56.10416850366187
    ],
    [
      35.321726,
      56.105068
    ],
    [
      35.318529,
      56.10643
    ],
    [
      35.31604516874739,
      56.10695808869001
    ],
    [
      35.308,
      56.115153
    ],
    [
      35.304401,
      56.115218
    ],
    [
      35.30436,
      56.112934
    ],
    [
      35.30544579444391,
      56.11129549064678
    ],
    [
      35.305426,
      56.111274
    ],
    [
      35.30279293867753,
      56.101837080837115
    ],
    [
      35.30236,
      56.102073
    ],
    [
      35.297646,
      56.103901
    ],
    [
      35.29638,
      56.100531
    ],
    [
      35.293885816824336,
      56.09743374454188
    ],
    [
      35.288073,
      56.099419
    ],
    [
      35.286708,
      56.100232
    ],
    [
      35.285,
      56.100934
    ],
    [
      35.282887084103386,
      56.100301779296814
    ],
    [
      35.282789,
      56.100354
    ],
    [
      35.282028,
      56.100903
    ],
    [
      35.281738,
      56.100885
    ],
    [
      35.279966,
      56.098121
    ],
    [
      35.280116,
      56.09793
    ],
    [
      35.281154339667374,
      56.09701602136061
    ],
    [
      35.267204,
      56.088761
    ],
    [
      35.265201,
      56.095508
    ],
    [
      35.265691,
      56.102125
    ],
    [
      35.271645,
      56.107096
    ],
    [
      35.271596,
      56.110696
    ],
    [
      35.26707,
      56.114317
    ],
    [
      35.258838,
      56.113276
    ],
    [
      35.258830406676935,
      56.11325199642903
    ],
    [
      35.256825,
      56.113853
    ],
    [
      35.254048,
      56.110787
    ],
    [
      35.254119,
      56.104729
    ],
    [
      35.256821,
      56.099828
    ],
    [
      35.25198140948299,
      56.092978615827974
    ],
    [
      35.250023,
      56.092833
    ],
    [
      35.250016853313525,
      56.09282102225325
    ],
    [
      35.246106,
      56.093823
    ],
    [
      35.240826,
      56.103646
    ],
    [
      35.240868,
      56.107157
    ],
    [
      35.241639,
      56.113823
    ],
    [
      35.241751,
      56.118006
    ],
    [
      35.238137,
      56.123271
    ],
    [
      35.233773,
      56.12525
    ],
    [
      35.231957,
      56.122141
    ],
    [
      35.224393,
      56.116942
    ],
    [
      35.222279,
      56.113575
    ],
    [
      35.219405,
      56.110936
    ],
    [
      35.215401,
      56.106855
    ],
    [
      35.212149037627924,
      56.104463978682446
    ],
    [
      35.20831200268169,
      56.10350762302032
    ],
    [
      35.201313,
      56.110369
    ],
    [
      35.201476,
      56.11145
    ],
    [
      35.201142,
      56.112368
    ],
    [
      35.2005,
      56.112401
    ],
    [
      35.197916,
      56.111986
    ],
    [
      35.197753,
      56.110905
    ],
    [
      35.192266,
      56.1028
    ],
    [
      35.185923,
      56.102867
    ],
    [
      35.183827,
      56.102893
    ],
    [
      35.179981,
      56.102941
    ],
    [
      35.179936,
      56.099342
    ],
    [
      35.183782,
      56.099294
    ],
    [
      35.185878,
      56.099267
    ],
    [
      35.19314,
      56.090782
    ],
    [
      35.196197,
      56.081936
    ],
    [
      35.195688,
      56.077932
    ],
    [
      35.201993,
      56.069763
    ],
    [
      35.202553,
      56.066206
    ],
    [
      35.203216,
      56.066311
    ],
    [
      35.206914,
      56.066911
    ],
    [
      35.210362,
      56.068778
    ],
    [
      35.220085,
      56.06093
    ],
    [
      35.2192,
      56.058613
    ],
    [
      35.222546,
      56.057286
    ],
    [
      35.22344,
      56.059541
    ],
    [
      35.223597,
      56.060138
    ],
    [
      35.222401,
      56.065269
    ],
    [
      35.225293446924226,
      56.07072905850051
    ],
    [
      35.226023,
      56.070314
    ],
    [
      35.227689,
      56.07068
    ],
    [
      35.228073,
      56.070818
    ],
    [
      35.229088848260396,
      56.071052368968935
    ],
    [
      35.234726,
      56.071028
    ],
    [
      35.239983612440284,
      56.073242962381514
    ],
    [
      35.24257020301583,
      56.07304306856737
    ],
    [
      35.251295,
      56.069456
    ],
    [
      35.251908,
      56.068813
    ],
    [
      35.255,
      56.06988
    ],
    [
      35.255603,
      56.073429
    ],
    [
      35.254126,
      56.07368
    ],
    [
      35.252049,
      56.073835
    ],
    [
      35.25146376205736,
      56.07397782294653
    ],
    [
      35.251471,
      56.076873
    ],
    [
      35.247573,
      56.086019
    ],
    [
      35.259027,
      56.086854
    ],
    [
      35.26644,
      56.085243
    ],
    [
      35.26679405936912,
      56.085166273377695
    ],
    [
      35.266794,
      56.085166
    ],
    [
      35.273818,
      56.08364
    ],
    [
      35.281022,
      56.082083
    ],
    [
      35.285417943604344,
      56.08113469673779
    ],
    [
      35.285418,
      56.081132
    ],
    [
      35.286483,
      56.080902
    ],
    [
      35.289176,
      56.080324
    ],
    [
      35.293339,
      56.079418
    ],
    [
      35.297829,
      56.078454
    ],
    [
      35.301347,
      56.077689
    ],
    [
      35.30868852085176,
      56.0742855115314
    ],
    [
      35.308946167575776,
      56.074093350016405
    ],
    [
      35.309579,
      56.062758
    ],
    [
      35.308638,
      56.061664
    ],
    [
      35.308343,
      56.061339
    ],
    [
      35.308171,
      56.060494
    ],
    [
      35.308955,
      56.056981
    ],
    [
      35.309154,
      56.057025
    ],
    [
      35.309994,
      56.057461
    ],
    [
      35.31697428601903,
      56.05865489507401
    ],
    [
      35.325154,
      56.056766
    ],
    [
      35.33544,
      56.055152
    ],
    [
      35.336050637156674,
      56.05557643389814
    ],
    [
      35.337133,
      56.054906
    ],
    [
      35.341976,
      56.057443
    ],
    [
      35.34863,
      56.062055
    ],
    [
      35.352432,
      56.064214
    ],
    [
      35.361462,
      56.062335
    ],
    [
      35.366272,
      56.060098
    ],
    [
      35.372245,
      56.051686
    ],
    [
      35.37532512300381,
      56.05218567399828
    ],
    [
      35.375486,
      56.051835
    ],
    [
      35.382045,
      56.044241
    ],
    [
      35.3852622704887,
      56.043032922321856
    ],
    [
      35.387342,
      56.04194
    ],
    [
      35.38778385011338,
      56.042086074965084
    ],
    [
      35.388575,
      56.041789
    ],
    [
      35.38895290230763,
      56.04247256181502
    ],
    [
      35.391486,
      56.04331
    ],
    [
      35.39780693128804,
      56.04437779518261
    ],
    [
      35.406844,
      56.042041
    ],
    [
      35.412051,
      56.041501
    ],
    [
      35.417691,
      56.041131
    ],
    [
      35.42334468847765,
      56.040740713118
    ],
    [
      35.424484,
      56.040662
    ],
    [
      35.430101,
      56.040275
    ],
    [
      35.433319655875074,
      56.04005666666931
    ],
    [
      35.437421,
      56.039778
    ],
    [
      35.442895,
      56.039407
    ],
    [
      35.443227,
      56.039385
    ],
    [
      35.450626,
      56.038887
    ],
    [
      35.451675,
      56.028647
    ],
    [
      35.451167,
      56.027962
    ],
    [
      35.447408,
      56.025595
    ],
    [
      35.437889,
      56.022983
    ],
    [
      35.433088,
      56.023372
    ],
    [
      35.429916,
      56.023413
    ],
    [
      35.425811334930586,
      56.0227167541512
    ],
    [
      35.416575,
      56.02223
    ],
    [
      35.413532,
      56.024389
    ],
    [
      35.410031,
      56.025745
    ],
    [
      35.409046,
      56.026493
    ],
    [
      35.406511,
      56.023937
    ],
    [
      35.40648764205655,
      56.01791716908448
    ],
    [
      35.405573,
      56.012256
    ],
    [
      35.395247,
      56.00833
    ],
    [
      35.389182,
      56.006326
    ],
    [
      35.385531,
      56.005098
    ],
    [
      35.375258,
      56.000949
    ],
    [
      35.37017,
      55.998965
    ],
    [
      35.369782,
      55.99857
    ],
    [
      35.369467,
      55.997967
    ],
    [
      35.36976070326057,
      55.9972225490619
    ],
    [
      35.36812178107459,
      55.99616292525868
    ],
    [
      35.364017832262086,
      55.99674650220619
    ],
    [
      35.360057,
      56.000005
    ],
    [
      35.351536,
      56.003972
    ],
    [
      35.349337,
      56.000237
    ],
    [
      35.343452,
      55.991682
    ],
    [
      35.339847,
      55.991128
    ],
    [
      35.335488,
      55.991731
    ],
    [
      35.331869,
      55.993517
    ],
    [
      35.32934817700487,
      55.994620121051156
    ],
    [
      35.326444,
      55.996549
    ],
    [
      35.323904,
      55.997623
    ],
    [
      35.32347044164793,
      55.997454255251874
    ],
    [
      35.322112,
      55.998357
    ],
    [
      35.319939,
      55.997966
    ],
    [
      35.310966901798636,
      55.994431242317454
    ],
    [
      35.30701272724014,
      55.99482724927578
    ],
    [
      35.303062256604036,
      55.996953334810684
    ],
    [
      35.297042,
      56.002551
    ],
    [
      35.297001796595794,
      56.00253751248233
    ],
    [
      35.296716,
      56.00336
    ],
    [
      35.29648846831027,
      56.0060859313331
    ],
    [
      35.296415,
      56.00697
    ],
    [
      35.29641467792972,
      56.00696997307105
    ],
    [
      35.296358,
      56.007649
    ],
    [
      35.302697,
      56.016831
    ],
    [
      35.30993,
      56.02031
    ],
    [
      35.313767,
      56.020473
    ],
    [
      35.32153096651545,
      56.02091724261549
    ],
    [
      35.327745,
      56.021272
    ],
    [
      35.32774498828451,
      56.021272204395586
    ],
    [
      35.329878,
      56.021394
    ],
    [
      35.32987796122511,
      56.021394676490125
    ],
    [
      35.33109,
      56.021464
    ],
    [
      35.33559,
      56.021097
    ],
    [
      35.339925,
      56.020637
    ],
    [
      35.346394,
      56.018051
    ],
    [
      35.346773,
      56.017994
    ],
    [
      35.347303,
      56.021555
    ],
    [
      35.347703,
      56.023688
    ],
    [
      35.34402,
      56.024599
    ],
    [
      35.339997,
      56.024237
    ],
    [
      35.336066,
      56.024665
    ],
    [
      35.330982,
      56.025063
    ],
    [
      35.322247,
      56.024564
    ],
    [
      35.31927250899716,
      56.02920746847384
    ],
    [
      35.317674,
      56.032398
    ],
    [
      35.316207,
      56.035686
    ],
    [
      35.315815,
      56.035511
    ],
    [
      35.311226,
      56.029607
    ],
    [
      35.308354,
      56.027576
    ],
    [
      35.301792,
      56.024577
    ],
    [
      35.296061,
      56.024246
    ],
    [
      35.289836,
      56.029256
    ],
    [
      35.287908,
      56.033998
    ],
    [
      35.285977,
      56.038113
    ],
    [
      35.28581252805824,
      56.03832931520108
    ],
    [
      35.285603,
      56.039559
    ],
    [
      35.285565,
      56.039704
    ],
    [
      35.28553695365266,
      56.03969663803519
    ],
    [
      35.285478,
      56.040025
    ],
    [
      35.284799,
      56.042116
    ],
    [
      35.283302564576836,
      56.04163044516497
    ],
    [
      35.282887,
      56.042177
    ],
    [
      35.278195,
      56.043899
    ],
    [
      35.272824,
      56.046105
    ],
    [
      35.269062,
      56.048124
    ],
    [
      35.265313,
      56.050011
    ],
    [
      35.265172,
      56.050057
    ],
    [
      35.26402,
      56.048027
    ],
    [
      35.263025,
      56.044567
    ],
    [
      35.26426,
      56.044212
    ],
    [
      35.271331,
      56.042829
    ],
    [
      35.27768,
      56.040336
    ],
    [
      35.27952854361225,
      56.03825187331533
    ],
    [
      35.27933466902787,
      56.03796279998802
    ],
    [
      35.27221317009934,
      56.03581935217963
    ],
    [
      35.269018,
      56.035995
    ],
    [
      35.26882,
      56.0324
    ],
    [
      35.27711674628146,
      56.031943956213844
    ],
    [
      35.27749810746753,
      56.03192295199656
    ],
    [
      35.2865,
      56.027902
    ],
    [
      35.280659,
      56.018459
    ],
    [
      35.27568,
      56.017991
    ],
    [
      35.270912,
      56.01762
    ],
    [
      35.266947,
      56.016919
    ],
    [
      35.26281,
      56.014762
    ],
    [
      35.254344,
      56.008973
    ],
    [
      35.247758,
      56.008542
    ],
    [
      35.239212,
      56.00639
    ],
    [
      35.237425,
      56.003265
    ],
    [
      35.238344,
      56.002739
    ],
    [
      35.239643,
      56.002395
    ],
    [
      35.243466,
      56.001003
    ],
    [
      35.252785,
      55.995098
    ],
    [
      35.254688,
      55.992133
    ],
    [
      35.256626,
      55.989116
    ],
    [
      35.25774670824462,
      55.988159048211216
    ],
    [
      35.257706,
      55.98811
    ],
    [
      35.259341,
      55.985336
    ],
    [
      35.259376,
      55.985277
    ],
    [
      35.259691,
      55.98476
    ],
    [
      35.260354,
      55.984223
    ],
    [
      35.261855150701166,
      55.98371350304122
    ],
    [
      35.264695,
      55.977064
    ],
    [
      35.261921,
      55.973113
    ],
    [
      35.259201,
      55.967806
    ],
    [
      35.25494733614175,
      55.961854970856116
    ],
    [
      35.245259,
      55.957674
    ],
    [
      35.238993,
      55.956689
    ],
    [
      35.234602,
      55.954825
    ],
    [
      35.230324,
      55.952983
    ],
    [
      35.227047,
      55.952018
    ],
    [
      35.228063,
      55.948565
    ],
    [
      35.23134,
      55.949529
    ],
    [
      35.234618,
      55.950493
    ],
    [
      35.245816,
      55.954118
    ],
    [
      35.25172472351,
      55.9567555362048
    ],
    [
      35.252327,
      55.955181
    ],
    [
      35.254086,
      55.955854
    ],
    [
      35.25490106112808,
      55.956319168336506
    ],
    [
      35.254942,
      55.956246
    ],
    [
      35.257306,
      55.957568
    ],
    [
      35.258095,
      55.958142
    ],
    [
      35.26007,
      55.961273
    ],
    [
      35.26245,
      55.966257
    ],
    [
      35.267629,
      55.974979
    ],
    [
      35.269904,
      55.978169
    ],
    [
      35.273686,
      55.982469
    ],
    [
      35.273708905431484,
      55.98249995909195
    ],
    [
      35.273716,
      55.982498
    ],
    [
      35.274608,
      55.983365
    ],
    [
      35.278484,
      55.988954
    ],
    [
      35.280097975645546,
      55.98929278779063
    ],
    [
      35.280472,
      55.989275
    ],
    [
      35.28401,
      55.98994
    ],
    [
      35.288798,
      55.991119
    ],
    [
      35.29283497173372,
      55.99130860503299
    ],
    [
      35.292835,
      55.991308
    ],
    [
      35.29356452459518,
      55.99134211733583
    ],
    [
      35.2982198503633,
      55.991371604431805
    ],
    [
      35.306674,
      55.991166
    ],
    [
      35.31048579715441,
      55.99078330766664
    ],
    [
      35.310483,
      55.99074
    ],
    [
      35.31086003597433,
      55.99071555713694
    ],
    [
      35.31086,
      55.990715
    ],
    [
      35.31204197568699,
      55.99062189528691
    ],
    [
      35.319581,
      55.989746
    ],
    [
      35.325439,
      55.988828
    ],
    [
      35.329416,
      55.988562
    ],
    [
      35.339525,
      55.987542
    ],
    [
      35.344064,
      55.988135
    ],
    [
      35.351292,
      55.989478
    ],
    [
      35.358497,
      55.990818
    ],
    [
      35.362713,
      55.991134
    ],
    [
      35.37218,
      55.990465
    ],
    [
      35.382846,
      55.986861
    ],
    [
      35.384264,
      55.984899
    ],
    [
      35.386986,
      55.982407
    ],
    [
      35.387209107781196,
      55.982202987195606
    ],
    [
      35.388946,
      55.980613
    ],
    [
      35.388946871748125,
      55.980613952073746
    ],
    [
      35.393015,
      55.976894
    ],
    [
      35.402389,
      55.974648
    ],
    [
      35.406004,
      55.974557
    ],
    [
      35.411788,
      55.97554
    ],
    [
      35.4119744392487,
      55.9754474567599
    ],
    [
      35.411687,
      55.973732
    ],
    [
      35.414582,
      55.973248
    ],
    [
      35.417163,
      55.972872
    ],
    [
      35.419615,
      55.965222
    ],
    [
      35.42225256192202,
      55.96150929561273
    ],
    [
      35.422135,
      55.960838
    ],
    [
      35.424058,
      55.959697
    ],
    [
      35.434714,
      55.956795
    ],
    [
      35.44632,
      55.955214
    ],
    [
      35.448505,
      55.953512
    ],
    [
      35.450028,
      55.953185
    ],
    [
      35.4539445126818,
      55.95191781635849
    ],
    [
      35.457891,
      55.949885
    ],
    [
      35.46214,
      55.940979
    ],
    [
      35.461429,
      55.93745
    ],
    [
      35.461462268378824,
      55.937428157017855
    ],
    [
      35.459057,
      55.936798
    ],
    [
      35.453454,
      55.936643
    ],
    [
      35.449638,
      55.936473
    ],
    [
      35.44905559559349,
      55.93615399203081
    ],
    [
      35.448507,
      55.936093
    ],
    [
      35.447585,
      55.935437
    ],
    [
      35.44762355935618,
      55.93536960412652
    ],
    [
      35.446182,
      55.93458
    ],
    [
      35.445494,
      55.934243
    ],
    [
      35.44551486140141,
      55.93420042114218
    ],
    [
      35.441426,
      55.931874
    ],
    [
      35.438293,
      55.929752
    ],
    [
      35.43197,
      55.922747
    ],
    [
      35.429543,
      55.919671
    ],
    [
      35.436526,
      55.921983
    ],
    [
      35.439333,
      55.924585
    ],
    [
      35.442903,
      55.928591
    ],
    [
      35.451367669320405,
      55.93212906654965
    ],
    [
      35.451942,
      55.932002
    ],
    [
      35.45553,
      55.932293
    ],
    [
      35.459171,
      55.9332
    ],
    [
      35.464249,
      55.935121
    ],
    [
      35.46917,
      55.936289
    ],
    [
      35.469170076346494,
      55.93628902155911
    ],
    [
      35.4699,
      55.936221
    ],
    [
      35.471853,
      55.93604
    ],
    [
      35.481328,
      55.932997
    ],
    [
      35.48528,
      55.930648
    ],
    [
      35.49101,
      55.92886
    ],
    [
      35.496405,
      55.92848
    ],
    [
      35.500358,
      55.927903
    ],
    [
      35.504287,
      55.927159
    ],
    [
      35.507436,
      55.917182
    ],
    [
      35.497594,
      55.915255
    ],
    [
      35.498301675241954,
      55.91345064521131
    ],
    [
      35.498234,
      55.913423
    ],
    [
      35.497982,
      55.911811
    ],
    [
      35.498165373633185,
      55.910357783085026
    ],
    [
      35.48202160242605,
      55.89905442179978
    ],
    [
      35.479544,
      55.899322
    ],
    [
      35.47954262516593,
      55.899309285449235
    ],
    [
      35.477196,
      55.899551
    ],
    [
      35.471776,
      55.89954
    ],
    [
      35.467196,
      55.89895
    ],
    [
      35.462696,
      55.898114
    ],
    [
      35.460969,
      55.897455
    ],
    [
      35.459241,
      55.896797
    ],
    [
      35.459247781311625,
      55.89677920566902
    ],
    [
      35.458759,
      55.89659
    ],
    [
      35.455881,
      55.895982
    ],
    [
      35.453002,
      55.895374
    ],
    [
      35.45300235257927,
      55.895372330935224
    ],
    [
      35.452112,
      55.895184
    ],
    [
      35.447313,
      55.893771
    ],
    [
      35.440317,
      55.889925
    ],
    [
      35.436302,
      55.889014
    ],
    [
      35.430134,
      55.889501
    ],
    [
      35.425729,
      55.888485
    ],
    [
      35.421786,
      55.887589
    ],
    [
      35.41159545535906,
      55.88721349580704
    ],
    [
      35.40915826045862,
      55.88720428827046
    ],
    [
      35.407718,
      55.887199
    ],
    [
      35.40771800055222,
      55.8871988470783
    ],
    [
      35.406435,
      55.887194
    ],
    [
      35.4064350069644,
      55.8871920713968
    ],
    [
      35.40445,
      55.887184
    ],
    [
      35.400111,
      55.88672
    ],
    [
      35.396475,
      55.886305
    ],
    [
      35.395689,
      55.88625
    ],
    [
      35.388747,
      55.885972
    ],
    [
      35.388891,
      55.882375
    ],
    [
      35.392362,
      55.882514
    ],
    [
      35.395834,
      55.882653
    ],
    [
      35.396722,
      55.882714
    ],
    [
      35.400547,
      55.883146
    ],
    [
      35.411596,
      55.883613
    ],
    [
      35.418625,
      55.883639
    ],
    [
      35.422245,
      55.884018
    ],
    [
      35.426573,
      55.884985
    ],
    [
      35.430896,
      55.885983
    ],
    [
      35.448407,
      55.890342
    ],
    [
      35.452984,
      55.891692
    ],
    [
      35.45374583524002,
      55.891852779952494
    ],
    [
      35.453746,
      55.891852
    ],
    [
      35.459504,
      55.893068
    ],
    [
      35.467701,
      55.895386
    ],
    [
      35.472236,
      55.89597
    ],
    [
      35.4771,
      55.895952
    ],
    [
      35.481634,
      55.895475
    ],
    [
      35.481636253061886,
      55.89547520559341
    ],
    [
      35.48263760420371,
      55.89536709368282
    ],
    [
      35.486083,
      55.894994
    ],
    [
      35.491932089344395,
      55.894372505404114
    ],
    [
      35.492354,
      55.893569
    ],
    [
      35.495339,
      55.891561
    ],
    [
      35.49611,
      55.891618
    ],
    [
      35.49788295746401,
      55.89139802024926
    ],
    [
      35.500381,
      55.883801
    ],
    [
      35.50045957657677,
      55.88187719819341
    ],
    [
      35.500523,
      55.880315
    ],
    [
      35.50052338302289,
      55.880315015653146
    ],
    [
      35.500559,
      55.879443
    ],
    [
      35.500652,
      55.874612
    ],
    [
      35.500746,
      55.86978
    ],
    [
      35.500746106697115,
      55.86978000207466
    ],
    [
      35.500815,
      55.866225
    ],
    [
      35.500831187006355,
      55.865281003220666
    ],
    [
      35.500831,
      55.865281
    ],
    [
      35.500844191899866,
      55.864522581476265
    ],
    [
      35.500881,
      55.862376
    ],
    [
      35.500925,
      55.856955
    ],
    [
      35.50092498969403,
      55.85694849987452
    ],
    [
      35.500906,
      55.855132
    ],
    [
      35.500918,
      55.85254
    ],
    [
      35.502771,
      55.849479
    ],
    [
      35.497705,
      55.840099
    ],
    [
      35.490307,
      55.83759
    ],
    [
      35.481537,
      55.837282
    ],
    [
      35.478186,
      55.837056
    ],
    [
      35.47827605230221,
      55.83571935591091
    ],
    [
      35.476441,
      55.83484
    ],
    [
      35.477517,
      55.828161
    ],
    [
      35.477509,
      55.825425
    ],
    [
      35.481109,
      55.825414
    ],
    [
      35.481117,
      55.82815
    ],
    [
      35.490524,
      55.833996
    ],
    [
      35.498835,
      55.836681
    ],
    [
      35.50923757416623,
      55.8315457038718
    ],
    [
      35.509463,
      55.831079
    ],
    [
      35.51127,
      55.82734
    ],
    [
      35.515850001271886,
      55.817813000611636
    ],
    [
      35.51585,
      55.817813
    ],
    [
      35.518139,
      55.81305
    ],
    [
      35.51696549881947,
      55.81107651299919
    ],
    [
      35.510433,
      55.805724
    ],
    [
      35.505355506040004,
      55.80281030407428
    ],
    [
      35.503554,
      55.802729
    ],
    [
      35.496707,
      55.804146
    ],
    [
      35.494639149836196,
      55.804026951054915
    ],
    [
      35.494639,
      55.804027
    ],
    [
      35.489376,
      55.803727
    ],
    [
      35.48930291945884,
      55.80371907309068
    ],
    [
      35.486013,
      55.803529
    ],
    [
      35.482019,
      55.802929
    ],
    [
      35.473756,
      55.801677
    ],
    [
      35.46488,
      55.800341
    ],
    [
      35.458166,
      55.799408
    ],
    [
      35.454685,
      55.798954
    ],
    [
      35.450947,
      55.798365
    ],
    [
      35.447235,
      55.79778
    ],
    [
      35.442005,
      55.797033
    ],
    [
      35.434895,
      55.795954
    ],
    [
      35.427769,
      55.794872
    ],
    [
      35.426029,
      55.794592
    ],
    [
      35.42603921456596,
      55.79452862690752
    ],
    [
      35.424288,
      55.794181
    ],
    [
      35.421615,
      55.793274
    ],
    [
      35.41889,
      55.792041
    ],
    [
      35.416166,
      55.790807
    ],
    [
      35.41617383378958,
      55.790789702359575
    ],
    [
      35.411055,
      55.788432
    ],
    [
      35.402541,
      55.785339
    ],
    [
      35.399251,
      55.781546
    ],
    [
      35.401779,
      55.778982
    ],
    [
      35.407491,
      55.782806
    ],
    [
      35.412572,
      55.785168
    ],
    [
      35.42034826378032,
      55.78874889803272
    ],
    [
      35.42035799467521,
      55.78875330265585
    ],
    [
      35.424116,
      55.790388
    ],
    [
      35.428341,
      55.791318
    ],
    [
      35.42891092028533,
      55.79140452440757
    ],
    [
      35.428911,
      55.791404
    ],
    [
      35.435728,
      55.792439
    ],
    [
      35.442546,
      55.793474
    ],
    [
      35.447744,
      55.794216
    ],
    [
      35.451508,
      55.794809
    ],
    [
      35.458631,
      55.795838
    ],
    [
      35.46246108847016,
      55.79637038060187
    ],
    [
      35.462465,
      55.796344
    ],
    [
      35.46765,
      55.796121
    ],
    [
      35.46871934691519,
      55.796442077400336
    ],
    [
      35.469135,
      55.796448
    ],
    [
      35.474301,
      55.798118
    ],
    [
      35.482553,
      55.799369
    ],
    [
      35.486548,
      55.799969
    ],
    [
      35.492777,
      55.800313
    ],
    [
      35.497458,
      55.799342
    ],
    [
      35.501075,
      55.799182
    ],
    [
      35.5022932941636,
      55.79917166394771
    ],
    [
      35.503476,
      55.79913
    ],
    [
      35.50408351274744,
      55.799156475667694
    ],
    [
      35.505554,
      55.799144
    ],
    [
      35.50753925870773,
      55.79930707856791
    ],
    [
      35.508157,
      55.799334
    ],
    [
      35.509351326472434,
      55.799440679261814
    ],
    [
      35.516652,
      55.79021
    ],
    [
      35.51935327161985,
      55.79062099559006
    ],
    [
      35.520985,
      55.788413
    ],
    [
      35.529011,
      55.78266
    ],
    [
      35.530391,
      55.778347
    ],
    [
      35.522192,
      55.771472
    ],
    [
      35.51695687220249,
      55.77089114855112
    ],
    [
      35.513017,
      55.770462
    ],
    [
      35.512931876809134,
      55.77044456453323
    ],
    [
      35.510971,
      55.770227
    ],
    [
      35.506138,
      55.769053
    ],
    [
      35.502334,
      55.768007
    ],
    [
      35.495452,
      55.76585
    ],
    [
      35.491643,
      55.764029
    ],
    [
      35.48763,
      55.762094
    ],
    [
      35.48959,
      55.759074
    ],
    [
      35.493196,
      55.760781
    ],
    [
      35.497005,
      55.762602
    ],
    [
      35.503402,
      55.764569
    ],
    [
      35.507091,
      55.765581
    ],
    [
      35.51164065679223,
      55.76651792632379
    ],
    [
      35.521396,
      55.761499
    ],
    [
      35.515159,
      55.751962
    ],
    [
      35.51067765621261,
      55.751517580374966
    ],
    [
      35.508362,
      55.752057
    ],
    [
      35.50820691001604,
      55.747553619675074
    ],
    [
      35.506272,
      55.745925
    ],
    [
      35.506312,
      55.745653
    ],
    [
      35.509125,
      55.743523
    ],
    [
      35.51289273586391,
      55.735976670243765
    ],
    [
      35.510125,
      55.730587
    ],
    [
      35.50207916322894,
      55.72939924350055
    ],
    [
      35.50188119381599,
      55.72937954563138
    ],
    [
      35.495562297398514,
      55.72894096133459
    ],
    [
      35.49494,
      55.728925
    ],
    [
      35.494633,
      55.728894
    ],
    [
      35.4923784997876,
      55.72855549216549
    ],
    [
      35.48822,
      55.728013
    ],
    [
      35.48648153700728,
      55.72783676751868
    ],
    [
      35.485516,
      55.727739
    ],
    [
      35.48551601118052,
      55.72773888967317
    ],
    [
      35.482173,
      55.7274
    ],
    [
      35.47735086028949,
      55.72690621525202
    ],
    [
      35.475969,
      55.726765
    ],
    [
      35.47596902898541,
      55.7267647164023
    ],
    [
      35.47563,
      55.72673
    ],
    [
      35.471759,
      55.726348
    ],
    [
      35.467876,
      55.725964
    ],
    [
      35.462964,
      55.725446
    ],
    [
      35.458065,
      55.724929
    ],
    [
      35.45035,
      55.727009
    ],
    [
      35.445106,
      55.7273
    ],
    [
      35.437177,
      55.7332
    ],
    [
      35.432638,
      55.74354
    ],
    [
      35.43263798834601,
      55.74353999887461
    ],
    [
      35.432343,
      55.746593
    ],
    [
      35.43218502085225,
      55.74657774440828
    ],
    [
      35.431985,
      55.748173
    ],
    [
      35.430033,
      55.751168
    ],
    [
      35.429331,
      55.762178
    ],
    [
      35.426054,
      55.763668
    ],
    [
      35.418169901535165,
      55.75873745112208
    ],
    [
      35.41420524232463,
      55.75812103767591
    ],
    [
      35.408961,
      55.759122
    ],
    [
      35.408960978964714,
      55.75912188977513
    ],
    [
      35.407321,
      55.759435
    ],
    [
      35.4073209074567,
      55.7594345150731
    ],
    [
      35.403178,
      55.760225
    ],
    [
      35.399511,
      55.760885
    ],
    [
      35.39454,
      55.761569
    ],
    [
      35.390942,
      55.770126
    ],
    [
      35.390565,
      55.773706
    ],
    [
      35.380696,
      55.775272
    ],
    [
      35.379511,
      55.773103
    ],
    [
      35.380904,
      55.769756
    ],
    [
      35.38234923529676,
      55.76794620629122
    ],
    [
      35.38136,
      55.76753
    ],
    [
      35.370736,
      55.762844
    ],
    [
      35.371184,
      55.759272
    ],
    [
      35.373502829667316,
      55.75956278571391
    ],
    [
      35.375637,
      55.75983
    ],
    [
      35.378647,
      55.760208
    ],
    [
      35.384088,
      55.759349
    ],
    [
      35.394091,
      55.757997
    ],
    [
      35.398977,
      55.757325
    ],
    [
      35.40254,
      55.756682
    ],
    [
      35.406646098484366,
      55.755898516058075
    ],
    [
      35.406646,
      55.755898
    ],
    [
      35.408286012720225,
      55.75558506665399
    ],
    [
      35.408286,
      55.755585
    ],
    [
      35.409336160576196,
      55.75538468632869
    ],
    [
      35.4147062574361,
      55.75436000989901
    ],
    [
      35.41641388211429,
      55.75403414226702
    ],
    [
      35.427017,
      55.749202
    ],
    [
      35.4292973649489,
      55.74068237260213
    ],
    [
      35.429482,
      55.738769
    ],
    [
      35.42991,
      55.734344
    ],
    [
      35.426714,
      55.726498
    ],
    [
      35.418555,
      55.724436
    ],
    [
      35.41756795454173,
      55.72418639952059
    ],
    [
      35.41596,
      55.72378
    ],
    [
      35.415960050935176,
      55.72377979868202
    ],
    [
      35.410385,
      55.72237
    ],
    [
      35.409515,
      55.722361
    ],
    [
      35.40946696476517,
      55.72234147970537
    ],
    [
      35.405443557424846,
      55.72221648585066
    ],
    [
      35.403956,
      55.722227
    ],
    [
      35.40338762537374,
      55.72215261489494
    ],
    [
      35.402885,
      55.722137
    ],
    [
      35.40269146253014,
      55.72206150571472
    ],
    [
      35.400189,
      55.721734
    ],
    [
      35.3923306343571,
      55.72255266975774
    ],
    [
      35.388096164881745,
      55.7239959438018
    ],
    [
      35.387291,
      55.724764
    ],
    [
      35.387290830949766,
      55.72476382278638
    ],
    [
      35.386995,
      55.725046
    ],
    [
      35.386983096893466,
      55.72503352688555
    ],
    [
      35.385856,
      55.726097
    ],
    [
      35.383801089977965,
      55.72740748200602
    ],
    [
      35.383716,
      55.727478
    ],
    [
      35.382825065845466,
      55.72802992388882
    ],
    [
      35.38187,
      55.728639
    ],
    [
      35.381681036675324,
      55.72873863735018
    ],
    [
      35.379724,
      55.729951
    ],
    [
      35.37913908910828,
      55.730078965799166
    ],
    [
      35.378663,
      55.73033
    ],
    [
      35.370688,
      55.732183
    ],
    [
      35.366067,
      55.733449
    ],
    [
      35.360886,
      55.734849
    ],
    [
      35.357204,
      55.735345
    ],
    [
      35.351818,
      55.736136
    ],
    [
      35.349567,
      55.736731
    ],
    [
      35.348646,
      55.733251
    ],
    [
      35.350897,
      55.732655
    ],
    [
      35.353148,
      55.73206
    ],
    [
      35.354462,
      55.73184
    ],
    [
      35.360144,
      55.731326
    ],
    [
      35.365126,
      55.729974
    ],
    [
      35.369566,
      55.719772
    ],
    [
      35.37149064599968,
      55.71832753819184
    ],
    [
      35.3715,
      55.717398
    ],
    [
      35.37209101559948,
      55.717876956132685
    ],
    [
      35.37252,
      55.717555
    ],
    [
      35.37968463263181,
      55.72263200038426
    ],
    [
      35.38048388294948,
      55.722723121392136
    ],
    [
      35.384365174577184,
      55.72086073432309
    ],
    [
      35.3842992729824,
      55.72014119263037
    ],
    [
      35.382022,
      55.711073
    ],
    [
      35.378932,
      55.711017
    ],
    [
      35.37893200501321,
      55.71101672234514
    ],
    [
      35.378508,
      55.711009
    ],
    [
      35.378508032858214,
      55.71100718066559
    ],
    [
      35.378192,
      55.711032
    ],
    [
      35.377904,
      55.707444
    ],
    [
      35.378286,
      55.707413
    ],
    [
      35.378572892618635,
      55.70741594562356
    ],
    [
      35.378573,
      55.70741
    ],
    [
      35.37899698918044,
      55.70741759923709
    ],
    [
      35.378997,
      55.707417
    ],
    [
      35.3794174040357,
      55.70742461650793
    ],
    [
      35.383448,
      55.707466
    ],
    [
      35.38930327407251,
      55.70694229846804
    ],
    [
      35.389239,
      55.706413
    ],
    [
      35.39416,
      55.706442
    ],
    [
      35.40239033992694,
      55.701041714759825
    ],
    [
      35.39882620202551,
      55.6939698764984
    ],
    [
      35.393548,
      55.691514
    ],
    [
      35.386471,
      55.691483
    ],
    [
      35.38560846703935,
      55.69147909989335
    ],
    [
      35.383083,
      55.691468
    ],
    [
      35.383083001419905,
      55.69146768052076
    ],
    [
      35.379394,
      55.691451
    ],
    [
      35.379394013888856,
      55.69144787500694
    ],
    [
      35.37742,
      55.691438
    ],
    [
      35.371631,
      55.693419
    ],
    [
      35.36815,
      55.694679
    ],
    [
      35.363028,
      55.695272
    ],
    [
      35.35926,
      55.694615
    ],
    [
      35.357618,
      55.693196
    ],
    [
      35.35667809955143,
      55.69167618196092
    ],
    [
      35.355049,
      55.689237
    ],
    [
      35.35504926512742,
      55.68923682289417
    ],
    [
      35.355018,
      55.68919
    ],
    [
      35.356291166752136,
      55.68833994644706
    ],
    [
      35.356214,
      55.687691
    ],
    [
      35.356776539138465,
      55.688015878511095
    ],
    [
      35.358012,
      55.687191
    ],
    [
      35.36756,
      55.691128
    ],
    [
      35.373196,
      55.689019
    ],
    [
      35.377163,
      55.687847
    ],
    [
      35.37940998033867,
      55.68785542379858
    ],
    [
      35.37941,
      55.687851
    ],
    [
      35.385134856524026,
      55.68787688602639
    ],
    [
      35.385432,
      55.687878
    ],
    [
      35.388331543638145,
      55.6878908361499
    ],
    [
      35.399008,
      55.684673
    ],
    [
      35.400125,
      55.684727
    ],
    [
      35.404208,
      55.684811
    ],
    [
      35.413167,
      55.689761
    ],
    [
      35.421002,
      55.687986
    ],
    [
      35.427756183112194,
      55.67900719857997
    ],
    [
      35.427356,
      55.677754
    ],
    [
      35.425384,
      55.674742
    ],
    [
      35.431857,
      55.675631
    ],
    [
      35.435917,
      55.676859
    ],
    [
      35.447348,
      55.680264
    ],
    [
      35.452809,
      55.681042
    ],
    [
      35.458775383862715,
      55.68164048835026
    ],
    [
      35.464268,
      55.679446
    ],
    [
      35.46546254787321,
      55.679589720085474
    ],
    [
      35.466148,
      55.679311
    ],
    [
      35.47436696627091,
      55.68045899833854
    ],
    [
      35.47686053916803,
      55.68068179624977
    ],
    [
      35.47970977055356,
      55.680671370416164
    ],
    [
      35.47989188851961,
      55.68066622877015
    ],
    [
      35.483812,
      55.680435
    ],
    [
      35.48393077296617,
      55.680459376037675
    ],
    [
      35.485379,
      55.680359
    ],
    [
      35.488743,
      55.681447
    ],
    [
      35.490957,
      55.684247
    ],
    [
      35.495880765882845,
      55.68619124084708
    ],
    [
      35.503395,
      55.686592
    ],
    [
      35.512541,
      55.686976
    ],
    [
      35.51588254482667,
      55.68748504134075
    ],
    [
      35.515929,
      55.68735
    ],
    [
      35.51667,
      55.687605
    ],
    [
      35.525971,
      55.682796
    ],
    [
      35.526314,
      55.679212
    ],
    [
      35.526802,
      55.679259
    ],
    [
      35.530304,
      55.679807
    ],
    [
      35.53766609729817,
      55.67672989234386
    ],
    [
      35.53778363665101,
      55.675695421091994
    ],
    [
      35.535825,
      55.673305
    ],
    [
      35.53246657005133,
      55.67214195657728
    ],
    [
      35.530254,
      55.672034
    ],
    [
      35.52899072713157,
      55.67181202688907
    ],
    [
      35.527737,
      55.671711
    ],
    [
      35.52425,
      55.67112
    ],
    [
      35.5226240259324,
      55.67061774841162
    ],
    [
      35.522312,
      55.670557
    ],
    [
      35.518441,
      55.669852
    ],
    [
      35.514884,
      55.669928
    ],
    [
      35.508099,
      55.668442
    ],
    [
      35.498344,
      55.666299
    ],
    [
      35.498200632484036,
      55.66610076563754
    ],
    [
      35.493795,
      55.665167
    ],
    [
      35.49693243360332,
      55.66434722617708
    ],
    [
      35.496737,
      55.664077
    ],
    [
      35.497256,
      55.662867
    ],
    [
      35.499181,
      55.661434
    ],
    [
      35.507895560107215,
      55.659820108934106
    ],
    [
      35.510944,
      55.658132
    ],
    [
      35.512038,
      55.654778
    ],
    [
      35.51333569514085,
      55.65630301036284
    ],
    [
      35.513983,
      55.655808
    ],
    [
      35.51415779927164,
      55.65726912118449
    ],
    [
      35.515155,
      55.658441
    ],
    [
      35.521024,
      55.666255
    ],
    [
      35.524611,
      55.667445
    ],
    [
      35.5268027815786,
      55.66787342038168
    ],
    [
      35.526809,
      55.667801
    ],
    [
      35.527248,
      55.667838
    ],
    [
      35.528714,
      55.668247
    ],
    [
      35.532338,
      55.668477
    ],
    [
      35.536354,
      55.669241
    ],
    [
      35.540187,
      55.671065
    ],
    [
      35.540407677543215,
      55.67138326815523
    ],
    [
      35.540733,
      55.671529
    ],
    [
      35.54140875447471,
      55.67282705307141
    ],
    [
      35.542978117906635,
      55.67509043881103
    ],
    [
      35.544554,
      55.675225
    ],
    [
      35.547114,
      55.676138
    ],
    [
      35.55126652795742,
      55.68310816099593
    ],
    [
      35.554932402320915,
      55.68551139691375
    ],
    [
      35.554976725267586,
      55.68550401260486
    ],
    [
      35.561595,
      55.682473
    ],
    [
      35.566307,
      55.680323
    ],
    [
      35.570102,
      55.678671
    ],
    [
      35.574539,
      55.675268
    ],
    [
      35.574898,
      55.67944
    ],
    [
      35.571625,
      55.681932
    ],
    [
      35.57608855697326,
      55.69199508588848
    ],
    [
      35.578027158014706,
      55.69160544259495
    ],
    [
      35.585137,
      55.687479
    ],
    [
      35.589275,
      55.684966
    ],
    [
      35.58960264729282,
      55.685260956027065
    ],
    [
      35.593236,
      55.681622
    ],
    [
      35.598021,
      55.673814
    ],
    [
      35.597373,
      55.661841
    ],
    [
      35.60057,
      55.660186
    ],
    [
      35.601521,
      55.662023
    ],
    [
      35.604191,
      55.668089
    ],
    [
      35.6066,
      55.673854
    ],
    [
      35.613719,
      55.682043
    ],
    [
      35.613975759942015,
      55.68195235153907
    ],
    [
      35.613605,
      55.681523
    ],
    [
      35.616333,
      55.679173
    ],
    [
      35.61876723786637,
      55.679964189851226
    ],
    [
      35.627633,
      55.674599
    ],
    [
      35.62923094255266,
      55.67420396343784
    ],
    [
      35.629115,
      55.667977
    ],
    [
      35.627426,
      55.665417
    ],
    [
      35.626454,
      55.660907
    ],
    [
      35.633353,
      55.658866
    ],
    [
      35.634054819557804,
      55.65631626681098
    ],
    [
      35.633843,
      55.656277
    ],
    [
      35.633958,
      55.651678
    ],
    [
      35.639668,
      55.645895
    ],
    [
      35.643922,
      55.642527
    ],
    [
      35.640365,
      55.632232
    ],
    [
      35.634651,
      55.63048
    ],
    [
      35.626212,
      55.62883
    ],
    [
      35.620642,
      55.630724
    ],
    [
      35.616671,
      55.630924
    ],
    [
      35.609347,
      55.630935
    ],
    [
      35.606872,
      55.630779
    ],
    [
      35.602127,
      55.630372
    ],
    [
      35.59915583965249,
      55.63011709648789
    ],
    [
      35.589514,
      55.635919
    ],
    [
      35.588069,
      55.639783
    ],
    [
      35.587141,
      55.641122
    ],
    [
      35.586772,
      55.641454
    ],
    [
      35.5845127254076,
      55.64036536503633
    ],
    [
      35.577046,
      55.643312
    ],
    [
      35.574957,
      55.639814
    ],
    [
      35.579222,
      55.638564
    ],
    [
      35.57892865505933,
      55.6337142567261
    ],
    [
      35.575899,
      55.628129
    ],
    [
      35.571565,
      55.627701
    ],
    [
      35.56314,
      55.634957
    ],
    [
      35.56240318407975,
      55.63711891693913
    ],
    [
      35.561932,
      55.638502
    ],
    [
      35.561931827274186,
      55.63850194115767
    ],
    [
      35.560893,
      55.64155
    ],
    [
      35.559603,
      55.645546
    ],
    [
      35.558206,
      55.649726
    ],
    [
      35.557053,
      55.653089
    ],
    [
      35.554226,
      55.656989
    ],
    [
      35.549224,
      55.655826
    ],
    [
      35.544072,
      55.65437
    ],
    [
      35.541459,
      55.651894
    ],
    [
      35.542249,
      55.650553
    ],
    [
      35.544513,
      55.648191
    ],
    [
      35.54487547907181,
      55.648090692109726
    ],
    [
      35.54498,
      55.647648
    ],
    [
      35.54655,
      55.644408
    ],
    [
      35.557466,
      55.640447
    ],
    [
      35.55852419372336,
      55.63734106599555
    ],
    [
      35.558524,
      55.637341
    ],
    [
      35.560256,
      55.632257
    ],
    [
      35.556833,
      55.622573
    ],
    [
      35.556833,
      55.620497447537474
    ],
    [
      35.556785,
      55.620468
    ],
    [
      35.5501,
      55.620601
    ],
    [
      35.545029,
      55.620687
    ],
    [
      35.535872,
      55.620766
    ],
    [
      35.529397,
      55.620788
    ],
    [
      35.523732,
      55.6201
    ],
    [
      35.51482615557094,
      55.61873939657999
    ],
    [
      35.51281810066507,
      55.61848144697373
    ],
    [
      35.507853,
      55.61843
    ],
    [
      35.505917,
      55.618494
    ],
    [
      35.498095,
      55.618693
    ],
    [
      35.490274,
      55.618893
    ],
    [
      35.49027399239367,
      55.618892699173635
    ],
    [
      35.484343,
      55.619044
    ],
    [
      35.478412,
      55.619242
    ],
    [
      35.474621,
      55.619337
    ],
    [
      35.47391227736799,
      55.61935446074949
    ],
    [
      35.47145,
      55.619416
    ],
    [
      35.47144997809998,
      55.61941512424287
    ],
    [
      35.470765,
      55.619432
    ],
    [
      35.465784,
      55.619256
    ],
    [
      35.462107,
      55.61904
    ],
    [
      35.455903,
      55.618616
    ],
    [
      35.446594,
      55.619171
    ],
    [
      35.44312,
      55.620214
    ],
    [
      35.440299,
      55.630089
    ],
    [
      35.442508,
      55.634412
    ],
    [
      35.445036,
      55.637579
    ],
    [
      35.44570925059489,
      55.64219614461226
    ],
    [
      35.446092,
      55.644483
    ],
    [
      35.44579292902129,
      55.64453312598094
    ],
    [
      35.445844,
      55.645996
    ],
    [
      35.44427146675782,
      55.64904355004923
    ],
    [
      35.443632,
      55.650345
    ],
    [
      35.44360640634769,
      55.6503324289303
    ],
    [
      35.443409,
      55.650715
    ],
    [
      35.44038,
      55.653551
    ],
    [
      35.436365,
      55.654888
    ],
    [
      35.432381,
      55.65514
    ],
    [
      35.428353,
      55.654396
    ],
    [
      35.432181,
      55.65119
    ],
    [
      35.441380673744085,
      55.64676415695747
    ],
    [
      35.44138116747727,
      55.64676315210614
    ],
    [
      35.44216170275616,
      55.642811504868334
    ],
    [
      35.44216119842764,
      55.64280849917176
    ],
    [
      35.437861,
      55.634757
    ],
    [
      35.427301,
      55.629458
    ],
    [
      35.424792432846466,
      55.62813233759354
    ],
    [
      35.41687,
      55.627058
    ],
    [
      35.412472,
      55.627307
    ],
    [
      35.401222,
      55.628923
    ],
    [
      35.398037,
      55.634277
    ],
    [
      35.397537,
      55.634666
    ],
    [
      35.395639,
      55.633611
    ],
    [
      35.39543,
      55.633388
    ],
    [
      35.395329,
      55.631823
    ],
    [
      35.397812,
      55.627768
    ],
    [
      35.401039,
      55.622138
    ],
    [
      35.40268980149764,
      55.62180743657434
    ],
    [
      35.40307,
      55.621467
    ],
    [
      35.40469385551257,
      55.62140613638088
    ],
    [
      35.405134,
      55.621318
    ],
    [
      35.415737,
      55.617078
    ],
    [
      35.427014,
      55.617278
    ],
    [
      35.4357292739221,
      55.616829544157405
    ],
    [
      35.435729,
      55.616809
    ],
    [
      35.43627009823022,
      55.616801715333786
    ],
    [
      35.436284,
      55.616801
    ],
    [
      35.44237021172427,
      55.616650393664465
    ],
    [
      35.445533,
      55.615731
    ],
    [
      35.449735,
      55.614788
    ],
    [
      35.45008833145299,
      55.614800934954935
    ],
    [
      35.450089,
      55.614784
    ],
    [
      35.453067,
      55.614901
    ],
    [
      35.456045,
      55.615019
    ],
    [
      35.462352,
      55.615449
    ],
    [
      35.465995,
      55.615662
    ],
    [
      35.470805,
      55.615833
    ],
    [
      35.47136004621928,
      55.615818848257966
    ],
    [
      35.47136,
      55.615817
    ],
    [
      35.478322,
      55.615643
    ],
    [
      35.484223,
      55.615446
    ],
    [
      35.495031,
      55.61517
    ],
    [
      35.507690513380496,
      55.6148331570871
    ],
    [
      35.509656,
      55.614768
    ],
    [
      35.50967901819483,
      55.61476878895435
    ],
    [
      35.509679,
      55.614768
    ],
    [
      35.511053,
      55.614736
    ],
    [
      35.51198284025164,
      55.61479850060248
    ],
    [
      35.5123,
      55.614796
    ],
    [
      35.51270459966504,
      55.61484701474037
    ],
    [
      35.513478,
      55.614899
    ],
    [
      35.520506,
      55.614392
    ],
    [
      35.522453,
      55.614571
    ],
    [
      35.524175,
      55.61472
    ],
    [
      35.535859,
      55.617166
    ],
    [
      35.544954,
      55.617087
    ],
    [
      35.550039,
      55.617001
    ],
    [
      35.556653,
      55.616871
    ],
    [
      35.55967011548979,
      55.616541135490294
    ],
    [
      35.564744,
      55.612192
    ],
    [
      35.566422,
      55.609007
    ],
    [
      35.568577,
      55.606204
    ],
    [
      35.569180954205656,
      55.60515060224397
    ],
    [
      35.569003,
      55.60463
    ],
    [
      35.57047603296487,
      55.59792889343933
    ],
    [
      35.563618,
      55.5941
    ],
    [
      35.562249,
      55.59077
    ],
    [
      35.564321,
      55.589919
    ],
    [
      35.566985,
      55.588666
    ],
    [
      35.573098,
      55.588795
    ],
    [
      35.581569,
      55.580578
    ],
    [
      35.582073,
      55.575363
    ],
    [
      35.582002,
      55.569473
    ],
    [
      35.582965,
      55.565051
    ],
    [
      35.583908,
      55.56103
    ],
    [
      35.57432335164209,
      55.55452099255594
    ],
    [
      35.5715021781982,
      55.5552023343125
    ],
    [
      35.57148697084016,
      55.555206043620466
    ],
    [
      35.565131,
      55.564714
    ],
    [
      35.562269,
      55.567903
    ],
    [
      35.56098632634342,
      55.568257128217326
    ],
    [
      35.56072222062741,
      55.568418610446415
    ],
    [
      35.557038,
      55.571766
    ],
    [
      35.555608,
      55.573808
    ],
    [
      35.553835,
      55.576342
    ],
    [
      35.55366362227629,
      55.57633236775175
    ],
    [
      35.553527,
      55.578639
    ],
    [
      35.552413,
      55.583284
    ],
    [
      35.55178666485651,
      55.58253994467468
    ],
    [
      35.540676,
      55.584351
    ],
    [
      35.537808,
      55.586527
    ],
    [
      35.534728,
      55.585494
    ],
    [
      35.53231,
      55.582741
    ],
    [
      35.529219,
      55.576682
    ],
    [
      35.527413734163815,
      55.56850100552226
    ],
    [
      35.526288,
      55.568162
    ],
    [
      35.522623,
      55.567531
    ],
    [
      35.512733,
      55.568179
    ],
    [
      35.509982,
      55.575988
    ],
    [
      35.50861128798862,
      55.57652965232708
    ],
    [
      35.510069,
      55.581212
    ],
    [
      35.50942469479553,
      55.5808026136765
    ],
    [
      35.501712,
      55.578527
    ],
    [
      35.50092668666946,
      55.57530706004223
    ],
    [
      35.496740413029805,
      55.57180676740651
    ],
    [
      35.49197,
      55.571116
    ],
    [
      35.487576,
      55.571974
    ],
    [
      35.482514,
      55.576471
    ],
    [
      35.47928433916463,
      55.57928622816677
    ],
    [
      35.478045,
      55.580985
    ],
    [
      35.47687113334789,
      55.5813897694777
    ],
    [
      35.476529,
      55.581688
    ],
    [
      35.471369,
      55.583287
    ],
    [
      35.466224,
      55.584882
    ],
    [
      35.461447,
      55.586409
    ],
    [
      35.452607,
      55.592198
    ],
    [
      35.4479477156545,
      55.591962234609774
    ],
    [
      35.442797,
      55.593606
    ],
    [
      35.435204,
      55.594873
    ],
    [
      35.426768,
      55.597852
    ],
    [
      35.424562,
      55.598475
    ],
    [
      35.422356,
      55.599099
    ],
    [
      35.421377,
      55.595634
    ],
    [
      35.425763,
      55.593451
    ],
    [
      35.434611,
      55.591323
    ],
    [
      35.443957,
      55.585918
    ],
    [
      35.448449303155826,
      55.58162552721561
    ],
    [
      35.447047,
      55.579374
    ],
    [
      35.43776,
      55.574852
    ],
    [
      35.428491,
      55.57034
    ],
    [
      35.4281150067185,
      55.570150986637785
    ],
    [
      35.428115,
      55.570151
    ],
    [
      35.420745,
      55.566447
    ],
    [
      35.42018852356537,
      55.56622955750868
    ],
    [
      35.420186,
      55.566236
    ],
    [
      35.4129182794376,
      55.56338879275789
    ],
    [
      35.403959,
      55.560812
    ],
    [
      35.397943,
      55.560904
    ],
    [
      35.390762,
      55.559971
    ],
    [
      35.386172,
      55.567971
    ],
    [
      35.393744,
      55.57349
    ],
    [
      35.397424,
      55.57333
    ],
    [
      35.401522,
      55.574117
    ],
    [
      35.403664,
      55.574279
    ],
    [
      35.405805,
      55.574442
    ],
    [
      35.405533,
      55.578031
    ],
    [
      35.403391,
      55.577869
    ],
    [
      35.40125,
      55.577706
    ],
    [
      35.393398,
      55.577074
    ],
    [
      35.389525,
      55.577066
    ],
    [
      35.386394,
      55.575463
    ],
    [
      35.379404,
      55.5709
    ],
    [
      35.372088,
      55.570892
    ],
    [
      35.37232924839301,
      55.57034343725048
    ],
    [
      35.370487,
      55.570042
    ],
    [
      35.373545,
      55.567579
    ],
    [
      35.375766,
      55.564173
    ],
    [
      35.369771,
      55.555604
    ],
    [
      35.366744,
      55.553612
    ],
    [
      35.352157,
      55.546963
    ],
    [
      35.34381133255156,
      55.55014158814099
    ],
    [
      35.343803829304846,
      55.55014615735704
    ],
    [
      35.338805,
      55.559358
    ],
    [
      35.344617,
      55.565121
    ],
    [
      35.344125,
      55.576564
    ],
    [
      35.345294,
      55.580679
    ],
    [
      35.343454,
      55.583809
    ],
    [
      35.339263,
      55.59247
    ],
    [
      35.334261,
      55.601151
    ],
    [
      35.331251,
      55.605438
    ],
    [
      35.330474937710655,
      55.60573267294488
    ],
    [
      35.329331,
      55.607117
    ],
    [
      35.32315915437268,
      55.60913075773597
    ],
    [
      35.316217,
      55.613396
    ],
    [
      35.311231,
      55.615793
    ],
    [
      35.306562,
      55.618586
    ],
    [
      35.300994,
      55.621714
    ],
    [
      35.298113,
      55.623767
    ],
    [
      35.297971,
      55.623599
    ],
    [
      35.297696,
      55.623158
    ],
    [
      35.297371,
      55.622416
    ],
    [
      35.29125,
      55.612176
    ],
    [
      35.287231,
      55.613644
    ],
    [
      35.28593,
      55.614209
    ],
    [
      35.284007,
      55.611166
    ],
    [
      35.287524,
      55.606542
    ],
    [
      35.289699,
      55.603673
    ],
    [
      35.29325,
      55.604094
    ],
    [
      35.30403570169522,
      55.60521696894157
    ],
    [
      35.304080212280056,
      55.60520608218059
    ],
    [
      35.30894814254082,
      55.604003991190346
    ],
    [
      35.31082566649425,
      55.60288698869226
    ],
    [
      35.310937,
      55.591948
    ],
    [
      35.309217,
      55.587468
    ],
    [
      35.312797,
      55.588866
    ],
    [
      35.31626,
      55.590674
    ],
    [
      35.31677351673988,
      55.59098564166463
    ],
    [
      35.316806,
      55.590929
    ],
    [
      35.318582,
      55.591947
    ],
    [
      35.320564,
      55.593286
    ],
    [
      35.321357,
      55.596961
    ],
    [
      35.330963,
      55.599708
    ],
    [
      35.333486,
      55.59415
    ],
    [
      35.337503,
      55.585384
    ],
    [
      35.339231,
      55.573835
    ],
    [
      35.337909,
      55.570258
    ],
    [
      35.337870044181905,
      55.56988776306929
    ],
    [
      35.337813,
      55.569903
    ],
    [
      35.337349,
      55.568166
    ],
    [
      35.336864586533494,
      55.565456123956025
    ],
    [
      35.3346862978612,
      55.56137839989229
    ],
    [
      35.324866,
      55.558256
    ],
    [
      35.321574,
      55.561476
    ],
    [
      35.318939,
      55.564211
    ],
    [
      35.316182,
      55.566448
    ],
    [
      35.31264,
      55.568339
    ],
    [
      35.307454,
      55.572878
    ],
    [
      35.307283,
      55.573041
    ],
    [
      35.3017980913651,
      55.56855263785807
    ],
    [
      35.29678,
      55.56811
    ],
    [
      35.293608221692445,
      55.568139570509175
    ],
    [
      35.28425,
      55.571675
    ],
    [
      35.280782,
      55.572545
    ],
    [
      35.27789590400185,
      55.57284710355877
    ],
    [
      35.276611,
      55.572982
    ],
    [
      35.27661095871406,
      55.57298160585687
    ],
    [
      35.276483,
      55.572995
    ],
    [
      35.27331,
      55.573281
    ],
    [
      35.27330997040401,
      55.57328067151192
    ],
    [
      35.273273,
      55.573284
    ],
    [
      35.27299214863456,
      55.57016681069619
    ],
    [
      35.259781,
      55.573323
    ],
    [
      35.246637,
      55.573343
    ],
    [
      35.239684,
      55.573705
    ],
    [
      35.235612,
      55.57397
    ],
    [
      35.233111082655405,
      55.57413218181748
    ],
    [
      35.230249,
      55.574318
    ],
    [
      35.223066521724554,
      55.57457488405849
    ],
    [
      35.219886,
      55.574689
    ],
    [
      35.21138,
      55.574944
    ],
    [
      35.206199,
      55.575879
    ],
    [
      35.203644,
      55.576296
    ],
    [
      35.203065,
      55.572743
    ],
    [
      35.205619,
      55.572326
    ],
    [
      35.214956,
      55.568291
    ],
    [
      35.224938607451406,
      55.57090537340866
    ],
    [
      35.225227,
      55.570895
    ],
    [
      35.23012,
      55.57072
    ],
    [
      35.23086204493286,
      55.570671692698916
    ],
    [
      35.230862,
      55.570671
    ],
    [
      35.23130700560476,
      55.570642086035456
    ],
    [
      35.231307,
      55.570642
    ],
    [
      35.235379,
      55.570377
    ],
    [
      35.23945,
      55.570112
    ],
    [
      35.24645,
      55.569747
    ],
    [
      35.259775,
      55.569723
    ],
    [
      35.267175,
      55.554812
    ],
    [
      35.261628,
      55.554451
    ],
    [
      35.259729,
      55.549302
    ],
    [
      35.262177,
      55.545967
    ],
    [
      35.265354,
      55.534902
    ],
    [
      35.269045,
      55.530281
    ],
    [
      35.270588,
      55.527015
    ],
    [
      35.27394991028543,
      55.52693610758856
    ],
    [
      35.275754,
      55.523909
    ],
    [
      35.26892545229828,
      55.51768095108074
    ],
    [
      35.265893,
      55.518306
    ],
    [
      35.262431,
      55.516543
    ],
    [
      35.259042,
      55.512121
    ],
    [
      35.253421,
      55.506017
    ],
    [
      35.249634,
      55.50452
    ],
    [
      35.24759395238354,
      55.50372767660631
    ],
    [
      35.247559,
      55.503752
    ],
    [
      35.24504,
      55.502733
    ],
    [
      35.23328160915324,
      55.50119847101261
    ],
    [
      35.232556,
      55.501224
    ],
    [
      35.223887,
      55.501528
    ],
    [
      35.22388696342941,
      55.50152695570657
    ],
    [
      35.215969,
      55.501804
    ],
    [
      35.21027758710968,
      55.50165640368047
    ],
    [
      35.204475,
      55.51728
    ],
    [
      35.200976,
      55.518208
    ],
    [
      35.195595,
      55.517462
    ],
    [
      35.190936,
      55.516538
    ],
    [
      35.186725,
      55.515773
    ],
    [
      35.18608517515186,
      55.515172917269204
    ],
    [
      35.184833,
      55.515084
    ],
    [
      35.183796,
      55.514982
    ],
    [
      35.183693,
      55.514969
    ],
    [
      35.181511,
      55.514626
    ],
    [
      35.179329,
      55.514282
    ],
    [
      35.177479,
      55.505549
    ],
    [
      35.169095,
      55.503127
    ],
    [
      35.16310864353721,
      55.50461665479709
    ],
    [
      35.158605,
      55.51341
    ],
    [
      35.158642,
      55.515076
    ],
    [
      35.158588,
      55.51626
    ],
    [
      35.154991,
      55.516096
    ],
    [
      35.151626,
      55.512753
    ],
    [
      35.144345,
      55.505401
    ],
    [
      35.140464,
      55.505746
    ],
    [
      35.136839,
      55.506102
    ],
    [
      35.132534,
      55.506477
    ],
    [
      35.125929,
      55.508603
    ],
    [
      35.124664,
      55.509057
    ],
    [
      35.121564,
      55.507447
    ],
    [
      35.121276,
      55.503858
    ]
]

export const Mo150Area = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              35.121276,
              55.503858
            ],
            [
              35.122971,
              55.503722
            ],
            [
              35.131001,
              55.50294
            ],
            [
              35.132784,
              55.502763
            ],
            [
              35.136518,
              55.502516
            ],
            [
              35.143829,
              55.497407
            ],
            [
              35.148881,
              55.501354
            ],
            [
              35.15375790013799,
              55.501662832932965
            ],
            [
              35.153705,
              55.500935
            ],
            [
              35.154903,
              55.500848
            ],
            [
              35.15696239145845,
              55.50115847617128
            ],
            [
              35.156907,
              55.500638
            ],
            [
              35.157437,
              55.500582
            ],
            [
              35.160622000856705,
              55.50028974389384
            ],
            [
              35.163427,
              55.499333
            ],
            [
              35.165146,
              55.499059
            ],
            [
              35.173568,
              55.497288
            ],
            [
              35.177019099421784,
              55.488925859620934
            ],
            [
              35.177258,
              55.487902
            ],
            [
              35.17729577675373,
              55.487864267741855
            ],
            [
              35.175512,
              55.48462
            ],
            [
              35.174377,
              55.481035
            ],
            [
              35.174354,
              55.480363
            ],
            [
              35.17442,
              55.479592
            ],
            [
              35.178007,
              55.479898
            ],
            [
              35.189362,
              55.479021
            ],
            [
              35.191476,
              55.481935
            ],
            [
              35.20013712504998,
              55.48978725419728
            ],
            [
              35.223463,
              55.482024
            ],
            [
              35.224728,
              55.472798
            ],
            [
              35.227467,
              55.468441
            ],
            [
              35.230346,
              55.461129
            ],
            [
              35.22833520058676,
              55.450063142428256
            ],
            [
              35.226012,
              55.447119
            ],
            [
              35.216806,
              55.445536
            ],
            [
              35.213682,
              55.448075
            ],
            [
              35.212499,
              55.448994
            ],
            [
              35.210291,
              55.44615
            ],
            [
              35.206845,
              55.442065
            ],
            [
              35.20751,
              55.441402
            ],
            [
              35.210493,
              55.440702
            ],
            [
              35.219154,
              55.434615
            ],
            [
              35.221081,
              55.432819
            ],
            [
              35.223007,
              55.431023
            ],
            [
              35.225462,
              55.433656
            ],
            [
              35.23100009291659,
              55.441093173915235
            ],
            [
              35.235285,
              55.441394
            ],
            [
              35.242823,
              55.440926
            ],
            [
              35.24282303616304,
              55.44092658266271
            ],
            [
              35.245267,
              55.440775
            ],
            [
              35.249419,
              55.440476
            ],
            [
              35.253459,
              55.44019
            ],
            [
              35.256148,
              55.439827
            ],
            [
              35.256629,
              55.443395
            ],
            [
              35.25394,
              55.443757
            ],
            [
              35.249677,
              55.444067
            ],
            [
              35.245525,
              55.444365
            ],
            [
              35.24304583601137,
              55.444519175635726
            ],
            [
              35.235105,
              55.453024
            ],
            [
              35.235968,
              55.456771
            ],
            [
              35.23336,
              55.463325
            ],
            [
              35.231319,
              55.468513
            ],
            [
              35.23529917459179,
              55.470191804422655
            ],
            [
              35.242444,
              55.470453
            ],
            [
              35.250556,
              55.47181
            ],
            [
              35.25055602045507,
              55.471810608242436
            ],
            [
              35.251082,
              55.471793
            ],
            [
              35.25527,
              55.47175
            ],
            [
              35.259446,
              55.471707
            ],
            [
              35.264887,
              55.471028
            ],
            [
              35.269882,
              55.469815
            ],
            [
              35.273962,
              55.469433
            ],
            [
              35.279939,
              55.472243
            ],
            [
              35.283836,
              55.47369
            ],
            [
              35.282584,
              55.477065
            ],
            [
              35.280590066798354,
              55.47632482007625
            ],
            [
              35.28059,
              55.476325
            ],
            [
              35.27769,
              55.475249
            ],
            [
              35.274789,
              55.474172
            ],
            [
              35.268069,
              55.473953
            ],
            [
              35.263415,
              55.475097
            ],
            [
              35.259507,
              55.475307
            ],
            [
              35.255307,
              55.47535
            ],
            [
              35.251119,
              55.475393
            ],
            [
              35.25067699630047,
              55.47540788999247
            ],
            [
              35.250677,
              55.475408
            ],
            [
              35.24726,
              55.475523
            ],
            [
              35.245168989909146,
              55.476024515900775
            ],
            [
              35.244459,
              55.478879
            ],
            [
              35.244214,
              55.478851
            ],
            [
              35.238329214667516,
              55.486409393882944
            ],
            [
              35.245956,
              55.491474
            ],
            [
              35.24596364374007,
              55.491479983705986
            ],
            [
              35.246858,
              55.492062
            ],
            [
              35.251953,
              55.495377
            ],
            [
              35.258429,
              55.495855
            ],
            [
              35.264449,
              55.495187
            ],
            [
              35.27229624903329,
              55.49430264810971
            ],
            [
              35.276752,
              55.4938
            ],
            [
              35.27676775499054,
              55.49379867132012
            ],
            [
              35.280232,
              55.493408
            ],
            [
              35.289505,
              55.49237
            ],
            [
              35.29407522042141,
              55.49193811317275
            ],
            [
              35.298358,
              55.491453
            ],
            [
              35.307929,
              55.490368
            ],
            [
              35.31249139795665,
              55.48998241822454
            ],
            [
              35.312483,
              55.489874
            ],
            [
              35.31295,
              55.489838
            ],
            [
              35.315064,
              55.489765
            ],
            [
              35.316483,
              55.489695
            ],
            [
              35.321244,
              55.489687
            ],
            [
              35.322383485650356,
              55.48968508369031
            ],
            [
              35.32405,
              55.489682
            ],
            [
              35.32413334738909,
              55.48968214089151
            ],
            [
              35.326001,
              55.489679
            ],
            [
              35.338809,
              55.489633
            ],
            [
              35.35193827474078,
              55.48958718399044
            ],
            [
              35.353661,
              55.480142
            ],
            [
              35.353613,
              55.476543
            ],
            [
              35.3561937074301,
              55.47464877648233
            ],
            [
              35.356161,
              55.474156
            ],
            [
              35.354963424984774,
              55.465994381260685
            ],
            [
              35.345617,
              55.459153
            ],
            [
              35.341609,
              55.457419
            ],
            [
              35.337398,
              55.455438
            ],
            [
              35.334264,
              55.453579
            ],
            [
              35.329791,
              55.444463
            ],
            [
              35.325028,
              55.443519
            ],
            [
              35.32247,
              55.44314
            ],
            [
              35.32267405844198,
              55.44176636651814
            ],
            [
              35.319734,
              55.440395
            ],
            [
              35.319793,
              55.433321
            ],
            [
              35.324815,
              55.435568
            ],
            [
              35.330559,
              55.440946
            ],
            [
              35.334660539535726,
              55.44268384226168
            ],
            [
              35.334795,
              55.441574
            ],
            [
              35.337145,
              55.441858
            ],
            [
              35.337406841234504,
              55.441901134550974
            ],
            [
              35.337408,
              55.441892
            ],
            [
              35.339556,
              55.442164
            ],
            [
              35.341704,
              55.442437
            ],
            [
              35.345096,
              55.442781
            ],
            [
              35.34918,
              55.443001
            ],
            [
              35.353336,
              55.444174
            ],
            [
              35.357888,
              55.445682
            ],
            [
              35.362413,
              55.446857
            ],
            [
              35.366086,
              55.449978
            ],
            [
              35.362856,
              55.453731
            ],
            [
              35.36164028927192,
              55.45493675920358
            ],
            [
              35.358617,
              55.466099
            ],
            [
              35.35897812888404,
              55.468561183238194
            ],
            [
              35.360756,
              55.468193
            ],
            [
              35.361273,
              55.470689
            ],
            [
              35.360919,
              55.481899
            ],
            [
              35.367752,
              55.489534
            ],
            [
              35.378579,
              55.489544
            ],
            [
              35.38104095066968,
              55.48959139729012
            ],
            [
              35.381041,
              55.489547
            ],
            [
              35.38181796388233,
              55.48959134120964
            ],
            [
              35.381818,
              55.489548
            ],
            [
              35.389344145534345,
              55.4895554993621
            ],
            [
              35.389405,
              55.489555
            ],
            [
              35.39515974904791,
              55.489478677068334
            ],
            [
              35.39940107873355,
              55.48906348867292
            ],
            [
              35.405046,
              55.488267
            ],
            [
              35.40859818599212,
              55.487504919258974
            ],
            [
              35.41209,
              55.486678
            ],
            [
              35.418568,
              55.485077
            ],
            [
              35.42918716914487,
              55.47778185614026
            ],
            [
              35.428676,
              55.474268
            ],
            [
              35.427952,
              55.4733
            ],
            [
              35.42855735683667,
              55.47284729471389
            ],
            [
              35.428431,
              55.47192
            ],
            [
              35.42920527670136,
              55.47224869432418
            ],
            [
              35.429688,
              55.470407
            ],
            [
              35.43080600043881,
              55.47116568680331
            ],
            [
              35.430835,
              55.471144
            ],
            [
              35.437921,
              55.475994
            ],
            [
              35.448682,
              55.478053
            ],
            [
              35.45374,
              55.477372
            ],
            [
              35.457661,
              55.476854
            ],
            [
              35.462758,
              55.476166
            ],
            [
              35.46557346959563,
              55.475786441991325
            ],
            [
              35.468535,
              55.475387
            ],
            [
              35.480263,
              55.47387
            ],
            [
              35.491988,
              55.472353
            ],
            [
              35.501666,
              55.471089
            ],
            [
              35.511349,
              55.469824
            ],
            [
              35.51965334761786,
              55.462894246116576
            ],
            [
              35.511067,
              55.454166
            ],
            [
              35.507925,
              55.452144
            ],
            [
              35.5080163086921,
              55.446474247062326
            ],
            [
              35.50697892147445,
              55.441608579742784
            ],
            [
              35.498917,
              55.436776
            ],
            [
              35.495764,
              55.435061
            ],
            [
              35.492601,
              55.43334
            ],
            [
              35.489138,
              55.431426
            ],
            [
              35.482604,
              55.429627
            ],
            [
              35.479075,
              55.429043
            ],
            [
              35.479075074856965,
              55.42904254780282
            ],
            [
              35.47752,
              55.428785
            ],
            [
              35.471824,
              55.427814
            ],
            [
              35.465505,
              55.426737
            ],
            [
              35.455965,
              55.425139
            ],
            [
              35.453644871240954,
              55.42475426978806
            ],
            [
              35.453642,
              55.424755
            ],
            [
              35.45361449014064,
              55.4247492319094
            ],
            [
              35.451225,
              55.424353
            ],
            [
              35.45042976108855,
              55.4240814783793
            ],
            [
              35.449445,
              55.423875
            ],
            [
              35.44642148485994,
              55.42271291650168
            ],
            [
              35.446246,
              55.422653
            ],
            [
              35.44542269533566,
              55.422329033249675
            ],
            [
              35.444257,
              55.421881
            ],
            [
              35.4415809920694,
              55.42081736130734
            ],
            [
              35.425948,
              55.414681
            ],
            [
              35.425727257865745,
              55.41459441424573
            ],
            [
              35.42014367125455,
              55.41240795823154
            ],
            [
              35.413493,
              55.409804
            ],
            [
              35.413495870493186,
              55.40979667182548
            ],
            [
              35.413099,
              55.409641
            ],
            [
              35.408281,
              55.409216
            ],
            [
              35.395363,
              55.408482
            ],
            [
              35.38242,
              55.407747
            ],
            [
              35.377213,
              55.417571
            ],
            [
              35.371047,
              55.418596
            ],
            [
              35.3706177433137,
              55.417717755961974
            ],
            [
              35.37059,
              55.417731
            ],
            [
              35.369176,
              55.414768
            ],
            [
              35.367752,
              55.410861
            ],
            [
              35.35639,
              55.407963
            ],
            [
              35.353774,
              55.409648
            ],
            [
              35.351179,
              55.407153
            ],
            [
              35.351800804789484,
              55.40149557753253
            ],
            [
              35.35113,
              55.401429
            ],
            [
              35.345804,
              55.40365
            ],
            [
              35.33793,
              55.40645
            ],
            [
              35.330352,
              55.413704
            ],
            [
              35.324247,
              55.411777
            ],
            [
              35.328822,
              55.404047
            ],
            [
              35.334081,
              55.4032
            ],
            [
              35.344503,
              55.400294
            ],
            [
              35.34939231016902,
              55.39825613708238
            ],
            [
              35.3547497956394,
              55.39323870946143
            ],
            [
              35.356748,
              55.39019
            ],
            [
              35.356754852281455,
              55.39019237744549
            ],
            [
              35.35734,
              55.388525
            ],
            [
              35.360719,
              55.38433
            ],
            [
              35.355736,
              55.374694
            ],
            [
              35.351492,
              55.375133
            ],
            [
              35.350846,
              55.375424
            ],
            [
              35.350544,
              55.375561
            ],
            [
              35.350177,
              55.375293
            ],
            [
              35.349708,
              55.37496
            ],
            [
              35.349259,
              55.373694
            ],
            [
              35.349102203146536,
              55.372060395573214
            ],
            [
              35.34184,
              55.365751
            ],
            [
              35.333741,
              55.365813
            ],
            [
              35.331589,
              55.365888
            ],
            [
              35.329436,
              55.365962
            ],
            [
              35.329390470125475,
              55.3659334686084
            ],
            [
              35.329076,
              55.36597
            ],
            [
              35.328275,
              55.365937
            ],
            [
              35.327108,
              55.365804
            ],
            [
              35.319639,
              55.36016
            ],
            [
              35.319639003713775,
              55.36015996345401
            ],
            [
              35.319403,
              55.360136
            ],
            [
              35.31940314535653,
              55.36013456959588
            ],
            [
              35.316138,
              55.359802
            ],
            [
              35.310163,
              55.359503
            ],
            [
              35.306234,
              55.359312
            ],
            [
              35.296700559479326,
              55.360814590511914
            ],
            [
              35.292081,
              55.362592
            ],
            [
              35.29086319744056,
              55.36204145821528
            ],
            [
              35.281162,
              55.362569
            ],
            [
              35.279119,
              55.362721
            ],
            [
              35.278372,
              55.362792
            ],
            [
              35.278035,
              55.359207
            ],
            [
              35.278781,
              55.359137
            ],
            [
              35.283208,
              55.3586
            ],
            [
              35.28688,
              55.358109
            ],
            [
              35.297702,
              55.356201
            ],
            [
              35.303023,
              55.355479
            ],
            [
              35.306781,
              55.355754
            ],
            [
              35.310328,
              55.355906
            ],
            [
              35.316344,
              55.356208
            ],
            [
              35.31976688003645,
              55.356555180520466
            ],
            [
              35.319767,
              55.356554
            ],
            [
              35.320002999417454,
              55.3565780057326
            ],
            [
              35.320003,
              55.356578
            ],
            [
              35.32064496940015,
              55.35664330112263
            ],
            [
              35.320645,
              55.356643
            ],
            [
              35.3236343336609,
              55.356946870527466
            ],
            [
              35.333878,
              55.357569
            ],
            [
              35.335894,
              55.360476
            ],
            [
              35.341881,
              55.362151
            ],
            [
              35.346753,
              55.353292
            ],
            [
              35.342751,
              55.347541
            ],
            [
              35.33807,
              55.346068
            ],
            [
              35.332879,
              55.344611
            ],
            [
              35.324848,
              55.340436
            ],
            [
              35.32184,
              55.338198
            ],
            [
              35.316079,
              55.336811
            ],
            [
              35.312422,
              55.335744
            ],
            [
              35.310462,
              55.332745
            ],
            [
              35.303906,
              55.327051
            ],
            [
              35.296728,
              55.327409
            ],
            [
              35.296549,
              55.323813
            ],
            [
              35.303727,
              55.323456
            ],
            [
              35.310905,
              55.323098
            ],
            [
              35.312988,
              55.324162
            ],
            [
              35.31852834981886,
              55.33164734934673
            ],
            [
              35.323467,
              55.334987
            ],
            [
              35.330466236946286,
              55.33925540597587
            ],
            [
              35.336627,
              55.341911
            ],
            [
              35.341605,
              55.343511
            ],
            [
              35.345586,
              55.344516
            ],
            [
              35.34836320498837,
              55.345959027004035
            ],
            [
              35.348619,
              55.34535
            ],
            [
              35.349274,
              55.345625
            ],
            [
              35.34946147924548,
              55.34581304056058
            ],
            [
              35.349512,
              55.345746
            ],
            [
              35.350094,
              55.346098
            ],
            [
              35.350944,
              55.3473
            ],
            [
              35.35876141751296,
              55.35512024581162
            ],
            [
              35.36012811872602,
              55.35508681834219
            ],
            [
              35.3667116000501,
              55.35133663985592
            ],
            [
              35.36837448037846,
              55.349390608101494
            ],
            [
              35.369579,
              55.347681
            ],
            [
              35.37481734578682,
              55.35112950764331
            ],
            [
              35.380352906790336,
              55.350603543124286
            ],
            [
              35.383454,
              55.347796
            ],
            [
              35.38599173705126,
              55.346760021351706
            ],
            [
              35.386041,
              55.346712
            ],
            [
              35.38841403549023,
              55.345710963033575
            ],
            [
              35.39418563531028,
              55.34280678223241
            ],
            [
              35.394675921245046,
              55.34252455423324
            ],
            [
              35.396789,
              55.341299
            ],
            [
              35.39678906434567,
              55.34129911098381
            ],
            [
              35.397022,
              55.341164
            ],
            [
              35.3970221807506,
              55.34116431175975
            ],
            [
              35.398073,
              55.340555
            ],
            [
              35.403913,
              55.337304
            ],
            [
              35.409104,
              55.328252
            ],
            [
              35.409376,
              55.325791
            ],
            [
              35.409386856244694,
              55.3257922015296
            ],
            [
              35.409418,
              55.325516
            ],
            [
              35.409731,
              55.324288
            ],
            [
              35.41044,
              55.322679
            ],
            [
              35.41044807982221,
              55.32268255914451
            ],
            [
              35.410489,
              55.32257
            ],
            [
              35.41291491113461,
              55.31752120687804
            ],
            [
              35.412922,
              55.317505
            ],
            [
              35.415367,
              55.312417
            ],
            [
              35.417501,
              55.308613
            ],
            [
              35.422631,
              55.301499
            ],
            [
              35.425551,
              55.303605
            ],
            [
              35.420421,
              55.310719
            ],
            [
              35.418589,
              55.314023
            ],
            [
              35.41758142997852,
              55.31612009350878
            ],
            [
              35.416159530118925,
              55.31908072533595
            ],
            [
              35.41354702741284,
              55.32493021410035
            ],
            [
              35.412687,
              55.328611
            ],
            [
              35.415216,
              55.337836
            ],
            [
              35.423719,
              55.341239
            ],
            [
              35.42402583492679,
              55.341482017346344
            ],
            [
              35.426602,
              55.341728
            ],
            [
              35.431812,
              55.350091
            ],
            [
              35.438105,
              55.34971
            ],
            [
              35.443061,
              55.349622
            ],
            [
              35.448018,
              55.349535
            ],
            [
              35.448081,
              55.353134
            ],
            [
              35.439457,
              55.358957
            ],
            [
              35.436051,
              55.360123
            ],
            [
              35.428182,
              55.353179
            ],
            [
              35.42789509408347,
              55.353091939715654
            ],
            [
              35.427889,
              55.353121
            ],
            [
              35.426056,
              55.352736
            ],
            [
              35.422936,
              55.352484
            ],
            [
              35.422493,
              55.352429
            ],
            [
              35.421622,
              55.350264
            ],
            [
              35.417234059289534,
              55.34440038819536
            ],
            [
              35.416435797061226,
              55.343703123364435
            ],
            [
              35.405664,
              55.34045
            ],
            [
              35.401811,
              55.342595
            ],
            [
              35.39662136036115,
              55.34555868162089
            ],
            [
              35.395907,
              55.345973
            ],
            [
              35.393218,
              55.347532
            ],
            [
              35.392822,
              55.347743
            ],
            [
              35.39104124674788,
              55.34852700095116
            ],
            [
              35.390742,
              55.348686
            ],
            [
              35.3899277661006,
              55.34901722602053
            ],
            [
              35.389181,
              55.349346
            ],
            [
              35.38732662926144,
              55.35010314791401
            ],
            [
              35.380986,
              55.358485
            ],
            [
              35.37632001664428,
              55.36235605844636
            ],
            [
              35.37283,
              55.369575
            ],
            [
              35.379595,
              55.375207
            ],
            [
              35.387297,
              55.376308
            ],
            [
              35.395643,
              55.377632
            ],
            [
              35.396474234635875,
              55.37813824946419
            ],
            [
              35.397185,
              55.37828
            ],
            [
              35.400003,
              55.37604
            ],
            [
              35.406787,
              55.376829
            ],
            [
              35.407641,
              55.380326
            ],
            [
              35.403919,
              55.384614
            ],
            [
              35.401174,
              55.386944
            ],
            [
              35.40038,
              55.386008
            ],
            [
              35.40002612651172,
              55.384904475800475
            ],
            [
              35.399703,
              55.385163
            ],
            [
              35.386788,
              55.379872
            ],
            [
              35.377019,
              55.378884
            ],
            [
              35.367482,
              55.382035
            ],
            [
              35.363437,
              55.386691
            ],
            [
              35.360572,
              55.390111
            ],
            [
              35.36581924978574,
              55.400059173398475
            ],
            [
              35.37499,
              55.402626
            ],
            [
              35.382675,
              55.404156
            ],
            [
              35.38414796401279,
              55.40423963401003
            ],
            [
              35.384148,
              55.404239
            ],
            [
              35.3961493801382,
              55.4049204819357
            ],
            [
              35.410287,
              55.405709
            ],
            [
              35.414091,
              55.406181
            ],
            [
              35.41480311044524,
              55.406459376837425
            ],
            [
              35.414806,
              55.406452
            ],
            [
              35.416213890653616,
              55.4070032791539
            ],
            [
              35.416214,
              55.407003
            ],
            [
              35.422072428121346,
              55.40929727092804
            ],
            [
              35.427537,
              55.411437
            ],
            [
              35.440269,
              55.416422
            ],
            [
              35.442932,
              55.41748
            ],
            [
              35.44673898926572,
              55.41897789941442
            ],
            [
              35.4477403836748,
              55.41936290039541
            ],
            [
              35.45146871595688,
              55.420653444723214
            ],
            [
              35.45212057843841,
              55.42078322646338
            ],
            [
              35.45788563077389,
              55.41823337258363
            ],
            [
              35.461819,
              55.415039
            ],
            [
              35.46313220534138,
              55.41557232517712
            ],
            [
              35.464199,
              55.414922
            ],
            [
              35.468056,
              55.417572
            ],
            [
              35.470571,
              55.421055
            ],
            [
              35.478125,
              55.425236
            ],
            [
              35.488005,
              55.420222
            ],
            [
              35.489236,
              55.418233
            ],
            [
              35.489289672937275,
              55.41825222953004
            ],
            [
              35.489556,
              55.417833
            ],
            [
              35.493052,
              55.416187
            ],
            [
              35.50093,
              55.413383
            ],
            [
              35.505871,
              55.411496
            ],
            [
              35.51045,
              55.405727
            ],
            [
              35.510923,
              55.405021
            ],
            [
              35.517867,
              55.397448
            ],
            [
              35.51920851336375,
              55.39598477675446
            ],
            [
              35.51922,
              55.395972
            ],
            [
              35.519632824712325,
              55.3955219694166
            ],
            [
              35.523844766209294,
              55.39092789613059
            ],
            [
              35.525636,
              55.388974
            ],
            [
              35.52563787224235,
              55.38897571633972
            ],
            [
              35.527453,
              55.386997
            ],
            [
              35.530036,
              55.377482
            ],
            [
              35.530222,
              55.374602
            ],
            [
              35.533815,
              55.374834
            ],
            [
              35.533629,
              55.377713
            ],
            [
              35.533443,
              55.380593
            ],
            [
              35.532293,
              55.385062
            ],
            [
              35.530235,
              55.389282
            ],
            [
              35.52828863650782,
              55.391405750046545
            ],
            [
              35.52829,
              55.391407
            ],
            [
              35.52746489843164,
              55.392306906889296
            ],
            [
              35.527465,
              55.392307
            ],
            [
              35.521872542636714,
              55.39840683756762
            ],
            [
              35.513468,
              55.409004
            ],
            [
              35.511421,
              55.413487
            ],
            [
              35.506905,
              55.414945
            ],
            [
              35.502385,
              55.417684
            ],
            [
              35.498304,
              55.418584
            ],
            [
              35.49398,
              55.419666
            ],
            [
              35.49399244243042,
              55.4200266933755
            ],
            [
              35.494221,
              55.420148
            ],
            [
              35.4940103184213,
              55.42054490013049
            ],
            [
              35.49420622544603,
              55.42622404509247
            ],
            [
              35.497485,
              55.431899
            ],
            [
              35.500637,
              55.433614
            ],
            [
              35.508921,
              55.437183
            ],
            [
              35.512282,
              55.440856
            ],
            [
              35.513766,
              55.444293
            ],
            [
              35.519162,
              55.453367
            ],
            [
              35.52324,
              55.459295
            ],
            [
              35.52336511549122,
              55.45946005096532
            ],
            [
              35.525022,
              55.460939
            ],
            [
              35.52544531381453,
              55.46193939416901
            ],
            [
              35.525664,
              55.462187
            ],
            [
              35.52781617685002,
              55.46497582769914
            ],
            [
              35.529096,
              55.466426
            ],
            [
              35.539077,
              55.467742
            ],
            [
              35.54812,
              55.466441
            ],
            [
              35.55415066794146,
              55.46569548319932
            ],
            [
              35.556899028435765,
              55.46424266053504
            ],
            [
              35.562096,
              55.457938
            ],
            [
              35.564059,
              55.451018
            ],
            [
              35.56492692698189,
              55.44976471190132
            ],
            [
              35.565123,
              55.448846
            ],
            [
              35.56547790538532,
              55.44896909811115
            ],
            [
              35.566318,
              55.447756
            ],
            [
              35.57018370671354,
              55.45060129440594
            ],
            [
              35.5705,
              55.450711
            ],
            [
              35.570504268180564,
              55.4508372387795
            ],
            [
              35.573228,
              55.452842
            ],
            [
              35.57107095219055,
              55.454243364788915
            ],
            [
              35.571103,
              55.454334
            ],
            [
              35.570689,
              55.456301
            ],
            [
              35.575416,
              55.465942
            ],
            [
              35.57790100005113,
              55.465813000983
            ],
            [
              35.577901,
              55.465813
            ],
            [
              35.585789,
              55.465403
            ],
            [
              35.593678,
              55.464993
            ],
            [
              35.601671,
              55.46459
            ],
            [
              35.60737,
              55.464291
            ],
            [
              35.618855,
              55.463642
            ],
            [
              35.630703,
              55.463064
            ],
            [
              35.63209392455461,
              55.463050430004344
            ],
            [
              35.632137,
              55.462994
            ],
            [
              35.63260563776682,
              55.46304276749103
            ],
            [
              35.642551,
              55.462485
            ],
            [
              35.645421,
              55.462433
            ],
            [
              35.649508,
              55.462148
            ],
            [
              35.660675,
              55.461639
            ],
            [
              35.66067879857303,
              55.461722313824396
            ],
            [
              35.66122302927588,
              55.46169681032198
            ],
            [
              35.671356926098944,
              55.45998168710514
            ],
            [
              35.67280039653909,
              55.45953494880834
            ],
            [
              35.672557571666935,
              55.456052155331584
            ],
            [
              35.66984902393516,
              55.45037664113962
            ],
            [
              35.668757,
              55.449421
            ],
            [
              35.668651612960254,
              55.44938358845684
            ],
            [
              35.662631,
              55.448129
            ],
            [
              35.65995060237388,
              55.44852609594461
            ],
            [
              35.65932088531148,
              55.44863725054729
            ],
            [
              35.657395,
              55.449558
            ],
            [
              35.655842,
              55.44631
            ],
            [
              35.656152566737,
              55.44467952463078
            ],
            [
              35.65592853365534,
              55.44455389413936
            ],
            [
              35.646189530024756,
              55.4434241852602
            ],
            [
              35.645012807051636,
              55.4437143156889
            ],
            [
              35.64276449210608,
              55.444378313783474
            ],
            [
              35.64105640430546,
              55.44498518201528
            ],
            [
              35.637765614423174,
              55.44625889300141
            ],
            [
              35.63765549243444,
              55.44630318596983
            ],
            [
              35.63336791076278,
              55.44806721957189
            ],
            [
              35.633340338588845,
              55.44807882441978
            ],
            [
              35.628824,
              55.450073
            ],
            [
              35.62836667642484,
              55.45012940194135
            ],
            [
              35.627927,
              55.450308
            ],
            [
              35.62755304022013,
              55.45022974809448
            ],
            [
              35.625305,
              55.450507
            ],
            [
              35.615916,
              55.449699
            ],
            [
              35.615704730216905,
              55.44961929838789
            ],
            [
              35.614457,
              55.449719
            ],
            [
              35.613337,
              55.44902
            ],
            [
              35.61348548196937,
              55.44878208607846
            ],
            [
              35.61225,
              55.448316
            ],
            [
              35.609292,
              55.445449
            ],
            [
              35.602115,
              55.442662
            ],
            [
              35.596509,
              55.442784
            ],
            [
              35.592872,
              55.443101
            ],
            [
              35.588151,
              55.443952
            ],
            [
              35.584078,
              55.444877
            ],
            [
              35.582903,
              55.44526
            ],
            [
              35.581786,
              55.441838
            ],
            [
              35.582961,
              55.441454
            ],
            [
              35.590158,
              55.432217
            ],
            [
              35.589673,
              55.42865
            ],
            [
              35.591614,
              55.428303
            ],
            [
              35.599784,
              55.426187
            ],
            [
              35.600253,
              55.426002
            ],
            [
              35.600948,
              55.425755
            ],
            [
              35.608709,
              55.431561
            ],
            [
              35.611272,
              55.434055
            ],
            [
              35.614529,
              55.445529
            ],
            [
              35.624938,
              55.446925
            ],
            [
              35.629753,
              55.445686
            ],
            [
              35.631914636391116,
              55.444774340330895
            ],
            [
              35.631951,
              55.444758
            ],
            [
              35.63204913162646,
              55.44471761761737
            ],
            [
              35.634085,
              55.443859
            ],
            [
              35.63631980741563,
              55.44296018150819
            ],
            [
              35.636381,
              55.442935
            ],
            [
              35.63641843949203,
              55.442920512616126
            ],
            [
              35.63865,
              55.442023
            ],
            [
              35.63974884604764,
              55.44163179646107
            ],
            [
              35.640919,
              55.441179
            ],
            [
              35.641534961802954,
              55.44099591593357
            ],
            [
              35.642633,
              55.440605
            ],
            [
              35.64286527019385,
              55.44054470066413
            ],
            [
              35.642864,
              55.44054
            ],
            [
              35.645111,
              55.439933
            ],
            [
              35.655499,
              55.435754
            ],
            [
              35.657111,
              55.434938
            ],
            [
              35.658737,
              55.438149
            ],
            [
              35.66096186740717,
              55.441383475340224
            ],
            [
              35.66417,
              55.441181
            ],
            [
              35.67148168320607,
              55.437829811863885
            ],
            [
              35.67084128607216,
              55.43376451219548
            ],
            [
              35.67082958273278,
              55.43373451468875
            ],
            [
              35.668672187663134,
              55.43018699571526
            ],
            [
              35.66357,
              55.427375
            ],
            [
              35.66219714151201,
              55.42131166097406
            ],
            [
              35.66029578658628,
              55.416758828695905
            ],
            [
              35.66026869857521,
              55.41671598261914
            ],
            [
              35.652999,
              55.409127
            ],
            [
              35.64433815420174,
              55.40643368819688
            ],
            [
              35.635037,
              55.405374
            ],
            [
              35.631206,
              55.404302
            ],
            [
              35.627917,
              55.402745
            ],
            [
              35.621911,
              55.401239
            ],
            [
              35.619922,
              55.40091
            ],
            [
              35.617932,
              55.400581
            ],
            [
              35.61852,
              55.397029
            ],
            [
              35.620509,
              55.397358
            ],
            [
              35.622499,
              55.397687
            ],
            [
              35.623996,
              55.397898
            ],
            [
              35.6311420848664,
              55.388779204363
            ],
            [
              35.629511,
              55.385484
            ],
            [
              35.62950845338395,
              55.38547891306361
            ],
            [
              35.628033,
              55.382545
            ],
            [
              35.62613,
              55.378718
            ],
            [
              35.62419,
              55.3749
            ],
            [
              35.614967,
              55.369265
            ],
            [
              35.607232,
              55.368002
            ],
            [
              35.601757,
              55.367099
            ],
            [
              35.599656,
              55.367009
            ],
            [
              35.59981,
              55.363412
            ],
            [
              35.60191,
              55.363502
            ],
            [
              35.604011,
              55.363592
            ],
            [
              35.608044,
              55.364495
            ],
            [
              35.61246072431543,
              55.36112531770998
            ],
            [
              35.615677,
              55.357709
            ],
            [
              35.613065,
              55.352476
            ],
            [
              35.611002,
              55.348255
            ],
            [
              35.604465,
              55.341772
            ],
            [
              35.601257,
              55.341203
            ],
            [
              35.60126835681982,
              55.34113901181325
            ],
            [
              35.598415,
              55.340574
            ],
            [
              35.592228,
              55.340591
            ],
            [
              35.589025,
              55.340632
            ],
            [
              35.586744,
              55.340403
            ],
            [
              35.58658487361235,
              55.34038703157635
            ],
            [
              35.586584,
              55.340387
            ],
            [
              35.58657868675587,
              55.34038641072178
            ],
            [
              35.584462,
              55.340174
            ],
            [
              35.58446421492605,
              55.340151900097204
            ],
            [
              35.58222,
              55.339903
            ],
            [
              35.577975,
              55.338883
            ],
            [
              35.570032,
              55.338736
            ],
            [
              35.570772,
              55.334695
            ],
            [
              35.576439,
              55.334563
            ],
            [
              35.586768,
              55.331927
            ],
            [
              35.59184,
              55.333506
            ],
            [
              35.600461,
              55.334269
            ],
            [
              35.601208,
              55.322315
            ],
            [
              35.597985,
              55.320742
            ],
            [
              35.593644,
              55.318688
            ],
            [
              35.590397,
              55.317287
            ],
            [
              35.584776,
              55.315709
            ],
            [
              35.579168,
              55.314134
            ],
            [
              35.573359,
              55.312525
            ],
            [
              35.569558,
              55.311433
            ],
            [
              35.558025,
              55.310135
            ],
            [
              35.550337,
              55.31759
            ],
            [
              35.546805,
              55.31776
            ],
            [
              35.540254182463215,
              55.31273658626832
            ],
            [
              35.535973,
              55.312291
            ],
            [
              35.533969,
              55.313989
            ],
            [
              35.533958273265874,
              55.31397634180838
            ],
            [
              35.533638,
              55.314258
            ],
            [
              35.530257,
              55.316592
            ],
            [
              35.526636,
              55.319066
            ],
            [
              35.523196,
              55.320718
            ],
            [
              35.51927,
              55.322163
            ],
            [
              35.517333,
              55.322615
            ],
            [
              35.515561,
              55.322635
            ],
            [
              35.515521,
              55.319035
            ],
            [
              35.520025,
              55.31805
            ],
            [
              35.52507,
              55.315824
            ],
            [
              35.528212,
              55.31363
            ],
            [
              35.53235546455824,
              55.31051439525488
            ],
            [
              35.536967,
              55.305805
            ],
            [
              35.541011,
              55.303798
            ],
            [
              35.545689,
              55.303226
            ],
            [
              35.54823983623533,
              55.30082908746459
            ],
            [
              35.54885100014724,
              55.29955592319558
            ],
            [
              35.546935151472965,
              55.29120566019781
            ],
            [
              35.543444,
              55.290218
            ],
            [
              35.543449812411225,
              55.29019743391983
            ],
            [
              35.542926,
              55.290046
            ],
            [
              35.531114,
              55.289692
            ],
            [
              35.523216,
              55.287972
            ],
            [
              35.518732,
              55.289618
            ],
            [
              35.51456,
              55.29014
            ],
            [
              35.510578,
              55.29029
            ],
            [
              35.510442,
              55.286693
            ],
            [
              35.512433,
              55.286617
            ],
            [
              35.519808,
              55.285392
            ],
            [
              35.52214783237072,
              55.28455757448544
            ],
            [
              35.52214,
              55.284535
            ],
            [
              35.524704,
              55.283646
            ],
            [
              35.528323,
              55.283749
            ],
            [
              35.536762,
              55.284304
            ],
            [
              35.540251,
              55.284929
            ],
            [
              35.547914,
              55.287741
            ],
            [
              35.54793747677251,
              55.28774798359688
            ],
            [
              35.548033,
              55.287775
            ],
            [
              35.551642,
              55.288795
            ],
            [
              35.55218,
              55.28901
            ],
            [
              35.552724963585966,
              55.28971190218125
            ],
            [
              35.552804,
              55.289431
            ],
            [
              35.554267584151496,
              55.291698766565744
            ],
            [
              35.554576,
              55.292096
            ],
            [
              35.55492197020531,
              55.292712712236316
            ],
            [
              35.555958,
              55.294318
            ],
            [
              35.55602642403566,
              55.294681466138144
            ],
            [
              35.556319,
              55.295203
            ],
            [
              35.55709450008552,
              55.29846612149571
            ],
            [
              35.559226360796636,
              55.30052252764999
            ],
            [
              35.559788,
              55.300717
            ],
            [
              35.56299214379293,
              55.30404038644298
            ],
            [
              35.570785,
              55.308049
            ],
            [
              35.574331,
              55.309059
            ],
            [
              35.580116,
              55.310661
            ],
            [
              35.585749,
              55.312243
            ],
            [
              35.594227,
              55.311759
            ],
            [
              35.59572567085681,
              55.313040723410666
            ],
            [
              35.597196,
              55.313191
            ],
            [
              35.60278577900201,
              55.319078767220724
            ],
            [
              35.603446993644305,
              55.319401445866575
            ],
            [
              35.606029,
              55.320661
            ],
            [
              35.61215710257826,
              55.32297586587364
            ],
            [
              35.614304248242554,
              55.32077823356738
            ],
            [
              35.61414,
              55.319674
            ],
            [
              35.614734,
              55.319294
            ],
            [
              35.61606235070384,
              55.318440623360885
            ],
            [
              35.616048,
              55.318419
            ],
            [
              35.617948,
              55.317159
            ],
            [
              35.619847,
              55.315899
            ],
            [
              35.621474,
              55.314964
            ],
            [
              35.625022,
              55.314121
            ],
            [
              35.630624,
              55.313318
            ],
            [
              35.634487,
              55.311983
            ],
            [
              35.639777,
              55.310235
            ],
            [
              35.64071417740823,
              55.31002278547322
            ],
            [
              35.640714,
              55.310022
            ],
            [
              35.644741,
              55.309111
            ],
            [
              35.64517766265572,
              55.30901232530005
            ],
            [
              35.65087,
              55.307725
            ],
            [
              35.656388,
              55.297653
            ],
            [
              35.655068,
              55.295471
            ],
            [
              35.655068011579715,
              55.29547099299805
            ],
            [
              35.654552,
              55.294618
            ],
            [
              35.652002,
              55.290408
            ],
            [
              35.647533,
              55.284759
            ],
            [
              35.64717604441444,
              55.28391332669996
            ],
            [
              35.646864,
              55.283466
            ],
            [
              35.6467701898249,
              55.282951805424084
            ],
            [
              35.64649,
              55.282288
            ],
            [
              35.646174,
              55.279904
            ],
            [
              35.64621319713059,
              55.27989880374362
            ],
            [
              35.646145,
              55.279525
            ],
            [
              35.64466173106478,
              55.273684557529776
            ],
            [
              35.638422,
              55.266845
            ],
            [
              35.6359,
              55.264398
            ],
            [
              35.632308,
              55.261297
            ],
            [
              35.628818,
              55.257261
            ],
            [
              35.625288,
              55.253763
            ],
            [
              35.618574,
              55.247652
            ],
            [
              35.617437,
              55.246566
            ],
            [
              35.614922,
              55.243843
            ],
            [
              35.61491509705669,
              55.24383553187135
            ],
            [
              35.614905,
              55.243825
            ],
            [
              35.61488445146862,
              55.2438023771453
            ],
            [
              35.612406,
              55.241121
            ],
            [
              35.61242934300076,
              55.2410994315617
            ],
            [
              35.612161,
              55.240804
            ],
            [
              35.607383,
              55.230334
            ],
            [
              35.606473,
              55.229853
            ],
            [
              35.60492,
              55.228501
            ],
            [
              35.607282,
              55.225785
            ],
            [
              35.616052,
              55.223646
            ],
            [
              35.61865,
              55.224032
            ],
            [
              35.621099,
              55.225876
            ],
            [
              35.6192619645227,
              55.229170654312775
            ],
            [
              35.619767,
              55.229691
            ],
            [
              35.617632455917324,
              55.23303102401425
            ],
            [
              35.61754900412325,
              55.24138158740904
            ],
            [
              35.617559945344915,
              55.24139342923781
            ],
            [
              35.61756701824444,
              55.2414008148297
            ],
            [
              35.624524,
              55.246968
            ],
            [
              35.627794,
              55.250305
            ],
            [
              35.63018,
              55.253497
            ],
            [
              35.633264,
              55.2569
            ],
            [
              35.636636,
              55.260052
            ],
            [
              35.64346,
              55.265292
            ],
            [
              35.645232900390575,
              55.26639851225825
            ],
            [
              35.645378,
              55.266155
            ],
            [
              35.646671,
              55.266926
            ],
            [
              35.647605,
              55.267879
            ],
            [
              35.648934,
              55.271991
            ],
            [
              35.649784,
              55.27559
            ],
            [
              35.65197162797747,
              55.277864544455866
            ],
            [
              35.657734,
              55.279592
            ],
            [
              35.66037,
              55.280811
            ],
            [
              35.660371922686736,
              55.280833660236496
            ],
            [
              35.661789,
              55.280906
            ],
            [
              35.660068474922824,
              55.28274617250923
            ],
            [
              35.659891,
              55.283447
            ],
            [
              35.65763,
              55.292751
            ],
            [
              35.66195,
              55.298203
            ],
            [
              35.66311743035268,
              55.30015870810045
            ],
            [
              35.663279,
              55.300364
            ],
            [
              35.66879347156172,
              55.303324753891005
            ],
            [
              35.67512250072083,
              55.30501955667838
            ],
            [
              35.679746,
              55.304524
            ],
            [
              35.687711,
              55.303002
            ],
            [
              35.692055,
              55.303301
            ],
            [
              35.699375,
              55.303847
            ],
            [
              35.702983,
              55.304097
            ],
            [
              35.706991,
              55.293389
            ],
            [
              35.704452,
              55.290436
            ],
            [
              35.70607465428517,
              55.27943757010128
            ],
            [
              35.70538,
              55.276744
            ],
            [
              35.70535783778301,
              55.276650090014456
            ],
            [
              35.704636,
              55.274025
            ],
            [
              35.704451497362705,
              55.27280957099865
            ],
            [
              35.70423,
              55.271871
            ],
            [
              35.699128,
              55.262713
            ],
            [
              35.698802,
              55.253672
            ],
            [
              35.697985,
              55.250043
            ],
            [
              35.697629,
              55.248213
            ],
            [
              35.69736462869788,
              55.24685327007252
            ],
            [
              35.697159,
              55.245796
            ],
            [
              35.69701591854129,
              55.245115096000994
            ],
            [
              35.691317,
              55.238964
            ],
            [
              35.68882,
              55.238254
            ],
            [
              35.686322,
              55.237543
            ],
            [
              35.687307,
              55.234081
            ],
            [
              35.69458861811517,
              55.233331490553354
            ],
            [
              35.693251,
              55.226989
            ],
            [
              35.692024,
              55.220667
            ],
            [
              35.68416850325698,
              55.214454517276735
            ],
            [
              35.681784730655366,
              55.21368571295979
            ],
            [
              35.678055,
              55.21404
            ],
            [
              35.676966,
              55.214128
            ],
            [
              35.67693364466677,
              55.21372618930462
            ],
            [
              35.672209,
              55.212404
            ],
            [
              35.674566,
              55.209683
            ],
            [
              35.674706,
              55.200383
            ],
            [
              35.664548,
              55.194516
            ],
            [
              35.661041,
              55.194377
            ],
            [
              35.656813,
              55.194593
            ],
            [
              35.652035,
              55.193596
            ],
            [
              35.646832,
              55.193495
            ],
            [
              35.643089,
              55.191499
            ],
            [
              35.641372,
              55.18682
            ],
            [
              35.639883,
              55.182988
            ],
            [
              35.6396577042084,
              55.182407802773255
            ],
            [
              35.63886854088385,
              55.180375886801066
            ],
            [
              35.638332,
              55.178995
            ],
            [
              35.63833219703344,
              55.17899492344112
            ],
            [
              35.638293,
              55.178894
            ],
            [
              35.635849,
              55.172666
            ],
            [
              35.63584589728599,
              55.172658090174835
            ],
            [
              35.635818,
              55.172587
            ],
            [
              35.63578694060942,
              55.17250779046887
            ],
            [
              35.633406,
              55.166438
            ],
            [
              35.63340675708982,
              55.16643770290268
            ],
            [
              35.633321,
              55.166219
            ],
            [
              35.631482,
              55.161287
            ],
            [
              35.63049,
              55.158772
            ],
            [
              35.62885883292969,
              55.15454824277619
            ],
            [
              35.628842,
              55.154505
            ],
            [
              35.627199,
              55.150252
            ],
            [
              35.626609,
              55.145978
            ],
            [
              35.629095,
              55.142929
            ],
            [
              35.632032,
              55.139421
            ],
            [
              35.638449,
              55.131786
            ],
            [
              35.641656,
              55.127903
            ],
            [
              35.64543883672613,
              55.123444406738436
            ],
            [
              35.649273,
              55.116005
            ],
            [
              35.65291,
              55.113438
            ],
            [
              35.655868,
              55.111385
            ],
            [
              35.659534,
              55.109016
            ],
            [
              35.663391,
              55.107097
            ],
            [
              35.666455,
              55.106272
            ],
            [
              35.669146,
              55.105676
            ],
            [
              35.669201172466046,
              55.1056637964248
            ],
            [
              35.676836,
              55.101434
            ],
            [
              35.680588,
              55.098309
            ],
            [
              35.683744,
              55.095767
            ],
            [
              35.68842,
              55.092723
            ],
            [
              35.691513,
              55.09313
            ],
            [
              35.691646,
              55.096727
            ],
            [
              35.68444,
              55.099828
            ],
            [
              35.680887,
              55.102803
            ],
            [
              35.680819315512224,
              55.10285714378131
            ],
            [
              35.68082,
              55.102858
            ],
            [
              35.679076,
              55.104252
            ],
            [
              35.677333,
              55.105646
            ],
            [
              35.674427,
              55.10795
            ],
            [
              35.67265986543553,
              55.10843833419778
            ],
            [
              35.672691,
              55.108579
            ],
            [
              35.66997779168027,
              55.10917950488279
            ],
            [
              35.669976,
              55.10918
            ],
            [
              35.660374,
              55.112799
            ],
            [
              35.6576079677125,
              55.1146375678569
            ],
            [
              35.650778,
              55.122518
            ],
            [
              35.65048233927049,
              55.122897146658424
            ],
            [
              35.650557,
              55.122949
            ],
            [
              35.650031,
              55.123707
            ],
            [
              35.64983657319772,
              55.1239327068241
            ],
            [
              35.649841,
              55.123937
            ],
            [
              35.648129,
              55.125915
            ],
            [
              35.644376,
              55.130261
            ],
            [
              35.641173,
              55.13414
            ],
            [
              35.638381,
              55.137521
            ],
            [
              35.638245215153106,
              55.137680330917306
            ],
            [
              35.638246,
              55.137681
            ],
            [
              35.636509,
              55.139719
            ],
            [
              35.634771,
              55.141757
            ],
            [
              35.63220004891847,
              55.153207782121584
            ],
            [
              35.632216562428155,
              55.153250542337325
            ],
            [
              35.634831,
              55.159966
            ],
            [
              35.636717,
              55.165026
            ],
            [
              35.63675533578754,
              55.165123653070545
            ],
            [
              35.636757,
              55.165123
            ],
            [
              35.639196897285586,
              55.17134309017382
            ],
            [
              35.641644,
              55.177579
            ],
            [
              35.641687582544286,
              55.17769116220568
            ],
            [
              35.641688,
              55.177691
            ],
            [
              35.64318704175241,
              55.181550046300785
            ],
            [
              35.650494,
              55.189653
            ],
            [
              35.652222513448976,
              55.19003861667982
            ],
            [
              35.652808,
              55.19008
            ],
            [
              35.65616793367334,
              55.19068941636814
            ],
            [
              35.659076,
              55.19088
            ],
            [
              35.65995253488556,
              55.19088831723652
            ],
            [
              35.661917,
              55.190808
            ],
            [
              35.666319,
              55.190807
            ],
            [
              35.6665626539195,
              55.190814459374494
            ],
            [
              35.669492,
              55.19067
            ],
            [
              35.669930013853744,
              55.19091754985157
            ],
            [
              35.670598,
              55.190938
            ],
            [
              35.672492390880265,
              55.19236571421248
            ],
            [
              35.672776,
              55.192526
            ],
            [
              35.6731460592352,
              55.19285835361985
            ],
            [
              35.67443,
              55.193826
            ],
            [
              35.67507502196463,
              55.194590772877895
            ],
            [
              35.675632,
              55.195091
            ],
            [
              35.686261,
              55.197964
            ],
            [
              35.68998,
              55.195622
            ],
            [
              35.697963,
              55.187905
            ],
            [
              35.69831225560934,
              55.1876593241767
            ],
            [
              35.699347,
              55.186208
            ],
            [
              35.704012367721205,
              55.18097440185496
            ],
            [
              35.70397363988189,
              55.18003902428628
            ],
            [
              35.70286573362873,
              55.17327032773647
            ],
            [
              35.701416,
              55.170918
            ],
            [
              35.701311794458356,
              55.17067003003166
            ],
            [
              35.700138,
              55.168797
            ],
            [
              35.698966,
              55.163374
            ],
            [
              35.69945499360195,
              55.162505121253346
            ],
            [
              35.699441,
              55.162347
            ],
            [
              35.703089,
              55.151832
            ],
            [
              35.707482,
              55.150881
            ],
            [
              35.711542,
              55.150777
            ],
            [
              35.71224,
              55.150812
            ],
            [
              35.712056,
              55.154408
            ],
            [
              35.705131,
              55.159793
            ],
            [
              35.705857,
              55.171144
            ],
            [
              35.706878,
              55.172923
            ],
            [
              35.708763,
              55.176462
            ],
            [
              35.71895,
              55.180511
            ],
            [
              35.724121,
              55.180976
            ],
            [
              35.732286,
              55.180636
            ],
            [
              35.736837,
              55.181098
            ],
            [
              35.745791,
              55.18158
            ],
            [
              35.749576,
              55.182285
            ],
            [
              35.753138,
              55.182787
            ],
            [
              35.753352,
              55.187116
            ],
            [
              35.75338417015363,
              55.18808433631394
            ],
            [
              35.755042,
              55.186394
            ],
            [
              35.755041627849764,
              55.18639993596397
            ],
            [
              35.755837,
              55.18559
            ],
            [
              35.75482,
              55.189935
            ],
            [
              35.765204,
              55.194932
            ],
            [
              35.768687,
              55.192982
            ],
            [
              35.775053,
              55.188809
            ],
            [
              35.783868,
              55.180738
            ],
            [
              35.787388,
              55.17728
            ],
            [
              35.789939,
              55.174901
            ],
            [
              35.789949303394295,
              55.174912041748286
            ],
            [
              35.791084,
              55.173863
            ],
            [
              35.79335208173927,
              55.173439995005914
            ],
            [
              35.793686,
              55.173225
            ],
            [
              35.79747,
              55.172264
            ],
            [
              35.799773,
              55.174818
            ],
            [
              35.799689,
              55.174945
            ],
            [
              35.798212,
              55.175787
            ],
            [
              35.790004806617276,
              55.18043484706201
            ],
            [
              35.785812,
              55.186135
            ],
            [
              35.78286,
              55.188146
            ],
            [
              35.77778,
              55.191731
            ],
            [
              35.773945,
              55.194812
            ],
            [
              35.772917,
              55.205987
            ],
            [
              35.7747219617638,
              55.20627423973485
            ],
            [
              35.774722,
              55.206274
            ],
            [
              35.77517756913721,
              55.20634674461765
            ],
            [
              35.77812,
              55.206815
            ],
            [
              35.78451697793521,
              55.20942275173294
            ],
            [
              35.793348,
              55.210106
            ],
            [
              35.798921,
              55.211285
            ],
            [
              35.808646,
              55.212227
            ],
            [
              35.81509,
              55.211853
            ],
            [
              35.817804063872565,
              55.21172868069612
            ],
            [
              35.819101,
              55.211669
            ],
            [
              35.822579,
              55.211509
            ],
            [
              35.822777008838315,
              55.21150679544816
            ],
            [
              35.822777,
              55.211506
            ],
            [
              35.82458244452574,
              55.211486439387585
            ],
            [
              35.828863,
              55.211399
            ],
            [
              35.832582,
              55.211311
            ],
            [
              35.836163,
              55.211458
            ],
            [
              35.836876988432046,
              55.211467832892964
            ],
            [
              35.836877,
              55.211467
            ],
            [
              35.840103,
              55.211512
            ],
            [
              35.84893,
              55.210554
            ],
            [
              35.856926,
              55.213051
            ],
            [
              35.861464,
              55.212996
            ],
            [
              35.867726716339654,
              55.21293751332206
            ],
            [
              35.876664,
              55.206785
            ],
            [
              35.880499,
              55.202608
            ],
            [
              35.885571,
              55.201107
            ],
            [
              35.88899043531975,
              55.19910698709505
            ],
            [
              35.893419555068945,
              55.196213890273086
            ],
            [
              35.892759873318845,
              55.19247632411896
            ],
            [
              35.892044,
              55.190896
            ],
            [
              35.887064,
              55.182912
            ],
            [
              35.879717,
              55.179434
            ],
            [
              35.877398,
              55.17668
            ],
            [
              35.877578,
              55.175183
            ],
            [
              35.878677,
              55.174697
            ],
            [
              35.881282,
              55.173716
            ],
            [
              35.889029,
              55.179895
            ],
            [
              35.897167,
              55.18031
            ],
            [
              35.901385,
              55.17779
            ],
            [
              35.90518132046444,
              55.175648637988026
            ],
            [
              35.90522507978953,
              55.175623948490184
            ],
            [
              35.913489,
              55.170289
            ],
            [
              35.915837,
              55.167155
            ],
            [
              35.918476,
              55.163715
            ],
            [
              35.919149,
              55.163178
            ],
            [
              35.924613,
              55.159789
            ],
            [
              35.92540069764654,
              55.159300293004804
            ],
            [
              35.925409,
              55.159295
            ],
            [
              35.92640154337667,
              55.15867934369566
            ],
            [
              35.930077,
              55.156399
            ],
            [
              35.93007723557023,
              55.15639937979185
            ],
            [
              35.931661,
              55.155417
            ],
            [
              35.934876,
              55.1534
            ],
            [
              35.93491969289832,
              55.15337280310728
            ],
            [
              35.93794907042575,
              55.151473040055485
            ],
            [
              35.944854,
              55.143576
            ],
            [
              35.937938066748124,
              55.13496881670301
            ],
            [
              35.937892505930336,
              55.13496877952062
            ],
            [
              35.93516214656001,
              55.13521162398384
            ],
            [
              35.929969,
              55.137522
            ],
            [
              35.926731,
              55.139815
            ],
            [
              35.922068,
              55.140371
            ],
            [
              35.918281,
              55.14141
            ],
            [
              35.914018,
              55.141241
            ],
            [
              35.909041,
              55.141689
            ],
            [
              35.904514,
              55.142493
            ],
            [
              35.90315,
              55.142518
            ],
            [
              35.899014,
              55.142518
            ],
            [
              35.899014,
              55.14244923342175
            ],
            [
              35.898482,
              55.142445
            ],
            [
              35.89044668729599,
              55.13932551015176
            ],
            [
              35.887832626081355,
              55.139566416239276
            ],
            [
              35.88166,
              55.142729
            ],
            [
              35.878841,
              55.14494
            ],
            [
              35.871329,
              55.149172
            ],
            [
              35.867686,
              55.151422
            ],
            [
              35.863494,
              55.1534
            ],
            [
              35.855163,
              55.15752
            ],
            [
              35.852643,
              55.15495
            ],
            [
              35.853551,
              55.154059
            ],
            [
              35.854454,
              55.153166
            ],
            [
              35.85576282918501,
              55.15262452343801
            ],
            [
              35.855366,
              55.152391
            ],
            [
              35.859472,
              55.15109
            ],
            [
              35.863608,
              55.149413
            ],
            [
              35.86683882697057,
              55.14773554771162
            ],
            [
              35.86709823041857,
              55.14757757981766
            ],
            [
              35.870537,
              55.145182
            ],
            [
              35.878409,
              55.141182
            ],
            [
              35.875582,
              55.133234
            ],
            [
              35.87253,
              55.129631
            ],
            [
              35.869326,
              55.120793
            ],
            [
              35.869808,
              55.116928
            ],
            [
              35.863431,
              55.109152
            ],
            [
              35.858327,
              55.110853
            ],
            [
              35.851952,
              55.120362
            ],
            [
              35.850295,
              55.124449
            ],
            [
              35.845583,
              55.126338
            ],
            [
              35.8395,
              55.125659
            ],
            [
              35.834925,
              55.125386
            ],
            [
              35.825727,
              55.13191
            ],
            [
              35.831335,
              55.139057
            ],
            [
              35.833965,
              55.141614
            ],
            [
              35.832995,
              55.143927
            ],
            [
              35.832801,
              55.144463
            ],
            [
              35.825326,
              55.14307
            ],
            [
              35.82181,
              55.141825
            ],
            [
              35.820379006568224,
              55.13893021099474
            ],
            [
              35.820235,
              55.138983
            ],
            [
              35.819711,
              55.138366
            ],
            [
              35.819367,
              55.136883
            ],
            [
              35.818406,
              55.13019
            ],
            [
              35.808022,
              55.124627
            ],
            [
              35.80554,
              55.12632
            ],
            [
              35.803173,
              55.127865
            ],
            [
              35.80314856416393,
              55.12782757641773
            ],
            [
              35.802784,
              55.128067
            ],
            [
              35.799713,
              55.132871
            ],
            [
              35.796607,
              55.134692
            ],
            [
              35.7918548069839,
              55.13362955228086
            ],
            [
              35.790939,
              55.134548
            ],
            [
              35.78619,
              55.13614
            ],
            [
              35.779473,
              55.139172
            ],
            [
              35.775565,
              55.139191
            ],
            [
              35.769103,
              55.140793
            ],
            [
              35.766013,
              55.138945
            ],
            [
              35.766376,
              55.138339
            ],
            [
              35.772003,
              55.135631
            ],
            [
              35.78255,
              55.133056
            ],
            [
              35.789713,
              55.1283
            ],
            [
              35.79485,
              55.126878
            ],
            [
              35.79565154520781,
              55.12652252443212
            ],
            [
              35.794422,
              55.125078
            ],
            [
              35.803572,
              55.123305
            ],
            [
              35.808445,
              55.11998
            ],
            [
              35.812051,
              55.119207
            ],
            [
              35.816263,
              55.119634
            ],
            [
              35.820324,
              55.120051
            ],
            [
              35.824059,
              55.120934
            ],
            [
              35.834691,
              55.121794
            ],
            [
              35.839704,
              55.122064
            ],
            [
              35.84852782885077,
              55.115933118916125
            ],
            [
              35.848539,
              55.112262
            ],
            [
              35.850599,
              55.109561
            ],
            [
              35.857236,
              55.107423
            ],
            [
              35.862292,
              55.105737
            ],
            [
              35.86605,
              55.104337
            ],
            [
              35.869865,
              55.102917
            ],
            [
              35.873457,
              55.100911
            ],
            [
              35.882905,
              55.097077
            ],
            [
              35.888646,
              55.095279
            ],
            [
              35.894795,
              55.093307
            ],
            [
              35.902011,
              55.092599
            ],
            [
              35.902522973191886,
              55.09241875255744
            ],
            [
              35.90998531132164,
              55.08798673123565
            ],
            [
              35.913474,
              55.083392
            ],
            [
              35.915851,
              55.078806
            ],
            [
              35.9146010362067,
              55.070709709422246
            ],
            [
              35.914032770567246,
              55.07011045625885
            ],
            [
              35.90625910761408,
              55.06554125346302
            ],
            [
              35.90525162118837,
              55.0653026738719
            ],
            [
              35.901314,
              55.064521
            ],
            [
              35.893285,
              55.064456
            ],
            [
              35.888337,
              55.064609
            ],
            [
              35.877234,
              55.067357
            ],
            [
              35.874219,
              55.069886
            ],
            [
              35.869072,
              55.070869
            ],
            [
              35.868428,
              55.070803
            ],
            [
              35.865713,
              55.067948
            ],
            [
              35.86570885245525,
              55.06793719276519
            ],
            [
              35.86536,
              55.068022
            ],
            [
              35.860983,
              55.068722
            ],
            [
              35.85384581698527,
              55.06999269846615
            ],
            [
              35.853532,
              55.070076
            ],
            [
              35.849778,
              55.071304
            ],
            [
              35.845643,
              55.072247
            ],
            [
              35.8449283303713,
              55.0722569055852
            ],
            [
              35.844224,
              55.072439
            ],
            [
              35.838292,
              55.072977
            ],
            [
              35.827042,
              55.075333
            ],
            [
              35.825709,
              55.075844
            ],
            [
              35.818351,
              55.076934
            ],
            [
              35.818350872364555,
              55.07693313918595
            ],
            [
              35.817014,
              55.077131
            ],
            [
              35.81006554128129,
              55.07941703641849
            ],
            [
              35.80528,
              55.082527
            ],
            [
              35.80147,
              55.084843
            ],
            [
              35.79188,
              55.078704
            ],
            [
              35.782268,
              55.076998
            ],
            [
              35.77788,
              55.077055
            ],
            [
              35.77763,
              55.073464
            ],
            [
              35.779647,
              55.073324
            ],
            [
              35.784931,
              55.073479
            ],
            [
              35.789139,
              55.073948
            ],
            [
              35.796826,
              55.074151
            ],
            [
              35.79699705214037,
              55.07413557887114
            ],
            [
              35.796997,
              55.074135
            ],
            [
              35.80032,
              55.073836
            ],
            [
              35.808716,
              55.074604
            ],
            [
              35.81642,
              55.073581
            ],
            [
              35.81782300092368,
              55.073373006229595
            ],
            [
              35.817823,
              55.073373
            ],
            [
              35.81786505716144,
              55.07336677142582
            ],
            [
              35.820818,
              55.072929
            ],
            [
              35.825182,
              55.072283
            ],
            [
              35.832555,
              55.069651
            ],
            [
              35.840972,
              55.062617
            ],
            [
              35.846468,
              55.057223
            ],
            [
              35.850456,
              55.051335
            ],
            [
              35.853744,
              55.052803
            ],
            [
              35.852304,
              55.056026
            ],
            [
              35.858429949994495,
              55.06508269604535
            ],
            [
              35.85930267999338,
              55.06511642582007
            ],
            [
              35.862918,
              55.064716
            ],
            [
              35.864435653776574,
              55.064055563377735
            ],
            [
              35.86407,
              55.06136
            ],
            [
              35.864796,
              55.061262
            ],
            [
              35.865515,
              55.061229
            ],
            [
              35.869062,
              55.060921
            ],
            [
              35.876557,
              55.0583
            ],
            [
              35.880797,
              55.058869
            ],
            [
              35.888893,
              55.061052
            ],
            [
              35.892429,
              55.060959
            ],
            [
              35.89720361154395,
              55.05360954328015
            ],
            [
              35.89746,
              55.049339
            ],
            [
              35.894562,
              55.045416
            ],
            [
              35.894548320657364,
              55.04538578345041
            ],
            [
              35.893198,
              55.043669
            ],
            [
              35.891179,
              55.04066
            ],
            [
              35.88863241464037,
              55.03608631674056
            ],
            [
              35.88855007107145,
              55.035946374052074
            ],
            [
              35.885203,
              55.03029
            ],
            [
              35.88195074994269,
              55.024504955773416
            ],
            [
              35.874430172365024,
              55.02435713311598
            ],
            [
              35.871204,
              55.025116
            ],
            [
              35.868668715028825,
              55.025053780979036
            ],
            [
              35.868219,
              55.025139
            ],
            [
              35.864822846827515,
              55.02543029819199
            ],
            [
              35.86236,
              55.025994
            ],
            [
              35.858286,
              55.025996
            ],
            [
              35.856035,
              55.034638
            ],
            [
              35.858073,
              55.037943
            ],
            [
              35.859427,
              55.042516
            ],
            [
              35.855682,
              55.047483
            ],
            [
              35.85221,
              55.046532
            ],
            [
              35.852874,
              55.044108
            ],
            [
              35.852979,
              55.036541
            ],
            [
              35.850672,
              55.032032
            ],
            [
              35.85013,
              55.028253
            ],
            [
              35.84182827113412,
              55.023734578105824
            ],
            [
              35.832992,
              55.026403
            ],
            [
              35.832900094286465,
              55.02645471405942
            ],
            [
              35.830052,
              55.028058
            ],
            [
              35.83005169939291,
              55.0280574660224
            ],
            [
              35.827616,
              55.029428
            ],
            [
              35.823746,
              55.030391
            ],
            [
              35.818433,
              55.032201
            ],
            [
              35.813729,
              55.032848
            ],
            [
              35.812228,
              55.032545
            ],
            [
              35.809259272454796,
              55.031831430948024
            ],
            [
              35.803697,
              55.030505
            ],
            [
              35.80001181865481,
              55.02986831009893
            ],
            [
              35.795425771978074,
              55.02923337137744
            ],
            [
              35.787896,
              55.032805
            ],
            [
              35.783984,
              55.036899
            ],
            [
              35.781381,
              55.034412
            ],
            [
              35.77670636872191,
              55.025166297951806
            ],
            [
              35.771297,
              55.025515
            ],
            [
              35.76713,
              55.02609
            ],
            [
              35.759505,
              55.026501
            ],
            [
              35.758469,
              55.026476
            ],
            [
              35.758557,
              55.022877
            ],
            [
              35.759432959479014,
              55.02269576700434
            ],
            [
              35.759585,
              55.022643
            ],
            [
              35.761022,
              55.022367
            ],
            [
              35.76532,
              55.022411
            ],
            [
              35.772943,
              55.020698
            ],
            [
              35.776663,
              55.020578
            ],
            [
              35.77678605295183,
              55.02060655856357
            ],
            [
              35.776788,
              55.020595
            ],
            [
              35.777891,
              55.020781
            ],
            [
              35.788061624162424,
              55.01727850365578
            ],
            [
              35.78814193824305,
              55.016921289138104
            ],
            [
              35.789147,
              55.011736
            ],
            [
              35.788838,
              55.009718
            ],
            [
              35.788529,
              55.007701
            ],
            [
              35.788530706597555,
              55.00770073866376
            ],
            [
              35.788503,
              55.007528
            ],
            [
              35.78163633612674,
              55.00120334899814
            ],
            [
              35.779138,
              55.000766
            ],
            [
              35.776036,
              55.001255
            ],
            [
              35.77599635008889,
              55.00126126320324
            ],
            [
              35.775983,
              55.001264
            ],
            [
              35.771207,
              55.002193
            ],
            [
              35.766892,
              55.003896
            ],
            [
              35.759719,
              55.007909
            ],
            [
              35.757021,
              55.010161
            ],
            [
              35.754359,
              55.012699
            ],
            [
              35.750827,
              55.013949
            ],
            [
              35.746507715222926,
              55.015718068765075
            ],
            [
              35.744714,
              55.016514
            ],
            [
              35.74462936199308,
              55.01632308589391
            ],
            [
              35.737714,
              55.018549
            ],
            [
              35.737464,
              55.018617
            ],
            [
              35.736116,
              55.018147
            ],
            [
              35.73579820289586,
              55.017859792991445
            ],
            [
              35.730985,
              55.020011
            ],
            [
              35.723647,
              55.021623
            ],
            [
              35.722874,
              55.018107
            ],
            [
              35.731039,
              55.011258
            ],
            [
              35.732673,
              55.00805
            ],
            [
              35.73318,
              55.008309
            ],
            [
              35.734186,
              55.009367
            ],
            [
              35.74328989189119,
              55.01320751730482
            ],
            [
              35.747005,
              55.011559
            ],
            [
              35.754529,
              55.007557
            ],
            [
              35.757751,
              55.004895
            ],
            [
              35.75984675281008,
              55.002980648748945
            ],
            [
              35.759648,
              55.002715
            ],
            [
              35.76003,
              55.00243
            ],
            [
              35.760905,
              55.002014
            ],
            [
              35.76111894551172,
              55.001946372519654
            ],
            [
              35.761118,
              55.001943
            ],
            [
              35.76121994914685,
              55.00191444559913
            ],
            [
              35.765701,
              55.000498
            ],
            [
              35.7703,
              54.998709
            ],
            [
              35.77239058013198,
              54.9983004348473
            ],
            [
              35.772373,
              54.998189
            ],
            [
              35.77542513997181,
              54.997706876019926
            ],
            [
              35.782339,
              54.995232
            ],
            [
              35.78548,
              54.99324
            ],
            [
              35.785551091400876,
              54.99320201117785
            ],
            [
              35.78555,
              54.9932
            ],
            [
              35.786547,
              54.992659
            ],
            [
              35.789612,
              54.991032
            ],
            [
              35.793236,
              54.986753
            ],
            [
              35.79323753626706,
              54.98039669506068
            ],
            [
              35.788691,
              54.973154
            ],
            [
              35.785431252651314,
              54.9736567513646
            ],
            [
              35.777947,
              54.976875
            ],
            [
              35.771546,
              54.975777
            ],
            [
              35.77140846576226,
              54.9756929996586
            ],
            [
              35.770169,
              54.975994
            ],
            [
              35.761858,
              54.977099
            ],
            [
              35.754987,
              54.984503
            ],
            [
              35.754391,
              54.986174
            ],
            [
              35.75406,
              54.986891
            ],
            [
              35.75371106428578,
              54.986729928114784
            ],
            [
              35.752953,
              54.988053
            ],
            [
              35.749267,
              54.989503
            ],
            [
              35.745411,
              54.99176
            ],
            [
              35.744091,
              54.992384
            ],
            [
              35.742312,
              54.99304
            ],
            [
              35.741067,
              54.989663
            ],
            [
              35.739957,
              54.987431
            ],
            [
              35.740873,
              54.983949
            ],
            [
              35.74361152568235,
              54.97575244049242
            ],
            [
              35.742711,
              54.97483
            ],
            [
              35.74194428769781,
              54.972161180862
            ],
            [
              35.73599,
              54.970865
            ],
            [
              35.731775,
              54.972871
            ],
            [
              35.731075,
              54.972038
            ],
            [
              35.730529,
              54.970567
            ],
            [
              35.734806,
              54.967465
            ],
            [
              35.740961,
              54.969834
            ],
            [
              35.749968,
              54.962174
            ],
            [
              35.750385,
              54.958598
            ],
            [
              35.743929,
              54.948686
            ],
            [
              35.73423,
              54.950534
            ],
            [
              35.726399,
              54.950678
            ],
            [
              35.725329,
              54.951116
            ],
            [
              35.723967,
              54.947783
            ],
            [
              35.725037,
              54.947346
            ],
            [
              35.730391,
              54.940863
            ],
            [
              35.726513,
              54.933274
            ],
            [
              35.718682,
              54.930888
            ],
            [
              35.716719,
              54.931115
            ],
            [
              35.715977,
              54.930506
            ],
            [
              35.716307,
              54.927538
            ],
            [
              35.713997,
              54.917047
            ],
            [
              35.715175,
              54.913645
            ],
            [
              35.715873,
              54.913887
            ],
            [
              35.722938,
              54.916278
            ],
            [
              35.724460907451984,
              54.916808265762874
            ],
            [
              35.724461,
              54.916808
            ],
            [
              35.728312,
              54.918149
            ],
            [
              35.732162,
              54.91949
            ],
            [
              35.734440090536914,
              54.92025299856981
            ],
            [
              35.735784,
              54.920703
            ],
            [
              35.738429,
              54.921589
            ],
            [
              35.738549834673734,
              54.92163048196569
            ],
            [
              35.73855,
              54.92163
            ],
            [
              35.746545,
              54.920288
            ],
            [
              35.748273,
              54.923446
            ],
            [
              35.75914915406727,
              54.92263332374182
            ],
            [
              35.760145,
              54.922145
            ],
            [
              35.761702,
              54.9211
            ],
            [
              35.763708,
              54.924089
            ],
            [
              35.76762812575632,
              54.933155906477005
            ],
            [
              35.77151567583503,
              54.93134475627007
            ],
            [
              35.774512,
              54.927856
            ],
            [
              35.776336,
              54.926006
            ],
            [
              35.778597,
              54.925077
            ],
            [
              35.780858,
              54.924149
            ],
            [
              35.78880752023861,
              54.920808494180065
            ],
            [
              35.790743,
              54.918202
            ],
            [
              35.79414439655226,
              54.91869645067478
            ],
            [
              35.796144,
              54.916952
            ],
            [
              35.79857,
              54.91029
            ],
            [
              35.801139,
              54.907769
            ],
            [
              35.80542016622204,
              54.9110715081609
            ],
            [
              35.812366,
              54.90945
            ],
            [
              35.814578,
              54.907946
            ],
            [
              35.815194,
              54.908293
            ],
            [
              35.816075,
              54.911078
            ],
            [
              35.812087,
              54.914417
            ],
            [
              35.810519458022064,
              54.918349176314386
            ],
            [
              35.812392,
              54.927526
            ],
            [
              35.81581045986327,
              54.92830433858335
            ],
            [
              35.823762,
              54.924099
            ],
            [
              35.827339,
              54.9245
            ],
            [
              35.836612,
              54.922171
            ],
            [
              35.847438,
              54.926182
            ],
            [
              35.847493,
              54.929781
            ],
            [
              35.845064,
              54.93045
            ],
            [
              35.841398,
              54.930604
            ],
            [
              35.837456,
              54.940765
            ],
            [
              35.83794,
              54.944776
            ],
            [
              35.83816719446396,
              54.94505764971858
            ],
            [
              35.838168,
              54.945057
            ],
            [
              35.842521241414225,
              54.95045486401942
            ],
            [
              35.851815,
              54.94522
            ],
            [
              35.853159,
              54.944798
            ],
            [
              35.855079,
              54.944863
            ],
            [
              35.855522148687065,
              54.94487823323612
            ],
            [
              35.858225,
              54.94497
            ],
            [
              35.862452,
              54.945114
            ],
            [
              35.86233,
              54.948712
            ],
            [
              35.858102,
              54.948568
            ],
            [
              35.853932,
              54.958495
            ],
            [
              35.859924120700825,
              54.96018813785807
            ],
            [
              35.864742,
              54.957803
            ],
            [
              35.866342,
              54.957684
            ],
            [
              35.86659692626299,
              54.95770698166645
            ],
            [
              35.874316,
              54.953994
            ],
            [
              35.876618,
              54.953777
            ],
            [
              35.87718464289555,
              54.954130212414995
            ],
            [
              35.878452,
              54.952229
            ],
            [
              35.879091,
              54.951691
            ],
            [
              35.879277,
              54.951565
            ],
            [
              35.880232,
              54.951917
            ],
            [
              35.88072694526607,
              54.95298006001073
            ],
            [
              35.884013,
              54.954335
            ],
            [
              35.905877,
              54.945586
            ],
            [
              35.90298,
              54.941754
            ],
            [
              35.8945,
              54.938091
            ],
            [
              35.891461,
              54.935977
            ],
            [
              35.891674,
              54.93212
            ],
            [
              35.883825,
              54.923773
            ],
            [
              35.88381765329976,
              54.923689891901944
            ],
            [
              35.880077,
              54.923869
            ],
            [
              35.873683,
              54.924411
            ],
            [
              35.869723,
              54.924858
            ],
            [
              35.868205,
              54.925268
            ],
            [
              35.860925,
              54.92664
            ],
            [
              35.860259,
              54.923102
            ],
            [
              35.863899,
              54.922416
            ],
            [
              35.867539,
              54.921731
            ],
            [
              35.870234,
              54.921322
            ],
            [
              35.876309,
              54.920364
            ],
            [
              35.88,
              54.920269
            ],
            [
              35.88555886206893,
              54.920005338558
            ],
            [
              35.885653,
              54.919997
            ],
            [
              35.887797,
              54.919807
            ],
            [
              35.890133,
              54.919778
            ],
            [
              35.893641,
              54.920373
            ],
            [
              35.897807,
              54.920796
            ],
            [
              35.904928,
              54.914636
            ],
            [
              35.906457,
              54.912237
            ],
            [
              35.90645905403799,
              54.91223830914476
            ],
            [
              35.906468,
              54.912221
            ],
            [
              35.905833,
              54.904917
            ],
            [
              35.89792,
              54.903092
            ],
            [
              35.889999,
              54.901265
            ],
            [
              35.885425,
              54.900485
            ],
            [
              35.881708,
              54.900467
            ],
            [
              35.879799,
              54.900541
            ],
            [
              35.875749,
              54.900685
            ],
            [
              35.87364030229237,
              54.90075945525239
            ],
            [
              35.873377,
              54.900769
            ],
            [
              35.863565,
              54.903772
            ],
            [
              35.85998,
              54.903438
            ],
            [
              35.858623889161734,
              54.9032827247095
            ],
            [
              35.853865,
              54.904955
            ],
            [
              35.85108495636897,
              54.90562209495717
            ],
            [
              35.850563,
              54.906131
            ],
            [
              35.845876,
              54.908458
            ],
            [
              35.841968,
              54.90675
            ],
            [
              35.839441,
              54.904662
            ],
            [
              35.841572,
              54.901761
            ],
            [
              35.842409570164705,
              54.90114618574387
            ],
            [
              35.843197,
              54.898512
            ],
            [
              35.84966376408282,
              54.893850540890305
            ],
            [
              35.849191,
              54.891443
            ],
            [
              35.847267,
              54.887053
            ],
            [
              35.848032,
              54.883536
            ],
            [
              35.848985,
              54.883743
            ],
            [
              35.851624,
              54.88787
            ],
            [
              35.85291,
              54.892261
            ],
            [
              35.856726605558094,
              54.896390773298435
            ],
            [
              35.86101860630987,
              54.898028946585065
            ],
            [
              35.86466,
              54.897486
            ],
            [
              35.871571090255735,
              54.89723253703219
            ],
            [
              35.871571,
              54.89723
            ],
            [
              35.87267200441321,
              54.89719112502936
            ],
            [
              35.872672,
              54.897191
            ],
            [
              35.876171,
              54.897067
            ],
            [
              35.879671,
              54.896943
            ],
            [
              35.883472,
              54.896795
            ],
            [
              35.887231,
              54.897047
            ],
            [
              35.890822,
              54.89776
            ],
            [
              35.891259991553696,
              54.89786103662501
            ],
            [
              35.89126,
              54.897861
            ],
            [
              35.898729021859204,
              54.89958379869695
            ],
            [
              35.899963,
              54.88822
            ],
            [
              35.902242,
              54.885434
            ],
            [
              35.90267490926944,
              54.88578811086637
            ],
            [
              35.902675,
              54.885788
            ],
            [
              35.90392086015272,
              54.88680727302779
            ],
            [
              35.905835,
              54.888373
            ],
            [
              35.909427,
              54.891312
            ],
            [
              35.911709,
              54.892395
            ],
            [
              35.92322,
              54.88921
            ],
            [
              35.925412,
              54.886477
            ],
            [
              35.926263,
              54.885733
            ],
            [
              35.930148,
              54.882418
            ],
            [
              35.934033,
              54.879104
            ],
            [
              35.93403325613889,
              54.87910430019872
            ],
            [
              35.93456,
              54.878655
            ],
            [
              35.93456018505876,
              54.87865521698457
            ],
            [
              35.939474,
              54.874464
            ],
            [
              35.941546,
              54.866853
            ],
            [
              35.941847,
              54.863265
            ],
            [
              35.948351,
              54.860698
            ],
            [
              35.95195,
              54.860635
            ],
            [
              35.953782,
              54.862709
            ],
            [
              35.953191,
              54.863963
            ],
            [
              35.957388,
              54.875074
            ],
            [
              35.959194,
              54.878636
            ],
            [
              35.959143,
              54.879605
            ],
            [
              35.955555,
              54.879903
            ],
            [
              35.95119339633708,
              54.8760366331795
            ],
            [
              35.941819,
              54.877194
            ],
            [
              35.93636989701551,
              54.8818428793006
            ],
            [
              35.93637,
              54.881843
            ],
            [
              35.93577067935803,
              54.88235410360461
            ],
            [
              35.932748,
              54.884933
            ],
            [
              35.928599,
              54.888472
            ],
            [
              35.926264,
              54.891133
            ],
            [
              35.92588534316109,
              54.89178561851438
            ],
            [
              35.925886,
              54.891786
            ],
            [
              35.92502861187471,
              54.89326220269796
            ],
            [
              35.924235,
              54.89463
            ],
            [
              35.92225,
              54.901953
            ],
            [
              35.929321,
              54.909413
            ],
            [
              35.935813,
              54.909862
            ],
            [
              35.941503,
              54.909232
            ],
            [
              35.944098,
              54.910499
            ],
            [
              35.94409683318681,
              54.91050139052606
            ],
            [
              35.944754,
              54.910823
            ],
            [
              35.952003,
              54.916201
            ],
            [
              35.952346,
              54.916491
            ],
            [
              35.95225100849288,
              54.91660282183421
            ],
            [
              35.952761,
              54.916996
            ],
            [
              35.961954,
              54.922429
            ],
            [
              35.973003,
              54.91805
            ],
            [
              35.975342,
              54.917361
            ],
            [
              35.977327,
              54.917067
            ],
            [
              35.979313,
              54.916773
            ],
            [
              35.979841,
              54.920334
            ],
            [
              35.972433,
              54.925918
            ],
            [
              35.969066,
              54.928521
            ],
            [
              35.96626897761914,
              54.92754259183421
            ],
            [
              35.960495,
              54.925975
            ],
            [
              35.952723,
              54.929661
            ],
            [
              35.949521,
              54.931307
            ],
            [
              35.948446,
              54.929783
            ],
            [
              35.948141,
              54.923503
            ],
            [
              35.944428,
              54.914698
            ],
            [
              35.935972,
              54.913458
            ],
            [
              35.929079,
              54.913004
            ],
            [
              35.919701,
              54.916181
            ],
            [
              35.916099,
              54.917496
            ],
            [
              35.91380956082488,
              54.92021806423304
            ],
            [
              35.91579,
              54.922992
            ],
            [
              35.919783,
              54.92286
            ],
            [
              35.920767,
              54.926323
            ],
            [
              35.918103,
              54.92708
            ],
            [
              35.91485,
              54.926467
            ],
            [
              35.909574,
              54.925254
            ],
            [
              35.905783,
              54.924885
            ],
            [
              35.90221478129425,
              54.93012933418275
            ],
            [
              35.90402212214662,
              54.93698334657594
            ],
            [
              35.907811,
              54.94153
            ],
            [
              35.911062,
              54.945358
            ],
            [
              35.913871,
              54.948667
            ],
            [
              35.922778,
              54.949881
            ],
            [
              35.926736,
              54.949798
            ],
            [
              35.931312,
              54.949459
            ],
            [
              35.936376,
              54.950068
            ],
            [
              35.938867,
              54.950318
            ],
            [
              35.941357,
              54.950569
            ],
            [
              35.94135695103044,
              54.95056948724713
            ],
            [
              35.941809,
              54.950615
            ],
            [
              35.944496,
              54.950787
            ],
            [
              35.94449399062626,
              54.95081852698625
            ],
            [
              35.945582,
              54.950901
            ],
            [
              35.945328,
              54.95461
            ],
            [
              35.958458,
              54.966171
            ],
            [
              35.966302,
              54.958438
            ],
            [
              35.970146,
              54.958177
            ],
            [
              35.981741708417225,
              54.959991436389025
            ],
            [
              35.983742,
              54.958082
            ],
            [
              35.990889,
              54.956838
            ],
            [
              35.989634,
              54.967257
            ],
            [
              35.996593,
              54.967504
            ],
            [
              35.996465,
              54.971101
            ],
            [
              36.002904,
              54.979827
            ],
            [
              36.008201,
              54.979635
            ],
            [
              36.013209,
              54.979408
            ],
            [
              36.02002719463767,
              54.97192388402068
            ],
            [
              36.005356,
              54.971412
            ],
            [
              36.005481,
              54.967814
            ],
            [
              36.024599,
              54.968481
            ],
            [
              36.03469,
              54.978615
            ],
            [
              36.04359,
              54.972746
            ],
            [
              36.043716,
              54.969148
            ],
            [
              36.047007,
              54.96756
            ],
            [
              36.04820926528628,
              54.96861153776369
            ],
            [
              36.050501,
              54.969383
            ],
            [
              36.059500086319126,
              54.966675872915594
            ],
            [
              36.060886,
              54.965682
            ],
            [
              36.063877,
              54.963411
            ],
            [
              36.06408,
              54.963237
            ],
            [
              36.065547,
              54.963146
            ],
            [
              36.065708,
              54.963221
            ],
            [
              36.074539,
              54.97021
            ],
            [
              36.082499,
              54.970496
            ],
            [
              36.087556013877055,
              54.97066593658154
            ],
            [
              36.090098,
              54.959049
            ],
            [
              36.089899229739736,
              54.95888813189495
            ],
            [
              36.089512,
              54.958639
            ],
            [
              36.085268,
              54.95514
            ],
            [
              36.08221,
              54.952497
            ],
            [
              36.079816,
              54.949618
            ],
            [
              36.079069,
              54.947716
            ],
            [
              36.08017026007048,
              54.947011963311354
            ],
            [
              36.079846,
              54.94614
            ],
            [
              36.08070750194656,
              54.94666850403086
            ],
            [
              36.082102,
              54.945777
            ],
            [
              36.082289,
              54.946069
            ],
            [
              36.0839,
              54.948627
            ],
            [
              36.094329,
              54.943406
            ],
            [
              36.093555,
              54.940787
            ],
            [
              36.0944202618251,
              54.939177765250804
            ],
            [
              36.092441,
              54.936862
            ],
            [
              36.08874,
              54.935258
            ],
            [
              36.083893,
              54.933801
            ],
            [
              36.080779,
              54.934158
            ],
            [
              36.07922,
              54.934044
            ],
            [
              36.07922783043032,
              54.933937113137446
            ],
            [
              36.075354,
              54.932351
            ],
            [
              36.06622,
              54.928288
            ],
            [
              36.06059,
              54.928849
            ],
            [
              36.054461,
              54.930848
            ],
            [
              36.050871,
              54.931119
            ],
            [
              36.05086956117636,
              54.931099957481905
            ],
            [
              36.050869,
              54.9311
            ],
            [
              36.050756,
              54.929597
            ],
            [
              36.053755,
              54.925473
            ],
            [
              36.062539,
              54.924935
            ],
            [
              36.067566,
              54.924949
            ],
            [
              36.071957,
              54.925768
            ],
            [
              36.076753,
              54.926843
            ],
            [
              36.080820069846446,
              54.9290470727948
            ],
            [
              36.085225,
              54.924412
            ],
            [
              36.087874,
              54.913391
            ],
            [
              36.088655,
              54.909877
            ],
            [
              36.090828,
              54.91036
            ],
            [
              36.097961,
              54.913777
            ],
            [
              36.103215,
              54.913896
            ],
            [
              36.113999,
              54.911571
            ],
            [
              36.116433,
              54.90841
            ],
            [
              36.11992,
              54.904193
            ],
            [
              36.12120092723994,
              54.89686896185594
            ],
            [
              36.11872614272264,
              54.89231196586297
            ],
            [
              36.116472,
              54.891706
            ],
            [
              36.115679,
              54.891506
            ],
            [
              36.116561,
              54.888016
            ],
            [
              36.117354,
              54.888216
            ],
            [
              36.11737997181015,
              54.888222119585
            ],
            [
              36.11738,
              54.888222
            ],
            [
              36.121632,
              54.889224
            ],
            [
              36.129724557561296,
              54.88359954556388
            ],
            [
              36.123815,
              54.879528
            ],
            [
              36.120639,
              54.877056
            ],
            [
              36.127409,
              54.879728
            ],
            [
              36.13070728924503,
              54.87952895560983
            ],
            [
              36.136273,
              54.878539
            ],
            [
              36.136835267218515,
              54.879284410574314
            ],
            [
              36.134599,
              54.860491
            ],
            [
              36.13413296777454,
              54.86047305431464
            ],
            [
              36.133558,
              54.860508
            ],
            [
              36.13318526121451,
              54.86043656060709
            ],
            [
              36.12628894617157,
              54.860171001503744
            ],
            [
              36.126163480959015,
              54.8601904302187
            ],
            [
              36.122815,
              54.861771
            ],
            [
              36.119236,
              54.862345
            ],
            [
              36.115304,
              54.862704
            ],
            [
              36.111617,
              54.862925
            ],
            [
              36.107598,
              54.863222
            ],
            [
              36.10368,
              54.859619
            ],
            [
              36.098918,
              54.856783
            ],
            [
              36.091085,
              54.857512
            ],
            [
              36.08966589694882,
              54.85782479453771
            ],
            [
              36.087277,
              54.858845
            ],
            [
              36.08623547450323,
              54.858846458719185
            ],
            [
              36.079821,
              54.862608
            ],
            [
              36.076209,
              54.862649
            ],
            [
              36.065082,
              54.861
            ],
            [
              36.061419,
              54.861526
            ],
            [
              36.05118531088843,
              54.863983693131786
            ],
            [
              36.050836,
              54.86409
            ],
            [
              36.05083062794099,
              54.86407232402743
            ],
            [
              36.046946,
              54.865095
            ],
            [
              36.039356,
              54.86716
            ],
            [
              36.034738,
              54.871238
            ],
            [
              36.02936,
              54.876917
            ],
            [
              36.027928,
              54.877004
            ],
            [
              36.027856093361684,
              54.87699267542331
            ],
            [
              36.020119,
              54.877382
            ],
            [
              36.019623,
              54.886282
            ],
            [
              36.019527,
              54.888773
            ],
            [
              36.018393,
              54.890738
            ],
            [
              36.017279712830124,
              54.89009530695774
            ],
            [
              36.013674,
              54.895963
            ],
            [
              36.005621,
              54.896701
            ],
            [
              36.00528068570683,
              54.89675014285822
            ],
            [
              36.000296,
              54.897471
            ],
            [
              36.00029585148736,
              54.89746997451446
            ],
            [
              35.998973,
              54.897661
            ],
            [
              35.994372,
              54.898438
            ],
            [
              35.99231,
              54.898322
            ],
            [
              35.990248,
              54.898205
            ],
            [
              35.990248851497704,
              54.89818992471555
            ],
            [
              35.990167,
              54.898185
            ],
            [
              35.983335,
              54.898368
            ],
            [
              35.981458,
              54.89804
            ],
            [
              35.978747,
              54.898064
            ],
            [
              35.978715,
              54.894464
            ],
            [
              35.981426,
              54.89444
            ],
            [
              35.983908,
              54.894813
            ],
            [
              35.98989,
              54.894595
            ],
            [
              35.99045022195364,
              54.89462477487011
            ],
            [
              35.990451,
              54.894611
            ],
            [
              35.992513,
              54.894727
            ],
            [
              35.998336,
              54.894118
            ],
            [
              35.999780133486425,
              54.89390892172895
            ],
            [
              35.99978,
              54.893908
            ],
            [
              36.00042202454169,
              54.89381516946132
            ],
            [
              36.000422,
              54.893815
            ],
            [
              36.00579774757417,
              54.893037328651495
            ],
            [
              36.0143,
              54.885402
            ],
            [
              36.014232,
              54.881243
            ],
            [
              36.014340060591394,
              54.88085762626741
            ],
            [
              36.014334,
              54.880856
            ],
            [
              36.015179,
              54.877708
            ],
            [
              36.015507,
              54.876696
            ],
            [
              36.017398,
              54.866206
            ],
            [
              36.016554,
              54.862535
            ],
            [
              36.014702,
              54.851489
            ],
            [
              36.01065676252753,
              54.848322137142375
            ],
            [
              36.005309,
              54.847499
            ],
            [
              36.001515,
              54.851679
            ],
            [
              35.998333,
              54.854262
            ],
            [
              35.995734,
              54.858064
            ],
            [
              35.994003,
              54.861407
            ],
            [
              35.991975,
              54.863859
            ],
            [
              35.99182716562252,
              54.86396744470155
            ],
            [
              35.990801,
              54.865175
            ],
            [
              35.990409734949,
              54.86499739097136
            ],
            [
              35.988773,
              54.866702
            ],
            [
              35.984372,
              54.869207
            ],
            [
              35.980185,
              54.872218
            ],
            [
              35.976673,
              54.874872
            ],
            [
              35.974167,
              54.876167
            ],
            [
              35.972515,
              54.872969
            ],
            [
              35.97502,
              54.871674
            ],
            [
              35.976479,
              54.870688
            ],
            [
              35.97935,
              54.868174
            ],
            [
              35.983809,
              54.864863
            ],
            [
              35.988026,
              54.862578
            ],
            [
              35.991442,
              54.858343
            ],
            [
              35.994194,
              54.853991
            ],
            [
              35.996374,
              54.85105
            ],
            [
              35.99549537888817,
              54.84944278216812
            ],
            [
              35.989962,
              54.843511
            ],
            [
              35.989252725316064,
              54.841913796912976
            ],
            [
              35.987256,
              54.842743
            ],
            [
              35.982651,
              54.843775
            ],
            [
              35.974185,
              54.851375
            ],
            [
              35.972043,
              54.854268
            ],
            [
              35.969718,
              54.852547
            ],
            [
              35.971019,
              54.848317
            ],
            [
              35.972686,
              54.844972
            ],
            [
              35.974962,
              54.841973
            ],
            [
              35.9799,
              54.840605
            ],
            [
              35.978662,
              54.829501
            ],
            [
              35.972654,
              54.826634
            ],
            [
              35.9651,
              54.831151
            ],
            [
              35.962163,
              54.834101
            ],
            [
              35.960324,
              54.834429
            ],
            [
              35.957309,
              54.83246
            ],
            [
              35.957802,
              54.830859
            ],
            [
              35.958071,
              54.830275
            ],
            [
              35.956099,
              54.818737
            ],
            [
              35.950573,
              54.817889
            ],
            [
              35.947106,
              54.816917
            ],
            [
              35.947844,
              54.814287
            ],
            [
              35.947848,
              54.814272
            ],
            [
              35.948531,
              54.811751
            ],
            [
              35.952006,
              54.812693
            ],
            [
              35.957602,
              54.815467
            ],
            [
              35.95806560170552,
              54.81568883221505
            ],
            [
              35.958066,
              54.815688
            ],
            [
              35.96106747634851,
              54.817125221454845
            ],
            [
              35.961462,
              54.817314
            ],
            [
              35.96146240575439,
              54.817314328730525
            ],
            [
              35.961681,
              54.817419
            ],
            [
              35.97022022956183,
              54.819485154994204
            ],
            [
              35.972182,
              54.819352
            ],
            [
              35.972193527404066,
              54.819361904007984
            ],
            [
              35.974854,
              54.81889
            ],
            [
              35.979384,
              54.819226
            ],
            [
              35.98482495348595,
              54.828362921889564
            ],
            [
              35.98533728403282,
              54.82859738264719
            ],
            [
              35.989463,
              54.830485
            ],
            [
              35.994943,
              54.832903
            ],
            [
              35.99818536873285,
              54.83435462171585
            ],
            [
              36.008971,
              54.833305
            ],
            [
              36.012705,
              54.831049
            ],
            [
              36.013646,
              54.830298
            ],
            [
              36.015938,
              54.833073
            ],
            [
              36.018567,
              54.839552
            ],
            [
              36.02638549601368,
              54.837749384622256
            ],
            [
              36.026398,
              54.837238
            ],
            [
              36.02840912510878,
              54.83728699414328
            ],
            [
              36.033189391293135,
              54.837285449506005
            ],
            [
              36.044104,
              54.836017
            ],
            [
              36.04485134105713,
              54.83577312558817
            ],
            [
              36.04485,
              54.835769
            ],
            [
              36.051963,
              54.830174
            ],
            [
              36.057482774415526,
              54.8312533140469
            ],
            [
              36.059028916930174,
              54.83135760542831
            ],
            [
              36.061416,
              54.83066
            ],
            [
              36.06377476021342,
              54.82603623123161
            ],
            [
              36.06425,
              54.823698
            ],
            [
              36.063735,
              54.820172
            ],
            [
              36.060387,
              54.813696
            ],
            [
              36.057413,
              54.811073
            ],
            [
              36.055299,
              54.80085
            ],
            [
              36.051537,
              54.799096
            ],
            [
              36.039614,
              54.798773
            ],
            [
              36.03729787691514,
              54.798824114940516
            ],
            [
              36.031256,
              54.805041
            ],
            [
              36.02924440539401,
              54.804813365678044
            ],
            [
              36.02809,
              54.805582
            ],
            [
              36.027874,
              54.805421
            ],
            [
              36.026929,
              54.804417
            ],
            [
              36.026803929169155,
              54.803968242335806
            ],
            [
              36.02286948858085,
              54.797739773454474
            ],
            [
              36.017656,
              54.79771
            ],
            [
              36.017656000876684,
              54.797709842197285
            ],
            [
              36.015237,
              54.797696
            ],
            [
              36.01523743971038,
              54.7976206210769
            ],
            [
              36.010941,
              54.797902
            ],
            [
              36.00566,
              54.797678
            ],
            [
              36.003691,
              54.797691
            ],
            [
              35.998323,
              54.798331
            ],
            [
              35.992954,
              54.798971
            ],
            [
              35.992528,
              54.795396
            ],
            [
              35.997897,
              54.794756
            ],
            [
              36.003266,
              54.794117
            ],
            [
              36.007778,
              54.794053
            ],
            [
              36.011022,
              54.782758
            ],
            [
              36.011219,
              54.779163
            ],
            [
              36.017673,
              54.779516
            ],
            [
              36.025566,
              54.778957
            ],
            [
              36.028157,
              54.779154
            ],
            [
              36.032674,
              54.780122
            ],
            [
              36.033707,
              54.780394
            ],
            [
              36.035806,
              54.780808
            ],
            [
              36.03511,
              54.78434
            ],
            [
              36.040444,
              54.793101
            ],
            [
              36.04744,
              54.790791
            ],
            [
              36.050948,
              54.788882
            ],
            [
              36.051635,
              54.779483
            ],
            [
              36.053429,
              54.777192
            ],
            [
              36.056263,
              54.779412
            ],
            [
              36.062147,
              54.782766
            ],
            [
              36.06131236250927,
              54.785854780916175
            ],
            [
              36.062244,
              54.786633
            ],
            [
              36.060678,
              54.788508
            ],
            [
              36.059111,
              54.790384
            ],
            [
              36.059820060296225,
              54.79716976763827
            ],
            [
              36.061618,
              54.797548
            ],
            [
              36.06556,
              54.798288
            ],
            [
              36.069304,
              54.799964
            ],
            [
              36.071814,
              54.802611
            ],
            [
              36.072964,
              54.806453
            ],
            [
              36.06591,
              54.810267
            ],
            [
              36.06600569061601,
              54.810914412213855
            ],
            [
              36.06601,
              54.810915
            ],
            [
              36.068841,
              54.811618
            ],
            [
              36.068563,
              54.815207
            ],
            [
              36.067297,
              54.819651
            ],
            [
              36.0748621335533,
              54.82623601225345
            ],
            [
              36.075791671724225,
              54.82650110927687
            ],
            [
              36.086062208747975,
              54.8245638413444
            ],
            [
              36.086673178564666,
              54.824238052583276
            ],
            [
              36.092111,
              54.816109
            ],
            [
              36.093481,
              54.809996
            ],
            [
              36.094039677306505,
              54.80925558664445
            ],
            [
              36.094031,
              54.809249
            ],
            [
              36.0963133378279,
              54.806242311187546
            ],
            [
              36.096343,
              54.806203
            ],
            [
              36.099084,
              54.802591
            ],
            [
              36.102596,
              54.797384
            ],
            [
              36.107961,
              54.786586
            ],
            [
              36.10917123080026,
              54.784155114934656
            ],
            [
              36.109171,
              54.784155
            ],
            [
              36.111294,
              54.77989
            ],
            [
              36.113418,
              54.775625
            ],
            [
              36.104077,
              54.769084
            ],
            [
              36.099055,
              54.769373
            ],
            [
              36.095576,
              54.769915
            ],
            [
              36.091701,
              54.769977
            ],
            [
              36.083329,
              54.763036
            ],
            [
              36.0729,
              54.761967
            ],
            [
              36.069274,
              54.761831
            ],
            [
              36.067461,
              54.761743
            ],
            [
              36.064592,
              54.761612
            ],
            [
              36.06269650237588,
              54.758210678333576
            ],
            [
              36.060963,
              54.757775
            ],
            [
              36.055664,
              54.756321
            ],
            [
              36.050962,
              54.754189
            ],
            [
              36.047462,
              54.752439
            ],
            [
              36.047464607677576,
              54.75243378464486
            ],
            [
              36.045329,
              54.751362
            ],
            [
              36.042162,
              54.748777
            ],
            [
              36.0338470508897,
              54.743033596363965
            ],
            [
              36.031094,
              54.742015
            ],
            [
              36.021582,
              54.744043
            ],
            [
              36.018518,
              54.754125
            ],
            [
              36.018701,
              54.758163
            ],
            [
              36.016939,
              54.76218
            ],
            [
              36.013345,
              54.761975
            ],
            [
              36.00781550453905,
              54.756303510701805
            ],
            [
              36.006661,
              54.756521
            ],
            [
              35.999447,
              54.757583
            ],
            [
              35.996518,
              54.757255
            ],
            [
              35.99632611400775,
              54.75724276499999
            ],
            [
              35.996171,
              54.757288
            ],
            [
              35.985364,
              54.759563
            ],
            [
              35.981471,
              54.762852
            ],
            [
              35.979342,
              54.764707
            ],
            [
              35.976977,
              54.761992
            ],
            [
              35.973674,
              54.751807
            ],
            [
              35.973655,
              54.748207
            ],
            [
              35.971661,
              54.737007
            ],
            [
              35.971656,
              54.729849
            ],
            [
              35.971986,
              54.727436
            ],
            [
              35.972247,
              54.727335
            ],
            [
              35.974139,
              54.727242
            ],
            [
              35.978614,
              54.727234
            ],
            [
              35.9814,
              54.727176
            ],
            [
              35.990431,
              54.72368
            ],
            [
              35.992198,
              54.722848
            ],
            [
              35.992668,
              54.72284
            ],
            [
              35.99864,
              54.724222
            ],
            [
              36.005323,
              54.728311
            ],
            [
              36.002156,
              54.730715
            ],
            [
              36.00186,
              54.739724
            ],
            [
              36.0062710467504,
              54.74038608595544
            ],
            [
              36.007248,
              54.739811
            ],
            [
              36.008431,
              54.739305
            ],
            [
              36.009501783526225,
              54.73907276252227
            ],
            [
              36.009573,
              54.738998
            ],
            [
              36.00978,
              54.738942
            ],
            [
              36.00993065283084,
              54.73897974696577
            ],
            [
              36.011096,
              54.738727
            ],
            [
              36.011795,
              54.738604
            ],
            [
              36.02084536099362,
              54.74044125457076
            ],
            [
              36.028455,
              54.738234
            ],
            [
              36.032636,
              54.738597
            ],
            [
              36.03499209906866,
              54.739586097756096
            ],
            [
              36.035026,
              54.739518
            ],
            [
              36.03608454576636,
              54.740044710295656
            ],
            [
              36.03609,
              54.740047
            ],
            [
              36.03615916333321,
              54.74008183844065
            ],
            [
              36.037711,
              54.740854
            ],
            [
              36.040265,
              54.74215
            ],
            [
              36.043189,
              54.74481
            ],
            [
              36.047257,
              54.748322
            ],
            [
              36.04906923205544,
              54.749224535889134
            ],
            [
              36.049072,
              54.749219
            ],
            [
              36.053443,
              54.749703
            ],
            [
              36.061867,
              54.75429
            ],
            [
              36.062450342643395,
              54.75442076004336
            ],
            [
              36.062452,
              54.754413
            ],
            [
              36.066034,
              54.755177
            ],
            [
              36.06626580500692,
              54.755236724848764
            ],
            [
              36.066266,
              54.755236
            ],
            [
              36.067439,
              54.755539
            ],
            [
              36.07127,
              54.756596
            ],
            [
              36.075064,
              54.758817
            ],
            [
              36.084102,
              54.75952
            ],
            [
              36.091195,
              54.761081
            ],
            [
              36.096461,
              54.762228
            ],
            [
              36.105815658800644,
              54.762425561578375
            ],
            [
              36.106662,
              54.762186
            ],
            [
              36.110062,
              54.761002
            ],
            [
              36.11943314187204,
              54.757646767904426
            ],
            [
              36.119684295824946,
              54.757505595056934
            ],
            [
              36.116111,
              54.751372
            ],
            [
              36.110643,
              54.745655
            ],
            [
              36.107153,
              54.744311
            ],
            [
              36.102866,
              54.743316
            ],
            [
              36.099543,
              54.742167
            ],
            [
              36.096294,
              54.740541
            ],
            [
              36.087441,
              54.740131
            ],
            [
              36.086529,
              54.739829
            ],
            [
              36.08654090137608,
              54.73979303279978
            ],
            [
              36.086384,
              54.739781
            ],
            [
              36.084591,
              54.739139
            ],
            [
              36.08460662161025,
              54.73909535479215
            ],
            [
              36.083097,
              54.738513
            ],
            [
              36.072566,
              54.73948
            ],
            [
              36.072,
              54.739634
            ],
            [
              36.071051,
              54.736162
            ],
            [
              36.075605,
              54.732616
            ],
            [
              36.084255,
              54.735104
            ],
            [
              36.08840673211303,
              54.73498958218586
            ],
            [
              36.091812,
              54.734252
            ],
            [
              36.09302237890351,
              54.734862379321555
            ],
            [
              36.093145,
              54.734859
            ],
            [
              36.103809,
              54.729766
            ],
            [
              36.103845,
              54.725117
            ],
            [
              36.102767,
              54.721311
            ],
            [
              36.104615,
              54.719729
            ],
            [
              36.11404495128304,
              54.71688782193477
            ],
            [
              36.11409382942484,
              54.71681417921501
            ],
            [
              36.112853,
              54.71536
            ],
            [
              36.10624,
              54.712151
            ],
            [
              36.1043635466535,
              54.71184327698257
            ],
            [
              36.099521,
              54.71239
            ],
            [
              36.095696,
              54.713845
            ],
            [
              36.091669,
              54.71402
            ],
            [
              36.086869,
              54.713045
            ],
            [
              36.07799655931024,
              54.71073292516415
            ],
            [
              36.074302,
              54.711712
            ],
            [
              36.069994,
              54.714414
            ],
            [
              36.06999077458735,
              54.71440885754909
            ],
            [
              36.066229,
              54.716713
            ],
            [
              36.062853,
              54.725881
            ],
            [
              36.05889,
              54.72842
            ],
            [
              36.05727,
              54.729122
            ],
            [
              36.056373,
              54.729331
            ],
            [
              36.055555,
              54.725825
            ],
            [
              36.051068,
              54.718965
            ],
            [
              36.0396687762512,
              54.720194494109435
            ],
            [
              36.039613813558795,
              54.72021164230093
            ],
            [
              36.03558,
              54.724079
            ],
            [
              36.034854,
              54.72414
            ],
            [
              36.034253,
              54.724196
            ],
            [
              36.033749,
              54.724234
            ],
            [
              36.033482,
              54.720644
            ],
            [
              36.03430314373942,
              54.719993748062564
            ],
            [
              36.034092,
              54.718748
            ],
            [
              36.038239,
              54.716877
            ],
            [
              36.04347,
              54.706665
            ],
            [
              36.041451,
              54.701835
            ],
            [
              36.040773,
              54.700426
            ],
            [
              36.036887,
              54.693555
            ],
            [
              36.030059,
              54.693397
            ],
            [
              36.028318,
              54.693106
            ],
            [
              36.027925,
              54.692082
            ],
            [
              36.027788,
              54.690603
            ],
            [
              36.028608,
              54.689518
            ],
            [
              36.03002,
              54.689632
            ],
            [
              36.036979,
              54.689956
            ],
            [
              36.043863,
              54.682582
            ],
            [
              36.049234,
              54.682567
            ],
            [
              36.049745,
              54.682791
            ],
            [
              36.050038,
              54.683014
            ],
            [
              36.058276,
              54.690531
            ],
            [
              36.062636,
              54.690681
            ],
            [
              36.067339,
              54.692363
            ],
            [
              36.074713,
              54.688782
            ],
            [
              36.08501,
              54.683696
            ],
            [
              36.0851063737752,
              54.68383575140396
            ],
            [
              36.08749,
              54.682614
            ],
            [
              36.096603,
              54.677454
            ],
            [
              36.097657,
              54.676988
            ],
            [
              36.100381,
              54.676099
            ],
            [
              36.101498,
              54.679522
            ],
            [
              36.105857,
              54.68607
            ],
            [
              36.108553,
              54.696363
            ],
            [
              36.112704,
              54.696078
            ],
            [
              36.117007,
              54.69661
            ],
            [
              36.117538,
              54.696562
            ],
            [
              36.118316,
              54.696927
            ],
            [
              36.117468,
              54.700426
            ],
            [
              36.115985652676976,
              54.7064299540468
            ],
            [
              36.117655,
              54.706035
            ],
            [
              36.120876,
              54.707642
            ],
            [
              36.124162,
              54.718403
            ],
            [
              36.127404,
              54.719979
            ],
            [
              36.136912,
              54.7231
            ],
            [
              36.144865,
              54.724407
            ],
            [
              36.149954,
              54.726214
            ],
            [
              36.14989265596243,
              54.72783301668771
            ],
            [
              36.150685,
              54.727874
            ],
            [
              36.150599,
              54.729522
            ],
            [
              36.15020191592091,
              54.730848765057914
            ],
            [
              36.150206,
              54.73085
            ],
            [
              36.149717,
              54.732469
            ],
            [
              36.1532919039363,
              54.74213913418338
            ],
            [
              36.162385,
              54.737677
            ],
            [
              36.166308,
              54.735757
            ],
            [
              36.167825,
              54.724743
            ],
            [
              36.166833,
              54.723196
            ],
            [
              36.16693613966066,
              54.72312988308787
            ],
            [
              36.165962,
              54.721362
            ],
            [
              36.16275526065105,
              54.71348856274776
            ],
            [
              36.162277,
              54.712393
            ],
            [
              36.160242,
              54.708056
            ],
            [
              36.155841,
              54.697974
            ],
            [
              36.15446341820377,
              54.694817459166
            ],
            [
              36.152183,
              54.689594
            ],
            [
              36.15218363148617,
              54.6895937244424
            ],
            [
              36.145571,
              54.674445
            ],
            [
              36.144579,
              54.672218
            ],
            [
              36.14420930052236,
              54.67138757513903
            ],
            [
              36.143443,
              54.669667
            ],
            [
              36.141747,
              54.665717
            ],
            [
              36.14005,
              54.661767
            ],
            [
              36.140050341778064,
              54.66176685318421
            ],
            [
              36.140001,
              54.661652
            ],
            [
              36.13469470354122,
              54.65679925882554
            ],
            [
              36.130341,
              54.653627
            ],
            [
              36.12338942685795,
              54.65820486523989
            ],
            [
              36.1233626036543,
              54.65823804819893
            ],
            [
              36.121394,
              54.663173
            ],
            [
              36.119508,
              54.665048
            ],
            [
              36.11906976725386,
              54.66475666015264
            ],
            [
              36.117245,
              54.666
            ],
            [
              36.117166,
              54.665898
            ],
            [
              36.116283,
              54.662904
            ],
            [
              36.118717,
              54.658992
            ],
            [
              36.12071624331195,
              54.65626447011728
            ],
            [
              36.120482,
              54.656075
            ],
            [
              36.121199,
              54.655188
            ],
            [
              36.124921,
              54.650528
            ],
            [
              36.130939,
              54.636821
            ],
            [
              36.127974,
              54.634337
            ],
            [
              36.1273123295351,
              54.632734135062876
            ],
            [
              36.126357,
              54.630871
            ],
            [
              36.12636889509935,
              54.63086489461651
            ],
            [
              36.123705,
              54.62741
            ],
            [
              36.118254,
              54.62268
            ],
            [
              36.112727,
              54.618257
            ],
            [
              36.111089,
              54.616967
            ],
            [
              36.11095927075832,
              54.61686476987064
            ],
            [
              36.107332,
              54.614008
            ],
            [
              36.101937,
              54.609758
            ],
            [
              36.104164,
              54.60693
            ],
            [
              36.109559,
              54.611179
            ],
            [
              36.114955,
              54.615429
            ],
            [
              36.118614618289705,
              54.618358798616406
            ],
            [
              36.118625,
              54.618346
            ],
            [
              36.12017989861719,
              54.619608124985156
            ],
            [
              36.12018,
              54.619608
            ],
            [
              36.120521,
              54.619885
            ],
            [
              36.126258,
              54.624871
            ],
            [
              36.129327,
              54.628812
            ],
            [
              36.13784,
              54.632632
            ],
            [
              36.144279,
              54.625257
            ],
            [
              36.142839,
              54.619952
            ],
            [
              36.144286,
              54.618512
            ],
            [
              36.145791,
              54.617847
            ],
            [
              36.154391,
              54.618512
            ],
            [
              36.156005,
              54.620677
            ],
            [
              36.164595,
              54.626589
            ],
            [
              36.166953,
              54.627292
            ],
            [
              36.169349,
              54.628293
            ],
            [
              36.169849,
              54.628993
            ],
            [
              36.170029,
              54.629521
            ],
            [
              36.170919,
              54.631696
            ],
            [
              36.17113,
              54.632949
            ],
            [
              36.187036,
              54.632134
            ],
            [
              36.188154,
              54.631779
            ],
            [
              36.197424,
              54.624676
            ],
            [
              36.201941,
              54.624487
            ],
            [
              36.204002,
              54.627439
            ],
            [
              36.210916873713735,
              54.631430334970815
            ],
            [
              36.215153,
              54.630694
            ],
            [
              36.217991,
              54.633108
            ],
            [
              36.221911,
              54.63615
            ],
            [
              36.22954075393429,
              54.62919967289398
            ],
            [
              36.227198,
              54.624441
            ],
            [
              36.224145,
              54.618855
            ],
            [
              36.224165,
              54.618444
            ],
            [
              36.224157,
              54.617031
            ],
            [
              36.230385,
              54.614099
            ],
            [
              36.237073,
              54.617314
            ],
            [
              36.241745,
              54.619888
            ],
            [
              36.247932,
              54.620852
            ],
            [
              36.250303,
              54.609323
            ],
            [
              36.251618,
              54.605972
            ],
            [
              36.258967,
              54.597614
            ],
            [
              36.260362,
              54.596214
            ],
            [
              36.260737,
              54.596117
            ],
            [
              36.269097,
              54.597282
            ],
            [
              36.273193,
              54.597358
            ],
            [
              36.275587,
              54.597509
            ],
            [
              36.285831,
              54.594629
            ],
            [
              36.29456,
              54.594827
            ],
            [
              36.297382,
              54.597062
            ],
            [
              36.297055,
              54.597475
            ],
            [
              36.297959642817396,
              54.60484023943114
            ],
            [
              36.298212,
              54.604897
            ],
            [
              36.300165,
              54.605496
            ],
            [
              36.310336,
              54.606752
            ],
            [
              36.314038,
              54.601284
            ],
            [
              36.315676,
              54.598078
            ],
            [
              36.318015,
              54.597689
            ],
            [
              36.31997,
              54.597503
            ],
            [
              36.321658,
              54.599025
            ],
            [
              36.322532,
              54.600033
            ],
            [
              36.324338,
              54.606575
            ],
            [
              36.328771,
              54.610436
            ],
            [
              36.339449,
              54.609155
            ],
            [
              36.343048,
              54.609043
            ],
            [
              36.343138,
              54.611959
            ],
            [
              36.343642,
              54.61501
            ],
            [
              36.341256,
              54.618974
            ],
            [
              36.349977276163074,
              54.62616936028813
            ],
            [
              36.350475,
              54.626022
            ],
            [
              36.35094349082646,
              54.626022
            ],
            [
              36.355463,
              54.620639
            ],
            [
              36.358235,
              54.617557
            ],
            [
              36.360336,
              54.615086
            ],
            [
              36.360505,
              54.614916
            ],
            [
              36.362478,
              54.616142
            ],
            [
              36.363053,
              54.61746
            ],
            [
              36.362883,
              54.61763
            ],
            [
              36.358566,
              54.622502
            ],
            [
              36.357731,
              54.626948
            ],
            [
              36.362337,
              54.634842
            ],
            [
              36.370401,
              54.635971
            ],
            [
              36.373094,
              54.636011
            ],
            [
              36.376123,
              54.636537
            ],
            [
              36.375508,
              54.640084
            ],
            [
              36.371135,
              54.639752
            ],
            [
              36.365986,
              54.640414
            ],
            [
              36.357674,
              54.642713
            ],
            [
              36.353933,
              54.647425
            ],
            [
              36.351114,
              54.645186
            ],
            [
              36.354854,
              54.640475
            ],
            [
              36.348673,
              54.633401
            ],
            [
              36.344712,
              54.633155
            ],
            [
              36.34208858677883,
              54.634257839613824
            ],
            [
              36.345121,
              54.636614
            ],
            [
              36.344338,
              54.640128
            ],
            [
              36.337306,
              54.639925
            ],
            [
              36.332187,
              54.640748
            ],
            [
              36.33138589672142,
              54.64105495651649
            ],
            [
              36.331414,
              54.641138
            ],
            [
              36.331429,
              54.648057
            ],
            [
              36.329674,
              54.648309
            ],
            [
              36.328332,
              54.648342
            ],
            [
              36.324757,
              54.646561
            ],
            [
              36.316846,
              54.65493
            ],
            [
              36.315958288880175,
              54.65724795863229
            ],
            [
              36.316591,
              54.657655
            ],
            [
              36.315272,
              54.659704
            ],
            [
              36.314714,
              54.660497
            ],
            [
              36.30957346348566,
              54.665907213511
            ],
            [
              36.312189,
              54.67389
            ],
            [
              36.31095,
              54.67727
            ],
            [
              36.301186,
              54.682161
            ],
            [
              36.301001733209404,
              54.68371209029302
            ],
            [
              36.301242,
              54.683746
            ],
            [
              36.301132,
              54.684529
            ],
            [
              36.300755,
              54.685789
            ],
            [
              36.300414,
              54.686854
            ],
            [
              36.299030970374545,
              54.689336069594745
            ],
            [
              36.296903,
              54.694335
            ],
            [
              36.296405,
              54.696614
            ],
            [
              36.295664,
              54.698528
            ],
            [
              36.293658,
              54.702963
            ],
            [
              36.291981905210044,
              54.71169979448766
            ],
            [
              36.292092,
              54.713948
            ],
            [
              36.29211275392398,
              54.71449327540936
            ],
            [
              36.292122,
              54.714566
            ],
            [
              36.292221,
              54.717138
            ],
            [
              36.29232,
              54.719709
            ],
            [
              36.29242885345705,
              54.72269376179223
            ],
            [
              36.29370295704809,
              54.727553836159736
            ],
            [
              36.2937030711248,
              54.72755417421984
            ],
            [
              36.295287,
              54.731434
            ],
            [
              36.298124,
              54.738138
            ],
            [
              36.298918,
              54.741802
            ],
            [
              36.304005,
              54.750243
            ],
            [
              36.309204,
              54.749391
            ],
            [
              36.312413,
              54.747545
            ],
            [
              36.31478,
              54.74441
            ],
            [
              36.320309,
              54.741814
            ],
            [
              36.324393,
              54.740898
            ],
            [
              36.320822,
              54.729464
            ],
            [
              36.321009,
              54.725869
            ],
            [
              36.32957368439269,
              54.72546125214123
            ],
            [
              36.332494,
              54.724976
            ],
            [
              36.33274022832035,
              54.72509982171282
            ],
            [
              36.335375,
              54.724794
            ],
            [
              36.33782130704623,
              54.72594463453953
            ],
            [
              36.337869,
              54.725831
            ],
            [
              36.342927,
              54.726458
            ],
            [
              36.347712544458794,
              54.72689471763083
            ],
            [
              36.352082,
              54.726368
            ],
            [
              36.355218,
              54.723884
            ],
            [
              36.361366,
              54.71702
            ],
            [
              36.36410424647797,
              54.716571344393145
            ],
            [
              36.364679,
              54.716105
            ],
            [
              36.37347146767866,
              54.712973090448166
            ],
            [
              36.375695,
              54.704703
            ],
            [
              36.370828,
              54.702606
            ],
            [
              36.366238,
              54.70012
            ],
            [
              36.363364,
              54.694288
            ],
            [
              36.363676,
              54.693479
            ],
            [
              36.36399,
              54.69297
            ],
            [
              36.36426609075525,
              54.69298146139466
            ],
            [
              36.364204,
              54.692806
            ],
            [
              36.364253,
              54.692661
            ],
            [
              36.365957341847725,
              54.69296128880174
            ],
            [
              36.36669,
              54.690381
            ],
            [
              36.36951295411718,
              54.68702490311222
            ],
            [
              36.371412,
              54.682113
            ],
            [
              36.366231,
              54.678575
            ],
            [
              36.362721,
              54.677775
            ],
            [
              36.360024,
              54.677035
            ],
            [
              36.357766,
              54.676365
            ],
            [
              36.35776699525086,
              54.67636164588796
            ],
            [
              36.357607,
              54.676316
            ],
            [
              36.352711,
              54.674425
            ],
            [
              36.34944,
              54.672284
            ],
            [
              36.347514,
              54.669242
            ],
            [
              36.35474,
              54.661463
            ],
            [
              36.354785104547396,
              54.66147151847262
            ],
            [
              36.356729,
              54.658
            ],
            [
              36.360052,
              54.656795
            ],
            [
              36.365429,
              54.656808
            ],
            [
              36.370282,
              54.656921
            ],
            [
              36.37318,
              54.657002
            ],
            [
              36.373179984939114,
              54.657002536525404
            ],
            [
              36.373232,
              54.657004
            ],
            [
              36.376705,
              54.657175
            ],
            [
              36.380178,
              54.657347
            ],
            [
              36.380001,
              54.660942
            ],
            [
              36.376528,
              54.660771
            ],
            [
              36.373067972054294,
              54.6605996917655
            ],
            [
              36.370181,
              54.660519
            ],
            [
              36.365321,
              54.660407
            ],
            [
              36.358025,
              54.664486
            ],
            [
              36.361048,
              54.673583
            ],
            [
              36.36703,
              54.675065
            ],
            [
              36.370463,
              54.675847
            ],
            [
              36.373304,
              54.678056
            ],
            [
              36.375074,
              54.68112
            ],
            [
              36.375149664098736,
              54.68118916227345
            ],
            [
              36.375151,
              54.681188
            ],
            [
              36.37516112065817,
              54.68119963436933
            ],
            [
              36.378676214082134,
              54.68441267538386
            ],
            [
              36.38400207809674,
              54.68847613312546
            ],
            [
              36.384055,
              54.688378
            ],
            [
              36.385029,
              54.688903
            ],
            [
              36.38603535916489,
              54.69002582209495
            ],
            [
              36.386039,
              54.690023
            ],
            [
              36.387305,
              54.691657
            ],
            [
              36.393558,
              54.700832
            ],
            [
              36.399275,
              54.706784
            ],
            [
              36.399846,
              54.710338
            ],
            [
              36.399654,
              54.710369
            ],
            [
              36.39704125518868,
              54.70995267964856
            ],
            [
              36.397021,
              54.710037
            ],
            [
              36.395945,
              54.709778
            ],
            [
              36.385936,
              54.706013
            ],
            [
              36.382309,
              54.712533
            ],
            [
              36.38206091846616,
              54.71263850154598
            ],
            [
              36.385937,
              54.715685
            ],
            [
              36.386038,
              54.715877
            ],
            [
              36.383929,
              54.718999
            ],
            [
              36.384733,
              54.73083
            ],
            [
              36.38497124679817,
              54.730977416788775
            ],
            [
              36.386271,
              54.730184
            ],
            [
              36.392919,
              54.727912
            ],
            [
              36.395886,
              54.731849
            ],
            [
              36.395725,
              54.73691
            ],
            [
              36.39132081255406,
              54.73995972134205
            ],
            [
              36.390849,
              54.740587
            ],
            [
              36.38863,
              54.741823
            ],
            [
              36.379663,
              54.744087
            ],
            [
              36.375443,
              54.745064
            ],
            [
              36.369699,
              54.746225
            ],
            [
              36.365432,
              54.74843
            ],
            [
              36.366434,
              54.760193
            ],
            [
              36.362243,
              54.759827
            ],
            [
              36.354606,
              54.760408
            ],
            [
              36.35349353539105,
              54.760176443471465
            ],
            [
              36.353493,
              54.760179
            ],
            [
              36.351022,
              54.759662
            ],
            [
              36.342507,
              54.759659
            ],
            [
              36.338651,
              54.759779
            ],
            [
              36.335179,
              54.757716
            ],
            [
              36.33181,
              54.755998
            ],
            [
              36.32848,
              54.754315
            ],
            [
              36.320381,
              54.750958
            ],
            [
              36.313768,
              54.75224
            ],
            [
              36.309805,
              54.75294
            ],
            [
              36.304541,
              54.753803
            ],
            [
              36.303819966366056,
              54.75391778906118
            ],
            [
              36.30382,
              54.753918
            ],
            [
              36.29998615464758,
              54.7545285314308
            ],
            [
              36.29235,
              54.759329
            ],
            [
              36.290026,
              54.765414
            ],
            [
              36.293983,
              54.776675
            ],
            [
              36.295501,
              54.780347
            ],
            [
              36.295747,
              54.786148
            ],
            [
              36.29634862287752,
              54.79544676763319
            ],
            [
              36.303404,
              54.799694
            ],
            [
              36.313099,
              54.800258
            ],
            [
              36.316503,
              54.801456
            ],
            [
              36.31671623060085,
              54.801921015140074
            ],
            [
              36.316829,
              54.801943
            ],
            [
              36.325431,
              54.803206
            ],
            [
              36.32561,
              54.803401
            ],
            [
              36.327247,
              54.804953
            ],
            [
              36.33832,
              54.807761
            ],
            [
              36.341865,
              54.807135
            ],
            [
              36.34218,
              54.808921
            ],
            [
              36.342272,
              54.809434
            ],
            [
              36.337443,
              54.812117
            ],
            [
              36.32927567390367,
              54.82329244827815
            ],
            [
              36.32925661381086,
              54.82336606809009
            ],
            [
              36.329244571285756,
              54.82486497399738
            ],
            [
              36.330105,
              54.825943
            ],
            [
              36.33452494390035,
              54.833698372265225
            ],
            [
              36.336494,
              54.835197
            ],
            [
              36.339875854163296,
              54.83989568233307
            ],
            [
              36.342177068655346,
              54.84229438612501
            ],
            [
              36.34466530345956,
              54.84165515995071
            ],
            [
              36.349711,
              54.835148
            ],
            [
              36.349555,
              54.831552
            ],
            [
              36.351286,
              54.831477
            ],
            [
              36.35564,
              54.83163
            ],
            [
              36.35663129075924,
              54.831849632332904
            ],
            [
              36.358309,
              54.829981
            ],
            [
              36.36032619990503,
              54.82628195626605
            ],
            [
              36.356764,
              54.817104
            ],
            [
              36.35715404985256,
              54.81669333212762
            ],
            [
              36.356896,
              54.815926
            ],
            [
              36.35748489970572,
              54.80410828246546
            ],
            [
              36.354967,
              54.800757
            ],
            [
              36.3571668481887,
              54.80084058619886
            ],
            [
              36.366469,
              54.800923
            ],
            [
              36.372829,
              54.80626
            ],
            [
              36.378371,
              54.804474
            ],
            [
              36.380665556954185,
              54.803702334864376
            ],
            [
              36.38350950560571,
              54.802616639888974
            ],
            [
              36.387066,
              54.796106
            ],
            [
              36.387416,
              54.790317
            ],
            [
              36.38605524157588,
              54.783014836725044
            ],
            [
              36.38349,
              54.778929
            ],
            [
              36.38536,
              54.776757448275866
            ],
            [
              36.38536,
              54.775684
            ],
            [
              36.386240389475994,
              54.7757350893002
            ],
            [
              36.386448,
              54.775494
            ],
            [
              36.3873605781283,
              54.77555845780094
            ],
            [
              36.387361,
              54.775556
            ],
            [
              36.38738566980735,
              54.77556023009243
            ],
            [
              36.392012,
              54.775887
            ],
            [
              36.400456,
              54.775802
            ],
            [
              36.404672,
              54.775494
            ],
            [
              36.408145,
              54.77618
            ],
            [
              36.411628,
              54.777044
            ],
            [
              36.412049794992335,
              54.777236761977655
            ],
            [
              36.415694,
              54.778223
            ],
            [
              36.4178557112357,
              54.779622994045404
            ],
            [
              36.422478,
              54.781315
            ],
            [
              36.42410871217117,
              54.783097942268355
            ],
            [
              36.42877058999635,
              54.78474510082506
            ],
            [
              36.433777,
              54.776604
            ],
            [
              36.432964,
              54.773097
            ],
            [
              36.429783,
              54.762721
            ],
            [
              36.429263,
              54.759159
            ],
            [
              36.430002901520275,
              54.75933228041399
            ],
            [
              36.429859,
              54.758918
            ],
            [
              36.435783206083805,
              54.75472944462811
            ],
            [
              36.437947,
              54.750505
            ],
            [
              36.441623,
              54.752976
            ],
            [
              36.441919,
              54.757119
            ],
            [
              36.43875511847604,
              54.763525160112245
            ],
            [
              36.438232196553024,
              54.765369639414
            ],
            [
              36.437943341231524,
              54.7667191458898
            ],
            [
              36.438622,
              54.771973
            ],
            [
              36.44833094067226,
              54.774493724967535
            ],
            [
              36.452808,
              54.771839
            ],
            [
              36.45280956159306,
              54.77184163412512
            ],
            [
              36.453783,
              54.771265
            ],
            [
              36.458407,
              54.77002
            ],
            [
              36.464845,
              54.768912
            ],
            [
              36.470954,
              54.765066
            ],
            [
              36.473269,
              54.759075
            ],
            [
              36.472177,
              54.749488
            ],
            [
              36.46761,
              54.746238
            ],
            [
              36.467423,
              54.745758
            ],
            [
              36.467353,
              54.745515
            ],
            [
              36.46761524168435,
              54.7454389142582
            ],
            [
              36.467639,
              54.741817
            ],
            [
              36.468265,
              54.73655
            ],
            [
              36.46855602609791,
              54.7342869968701
            ],
            [
              36.462253,
              54.724403
            ],
            [
              36.460688,
              54.713293
            ],
            [
              36.458434,
              54.70951
            ],
            [
              36.456472,
              54.7047
            ],
            [
              36.447839,
              54.697552
            ],
            [
              36.447393,
              54.694128
            ],
            [
              36.448969,
              54.690904
            ],
            [
              36.452683,
              54.689657
            ],
            [
              36.456522,
              54.691048
            ],
            [
              36.45699982881291,
              54.69306772818571
            ],
            [
              36.45757,
              54.693095
            ],
            [
              36.458523,
              54.699506
            ],
            [
              36.459838,
              54.703425
            ],
            [
              36.461746,
              54.708101
            ],
            [
              36.463568,
              54.711133
            ],
            [
              36.473319,
              54.712428
            ],
            [
              36.477247,
              54.712214
            ],
            [
              36.487124,
              54.708378
            ],
            [
              36.491844,
              54.70771
            ],
            [
              36.4923526645162,
              54.70766015252981
            ],
            [
              36.493375,
              54.70749
            ],
            [
              36.494953,
              54.707304
            ],
            [
              36.497108,
              54.707181
            ],
            [
              36.497108749239146,
              54.70719407167716
            ],
            [
              36.497385,
              54.707167
            ],
            [
              36.498476,
              54.707283
            ],
            [
              36.49959,
              54.7076
            ],
            [
              36.498604,
              54.711062
            ],
            [
              36.497403,
              54.710767
            ],
            [
              36.4973139640187,
              54.710774372248515
            ],
            [
              36.497314,
              54.710775
            ],
            [
              36.49728948104629,
              54.71077639945768
            ],
            [
              36.49502409497582,
              54.71096397518677
            ],
            [
              36.491415,
              54.712729
            ],
            [
              36.4897161654613,
              54.713604961559014
            ],
            [
              36.48826156385109,
              54.714508773134234
            ],
            [
              36.492013,
              54.724964
            ],
            [
              36.495598,
              54.724919
            ],
            [
              36.499222,
              54.724453
            ],
            [
              36.504118,
              54.725283
            ],
            [
              36.509418,
              54.726415
            ],
            [
              36.513841,
              54.727138
            ],
            [
              36.510242,
              54.730213
            ],
            [
              36.506747,
              54.729507
            ],
            [
              36.496354,
              54.729053
            ],
            [
              36.485266,
              54.730927
            ],
            [
              36.481368,
              54.732232
            ],
            [
              36.47785,
              54.733032
            ],
            [
              36.47323765202941,
              54.737843394919054
            ],
            [
              36.47193429870055,
              54.74067636051356
            ],
            [
              36.474233,
              54.746533
            ],
            [
              36.482135,
              54.747071
            ],
            [
              36.486298,
              54.745137
            ],
            [
              36.488326,
              54.744659
            ],
            [
              36.489151,
              54.748163
            ],
            [
              36.487185,
              54.748967
            ],
            [
              36.483284,
              54.750483
            ],
            [
              36.478513,
              54.754065
            ],
            [
              36.47773933810482,
              54.75665166290234
            ],
            [
              36.483194,
              54.765197
            ],
            [
              36.4907,
              54.765606
            ],
            [
              36.495399,
              54.76587
            ],
            [
              36.498901,
              54.766051
            ],
            [
              36.5024,
              54.766231
            ],
            [
              36.507507,
              54.766496
            ],
            [
              36.52244485421695,
              54.762521013364285
            ],
            [
              36.52820189163479,
              54.760958146316426
            ],
            [
              36.537016,
              54.758565
            ],
            [
              36.54781788234125,
              54.751707074699475
            ],
            [
              36.549796623958656,
              54.750428733698996
            ],
            [
              36.556278,
              54.746241
            ],
            [
              36.55627823817877,
              54.74624136879294
            ],
            [
              36.557206,
              54.745642
            ],
            [
              36.55721404276786,
              54.74565444282868
            ],
            [
              36.558452,
              54.744857
            ],
            [
              36.563322,
              54.74258
            ],
            [
              36.571564,
              54.738969
            ],
            [
              36.576553,
              54.740466
            ],
            [
              36.581744,
              54.742736
            ],
            [
              36.589694,
              54.739399
            ],
            [
              36.594457,
              54.741784
            ],
            [
              36.59301048594674,
              54.74358294573468
            ],
            [
              36.593489,
              54.74499
            ],
            [
              36.592020975463704,
              54.74548970117051
            ],
            [
              36.59194397499363,
              54.74551592653304
            ],
            [
              36.591944,
              54.745516
            ],
            [
              36.591582070977644,
              54.74563918635225
            ],
            [
              36.59127222976438,
              54.74574471425535
            ],
            [
              36.591272,
              54.745745
            ],
            [
              36.59108511111333,
              54.74580844444369
            ],
            [
              36.590646,
              54.745958
            ],
            [
              36.588671,
              54.74663
            ],
            [
              36.587908,
              54.746887
            ],
            [
              36.584356,
              54.747146
            ],
            [
              36.579944,
              54.745854
            ],
            [
              36.572044,
              54.742537
            ],
            [
              36.565082,
              54.745721
            ],
            [
              36.559979,
              54.748117
            ],
            [
              36.559152095283366,
              54.74865277074802
            ],
            [
              36.55916,
              54.748665
            ],
            [
              36.552776777709084,
              54.75278944448883
            ],
            [
              36.548791,
              54.755365
            ],
            [
              36.53935,
              54.761464
            ],
            [
              36.538038,
              54.762017
            ],
            [
              36.5325969012238,
              54.763494636109726
            ],
            [
              36.532597,
              54.763495
            ],
            [
              36.527445098634495,
              54.764893708218
            ],
            [
              36.523434,
              54.765983
            ],
            [
              36.52340413883026,
              54.76599080291872
            ],
            [
              36.520733,
              54.766716
            ],
            [
              36.508868,
              54.769938
            ],
            [
              36.507713,
              54.770091
            ],
            [
              36.502221,
              54.769827
            ],
            [
              36.498716,
              54.769646
            ],
            [
              36.495214,
              54.769465
            ],
            [
              36.490498,
              54.7692
            ],
            [
              36.483009,
              54.768792
            ],
            [
              36.474361,
              54.770103
            ],
            [
              36.469213,
              54.771802
            ],
            [
              36.465464,
              54.772459
            ],
            [
              36.459007,
              54.77357
            ],
            [
              36.455428,
              54.774468
            ],
            [
              36.454642830146945,
              54.774934026669435
            ],
            [
              36.454644,
              54.774936
            ],
            [
              36.45006223920192,
              54.77765280893539
            ],
            [
              36.449727546684336,
              54.777851575218335
            ],
            [
              36.442577,
              54.786925
            ],
            [
              36.441873,
              54.790455
            ],
            [
              36.437138,
              54.790056
            ],
            [
              36.428237,
              54.790552
            ],
            [
              36.423494,
              54.799772
            ],
            [
              36.423483,
              54.803374
            ],
            [
              36.42002,
              54.80748
            ],
            [
              36.417881,
              54.810435
            ],
            [
              36.408472,
              54.806503
            ],
            [
              36.406099735165625,
              54.80607859783676
            ],
            [
              36.404411,
              54.808349
            ],
            [
              36.40354762974428,
              54.805622021890464
            ],
            [
              36.403173,
              54.805555
            ],
            [
              36.401896372816495,
              54.804198856154464
            ],
            [
              36.39267646926073,
              54.80270441411379
            ],
            [
              36.390489,
              54.80367
            ],
            [
              36.384837,
              54.806086
            ],
            [
              36.381462,
              54.807247
            ],
            [
              36.377493,
              54.808554
            ],
            [
              36.37621303507496,
              54.80881412477043
            ],
            [
              36.372637,
              54.809817
            ],
            [
              36.370051,
              54.820919
            ],
            [
              36.368581,
              54.824205
            ],
            [
              36.37248166336558,
              54.82924688672042
            ],
            [
              36.37425921264805,
              54.8306083969294
            ],
            [
              36.375725,
              54.831147
            ],
            [
              36.37662780232793,
              54.83202886240312
            ],
            [
              36.384731,
              54.830771
            ],
            [
              36.386277,
              54.829899
            ],
            [
              36.386571,
              54.829787
            ],
            [
              36.38685739797946,
              54.830537121810686
            ],
            [
              36.388886,
              54.830314
            ],
            [
              36.394595075117124,
              54.83210838308261
            ],
            [
              36.400054,
              54.832429
            ],
            [
              36.40375,
              54.832204
            ],
            [
              36.406630609354714,
              54.83205626410426
            ],
            [
              36.407104,
              54.832025
            ],
            [
              36.411688,
              54.831674
            ],
            [
              36.412463,
              54.831703
            ],
            [
              36.413423,
              54.834828
            ],
            [
              36.41813802426732,
              54.844387613120254
            ],
            [
              36.421229,
              54.841851
            ],
            [
              36.424155,
              54.840614
            ],
            [
              36.42716991260971,
              54.8404993980587
            ],
            [
              36.428386,
              54.840453
            ],
            [
              36.431224,
              54.840368
            ],
            [
              36.437511,
              54.849005
            ],
            [
              36.442411,
              54.849085
            ],
            [
              36.451986,
              54.843623
            ],
            [
              36.452845,
              54.840203
            ],
            [
              36.452919,
              54.836463
            ],
            [
              36.45415,
              54.832505
            ],
            [
              36.458054,
              54.830449
            ],
            [
              36.465796,
              54.828166
            ],
            [
              36.470612,
              54.826789
            ],
            [
              36.477844,
              54.820131
            ],
            [
              36.478767,
              54.815731
            ],
            [
              36.474964,
              54.805294
            ],
            [
              36.473098,
              54.801347
            ],
            [
              36.474724,
              54.79155
            ],
            [
              36.476233,
              54.78902
            ],
            [
              36.477743,
              54.786491
            ],
            [
              36.480834,
              54.788336
            ],
            [
              36.479542,
              54.798805
            ],
            [
              36.482017,
              54.806985
            ],
            [
              36.48286,
              54.811031
            ],
            [
              36.48398153014997,
              54.81689585945139
            ],
            [
              36.49328650405321,
              54.82119984550776
            ],
            [
              36.496251,
              54.820532
            ],
            [
              36.502135,
              54.816357
            ],
            [
              36.50471,
              54.813462
            ],
            [
              36.507863,
              54.811012
            ],
            [
              36.508275343326424,
              54.811468552985964
            ],
            [
              36.509607,
              54.810724
            ],
            [
              36.50909134902,
              54.81182224206105
            ],
            [
              36.513719,
              54.812321
            ],
            [
              36.514806,
              54.815753
            ],
            [
              36.512466,
              54.816494
            ],
            [
              36.510127,
              54.817235
            ],
            [
              36.507693,
              54.8181
            ],
            [
              36.503064534975444,
              54.820905966867585
            ],
            [
              36.502193,
              54.822047
            ],
            [
              36.498304,
              54.823792
            ],
            [
              36.495080046419865,
              54.82448179139259
            ],
            [
              36.492877,
              54.824982
            ],
            [
              36.487961,
              54.825871
            ],
            [
              36.48500189335049,
              54.826423245141356
            ],
            [
              36.478305,
              54.828489
            ],
            [
              36.474828,
              54.829495
            ],
            [
              36.471137,
              54.830351
            ],
            [
              36.467179,
              54.831669
            ],
            [
              36.463247,
              54.83295
            ],
            [
              36.456064,
              54.842287
            ],
            [
              36.454882558220596,
              54.846738896519
            ],
            [
              36.454883,
              54.846739
            ],
            [
              36.454226,
              54.849544
            ],
            [
              36.452953,
              54.851259
            ],
            [
              36.449126,
              54.852185
            ],
            [
              36.445634,
              54.852412
            ],
            [
              36.441167,
              54.85287
            ],
            [
              36.44087324319397,
              54.85284937767877
            ],
            [
              36.440872,
              54.852883
            ],
            [
              36.440606,
              54.852873
            ],
            [
              36.437207,
              54.852592
            ],
            [
              36.433005,
              54.851904
            ],
            [
              36.428144,
              54.85161
            ],
            [
              36.424426,
              54.852232
            ],
            [
              36.420342,
              54.852903
            ],
            [
              36.41920313136237,
              54.85309152632491
            ],
            [
              36.419201,
              54.853095
            ],
            [
              36.418104,
              54.854038
            ],
            [
              36.412311,
              54.860963
            ],
            [
              36.408966,
              54.860159
            ],
            [
              36.40455378991594,
              54.85625917130444
            ],
            [
              36.400904,
              54.856367
            ],
            [
              36.39894491350008,
              54.85683622241098
            ],
            [
              36.393312,
              54.866138
            ],
            [
              36.395266,
              54.872275
            ],
            [
              36.398125,
              54.883345
            ],
            [
              36.400986,
              54.88604
            ],
            [
              36.408221,
              54.888836
            ],
            [
              36.41494723580885,
              54.89011533444502
            ],
            [
              36.416679,
              54.889975
            ],
            [
              36.416932873238366,
              54.89049300398131
            ],
            [
              36.418763100471196,
              54.890841114396665
            ],
            [
              36.425246,
              54.885253
            ],
            [
              36.433427,
              54.879687
            ],
            [
              36.435688,
              54.882489
            ],
            [
              36.438016,
              54.886659
            ],
            [
              36.43676175689444,
              54.88871907805421
            ],
            [
              36.437002,
              54.890113
            ],
            [
              36.432404,
              54.89663
            ],
            [
              36.435096,
              54.907762
            ],
            [
              36.43570628985256,
              54.90877331202708
            ],
            [
              36.437208,
              54.910544
            ],
            [
              36.440141,
              54.912666
            ],
            [
              36.445017,
              54.915215
            ],
            [
              36.456604,
              54.915752
            ],
            [
              36.46112068389971,
              54.914393326197526
            ],
            [
              36.461771,
              54.913903
            ],
            [
              36.46440777229431,
              54.91314911871173
            ],
            [
              36.465023,
              54.912716
            ],
            [
              36.46733943282565,
              54.91248969767261
            ],
            [
              36.47295068175927,
              54.90954759437767
            ],
            [
              36.477631,
              54.906079
            ],
            [
              36.485448,
              54.898456
            ],
            [
              36.48646,
              54.892318
            ],
            [
              36.487597,
              54.891078
            ],
            [
              36.48867,
              54.890463
            ],
            [
              36.488996,
              54.890298
            ],
            [
              36.489642,
              54.890633
            ],
            [
              36.490041,
              54.891387
            ],
            [
              36.490661,
              54.893055
            ],
            [
              36.49064087366352,
              54.89347351900784
            ],
            [
              36.490787,
              54.893443
            ],
            [
              36.490851,
              54.893751
            ],
            [
              36.490476,
              54.896902
            ],
            [
              36.490413235643004,
              54.89702192864969
            ],
            [
              36.490175,
              54.898802
            ],
            [
              36.49326329768325,
              54.909404658068745
            ],
            [
              36.49467830962749,
              54.90977391247003
            ],
            [
              36.49633,
              54.909265
            ],
            [
              36.502193,
              54.909242
            ],
            [
              36.502195706565615,
              54.90925416223986
            ],
            [
              36.502939,
              54.909082
            ],
            [
              36.512049,
              54.903778
            ],
            [
              36.516932,
              54.896694
            ],
            [
              36.517902,
              54.894814
            ],
            [
              36.51857377495624,
              54.89351269572911
            ],
            [
              36.519137,
              54.892421
            ],
            [
              36.519137726822365,
              54.892421375112136
            ],
            [
              36.519204,
              54.892293
            ],
            [
              36.521209,
              54.888771
            ],
            [
              36.524804,
              54.882839
            ],
            [
              36.528531,
              54.872895
            ],
            [
              36.52981431815967,
              54.87236445763691
            ],
            [
              36.528969,
              54.871258
            ],
            [
              36.529827,
              54.86963
            ],
            [
              36.531331,
              54.868476
            ],
            [
              36.532835,
              54.867321
            ],
            [
              36.542074,
              54.863525
            ],
            [
              36.550746,
              54.865016
            ],
            [
              36.562216,
              54.867878
            ],
            [
              36.56231725439318,
              54.867933207559446
            ],
            [
              36.56478245458041,
              54.86741189940369
            ],
            [
              36.570632,
              54.862134
            ],
            [
              36.571893,
              54.858762
            ],
            [
              36.572248,
              54.858326
            ],
            [
              36.57822453902412,
              54.858296117304874
            ],
            [
              36.578426,
              54.857515
            ],
            [
              36.578984,
              54.857296
            ],
            [
              36.58934,
              54.858181
            ],
            [
              36.597995,
              54.854454
            ],
            [
              36.607567,
              54.847982
            ],
            [
              36.60767,
              54.838587
            ],
            [
              36.600151,
              54.836078
            ],
            [
              36.591831,
              54.836673
            ],
            [
              36.59589270380034,
              54.83438728023188
            ],
            [
              36.59782348174612,
              54.83092537817146
            ],
            [
              36.593916,
              54.824161
            ],
            [
              36.58962,
              54.823212
            ],
            [
              36.585382,
              54.82184
            ],
            [
              36.57676420213827,
              54.820687300922955
            ],
            [
              36.573875,
              54.820729
            ],
            [
              36.567557,
              54.82101
            ],
            [
              36.563856,
              54.821125
            ],
            [
              36.557572,
              54.821028
            ],
            [
              36.553889,
              54.820977
            ],
            [
              36.547758,
              54.820832
            ],
            [
              36.54448,
              54.819984
            ],
            [
              36.540624,
              54.818536
            ],
            [
              36.54062401524767,
              54.818535959411804
            ],
            [
              36.539298,
              54.818038
            ],
            [
              36.533474,
              54.815861
            ],
            [
              36.526747,
              54.811699
            ],
            [
              36.52648380284053,
              54.811194801512436
            ],
            [
              36.526177,
              54.81099
            ],
            [
              36.522409,
              54.808064
            ],
            [
              36.5183,
              54.80186
            ],
            [
              36.515346,
              54.7989
            ],
            [
              36.517895,
              54.796357
            ],
            [
              36.526066,
              54.797614
            ],
            [
              36.529734,
              54.804513
            ],
            [
              36.531532,
              54.807789
            ],
            [
              36.540564,
              54.814667
            ],
            [
              36.5443532612817,
              54.81596843891936
            ],
            [
              36.553972,
              54.817378
            ],
            [
              36.557622,
              54.817428
            ],
            [
              36.563911,
              54.817525
            ],
            [
              36.567444,
              54.817412
            ],
            [
              36.573715,
              54.817132
            ],
            [
              36.57372090337716,
              54.81713197812336
            ],
            [
              36.576115,
              54.817025
            ],
            [
              36.58001,
              54.816851
            ],
            [
              36.58262,
              54.817099
            ],
            [
              36.586577,
              54.818444
            ],
            [
              36.590547,
              54.819733
            ],
            [
              36.594691,
              54.820646
            ],
            [
              36.598598,
              54.821547
            ],
            [
              36.60151635280919,
              54.822193908220584
            ],
            [
              36.601517,
              54.822191
            ],
            [
              36.6053469594303,
              54.821893378639615
            ],
            [
              36.610961,
              54.81616
            ],
            [
              36.609892770343194,
              54.8107878740552
            ],
            [
              36.60989155133952,
              54.810783031036095
            ],
            [
              36.606623,
              54.803724
            ],
            [
              36.60662459938245,
              54.803038436135424
            ],
            [
              36.605828,
              54.801293
            ],
            [
              36.606637,
              54.797723
            ],
            [
              36.602007,
              54.787675
            ],
            [
              36.593803,
              54.785602
            ],
            [
              36.583589,
              54.784306
            ],
            [
              36.577167,
              54.785332
            ],
            [
              36.57712862929368,
              54.78533845410181
            ],
            [
              36.575867150094744,
              54.78555502528
            ],
            [
              36.573976,
              54.78588
            ],
            [
              36.57397412126633,
              54.785869054602536
            ],
            [
              36.573047,
              54.786025
            ],
            [
              36.56259,
              54.791092
            ],
            [
              36.559031,
              54.788347
            ],
            [
              36.562285,
              54.786882
            ],
            [
              36.565557818133165,
              54.78523133710471
            ],
            [
              36.567432,
              54.784034
            ],
            [
              36.572881,
              54.782429
            ],
            [
              36.57850722202123,
              54.779164945709034
            ],
            [
              36.5815,
              54.777321
            ],
            [
              36.582684,
              54.773922
            ],
            [
              36.583643,
              54.774255
            ],
            [
              36.58436699030586,
              54.77475207118147
            ],
            [
              36.58438,
              54.774739
            ],
            [
              36.587009,
              54.776566
            ],
            [
              36.594798,
              54.782142
            ],
            [
              36.602556,
              54.784117
            ],
            [
              36.606848,
              54.785046
            ],
            [
              36.610411,
              54.786299
            ],
            [
              36.621325,
              54.790439
            ],
            [
              36.623734627593905,
              54.79088294575573
            ],
            [
              36.623833,
              54.790889
            ],
            [
              36.628607,
              54.791178
            ],
            [
              36.629795966317225,
              54.79128807171029
            ],
            [
              36.631764,
              54.791276
            ],
            [
              36.635393,
              54.791732
            ],
            [
              36.639011,
              54.792302
            ],
            [
              36.647018,
              54.792977
            ],
            [
              36.653747,
              54.783719
            ],
            [
              36.655268,
              54.77949
            ],
            [
              36.651331,
              54.77152
            ],
            [
              36.646848,
              54.765905
            ],
            [
              36.64811972334839,
              54.7641871974555
            ],
            [
              36.647602,
              54.763005
            ],
            [
              36.64853647796372,
              54.756376665982714
            ],
            [
              36.647525,
              54.753736
            ],
            [
              36.639408,
              54.746712
            ],
            [
              36.636695,
              54.746777
            ],
            [
              36.636103,
              54.746742
            ],
            [
              36.635712,
              54.745348
            ],
            [
              36.63360467093836,
              54.737173224934004
            ],
            [
              36.63299057255913,
              54.73668559806182
            ],
            [
              36.622788,
              54.734529
            ],
            [
              36.620725,
              54.735389
            ],
            [
              36.617858,
              54.73652
            ],
            [
              36.61785632855391,
              54.73651576701853
            ],
            [
              36.617471,
              54.736671
            ],
            [
              36.616809,
              54.736896
            ],
            [
              36.615653,
              54.733486
            ],
            [
              36.616315,
              54.733262
            ],
            [
              36.6165362371918,
              54.73317260069451
            ],
            [
              36.616536,
              54.733172
            ],
            [
              36.616612438169625,
              54.73314180878995
            ],
            [
              36.619424237366815,
              54.732005594334474
            ],
            [
              36.62224019750049,
              54.72764873934902
            ],
            [
              36.623676,
              54.721548
            ],
            [
              36.62368469518686,
              54.72153448159701
            ],
            [
              36.614396,
              54.715198
            ],
            [
              36.609818,
              54.714087
            ],
            [
              36.604773,
              54.714081
            ],
            [
              36.601329,
              54.713426
            ],
            [
              36.590728,
              54.715812
            ],
            [
              36.587701,
              54.717972
            ],
            [
              36.587194,
              54.718334
            ],
            [
              36.58713350702519,
              54.71837198958818
            ],
            [
              36.584496,
              54.720231
            ],
            [
              36.579727,
              54.72079
            ],
            [
              36.57686,
              54.717948
            ],
            [
              36.576639,
              54.713343
            ],
            [
              36.571028,
              54.704808
            ],
            [
              36.568263,
              54.704463
            ],
            [
              36.566634,
              54.704109
            ],
            [
              36.56704002293223,
              54.70223693096252
            ],
            [
              36.56523,
              54.70107
            ],
            [
              36.562343,
              54.696325
            ],
            [
              36.562316,
              54.696259
            ],
            [
              36.564965,
              54.694029
            ],
            [
              36.565284,
              54.694063
            ],
            [
              36.573292,
              54.696849
            ],
            [
              36.57791925502386,
              54.701995171479645
            ],
            [
              36.580099,
              54.702293
            ],
            [
              36.5801824390544,
              54.70234894119794
            ],
            [
              36.580201,
              54.702309
            ],
            [
              36.581297,
              54.70249
            ],
            [
              36.583276,
              54.704423
            ],
            [
              36.592538,
              54.710295
            ],
            [
              36.595961,
              54.708384
            ],
            [
              36.60138781763987,
              54.70735111216199
            ],
            [
              36.60691708822577,
              54.705346970255036
            ],
            [
              36.609908,
              54.699208
            ],
            [
              36.613335,
              54.697128
            ],
            [
              36.616843,
              54.696905
            ],
            [
              36.627244598888915,
              54.69661396254546
            ],
            [
              36.62911978411745,
              54.696218689959544
            ],
            [
              36.630024,
              54.696025
            ],
            [
              36.63002463393422,
              54.69602795556088
            ],
            [
              36.630162,
              54.695999
            ],
            [
              36.631441,
              54.695873
            ],
            [
              36.632211,
              54.695895
            ],
            [
              36.632109,
              54.699494
            ],
            [
              36.628034,
              54.700133
            ],
            [
              36.62383,
              54.701006
            ],
            [
              36.619735,
              54.701327
            ],
            [
              36.619905,
              54.7132
            ],
            [
              36.624148,
              54.716253
            ],
            [
              36.629739,
              54.720682
            ],
            [
              36.632671,
              54.723602
            ],
            [
              36.643129,
              54.725367
            ],
            [
              36.651154,
              54.71663
            ],
            [
              36.65144845636185,
              54.71654022671895
            ],
            [
              36.649985,
              54.710494
            ],
            [
              36.651225,
              54.707589
            ],
            [
              36.651506,
              54.70764
            ],
            [
              36.652393,
              54.707817
            ],
            [
              36.652848,
              54.708146
            ],
            [
              36.65333346247341,
              54.70829180893045
            ],
            [
              36.654707,
              54.706985
            ],
            [
              36.65493322719807,
              54.70695165757349
            ],
            [
              36.656421,
              54.705625
            ],
            [
              36.663677,
              54.696228
            ],
            [
              36.667235,
              54.691251
            ],
            [
              36.669993,
              54.688346
            ],
            [
              36.671484,
              54.687701
            ],
            [
              36.672189,
              54.687552
            ],
            [
              36.67237712263048,
              54.68844640759402
            ],
            [
              36.67561,
              54.688754
            ],
            [
              36.675568,
              54.689202
            ],
            [
              36.67288211367317,
              54.690847329919805
            ],
            [
              36.67293,
              54.691075
            ],
            [
              36.667479,
              54.699177
            ],
            [
              36.665477,
              54.703465
            ],
            [
              36.666464,
              54.711551
            ],
            [
              36.675791,
              54.704372
            ],
            [
              36.675248,
              54.703209
            ],
            [
              36.67851,
              54.701687
            ],
            [
              36.679053,
              54.702849
            ],
            [
              36.682425,
              54.706161
            ],
            [
              36.692072,
              54.700761
            ],
            [
              36.689909,
              54.696179
            ],
            [
              36.68776,
              54.693058
            ],
            [
              36.684979,
              54.690594
            ],
            [
              36.680661,
              54.68862
            ],
            [
              36.677067,
              54.687743
            ],
            [
              36.670203,
              54.68554
            ],
            [
              36.663707,
              54.68418
            ],
            [
              36.66301055737705,
              54.68415157377049
            ],
            [
              36.662484,
              54.684166
            ],
            [
              36.66243653733739,
              54.6823679144976
            ],
            [
              36.660687,
              54.681921
            ],
            [
              36.662281,
              54.673911
            ],
            [
              36.661723,
              54.664101
            ],
            [
              36.654034,
              54.657264
            ],
            [
              36.653835,
              54.65367
            ],
            [
              36.656740255073245,
              54.64950146115866
            ],
            [
              36.656516049234895,
              54.64241955516402
            ],
            [
              36.655242,
              54.639957
            ],
            [
              36.65524209439104,
              54.63995695118112
            ],
            [
              36.654989,
              54.639468
            ],
            [
              36.658187,
              54.637814
            ],
            [
              36.658439963629014,
              54.63830301881101
            ],
            [
              36.65844,
              54.638303
            ],
            [
              36.65984,
              54.641009
            ],
            [
              36.661239,
              54.643714
            ],
            [
              36.661697,
              54.646471
            ],
            [
              36.66255,
              54.658309
            ],
            [
              36.664731,
              54.662123
            ],
            [
              36.666998,
              54.665768
            ],
            [
              36.673825,
              54.670658
            ],
            [
              36.67701360606155,
              54.671697156814155
            ],
            [
              36.685564,
              54.672665
            ],
            [
              36.688647,
              54.670882
            ],
            [
              36.68894620718778,
              54.67139955205567
            ],
            [
              36.692668,
              54.670759
            ],
            [
              36.700113,
              54.668277
            ],
            [
              36.708141,
              54.668469
            ],
            [
              36.713884,
              54.666847
            ],
            [
              36.71777,
              54.665393
            ],
            [
              36.722409,
              54.663689
            ],
            [
              36.728139,
              54.662482
            ],
            [
              36.736308,
              54.657699
            ],
            [
              36.739172,
              54.65988
            ],
            [
              36.738724,
              54.660469
            ],
            [
              36.731447,
              54.665577
            ],
            [
              36.725462,
              54.666155
            ],
            [
              36.72354672472141,
              54.66704810465362
            ],
            [
              36.723574,
              54.66711
            ],
            [
              36.720654,
              54.668397
            ],
            [
              36.716661,
              54.669253
            ],
            [
              36.712473,
              54.671043
            ],
            [
              36.709063,
              54.671949
            ],
            [
              36.705068,
              54.671995
            ],
            [
              36.700333,
              54.672666
            ],
            [
              36.697939,
              54.683881
            ],
            [
              36.701485,
              54.685442
            ],
            [
              36.712592,
              54.688623
            ],
            [
              36.71259557200128,
              54.68862353255292
            ],
            [
              36.712623,
              54.688626
            ],
            [
              36.714923,
              54.688833
            ],
            [
              36.71525211200727,
              54.68890394439596
            ],
            [
              36.715255,
              54.688891
            ],
            [
              36.717042,
              54.68929
            ],
            [
              36.71883,
              54.689688
            ],
            [
              36.72053,
              54.690356
            ],
            [
              36.725168,
              54.692956
            ],
            [
              36.734894,
              54.693029
            ],
            [
              36.74467,
              54.691356
            ],
            [
              36.75038,
              54.682238
            ],
            [
              36.76033,
              54.681194
            ],
            [
              36.762398,
              54.678153
            ],
            [
              36.766187,
              54.675058
            ],
            [
              36.769485,
              54.671902
            ],
            [
              36.777722,
              54.667889
            ],
            [
              36.781792,
              54.667439
            ],
            [
              36.77938,
              54.671085
            ],
            [
              36.7789638449677,
              54.671397665084555
            ],
            [
              36.778976,
              54.671414
            ],
            [
              36.776239,
              54.681517
            ],
            [
              36.783897500250276,
              54.68866607195418
            ],
            [
              36.792402,
              54.689739
            ],
            [
              36.799382,
              54.687185
            ],
            [
              36.80373847401007,
              54.68497686207102
            ],
            [
              36.809268,
              54.681196
            ],
            [
              36.814317748359905,
              54.68125344590355
            ],
            [
              36.815985,
              54.680922
            ],
            [
              36.819891,
              54.680308
            ],
            [
              36.823399,
              54.680068
            ],
            [
              36.827143,
              54.679271
            ],
            [
              36.827307170420504,
              54.67928987993854
            ],
            [
              36.827387,
              54.679263
            ],
            [
              36.835574,
              54.677848
            ],
            [
              36.840936,
              54.672105
            ],
            [
              36.843343,
              54.668953
            ],
            [
              36.846576,
              54.670537
            ],
            [
              36.857502,
              54.670302
            ],
            [
              36.858078,
              54.666587
            ],
            [
              36.859562,
              54.659752
            ],
            [
              36.859761,
              54.655129
            ],
            [
              36.86015887024689,
              54.65523976443161
            ],
            [
              36.860501,
              54.65211
            ],
            [
              36.860829,
              54.648277
            ],
            [
              36.860933,
              54.64709
            ],
            [
              36.864519,
              54.647406
            ],
            [
              36.864415,
              54.648593
            ],
            [
              36.8654919441435,
              54.649356981140464
            ],
            [
              36.875551,
              54.646557
            ],
            [
              36.877258,
              54.644692
            ],
            [
              36.879914,
              54.647122
            ],
            [
              36.879826,
              54.656945
            ],
            [
              36.88627,
              54.65818
            ],
            [
              36.892936,
              54.668024
            ],
            [
              36.894184,
              54.66767
            ],
            [
              36.8951218632952,
              54.67066488687947
            ],
            [
              36.895434,
              54.671042
            ],
            [
              36.898326,
              54.682049
            ],
            [
              36.901846,
              54.68473
            ],
            [
              36.901932,
              54.68435
            ],
            [
              36.905444,
              54.685141
            ],
            [
              36.911144,
              54.690283
            ],
            [
              36.914879,
              54.693623
            ],
            [
              36.915918,
              54.694862
            ],
            [
              36.915672,
              54.695681
            ],
            [
              36.915814158408274,
              54.702343430369886
            ],
            [
              36.924885737247145,
              54.70686892566009
            ],
            [
              36.92563955261649,
              54.70687497554267
            ],
            [
              36.928378516595025,
              54.70680091242057
            ],
            [
              36.937238,
              54.704563
            ],
            [
              36.9483,
              54.701658
            ],
            [
              36.9503125621916,
              54.701355424508975
            ],
            [
              36.951042,
              54.701067
            ],
            [
              36.954892,
              54.700613
            ],
            [
              36.956812,
              54.703787
            ],
            [
              36.952755,
              54.704629
            ],
            [
              36.949081,
              54.705148
            ],
            [
              36.945975,
              54.706779
            ],
            [
              36.945361006235096,
              54.70671928115385
            ],
            [
              36.946031,
              54.707476
            ],
            [
              36.946021,
              54.708254
            ],
            [
              36.939988,
              54.710714
            ],
            [
              36.937261,
              54.721525
            ],
            [
              36.948328,
              54.724317
            ],
            [
              36.953656,
              54.724449
            ],
            [
              36.958389,
              54.725565
            ],
            [
              36.961921,
              54.727213
            ],
            [
              36.96212893050501,
              54.72737570633915
            ],
            [
              36.96215,
              54.727343
            ],
            [
              36.973563,
              54.728342
            ],
            [
              36.97439510510225,
              54.73140421729366
            ],
            [
              36.97509885915621,
              54.73215535853912
            ],
            [
              36.984844,
              54.737743
            ],
            [
              36.994137,
              54.733554
            ],
            [
              36.998653,
              54.732924
            ],
            [
              37.00433674502752,
              54.73252842004015
            ],
            [
              37.010526,
              54.730816
            ],
            [
              37.019844,
              54.73036
            ],
            [
              37.023663,
              54.728744
            ],
            [
              37.027161,
              54.729738
            ],
            [
              37.027907,
              54.733743
            ],
            [
              37.029181,
              54.738552
            ],
            [
              37.034072,
              54.744829
            ],
            [
              37.037593,
              54.745933
            ],
            [
              37.047917,
              54.742375
            ],
            [
              37.041199,
              54.733963
            ],
            [
              37.04538227563457,
              54.731420943737675
            ],
            [
              37.048311,
              54.728082
            ],
            [
              37.04944463565786,
              54.72759059304907
            ],
            [
              37.049824,
              54.727109
            ],
            [
              37.042221,
              54.717994
            ],
            [
              37.040318610477335,
              54.71828364065934
            ],
            [
              37.039671,
              54.719864
            ],
            [
              37.039550182076994,
              54.719814555678774
            ],
            [
              37.03955,
              54.719815
            ],
            [
              37.037919,
              54.719147
            ],
            [
              37.036579,
              54.718853
            ],
            [
              37.032285,
              54.717443
            ],
            [
              37.028873,
              54.715301
            ],
            [
              37.02831,
              54.711366
            ],
            [
              37.028916,
              54.706772
            ],
            [
              37.028962,
              54.699494
            ],
            [
              37.033167,
              54.695577
            ],
            [
              37.039366,
              54.694596
            ],
            [
              37.04035244151255,
              54.69499406782758
            ],
            [
              37.04008,
              54.693475
            ],
            [
              37.04089,
              54.69333
            ],
            [
              37.04308,
              54.693198
            ],
            [
              37.043613,
              54.693756
            ],
            [
              37.052315,
              54.699413
            ],
            [
              37.05644,
              54.700088
            ],
            [
              37.059694,
              54.701515
            ],
            [
              37.064871,
              54.702551
            ],
            [
              37.068404,
              54.703817
            ],
            [
              37.077991,
              54.699974
            ],
            [
              37.079673947472756,
              54.69655704305718
            ],
            [
              37.08189668716008,
              54.688063261062446
            ],
            [
              37.08172056688195,
              54.687647259522485
            ],
            [
              37.07564,
              54.681639
            ],
            [
              37.065196,
              54.677636
            ],
            [
              37.06519544703292,
              54.67763559779551
            ],
            [
              37.060617,
              54.676328
            ],
            [
              37.06094971162854,
              54.67516249904213
            ],
            [
              37.053384,
              54.674261
            ],
            [
              37.048904225904366,
              54.67341095710235
            ],
            [
              37.04863038903128,
              54.6733712324389
            ],
            [
              37.042179,
              54.673028
            ],
            [
              37.035596,
              54.672677
            ],
            [
              37.031337,
              54.672153
            ],
            [
              37.026612,
              54.671006
            ],
            [
              37.021989,
              54.669847
            ],
            [
              37.016057,
              54.668528
            ],
            [
              37.010143,
              54.667214
            ],
            [
              37.003639,
              54.665836
            ],
            [
              37.000784,
              54.66527
            ],
            [
              36.99953463523319,
              54.66502187747013
            ],
            [
              36.997986,
              54.664715
            ],
            [
              36.997986129637546,
              54.66471434588599
            ],
            [
              36.997929,
              54.664703
            ],
            [
              36.99792985300875,
              54.664698697180135
            ],
            [
              36.997585,
              54.66463
            ],
            [
              36.994384,
              54.663419
            ],
            [
              36.98897786791818,
              54.66001618094014
            ],
            [
              36.983674,
              54.667282
            ],
            [
              36.980075,
              54.66719
            ],
            [
              36.980139,
              54.664715
            ],
            [
              36.980202,
              54.66224
            ],
            [
              36.98021,
              54.659539
            ],
            [
              36.97587026501261,
              54.649539181779595
            ],
            [
              36.970903,
              54.645107
            ],
            [
              36.964611,
              54.639052
            ],
            [
              36.960631,
              54.636655
            ],
            [
              36.949559,
              54.641171
            ],
            [
              36.942414,
              54.640739
            ],
            [
              36.937592,
              54.642707
            ],
            [
              36.933446,
              54.645417
            ],
            [
              36.93311859190248,
              54.64500959107687
            ],
            [
              36.930766,
              54.645699
            ],
            [
              36.925434,
              54.644867
            ],
            [
              36.91488,
              54.645601
            ],
            [
              36.913019,
              54.646563
            ],
            [
              36.911159,
              54.647524
            ],
            [
              36.911145939149804,
              54.6474987237388
            ],
            [
              36.910622,
              54.647765
            ],
            [
              36.905587,
              54.651225
            ],
            [
              36.908031,
              54.645143
            ],
            [
              36.90951840617837,
              54.644349009294984
            ],
            [
              36.909506,
              54.644325
            ],
            [
              36.903485,
              54.637477
            ],
            [
              36.90048,
              54.635495
            ],
            [
              36.904885,
              54.625094
            ],
            [
              36.908672,
              54.622254
            ],
            [
              36.90912609235617,
              54.622249832485714
            ],
            [
              36.909126,
              54.622234
            ],
            [
              36.91412,
              54.622204
            ],
            [
              36.920985,
              54.622122
            ],
            [
              36.925347,
              54.622122
            ],
            [
              36.925542056348036,
              54.6221157946601
            ],
            [
              36.925542,
              54.622114
            ],
            [
              36.92889901754819,
              54.622008912431724
            ],
            [
              36.93113495986591,
              54.61085120027556
            ],
            [
              36.927811,
              54.609773
            ],
            [
              36.92781138328689,
              54.6097718176808
            ],
            [
              36.927621,
              54.60971
            ],
            [
              36.924353,
              54.608346
            ],
            [
              36.919674,
              54.606015
            ],
            [
              36.915876,
              54.603361
            ],
            [
              36.914059,
              54.601347
            ],
            [
              36.911851,
              54.598135
            ],
            [
              36.91144940948209,
              54.59755053749727
            ],
            [
              36.911449,
              54.59755
            ],
            [
              36.91137703928244,
              54.59744521213195
            ],
            [
              36.91025429952864,
              54.595811211185314
            ],
            [
              36.909527,
              54.594753
            ],
            [
              36.90952783078696,
              54.59475242886897
            ],
            [
              36.908893,
              54.593828
            ],
            [
              36.906217,
              54.590436
            ],
            [
              36.897919,
              54.586842
            ],
            [
              36.89266,
              54.58563
            ],
            [
              36.8874,
              54.584417
            ],
            [
              36.887400095845166,
              54.584416584394496
            ],
            [
              36.885125,
              54.583892
            ],
            [
              36.873303,
              54.58332
            ],
            [
              36.87330269020662,
              54.58331990325719
            ],
            [
              36.873036,
              54.584173
            ],
            [
              36.87241420501386,
              54.58397864382564
            ],
            [
              36.872485,
              54.58597
            ],
            [
              36.869994,
              54.588825
            ],
            [
              36.865361,
              54.591003
            ],
            [
              36.85912,
              54.592343
            ],
            [
              36.859834,
              54.603008
            ],
            [
              36.86328,
              54.606063
            ],
            [
              36.862361,
              54.612265
            ],
            [
              36.861065,
              54.613563
            ],
            [
              36.86088331923655,
              54.6133814618991
            ],
            [
              36.858262,
              54.615362
            ],
            [
              36.857222,
              54.623295
            ],
            [
              36.860528,
              54.629425
            ],
            [
              36.85795,
              54.631937
            ],
            [
              36.855573,
              54.629498
            ],
            [
              36.851006,
              54.621571
            ],
            [
              36.850831,
              54.616556
            ],
            [
              36.841357,
              54.610316
            ],
            [
              36.839183,
              54.611508
            ],
            [
              36.83608,
              54.61272
            ],
            [
              36.836071153354574,
              54.61269735663961
            ],
            [
              36.83583,
              54.612806
            ],
            [
              36.829105,
              54.615757
            ],
            [
              36.823907,
              54.62429
            ],
            [
              36.821869,
              54.627574
            ],
            [
              36.818642,
              54.631475
            ],
            [
              36.814729,
              54.635918
            ],
            [
              36.812347,
              54.637419
            ],
            [
              36.8109882093903,
              54.63827502096369
            ],
            [
              36.809863,
              54.638984
            ],
            [
              36.809804210263614,
              54.638890683930676
            ],
            [
              36.804885,
              54.64186
            ],
            [
              36.799745,
              54.638971
            ],
            [
              36.789217,
              54.637947
            ],
            [
              36.784458,
              54.634978
            ],
            [
              36.780785,
              54.632517
            ],
            [
              36.772988,
              54.626855
            ],
            [
              36.768541,
              54.625472
            ],
            [
              36.764478,
              54.625455
            ],
            [
              36.758784,
              54.634021
            ],
            [
              36.754329,
              54.632679
            ],
            [
              36.743758,
              54.628553
            ],
            [
              36.73923,
              54.629331
            ],
            [
              36.735576,
              54.630089
            ],
            [
              36.730229,
              54.629492
            ],
            [
              36.726757,
              54.628885
            ],
            [
              36.716457,
              54.624539
            ],
            [
              36.711952,
              54.623801
            ],
            [
              36.711456,
              54.62368
            ],
            [
              36.712308,
              54.620182
            ],
            [
              36.712493,
              54.620061
            ],
            [
              36.714338,
              54.620448
            ],
            [
              36.719367,
              54.621398
            ],
            [
              36.72278,
              54.622203
            ],
            [
              36.726631,
              54.623538
            ],
            [
              36.734845,
              54.626564
            ],
            [
              36.7385,
              54.625806
            ],
            [
              36.743147,
              54.625005
            ],
            [
              36.74821,
              54.62515
            ],
            [
              36.755728,
              54.621986
            ],
            [
              36.760756,
              54.62187
            ],
            [
              36.764492,
              54.621855
            ],
            [
              36.768556,
              54.621872
            ],
            [
              36.773423,
              54.622491
            ],
            [
              36.77424308286866,
              54.62302534961228
            ],
            [
              36.774264,
              54.623005
            ],
            [
              36.778328,
              54.625687
            ],
            [
              36.782464,
              54.627691
            ],
            [
              36.789098,
              54.633434
            ],
            [
              36.794336,
              54.634163
            ],
            [
              36.799409,
              54.634643
            ],
            [
              36.802706,
              54.636924
            ],
            [
              36.81018113169437,
              54.633926253204116
            ],
            [
              36.815438,
              54.629199
            ],
            [
              36.816332293104914,
              54.627660221162415
            ],
            [
              36.816277,
              54.627595
            ],
            [
              36.820611,
              54.620298
            ],
            [
              36.823302,
              54.615185
            ],
            [
              36.827217,
              54.612692
            ],
            [
              36.830746,
              54.610518
            ],
            [
              36.83477913134312,
              54.60936262534946
            ],
            [
              36.838164,
              54.607741
            ],
            [
              36.828505,
              54.596714
            ],
            [
              36.82295,
              54.598692
            ],
            [
              36.820952,
              54.599119
            ],
            [
              36.818955,
              54.599547
            ],
            [
              36.818201,
              54.596026
            ],
            [
              36.820661,
              54.594389
            ],
            [
              36.823981,
              54.592996
            ],
            [
              36.828303,
              54.593119
            ],
            [
              36.83325,
              54.594592
            ],
            [
              36.839387,
              54.593439
            ],
            [
              36.845467,
              54.593329
            ],
            [
              36.85157,
              54.593219
            ],
            [
              36.860688,
              54.589055
            ],
            [
              36.860839,
              54.578107
            ],
            [
              36.856282,
              54.576406
            ],
            [
              36.852276,
              54.574813
            ],
            [
              36.846216,
              54.57448
            ],
            [
              36.841358,
              54.573387
            ],
            [
              36.837636,
              54.572637
            ],
            [
              36.833458,
              54.572483
            ],
            [
              36.827168,
              54.570531
            ],
            [
              36.820095,
              54.56983
            ],
            [
              36.815783,
              54.56734
            ],
            [
              36.81509005424593,
              54.56694062941211
            ],
            [
              36.815082,
              54.566939
            ],
            [
              36.812929,
              54.565697
            ],
            [
              36.81092235499085,
              54.56453903354393
            ],
            [
              36.803443,
              54.564928
            ],
            [
              36.799701,
              54.565036
            ],
            [
              36.792246,
              54.567408
            ],
            [
              36.791781,
              54.567567
            ],
            [
              36.788843,
              54.564059
            ],
            [
              36.787963,
              54.56308
            ],
            [
              36.78453701647664,
              54.554290606660686
            ],
            [
              36.779688,
              54.553312
            ],
            [
              36.771059151316734,
              54.55219850272208
            ],
            [
              36.77105,
              54.552199
            ],
            [
              36.769852594826524,
              54.5520442112101
            ],
            [
              36.765238,
              54.552976
            ],
            [
              36.760381,
              54.550782
            ],
            [
              36.76038100937182,
              54.550781931637495
            ],
            [
              36.760323,
              54.550774
            ],
            [
              36.755362,
              54.550094
            ],
            [
              36.743627,
              54.551163
            ],
            [
              36.740859,
              54.554502
            ],
            [
              36.740279,
              54.563941
            ],
            [
              36.746643,
              54.568227
            ],
            [
              36.740719,
              54.570309
            ],
            [
              36.735492,
              54.565792
            ],
            [
              36.726588,
              54.573303
            ],
            [
              36.726568,
              54.57684
            ],
            [
              36.716193,
              54.57708
            ],
            [
              36.713812,
              54.580305
            ],
            [
              36.711713,
              54.58397
            ],
            [
              36.706626,
              54.587691
            ],
            [
              36.703327,
              54.589469
            ],
            [
              36.698462,
              54.593021
            ],
            [
              36.695175,
              54.595571
            ],
            [
              36.692888,
              54.597428
            ],
            [
              36.6906,
              54.599285
            ],
            [
              36.688331,
              54.596489
            ],
            [
              36.690619,
              54.594633
            ],
            [
              36.692907,
              54.592776
            ],
            [
              36.696256,
              54.590177
            ],
            [
              36.701732,
              54.586241
            ],
            [
              36.704916,
              54.584522
            ],
            [
              36.708695,
              54.582007
            ],
            [
              36.706037,
              54.571404
            ],
            [
              36.699205,
              54.572
            ],
            [
              36.692361,
              54.572597
            ],
            [
              36.68711,
              54.573023
            ],
            [
              36.681838,
              54.573451
            ],
            [
              36.678086,
              54.573713
            ],
            [
              36.674367,
              54.573973
            ],
            [
              36.657977,
              54.575427
            ],
            [
              36.657658,
              54.571841
            ],
            [
              36.665854,
              54.571114
            ],
            [
              36.672596,
              54.561482
            ],
            [
              36.671781,
              54.558144
            ],
            [
              36.672909,
              54.554547
            ],
            [
              36.673389,
              54.547204
            ],
            [
              36.674949,
              54.543798
            ],
            [
              36.666998,
              54.534961
            ],
            [
              36.661412,
              54.536091
            ],
            [
              36.657589,
              54.53696
            ],
            [
              36.647959,
              54.540739
            ],
            [
              36.645011,
              54.539044
            ],
            [
              36.644946,
              54.538769
            ],
            [
              36.645978,
              54.535577
            ],
            [
              36.656522,
              54.533522
            ],
            [
              36.66061,
              54.532582
            ],
            [
              36.667636,
              54.531343
            ],
            [
              36.67146,
              54.531556
            ],
            [
              36.677596,
              54.532145
            ],
            [
              36.681669,
              54.533089
            ],
            [
              36.689456,
              54.534207
            ],
            [
              36.694697,
              54.533963
            ],
            [
              36.698224,
              54.533841
            ],
            [
              36.70514,
              54.532671
            ],
            [
              36.710791,
              54.532058
            ],
            [
              36.71939,
              54.538395
            ],
            [
              36.724668,
              54.5381
            ],
            [
              36.730638,
              54.531066
            ],
            [
              36.731232,
              54.531134
            ],
            [
              36.735208,
              54.532404
            ],
            [
              36.73687,
              54.538191
            ],
            [
              36.747682,
              54.536666
            ],
            [
              36.750036,
              54.533069
            ],
            [
              36.747459,
              54.521971
            ],
            [
              36.743078,
              54.521617
            ],
            [
              36.741967,
              54.517546
            ],
            [
              36.742709,
              54.513777
            ],
            [
              36.740493,
              54.504549
            ],
            [
              36.73081,
              54.49762
            ],
            [
              36.724727,
              54.495661
            ],
            [
              36.725831,
              54.492234
            ],
            [
              36.731914,
              54.494193
            ],
            [
              36.737997,
              54.496151
            ],
            [
              36.746094,
              54.487854
            ],
            [
              36.745695,
              54.486541
            ],
            [
              36.745668,
              54.485856
            ],
            [
              36.74741,
              54.484503
            ],
            [
              36.748151,
              54.484794
            ],
            [
              36.749265,
              54.485714
            ],
            [
              36.749292,
              54.486399
            ],
            [
              36.749459,
              54.489414
            ],
            [
              36.753527,
              54.498702
            ],
            [
              36.75761,
              54.502043
            ],
            [
              36.760264,
              54.504923
            ],
            [
              36.771412,
              54.508964
            ],
            [
              36.782231,
              54.506371
            ],
            [
              36.786277,
              54.502151
            ],
            [
              36.785756,
              54.490848
            ],
            [
              36.784259,
              54.489886
            ],
            [
              36.783094,
              54.489135
            ],
            [
              36.785045,
              54.48611
            ],
            [
              36.795515,
              54.485408
            ],
            [
              36.798561,
              54.487187
            ],
            [
              36.807403,
              54.482317
            ],
            [
              36.808157,
              54.480417
            ],
            [
              36.809377,
              54.479307
            ],
            [
              36.816414,
              54.477959
            ],
            [
              36.818931,
              54.480532
            ],
            [
              36.817056,
              54.482366
            ],
            [
              36.815182,
              54.4842
            ],
            [
              36.814304,
              54.485064
            ],
            [
              36.811307,
              54.489305
            ],
            [
              36.802885,
              54.491578
            ],
            [
              36.798358,
              54.494932
            ],
            [
              36.7946,
              54.496285
            ],
            [
              36.789403,
              54.503938
            ],
            [
              36.784569,
              54.509109
            ],
            [
              36.787387,
              54.518627
            ],
            [
              36.794996,
              54.51875
            ],
            [
              36.804232,
              54.514982
            ],
            [
              36.806647,
              54.517652
            ],
            [
              36.804005,
              54.520041
            ],
            [
              36.800612,
              54.530524
            ],
            [
              36.791916,
              54.536696
            ],
            [
              36.790779,
              54.540897
            ],
            [
              36.790748346855615,
              54.540966744304164
            ],
            [
              36.790795,
              54.541016
            ],
            [
              36.790668,
              54.541435
            ],
            [
              36.79016822277355,
              54.54247895007849
            ],
            [
              36.790454,
              54.542641
            ],
            [
              36.789896,
              54.543624
            ],
            [
              36.796772,
              54.553206
            ],
            [
              36.800177,
              54.554189
            ],
            [
              36.803384,
              54.555959
            ],
            [
              36.808575,
              54.559028
            ],
            [
              36.81257492439847,
              54.56133613095866
            ],
            [
              36.812575,
              54.561336
            ],
            [
              36.820987,
              54.561235
            ],
            [
              36.82952577192653,
              54.560419397795016
            ],
            [
              36.832525,
              54.554539
            ],
            [
              36.834376,
              54.550995
            ],
            [
              36.838212,
              54.547745
            ],
            [
              36.841332,
              54.536259
            ],
            [
              36.837925,
              54.534107
            ],
            [
              36.833636,
              54.526221
            ],
            [
              36.833061,
              54.525219
            ],
            [
              36.836182,
              54.523426
            ],
            [
              36.839736,
              54.527095
            ],
            [
              36.846452,
              54.533444
            ],
            [
              36.847207013053136,
              54.53509460796383
            ],
            [
              36.847472,
              54.535018
            ],
            [
              36.84798636958666,
              54.53679843559375
            ],
            [
              36.847988,
              54.536802
            ],
            [
              36.84798775150859,
              54.536803218969496
            ],
            [
              36.848003,
              54.536856
            ],
            [
              36.848535,
              54.538695
            ],
            [
              36.847114,
              54.546583
            ],
            [
              36.844139,
              54.548544
            ],
            [
              36.836932,
              54.554788
            ],
            [
              36.83576,
              54.558421
            ],
            [
              36.83766022035019,
              54.56554350424966
            ],
            [
              36.842131,
              54.569871
            ],
            [
              36.848893,
              54.570951
            ],
            [
              36.853311,
              54.571365
            ],
            [
              36.857607,
              54.573059
            ],
            [
              36.867466,
              54.576034
            ],
            [
              36.86804098098095,
              54.576175803764116
            ],
            [
              36.868955,
              54.576401
            ],
            [
              36.873167,
              54.57744
            ],
            [
              36.885934,
              54.580384
            ],
            [
              36.89278426620496,
              54.58196409465905
            ],
            [
              36.893469,
              54.582122
            ],
            [
              36.898728,
              54.583335
            ],
            [
              36.907938,
              54.582587
            ],
            [
              36.91358,
              54.572738
            ],
            [
              36.913756965472786,
              54.5726817795496
            ],
            [
              36.91387680855603,
              54.57189915982966
            ],
            [
              36.913789,
              54.570495
            ],
            [
              36.91406287198369,
              54.5706840967347
            ],
            [
              36.914335,
              54.568907
            ],
            [
              36.914795,
              54.568833
            ],
            [
              36.91512,
              54.568811
            ],
            [
              36.915597,
              54.568822
            ],
            [
              36.92660447367492,
              54.56840935914289
            ],
            [
              36.927799,
              54.567808
            ],
            [
              36.930868,
              54.565924
            ],
            [
              36.932593,
              54.554283
            ],
            [
              36.925306,
              54.546296
            ],
            [
              36.923011,
              54.545545
            ],
            [
              36.92306955730056,
              54.545366138816796
            ],
            [
              36.922313,
              54.545189
            ],
            [
              36.912451,
              54.542946
            ],
            [
              36.907651,
              54.54376
            ],
            [
              36.902829,
              54.541467
            ],
            [
              36.899698,
              54.539865
            ],
            [
              36.897986,
              54.537901
            ],
            [
              36.90052573363532,
              54.53207008733217
            ],
            [
              36.899804,
              54.531808
            ],
            [
              36.891865,
              54.530304
            ],
            [
              36.88508548252144,
              54.52353034546536
            ],
            [
              36.880598,
              54.52014
            ],
            [
              36.872473,
              54.525084
            ],
            [
              36.870978,
              54.52772
            ],
            [
              36.868723,
              54.526927
            ],
            [
              36.868362,
              54.523503
            ],
            [
              36.867887,
              54.518486
            ],
            [
              36.867537,
              54.50916
            ],
            [
              36.86322,
              54.506701
            ],
            [
              36.858383,
              54.506282
            ],
            [
              36.854373,
              54.506437
            ],
            [
              36.852695,
              54.506252
            ],
            [
              36.85038,
              54.505908
            ],
            [
              36.850380364376385,
              54.50590554717525
            ],
            [
              36.850226,
              54.505885
            ],
            [
              36.840375,
              54.505005
            ],
            [
              36.836381,
              54.505861
            ],
            [
              36.834969,
              54.50255
            ],
            [
              36.839593,
              54.501491
            ],
            [
              36.847374,
              54.499652
            ],
            [
              36.858125,
              54.494695
            ],
            [
              36.862978,
              54.49372
            ],
            [
              36.86796,
              54.49464
            ],
            [
              36.879531,
              54.495738
            ],
            [
              36.883915,
              54.495666
            ],
            [
              36.893588,
              54.493886
            ],
            [
              36.896324,
              54.491546
            ],
            [
              36.902581,
              54.493287
            ],
            [
              36.903091,
              54.496851
            ],
            [
              36.903204,
              54.497341
            ],
            [
              36.902373,
              54.497482
            ],
            [
              36.897547,
              54.498274
            ],
            [
              36.89134,
              54.501544
            ],
            [
              36.889394,
              54.503363
            ],
            [
              36.887823,
              54.504132
            ],
            [
              36.879638,
              54.499336
            ],
            [
              36.875798,
              54.500352
            ],
            [
              36.87320555868048,
              54.508834802395945
            ],
            [
              36.874049,
              54.509846
            ],
            [
              36.882752,
              54.517255
            ],
            [
              36.889626,
              54.522388
            ],
            [
              36.900165,
              54.527505
            ],
            [
              36.903244,
              54.529612
            ],
            [
              36.904089,
              54.529934
            ],
            [
              36.908503,
              54.533
            ],
            [
              36.912499,
              54.536216
            ],
            [
              36.923804,
              54.532267
            ],
            [
              36.925191,
              54.52942
            ],
            [
              36.926125,
              54.528011
            ],
            [
              36.926256500998186,
              54.528098272829126
            ],
            [
              36.928229,
              54.525237
            ],
            [
              36.929731,
              54.520931
            ],
            [
              36.930447,
              54.517436
            ],
            [
              36.940098,
              54.51177
            ],
            [
              36.945856,
              54.506632
            ],
            [
              36.947712,
              54.505383
            ],
            [
              36.948635,
              54.504931
            ],
            [
              36.9486909765965,
              54.50494727264223
            ],
            [
              36.94889,
              54.504829
            ],
            [
              36.957135,
              54.499422
            ],
            [
              36.950163,
              54.49231
            ],
            [
              36.943762,
              54.492944
            ],
            [
              36.93725,
              54.497653
            ],
            [
              36.934674,
              54.498225
            ],
            [
              36.933894,
              54.494711
            ],
            [
              36.934596,
              54.490182
            ],
            [
              36.935966,
              54.489495
            ],
            [
              36.943861,
              54.489345
            ],
            [
              36.95138,
              54.480566
            ],
            [
              36.946997,
              54.470059
            ],
            [
              36.947492,
              54.466493
            ],
            [
              36.948993,
              54.466626
            ],
            [
              36.952781,
              54.466292
            ],
            [
              36.959613,
              54.459407
            ],
            [
              36.96226,
              54.456966
            ],
            [
              36.967841,
              54.456928
            ],
            [
              36.978616,
              54.456037
            ],
            [
              36.981421,
              54.45588
            ],
            [
              36.987759,
              54.456267
            ],
            [
              36.995692,
              54.45937
            ],
            [
              37.000912,
              54.460214
            ],
            [
              37.004476,
              54.460821
            ],
            [
              37.011968,
              54.459923
            ],
            [
              37.016162,
              54.459133
            ],
            [
              37.019602,
              54.448495
            ],
            [
              37.023026,
              54.449607
            ],
            [
              37.025358,
              54.452377
            ],
            [
              37.035284,
              54.457301
            ],
            [
              37.039973,
              54.457555
            ],
            [
              37.0430759145976,
              54.4583963900706
            ],
            [
              37.043088,
              54.458356
            ],
            [
              37.04548417354513,
              54.458307185470225
            ],
            [
              37.051923,
              54.454147
            ],
            [
              37.053393,
              54.457433
            ],
            [
              37.059924,
              54.462996
            ],
            [
              37.063884,
              54.463416
            ],
            [
              37.068164,
              54.464073
            ],
            [
              37.073502,
              54.46516
            ],
            [
              37.077642,
              54.465051
            ],
            [
              37.087081,
              54.464658
            ],
            [
              37.089344,
              54.453786
            ],
            [
              37.085085,
              54.449144
            ],
            [
              37.08096874712825,
              54.44101251829087
            ],
            [
              37.076424,
              54.438822
            ],
            [
              37.073369,
              54.436958
            ],
            [
              37.069604,
              54.433525
            ],
            [
              37.060678,
              54.435709
            ],
            [
              37.055161,
              54.433999
            ],
            [
              37.051746,
              54.432906
            ],
            [
              37.042108,
              54.426424
            ],
            [
              37.039343,
              54.42382
            ],
            [
              37.039101,
              54.423151
            ],
            [
              37.042486,
              54.421927
            ],
            [
              37.043877,
              54.423288
            ],
            [
              37.049455,
              54.427989
            ],
            [
              37.059158,
              54.427465
            ],
            [
              37.063613,
              54.426169
            ],
            [
              37.071017,
              54.429736
            ],
            [
              37.073993,
              54.432319
            ],
            [
              37.080214,
              54.434947
            ],
            [
              37.091895,
              54.434036
            ],
            [
              37.096276,
              54.43069
            ],
            [
              37.100096,
              54.432297
            ],
            [
              37.110275,
              54.435778
            ],
            [
              37.113074,
              54.431916
            ],
            [
              37.115885,
              54.428037
            ],
            [
              37.118541,
              54.424465
            ],
            [
              37.113171,
              54.415573
            ],
            [
              37.1084,
              54.415143
            ],
            [
              37.104187,
              54.414926
            ],
            [
              37.098808,
              54.414438
            ],
            [
              37.095821,
              54.412804
            ],
            [
              37.093295,
              54.408828
            ],
            [
              37.096334,
              54.406898
            ],
            [
              37.104499,
              54.411339
            ],
            [
              37.109964,
              54.411651
            ],
            [
              37.113514,
              54.41199
            ],
            [
              37.117027,
              54.412326
            ],
            [
              37.120649,
              54.412905
            ],
            [
              37.129189,
              54.409872
            ],
            [
              37.132439,
              54.399602
            ],
            [
              37.132567,
              54.396678
            ],
            [
              37.136163,
              54.396836
            ],
            [
              37.13927,
              54.39975
            ],
            [
              37.141057,
              54.40291
            ],
            [
              37.142018,
              54.40638
            ],
            [
              37.145904,
              54.416545
            ],
            [
              37.156266,
              54.414954
            ],
            [
              37.160267,
              54.409482
            ],
            [
              37.161326,
              54.408329
            ],
            [
              37.162408,
              54.407459
            ],
            [
              37.173664,
              54.405871
            ],
            [
              37.177113,
              54.40484
            ],
            [
              37.185262,
              54.406662
            ],
            [
              37.193925,
              54.407107
            ],
            [
              37.197693,
              54.407504
            ],
            [
              37.207127,
              54.405275
            ],
            [
              37.208314,
              54.408673
            ],
            [
              37.219612,
              54.409071
            ],
            [
              37.22104,
              54.40571
            ],
            [
              37.223646,
              54.398361
            ],
            [
              37.222324,
              54.392267
            ],
            [
              37.222041,
              54.391742
            ],
            [
              37.224411,
              54.389031
            ],
            [
              37.224709,
              54.389292
            ],
            [
              37.225541,
              54.390652
            ],
            [
              37.23532,
              54.397283
            ],
            [
              37.240227,
              54.396237
            ],
            [
              37.24466,
              54.392967
            ],
            [
              37.247637,
              54.394991
            ],
            [
              37.246603,
              54.396513
            ],
            [
              37.245568,
              54.398035
            ],
            [
              37.246633,
              54.408127
            ],
            [
              37.255035,
              54.409582
            ],
            [
              37.263438,
              54.411038
            ],
            [
              37.26488011530012,
              54.41161574546439
            ],
            [
              37.264893,
              54.411584
            ],
            [
              37.26695399579133,
              54.4124202651397
            ],
            [
              37.27263924430239,
              54.414726675834714
            ],
            [
              37.278962,
              54.406337
            ],
            [
              37.280131,
              54.402932
            ],
            [
              37.280658915940606,
              54.40311324484365
            ],
            [
              37.280659,
              54.403113
            ],
            [
              37.28685095068539,
              54.40523848027872
            ],
            [
              37.294675,
              54.407517
            ],
            [
              37.29698,
              54.411393
            ],
            [
              37.29999695146657,
              54.41712842110756
            ],
            [
              37.300704,
              54.41321
            ],
            [
              37.308906,
              54.404878
            ],
            [
              37.309063820394066,
              54.40488801476477
            ],
            [
              37.309268,
              54.403166
            ],
            [
              37.31666,
              54.394013
            ],
            [
              37.311744,
              54.38649
            ],
            [
              37.305239,
              54.386284
            ],
            [
              37.297983,
              54.385736
            ],
            [
              37.294132,
              54.386299
            ],
            [
              37.289982,
              54.387428
            ],
            [
              37.28856,
              54.384121
            ],
            [
              37.292488,
              54.382765
            ],
            [
              37.296786,
              54.381762
            ],
            [
              37.302149,
              54.3825
            ],
            [
              37.308629,
              54.38279
            ],
            [
              37.31213105720255,
              54.38295361380266
            ],
            [
              37.312134,
              54.382909
            ],
            [
              37.31445404025234,
              54.38306207482077
            ],
            [
              37.314566651623764,
              54.383064846192475
            ],
            [
              37.32194944044287,
              54.37905698006217
            ],
            [
              37.324133,
              54.373818
            ],
            [
              37.326381,
              54.371029
            ],
            [
              37.331817,
              54.366118
            ],
            [
              37.335466,
              54.363305
            ],
            [
              37.340972,
              54.360367
            ],
            [
              37.346273,
              54.357557
            ],
            [
              37.351975,
              54.352253
            ],
            [
              37.353753,
              54.34214
            ],
            [
              37.355754,
              54.341999
            ],
            [
              37.357382,
              54.342371
            ],
            [
              37.36684,
              54.344978
            ],
            [
              37.369453,
              54.338671
            ],
            [
              37.370801,
              54.335487
            ],
            [
              37.371399,
              54.334512
            ],
            [
              37.382792,
              54.331852
            ],
            [
              37.385971,
              54.330175
            ],
            [
              37.393232,
              54.326241
            ],
            [
              37.392466,
              54.315457
            ],
            [
              37.38771,
              54.313136
            ],
            [
              37.383811,
              54.310692
            ],
            [
              37.373978,
              54.308449
            ],
            [
              37.370481,
              54.308988
            ],
            [
              37.366704,
              54.307087
            ],
            [
              37.364505,
              54.305419
            ],
            [
              37.366335,
              54.302318
            ],
            [
              37.369611,
              54.297489
            ],
            [
              37.372121,
              54.294909
            ],
            [
              37.37663,
              54.299296
            ],
            [
              37.385735,
              54.301569
            ],
            [
              37.3887,
              54.300262
            ],
            [
              37.398855,
              54.301537
            ],
            [
              37.401861,
              54.300819
            ],
            [
              37.408784,
              54.298727
            ],
            [
              37.419982,
              54.298921
            ],
            [
              37.428708,
              54.300781
            ],
            [
              37.437646,
              54.298444
            ],
            [
              37.439264,
              54.297948
            ],
            [
              37.440318,
              54.301391
            ],
            [
              37.438625,
              54.303492
            ],
            [
              37.436965,
              54.306686
            ],
            [
              37.435295,
              54.305818
            ],
            [
              37.427651,
              54.305764
            ],
            [
              37.423318,
              54.308774
            ],
            [
              37.429222,
              54.318594
            ],
            [
              37.432786,
              54.319013
            ],
            [
              37.440055,
              54.320239
            ],
            [
              37.443692,
              54.318672
            ],
            [
              37.445718,
              54.318463
            ],
            [
              37.44712,
              54.31832
            ],
            [
              37.447486,
              54.321901
            ],
            [
              37.441419,
              54.323571
            ],
            [
              37.436964,
              54.326138
            ],
            [
              37.433977,
              54.326855
            ],
            [
              37.429556,
              54.33722
            ],
            [
              37.430378,
              54.339104
            ],
            [
              37.431010482833045,
              54.34055286274285
            ],
            [
              37.435428,
              54.347558
            ],
            [
              37.44232381437752,
              54.353743451580876
            ],
            [
              37.442469820915875,
              54.35383593822865
            ],
            [
              37.44970753255827,
              54.35833622222196
            ],
            [
              37.449874366217216,
              54.358439078702084
            ],
            [
              37.454133,
              54.361064
            ],
            [
              37.45788,
              54.363394
            ],
            [
              37.461298,
              54.365477
            ],
            [
              37.466535,
              54.36871
            ],
            [
              37.473992,
              54.373388
            ],
            [
              37.477215,
              54.37572
            ],
            [
              37.479907,
              54.377964
            ],
            [
              37.48059884047755,
              54.37864525199813
            ],
            [
              37.480616,
              54.378627
            ],
            [
              37.490672,
              54.382741
            ],
            [
              37.491402,
              54.38368
            ],
            [
              37.492572,
              54.385799
            ],
            [
              37.495551,
              54.390106
            ],
            [
              37.507383,
              54.390444
            ],
            [
              37.512559,
              54.383268
            ],
            [
              37.504838,
              54.375292
            ],
            [
              37.503007,
              54.374161
            ],
            [
              37.501458,
              54.372869
            ],
            [
              37.503763,
              54.370104
            ],
            [
              37.507217362239885,
              54.36275999949764
            ],
            [
              37.505001,
              54.359138
            ],
            [
              37.504748,
              54.359022
            ],
            [
              37.506241,
              54.355746
            ],
            [
              37.50655730404485,
              54.35583073913301
            ],
            [
              37.506696,
              54.355536
            ],
            [
              37.511952,
              54.357276
            ],
            [
              37.51195237616782,
              54.35727612970601
            ],
            [
              37.512691044531344,
              54.357471209350926
            ],
            [
              37.517696,
              54.358792
            ],
            [
              37.529298,
              54.35999
            ],
            [
              37.532311,
              54.353779
            ],
            [
              37.532878,
              54.350118
            ],
            [
              37.529759,
              54.339094
            ],
            [
              37.525591,
              54.337117
            ],
            [
              37.520447,
              54.335239
            ],
            [
              37.510628,
              54.334202
            ],
            [
              37.503277,
              54.336732
            ],
            [
              37.499816,
              54.337923
            ],
            [
              37.49445,
              54.338628
            ],
            [
              37.498652,
              54.334488
            ],
            [
              37.502043,
              54.33335
            ],
            [
              37.498639,
              54.322154
            ],
            [
              37.495314,
              54.321046
            ],
            [
              37.491702,
              54.317104
            ],
            [
              37.483833,
              54.316283
            ],
            [
              37.479143,
              54.325084
            ],
            [
              37.478808,
              54.329432
            ],
            [
              37.476006,
              54.331692
            ],
            [
              37.474431,
              54.329739
            ],
            [
              37.474115,
              54.329297
            ],
            [
              37.47512,
              54.325345
            ],
            [
              37.475145,
              54.321651
            ],
            [
              37.47632,
              54.317315
            ],
            [
              37.474445,
              54.310001
            ],
            [
              37.465619,
              54.310121
            ],
            [
              37.465294,
              54.309535
            ],
            [
              37.464458,
              54.30709
            ],
            [
              37.463621,
              54.304646
            ],
            [
              37.463453,
              54.300137
            ],
            [
              37.463538,
              54.2998
            ],
            [
              37.464797,
              54.298685
            ],
            [
              37.465503,
              54.298806
            ],
            [
              37.467027,
              54.300687
            ],
            [
              37.473623,
              54.306496
            ],
            [
              37.48292,
              54.302679
            ],
            [
              37.483791,
              54.299186
            ],
            [
              37.490907,
              54.298422
            ],
            [
              37.49646,
              54.297546
            ],
            [
              37.504407,
              54.295592
            ],
            [
              37.515609,
              54.292935
            ],
            [
              37.516004,
              54.292798
            ],
            [
              37.520367,
              54.293019
            ],
            [
              37.525762,
              54.292766
            ],
            [
              37.532344,
              54.28728
            ],
            [
              37.535075,
              54.281352
            ],
            [
              37.538579,
              54.280522
            ],
            [
              37.539009,
              54.282342
            ],
            [
              37.53944,
              54.284162
            ],
            [
              37.549286,
              54.288747
            ],
            [
              37.551777,
              54.291346
            ],
            [
              37.562675,
              54.290754
            ],
            [
              37.564455,
              54.287997
            ],
            [
              37.565028,
              54.288059
            ],
            [
              37.565192,
              54.288181
            ],
            [
              37.565518,
              54.288499
            ],
            [
              37.565979,
              54.288972
            ],
            [
              37.568924,
              54.292303
            ],
            [
              37.578745,
              54.291794
            ],
            [
              37.581425,
              54.288212
            ],
            [
              37.585349,
              54.282806
            ],
            [
              37.588262,
              54.284921
            ],
            [
              37.5863,
              54.287624
            ],
            [
              37.584339,
              54.290327
            ],
            [
              37.58165,
              54.29392
            ],
            [
              37.584642,
              54.305055
            ],
            [
              37.588431,
              54.304323
            ],
            [
              37.594423,
              54.303066
            ],
            [
              37.597329,
              54.30104
            ],
            [
              37.602626,
              54.298021
            ],
            [
              37.604764,
              54.297045
            ],
            [
              37.607529,
              54.298986
            ],
            [
              37.608676,
              54.300385
            ],
            [
              37.609549,
              54.301966
            ],
            [
              37.608038,
              54.303928
            ],
            [
              37.60554,
              54.310259
            ],
            [
              37.609297,
              54.321028
            ],
            [
              37.615598,
              54.327352
            ],
            [
              37.615178,
              54.330979
            ],
            [
              37.612931,
              54.332005
            ],
            [
              37.611509,
              54.34379
            ],
            [
              37.61589,
              54.344481
            ],
            [
              37.626811,
              54.340944
            ],
            [
              37.62948,
              54.34202
            ],
            [
              37.63317,
              54.344467
            ],
            [
              37.635979,
              54.348746
            ],
            [
              37.638177,
              54.351597
            ],
            [
              37.64127,
              54.358471
            ],
            [
              37.648565,
              54.360606
            ],
            [
              37.64897,
              54.360536
            ],
            [
              37.652189,
              54.362662
            ],
            [
              37.662025,
              54.366263
            ],
            [
              37.662128,
              54.366653
            ],
            [
              37.662409,
              54.368008
            ],
            [
              37.665685,
              54.374146
            ],
            [
              37.675898,
              54.373251
            ],
            [
              37.678394,
              54.366953
            ],
            [
              37.680509,
              54.36404
            ],
            [
              37.683846,
              54.365318
            ],
            [
              37.693106,
              54.362116
            ],
            [
              37.70188,
              54.357731
            ],
            [
              37.704497,
              54.360204
            ],
            [
              37.706487,
              54.36142
            ],
            [
              37.717685,
              54.35816
            ],
            [
              37.718472,
              54.357913
            ],
            [
              37.718986,
              54.357855
            ],
            [
              37.719383,
              54.361433
            ],
            [
              37.726089,
              54.369568
            ],
            [
              37.731091,
              54.368918
            ],
            [
              37.736105,
              54.365856
            ],
            [
              37.73878,
              54.368266
            ],
            [
              37.736806,
              54.372366
            ],
            [
              37.736011,
              54.374369
            ],
            [
              37.738179,
              54.384501
            ],
            [
              37.74242,
              54.386109
            ],
            [
              37.742153,
              54.389699
            ],
            [
              37.733058,
              54.393786
            ],
            [
              37.729099,
              54.399798
            ],
            [
              37.723986,
              54.398627
            ],
            [
              37.720607,
              54.394254
            ],
            [
              37.717383,
              54.391623
            ],
            [
              37.713525,
              54.3891
            ],
            [
              37.709262,
              54.387965
            ],
            [
              37.699295,
              54.390264
            ],
            [
              37.701103,
              54.399291
            ],
            [
              37.70110300791334,
              54.39929153369951
            ],
            [
              37.701521,
              54.401284
            ],
            [
              37.701182,
              54.404619
            ],
            [
              37.708574,
              54.412957
            ],
            [
              37.7117,
              54.415419
            ],
            [
              37.712438,
              54.418878
            ],
            [
              37.711508,
              54.428422
            ],
            [
              37.714018674754655,
              54.43036675480785
            ],
            [
              37.72326223874609,
              54.433675702752325
            ],
            [
              37.724136,
              54.433926
            ],
            [
              37.72425079963909,
              54.43407941406315
            ],
            [
              37.72855,
              54.431657
            ],
            [
              37.733314,
              54.427288
            ],
            [
              37.73377163189141,
              54.427095581414555
            ],
            [
              37.737155,
              54.42414
            ],
            [
              37.742017,
              54.423336
            ],
            [
              37.74902587626604,
              54.424431924510685
            ],
            [
              37.750676,
              54.4243
            ],
            [
              37.751299,
              54.427845
            ],
            [
              37.760655,
              54.433354
            ],
            [
              37.770656,
              54.428283
            ],
            [
              37.773091,
              54.425623
            ],
            [
              37.781638,
              54.428024
            ],
            [
              37.786291,
              54.42645
            ],
            [
              37.788666,
              54.425692
            ],
            [
              37.790281,
              54.42508
            ],
            [
              37.790281018566816,
              54.425080048977975
            ],
            [
              37.790487,
              54.425002
            ],
            [
              37.79053087324535,
              54.4251177345955
            ],
            [
              37.791297,
              54.424877
            ],
            [
              37.799164,
              54.423121
            ],
            [
              37.802857,
              54.421596
            ],
            [
              37.806712,
              54.420693
            ],
            [
              37.811283,
              54.417663
            ],
            [
              37.817843,
              54.411507
            ],
            [
              37.815246,
              54.400324
            ],
            [
              37.812849,
              54.400466
            ],
            [
              37.811415,
              54.400759
            ],
            [
              37.810695,
              54.397232
            ],
            [
              37.812129,
              54.396939
            ],
            [
              37.822969,
              54.393863
            ],
            [
              37.825274,
              54.39317
            ],
            [
              37.826309,
              54.396618
            ],
            [
              37.827605,
              54.403811
            ],
            [
              37.829604,
              54.413016
            ],
            [
              37.841494,
              54.413179
            ],
            [
              37.84538,
              54.410956
            ],
            [
              37.847605,
              54.410992
            ],
            [
              37.855694,
              54.419745
            ],
            [
              37.855634,
              54.423345
            ],
            [
              37.849795,
              54.42487
            ],
            [
              37.846032,
              54.426536
            ],
            [
              37.841239,
              54.428991
            ],
            [
              37.836467,
              54.429261
            ],
            [
              37.83127603585096,
              54.43358559106867
            ],
            [
              37.832441,
              54.440463
            ],
            [
              37.832605055219716,
              54.44080347446918
            ],
            [
              37.832637,
              54.440784
            ],
            [
              37.834041,
              54.443087
            ],
            [
              37.845896,
              54.444149
            ],
            [
              37.847234,
              54.440807
            ],
            [
              37.858479,
              54.442978
            ],
            [
              37.867999,
              54.44722
            ],
            [
              37.871813,
              54.443185
            ],
            [
              37.874515,
              54.440328
            ],
            [
              37.878544,
              54.436197
            ],
            [
              37.879088,
              54.435677
            ],
            [
              37.880021,
              54.434809
            ],
            [
              37.88005262789527,
              54.43482976401989
            ],
            [
              37.880841,
              54.434047
            ],
            [
              37.8843039032684,
              54.428932619768716
            ],
            [
              37.88430499442235,
              54.4289307673016
            ],
            [
              37.884098,
              54.420295
            ],
            [
              37.884297,
              54.419181
            ],
            [
              37.887841,
              54.419817
            ],
            [
              37.898632,
              54.419292
            ],
            [
              37.900094,
              54.422582
            ],
            [
              37.911691,
              54.423932
            ],
            [
              37.921905,
              54.423176
            ],
            [
              37.925095,
              54.425557
            ],
            [
              37.924433,
              54.429485
            ],
            [
              37.923109,
              54.438922
            ],
            [
              37.92306858730715,
              54.43991677397782
            ],
            [
              37.923161,
              54.439923
            ],
            [
              37.922927,
              54.443402
            ],
            [
              37.929217,
              54.453256
            ],
            [
              37.934345,
              54.456084
            ],
            [
              37.945042,
              54.456494
            ],
            [
              37.948671,
              54.454962
            ],
            [
              37.95264,
              54.452878
            ],
            [
              37.960905,
              54.448355
            ],
            [
              37.965239,
              54.447482
            ],
            [
              37.971191,
              54.445754
            ],
            [
              37.972771,
              54.448988
            ],
            [
              37.970719,
              54.44999
            ],
            [
              37.96595,
              54.451011
            ],
            [
              37.958471,
              54.454364
            ],
            [
              37.953916,
              54.456244
            ],
            [
              37.950676,
              54.457952
            ],
            [
              37.946752,
              54.459662
            ],
            [
              37.94459133176412,
              54.460793924831925
            ],
            [
              37.944601,
              54.460813
            ],
            [
              37.94156,
              54.462353
            ],
            [
              37.939124,
              54.463178
            ],
            [
              37.933287283262366,
              54.47238328084653
            ],
            [
              37.933146,
              54.475494
            ],
            [
              37.931207,
              54.478821
            ],
            [
              37.926489,
              54.482411
            ],
            [
              37.921203,
              54.484268
            ],
            [
              37.916611443356025,
              54.492263368313516
            ],
            [
              37.923053,
              54.496101
            ],
            [
              37.929863,
              54.499505
            ],
            [
              37.93054831190737,
              54.50264333590917
            ],
            [
              37.937443,
              54.501394
            ],
            [
              37.93839,
              54.499928
            ],
            [
              37.941415,
              54.501881
            ],
            [
              37.94884,
              54.508698
            ],
            [
              37.952443,
              54.508742
            ],
            [
              37.962468,
              54.508352
            ],
            [
              37.96246933339356,
              54.508351918749014
            ],
            [
              37.964923,
              54.507989
            ],
            [
              37.96495423220631,
              54.508200500226025
            ],
            [
              37.969459,
              54.507926
            ],
            [
              37.979085,
              54.504431
            ],
            [
              37.988073,
              54.498486
            ],
            [
              37.996677,
              54.497516
            ],
            [
              38.002034,
              54.498517
            ],
            [
              38.003606,
              54.508628
            ],
            [
              38.002093,
              54.511881
            ],
            [
              38.00185107289555,
              54.512547155648996
            ],
            [
              38.001962,
              54.512582
            ],
            [
              38.001452,
              54.514204
            ],
            [
              38.000892,
              54.515188
            ],
            [
              37.99939996329618,
              54.51719585510714
            ],
            [
              37.999418,
              54.517213
            ],
            [
              37.998442,
              54.518485
            ],
            [
              37.995582,
              54.529926
            ],
            [
              37.997793,
              54.533133
            ],
            [
              38.000742,
              54.535487
            ],
            [
              37.997576,
              54.542662
            ],
            [
              37.99538976822891,
              54.543364277717004
            ],
            [
              37.99539,
              54.543365
            ],
            [
              37.992966,
              54.544143
            ],
            [
              37.990543,
              54.54492
            ],
            [
              37.99048044466816,
              54.54493376856708
            ],
            [
              37.990482,
              54.544939
            ],
            [
              37.989648,
              54.545187
            ],
            [
              37.987608,
              54.545566
            ],
            [
              37.978123,
              54.55147
            ],
            [
              37.974709,
              54.553753
            ],
            [
              37.97202,
              54.55616
            ],
            [
              37.973878,
              54.567003
            ],
            [
              37.97859362855127,
              54.569479684316455
            ],
            [
              37.985035,
              54.570969
            ],
            [
              37.988688,
              54.571648
            ],
            [
              37.99265774763946,
              54.57246965820181
            ],
            [
              37.992677,
              54.572393
            ],
            [
              37.99477,
              54.572919
            ],
            [
              37.996862,
              54.573446
            ],
            [
              37.999211,
              54.574056
            ],
            [
              38.004464892473266,
              54.57539247311831
            ],
            [
              38.004555,
              54.575415
            ],
            [
              38.007514,
              54.576156
            ],
            [
              38.011849,
              54.581078
            ],
            [
              38.02201,
              54.583826
            ],
            [
              38.023207,
              54.584212
            ],
            [
              38.03279430292271,
              54.58041387786124
            ],
            [
              38.033786,
              54.579513
            ],
            [
              38.044734,
              54.578689
            ],
            [
              38.052997,
              54.577382
            ],
            [
              38.058128,
              54.577553
            ],
            [
              38.059467669443805,
              54.57908870253207
            ],
            [
              38.059828,
              54.579049
            ],
            [
              38.071453,
              54.579133
            ],
            [
              38.076999,
              54.572667
            ],
            [
              38.080369,
              54.573574
            ],
            [
              38.085799,
              54.576616
            ],
            [
              38.093094,
              54.573689
            ],
            [
              38.092995,
              54.56277
            ],
            [
              38.089383,
              54.560897
            ],
            [
              38.08498,
              54.559341
            ],
            [
              38.078987,
              54.552947
            ],
            [
              38.07445283440046,
              54.54442045424959
            ],
            [
              38.0723705935067,
              54.54364532145229
            ],
            [
              38.067299,
              54.543234
            ],
            [
              38.065869,
              54.543829
            ],
            [
              38.060686,
              54.544205
            ],
            [
              38.06020867225931,
              54.54423962099354
            ],
            [
              38.060206,
              54.54424
            ],
            [
              38.0585729564858,
              54.54435826087217
            ],
            [
              38.055502,
              54.544581
            ],
            [
              38.055501974917604,
              54.5445806535736
            ],
            [
              38.054572,
              54.544648
            ],
            [
              38.048985,
              54.546167
            ],
            [
              38.047752,
              54.54624
            ],
            [
              38.045433,
              54.545956
            ],
            [
              38.043113,
              54.545672
            ],
            [
              38.04355,
              54.542099
            ],
            [
              38.04587,
              54.542383
            ],
            [
              38.048335,
              54.542627
            ],
            [
              38.054254,
              54.541062
            ],
            [
              38.059772,
              54.531157
            ],
            [
              38.058661,
              54.528029
            ],
            [
              38.058669,
              54.52793
            ],
            [
              38.061838,
              54.527021
            ],
            [
              38.062256,
              54.528227
            ],
            [
              38.063278,
              54.53034
            ],
            [
              38.064221,
              54.534607
            ],
            [
              38.071400539624406,
              54.53809995029445
            ],
            [
              38.073216,
              54.537926
            ],
            [
              38.074181,
              54.537984
            ],
            [
              38.079325059890124,
              54.53840118366388
            ],
            [
              38.08485233868366,
              54.533186757121626
            ],
            [
              38.085022,
              54.529876
            ],
            [
              38.084888,
              54.529506
            ],
            [
              38.085979,
              54.52762
            ],
            [
              38.086785,
              54.52726
            ],
            [
              38.08861020316769,
              54.52782670690459
            ],
            [
              38.08953,
              54.52713
            ],
            [
              38.089739050468246,
              54.52817720243888
            ],
            [
              38.092257,
              54.528959
            ],
            [
              38.092712,
              54.529277
            ],
            [
              38.090866,
              54.540119
            ],
            [
              38.0880528982017,
              54.54136845851324
            ],
            [
              38.087888,
              54.542663
            ],
            [
              38.08710695604022,
              54.54388047320974
            ],
            [
              38.088991,
              54.544516
            ],
            [
              38.092334,
              54.545645
            ],
            [
              38.095796,
              54.546797
            ],
            [
              38.099054,
              54.54877
            ],
            [
              38.102489,
              54.551279
            ],
            [
              38.105615,
              54.553562
            ],
            [
              38.11005762236354,
              54.55678814398774
            ],
            [
              38.11006,
              54.556787
            ],
            [
              38.121004,
              54.552897
            ],
            [
              38.12115442070697,
              54.552915565779955
            ],
            [
              38.121576,
              54.547933
            ],
            [
              38.122352920795116,
              54.54527504334968
            ],
            [
              38.12233,
              54.544622
            ],
            [
              38.122690705047624,
              54.544119435331524
            ],
            [
              38.122749,
              54.54392
            ],
            [
              38.125334,
              54.540216
            ],
            [
              38.126059788609986,
              54.539343413766645
            ],
            [
              38.127168,
              54.537741
            ],
            [
              38.128172498990565,
              54.53681622756095
            ],
            [
              38.13123979855212,
              54.533545301001
            ],
            [
              38.135464,
              54.525513
            ],
            [
              38.13664930835536,
              54.525331393247875
            ],
            [
              38.135065,
              54.522959
            ],
            [
              38.13707481544046,
              54.51921028468003
            ],
            [
              38.13871,
              54.516158
            ],
            [
              38.141883,
              54.517859
            ],
            [
              38.147848925033344,
              54.52557465169959
            ],
            [
              38.155845,
              54.522571
            ],
            [
              38.162407468751425,
              54.521017512925724
            ],
            [
              38.162943,
              54.520378
            ],
            [
              38.16394657244214,
              54.51904089606373
            ],
            [
              38.163642,
              54.516733
            ],
            [
              38.160955,
              54.50543
            ],
            [
              38.157224,
              54.505491
            ],
            [
              38.151459,
              54.501367
            ],
            [
              38.147131,
              54.498082
            ],
            [
              38.150818,
              54.489237
            ],
            [
              38.151307388335304,
              54.48390909345667
            ],
            [
              38.143516,
              54.484836
            ],
            [
              38.140046,
              54.486365
            ],
            [
              38.135951,
              54.487868
            ],
            [
              38.13267,
              54.489887
            ],
            [
              38.129167,
              54.49153
            ],
            [
              38.119542,
              54.491955
            ],
            [
              38.110095,
              54.493323
            ],
            [
              38.103976,
              54.496303
            ],
            [
              38.10323,
              54.496276
            ],
            [
              38.10279,
              54.496231
            ],
            [
              38.101759,
              54.496164
            ],
            [
              38.10141775277078,
              54.494031102402225
            ],
            [
              38.100447,
              54.493972
            ],
            [
              38.093011,
              54.492649
            ],
            [
              38.083583,
              54.497917
            ],
            [
              38.080748873321184,
              54.50308527450902
            ],
            [
              38.08007,
              54.51193
            ],
            [
              38.077054,
              54.509745
            ],
            [
              38.07703387634283,
              54.50970269588285
            ],
            [
              38.075273,
              54.510009
            ],
            [
              38.075112,
              54.509083
            ],
            [
              38.075469,
              54.506413
            ],
            [
              38.07630679911836,
              54.50276982221127
            ],
            [
              38.076196,
              54.502736
            ],
            [
              38.076677,
              54.50116
            ],
            [
              38.079223,
              54.497608
            ],
            [
              38.070702,
              54.489323
            ],
            [
              38.06649,
              54.489247
            ],
            [
              38.062466,
              54.486376
            ],
            [
              38.067827,
              54.48521
            ],
            [
              38.076414,
              54.48366
            ],
            [
              38.08114257096773,
              54.48362367923484
            ],
            [
              38.081091,
              54.483477
            ],
            [
              38.086664,
              54.47405
            ],
            [
              38.089674398459195,
              54.47333757627101
            ],
            [
              38.089861,
              54.472023
            ],
            [
              38.094823,
              54.471155
            ],
            [
              38.100487,
              54.470907
            ],
            [
              38.106582,
              54.470279
            ],
            [
              38.110994,
              54.469834
            ],
            [
              38.114664,
              54.467746
            ],
            [
              38.115691,
              54.456896
            ],
            [
              38.112749,
              54.455635
            ],
            [
              38.1127491572944,
              54.455634633459745
            ],
            [
              38.112048,
              54.455334
            ],
            [
              38.10845,
              54.453012
            ],
            [
              38.097034,
              54.452281
            ],
            [
              38.093547,
              54.453279
            ],
            [
              38.085894,
              54.45598
            ],
            [
              38.08563340789641,
              54.455931290346804
            ],
            [
              38.08429,
              54.456806
            ],
            [
              38.081193,
              54.458633
            ],
            [
              38.077735,
              54.457746
            ],
            [
              38.077019,
              54.457422
            ],
            [
              38.07711077542097,
              54.45441737217838
            ],
            [
              38.06967514689636,
              54.45638516855845
            ],
            [
              38.066589,
              54.461598
            ],
            [
              38.06623,
              54.462573
            ],
            [
              38.064274,
              54.465594
            ],
            [
              38.06329,
              54.464958
            ],
            [
              38.062502,
              54.462219
            ],
            [
              38.057585,
              54.453648
            ],
            [
              38.051733,
              54.447722
            ],
            [
              38.047889,
              54.446352
            ],
            [
              38.044534,
              54.443486
            ],
            [
              38.03764,
              54.437921
            ],
            [
              38.03383,
              54.437643
            ],
            [
              38.032004,
              54.437858
            ],
            [
              38.03194848395504,
              54.43738644328558
            ],
            [
              38.027567,
              54.436789
            ],
            [
              38.022134,
              54.445292
            ],
            [
              38.020085,
              54.448252
            ],
            [
              38.009645,
              54.4497
            ],
            [
              38.002726,
              54.453247
            ],
            [
              37.997689,
              54.455716
            ],
            [
              37.993511,
              54.462358
            ],
            [
              37.989915,
              54.462194
            ],
            [
              37.990318,
              54.457498
            ],
            [
              37.993401,
              54.455639
            ],
            [
              37.996105,
              54.452483
            ],
            [
              37.991356,
              54.443571
            ],
            [
              37.98716,
              54.442563
            ],
            [
              37.983694,
              54.442045
            ],
            [
              37.982639,
              54.441669
            ],
            [
              37.982814,
              54.441145
            ],
            [
              37.983905,
              54.437798
            ],
            [
              37.982097710904306,
              54.432013123096944
            ],
            [
              37.976213,
              54.427324
            ],
            [
              37.972062,
              54.426679
            ],
            [
              37.966386,
              54.425434
            ],
            [
              37.960627,
              54.424167
            ],
            [
              37.956788,
              54.42313
            ],
            [
              37.953471,
              54.422159
            ],
            [
              37.950155,
              54.421187
            ],
            [
              37.951166,
              54.417733
            ],
            [
              37.9578,
              54.419675
            ],
            [
              37.961566,
              54.420692
            ],
            [
              37.967158,
              54.421917
            ],
            [
              37.972834,
              54.423163
            ],
            [
              37.976486,
              54.423734
            ],
            [
              37.98067107020899,
              54.42375955334592
            ],
            [
              37.99083359254186,
              54.41951820219436
            ],
            [
              37.991668,
              54.418855
            ],
            [
              37.989726,
              54.411691
            ],
            [
              37.987154,
              54.409298
            ],
            [
              37.986352,
              54.40753
            ],
            [
              37.98542,
              54.405232
            ],
            [
              37.988757,
              54.40388
            ],
            [
              37.994136,
              54.410697
            ],
            [
              37.996733,
              54.413211
            ],
            [
              37.998502,
              54.417152
            ],
            [
              38.00353877284319,
              54.42051764240792
            ],
            [
              38.008943,
              54.413712
            ],
            [
              38.019387,
              54.410557
            ],
            [
              38.0214,
              54.409701
            ],
            [
              38.022404,
              54.409645
            ],
            [
              38.034323,
              54.40913
            ],
            [
              38.037859,
              54.40737
            ],
            [
              38.041724,
              54.404694
            ],
            [
              38.04778,
              54.39713
            ],
            [
              38.048984,
              54.391648
            ],
            [
              38.054415,
              54.383581
            ],
            [
              38.057433,
              54.376059
            ],
            [
              38.055248,
              54.371574
            ],
            [
              38.049895,
              54.365342
            ],
            [
              38.051334,
              54.362042
            ],
            [
              38.059955,
              54.361828
            ],
            [
              38.063013,
              54.363727
            ],
            [
              38.071469,
              54.368615
            ],
            [
              38.072245,
              54.368902
            ],
            [
              38.073068,
              54.369183
            ],
            [
              38.081,
              54.372472
            ],
            [
              38.085944,
              54.365465
            ],
            [
              38.089262,
              54.364067
            ],
            [
              38.090042,
              54.365921
            ],
            [
              38.099379,
              54.373252
            ],
            [
              38.106992,
              54.371154
            ],
            [
              38.112002,
              54.372331
            ],
            [
              38.116364,
              54.375883
            ],
            [
              38.120049,
              54.378631
            ],
            [
              38.12404,
              54.384281
            ],
            [
              38.12863946157569,
              54.38535724188209
            ],
            [
              38.132885640106075,
              54.383127872229004
            ],
            [
              38.135216,
              54.376935
            ],
            [
              38.13835355048127,
              54.37723147723622
            ],
            [
              38.139318,
              54.375892
            ],
            [
              38.141659,
              54.375314
            ],
            [
              38.145129365640905,
              54.37826028255006
            ],
            [
              38.145322,
              54.378299
            ],
            [
              38.153064,
              54.369188
            ],
            [
              38.152967,
              54.36152
            ],
            [
              38.15287,
              54.353838
            ],
            [
              38.152864,
              54.34926
            ],
            [
              38.15301301584186,
              54.348751165418044
            ],
            [
              38.15191993170742,
              54.34272673877744
            ],
            [
              38.144777,
              54.340147
            ],
            [
              38.14467,
              54.34012
            ],
            [
              38.14285549955056,
              54.33873893071112
            ],
            [
              38.142503,
              54.338716
            ],
            [
              38.138578,
              54.338694
            ],
            [
              38.134906,
              54.338111
            ],
            [
              38.124897,
              54.338585
            ],
            [
              38.120194,
              54.339076
            ],
            [
              38.11661,
              54.338621
            ],
            [
              38.113356,
              54.338778
            ],
            [
              38.112665,
              54.338815
            ],
            [
              38.11247,
              54.33522
            ],
            [
              38.113162,
              54.335183
            ],
            [
              38.116014,
              54.334994
            ],
            [
              38.124519,
              54.335005
            ],
            [
              38.12955,
              54.334096
            ],
            [
              38.133634,
              54.334199
            ],
            [
              38.138728,
              54.335097
            ],
            [
              38.142522,
              54.335116
            ],
            [
              38.15263,
              54.328975
            ],
            [
              38.152612,
              54.323168
            ],
            [
              38.152602,
              54.319234
            ],
            [
              38.152632,
              54.31455
            ],
            [
              38.152705,
              54.313026
            ],
            [
              38.1563,
              54.313197
            ],
            [
              38.156228,
              54.314721
            ],
            [
              38.156202,
              54.319256
            ],
            [
              38.156212,
              54.323158
            ],
            [
              38.15623,
              54.328962
            ],
            [
              38.163624,
              54.333522
            ],
            [
              38.167583,
              54.333223
            ],
            [
              38.171465,
              54.33377
            ],
            [
              38.183001,
              54.336667
            ],
            [
              38.189795,
              54.336375
            ],
            [
              38.18995,
              54.339972
            ],
            [
              38.180491,
              54.341664
            ],
            [
              38.17726,
              54.343252
            ],
            [
              38.168012,
              54.336835
            ],
            [
              38.164091,
              54.337092
            ],
            [
              38.158159,
              54.337756
            ],
            [
              38.15665,
              54.348959
            ],
            [
              38.156649,
              54.350584
            ],
            [
              38.156567,
              54.361475
            ],
            [
              38.15660363438228,
              54.364371004570216
            ],
            [
              38.156604,
              54.364371
            ],
            [
              38.156664,
              54.369143
            ],
            [
              38.157021,
              54.378415
            ],
            [
              38.156964,
              54.378765
            ],
            [
              38.156328,
              54.384765
            ],
            [
              38.162695,
              54.393273
            ],
            [
              38.168526,
              54.388485
            ],
            [
              38.17205,
              54.388274
            ],
            [
              38.175163,
              54.390152
            ],
            [
              38.179584,
              54.397386
            ],
            [
              38.191114,
              54.399672
            ],
            [
              38.202659,
              54.398793
            ],
            [
              38.2083476437935,
              54.39412720358478
            ],
            [
              38.207771,
              54.386421
            ],
            [
              38.21164,
              54.382803
            ],
            [
              38.214662,
              54.380804
            ],
            [
              38.21468,
              54.384978
            ],
            [
              38.21507858481754,
              54.39152066488919
            ],
            [
              38.215488,
              54.391741
            ],
            [
              38.21772292251411,
              54.39200203032846
            ],
            [
              38.226148,
              54.389466
            ],
            [
              38.229399,
              54.387452
            ],
            [
              38.232777,
              54.385451
            ],
            [
              38.236251,
              54.382992
            ],
            [
              38.242353,
              54.377628
            ],
            [
              38.24392492919961,
              54.36924388652766
            ],
            [
              38.241397,
              54.365004
            ],
            [
              38.240051,
              54.364109
            ],
            [
              38.23947,
              54.363806
            ],
            [
              38.241131,
              54.360612
            ],
            [
              38.24148192778777,
              54.36056829442289
            ],
            [
              38.240365,
              54.358458
            ],
            [
              38.240199,
              54.357265
            ],
            [
              38.240188,
              54.355119
            ],
            [
              38.242263,
              54.350008
            ],
            [
              38.24383,
              54.349792
            ],
            [
              38.245054,
              54.349722
            ],
            [
              38.254709,
              54.347085
            ],
            [
              38.257431,
              54.344694
            ],
            [
              38.257468,
              54.344686
            ],
            [
              38.260021,
              54.344323
            ],
            [
              38.26388,
              54.345097
            ],
            [
              38.272723,
              54.34662
            ],
            [
              38.274818,
              54.347673
            ],
            [
              38.275604,
              54.348165
            ],
            [
              38.276848,
              54.349619
            ],
            [
              38.277818,
              54.353086
            ],
            [
              38.278747,
              54.356408
            ],
            [
              38.277197,
              54.359658
            ],
            [
              38.275371,
              54.360068
            ],
            [
              38.270948,
              54.37114
            ],
            [
              38.280978,
              54.371863
            ],
            [
              38.28165,
              54.37161
            ],
            [
              38.283744,
              54.37329
            ],
            [
              38.284295,
              54.374052
            ],
            [
              38.282918,
              54.374979
            ],
            [
              38.281377,
              54.37616
            ],
            [
              38.278335,
              54.385807
            ],
            [
              38.280621,
              54.389065
            ],
            [
              38.277794,
              54.391277
            ],
            [
              38.272807,
              54.394012
            ],
            [
              38.269203,
              54.394704
            ],
            [
              38.259088,
              54.392819
            ],
            [
              38.256111,
              54.390294
            ],
            [
              38.2462,
              54.393133
            ],
            [
              38.245695,
              54.398671
            ],
            [
              38.251406,
              54.407024
            ],
            [
              38.257278,
              54.407944
            ],
            [
              38.261141,
              54.408327
            ],
            [
              38.267481,
              54.409675
            ],
            [
              38.2649920657349,
              54.41900934662903
            ],
            [
              38.270491,
              54.424712
            ],
            [
              38.276825,
              54.426318
            ],
            [
              38.284886,
              54.428735
            ],
            [
              38.288323,
              54.4296
            ],
            [
              38.28857741922784,
              54.429774705518966
            ],
            [
              38.288714,
              54.429778
            ],
            [
              38.289956,
              54.430097
            ],
            [
              38.28982001948749,
              54.430627978776144
            ],
            [
              38.294218,
              54.433648
            ],
            [
              38.305695,
              54.431775
            ],
            [
              38.310835,
              54.431361
            ],
            [
              38.317135,
              54.426585
            ],
            [
              38.311834,
              54.415355
            ],
            [
              38.314175,
              54.405737
            ],
            [
              38.315704,
              54.404283
            ],
            [
              38.316055,
              54.40411
            ],
            [
              38.318258,
              54.407508
            ],
            [
              38.320195,
              54.413639
            ],
            [
              38.319767,
              54.421952
            ],
            [
              38.32007168483276,
              54.422108524490255
            ],
            [
              38.32107,
              54.420758
            ],
            [
              38.324666,
              54.423416
            ],
            [
              38.329311,
              54.426855
            ],
            [
              38.336186,
              54.428505
            ],
            [
              38.34053081002953,
              54.42847082171696
            ],
            [
              38.347233,
              54.425521
            ],
            [
              38.34621,
              54.417729
            ],
            [
              38.346977,
              54.413919
            ],
            [
              38.347457,
              54.413486
            ],
            [
              38.355458,
              54.416797
            ],
            [
              38.356739,
              54.417303
            ],
            [
              38.355417,
              54.420651
            ],
            [
              38.353102,
              54.425324
            ],
            [
              38.355076,
              54.434153
            ],
            [
              38.359483,
              54.442192
            ],
            [
              38.363679,
              54.446237
            ],
            [
              38.364243682573395,
              54.44680831370821
            ],
            [
              38.364244,
              54.446808
            ],
            [
              38.366672,
              54.449265
            ],
            [
              38.3691,
              54.451723
            ],
            [
              38.374518,
              54.4567
            ],
            [
              38.382747,
              54.455713
            ],
            [
              38.386929,
              54.457078
            ],
            [
              38.394089,
              54.463594
            ],
            [
              38.398746,
              54.465199
            ],
            [
              38.402272,
              54.466725
            ],
            [
              38.409721,
              54.469987
            ],
            [
              38.420903,
              54.474328
            ],
            [
              38.425285,
              54.474751
            ],
            [
              38.435055,
              54.481061
            ],
            [
              38.438043,
              54.484574
            ],
            [
              38.445426,
              54.491309
            ],
            [
              38.449661,
              54.491562
            ],
            [
              38.45862,
              54.486562
            ],
            [
              38.45855,
              54.482683
            ],
            [
              38.465053,
              54.479564
            ],
            [
              38.469153,
              54.477976
            ],
            [
              38.4765340674094,
              54.47274512835008
            ],
            [
              38.479981,
              54.468964
            ],
            [
              38.479516,
              54.465394
            ],
            [
              38.480117,
              54.465316
            ],
            [
              38.48417601645541,
              54.465286861331975
            ],
            [
              38.487888,
              54.461799
            ],
            [
              38.485918,
              54.451952
            ],
            [
              38.481029,
              54.451218
            ],
            [
              38.476465,
              54.448871
            ],
            [
              38.472972,
              54.4476
            ],
            [
              38.472302,
              54.447142
            ],
            [
              38.471726,
              54.446562
            ],
            [
              38.47490960526233,
              54.441476442167826
            ],
            [
              38.474429,
              54.441338
            ],
            [
              38.470904,
              54.443078
            ],
            [
              38.462475,
              54.442018
            ],
            [
              38.457081,
              54.440585
            ],
            [
              38.452189,
              54.4395
            ],
            [
              38.44978,
              54.438561
            ],
            [
              38.450003,
              54.436639
            ],
            [
              38.450534,
              54.43504
            ],
            [
              38.452168,
              54.43539
            ],
            [
              38.453667,
              54.435989
            ],
            [
              38.45795,
              54.437092
            ],
            [
              38.463503,
              54.438568
            ],
            [
              38.472836,
              54.43811
            ],
            [
              38.477237,
              54.436286
            ],
            [
              38.472067,
              54.425617
            ],
            [
              38.46263,
              54.421762
            ],
            [
              38.459127,
              54.420136
            ],
            [
              38.457044,
              54.419021
            ],
            [
              38.458743,
              54.415847
            ],
            [
              38.460827,
              54.416963
            ],
            [
              38.46405,
              54.418453
            ],
            [
              38.473429,
              54.422285
            ],
            [
              38.482962,
              54.426181
            ],
            [
              38.48457098179072,
              54.42733970805845
            ],
            [
              38.484728,
              54.427122
            ],
            [
              38.485243,
              54.427494
            ],
            [
              38.485854,
              54.427989
            ],
            [
              38.488833,
              54.430409
            ],
            [
              38.491815,
              54.432832
            ],
            [
              38.494658,
              54.434936
            ],
            [
              38.49893,
              54.437956
            ],
            [
              38.505513,
              54.44239
            ],
            [
              38.512404,
              54.445819
            ],
            [
              38.51897,
              54.453568
            ],
            [
              38.525572,
              54.45077
            ],
            [
              38.52406,
              54.441932
            ],
            [
              38.523531,
              54.436654
            ],
            [
              38.520464,
              54.432552
            ],
            [
              38.518512,
              54.427739
            ],
            [
              38.513193,
              54.419676
            ],
            [
              38.510437,
              54.415805
            ],
            [
              38.509488,
              54.412332
            ],
            [
              38.51268,
              54.406283
            ],
            [
              38.516142,
              54.405296
            ],
            [
              38.528014,
              54.4044
            ],
            [
              38.529706,
              54.404954
            ],
            [
              38.531534,
              54.408055
            ],
            [
              38.52895,
              54.409579
            ],
            [
              38.526782,
              54.410404
            ],
            [
              38.520802,
              54.418075
            ],
            [
              38.52124681632029,
              54.420079678956974
            ],
            [
              38.521954,
              54.420073
            ],
            [
              38.521986,
              54.423411
            ],
            [
              38.522884,
              54.429458
            ],
            [
              38.524536,
              54.432568
            ],
            [
              38.527891,
              54.436073
            ],
            [
              38.536813,
              54.435056
            ],
            [
              38.54003,
              54.432736
            ],
            [
              38.543155,
              54.430047
            ],
            [
              38.543304,
              54.42995
            ],
            [
              38.546616,
              54.423407
            ],
            [
              38.547755,
              54.419992
            ],
            [
              38.551933,
              54.421386
            ],
            [
              38.556112,
              54.422779
            ],
            [
              38.554973,
              54.426194
            ],
            [
              38.551665447425776,
              54.42872477886362
            ],
            [
              38.551862,
              54.429055
            ],
            [
              38.554404,
              54.440235
            ],
            [
              38.56258,
              54.436651
            ],
            [
              38.566686,
              54.434684
            ],
            [
              38.570616,
              54.432518
            ],
            [
              38.576643,
              54.431869
            ],
            [
              38.581418,
              54.430478
            ],
            [
              38.584568,
              54.43222
            ],
            [
              38.58584,
              54.435761
            ],
            [
              38.58936,
              54.438704
            ],
            [
              38.598509,
              54.43132
            ],
            [
              38.602434,
              54.432071
            ],
            [
              38.606063,
              54.433671
            ],
            [
              38.615526,
              54.43243
            ],
            [
              38.617375,
              54.42187
            ],
            [
              38.619062,
              54.41869
            ],
            [
              38.621335,
              54.419896
            ],
            [
              38.631735,
              54.418917
            ],
            [
              38.633749,
              54.421901
            ],
            [
              38.634962,
              54.427707
            ],
            [
              38.635128,
              54.429655
            ],
            [
              38.637185,
              54.440826
            ],
            [
              38.640019,
              54.441869
            ],
            [
              38.640312,
              54.44261
            ],
            [
              38.639501,
              54.446536
            ],
            [
              38.645447,
              54.456676
            ],
            [
              38.645719,
              54.456816
            ],
            [
              38.646283,
              54.457212
            ],
            [
              38.65562,
              54.462655
            ],
            [
              38.662175,
              54.460916
            ],
            [
              38.663098,
              54.464396
            ],
            [
              38.659458,
              54.475618
            ],
            [
              38.660306,
              54.481748
            ],
            [
              38.659,
              54.486001
            ],
            [
              38.665752,
              54.493299
            ],
            [
              38.668149,
              54.493765
            ],
            [
              38.671719,
              54.493304
            ],
            [
              38.671756,
              54.493589
            ],
            [
              38.667748,
              54.499743
            ],
            [
              38.666327,
              54.503051
            ],
            [
              38.659197,
              54.506402
            ],
            [
              38.655687,
              54.505605
            ],
            [
              38.652296,
              54.500784
            ],
            [
              38.646384,
              54.496602
            ],
            [
              38.640904374730454,
              54.49848743721112
            ],
            [
              38.638173,
              54.506573
            ],
            [
              38.63863,
              54.50831
            ],
            [
              38.638775,
              54.512243
            ],
            [
              38.636441,
              54.522381
            ],
            [
              38.641336,
              54.525973
            ],
            [
              38.6413,
              54.529573
            ],
            [
              38.648566,
              54.537079
            ],
            [
              38.65206,
              54.538552
            ],
            [
              38.65963296257228,
              54.53935415211878
            ],
            [
              38.655301,
              54.529396
            ],
            [
              38.654779,
              54.528962
            ],
            [
              38.654489,
              54.528628
            ],
            [
              38.657205,
              54.526266
            ],
            [
              38.661583,
              54.527872
            ],
            [
              38.667367,
              54.530378
            ],
            [
              38.665883,
              54.534643
            ],
            [
              38.671465,
              54.543861
            ],
            [
              38.674984,
              54.544869
            ],
            [
              38.681472,
              54.547029
            ],
            [
              38.687474,
              54.542252
            ],
            [
              38.69042706864294,
              54.536169277190545
            ],
            [
              38.691682,
              54.529745
            ],
            [
              38.69224,
              54.526853
            ],
            [
              38.693016,
              54.522736
            ],
            [
              38.693792,
              54.518618
            ],
            [
              38.697329,
              54.519285
            ],
            [
              38.696553,
              54.523402
            ],
            [
              38.703033,
              54.531099
            ],
            [
              38.708144,
              54.531993
            ],
            [
              38.718784,
              54.533653
            ],
            [
              38.723551,
              54.541365
            ],
            [
              38.722054,
              54.544639
            ],
            [
              38.716344,
              54.544532
            ],
            [
              38.709171,
              54.541476
            ],
            [
              38.698309,
              54.543655
            ],
            [
              38.694746,
              54.54459
            ],
            [
              38.689196,
              54.545894
            ],
            [
              38.68375,
              54.555923
            ],
            [
              38.689438,
              54.560789
            ],
            [
              38.69084,
              54.56105
            ],
            [
              38.697536,
              54.570452
            ],
            [
              38.701693,
              54.569213
            ],
            [
              38.705038,
              54.567753
            ],
            [
              38.706465,
              54.566632
            ],
            [
              38.707966,
              54.565448
            ],
            [
              38.710195,
              54.568275
            ],
            [
              38.710404,
              54.569651
            ],
            [
              38.71041501028021,
              54.569693654102956
            ],
            [
              38.712,
              54.569781
            ],
            [
              38.715406,
              54.568306
            ],
            [
              38.724403,
              54.564154
            ],
            [
              38.726111,
              54.562464
            ],
            [
              38.727457,
              54.561022
            ],
            [
              38.730089,
              54.563478
            ],
            [
              38.728743,
              54.56492
            ],
            [
              38.725848,
              54.568085
            ],
            [
              38.723368,
              54.570746
            ],
            [
              38.720354,
              54.573198
            ],
            [
              38.712961,
              54.574667
            ],
            [
              38.71224705522685,
              54.57649374882727
            ],
            [
              38.712988,
              54.578113
            ],
            [
              38.71266,
              54.581698
            ],
            [
              38.712222,
              54.581658
            ],
            [
              38.706598,
              54.581997
            ],
            [
              38.70269,
              54.582625
            ],
            [
              38.696814,
              54.585813
            ],
            [
              38.691391,
              54.589809
            ],
            [
              38.681408,
              54.593436
            ],
            [
              38.67810796780324,
              54.60466130637006
            ],
            [
              38.67844680381092,
              54.605096071115014
            ],
            [
              38.67987026894753,
              54.60688403324656
            ],
            [
              38.690927,
              54.609333
            ],
            [
              38.693348774353154,
              54.60875939083602
            ],
            [
              38.69494,
              54.608382
            ],
            [
              38.69494011244767,
              54.60838247458335
            ],
            [
              38.695035,
              54.60836
            ],
            [
              38.702503,
              54.607515
            ],
            [
              38.709604,
              54.605079
            ],
            [
              38.713146,
              54.603835
            ],
            [
              38.721396,
              54.599226
            ],
            [
              38.723759,
              54.593826
            ],
            [
              38.723956,
              54.587817
            ],
            [
              38.725072,
              54.586657
            ],
            [
              38.7271,
              54.586062
            ],
            [
              38.731194743076124,
              54.587084209654
            ],
            [
              38.735585,
              54.581337
            ],
            [
              38.734699,
              54.577182
            ],
            [
              38.737326,
              54.574875
            ],
            [
              38.738236,
              54.574843
            ],
            [
              38.740543,
              54.58179
            ],
            [
              38.743254,
              54.586419
            ],
            [
              38.754411,
              54.587523
            ],
            [
              38.757129,
              54.584448
            ],
            [
              38.759278,
              54.582111
            ],
            [
              38.759846,
              54.581386
            ],
            [
              38.762681,
              54.583605
            ],
            [
              38.762113,
              54.58433
            ],
            [
              38.759713,
              54.586955
            ],
            [
              38.7572,
              54.589799
            ],
            [
              38.764445,
              54.59812
            ],
            [
              38.767943,
              54.598679
            ],
            [
              38.773329,
              54.598279
            ],
            [
              38.776892,
              54.59846
            ],
            [
              38.779148,
              54.598309
            ],
            [
              38.779598,
              54.60188
            ],
            [
              38.786525,
              54.61127
            ],
            [
              38.787602,
              54.611699
            ],
            [
              38.789067,
              54.612284
            ],
            [
              38.79221,
              54.616007
            ],
            [
              38.799771,
              54.618437
            ],
            [
              38.800985,
              54.618848
            ],
            [
              38.799831,
              54.622258
            ],
            [
              38.789973,
              54.623635
            ],
            [
              38.786076,
              54.624693
            ],
            [
              38.782457,
              54.625978
            ],
            [
              38.775983,
              54.625628
            ],
            [
              38.77227427732974,
              54.62520859769231
            ],
            [
              38.772163,
              54.625434
            ],
            [
              38.771617,
              54.625577
            ],
            [
              38.771055,
              54.625641
            ],
            [
              38.763365,
              54.628225
            ],
            [
              38.759308,
              54.631183
            ],
            [
              38.755859,
              54.632965
            ],
            [
              38.751411,
              54.634809
            ],
            [
              38.752206,
              54.644937
            ],
            [
              38.762695,
              54.645826
            ],
            [
              38.767366,
              54.644914
            ],
            [
              38.772414,
              54.643036
            ],
            [
              38.776298,
              54.643439
            ],
            [
              38.780781,
              54.643019
            ],
            [
              38.785288,
              54.646172
            ],
            [
              38.79231,
              54.647543
            ],
            [
              38.793164,
              54.65104
            ],
            [
              38.791293,
              54.651497
            ],
            [
              38.786085,
              54.660787
            ],
            [
              38.78641158235343,
              54.663385111729276
            ],
            [
              38.787786,
              54.663728
            ],
            [
              38.78649978833561,
              54.66408683058976
            ],
            [
              38.786534,
              54.664359
            ],
            [
              38.786073,
              54.665758
            ],
            [
              38.785355,
              54.665869
            ],
            [
              38.775662,
              54.667638
            ],
            [
              38.768309,
              54.66418
            ],
            [
              38.763375,
              54.660975
            ],
            [
              38.759673,
              54.657561
            ],
            [
              38.757292,
              54.654014
            ],
            [
              38.750654,
              54.652549
            ],
            [
              38.747723696943396,
              54.66128295478581
            ],
            [
              38.747607441213184,
              54.6619008489476
            ],
            [
              38.749575,
              54.66924
            ],
            [
              38.751567,
              54.676134
            ],
            [
              38.75155661138546,
              54.676139538591315
            ],
            [
              38.75167,
              54.676306
            ],
            [
              38.7523582677584,
              54.67683754436756
            ],
            [
              38.753631,
              54.676099
            ],
            [
              38.755439688626474,
              54.67921851522684
            ],
            [
              38.759728,
              54.685606
            ],
            [
              38.769158,
              54.691199
            ],
            [
              38.773032,
              54.69249
            ],
            [
              38.779105,
              54.69448
            ],
            [
              38.787527,
              54.693099
            ],
            [
              38.789905,
              54.689233
            ],
            [
              38.793989,
              54.683044
            ],
            [
              38.794734,
              54.681953
            ],
            [
              38.794752765038886,
              54.68196581930508
            ],
            [
              38.795874,
              54.680285
            ],
            [
              38.79619,
              54.679745
            ],
            [
              38.79632230311623,
              54.679733199617125
            ],
            [
              38.796919,
              54.677748
            ],
            [
              38.800621,
              54.680044
            ],
            [
              38.810853,
              54.675998
            ],
            [
              38.813679,
              54.673768
            ],
            [
              38.814013,
              54.674191
            ],
            [
              38.815651,
              54.676818
            ],
            [
              38.818128,
              54.680311
            ],
            [
              38.817055,
              54.683676
            ],
            [
              38.81887389609496,
              54.68790753398416
            ],
            [
              38.820918,
              54.687549
            ],
            [
              38.830421,
              54.693461
            ],
            [
              38.830420782748746,
              54.69349500454476
            ],
            [
              38.830816,
              54.693479
            ],
            [
              38.840513,
              54.688457
            ],
            [
              38.840588,
              54.684857
            ],
            [
              38.841678879826006,
              54.68404259315742
            ],
            [
              38.836486,
              54.681028
            ],
            [
              38.834016,
              54.678452
            ],
            [
              38.833989,
              54.678424
            ],
            [
              38.836614,
              54.67596
            ],
            [
              38.836641,
              54.675988
            ],
            [
              38.839084,
              54.678536
            ],
            [
              38.8444790426266,
              54.68252252268407
            ],
            [
              38.848622,
              54.681283
            ],
            [
              38.85489711682108,
              54.68406337308866
            ],
            [
              38.85597280587216,
              54.683701735652946
            ],
            [
              38.859815,
              54.673511
            ],
            [
              38.854242,
              54.670558
            ],
            [
              38.852453,
              54.668464
            ],
            [
              38.852865,
              54.667232
            ],
            [
              38.853514,
              54.665024
            ],
            [
              38.854132,
              54.665215
            ],
            [
              38.864551,
              54.667852
            ],
            [
              38.86908887840931,
              54.66697561351318
            ],
            [
              38.874,
              54.665288
            ],
            [
              38.87402065450022,
              54.66529676289297
            ],
            [
              38.876808,
              54.65916
            ],
            [
              38.877062,
              54.658245
            ],
            [
              38.880531,
              54.659204
            ],
            [
              38.886126,
              54.663928
            ],
            [
              38.889022,
              54.666068
            ],
            [
              38.896237,
              54.669761
            ],
            [
              38.901078,
              54.664916
            ],
            [
              38.901743,
              54.664802
            ],
            [
              38.909341,
              54.672518
            ],
            [
              38.916401,
              54.667304
            ],
            [
              38.91697,
              54.6676
            ],
            [
              38.918937,
              54.668545
            ],
            [
              38.929686,
              54.666453
            ],
            [
              38.930603,
              54.662972
            ],
            [
              38.935504,
              54.663493
            ],
            [
              38.943313,
              54.656804
            ],
            [
              38.947754,
              54.655364
            ],
            [
              38.951122,
              54.656637
            ],
            [
              38.953443,
              54.660375
            ],
            [
              38.952121,
              54.663724
            ],
            [
              38.946974,
              54.66693
            ],
            [
              38.943968,
              54.670291
            ],
            [
              38.941055,
              54.673539
            ],
            [
              38.93764,
              54.676582
            ],
            [
              38.93801438568137,
              54.67751828814654
            ],
            [
              38.939606,
              54.677846
            ],
            [
              38.939916,
              54.678233
            ],
            [
              38.947095,
              54.686113
            ],
            [
              38.951518,
              54.685597
            ],
            [
              38.95330489199624,
              54.685388940000436
            ],
            [
              38.958678,
              54.684762
            ],
            [
              38.96293,
              54.684234
            ],
            [
              38.970263,
              54.67571
            ],
            [
              38.970568,
              54.67387
            ],
            [
              38.97412,
              54.67446
            ],
            [
              38.973814,
              54.6763
            ],
            [
              38.979657,
              54.684614
            ],
            [
              38.985777,
              54.683974
            ],
            [
              38.988548,
              54.686272
            ],
            [
              38.986563,
              54.688665
            ],
            [
              38.978972,
              54.688148
            ],
            [
              38.975821005087475,
              54.68752697421957
            ],
            [
              38.975821,
              54.687527
            ],
            [
              38.973648,
              54.687887
            ],
            [
              38.970056,
              54.688128
            ],
            [
              38.968852,
              54.688478
            ],
            [
              38.965331,
              54.689226
            ],
            [
              38.959122,
              54.688335
            ],
            [
              38.95331,
              54.689013
            ],
            [
              38.947512,
              54.689689
            ],
            [
              38.937864,
              54.695523
            ],
            [
              38.935042,
              54.697789
            ],
            [
              38.92897862854153,
              54.69864957844961
            ],
            [
              38.92981,
              54.700404
            ],
            [
              38.928947,
              54.701357
            ],
            [
              38.932665,
              54.712427
            ],
            [
              38.936394,
              54.710311
            ],
            [
              38.937071,
              54.710068
            ],
            [
              38.937711,
              54.710156
            ],
            [
              38.93826543815776,
              54.71179537595231
            ],
            [
              38.940231,
              54.71085
            ],
            [
              38.94353,
              54.707756
            ],
            [
              38.94849,
              54.705951
            ],
            [
              38.952098,
              54.704639
            ],
            [
              38.960711,
              54.702103
            ],
            [
              38.964795,
              54.70456
            ],
            [
              38.961092,
              54.705683
            ],
            [
              38.953285,
              54.708037
            ],
            [
              38.949721,
              54.709334
            ],
            [
              38.94622531685356,
              54.710606375091615
            ],
            [
              38.946239,
              54.711659
            ],
            [
              38.940998144697176,
              54.71880610902465
            ],
            [
              38.941714,
              54.720321
            ],
            [
              38.951315,
              54.724361
            ],
            [
              38.95296581122685,
              54.72449315963624
            ],
            [
              38.952966,
              54.724493
            ],
            [
              38.95297090216306,
              54.7244935672033
            ],
            [
              38.9548,
              54.72464
            ],
            [
              38.956691,
              54.724924
            ],
            [
              38.964506,
              54.721821
            ],
            [
              38.967504,
              54.719828
            ],
            [
              38.968202,
              54.720879
            ],
            [
              38.968781,
              54.724649
            ],
            [
              38.96984409725411,
              54.72745669329232
            ],
            [
              38.971702,
              54.729303
            ],
            [
              38.97801,
              54.728074
            ],
            [
              38.979368,
              54.727456
            ],
            [
              38.979838,
              54.72721
            ],
            [
              38.986137,
              54.724516
            ],
            [
              38.98637,
              54.724362
            ],
            [
              38.988109,
              54.724682
            ],
            [
              38.991906,
              54.72108
            ],
            [
              38.994677,
              54.721031
            ],
            [
              39.005621,
              54.717995
            ],
            [
              39.007928,
              54.713688
            ],
            [
              39.007991200548794,
              54.71367748243879
            ],
            [
              39.008786,
              54.71028
            ],
            [
              39.003133,
              54.703243
            ],
            [
              39.003711,
              54.699479
            ],
            [
              38.997646,
              54.691788
            ],
            [
              38.994282,
              54.69113
            ],
            [
              38.994973,
              54.687597
            ],
            [
              39.00302,
              54.687656
            ],
            [
              39.00330806257525,
              54.68794064804041
            ],
            [
              39.008906,
              54.684487
            ],
            [
              39.011071,
              54.683722
            ],
            [
              39.013235,
              54.682958
            ],
            [
              39.014434,
              54.686352
            ],
            [
              39.01996977978023,
              54.69638958916563
            ],
            [
              39.019975,
              54.696389
            ],
            [
              39.020414593872445,
              54.69668004651634
            ],
            [
              39.027632,
              54.701076
            ],
            [
              39.031531,
              54.701205
            ],
            [
              39.036427,
              54.700351
            ],
            [
              39.043225,
              54.697321
            ],
            [
              39.04919527818239,
              54.69989201320519
            ],
            [
              39.058677,
              54.698602
            ],
            [
              39.061603,
              54.6977
            ],
            [
              39.062664,
              54.70114
            ],
            [
              39.06949,
              54.710624
            ],
            [
              39.071893,
              54.710872
            ],
            [
              39.074008,
              54.714693
            ],
            [
              39.074255,
              54.716048
            ],
            [
              39.070772,
              54.716961
            ],
            [
              39.066504,
              54.716381
            ],
            [
              39.061411,
              54.71618
            ],
            [
              39.05812126972528,
              54.71628698667783
            ],
            [
              39.047071,
              54.720685
            ],
            [
              39.043054,
              54.720186
            ],
            [
              39.035825,
              54.716704
            ],
            [
              39.031668,
              54.71589
            ],
            [
              39.028033,
              54.7149
            ],
            [
              39.024309,
              54.714398
            ],
            [
              39.018507,
              54.715712
            ],
            [
              39.014689,
              54.716562
            ],
            [
              39.009696,
              54.719829
            ],
            [
              39.007542,
              54.722668
            ],
            [
              39.00603089340613,
              54.72597254952774
            ],
            [
              39.006057,
              54.725993
            ],
            [
              39.005551,
              54.727022
            ],
            [
              39.003866,
              54.737489
            ],
            [
              39.005495,
              54.741379
            ],
            [
              39.006773617888214,
              54.742790422233575
            ],
            [
              39.01528655629631,
              54.74210577013095
            ],
            [
              39.018482,
              54.740907
            ],
            [
              39.020915,
              54.738312
            ],
            [
              39.02459,
              54.735774
            ],
            [
              39.025301,
              54.739303
            ],
            [
              39.022991,
              54.742003
            ],
            [
              39.020353,
              54.749729
            ],
            [
              39.022253,
              54.757768
            ],
            [
              39.027667,
              54.760292
            ],
            [
              39.036927,
              54.76308
            ],
            [
              39.048425,
              54.760363
            ],
            [
              39.04847099126128,
              54.76037284767708
            ],
            [
              39.049081,
              54.759664
            ],
            [
              39.049298,
              54.759508
            ],
            [
              39.05046094040856,
              54.75976374016044
            ],
            [
              39.051465,
              54.759071
            ],
            [
              39.062334,
              54.757394
            ],
            [
              39.066332,
              54.756916
            ],
            [
              39.070215,
              54.756767
            ],
            [
              39.072,
              54.756629
            ],
            [
              39.07200404105912,
              54.75667559562049
            ],
            [
              39.074247,
              54.756561
            ],
            [
              39.080889513384136,
              54.75707040964763
            ],
            [
              39.088594,
              54.754464
            ],
            [
              39.09140696764235,
              54.75434567800721
            ],
            [
              39.096644,
              54.751594
            ],
            [
              39.096943,
              54.751729
            ],
            [
              39.103845,
              54.754927
            ],
            [
              39.105861,
              54.758645
            ],
            [
              39.114508,
              54.765467
            ],
            [
              39.11976,
              54.766077
            ],
            [
              39.123996,
              54.764713
            ],
            [
              39.128829,
              54.757936
            ],
            [
              39.129984,
              54.75422
            ],
            [
              39.133023,
              54.748066
            ],
            [
              39.134668,
              54.74299
            ],
            [
              39.134353663836094,
              54.73390158482622
            ],
            [
              39.12848762511315,
              54.72738984833642
            ],
            [
              39.124016,
              54.72749
            ],
            [
              39.120199,
              54.727986
            ],
            [
              39.116636,
              54.728431
            ],
            [
              39.112576,
              54.728869
            ],
            [
              39.107213,
              54.727351
            ],
            [
              39.102712,
              54.725932
            ],
            [
              39.098212,
              54.724514
            ],
            [
              39.094904,
              54.713869
            ],
            [
              39.09490415374857,
              54.713868146189355
            ],
            [
              39.086486,
              54.712352
            ],
            [
              39.087124,
              54.708809
            ],
            [
              39.099007,
              54.710949
            ],
            [
              39.110889,
              54.71309
            ],
            [
              39.116445,
              54.714083
            ],
            [
              39.12183538075118,
              54.71734351526636
            ],
            [
              39.122231,
              54.715118
            ],
            [
              39.125288,
              54.715661
            ],
            [
              39.128281,
              54.717408
            ],
            [
              39.132271,
              54.719027
            ],
            [
              39.143522,
              54.721136
            ],
            [
              39.147322,
              54.713718
            ],
            [
              39.14450380765488,
              54.703809630920155
            ],
            [
              39.142901,
              54.702097
            ],
            [
              39.142118,
              54.696791
            ],
            [
              39.141769,
              54.694208
            ],
            [
              39.141876,
              54.693278
            ],
            [
              39.146427,
              54.693188
            ],
            [
              39.151895,
              54.695997
            ],
            [
              39.160542,
              54.700586
            ],
            [
              39.169,
              54.701454
            ],
            [
              39.173858,
              54.702322
            ],
            [
              39.180071,
              54.703327
            ],
            [
              39.181056,
              54.706789
            ],
            [
              39.180164,
              54.707043
            ],
            [
              39.175873,
              54.709183
            ],
            [
              39.170116,
              54.712687
            ],
            [
              39.170130255514486,
              54.71318523982024
            ],
            [
              39.17427,
              54.720537
            ],
            [
              39.1726073172238,
              54.72287751508305
            ],
            [
              39.173807,
              54.724145
            ],
            [
              39.17216294534146,
              54.72349222944541
            ],
            [
              39.17204,
              54.723605
            ],
            [
              39.171499,
              54.723958
            ],
            [
              39.17092,
              54.72424
            ],
            [
              39.169982,
              54.723539
            ],
            [
              39.160539,
              54.72019
            ],
            [
              39.155927,
              54.71981
            ],
            [
              39.145286,
              54.724274
            ],
            [
              39.139592469494644,
              54.73302628412705
            ],
            [
              39.142609,
              54.734873
            ],
            [
              39.1411,
              54.738142
            ],
            [
              39.13814,
              54.743941
            ],
            [
              39.137294,
              54.747714
            ],
            [
              39.139545103341696,
              54.749588115917064
            ],
            [
              39.141712,
              54.749925
            ],
            [
              39.143513,
              54.750735
            ],
            [
              39.154979941742596,
              54.75222390547166
            ],
            [
              39.155857,
              54.752058
            ],
            [
              39.15923,
              54.750884
            ],
            [
              39.163317,
              54.748945
            ],
            [
              39.164418,
              54.748463
            ],
            [
              39.169503462126706,
              54.74692687072302
            ],
            [
              39.174053,
              54.745552
            ],
            [
              39.17405301296159,
              54.745552042906496
            ],
            [
              39.176634,
              54.744772
            ],
            [
              39.176637155084094,
              54.744782447238116
            ],
            [
              39.176865,
              54.744714
            ],
            [
              39.179188,
              54.744353
            ],
            [
              39.187687254240366,
              54.74371258525812
            ],
            [
              39.187726,
              54.743709
            ],
            [
              39.19006699400053,
              54.743533273005404
            ],
            [
              39.192778,
              54.743329
            ],
            [
              39.19277805787409,
              54.743329766671536
            ],
            [
              39.196172,
              54.743075
            ],
            [
              39.206654,
              54.741885
            ],
            [
              39.210399,
              54.740593
            ],
            [
              39.215946,
              54.730176
            ],
            [
              39.21244,
              54.728572
            ],
            [
              39.203782,
              54.724669
            ],
            [
              39.201947,
              54.723958
            ],
            [
              39.201667,
              54.723845
            ],
            [
              39.203017,
              54.720508
            ],
            [
              39.21014940304699,
              54.71952151564473
            ],
            [
              39.208902,
              54.709777
            ],
            [
              39.208368,
              54.709346
            ],
            [
              39.210632,
              54.706546
            ],
            [
              39.222216,
              54.707895
            ],
            [
              39.22295439686972,
              54.70847070779133
            ],
            [
              39.223149,
              54.708089
            ],
            [
              39.223876,
              54.708459
            ],
            [
              39.225148,
              54.710181
            ],
            [
              39.223999,
              54.714129
            ],
            [
              39.222097,
              54.717251
            ],
            [
              39.21829990226773,
              54.725152006852085
            ],
            [
              39.222844,
              54.729727
            ],
            [
              39.22537384950717,
              54.735665064337006
            ],
            [
              39.225374,
              54.735665
            ],
            [
              39.22556952494274,
              54.73603575587944
            ],
            [
              39.227343,
              54.739175
            ],
            [
              39.228689,
              54.742713
            ],
            [
              39.2298858040277,
              54.745593723032236
            ],
            [
              39.229895,
              54.74559
            ],
            [
              39.239464,
              54.748728
            ],
            [
              39.244634216949876,
              54.7476410634056
            ],
            [
              39.245232,
              54.738816
            ],
            [
              39.24640247217276,
              54.736951842315825
            ],
            [
              39.24457,
              54.727435
            ],
            [
              39.247005,
              54.724059
            ],
            [
              39.248817,
              54.720816
            ],
            [
              39.25187,
              54.718976
            ],
            [
              39.252244,
              54.718894
            ],
            [
              39.253018,
              54.72241
            ],
            [
              39.260779,
              54.728359
            ],
            [
              39.266815,
              54.727973
            ],
            [
              39.270603,
              54.7279
            ],
            [
              39.27530662661183,
              54.72778704236279
            ],
            [
              39.28175527965651,
              54.725384294101424
            ],
            [
              39.285193,
              54.717241
            ],
            [
              39.286414,
              54.713854
            ],
            [
              39.287171,
              54.714126
            ],
            [
              39.290838,
              54.715461
            ],
            [
              39.299939,
              54.713917
            ],
            [
              39.301457,
              54.713282
            ],
            [
              39.302043,
              54.714133
            ],
            [
              39.302931,
              54.716566
            ],
            [
              39.311888,
              54.722534
            ],
            [
              39.311711,
              54.726129
            ],
            [
              39.309324,
              54.726012
            ],
            [
              39.306938,
              54.725895
            ],
            [
              39.304849,
              54.72601
            ],
            [
              39.30005,
              54.725611
            ],
            [
              39.295982,
              54.725145
            ],
            [
              39.292701701972405,
              54.72500579916119
            ],
            [
              39.283534,
              54.729595
            ],
            [
              39.279537,
              54.731084
            ],
            [
              39.27675443441111,
              54.73142086442837
            ],
            [
              39.28041,
              54.740442
            ],
            [
              39.28383,
              54.741567
            ],
            [
              39.286642,
              54.74682
            ],
            [
              39.288601,
              54.750792
            ],
            [
              39.296151,
              54.756367
            ],
            [
              39.300019394199445,
              54.757159924582176
            ],
            [
              39.31135446884259,
              54.75662344501405
            ],
            [
              39.315939821957514,
              54.754022566638625
            ],
            [
              39.318832,
              54.752373
            ],
            [
              39.318837735535375,
              54.752383050046845
            ],
            [
              39.32086,
              54.751239
            ],
            [
              39.318279,
              54.741985
            ],
            [
              39.319306,
              54.738534
            ],
            [
              39.325612,
              54.740547
            ],
            [
              39.329656,
              54.741729
            ],
            [
              39.330143519834344,
              54.741847998311286
            ],
            [
              39.330144,
              54.741846
            ],
            [
              39.332503,
              54.74138
            ],
            [
              39.333615,
              54.741235
            ],
            [
              39.343429,
              54.741513
            ],
            [
              39.34454522324347,
              54.742196766466854
            ],
            [
              39.346371,
              54.740899
            ],
            [
              39.349586,
              54.732756
            ],
            [
              39.350532,
              54.731016
            ],
            [
              39.35058979706877,
              54.73104742932605
            ],
            [
              39.350748,
              54.730678
            ],
            [
              39.352842,
              54.727109
            ],
            [
              39.35587,
              54.723181
            ],
            [
              39.358721,
              54.725379
            ],
            [
              39.357207,
              54.727343
            ],
            [
              39.355694,
              54.729307
            ],
            [
              39.35367748361479,
              54.73272647480792
            ],
            [
              39.353695,
              54.732736
            ],
            [
              39.35363659422999,
              54.73315507942633
            ],
            [
              39.35572962031897,
              54.73955312419802
            ],
            [
              39.361981,
              54.741762
            ],
            [
              39.365137,
              54.744495
            ],
            [
              39.365219,
              54.745562
            ],
            [
              39.365013,
              54.747061
            ],
            [
              39.3650072045626,
              54.74707224525233
            ],
            [
              39.367657,
              54.747933
            ],
            [
              39.355395,
              54.760041
            ],
            [
              39.35540095776486,
              54.76004876655139
            ],
            [
              39.356853,
              54.761916
            ],
            [
              39.366256,
              54.769149
            ],
            [
              39.373253,
              54.771664
            ],
            [
              39.38356,
              54.769658
            ],
            [
              39.39330436486032,
              54.76468654816228
            ],
            [
              39.396358,
              54.763121
            ],
            [
              39.398844,
              54.761839
            ],
            [
              39.401331,
              54.760558
            ],
            [
              39.401331064018905,
              54.760558124119086
            ],
            [
              39.408457,
              54.756886
            ],
            [
              39.404128,
              54.745754
            ],
            [
              39.400244,
              54.743771
            ],
            [
              39.39971076959397,
              54.74349861865975
            ],
            [
              39.399547,
              54.743415
            ],
            [
              39.39944913224685,
              54.74336497074608
            ],
            [
              39.39636,
              54.741787
            ],
            [
              39.396360473853605,
              54.74178607197639
            ],
            [
              39.394901,
              54.74104
            ],
            [
              39.387573,
              54.740458
            ],
            [
              39.384839,
              54.739957
            ],
            [
              39.385489,
              54.736416
            ],
            [
              39.391392,
              54.733122
            ],
            [
              39.395649,
              54.733876
            ],
            [
              39.396749,
              54.73537
            ],
            [
              39.405766,
              54.742548
            ],
            [
              39.409306,
              54.744377
            ],
            [
              39.415514,
              54.747047
            ],
            [
              39.415794521007804,
              54.7471024161735
            ],
            [
              39.415795,
              54.7471
            ],
            [
              39.426608,
              54.74754
            ],
            [
              39.42722640511702,
              54.74874078663499
            ],
            [
              39.428022,
              54.748836
            ],
            [
              39.432115,
              54.744748
            ],
            [
              39.43766,
              54.743966
            ],
            [
              39.443801,
              54.738674
            ],
            [
              39.444232,
              54.738452
            ],
            [
              39.44423342252631,
              54.73845198478582
            ],
            [
              39.447638,
              54.736704
            ],
            [
              39.452058,
              54.734436
            ],
            [
              39.454852,
              54.732963
            ],
            [
              39.456531,
              54.736147
            ],
            [
              39.453737,
              54.73762
            ],
            [
              39.449282,
              54.739907
            ],
            [
              39.442987,
              54.748285
            ],
            [
              39.439723,
              54.756847
            ],
            [
              39.437926971529684,
              54.75768610097755
            ],
            [
              39.437434,
              54.758602
            ],
            [
              39.440575,
              54.769511
            ],
            [
              39.448172,
              54.769617
            ],
            [
              39.451892,
              54.769901
            ],
            [
              39.451754307318765,
              54.77169851536594
            ],
            [
              39.451844,
              54.771703
            ],
            [
              39.457781,
              54.767279
            ],
            [
              39.458806,
              54.766165
            ],
            [
              39.459881,
              54.764613
            ],
            [
              39.462487,
              54.766366
            ],
            [
              39.462841,
              54.766662
            ],
            [
              39.464329,
              54.767881
            ],
            [
              39.465497,
              54.771286
            ],
            [
              39.465081,
              54.771429
            ],
            [
              39.460012,
              54.773646
            ],
            [
              39.456263,
              54.782571
            ],
            [
              39.455338,
              54.78605
            ],
            [
              39.452602,
              54.790622
            ],
            [
              39.450109,
              54.79121
            ],
            [
              39.446951,
              54.790384
            ],
            [
              39.440470076035204,
              54.785324677292415
            ],
            [
              39.432349,
              54.788469
            ],
            [
              39.43198524123171,
              54.792690406622114
            ],
            [
              39.43199,
              54.792702
            ],
            [
              39.434341662359486,
              54.79836510563114
            ],
            [
              39.436907,
              54.802254
            ],
            [
              39.443569,
              54.810773
            ],
            [
              39.447569,
              54.808059
            ],
            [
              39.447996,
              54.807905
            ],
            [
              39.450052,
              54.807423
            ],
            [
              39.450874,
              54.810927
            ],
            [
              39.45078361746811,
              54.812719229921385
            ],
            [
              39.454654,
              54.81302
            ],
            [
              39.45878,
              54.810438
            ],
            [
              39.460862,
              54.810765
            ],
            [
              39.461607,
              54.812667
            ],
            [
              39.461969,
              54.81702
            ],
            [
              39.462271,
              54.820608
            ],
            [
              39.455518,
              54.823456
            ],
            [
              39.450575,
              54.823308
            ],
            [
              39.450102,
              54.823335
            ],
            [
              39.448707,
              54.821975
            ],
            [
              39.44593651610225,
              54.81926460602336
            ],
            [
              39.445556,
              54.819254
            ],
            [
              39.435453,
              54.812919
            ],
            [
              39.427153,
              54.816366
            ],
            [
              39.416071,
              54.815989
            ],
            [
              39.411484,
              54.817618
            ],
            [
              39.408039,
              54.819309
            ],
            [
              39.402747,
              54.822891
            ],
            [
              39.39993512848226,
              54.82511968718326
            ],
            [
              39.402598,
              54.83618
            ],
            [
              39.407001,
              54.838203
            ],
            [
              39.414192,
              54.838022
            ],
            [
              39.415563,
              54.841351
            ],
            [
              39.416653,
              54.850417
            ],
            [
              39.419414,
              54.850352
            ],
            [
              39.419748,
              54.850594
            ],
            [
              39.41941675933248,
              54.851758012202815
            ],
            [
              39.425546,
              54.854732
            ],
            [
              39.430713,
              54.856217
            ],
            [
              39.438529,
              54.858618
            ],
            [
              39.444646,
              54.861156
            ],
            [
              39.449522,
              54.863238
            ],
            [
              39.456424,
              54.857634
            ],
            [
              39.458176,
              54.85449
            ],
            [
              39.460032,
              54.855524
            ],
            [
              39.47173,
              54.855703
            ],
            [
              39.475933,
              54.860473
            ],
            [
              39.480956,
              54.864366
            ],
            [
              39.489336,
              54.865151
            ],
            [
              39.490803,
              54.865389
            ],
            [
              39.490815,
              54.866589
            ],
            [
              39.490534,
              54.868851
            ],
            [
              39.490178,
              54.869252
            ],
            [
              39.484595,
              54.873835
            ],
            [
              39.484111,
              54.873518
            ],
            [
              39.482234,
              54.872468
            ],
            [
              39.477435,
              54.867622
            ],
            [
              39.476884122864796,
              54.86752997270722
            ],
            [
              39.476683,
              54.868582
            ],
            [
              39.473671,
              54.868006
            ],
            [
              39.4654,
              54.867829
            ],
            [
              39.457235,
              54.869088
            ],
            [
              39.45693404873474,
              54.86900841959831
            ],
            [
              39.456581,
              54.873496
            ],
            [
              39.455976,
              54.873853
            ],
            [
              39.455133,
              54.874268
            ],
            [
              39.451598,
              54.874616
            ],
            [
              39.443366,
              54.87485
            ],
            [
              39.450529,
              54.88288
            ],
            [
              39.453043,
              54.882896
            ],
            [
              39.458085,
              54.883574
            ],
            [
              39.463127,
              54.884253
            ],
            [
              39.462647,
              54.88782
            ],
            [
              39.452563,
              54.886464
            ],
            [
              39.450529,
              54.88648
            ],
            [
              39.44649786792369,
              54.885470157478835
            ],
            [
              39.446441,
              54.885633
            ],
            [
              39.440451,
              54.89533
            ],
            [
              39.44788,
              54.894494
            ],
            [
              39.449445,
              54.894562
            ],
            [
              39.449779,
              54.894682
            ],
            [
              39.448561,
              54.89807
            ],
            [
              39.448441,
              54.908313
            ],
            [
              39.45115,
              54.915061
            ],
            [
              39.460265,
              54.91441
            ],
            [
              39.465685,
              54.91404
            ],
            [
              39.47039,
              54.912968
            ],
            [
              39.476215,
              54.911154
            ],
            [
              39.479489,
              54.910198
            ],
            [
              39.47951955893139,
              54.910302669640124
            ],
            [
              39.480164,
              54.910088
            ],
            [
              39.484856,
              54.910061
            ],
            [
              39.49208,
              54.908413
            ],
            [
              39.498094,
              54.908052
            ],
            [
              39.502653,
              54.908035
            ],
            [
              39.503707,
              54.908224
            ],
            [
              39.50405942217742,
              54.90931759632994
            ],
            [
              39.513824,
              54.906552
            ],
            [
              39.514219,
              54.906405
            ],
            [
              39.516027,
              54.90796
            ],
            [
              39.517236,
              54.911471
            ],
            [
              39.517978,
              54.914577
            ],
            [
              39.517165,
              54.915071
            ],
            [
              39.510887,
              54.924187
            ],
            [
              39.512899,
              54.927172
            ],
            [
              39.511256,
              54.92828
            ],
            [
              39.509613,
              54.929387
            ],
            [
              39.501862,
              54.938061
            ],
            [
              39.500991,
              54.941302
            ],
            [
              39.497514,
              54.940367
            ],
            [
              39.487589,
              54.939129
            ],
            [
              39.484489,
              54.941889
            ],
            [
              39.481157,
              54.943869
            ],
            [
              39.47787,
              54.946672
            ],
            [
              39.468039,
              54.951311
            ],
            [
              39.46268,
              54.951142
            ],
            [
              39.458467,
              54.950466
            ],
            [
              39.454362,
              54.950454
            ],
            [
              39.450475,
              54.951537
            ],
            [
              39.44642,
              54.952648
            ],
            [
              39.442281,
              54.954219
            ],
            [
              39.438949378418506,
              54.95470337854155
            ],
            [
              39.438952,
              54.954746
            ],
            [
              39.438443,
              54.954777
            ],
            [
              39.434198,
              54.95355
            ],
            [
              39.430065,
              54.952809
            ],
            [
              39.426406,
              54.951776
            ],
            [
              39.42501868068845,
              54.95127179508798
            ],
            [
              39.421444,
              54.950548
            ],
            [
              39.418146,
              54.948774
            ],
            [
              39.408778,
              54.953154
            ],
            [
              39.40507,
              54.952304
            ],
            [
              39.40489934467965,
              54.9522346865113
            ],
            [
              39.402128,
              54.956183
            ],
            [
              39.401842,
              54.968141
            ],
            [
              39.408144,
              54.972201
            ],
            [
              39.41261752742907,
              54.97603687652282
            ],
            [
              39.420049,
              54.976438
            ],
            [
              39.421812,
              54.975006
            ],
            [
              39.423575,
              54.973573
            ],
            [
              39.423587015645715,
              54.97358421728175
            ],
            [
              39.423649,
              54.973513
            ],
            [
              39.427711,
              54.970283
            ],
            [
              39.429957,
              54.973097
            ],
            [
              39.440303,
              54.97258
            ],
            [
              39.441864,
              54.971157
            ],
            [
              39.44429,
              54.973817
            ],
            [
              39.44676964019626,
              54.98052080601732
            ],
            [
              39.45104,
              54.978245
            ],
            [
              39.456987,
              54.975174
            ],
            [
              39.460492,
              54.974749
            ],
            [
              39.464087,
              54.973691
            ],
            [
              39.465532,
              54.973521
            ],
            [
              39.467253,
              54.973434
            ],
            [
              39.46725718404026,
              54.97351712601525
            ],
            [
              39.468554,
              54.973446
            ],
            [
              39.477995,
              54.974175
            ],
            [
              39.481456,
              54.97248
            ],
            [
              39.484494,
              54.975901
            ],
            [
              39.488779,
              54.986184
            ],
            [
              39.496127,
              54.98782
            ],
            [
              39.503497,
              54.988299
            ],
            [
              39.51196834540654,
              54.985659355136384
            ],
            [
              39.517452,
              54.979509
            ],
            [
              39.519772,
              54.977088
            ],
            [
              39.522838,
              54.978976
            ],
            [
              39.525621,
              54.983252
            ],
            [
              39.526341,
              54.986779
            ],
            [
              39.525376,
              54.986976
            ],
            [
              39.522548,
              54.986965
            ],
            [
              39.51144,
              54.990298
            ],
            [
              39.508161,
              54.991956
            ],
            [
              39.503678,
              54.991895
            ],
            [
              39.499469,
              54.992483
            ],
            [
              39.496033,
              54.991598
            ],
            [
              39.49104,
              54.991591
            ],
            [
              39.479535,
              54.989132
            ],
            [
              39.471358,
              54.987464
            ],
            [
              39.467165,
              54.987419
            ],
            [
              39.466655633020466,
              54.98701732125625
            ],
            [
              39.466621,
              54.987056
            ],
            [
              39.465968,
              54.986809
            ],
            [
              39.464029,
              54.984946
            ],
            [
              39.454325,
              54.981464
            ],
            [
              39.447387,
              54.984697
            ],
            [
              39.44689953701798,
              54.984728240867156
            ],
            [
              39.446899,
              54.984747
            ],
            [
              39.446695893779825,
              54.98474129209686
            ],
            [
              39.44468524708423,
              54.98487015182289
            ],
            [
              39.44369113833225,
              54.98497773380584
            ],
            [
              39.444255,
              54.99651
            ],
            [
              39.44068,
              54.999045
            ],
            [
              39.43561028789925,
              55.00812186062004
            ],
            [
              39.441555,
              55.014985
            ],
            [
              39.445907,
              55.013893
            ],
            [
              39.449878,
              55.013046
            ],
            [
              39.455361,
              55.012614
            ],
            [
              39.459235,
              55.012219
            ],
            [
              39.463139,
              55.011812
            ],
            [
              39.467182,
              55.01226
            ],
            [
              39.470692,
              55.012572
            ],
            [
              39.474333,
              55.014851
            ],
            [
              39.479685,
              55.019325
            ],
            [
              39.483107,
              55.021744
            ],
            [
              39.485952,
              55.029111
            ],
            [
              39.48553,
              55.032056
            ],
            [
              39.485176,
              55.034084
            ],
            [
              39.48163,
              55.033465
            ],
            [
              39.479388,
              55.025261
            ],
            [
              39.472465,
              55.019779
            ],
            [
              39.464619,
              55.015563
            ],
            [
              39.461026,
              55.015793
            ],
            [
              39.457336,
              55.016145
            ],
            [
              39.452264,
              55.016495
            ],
            [
              39.446077,
              55.02533
            ],
            [
              39.448285,
              55.034654
            ],
            [
              39.44813027887802,
              55.03744831011262
            ],
            [
              39.450884,
              55.036514
            ],
            [
              39.456877,
              55.036882
            ],
            [
              39.458905,
              55.037678
            ],
            [
              39.46098,
              55.038236
            ],
            [
              39.460045,
              55.041713
            ],
            [
              39.453158149275865,
              55.04756569200442
            ],
            [
              39.454445,
              55.051689
            ],
            [
              39.455458,
              55.052989
            ],
            [
              39.455142,
              55.056563
            ],
            [
              39.45674071815275,
              55.064214074425315
            ],
            [
              39.458835,
              55.065158
            ],
            [
              39.459496,
              55.065399
            ],
            [
              39.466528,
              55.073208
            ],
            [
              39.46972698752991,
              55.0754084004719
            ],
            [
              39.470492,
              55.075489
            ],
            [
              39.479708,
              55.076444
            ],
            [
              39.488928,
              55.077399
            ],
            [
              39.493686,
              55.07787
            ],
            [
              39.499247,
              55.07759
            ],
            [
              39.505077,
              55.067588
            ],
            [
              39.500262,
              55.057238
            ],
            [
              39.497811,
              55.054225
            ],
            [
              39.494223,
              55.051619
            ],
            [
              39.495533,
              55.048266
            ],
            [
              39.504959827874764,
              55.04109113100653
            ],
            [
              39.504958960749335,
              55.04025336396762
            ],
            [
              39.504956609457906,
              55.03835822307474
            ],
            [
              39.501013,
              55.030513
            ],
            [
              39.503804,
              55.027641
            ],
            [
              39.510685,
              55.028794
            ],
            [
              39.514973,
              55.029086
            ],
            [
              39.518865,
              55.029483
            ],
            [
              39.524001,
              55.03004
            ],
            [
              39.528619,
              55.030335
            ],
            [
              39.529499,
              55.033826
            ],
            [
              39.530504,
              55.042286
            ],
            [
              39.538918,
              55.042302
            ],
            [
              39.547333,
              55.042318
            ],
            [
              39.54733299933583,
              55.04231834157608
            ],
            [
              39.552354,
              55.042328
            ],
            [
              39.565518,
              55.042238
            ],
            [
              39.565542,
              55.045838
            ],
            [
              39.552378,
              55.045928
            ],
            [
              39.54143,
              55.045907
            ],
            [
              39.530497,
              55.045886
            ],
            [
              39.526516,
              55.045895
            ],
            [
              39.522527,
              55.045905
            ],
            [
              39.515853,
              55.045921
            ],
            [
              39.508616,
              55.052485
            ],
            [
              39.50866403572594,
              55.05653200991059
            ],
            [
              39.508665,
              55.056532
            ],
            [
              39.51431613871581,
              55.05972153230672
            ],
            [
              39.517319,
              55.060883
            ],
            [
              39.51732,
              55.064483
            ],
            [
              39.50866513344567,
              55.07007780295838
            ],
            [
              39.515186,
              55.077673
            ],
            [
              39.524686,
              55.080719
            ],
            [
              39.530258,
              55.076799
            ],
            [
              39.533813,
              55.075563
            ],
            [
              39.536942,
              55.077513
            ],
            [
              39.544625,
              55.085288
            ],
            [
              39.549249,
              55.081887
            ],
            [
              39.552438,
              55.079629
            ],
            [
              39.555625,
              55.070542
            ],
            [
              39.562386,
              55.06096
            ],
            [
              39.570933,
              55.060935
            ],
            [
              39.57751718795277,
              55.06091651158174
            ],
            [
              39.578391,
              55.060914
            ],
            [
              39.578391000176964,
              55.06091405791456
            ],
            [
              39.57885503265512,
              55.060912754910056
            ],
            [
              39.583553,
              55.060899
            ],
            [
              39.591289,
              55.060912
            ],
            [
              39.600435,
              55.057953
            ],
            [
              39.604696,
              55.056665
            ],
            [
              39.606107,
              55.056394
            ],
            [
              39.606787,
              55.05993
            ],
            [
              39.605375,
              55.060201
            ],
            [
              39.59946,
              55.064471
            ],
            [
              39.596263056617204,
              55.064487029678205
            ],
            [
              39.596263,
              55.064521
            ],
            [
              39.583547,
              55.064499
            ],
            [
              39.579489999714895,
              55.06451089736154
            ],
            [
              39.57949,
              55.064511
            ],
            [
              39.57866590905143,
              55.0645133140497
            ],
            [
              39.572976,
              55.06453
            ],
            [
              39.56703,
              55.068793
            ],
            [
              39.561208,
              55.07463
            ],
            [
              39.558813,
              55.078823
            ],
            [
              39.556485,
              55.081607
            ],
            [
              39.552479,
              55.087904
            ],
            [
              39.547794,
              55.093398
            ],
            [
              39.548981359556514,
              55.100849337216864
            ],
            [
              39.55192703337619,
              55.102275406428795
            ],
            [
              39.55885,
              55.101735
            ],
            [
              39.564822,
              55.101341
            ],
            [
              39.571393,
              55.10041
            ],
            [
              39.579215,
              55.102783
            ],
            [
              39.579213967615836,
              55.10280509793723
            ],
            [
              39.57942,
              55.102816
            ],
            [
              39.583431,
              55.103076
            ],
            [
              39.587058,
              55.103978
            ],
            [
              39.591788,
              55.103881
            ],
            [
              39.598367,
              55.094048
            ],
            [
              39.598338,
              55.092184
            ],
            [
              39.602691,
              55.092078
            ],
            [
              39.602888,
              55.095673
            ],
            [
              39.601518,
              55.101602
            ],
            [
              39.607453,
              55.109141
            ],
            [
              39.611604,
              55.108231
            ],
            [
              39.621444,
              55.1019
            ],
            [
              39.618659,
              55.097073
            ],
            [
              39.616843,
              55.093925
            ],
            [
              39.615027,
              55.090776
            ],
            [
              39.61502737579218,
              55.09077578336827
            ],
            [
              39.613725,
              55.088517
            ],
            [
              39.610485,
              55.082593
            ],
            [
              39.610924749258686,
              55.08235259260217
            ],
            [
              39.610239,
              55.080843
            ],
            [
              39.612701,
              55.077978
            ],
            [
              39.61923,
              55.076595
            ],
            [
              39.623246,
              55.075878
            ],
            [
              39.625354,
              55.075515
            ],
            [
              39.626115182687194,
              55.07538422252835
            ],
            [
              39.62712137016032,
              55.075211060774414
            ],
            [
              39.627934,
              55.075071
            ],
            [
              39.627934035015656,
              55.07507120366481
            ],
            [
              39.628034,
              55.075054
            ],
            [
              39.631667,
              55.074402
            ],
            [
              39.635508,
              55.073705
            ],
            [
              39.641279,
              55.072652
            ],
            [
              39.649469,
              55.071116
            ],
            [
              39.657586,
              55.063911
            ],
            [
              39.655393,
              55.059985
            ],
            [
              39.658536,
              55.058229
            ],
            [
              39.662922,
              55.066081
            ],
            [
              39.664155,
              55.06832
            ],
            [
              39.66330429243165,
              55.07097406403392
            ],
            [
              39.663677,
              55.071344
            ],
            [
              39.663272,
              55.071751
            ],
            [
              39.66297,
              55.072017
            ],
            [
              39.658316,
              55.073119
            ],
            [
              39.65384395888522,
              55.073957780927564
            ],
            [
              39.653844,
              55.073958
            ],
            [
              39.64830111533335,
              55.074997640216466
            ],
            [
              39.641943,
              55.076191
            ],
            [
              39.636155,
              55.077246
            ],
            [
              39.632304,
              55.077945
            ],
            [
              39.62867,
              55.078597
            ],
            [
              39.62854394956488,
              55.07861870664951
            ],
            [
              39.628544,
              55.078619
            ],
            [
              39.6262,
              55.079023
            ],
            [
              39.623856,
              55.079426
            ],
            [
              39.616883,
              55.086789
            ],
            [
              39.61814571899782,
              55.08897816198843
            ],
            [
              39.618146,
              55.088978
            ],
            [
              39.619015836420125,
              55.090486674958974
            ],
            [
              39.61932,
              55.091014
            ],
            [
              39.621777,
              55.095275
            ],
            [
              39.624562,
              55.1001
            ],
            [
              39.626837,
              55.107644
            ],
            [
              39.632208,
              55.111533
            ],
            [
              39.646564,
              55.109053
            ],
            [
              39.66092,
              55.106574
            ],
            [
              39.661533,
              55.110121
            ],
            [
              39.647772401594324,
              55.1124981443331
            ],
            [
              39.66107432074818,
              55.11386794672784
            ],
            [
              39.662349,
              55.109941
            ],
            [
              39.662482,
              55.106344
            ],
            [
              39.664712,
              55.105919
            ],
            [
              39.674181,
              55.104284
            ],
            [
              39.676315532844946,
              55.10391516859587
            ],
            [
              39.676318,
              55.103914
            ],
            [
              39.6813537803371,
              55.10304459689148
            ],
            [
              39.683649,
              55.102648
            ],
            [
              39.683649056760075,
              55.10264832852323
            ],
            [
              39.687943,
              55.101907
            ],
            [
              39.691777,
              55.101287
            ],
            [
              39.695596,
              55.10067
            ],
            [
              39.70028607417794,
              55.09987136423743
            ],
            [
              39.701168,
              55.099721
            ],
            [
              39.70116803110114,
              55.09972118274494
            ],
            [
              39.70870533504435,
              55.09843771466874
            ],
            [
              39.71339132990007,
              55.09763941608699
            ],
            [
              39.728882,
              55.090838
            ],
            [
              39.727615,
              55.088264
            ],
            [
              39.726329,
              55.085947
            ],
            [
              39.725044,
              55.08363
            ],
            [
              39.728192,
              55.081884
            ],
            [
              39.729477,
              55.084201
            ],
            [
              39.730763,
              55.086518
            ],
            [
              39.737930359490676,
              55.09200452936344
            ],
            [
              39.74104479137593,
              55.09301693218295
            ],
            [
              39.744171,
              55.092522
            ],
            [
              39.748332,
              55.091923
            ],
            [
              39.752493,
              55.091325
            ],
            [
              39.753005,
              55.094888
            ],
            [
              39.748844,
              55.095487
            ],
            [
              39.744684,
              55.096085
            ],
            [
              39.741607938369896,
              55.09657261073418
            ],
            [
              39.741608,
              55.096573
            ],
            [
              39.74064670564637,
              55.096724983297015
            ],
            [
              39.740123,
              55.096808
            ],
            [
              39.740122200736245,
              55.096807908974505
            ],
            [
              39.738572,
              55.097053
            ],
            [
              39.735537,
              55.097534
            ],
            [
              39.731316,
              55.095805
            ],
            [
              39.71716514953984,
              55.10020278465603
            ],
            [
              39.717239,
              55.100636
            ],
            [
              39.70672,
              55.102428
            ],
            [
              39.7001930768382,
              55.103539295691874
            ],
            [
              39.69948095776859,
              55.10366075226568
            ],
            [
              39.699481,
              55.103661
            ],
            [
              39.696201,
              55.104219
            ],
            [
              39.692351,
              55.104841
            ],
            [
              39.688517,
              55.105461
            ],
            [
              39.68426196829107,
              55.10619581647101
            ],
            [
              39.684262,
              55.106196
            ],
            [
              39.6769299942418,
              55.10746200796437
            ],
            [
              39.67330151526284,
              55.11248069265451
            ],
            [
              39.67175840198942,
              55.117291336889565
            ],
            [
              39.67185207621001,
              55.117451465629514
            ],
            [
              39.67527596875185,
              55.12137209272334
            ],
            [
              39.681638,
              55.126757
            ],
            [
              39.686306,
              55.125874
            ],
            [
              39.693108,
              55.12676
            ],
            [
              39.696102,
              55.126284
            ],
            [
              39.697853,
              55.126202
            ],
            [
              39.6978552627274,
              55.12625043314131
            ],
            [
              39.698204,
              55.126213
            ],
            [
              39.702696,
              55.126428
            ],
            [
              39.706305,
              55.126415
            ],
            [
              39.713798,
              55.128736
            ],
            [
              39.717158,
              55.129946
            ],
            [
              39.725281,
              55.131331
            ],
            [
              39.72882,
              55.131644
            ],
            [
              39.733238,
              55.132054
            ],
            [
              39.737235,
              55.132594
            ],
            [
              39.741832,
              55.134174
            ],
            [
              39.741433,
              55.137752
            ],
            [
              39.740121,
              55.137606
            ],
            [
              39.731381,
              55.135489
            ],
            [
              39.72950857688495,
              55.13525148493009
            ],
            [
              39.729493,
              55.135367
            ],
            [
              39.728339,
              55.135211
            ],
            [
              39.722177,
              55.134967
            ],
            [
              39.714886,
              55.132897
            ],
            [
              39.710959,
              55.132493
            ],
            [
              39.70682,
              55.13103
            ],
            [
              39.699113,
              55.130591
            ],
            [
              39.695394,
              55.129819
            ],
            [
              39.686975,
              55.129412
            ],
            [
              39.679487,
              55.13436
            ],
            [
              39.67485,
              55.136378
            ],
            [
              39.66599,
              55.13872
            ],
            [
              39.660337,
              55.139285
            ],
            [
              39.65656,
              55.140171
            ],
            [
              39.653553,
              55.142371
            ],
            [
              39.65538,
              55.15404
            ],
            [
              39.662384,
              55.155134
            ],
            [
              39.662935,
              55.15554
            ],
            [
              39.663106954251205,
              55.15569427101261
            ],
            [
              39.668576,
              55.157072
            ],
            [
              39.67625,
              55.160298
            ],
            [
              39.680399,
              55.16176
            ],
            [
              39.683746,
              55.163775
            ],
            [
              39.6885,
              55.165438
            ],
            [
              39.690352,
              55.168433
            ],
            [
              39.699579,
              55.174489
            ],
            [
              39.706917,
              55.17557
            ],
            [
              39.710385,
              55.17624
            ],
            [
              39.714192,
              55.17864
            ],
            [
              39.71626,
              55.181648
            ],
            [
              39.720702,
              55.192261
            ],
            [
              39.71773,
              55.194293
            ],
            [
              39.716467,
              55.192446
            ],
            [
              39.715204,
              55.190598
            ],
            [
              39.714312,
              55.185657
            ],
            [
              39.706125,
              55.179129
            ],
            [
              39.70177499577506,
              55.178745706607685
            ],
            [
              39.70168,
              55.179045
            ],
            [
              39.700337,
              55.178619
            ],
            [
              39.695388,
              55.177255
            ],
            [
              39.690174,
              55.175032
            ],
            [
              39.688111016745964,
              55.17260965837268
            ],
            [
              39.687933,
              55.172685
            ],
            [
              39.681991,
              55.166918
            ],
            [
              39.673894,
              55.163728
            ],
            [
              39.669157,
              55.162788
            ],
            [
              39.66202,
              55.159127
            ],
            [
              39.64834,
              55.154883
            ],
            [
              39.642716,
              55.158644
            ],
            [
              39.632847,
              55.160404
            ],
            [
              39.627237,
              55.1624
            ],
            [
              39.622081,
              55.172581
            ],
            [
              39.625463,
              55.176309
            ],
            [
              39.62546,
              55.179324
            ],
            [
              39.625457,
              55.182338
            ],
            [
              39.624817,
              55.192622
            ],
            [
              39.624763,
              55.196427
            ],
            [
              39.625127,
              55.203508
            ],
            [
              39.624256,
              55.20836
            ],
            [
              39.624611,
              55.213401
            ],
            [
              39.6250588006185,
              55.21405431880276
            ],
            [
              39.63347979371695,
              55.22141099062735
            ],
            [
              39.63388326739098,
              55.22154874986309
            ],
            [
              39.635658,
              55.22207
            ],
            [
              39.63559026337928,
              55.22230050471721
            ],
            [
              39.637712,
              55.223235
            ],
            [
              39.640593,
              55.227743
            ],
            [
              39.650315,
              55.232367
            ],
            [
              39.655118,
              55.229374
            ],
            [
              39.65541259332637,
              55.2292246390734
            ],
            [
              39.658273,
              55.226782
            ],
            [
              39.659377,
              55.223448
            ],
            [
              39.666363,
              55.223295
            ],
            [
              39.668267811487716,
              55.226219789642926
            ],
            [
              39.668478,
              55.226459
            ],
            [
              39.679329,
              55.231324
            ],
            [
              39.683988,
              55.22813
            ],
            [
              39.685156,
              55.226523
            ],
            [
              39.688754,
              55.226402
            ],
            [
              39.688794,
              55.2276
            ],
            [
              39.685223,
              55.238272
            ],
            [
              39.68518128293407,
              55.23928003246694
            ],
            [
              39.685286,
              55.239287
            ],
            [
              39.685147,
              55.241365
            ],
            [
              39.694064,
              55.24915
            ],
            [
              39.697604,
              55.249227
            ],
            [
              39.701247,
              55.249787
            ],
            [
              39.704029,
              55.252072
            ],
            [
              39.70440438639814,
              55.25289860431412
            ],
            [
              39.708807,
              55.252492
            ],
            [
              39.717991,
              55.244995
            ],
            [
              39.718913,
              55.235903
            ],
            [
              39.726481,
              55.22817
            ],
            [
              39.725512,
              55.217246
            ],
            [
              39.721732,
              55.209206
            ],
            [
              39.717592,
              55.205942
            ],
            [
              39.719822,
              55.203115
            ],
            [
              39.723961,
              55.206379
            ],
            [
              39.728101,
              55.209644
            ],
            [
              39.72850304165864,
              55.21125067094387
            ],
            [
              39.728798,
              55.211235
            ],
            [
              39.730789,
              55.220386
            ],
            [
              39.732636,
              55.224378
            ],
            [
              39.732161699543845,
              55.225763977978524
            ],
            [
              39.73259,
              55.225982
            ],
            [
              39.731056,
              55.228995
            ],
            [
              39.729918,
              55.232448
            ],
            [
              39.72694,
              55.235592
            ],
            [
              39.724662,
              55.240197
            ],
            [
              39.722703,
              55.24314
            ],
            [
              39.728625,
              55.252084
            ],
            [
              39.7367,
              55.251425
            ],
            [
              39.743206,
              55.251746
            ],
            [
              39.751728,
              55.250891
            ],
            [
              39.755653,
              55.249732
            ],
            [
              39.766452,
              55.245477
            ],
            [
              39.767449,
              55.245284
            ],
            [
              39.768081,
              55.24525
            ],
            [
              39.770971,
              55.24712
            ],
            [
              39.771148657232935,
              55.24737609168968
            ],
            [
              39.771987,
              55.247132
            ],
            [
              39.776309,
              55.247108
            ],
            [
              39.790514,
              55.245695
            ],
            [
              39.795327,
              55.244848
            ],
            [
              39.798568,
              55.234429
            ],
            [
              39.798598844353634,
              55.23434524419087
            ],
            [
              39.798267,
              55.232311
            ],
            [
              39.794154,
              55.230614
            ],
            [
              39.790204,
              55.229023
            ],
            [
              39.78922634957149,
              55.22831800611449
            ],
            [
              39.787724,
              55.227618
            ],
            [
              39.780255,
              55.228894
            ],
            [
              39.778668,
              55.228909
            ],
            [
              39.776815,
              55.228341
            ],
            [
              39.776885,
              55.224742
            ],
            [
              39.784964,
              55.216871
            ],
            [
              39.785536,
              55.216274
            ],
            [
              39.787898,
              55.215625
            ],
            [
              39.791208,
              55.214681
            ],
            [
              39.7918,
              55.214882
            ],
            [
              39.792031,
              55.21496
            ],
            [
              39.796029,
              55.216322
            ],
            [
              39.800019,
              55.217682
            ],
            [
              39.80204532727376,
              55.21914080196872
            ],
            [
              39.802642,
              55.218921
            ],
            [
              39.803611,
              55.218636
            ],
            [
              39.803774,
              55.218594
            ],
            [
              39.81298296985326,
              55.220830845431024
            ],
            [
              39.816893,
              55.215659
            ],
            [
              39.817284,
              55.215463
            ],
            [
              39.82623,
              55.22271
            ],
            [
              39.824858,
              55.226039
            ],
            [
              39.823595,
              55.225518
            ],
            [
              39.821178,
              55.22466
            ],
            [
              39.8137266625588,
              55.225629847382685
            ],
            [
              39.8100558824207,
              55.22889330442609
            ],
            [
              39.807007,
              55.23562
            ],
            [
              39.813377,
              55.244294
            ],
            [
              39.82046,
              55.242573
            ],
            [
              39.824929,
              55.242504
            ],
            [
              39.829397,
              55.242435
            ],
            [
              39.8293970081955,
              55.24243552685333
            ],
            [
              39.831896,
              55.242397
            ],
            [
              39.835723,
              55.242295
            ],
            [
              39.842536,
              55.242174
            ],
            [
              39.845739,
              55.242108
            ],
            [
              39.848943,
              55.242041
            ],
            [
              39.8489430304679,
              55.242042462052936
            ],
            [
              39.849891,
              55.242023
            ],
            [
              39.856653,
              55.241881
            ],
            [
              39.860399,
              55.241812
            ],
            [
              39.86149,
              55.241792
            ],
            [
              39.861557,
              55.245391
            ],
            [
              39.857791,
              55.25295
            ],
            [
              39.856669,
              55.253379
            ],
            [
              39.852861,
              55.253691
            ],
            [
              39.8482,
              55.253661
            ],
            [
              39.843467631046,
              55.253458848400086
            ],
            [
              39.843322,
              55.253664
            ],
            [
              39.840445,
              55.253648
            ],
            [
              39.836495,
              55.253161
            ],
            [
              39.827063,
              55.251914
            ],
            [
              39.82219152720246,
              55.249684807152796
            ],
            [
              39.819941,
              55.249878
            ],
            [
              39.816457,
              55.250786
            ],
            [
              39.809367,
              55.258743
            ],
            [
              39.805786,
              55.259116
            ],
            [
              39.805446,
              55.255855
            ],
            [
              39.805698,
              55.253514
            ],
            [
              39.795951,
              55.248394
            ],
            [
              39.79325798613367,
              55.24886792120191
            ],
            [
              39.793258,
              55.248868
            ],
            [
              39.790496,
              55.251208
            ],
            [
              39.786896,
              55.251193
            ],
            [
              39.776498,
              55.250703
            ],
            [
              39.77023672498061,
              55.26066695592785
            ],
            [
              39.770402,
              55.261911
            ],
            [
              39.774354,
              55.267578
            ],
            [
              39.775386,
              55.269644
            ],
            [
              39.775386,
              55.270455
            ],
            [
              39.77520400274977,
              55.270455
            ],
            [
              39.775321,
              55.270851
            ],
            [
              39.775321,
              55.271133
            ],
            [
              39.769629,
              55.281583
            ],
            [
              39.775862,
              55.283774
            ],
            [
              39.780407,
              55.286508
            ],
            [
              39.782243,
              55.287686
            ],
            [
              39.78340871006747,
              55.288434976659154
            ],
            [
              39.78749,
              55.291055
            ],
            [
              39.794572,
              55.295602
            ],
            [
              39.80225,
              55.293183
            ],
            [
              39.807328,
              55.295738
            ],
            [
              39.810572,
              55.298176
            ],
            [
              39.810853,
              55.300028
            ],
            [
              39.810723195096806,
              55.30108823128957
            ],
            [
              39.814228,
              55.30176
            ],
            [
              39.817952,
              55.302097
            ],
            [
              39.822853,
              55.302308
            ],
            [
              39.825597,
              55.302289
            ],
            [
              39.827795,
              55.30223
            ],
            [
              39.82779718403067,
              55.30231187839977
            ],
            [
              39.829227,
              55.302313
            ],
            [
              39.834754,
              55.302151
            ],
            [
              39.838786,
              55.302254
            ],
            [
              39.840402,
              55.302421
            ],
            [
              39.840848,
              55.302451
            ],
            [
              39.840608,
              55.306043
            ],
            [
              39.834398,
              55.314451
            ],
            [
              39.83432,
              55.314589
            ],
            [
              39.831915,
              55.31813
            ],
            [
              39.830358,
              55.319049
            ],
            [
              39.828066,
              55.319469
            ],
            [
              39.822047,
              55.31928
            ],
            [
              39.820749,
              55.31936
            ],
            [
              39.814136,
              55.31981
            ],
            [
              39.813239,
              55.319835
            ],
            [
              39.806004,
              55.322091
            ],
            [
              39.805772,
              55.322132
            ],
            [
              39.803548,
              55.321401
            ],
            [
              39.800483,
              55.320434
            ],
            [
              39.800323,
              55.320149
            ],
            [
              39.799711,
              55.316999
            ],
            [
              39.803469,
              55.310531
            ],
            [
              39.7994262268174,
              55.304480690332575
            ],
            [
              39.793876,
              55.302557
            ],
            [
              39.787421,
              55.302446
            ],
            [
              39.786086,
              55.302578
            ],
            [
              39.78594003498358,
              55.301105192425915
            ],
            [
              39.784802,
              55.300897
            ],
            [
              39.78509900405897,
              55.29817359669746
            ],
            [
              39.778462,
              55.289537
            ],
            [
              39.768458,
              55.284987
            ],
            [
              39.76597104945356,
              55.28413185624259
            ],
            [
              39.765971,
              55.284132
            ],
            [
              39.765099,
              55.283832
            ],
            [
              39.761626,
              55.282637
            ],
            [
              39.758055,
              55.281409
            ],
            [
              39.753452,
              55.279823
            ],
            [
              39.74885,
              55.278239
            ],
            [
              39.744214,
              55.276604
            ],
            [
              39.73959,
              55.274973
            ],
            [
              39.734457,
              55.273181
            ],
            [
              39.729141,
              55.271325
            ],
            [
              39.72500114992872,
              55.26986834997493
            ],
            [
              39.724227,
              55.269596
            ],
            [
              39.720654,
              55.268338
            ],
            [
              39.716652,
              55.266917
            ],
            [
              39.706868,
              55.266368
            ],
            [
              39.704546,
              55.269125
            ],
            [
              39.702649,
              55.272024
            ],
            [
              39.70194,
              55.273201
            ],
            [
              39.698857,
              55.271342
            ],
            [
              39.692982,
              55.261843
            ],
            [
              39.689103,
              55.260364
            ],
            [
              39.682862,
              55.270597
            ],
            [
              39.685203,
              55.273252
            ],
            [
              39.686407,
              55.274713
            ],
            [
              39.68784,
              55.276827
            ],
            [
              39.687829424115485,
              55.27683416888816
            ],
            [
              39.688006,
              55.277075
            ],
            [
              39.690816,
              55.281604
            ],
            [
              39.697707,
              55.290347
            ],
            [
              39.705555,
              55.295854
            ],
            [
              39.705572,
              55.299454
            ],
            [
              39.700287,
              55.299382
            ],
            [
              39.695471,
              55.309917
            ],
            [
              39.69131,
              55.317445
            ],
            [
              39.698113,
              55.323363
            ],
            [
              39.7025,
              55.323966
            ],
            [
              39.706115,
              55.324109
            ],
            [
              39.707316,
              55.324181
            ],
            [
              39.70880129067633,
              55.32451389705214
            ],
            [
              39.710653,
              55.324793
            ],
            [
              39.714179,
              55.325064
            ],
            [
              39.725646,
              55.327576
            ],
            [
              39.732632,
              55.323813
            ],
            [
              39.73308,
              55.323563
            ],
            [
              39.734835,
              55.326706
            ],
            [
              39.733475,
              55.3281
            ],
            [
              39.730144,
              55.330082
            ],
            [
              39.726294,
              55.331117
            ],
            [
              39.723323229817595,
              55.33186439776376
            ],
            [
              39.722972,
              55.332001
            ],
            [
              39.719191,
              55.332904
            ],
            [
              39.708685,
              55.328169
            ],
            [
              39.700498,
              55.328028
            ],
            [
              39.693172,
              55.326253
            ],
            [
              39.69290186663952,
              55.32611755837488
            ],
            [
              39.692888,
              55.326212
            ],
            [
              39.688892,
              55.325626
            ],
            [
              39.685067,
              55.320736
            ],
            [
              39.679161,
              55.315221
            ],
            [
              39.673033,
              55.317607
            ],
            [
              39.67115366550788,
              55.31953200165127
            ],
            [
              39.669807,
              55.321451
            ],
            [
              39.669806912968376,
              55.32145093895679
            ],
            [
              39.6693,
              55.322174
            ],
            [
              39.66881371171583,
              55.32192881548749
            ],
            [
              39.666572,
              55.324225
            ],
            [
              39.665149,
              55.331451
            ],
            [
              39.672106,
              55.337035
            ],
            [
              39.676495,
              55.337114
            ],
            [
              39.680883,
              55.337194
            ],
            [
              39.680818,
              55.340793
            ],
            [
              39.673151,
              55.344287
            ],
            [
              39.661335,
              55.34484
            ],
            [
              39.659937,
              55.341522
            ],
            [
              39.651444,
              55.335699
            ],
            [
              39.646975,
              55.335007
            ],
            [
              39.646321,
              55.334607
            ],
            [
              39.643864,
              55.331976
            ],
            [
              39.644022,
              55.331829
            ],
            [
              39.649073,
              55.327116
            ],
            [
              39.650053,
              55.327511
            ],
            [
              39.660033,
              55.323385
            ],
            [
              39.650891,
              55.315717
            ],
            [
              39.650347,
              55.315329
            ],
            [
              39.652439,
              55.312399
            ],
            [
              39.655666,
              55.312955
            ],
            [
              39.663811558375315,
              55.31555994937446
            ],
            [
              39.667706,
              55.314489
            ],
            [
              39.674419,
              55.312004
            ],
            [
              39.679138,
              55.311621
            ],
            [
              39.679388560873264,
              55.311610609644276
            ],
            [
              39.679388,
              55.311602
            ],
            [
              39.682436,
              55.311403
            ],
            [
              39.686318,
              55.300864
            ],
            [
              39.682773,
              55.289741
            ],
            [
              39.678785,
              55.288678
            ],
            [
              39.671612,
              55.290982
            ],
            [
              39.668285,
              55.292278
            ],
            [
              39.661471,
              55.293216
            ],
            [
              39.654249,
              55.292915
            ],
            [
              39.653179,
              55.292813
            ],
            [
              39.65298,
              55.292784
            ],
            [
              39.6535,
              55.289222
            ],
            [
              39.658059,
              55.285691
            ],
            [
              39.658793,
              55.286297
            ],
            [
              39.667157317600505,
              55.28772679788043
            ],
            [
              39.672571,
              55.285164
            ],
            [
              39.676436,
              55.284833
            ],
            [
              39.682246,
              55.275342
            ],
            [
              39.679682,
              55.272416
            ],
            [
              39.677859,
              55.269124
            ],
            [
              39.675874,
              55.26591
            ],
            [
              39.66722,
              55.260527
            ],
            [
              39.659027,
              55.257057
            ],
            [
              39.65869889988364,
              55.25727918333962
            ],
            [
              39.657714,
              55.259032
            ],
            [
              39.652445,
              55.261647
            ],
            [
              39.650370099303096,
              55.26295878325265
            ],
            [
              39.649699,
              55.264722
            ],
            [
              39.649263,
              55.265435
            ],
            [
              39.64498657736732,
              55.27308876159605
            ],
            [
              39.64492,
              55.274186
            ],
            [
              39.645227,
              55.276502
            ],
            [
              39.645149,
              55.27872
            ],
            [
              39.64471319620537,
              55.27870473838851
            ],
            [
              39.644646,
              55.280173
            ],
            [
              39.638864,
              55.286393
            ],
            [
              39.636642,
              55.283347
            ],
            [
              39.639196,
              55.279633
            ],
            [
              39.6341,
              55.270947
            ],
            [
              39.622633,
              55.273117
            ],
            [
              39.621262,
              55.276446
            ],
            [
              39.61391,
              55.270418
            ],
            [
              39.60417,
              55.27201
            ],
            [
              39.597693,
              55.271939
            ],
            [
              39.5924,
              55.271558
            ],
            [
              39.58892,
              55.271957
            ],
            [
              39.585239,
              55.272519
            ],
            [
              39.580867,
              55.273247
            ],
            [
              39.576706396771954,
              55.2800228158296
            ],
            [
              39.578453,
              55.284299
            ],
            [
              39.581049206546616,
              55.289536754345974
            ],
            [
              39.585779,
              55.29456
            ],
            [
              39.589731,
              55.296463
            ],
            [
              39.595362,
              55.296998
            ],
            [
              39.598938,
              55.297406
            ],
            [
              39.602163,
              55.299321
            ],
            [
              39.609278,
              55.300662
            ],
            [
              39.613305,
              55.302284
            ],
            [
              39.617335,
              55.303085
            ],
            [
              39.62128,
              55.304335
            ],
            [
              39.625483,
              55.305688
            ],
            [
              39.63104,
              55.305775
            ],
            [
              39.634886,
              55.306031
            ],
            [
              39.638622,
              55.306956
            ],
            [
              39.636461,
              55.31009
            ],
            [
              39.630853,
              55.30937
            ],
            [
              39.624976,
              55.309252
            ],
            [
              39.620324,
              55.31014
            ],
            [
              39.612313,
              55.305745
            ],
            [
              39.602425,
              55.303754
            ],
            [
              39.595771,
              55.300766
            ],
            [
              39.591655,
              55.30037
            ],
            [
              39.587703,
              55.299438
            ],
            [
              39.584312,
              55.297847
            ],
            [
              39.578861,
              55.296443
            ],
            [
              39.574949,
              55.295847
            ],
            [
              39.570509,
              55.294833
            ],
            [
              39.563211,
              55.294053
            ],
            [
              39.557805,
              55.293905
            ],
            [
              39.556491,
              55.304545
            ],
            [
              39.559994,
              55.307087
            ],
            [
              39.570139,
              55.311224
            ],
            [
              39.575051,
              55.311108
            ],
            [
              39.581037,
              55.311168
            ],
            [
              39.58047,
              55.314723
            ],
            [
              39.575066,
              55.314708
            ],
            [
              39.570646,
              55.314788
            ],
            [
              39.565534,
              55.316628
            ],
            [
              39.561942,
              55.318348
            ],
            [
              39.560812,
              55.31493
            ],
            [
              39.55816038893367,
              55.311186892428694
            ],
            [
              39.558004,
              55.311258
            ],
            [
              39.549563,
              55.308538
            ],
            [
              39.543029,
              55.308383
            ],
            [
              39.53542,
              55.315701
            ],
            [
              39.533068,
              55.319046
            ],
            [
              39.529746,
              55.320342
            ],
            [
              39.525243,
              55.320899
            ],
            [
              39.521257,
              55.321532
            ],
            [
              39.516394,
              55.323112
            ],
            [
              39.512699,
              55.323686
            ],
            [
              39.511864,
              55.320085
            ],
            [
              39.515862,
              55.319551
            ],
            [
              39.520271,
              55.31807
            ],
            [
              39.52311952148048,
              55.31760225732798
            ],
            [
              39.523111,
              55.317504
            ],
            [
              39.529812,
              55.311073
            ],
            [
              39.525825,
              55.304349
            ],
            [
              39.525371,
              55.300743
            ],
            [
              39.524034,
              55.296995
            ],
            [
              39.51621467958709,
              55.29471965860954
            ],
            [
              39.516265,
              55.29555
            ],
            [
              39.515145,
              55.295938
            ],
            [
              39.507194,
              55.296506
            ],
            [
              39.50190899709198,
              55.29714716879412
            ],
            [
              39.500969,
              55.297431
            ],
            [
              39.497713162976936,
              55.29849344413951
            ],
            [
              39.492187,
              55.306251
            ],
            [
              39.492061,
              55.310018
            ],
            [
              39.488575,
              55.311546
            ],
            [
              39.48178498927644,
              55.3181415812657
            ],
            [
              39.479083,
              55.323539
            ],
            [
              39.47855,
              55.327139
            ],
            [
              39.475977,
              55.321856
            ],
            [
              39.47741,
              55.317503
            ],
            [
              39.479576,
              55.314677
            ],
            [
              39.480883,
              55.310735
            ],
            [
              39.483938,
              55.308348
            ],
            [
              39.48838899471147,
              55.3012881350181
            ],
            [
              39.473971928961625,
              55.29057728981133
            ],
            [
              39.471709,
              55.291789
            ],
            [
              39.464678,
              55.298946
            ],
            [
              39.465358,
              55.302481
            ],
            [
              39.45467552875962,
              55.30186064531796
            ],
            [
              39.455617,
              55.310176
            ],
            [
              39.45361846597631,
              55.31181037598033
            ],
            [
              39.453704,
              55.312686
            ],
            [
              39.45239,
              55.312815
            ],
            [
              39.44875,
              55.31318
            ],
            [
              39.44498,
              55.313465
            ],
            [
              39.441559457788486,
              55.313719242702966
            ],
            [
              39.44156,
              55.313727
            ],
            [
              39.439733,
              55.313855
            ],
            [
              39.435831,
              55.313791
            ],
            [
              39.432241,
              55.312768
            ],
            [
              39.428586,
              55.311458
            ],
            [
              39.421416,
              55.320549
            ],
            [
              39.419589,
              55.331417
            ],
            [
              39.423227,
              55.334005
            ],
            [
              39.426841,
              55.336576
            ],
            [
              39.434196,
              55.339583
            ],
            [
              39.437337,
              55.340106
            ],
            [
              39.437757,
              55.340272
            ],
            [
              39.437545589827394,
              55.340807401859216
            ],
            [
              39.438212,
              55.341051
            ],
            [
              39.446637,
              55.342164
            ],
            [
              39.451332,
              55.34146
            ],
            [
              39.456074,
              55.341307
            ],
            [
              39.458755,
              55.344643
            ],
            [
              39.450654,
              55.350215
            ],
            [
              39.448643,
              55.35319
            ],
            [
              39.439072,
              55.35651
            ],
            [
              39.431969,
              55.361767
            ],
            [
              39.426529,
              55.362421
            ],
            [
              39.423045,
              55.36172
            ],
            [
              39.413327116830914,
              55.3616868730672
            ],
            [
              39.411589,
              55.36227
            ],
            [
              39.409582,
              55.362831
            ],
            [
              39.40552368534564,
              55.363309943685124
            ],
            [
              39.404633,
              55.364191
            ],
            [
              39.401823,
              55.364735
            ],
            [
              39.397725,
              55.364005
            ],
            [
              39.388951,
              55.362645
            ],
            [
              39.382232,
              55.369343
            ],
            [
              39.381220227646885,
              55.37066200182737
            ],
            [
              39.3813,
              55.370701
            ],
            [
              39.380952,
              55.371415
            ],
            [
              39.379128,
              55.373766
            ],
            [
              39.370295,
              55.373542
            ],
            [
              39.373586,
              55.384419
            ],
            [
              39.37562518952506,
              55.38891831759347
            ],
            [
              39.376241,
              55.388597
            ],
            [
              39.38492205872335,
              55.394307479518126
            ],
            [
              39.387808,
              55.394885
            ],
            [
              39.387908614035474,
              55.39489482999701
            ],
            [
              39.389856,
              55.39508
            ],
            [
              39.38985551880448,
              55.395085042703954
            ],
            [
              39.393069,
              55.395399
            ],
            [
              39.401788,
              55.394236
            ],
            [
              39.406274,
              55.392405
            ],
            [
              39.413387,
              55.390968
            ],
            [
              39.42065,
              55.390299
            ],
            [
              39.425325,
              55.391149
            ],
            [
              39.428961,
              55.391442
            ],
            [
              39.434329,
              55.391807
            ],
            [
              39.434889,
              55.391867
            ],
            [
              39.43487733972229,
              55.39197570779735
            ],
            [
              39.43781,
              55.392878
            ],
            [
              39.438702,
              55.396602
            ],
            [
              39.433946,
              55.395387
            ],
            [
              39.429386,
              55.395531
            ],
            [
              39.425569,
              55.394741
            ],
            [
              39.42033,
              55.393885
            ],
            [
              39.413903,
              55.394531
            ],
            [
              39.407745,
              55.395959
            ],
            [
              39.403267,
              55.397494
            ],
            [
              39.40012,
              55.39903
            ],
            [
              39.395784,
              55.399279
            ],
            [
              39.39505,
              55.411152
            ],
            [
              39.404821,
              55.41699
            ],
            [
              39.408045,
              55.419582
            ],
            [
              39.40824885471373,
              55.419854107221965
            ],
            [
              39.408249,
              55.419854
            ],
            [
              39.40826335977243,
              55.419873468713064
            ],
            [
              39.41011196401157,
              55.4223410033352
            ],
            [
              39.41212825448288,
              55.42441013916078
            ],
            [
              39.421692,
              55.428629
            ],
            [
              39.423754,
              55.428917
            ],
            [
              39.425534,
              55.429806
            ],
            [
              39.42709034111433,
              55.43079035590172
            ],
            [
              39.433335,
              55.425956
            ],
            [
              39.431912,
              55.42265
            ],
            [
              39.428497,
              55.419829
            ],
            [
              39.428951,
              55.416258
            ],
            [
              39.431027,
              55.416522
            ],
            [
              39.433104,
              55.416786
            ],
            [
              39.440129,
              55.41947
            ],
            [
              39.44252891899425,
              55.41981556261888
            ],
            [
              39.442529,
              55.419815
            ],
            [
              39.44566899432951,
              55.420267039395036
            ],
            [
              39.445669,
              55.420267
            ],
            [
              39.4502882146437,
              55.42093200569519
            ],
            [
              39.45303673533627,
              55.421004932812366
            ],
            [
              39.46284629509987,
              55.419731091536306
            ],
            [
              39.463615387489014,
              55.41945414026177
            ],
            [
              39.46528315921262,
              55.418853220999786
            ],
            [
              39.46658,
              55.412508
            ],
            [
              39.466835322198165,
              55.411360682603146
            ],
            [
              39.46575,
              55.406614
            ],
            [
              39.466052,
              55.403027
            ],
            [
              39.468039,
              55.403194
            ],
            [
              39.469436,
              55.403535
            ],
            [
              39.473201,
              55.404806
            ],
            [
              39.476664,
              55.406135
            ],
            [
              39.480187,
              55.407814
            ],
            [
              39.487206,
              55.409214
            ],
            [
              39.49522,
              55.404606
            ],
            [
              39.498836,
              55.397279
            ],
            [
              39.498242,
              55.392089
            ],
            [
              39.498042,
              55.390204
            ],
            [
              39.497841,
              55.388319
            ],
            [
              39.49784327660243,
              55.38831875834946
            ],
            [
              39.497801,
              55.387919
            ],
            [
              39.497237,
              55.383794
            ],
            [
              39.497074,
              55.38267
            ],
            [
              39.49707625068022,
              55.38266967332912
            ],
            [
              39.497062,
              55.38257
            ],
            [
              39.496902,
              55.380831
            ],
            [
              39.49814686231589,
              55.38071675740532
            ],
            [
              39.498284,
              55.377861
            ],
            [
              39.496931,
              55.366402
            ],
            [
              39.496089,
              55.362902
            ],
            [
              39.501018,
              55.360208
            ],
            [
              39.504506,
              55.361097
            ],
            [
              39.514441,
              55.362635
            ],
            [
              39.524608,
              55.357086
            ],
            [
              39.527909,
              55.349815
            ],
            [
              39.531621,
              55.348231
            ],
            [
              39.53668,
              55.346588
            ],
            [
              39.541833,
              55.345265
            ],
            [
              39.547121,
              55.343863
            ],
            [
              39.551242,
              55.343054
            ],
            [
              39.556736,
              55.341906
            ],
            [
              39.56208572506074,
              55.34091911008012
            ],
            [
              39.56221664858217,
              55.34088838039023
            ],
            [
              39.567315,
              55.334011
            ],
            [
              39.570708,
              55.331093
            ],
            [
              39.579623,
              55.331964
            ],
            [
              39.583617,
              55.331212
            ],
            [
              39.58728,
              55.33111
            ],
            [
              39.592609,
              55.331747
            ],
            [
              39.596522,
              55.33239
            ],
            [
              39.60016,
              55.333252
            ],
            [
              39.607571,
              55.336191
            ],
            [
              39.617544,
              55.341406
            ],
            [
              39.625942,
              55.341516
            ],
            [
              39.629444,
              55.340943
            ],
            [
              39.631535,
              55.34085
            ],
            [
              39.63196,
              55.340869
            ],
            [
              39.631797,
              55.344466
            ],
            [
              39.629138,
              55.344682
            ],
            [
              39.62551,
              55.34526
            ],
            [
              39.621683,
              55.34591
            ],
            [
              39.617244,
              55.344994
            ],
            [
              39.612611,
              55.34413
            ],
            [
              39.609063,
              55.343162
            ],
            [
              39.606158,
              55.340769
            ],
            [
              39.602668,
              55.338763
            ],
            [
              39.599323,
              55.337297
            ],
            [
              39.592281,
              55.335423
            ],
            [
              39.588049,
              55.33474
            ],
            [
              39.584489,
              55.334776
            ],
            [
              39.574368,
              55.340914
            ],
            [
              39.570089,
              55.34202
            ],
            [
              39.56334,
              55.344315
            ],
            [
              39.56313232184463,
              55.344354358912106
            ],
            [
              39.563136,
              55.34437
            ],
            [
              39.560698,
              55.344943
            ],
            [
              39.557272,
              55.345465
            ],
            [
              39.553675,
              55.346226
            ],
            [
              39.549755,
              55.347269
            ],
            [
              39.545345,
              55.348088
            ],
            [
              39.540138,
              55.349408
            ],
            [
              39.535023,
              55.350739
            ],
            [
              39.531955,
              55.359414
            ],
            [
              39.530025,
              55.362558
            ],
            [
              39.518672,
              55.365071
            ],
            [
              39.511923,
              55.372856
            ],
            [
              39.522045,
              55.375479
            ],
            [
              39.529523,
              55.376415
            ],
            [
              39.535582,
              55.376257
            ],
            [
              39.536566,
              55.37972
            ],
            [
              39.535277,
              55.380086
            ],
            [
              39.533759,
              55.380409
            ],
            [
              39.530024,
              55.380067
            ],
            [
              39.525699,
              55.379825
            ],
            [
              39.521238,
              55.378987
            ],
            [
              39.515622,
              55.379319
            ],
            [
              39.50847,
              55.380105
            ],
            [
              39.501605,
              55.389666
            ],
            [
              39.502106,
              55.393887
            ],
            [
              39.50818,
              55.403515
            ],
            [
              39.514889,
              55.403245
            ],
            [
              39.519434,
              55.4031
            ],
            [
              39.524058,
              55.402953
            ],
            [
              39.529003,
              55.403677
            ],
            [
              39.539543,
              55.407564
            ],
            [
              39.542036,
              55.407763
            ],
            [
              39.54453,
              55.407962
            ],
            [
              39.54452997250555,
              55.40796234502648
            ],
            [
              39.544726,
              55.407978
            ],
            [
              39.54472362021716,
              55.40800775143137
            ],
            [
              39.550449,
              55.407613
            ],
            [
              39.562206,
              55.407479
            ],
            [
              39.570833,
              55.407412
            ],
            [
              39.574868,
              55.407475
            ],
            [
              39.584463,
              55.40651
            ],
            [
              39.592315,
              55.406836
            ],
            [
              39.593399185499365,
              55.40875053037069
            ],
            [
              39.599458,
              55.406398
            ],
            [
              39.602894,
              55.403252
            ],
            [
              39.604547,
              55.399778
            ],
            [
              39.605921,
              55.396503
            ],
            [
              39.599373,
              55.387316
            ],
            [
              39.594408,
              55.386872
            ],
            [
              39.59061,
              55.386387
            ],
            [
              39.586388,
              55.386328
            ],
            [
              39.582694,
              55.386276
            ],
            [
              39.577808,
              55.386416
            ],
            [
              39.574077,
              55.38612
            ],
            [
              39.570474,
              55.386261
            ],
            [
              39.566448,
              55.386873
            ],
            [
              39.56496,
              55.3834
            ],
            [
              39.568715,
              55.382762
            ],
            [
              39.573394,
              55.382586
            ],
            [
              39.578376,
              55.382861
            ],
            [
              39.582881,
              55.382681
            ],
            [
              39.586533,
              55.382731
            ],
            [
              39.590737,
              55.382789
            ],
            [
              39.596033,
              55.383038
            ],
            [
              39.600276,
              55.383831
            ],
            [
              39.607953,
              55.375975
            ],
            [
              39.612016,
              55.369342
            ],
            [
              39.610069,
              55.36269
            ],
            [
              39.612989,
              55.360598
            ],
            [
              39.620859,
              55.359145
            ],
            [
              39.624875,
              55.360712
            ],
            [
              39.618129,
              55.363738
            ],
            [
              39.617243,
              55.37095
            ],
            [
              39.616884,
              55.374463
            ],
            [
              39.613652,
              55.385143
            ],
            [
              39.611612,
              55.392685
            ],
            [
              39.609803,
              55.396826
            ],
            [
              39.60788073203609,
              55.401139766466706
            ],
            [
              39.606501,
              55.404358
            ],
            [
              39.60607456111791,
              55.404821431549976
            ],
            [
              39.605806,
              55.405368
            ],
            [
              39.60405934167616,
              55.40701146700088
            ],
            [
              39.603792,
              55.407302
            ],
            [
              39.601896861854044,
              55.40904618906368
            ],
            [
              39.601896,
              55.409047
            ],
            [
              39.601895973592455,
              55.40904700657461
            ],
            [
              39.600013,
              55.41078
            ],
            [
              39.59425632043496,
              55.41239722166865
            ],
            [
              39.594278,
              55.412499
            ],
            [
              39.59059,
              55.414956
            ],
            [
              39.5835596460687,
              55.41316177241774
            ],
            [
              39.583518,
              55.413296
            ],
            [
              39.580900562519446,
              55.41248314356147
            ],
            [
              39.5809,
              55.412483
            ],
            [
              39.576389,
              55.411373
            ],
            [
              39.570795,
              55.411012
            ],
            [
              39.562234,
              55.411079
            ],
            [
              39.550525,
              55.411212
            ],
            [
              39.545124,
              55.411582
            ],
            [
              39.5444417101112,
              55.4115321188885
            ],
            [
              39.544439,
              55.411566
            ],
            [
              39.54424403988346,
              55.411550499504436
            ],
            [
              39.544244,
              55.411551
            ],
            [
              39.542479951219974,
              55.41141024390248
            ],
            [
              39.541848,
              55.41136
            ],
            [
              39.535435,
              55.413761
            ],
            [
              39.534444,
              55.424413
            ],
            [
              39.534152,
              55.424621
            ],
            [
              39.528261,
              55.430509
            ],
            [
              39.525433,
              55.43304
            ],
            [
              39.517457,
              55.440201
            ],
            [
              39.516437,
              55.443653
            ],
            [
              39.51794822834186,
              55.45183438259678
            ],
            [
              39.518081,
              55.451738
            ],
            [
              39.520080014226075,
              55.45359450968285
            ],
            [
              39.520616,
              55.45383
            ],
            [
              39.53178580453987,
              55.45768293257763
            ],
            [
              39.533059,
              55.457561
            ],
            [
              39.537592,
              55.45713
            ],
            [
              39.54173,
              55.456621
            ],
            [
              39.543581,
              55.456238
            ],
            [
              39.545432,
              55.455856
            ],
            [
              39.545432065398096,
              55.45585631674956
            ],
            [
              39.545831,
              55.455774
            ],
            [
              39.54583155021117,
              55.45577666414063
            ],
            [
              39.54651,
              55.455637
            ],
            [
              39.553616,
              55.448459
            ],
            [
              39.553515,
              55.445682
            ],
            [
              39.553414,
              55.442904
            ],
            [
              39.55509401530605,
              55.44284329905787
            ],
            [
              39.555166,
              55.442535
            ],
            [
              39.55537,
              55.438941
            ],
            [
              39.556307,
              55.435875
            ],
            [
              39.558064,
              55.435205
            ],
            [
              39.55818372619279,
              55.43528962866186
            ],
            [
              39.558326,
              55.435105
            ],
            [
              39.567073,
              55.429278
            ],
            [
              39.571083,
              55.430021
            ],
            [
              39.568681,
              55.435131
            ],
            [
              39.576925,
              55.441439
            ],
            [
              39.580354,
              55.440643
            ],
            [
              39.579628,
              55.444492
            ],
            [
              39.574985,
              55.445931
            ],
            [
              39.576181,
              55.456984
            ],
            [
              39.581205,
              55.458656
            ],
            [
              39.58411,
              55.45961
            ],
            [
              39.585628,
              55.460192
            ],
            [
              39.58562743850741,
              55.46019346519922
            ],
            [
              39.585675,
              55.46021
            ],
            [
              39.590027,
              55.461963
            ],
            [
              39.593753,
              55.462844
            ],
            [
              39.597073,
              55.463197
            ],
            [
              39.59707283690081,
              55.46319852852123
            ],
            [
              39.597508,
              55.463245
            ],
            [
              39.60115,
              55.464318
            ],
            [
              39.611871,
              55.469322
            ],
            [
              39.61623,
              55.470187
            ],
            [
              39.620046,
              55.470967
            ],
            [
              39.627824,
              55.471652
            ],
            [
              39.632721,
              55.471316
            ],
            [
              39.641629,
              55.470138
            ],
            [
              39.647006,
              55.468364
            ],
            [
              39.651901,
              55.467422
            ],
            [
              39.651915379026704,
              55.46741923344707
            ],
            [
              39.65945,
              55.463231
            ],
            [
              39.662216,
              55.460785
            ],
            [
              39.657872,
              55.449954
            ],
            [
              39.653773,
              55.448022
            ],
            [
              39.651147,
              55.445015
            ],
            [
              39.656597,
              55.445335
            ],
            [
              39.660066,
              55.447001
            ],
            [
              39.665865,
              55.450173
            ],
            [
              39.66863,
              55.453354
            ],
            [
              39.669102,
              55.45936
            ],
            [
              39.677392,
              55.4618
            ],
            [
              39.684015,
              55.452816
            ],
            [
              39.68221,
              55.449761
            ],
            [
              39.679095,
              55.446826
            ],
            [
              39.681662,
              55.444171
            ],
            [
              39.685296,
              55.446959
            ],
            [
              39.686995,
              55.450565
            ],
            [
              39.690384,
              55.455969
            ],
            [
              39.705531,
              55.45861
            ],
            [
              39.717731,
              55.453304
            ],
            [
              39.717943,
              55.451151
            ],
            [
              39.71798552112361,
              55.45115516664277
            ],
            [
              39.718289,
              55.448593
            ],
            [
              39.718814,
              55.444472
            ],
            [
              39.713909,
              55.435354
            ],
            [
              39.708552,
              55.434314
            ],
            [
              39.700997,
              55.430662
            ],
            [
              39.697813,
              55.428982
            ],
            [
              39.698488,
              55.425824
            ],
            [
              39.692655,
              55.423298
            ],
            [
              39.689166,
              55.422719
            ],
            [
              39.685486,
              55.421651
            ],
            [
              39.682583,
              55.421352
            ],
            [
              39.682952,
              55.417771
            ],
            [
              39.685912974141516,
              55.414040883080155
            ],
            [
              39.6826,
              55.412123
            ],
            [
              39.677357,
              55.40893
            ],
            [
              39.673074,
              55.40311
            ],
            [
              39.674964,
              55.399884
            ],
            [
              39.676665,
              55.396753
            ],
            [
              39.68265,
              55.394323
            ],
            [
              39.687523,
              55.392881
            ],
            [
              39.689305,
              55.396009
            ],
            [
              39.687957,
              55.396777
            ],
            [
              39.683408,
              55.397842
            ],
            [
              39.684422,
              55.409018
            ],
            [
              39.68844719787454,
              55.411210126542
            ],
            [
              39.694226,
              55.412491
            ],
            [
              39.696588,
              55.415588
            ],
            [
              39.700304,
              55.420055
            ],
            [
              39.70235306367886,
              55.42419498212203
            ],
            [
              39.704826,
              55.424048
            ],
            [
              39.708412,
              55.423735
            ],
            [
              39.71459,
              55.431819
            ],
            [
              39.723565,
              55.429575
            ],
            [
              39.726858,
              55.427839
            ],
            [
              39.73401,
              55.424006
            ],
            [
              39.73823,
              55.421757
            ],
            [
              39.746641,
              55.414924
            ],
            [
              39.743891,
              55.403725
            ],
            [
              39.742679,
              55.40347
            ],
            [
              39.74303251975857,
              55.401789707706236
            ],
            [
              39.742583,
              55.401611
            ],
            [
              39.739089,
              55.400294
            ],
            [
              39.736729,
              55.397328
            ],
            [
              39.746338,
              55.400266
            ],
            [
              39.749899,
              55.40064
            ],
            [
              39.755015,
              55.400519
            ],
            [
              39.75849,
              55.391599
            ],
            [
              39.761288,
              55.389334
            ],
            [
              39.76424149066944,
              55.38797865102987
            ],
            [
              39.76332430696643,
              55.380079089832705
            ],
            [
              39.757651,
              55.376259
            ],
            [
              39.758869,
              55.372288
            ],
            [
              39.759798,
              55.368324
            ],
            [
              39.76377006788222,
              55.366149731159254
            ],
            [
              39.76412,
              55.365955
            ],
            [
              39.76412135743836,
              55.36595743889269
            ],
            [
              39.764261,
              55.365881
            ],
            [
              39.76863828310183,
              55.360936234505864
            ],
            [
              39.765572,
              55.353854
            ],
            [
              39.764475,
              55.353134
            ],
            [
              39.76456941575763,
              55.352990105604825
            ],
            [
              39.763918,
              55.352734
            ],
            [
              39.758411,
              55.352222
            ],
            [
              39.756439,
              55.352759
            ],
            [
              39.755042,
              55.349442
            ],
            [
              39.757828,
              55.347904
            ],
            [
              39.7654,
              55.344766
            ],
            [
              39.768782,
              55.345999
            ],
            [
              39.778265,
              55.348403
            ],
            [
              39.788346,
              55.348632
            ],
            [
              39.790358,
              55.349015
            ],
            [
              39.790067,
              55.352603
            ],
            [
              39.784903,
              55.354804
            ],
            [
              39.777241,
              55.358758
            ],
            [
              39.7765543012386,
              55.35981000298705
            ],
            [
              39.777479,
              55.361354
            ],
            [
              39.776615,
              55.361871
            ],
            [
              39.771341,
              55.366652
            ],
            [
              39.765290236472204,
              55.37601529684881
            ],
            [
              39.768961,
              55.380995
            ],
            [
              39.767376,
              55.384862
            ],
            [
              39.76738340501586,
              55.386554663209495
            ],
            [
              39.76791,
              55.386564
            ],
            [
              39.769061,
              55.38765
            ],
            [
              39.774407,
              55.39206
            ],
            [
              39.783904777356305,
              55.390975902082566
            ],
            [
              39.78873,
              55.388028
            ],
            [
              39.792624,
              55.387342
            ],
            [
              39.808832,
              55.380332
            ],
            [
              39.810643,
              55.377293
            ],
            [
              39.810908,
              55.37686
            ],
            [
              39.81334464273426,
              55.37613336100839
            ],
            [
              39.81759,
              55.370306
            ],
            [
              39.818421061200986,
              55.368254025854874
            ],
            [
              39.820138,
              55.363789
            ],
            [
              39.82096,
              55.35563
            ],
            [
              39.821795,
              55.354847
            ],
            [
              39.82180081296457,
              55.3548479254959
            ],
            [
              39.822147,
              55.352715
            ],
            [
              39.822459,
              55.350878
            ],
            [
              39.822926,
              55.348235
            ],
            [
              39.826471,
              55.34886
            ],
            [
              39.833112,
              55.350162
            ],
            [
              39.834096,
              55.350266
            ],
            [
              39.834506,
              55.351668
            ],
            [
              39.84353,
              55.359031
            ],
            [
              39.844087,
              55.359192
            ],
            [
              39.846748,
              55.359956
            ],
            [
              39.845754,
              55.363416
            ],
            [
              39.843093,
              55.362652
            ],
            [
              39.835946,
              55.362151
            ],
            [
              39.8328899496269,
              55.362873100777705
            ],
            [
              39.832959,
              55.363136
            ],
            [
              39.832622,
              55.363279
            ],
            [
              39.8293126077053,
              55.363820410119196
            ],
            [
              39.82388273426789,
              55.370262406947845
            ],
            [
              39.824733,
              55.37144
            ],
            [
              39.824108,
              55.372706
            ],
            [
              39.817322,
              55.377792
            ],
            [
              39.81663068989183,
              55.378452796420326
            ],
            [
              39.816657,
              55.37848
            ],
            [
              39.815446,
              55.379651
            ],
            [
              39.814558,
              55.380434
            ],
            [
              39.811043,
              55.383173
            ],
            [
              39.8050582175964,
              55.38586179311667
            ],
            [
              39.805934,
              55.387128
            ],
            [
              39.805321,
              55.387552
            ],
            [
              39.796153,
              55.389298
            ],
            [
              39.791474,
              55.391625
            ],
            [
              39.7885364932279,
              55.392057955344434
            ],
            [
              39.788951,
              55.392888
            ],
            [
              39.788607,
              55.396331
            ],
            [
              39.787492,
              55.399754
            ],
            [
              39.782728,
              55.400507
            ],
            [
              39.777946,
              55.402455
            ],
            [
              39.779681,
              55.412136
            ],
            [
              39.785056,
              55.412288
            ],
            [
              39.790023,
              55.41344
            ],
            [
              39.794527,
              55.414563
            ],
            [
              39.795954,
              55.414946
            ],
            [
              39.796657,
              55.415258
            ],
            [
              39.7958087998421,
              55.41717387283436
            ],
            [
              39.796181,
              55.417932
            ],
            [
              39.802325,
              55.425273
            ],
            [
              39.80031,
              55.428256
            ],
            [
              39.799553,
              55.427745
            ],
            [
              39.79901720460866,
              55.427401388021885
            ],
            [
              39.798986,
              55.427445
            ],
            [
              39.79812,
              55.426826
            ],
            [
              39.794856,
              55.42499
            ],
            [
              39.793243,
              55.421287
            ],
            [
              39.784266,
              55.4158
            ],
            [
              39.779273,
              55.415713
            ],
            [
              39.775344275539496,
              55.41451637403344
            ],
            [
              39.775288,
              55.414642
            ],
            [
              39.774299,
              55.414198
            ],
            [
              39.76844,
              55.411066
            ],
            [
              39.758445,
              55.409893
            ],
            [
              39.757338839412036,
              55.41064376328968
            ],
            [
              39.757339,
              55.410644
            ],
            [
              39.756570886776515,
              55.41116498114289
            ],
            [
              39.754349,
              55.412673
            ],
            [
              39.74952,
              55.417085
            ],
            [
              39.744344,
              55.42212
            ],
            [
              39.74246640073964,
              55.423317905730556
            ],
            [
              39.742495,
              55.423363
            ],
            [
              39.74085,
              55.424406
            ],
            [
              39.740007,
              55.424887
            ],
            [
              39.735704,
              55.427183
            ],
            [
              39.730235,
              55.433249
            ],
            [
              39.731338,
              55.439167
            ],
            [
              39.725558,
              55.441678
            ],
            [
              39.721525,
              55.451496
            ],
            [
              39.72152430803084,
              55.45150193219398
            ],
            [
              39.721525,
              55.451502
            ],
            [
              39.721314,
              55.453656
            ],
            [
              39.720949,
              55.456434
            ],
            [
              39.718451,
              55.461908
            ],
            [
              39.717498,
              55.468926
            ],
            [
              39.716469,
              55.477606
            ],
            [
              39.727074,
              55.482207
            ],
            [
              39.732964,
              55.482163
            ],
            [
              39.73714,
              55.481887
            ],
            [
              39.740196,
              55.481874
            ],
            [
              39.740699,
              55.481904
            ],
            [
              39.74071154328753,
              55.48191782137251
            ],
            [
              39.740964,
              55.48192
            ],
            [
              39.745217,
              55.482547
            ],
            [
              39.748886,
              55.483539
            ],
            [
              39.757772,
              55.481057
            ],
            [
              39.763885,
              55.471458
            ],
            [
              39.767188,
              55.467591
            ],
            [
              39.776774,
              55.473588
            ],
            [
              39.779251,
              55.466256
            ],
            [
              39.782506,
              55.46171
            ],
            [
              39.78523,
              55.453133
            ],
            [
              39.787296,
              55.443181
            ],
            [
              39.788058,
              55.439518
            ],
            [
              39.78810525889347,
              55.439529740331864
            ],
            [
              39.788519,
              55.437663
            ],
            [
              39.788841,
              55.436367
            ],
            [
              39.78884111114406,
              55.436367027611055
            ],
            [
              39.790145,
              55.431121
            ],
            [
              39.793639,
              55.43199
            ],
            [
              39.792013,
              55.438532
            ],
            [
              39.790821,
              55.443913
            ],
            [
              39.78922188922096,
              55.451615976995676
            ],
            [
              39.789222,
              55.451616
            ],
            [
              39.78908195611627,
              55.45229004004401
            ],
            [
              39.788755,
              55.453865
            ],
            [
              39.798572,
              55.460143
            ],
            [
              39.801643,
              55.457803
            ],
            [
              39.804873,
              55.456204
            ],
            [
              39.81067,
              55.448929
            ],
            [
              39.811604,
              55.444492
            ],
            [
              39.81081,
              55.435116
            ],
            [
              39.809706,
              55.429861
            ],
            [
              39.813214,
              55.432373
            ],
            [
              39.816118,
              55.434822
            ],
            [
              39.820309,
              55.437926
            ],
            [
              39.829641711704305,
              55.44377713436076
            ],
            [
              39.841153,
              55.444849
            ],
            [
              39.843233,
              55.445557
            ],
            [
              39.844023,
              55.445963
            ],
            [
              39.842377,
              55.449165
            ],
            [
              39.832564,
              55.449334
            ],
            [
              39.827816,
              55.447138
            ],
            [
              39.819961,
              55.445805
            ],
            [
              39.813533,
              55.454895
            ],
            [
              39.80870524944912,
              55.458561791489835
            ],
            [
              39.811837,
              55.463904
            ],
            [
              39.810415,
              55.467211
            ],
            [
              39.80961,
              55.466865
            ],
            [
              39.803975,
              55.47744
            ],
            [
              39.800922,
              55.483393
            ],
            [
              39.795664,
              55.479632
            ],
            [
              39.7887,
              55.477262
            ],
            [
              39.777789,
              55.477042
            ],
            [
              39.771867,
              55.481477
            ],
            [
              39.768346,
              55.482299
            ],
            [
              39.76641580814605,
              55.484146357709605
            ],
            [
              39.76662221639323,
              55.48894263355853
            ],
            [
              39.770948,
              55.495706
            ],
            [
              39.775057,
              55.494923
            ],
            [
              39.778324,
              55.492351
            ],
            [
              39.779339,
              55.491378
            ],
            [
              39.779458,
              55.491324
            ],
            [
              39.780325,
              55.49144
            ],
            [
              39.780867,
              55.491554
            ],
            [
              39.781619821014566,
              55.492989370587026
            ],
            [
              39.78386,
              55.49255
            ],
            [
              39.786264,
              55.496779
            ],
            [
              39.788237,
              55.504531
            ],
            [
              39.787763,
              55.508404
            ],
            [
              39.78676804533238,
              55.51161297164416
            ],
            [
              39.786801,
              55.511684
            ],
            [
              39.784895,
              55.517654
            ],
            [
              39.78416278781016,
              55.518837097106356
            ],
            [
              39.784443,
              55.519029
            ],
            [
              39.783493,
              55.520416
            ],
            [
              39.782779,
              55.521073
            ],
            [
              39.778795,
              55.522998
            ],
            [
              39.772541,
              55.527734
            ],
            [
              39.770595509730136,
              55.5371446285064
            ],
            [
              39.771227,
              55.537574
            ],
            [
              39.772901,
              55.5428
            ],
            [
              39.77637,
              55.552824
            ],
            [
              39.781876,
              55.5548
            ],
            [
              39.790018,
              55.551653
            ],
            [
              39.794905,
              55.551133
            ],
            [
              39.79672286636082,
              55.55230442059476
            ],
            [
              39.797025,
              55.552232
            ],
            [
              39.79859,
              55.552755
            ],
            [
              39.799506,
              55.553792
            ],
            [
              39.799614962804085,
              55.55416806767503
            ],
            [
              39.801834,
              55.555598
            ],
            [
              39.807818,
              55.554358
            ],
            [
              39.811646,
              55.551607
            ],
            [
              39.818074,
              55.542505
            ],
            [
              39.819168,
              55.539807
            ],
            [
              39.820424,
              55.536725
            ],
            [
              39.821679,
              55.533642
            ],
            [
              39.825013,
              55.535
            ],
            [
              39.83218274020919,
              55.541448972674935
            ],
            [
              39.83262557142868,
              55.54151514285716
            ],
            [
              39.835209,
              55.541846
            ],
            [
              39.83520917483209,
              55.54184624030293
            ],
            [
              39.836121,
              55.541963
            ],
            [
              39.844238,
              55.544797
            ],
            [
              39.848316,
              55.533607
            ],
            [
              39.848707,
              55.533444
            ],
            [
              39.851219,
              55.533228
            ],
            [
              39.850878,
              55.536812
            ],
            [
              39.859376,
              55.545168
            ],
            [
              39.865115,
              55.538643
            ],
            [
              39.865574,
              55.535073
            ],
            [
              39.86899311774328,
              55.53624661991016
            ],
            [
              39.869086,
              55.535524
            ],
            [
              39.880347,
              55.532618
            ],
            [
              39.883674,
              55.533993
            ],
            [
              39.891929,
              55.538427
            ],
            [
              39.891920700858655,
              55.53849302345849
            ],
            [
              39.895826,
              55.539044
            ],
            [
              39.9032,
              55.540048
            ],
            [
              39.90320108372568,
              55.54004840919911
            ],
            [
              39.910617,
              55.541028
            ],
            [
              39.912581,
              55.545886
            ],
            [
              39.91108,
              55.549627
            ],
            [
              39.909579,
              55.553367
            ],
            [
              39.922443,
              55.561731
            ],
            [
              39.926419,
              55.562954
            ],
            [
              39.931503,
              55.563351
            ],
            [
              39.935313,
              55.563283
            ],
            [
              39.939089,
              55.563733
            ],
            [
              39.940412,
              55.564187
            ],
            [
              39.942014,
              55.564842
            ],
            [
              39.94199324396723,
              55.56489283016695
            ],
            [
              39.946243,
              55.56659
            ],
            [
              39.954795,
              55.567516
            ],
            [
              39.956703,
              55.567445
            ],
            [
              39.95701870603993,
              55.56743341749329
            ],
            [
              39.95738,
              55.56742
            ],
            [
              39.95806725333793,
              55.56739473333316
            ],
            [
              39.958542,
              55.567377
            ],
            [
              39.95854201031443,
              55.567377279032556
            ],
            [
              39.959964,
              55.567325
            ],
            [
              39.96002395414437,
              55.568946466596216
            ],
            [
              39.960428,
              55.569057
            ],
            [
              39.967576,
              55.566984
            ],
            [
              39.972337,
              55.56557
            ],
            [
              39.973959,
              55.565343
            ],
            [
              39.974897647418764,
              55.56649456214659
            ],
            [
              39.982525,
              55.565385
            ],
            [
              39.987066,
              55.565345
            ],
            [
              39.9931310134309,
              55.56513332642615
            ],
            [
              40.000443,
              55.560604
            ],
            [
              40.001156,
              55.559746
            ],
            [
              40.001318,
              55.559754
            ],
            [
              40.002293,
              55.560016
            ],
            [
              40.006163,
              55.564661
            ],
            [
              40.005929,
              55.568253
            ],
            [
              40.005649,
              55.56841
            ],
            [
              40.00225,
              55.569596
            ],
            [
              39.993153,
              55.568873
            ],
            [
              39.987097,
              55.568945
            ],
            [
              39.982557,
              55.568985
            ],
            [
              39.97518564379785,
              55.57027963562819
            ],
            [
              39.975187,
              55.570331
            ],
            [
              39.97316130823324,
              55.570384379104176
            ],
            [
              39.971139,
              55.570473
            ],
            [
              39.96254278205972,
              55.57370966292882
            ],
            [
              39.959871229985616,
              55.57546084924581
            ],
            [
              39.958546,
              55.577036
            ],
            [
              39.95854575367759,
              55.57703585745309
            ],
            [
              39.956712,
              55.579215
            ],
            [
              39.952807,
              55.58388
            ],
            [
              39.948481,
              55.589061
            ],
            [
              39.944156,
              55.594242
            ],
            [
              39.941392,
              55.591935
            ],
            [
              39.94552326577822,
              55.58698722191471
            ],
            [
              39.945523,
              55.586987
            ],
            [
              39.944854,
              55.583512
            ],
            [
              39.945945,
              55.580081
            ],
            [
              39.94517921542365,
              55.573207155714655
            ],
            [
              39.944477,
              55.573358
            ],
            [
              39.943512,
              55.573402
            ],
            [
              39.93558850439175,
              55.56817064180258
            ],
            [
              39.93173986777825,
              55.569867571526345
            ],
            [
              39.927168,
              55.576903
            ],
            [
              39.926004,
              55.576912
            ],
            [
              39.92440342280415,
              55.57697722527926
            ],
            [
              39.924397,
              55.577
            ],
            [
              39.92438716019302,
              55.57700143497185
            ],
            [
              39.924387,
              55.577002
            ],
            [
              39.923245,
              55.577168
            ],
            [
              39.920998,
              55.577116
            ],
            [
              39.919084,
              55.573868
            ],
            [
              39.91555711317899,
              55.565277704137564
            ],
            [
              39.907087,
              55.566415
            ],
            [
              39.903029,
              55.566507
            ],
            [
              39.896716,
              55.566663
            ],
            [
              39.88835,
              55.566886
            ],
            [
              39.887485,
              55.576094
            ],
            [
              39.894036,
              55.579023
            ],
            [
              39.902466,
              55.582793
            ],
            [
              39.904199,
              55.583568
            ],
            [
              39.90273,
              55.586854
            ],
            [
              39.900996,
              55.586079
            ],
            [
              39.896781,
              55.584195
            ],
            [
              39.892566,
              55.58231
            ],
            [
              39.886015,
              55.57938
            ],
            [
              39.879539,
              55.573772
            ],
            [
              39.873423,
              55.569986
            ],
            [
              39.863295,
              55.566227
            ],
            [
              39.859708,
              55.566226
            ],
            [
              39.854649,
              55.564522
            ],
            [
              39.85042874027162,
              55.56481224426154
            ],
            [
              39.850365,
              55.565337
            ],
            [
              39.847626,
              55.565005
            ],
            [
              39.843399,
              55.564538
            ],
            [
              39.838788,
              55.564107
            ],
            [
              39.834213,
              55.574328
            ],
            [
              39.834472,
              55.577919
            ],
            [
              39.826375,
              55.578504
            ],
            [
              39.818277,
              55.57909
            ],
            [
              39.812771349526784,
              55.585658929220855
            ],
            [
              39.815451,
              55.592382
            ],
            [
              39.818038,
              55.5957
            ],
            [
              39.821609,
              55.598634
            ],
            [
              39.831953,
              55.601
            ],
            [
              39.835431,
              55.601661
            ],
            [
              39.838606,
              55.604954
            ],
            [
              39.839301,
              55.608504
            ],
            [
              39.846857,
              55.616501
            ],
            [
              39.850489,
              55.616887
            ],
            [
              39.855178,
              55.618627
            ],
            [
              39.858893,
              55.619627
            ],
            [
              39.861707,
              55.623826
            ],
            [
              39.862592,
              55.627249
            ],
            [
              39.863654,
              55.631578
            ],
            [
              39.871243,
              55.638891
            ],
            [
              39.871670020548066,
              55.64106593533789
            ],
            [
              39.882446,
              55.644201
            ],
            [
              39.885912,
              55.644181
            ],
            [
              39.88591204024401,
              55.6441878989738
            ],
            [
              39.886679,
              55.644185
            ],
            [
              39.894886,
              55.644965
            ],
            [
              39.897006,
              55.645184
            ],
            [
              39.897739686269794,
              55.64526013725441
            ],
            [
              39.901751,
              55.645675
            ],
            [
              39.912676,
              55.643612
            ],
            [
              39.914022,
              55.642017
            ],
            [
              39.91412112567632,
              55.642071386567466
            ],
            [
              39.915158,
              55.640966
            ],
            [
              39.918848,
              55.63924
            ],
            [
              39.923364,
              55.63832
            ],
            [
              39.927879,
              55.6374
            ],
            [
              39.932326,
              55.644681
            ],
            [
              39.931858,
              55.646931
            ],
            [
              39.930569,
              55.647824
            ],
            [
              39.922226,
              55.648787
            ],
            [
              39.92099750339084,
              55.64888684382207
            ],
            [
              39.921023,
              55.649019
            ],
            [
              39.917575,
              55.649684
            ],
            [
              39.914128,
              55.650348
            ],
            [
              39.912518,
              55.649576
            ],
            [
              39.909705,
              55.647328
            ],
            [
              39.90138,
              55.649256
            ],
            [
              39.90153981495495,
              55.649818995013135
            ],
            [
              39.90399084426791,
              55.65430205705217
            ],
            [
              39.903997,
              55.654302
            ],
            [
              39.906733,
              55.656642
            ],
            [
              39.906049,
              55.65744
            ],
            [
              39.904144,
              55.658993
            ],
            [
              39.899979,
              55.660381
            ],
            [
              39.900581,
              55.672065
            ],
            [
              39.902244,
              55.675257
            ],
            [
              39.90139929162411,
              55.67608590732992
            ],
            [
              39.907653,
              55.677459
            ],
            [
              39.909323,
              55.680648
            ],
            [
              39.905452,
              55.689183
            ],
            [
              39.905954,
              55.692748
            ],
            [
              39.903091,
              55.693151
            ],
            [
              39.900258,
              55.693444
            ],
            [
              39.896176,
              55.694012
            ],
            [
              39.891407554561326,
              55.69379420802312
            ],
            [
              39.891412,
              55.693802
            ],
            [
              39.891177,
              55.693936
            ],
            [
              39.88557,
              55.692627
            ],
            [
              39.881219,
              55.692358
            ],
            [
              39.873924,
              55.700541
            ],
            [
              39.874208,
              55.703173
            ],
            [
              39.87420577068477,
              55.70317323981178
            ],
            [
              39.874272,
              55.703764
            ],
            [
              39.87821,
              55.712662
            ],
            [
              39.87821,
              55.716262
            ],
            [
              39.876354,
              55.716262
            ],
            [
              39.8697,
              55.715522
            ],
            [
              39.862355,
              55.720303
            ],
            [
              39.857887,
              55.725289
            ],
            [
              39.854559,
              55.726662
            ],
            [
              39.84713110301641,
              55.72497816050181
            ],
            [
              39.84699,
              55.725165
            ],
            [
              39.843506,
              55.72607
            ],
            [
              39.836405,
              55.719131
            ],
            [
              39.832919942791776,
              55.71843259523213
            ],
            [
              39.832831,
              55.71878
            ],
            [
              39.822789,
              55.721571
            ],
            [
              39.819237,
              55.722156
            ],
            [
              39.809473,
              55.716501
            ],
            [
              39.805968,
              55.719099
            ],
            [
              39.808991,
              55.72884
            ],
            [
              39.820075,
              55.732283
            ],
            [
              39.826365,
              55.732359
            ],
            [
              39.830417,
              55.732756
            ],
            [
              39.831662,
              55.732488
            ],
            [
              39.832419,
              55.736008
            ],
            [
              39.831175,
              55.736276
            ],
            [
              39.829409,
              55.73656
            ],
            [
              39.822627,
              55.74307
            ],
            [
              39.822391,
              55.746825
            ],
            [
              39.818851,
              55.746382
            ],
            [
              39.809408,
              55.743767
            ],
            [
              39.807805,
              55.740544
            ],
            [
              39.804325,
              55.730336
            ],
            [
              39.800688,
              55.726507
            ],
            [
              39.79973916909515,
              55.72231395809299
            ],
            [
              39.799461,
              55.722307
            ],
            [
              39.79861066028206,
              55.72030631125744
            ],
            [
              39.796235606248906,
              55.71787617020153
            ],
            [
              39.792879,
              55.718648
            ],
            [
              39.783663,
              55.719579
            ],
            [
              39.778214,
              55.717495
            ],
            [
              39.77691892657226,
              55.716138857514416
            ],
            [
              39.775621,
              55.726847
            ],
            [
              39.77386,
              55.727686
            ],
            [
              39.77194,
              55.72834
            ],
            [
              39.768068,
              55.728192
            ],
            [
              39.762415591069725,
              55.72567119434572
            ],
            [
              39.760167,
              55.726194
            ],
            [
              39.759771340910326,
              55.72449193770747
            ],
            [
              39.759693,
              55.724457
            ],
            [
              39.75709008812368,
              55.722068338860915
            ],
            [
              39.74924441369614,
              55.71991898034544
            ],
            [
              39.744797,
              55.7264
            ],
            [
              39.740944,
              55.725442
            ],
            [
              39.73515982482209,
              55.72384555859307
            ],
            [
              39.73449,
              55.723788
            ],
            [
              39.73268853655279,
              55.723352643767846
            ],
            [
              39.723023,
              55.723771
            ],
            [
              39.715632,
              55.727428
            ],
            [
              39.71041,
              55.730443
            ],
            [
              39.705146,
              55.734879
            ],
            [
              39.701791,
              55.735972
            ],
            [
              39.697166,
              55.737749
            ],
            [
              39.693536,
              55.73899
            ],
            [
              39.691926,
              55.750909
            ],
            [
              39.688359,
              55.750427
            ],
            [
              39.689968,
              55.738508
            ],
            [
              39.695875,
              55.734389
            ],
            [
              39.700545,
              55.732595
            ],
            [
              39.70043093552688,
              55.722304755032525
            ],
            [
              39.69759927140541,
              55.72007569711872
            ],
            [
              39.692241,
              55.717886
            ],
            [
              39.69033503297132,
              55.7175351982495
            ],
            [
              39.681473,
              55.718331
            ],
            [
              39.677303,
              55.719392
            ],
            [
              39.67178489623718,
              55.7209846391806
            ],
            [
              39.668275,
              55.722391
            ],
            [
              39.667482924645434,
              55.72254255960327
            ],
            [
              39.665987,
              55.72315
            ],
            [
              39.657812,
              55.724955
            ],
            [
              39.6553676747546,
              55.72522879690422
            ],
            [
              39.654648,
              55.725444
            ],
            [
              39.65358318743234,
              55.725428683194046
            ],
            [
              39.652393,
              55.725562
            ],
            [
              39.647777,
              55.725072
            ],
            [
              39.643542,
              55.72509
            ],
            [
              39.64228598783791,
              55.72486510690078
            ],
            [
              39.641451,
              55.724903
            ],
            [
              39.639340632358966,
              55.72433773133709
            ],
            [
              39.639169,
              55.724307
            ],
            [
              39.63916275654784,
              55.72429008673718
            ],
            [
              39.636956,
              55.723699
            ],
            [
              39.63764,
              55.720165
            ],
            [
              39.644747,
              55.721161
            ],
            [
              39.648385,
              55.721523
            ],
            [
              39.656651,
              55.721352
            ],
            [
              39.66111,
              55.720127
            ],
            [
              39.669179,
              55.717764
            ],
            [
              39.66949070213999,
              55.717615703354895
            ],
            [
              39.671669,
              55.716171
            ],
            [
              39.67263213258483,
              55.7161211239599
            ],
            [
              39.672975,
              55.715958
            ],
            [
              39.678485774922194,
              55.71494865648347
            ],
            [
              39.683092,
              55.713438
            ],
            [
              39.68479735958934,
              55.71344467390982
            ],
            [
              39.685414,
              55.713284
            ],
            [
              39.685691475883274,
              55.71331513167242
            ],
            [
              39.685693,
              55.713294
            ],
            [
              39.687649,
              55.71145
            ],
            [
              39.690029,
              55.712758
            ],
            [
              39.692922,
              55.714081
            ],
            [
              39.6931951072638,
              55.71419619010048
            ],
            [
              39.695294,
              55.714474
            ],
            [
              39.698907,
              55.714027
            ],
            [
              39.703267,
              55.713468
            ],
            [
              39.70618007861351,
              55.711690733223115
            ],
            [
              39.708664,
              55.700675
            ],
            [
              39.706606,
              55.695803
            ],
            [
              39.696339,
              55.689908
            ],
            [
              39.692328,
              55.687479
            ],
            [
              39.685065,
              55.684256
            ],
            [
              39.681724,
              55.681294
            ],
            [
              39.673406,
              55.688879
            ],
            [
              39.671031,
              55.691584
            ],
            [
              39.663334,
              55.689478
            ],
            [
              39.656159,
              55.689805
            ],
            [
              39.656998,
              55.686304
            ],
            [
              39.656278,
              55.675398
            ],
            [
              39.647827,
              55.675131
            ],
            [
              39.641902,
              55.674931
            ],
            [
              39.635988,
              55.674731
            ],
            [
              39.629226,
              55.674512
            ],
            [
              39.621719,
              55.67662
            ],
            [
              39.620887,
              55.683989
            ],
            [
              39.620056,
              55.691359
            ],
            [
              39.619431,
              55.696854
            ],
            [
              39.619073,
              55.700381
            ],
            [
              39.615148,
              55.704022
            ],
            [
              39.608307,
              55.710903
            ],
            [
              39.604826,
              55.711835
            ],
            [
              39.601141,
              55.711642
            ],
            [
              39.59808,
              55.709538
            ],
            [
              39.58902,
              55.711886
            ],
            [
              39.585287,
              55.715224
            ],
            [
              39.581303,
              55.720581
            ],
            [
              39.580600126570076,
              55.72687644489777
            ],
            [
              39.58430180719471,
              55.73167200130874
            ],
            [
              39.58744,
              55.732885
            ],
            [
              39.58757838375231,
              55.73324840588818
            ],
            [
              39.589665,
              55.733193
            ],
            [
              39.593336,
              55.732542
            ],
            [
              39.59842,
              55.734326
            ],
            [
              39.602687,
              55.735333
            ],
            [
              39.606922,
              55.737745
            ],
            [
              39.615742,
              55.745605
            ],
            [
              39.623621,
              55.749002
            ],
            [
              39.632349,
              55.750463
            ],
            [
              39.636747,
              55.75076
            ],
            [
              39.633285,
              55.754247
            ],
            [
              39.630962,
              55.76435
            ],
            [
              39.63337442211901,
              55.76715323084988
            ],
            [
              39.6374,
              55.767896
            ],
            [
              39.641196,
              55.768303
            ],
            [
              39.650232,
              55.768562
            ],
            [
              39.653863,
              55.768122
            ],
            [
              39.65453736213451,
              55.769061945273144
            ],
            [
              39.657308,
              55.768176
            ],
            [
              39.659182,
              55.771461
            ],
            [
              39.655881,
              55.775684
            ],
            [
              39.652136,
              55.777888
            ],
            [
              39.65163292521975,
              55.7780001771712
            ],
            [
              39.651635,
              55.778015
            ],
            [
              39.651450642633826,
              55.77804082310626
            ],
            [
              39.647463,
              55.77893
            ],
            [
              39.64157,
              55.784887
            ],
            [
              39.643868,
              55.793273
            ],
            [
              39.652502,
              55.787438
            ],
            [
              39.653818,
              55.787951
            ],
            [
              39.656397,
              55.788983
            ],
            [
              39.668105,
              55.787404
            ],
            [
              39.66810510345778,
              55.78740421466002
            ],
            [
              39.669095,
              55.786927
            ],
            [
              39.673694,
              55.784778
            ],
            [
              39.678774,
              55.783125
            ],
            [
              39.684567,
              55.784377
            ],
            [
              39.689847,
              55.777589
            ],
            [
              39.685541,
              55.771296
            ],
            [
              39.686749,
              55.762347
            ],
            [
              39.690317,
              55.762829
            ],
            [
              39.694009,
              55.773252
            ],
            [
              39.694986,
              55.781043
            ],
            [
              39.695121,
              55.786527
            ],
            [
              39.698773,
              55.797656
            ],
            [
              39.707252,
              55.798342
            ],
            [
              39.71399,
              55.793974
            ],
            [
              39.717923,
              55.792937
            ],
            [
              39.722028,
              55.79174
            ],
            [
              39.726865,
              55.790282
            ],
            [
              39.730821,
              55.788637
            ],
            [
              39.730378,
              55.793039
            ],
            [
              39.724862,
              55.794751
            ],
            [
              39.721303,
              55.795858
            ],
            [
              39.714679,
              55.798988
            ],
            [
              39.711619,
              55.80093
            ],
            [
              39.707845,
              55.801893
            ],
            [
              39.696125,
              55.801806
            ],
            [
              39.691005,
              55.802451
            ],
            [
              39.686263,
              55.803154
            ],
            [
              39.68116,
              55.803942
            ],
            [
              39.676662,
              55.802587
            ],
            [
              39.67504846670961,
              55.81203515180467
            ],
            [
              39.678547,
              55.812413
            ],
            [
              39.681441,
              55.812645
            ],
            [
              39.684486,
              55.812608
            ],
            [
              39.684529,
              55.816208
            ],
            [
              39.681484,
              55.816245
            ],
            [
              39.678439,
              55.816281
            ],
            [
              39.671612791461556,
              55.81522026297326
            ],
            [
              39.66994220822327,
              55.815743692866064
            ],
            [
              39.662361,
              55.820319
            ],
            [
              39.65966644830123,
              55.82125293301635
            ],
            [
              39.659881,
              55.821666
            ],
            [
              39.655486,
              55.827014
            ],
            [
              39.654509,
              55.826429
            ],
            [
              39.647419,
              55.819594
            ],
            [
              39.643973,
              55.818886
            ],
            [
              39.63677882346565,
              55.81803235384822
            ],
            [
              39.630609,
              55.823308
            ],
            [
              39.637006,
              55.830602
            ],
            [
              39.638373,
              55.833933
            ],
            [
              39.631338,
              55.840463
            ],
            [
              39.63106574712489,
              55.84047617352622
            ],
            [
              39.631384,
              55.841409
            ],
            [
              39.631672,
              55.849405
            ],
            [
              39.631985,
              55.853797
            ],
            [
              39.632227,
              55.858783
            ],
            [
              39.632485,
              55.863731
            ],
            [
              39.626821,
              55.865705
            ],
            [
              39.621634,
              55.867371
            ],
            [
              39.618613,
              55.87029
            ],
            [
              39.619617,
              55.881547
            ],
            [
              39.625483,
              55.881642
            ],
            [
              39.636229,
              55.883988
            ],
            [
              39.63764332891327,
              55.8831357413979
            ],
            [
              39.638429,
              55.881984
            ],
            [
              39.64481,
              55.874804
            ],
            [
              39.646542731415984,
              55.87266728106757
            ],
            [
              39.646555,
              55.872641
            ],
            [
              39.646619936717414,
              55.87257207529723
            ],
            [
              39.64846,
              55.870303
            ],
            [
              39.64959642601537,
              55.86941278963688
            ],
            [
              39.650984,
              55.86794
            ],
            [
              39.653769,
              55.866067
            ],
            [
              39.65378328533298,
              55.86608824667743
            ],
            [
              39.654151,
              55.865845
            ],
            [
              39.65918059160842,
              55.86074555295257
            ],
            [
              39.662048,
              55.852983
            ],
            [
              39.661769,
              55.847477
            ],
            [
              39.661593,
              55.843885
            ],
            [
              39.661443,
              55.841736
            ],
            [
              39.665034,
              55.841485
            ],
            [
              39.665184,
              55.843634
            ],
            [
              39.665365,
              55.8473
            ],
            [
              39.671645,
              55.857086
            ],
            [
              39.67769,
              55.855274
            ],
            [
              39.681076,
              55.854157
            ],
            [
              39.686031,
              55.85334
            ],
            [
              39.691371,
              55.850846
            ],
            [
              39.691676,
              55.850871
            ],
            [
              39.692287353780294,
              55.85242962051835
            ],
            [
              39.696598,
              55.851533
            ],
            [
              39.703307,
              55.851304
            ],
            [
              39.713841,
              55.849583
            ],
            [
              39.725024,
              55.848881
            ],
            [
              39.731146,
              55.842214
            ],
            [
              39.734014,
              55.83978
            ],
            [
              39.734417,
              55.839721
            ],
            [
              39.734623404801965,
              55.84112101428533
            ],
            [
              39.73552,
              55.843094
            ],
            [
              39.733787,
              55.846268
            ],
            [
              39.731373,
              55.849397
            ],
            [
              39.728422,
              55.85147
            ],
            [
              39.724879,
              55.853081
            ],
            [
              39.719153,
              55.854829
            ],
            [
              39.709264,
              55.854794
            ],
            [
              39.703441,
              55.854901
            ],
            [
              39.696722,
              55.855131
            ],
            [
              39.69412019642179,
              55.85557075302685
            ],
            [
              39.700563,
              55.860747
            ],
            [
              39.703259,
              55.863133
            ],
            [
              39.700911,
              55.865788
            ],
            [
              39.699185,
              55.867644
            ],
            [
              39.694473,
              55.86997
            ],
            [
              39.690867,
              55.872065
            ],
            [
              39.688197,
              55.876246
            ],
            [
              39.684523,
              55.87925
            ],
            [
              39.68439678718416,
              55.88147957108752
            ],
            [
              39.689858,
              55.880045
            ],
            [
              39.693131,
              55.881543
            ],
            [
              39.696619,
              55.884119
            ],
            [
              39.706629,
              55.880133
            ],
            [
              39.712982,
              55.874761
            ],
            [
              39.71572,
              55.871905
            ],
            [
              39.721551,
              55.869176
            ],
            [
              39.729812,
              55.866903
            ],
            [
              39.73328,
              55.865112
            ],
            [
              39.736909,
              55.864789
            ],
            [
              39.741412,
              55.864624
            ],
            [
              39.753156,
              55.864358
            ],
            [
              39.756714,
              55.863935
            ],
            [
              39.760575,
              55.864225
            ],
            [
              39.762393,
              55.867333
            ],
            [
              39.761408,
              55.867909
            ],
            [
              39.760247,
              55.868278
            ],
            [
              39.752593,
              55.868952
            ],
            [
              39.748364,
              55.869805
            ],
            [
              39.743205,
              55.878876
            ],
            [
              39.742349,
              55.887879
            ],
            [
              39.738956,
              55.889139
            ],
            [
              39.731482,
              55.891758
            ],
            [
              39.728482,
              55.894043
            ],
            [
              39.724598,
              55.902728
            ],
            [
              39.728862,
              55.910534
            ],
            [
              39.72971288929211,
              55.91201287815961
            ],
            [
              39.741249,
              55.915275
            ],
            [
              39.746352,
              55.912917
            ],
            [
              39.749946,
              55.912125
            ],
            [
              39.754104,
              55.910496
            ],
            [
              39.759694,
              55.908923
            ],
            [
              39.768658,
              55.901563
            ],
            [
              39.772312,
              55.898815
            ],
            [
              39.774816,
              55.897772
            ],
            [
              39.77485961434232,
              55.8978450924371
            ],
            [
              39.775879,
              55.897457
            ],
            [
              39.783661,
              55.894168
            ],
            [
              39.787608,
              55.892042
            ],
            [
              39.788708,
              55.883142
            ],
            [
              39.790073,
              55.879811
            ],
            [
              39.79268684063536,
              55.88069659077295
            ],
            [
              39.79269492810117,
              55.88069899607584
            ],
            [
              39.797234,
              55.88185
            ],
            [
              39.805978,
              55.88112
            ],
            [
              39.809954,
              55.880567
            ],
            [
              39.811262,
              55.869262
            ],
            [
              39.807285,
              55.868556
            ],
            [
              39.801755,
              55.869359
            ],
            [
              39.798077,
              55.870381
            ],
            [
              39.79631,
              55.870744
            ],
            [
              39.795299,
              55.867289
            ],
            [
              39.79625,
              55.86701
            ],
            [
              39.800287,
              55.866046
            ],
            [
              39.80887240292907,
              55.85971148018011
            ],
            [
              39.809892,
              55.858306
            ],
            [
              39.813045,
              55.860354
            ],
            [
              39.818891,
              55.868301
            ],
            [
              39.826129,
              55.870894
            ],
            [
              39.834101,
              55.868505
            ],
            [
              39.83622,
              55.86429
            ],
            [
              39.836364,
              55.85763
            ],
            [
              39.837125,
              55.855436
            ],
            [
              39.837686,
              55.85188
            ],
            [
              39.840718,
              55.852358
            ],
            [
              39.85184404371421,
              55.85309844739228
            ],
            [
              39.851721,
              55.852191
            ],
            [
              39.849201,
              55.84504
            ],
            [
              39.838512,
              55.840858
            ],
            [
              39.835296,
              55.842778
            ],
            [
              39.832068,
              55.844515
            ],
            [
              39.828519,
              55.845271
            ],
            [
              39.81918,
              55.843148
            ],
            [
              39.808691,
              55.843948
            ],
            [
              39.803088,
              55.847564
            ],
            [
              39.802492,
              55.847325
            ],
            [
              39.8034338861247,
              55.84497415352595
            ],
            [
              39.80012,
              55.846176
            ],
            [
              39.795504,
              55.846771
            ],
            [
              39.789126,
              55.849769
            ],
            [
              39.787844,
              55.849427
            ],
            [
              39.786086,
              55.845856
            ],
            [
              39.788458906864776,
              55.84345869090151
            ],
            [
              39.78837,
              55.843176
            ],
            [
              39.788906,
              55.843007
            ],
            [
              39.792806,
              55.843033
            ],
            [
              39.799827,
              55.842513
            ],
            [
              39.80683291746985,
              55.84056533828246
            ],
            [
              39.812285,
              55.83823
            ],
            [
              39.816069,
              55.837889
            ],
            [
              39.82276469734672,
              55.83918365144666
            ],
            [
              39.827288,
              55.838666
            ],
            [
              39.838043,
              55.836925
            ],
            [
              39.841571,
              55.836303
            ],
            [
              39.845317,
              55.837477
            ],
            [
              39.848926,
              55.839204
            ],
            [
              39.85156,
              55.841696
            ],
            [
              39.853672,
              55.845253
            ],
            [
              39.854948,
              55.848644
            ],
            [
              39.855405,
              55.852185
            ],
            [
              39.85542672296277,
              55.85242696776514
            ],
            [
              39.8555,
              55.852411
            ],
            [
              39.855627,
              55.852783
            ],
            [
              39.862511,
              55.858883
            ],
            [
              39.86709,
              55.858602
            ],
            [
              39.867741,
              55.858434
            ],
            [
              39.868639,
              55.861921
            ],
            [
              39.867988,
              55.862088
            ],
            [
              39.8626,
              55.862482
            ],
            [
              39.858747,
              55.862419
            ],
            [
              39.855674033084625,
              55.862887078859494
            ],
            [
              39.848698,
              55.871837
            ],
            [
              39.856372,
              55.876817
            ],
            [
              39.859942,
              55.877597
            ],
            [
              39.862972,
              55.882788
            ],
            [
              39.869886,
              55.884801
            ],
            [
              39.873788,
              55.883961
            ],
            [
              39.878974,
              55.883682
            ],
            [
              39.884886,
              55.888779
            ],
            [
              39.886578,
              55.890487
            ],
            [
              39.886626,
              55.890938
            ],
            [
              39.886624617873274,
              55.89094703565349
            ],
            [
              39.896337,
              55.89282
            ],
            [
              39.897023,
              55.892612
            ],
            [
              39.898178,
              55.89401
            ],
            [
              39.898095619113235,
              55.89558524574134
            ],
            [
              39.903853,
              55.895789
            ],
            [
              39.915071,
              55.895865
            ],
            [
              39.917515,
              55.8932
            ],
            [
              39.919572,
              55.890259
            ],
            [
              39.92248,
              55.888608
            ],
            [
              39.922689,
              55.888813
            ],
            [
              39.923347,
              55.890403
            ],
            [
              39.921575,
              55.894388
            ],
            [
              39.918471,
              55.897411
            ],
            [
              39.915211,
              55.903483
            ],
            [
              39.910761,
              55.903487
            ],
            [
              39.907504,
              55.901758
            ],
            [
              39.907798,
              55.920198
            ],
            [
              39.919031,
              55.918199
            ],
            [
              39.920697,
              55.915008
            ],
            [
              39.930446,
              55.914605
            ],
            [
              39.930446241281345,
              55.91460548062049
            ],
            [
              39.934957,
              55.912355
            ],
            [
              39.939873,
              55.911873
            ],
            [
              39.943422,
              55.911342
            ],
            [
              39.944127,
              55.914872
            ],
            [
              39.943412,
              55.915015
            ],
            [
              39.93994,
              55.915473
            ],
            [
              39.936171,
              55.915744
            ],
            [
              39.93206100145147,
              55.91782247380191
            ],
            [
              39.93209162448516,
              55.92781870694152
            ],
            [
              39.93501531546785,
              55.929926098089496
            ],
            [
              39.938613,
              55.930763
            ],
            [
              39.943676,
              55.930184
            ],
            [
              39.947382,
              55.930025
            ],
            [
              39.94726815955659,
              55.930145720379834
            ],
            [
              39.948368,
              55.930134
            ],
            [
              39.952084,
              55.931502
            ],
            [
              39.954964,
              55.933662
            ],
            [
              39.953912,
              55.935622
            ],
            [
              39.949549,
              55.936325
            ],
            [
              39.945301068852324,
              55.93707885489082
            ],
            [
              39.944608,
              55.940261
            ],
            [
              39.94215,
              55.939726
            ],
            [
              39.938713,
              55.938248
            ],
            [
              39.931392,
              55.946751
            ],
            [
              39.924681,
              55.952431
            ],
            [
              39.922397,
              55.958584
            ],
            [
              39.932914,
              55.962552
            ],
            [
              39.934958,
              55.967398
            ],
            [
              39.931515,
              55.9768
            ],
            [
              39.934211,
              55.979575
            ],
            [
              39.937747,
              55.982785
            ],
            [
              39.940345,
              55.983981
            ],
            [
              39.942008842649294,
              55.985511259653904
            ],
            [
              39.94201,
              55.985512
            ],
            [
              39.942010175821025,
              55.985512485790835
            ],
            [
              39.942724,
              55.986169
            ],
            [
              39.951555,
              55.993968
            ],
            [
              39.9516,
              55.994
            ],
            [
              39.955072717870216,
              55.99646657243235
            ],
            [
              39.959817743124496,
              55.99750821502188
            ],
            [
              39.965528,
              55.994634
            ],
            [
              39.966731,
              55.993573
            ],
            [
              39.9781673335561,
              55.99618564453922
            ],
            [
              39.980213,
              55.987769
            ],
            [
              39.980226,
              55.986473
            ],
            [
              39.98031180874786,
              55.98422734606455
            ],
            [
              39.978922,
              55.983002
            ],
            [
              39.974088,
              55.981519
            ],
            [
              39.970737,
              55.978387
            ],
            [
              39.968476,
              55.971041
            ],
            [
              39.966374,
              55.964262
            ],
            [
              39.96808,
              55.958368
            ],
            [
              39.970547,
              55.955669
            ],
            [
              39.973446,
              55.95425
            ],
            [
              39.976345,
              55.95283
            ],
            [
              39.977928,
              55.956064
            ],
            [
              39.985963,
              55.96352
            ],
            [
              39.987967,
              55.962796
            ],
            [
              39.992859,
              55.954689
            ],
            [
              39.995726,
              55.952511
            ],
            [
              40.005657,
              55.950846
            ],
            [
              40.006139,
              55.950816
            ],
            [
              40.012284,
              55.950356
            ],
            [
              40.013664,
              55.953681
            ],
            [
              40.010796,
              55.959079
            ],
            [
              40.008435,
              55.964282
            ],
            [
              40.006906,
              55.969055
            ],
            [
              40.00872680900136,
              55.97645213754456
            ],
            [
              40.00893,
              55.976426
            ],
            [
              40.010542,
              55.977121
            ],
            [
              40.021704,
              55.980342
            ],
            [
              40.02343103449914,
              55.98194595962283
            ],
            [
              40.025097,
              55.981682
            ],
            [
              40.025354,
              55.981643
            ],
            [
              40.025925,
              55.981706
            ],
            [
              40.030372112800585,
              55.9840955413173
            ],
            [
              40.03265077799685,
              55.98432968477831
            ],
            [
              40.036868,
              55.979489
            ],
            [
              40.037843,
              55.978696
            ],
            [
              40.042881,
              55.976953
            ],
            [
              40.042951365832295,
              55.97705413377161
            ],
            [
              40.043158,
              55.976761
            ],
            [
              40.040771,
              55.968321
            ],
            [
              40.037575,
              55.965464
            ],
            [
              40.044581,
              55.964932
            ],
            [
              40.051026,
              55.966921
            ],
            [
              40.057358,
              55.953682
            ],
            [
              40.056945,
              55.950083
            ],
            [
              40.056515,
              55.944659
            ],
            [
              40.056241,
              55.943068
            ],
            [
              40.056554,
              55.942995
            ],
            [
              40.058054,
              55.942738
            ],
            [
              40.060016,
              55.943244
            ],
            [
              40.060104,
              55.944381
            ],
            [
              40.060708,
              55.952365
            ],
            [
              40.06036,
              55.956632
            ],
            [
              40.06012,
              55.960726
            ],
            [
              40.059091,
              55.96419
            ],
            [
              40.061611,
              55.973483
            ],
            [
              40.072128,
              55.976057
            ],
            [
              40.075004,
              55.973396
            ],
            [
              40.077657,
              55.969832
            ],
            [
              40.081307,
              55.968196
            ],
            [
              40.084998,
              55.967053
            ],
            [
              40.091658,
              55.971369
            ],
            [
              40.092757,
              55.974797
            ],
            [
              40.092169,
              55.9751
            ],
            [
              40.088564,
              55.976312
            ],
            [
              40.083634,
              55.976313
            ],
            [
              40.079751,
              55.976224
            ],
            [
              40.074252,
              55.980264
            ],
            [
              40.070492,
              55.98075
            ],
            [
              40.066422,
              55.980141
            ],
            [
              40.06159,
              55.982793
            ],
            [
              40.0641,
              55.993084
            ],
            [
              40.075077,
              55.997399
            ],
            [
              40.079986,
              55.995
            ],
            [
              40.083994,
              55.991289
            ],
            [
              40.091305,
              55.997509
            ],
            [
              40.095613,
              55.997644
            ],
            [
              40.10489,
              55.998335
            ],
            [
              40.109124,
              55.997026
            ],
            [
              40.119784,
              55.998192
            ],
            [
              40.120225,
              56.001765
            ],
            [
              40.117882,
              56.011926
            ],
            [
              40.115576,
              56.018469
            ],
            [
              40.121645,
              56.024645
            ],
            [
              40.121222,
              56.028221
            ],
            [
              40.131482,
              56.034427
            ],
            [
              40.132358,
              56.034506
            ],
            [
              40.132033,
              56.038091
            ],
            [
              40.132789,
              56.049974
            ],
            [
              40.134963,
              56.053574
            ],
            [
              40.139372,
              56.05835
            ],
            [
              40.13949,
              56.058516
            ],
            [
              40.142142,
              56.063758
            ],
            [
              40.141217,
              56.067237
            ],
            [
              40.140019,
              56.066919
            ],
            [
              40.136399,
              56.065922
            ],
            [
              40.131982,
              56.064706
            ],
            [
              40.126617,
              56.06323
            ],
            [
              40.123543,
              56.060579
            ],
            [
              40.114214,
              56.065856
            ],
            [
              40.110952,
              56.06513
            ],
            [
              40.105307,
              56.062851
            ],
            [
              40.094197,
              56.061224
            ],
            [
              40.090839,
              56.063092
            ],
            [
              40.08154,
              56.063912
            ],
            [
              40.076663,
              56.061998
            ],
            [
              40.072253,
              56.060204
            ],
            [
              40.067043,
              56.058086
            ],
            [
              40.061291,
              56.057201
            ],
            [
              40.052529,
              56.055414
            ],
            [
              40.048605,
              56.056231
            ],
            [
              40.039668,
              56.063786
            ],
            [
              40.040897,
              56.075119
            ],
            [
              40.045281,
              56.074861
            ],
            [
              40.045493,
              56.078454
            ],
            [
              40.041109,
              56.078713
            ],
            [
              40.036726,
              56.078971
            ],
            [
              40.033612,
              56.079185
            ],
            [
              40.03009,
              56.079596
            ],
            [
              40.021704,
              56.080541
            ],
            [
              40.019387,
              56.086215
            ],
            [
              40.015745,
              56.093565
            ],
            [
              40.014568,
              56.096966
            ],
            [
              40.009913,
              56.100918
            ],
            [
              40.007021,
              56.103063
            ],
            [
              40.006269,
              56.102049
            ],
            [
              40.000644,
              56.097619
            ],
            [
              39.998195,
              56.094886
            ],
            [
              39.98801,
              56.093515
            ],
            [
              39.985423,
              56.103124
            ],
            [
              39.988612,
              56.110741
            ],
            [
              39.989603,
              56.113058
            ],
            [
              39.986293,
              56.114474
            ],
            [
              39.985302,
              56.112156
            ],
            [
              39.982102,
              56.104514
            ],
            [
              39.973594,
              56.108229
            ],
            [
              39.970145,
              56.10926
            ],
            [
              39.969638,
              56.107566
            ],
            [
              39.96789,
              56.103072
            ],
            [
              39.965398,
              56.096326
            ],
            [
              39.956994,
              56.093188
            ],
            [
              39.953145,
              56.094653
            ],
            [
              39.946484,
              56.101121
            ],
            [
              39.94585,
              56.100997
            ],
            [
              39.935456,
              56.09876
            ],
            [
              39.934319,
              56.095344
            ],
            [
              39.926816,
              56.086607
            ],
            [
              39.926626,
              56.083012
            ],
            [
              39.92761,
              56.071386
            ],
            [
              39.922912,
              56.071354
            ],
            [
              39.920033,
              56.068218
            ],
            [
              39.913536,
              56.066091
            ],
            [
              39.90705,
              56.07041
            ],
            [
              39.898555,
              56.070987
            ],
            [
              39.893749,
              56.072063
            ],
            [
              39.884438,
              56.075117
            ],
            [
              39.877643,
              56.0725
            ],
            [
              39.877106,
              56.071217
            ],
            [
              39.876675,
              56.069033
            ],
            [
              39.881824,
              56.065202
            ],
            [
              39.883788,
              56.066336
            ],
            [
              39.891174,
              56.068716
            ],
            [
              39.898353,
              56.065027
            ],
            [
              39.903976,
              56.066504
            ],
            [
              39.911488,
              56.057548
            ],
            [
              39.911372,
              56.049553
            ],
            [
              39.912042,
              56.047558
            ],
            [
              39.91622,
              56.04566
            ],
            [
              39.921361,
              56.050094
            ],
            [
              39.923735,
              56.051177
            ],
            [
              39.925022,
              56.05274
            ],
            [
              39.922243,
              56.055029
            ],
            [
              39.927383,
              56.065216
            ],
            [
              39.931281,
              56.064596
            ],
            [
              39.937307290028684,
              56.06414043525207
            ],
            [
              39.936817,
              56.063338
            ],
            [
              39.94195,
              56.053674
            ],
            [
              39.952345,
              56.048791
            ],
            [
              39.95424942648488,
              56.04913814948778
            ],
            [
              39.954205,
              56.048556
            ],
            [
              39.954247,
              56.048479
            ],
            [
              39.955971,
              56.047623
            ],
            [
              39.959879,
              56.046398
            ],
            [
              39.960489,
              56.046512
            ],
            [
              39.967371,
              56.049958
            ],
            [
              39.970825,
              56.03941
            ],
            [
              39.970285,
              56.03344
            ],
            [
              39.959123,
              56.030005
            ],
            [
              39.957804,
              56.033355
            ],
            [
              39.95293,
              56.031373
            ],
            [
              39.94359,
              56.025474
            ],
            [
              39.940952,
              56.028598
            ],
            [
              39.93712,
              56.024053
            ],
            [
              39.928059,
              56.022937
            ],
            [
              39.918334,
              56.024517
            ],
            [
              39.911426,
              56.027075
            ],
            [
              39.907909,
              56.027532
            ],
            [
              39.904404,
              56.026602
            ],
            [
              39.894423,
              56.024674
            ],
            [
              39.890414,
              56.025891
            ],
            [
              39.885706,
              56.028635
            ],
            [
              39.880343,
              56.032357
            ],
            [
              39.88002109344045,
              56.032016747108635
            ],
            [
              39.876162,
              56.033378
            ],
            [
              39.872017,
              56.034051
            ],
            [
              39.873233,
              56.030229
            ],
            [
              39.878635,
              56.029017
            ],
            [
              39.88186953778884,
              56.02677764380358
            ],
            [
              39.881777,
              56.026489
            ],
            [
              39.882727,
              56.026184
            ],
            [
              39.886274,
              56.024138
            ],
            [
              39.89155,
              56.021691
            ],
            [
              39.895251729791724,
              56.020683634700426
            ],
            [
              39.893537,
              56.013057
            ],
            [
              39.884891531478836,
              56.00912868303325
            ],
            [
              39.878253,
              56.013682
            ],
            [
              39.87512177572878,
              56.014502233770266
            ],
            [
              39.874971,
              56.014739
            ],
            [
              39.874557106858354,
              56.01473880582071
            ],
            [
              39.871406,
              56.016705
            ],
            [
              39.864261,
              56.009721
            ],
            [
              39.860725,
              56.010939
            ],
            [
              39.85533,
              56.010844
            ],
            [
              39.84825,
              56.004964
            ],
            [
              39.842195,
              56.004273
            ],
            [
              39.837305,
              56.004314
            ],
            [
              39.830839,
              56.00554
            ],
            [
              39.822991,
              56.00538
            ],
            [
              39.818556,
              56.005445
            ],
            [
              39.807276,
              56.009432
            ],
            [
              39.803693,
              56.009075
            ],
            [
              39.803781,
              56.008192
            ],
            [
              39.808103,
              56.004672
            ],
            [
              39.813072,
              56.002356
            ],
            [
              39.818228,
              56.00186
            ],
            [
              39.822934,
              56.001781
            ],
            [
              39.826545,
              56.001281
            ],
            [
              39.831637,
              56.002029
            ],
            [
              39.836673,
              56.00077
            ],
            [
              39.842291,
              56.000674
            ],
            [
              39.848524,
              56.001375
            ],
            [
              39.852678,
              56.001878
            ],
            [
              39.854272983512345,
              55.98776614587696
            ],
            [
              39.844505,
              55.985197
            ],
            [
              39.832761,
              55.982019
            ],
            [
              39.822999,
              55.982318
            ],
            [
              39.820758,
              55.985024
            ],
            [
              39.817066,
              55.989394
            ],
            [
              39.814316,
              55.98707
            ],
            [
              39.812987,
              55.976948
            ],
            [
              39.809262,
              55.975854
            ],
            [
              39.8079,
              55.975619
            ],
            [
              39.80524,
              55.974936
            ],
            [
              39.802579,
              55.974253
            ],
            [
              39.800357,
              55.973511
            ],
            [
              39.795382840333964,
              55.97235945389803
            ],
            [
              39.793834,
              55.972123
            ],
            [
              39.791713,
              55.971535
            ],
            [
              39.791392621871395,
              55.97143569575091
            ],
            [
              39.791165,
              55.971383
            ],
            [
              39.787197,
              55.970147
            ],
            [
              39.781556,
              55.968578
            ],
            [
              39.772828,
              55.967134
            ],
            [
              39.76991010973391,
              55.97870356490017
            ],
            [
              39.770324,
              55.979006
            ],
            [
              39.773615,
              55.98272
            ],
            [
              39.776192,
              55.985477
            ],
            [
              39.778659,
              55.98817
            ],
            [
              39.778739,
              55.993249
            ],
            [
              39.778556,
              55.999293
            ],
            [
              39.774803,
              56.006315
            ],
            [
              39.773401,
              56.007539
            ],
            [
              39.768747,
              56.011495
            ],
            [
              39.76874597787435,
              56.01149379773128
            ],
            [
              39.768582,
              56.011634
            ],
            [
              39.766682926909574,
              56.01311058267256
            ],
            [
              39.764498,
              56.022291
            ],
            [
              39.767574,
              56.027789
            ],
            [
              39.763346,
              56.030266
            ],
            [
              39.753834,
              56.036499
            ],
            [
              39.750103,
              56.038346
            ],
            [
              39.738795,
              56.038278
            ],
            [
              39.733418,
              56.029096
            ],
            [
              39.729619,
              56.029056
            ],
            [
              39.726043,
              56.029292
            ],
            [
              39.722476,
              56.030306
            ],
            [
              39.718937,
              56.031282
            ],
            [
              39.710922,
              56.037945
            ],
            [
              39.709899,
              56.039448
            ],
            [
              39.705043,
              56.043887
            ],
            [
              39.700322,
              56.050702
            ],
            [
              39.70063774926659,
              56.0512961587682
            ],
            [
              39.701705,
              56.051881
            ],
            [
              39.701474822740714,
              56.05287131537789
            ],
            [
              39.705717,
              56.060854
            ],
            [
              39.709774,
              56.061239
            ],
            [
              39.713581,
              56.061886
            ],
            [
              39.713527,
              56.066289
            ],
            [
              39.716437,
              56.076861
            ],
            [
              39.719887,
              56.077554
            ],
            [
              39.722846,
              56.079891
            ],
            [
              39.724165,
              56.088874
            ],
            [
              39.726856,
              56.091309
            ],
            [
              39.732293,
              56.09563
            ],
            [
              39.733782,
              56.096618
            ],
            [
              39.733508054552,
              56.097227324461926
            ],
            [
              39.73475,
              56.09886
            ],
            [
              39.735825,
              56.105024
            ],
            [
              39.735677238095235,
              56.108898952381054
            ],
            [
              39.735556,
              56.1121
            ],
            [
              39.731958,
              56.111963
            ],
            [
              39.72164,
              56.114088
            ],
            [
              39.717059,
              56.119178
            ],
            [
              39.714415,
              56.124355
            ],
            [
              39.712567,
              56.128995
            ],
            [
              39.710939,
              56.138193
            ],
            [
              39.710037,
              56.148138
            ],
            [
              39.711029,
              56.153048
            ],
            [
              39.715965,
              56.158634
            ],
            [
              39.714939,
              56.162735
            ],
            [
              39.71416846839825,
              56.16328678943946
            ],
            [
              39.712805,
              56.166818
            ],
            [
              39.709255,
              56.166216
            ],
            [
              39.709532,
              56.1645
            ],
            [
              39.70945684814201,
              56.16410282686859
            ],
            [
              39.707088,
              56.164466
            ],
            [
              39.704647,
              56.16182
            ],
            [
              39.706838,
              56.159798
            ],
            [
              39.706757,
              56.149621
            ],
            [
              39.705893,
              56.144419
            ],
            [
              39.70624724331008,
              56.14226252662015
            ],
            [
              39.70459,
              56.140797
            ],
            [
              39.69768,
              56.134538
            ],
            [
              39.694834691569305,
              56.13238336724482
            ],
            [
              39.69478,
              56.132427
            ],
            [
              39.69396,
              56.131721
            ],
            [
              39.685865,
              56.127464
            ],
            [
              39.678855,
              56.137159
            ],
            [
              39.678892,
              56.138989
            ],
            [
              39.67836,
              56.140725
            ],
            [
              39.677499,
              56.142308
            ],
            [
              39.672673,
              56.152091
            ],
            [
              39.669177,
              56.151119
            ],
            [
              39.667813,
              56.147579
            ],
            [
              39.659824,
              56.140331
            ],
            [
              39.654775,
              56.136479
            ],
            [
              39.64828,
              56.131277
            ],
            [
              39.642946,
              56.128926
            ],
            [
              39.63369,
              56.1349
            ],
            [
              39.633958,
              56.138606
            ],
            [
              39.635889,
              56.142414
            ],
            [
              39.64051,
              56.148895
            ],
            [
              39.643599,
              56.150738
            ],
            [
              39.6451,
              56.151251
            ],
            [
              39.64628267751712,
              56.15233851073443
            ],
            [
              39.651183,
              56.155261
            ],
            [
              39.655452,
              56.158774
            ],
            [
              39.658829,
              56.1626
            ],
            [
              39.662009,
              56.165174
            ],
            [
              39.667716,
              56.16714
            ],
            [
              39.672226,
              56.169622
            ],
            [
              39.677662,
              56.171389
            ],
            [
              39.684633,
              56.173462
            ],
            [
              39.692192,
              56.174627
            ],
            [
              39.693090305088106,
              56.175137757007704
            ],
            [
              39.693142,
              56.175148
            ],
            [
              39.696732,
              56.175417
            ],
            [
              39.696603,
              56.177135
            ],
            [
              39.699429,
              56.179394
            ],
            [
              39.706231,
              56.180812
            ],
            [
              39.70972060281089,
              56.18251064263811
            ],
            [
              39.71153694664937,
              56.18338233497535
            ],
            [
              39.721309,
              56.179413
            ],
            [
              39.726622,
              56.176248
            ],
            [
              39.732427,
              56.174476
            ],
            [
              39.735856,
              56.171668
            ],
            [
              39.742369,
              56.164997
            ],
            [
              39.744189,
              56.163884
            ],
            [
              39.746067,
              56.166956
            ],
            [
              39.753398,
              56.172157
            ],
            [
              39.756046,
              56.173005
            ],
            [
              39.762432,
              56.18078
            ],
            [
              39.768651830868855,
              56.183361672557496
            ],
            [
              39.772564,
              56.183628
            ],
            [
              39.775227,
              56.183757
            ],
            [
              39.775852,
              56.183811
            ],
            [
              39.776897,
              56.185258
            ],
            [
              39.776851,
              56.185592
            ],
            [
              39.773133,
              56.194235
            ],
            [
              39.772255,
              56.196942
            ],
            [
              39.771772,
              56.197492
            ],
            [
              39.765473,
              56.198676
            ],
            [
              39.755735,
              56.2019
            ],
            [
              39.75272,
              56.204225
            ],
            [
              39.750144,
              56.20171
            ],
            [
              39.746168,
              56.193257
            ],
            [
              39.739196,
              56.194361
            ],
            [
              39.732224,
              56.195464
            ],
            [
              39.726427,
              56.196376
            ],
            [
              39.720632,
              56.197288
            ],
            [
              39.715641,
              56.198046
            ],
            [
              39.707296,
              56.199364
            ],
            [
              39.700505,
              56.198141
            ],
            [
              39.688252,
              56.200714
            ],
            [
              39.6799820427545,
              56.1981909889231
            ],
            [
              39.677693629328616,
              56.20650302936738
            ],
            [
              39.678319,
              56.208237
            ],
            [
              39.684598,
              56.21313
            ],
            [
              39.689878,
              56.213398
            ],
            [
              39.68987625435231,
              56.21343230245404
            ],
            [
              39.690517,
              56.213469
            ],
            [
              39.694116,
              56.216866
            ],
            [
              39.68969727768975,
              56.21694924277402
            ],
            [
              39.689695,
              56.216994
            ],
            [
              39.681562,
              56.221434
            ],
            [
              39.67849,
              56.223311
            ],
            [
              39.668604,
              56.222987
            ],
            [
              39.665022,
              56.222633
            ],
            [
              39.665222,
              56.220603
            ],
            [
              39.665423,
              56.218574
            ],
            [
              39.665909,
              56.214263
            ],
            [
              39.667348,
              56.210552
            ],
            [
              39.660365,
              56.203435
            ],
            [
              39.65984867363199,
              56.203688406065694
            ],
            [
              39.660047,
              56.205288
            ],
            [
              39.65704,
              56.205661
            ],
            [
              39.655318,
              56.205912
            ],
            [
              39.65358297583003,
              56.20621386086572
            ],
            [
              39.653583,
              56.206214
            ],
            [
              39.650576,
              56.206737
            ],
            [
              39.647568,
              56.20726
            ],
            [
              39.645626,
              56.207427
            ],
            [
              39.638069,
              56.206749
            ],
            [
              39.636364,
              56.215624
            ],
            [
              39.637575,
              56.219015
            ],
            [
              39.628731,
              56.217683
            ],
            [
              39.62422453083399,
              56.21695061054445
            ],
            [
              39.620715,
              56.227678
            ],
            [
              39.61802,
              56.227671
            ],
            [
              39.610992,
              56.228801
            ],
            [
              39.607955,
              56.235252
            ],
            [
              39.60794298239154,
              56.23525253083243
            ],
            [
              39.607943,
              56.235253
            ],
            [
              39.60787215227675,
              56.23525565948505
            ],
            [
              39.603563,
              56.235446
            ],
            [
              39.598748,
              56.23556
            ],
            [
              39.594756,
              56.235722
            ],
            [
              39.59095,
              56.235853
            ],
            [
              39.587416,
              56.235967
            ],
            [
              39.587034997250484,
              56.23597588219648
            ],
            [
              39.587035,
              56.235976
            ],
            [
              39.584159,
              56.236043
            ],
            [
              39.581282,
              56.23611
            ],
            [
              39.577517,
              56.236278
            ],
            [
              39.572018,
              56.245987
            ],
            [
              39.5714,
              56.249534
            ],
            [
              39.570067,
              56.249302
            ],
            [
              39.564132,
              56.248261
            ],
            [
              39.55969,
              56.256241
            ],
            [
              39.553849,
              56.256108
            ],
            [
              39.55062,
              56.254468
            ],
            [
              39.54971058308663,
              56.25399991776518
            ],
            [
              39.549708,
              56.254005
            ],
            [
              39.546536,
              56.252394
            ],
            [
              39.535045,
              56.253882
            ],
            [
              39.532351,
              56.255264
            ],
            [
              39.531342,
              56.253832
            ],
            [
              39.526928,
              56.246064
            ],
            [
              39.52672053793884,
              56.24555105345529
            ],
            [
              39.524117,
              56.243457
            ],
            [
              39.516373,
              56.235596
            ],
            [
              39.51315470256145,
              56.23702119626922
            ],
            [
              39.513164,
              56.237042
            ],
            [
              39.51183,
              56.237638
            ],
            [
              39.510827,
              56.238052
            ],
            [
              39.507398,
              56.23933
            ],
            [
              39.504002,
              56.240596
            ],
            [
              39.500075,
              56.242236
            ],
            [
              39.495981,
              56.244582
            ],
            [
              39.489043,
              56.246968
            ],
            [
              39.483576876300134,
              56.252227067104535
            ],
            [
              39.483301,
              56.253311
            ],
            [
              39.473632,
              56.260239
            ],
            [
              39.470629,
              56.2628
            ],
            [
              39.466185,
              56.266757
            ],
            [
              39.470813,
              56.276693
            ],
            [
              39.471641,
              56.280196
            ],
            [
              39.46793,
              56.27984
            ],
            [
              39.459187,
              56.283991
            ],
            [
              39.464685,
              56.293727
            ],
            [
              39.469143,
              56.294312
            ],
            [
              39.47382,
              56.29414
            ],
            [
              39.477917,
              56.29297
            ],
            [
              39.481778,
              56.292348
            ],
            [
              39.485472,
              56.291855
            ],
            [
              39.490358,
              56.291476
            ],
            [
              39.494092,
              56.291446
            ],
            [
              39.498628,
              56.2907
            ],
            [
              39.499129,
              56.290815
            ],
            [
              39.50498621724704,
              56.29261095300605
            ],
            [
              39.512258,
              56.292697
            ],
            [
              39.516254,
              56.292201
            ],
            [
              39.522676,
              56.291447
            ],
            [
              39.52652,
              56.290799
            ],
            [
              39.530786,
              56.280329
            ],
            [
              39.532364,
              56.276714
            ],
            [
              39.53464,
              56.279581
            ],
            [
              39.538506863038535,
              56.28979325509741
            ],
            [
              39.53950111868944,
              56.2898300117357
            ],
            [
              39.546511,
              56.289635
            ],
            [
              39.552536,
              56.289456
            ],
            [
              39.557585,
              56.289227
            ],
            [
              39.562706,
              56.289048
            ],
            [
              39.567148,
              56.288944
            ],
            [
              39.571574,
              56.288841
            ],
            [
              39.5838025427282,
              56.288446281215734
            ],
            [
              39.587865,
              56.283066
            ],
            [
              39.583279,
              56.272613
            ],
            [
              39.579121,
              56.272006
            ],
            [
              39.573599,
              56.271279
            ],
            [
              39.573077,
              56.270882
            ],
            [
              39.575257,
              56.268017
            ],
            [
              39.579642,
              56.268444
            ],
            [
              39.583799,
              56.269051
            ],
            [
              39.590178,
              56.270747
            ],
            [
              39.59160432206878,
              56.274673744864174
            ],
            [
              39.592258,
              56.274687
            ],
            [
              39.59222294836354,
              56.276376857841925
            ],
            [
              39.592223,
              56.276377
            ],
            [
              39.603767,
              56.279161
            ],
            [
              39.609891,
              56.276795
            ],
            [
              39.611339,
              56.275968
            ],
            [
              39.611996701585134,
              56.27670671010261
            ],
            [
              39.614606,
              56.273842
            ],
            [
              39.618124,
              56.27423
            ],
            [
              39.628305,
              56.272243
            ],
            [
              39.632494,
              56.271987
            ],
            [
              39.633836,
              56.277475
            ],
            [
              39.63355036386675,
              56.27954174355651
            ],
            [
              39.635624,
              56.281449
            ],
            [
              39.64229472756773,
              56.28635239297815
            ],
            [
              39.64340364940518,
              56.286524945446104
            ],
            [
              39.64340417721788,
              56.28652492707268
            ],
            [
              39.650857,
              56.283458
            ],
            [
              39.654307,
              56.276608
            ],
            [
              39.656087,
              56.273216
            ],
            [
              39.662803,
              56.26913
            ],
            [
              39.664831,
              56.267913
            ],
            [
              39.664931383965914,
              56.26785280921965
            ],
            [
              39.668407,
              56.257074
            ],
            [
              39.672508,
              56.253962
            ],
            [
              39.674877,
              56.246316
            ],
            [
              39.668003,
              56.242194
            ],
            [
              39.664192,
              56.242396
            ],
            [
              39.658837,
              56.244184
            ],
            [
              39.655151,
              56.245557
            ],
            [
              39.651465,
              56.247611
            ],
            [
              39.648273,
              56.249077
            ],
            [
              39.644662,
              56.248601
            ],
            [
              39.649899,
              56.244277
            ],
            [
              39.653589,
              56.242313
            ],
            [
              39.657635,
              56.240791
            ],
            [
              39.662892,
              56.23886
            ],
            [
              39.667919,
              56.238595
            ],
            [
              39.674200371303954,
              56.2380986988506
            ],
            [
              39.674202,
              56.238086
            ],
            [
              39.67469809862711,
              56.23789027147062
            ],
            [
              39.680695,
              56.232871
            ],
            [
              39.686929,
              56.234143
            ],
            [
              39.689293,
              56.238168
            ],
            [
              39.700892,
              56.239839
            ],
            [
              39.701405,
              56.236276
            ],
            [
              39.705755,
              56.244962
            ],
            [
              39.712352,
              56.24211
            ],
            [
              39.717424,
              56.241915
            ],
            [
              39.723806,
              56.243088
            ],
            [
              39.723116,
              56.247115
            ],
            [
              39.720745,
              56.254008
            ],
            [
              39.720168,
              56.259408
            ],
            [
              39.721013,
              56.264025
            ],
            [
              39.72100031603573,
              56.26403511701424
            ],
            [
              39.721725,
              56.265678
            ],
            [
              39.720665,
              56.266146
            ],
            [
              39.71670724729809,
              56.26883018532064
            ],
            [
              39.713351,
              56.277461
            ],
            [
              39.709801,
              56.285033
            ],
            [
              39.717885,
              56.289391
            ],
            [
              39.721951,
              56.289054
            ],
            [
              39.725855,
              56.289022
            ],
            [
              39.729812,
              56.288742
            ],
            [
              39.734321,
              56.288941
            ],
            [
              39.7382,
              56.291146
            ],
            [
              39.747403,
              56.288201
            ],
            [
              39.758062,
              56.283651
            ],
            [
              39.758574,
              56.278173
            ],
            [
              39.75952,
              56.264295
            ],
            [
              39.756635,
              56.261732
            ],
            [
              39.754634,
              56.259701
            ],
            [
              39.757199,
              56.257175
            ],
            [
              39.760619,
              56.256413
            ],
            [
              39.761706,
              56.256378
            ],
            [
              39.761888,
              56.257327
            ],
            [
              39.763845,
              56.264541
            ],
            [
              39.762284,
              56.272665
            ],
            [
              39.762145,
              56.278635
            ],
            [
              39.770463,
              56.285003
            ],
            [
              39.778944,
              56.276563
            ],
            [
              39.781041,
              56.273676
            ],
            [
              39.783824,
              56.270431
            ],
            [
              39.786413,
              56.267082
            ],
            [
              39.792951,
              56.268288
            ],
            [
              39.795425,
              56.268884
            ],
            [
              39.7979,
              56.269481
            ],
            [
              39.797056,
              56.27298
            ],
            [
              39.792108,
              56.271788
            ],
            [
              39.786791,
              56.273352
            ],
            [
              39.785691,
              56.282561
            ],
            [
              39.782499,
              56.285158
            ],
            [
              39.776801,
              56.287695
            ],
            [
              39.77096,
              56.288568
            ],
            [
              39.767587,
              56.289511
            ],
            [
              39.762783,
              56.291548
            ],
            [
              39.758452,
              56.292332
            ],
            [
              39.754137,
              56.2984
            ],
            [
              39.756294,
              56.304137
            ],
            [
              39.76169,
              56.30888
            ],
            [
              39.765347,
              56.310332
            ],
            [
              39.769263,
              56.312079
            ],
            [
              39.779956,
              56.313713
            ],
            [
              39.790982,
              56.310429
            ],
            [
              39.791826,
              56.310138
            ],
            [
              39.793001,
              56.31354
            ],
            [
              39.787989,
              56.316423
            ],
            [
              39.78232,
              56.324266
            ],
            [
              39.780049,
              56.32706
            ],
            [
              39.779044,
              56.327045
            ],
            [
              39.776792,
              56.324236
            ],
            [
              39.774606,
              56.31873
            ],
            [
              39.765571,
              56.314403
            ],
            [
              39.761773,
              56.312855
            ],
            [
              39.75711,
              56.310918
            ],
            [
              39.753842,
              56.308245
            ],
            [
              39.747479,
              56.299174
            ],
            [
              39.745867470068696,
              56.297988410824196
            ],
            [
              39.745672,
              56.298145
            ],
            [
              39.744002,
              56.296616
            ],
            [
              39.733883,
              56.292514
            ],
            [
              39.729658,
              56.292338
            ],
            [
              39.72541,
              56.292683
            ],
            [
              39.71577,
              56.296707
            ],
            [
              39.70725,
              56.29297
            ],
            [
              39.70052,
              56.291831
            ],
            [
              39.695944,
              56.297436
            ],
            [
              39.695125,
              56.301211
            ],
            [
              39.693093,
              56.306051
            ],
            [
              39.686946,
              56.300264
            ],
            [
              39.68377419984632,
              56.300537595942615
            ],
            [
              39.67761464177565,
              56.30192529432196
            ],
            [
              39.67727,
              56.308462
            ],
            [
              39.684328,
              56.317959
            ],
            [
              39.686794,
              56.322304
            ],
            [
              39.684323,
              56.324882
            ],
            [
              39.683459569554515,
              56.32419927919977
            ],
            [
              39.681611,
              56.32525
            ],
            [
              39.679323,
              56.323004
            ],
            [
              39.679123,
              56.322643
            ],
            [
              39.677113,
              56.319181
            ],
            [
              39.670396,
              56.312497
            ],
            [
              39.670999,
              56.306541
            ],
            [
              39.67407679234076,
              56.3007153930548
            ],
            [
              39.674516,
              56.296211
            ],
            [
              39.666019,
              56.289304
            ],
            [
              39.659569,
              56.289546
            ],
            [
              39.653034,
              56.287991
            ],
            [
              39.65137540375258,
              56.288362853614224
            ],
            [
              39.651427,
              56.289848
            ],
            [
              39.643260648705336,
              56.290131339567424
            ],
            [
              39.633961,
              56.290455
            ],
            [
              39.628026,
              56.284355
            ],
            [
              39.624866,
              56.282687
            ],
            [
              39.617729,
              56.283385
            ],
            [
              39.616322493061276,
              56.28741079141699
            ],
            [
              39.627817,
              56.287047
            ],
            [
              39.627931,
              56.290646
            ],
            [
              39.615050048657494,
              56.2910528607295
            ],
            [
              39.61505,
              56.291053
            ],
            [
              39.615049995679776,
              56.291052862402864
            ],
            [
              39.612038,
              56.291148
            ],
            [
              39.61177075194273,
              56.29095705743309
            ],
            [
              39.611777,
              56.291156
            ],
            [
              39.596145,
              56.29165
            ],
            [
              39.5931,
              56.291343
            ],
            [
              39.58748494773966,
              56.29177229282147
            ],
            [
              39.58749,
              56.291929
            ],
            [
              39.577869,
              56.293356
            ],
            [
              39.57412,
              56.293453
            ],
            [
              39.567232,
              56.292543
            ],
            [
              39.56279,
              56.292647
            ],
            [
              39.555205,
              56.299855
            ],
            [
              39.548472,
              56.308498
            ],
            [
              39.54697431670151,
              56.30892597133262
            ],
            [
              39.546686,
              56.313209
            ],
            [
              39.548679,
              56.317824
            ],
            [
              39.551443,
              56.321625
            ],
            [
              39.55116403136962,
              56.322039610467385
            ],
            [
              39.553264,
              56.325602
            ],
            [
              39.553562,
              56.326181
            ],
            [
              39.550364,
              56.327833
            ],
            [
              39.54769590844126,
              56.327194025379
            ],
            [
              39.545526,
              56.330419
            ],
            [
              39.544715,
              56.333407
            ],
            [
              39.541917,
              56.334986
            ],
            [
              39.540148,
              56.331851
            ],
            [
              39.538927,
              56.32891
            ],
            [
              39.53776337647032,
              56.325304178732985
            ],
            [
              39.537258,
              56.325209
            ],
            [
              39.532462,
              56.314969
            ],
            [
              39.53152,
              56.311341
            ],
            [
              39.52997754574327,
              56.3091251249659
            ],
            [
              39.522808,
              56.304277
            ],
            [
              39.516675,
              56.295776
            ],
            [
              39.512702,
              56.29627
            ],
            [
              39.507805,
              56.296768
            ],
            [
              39.50558671231479,
              56.29649726939546
            ],
            [
              39.505586,
              56.296503
            ],
            [
              39.50296577182048,
              56.29617739708619
            ],
            [
              39.502856,
              56.296164
            ],
            [
              39.50192440397402,
              56.29604799140778
            ],
            [
              39.501844,
              56.296038
            ],
            [
              39.498102,
              56.295572
            ],
            [
              39.490553,
              56.295071
            ],
            [
              39.485815,
              56.295439
            ],
            [
              39.482317,
              56.295907
            ],
            [
              39.47826,
              56.307175
            ],
            [
              39.478281,
              56.310775
            ],
            [
              39.476614,
              56.310785
            ],
            [
              39.47465,
              56.309981
            ],
            [
              39.474223,
              56.305542
            ],
            [
              39.46870875237844,
              56.299458560947336
            ],
            [
              39.463547,
              56.297142
            ],
            [
              39.458706,
              56.295485
            ],
            [
              39.454396,
              56.293959
            ],
            [
              39.447779,
              56.291529
            ],
            [
              39.437398,
              56.294441
            ],
            [
              39.430496,
              56.300298
            ],
            [
              39.429469038048325,
              56.299880220134014
            ],
            [
              39.42943,
              56.299907
            ],
            [
              39.429368537237586,
              56.29983933525491
            ],
            [
              39.424095,
              56.297694
            ],
            [
              39.425432,
              56.294164
            ],
            [
              39.424291,
              56.289541
            ],
            [
              39.422397,
              56.286253
            ],
            [
              39.411147,
              56.286749
            ],
            [
              39.41059060158463,
              56.28895104530334
            ],
            [
              39.411298,
              56.29219
            ],
            [
              39.40867,
              56.294858
            ],
            [
              39.405904144579715,
              56.29706710648255
            ],
            [
              39.405581,
              56.297561
            ],
            [
              39.40340408964457,
              56.29914177182732
            ],
            [
              39.403046,
              56.299777
            ],
            [
              39.403632,
              56.307387
            ],
            [
              39.411562,
              56.311038
            ],
            [
              39.414773,
              56.31637
            ],
            [
              39.41470116343411,
              56.31686781479872
            ],
            [
              39.416224,
              56.317519
            ],
            [
              39.419119,
              56.325372
            ],
            [
              39.42191,
              56.335986
            ],
            [
              39.429489,
              56.340142
            ],
            [
              39.437044,
              56.344284
            ],
            [
              39.44218,
              56.346998
            ],
            [
              39.450031,
              56.35091
            ],
            [
              39.457883,
              56.354823
            ],
            [
              39.456277,
              56.358045
            ],
            [
              39.45270303303567,
              56.356263933681674
            ],
            [
              39.452703,
              56.356264
            ],
            [
              39.453884,
              56.366547
            ],
            [
              39.464841,
              56.362313
            ],
            [
              39.466428,
              56.359081
            ],
            [
              39.470349,
              56.361006
            ],
            [
              39.481725,
              56.362609
            ],
            [
              39.483788,
              56.362269
            ],
            [
              39.486678,
              56.366084
            ],
            [
              39.48667787551362,
              56.3660842573089
            ],
            [
              39.486946,
              56.366214
            ],
            [
              39.497778,
              56.371562
            ],
            [
              39.508597,
              56.376904
            ],
            [
              39.521013,
              56.382916
            ],
            [
              39.533426,
              56.388928
            ],
            [
              39.542551,
              56.391369
            ],
            [
              39.5464045100216,
              56.39041239300076
            ],
            [
              39.548756,
              56.383438
            ],
            [
              39.54278,
              56.377552
            ],
            [
              39.538282,
              56.377364
            ],
            [
              39.537926,
              56.377277
            ],
            [
              39.53795116026898,
              56.37717370114919
            ],
            [
              39.535024,
              56.37549
            ],
            [
              39.534859,
              56.373184
            ],
            [
              39.535114,
              56.371905
            ],
            [
              39.538644,
              56.372611
            ],
            [
              39.545059,
              56.374035
            ],
            [
              39.548941,
              56.374797
            ],
            [
              39.5546,
              56.384096
            ],
            [
              39.566172,
              56.387064
            ],
            [
              39.572833,
              56.383775
            ],
            [
              39.578132,
              56.374945
            ],
            [
              39.581732,
              56.374945
            ],
            [
              39.58224,
              56.374946
            ],
            [
              39.58335505666516,
              56.37720255039563
            ],
            [
              39.583386,
              56.377188
            ],
            [
              39.592234,
              56.382705
            ],
            [
              39.600954,
              56.380765
            ],
            [
              39.603974,
              56.379663
            ],
            [
              39.6063169024483,
              56.385461110442506
            ],
            [
              39.614046,
              56.383232
            ],
            [
              39.61663,
              56.380654
            ],
            [
              39.621681,
              56.380355
            ],
            [
              39.630792,
              56.381857
            ],
            [
              39.636652,
              56.382763
            ],
            [
              39.630437,
              56.389189
            ],
            [
              39.62886,
              56.392425
            ],
            [
              39.620339,
              56.392747
            ],
            [
              39.609521,
              56.389742
            ],
            [
              39.605729,
              56.38959
            ],
            [
              39.601042,
              56.389489
            ],
            [
              39.600604835703656,
              56.38945860527379
            ],
            [
              39.599904,
              56.389449
            ],
            [
              39.591396,
              56.390385
            ],
            [
              39.585763,
              56.393331
            ],
            [
              39.588976,
              56.403869
            ],
            [
              39.597308,
              56.405659
            ],
            [
              39.602193,
              56.404706
            ],
            [
              39.603686,
              56.40484
            ],
            [
              39.606103,
              56.405778
            ],
            [
              39.604801,
              56.409134
            ],
            [
              39.598132,
              56.409164
            ],
            [
              39.591859,
              56.41042
            ],
            [
              39.591736001934464,
              56.42172282223364
            ],
            [
              39.596846,
              56.426881
            ],
            [
              39.59683978330394,
              56.426887160777405
            ],
            [
              39.597146,
              56.427197
            ],
            [
              39.59799618812182,
              56.42864935502437
            ],
            [
              39.59947,
              56.43028
            ],
            [
              39.59937762277501,
              56.43100922516123
            ],
            [
              39.602137,
              56.435723
            ],
            [
              39.605095,
              56.442298
            ],
            [
              39.607971,
              56.448711
            ],
            [
              39.615585,
              56.455756
            ],
            [
              39.617483,
              56.456877
            ],
            [
              39.616571812453756,
              56.45841969874023
            ],
            [
              39.616585,
              56.458437
            ],
            [
              39.624838,
              56.463242
            ],
            [
              39.630225,
              56.464407
            ],
            [
              39.636073,
              56.467857
            ],
            [
              39.64192,
              56.471307
            ],
            [
              39.647168,
              56.474403
            ],
            [
              39.650478,
              56.47727
            ],
            [
              39.662005,
              56.476637
            ],
            [
              39.66259,
              56.47657
            ],
            [
              39.667045,
              56.478917
            ],
            [
              39.668261,
              56.481205
            ],
            [
              39.66844,
              56.481609
            ],
            [
              39.66796,
              56.484668
            ],
            [
              39.667761,
              56.485577
            ],
            [
              39.665616,
              56.48877
            ],
            [
              39.665441,
              56.488857
            ],
            [
              39.663269,
              56.489606
            ],
            [
              39.66156,
              56.489572
            ],
            [
              39.656584,
              56.482007
            ],
            [
              39.646298,
              56.479027
            ],
            [
              39.640091,
              56.474408
            ],
            [
              39.634243,
              56.470958
            ],
            [
              39.628039,
              56.478606
            ],
            [
              39.631209,
              56.484121
            ],
            [
              39.632797,
              56.485258
            ],
            [
              39.633859,
              56.486188
            ],
            [
              39.631486,
              56.488896
            ],
            [
              39.627274,
              56.488388
            ],
            [
              39.625304,
              56.48518
            ],
            [
              39.612616,
              56.484617
            ],
            [
              39.611199,
              56.484883
            ],
            [
              39.607239,
              56.481873
            ],
            [
              39.60768,
              56.479388
            ],
            [
              39.611244,
              56.478885
            ],
            [
              39.618781,
              56.480239
            ],
            [
              39.624256,
              56.473601
            ],
            [
              39.621861,
              56.468009
            ],
            [
              39.612006,
              56.461348
            ],
            [
              39.60782,
              56.454481
            ],
            [
              39.604853,
              56.450512
            ],
            [
              39.601812,
              56.443775
            ],
            [
              39.593489,
              56.437571
            ],
            [
              39.585592,
              56.438111
            ],
            [
              39.584177,
              56.4348
            ],
            [
              39.589177742307335,
              56.42425654331363
            ],
            [
              39.589091,
              56.424169
            ],
            [
              39.583894,
              56.418922
            ],
            [
              39.579021,
              56.415169
            ],
            [
              39.574864,
              56.413042
            ],
            [
              39.571856191759565,
              56.41157760613849
            ],
            [
              39.571856,
              56.411578
            ],
            [
              39.56497,
              56.408225
            ],
            [
              39.557893,
              56.409725
            ],
            [
              39.554618,
              56.408229
            ],
            [
              39.549966,
              56.401662
            ],
            [
              39.538861,
              56.40229
            ],
            [
              39.536657,
              56.412322
            ],
            [
              39.536654,
              56.412385
            ],
            [
              39.536286949563404,
              56.41274431219829
            ],
            [
              39.5427,
              56.421182
            ],
            [
              39.548279,
              56.422987
            ],
            [
              39.553847,
              56.4263
            ],
            [
              39.562608,
              56.428513
            ],
            [
              39.564652,
              56.432447
            ],
            [
              39.564436,
              56.432626
            ],
            [
              39.55894582787943,
              56.43251040879961
            ],
            [
              39.558932,
              56.432627
            ],
            [
              39.557739,
              56.432485
            ],
            [
              39.55207,
              56.429431
            ],
            [
              39.546051,
              56.425814
            ],
            [
              39.541666,
              56.42463
            ],
            [
              39.536683,
              56.423136
            ],
            [
              39.529092,
              56.428375
            ],
            [
              39.52463,
              56.428925
            ],
            [
              39.517035,
              56.432569
            ],
            [
              39.508389,
              56.436637
            ],
            [
              39.50838889012649,
              56.43663676633949
            ],
            [
              39.507737981382576,
              56.43694303713504
            ],
            [
              39.502702,
              56.446988
            ],
            [
              39.506203,
              56.448866
            ],
            [
              39.509705,
              56.450786
            ],
            [
              39.519053,
              56.452361
            ],
            [
              39.524029,
              56.45084
            ],
            [
              39.528611,
              56.449386
            ],
            [
              39.532787,
              56.447888
            ],
            [
              39.536236,
              56.446688
            ],
            [
              39.540784,
              56.44922
            ],
            [
              39.537334,
              56.450116
            ],
            [
              39.533944,
              56.451297
            ],
            [
              39.529827,
              56.452775
            ],
            [
              39.52696519723366,
              56.45368375851058
            ],
            [
              39.526969,
              56.453696
            ],
            [
              39.524962,
              56.454319
            ],
            [
              39.522955,
              56.454943
            ],
            [
              39.520093,
              56.455807
            ],
            [
              39.516624,
              56.456778
            ],
            [
              39.511629,
              56.455925
            ],
            [
              39.507742,
              56.453804
            ],
            [
              39.497752,
              56.460387
            ],
            [
              39.49612,
              56.464577
            ],
            [
              39.493952,
              56.469624
            ],
            [
              39.49271,
              56.474719
            ],
            [
              39.492502,
              56.479094
            ],
            [
              39.492501,
              56.482674
            ],
            [
              39.492781,
              56.487266
            ],
            [
              39.496316,
              56.494708
            ],
            [
              39.503576,
              56.497112
            ],
            [
              39.50444,
              56.49719
            ],
            [
              39.508288,
              56.497296
            ],
            [
              39.508189,
              56.500895
            ],
            [
              39.499053,
              56.50388
            ],
            [
              39.496731,
              56.504534
            ],
            [
              39.485232,
              56.502866
            ],
            [
              39.484619,
              56.503375
            ],
            [
              39.48314,
              56.503123
            ],
            [
              39.482319,
              56.500605
            ],
            [
              39.482764,
              56.499543
            ],
            [
              39.48437,
              56.499162
            ],
            [
              39.48904,
              56.488579
            ],
            [
              39.488926,
              56.484943
            ],
            [
              39.488857,
              56.480801
            ],
            [
              39.489005,
              56.476879
            ],
            [
              39.489493,
              56.473101
            ],
            [
              39.490736,
              56.468006
            ],
            [
              39.49157244191444,
              56.465400516367296
            ],
            [
              39.491524,
              56.465383
            ],
            [
              39.492006,
              56.46405
            ],
            [
              39.494367,
              56.459162
            ],
            [
              39.484424,
              56.452774
            ],
            [
              39.480805,
              56.453879
            ],
            [
              39.477182,
              56.452943
            ],
            [
              39.471174,
              56.452238
            ],
            [
              39.467689,
              56.451607
            ],
            [
              39.463563,
              56.451144
            ],
            [
              39.457935,
              56.450612
            ],
            [
              39.452528,
              56.450326
            ],
            [
              39.449211,
              56.446536
            ],
            [
              39.452745,
              56.446732
            ],
            [
              39.458196,
              56.447022
            ],
            [
              39.463782,
              56.447551
            ],
            [
              39.464084352257856,
              56.44758495256158
            ],
            [
              39.464085,
              56.447578
            ],
            [
              39.467069,
              56.447856
            ],
            [
              39.468288,
              56.448057
            ],
            [
              39.474746,
              56.448992
            ],
            [
              39.478425,
              56.449565
            ],
            [
              39.489015,
              56.445952
            ],
            [
              39.495335,
              56.440229
            ],
            [
              39.499302,
              56.439313
            ],
            [
              39.50234022105481,
              56.43683638550262
            ],
            [
              39.501827,
              56.435746
            ],
            [
              39.50620404370573,
              56.433686779525985
            ],
            [
              39.506205,
              56.433686
            ],
            [
              39.50623895847537,
              56.43367035355144
            ],
            [
              39.50685722726622,
              56.43337948331158
            ],
            [
              39.506857,
              56.433379
            ],
            [
              39.511179,
              56.431346
            ],
            [
              39.51533144499142,
              56.42939224725595
            ],
            [
              39.522895,
              56.422877
            ],
            [
              39.52366367879751,
              56.42224501493593
            ],
            [
              39.523623,
              56.422106
            ],
            [
              39.52375,
              56.421972
            ],
            [
              39.524094754576076,
              56.421890597129156
            ],
            [
              39.52560837778341,
              56.420646140715434
            ],
            [
              39.524759,
              56.409867
            ],
            [
              39.520145,
              56.408311
            ],
            [
              39.515802,
              56.407597
            ],
            [
              39.511899,
              56.40615
            ],
            [
              39.509566,
              56.405102
            ],
            [
              39.508071,
              56.404563
            ],
            [
              39.509291,
              56.401177
            ],
            [
              39.510786,
              56.401715
            ],
            [
              39.516555,
              56.404076
            ],
            [
              39.521133,
              56.404516
            ],
            [
              39.525615562064644,
              56.40634693273428
            ],
            [
              39.52590929037996,
              56.40635076330699
            ],
            [
              39.535636,
              56.400692
            ],
            [
              39.531863,
              56.39217
            ],
            [
              39.531167387385906,
              56.3918332000444
            ],
            [
              39.531167,
              56.391834
            ],
            [
              39.5230155396731,
              56.38788625904294
            ],
            [
              39.519444,
              56.386157
            ],
            [
              39.507028,
              56.380144
            ],
            [
              39.496184,
              56.37479
            ],
            [
              39.48508031392427,
              56.369328558947664
            ],
            [
              39.474575,
              56.370588
            ],
            [
              39.473756,
              56.370391
            ],
            [
              39.473226799768334,
              56.369357799547686
            ],
            [
              39.471432,
              56.37274
            ],
            [
              39.467575,
              56.376046
            ],
            [
              39.460472,
              56.379787
            ],
            [
              39.453385,
              56.38352
            ],
            [
              39.44958,
              56.385572
            ],
            [
              39.443592,
              56.388253
            ],
            [
              39.439002,
              56.389104
            ],
            [
              39.434412,
              56.389954
            ],
            [
              39.43404963296652,
              56.387999096141
            ],
            [
              39.433344,
              56.387971
            ],
            [
              39.42659512991045,
              56.3848657222557
            ],
            [
              39.426477,
              56.384838
            ],
            [
              39.427298,
              56.381333
            ],
            [
              39.427869,
              56.381467
            ],
            [
              39.432683,
              56.382499
            ],
            [
              39.440198,
              56.384346
            ],
            [
              39.447871,
              56.382404
            ],
            [
              39.452921580059275,
              56.37196886765025
            ],
            [
              39.449492,
              56.369168
            ],
            [
              39.448752,
              56.367747
            ],
            [
              39.443901,
              56.361939
            ],
            [
              39.436156,
              56.360927
            ],
            [
              39.432561,
              56.36111
            ],
            [
              39.427607,
              56.353888
            ],
            [
              39.423458,
              56.351635
            ],
            [
              39.416816,
              56.346666
            ],
            [
              39.411254,
              56.345401
            ],
            [
              39.402645,
              56.350439
            ],
            [
              39.39679,
              56.353525
            ],
            [
              39.391886,
              56.356108
            ],
            [
              39.383122,
              56.363559
            ],
            [
              39.38931,
              56.37063
            ],
            [
              39.393123,
              56.373513
            ],
            [
              39.396944,
              56.376402
            ],
            [
              39.400919,
              56.379442
            ],
            [
              39.404886,
              56.382477
            ],
            [
              39.408143,
              56.386763
            ],
            [
              39.415542,
              56.388773
            ],
            [
              39.417023,
              56.389955
            ],
            [
              39.416794,
              56.392148
            ],
            [
              39.416906,
              56.393553
            ],
            [
              39.415128,
              56.393782
            ],
            [
              39.412787,
              56.391047
            ],
            [
              39.402713,
              56.385346
            ],
            [
              39.398732,
              56.382301
            ],
            [
              39.394757,
              56.379262
            ],
            [
              39.390952,
              56.376385
            ],
            [
              39.387139,
              56.373502
            ],
            [
              39.387099881523945,
              56.37348631638877
            ],
            [
              39.387078,
              56.373539
            ],
            [
              39.378234,
              56.372061
            ],
            [
              39.370672,
              56.373614
            ],
            [
              39.368247,
              56.370953
            ],
            [
              39.367772595041046,
              56.36889086394262
            ],
            [
              39.367018,
              56.368802
            ],
            [
              39.368391,
              56.364535
            ],
            [
              39.36849729561585,
              56.364549848588126
            ],
            [
              39.368629,
              56.363861
            ],
            [
              39.369884,
              56.361498
            ],
            [
              39.370402,
              56.360253
            ],
            [
              39.373726,
              56.361635
            ],
            [
              39.382639,
              56.356943
            ],
            [
              39.38712190786203,
              56.354562193295614
            ],
            [
              39.386582,
              56.352423
            ],
            [
              39.387012,
              56.348849
            ],
            [
              39.386661,
              56.340992
            ],
            [
              39.377152,
              56.333809
            ],
            [
              39.37619681798079,
              56.3344247471802
            ],
            [
              39.376068,
              56.335773
            ],
            [
              39.374923,
              56.339186
            ],
            [
              39.370565,
              56.340048
            ],
            [
              39.36787978457264,
              56.34007051752979
            ],
            [
              39.366639,
              56.341022
            ],
            [
              39.364318,
              56.350413
            ],
            [
              39.35711549503869,
              56.35554343706764
            ],
            [
              39.355437,
              56.361093
            ],
            [
              39.352436,
              56.365545
            ],
            [
              39.348097,
              56.371331
            ],
            [
              39.347758,
              56.386929
            ],
            [
              39.354788,
              56.390038
            ],
            [
              39.362259,
              56.392435
            ],
            [
              39.366202,
              56.394534
            ],
            [
              39.364339,
              56.397614
            ],
            [
              39.35644,
              56.402892
            ],
            [
              39.355655,
              56.406702
            ],
            [
              39.355189,
              56.408961
            ],
            [
              39.3541652219955,
              56.40874962439385
            ],
            [
              39.353385,
              56.409822
            ],
            [
              39.357426,
              56.418854
            ],
            [
              39.363429,
              56.426065
            ],
            [
              39.370405,
              56.425305
            ],
            [
              39.374111,
              56.425131
            ],
            [
              39.37422194100136,
              56.42749161444312
            ],
            [
              39.380453,
              56.428336
            ],
            [
              39.382348,
              56.427837
            ],
            [
              39.384162,
              56.427401
            ],
            [
              39.385005,
              56.430901
            ],
            [
              39.379513,
              56.432306
            ],
            [
              39.374436,
              56.432162
            ],
            [
              39.37128229459452,
              56.431114731789876
            ],
            [
              39.370132,
              56.431108
            ],
            [
              39.360074,
              56.432676
            ],
            [
              39.357656,
              56.435343
            ],
            [
              39.35452285182125,
              56.43492041227303
            ],
            [
              39.352139,
              56.439053
            ],
            [
              39.351481,
              56.442166
            ],
            [
              39.35147567813294,
              56.44216487578959
            ],
            [
              39.351324,
              56.442912
            ],
            [
              39.352683,
              56.451478
            ],
            [
              39.351088,
              56.454705
            ],
            [
              39.346125,
              56.455154
            ],
            [
              39.34321557445828,
              56.46053422836078
            ],
            [
              39.346157,
              56.466583
            ],
            [
              39.346152208179,
              56.46664816150528
            ],
            [
              39.347308,
              56.466898
            ],
            [
              39.352923,
              56.46832
            ],
            [
              39.356959,
              56.468975
            ],
            [
              39.36312,
              56.469928
            ],
            [
              39.367073,
              56.470696
            ],
            [
              39.37238199287701,
              56.47150823631245
            ],
            [
              39.372489,
              56.471524
            ],
            [
              39.372488912420636,
              56.47152459420516
            ],
            [
              39.37257,
              56.471537
            ],
            [
              39.382582,
              56.472525
            ],
            [
              39.387691,
              56.472681
            ],
            [
              39.391558,
              56.473589
            ],
            [
              39.402328,
              56.475909
            ],
            [
              39.40698,
              56.476448
            ],
            [
              39.409506,
              56.476769
            ],
            [
              39.411229,
              56.477026
            ],
            [
              39.41122857155209,
              56.47702886705747
            ],
            [
              39.41131,
              56.47704
            ],
            [
              39.417411,
              56.479004
            ],
            [
              39.425773,
              56.483756
            ],
            [
              39.424815,
              56.487227
            ],
            [
              39.424096,
              56.487028
            ],
            [
              39.419163,
              56.484727
            ],
            [
              39.41077885823254,
              56.484466815537395
            ],
            [
              39.406043,
              56.488725
            ],
            [
              39.403326,
              56.486146
            ],
            [
              39.395034,
              56.478575
            ],
            [
              39.391353,
              56.477481
            ],
            [
              39.383136,
              56.476082
            ],
            [
              39.378605,
              56.476303
            ],
            [
              39.37195718075999,
              56.47508499542791
            ],
            [
              39.3669,
              56.47434
            ],
            [
              39.366294,
              56.474211
            ],
            [
              39.362478,
              56.473471
            ],
            [
              39.356408,
              56.472532
            ],
            [
              39.352394,
              56.471881
            ],
            [
              39.346429,
              56.470389
            ],
            [
              39.34523572205347,
              56.470124708447365
            ],
            [
              39.344355,
              56.47006
            ],
            [
              39.342668,
              56.469556
            ],
            [
              39.33951938668099,
              56.46872575553519
            ],
            [
              39.339492,
              56.468811
            ],
            [
              39.33787300170579,
              56.468291160344606
            ],
            [
              39.334165,
              56.467121
            ],
            [
              39.329789,
              56.466079
            ],
            [
              39.319562,
              56.469099
            ],
            [
              39.314892,
              56.470824
            ],
            [
              39.311313,
              56.47266
            ],
            [
              39.306724,
              56.480093
            ],
            [
              39.305344,
              56.483418
            ],
            [
              39.301829,
              56.484047
            ],
            [
              39.30077245255025,
              56.48387388215645
            ],
            [
              39.295726,
              56.488836
            ],
            [
              39.298856,
              56.499119
            ],
            [
              39.303484,
              56.500789
            ],
            [
              39.305934,
              56.50176
            ],
            [
              39.306315,
              56.501957
            ],
            [
              39.304662,
              56.505155
            ],
            [
              39.30217,
              56.50414
            ],
            [
              39.297774,
              56.502552
            ],
            [
              39.294365,
              56.501742
            ],
            [
              39.291976,
              56.498066
            ],
            [
              39.290982,
              56.492095
            ],
            [
              39.291823,
              56.488205
            ],
            [
              39.294462,
              56.484973
            ],
            [
              39.29782,
              56.481281
            ],
            [
              39.303681,
              56.474878
            ],
            [
              39.306139,
              56.47192
            ],
            [
              39.303573,
              56.461789
            ],
            [
              39.298321,
              56.461624
            ],
            [
              39.293855,
              56.461294
            ],
            [
              39.289603,
              56.460276
            ],
            [
              39.286022,
              56.459666
            ],
            [
              39.281733,
              56.458968
            ],
            [
              39.277699,
              56.458074
            ],
            [
              39.272522,
              56.456938
            ],
            [
              39.267882,
              56.455707
            ],
            [
              39.263829,
              56.454314
            ],
            [
              39.259459,
              56.452773
            ],
            [
              39.255012,
              56.451141
            ],
            [
              39.247269,
              56.448886
            ],
            [
              39.242764,
              56.448586
            ],
            [
              39.24267300036972,
              56.44858696831009
            ],
            [
              39.242673,
              56.448587
            ],
            [
              39.242671616073714,
              56.44858698304012
            ],
            [
              39.238535,
              56.448631
            ],
            [
              39.234033,
              56.448475
            ],
            [
              39.229869,
              56.447219
            ],
            [
              39.22893699257153,
              56.446954201153765
            ],
            [
              39.226703,
              56.447913
            ],
            [
              39.225472,
              56.448097
            ],
            [
              39.221508382518294,
              56.44532972253609
            ],
            [
              39.213667,
              56.445288
            ],
            [
              39.20792,
              56.439778
            ],
            [
              39.204878,
              56.437664
            ],
            [
              39.20552,
              56.433751
            ],
            [
              39.201764,
              56.422889
            ],
            [
              39.195674,
              56.419278
            ],
            [
              39.18593802606538,
              56.421716496510975
            ],
            [
              39.18438,
              56.423548
            ],
            [
              39.184046,
              56.425477
            ],
            [
              39.18394212020743,
              56.425919423289194
            ],
            [
              39.183483,
              56.43772
            ],
            [
              39.185395,
              56.44077
            ],
            [
              39.17923,
              56.444316
            ],
            [
              39.173952,
              56.44939
            ],
            [
              39.17074034344354,
              56.4506505477952
            ],
            [
              39.17075,
              56.450679
            ],
            [
              39.170143,
              56.450885
            ],
            [
              39.162034,
              56.454661
            ],
            [
              39.158636812848926,
              56.45593554277297
            ],
            [
              39.15736944700914,
              56.467460253297574
            ],
            [
              39.15876,
              56.469775
            ],
            [
              39.167618,
              56.471571
            ],
            [
              39.175204,
              56.472674
            ],
            [
              39.181136,
              56.472812
            ],
            [
              39.184926,
              56.473933
            ],
            [
              39.189748,
              56.475052
            ],
            [
              39.199651,
              56.472918
            ],
            [
              39.205105,
              56.47256
            ],
            [
              39.211725,
              56.470753
            ],
            [
              39.215824,
              56.470181
            ],
            [
              39.218661,
              56.470039
            ],
            [
              39.21866644521274,
              56.470091843649946
            ],
            [
              39.22067,
              56.470029
            ],
            [
              39.224469,
              56.469948
            ],
            [
              39.229247,
              56.469191
            ],
            [
              39.230563,
              56.468796
            ],
            [
              39.231597,
              56.472244
            ],
            [
              39.23028,
              56.472639
            ],
            [
              39.228493,
              56.473076
            ],
            [
              39.224019,
              56.47352
            ],
            [
              39.220365,
              56.473616
            ],
            [
              39.21759079428448,
              56.47376809768576
            ],
            [
              39.217514,
              56.473776
            ],
            [
              39.216407,
              56.473833
            ],
            [
              39.212317,
              56.474304
            ],
            [
              39.208516,
              56.473477
            ],
            [
              39.19942,
              56.477055
            ],
            [
              39.194778,
              56.478655
            ],
            [
              39.192224,
              56.489732
            ],
            [
              39.199676,
              56.49844
            ],
            [
              39.199543,
              56.502037
            ],
            [
              39.192867,
              56.51031
            ],
            [
              39.190334,
              56.514131
            ],
            [
              39.190234689021196,
              56.51417794569432
            ],
            [
              39.19021,
              56.514333
            ],
            [
              39.202153,
              56.522396
            ],
            [
              39.212716,
              56.530191
            ],
            [
              39.219152,
              56.529571
            ],
            [
              39.22460471879476,
              56.522287725609864
            ],
            [
              39.224881,
              56.5184
            ],
            [
              39.229063,
              56.516431
            ],
            [
              39.233743,
              56.514975
            ],
            [
              39.235206,
              56.515291
            ],
            [
              39.238078,
              56.516173
            ],
            [
              39.23812099371784,
              56.51618618853253
            ],
            [
              39.238142,
              56.516192
            ],
            [
              39.238142808463635,
              56.51619288031214
            ],
            [
              39.24095,
              56.517054
            ],
            [
              39.24044508361634,
              56.518699759652016
            ],
            [
              39.24055,
              56.518814
            ],
            [
              39.24039907377368,
              56.51884972733995
            ],
            [
              39.239894,
              56.520496
            ],
            [
              39.23708663513958,
              56.51963384964941
            ],
            [
              39.237086,
              56.519634
            ],
            [
              39.229174827930976,
              56.52422834802719
            ],
            [
              39.232864,
              56.530792
            ],
            [
              39.232482864864046,
              56.53410796494162
            ],
            [
              39.236385,
              56.536248
            ],
            [
              39.241943,
              56.53561
            ],
            [
              39.247711,
              56.537437
            ],
            [
              39.253713,
              56.53636
            ],
            [
              39.257948,
              56.535374
            ],
            [
              39.262198,
              56.534141
            ],
            [
              39.265888,
              56.534669
            ],
            [
              39.275042,
              56.529988
            ],
            [
              39.280119,
              56.530707
            ],
            [
              39.283881,
              56.533291
            ],
            [
              39.288438,
              56.535259
            ],
            [
              39.296958,
              56.541607
            ],
            [
              39.303625,
              56.541352
            ],
            [
              39.308041,
              56.541402
            ],
            [
              39.318874,
              56.543182
            ],
            [
              39.325621,
              56.542495
            ],
            [
              39.329619,
              56.546026
            ],
            [
              39.3301692796296,
              56.548035904999296
            ],
            [
              39.332879,
              56.551337
            ],
            [
              39.33566,
              56.554392
            ],
            [
              39.339157,
              56.557836
            ],
            [
              39.341759,
              56.560371
            ],
            [
              39.344362,
              56.562905
            ],
            [
              39.341851,
              56.565484
            ],
            [
              39.339248,
              56.56295
            ],
            [
              39.336645,
              56.560415
            ],
            [
              39.333134,
              56.556957
            ],
            [
              39.330035,
              56.553544
            ],
            [
              39.32860283799077,
              56.55113205158849
            ],
            [
              39.328281,
              56.55136
            ],
            [
              39.327514,
              56.551441
            ],
            [
              39.317271,
              56.547183
            ],
            [
              39.313663,
              56.54724
            ],
            [
              39.310169,
              56.546909
            ],
            [
              39.299273,
              56.545016
            ],
            [
              39.294025068113015,
              56.54486201934489
            ],
            [
              39.294025,
              56.544868
            ],
            [
              39.29238,
              56.544849
            ],
            [
              39.289739,
              56.544154
            ],
            [
              39.284585,
              56.537821
            ],
            [
              39.274485,
              56.537284
            ],
            [
              39.270416,
              56.538368
            ],
            [
              39.265843,
              56.538268
            ],
            [
              39.258014,
              56.539382
            ],
            [
              39.249892,
              56.541519
            ],
            [
              39.245969,
              56.540587
            ],
            [
              39.235521,
              56.540138
            ],
            [
              39.23067603719086,
              56.539764156572936
            ],
            [
              39.230475,
              56.540326
            ],
            [
              39.22705448117283,
              56.54160802281651
            ],
            [
              39.225855,
              56.548004
            ],
            [
              39.226983,
              56.556729
            ],
            [
              39.228131,
              56.559523
            ],
            [
              39.228100779005295,
              56.55953541138419
            ],
            [
              39.228392,
              56.560194
            ],
            [
              39.229179,
              56.563752
            ],
            [
              39.235896,
              56.569818
            ],
            [
              39.241508,
              56.564584
            ],
            [
              39.243436,
              56.562485
            ],
            [
              39.246432,
              56.564482
            ],
            [
              39.245558,
              56.565793
            ],
            [
              39.24295060446587,
              56.57394016413992
            ],
            [
              39.246169,
              56.573907
            ],
            [
              39.252457,
              56.574432
            ],
            [
              39.256448,
              56.573732
            ],
            [
              39.264214,
              56.571236
            ],
            [
              39.267974,
              56.5697
            ],
            [
              39.272259,
              56.568386
            ],
            [
              39.275614,
              56.56665
            ],
            [
              39.278858,
              56.564839
            ],
            [
              39.279716,
              56.564377
            ],
            [
              39.280377,
              56.564039
            ],
            [
              39.281817,
              56.564503
            ],
            [
              39.28183606642362,
              56.564764210003716
            ],
            [
              39.283039,
              56.564734
            ],
            [
              39.286397,
              56.565935
            ],
            [
              39.295876,
              56.572501
            ],
            [
              39.296693,
              56.572589
            ],
            [
              39.296308,
              56.576169
            ],
            [
              39.292839,
              56.583455
            ],
            [
              39.288777,
              56.584398
            ],
            [
              39.284513,
              56.585732
            ],
            [
              39.279394,
              56.586419
            ],
            [
              39.275355,
              56.587848
            ],
            [
              39.270341,
              56.588771
            ],
            [
              39.266639,
              56.589659
            ],
            [
              39.268574,
              56.601313
            ],
            [
              39.276466,
              56.603958
            ],
            [
              39.280522,
              56.603382
            ],
            [
              39.288329,
              56.602878
            ],
            [
              39.293846,
              56.603055
            ],
            [
              39.296919,
              56.603276
            ],
            [
              39.296662,
              56.606866
            ],
            [
              39.293588,
              56.606646
            ],
            [
              39.288214,
              56.606476
            ],
            [
              39.279424,
              56.607348
            ],
            [
              39.274813,
              56.607617
            ],
            [
              39.264099,
              56.608579
            ],
            [
              39.252879,
              56.609343
            ],
            [
              39.246125,
              56.611863
            ],
            [
              39.245077,
              56.61211
            ],
            [
              39.244251,
              56.608606
            ],
            [
              39.245506,
              56.607842
            ],
            [
              39.249564,
              56.606905
            ],
            [
              39.256382,
              56.608275
            ],
            [
              39.260461,
              56.599615
            ],
            [
              39.255488,
              56.594775
            ],
            [
              39.249248,
              56.590376
            ],
            [
              39.245363248193,
              56.58775763196417
            ],
            [
              39.245363,
              56.587758
            ],
            [
              39.235559,
              56.583424
            ],
            [
              39.226591,
              56.575832
            ],
            [
              39.22267,
              56.575095
            ],
            [
              39.225251,
              56.568592
            ],
            [
              39.225249,
              56.564995
            ],
            [
              39.224923,
              56.561155
            ],
            [
              39.22170082008935,
              56.55925587628056
            ],
            [
              39.217152219646835,
              56.55685603997282
            ],
            [
              39.212307,
              56.56403
            ],
            [
              39.211847,
              56.575107
            ],
            [
              39.213133,
              56.578469
            ],
            [
              39.210964,
              56.582765
            ],
            [
              39.207379,
              56.583093
            ],
            [
              39.20122083632124,
              56.58199557649985
            ],
            [
              39.195038,
              56.582572
            ],
            [
              39.19123,
              56.58342
            ],
            [
              39.184051,
              56.585097
            ],
            [
              39.176853,
              56.586778
            ],
            [
              39.17670755983546,
              56.58681737279597
            ],
            [
              39.176708,
              56.586819
            ],
            [
              39.173618,
              56.587654
            ],
            [
              39.170529,
              56.58849
            ],
            [
              39.167096,
              56.589443
            ],
            [
              39.163651,
              56.590399
            ],
            [
              39.159798,
              56.591237
            ],
            [
              39.15492,
              56.592618
            ],
            [
              39.147466,
              56.599492
            ],
            [
              39.145094,
              56.60365
            ],
            [
              39.142186,
              56.605508
            ],
            [
              39.14212302191345,
              56.60554821674922
            ],
            [
              39.14325073023895,
              56.61670254945197
            ],
            [
              39.146131,
              56.617627
            ],
            [
              39.153406,
              56.620485
            ],
            [
              39.157877,
              56.622454
            ],
            [
              39.163599,
              56.624673
            ],
            [
              39.171342,
              56.624817
            ],
            [
              39.17499,
              56.623678
            ],
            [
              39.183525,
              56.618628
            ],
            [
              39.191335,
              56.616562
            ],
            [
              39.200209,
              56.611207
            ],
            [
              39.203963,
              56.610179
            ],
            [
              39.207848,
              56.610031
            ],
            [
              39.212978,
              56.609172
            ],
            [
              39.214139,
              56.61258
            ],
            [
              39.212884,
              56.613007
            ],
            [
              39.207925,
              56.61363
            ],
            [
              39.202193,
              56.614211
            ],
            [
              39.20041435182972,
              56.61558071230278
            ],
            [
              39.199659,
              56.616228
            ],
            [
              39.198283,
              56.617408
            ],
            [
              39.195808,
              56.619128
            ],
            [
              39.191686,
              56.620145
            ],
            [
              39.193584,
              56.632096
            ],
            [
              39.197755,
              56.633223
            ],
            [
              39.20132,
              56.634911
            ],
            [
              39.211614,
              56.638111
            ],
            [
              39.214877,
              56.638462
            ],
            [
              39.214926,
              56.639404
            ],
            [
              39.214491,
              56.642041
            ],
            [
              39.213973,
              56.642876
            ],
            [
              39.210101,
              56.64199
            ],
            [
              39.204566,
              56.641016
            ],
            [
              39.201022,
              56.639745
            ],
            [
              39.192646,
              56.635572
            ],
            [
              39.186965,
              56.632471
            ],
            [
              39.183121,
              56.630702
            ],
            [
              39.174089,
              56.636368
            ],
            [
              39.173538,
              56.639925
            ],
            [
              39.166385,
              56.640166
            ],
            [
              39.157258,
              56.645846
            ],
            [
              39.15598,
              56.649212
            ],
            [
              39.153261,
              56.64818
            ],
            [
              39.150137,
              56.646841
            ],
            [
              39.146875,
              56.644975
            ],
            [
              39.138757,
              56.637994
            ],
            [
              39.134954,
              56.638427
            ],
            [
              39.134079,
              56.638398
            ],
            [
              39.133229,
              56.638302
            ],
            [
              39.13322941632224,
              56.638298350037665
            ],
            [
              39.13322,
              56.6383
            ],
            [
              39.128503,
              56.637676
            ],
            [
              39.122417,
              56.636801
            ],
            [
              39.112456,
              56.636721
            ],
            [
              39.109147,
              56.63797
            ],
            [
              39.105217,
              56.639759
            ],
            [
              39.100003,
              56.639103
            ],
            [
              39.094978,
              56.646323
            ],
            [
              39.091854,
              56.657444
            ],
            [
              39.095478,
              56.659101
            ],
            [
              39.098391,
              56.661538
            ],
            [
              39.107692,
              56.667186
            ],
            [
              39.112557,
              56.66789
            ],
            [
              39.116072,
              56.668443
            ],
            [
              39.124514,
              56.6678
            ],
            [
              39.131412,
              56.667751
            ],
            [
              39.137061,
              56.663615
            ],
            [
              39.141799,
              56.665768
            ],
            [
              39.150799,
              56.66026
            ],
            [
              39.158657,
              56.657632
            ],
            [
              39.162154,
              56.657063
            ],
            [
              39.166143,
              56.656447
            ],
            [
              39.169068,
              56.660758
            ],
            [
              39.176958,
              56.6683
            ],
            [
              39.180556,
              56.668653
            ],
            [
              39.184955,
              56.669024
            ],
            [
              39.18856,
              56.66926
            ],
            [
              39.192521,
              56.669542
            ],
            [
              39.197612,
              56.668784
            ],
            [
              39.201677,
              56.670412
            ],
            [
              39.196255,
              56.672684
            ],
            [
              39.192318,
              56.673185
            ],
            [
              39.188291,
              56.67285
            ],
            [
              39.184764,
              56.672619
            ],
            [
              39.180011,
              56.672211
            ],
            [
              39.176335,
              56.671845
            ],
            [
              39.168719,
              56.6699
            ],
            [
              39.164259,
              56.669619
            ],
            [
              39.160258,
              56.6694
            ],
            [
              39.156156,
              56.669155
            ],
            [
              39.147929,
              56.671671
            ],
            [
              39.14095,
              56.681173
            ],
            [
              39.140717,
              56.690655
            ],
            [
              39.140804060307836,
              56.69252595626803
            ],
            [
              39.142702,
              56.692575
            ],
            [
              39.142698,
              56.692746
            ],
            [
              39.140976,
              56.696221
            ],
            [
              39.13975,
              56.704953
            ],
            [
              39.148213,
              56.707346
            ],
            [
              39.152096,
              56.708212
            ],
            [
              39.156269,
              56.710335
            ],
            [
              39.164875,
              56.713229
            ],
            [
              39.169059,
              56.712081
            ],
            [
              39.173126,
              56.711213
            ],
            [
              39.176861,
              56.71115
            ],
            [
              39.181934,
              56.711512
            ],
            [
              39.186314,
              56.713746
            ],
            [
              39.194838,
              56.706742
            ],
            [
              39.192978,
              56.703578
            ],
            [
              39.193262,
              56.699864
            ],
            [
              39.197544,
              56.695509
            ],
            [
              39.203425,
              56.68725
            ],
            [
              39.204217,
              56.682936
            ],
            [
              39.205636,
              56.682992
            ],
            [
              39.207589,
              56.685705
            ],
            [
              39.208078,
              56.693363
            ],
            [
              39.216569,
              56.696404
            ],
            [
              39.221369,
              56.687744
            ],
            [
              39.221089,
              56.684051
            ],
            [
              39.221204,
              56.681272
            ],
            [
              39.221234,
              56.680958
            ],
            [
              39.224817,
              56.681309
            ],
            [
              39.233899,
              56.676849
            ],
            [
              39.238966,
              56.676005
            ],
            [
              39.24033,
              56.679337
            ],
            [
              39.24754,
              56.686958
            ],
            [
              39.253736,
              56.686325
            ],
            [
              39.254916,
              56.686409
            ],
            [
              39.25466,
              56.69
            ],
            [
              39.25348,
              56.689916
            ],
            [
              39.246583,
              56.690429
            ],
            [
              39.239403,
              56.692145
            ],
            [
              39.232859,
              56.693439
            ],
            [
              39.228252,
              56.697508
            ],
            [
              39.231656,
              56.708396
            ],
            [
              39.235963,
              56.70886
            ],
            [
              39.242776,
              56.709725
            ],
            [
              39.246494,
              56.710183
            ],
            [
              39.24988,
              56.711303
            ],
            [
              39.255501,
              56.711052
            ],
            [
              39.259207,
              56.709656
            ],
            [
              39.264302,
              56.709626
            ],
            [
              39.275832,
              56.711554
            ],
            [
              39.27942,
              56.711254
            ],
            [
              39.281687,
              56.711649
            ],
            [
              39.282234,
              56.711738
            ],
            [
              39.281655,
              56.715292
            ],
            [
              39.278727,
              56.719428
            ],
            [
              39.278024,
              56.719648
            ],
            [
              39.277643,
              56.719726
            ],
            [
              39.273952,
              56.718735
            ],
            [
              39.267271,
              56.718474
            ],
            [
              39.265281,
              56.731275
            ],
            [
              39.266186,
              56.732726
            ],
            [
              39.26629,
              56.733217
            ],
            [
              39.26537,
              56.734874
            ],
            [
              39.264469,
              56.734896
            ],
            [
              39.262767,
              56.733958
            ],
            [
              39.262664,
              56.733467
            ],
            [
              39.264381,
              56.731297
            ],
            [
              39.264303,
              56.719091
            ],
            [
              39.262119,
              56.718479
            ],
            [
              39.256456,
              56.716617
            ],
            [
              39.251109,
              56.725741
            ],
            [
              39.250362,
              56.729263
            ],
            [
              39.241686,
              56.722465
            ],
            [
              39.237266,
              56.726488
            ],
            [
              39.232677,
              56.735574
            ],
            [
              39.231192,
              56.742477
            ],
            [
              39.227997,
              56.744136
            ],
            [
              39.224509,
              56.743087
            ],
            [
              39.224333,
              56.742736
            ],
            [
              39.228151,
              56.735503
            ],
            [
              39.226333,
              56.724483
            ],
            [
              39.223245,
              56.722184
            ],
            [
              39.220544,
              56.719013
            ],
            [
              39.209253,
              56.721481
            ],
            [
              39.209503,
              56.725018
            ],
            [
              39.206183,
              56.727569
            ],
            [
              39.205767,
              56.722972
            ],
            [
              39.20293910001327,
              56.71722950665807
            ],
            [
              39.201229,
              56.718337
            ],
            [
              39.200979,
              56.71823
            ],
            [
              39.195934,
              56.714275
            ],
            [
              39.192331,
              56.714118
            ],
            [
              39.190777,
              56.726487
            ],
            [
              39.1891501441978,
              56.72742903438327
            ],
            [
              39.189069,
              56.729224
            ],
            [
              39.182847,
              56.736352
            ],
            [
              39.179489,
              56.737649
            ],
            [
              39.178569,
              56.735267
            ],
            [
              39.176415,
              56.729857
            ],
            [
              39.17125,
              56.722463
            ],
            [
              39.16756,
              56.719733
            ],
            [
              39.160124,
              56.71781
            ],
            [
              39.153857,
              56.718711
            ],
            [
              39.14856,
              56.720715
            ],
            [
              39.144078,
              56.723692
            ],
            [
              39.137352,
              56.724977
            ],
            [
              39.131368,
              56.726031
            ],
            [
              39.126281,
              56.726765
            ],
            [
              39.120973,
              56.726737
            ],
            [
              39.11898036355572,
              56.726372641787734
            ],
            [
              39.118979,
              56.72638
            ],
            [
              39.115337,
              56.725705
            ],
            [
              39.111696,
              56.725029
            ],
            [
              39.107857,
              56.724968
            ],
            [
              39.103021,
              56.725279
            ],
            [
              39.102887,
              56.736919
            ],
            [
              39.107449,
              56.739822
            ],
            [
              39.114203,
              56.744085
            ],
            [
              39.117229,
              56.745963
            ],
            [
              39.119115,
              56.748949
            ],
            [
              39.116911,
              56.752863
            ],
            [
              39.112294,
              56.747137
            ],
            [
              39.105516,
              56.742859
            ],
            [
              39.100954,
              56.739956
            ],
            [
              39.097555,
              56.738028
            ],
            [
              39.09570993024621,
              56.737024395356265
            ],
            [
              39.095597,
              56.736963
            ],
            [
              39.093777,
              56.735973
            ],
            [
              39.09087,
              56.731811
            ],
            [
              39.079553,
              56.729459
            ],
            [
              39.075966,
              56.729962
            ],
            [
              39.071984,
              56.730962
            ],
            [
              39.067571,
              56.732391
            ],
            [
              39.062562,
              56.734528
            ],
            [
              39.056971,
              56.736445
            ],
            [
              39.053214,
              56.737513
            ],
            [
              39.05281001475764,
              56.737614257462916
            ],
            [
              39.052813,
              56.737625
            ],
            [
              39.050103,
              56.738378
            ],
            [
              39.042657,
              56.746346
            ],
            [
              39.04137772635994,
              56.74701856225584
            ],
            [
              39.041779,
              56.749633
            ],
            [
              39.045709,
              56.75354
            ],
            [
              39.048211,
              56.756929
            ],
            [
              39.053815,
              56.762822
            ],
            [
              39.06227,
              56.768139
            ],
            [
              39.068085,
              56.768797
            ],
            [
              39.073913,
              56.770868
            ],
            [
              39.077055,
              56.772572
            ],
            [
              39.080989,
              56.774711
            ],
            [
              39.089249,
              56.776625
            ],
            [
              39.093551,
              56.775223
            ],
            [
              39.097309,
              56.775137
            ],
            [
              39.108625,
              56.77509
            ],
            [
              39.115544,
              56.772548
            ],
            [
              39.119751,
              56.77141
            ],
            [
              39.123577,
              56.77034
            ],
            [
              39.128794,
              56.769743
            ],
            [
              39.135752,
              56.76312
            ],
            [
              39.14359,
              56.763507
            ],
            [
              39.148293,
              56.761984
            ],
            [
              39.155666,
              56.762591
            ],
            [
              39.15566679939335,
              56.76259127727793
            ],
            [
              39.162962,
              56.763192
            ],
            [
              39.16280723427051,
              56.76506801161289
            ],
            [
              39.16296,
              56.765121
            ],
            [
              39.17054,
              56.765966
            ],
            [
              39.178471,
              56.766631
            ],
            [
              39.182685,
              56.766105
            ],
            [
              39.187295,
              56.765694
            ],
            [
              39.191086,
              56.765419
            ],
            [
              39.194796,
              56.765292
            ],
            [
              39.200028,
              56.76437
            ],
            [
              39.204476,
              56.763517
            ],
            [
              39.209128,
              56.763932
            ],
            [
              39.211929,
              56.766288
            ],
            [
              39.213216,
              56.772601
            ],
            [
              39.213353,
              56.775778
            ],
            [
              39.213611,
              56.77814
            ],
            [
              39.213869,
              56.780501
            ],
            [
              39.210291,
              56.780893
            ],
            [
              39.210032,
              56.778531
            ],
            [
              39.209774,
              56.77617
            ],
            [
              39.204302,
              56.767113
            ],
            [
              39.200787,
              56.767889
            ],
            [
              39.196465,
              56.768702
            ],
            [
              39.192071,
              56.769076
            ],
            [
              39.18761,
              56.76928
            ],
            [
              39.182461,
              56.769815
            ],
            [
              39.177818,
              56.770276
            ],
            [
              39.172602,
              56.77228
            ],
            [
              39.172418,
              56.782123
            ],
            [
              39.178437,
              56.785513
            ],
            [
              39.183458,
              56.787755
            ],
            [
              39.186734,
              56.789238
            ],
            [
              39.19036,
              56.790645
            ],
            [
              39.193692,
              56.792463
            ],
            [
              39.198167,
              56.797899
            ],
            [
              39.20628,
              56.800471
            ],
            [
              39.208099,
              56.805615
            ],
            [
              39.206289,
              56.808392
            ],
            [
              39.202626,
              56.816592
            ],
            [
              39.201975,
              56.820132
            ],
            [
              39.199318,
              56.819644
            ],
            [
              39.195976,
              56.819097
            ],
            [
              39.185345,
              56.819202
            ],
            [
              39.177479,
              56.817733
            ],
            [
              39.172712,
              56.817426
            ],
            [
              39.167084,
              56.812821
            ],
            [
              39.159184,
              56.819438
            ],
            [
              39.159152,
              56.815838
            ],
            [
              39.167597,
              56.809257
            ],
            [
              39.170335,
              56.809652
            ],
            [
              39.173797,
              56.810606
            ],
            [
              39.17921,
              56.810848
            ],
            [
              39.182799,
              56.811687
            ],
            [
              39.187141,
              56.811585
            ],
            [
              39.192366,
              56.811884
            ],
            [
              39.194828,
              56.811667
            ],
            [
              39.201232,
              56.804856
            ],
            [
              39.194247,
              56.797116
            ],
            [
              39.187447,
              56.793365
            ],
            [
              39.183732,
              56.791878
            ],
            [
              39.179984,
              56.790318
            ],
            [
              39.169113,
              56.792153
            ],
            [
              39.169227,
              56.788571
            ],
            [
              39.163823,
              56.782356
            ],
            [
              39.158059,
              56.784167
            ],
            [
              39.15590861917874,
              56.78403365760846
            ],
            [
              39.15591,
              56.784189
            ],
            [
              39.145412,
              56.788324
            ],
            [
              39.138367,
              56.785
            ],
            [
              39.130585,
              56.785706
            ],
            [
              39.124188,
              56.788714
            ],
            [
              39.117515,
              56.787649
            ],
            [
              39.110152,
              56.786664
            ],
            [
              39.10505,
              56.787868
            ],
            [
              39.104241,
              56.799373
            ],
            [
              39.107228,
              56.801706
            ],
            [
              39.10996,
              56.804172
            ],
            [
              39.116761,
              56.80754
            ],
            [
              39.125768,
              56.805788
            ],
            [
              39.129415,
              56.804291
            ],
            [
              39.129848,
              56.804926
            ],
            [
              39.130748,
              56.806537
            ],
            [
              39.12683235810744,
              56.815948229603386
            ],
            [
              39.13327,
              56.81607
            ],
            [
              39.14381,
              56.815976
            ],
            [
              39.143842,
              56.819575
            ],
            [
              39.122762,
              56.819765
            ],
            [
              39.116049,
              56.823525
            ],
            [
              39.11789,
              56.834772
            ],
            [
              39.120628,
              56.836973
            ],
            [
              39.126571,
              56.838982
            ],
            [
              39.129949,
              56.841672
            ],
            [
              39.129337,
              56.850238
            ],
            [
              39.127655,
              56.859066
            ],
            [
              39.136009,
              56.860715
            ],
            [
              39.137401,
              56.860004
            ],
            [
              39.138626,
              56.859471
            ],
            [
              39.140062,
              56.862772
            ],
            [
              39.135025,
              56.870192
            ],
            [
              39.132069,
              56.870346
            ],
            [
              39.127748,
              56.867209
            ],
            [
              39.126145,
              56.863776
            ],
            [
              39.115166,
              56.860955
            ],
            [
              39.107507,
              56.865027
            ],
            [
              39.103163,
              56.870646
            ],
            [
              39.099412,
              56.873011
            ],
            [
              39.104306,
              56.882935
            ],
            [
              39.111529,
              56.881554
            ],
            [
              39.115715,
              56.880678
            ],
            [
              39.116355,
              56.884221
            ],
            [
              39.112306,
              56.885069
            ],
            [
              39.106321,
              56.894482
            ],
            [
              39.112475,
              56.899175
            ],
            [
              39.11531,
              56.901359
            ],
            [
              39.117113,
              56.902725
            ],
            [
              39.119708,
              56.904671
            ],
            [
              39.122304,
              56.906617
            ],
            [
              39.120144,
              56.909498
            ],
            [
              39.117549,
              56.907552
            ],
            [
              39.114953,
              56.905606
            ],
            [
              39.113136,
              56.904228
            ],
            [
              39.110241,
              56.901998
            ],
            [
              39.104143,
              56.897348
            ],
            [
              39.100674,
              56.892476
            ],
            [
              39.0907,
              56.893548
            ],
            [
              39.088291,
              56.898198
            ],
            [
              39.085784,
              56.901784
            ],
            [
              39.084008,
              56.905063
            ],
            [
              39.080663,
              56.908406
            ],
            [
              39.077568,
              56.906568
            ],
            [
              39.077988,
              56.90586
            ],
            [
              39.079079,
              56.904777
            ],
            [
              39.081792,
              56.901097
            ],
            [
              39.08538,
              56.894543
            ],
            [
              39.088139,
              56.891018
            ],
            [
              39.082511,
              56.88089
            ],
            [
              39.078291,
              56.877648
            ],
            [
              39.07330055198574,
              56.87378439611544
            ],
            [
              39.07313510527555,
              56.87365646261596
            ],
            [
              39.069962100481696,
              56.871750711481056
            ],
            [
              39.059491,
              56.875537
            ],
            [
              39.058719,
              56.877538
            ],
            [
              39.058251,
              56.878486
            ],
            [
              39.058208115755555,
              56.8784648368645
            ],
            [
              39.058056,
              56.878812
            ],
            [
              39.053351,
              56.883782
            ],
            [
              39.054212,
              56.890706
            ],
            [
              39.050806,
              56.893996
            ],
            [
              39.041917,
              56.893963
            ],
            [
              39.038603,
              56.896381
            ],
            [
              39.035918,
              56.90098
            ],
            [
              39.031498,
              56.903195
            ],
            [
              39.024411,
              56.909324
            ],
            [
              39.018504,
              56.919263
            ],
            [
              39.018896,
              56.922842
            ],
            [
              39.01563,
              56.926123
            ],
            [
              39.015039,
              56.926286
            ],
            [
              39.013813,
              56.926466
            ],
            [
              39.003867,
              56.920828
            ],
            [
              38.996278,
              56.918011
            ],
            [
              38.990689,
              56.915938
            ],
            [
              38.985602,
              56.914579
            ],
            [
              38.975126,
              56.91333
            ],
            [
              38.970722,
              56.914146
            ],
            [
              38.966388,
              56.913953
            ],
            [
              38.954695,
              56.915128
            ],
            [
              38.95133323869385,
              56.91522272050316
            ],
            [
              38.95133,
              56.915284
            ],
            [
              38.942099,
              56.916812
            ],
            [
              38.938376,
              56.918417
            ],
            [
              38.932403,
              56.91958
            ],
            [
              38.926561,
              56.920318
            ],
            [
              38.925424,
              56.912599
            ],
            [
              38.926263,
              56.907876
            ],
            [
              38.926957,
              56.902414
            ],
            [
              38.927531,
              56.896454
            ],
            [
              38.928458,
              56.891191
            ],
            [
              38.929139,
              56.885271
            ],
            [
              38.930024,
              56.879033
            ],
            [
              38.930985,
              56.875579
            ],
            [
              38.93161,
              56.872118
            ],
            [
              38.932055,
              56.868001
            ],
            [
              38.932882,
              56.862731
            ],
            [
              38.933959,
              56.857647
            ],
            [
              38.930251,
              56.84889
            ],
            [
              38.918471,
              56.846995
            ],
            [
              38.918427,
              56.856101
            ],
            [
              38.919556,
              56.859519
            ],
            [
              38.917489,
              56.860351
            ],
            [
              38.91377,
              56.862325
            ],
            [
              38.909478,
              56.864006
            ],
            [
              38.901193,
              56.866439
            ],
            [
              38.895566,
              56.868911
            ],
            [
              38.886192723941896,
              56.874307762306174
            ],
            [
              38.882658,
              56.878304
            ],
            [
              38.879395,
              56.882449
            ],
            [
              38.871047,
              56.887741
            ],
            [
              38.868312,
              56.890083
            ],
            [
              38.863673,
              56.89084
            ],
            [
              38.856358,
              56.894449
            ],
            [
              38.860066,
              56.902553
            ],
            [
              38.869396,
              56.905409
            ],
            [
              38.873239,
              56.90587
            ],
            [
              38.876292,
              56.905844
            ],
            [
              38.876943,
              56.906127
            ],
            [
              38.875507,
              56.909428
            ],
            [
              38.867304,
              56.911371
            ],
            [
              38.863482,
              56.912873
            ],
            [
              38.863423,
              56.912779
            ],
            [
              38.856278,
              56.904059
            ],
            [
              38.852729,
              56.901928
            ],
            [
              38.849499,
              56.900368
            ],
            [
              38.838894,
              56.901335
            ],
            [
              38.835648,
              56.90315
            ],
            [
              38.831147,
              56.904497
            ],
            [
              38.823105,
              56.908126
            ],
            [
              38.825296,
              56.918959
            ],
            [
              38.827896,
              56.921897
            ],
            [
              38.830683,
              56.927486
            ],
            [
              38.828179,
              56.93769
            ],
            [
              38.827804,
              56.941224
            ],
            [
              38.823892,
              56.948645
            ],
            [
              38.820418,
              56.949669
            ],
            [
              38.817247,
              56.947964
            ],
            [
              38.817565,
              56.945289
            ],
            [
              38.817739,
              56.944876
            ],
            [
              38.824298,
              56.938235
            ],
            [
              38.825103,
              56.933921
            ],
            [
              38.824155,
              56.927138
            ],
            [
              38.809697,
              56.930953
            ],
            [
              38.79429,
              56.927985
            ],
            [
              38.794971,
              56.92445
            ],
            [
              38.810378,
              56.927418
            ],
            [
              38.816327,
              56.917887
            ],
            [
              38.815126,
              56.914557
            ],
            [
              38.81475,
              56.910999
            ],
            [
              38.81752,
              56.907626
            ],
            [
              38.820799,
              56.904918
            ],
            [
              38.824129,
              56.902344
            ],
            [
              38.828013,
              56.901473
            ],
            [
              38.8334,
              56.90016
            ],
            [
              38.837085,
              56.898223
            ],
            [
              38.843142,
              56.894813
            ],
            [
              38.843335,
              56.884736
            ],
            [
              38.836984,
              56.878171
            ],
            [
              38.831329,
              56.873451
            ],
            [
              38.83006,
              56.863786
            ],
            [
              38.823979,
              56.854502
            ],
            [
              38.820579,
              56.852922
            ],
            [
              38.821977,
              56.847366
            ],
            [
              38.825643,
              56.842655
            ],
            [
              38.825543,
              56.847861
            ],
            [
              38.832216,
              56.854601
            ],
            [
              38.834187,
              56.858817
            ],
            [
              38.834442,
              56.863656
            ],
            [
              38.83506,
              56.868765
            ],
            [
              38.840553,
              56.876073
            ],
            [
              38.845064,
              56.881579
            ],
            [
              38.852722,
              56.883463
            ],
            [
              38.85881,
              56.882273
            ],
            [
              38.867776,
              56.883909
            ],
            [
              38.876964,
              56.879794
            ],
            [
              38.879595,
              56.876412
            ],
            [
              38.881969,
              56.872968
            ],
            [
              38.882903837953336,
              56.872139630580044
            ],
            [
              38.882898,
              56.872133
            ],
            [
              38.879065,
              56.863209
            ],
            [
              38.879718,
              56.859668
            ],
            [
              38.88702,
              56.861016
            ],
            [
              38.894323,
              56.862364
            ],
            [
              38.90199385187996,
              56.85996040742708
            ],
            [
              38.905885,
              56.855264
            ],
            [
              38.906861,
              56.850662
            ],
            [
              38.909382,
              56.8476
            ],
            [
              38.912908,
              56.845193
            ],
            [
              38.916879463372226,
              56.83839476175979
            ],
            [
              38.914546,
              56.832833
            ],
            [
              38.910476,
              56.828654
            ],
            [
              38.90950919290125,
              56.82766040412045
            ],
            [
              38.908759,
              56.82689
            ],
            [
              38.906867,
              56.824945
            ],
            [
              38.904405,
              56.822261
            ],
            [
              38.90249,
              56.819135
            ],
            [
              38.901901241106124,
              56.81816885301468
            ],
            [
              38.901901,
              56.818169
            ],
            [
              38.900436,
              56.815765
            ],
            [
              38.898972,
              56.813362
            ],
            [
              38.895719,
              56.807915
            ],
            [
              38.89464079809665,
              56.80610979473859
            ],
            [
              38.88935,
              56.800731
            ],
            [
              38.878839,
              56.7962
            ],
            [
              38.873627,
              56.796062
            ],
            [
              38.864944,
              56.800807
            ],
            [
              38.864326,
              56.804807
            ],
            [
              38.861467,
              56.806967
            ],
            [
              38.858318,
              56.809064
            ],
            [
              38.852019594861815,
              56.81514361142398
            ],
            [
              38.853338,
              56.816066
            ],
            [
              38.856398,
              56.818209
            ],
            [
              38.84668,
              56.818574
            ],
            [
              38.84158,
              56.818032
            ],
            [
              38.835182,
              56.824285
            ],
            [
              38.832797,
              56.82145
            ],
            [
              38.830532891985335,
              56.81973420385815
            ],
            [
              38.822372,
              56.818534
            ],
            [
              38.81804,
              56.816394
            ],
            [
              38.808536,
              56.814621
            ],
            [
              38.801135,
              56.815161
            ],
            [
              38.799552,
              56.811927
            ],
            [
              38.78933766866419,
              56.80976096715956
            ],
            [
              38.786479,
              56.811528
            ],
            [
              38.784494963351534,
              56.81246925748847
            ],
            [
              38.784433,
              56.812522
            ],
            [
              38.78225,
              56.813535
            ],
            [
              38.782249734787804,
              56.81353442843709
            ],
            [
              38.78216,
              56.813577
            ],
            [
              38.774736,
              56.816958
            ],
            [
              38.77134,
              56.818617
            ],
            [
              38.768107,
              56.820448
            ],
            [
              38.762952,
              56.823158
            ],
            [
              38.758537,
              56.825012
            ],
            [
              38.752145,
              56.827782
            ],
            [
              38.749388,
              56.828944
            ],
            [
              38.74799,
              56.825626
            ],
            [
              38.750746,
              56.824465
            ],
            [
              38.755799,
              56.822158
            ],
            [
              38.7594,
              56.820747
            ],
            [
              38.763921,
              56.818585
            ],
            [
              38.769181,
              56.815736
            ],
            [
              38.773494,
              56.813579
            ],
            [
              38.776546,
              56.811854
            ],
            [
              38.780643,
              56.810312
            ],
            [
              38.78132963578214,
              56.80969055679212
            ],
            [
              38.788289,
              56.802909
            ],
            [
              38.789114,
              56.80211
            ],
            [
              38.794955,
              56.801947
            ],
            [
              38.801357,
              56.797971
            ],
            [
              38.80459,
              56.79601
            ],
            [
              38.808875,
              56.793658
            ],
            [
              38.812307,
              56.791437
            ],
            [
              38.813997482373125,
              56.790462837975575
            ],
            [
              38.813997,
              56.790462
            ],
            [
              38.81566292769646,
              56.789503103870366
            ],
            [
              38.816326,
              56.789121
            ],
            [
              38.823254,
              56.785163
            ],
            [
              38.826324,
              56.782617
            ],
            [
              38.830536,
              56.779308
            ],
            [
              38.83738491451109,
              56.77364904779913
            ],
            [
              38.840814,
              56.76785
            ],
            [
              38.844372,
              56.766354
            ],
            [
              38.848692,
              56.757117
            ],
            [
              38.84885375130627,
              56.75690494980668
            ],
            [
              38.848941,
              56.756721
            ],
            [
              38.84976267551943,
              56.754116932266015
            ],
            [
              38.848607,
              56.752956
            ],
            [
              38.848867,
              56.752444
            ],
            [
              38.8486508358884,
              56.74788222940307
            ],
            [
              38.843375,
              56.744579
            ],
            [
              38.840466,
              56.742508
            ],
            [
              38.83641,
              56.739519
            ],
            [
              38.83517183633817,
              56.73820162403993
            ],
            [
              38.833652,
              56.737614
            ],
            [
              38.829924,
              56.735833
            ],
            [
              38.82836747745565,
              56.73327555965542
            ],
            [
              38.827661,
              56.733152
            ],
            [
              38.823766,
              56.731853
            ],
            [
              38.822148,
              56.730975
            ],
            [
              38.82237180731423,
              56.72498662141763
            ],
            [
              38.820447620379376,
              56.722036621309385
            ],
            [
              38.81473510278483,
              56.718809954310736
            ],
            [
              38.805208,
              56.724304
            ],
            [
              38.801698,
              56.725377
            ],
            [
              38.79383805781083,
              56.72554137779226
            ],
            [
              38.788253,
              56.725959
            ],
            [
              38.78515948770337,
              56.72530320189001
            ],
            [
              38.782551,
              56.725205
            ],
            [
              38.780741033494145,
              56.72483736687244
            ],
            [
              38.775911,
              56.724771
            ],
            [
              38.768530442534846,
              56.72207099961554
            ],
            [
              38.765823521608674,
              56.72355892465276
            ],
            [
              38.762742,
              56.728568
            ],
            [
              38.762739,
              56.728569
            ],
            [
              38.762474703109184,
              56.727554975016204
            ],
            [
              38.761549,
              56.728768
            ],
            [
              38.757538,
              56.729483
            ],
            [
              38.754028,
              56.728638
            ],
            [
              38.744124,
              56.730628
            ],
            [
              38.74077818900113,
              56.730179474746166
            ],
            [
              38.740773,
              56.731347
            ],
            [
              38.731346,
              56.734685
            ],
            [
              38.729333,
              56.745618
            ],
            [
              38.729592,
              56.749706
            ],
            [
              38.7269,
              56.752959
            ],
            [
              38.722833631834206,
              56.75760901159665
            ],
            [
              38.718219,
              56.766713
            ],
            [
              38.716779,
              56.777195
            ],
            [
              38.716562,
              56.77838
            ],
            [
              38.71648153003595,
              56.778391828489404
            ],
            [
              38.716292,
              56.77985
            ],
            [
              38.716102913094744,
              56.780544913515165
            ],
            [
              38.716133,
              56.780768
            ],
            [
              38.714958,
              56.788823
            ],
            [
              38.711119,
              56.792637
            ],
            [
              38.7067900223787,
              56.79601022112228
            ],
            [
              38.70662382859407,
              56.79616008360597
            ],
            [
              38.701827,
              56.806001
            ],
            [
              38.698646,
              56.81058
            ],
            [
              38.693541,
              56.81352
            ],
            [
              38.696208,
              56.822593
            ],
            [
              38.69975,
              56.82331
            ],
            [
              38.704798,
              56.821788
            ],
            [
              38.709675,
              56.820179
            ],
            [
              38.708907675723175,
              56.82230917633769
            ],
            [
              38.711587,
              56.821393
            ],
            [
              38.715153,
              56.824869
            ],
            [
              38.718605,
              56.827776
            ],
            [
              38.71663719882425,
              56.83619018718509
            ],
            [
              38.720054,
              56.836016
            ],
            [
              38.72475,
              56.83528
            ],
            [
              38.732994,
              56.833976
            ],
            [
              38.736829,
              56.833508
            ],
            [
              38.735889,
              56.837184
            ],
            [
              38.730942,
              56.844507
            ],
            [
              38.727375,
              56.84631
            ],
            [
              38.723905,
              56.845413
            ],
            [
              38.719132,
              56.843188
            ],
            [
              38.715992,
              56.841636
            ],
            [
              38.71163012101451,
              56.842704983145545
            ],
            [
              38.70500509734254,
              56.84446223027805
            ],
            [
              38.706686,
              56.852657
            ],
            [
              38.703652,
              56.854837
            ],
            [
              38.698531,
              56.85711
            ],
            [
              38.690298,
              56.859807
            ],
            [
              38.685607,
              56.863355
            ],
            [
              38.680822,
              56.866524
            ],
            [
              38.673694,
              56.871069
            ],
            [
              38.672047,
              56.880971
            ],
            [
              38.680341,
              56.88437
            ],
            [
              38.68602,
              56.88259
            ],
            [
              38.693162,
              56.884668
            ],
            [
              38.697918,
              56.884747
            ],
            [
              38.702723,
              56.884828
            ],
            [
              38.710948,
              56.8851
            ],
            [
              38.716566,
              56.885304
            ],
            [
              38.720499,
              56.885434
            ],
            [
              38.725492,
              56.885634
            ],
            [
              38.729574,
              56.885731
            ],
            [
              38.733091,
              56.885776
            ],
            [
              38.736607,
              56.885821
            ],
            [
              38.736561,
              56.889421
            ],
            [
              38.733044,
              56.889376
            ],
            [
              38.729528,
              56.889331
            ],
            [
              38.725407,
              56.889233
            ],
            [
              38.720111,
              56.889013
            ],
            [
              38.710853,
              56.888699
            ],
            [
              38.702563,
              56.888424
            ],
            [
              38.697858,
              56.888346
            ],
            [
              38.693103,
              56.888267
            ],
            [
              38.689155,
              56.888202
            ],
            [
              38.680277,
              56.887969
            ],
            [
              38.675416,
              56.887883
            ],
            [
              38.665695,
              56.882572
            ],
            [
              38.661038,
              56.881068
            ],
            [
              38.654123,
              56.876883
            ],
            [
              38.648415,
              56.879939
            ],
            [
              38.641368,
              56.883284
            ],
            [
              38.638232,
              56.886674
            ],
            [
              38.635113,
              56.891279
            ],
            [
              38.629727,
              56.896591
            ],
            [
              38.623582,
              56.904735
            ],
            [
              38.618222,
              56.912012
            ],
            [
              38.616237,
              56.915153
            ],
            [
              38.613767,
              56.917686
            ],
            [
              38.615112,
              56.927947
            ],
            [
              38.612556,
              56.932123
            ],
            [
              38.609844,
              56.926447
            ],
            [
              38.607427,
              56.922395
            ],
            [
              38.607639,
              56.917019
            ],
            [
              38.613924,
              56.908497
            ],
            [
              38.615791,
              56.904747
            ],
            [
              38.622013,
              56.899729
            ],
            [
              38.624669,
              56.896229
            ],
            [
              38.627394,
              56.893849
            ],
            [
              38.631602,
              56.889627
            ],
            [
              38.636111,
              56.883766
            ],
            [
              38.638984,
              56.880587
            ],
            [
              38.643819,
              56.87488
            ],
            [
              38.65129,
              56.874461
            ],
            [
              38.66019,
              56.873557
            ],
            [
              38.666004,
              56.870536
            ],
            [
              38.669107686264944,
              56.86908082906266
            ],
            [
              38.669064,
              56.868844
            ],
            [
              38.673468,
              56.865426
            ],
            [
              38.678941,
              56.863391
            ],
            [
              38.682148,
              56.86186
            ],
            [
              38.684735,
              56.859053
            ],
            [
              38.680418,
              56.84901
            ],
            [
              38.676597,
              56.848244
            ],
            [
              38.672502,
              56.848381
            ],
            [
              38.667943,
              56.848107
            ],
            [
              38.661668,
              56.850857
            ],
            [
              38.655862,
              56.858152
            ],
            [
              38.65518437270784,
              56.858059484022625
            ],
            [
              38.652502,
              56.859557
            ],
            [
              38.65088,
              56.855776
            ],
            [
              38.654955,
              56.854008
            ],
            [
              38.659262,
              56.8462
            ],
            [
              38.664076,
              56.844472
            ],
            [
              38.667992,
              56.844508
            ],
            [
              38.672978,
              56.835783
            ],
            [
              38.662681,
              56.832065
            ],
            [
              38.653079,
              56.835645
            ],
            [
              38.648755,
              56.834982
            ],
            [
              38.638259960447925,
              56.834941800390354
            ],
            [
              38.63713752084191,
              56.83520168226571
            ],
            [
              38.634284,
              56.836016
            ],
            [
              38.63220545222919,
              56.83647229622213
            ],
            [
              38.631247,
              56.836732
            ],
            [
              38.627527,
              56.837686
            ],
            [
              38.626815785647445,
              56.8378719170745
            ],
            [
              38.62631,
              56.83801
            ],
            [
              38.6256302273231,
              56.83818183142666
            ],
            [
              38.623166,
              56.838826
            ],
            [
              38.61825,
              56.840039
            ],
            [
              38.61411,
              56.841137
            ],
            [
              38.609869,
              56.842227
            ],
            [
              38.606308,
              56.84305
            ],
            [
              38.601559,
              56.844222
            ],
            [
              38.597145,
              56.845302
            ],
            [
              38.593523,
              56.846311
            ],
            [
              38.590071,
              56.845386
            ],
            [
              38.580811,
              56.842242
            ],
            [
              38.57733,
              56.844373
            ],
            [
              38.573768,
              56.844776
            ],
            [
              38.567102,
              56.844642
            ],
            [
              38.558431,
              56.845523
            ],
            [
              38.55236,
              56.846725
            ],
            [
              38.552349032745,
              56.8466836500028
            ],
            [
              38.544565,
              56.848501
            ],
            [
              38.541005,
              56.848805
            ],
            [
              38.529569,
              56.850718
            ],
            [
              38.525899,
              56.852618
            ],
            [
              38.516728,
              56.859917
            ],
            [
              38.521152,
              56.86971
            ],
            [
              38.515388,
              56.87225
            ],
            [
              38.507622,
              56.872755
            ],
            [
              38.50391803343953,
              56.87365078269203
            ],
            [
              38.502817,
              56.87432
            ],
            [
              38.495329,
              56.877095
            ],
            [
              38.489526,
              56.876857
            ],
            [
              38.480893,
              56.883681
            ],
            [
              38.48002,
              56.889003
            ],
            [
              38.48464,
              56.895139
            ],
            [
              38.491669,
              56.903211
            ],
            [
              38.500861,
              56.900014
            ],
            [
              38.502109,
              56.894432
            ],
            [
              38.509124,
              56.897139
            ],
            [
              38.50806,
              56.904737
            ],
            [
              38.516933,
              56.912655
            ],
            [
              38.521716,
              56.913456
            ],
            [
              38.531777,
              56.915742
            ],
            [
              38.537718,
              56.915134
            ],
            [
              38.547878,
              56.91344
            ],
            [
              38.548469,
              56.916991
            ],
            [
              38.54339,
              56.917838
            ],
            [
              38.53831,
              56.918685
            ],
            [
              38.534814,
              56.919189
            ],
            [
              38.529335,
              56.919612
            ],
            [
              38.524959,
              56.919051
            ],
            [
              38.520122,
              56.916683
            ],
            [
              38.513423,
              56.911854
            ],
            [
              38.508159,
              56.913831
            ],
            [
              38.50417,
              56.912468
            ],
            [
              38.494473,
              56.919458
            ],
            [
              38.492343,
              56.922369
            ],
            [
              38.489697,
              56.924998
            ],
            [
              38.480645,
              56.924379
            ],
            [
              38.477132,
              56.924999
            ],
            [
              38.473101,
              56.926059
            ],
            [
              38.472083,
              56.926357
            ],
            [
              38.471575,
              56.926408
            ],
            [
              38.471213,
              56.922826
            ],
            [
              38.472696,
              56.922059
            ],
            [
              38.476266,
              56.921382
            ],
            [
              38.480085,
              56.920779
            ],
            [
              38.483813,
              56.920424
            ],
            [
              38.487904,
              56.910349
            ],
            [
              38.482933,
              56.903499
            ],
            [
              38.480676084944,
              56.90058101433734
            ],
            [
              38.480497,
              56.900607
            ],
            [
              38.480451,
              56.90029
            ],
            [
              38.477786,
              56.89349
            ],
            [
              38.469936,
              56.887624
            ],
            [
              38.459491,
              56.891115
            ],
            [
              38.453527,
              56.893663
            ],
            [
              38.448964,
              56.895496
            ],
            [
              38.441334,
              56.897746
            ],
            [
              38.44089,
              56.897741
            ],
            [
              38.437791422848676,
              56.89652476386287
            ],
            [
              38.437479,
              56.89653
            ],
            [
              38.430813,
              56.895104
            ],
            [
              38.423729,
              56.888462
            ],
            [
              38.420021815178075,
              56.8891000822043
            ],
            [
              38.420028,
              56.88919
            ],
            [
              38.419145,
              56.889251
            ],
            [
              38.41361,
              56.889677
            ],
            [
              38.408553,
              56.889976
            ],
            [
              38.403169,
              56.889788
            ],
            [
              38.398823,
              56.889644
            ],
            [
              38.391959,
              56.889951
            ],
            [
              38.388453,
              56.88977
            ],
            [
              38.384496,
              56.889474
            ],
            [
              38.380107,
              56.889136
            ],
            [
              38.375816,
              56.88882
            ],
            [
              38.372153,
              56.888562
            ],
            [
              38.36692,
              56.888354
            ],
            [
              38.362391,
              56.888194
            ],
            [
              38.357165723071944,
              56.89052913691063
            ],
            [
              38.350675,
              56.898513
            ],
            [
              38.34948893972384,
              56.89775243395493
            ],
            [
              38.348376,
              56.898764
            ],
            [
              38.352746,
              56.907863
            ],
            [
              38.356526,
              56.908184
            ],
            [
              38.36261,
              56.908618
            ],
            [
              38.362454,
              56.912215
            ],
            [
              38.35625,
              56.911774
            ],
            [
              38.352368,
              56.911443
            ],
            [
              38.342441,
              56.90951
            ],
            [
              38.333496,
              56.911868
            ],
            [
              38.326866,
              56.912923
            ],
            [
              38.319221,
              56.915314
            ],
            [
              38.317867,
              56.911978
            ],
            [
              38.321495,
              56.910303
            ],
            [
              38.326311,
              56.909366
            ],
            [
              38.330693,
              56.909021
            ],
            [
              38.334558,
              56.907537
            ],
            [
              38.338479,
              56.906628
            ],
            [
              38.343372,
              56.904213
            ],
            [
              38.34174,
              56.89384
            ],
            [
              38.330764,
              56.891552
            ],
            [
              38.325745,
              56.890409
            ],
            [
              38.3236780110408,
              56.887544920687674
            ],
            [
              38.323325,
              56.887591
            ],
            [
              38.321798,
              56.88694
            ],
            [
              38.319374,
              56.884409
            ],
            [
              38.31711449167671,
              56.882485399252914
            ],
            [
              38.317022,
              56.882578
            ],
            [
              38.3158609033944,
              56.881418174436114
            ],
            [
              38.315856,
              56.881414
            ],
            [
              38.31077,
              56.876739
            ],
            [
              38.305803,
              56.873953
            ],
            [
              38.302137,
              56.871842
            ],
            [
              38.298619,
              56.867332
            ],
            [
              38.29817791646127,
              56.86704417185892
            ],
            [
              38.295964,
              56.865991
            ],
            [
              38.294716,
              56.865189
            ],
            [
              38.291951842951406,
              56.86301869613999
            ],
            [
              38.28902576702585,
              56.86210260862203
            ],
            [
              38.28352945456322,
              56.861666237695026
            ],
            [
              38.282847774203084,
              56.8619587420492
            ],
            [
              38.27821,
              56.867168
            ],
            [
              38.277913,
              56.870756
            ],
            [
              38.275587981329764,
              56.871610913697374
            ],
            [
              38.27563598027566,
              56.872049905976056
            ],
            [
              38.275916,
              56.872828
            ],
            [
              38.276071,
              56.875934
            ],
            [
              38.2760607192416,
              56.875934514752856
            ],
            [
              38.276081,
              56.87612
            ],
            [
              38.27955,
              56.886203
            ],
            [
              38.27993334043335,
              56.88797534295293
            ],
            [
              38.286959,
              56.895053
            ],
            [
              38.285812,
              56.898465
            ],
            [
              38.28029245197358,
              56.89941456489187
            ],
            [
              38.285294,
              56.904142
            ],
            [
              38.290115,
              56.903776
            ],
            [
              38.295357,
              56.902386
            ],
            [
              38.297501,
              56.901794
            ],
            [
              38.301627,
              56.901365
            ],
            [
              38.305752,
              56.900937
            ],
            [
              38.306124,
              56.904517
            ],
            [
              38.301999,
              56.904946
            ],
            [
              38.291038,
              56.907255
            ],
            [
              38.28593,
              56.908063
            ],
            [
              38.281846,
              56.907203
            ],
            [
              38.274608,
              56.904672
            ],
            [
              38.268209,
              56.906388
            ],
            [
              38.272455,
              56.91663
            ],
            [
              38.277563,
              56.918235
            ],
            [
              38.283632,
              56.916224
            ],
            [
              38.293923,
              56.91378
            ],
            [
              38.295443,
              56.917159
            ],
            [
              38.294762,
              56.92689
            ],
            [
              38.297133,
              56.93037
            ],
            [
              38.300864,
              56.936475
            ],
            [
              38.303928,
              56.939217
            ],
            [
              38.303305,
              56.942896
            ],
            [
              38.29759,
              56.939637
            ],
            [
              38.293979,
              56.939349
            ],
            [
              38.291735,
              56.95086
            ],
            [
              38.29277,
              56.955712
            ],
            [
              38.300313,
              56.96185
            ],
            [
              38.309811,
              56.961467
            ],
            [
              38.318462,
              56.961128
            ],
            [
              38.323027,
              56.960958
            ],
            [
              38.32754,
              56.960791
            ],
            [
              38.335819,
              56.960981
            ],
            [
              38.343513,
              56.9596
            ],
            [
              38.347676,
              56.958905
            ],
            [
              38.351604,
              56.958313
            ],
            [
              38.355517,
              56.958419
            ],
            [
              38.358396,
              56.958923
            ],
            [
              38.361143,
              56.959654
            ],
            [
              38.360217,
              56.963133
            ],
            [
              38.35747,
              56.962402
            ],
            [
              38.350756,
              56.962152
            ],
            [
              38.345128,
              56.963095
            ],
            [
              38.340885,
              56.964045
            ],
            [
              38.336281,
              56.964803
            ],
            [
              38.331874,
              56.965594
            ],
            [
              38.327778,
              56.964384
            ],
            [
              38.323161,
              56.964556
            ],
            [
              38.318595,
              56.964725
            ],
            [
              38.314647,
              56.974078
            ],
            [
              38.316979,
              56.976799
            ],
            [
              38.320684,
              56.982589
            ],
            [
              38.327418,
              56.983813
            ],
            [
              38.33237,
              56.983643
            ],
            [
              38.334091,
              56.983785
            ],
            [
              38.338316,
              56.9844
            ],
            [
              38.34254,
              56.985016
            ],
            [
              38.34253966149844,
              56.98501832320335
            ],
            [
              38.342797,
              56.985056
            ],
            [
              38.349688,
              56.98567
            ],
            [
              38.356572,
              56.986284
            ],
            [
              38.360103,
              56.986585
            ],
            [
              38.363679,
              56.98689
            ],
            [
              38.369856,
              56.987576
            ],
            [
              38.373724,
              56.987749
            ],
            [
              38.379504,
              56.989343
            ],
            [
              38.383835,
              56.990733
            ],
            [
              38.388551,
              56.992012
            ],
            [
              38.392307,
              56.993195
            ],
            [
              38.398935,
              56.99669
            ],
            [
              38.403947,
              56.997199
            ],
            [
              38.409259,
              56.997016
            ],
            [
              38.411687,
              56.996909
            ],
            [
              38.413684,
              56.996863
            ],
            [
              38.413768,
              57.000462
            ],
            [
              38.411771,
              57.000509
            ],
            [
              38.406736,
              57.000705
            ],
            [
              38.401221,
              57.000815
            ],
            [
              38.397666,
              57.000059
            ],
            [
              38.393019,
              56.997561
            ],
            [
              38.389654,
              56.995996
            ],
            [
              38.385262,
              56.99485
            ],
            [
              38.38061,
              56.993477
            ],
            [
              38.375707,
              56.991819
            ],
            [
              38.371623,
              56.991246
            ],
            [
              38.366437,
              56.990816
            ],
            [
              38.359798,
              56.990172
            ],
            [
              38.356267,
              56.989871
            ],
            [
              38.349368,
              56.989256
            ],
            [
              38.345701,
              57.000155
            ],
            [
              38.350464,
              57.007927
            ],
            [
              38.353509,
              57.010374
            ],
            [
              38.363412,
              57.012736
            ],
            [
              38.368544,
              57.011305
            ],
            [
              38.378129,
              57.017311
            ],
            [
              38.378715,
              57.020863
            ],
            [
              38.371634,
              57.022946
            ],
            [
              38.368788,
              57.020742
            ],
            [
              38.36026,
              57.019697
            ],
            [
              38.354495,
              57.027409
            ],
            [
              38.354002,
              57.030975
            ],
            [
              38.346308,
              57.031165
            ],
            [
              38.342709,
              57.031072
            ],
            [
              38.342667,
              57.029687
            ],
            [
              38.345845,
              57.026363
            ],
            [
              38.349176,
              57.020059
            ],
            [
              38.349707,
              57.011936
            ],
            [
              38.345581,
              57.008561
            ],
            [
              38.343464,
              57.005448
            ],
            [
              38.337824,
              56.997798
            ],
            [
              38.332556,
              56.995104
            ],
            [
              38.32544,
              56.995523
            ],
            [
              38.320999,
              56.998209
            ],
            [
              38.314009,
              57.0036
            ],
            [
              38.30504,
              57.009829
            ],
            [
              38.304737,
              57.013857
            ],
            [
              38.301542,
              57.017023
            ],
            [
              38.298255,
              57.018677
            ],
            [
              38.293797,
              57.020399
            ],
            [
              38.290199,
              57.028842
            ],
            [
              38.287461,
              57.03118
            ],
            [
              38.286044,
              57.029519
            ],
            [
              38.276001,
              57.023857
            ],
            [
              38.274126,
              57.023574
            ],
            [
              38.274665,
              57.020014
            ],
            [
              38.27654,
              57.020298
            ],
            [
              38.278414,
              57.020582
            ],
            [
              38.286856,
              57.019922
            ],
            [
              38.292646,
              57.016988
            ],
            [
              38.296856,
              57.01536
            ],
            [
              38.301732,
              57.006083
            ],
            [
              38.298194,
              57.0029
            ],
            [
              38.294828,
              57.001344
            ],
            [
              38.291272,
              57.001021
            ],
            [
              38.28757,
              57.001332
            ],
            [
              38.283742,
              57.002034
            ],
            [
              38.279876,
              57.001988
            ],
            [
              38.274376,
              57.001356
            ],
            [
              38.271783,
              57.001208
            ],
            [
              38.271099,
              57.00112
            ],
            [
              38.271554,
              56.997549
            ],
            [
              38.272239,
              56.997636
            ],
            [
              38.275376,
              56.997897
            ],
            [
              38.280369,
              56.998422
            ],
            [
              38.284187,
              56.998273
            ],
            [
              38.288349,
              56.997614
            ],
            [
              38.292313,
              56.997322
            ],
            [
              38.295988,
              56.997857
            ],
            [
              38.306225,
              56.992139
            ],
            [
              38.30458,
              56.988999
            ],
            [
              38.300284,
              56.980669
            ],
            [
              38.295536,
              56.977226
            ],
            [
              38.291675,
              56.974153
            ],
            [
              38.289686,
              56.972887
            ],
            [
              38.289408,
              56.972519
            ],
            [
              38.290446,
              56.9606
            ],
            [
              38.282335,
              56.960121
            ],
            [
              38.278024,
              56.95897
            ],
            [
              38.274936,
              56.957028
            ],
            [
              38.265488,
              56.958434
            ],
            [
              38.26128,
              56.960534
            ],
            [
              38.258005,
              56.96213
            ],
            [
              38.255852,
              56.972755
            ],
            [
              38.256077,
              56.976357
            ],
            [
              38.265957,
              56.982775
            ],
            [
              38.271964,
              56.982633
            ],
            [
              38.276015,
              56.9853
            ],
            [
              38.275457,
              56.98727
            ],
            [
              38.275114,
              56.988106
            ],
            [
              38.26606,
              56.986374
            ],
            [
              38.260385,
              56.986537
            ],
            [
              38.256622,
              56.983063
            ],
            [
              38.246121,
              56.982668
            ],
            [
              38.244065,
              56.989458
            ],
            [
              38.242631,
              56.994123
            ],
            [
              38.241533,
              56.999388
            ],
            [
              38.241247,
              56.999819
            ],
            [
              38.238249,
              56.997826
            ],
            [
              38.238246,
              56.995734
            ],
            [
              38.239433,
              56.991784
            ],
            [
              38.240733,
              56.988082
            ],
            [
              38.241096,
              56.983285
            ],
            [
              38.242299,
              56.976514
            ],
            [
              38.245645,
              56.973153
            ],
            [
              38.248137,
              56.970623
            ],
            [
              38.246521,
              56.959029
            ],
            [
              38.240817,
              56.960196
            ],
            [
              38.237191,
              56.961862
            ],
            [
              38.23152,
              56.96968
            ],
            [
              38.223704,
              56.96755
            ],
            [
              38.218042,
              56.967056
            ],
            [
              38.22304,
              56.964012
            ],
            [
              38.226961,
              56.962894
            ],
            [
              38.235097,
              56.958934
            ],
            [
              38.239593,
              56.95681
            ],
            [
              38.243724,
              56.955929
            ],
            [
              38.247863,
              56.955078
            ],
            [
              38.252074,
              56.954013
            ],
            [
              38.256499,
              56.953111
            ],
            [
              38.26225056290581,
              56.947520688922424
            ],
            [
              38.262966,
              56.942254
            ],
            [
              38.263932,
              56.938668
            ],
            [
              38.265149,
              56.934374
            ],
            [
              38.264026,
              56.92507
            ],
            [
              38.255062,
              56.922605
            ],
            [
              38.249983,
              56.922524
            ],
            [
              38.245620934722865,
              56.92517637773057
            ],
            [
              38.247293,
              56.935533
            ],
            [
              38.249365,
              56.942044
            ],
            [
              38.249428,
              56.944135
            ],
            [
              38.24583,
              56.944244
            ],
            [
              38.240788,
              56.936868
            ],
            [
              38.23191,
              56.934375
            ],
            [
              38.226963,
              56.935044
            ],
            [
              38.222702,
              56.93694
            ],
            [
              38.21799,
              56.938643
            ],
            [
              38.211448,
              56.940311
            ],
            [
              38.205615,
              56.944908
            ],
            [
              38.202901,
              56.948
            ],
            [
              38.201753,
              56.957247
            ],
            [
              38.19809,
              56.963377
            ],
            [
              38.19462,
              56.964728
            ],
            [
              38.184098,
              56.960591
            ],
            [
              38.18,
              56.961761
            ],
            [
              38.175601,
              56.961859
            ],
            [
              38.171568,
              56.961815
            ],
            [
              38.167754,
              56.960036
            ],
            [
              38.16153388933509,
              56.952252775087885
            ],
            [
              38.15791845272953,
              56.95475374980848
            ],
            [
              38.155045,
              56.958578
            ],
            [
              38.15554,
              56.962144
            ],
            [
              38.153826,
              56.971614
            ],
            [
              38.154829,
              56.975347
            ],
            [
              38.152921,
              56.984546
            ],
            [
              38.162134,
              56.987913
            ],
            [
              38.166067,
              56.988295
            ],
            [
              38.172696,
              56.98761
            ],
            [
              38.178198,
              56.987278
            ],
            [
              38.182797,
              56.986947
            ],
            [
              38.182924,
              56.990545
            ],
            [
              38.178066,
              56.990875
            ],
            [
              38.172536,
              56.991414
            ],
            [
              38.166102,
              56.991968
            ],
            [
              38.160645,
              56.999282
            ],
            [
              38.162146,
              57.003536
            ],
            [
              38.162793,
              57.007098
            ],
            [
              38.163205,
              57.010957
            ],
            [
              38.171878,
              57.016828
            ],
            [
              38.177676,
              57.012744
            ],
            [
              38.180663,
              57.009999
            ],
            [
              38.186349,
              57.010017
            ],
            [
              38.189876,
              57.008855
            ],
            [
              38.188995,
              57.012479
            ],
            [
              38.185792,
              57.014839
            ],
            [
              38.182499,
              57.019635
            ],
            [
              38.184169,
              57.03096
            ],
            [
              38.188706,
              57.031702
            ],
            [
              38.19404,
              57.033008
            ],
            [
              38.19877,
              57.033928
            ],
            [
              38.203027,
              57.035057
            ],
            [
              38.20676,
              57.036162
            ],
            [
              38.210778,
              57.03798
            ],
            [
              38.216149,
              57.040399
            ],
            [
              38.219745,
              57.041668
            ],
            [
              38.226165,
              57.044
            ],
            [
              38.223279,
              57.049282
            ],
            [
              38.220396,
              57.052916
            ],
            [
              38.21728,
              57.051113
            ],
            [
              38.211266,
              57.042321
            ],
            [
              38.207345,
              57.040195
            ],
            [
              38.203839,
              57.039051
            ],
            [
              38.200264,
              57.037972
            ],
            [
              38.196251,
              57.037118
            ],
            [
              38.191136,
              57.035975
            ],
            [
              38.185828,
              57.034878
            ],
            [
              38.181659,
              57.034232
            ],
            [
              38.175614,
              57.033555
            ],
            [
              38.171949,
              57.032611
            ],
            [
              38.16668,
              57.031383
            ],
            [
              38.16659570482238,
              57.031056463099986
            ],
            [
              38.166313,
              57.0312
            ],
            [
              38.166196,
              57.031124
            ],
            [
              38.165374,
              57.029611
            ],
            [
              38.165382,
              57.029495
            ],
            [
              38.157435,
              57.021592
            ],
            [
              38.151115,
              57.026922
            ],
            [
              38.15006,
              57.031858
            ],
            [
              38.151036,
              57.036324
            ],
            [
              38.152435,
              57.040052
            ],
            [
              38.15479,
              57.043971
            ],
            [
              38.151838,
              57.046172
            ],
            [
              38.143141,
              57.05203
            ],
            [
              38.138599,
              57.059453
            ],
            [
              38.135471,
              57.063234
            ],
            [
              38.132608,
              57.065553
            ],
            [
              38.128064,
              57.068008
            ],
            [
              38.123288,
              57.070537
            ],
            [
              38.123013,
              57.070674
            ],
            [
              38.113743,
              57.071301
            ],
            [
              38.1113,
              57.070778
            ],
            [
              38.108858,
              57.070254
            ],
            [
              38.106132,
              57.069474
            ],
            [
              38.101571,
              57.066751
            ],
            [
              38.098242,
              57.062823
            ],
            [
              38.094626,
              57.059292
            ],
            [
              38.088541,
              57.055695
            ],
            [
              38.07871,
              57.055131
            ],
            [
              38.072351,
              57.054255
            ],
            [
              38.068647,
              57.05368
            ],
            [
              38.060491,
              57.053419
            ],
            [
              38.057752,
              57.059701
            ],
            [
              38.051583,
              57.060122
            ],
            [
              38.048069,
              57.060073
            ],
            [
              38.051281,
              57.052131
            ],
            [
              38.044071,
              57.044828
            ],
            [
              38.039781,
              57.042718
            ],
            [
              38.030032,
              57.039496
            ],
            [
              38.026552,
              57.040893
            ],
            [
              38.01905,
              57.031942
            ],
            [
              38.013958,
              57.03392
            ],
            [
              38.013506,
              57.04297
            ],
            [
              38.00945,
              57.044847
            ],
            [
              38.004201,
              57.04891
            ],
            [
              38.003561,
              57.058021
            ],
            [
              38.006764,
              57.060958
            ],
            [
              38.007924,
              57.064943
            ],
            [
              38.009473,
              57.069028
            ],
            [
              38.008863,
              57.072962
            ],
            [
              38.009109,
              57.080453
            ],
            [
              38.005965,
              57.082208
            ],
            [
              38.004129,
              57.077836
            ],
            [
              37.993265,
              57.072809
            ],
            [
              37.99154,
              57.076023
            ],
            [
              37.982563,
              57.081416
            ],
            [
              37.982146,
              57.084992
            ],
            [
              37.978968,
              57.086736
            ],
            [
              37.973002,
              57.087231
            ],
            [
              37.971475,
              57.083971
            ],
            [
              37.961957,
              57.077717
            ],
            [
              37.956792,
              57.079383
            ],
            [
              37.953107,
              57.080494
            ],
            [
              37.950583,
              57.081269
            ],
            [
              37.949526,
              57.077827
            ],
            [
              37.952051,
              57.077052
            ],
            [
              37.955719,
              57.075947
            ],
            [
              37.961095,
              57.074221
            ],
            [
              37.963323,
              57.073672
            ],
            [
              37.971535,
              57.071454
            ],
            [
              37.974531,
              57.06935
            ],
            [
              37.978667,
              57.066403
            ],
            [
              37.977431,
              57.055976
            ],
            [
              37.972651,
              57.055913
            ],
            [
              37.967717,
              57.055925
            ],
            [
              37.963056,
              57.056687
            ],
            [
              37.963338,
              57.052836
            ],
            [
              37.9715,
              57.047598
            ],
            [
              37.972324,
              57.043904
            ],
            [
              37.973657,
              57.033023
            ],
            [
              37.96784734912819,
              57.03227039670852
            ],
            [
              37.967393,
              57.032345
            ],
            [
              37.965690577777785,
              57.032475955555554
            ],
            [
              37.962168,
              57.033012
            ],
            [
              37.956974,
              57.03432
            ],
            [
              37.952548,
              57.035981
            ],
            [
              37.947736,
              57.038251
            ],
            [
              37.944314,
              57.040653
            ],
            [
              37.941753,
              57.04369
            ],
            [
              37.938296,
              57.050064
            ],
            [
              37.936958,
              57.05496
            ],
            [
              37.936225,
              57.059311
            ],
            [
              37.935795,
              57.061578
            ],
            [
              37.934622,
              57.066918
            ],
            [
              37.93345,
              57.072257
            ],
            [
              37.929934,
              57.071485
            ],
            [
              37.931106,
              57.066146
            ],
            [
              37.932278,
              57.060806
            ],
            [
              37.933025,
              57.056514
            ],
            [
              37.933796,
              57.052177
            ],
            [
              37.930146,
              57.045856
            ],
            [
              37.9218,
              57.045855
            ],
            [
              37.914708,
              57.045764
            ],
            [
              37.910769,
              57.045728
            ],
            [
              37.904349,
              57.047039
            ],
            [
              37.900377,
              57.048133
            ],
            [
              37.899421,
              57.044663
            ],
            [
              37.903393,
              57.043569
            ],
            [
              37.909024,
              57.034781
            ],
            [
              37.911566,
              57.034768
            ],
            [
              37.916525,
              57.035596
            ],
            [
              37.923375,
              57.032967
            ],
            [
              37.920072,
              57.022843
            ],
            [
              37.915003,
              57.021265
            ],
            [
              37.910714,
              57.019778
            ],
            [
              37.906575,
              57.017985
            ],
            [
              37.900904,
              57.016914
            ],
            [
              37.897542,
              57.016445
            ],
            [
              37.897224,
              57.016333
            ],
            [
              37.898419,
              57.012937
            ],
            [
              37.90027,
              57.013152
            ],
            [
              37.904089,
              57.013843
            ],
            [
              37.907581,
              57.014529
            ],
            [
              37.911947,
              57.016395
            ],
            [
              37.916203,
              57.017871
            ],
            [
              37.921136,
              57.019403
            ],
            [
              37.924451,
              57.020712
            ],
            [
              37.928374,
              57.022042
            ],
            [
              37.93807,
              57.018677
            ],
            [
              37.939156,
              57.01524
            ],
            [
              37.948365,
              57.020684
            ],
            [
              37.952002,
              57.022089
            ],
            [
              37.955797,
              57.02367
            ],
            [
              37.960469,
              57.025892
            ],
            [
              37.96649131778243,
              57.02846193269681
            ],
            [
              37.966804,
              57.028375
            ],
            [
              37.968292,
              57.028849
            ],
            [
              37.968533,
              57.02893
            ],
            [
              37.977497,
              57.029072
            ],
            [
              37.979462,
              57.021358
            ],
            [
              37.9778274950797,
              57.01883651765633
            ],
            [
              37.977793,
              57.01879
            ],
            [
              37.9777757006846,
              57.01875661661038
            ],
            [
              37.977739,
              57.0187
            ],
            [
              37.976632,
              57.016758
            ],
            [
              37.976646593673266,
              57.01674968874217
            ],
            [
              37.976469,
              57.016459
            ],
            [
              37.975925710164816,
              57.01518659358252
            ],
            [
              37.975373,
              57.01412
            ],
            [
              37.97532266883652,
              57.01377424679001
            ],
            [
              37.974861,
              57.012693
            ],
            [
              37.97475679116544,
              57.01009705800474
            ],
            [
              37.966235,
              56.995013
            ],
            [
              37.95890830097431,
              56.98469392839635
            ],
            [
              37.958611,
              56.984779
            ],
            [
              37.95656576142746,
              56.98393402744843
            ],
            [
              37.95402,
              56.985201
            ],
            [
              37.946028,
              56.988197
            ],
            [
              37.94255,
              56.989144
            ],
            [
              37.94243102114851,
              56.98762229248868
            ],
            [
              37.940574,
              56.987542
            ],
            [
              37.937037,
              56.987943
            ],
            [
              37.932517,
              56.989821
            ],
            [
              37.926822,
              56.989684
            ],
            [
              37.91582,
              56.993068
            ],
            [
              37.912502,
              56.994496
            ],
            [
              37.909951270723276,
              56.992360392589035
            ],
            [
              37.907876,
              56.992986
            ],
            [
              37.902706,
              56.983234
            ],
            [
              37.895599,
              56.979457
            ],
            [
              37.891678,
              56.977506
            ],
            [
              37.88911161899734,
              56.97646844439536
            ],
            [
              37.879157,
              56.977774
            ],
            [
              37.878206,
              56.977497
            ],
            [
              37.8764505182323,
              56.976077341013294
            ],
            [
              37.867536,
              56.972605
            ],
            [
              37.856171,
              56.97208
            ],
            [
              37.852462,
              56.973207
            ],
            [
              37.849107,
              56.97468
            ],
            [
              37.845898,
              56.975579
            ],
            [
              37.845334,
              56.975661
            ],
            [
              37.84525549279701,
              56.9751199532606
            ],
            [
              37.839057,
              56.975828
            ],
            [
              37.836657,
              56.976247
            ],
            [
              37.836053,
              56.975486
            ],
            [
              37.836007,
              56.97386
            ],
            [
              37.838883,
              56.971695
            ],
            [
              37.84544186933435,
              56.97135821120109
            ],
            [
              37.85225,
              56.963298
            ],
            [
              37.851853,
              56.953893
            ],
            [
              37.8504854450454,
              56.952510617922655
            ],
            [
              37.845915,
              56.94904
            ],
            [
              37.836201,
              56.942326
            ],
            [
              37.832564,
              56.942596
            ],
            [
              37.827809,
              56.943118
            ],
            [
              37.823546,
              56.94374
            ],
            [
              37.818955,
              56.944488
            ],
            [
              37.814477,
              56.945413
            ],
            [
              37.810511,
              56.946359
            ],
            [
              37.804238,
              56.954461
            ],
            [
              37.80370525290681,
              56.95439584173531
            ],
            [
              37.803069,
              56.956036
            ],
            [
              37.799228,
              56.95728
            ],
            [
              37.793358,
              56.96465
            ],
            [
              37.78716066044245,
              56.96530799410158
            ],
            [
              37.784303,
              56.973374
            ],
            [
              37.780635,
              56.97697
            ],
            [
              37.778013,
              56.978445
            ],
            [
              37.77797173535656,
              56.97837163543846
            ],
            [
              37.775556,
              56.979643
            ],
            [
              37.77209,
              56.980863
            ],
            [
              37.764974,
              56.985511
            ],
            [
              37.761648,
              56.988124
            ],
            [
              37.758265,
              56.990546
            ],
            [
              37.751276,
              56.996074
            ],
            [
              37.743078,
              57.000249
            ],
            [
              37.742587,
              56.995826
            ],
            [
              37.746337,
              56.994621
            ],
            [
              37.752962,
              56.989595
            ],
            [
              37.756994,
              56.98694
            ],
            [
              37.759989,
              56.984799
            ],
            [
              37.76498,
              56.980301
            ],
            [
              37.768614,
              56.97857
            ],
            [
              37.772517,
              56.976903
            ],
            [
              37.776238,
              56.975316
            ],
            [
              37.781153,
              56.967155
            ],
            [
              37.783238,
              56.957456
            ],
            [
              37.784983,
              56.954113
            ],
            [
              37.789465,
              56.953848
            ],
            [
              37.793515,
              56.953407
            ],
            [
              37.801005,
              56.948198
            ],
            [
              37.803594,
              56.944957
            ],
            [
              37.809071,
              56.943059
            ],
            [
              37.813929,
              56.941855
            ],
            [
              37.818031,
              56.941008
            ],
            [
              37.82506184841064,
              56.93393130995437
            ],
            [
              37.823375,
              56.925312
            ],
            [
              37.821172,
              56.922266
            ],
            [
              37.819596,
              56.918981
            ],
            [
              37.81767030171085,
              56.91491736892781
            ],
            [
              37.812449,
              56.90855
            ],
            [
              37.810201,
              56.903246
            ],
            [
              37.802558,
              56.9012
            ],
            [
              37.798197,
              56.900882
            ],
            [
              37.793226,
              56.900528
            ],
            [
              37.78946,
              56.900444
            ],
            [
              37.78794200657685,
              56.900394797747985
            ],
            [
              37.787942,
              56.900395
            ],
            [
              37.78720385568967,
              56.90037087229125
            ],
            [
              37.785079,
              56.900302
            ],
            [
              37.780723,
              56.90016
            ],
            [
              37.772219,
              56.903029
            ],
            [
              37.769081,
              56.904724
            ],
            [
              37.766078,
              56.906776
            ],
            [
              37.765971,
              56.906741
            ],
            [
              37.764678,
              56.903433
            ],
            [
              37.75845967572347,
              56.89642796620378
            ],
            [
              37.754509,
              56.89584
            ],
            [
              37.751094,
              56.895215
            ],
            [
              37.750973,
              56.895163
            ],
            [
              37.750859,
              56.894195
            ],
            [
              37.750992,
              56.893466
            ],
            [
              37.751108,
              56.891616
            ],
            [
              37.74696289117441,
              56.883189611762546
            ],
            [
              37.746380341286894,
              56.88287013578439
            ],
            [
              37.739773,
              56.884689
            ],
            [
              37.737186,
              56.887646
            ],
            [
              37.73448457104412,
              56.889931605915336
            ],
            [
              37.73326798536404,
              56.891631888806586
            ],
            [
              37.733005256245306,
              56.89201954172998
            ],
            [
              37.7305204571311,
              56.89623203766817
            ],
            [
              37.73311604348438,
              56.90623968056766
            ],
            [
              37.738743,
              56.910234
            ],
            [
              37.740084,
              56.91401
            ],
            [
              37.73761954176589,
              56.919955035685675
            ],
            [
              37.741842,
              56.927731
            ],
            [
              37.741816,
              56.931331
            ],
            [
              37.736537,
              56.931099
            ],
            [
              37.727602,
              56.925759
            ],
            [
              37.7192,
              56.92506
            ],
            [
              37.707815,
              56.926238
            ],
            [
              37.70830606680615,
              56.925238690760125
            ],
            [
              37.705655,
              56.925792
            ],
            [
              37.708644,
              56.923721
            ],
            [
              37.70900093057215,
              56.92347734443495
            ],
            [
              37.707603,
              56.922041
            ],
            [
              37.707782,
              56.921867
            ],
            [
              37.70852,
              56.92104
            ],
            [
              37.713172,
              56.92063
            ],
            [
              37.719915,
              56.921532
            ],
            [
              37.727895,
              56.922171
            ],
            [
              37.728583,
              56.911201
            ],
            [
              37.726430634402945,
              56.90973851541065
            ],
            [
              37.719642,
              56.910159
            ],
            [
              37.717005,
              56.910096
            ],
            [
              37.713302,
              56.906422
            ],
            [
              37.71328500841915,
              56.90633826090114
            ],
            [
              37.703056,
              56.906856
            ],
            [
              37.69414,
              56.907232
            ],
            [
              37.69039,
              56.907378
            ],
            [
              37.68742292589113,
              56.907482408852665
            ],
            [
              37.687112,
              56.907494
            ],
            [
              37.68711197726797,
              56.90749335087426
            ],
            [
              37.684337,
              56.907591
            ],
            [
              37.684269,
              56.903991
            ],
            [
              37.68730707720776,
              56.90388405400553
            ],
            [
              37.694001,
              56.903635
            ],
            [
              37.702904,
              56.903259
            ],
            [
              37.714563,
              56.902674
            ],
            [
              37.718208,
              56.902695
            ],
            [
              37.725707,
              56.896465
            ],
            [
              37.72604947101794,
              56.89571292776069
            ],
            [
              37.717568,
              56.888788
            ],
            [
              37.718658,
              56.885358
            ],
            [
              37.713734713429105,
              56.859239983809154
            ],
            [
              37.712933,
              56.857927
            ],
            [
              37.71351610946343,
              56.85787566887996
            ],
            [
              37.722264,
              56.823322
            ],
            [
              37.714092,
              56.821204
            ],
            [
              37.7128545334936,
              56.820807076780966
            ],
            [
              37.711644,
              56.821636
            ],
            [
              37.708845,
              56.819521
            ],
            [
              37.704272,
              56.816634
            ],
            [
              37.705194805171544,
              56.813728814383296
            ],
            [
              37.704992,
              56.813407
            ],
            [
              37.70538647547406,
              56.81312539578185
            ],
            [
              37.705446,
              56.812938
            ],
            [
              37.70564312496481,
              56.81281606657876
            ],
            [
              37.705632,
              56.812799
            ],
            [
              37.708085,
              56.811199
            ],
            [
              37.70986048884818,
              56.808371554848335
            ],
            [
              37.709495,
              56.798804
            ],
            [
              37.706754,
              56.79349
            ],
            [
              37.707883,
              56.789511
            ],
            [
              37.70472206198682,
              56.77910320409962
            ],
            [
              37.704616,
              56.779047
            ],
            [
              37.701709,
              56.777506
            ],
            [
              37.700617,
              56.776725
            ],
            [
              37.69392180471344,
              56.77249666727277
            ],
            [
              37.69127888042458,
              56.77225835950333
            ],
            [
              37.685194,
              56.774886
            ],
            [
              37.681398,
              56.778214
            ],
            [
              37.675137,
              56.781441
            ],
            [
              37.673943,
              56.782122
            ],
            [
              37.67391025405675,
              56.78206452002779
            ],
            [
              37.673394,
              56.78233
            ],
            [
              37.669949,
              56.783359
            ],
            [
              37.660123,
              56.785058
            ],
            [
              37.659262,
              56.785186
            ],
            [
              37.658732,
              56.781625
            ],
            [
              37.659594,
              56.781497
            ],
            [
              37.66317158462989,
              56.78055519392114
            ],
            [
              37.663418,
              56.780463
            ],
            [
              37.664631,
              56.780171
            ],
            [
              37.669273,
              56.779823
            ],
            [
              37.673355,
              56.778314
            ],
            [
              37.674436,
              56.766769
            ],
            [
              37.666003,
              56.766005
            ],
            [
              37.660749441127685,
              56.765361349294366
            ],
            [
              37.660733,
              56.765442
            ],
            [
              37.651877,
              56.764764
            ],
            [
              37.648125,
              56.764894
            ],
            [
              37.64252143678702,
              56.76411765303911
            ],
            [
              37.642472,
              56.764394
            ],
            [
              37.639273,
              56.763822
            ],
            [
              37.636728,
              56.763315
            ],
            [
              37.632032,
              56.763029
            ],
            [
              37.628279,
              56.763343
            ],
            [
              37.623856,
              56.763609
            ],
            [
              37.619027,
              56.763031
            ],
            [
              37.610558,
              56.771153
            ],
            [
              37.610421,
              56.776328
            ],
            [
              37.610367,
              56.778422
            ],
            [
              37.610312,
              56.780516
            ],
            [
              37.6103104088896,
              56.78051595844279
            ],
            [
              37.610299,
              56.780948
            ],
            [
              37.610092,
              56.785208
            ],
            [
              37.610068,
              56.790443
            ],
            [
              37.609946,
              56.792548
            ],
            [
              37.609825,
              56.794652
            ],
            [
              37.60982101347425,
              56.794651770392086
            ],
            [
              37.609821,
              56.794652
            ],
            [
              37.610849,
              56.802364
            ],
            [
              37.610898,
              56.805964
            ],
            [
              37.609401,
              56.805984
            ],
            [
              37.60855366248582,
              56.8059567341889
            ],
            [
              37.613252870061075,
              56.81687169818768
            ],
            [
              37.613799,
              56.81698
            ],
            [
              37.61538725925544,
              56.817295467522044
            ],
            [
              37.616384,
              56.817493
            ],
            [
              37.616383931210855,
              56.81749334709038
            ],
            [
              37.616488,
              56.817514
            ],
            [
              37.61648798057855,
              56.81751409796734
            ],
            [
              37.61758,
              56.817731
            ],
            [
              37.624844,
              56.819169
            ],
            [
              37.63066421739141,
              56.820306457486566
            ],
            [
              37.634747,
              56.820745
            ],
            [
              37.643159,
              56.822643
            ],
            [
              37.647348,
              56.822943
            ],
            [
              37.660679077748576,
              56.822159815374064
            ],
            [
              37.664339,
              56.821904
            ],
            [
              37.66433900266987,
              56.82190403820785
            ],
            [
              37.665583,
              56.821817
            ],
            [
              37.66558305154624,
              56.8218177376658
            ],
            [
              37.670861,
              56.821449
            ],
            [
              37.679627,
              56.815866
            ],
            [
              37.682235,
              56.813518
            ],
            [
              37.685831,
              56.813266
            ],
            [
              37.6881422631617,
              56.82184381298406
            ],
            [
              37.690267,
              56.823671
            ],
            [
              37.690065,
              56.824435
            ],
            [
              37.689786,
              56.825161
            ],
            [
              37.68785303711495,
              56.82441830503434
            ],
            [
              37.687944,
              56.82492
            ],
            [
              37.68609997508865,
              56.8250837584711
            ],
            [
              37.686114,
              56.825245
            ],
            [
              37.681106627854284,
              56.827720581643575
            ],
            [
              37.67754533548708,
              56.83029852630338
            ],
            [
              37.67575086056459,
              56.83585433753557
            ],
            [
              37.674463,
              56.840473
            ],
            [
              37.67396470192613,
              56.842732913556674
            ],
            [
              37.673835,
              56.843512
            ],
            [
              37.673807822668955,
              56.84344440247996
            ],
            [
              37.673669,
              56.844074
            ],
            [
              37.6734345271624,
              56.843421364942
            ],
            [
              37.672662,
              56.845636
            ],
            [
              37.670377,
              56.834911
            ],
            [
              37.66956215573676,
              56.83055951221482
            ],
            [
              37.66097873885799,
              56.8257484141379
            ],
            [
              37.657737,
              56.825975
            ],
            [
              37.650884,
              56.826453
            ],
            [
              37.646377,
              56.826506
            ],
            [
              37.636851,
              56.832152
            ],
            [
              37.63502515809276,
              56.8403574516167
            ],
            [
              37.635045,
              56.845295
            ],
            [
              37.639712,
              56.852138
            ],
            [
              37.650912,
              56.854838
            ],
            [
              37.654539,
              56.8539
            ],
            [
              37.656963,
              56.856502
            ],
            [
              37.654861,
              56.86162
            ],
            [
              37.654227091143525,
              56.86198268221881
            ],
            [
              37.653568,
              56.863303
            ],
            [
              37.651792,
              56.863847
            ],
            [
              37.650016,
              56.864392
            ],
            [
              37.643368,
              56.864959
            ],
            [
              37.639566,
              56.866365
            ],
            [
              37.640376,
              56.878003
            ],
            [
              37.644018,
              56.881257
            ],
            [
              37.646259,
              56.882412
            ],
            [
              37.645008,
              56.885788
            ],
            [
              37.644258,
              56.88551
            ],
            [
              37.640907,
              56.883707
            ],
            [
              37.637546,
              56.880227
            ],
            [
              37.629902,
              56.876347
            ],
            [
              37.626246,
              56.875711
            ],
            [
              37.620905,
              56.874679
            ],
            [
              37.612757,
              56.87506
            ],
            [
              37.606123271416905,
              56.880157558537064
            ],
            [
              37.604344,
              56.883991
            ],
            [
              37.60441,
              56.884115
            ],
            [
              37.604279218790744,
              56.88423694464107
            ],
            [
              37.604883,
              56.886663
            ],
            [
              37.604027,
              56.89016
            ],
            [
              37.6027100288684,
              56.89011928379265
            ],
            [
              37.602675,
              56.890175
            ],
            [
              37.598686,
              56.892344
            ],
            [
              37.592659088421726,
              56.90148775631327
            ],
            [
              37.592803545390304,
              56.90364478776808
            ],
            [
              37.59356667588176,
              56.906332541294496
            ],
            [
              37.596254,
              56.912083
            ],
            [
              37.59596599349304,
              56.913573447081646
            ],
            [
              37.596908,
              56.91537
            ],
            [
              37.59475204267889,
              56.9199383374731
            ],
            [
              37.59343498162335,
              56.927008513792124
            ],
            [
              37.595155,
              56.930428
            ],
            [
              37.59344160107759,
              56.9395755531948
            ],
            [
              37.59708641030263,
              56.94679540798547
            ],
            [
              37.605254,
              56.952437
            ],
            [
              37.609617,
              56.952522
            ],
            [
              37.614269,
              56.952574
            ],
            [
              37.624264,
              56.953724
            ],
            [
              37.62509425791566,
              56.954017823906575
            ],
            [
              37.625343,
              56.954051
            ],
            [
              37.629014,
              56.955405
            ],
            [
              37.636919,
              56.957233
            ],
            [
              37.642966,
              56.957098
            ],
            [
              37.642974430246376,
              56.95717576250458
            ],
            [
              37.645304,
              56.957059
            ],
            [
              37.649642,
              56.957584
            ],
            [
              37.656751,
              56.957442
            ],
            [
              37.660868,
              56.957311
            ],
            [
              37.671476,
              56.95191
            ],
            [
              37.673058,
              56.949989
            ],
            [
              37.676284,
              56.951587
            ],
            [
              37.675957,
              56.952247
            ],
            [
              37.681215,
              56.962676
            ],
            [
              37.680472,
              56.966199
            ],
            [
              37.672925,
              56.965534
            ],
            [
              37.668022,
              56.964135
            ],
            [
              37.66616801842804,
              56.96333125219842
            ],
            [
              37.655405,
              56.961247
            ],
            [
              37.655373542495006,
              56.96124653974388
            ],
            [
              37.653691,
              56.961461
            ],
            [
              37.64963,
              56.961184
            ],
            [
              37.645532480675314,
              56.96069516008964
            ],
            [
              37.64340608617376,
              56.9607721357572
            ],
            [
              37.640546,
              56.960952
            ],
            [
              37.639472391397945,
              56.960885260088695
            ],
            [
              37.638427,
              56.960911
            ],
            [
              37.63543237790438,
              56.960634116290365
            ],
            [
              37.634964,
              56.960605
            ],
            [
              37.63131925215976,
              56.962418400820546
            ],
            [
              37.626308,
              56.967201
            ],
            [
              37.626159,
              56.967635
            ],
            [
              37.62532294225801,
              56.967348456862
            ],
            [
              37.624483,
              56.969567
            ],
            [
              37.623659,
              56.973015
            ],
            [
              37.624842,
              56.980296
            ],
            [
              37.632534,
              56.983179
            ],
            [
              37.633241,
              56.98309
            ],
            [
              37.633258508395905,
              56.98322991049257
            ],
            [
              37.636846,
              56.983482
            ],
            [
              37.641126,
              56.983323
            ],
            [
              37.646009,
              56.985443
            ],
            [
              37.648792,
              56.98897
            ],
            [
              37.653837,
              56.99725
            ],
            [
              37.6568,
              56.998563
            ],
            [
              37.657598,
              56.998826
            ],
            [
              37.656473,
              57.002246
            ],
            [
              37.655675,
              57.001983
            ],
            [
              37.649688,
              56.999819
            ],
            [
              37.646282,
              56.996643
            ],
            [
              37.640172,
              56.986893
            ],
            [
              37.636479,
              56.987063
            ],
            [
              37.632982,
              56.986751
            ],
            [
              37.629244,
              56.987081
            ],
            [
              37.623753,
              56.986483
            ],
            [
              37.622127,
              56.982661
            ],
            [
              37.619997,
              56.978279
            ],
            [
              37.618417,
              56.971169
            ],
            [
              37.617381,
              56.963087
            ],
            [
              37.607114,
              56.963312
            ],
            [
              37.604698,
              56.966908
            ],
            [
              37.60441076677116,
              56.96685334691461
            ],
            [
              37.601742,
              56.969465
            ],
            [
              37.60097703913404,
              56.969617317083625
            ],
            [
              37.599404,
              56.970913
            ],
            [
              37.596682,
              56.974022
            ],
            [
              37.594478,
              56.969926
            ],
            [
              37.584408,
              56.964231
            ],
            [
              37.579411,
              56.964928
            ],
            [
              37.575445,
              56.965677
            ],
            [
              37.571248,
              56.966307
            ],
            [
              37.565764,
              56.966139
            ],
            [
              37.556468,
              56.966299
            ],
            [
              37.549572,
              56.96823
            ],
            [
              37.538095,
              56.970294
            ],
            [
              37.5335412271732,
              56.98033548643499
            ],
            [
              37.53384262089627,
              56.98400167059458
            ],
            [
              37.5361065156476,
              56.98793660773069
            ],
            [
              37.540528,
              56.992082
            ],
            [
              37.540697528180836,
              56.99217171349286
            ],
            [
              37.543549,
              56.993587
            ],
            [
              37.54530918554798,
              56.99454477157206
            ],
            [
              37.550043,
              56.996724
            ],
            [
              37.551171,
              56.997295
            ],
            [
              37.55116877553384,
              56.9972993915091
            ],
            [
              37.55124,
              56.997331
            ],
            [
              37.555457,
              57.000188
            ],
            [
              37.558868963047225,
              57.00276164529164
            ],
            [
              37.55896,
              57.002825
            ],
            [
              37.55895785585168,
              57.002828081691774
            ],
            [
              37.562307,
              57.005194
            ],
            [
              37.564777,
              57.008962
            ],
            [
              37.566891,
              57.011986
            ],
            [
              37.56821885697611,
              57.01781925240031
            ],
            [
              37.573501,
              57.026277
            ],
            [
              37.578811,
              57.034205
            ],
            [
              37.582596,
              57.035353
            ],
            [
              37.587916,
              57.037741
            ],
            [
              37.592481,
              57.040231
            ],
            [
              37.604006,
              57.03938
            ],
            [
              37.609235,
              57.037203
            ],
            [
              37.614672,
              57.034936
            ],
            [
              37.622256,
              57.029876
            ],
            [
              37.625607,
              57.028138
            ],
            [
              37.630725,
              57.027384
            ],
            [
              37.640005,
              57.025009
            ],
            [
              37.644741,
              57.023266
            ],
            [
              37.649526,
              57.021527
            ],
            [
              37.65283,
              57.023173
            ],
            [
              37.649647,
              57.034645
            ],
            [
              37.645884,
              57.035348
            ],
            [
              37.642335,
              57.035255
            ],
            [
              37.637985,
              57.034484
            ],
            [
              37.631092,
              57.030965
            ],
            [
              37.62529,
              57.03237
            ],
            [
              37.622507,
              57.034677
            ],
            [
              37.61595,
              57.038302
            ],
            [
              37.610649,
              57.040514
            ],
            [
              37.605632,
              57.049948
            ],
            [
              37.602891,
              57.052915
            ],
            [
              37.600927,
              57.049384
            ],
            [
              37.589897,
              57.046995
            ],
            [
              37.586306,
              57.046745
            ],
            [
              37.581785,
              57.03886
            ],
            [
              37.57724,
              57.037444
            ],
            [
              37.571766,
              57.037225
            ],
            [
              37.563318,
              57.044636
            ],
            [
              37.560638,
              57.046948
            ],
            [
              37.559393,
              57.043467
            ],
            [
              37.564062,
              57.036596
            ],
            [
              37.56837187639243,
              57.03306178002648
            ],
            [
              37.56584,
              57.026352
            ],
            [
              37.563861,
              57.019735
            ],
            [
              37.560018,
              57.009242
            ],
            [
              37.557077,
              57.005897
            ],
            [
              37.555067094399604,
              57.00549452014404
            ],
            [
              37.54828091629462,
              57.0044398567212
            ],
            [
              37.540129,
              57.006177
            ],
            [
              37.53586,
              57.006891
            ],
            [
              37.526045,
              57.013123
            ],
            [
              37.522448,
              57.012991
            ],
            [
              37.52110780439534,
              57.01286249866503
            ],
            [
              37.518163,
              57.022966
            ],
            [
              37.523369,
              57.027928
            ],
            [
              37.525276,
              57.031371
            ],
            [
              37.526549,
              57.035218
            ],
            [
              37.522519,
              57.041421
            ],
            [
              37.520855,
              57.046591
            ],
            [
              37.529396,
              57.054796
            ],
            [
              37.537625,
              57.057062
            ],
            [
              37.539023,
              57.05726
            ],
            [
              37.538519,
              57.060825
            ],
            [
              37.53592,
              57.060665
            ],
            [
              37.526374,
              57.058187
            ],
            [
              37.522472,
              57.056483
            ],
            [
              37.518782,
              57.053312
            ],
            [
              37.516788,
              57.050222
            ],
            [
              37.511805,
              57.041451
            ],
            [
              37.507869,
              57.038782
            ],
            [
              37.499048,
              57.035701
            ],
            [
              37.494602,
              57.035189
            ],
            [
              37.489842,
              57.03458
            ],
            [
              37.484801,
              57.034833
            ],
            [
              37.480476,
              57.034746
            ],
            [
              37.477265,
              57.032947
            ],
            [
              37.472931,
              57.0293
            ],
            [
              37.466492,
              57.03927
            ],
            [
              37.46801,
              57.040709
            ],
            [
              37.470215,
              57.04284
            ],
            [
              37.472419,
              57.044971
            ],
            [
              37.469916,
              57.047559
            ],
            [
              37.465508,
              57.043297
            ],
            [
              37.464016,
              57.041884
            ],
            [
              37.456826,
              57.034779
            ],
            [
              37.449309,
              57.035179
            ],
            [
              37.442566,
              57.030642
            ],
            [
              37.438486,
              57.029032
            ],
            [
              37.434711,
              57.025851
            ],
            [
              37.429059,
              57.016079
            ],
            [
              37.418985,
              57.010279
            ],
            [
              37.41524,
              57.010745
            ],
            [
              37.411428,
              57.011481
            ],
            [
              37.406575,
              57.013774
            ],
            [
              37.403163,
              57.014841
            ],
            [
              37.402348,
              57.011171
            ],
            [
              37.406782,
              57.002043
            ],
            [
              37.410399,
              57.002692
            ],
            [
              37.417404,
              57.000636
            ],
            [
              37.421132,
              56.994974
            ],
            [
              37.42719,
              56.992739
            ],
            [
              37.431361,
              56.993695
            ],
            [
              37.440195,
              56.991701
            ],
            [
              37.44271706913216,
              56.98982666078808
            ],
            [
              37.442478,
              56.986534
            ],
            [
              37.44633722124321,
              56.97837288213567
            ],
            [
              37.44609923414303,
              56.97706406587476
            ],
            [
              37.44563642945554,
              56.97601674739436
            ],
            [
              37.44136357110778,
              56.97205317703598
            ],
            [
              37.4358516724395,
              56.9705117551351
            ],
            [
              37.434812,
              56.970314
            ],
            [
              37.436029,
              56.964315
            ],
            [
              37.436722,
              56.953335
            ],
            [
              37.43726298549199,
              56.95279832514939
            ],
            [
              37.43723,
              56.951856
            ],
            [
              37.440738,
              56.949351
            ],
            [
              37.443815,
              56.946643
            ],
            [
              37.44855138675329,
              56.942371511828696
            ],
            [
              37.447265,
              56.941428
            ],
            [
              37.447078,
              56.937833
            ],
            [
              37.444741,
              56.928563
            ],
            [
              37.441026,
              56.925315
            ],
            [
              37.43824083218347,
              56.9235853340758
            ],
            [
              37.438226,
              56.923608
            ],
            [
              37.43725,
              56.92297
            ],
            [
              37.429163,
              56.917739
            ],
            [
              37.424117,
              56.915017
            ],
            [
              37.420408,
              56.911784
            ],
            [
              37.41087,
              56.905177
            ],
            [
              37.40239878248086,
              56.905512621949676
            ],
            [
              37.400805,
              56.906049
            ],
            [
              37.400539664729166,
              56.905089648843294
            ],
            [
              37.398469,
              56.904608
            ],
            [
              37.39853,
              56.899974
            ],
            [
              37.388028,
              56.898365
            ],
            [
              37.385165,
              56.901234
            ],
            [
              37.382523,
              56.910951
            ],
            [
              37.385305,
              56.918862
            ],
            [
              37.381695,
              56.925198
            ],
            [
              37.38067317560731,
              56.93256649776182
            ],
            [
              37.381082,
              56.935841
            ],
            [
              37.38067636801383,
              56.936668531689314
            ],
            [
              37.380923,
              56.938147
            ],
            [
              37.378798,
              56.940576
            ],
            [
              37.378773644203456,
              56.94055469092486
            ],
            [
              37.376914,
              56.944919
            ],
            [
              37.374974,
              56.950617
            ],
            [
              37.37326442011,
              56.95483452291329
            ],
            [
              37.378491,
              56.963891
            ],
            [
              37.380413,
              56.964741
            ],
            [
              37.38038436407952,
              56.96480580968119
            ],
            [
              37.382382,
              56.965771
            ],
            [
              37.386042,
              56.96774
            ],
            [
              37.387582,
              56.968858
            ],
            [
              37.389121,
              56.970085
            ],
            [
              37.39066,
              56.971313
            ],
            [
              37.388416,
              56.974127
            ],
            [
              37.386876,
              56.9729
            ],
            [
              37.380604,
              56.968902
            ],
            [
              37.37824916351218,
              56.968166005405486
            ],
            [
              37.370898,
              56.969535
            ],
            [
              37.362755,
              56.963959
            ],
            [
              37.357648,
              56.972406
            ],
            [
              37.357861,
              56.973537
            ],
            [
              37.356833,
              56.975902
            ],
            [
              37.353813,
              56.980577
            ],
            [
              37.35363775073978,
              56.980554425186305
            ],
            [
              37.353279,
              56.981826
            ],
            [
              37.350813,
              56.984949
            ],
            [
              37.349416,
              56.986408
            ],
            [
              37.346551,
              56.984229
            ],
            [
              37.346504352093824,
              56.97387506882454
            ],
            [
              37.338992,
              56.969386
            ],
            [
              37.331141,
              56.965182
            ],
            [
              37.32306951302566,
              56.958940898846656
            ],
            [
              37.316143,
              56.960289
            ],
            [
              37.310249,
              56.964945
            ],
            [
              37.306649,
              56.964945
            ],
            [
              37.306649,
              56.963676
            ],
            [
              37.298375,
              56.955107
            ],
            [
              37.294696,
              56.957575
            ],
            [
              37.294484256451774,
              56.9575763025657
            ],
            [
              37.292687,
              56.959458
            ],
            [
              37.290436,
              56.962267
            ],
            [
              37.288018,
              56.960329
            ],
            [
              37.285599,
              56.958392
            ],
            [
              37.280671,
              56.953696
            ],
            [
              37.280347126905504,
              56.95338095037122
            ],
            [
              37.280332,
              56.953387
            ],
            [
              37.270913,
              56.949708
            ],
            [
              37.27078,
              56.949417
            ],
            [
              37.270575,
              56.948302
            ],
            [
              37.259938,
              56.942956
            ],
            [
              37.25466,
              56.948294
            ],
            [
              37.251123,
              56.947624
            ],
            [
              37.251314,
              56.946612
            ],
            [
              37.249684,
              56.937367
            ],
            [
              37.245509,
              56.933692
            ],
            [
              37.234234,
              56.937708
            ],
            [
              37.233885,
              56.941291
            ],
            [
              37.229912,
              56.941391
            ],
            [
              37.225636,
              56.941416
            ],
            [
              37.217037,
              56.941977
            ],
            [
              37.211671,
              56.94459
            ],
            [
              37.203539,
              56.950747
            ],
            [
              37.20322546113704,
              56.9507949144224
            ],
            [
              37.200612,
              56.953282
            ],
            [
              37.201548,
              56.956758
            ],
            [
              37.198179,
              56.957665
            ],
            [
              37.192374,
              56.960492
            ],
            [
              37.191808795769475,
              56.96126649846882
            ],
            [
              37.195663,
              56.970704
            ],
            [
              37.198857,
              56.972399
            ],
            [
              37.202916,
              56.974603
            ],
            [
              37.208166,
              56.9773
            ],
            [
              37.21875,
              56.974008
            ],
            [
              37.223295,
              56.972925
            ],
            [
              37.225843,
              56.983515
            ],
            [
              37.23026,
              56.984862
            ],
            [
              37.237138,
              56.986959
            ],
            [
              37.243892,
              56.989023
            ],
            [
              37.247794,
              56.989928
            ],
            [
              37.253222,
              56.992034
            ],
            [
              37.257456,
              56.993615
            ],
            [
              37.263624,
              56.996449
            ],
            [
              37.26722,
              56.997981
            ],
            [
              37.275515,
              57.000278
            ],
            [
              37.282083,
              56.999878
            ],
            [
              37.287251,
              56.999932
            ],
            [
              37.291815,
              56.999628
            ],
            [
              37.30117,
              56.999704
            ],
            [
              37.302335,
              57.000486
            ],
            [
              37.300096,
              57.004557
            ],
            [
              37.295434,
              57.007531
            ],
            [
              37.291128,
              57.013999
            ],
            [
              37.289355,
              57.017132
            ],
            [
              37.28835,
              57.017373
            ],
            [
              37.28475,
              57.017437
            ],
            [
              37.281618,
              57.011478
            ],
            [
              37.274795,
              57.006996
            ],
            [
              37.263161,
              57.007984
            ],
            [
              37.257922,
              57.005955
            ],
            [
              37.25151,
              57.006191
            ],
            [
              37.249045,
              57.003567
            ],
            [
              37.246576,
              56.993315
            ],
            [
              37.24309,
              56.992533
            ],
            [
              37.236088,
              56.990403
            ],
            [
              37.22921,
              56.988305
            ],
            [
              37.224793,
              56.986958
            ],
            [
              37.219355,
              56.985372
            ],
            [
              37.215645,
              56.984035
            ],
            [
              37.206463,
              56.980471
            ],
            [
              37.201355,
              56.977847
            ],
            [
              37.19714,
              56.975563
            ],
            [
              37.193975,
              56.973884
            ],
            [
              37.18498,
              56.980144
            ],
            [
              37.187932,
              56.986082
            ],
            [
              37.190212,
              56.990679
            ],
            [
              37.191816,
              56.993909
            ],
            [
              37.195268,
              56.997124
            ],
            [
              37.200847,
              57.000599
            ],
            [
              37.209327,
              57.002791
            ],
            [
              37.212982,
              57.002104
            ],
            [
              37.218756,
              57.003457
            ],
            [
              37.219918,
              57.012631
            ],
            [
              37.223801,
              57.015088
            ],
            [
              37.227649,
              57.017523
            ],
            [
              37.232729,
              57.020436
            ],
            [
              37.236089,
              57.02193
            ],
            [
              37.240564,
              57.025825
            ],
            [
              37.246835,
              57.028157
            ],
            [
              37.248505,
              57.029084
            ],
            [
              37.251321,
              57.030653
            ],
            [
              37.249568,
              57.033798
            ],
            [
              37.246752,
              57.032229
            ],
            [
              37.245087,
              57.031303
            ],
            [
              37.236621,
              57.026412
            ],
            [
              37.232485,
              57.024286
            ],
            [
              37.228937,
              57.022492
            ],
            [
              37.225797,
              57.02061
            ],
            [
              37.221876,
              57.01813
            ],
            [
              37.217993,
              57.015673
            ],
            [
              37.214329,
              57.01339
            ],
            [
              37.208767,
              57.009894
            ],
            [
              37.201786,
              57.005439
            ],
            [
              37.196069,
              57.001851
            ],
            [
              37.190672,
              56.998515
            ],
            [
              37.18861,
              56.995547
            ],
            [
              37.177903,
              56.997922
            ],
            [
              37.174957,
              57.002505
            ],
            [
              37.172353,
              57.005505
            ],
            [
              37.164837,
              57.010564
            ],
            [
              37.16639,
              57.019735
            ],
            [
              37.171127,
              57.020841
            ],
            [
              37.176853,
              57.02192
            ],
            [
              37.181067,
              57.021925
            ],
            [
              37.184628,
              57.022477
            ],
            [
              37.193641,
              57.025402
            ],
            [
              37.195049,
              57.028715
            ],
            [
              37.1946,
              57.028906
            ],
            [
              37.191371,
              57.0297
            ],
            [
              37.186505,
              57.027977
            ],
            [
              37.178454,
              57.025623
            ],
            [
              37.173956,
              57.025102
            ],
            [
              37.170858,
              57.03616
            ],
            [
              37.169417,
              57.039459
            ],
            [
              37.168381,
              57.039006
            ],
            [
              37.164739,
              57.036904
            ],
            [
              37.16126,
              57.034852
            ],
            [
              37.152127,
              57.033285
            ],
            [
              37.148146,
              57.036527
            ],
            [
              37.14575,
              57.03881
            ],
            [
              37.143353,
              57.041093
            ],
            [
              37.14087,
              57.038486
            ],
            [
              37.143267,
              57.036203
            ],
            [
              37.145663,
              57.03392
            ],
            [
              37.148983,
              57.030954
            ],
            [
              37.146796,
              57.019921
            ],
            [
              37.141326,
              57.0196
            ],
            [
              37.137618,
              57.00963
            ],
            [
              37.136093,
              57.006457
            ],
            [
              37.127025,
              56.999258
            ],
            [
              37.124404,
              56.99963
            ],
            [
              37.121783,
              57.000001
            ],
            [
              37.121278,
              56.996437
            ],
            [
              37.123899,
              56.996066
            ],
            [
              37.126823,
              56.995528
            ],
            [
              37.131625,
              56.995193
            ],
            [
              37.140025,
              56.994668
            ],
            [
              37.144672,
              56.993309
            ],
            [
              37.149765,
              56.992698
            ],
            [
              37.149103,
              56.981636
            ],
            [
              37.143697,
              56.980034
            ],
            [
              37.140128,
              56.978897
            ],
            [
              37.133871,
              56.97598
            ],
            [
              37.127399,
              56.979188
            ],
            [
              37.119171,
              56.982075
            ],
            [
              37.111204,
              56.984869
            ],
            [
              37.103141,
              56.987694
            ],
            [
              37.099703,
              56.995001
            ],
            [
              37.101158,
              57.001149
            ],
            [
              37.101676,
              57.00516
            ],
            [
              37.102289,
              57.008861
            ],
            [
              37.098319,
              57.007873
            ],
            [
              37.088448,
              57.005785
            ],
            [
              37.083974,
              57.001642
            ],
            [
              37.081279,
              56.998847
            ],
            [
              37.07797,
              56.993249
            ],
            [
              37.069139,
              56.995478
            ],
            [
              37.070419,
              57.004749
            ],
            [
              37.069727,
              57.008282
            ],
            [
              37.068995,
              57.008139
            ],
            [
              37.0646,
              57.0071
            ],
            [
              37.056086,
              57.007775
            ],
            [
              37.051879,
              57.01002
            ],
            [
              37.050184,
              57.006843
            ],
            [
              37.054391,
              57.004599
            ],
            [
              37.057986,
              56.993936
            ],
            [
              37.049185,
              56.989595
            ],
            [
              37.045429,
              56.988688
            ],
            [
              37.040841,
              56.98838
            ],
            [
              37.039117,
              56.98522
            ],
            [
              37.039452,
              56.985037
            ],
            [
              37.04282,
              56.983999
            ],
            [
              37.046394,
              56.985219
            ],
            [
              37.051784,
              56.985811
            ],
            [
              37.055285,
              56.985835
            ],
            [
              37.059319,
              56.986724
            ],
            [
              37.063689,
              56.977204
            ],
            [
              37.060538,
              56.973132
            ],
            [
              37.057054,
              56.971046
            ],
            [
              37.051819,
              56.969736
            ],
            [
              37.048259,
              56.969102
            ],
            [
              37.048889,
              56.965558
            ],
            [
              37.050669,
              56.965875
            ],
            [
              37.052449,
              56.966191
            ],
            [
              37.056686,
              56.966969
            ],
            [
              37.060177,
              56.968474
            ],
            [
              37.064786,
              56.971767
            ],
            [
              37.068661,
              56.976186
            ],
            [
              37.076429,
              56.980289
            ],
            [
              37.07957,
              56.982466
            ],
            [
              37.089724,
              56.985415
            ],
            [
              37.093969,
              56.985164
            ],
            [
              37.098549,
              56.984389
            ],
            [
              37.110013,
              56.981472
            ],
            [
              37.11798,
              56.978678
            ],
            [
              37.126207,
              56.975791
            ],
            [
              37.12934,
              56.966978
            ],
            [
              37.123086,
              56.956915
            ],
            [
              37.127329,
              56.957232
            ],
            [
              37.132213,
              56.956235
            ],
            [
              37.13328,
              56.945315
            ],
            [
              37.123409,
              56.942755
            ],
            [
              37.116237,
              56.941205
            ],
            [
              37.109177,
              56.939709
            ],
            [
              37.10201,
              56.938186
            ],
            [
              37.09704,
              56.93611
            ],
            [
              37.093406,
              56.93447
            ],
            [
              37.083957,
              56.931802
            ],
            [
              37.078924,
              56.929832
            ],
            [
              37.074012,
              56.928094
            ],
            [
              37.06463,
              56.924959
            ],
            [
              37.057024,
              56.919533
            ],
            [
              37.053823,
              56.917975
            ],
            [
              37.044885,
              56.919686
            ],
            [
              37.041348,
              56.920359
            ],
            [
              37.036149,
              56.920768
            ],
            [
              37.035037,
              56.917344
            ],
            [
              37.032339,
              56.906063
            ],
            [
              37.034286,
              56.903035
            ],
            [
              37.038298,
              56.905615
            ],
            [
              37.042311,
              56.908194
            ],
            [
              37.046093,
              56.910767
            ],
            [
              37.055821,
              56.914786
            ],
            [
              37.065448,
              56.921385
            ],
            [
              37.072139,
              56.923626
            ],
            [
              37.083306,
              56.919744
            ],
            [
              37.081942,
              56.912269
            ],
            [
              37.08714,
              56.912856
            ],
            [
              37.094067,
              56.916223
            ],
            [
              37.097701,
              56.916973
            ],
            [
              37.10444,
              56.917742
            ],
            [
              37.109436,
              56.920279
            ],
            [
              37.103962,
              56.922033
            ],
            [
              37.100397,
              56.92507
            ],
            [
              37.102972,
              56.934717
            ],
            [
              37.109924,
              56.936187
            ],
            [
              37.116983,
              56.937683
            ],
            [
              37.124169,
              56.939236
            ],
            [
              37.132851,
              56.941207
            ],
            [
              37.136496,
              56.942927
            ],
            [
              37.140729,
              56.94441
            ],
            [
              37.145066,
              56.945376
            ],
            [
              37.14951922288427,
              56.94728460289602
            ],
            [
              37.149534,
              56.947251
            ],
            [
              37.153064896902265,
              56.94880424021824
            ],
            [
              37.153139,
              56.948836
            ],
            [
              37.1577437073694,
              56.95077712731315
            ],
            [
              37.164483,
              56.951343
            ],
            [
              37.168674,
              56.951447
            ],
            [
              37.16933790962029,
              56.95158794467469
            ],
            [
              37.16961155708714,
              56.9516017510058
            ],
            [
              37.169642,
              56.951495
            ],
            [
              37.170102,
              56.951541
            ],
            [
              37.170885,
              56.951666
            ],
            [
              37.176173,
              56.953039
            ],
            [
              37.183369584961305,
              56.95338995269284
            ],
            [
              37.191022,
              56.950511
            ],
            [
              37.19509611056139,
              56.94855423012939
            ],
            [
              37.195096,
              56.948554
            ],
            [
              37.197136,
              56.947574
            ],
            [
              37.199175,
              56.946595
            ],
            [
              37.202347,
              56.945072
            ],
            [
              37.20623,
              56.943208
            ],
            [
              37.20780436568401,
              56.94244597903234
            ],
            [
              37.207679,
              56.941256
            ],
            [
              37.207837,
              56.941069
            ],
            [
              37.215459,
              56.938741
            ],
            [
              37.21918628796987,
              56.93687638587244
            ],
            [
              37.222579,
              56.925866
            ],
            [
              37.21659254755963,
              56.92195112261764
            ],
            [
              37.212032,
              56.920196
            ],
            [
              37.206972,
              56.920469
            ],
            [
              37.201912,
              56.920741
            ],
            [
              37.20190933034333,
              56.92069154254589
            ],
            [
              37.201236,
              56.920721
            ],
            [
              37.195164,
              56.919434
            ],
            [
              37.188808,
              56.919913
            ],
            [
              37.185532,
              56.921217
            ],
            [
              37.181297,
              56.922935
            ],
            [
              37.177842,
              56.924121
            ],
            [
              37.172414,
              56.926525
            ],
            [
              37.17213316177367,
              56.92616664991741
            ],
            [
              37.170258,
              56.926896
            ],
            [
              37.166882,
              56.925425
            ],
            [
              37.162873,
              56.92477
            ],
            [
              37.160775,
              56.924705
            ],
            [
              37.159959,
              56.924621
            ],
            [
              37.15998468565819,
              56.924372404481176
            ],
            [
              37.156088,
              56.923836
            ],
            [
              37.151375,
              56.922188
            ],
            [
              37.151217,
              56.922143
            ],
            [
              37.150784,
              56.918977
            ],
            [
              37.151094,
              56.91883
            ],
            [
              37.152199,
              56.91868
            ],
            [
              37.152357,
              56.918724
            ],
            [
              37.157227,
              56.920421
            ],
            [
              37.163057,
              56.921175
            ],
            [
              37.167453,
              56.92187
            ],
            [
              37.176655,
              56.920722
            ],
            [
              37.180129,
              56.91953
            ],
            [
              37.184177,
              56.917882
            ],
            [
              37.187477,
              56.916568
            ],
            [
              37.19485,
              56.915847
            ],
            [
              37.20171808452733,
              56.91714856593411
            ],
            [
              37.201718,
              56.917147
            ],
            [
              37.20665687583053,
              56.91688053496013
            ],
            [
              37.206665,
              56.91688
            ],
            [
              37.211838,
              56.916601
            ],
            [
              37.216599,
              56.91759
            ],
            [
              37.216796688317,
              56.917636033689874
            ],
            [
              37.218198,
              56.915603
            ],
            [
              37.219147,
              56.916257
            ],
            [
              37.222371,
              56.919135
            ],
            [
              37.225401,
              56.923631
            ],
            [
              37.235781,
              56.928751
            ],
            [
              37.240164,
              56.926631
            ],
            [
              37.24639,
              56.923573
            ],
            [
              37.255061,
              56.919341
            ],
            [
              37.258635,
              56.917535
            ],
            [
              37.26288000148905,
              56.915422002990155
            ],
            [
              37.26288,
              56.915422
            ],
            [
              37.268793,
              56.912478
            ],
            [
              37.274706,
              56.909535
            ],
            [
              37.281225,
              56.906322
            ],
            [
              37.286542,
              56.903732
            ],
            [
              37.291897,
              56.901123
            ],
            [
              37.299729,
              56.897319
            ],
            [
              37.302978,
              56.895749
            ],
            [
              37.306296,
              56.894118
            ],
            [
              37.31112,
              56.892439
            ],
            [
              37.316405,
              56.886764
            ],
            [
              37.316403,
              56.878576
            ],
            [
              37.31552825082362,
              56.878015041343964
            ],
            [
              37.314072,
              56.877284
            ],
            [
              37.30776217686541,
              56.873061532695274
            ],
            [
              37.301707,
              56.874794
            ],
            [
              37.297593,
              56.874932
            ],
            [
              37.29399,
              56.874967
            ],
            [
              37.290391,
              56.874913
            ],
            [
              37.285929,
              56.874812
            ],
            [
              37.280068,
              56.875018
            ],
            [
              37.27105,
              56.871499
            ],
            [
              37.27004053629841,
              56.871041209140856
            ],
            [
              37.269682,
              56.870994
            ],
            [
              37.266047,
              56.870405
            ],
            [
              37.26619278886647,
              56.868509004690885
            ],
            [
              37.2646,
              56.866325
            ],
            [
              37.26635330030411,
              56.866421541222664
            ],
            [
              37.266441,
              56.865281
            ],
            [
              37.272934194485764,
              56.8671595832786
            ],
            [
              37.277363,
              56.867915
            ],
            [
              37.286383332908756,
              56.86816130610896
            ],
            [
              37.28818259922873,
              56.86801940503993
            ],
            [
              37.291139,
              56.857363
            ],
            [
              37.288657,
              56.854698
            ],
            [
              37.28840452734573,
              56.85143802382512
            ],
            [
              37.288224,
              56.851445
            ],
            [
              37.2881986070419,
              56.85078828167477
            ],
            [
              37.28804270150076,
              56.84676605791467
            ],
            [
              37.288042,
              56.846757
            ],
            [
              37.28804222877053,
              56.84675386189688
            ],
            [
              37.287997,
              56.845587
            ],
            [
              37.287773,
              56.839807
            ],
            [
              37.28861142180078,
              56.83894609743885
            ],
            [
              37.288668,
              56.83817
            ],
            [
              37.28354168560994,
              56.830622971779746
            ],
            [
              37.279431,
              56.830341
            ],
            [
              37.2781661333096,
              56.83003644652005
            ],
            [
              37.278166,
              56.830037
            ],
            [
              37.276386,
              56.829608
            ],
            [
              37.274605,
              56.829179
            ],
            [
              37.26791,
              56.82758
            ],
            [
              37.264102,
              56.826506
            ],
            [
              37.26385788057678,
              56.82630962054887
            ],
            [
              37.263454,
              56.826183
            ],
            [
              37.263104614830766,
              56.825840021919355
            ],
            [
              37.262798,
              56.825729
            ],
            [
              37.26146092063307,
              56.824381409872636
            ],
            [
              37.261039,
              56.824042
            ],
            [
              37.260681554547105,
              56.8235959172018
            ],
            [
              37.25613752362585,
              56.819016165723035
            ],
            [
              37.255995,
              56.819026
            ],
            [
              37.25441,
              56.819039
            ],
            [
              37.254195791814354,
              56.81852167350037
            ],
            [
              37.246635,
              56.819085
            ],
            [
              37.24373291404829,
              56.819066853664175
            ],
            [
              37.238789,
              56.828344
            ],
            [
              37.235813,
              56.83037
            ],
            [
              37.23556425885381,
              56.830041048846525
            ],
            [
              37.235563,
              56.830042
            ],
            [
              37.23342920818263,
              56.8272175216908
            ],
            [
              37.233329,
              56.827085
            ],
            [
              37.227789861914964,
              56.8231999774933
            ],
            [
              37.220271644326964,
              56.82187853753802
            ],
            [
              37.2186691136083,
              56.82253950929198
            ],
            [
              37.213758,
              56.827567
            ],
            [
              37.21149175216555,
              56.826116832898215
            ],
            [
              37.210117,
              56.826334
            ],
            [
              37.201411,
              56.820708
            ],
            [
              37.198861,
              56.820299
            ],
            [
              37.198840331552105,
              56.820232467604974
            ],
            [
              37.197362,
              56.819959
            ],
            [
              37.193997933648525,
              56.818947519803615
            ],
            [
              37.182824,
              56.818313
            ],
            [
              37.177665,
              56.818832
            ],
            [
              37.156601,
              56.82177
            ],
            [
              37.155698024263764,
              56.82189583437149
            ],
            [
              37.155661,
              56.821901
            ],
            [
              37.15566099916518,
              56.8219009940118
            ],
            [
              37.154206226961925,
              56.82210372409468
            ],
            [
              37.137452,
              56.824441
            ],
            [
              37.137451659969194,
              56.824438560261825
            ],
            [
              37.135633,
              56.824692
            ],
            [
              37.128335,
              56.831325
            ],
            [
              37.12776843420271,
              56.83874166963677
            ],
            [
              37.12999291320377,
              56.84326793895598
            ],
            [
              37.13419,
              56.845639
            ],
            [
              37.137678,
              56.846341
            ],
            [
              37.141613,
              56.846649
            ],
            [
              37.145221,
              56.847516
            ],
            [
              37.149541,
              56.850967
            ],
            [
              37.152763,
              56.854787
            ],
            [
              37.156666,
              56.85791
            ],
            [
              37.15813307642958,
              56.85919247202297
            ],
            [
              37.162375,
              56.862563
            ],
            [
              37.170466,
              56.87021
            ],
            [
              37.174253,
              56.873519
            ],
            [
              37.176356,
              56.876372
            ],
            [
              37.17907124254178,
              56.87939786766657
            ],
            [
              37.183833589772156,
              56.88195702596498
            ],
            [
              37.192759,
              56.88298
            ],
            [
              37.197393,
              56.881686
            ],
            [
              37.202573,
              56.883173
            ],
            [
              37.201644,
              56.889495
            ],
            [
              37.19979,
              56.89053
            ],
            [
              37.197347,
              56.89077
            ],
            [
              37.194903,
              56.891009
            ],
            [
              37.18453,
              56.889466
            ],
            [
              37.181117,
              56.887028
            ],
            [
              37.173044,
              56.884729
            ],
            [
              37.168531,
              56.88443
            ],
            [
              37.163934,
              56.884124
            ],
            [
              37.159095,
              56.883576
            ],
            [
              37.154332,
              56.883108
            ],
            [
              37.142955,
              56.884516
            ],
            [
              37.14234755887781,
              56.88461427391404
            ],
            [
              37.141968,
              56.884785
            ],
            [
              37.14153999884928,
              56.88474492375305
            ],
            [
              37.141385,
              56.88477
            ],
            [
              37.14069932605888,
              56.88466620665078
            ],
            [
              37.136767,
              56.884298
            ],
            [
              37.13670140779986,
              56.88428684339735
            ],
            [
              37.134155,
              56.884146
            ],
            [
              37.130682,
              56.883263
            ],
            [
              37.126388,
              56.881446
            ],
            [
              37.12201,
              56.879347
            ],
            [
              37.112676,
              56.878887
            ],
            [
              37.108913,
              56.881233
            ],
            [
              37.106464,
              56.883297
            ],
            [
              37.105963,
              56.883648
            ],
            [
              37.10593419516828,
              56.883606864189474
            ],
            [
              37.105668,
              56.883819
            ],
            [
              37.098577,
              56.886372
            ],
            [
              37.092049,
              56.888673
            ],
            [
              37.088799692554545,
              56.88802245642065
            ],
            [
              37.087742,
              56.888201
            ],
            [
              37.084442258244856,
              56.88715005485196
            ],
            [
              37.084427,
              56.887147
            ],
            [
              37.084239903720935,
              56.88708560632208
            ],
            [
              37.08307,
              56.886713
            ],
            [
              37.08247168325198,
              56.8865053833561
            ],
            [
              37.079036,
              56.885378
            ],
            [
              37.079046044145024,
              56.88531668239304
            ],
            [
              37.078168,
              56.885012
            ],
            [
              37.075244,
              56.881201
            ],
            [
              37.076393,
              56.877507
            ],
            [
              37.077226,
              56.875913
            ],
            [
              37.080417,
              56.87758
            ],
            [
              37.08872899174559,
              56.883712581386874
            ],
            [
              37.097381,
              56.882977
            ],
            [
              37.106587,
              56.878486
            ],
            [
              37.109511,
              56.876115
            ],
            [
              37.111553261024284,
              56.8755122548689
            ],
            [
              37.111709,
              56.875419
            ],
            [
              37.11224979845659,
              56.87530668146719
            ],
            [
              37.114651,
              56.874598
            ],
            [
              37.118965,
              56.873912
            ],
            [
              37.123566,
              56.876101
            ],
            [
              37.12936572891636,
              56.8788031357018
            ],
            [
              37.12985199141806,
              56.87900942135292
            ],
            [
              37.133162192063025,
              56.880075825207754
            ],
            [
              37.136955,
              56.880703
            ],
            [
              37.14050357885658,
              56.88057387734858
            ],
            [
              37.144494,
              56.879711
            ],
            [
              37.14567389965666,
              56.87969131643632
            ],
            [
              37.14602,
              56.879606
            ],
            [
              37.150848,
              56.879605
            ],
            [
              37.156136,
              56.879528
            ],
            [
              37.159656,
              56.871031
            ],
            [
              37.155935,
              56.867219
            ],
            [
              37.152146,
              56.859318
            ],
            [
              37.149509,
              56.856327
            ],
            [
              37.146784,
              56.853282
            ],
            [
              37.138383,
              56.850156
            ],
            [
              37.134453,
              56.84952
            ],
            [
              37.130716,
              56.848321
            ],
            [
              37.127188,
              56.846428
            ],
            [
              37.115960760786336,
              56.84788737668821
            ],
            [
              37.114625,
              56.848962
            ],
            [
              37.111589,
              56.851546
            ],
            [
              37.102617,
              56.852846
            ],
            [
              37.099142,
              56.860221
            ],
            [
              37.10021,
              56.863704
            ],
            [
              37.099533,
              56.864815
            ],
            [
              37.096459,
              56.862941
            ],
            [
              37.09232,
              56.858849
            ],
            [
              37.09314893577908,
              56.857050956735094
            ],
            [
              37.092228,
              56.856257
            ],
            [
              37.093611,
              56.854654
            ],
            [
              37.094993,
              56.853051
            ],
            [
              37.099294,
              56.849701
            ],
            [
              37.10346,
              56.848791
            ],
            [
              37.108934,
              56.847994
            ],
            [
              37.112836,
              56.845606
            ],
            [
              37.115061,
              56.84264
            ],
            [
              37.107992,
              56.833211
            ],
            [
              37.107132023891396,
              56.83306761352247
            ],
            [
              37.10639367327916,
              56.833272126478825
            ],
            [
              37.106129,
              56.833512
            ],
            [
              37.101802,
              56.834234
            ],
            [
              37.097409,
              56.835036
            ],
            [
              37.093069,
              56.835652
            ],
            [
              37.08974,
              56.838167
            ],
            [
              37.086759,
              56.840367
            ],
            [
              37.083026,
              56.843568
            ],
            [
              37.07971,
              56.845417
            ],
            [
              37.075204,
              56.849896
            ],
            [
              37.069794,
              56.852442
            ],
            [
              37.067449,
              56.853111
            ],
            [
              37.067422931729915,
              56.85301965551515
            ],
            [
              37.065283,
              56.853541
            ],
            [
              37.060841,
              56.85403
            ],
            [
              37.053951,
              56.853413
            ],
            [
              37.053244,
              56.849883
            ],
            [
              37.055024,
              56.849527
            ],
            [
              37.056908,
              56.849478
            ],
            [
              37.062007,
              56.850292
            ],
            [
              37.066523848631036,
              56.8496310700494
            ],
            [
              37.06667343664575,
              56.84958839440682
            ],
            [
              37.071633,
              56.847771
            ],
            [
              37.069328,
              56.836131
            ],
            [
              37.064669,
              56.837375
            ],
            [
              37.059902,
              56.837564
            ],
            [
              37.0506,
              56.837231
            ],
            [
              37.05173770534249,
              56.836009782244076
            ],
            [
              37.051423,
              56.836024
            ],
            [
              37.05225739613445,
              56.83526653185762
            ],
            [
              37.052046,
              56.835203
            ],
            [
              37.05308833563303,
              56.83382301632005
            ],
            [
              37.052396,
              56.8322
            ],
            [
              37.05263,
              56.831683
            ],
            [
              37.05514097903338,
              56.83110545174347
            ],
            [
              37.057933,
              56.827409
            ],
            [
              37.059832,
              56.824351
            ],
            [
              37.060225,
              56.824153
            ],
            [
              37.060761,
              56.824201
            ],
            [
              37.062812,
              56.825832
            ],
            [
              37.072892,
              56.831561
            ],
            [
              37.078967,
              56.829088
            ],
            [
              37.0800665808501,
              56.82885060791844
            ],
            [
              37.081173,
              56.82842
            ],
            [
              37.08225765081481,
              56.82837757070638
            ],
            [
              37.083988,
              56.828004
            ],
            [
              37.08601951844485,
              56.82823041423533
            ],
            [
              37.086388,
              56.828216
            ],
            [
              37.08831818075882,
              56.828428224800675
            ],
            [
              37.09736,
              56.822597
            ],
            [
              37.105204,
              56.824238
            ],
            [
              37.11675636807913,
              56.82622789115186
            ],
            [
              37.11677425072836,
              56.82622463887054
            ],
            [
              37.126873,
              56.824257
            ],
            [
              37.130688,
              56.822706
            ],
            [
              37.133544260529035,
              56.82166729876859
            ],
            [
              37.1335,
              56.821441
            ],
            [
              37.134942,
              56.821159
            ],
            [
              37.136955409095854,
              56.82087793528335
            ],
            [
              37.136955,
              56.820875
            ],
            [
              37.157061,
              56.818071
            ],
            [
              37.177168,
              56.815266
            ],
            [
              37.182462,
              56.814731
            ],
            [
              37.191011,
              56.81441
            ],
            [
              37.19349754379297,
              56.81103325850471
            ],
            [
              37.184921,
              56.807593
            ],
            [
              37.183693,
              56.807234
            ],
            [
              37.18369636871597,
              56.80722248438932
            ],
            [
              37.181748,
              56.806633
            ],
            [
              37.182463,
              56.803104
            ],
            [
              37.184164,
              56.803449
            ],
            [
              37.185932,
              56.804138
            ],
            [
              37.190331,
              56.80432
            ],
            [
              37.201584,
              56.801564
            ],
            [
              37.199211,
              56.791365
            ],
            [
              37.19911398155216,
              56.79132846020312
            ],
            [
              37.198644,
              56.791208
            ],
            [
              37.19752359924499,
              56.790729478795264
            ],
            [
              37.195908,
              56.790121
            ],
            [
              37.19561247521686,
              56.78991324106226
            ],
            [
              37.195153,
              56.789717
            ],
            [
              37.19077958080179,
              56.7874761902916
            ],
            [
              37.183018,
              56.78585
            ],
            [
              37.18189123683196,
              56.77516868083799
            ],
            [
              37.177448,
              56.774759
            ],
            [
              37.168322,
              56.771023
            ],
            [
              37.164927,
              56.769826
            ],
            [
              37.163775971086345,
              56.767395623764166
            ],
            [
              37.16064024571302,
              56.76736608367358
            ],
            [
              37.15175479651364,
              56.769650776905046
            ],
            [
              37.151103,
              56.771155
            ],
            [
              37.145261,
              56.773015
            ],
            [
              37.141705,
              56.772455
            ],
            [
              37.13796513525641,
              56.770717242684455
            ],
            [
              37.135247,
              56.773954
            ],
            [
              37.13427001343498,
              56.774161090802274
            ],
            [
              37.13406851671761,
              56.77436706656953
            ],
            [
              37.133796,
              56.775402
            ],
            [
              37.130462,
              56.776224
            ],
            [
              37.1301456112109,
              56.775941708029485
            ],
            [
              37.126521,
              56.776835
            ],
            [
              37.122943,
              56.777239
            ],
            [
              37.12076,
              56.776895
            ],
            [
              37.11992341601896,
              56.77614242868736
            ],
            [
              37.112343,
              56.782774
            ],
            [
              37.112406,
              56.783458
            ],
            [
              37.110611,
              56.784738
            ],
            [
              37.109362,
              56.78478
            ],
            [
              37.107294325921764,
              56.78387947304654
            ],
            [
              37.105522,
              56.783777
            ],
            [
              37.09736,
              56.784066
            ],
            [
              37.09776012423828,
              56.783343174619375
            ],
            [
              37.097757,
              56.783343
            ],
            [
              37.09813130657961,
              56.78267263284386
            ],
            [
              37.099267,
              56.780621
            ],
            [
              37.09984012622005,
              56.77961790675662
            ],
            [
              37.09991974889671,
              56.779475103907636
            ],
            [
              37.098468,
              56.778384
            ],
            [
              37.098887,
              56.777678
            ],
            [
              37.102540710536815,
              56.77390449567509
            ],
            [
              37.102353,
              56.771709
            ],
            [
              37.10489997632447,
              56.76416760394646
            ],
            [
              37.104961,
              56.763712
            ],
            [
              37.10572192533543,
              56.76220886629002
            ],
            [
              37.105542,
              56.758805
            ],
            [
              37.105701,
              56.758447
            ],
            [
              37.11438287658766,
              56.75151354317999
            ],
            [
              37.115135,
              56.750299
            ],
            [
              37.118581911482984,
              56.74193774736457
            ],
            [
              37.11881,
              56.739934
            ],
            [
              37.11961316749187,
              56.738032548667334
            ],
            [
              37.118935,
              56.736482
            ],
            [
              37.119975,
              56.73493
            ],
            [
              37.124745,
              56.726133
            ],
            [
              37.127203,
              56.715536
            ],
            [
              37.123736,
              56.710625
            ],
            [
              37.119984,
              56.707652
            ],
            [
              37.115803,
              56.705814
            ],
            [
              37.112509,
              56.704441
            ],
            [
              37.107797,
              56.702492
            ],
            [
              37.098601,
              56.708663
            ],
            [
              37.093694,
              56.715227
            ],
            [
              37.094511,
              56.721997
            ],
            [
              37.091964,
              56.724542
            ],
            [
              37.089806,
              56.722383
            ],
            [
              37.087648,
              56.720223
            ],
            [
              37.087088,
              56.718794
            ],
            [
              37.090834,
              56.713041
            ],
            [
              37.095718,
              56.706508
            ],
            [
              37.096495,
              56.697809
            ],
            [
              37.089906,
              56.695077
            ],
            [
              37.08410358893821,
              56.695826427380176
            ],
            [
              37.07496388746464,
              56.698857537989035
            ],
            [
              37.072504,
              56.700671
            ],
            [
              37.070092,
              56.705022
            ],
            [
              37.066428,
              56.709448
            ],
            [
              37.063255,
              56.707746
            ],
            [
              37.064091,
              56.706188
            ],
            [
              37.06452995916973,
              56.70571695158806
            ],
            [
              37.064495,
              56.70568
            ],
            [
              37.06455921168466,
              56.70561922365874
            ],
            [
              37.064559,
              56.705619
            ],
            [
              37.065085494324684,
              56.705120805133994
            ],
            [
              37.067333,
              56.702709
            ],
            [
              37.063386,
              56.692007
            ],
            [
              37.056489,
              56.694986
            ],
            [
              37.055479,
              56.695412
            ],
            [
              37.05547407983145,
              56.695407162539276
            ],
            [
              37.054807,
              56.695769
            ],
            [
              37.054572,
              56.695863
            ],
            [
              37.0545068175433,
              56.69570019000726
            ],
            [
              37.052406,
              56.696574
            ],
            [
              37.0491,
              56.698108
            ],
            [
              37.04797234576705,
              56.698440194733955
            ],
            [
              37.047196,
              56.698769
            ],
            [
              37.04439143291416,
              56.700073381640046
            ],
            [
              37.037944,
              56.70504
            ],
            [
              37.03598818887356,
              56.70417728720305
            ],
            [
              37.035264,
              56.70469
            ],
            [
              37.025392,
              56.711466
            ],
            [
              37.033527,
              56.71743
            ],
            [
              37.038986,
              56.719645
            ],
            [
              37.03850690402333,
              56.719770417304545
            ],
            [
              37.040404,
              56.720662
            ],
            [
              37.041049,
              56.721179
            ],
            [
              37.0388,
              56.723989
            ],
            [
              37.034894655726745,
              56.726012953309755
            ],
            [
              37.032094,
              56.730542
            ],
            [
              37.030273,
              56.732031
            ],
            [
              37.02566694240055,
              56.73556845223638
            ],
            [
              37.026392,
              56.736967
            ],
            [
              37.029598,
              56.738605
            ],
            [
              37.025867,
              56.742412
            ],
            [
              37.025132,
              56.742972
            ],
            [
              37.022949,
              56.74011
            ],
            [
              37.022872,
              56.739947
            ],
            [
              37.02164720758222,
              56.73676932195192
            ],
            [
              37.020793,
              56.736421
            ],
            [
              37.019802,
              56.731982
            ],
            [
              37.01929637480579,
              56.73038308131385
            ],
            [
              37.01902,
              56.730365
            ],
            [
              37.009987,
              56.728467
            ],
            [
              37.00874409275383,
              56.727704974081846
            ],
            [
              37.006708,
              56.733713
            ],
            [
              37.003147,
              56.734658
            ],
            [
              36.998615,
              56.735003
            ],
            [
              36.996305,
              56.733646
            ],
            [
              36.992786121415826,
              56.7289203037504
            ],
            [
              36.984749,
              56.728867
            ],
            [
              36.983585,
              56.728466
            ],
            [
              36.976267,
              56.726808
            ],
            [
              36.97562,
              56.725938
            ],
            [
              36.963911,
              56.72766
            ],
            [
              36.96483,
              56.732044
            ],
            [
              36.964877,
              56.735644
            ],
            [
              36.96186471876545,
              56.73727453596686
            ],
            [
              36.962069,
              56.743479
            ],
            [
              36.958514,
              56.748488
            ],
            [
              36.954682458115165,
              56.751410353945445
            ],
            [
              36.950246,
              56.761196
            ],
            [
              36.95028265751038,
              56.7622891384149
            ],
            [
              36.951249,
              56.763904
            ],
            [
              36.950365211491075,
              56.76475092391751
            ],
            [
              36.950374,
              56.765013
            ],
            [
              36.9495383681583,
              56.76554327795639
            ],
            [
              36.948492,
              56.766546
            ],
            [
              36.945619,
              56.768646
            ],
            [
              36.94278768235106,
              56.77028021839624
            ],
            [
              36.935400905929605,
              56.77595226840609
            ],
            [
              36.931921,
              56.781133
            ],
            [
              36.931702,
              56.781663
            ],
            [
              36.927510646837405,
              56.78147771757412
            ],
            [
              36.927239,
              56.781661
            ],
            [
              36.926619055645844,
              56.781438304007075
            ],
            [
              36.925368,
              56.781383
            ],
            [
              36.922948,
              56.780297
            ],
            [
              36.92275601338876,
              56.78005062457203
            ],
            [
              36.919912,
              56.779029
            ],
            [
              36.920232251820444,
              56.77898154166015
            ],
            [
              36.918077,
              56.778671
            ],
            [
              36.92154973144122,
              56.77830938367611
            ],
            [
              36.921414,
              56.777871
            ],
            [
              36.921312,
              56.777091
            ],
            [
              36.921486,
              56.777002
            ],
            [
              36.92376,
              56.77675
            ],
            [
              36.931457,
              56.774156
            ],
            [
              36.93438,
              56.771086
            ],
            [
              36.934516533770584,
              56.77103592750945
            ],
            [
              36.934742,
              56.770806
            ],
            [
              36.9423639717102,
              56.76640145560977
            ],
            [
              36.945319,
              56.758663
            ],
            [
              36.945846,
              56.754318
            ],
            [
              36.946307073156326,
              56.75383350095242
            ],
            [
              36.946258,
              56.753783
            ],
            [
              36.9475546454133,
              56.75252254289294
            ],
            [
              36.950491,
              56.749437
            ],
            [
              36.950504072730354,
              56.74942671726433
            ],
            [
              36.950501,
              56.749424
            ],
            [
              36.951007,
              56.748853
            ],
            [
              36.95228291316831,
              56.74794508705076
            ],
            [
              36.952619,
              56.74762
            ],
            [
              36.95620373952688,
              56.73756893837541
            ],
            [
              36.951891,
              56.730226
            ],
            [
              36.947103,
              56.72892
            ],
            [
              36.942092,
              56.728117
            ],
            [
              36.93918,
              56.727468
            ],
            [
              36.939963,
              56.723954
            ],
            [
              36.942875,
              56.724603
            ],
            [
              36.94767,
              56.725365
            ],
            [
              36.954791,
              56.720688
            ],
            [
              36.95475170941695,
              56.71931357092516
            ],
            [
              36.954729,
              56.719314
            ],
            [
              36.948188,
              56.718053
            ],
            [
              36.947339,
              56.714555
            ],
            [
              36.949666,
              56.71399
            ],
            [
              36.951994,
              56.713425
            ],
            [
              36.953957,
              56.712772
            ],
            [
              36.95805558681655,
              56.71158086267885
            ],
            [
              36.95875078902152,
              56.71072380944999
            ],
            [
              36.959660952747605,
              56.708842738836424
            ],
            [
              36.962061,
              56.699851
            ],
            [
              36.958404978641845,
              56.691543983839104
            ],
            [
              36.956239,
              56.689282
            ],
            [
              36.95623539210646,
              56.68924182016701
            ],
            [
              36.953799,
              56.689373
            ],
            [
              36.946981,
              56.689088
            ],
            [
              36.94325,
              56.688812
            ],
            [
              36.93631524195206,
              56.68718926270657
            ],
            [
              36.931089,
              56.688942
            ],
            [
              36.930908,
              56.693296
            ],
            [
              36.927407,
              56.694135
            ],
            [
              36.926912,
              56.692277
            ],
            [
              36.92221165035209,
              56.68361860480959
            ],
            [
              36.919192,
              56.682101
            ],
            [
              36.91919214345594,
              56.682100444347334
            ],
            [
              36.915223,
              56.681076
            ],
            [
              36.916122,
              56.677591
            ],
            [
              36.913681,
              56.668696
            ],
            [
              36.905315,
              56.671587
            ],
            [
              36.905032,
              56.671564
            ],
            [
              36.904261479910204,
              56.6713774937429
            ],
            [
              36.89553,
              56.673263
            ],
            [
              36.891406,
              56.674395
            ],
            [
              36.887201,
              56.675547
            ],
            [
              36.88384326032346,
              56.674637299282516
            ],
            [
              36.880271195376096,
              56.67605382818253
            ],
            [
              36.880429,
              56.676908
            ],
            [
              36.879235,
              56.677128
            ],
            [
              36.87038022007264,
              56.680545617241684
            ],
            [
              36.867789,
              56.681834
            ],
            [
              36.862878,
              56.684321
            ],
            [
              36.86284884320454,
              56.68432955606839
            ],
            [
              36.862851,
              56.684334
            ],
            [
              36.862184,
              56.684658
            ],
            [
              36.859,
              56.685459
            ],
            [
              36.855507,
              56.685972
            ],
            [
              36.85201231157339,
              56.686489311187046
            ],
            [
              36.85180990293188,
              56.686590060401244
            ],
            [
              36.844646,
              56.69202
            ],
            [
              36.842339,
              56.692759
            ],
            [
              36.840966343655275,
              56.69282655856445
            ],
            [
              36.838592,
              56.703894
            ],
            [
              36.844133,
              56.70551
            ],
            [
              36.849694,
              56.707132
            ],
            [
              36.852451,
              56.70803
            ],
            [
              36.856961,
              56.709616
            ],
            [
              36.85696087999263,
              56.70961634104186
            ],
            [
              36.857085,
              56.70966
            ],
            [
              36.85708405591138,
              56.70966268294974
            ],
            [
              36.857377,
              56.709763
            ],
            [
              36.860972,
              56.711054
            ],
            [
              36.865758,
              56.71404
            ],
            [
              36.867372,
              56.717751
            ],
            [
              36.872523,
              56.725465
            ],
            [
              36.87239109605793,
              56.72584669142421
            ],
            [
              36.875254,
              56.726505
            ],
            [
              36.880988,
              56.729099
            ],
            [
              36.881166,
              56.732694
            ],
            [
              36.88026,
              56.732739
            ],
            [
              36.878131,
              56.732391
            ],
            [
              36.874226,
              56.729955
            ],
            [
              36.871262981779225,
              56.72924128703075
            ],
            [
              36.86878,
              56.740273
            ],
            [
              36.873342,
              56.741894
            ],
            [
              36.877979,
              56.74326
            ],
            [
              36.882791,
              56.746099
            ],
            [
              36.887033,
              56.747457
            ],
            [
              36.8947,
              56.754021
            ],
            [
              36.901109,
              56.759722
            ],
            [
              36.898306,
              56.764385
            ],
            [
              36.89333,
              56.758002
            ],
            [
              36.886721,
              56.75292
            ],
            [
              36.878921,
              56.748978
            ],
            [
              36.870778,
              56.745252
            ],
            [
              36.867097,
              56.743455
            ],
            [
              36.863686,
              56.741721
            ],
            [
              36.860559,
              56.739629
            ],
            [
              36.85860388552409,
              56.73820938678543
            ],
            [
              36.84877110187538,
              56.7436598923251
            ],
            [
              36.848365,
              56.743954
            ],
            [
              36.84827561057272,
              56.74393455290815
            ],
            [
              36.848273,
              56.743936
            ],
            [
              36.845033,
              56.743851
            ],
            [
              36.8399991632606,
              56.749388220413344
            ],
            [
              36.837766,
              56.753118
            ],
            [
              36.838454,
              56.75682
            ],
            [
              36.83842858570635,
              56.75682430253253
            ],
            [
              36.838908,
              56.759506
            ],
            [
              36.839865,
              56.764544
            ],
            [
              36.840539,
              56.76785
            ],
            [
              36.837012,
              56.768569
            ],
            [
              36.836338,
              56.765263
            ],
            [
              36.835371,
              56.760178
            ],
            [
              36.831592747642595,
              56.75562529383858
            ],
            [
              36.826016,
              56.752601
            ],
            [
              36.82085341494725,
              56.7520533949436
            ],
            [
              36.8198183991933,
              56.75205926289162
            ],
            [
              36.8184307928573,
              56.752279698402454
            ],
            [
              36.815152,
              56.75459
            ],
            [
              36.814811,
              56.754603
            ],
            [
              36.807224,
              56.755342
            ],
            [
              36.80592,
              56.755601
            ],
            [
              36.795682,
              56.761731
            ],
            [
              36.79556,
              56.761813
            ],
            [
              36.794108,
              56.76207
            ],
            [
              36.79123955214397,
              56.76252712429479
            ],
            [
              36.791052255935334,
              56.76264694822111
            ],
            [
              36.790897,
              56.762892
            ],
            [
              36.787146,
              56.765146
            ],
            [
              36.777036,
              56.769009
            ],
            [
              36.776636,
              56.772586
            ],
            [
              36.776121,
              56.772529
            ],
            [
              36.773977,
              56.772095
            ],
            [
              36.76916306631166,
              56.770104075570096
            ],
            [
              36.761243,
              56.768712
            ],
            [
              36.74937,
              56.769252
            ],
            [
              36.748859,
              56.769348
            ],
            [
              36.74830774474447,
              56.767540370880106
            ],
            [
              36.747184,
              56.767862
            ],
            [
              36.741173,
              56.764477
            ],
            [
              36.741829886374106,
              56.76366552269703
            ],
            [
              36.74106,
              56.763293
            ],
            [
              36.743478,
              56.76063
            ],
            [
              36.744872,
              56.757074
            ],
            [
              36.74947391431779,
              56.75425142391769
            ],
            [
              36.749694,
              56.753981
            ],
            [
              36.752918,
              56.752139
            ],
            [
              36.75708002439147,
              56.749637998577
            ],
            [
              36.757077,
              56.749609
            ],
            [
              36.757754,
              56.749233
            ],
            [
              36.75779496003291,
              56.74922280367036
            ],
            [
              36.75888,
              56.748574
            ],
            [
              36.75895281448645,
              56.74856873256906
            ],
            [
              36.759904,
              56.748006
            ],
            [
              36.76188659107134,
              56.74820425910713
            ],
            [
              36.763615,
              56.747774
            ],
            [
              36.76645694730325,
              56.74626660744689
            ],
            [
              36.77065436239358,
              56.74316315154927
            ],
            [
              36.77193345478335,
              56.741578781207515
            ],
            [
              36.770799,
              56.740476
            ],
            [
              36.771396,
              56.736926
            ],
            [
              36.77133342156781,
              56.73242901758838
            ],
            [
              36.769367439816804,
              56.72722050388616
            ],
            [
              36.767907,
              56.723886
            ],
            [
              36.76435369592164,
              56.715189492529014
            ],
            [
              36.758509,
              56.712956
            ],
            [
              36.758323,
              56.71278
            ],
            [
              36.75839,
              56.710266
            ],
            [
              36.758399,
              56.710227
            ],
            [
              36.760588,
              56.708625
            ],
            [
              36.76113715946111,
              56.70809646266052
            ],
            [
              36.76203334243139,
              56.70581313831409
            ],
            [
              36.755143,
              56.69843
            ],
            [
              36.75468237353532,
              56.6983719576909
            ],
            [
              36.753998,
              56.698347
            ],
            [
              36.75033585549941,
              56.69783353593908
            ],
            [
              36.744894,
              56.697574
            ],
            [
              36.74052,
              56.69805
            ],
            [
              36.731545,
              56.699638
            ],
            [
              36.728085,
              56.701318
            ],
            [
              36.725275,
              56.702424
            ],
            [
              36.72405914263642,
              56.702902121845824
            ],
            [
              36.723264,
              56.703215
            ],
            [
              36.722429,
              56.713293
            ],
            [
              36.727011,
              56.723802
            ],
            [
              36.732085,
              56.724185
            ],
            [
              36.731814,
              56.727775
            ],
            [
              36.721666,
              56.727009
            ],
            [
              36.719574194944606,
              56.72884348179781
            ],
            [
              36.720679,
              56.729158
            ],
            [
              36.729462,
              56.736728
            ],
            [
              36.73167,
              56.738046
            ],
            [
              36.7316615701989,
              56.73806011512199
            ],
            [
              36.732733,
              56.738709
            ],
            [
              36.736204,
              56.740903
            ],
            [
              36.738566,
              56.743916
            ],
            [
              36.736264,
              56.747399
            ],
            [
              36.73283,
              56.749053
            ],
            [
              36.727134,
              56.753658
            ],
            [
              36.724499,
              56.756144
            ],
            [
              36.720491,
              56.757946
            ],
            [
              36.716035,
              56.767812
            ],
            [
              36.715641,
              56.769753
            ],
            [
              36.715368,
              56.770554
            ],
            [
              36.71384670949751,
              56.77095337391305
            ],
            [
              36.711257,
              56.774671
            ],
            [
              36.708507,
              56.776994
            ],
            [
              36.6989693686638,
              56.77357014518482
            ],
            [
              36.693825,
              56.773605
            ],
            [
              36.693824981085356,
              56.77360486373814
            ],
            [
              36.693644,
              56.77363
            ],
            [
              36.689932,
              56.773552
            ],
            [
              36.684556,
              56.772371
            ],
            [
              36.680188,
              56.771367
            ],
            [
              36.676099,
              56.768917
            ],
            [
              36.671592,
              56.765627
            ],
            [
              36.67376,
              56.762753
            ],
            [
              36.678188,
              56.765985
            ],
            [
              36.681406,
              56.767979
            ],
            [
              36.685352,
              56.76886
            ],
            [
              36.691602,
              56.770235
            ],
            [
              36.698164,
              56.762041
            ],
            [
              36.697587,
              56.756132
            ],
            [
              36.699714,
              56.752809
            ],
            [
              36.703223,
              56.751478
            ],
            [
              36.707244,
              56.752393
            ],
            [
              36.712353,
              56.752641
            ],
            [
              36.717914,
              56.754312
            ],
            [
              36.721835,
              56.753314
            ],
            [
              36.725026,
              56.750739
            ],
            [
              36.724819900093095,
              56.74301962166854
            ],
            [
              36.71966127769052,
              56.73863898925284
            ],
            [
              36.715322,
              56.738155
            ],
            [
              36.712809350456006,
              56.737847700737184
            ],
            [
              36.712793,
              56.737982
            ],
            [
              36.711832,
              56.737865
            ],
            [
              36.705881,
              56.736929
            ],
            [
              36.702369,
              56.732601
            ],
            [
              36.698243,
              56.723918
            ],
            [
              36.69721027951575,
              56.72345737351889
            ],
            [
              36.69721,
              56.723458
            ],
            [
              36.694656630368016,
              56.72231836403972
            ],
            [
              36.693694,
              56.721889
            ],
            [
              36.689179,
              56.719874
            ],
            [
              36.679813,
              56.719136
            ],
            [
              36.675767,
              56.715763
            ],
            [
              36.667507,
              56.70777
            ],
            [
              36.666481,
              56.707313
            ],
            [
              36.66649859173465,
              56.70727357149042
            ],
            [
              36.665793,
              56.70694
            ],
            [
              36.657357,
              56.709659
            ],
            [
              36.653763,
              56.709447
            ],
            [
              36.64622854428455,
              56.71085770300528
            ],
            [
              36.643978,
              56.71403
            ],
            [
              36.642533,
              56.718237
            ],
            [
              36.642044,
              56.719021
            ],
            [
              36.640783,
              56.720438
            ],
            [
              36.639523,
              56.721855
            ],
            [
              36.63940721098918,
              56.72175203817327
            ],
            [
              36.639029,
              56.722391
            ],
            [
              36.635038746041324,
              56.72478579613761
            ],
            [
              36.634543,
              56.725219
            ],
            [
              36.628235,
              56.732749
            ],
            [
              36.627565,
              56.73272
            ],
            [
              36.62097,
              56.732251
            ],
            [
              36.62094394002411,
              56.732186583600345
            ],
            [
              36.610847,
              56.735414
            ],
            [
              36.605624,
              56.736727
            ],
            [
              36.601898,
              56.737672
            ],
            [
              36.59572958433738,
              56.74307084715535
            ],
            [
              36.59516851829277,
              56.74453515405204
            ],
            [
              36.595444,
              56.754037
            ],
            [
              36.598044,
              56.759553
            ],
            [
              36.600279,
              56.766772
            ],
            [
              36.60008384657624,
              56.76686987993349
            ],
            [
              36.60127,
              56.770073
            ],
            [
              36.604016,
              56.777523
            ],
            [
              36.605099,
              56.78119
            ],
            [
              36.609798,
              56.788274
            ],
            [
              36.608783,
              56.795257
            ],
            [
              36.610072,
              56.804397
            ],
            [
              36.610322,
              56.808038
            ],
            [
              36.611252,
              56.815558
            ],
            [
              36.614986,
              56.822595
            ],
            [
              36.615529,
              56.827194
            ],
            [
              36.607983,
              56.827484
            ],
            [
              36.602444,
              56.829477
            ],
            [
              36.598887,
              56.830293
            ],
            [
              36.594973826127436,
              56.82994104442843
            ],
            [
              36.591108,
              56.830123
            ],
            [
              36.582061,
              56.836428
            ],
            [
              36.578558,
              56.840882
            ],
            [
              36.575136,
              56.842201
            ],
            [
              36.566511,
              56.84358
            ],
            [
              36.56,
              56.843614
            ],
            [
              36.551619,
              56.847884
            ],
            [
              36.552802,
              56.854271
            ],
            [
              36.551109,
              56.857448
            ],
            [
              36.53931,
              56.85596
            ],
            [
              36.538101,
              56.855412
            ],
            [
              36.537501,
              56.854677
            ],
            [
              36.536423,
              56.851608
            ],
            [
              36.529006,
              56.842479
            ],
            [
              36.52341,
              56.840507
            ],
            [
              36.517813,
              56.838535
            ],
            [
              36.51901,
              56.835139
            ],
            [
              36.524606,
              56.837112
            ],
            [
              36.530203,
              56.839084
            ],
            [
              36.54157,
              56.838676
            ],
            [
              36.54168320318963,
              56.83862978147192
            ],
            [
              36.542375,
              56.83563
            ],
            [
              36.544831,
              56.836196
            ],
            [
              36.54662436400305,
              56.836612407398405
            ],
            [
              36.546667,
              56.836595
            ],
            [
              36.551169,
              56.837403
            ],
            [
              36.551386799077136,
              56.837454252859395
            ],
            [
              36.551389,
              56.837445
            ],
            [
              36.555427,
              56.838405
            ],
            [
              36.559561,
              56.839633
            ],
            [
              36.567281,
              56.839724
            ],
            [
              36.572166,
              56.838727
            ],
            [
              36.579567,
              56.831867
            ],
            [
              36.583846,
              56.828243
            ],
            [
              36.588084,
              56.826911
            ],
            [
              36.591689,
              56.82644
            ],
            [
              36.592790644602786,
              56.8264253922532
            ],
            [
              36.592808,
              56.826134
            ],
            [
              36.594845,
              56.826255
            ],
            [
              36.595686,
              56.826387
            ],
            [
              36.600792,
              56.826278
            ],
            [
              36.605605,
              56.824205
            ],
            [
              36.60668,
              56.814621
            ],
            [
              36.6068,
              56.809364
            ],
            [
              36.605561,
              56.800946
            ],
            [
              36.603995,
              56.796156
            ],
            [
              36.602723,
              56.786427
            ],
            [
              36.601726,
              56.78287
            ],
            [
              36.599229,
              56.775603
            ],
            [
              36.597822,
              56.771108
            ],
            [
              36.596176522022304,
              56.76662242303407
            ],
            [
              36.596091,
              56.766452
            ],
            [
              36.595128,
              56.764532
            ],
            [
              36.593068,
              56.758405
            ],
            [
              36.591951,
              56.754906
            ],
            [
              36.590004,
              56.751723
            ],
            [
              36.579506,
              56.746085
            ],
            [
              36.57112,
              56.748915
            ],
            [
              36.566047,
              56.750595
            ],
            [
              36.561119,
              56.7523
            ],
            [
              36.5565,
              56.753803
            ],
            [
              36.552401,
              56.755211
            ],
            [
              36.551612,
              56.755381
            ],
            [
              36.55158239727377,
              56.75524316720061
            ],
            [
              36.550702,
              56.755501
            ],
            [
              36.546853,
              56.75575
            ],
            [
              36.54593298886932,
              56.7556622409485
            ],
            [
              36.545319,
              56.755683
            ],
            [
              36.543013019562395,
              56.75538370764794
            ],
            [
              36.542754,
              56.755359
            ],
            [
              36.53850605205624,
              56.75484236810268
            ],
            [
              36.533935,
              56.754383
            ],
            [
              36.530361,
              56.754033
            ],
            [
              36.522856,
              56.75184
            ],
            [
              36.515786,
              56.752402
            ],
            [
              36.511999,
              56.753137
            ],
            [
              36.508154,
              56.753882
            ],
            [
              36.50548038010039,
              56.761905010063366
            ],
            [
              36.505727,
              56.765973
            ],
            [
              36.511944,
              56.773069
            ],
            [
              36.518046,
              56.780046
            ],
            [
              36.520802,
              56.783367
            ],
            [
              36.520008,
              56.786934
            ],
            [
              36.518396,
              56.791339
            ],
            [
              36.52187534905367,
              56.80036231184179
            ],
            [
              36.52187725900988,
              56.80036546155329
            ],
            [
              36.528869,
              56.808482
            ],
            [
              36.533292,
              56.810887
            ],
            [
              36.53328826319942,
              56.81089385152359
            ],
            [
              36.534846,
              56.811744
            ],
            [
              36.538051,
              56.813497
            ],
            [
              36.541262,
              56.815253
            ],
            [
              36.544273,
              56.81764
            ],
            [
              36.544433,
              56.818235
            ],
            [
              36.54489,
              56.820957
            ],
            [
              36.545347,
              56.82368
            ],
            [
              36.541797,
              56.824276
            ],
            [
              36.536324,
              56.816656
            ],
            [
              36.533118,
              56.814903
            ],
            [
              36.52715,
              56.811645
            ],
            [
              36.523302,
              56.809484
            ],
            [
              36.52127217145011,
              56.8062148223133
            ],
            [
              36.521229,
              56.806241
            ],
            [
              36.517947,
              56.800827
            ],
            [
              36.514664,
              56.795413
            ],
            [
              36.514062,
              56.792978
            ],
            [
              36.515873,
              56.787812
            ],
            [
              36.515569,
              56.782659
            ],
            [
              36.509236,
              56.775442
            ],
            [
              36.500481,
              56.767054
            ],
            [
              36.495924,
              56.764277
            ],
            [
              36.493013,
              56.76148
            ],
            [
              36.491075,
              56.757034
            ],
            [
              36.492225656861216,
              56.755785510379134
            ],
            [
              36.491904,
              56.754847
            ],
            [
              36.49363888959683,
              56.754252119790024
            ],
            [
              36.493639,
              56.754252
            ],
            [
              36.49364357473555,
              56.754250513292234
            ],
            [
              36.494345,
              56.75401
            ],
            [
              36.496787,
              56.753173
            ],
            [
              36.499267,
              56.752423
            ],
            [
              36.504034,
              56.751017
            ],
            [
              36.507583,
              56.750328
            ],
            [
              36.511313,
              56.749603
            ],
            [
              36.5151,
              56.748868
            ],
            [
              36.524743,
              56.748261
            ],
            [
              36.534266,
              56.750798
            ],
            [
              36.540389,
              56.751413
            ],
            [
              36.545771,
              56.752112
            ],
            [
              36.555291,
              56.750412
            ],
            [
              36.560004,
              56.748877
            ],
            [
              36.56487,
              56.747193
            ],
            [
              36.569988,
              56.745498
            ],
            [
              36.575083,
              56.74381
            ],
            [
              36.58159,
              56.741549
            ],
            [
              36.585604,
              56.740174
            ],
            [
              36.590534,
              56.738168
            ],
            [
              36.595238,
              56.737868
            ],
            [
              36.597118,
              56.726552
            ],
            [
              36.600663,
              56.726191
            ],
            [
              36.604193,
              56.726653
            ],
            [
              36.607965,
              56.727946
            ],
            [
              36.61921971386786,
              56.728858907972544
            ],
            [
              36.623344,
              56.727056
            ],
            [
              36.631192591131075,
              56.72291710751714
            ],
            [
              36.63321885134784,
              56.721719551329144
            ],
            [
              36.63635476300912,
              56.71914388374656
            ],
            [
              36.63582936853665,
              56.71812862634985
            ],
            [
              36.635513,
              56.717708
            ],
            [
              36.631354156982766,
              56.7094831717431
            ],
            [
              36.629394,
              56.709836
            ],
            [
              36.627117431121526,
              56.70727052203748
            ],
            [
              36.626601,
              56.70701
            ],
            [
              36.62700264543249,
              56.704855441651
            ],
            [
              36.620994,
              56.698882
            ],
            [
              36.617334572247195,
              56.69830505842799
            ],
            [
              36.60506261834523,
              56.69650739621516
            ],
            [
              36.5979,
              56.699356
            ],
            [
              36.59398588135012,
              56.697072764120904
            ],
            [
              36.592329,
              56.697289
            ],
            [
              36.590915,
              56.695635
            ],
            [
              36.59091079453684,
              56.69550534477711
            ],
            [
              36.588287,
              56.694743
            ],
            [
              36.584695,
              56.693943
            ],
            [
              36.579891,
              56.692785
            ],
            [
              36.572848,
              56.691283
            ],
            [
              36.57105634436323,
              56.690872290289974
            ],
            [
              36.569443,
              56.690523
            ],
            [
              36.56843850610111,
              56.69027219082249
            ],
            [
              36.567033,
              56.68995
            ],
            [
              36.557762,
              56.687559
            ],
            [
              36.553955,
              56.686489
            ],
            [
              36.549491,
              56.685294
            ],
            [
              36.544916,
              56.684058
            ],
            [
              36.539124,
              56.682523
            ],
            [
              36.534966,
              56.6814
            ],
            [
              36.52976,
              56.680006
            ],
            [
              36.524525,
              56.678604
            ],
            [
              36.515456,
              56.676121
            ],
            [
              36.509754,
              56.685021
            ],
            [
              36.509954,
              56.686322
            ],
            [
              36.510175,
              56.687404
            ],
            [
              36.506647,
              56.688123
            ],
            [
              36.501441,
              56.688531
            ],
            [
              36.500967,
              56.686196
            ],
            [
              36.492053,
              56.679425
            ],
            [
              36.486575,
              56.686081
            ],
            [
              36.483038,
              56.686755
            ],
            [
              36.482851,
              56.685773
            ],
            [
              36.482215,
              56.68186
            ],
            [
              36.478847953677196,
              56.679174623264046
            ],
            [
              36.474078,
              56.680383
            ],
            [
              36.471076,
              56.682309
            ],
            [
              36.467492,
              56.684131
            ],
            [
              36.463798,
              56.686577
            ],
            [
              36.45451367507028,
              56.680558216288915
            ],
            [
              36.450039,
              56.68447
            ],
            [
              36.44877156486331,
              56.68567466122662
            ],
            [
              36.448447,
              56.686117
            ],
            [
              36.445375,
              56.688903
            ],
            [
              36.438307,
              56.691519
            ],
            [
              36.435034,
              56.69319
            ],
            [
              36.431281,
              56.694917
            ],
            [
              36.427621,
              56.695461
            ],
            [
              36.425971,
              56.692262
            ],
            [
              36.426700534026594,
              56.692103093572236
            ],
            [
              36.426672,
              56.691999
            ],
            [
              36.42896095164205,
              56.691610731495295
            ],
            [
              36.42963,
              56.691465
            ],
            [
              36.43254898187312,
              56.69036497276095
            ],
            [
              36.434948,
              56.689145
            ],
            [
              36.43669289010549,
              56.68826149768383
            ],
            [
              36.436769,
              56.68822
            ],
            [
              36.4368019657895,
              56.688206268635795
            ],
            [
              36.438183,
              56.687507
            ],
            [
              36.44392961351928,
              56.68269989993931
            ],
            [
              36.444987,
              56.68147
            ],
            [
              36.447611,
              56.678784
            ],
            [
              36.451034,
              56.677338
            ],
            [
              36.451051532209526,
              56.677330173038335
            ],
            [
              36.451071,
              56.677316
            ],
            [
              36.45399209397531,
              56.67549118711968
            ],
            [
              36.456345,
              56.673266
            ],
            [
              36.460164,
              56.671945
            ],
            [
              36.466953,
              56.669841
            ],
            [
              36.47228,
              56.670178
            ],
            [
              36.47254602858012,
              56.67008973916828
            ],
            [
              36.473416,
              56.668718
            ],
            [
              36.484876,
              56.665999
            ],
            [
              36.489085,
              56.665746
            ],
            [
              36.494209,
              56.665409
            ],
            [
              36.498618,
              56.666565
            ],
            [
              36.508189,
              56.665997
            ],
            [
              36.515051,
              56.666143
            ],
            [
              36.517908,
              56.669283
            ],
            [
              36.525513,
              56.675142
            ],
            [
              36.530691,
              56.676528
            ],
            [
              36.535897,
              56.677922
            ],
            [
              36.540063,
              56.679048
            ],
            [
              36.545795,
              56.680567
            ],
            [
              36.550432,
              56.681819
            ],
            [
              36.554816,
              56.682994
            ],
            [
              36.558735,
              56.684093
            ],
            [
              36.568361,
              56.685477
            ],
            [
              36.57177,
              56.687186
            ],
            [
              36.57875912981385,
              56.68882339916041
            ],
            [
              36.588239,
              56.68678
            ],
            [
              36.590048129222524,
              56.685683273994634
            ],
            [
              36.591861,
              56.683788
            ],
            [
              36.596213,
              56.684577
            ],
            [
              36.59911493355271,
              56.68845211841694
            ],
            [
              36.605845,
              56.692852
            ],
            [
              36.614634872671544,
              56.694235807094444
            ],
            [
              36.614635,
              56.694235
            ],
            [
              36.621554,
              56.695325
            ],
            [
              36.628473,
              56.696416
            ],
            [
              36.633519,
              56.69705
            ],
            [
              36.644338,
              56.692764
            ],
            [
              36.644424,
              56.68863
            ],
            [
              36.644453,
              56.684966
            ],
            [
              36.63714544925841,
              56.67707538536785
            ],
            [
              36.627525,
              56.675561
            ],
            [
              36.62526713834781,
              56.675635960201916
            ],
            [
              36.625007,
              56.67568
            ],
            [
              36.62486233116143,
              56.67564939965619
            ],
            [
              36.623037,
              56.67571
            ],
            [
              36.620432687492816,
              56.674712441700464
            ],
            [
              36.619816,
              56.674582
            ],
            [
              36.616141,
              56.67332
            ],
            [
              36.61623125288842,
              56.67226363637723
            ],
            [
              36.615119,
              56.671492
            ],
            [
              36.616480834456965,
              56.66934241253013
            ],
            [
              36.61652,
              56.668884
            ],
            [
              36.61696298983948,
              56.66858135441217
            ],
            [
              36.617235,
              56.668152
            ],
            [
              36.620434,
              56.66621
            ],
            [
              36.620222,
              56.656351
            ],
            [
              36.613075,
              56.65365
            ],
            [
              36.606163,
              56.653032
            ],
            [
              36.602509,
              56.651862
            ],
            [
              36.60053,
              56.648574
            ],
            [
              36.591897,
              56.644318
            ],
            [
              36.588059,
              56.645644
            ],
            [
              36.586931,
              56.641965
            ],
            [
              36.58104,
              56.633477
            ],
            [
              36.576841,
              56.633054
            ],
            [
              36.573509,
              56.630696
            ],
            [
              36.567001,
              56.623093
            ],
            [
              36.567080921936444,
              56.62269229011412
            ],
            [
              36.566963,
              56.622565
            ],
            [
              36.565482286924436,
              56.61243819518531
            ],
            [
              36.55978,
              56.611705
            ],
            [
              36.55464678170984,
              56.61016978121415
            ],
            [
              36.546392,
              56.615056
            ],
            [
              36.54389742886564,
              56.61370075046706
            ],
            [
              36.54156,
              56.615326
            ],
            [
              36.540838,
              56.608459
            ],
            [
              36.536349,
              56.600439
            ],
            [
              36.526915,
              56.597016
            ],
            [
              36.52108533634649,
              56.59533884115406
            ],
            [
              36.519208,
              56.595577
            ],
            [
              36.51893814809224,
              56.59533473324375
            ],
            [
              36.51850551754946,
              56.59535279001768
            ],
            [
              36.51713,
              56.595521
            ],
            [
              36.51706507664295,
              56.59541290994271
            ],
            [
              36.517015,
              56.595415
            ],
            [
              36.51471355761672,
              56.591541994332076
            ],
            [
              36.510932,
              56.588147
            ],
            [
              36.507509016377625,
              56.58618838170611
            ],
            [
              36.50615597991921,
              56.58681101443318
            ],
            [
              36.498734,
              56.591126
            ],
            [
              36.492594147664704,
              56.591939930324614
            ],
            [
              36.48607,
              56.597931
            ],
            [
              36.48218,
              56.60358
            ],
            [
              36.47744,
              56.610539
            ],
            [
              36.47273,
              56.61738
            ],
            [
              36.465642,
              56.627601
            ],
            [
              36.45964,
              56.636278
            ],
            [
              36.464427,
              56.639931
            ],
            [
              36.47145451084409,
              56.64329912611402
            ],
            [
              36.47566919783742,
              56.644031918486355
            ],
            [
              36.476779,
              56.643963
            ],
            [
              36.48129736371101,
              56.64452040226661
            ],
            [
              36.48478420955442,
              56.64253115016543
            ],
            [
              36.486005,
              56.641685
            ],
            [
              36.486235,
              56.641624
            ],
            [
              36.486253437634254,
              56.64169295278701
            ],
            [
              36.486497,
              56.641554
            ],
            [
              36.487261,
              56.64135
            ],
            [
              36.488112,
              56.641908
            ],
            [
              36.48831663060921,
              56.64223096332573
            ],
            [
              36.489598261282445,
              56.641766496395704
            ],
            [
              36.4928909447861,
              56.64012027438653
            ],
            [
              36.498475,
              56.634724
            ],
            [
              36.498763,
              56.634564
            ],
            [
              36.4988572568418,
              56.63456716921555
            ],
            [
              36.499632,
              56.633843
            ],
            [
              36.499812,
              56.629291
            ],
            [
              36.501446,
              56.629157
            ],
            [
              36.503647,
              56.630163
            ],
            [
              36.503023,
              56.634952
            ],
            [
              36.502216149157825,
              56.63777692792898
            ],
            [
              36.503617,
              56.640702
            ],
            [
              36.503538,
              56.640969
            ],
            [
              36.501392,
              56.646341
            ],
            [
              36.500794,
              56.646612
            ],
            [
              36.49958531157216,
              56.6462835291167
            ],
            [
              36.499157,
              56.647444
            ],
            [
              36.498634,
              56.647586
            ],
            [
              36.497803,
              56.647756
            ],
            [
              36.49167611399525,
              56.6445714170462
            ],
            [
              36.48785249038457,
              56.64549347661588
            ],
            [
              36.486714,
              56.647735
            ],
            [
              36.485795,
              56.647938
            ],
            [
              36.483942,
              56.647983
            ],
            [
              36.4801095967722,
              56.64750858379456
            ],
            [
              36.478338,
              56.647988
            ],
            [
              36.473365,
              56.650305
            ],
            [
              36.4715835358977,
              56.649955893364414
            ],
            [
              36.469994,
              56.650419
            ],
            [
              36.46616361643941,
              56.64991546115933
            ],
            [
              36.46491922199339,
              56.650070732400536
            ],
            [
              36.457575,
              56.655921
            ],
            [
              36.452531,
              56.657635
            ],
            [
              36.4525049177878,
              56.657641904114996
            ],
            [
              36.441913,
              56.662402
            ],
            [
              36.44170658210111,
              56.6625649850655
            ],
            [
              36.4417,
              56.66307
            ],
            [
              36.44116651861837,
              56.66330436866788
            ],
            [
              36.4408,
              56.663874
            ],
            [
              36.439390100314995,
              56.66439405094434
            ],
            [
              36.439147,
              56.664586
            ],
            [
              36.438755307616034,
              56.6647208154925
            ],
            [
              36.438695,
              56.664842
            ],
            [
              36.435001,
              56.666013
            ],
            [
              36.42574,
              56.670397
            ],
            [
              36.425367,
              56.670556
            ],
            [
              36.4227095895879,
              56.67151216615299
            ],
            [
              36.422708,
              56.671513
            ],
            [
              36.42270595488858,
              56.67151347395869
            ],
            [
              36.420281,
              56.672386
            ],
            [
              36.420279808984084,
              56.672385665105466
            ],
            [
              36.420051,
              56.672469
            ],
            [
              36.409225,
              56.675135
            ],
            [
              36.400277,
              56.678376
            ],
            [
              36.398799,
              56.6789
            ],
            [
              36.398771327189436,
              56.67882203806194
            ],
            [
              36.394356,
              56.680347
            ],
            [
              36.3925934443989,
              56.68100332629728
            ],
            [
              36.389592,
              56.68215
            ],
            [
              36.38694247805048,
              56.68310758775559
            ],
            [
              36.386936,
              56.68311
            ],
            [
              36.381143,
              56.68639
            ],
            [
              36.378261,
              56.686216
            ],
            [
              36.37826031272577,
              56.68621406753988
            ],
            [
              36.376659,
              56.686787
            ],
            [
              36.375167,
              56.687317
            ],
            [
              36.375135100995855,
              56.687227355077
            ],
            [
              36.370019,
              56.689139
            ],
            [
              36.368295720472766,
              56.68975765138737
            ],
            [
              36.379938,
              56.701698
            ],
            [
              36.385165,
              56.701191
            ],
            [
              36.395578,
              56.701344
            ],
            [
              36.399647,
              56.701729
            ],
            [
              36.401726,
              56.701797
            ],
            [
              36.402844,
              56.705219
            ],
            [
              36.402019,
              56.705488
            ],
            [
              36.399406,
              56.705321
            ],
            [
              36.39525,
              56.704929
            ],
            [
              36.388348,
              56.70277
            ],
            [
              36.378859419247696,
              56.70392052067317
            ],
            [
              36.379928,
              56.706685
            ],
            [
              36.379092,
              56.707008
            ],
            [
              36.372848,
              56.710298
            ],
            [
              36.369091,
              56.712294
            ],
            [
              36.365456,
              56.713048
            ],
            [
              36.361058,
              56.712862
            ],
            [
              36.35092104030191,
              56.712913754056835
            ],
            [
              36.3498347051573,
              56.71329606942962
            ],
            [
              36.34760411058338,
              56.714305230781314
            ],
            [
              36.34733688413996,
              56.71445373391747
            ],
            [
              36.343851,
              56.716743
            ],
            [
              36.338014,
              56.725396
            ],
            [
              36.337983239064975,
              56.72695152455541
            ],
            [
              36.33807007976035,
              56.72997682169891
            ],
            [
              36.345544,
              56.736763
            ],
            [
              36.349598,
              56.736428
            ],
            [
              36.355165,
              56.743702
            ],
            [
              36.363645,
              56.749906
            ],
            [
              36.370868,
              56.746424
            ],
            [
              36.376497,
              56.743574
            ],
            [
              36.380496,
              56.741372
            ],
            [
              36.382209,
              56.744538
            ],
            [
              36.378233,
              56.746728
            ],
            [
              36.374246,
              56.748923
            ],
            [
              36.37202759008099,
              56.75032388478219
            ],
            [
              36.368998,
              56.758993
            ],
            [
              36.370978,
              56.763004
            ],
            [
              36.378102,
              56.766726
            ],
            [
              36.383863862023986,
              56.76808076038987
            ],
            [
              36.384884,
              56.768234
            ],
            [
              36.388312,
              56.769086
            ],
            [
              36.387183,
              56.776007
            ],
            [
              36.384039,
              56.777761
            ],
            [
              36.383446,
              56.776699
            ],
            [
              36.373063,
              56.770976
            ],
            [
              36.37291362333417,
              56.7710086047074
            ],
            [
              36.372915,
              56.771014
            ],
            [
              36.37166294014342,
              56.77133361381307
            ],
            [
              36.370399,
              56.771663
            ],
            [
              36.366594,
              56.772388
            ],
            [
              36.360797,
              56.781855
            ],
            [
              36.366766816989035,
              56.78772801695557
            ],
            [
              36.372111,
              56.78872
            ],
            [
              36.37587,
              56.789214
            ],
            [
              36.379014,
              56.788804
            ],
            [
              36.379479,
              56.792374
            ],
            [
              36.376335,
              56.792784
            ],
            [
              36.367735,
              56.798703
            ],
            [
              36.365184,
              56.796619
            ],
            [
              36.364047,
              56.795332
            ],
            [
              36.361598746953455,
              56.79541244348637
            ],
            [
              36.353395,
              56.797256
            ],
            [
              36.35326680700832,
              56.7970950098332
            ],
            [
              36.353265,
              56.797096
            ],
            [
              36.35217252428084,
              56.79572289781648
            ],
            [
              36.349834,
              56.795848
            ],
            [
              36.349642,
              56.792254
            ],
            [
              36.353607,
              56.790318
            ],
            [
              36.35547019469464,
              56.78234657354805
            ],
            [
              36.348188,
              56.777928
            ],
            [
              36.33903758732419,
              56.783251520428635
            ],
            [
              36.33804325565376,
              56.78567579573941
            ],
            [
              36.336707,
              56.793888
            ],
            [
              36.334313,
              56.797516
            ],
            [
              36.32809511421989,
              56.79540144561294
            ],
            [
              36.326583,
              56.796332
            ],
            [
              36.326255,
              56.796376
            ],
            [
              36.314655,
              56.793475
            ],
            [
              36.310094,
              56.792672
            ],
            [
              36.30296,
              56.790833
            ],
            [
              36.293302,
              56.797429
            ],
            [
              36.290033,
              56.80163
            ],
            [
              36.287832,
              56.804713
            ],
            [
              36.286922,
              56.805123
            ],
            [
              36.286213,
              56.805317
            ],
            [
              36.277098,
              56.805109
            ],
            [
              36.271648,
              56.801953
            ],
            [
              36.267735,
              56.799916
            ],
            [
              36.264243,
              56.797591
            ],
            [
              36.25994,
              56.794805
            ],
            [
              36.2549274580537,
              56.790943817814174
            ],
            [
              36.251097755297685,
              56.791627261294
            ],
            [
              36.248626873799545,
              56.79422782354556
            ],
            [
              36.250238668431024,
              56.80247432280913
            ],
            [
              36.251897,
              56.804124
            ],
            [
              36.252748,
              56.80509
            ],
            [
              36.255752,
              56.807075
            ],
            [
              36.255218,
              56.807882
            ],
            [
              36.249616407834374,
              56.805571580377574
            ],
            [
              36.249443,
              56.80628
            ],
            [
              36.246745,
              56.805619
            ],
            [
              36.244048,
              56.804959
            ],
            [
              36.242249,
              56.803087
            ],
            [
              36.230501,
              56.80545
            ],
            [
              36.23048180996717,
              56.80544090936349
            ],
            [
              36.230454,
              56.805544
            ],
            [
              36.22779151233016,
              56.81065449993728
            ],
            [
              36.22777505986951,
              56.81068791864118
            ],
            [
              36.225679,
              56.815099
            ],
            [
              36.225064142442704,
              56.816367823480114
            ],
            [
              36.224647,
              56.817258
            ],
            [
              36.223022,
              56.820582
            ],
            [
              36.23144510246157,
              56.82870270817252
            ],
            [
              36.233039,
              56.827943
            ],
            [
              36.234299,
              56.827388
            ],
            [
              36.237178,
              56.830228
            ],
            [
              36.23717754920162,
              56.83023311570567
            ],
            [
              36.237292,
              56.830239
            ],
            [
              36.241938,
              56.831033
            ],
            [
              36.246423,
              56.831878
            ],
            [
              36.253311,
              56.833207
            ],
            [
              36.257456,
              56.833999
            ],
            [
              36.261965,
              56.834334
            ],
            [
              36.265602,
              56.834647
            ],
            [
              36.272801,
              56.828845
            ],
            [
              36.27314,
              56.821525
            ],
            [
              36.279291,
              56.820272
            ],
            [
              36.282841,
              56.819874
            ],
            [
              36.279718,
              56.823847
            ],
            [
              36.276477,
              56.829732
            ],
            [
              36.280413,
              56.836562
            ],
            [
              36.285467,
              56.837299
            ],
            [
              36.28958,
              56.837941
            ],
            [
              36.294108,
              56.838576
            ],
            [
              36.298348,
              56.839208
            ],
            [
              36.305836,
              56.840312
            ],
            [
              36.313323,
              56.841416
            ],
            [
              36.320133,
              56.8424
            ],
            [
              36.328331,
              56.842795
            ],
            [
              36.333928,
              56.842708
            ],
            [
              36.337624,
              56.842671
            ],
            [
              36.341319,
              56.842634
            ],
            [
              36.347309,
              56.842573
            ],
            [
              36.357758,
              56.842075
            ],
            [
              36.362934,
              56.841139
            ],
            [
              36.373621,
              56.837992
            ],
            [
              36.375028,
              56.837863
            ],
            [
              36.376571126897545,
              56.83813179027413
            ],
            [
              36.379632,
              56.835541
            ],
            [
              36.383189,
              56.836096
            ],
            [
              36.384562,
              56.836961
            ],
            [
              36.388023386433936,
              56.84054646307997
            ],
            [
              36.393127,
              56.84079
            ],
            [
              36.39629,
              56.837816
            ],
            [
              36.397859,
              56.83519
            ],
            [
              36.398832,
              56.83402
            ],
            [
              36.401599,
              56.836322
            ],
            [
              36.405716,
              56.847335
            ],
            [
              36.417528,
              56.849269
            ],
            [
              36.41922,
              56.849504
            ],
            [
              36.418725,
              56.85307
            ],
            [
              36.417032,
              56.852835
            ],
            [
              36.41497,
              56.851376
            ],
            [
              36.405101,
              56.850882
            ],
            [
              36.397143,
              56.84803
            ],
            [
              36.394942737107556,
              56.848299154296576
            ],
            [
              36.394824,
              56.849103
            ],
            [
              36.391903,
              56.848671
            ],
            [
              36.388057,
              56.84792
            ],
            [
              36.384339,
              56.847112
            ],
            [
              36.38423714765311,
              56.8470805412446
            ],
            [
              36.384237,
              56.847081
            ],
            [
              36.38419817319935,
              56.847068503350094
            ],
            [
              36.380685877194416,
              56.845983673552055
            ],
            [
              36.371403,
              56.845002
            ],
            [
              36.367158,
              56.844694
            ],
            [
              36.356955,
              56.849956
            ],
            [
              36.353234,
              56.850205
            ],
            [
              36.347346,
              56.846173
            ],
            [
              36.341356,
              56.846234
            ],
            [
              36.333964,
              56.846308
            ],
            [
              36.328387,
              56.846395
            ],
            [
              36.319806,
              56.845985
            ],
            [
              36.31717942026445,
              56.84560757908195
            ],
            [
              36.317177,
              56.845624
            ],
            [
              36.312872,
              56.846325
            ],
            [
              36.30531,
              56.843874
            ],
            [
              36.297823,
              56.84277
            ],
            [
              36.293577,
              56.842136
            ],
            [
              36.28908,
              56.841506
            ],
            [
              36.284912,
              56.840856
            ],
            [
              36.279893,
              56.840124
            ],
            [
              36.273183,
              56.837728
            ],
            [
              36.265294,
              56.838234
            ],
            [
              36.25983885877493,
              56.83779954624077
            ],
            [
              36.257973,
              56.837674
            ],
            [
              36.256162,
              56.837433
            ],
            [
              36.252618,
              56.836739
            ],
            [
              36.245752,
              56.835415
            ],
            [
              36.241286,
              56.834574
            ],
            [
              36.23686204204,
              56.83381352292578
            ],
            [
              36.236862,
              56.833814
            ],
            [
              36.228366,
              56.838862
            ],
            [
              36.225869,
              56.841456
            ],
            [
              36.219237716536156,
              56.84008604393231
            ],
            [
              36.220317,
              56.848036
            ],
            [
              36.222294,
              56.851212
            ],
            [
              36.225576,
              56.854166
            ],
            [
              36.22856,
              56.856539
            ],
            [
              36.232297,
              56.859439
            ],
            [
              36.23486403247321,
              56.860882833199696
            ],
            [
              36.240869,
              56.861163
            ],
            [
              36.244344,
              56.860416
            ],
            [
              36.248995,
              56.862344
            ],
            [
              36.253646,
              56.864324
            ],
            [
              36.25567,
              56.867885
            ],
            [
              36.257179,
              56.872448
            ],
            [
              36.257405,
              56.873821
            ],
            [
              36.253853,
              56.874407
            ],
            [
              36.253627,
              56.873034
            ],
            [
              36.252324,
              56.869212
            ],
            [
              36.249745,
              56.866545
            ],
            [
              36.244613,
              56.86423
            ],
            [
              36.238223076746664,
              56.869744567173704
            ],
            [
              36.236126,
              56.872277
            ],
            [
              36.239581581686444,
              56.883196917206995
            ],
            [
              36.242592,
              56.883065
            ],
            [
              36.24926,
              56.88274
            ],
            [
              36.25325,
              56.882575
            ],
            [
              36.257721,
              56.882425
            ],
            [
              36.263824,
              56.882151
            ],
            [
              36.269758,
              56.871855
            ],
            [
              36.269495,
              56.869862
            ],
            [
              36.269337,
              56.868979
            ],
            [
              36.272881,
              56.868345
            ],
            [
              36.281416,
              56.871372
            ],
            [
              36.28923594810901,
              56.87504850545925
            ],
            [
              36.293719,
              56.874846
            ],
            [
              36.298024,
              56.874651
            ],
            [
              36.306379,
              56.874274
            ],
            [
              36.314733,
              56.873896
            ],
            [
              36.320729,
              56.878207
            ],
            [
              36.320964,
              56.881799
            ],
            [
              36.319714,
              56.881881
            ],
            [
              36.318938,
              56.881925
            ],
            [
              36.313012,
              56.882211
            ],
            [
              36.307635,
              56.882442
            ],
            [
              36.303739,
              56.882589
            ],
            [
              36.298028,
              56.882322
            ],
            [
              36.29591825271471,
              56.88255033267141
            ],
            [
              36.295927,
              56.882674
            ],
            [
              36.29156,
              56.887792
            ],
            [
              36.287495479204736,
              56.89046226841416
            ],
            [
              36.289352,
              56.895188
            ],
            [
              36.291463,
              56.899985
            ],
            [
              36.291866,
              56.903738
            ],
            [
              36.288454,
              56.905069
            ],
            [
              36.282142,
              56.915218
            ],
            [
              36.273029,
              56.920104
            ],
            [
              36.268012,
              56.920323
            ],
            [
              36.262995,
              56.920541
            ],
            [
              36.259717,
              56.921533
            ],
            [
              36.252389,
              56.93092
            ],
            [
              36.248961,
              56.932019
            ],
            [
              36.248144,
              56.929472
            ],
            [
              36.246809,
              56.925518
            ],
            [
              36.245527,
              56.918893
            ],
            [
              36.243930728766685,
              56.917643938503154
            ],
            [
              36.235883,
              56.919045
            ],
            [
              36.234588,
              56.918758
            ],
            [
              36.23300056803253,
              56.91855454920321
            ],
            [
              36.229053,
              56.925571
            ],
            [
              36.228886,
              56.928543
            ],
            [
              36.225292,
              56.928342
            ],
            [
              36.218292,
              56.919519
            ],
            [
              36.206801,
              56.91723
            ],
            [
              36.19692,
              56.921272
            ],
            [
              36.194768,
              56.922352
            ],
            [
              36.191552,
              56.923179
            ],
            [
              36.186758,
              56.918857
            ],
            [
              36.185895,
              56.915362
            ],
            [
              36.18493,
              56.910975
            ],
            [
              36.186158,
              56.907591
            ],
            [
              36.195593,
              56.905189
            ],
            [
              36.204726,
              56.900255
            ],
            [
              36.207894,
              56.898545
            ],
            [
              36.20809807745767,
              56.89855685601002
            ],
            [
              36.20922,
              56.897961
            ],
            [
              36.212662,
              56.898822
            ],
            [
              36.224259,
              56.89599
            ],
            [
              36.229648,
              56.885607
            ],
            [
              36.228614362666896,
              56.87831766181887
            ],
            [
              36.228305,
              56.876292
            ],
            [
              36.229943,
              56.873609
            ],
            [
              36.230017,
              56.862225
            ],
            [
              36.22634,
              56.859372
            ],
            [
              36.222115,
              56.856083
            ],
            [
              36.211474,
              56.853648
            ],
            [
              36.202313,
              56.855885
            ],
            [
              36.195532,
              56.858887
            ],
            [
              36.19151,
              56.860958
            ],
            [
              36.19140747568309,
              56.86099647237502
            ],
            [
              36.190028,
              56.861702
            ],
            [
              36.183027,
              56.870366
            ],
            [
              36.176731,
              56.87774
            ],
            [
              36.173993,
              56.875402
            ],
            [
              36.173537045813845,
              56.87007815356036
            ],
            [
              36.171352,
              56.871554
            ],
            [
              36.168581,
              56.874862
            ],
            [
              36.166091,
              56.877934
            ],
            [
              36.163591,
              56.881021
            ],
            [
              36.160525,
              56.884727
            ],
            [
              36.163924,
              56.895722
            ],
            [
              36.164954,
              56.895951
            ],
            [
              36.164171,
              56.899465
            ],
            [
              36.16160266198106,
              56.899473328528195
            ],
            [
              36.155896,
              56.903982
            ],
            [
              36.149605,
              56.90484
            ],
            [
              36.146543,
              56.902806
            ],
            [
              36.134835,
              56.900713
            ],
            [
              36.127494,
              56.903785
            ],
            [
              36.123908,
              56.904107
            ],
            [
              36.120897,
              56.898379
            ],
            [
              36.124008,
              56.892569
            ],
            [
              36.130597,
              56.885915
            ],
            [
              36.134182,
              56.88559
            ],
            [
              36.135846,
              56.888098
            ],
            [
              36.141391,
              56.896125
            ],
            [
              36.14306172686463,
              56.896476586837615
            ],
            [
              36.143062,
              56.896474
            ],
            [
              36.144585,
              56.896635
            ],
            [
              36.152867,
              56.892421
            ],
            [
              36.15383,
              56.888952
            ],
            [
              36.157751,
              56.882432
            ],
            [
              36.160817,
              56.878726
            ],
            [
              36.163294,
              56.875668
            ],
            [
              36.165784,
              56.872596
            ],
            [
              36.168592,
              56.869243
            ],
            [
              36.172956,
              56.864934
            ],
            [
              36.17309129386582,
              56.86487343125811
            ],
            [
              36.173091,
              56.86487
            ],
            [
              36.177706055351514,
              56.8628074817371
            ],
            [
              36.17848,
              56.862461
            ],
            [
              36.181929,
              56.852961
            ],
            [
              36.18310841422663,
              56.852716121622436
            ],
            [
              36.183218,
              56.852323
            ],
            [
              36.18669733392933,
              56.851797380907584
            ],
            [
              36.18767777158334,
              56.85145526359942
            ],
            [
              36.194588,
              56.845044
            ],
            [
              36.198001,
              56.840682
            ],
            [
              36.19308066117064,
              56.833461147410894
            ],
            [
              36.19169564138415,
              56.83286427640299
            ],
            [
              36.188333,
              56.832967
            ],
            [
              36.172085,
              56.833481
            ],
            [
              36.167658,
              56.83362
            ],
            [
              36.158486,
              56.835634
            ],
            [
              36.152771,
              56.838478
            ],
            [
              36.149376,
              56.840143
            ],
            [
              36.144514,
              56.84257
            ],
            [
              36.140973,
              56.844387
            ],
            [
              36.137031,
              56.846308
            ],
            [
              36.133086,
              56.848262
            ],
            [
              36.129125,
              56.850224
            ],
            [
              36.125281,
              56.852082
            ],
            [
              36.12527897187501,
              56.85207780655714
            ],
            [
              36.125096,
              56.852166
            ],
            [
              36.118855,
              56.855781
            ],
            [
              36.116931413397445,
              56.85675642663772
            ],
            [
              36.113752,
              56.858397
            ],
            [
              36.109452,
              56.860533
            ],
            [
              36.104968,
              56.862847
            ],
            [
              36.100224,
              56.86517
            ],
            [
              36.095319,
              56.868205
            ],
            [
              36.09430150223877,
              56.86863946973677
            ],
            [
              36.094092,
              56.868775
            ],
            [
              36.089689,
              56.870609
            ],
            [
              36.08625,
              56.872188
            ],
            [
              36.082213,
              56.874436
            ],
            [
              36.078318,
              56.876605
            ],
            [
              36.077011,
              56.877288
            ],
            [
              36.076992258565966,
              56.87725213606805
            ],
            [
              36.075783,
              56.877952
            ],
            [
              36.071697,
              56.879905
            ],
            [
              36.070766,
              56.880393
            ],
            [
              36.070174,
              56.880623
            ],
            [
              36.07011246144567,
              56.88046433262143
            ],
            [
              36.068357,
              56.881084
            ],
            [
              36.060319,
              56.889386
            ],
            [
              36.059994,
              56.889511
            ],
            [
              36.056753,
              56.888615
            ],
            [
              36.047542,
              56.883042
            ],
            [
              36.0492,
              56.879846
            ],
            [
              36.050063,
              56.88011
            ],
            [
              36.059924,
              56.880858
            ],
            [
              36.064926,
              56.878799
            ],
            [
              36.068506,
              56.876895
            ],
            [
              36.072183,
              56.87564
            ],
            [
              36.076642,
              56.87335
            ],
            [
              36.080278,
              56.871139
            ],
            [
              36.083453,
              56.869488
            ],
            [
              36.08679,
              56.867845
            ],
            [
              36.090908,
              56.866233
            ],
            [
              36.094786,
              56.863963
            ],
            [
              36.098617,
              56.861949
            ],
            [
              36.103143,
              56.859744
            ],
            [
              36.106464,
              56.857973
            ],
            [
              36.109761,
              56.85633
            ],
            [
              36.113432,
              56.854495
            ],
            [
              36.117226,
              56.85257
            ],
            [
              36.12371863397884,
              56.848851581424015
            ],
            [
              36.123714,
              56.848842
            ],
            [
              36.125636,
              56.847912
            ],
            [
              36.127558,
              56.846983
            ],
            [
              36.131488,
              56.845036
            ],
            [
              36.127365,
              56.834526
            ],
            [
              36.123357,
              56.83468
            ],
            [
              36.119699,
              56.835097
            ],
            [
              36.115264,
              56.839221
            ],
            [
              36.111239,
              56.838414
            ],
            [
              36.11043877645456,
              56.83835454175459
            ],
            [
              36.108399,
              56.838255
            ],
            [
              36.101434,
              56.83764
            ],
            [
              36.091614,
              56.836359
            ],
            [
              36.084346,
              56.836825
            ],
            [
              36.078851,
              56.836385
            ],
            [
              36.067133,
              56.839366
            ],
            [
              36.060508,
              56.84105
            ],
            [
              36.053167,
              56.84686
            ],
            [
              36.049967,
              56.847753
            ],
            [
              36.043365,
              56.850276
            ],
            [
              36.04269,
              56.850617
            ],
            [
              36.04255333135594,
              56.850624321534504
            ],
            [
              36.039305,
              56.857083
            ],
            [
              36.039116,
              56.861943
            ],
            [
              36.039075,
              56.861999
            ],
            [
              36.037208,
              56.862628
            ],
            [
              36.036994,
              56.862572
            ],
            [
              36.029711,
              56.857505
            ],
            [
              36.022359,
              56.858016
            ],
            [
              36.019329,
              56.858325
            ],
            [
              36.018589,
              56.858411
            ],
            [
              36.012457,
              56.857569
            ],
            [
              36.011053,
              56.857404
            ],
            [
              36.010431,
              56.856826
            ],
            [
              36.008847,
              56.853505
            ],
            [
              36.00868,
              56.849908
            ],
            [
              36.011256,
              56.842429
            ],
            [
              36.01207,
              56.840698
            ],
            [
              36.014954,
              56.841302
            ],
            [
              36.015589,
              56.841459
            ],
            [
              36.02089,
              56.84435
            ],
            [
              36.028348,
              56.843316
            ],
            [
              36.032092377751056,
              56.84378239395672
            ],
            [
              36.032082,
              56.843593
            ],
            [
              36.032088,
              56.843187
            ],
            [
              36.032128,
              56.842652
            ],
            [
              36.033112,
              56.842294
            ],
            [
              36.03407,
              56.842064
            ],
            [
              36.0424133800446,
              56.83951591955426
            ],
            [
              36.044007,
              56.836139
            ],
            [
              36.047922,
              56.835802
            ],
            [
              36.057813,
              56.83012
            ],
            [
              36.062123,
              56.829492
            ],
            [
              36.064236,
              56.82915
            ],
            [
              36.066307,
              56.829075
            ],
            [
              36.071985,
              56.836268
            ],
            [
              36.077966,
              56.832895
            ],
            [
              36.08036,
              56.830123
            ],
            [
              36.083184,
              56.822549
            ],
            [
              36.079283,
              56.811913
            ],
            [
              36.07928616688206,
              56.811912454440986
            ],
            [
              36.078943,
              56.811479
            ],
            [
              36.071661,
              56.808625
            ],
            [
              36.071163,
              56.808266
            ],
            [
              36.070769,
              56.807754
            ],
            [
              36.070759,
              56.807741
            ],
            [
              36.070358,
              56.80723
            ],
            [
              36.062023,
              56.800363
            ],
            [
              36.058569,
              56.799152
            ],
            [
              36.04985069203615,
              56.797954743917906
            ],
            [
              36.046906,
              56.798094
            ],
            [
              36.036888,
              56.80405
            ],
            [
              36.035626,
              56.805129
            ],
            [
              36.035038,
              56.805386
            ],
            [
              36.030235,
              56.804758
            ],
            [
              36.02961407967111,
              56.803845763162926
            ],
            [
              36.02465815809997,
              56.80480070091516
            ],
            [
              36.021079,
              56.806554
            ],
            [
              36.01475764949533,
              56.80763554607617
            ],
            [
              36.009359,
              56.810861
            ],
            [
              36.00798,
              56.811022
            ],
            [
              36.007124636771096,
              56.80991260032341
            ],
            [
              36.003373,
              56.812026
            ],
            [
              35.998651,
              56.811789
            ],
            [
              35.994908498121056,
              56.81285867975686
            ],
            [
              35.993719522013095,
              56.81389731724448
            ],
            [
              35.987631,
              56.820611
            ],
            [
              35.9832964632704,
              56.82096258635447
            ],
            [
              35.978006,
              56.822404
            ],
            [
              35.97735783455204,
              56.82297335135392
            ],
            [
              35.973965,
              56.831149
            ],
            [
              35.973951,
              56.831189
            ],
            [
              35.971992172993296,
              56.83193870866617
            ],
            [
              35.972935,
              56.840428
            ],
            [
              35.973838,
              56.849925
            ],
            [
              35.973455,
              56.850415
            ],
            [
              35.969813290235166,
              56.85071941220475
            ],
            [
              35.972656,
              56.858389
            ],
            [
              35.978955,
              56.855512
            ],
            [
              35.979341,
              56.855344
            ],
            [
              35.981579,
              56.856992
            ],
            [
              35.980123,
              56.861658
            ],
            [
              35.977636,
              56.867801
            ],
            [
              35.970942,
              56.872025
            ],
            [
              35.969843,
              56.872435
            ],
            [
              35.969565,
              56.872515
            ],
            [
              35.963323,
              56.870937
            ],
            [
              35.956381,
              56.878487
            ],
            [
              35.954743,
              56.878753
            ],
            [
              35.943377,
              56.880529
            ],
            [
              35.941262,
              56.881453
            ],
            [
              35.93945,
              56.881685
            ],
            [
              35.936047,
              56.880912
            ],
            [
              35.932676,
              56.879647
            ],
            [
              35.927842,
              56.877416
            ],
            [
              35.926126,
              56.876681
            ],
            [
              35.922715,
              56.874858
            ],
            [
              35.914173,
              56.873661
            ],
            [
              35.913209,
              56.873773
            ],
            [
              35.905636,
              56.871168
            ],
            [
              35.901032,
              56.869591
            ],
            [
              35.898626,
              56.868622
            ],
            [
              35.889519,
              56.864526
            ],
            [
              35.8883,
              56.861906
            ],
            [
              35.883625,
              56.852265
            ],
            [
              35.8832,
              56.849593
            ],
            [
              35.883067,
              56.848709
            ],
            [
              35.884555,
              56.845955
            ],
            [
              35.885459,
              56.844352
            ],
            [
              35.889644,
              56.8363
            ],
            [
              35.887106,
              56.827282
            ],
            [
              35.878262,
              56.822593
            ],
            [
              35.876955,
              56.819239
            ],
            [
              35.874449,
              56.81269
            ],
            [
              35.869705,
              56.805706
            ],
            [
              35.864306,
              56.802648
            ],
            [
              35.854234,
              56.802745
            ],
            [
              35.849537,
              56.805291
            ],
            [
              35.847053,
              56.806845
            ],
            [
              35.84457,
              56.808399
            ],
            [
              35.84266,
              56.805348
            ],
            [
              35.845144,
              56.803794
            ],
            [
              35.847627,
              56.80224
            ],
            [
              35.850565,
              56.800503
            ],
            [
              35.858064,
              56.792584
            ],
            [
              35.861583,
              56.793346
            ],
            [
              35.872953,
              56.794342
            ],
            [
              35.877174,
              56.793577
            ],
            [
              35.888264,
              56.791518
            ],
            [
              35.897416,
              56.780892
            ],
            [
              35.898035,
              56.779974
            ],
            [
              35.900006619674954,
              56.7786316657658
            ],
            [
              35.899676,
              56.775342
            ],
            [
              35.899243,
              56.770566
            ],
            [
              35.901269,
              56.765895
            ],
            [
              35.90162471711197,
              56.76570759236613
            ],
            [
              35.900269,
              56.76031
            ],
            [
              35.890583,
              56.755438
            ],
            [
              35.889853,
              56.754343
            ],
            [
              35.889573,
              56.753777
            ],
            [
              35.890244,
              56.751854
            ],
            [
              35.8993,
              56.744508
            ],
            [
              35.901306,
              56.745776
            ],
            [
              35.902351,
              56.747014
            ],
            [
              35.90396,
              56.751431
            ],
            [
              35.913417,
              56.754399
            ],
            [
              35.91876,
              56.754104
            ],
            [
              35.920725,
              56.754981
            ],
            [
              35.921621,
              56.756202
            ],
            [
              35.918719,
              56.758333
            ],
            [
              35.92028139452364,
              56.76810910172083
            ],
            [
              35.924977,
              56.77115
            ],
            [
              35.929024,
              56.772746
            ],
            [
              35.939276,
              56.7745
            ],
            [
              35.943449,
              56.775239
            ],
            [
              35.948583,
              56.775218
            ],
            [
              35.953189,
              56.765374
            ],
            [
              35.956495,
              56.761032
            ],
            [
              35.958060511688835,
              56.75946558234374
            ],
            [
              35.954046,
              56.75059
            ],
            [
              35.95514079629786,
              56.747824091391664
            ],
            [
              35.954596,
              56.747118
            ],
            [
              35.955782,
              56.74552
            ],
            [
              35.956638,
              56.744416
            ],
            [
              35.960876,
              56.740967
            ],
            [
              35.963372,
              56.738955
            ],
            [
              35.96517346362098,
              56.738490830672205
            ],
            [
              35.96831,
              56.735072
            ],
            [
              35.971211,
              56.728748
            ],
            [
              35.974664,
              56.725548
            ],
            [
              35.979504,
              56.724613
            ],
            [
              35.980493,
              56.724548
            ],
            [
              35.984518,
              56.726488
            ],
            [
              35.994537,
              56.72105
            ],
            [
              35.994811,
              56.720647
            ],
            [
              35.997788,
              56.72267
            ],
            [
              36.001757,
              56.729836
            ],
            [
              36.01033,
              56.725838
            ],
            [
              36.010664,
              56.722291
            ],
            [
              36.010038,
              56.720062
            ],
            [
              36.009678,
              56.719097
            ],
            [
              36.01305,
              56.717836
            ],
            [
              36.02027797697804,
              56.70905930671237
            ],
            [
              36.020133,
              56.708987
            ],
            [
              36.018318,
              56.705674
            ],
            [
              36.016132712693654,
              56.70479920593008
            ],
            [
              36.006391,
              56.703239
            ],
            [
              36.00505874007474,
              56.698980734441996
            ],
            [
              35.998526,
              56.69405
            ],
            [
              35.99510892564995,
              56.694507928538854
            ],
            [
              35.994970790188404,
              56.69453236788974
            ],
            [
              35.991357,
              56.696193
            ],
            [
              35.990776,
              56.696135
            ],
            [
              35.987177,
              56.696047
            ],
            [
              35.98719,
              56.695529
            ],
            [
              35.989125,
              56.69309
            ],
            [
              35.992417,
              56.69156
            ],
            [
              35.994314755777545,
              56.691101242791035
            ],
            [
              35.994296,
              56.690995
            ],
            [
              35.995987,
              56.690697
            ],
            [
              36.000967,
              56.689886
            ],
            [
              36.006528,
              56.691721
            ],
            [
              36.0127521731486,
              56.69059314578609
            ],
            [
              36.017655,
              56.688156
            ],
            [
              36.01780655458698,
              56.6906837335446
            ],
            [
              36.025425,
              56.697894
            ],
            [
              36.029296,
              56.698725
            ],
            [
              36.033101,
              56.699364
            ],
            [
              36.036877,
              56.698954
            ],
            [
              36.041326,
              56.697775
            ],
            [
              36.045771,
              56.692385
            ],
            [
              36.042779,
              56.685196
            ],
            [
              36.046184,
              56.682781
            ],
            [
              36.05193,
              56.6817
            ],
            [
              36.061587,
              56.678751
            ],
            [
              36.065221,
              56.676676
            ],
            [
              36.067759971438406,
              56.67576977460288
            ],
            [
              36.073907,
              56.668703
            ],
            [
              36.07443414039328,
              56.668472418736044
            ],
            [
              36.075012,
              56.6674
            ],
            [
              36.078541,
              56.666676
            ],
            [
              36.079257120969906,
              56.66652901526112
            ],
            [
              36.081594,
              56.666049
            ],
            [
              36.08159407246127,
              56.66604935299849
            ],
            [
              36.082083,
              56.665949
            ],
            [
              36.087176,
              56.666378
            ],
            [
              36.08706273339969,
              56.66664747588675
            ],
            [
              36.087316,
              56.666683
            ],
            [
              36.08402030837556,
              56.67524267629017
            ],
            [
              36.085493,
              56.680145
            ],
            [
              36.088274987033614,
              56.683056571445924
            ],
            [
              36.090262,
              56.684728
            ],
            [
              36.09026663120211,
              56.684734330183126
            ],
            [
              36.09204,
              56.686167
            ],
            [
              36.094175,
              56.689059
            ],
            [
              36.096447,
              56.693182
            ],
            [
              36.10691706238171,
              56.692820380993744
            ],
            [
              36.112839,
              56.686958
            ],
            [
              36.112935,
              56.686883
            ],
            [
              36.119419,
              56.678338
            ],
            [
              36.120165,
              56.674816
            ],
            [
              36.125038,
              56.67581
            ],
            [
              36.128816,
              56.67658
            ],
            [
              36.132595,
              56.67735
            ],
            [
              36.138351,
              56.678538
            ],
            [
              36.143747,
              56.679684
            ],
            [
              36.14453984167524,
              56.679842789387564
            ],
            [
              36.14454,
              56.679842
            ],
            [
              36.152374,
              56.681412
            ],
            [
              36.15304090822419,
              56.68154745158307
            ],
            [
              36.153041,
              56.681547
            ],
            [
              36.157541250692454,
              56.68246124474536
            ],
            [
              36.163243,
              56.683506
            ],
            [
              36.16957,
              56.683471
            ],
            [
              36.179789,
              56.679076
            ],
            [
              36.183285,
              56.674061
            ],
            [
              36.178127,
              56.665539
            ],
            [
              36.173471,
              56.664581
            ],
            [
              36.168844,
              56.663629
            ],
            [
              36.158741,
              56.661577
            ],
            [
              36.150317,
              56.659843
            ],
            [
              36.151043,
              56.656317
            ],
            [
              36.159467,
              56.658051
            ],
            [
              36.169513,
              56.660092
            ],
            [
              36.174197,
              56.661055
            ],
            [
              36.181112,
              56.651959
            ],
            [
              36.18207920515232,
              56.650696564092584
            ],
            [
              36.180998,
              56.650148
            ],
            [
              36.183634712357815,
              56.642607031441734
            ],
            [
              36.183702120299714,
              56.6395531585954
            ],
            [
              36.18143681678668,
              56.63927977439869
            ],
            [
              36.175947,
              56.638824
            ],
            [
              36.165788,
              56.634517
            ],
            [
              36.166536352440254,
              56.634099994591956
            ],
            [
              36.166497,
              56.634082
            ],
            [
              36.171686,
              56.628067
            ],
            [
              36.170439,
              56.617411
            ],
            [
              36.166376,
              56.615827
            ],
            [
              36.165442,
              56.607785
            ],
            [
              36.162559,
              56.597088
            ],
            [
              36.160065,
              56.594611
            ],
            [
              36.158323,
              56.58716
            ],
            [
              36.155089,
              56.576062
            ],
            [
              36.15676,
              56.572715
            ],
            [
              36.157099,
              56.572178
            ],
            [
              36.160143,
              56.5741
            ],
            [
              36.16316,
              56.575101
            ],
            [
              36.162873,
              56.57869
            ],
            [
              36.16287,
              56.587534
            ],
            [
              36.166559,
              56.596217
            ],
            [
              36.171696,
              56.6028
            ],
            [
              36.17150717483156,
              56.60440512513617
            ],
            [
              36.17213,
              56.604564
            ],
            [
              36.171463,
              56.607178
            ],
            [
              36.175233,
              56.616369
            ],
            [
              36.177881,
              56.619386
            ],
            [
              36.180736,
              56.623777
            ],
            [
              36.188179,
              56.629024
            ],
            [
              36.192896,
              56.627618
            ],
            [
              36.192924274720625,
              56.62762296375463
            ],
            [
              36.1929567052649,
              56.627613388678235
            ],
            [
              36.193334,
              56.625871
            ],
            [
              36.195572,
              56.625962
            ],
            [
              36.195678,
              56.625986
            ],
            [
              36.197027,
              56.626232
            ],
            [
              36.205290798947296,
              56.625483564147345
            ],
            [
              36.205969,
              56.624732
            ],
            [
              36.207957,
              56.623054
            ],
            [
              36.208708,
              56.622565
            ],
            [
              36.213323,
              56.620284
            ],
            [
              36.216342,
              56.619437
            ],
            [
              36.21763484481703,
              56.61906712667229
            ],
            [
              36.219382,
              56.613147
            ],
            [
              36.22,
              56.6096
            ],
            [
              36.22329452207009,
              56.60920826381173
            ],
            [
              36.222074,
              56.608276
            ],
            [
              36.218039,
              56.605649
            ],
            [
              36.211098,
              56.604223
            ],
            [
              36.204156,
              56.606567
            ],
            [
              36.20276851836534,
              56.60695230708221
            ],
            [
              36.201213,
              56.607666
            ],
            [
              36.199141199384634,
              56.60795962253619
            ],
            [
              36.198254,
              56.608206
            ],
            [
              36.194143,
              56.609001
            ],
            [
              36.19397321483385,
              56.6084730020287
            ],
            [
              36.193139,
              56.608542
            ],
            [
              36.189717,
              56.607424
            ],
            [
              36.189334,
              56.603543
            ],
            [
              36.188377,
              56.600042
            ],
            [
              36.188158,
              56.599593
            ],
            [
              36.191393,
              56.598013
            ],
            [
              36.193625,
              56.599744
            ],
            [
              36.201532862073044,
              56.60270356763361
            ],
            [
              36.206306,
              56.596102
            ],
            [
              36.207411,
              56.590792
            ],
            [
              36.207895,
              56.588735
            ],
            [
              36.2101653891004,
              56.58475910069654
            ],
            [
              36.206347,
              56.584109
            ],
            [
              36.205037,
              56.583771
            ],
            [
              36.205937,
              56.580285
            ],
            [
              36.20613354763117,
              56.58007279448627
            ],
            [
              36.204415,
              56.574456
            ],
            [
              36.198283,
              56.568448
            ],
            [
              36.191671,
              56.566049
            ],
            [
              36.183196,
              56.565717
            ],
            [
              36.176925,
              56.565075
            ],
            [
              36.174793,
              56.565066
            ],
            [
              36.172662,
              56.565057
            ],
            [
              36.172677,
              56.561457
            ],
            [
              36.174808,
              56.561466
            ],
            [
              36.17694,
              56.561475
            ],
            [
              36.183557,
              56.562135
            ],
            [
              36.192493,
              56.562007
            ],
            [
              36.196439,
              56.561865
            ],
            [
              36.20195,
              56.566189
            ],
            [
              36.205665,
              56.568591
            ],
            [
              36.207809,
              56.572913
            ],
            [
              36.209741,
              56.576523
            ],
            [
              36.21553616495199,
              56.57892764434875
            ],
            [
              36.219928,
              56.579038
            ],
            [
              36.221071,
              56.578917
            ],
            [
              36.2212595281288,
              56.58070776718623
            ],
            [
              36.221641,
              56.580667
            ],
            [
              36.221081,
              56.58907
            ],
            [
              36.220548,
              56.595227
            ],
            [
              36.223256,
              56.604821
            ],
            [
              36.226931,
              56.607213
            ],
            [
              36.228395884246275,
              56.60894090717634
            ],
            [
              36.229954,
              56.609015
            ],
            [
              36.229923,
              56.609656
            ],
            [
              36.229699,
              56.610478
            ],
            [
              36.229658598859366,
              56.61492691882536
            ],
            [
              36.230404,
              56.614493
            ],
            [
              36.230892,
              56.61533
            ],
            [
              36.241593,
              56.61945
            ],
            [
              36.246285,
              56.620145
            ],
            [
              36.250465,
              56.620859
            ],
            [
              36.25073328657059,
              56.62094301947076
            ],
            [
              36.250736,
              56.620934
            ],
            [
              36.252666,
              56.621514
            ],
            [
              36.258946,
              56.623515
            ],
            [
              36.264803,
              56.625107
            ],
            [
              36.270432,
              56.626265
            ],
            [
              36.27101724645155,
              56.626629722753684
            ],
            [
              36.274672,
              56.625846
            ],
            [
              36.275593,
              56.626275
            ],
            [
              36.27924423748883,
              56.63114412213103
            ],
            [
              36.286088,
              56.633641
            ],
            [
              36.29305626943048,
              56.634245236252205
            ],
            [
              36.299288,
              56.631004
            ],
            [
              36.301823510499865,
              56.62445131458279
            ],
            [
              36.30237727647687,
              56.618967078293956
            ],
            [
              36.302086,
              56.618309
            ],
            [
              36.303912,
              56.612485
            ],
            [
              36.307826,
              56.60882
            ],
            [
              36.3114,
              56.607426
            ],
            [
              36.313597,
              56.60678
            ],
            [
              36.31565443753248,
              56.60619539355173
            ],
            [
              36.315801,
              56.606153
            ],
            [
              36.319232,
              56.597914
            ],
            [
              36.320478,
              56.594089
            ],
            [
              36.3206509729945,
              56.59428742338326
            ],
            [
              36.322468,
              56.591641
            ],
            [
              36.327215,
              56.601001
            ],
            [
              36.328350188644805,
              56.602644311225404
            ],
            [
              36.328444,
              56.602752
            ],
            [
              36.329496,
              56.604303
            ],
            [
              36.333626,
              56.609292
            ],
            [
              36.33808677730658,
              56.61112701836953
            ],
            [
              36.348362,
              56.610162
            ],
            [
              36.34899943609107,
              56.609992160743
            ],
            [
              36.353272,
              56.608155
            ],
            [
              36.3565031802629,
              56.60799285390617
            ],
            [
              36.356938,
              56.607877
            ],
            [
              36.35732731584584,
              56.6079514973879
            ],
            [
              36.359051,
              56.607865
            ],
            [
              36.36034160505313,
              56.608528295570466
            ],
            [
              36.36057,
              56.608572
            ],
            [
              36.36065257384882,
              56.608688115357495
            ],
            [
              36.363534,
              56.610169
            ],
            [
              36.36891012579576,
              56.61758915317098
            ],
            [
              36.377244,
              56.619088
            ],
            [
              36.37906251166168,
              56.61958421199417
            ],
            [
              36.379492,
              56.618909
            ],
            [
              36.379872,
              56.618865
            ],
            [
              36.381037,
              56.619106
            ],
            [
              36.388297,
              56.622104
            ],
            [
              36.38831429272617,
              56.622107552466396
            ],
            [
              36.388315,
              56.622104
            ],
            [
              36.38832596324577,
              56.62210618460765
            ],
            [
              36.388326,
              56.622106
            ],
            [
              36.391587,
              56.622756
            ],
            [
              36.393044104523234,
              56.62305979713244
            ],
            [
              36.40240335720555,
              56.62386636092126
            ],
            [
              36.402546991909766,
              56.62384190317957
            ],
            [
              36.40810773429949,
              56.62059629576701
            ],
            [
              36.41011198514141,
              56.61903865702552
            ],
            [
              36.412014,
              56.612321
            ],
            [
              36.412782032788755,
              56.61137147536408
            ],
            [
              36.412892,
              56.608676
            ],
            [
              36.414145943109695,
              56.609685262913416
            ],
            [
              36.414278,
              56.609522
            ],
            [
              36.414984,
              56.610094
            ],
            [
              36.42364611416936,
              56.61451344600478
            ],
            [
              36.426381858909,
              56.615267982053936
            ],
            [
              36.42996264768169,
              56.61556397892676
            ],
            [
              36.431237510703504,
              56.61564023241591
            ],
            [
              36.432076,
              56.61569
            ],
            [
              36.432082293541306,
              56.615690761482846
            ],
            [
              36.432103,
              56.615692
            ],
            [
              36.432108068950185,
              56.615692613858464
            ],
            [
              36.433201,
              56.615758
            ],
            [
              36.437916,
              56.61605
            ],
            [
              36.447024,
              56.613659
            ],
            [
              36.450547,
              56.608838
            ],
            [
              36.454382,
              56.603496
            ],
            [
              36.459676,
              56.596079
            ],
            [
              36.45297,
              56.589358
            ],
            [
              36.451494,
              56.580508
            ],
            [
              36.441933,
              56.576545
            ],
            [
              36.433721,
              56.576084
            ],
            [
              36.4296146877513,
              56.57192505980392
            ],
            [
              36.420676,
              56.57057
            ],
            [
              36.419488196350066,
              56.56818462457801
            ],
            [
              36.418528,
              56.566817
            ],
            [
              36.41617995340049,
              56.56188936980031
            ],
            [
              36.409935281274834,
              56.5587488992267
            ],
            [
              36.404317,
              56.558777
            ],
            [
              36.398408,
              56.558794
            ],
            [
              36.394551,
              56.557259
            ],
            [
              36.384384,
              56.554517
            ],
            [
              36.376367,
              56.55969
            ],
            [
              36.376078,
              56.563279
            ],
            [
              36.375298,
              56.563216
            ],
            [
              36.372509,
              56.562753
            ],
            [
              36.369439,
              56.55984
            ],
            [
              36.360564,
              56.559637
            ],
            [
              36.359918,
              56.559618
            ],
            [
              36.3584551119511,
              56.558978745418955
            ],
            [
              36.357641,
              56.559089
            ],
            [
              36.356497,
              56.559073
            ],
            [
              36.346407,
              56.555555
            ],
            [
              36.344251,
              56.552672
            ],
            [
              36.33788321812195,
              56.54532235677348
            ],
            [
              36.330437,
              56.5437
            ],
            [
              36.329112744204636,
              56.542977678657074
            ],
            [
              36.325644,
              56.543117
            ],
            [
              36.324573,
              56.542454
            ],
            [
              36.32544666996519,
              56.540978001799196
            ],
            [
              36.320636,
              56.538354
            ],
            [
              36.315535,
              56.537835
            ],
            [
              36.305749,
              56.533006
            ],
            [
              36.300325,
              56.531206
            ],
            [
              36.300113,
              56.530626
            ],
            [
              36.299929064266784,
              56.52996922525638
            ],
            [
              36.293484,
              56.528059
            ],
            [
              36.285072502523384,
              56.52509258786709
            ],
            [
              36.285067698073554,
              56.52509117745763
            ],
            [
              36.275712,
              56.53059
            ],
            [
              36.27476465580829,
              56.53284205287554
            ],
            [
              36.274678,
              56.533111
            ],
            [
              36.27464113979024,
              56.53313567857785
            ],
            [
              36.273692,
              56.535392
            ],
            [
              36.268565,
              56.536675
            ],
            [
              36.26354,
              56.530206
            ],
            [
              36.26195749947997,
              56.52874437049538
            ],
            [
              36.252857,
              56.524464
            ],
            [
              36.248427,
              56.523227
            ],
            [
              36.240373,
              56.524511
            ],
            [
              36.236582,
              56.527064
            ],
            [
              36.22725536875056,
              56.53397378967673
            ],
            [
              36.225542,
              56.535882
            ],
            [
              36.225017096651484,
              56.535850442011665
            ],
            [
              36.224201,
              56.536538
            ],
            [
              36.220338122520666,
              56.53556913496446
            ],
            [
              36.219288,
              56.535506
            ],
            [
              36.21922767921447,
              56.53529061986812
            ],
            [
              36.218368,
              56.535075
            ],
            [
              36.21831142571406,
              56.534902881128964
            ],
            [
              36.218211,
              56.534875
            ],
            [
              36.214779,
              56.524156
            ],
            [
              36.212334684286084,
              56.5224836346471
            ],
            [
              36.207875,
              56.520568
            ],
            [
              36.207747,
              56.519979
            ],
            [
              36.211264,
              56.51921
            ],
            [
              36.219314645262386,
              56.51504353422612
            ],
            [
              36.213952,
              56.506493
            ],
            [
              36.20943,
              56.505719
            ],
            [
              36.205947,
              56.506507
            ],
            [
              36.202424,
              56.506139
            ],
            [
              36.19685,
              56.503949
            ],
            [
              36.193026,
              56.502931
            ],
            [
              36.187933,
              56.500991
            ],
            [
              36.179204,
              56.508371
            ],
            [
              36.175616,
              56.508068
            ],
            [
              36.174744,
              56.507722
            ],
            [
              36.168123,
              56.501107
            ],
            [
              36.165303,
              56.49993
            ],
            [
              36.16538292954334,
              56.49945550187481
            ],
            [
              36.163909,
              56.499065
            ],
            [
              36.160677,
              56.496237
            ],
            [
              36.151278,
              56.496468
            ],
            [
              36.150542478452984,
              56.49676941798686
            ],
            [
              36.147885,
              56.49913
            ],
            [
              36.143865,
              56.500342
            ],
            [
              36.137882,
              56.497608
            ],
            [
              36.133702,
              56.498198
            ],
            [
              36.132225929694805,
              56.509457076415586
            ],
            [
              36.132908843936384,
              56.510044519823715
            ],
            [
              36.136886,
              56.512003
            ],
            [
              36.141361,
              56.513597
            ],
            [
              36.147049,
              56.51521
            ],
            [
              36.151516,
              56.515663
            ],
            [
              36.152173123030195,
              56.51567942807576
            ],
            [
              36.153905,
              56.515493
            ],
            [
              36.158494,
              56.51601
            ],
            [
              36.158690439302305,
              56.51603816051092
            ],
            [
              36.16121,
              56.516287
            ],
            [
              36.1611834607638,
              56.51639554702804
            ],
            [
              36.162226,
              56.516545
            ],
            [
              36.160355,
              56.519784
            ],
            [
              36.155695,
              56.51936
            ],
            [
              36.150112,
              56.519512
            ],
            [
              36.14937203769423,
              56.51936283868796
            ],
            [
              36.149363,
              56.519404
            ],
            [
              36.146947976995904,
              56.518874197480955
            ],
            [
              36.144541,
              56.518389
            ],
            [
              36.14399730734326,
              56.51821572414745
            ],
            [
              36.143994,
              56.518226
            ],
            [
              36.141523,
              56.525507
            ],
            [
              36.140085,
              56.525548
            ],
            [
              36.135829,
              56.525849
            ],
            [
              36.132345,
              56.524965
            ],
            [
              36.120865,
              56.521848
            ],
            [
              36.117093,
              56.522211
            ],
            [
              36.11631994205398,
              56.52203460700813
            ],
            [
              36.115301,
              56.523178
            ],
            [
              36.11415849663023,
              56.521541417814475
            ],
            [
              36.113267,
              56.521338
            ],
            [
              36.106648,
              56.515603
            ],
            [
              36.099992,
              56.512988
            ],
            [
              36.09343,
              56.511521
            ],
            [
              36.089433,
              56.510192
            ],
            [
              36.086636,
              56.506451
            ],
            [
              36.08642614039621,
              56.50634598074389
            ],
            [
              36.086412,
              56.506374
            ],
            [
              36.078169,
              56.502213
            ],
            [
              36.069927,
              56.498052
            ],
            [
              36.059964,
              56.495155
            ],
            [
              36.051973,
              56.493408
            ],
            [
              36.048392,
              56.492733
            ],
            [
              36.046977,
              56.491854
            ],
            [
              36.045958491682605,
              56.48959002200474
            ],
            [
              36.04572,
              56.489282
            ],
            [
              36.043961462258196,
              56.486240503905684
            ],
            [
              36.04203865021849,
              56.4840052658428
            ],
            [
              36.034738,
              56.479515
            ],
            [
              36.026233,
              56.481992
            ],
            [
              36.022502,
              56.48259
            ],
            [
              36.01888047604762,
              56.48293258974234
            ],
            [
              36.01861155991375,
              56.48296911155654
            ],
            [
              36.0134980247308,
              56.48378731167265
            ],
            [
              36.01032,
              56.484373
            ],
            [
              36.00677937817523,
              56.48536344782753
            ],
            [
              35.999274,
              56.489377
            ],
            [
              35.992117,
              56.496682
            ],
            [
              35.983871,
              56.505047
            ],
            [
              35.985284,
              56.508358
            ],
            [
              35.984161,
              56.508837
            ],
            [
              35.980215,
              56.508564
            ],
            [
              35.974914,
              56.517854
            ],
            [
              35.975547,
              56.519724
            ],
            [
              35.975503,
              56.520303
            ],
            [
              35.974894,
              56.521454
            ],
            [
              35.972623,
              56.521768
            ],
            [
              35.96904,
              56.521412
            ],
            [
              35.963023,
              56.515607
            ],
            [
              35.95520412557731,
              56.51383439274897
            ],
            [
              35.951539502173574,
              56.51693595587505
            ],
            [
              35.950167,
              56.518976
            ],
            [
              35.94897904477495,
              56.51954017005853
            ],
            [
              35.947649,
              56.521627
            ],
            [
              35.946341,
              56.520793
            ],
            [
              35.93651,
              56.517733
            ],
            [
              35.934238,
              56.520525
            ],
            [
              35.924725,
              56.521497
            ],
            [
              35.92379,
              56.518021
            ],
            [
              35.92426726255616,
              56.517014611557144
            ],
            [
              35.922842,
              56.516877
            ],
            [
              35.922668,
              56.513281
            ],
            [
              35.924843582336756,
              56.506890226885794
            ],
            [
              35.92259807303651,
              56.502234846264365
            ],
            [
              35.918194,
              56.501118
            ],
            [
              35.913024,
              56.500452
            ],
            [
              35.907457,
              56.499929
            ],
            [
              35.903647822182954,
              56.49995809115204
            ],
            [
              35.90241380038279,
              56.50009206690895
            ],
            [
              35.899352302642306,
              56.500643437386536
            ],
            [
              35.89904170875778,
              56.50072792812834
            ],
            [
              35.892181,
              56.510028
            ],
            [
              35.891411,
              56.513915
            ],
            [
              35.88877449374935,
              56.51375376023697
            ],
            [
              35.888747,
              56.513904
            ],
            [
              35.883026,
              56.514869
            ],
            [
              35.882489,
              56.512962
            ],
            [
              35.875077420052385,
              56.50897709083821
            ],
            [
              35.868126,
              56.51127
            ],
            [
              35.86756224181914,
              56.511386603723466
            ],
            [
              35.866979,
              56.511633
            ],
            [
              35.862711,
              56.51239
            ],
            [
              35.856612,
              56.518561
            ],
            [
              35.853684,
              56.522878
            ],
            [
              35.844716,
              56.530738
            ],
            [
              35.838859,
              56.532185
            ],
            [
              35.8355993986149,
              56.533073478428165
            ],
            [
              35.82935,
              56.536059
            ],
            [
              35.831057,
              56.53961
            ],
            [
              35.83135,
              56.540087
            ],
            [
              35.828282,
              56.541969
            ],
            [
              35.827989,
              56.541492
            ],
            [
              35.821561,
              56.535367
            ],
            [
              35.811989,
              56.536916
            ],
            [
              35.810501,
              56.537667
            ],
            [
              35.809721,
              56.537877
            ],
            [
              35.80882191215504,
              56.5370888826951
            ],
            [
              35.803432,
              56.539325
            ],
            [
              35.801839,
              56.540145
            ],
            [
              35.800192,
              56.536944
            ],
            [
              35.801785,
              56.536124
            ],
            [
              35.803297,
              56.53526
            ],
            [
              35.806892,
              56.533106
            ],
            [
              35.80207204983314,
              56.52312635874356
            ],
            [
              35.801011469916766,
              56.523157208635915
            ],
            [
              35.798432,
              56.523401
            ],
            [
              35.79624316728185,
              56.523295907870875
            ],
            [
              35.79624,
              56.523296
            ],
            [
              35.794079,
              56.523192
            ],
            [
              35.784848,
              56.526235
            ],
            [
              35.779238,
              56.525038
            ],
            [
              35.77923769445849,
              56.52503286861596
            ],
            [
              35.7792,
              56.52504
            ],
            [
              35.775263,
              56.525434
            ],
            [
              35.766096,
              56.531083
            ],
            [
              35.756629,
              56.529685
            ],
            [
              35.755083,
              56.526433
            ],
            [
              35.758597,
              56.524763
            ],
            [
              35.76211,
              56.523093
            ],
            [
              35.7674,
              56.524383
            ],
            [
              35.77374,
              56.522016
            ],
            [
              35.777691,
              56.521553
            ],
            [
              35.781234,
              56.521103
            ],
            [
              35.78699713321087,
              56.52007366584012
            ],
            [
              35.787019,
              56.519371
            ],
            [
              35.79034897873707,
              56.5194750037977
            ],
            [
              35.790349,
              56.519475
            ],
            [
              35.79060521625174,
              56.51948293796771
            ],
            [
              35.802029,
              56.516206
            ],
            [
              35.808072,
              56.516158
            ],
            [
              35.812563,
              56.507578
            ],
            [
              35.81553427696296,
              56.5002376346802
            ],
            [
              35.81232905216213,
              56.49542433014453
            ],
            [
              35.809402,
              56.492943
            ],
            [
              35.799462,
              56.488083
            ],
            [
              35.794845,
              56.488442
            ],
            [
              35.790855,
              56.488671
            ],
            [
              35.783338,
              56.489757
            ],
            [
              35.77426,
              56.486813
            ],
            [
              35.77427621104101,
              56.48674196285398
            ],
            [
              35.768541,
              56.485409
            ],
            [
              35.76452601778348,
              56.483903874958344
            ],
            [
              35.761633,
              56.483141
            ],
            [
              35.75895692640655,
              56.48260114944552
            ],
            [
              35.757291745150475,
              56.48241557523495
            ],
            [
              35.75338,
              56.482398
            ],
            [
              35.751271,
              56.49369
            ],
            [
              35.75127098085407,
              56.493690054778625
            ],
            [
              35.7551,
              56.495028
            ],
            [
              35.762537,
              56.497668
            ],
            [
              35.771096,
              56.501488
            ],
            [
              35.771628,
              56.503263
            ],
            [
              35.768179,
              56.504295
            ],
            [
              35.761333,
              56.501061
            ],
            [
              35.753896,
              56.498421
            ],
            [
              35.75008304133895,
              56.49708888172467
            ],
            [
              35.750083,
              56.497089
            ],
            [
              35.749240827292084,
              56.49679464073669
            ],
            [
              35.74664,
              56.495886
            ],
            [
              35.738709,
              56.493347
            ],
            [
              35.728639,
              56.499272
            ],
            [
              35.729135,
              56.502838
            ],
            [
              35.727055,
              56.503127
            ],
            [
              35.724976,
              56.503416
            ],
            [
              35.724426,
              56.503513
            ],
            [
              35.720584,
              56.502841
            ],
            [
              35.708928,
              56.505469
            ],
            [
              35.708062,
              56.505672
            ],
            [
              35.707591,
              56.505445
            ],
            [
              35.707305,
              56.505081
            ],
            [
              35.707243,
              56.502166
            ],
            [
              35.70631,
              56.497308
            ],
            [
              35.701056,
              56.486901
            ],
            [
              35.694037,
              56.485089
            ],
            [
              35.685579,
              56.485279
            ],
            [
              35.682459,
              56.485665
            ],
            [
              35.681429,
              56.485765
            ],
            [
              35.681081,
              56.482182
            ],
            [
              35.682788,
              56.479048
            ],
            [
              35.685443,
              56.480207
            ],
            [
              35.694354,
              56.479259
            ],
            [
              35.697882,
              56.477064
            ],
            [
              35.701127,
              56.475042
            ],
            [
              35.69847,
              56.46358
            ],
            [
              35.689496,
              56.462285
            ],
            [
              35.686707,
              56.464034
            ],
            [
              35.684794,
              56.460985
            ],
            [
              35.685002,
              56.458479
            ],
            [
              35.685719,
              56.454951
            ],
            [
              35.686816,
              56.453849
            ],
            [
              35.690146,
              56.453426
            ],
            [
              35.693675,
              56.452712
            ],
            [
              35.69643,
              56.455152
            ],
            [
              35.705636,
              56.461766
            ],
            [
              35.709217,
              56.4624
            ],
            [
              35.71213060015481,
              56.463212118844936
            ],
            [
              35.712401,
              56.463026
            ],
            [
              35.723502,
              56.4624
            ],
            [
              35.72405021656509,
              56.46282894507584
            ],
            [
              35.725131,
              56.462789
            ],
            [
              35.7267624926506,
              56.46464613504966
            ],
            [
              35.737664,
              56.466109
            ],
            [
              35.747902,
              56.466625
            ],
            [
              35.753138,
              56.457219
            ],
            [
              35.749616,
              56.452966
            ],
            [
              35.752525,
              56.450779
            ],
            [
              35.75937,
              56.443424
            ],
            [
              35.755582,
              56.434804
            ],
            [
              35.747819,
              56.435502
            ],
            [
              35.747683,
              56.43534
            ],
            [
              35.746633,
              56.430212
            ],
            [
              35.747859,
              56.4288
            ],
            [
              35.748168216942105,
              56.42846824690788
            ],
            [
              35.746726,
              56.427039
            ],
            [
              35.738917,
              56.425102
            ],
            [
              35.735132,
              56.425669
            ],
            [
              35.731409,
              56.426062
            ],
            [
              35.727981,
              56.425717
            ],
            [
              35.726412,
              56.425502
            ],
            [
              35.726901,
              56.421935
            ],
            [
              35.736486,
              56.421793
            ],
            [
              35.740171,
              56.421371
            ],
            [
              35.743823,
              56.421462
            ],
            [
              35.748897,
              56.424168
            ],
            [
              35.754513,
              56.429747
            ],
            [
              35.760506,
              56.433626
            ],
            [
              35.762375,
              56.437068
            ],
            [
              35.76361638913787,
              56.44831833853729
            ],
            [
              35.764038,
              56.449986
            ],
            [
              35.764735,
              56.454387
            ],
            [
              35.765180196693244,
              56.45564048943107
            ],
            [
              35.769301,
              56.461026
            ],
            [
              35.778016,
              56.467074
            ],
            [
              35.786033,
              56.466775
            ],
            [
              35.790415,
              56.466424
            ],
            [
              35.796604,
              56.467783
            ],
            [
              35.800465,
              56.468079
            ],
            [
              35.8051,
              56.468269
            ],
            [
              35.809727,
              56.468176
            ],
            [
              35.814555,
              56.468315
            ],
            [
              35.815365979772764,
              56.46835937666677
            ],
            [
              35.815366,
              56.468359
            ],
            [
              35.818959,
              56.468552
            ],
            [
              35.819873,
              56.468606
            ],
            [
              35.823614,
              56.469105
            ],
            [
              35.831382,
              56.463681
            ],
            [
              35.837829,
              56.454061
            ],
            [
              35.837942725444414,
              56.45401941067692
            ],
            [
              35.838039,
              56.453187
            ],
            [
              35.833245,
              56.445306
            ],
            [
              35.8279543397435,
              56.444711988743656
            ],
            [
              35.827548,
              56.444736
            ],
            [
              35.82733604924965,
              56.444642569900786
            ],
            [
              35.827233,
              56.444631
            ],
            [
              35.82723595253682,
              56.44459844622679
            ],
            [
              35.824093,
              56.443213
            ],
            [
              35.826351,
              56.433975
            ],
            [
              35.828402,
              56.425997
            ],
            [
              35.830967,
              56.419404
            ],
            [
              35.8340637921943,
              56.418892665672416
            ],
            [
              35.838827,
              56.4174
            ],
            [
              35.842537,
              56.416566
            ],
            [
              35.846084,
              56.414432
            ],
            [
              35.848366,
              56.40488
            ],
            [
              35.846177,
              56.40172
            ],
            [
              35.844971,
              56.399571
            ],
            [
              35.847234,
              56.396771
            ],
            [
              35.84742,
              56.396625
            ],
            [
              35.847655,
              56.396656
            ],
            [
              35.849014,
              56.39857
            ],
            [
              35.855978,
              56.408119
            ],
            [
              35.853369,
              56.411812
            ],
            [
              35.851314,
              56.415526
            ],
            [
              35.847571,
              56.417624
            ],
            [
              35.843948,
              56.419878
            ],
            [
              35.839099,
              56.42099
            ],
            [
              35.83275133486409,
              56.42648821425068
            ],
            [
              35.831084,
              56.432125
            ],
            [
              35.83562620099597,
              56.440029831541345
            ],
            [
              35.845839,
              56.441968
            ],
            [
              35.85631,
              56.445861
            ],
            [
              35.856689,
              56.449441
            ],
            [
              35.85315,
              56.457201
            ],
            [
              35.85714945250616,
              56.4650468710345
            ],
            [
              35.861287,
              56.465209
            ],
            [
              35.86494,
              56.465525
            ],
            [
              35.86890597568261,
              56.47338841132985
            ],
            [
              35.870565,
              56.473902
            ],
            [
              35.877693,
              56.475574
            ],
            [
              35.881302,
              56.476741
            ],
            [
              35.885523,
              56.477894
            ],
            [
              35.88858568709124,
              56.47869707657396
            ],
            [
              35.88967905307433,
              56.478861686764496
            ],
            [
              35.895801,
              56.479481
            ],
            [
              35.897208377526546,
              56.4796514234207
            ],
            [
              35.903766,
              56.478412
            ],
            [
              35.90805,
              56.478014
            ],
            [
              35.9100994049461,
              56.480140897638194
            ],
            [
              35.917695,
              56.48122
            ],
            [
              35.921013,
              56.47338
            ],
            [
              35.92115714945569,
              56.473162689262786
            ],
            [
              35.919184,
              56.470828
            ],
            [
              35.918052,
              56.469885
            ],
            [
              35.920356,
              56.467119
            ],
            [
              35.921487,
              56.468061
            ],
            [
              35.92431782221222,
              56.470206697547006
            ],
            [
              35.926584,
              56.469908
            ],
            [
              35.929368,
              56.472191
            ],
            [
              35.932109,
              56.479863
            ],
            [
              35.93856915249274,
              56.486319354637075
            ],
            [
              35.93921587870068,
              56.48671188434149
            ],
            [
              35.948405,
              56.488195
            ],
            [
              35.952961,
              56.487052
            ],
            [
              35.956997,
              56.487254
            ],
            [
              35.967135,
              56.481163
            ],
            [
              35.96992,
              56.477256
            ],
            [
              35.97229,
              56.479966
            ],
            [
              35.975908,
              56.489209
            ],
            [
              35.98378473555244,
              56.494219545106375
            ],
            [
              35.987759,
              56.493686
            ],
            [
              35.990941,
              56.49063
            ],
            [
              35.99510239550822,
              56.48761567443299
            ],
            [
              35.995062,
              56.487536
            ],
            [
              36.000139,
              56.483934
            ],
            [
              36.003319,
              56.481912
            ],
            [
              36.007203,
              56.481304
            ],
            [
              36.012176,
              56.480355
            ],
            [
              36.02088992385027,
              56.47765686911202
            ],
            [
              36.021,
              56.477275
            ],
            [
              36.023179,
              56.475102
            ],
            [
              36.03321353709549,
              56.469000281479154
            ],
            [
              36.034559,
              56.467764
            ],
            [
              36.039821,
              56.46293
            ],
            [
              36.046099,
              56.456748
            ],
            [
              36.054089,
              56.449119
            ],
            [
              36.054936336716516,
              56.447750116972095
            ],
            [
              36.054872,
              56.447709
            ],
            [
              36.05769887459897,
              56.44328720258693
            ],
            [
              36.057699,
              56.443287
            ],
            [
              36.058663229680185,
              56.44177875342471
            ],
            [
              36.061437732135026,
              56.436494527616155
            ],
            [
              36.05772325440546,
              56.429421147423554
            ],
            [
              36.050463,
              56.429928
            ],
            [
              36.046681,
              56.430095
            ],
            [
              36.043186,
              56.429551
            ],
            [
              36.037127,
              56.421703
            ],
            [
              36.03464159681304,
              56.42121645716833
            ],
            [
              36.03437,
              56.421175
            ],
            [
              36.030475,
              56.420597
            ],
            [
              36.026407,
              56.419809
            ],
            [
              36.02553013474667,
              56.41957724603689
            ],
            [
              36.023905,
              56.419274
            ],
            [
              36.021755,
              56.418807
            ],
            [
              36.021512,
              56.418646
            ],
            [
              36.02158570385481,
              56.41853473980469
            ],
            [
              36.021284,
              56.418455
            ],
            [
              36.017194,
              56.417611
            ],
            [
              36.012814,
              56.416655
            ],
            [
              36.007159,
              56.415299
            ],
            [
              36.004842,
              56.41441
            ],
            [
              36.002788,
              56.412267
            ],
            [
              36.002769,
              56.41112
            ],
            [
              36.00297558722614,
              56.41104664496355
            ],
            [
              36.002156,
              56.409678
            ],
            [
              35.993997,
              56.404118
            ],
            [
              35.989358,
              56.402496
            ],
            [
              35.983938,
              56.400192
            ],
            [
              35.973611,
              56.397904
            ],
            [
              35.96861,
              56.396064
            ],
            [
              35.965122,
              56.394668
            ],
            [
              35.964426,
              56.394175
            ],
            [
              35.963785,
              56.393465
            ],
            [
              35.963951658791444,
              56.393314495634826
            ],
            [
              35.962662,
              56.391823
            ],
            [
              35.953341,
              56.387154
            ],
            [
              35.949516,
              56.387609
            ],
            [
              35.948398,
              56.387602
            ],
            [
              35.94778,
              56.387486
            ],
            [
              35.947817007592974,
              56.38728881195189
            ],
            [
              35.9456,
              56.386871
            ],
            [
              35.93611,
              56.386307
            ],
            [
              35.93136,
              56.386963
            ],
            [
              35.925984,
              56.395398
            ],
            [
              35.925545,
              56.404705
            ],
            [
              35.925107,
              56.414011
            ],
            [
              35.921511,
              56.413842
            ],
            [
              35.921949,
              56.404535
            ],
            [
              35.922387,
              56.395229
            ],
            [
              35.922877,
              56.388984
            ],
            [
              35.919354,
              56.380363
            ],
            [
              35.917763,
              56.376941
            ],
            [
              35.919348,
              56.368826
            ],
            [
              35.919638741479936,
              56.364494264574176
            ],
            [
              35.918658911307844,
              56.362292629310815
            ],
            [
              35.917827,
              56.362481
            ],
            [
              35.91737440580272,
              56.36048390811359
            ],
            [
              35.917374,
              56.360484
            ],
            [
              35.916865470229126,
              56.35823860224926
            ],
            [
              35.914717,
              56.349254
            ],
            [
              35.913159,
              56.342833
            ],
            [
              35.91225123619823,
              56.33875326663358
            ],
            [
              35.912234,
              56.338757
            ],
            [
              35.911588518380036,
              56.3357761942383
            ],
            [
              35.90950647187902,
              56.332292880687305
            ],
            [
              35.90486015943702,
              56.32778870296032
            ],
            [
              35.89912600801459,
              56.32608234183988
            ],
            [
              35.89160631025516,
              56.32423179900054
            ],
            [
              35.889677,
              56.323909
            ],
            [
              35.88926180550606,
              56.32376580812294
            ],
            [
              35.889187,
              56.323752
            ],
            [
              35.88887805924474,
              56.32363346208206
            ],
            [
              35.88480693483846,
              56.32222941661912
            ],
            [
              35.877639,
              56.320321
            ],
            [
              35.87763513108443,
              56.32031541174544
            ],
            [
              35.877358,
              56.320258
            ],
            [
              35.871971,
              56.31921
            ],
            [
              35.864677,
              56.316957
            ],
            [
              35.854142,
              56.314479
            ],
            [
              35.852393,
              56.314588
            ],
            [
              35.85239223314344,
              56.314575699483854
            ],
            [
              35.852098,
              56.314592
            ],
            [
              35.84729642962841,
              56.312717231958295
            ],
            [
              35.847211,
              56.312854
            ],
            [
              35.84305790256968,
              56.3107981842986
            ],
            [
              35.839285,
              56.308983
            ],
            [
              35.83908291840366,
              56.30883053605571
            ],
            [
              35.838898,
              56.308739
            ],
            [
              35.83838091242154,
              56.308300895539745
            ],
            [
              35.836263,
              56.306703
            ],
            [
              35.826145,
              56.30108
            ],
            [
              35.82511810070288,
              56.30005421447652
            ],
            [
              35.823835,
              56.299267
            ],
            [
              35.818154,
              56.292057
            ],
            [
              35.81800044201548,
              56.29159180148766
            ],
            [
              35.81794,
              56.291615
            ],
            [
              35.817236,
              56.289783
            ],
            [
              35.817211637679236,
              56.28969261415491
            ],
            [
              35.817199,
              56.289673
            ],
            [
              35.817193802505194,
              56.28962644446221
            ],
            [
              35.816491,
              56.287019
            ],
            [
              35.808949,
              56.279528
            ],
            [
              35.803359,
              56.279138
            ],
            [
              35.802119,
              56.278931
            ],
            [
              35.801172,
              56.278603
            ],
            [
              35.80121087593127,
              56.27849095165911
            ],
            [
              35.799986,
              56.278122
            ],
            [
              35.795789,
              56.276616
            ],
            [
              35.792774,
              56.274777
            ],
            [
              35.789984,
              56.272645
            ],
            [
              35.782058483177615,
              56.269674517190595
            ],
            [
              35.77846333842363,
              56.27011029231229
            ],
            [
              35.777174,
              56.272177
            ],
            [
              35.774872,
              56.275219
            ],
            [
              35.767807,
              56.280134
            ],
            [
              35.765959,
              56.281815
            ],
            [
              35.764112,
              56.283495
            ],
            [
              35.76411032368038,
              56.283493157571094
            ],
            [
              35.763656,
              56.283906
            ],
            [
              35.760716,
              56.286921
            ],
            [
              35.755944,
              56.292719
            ],
            [
              35.755901224367385,
              56.29268921344743
            ],
            [
              35.755556,
              56.293167
            ],
            [
              35.753812,
              56.29642
            ],
            [
              35.754,
              56.306175
            ],
            [
              35.75938,
              56.310668
            ],
            [
              35.763008,
              56.311621
            ],
            [
              35.763864,
              56.314838
            ],
            [
              35.76339846719672,
              56.31657026154696
            ],
            [
              35.76342,
              56.316714
            ],
            [
              35.7611990540556,
              56.317446527914946
            ],
            [
              35.760753,
              56.318883
            ],
            [
              35.76812599941227,
              56.327775999291106
            ],
            [
              35.771722,
              56.328798
            ],
            [
              35.77172137514275,
              56.32880019906572
            ],
            [
              35.771998,
              56.328879
            ],
            [
              35.775824,
              56.331925
            ],
            [
              35.781732,
              56.334992
            ],
            [
              35.782953,
              56.335509
            ],
            [
              35.78247050528548,
              56.336648223631514
            ],
            [
              35.788515,
              56.337691
            ],
            [
              35.797791,
              56.331333
            ],
            [
              35.799369,
              56.331919
            ],
            [
              35.80832,
              56.333944
            ],
            [
              35.808572,
              56.334104
            ],
            [
              35.80696766155688,
              56.34335175158919
            ],
            [
              35.807186,
              56.346993
            ],
            [
              35.807338197453916,
              56.347520388540886
            ],
            [
              35.807867,
              56.349352
            ],
            [
              35.804409,
              56.35035
            ],
            [
              35.80425908183395,
              56.34983097638222
            ],
            [
              35.804259,
              56.349831
            ],
            [
              35.80400959090865,
              56.34896722727119
            ],
            [
              35.803803,
              56.348252
            ],
            [
              35.80075,
              56.344157
            ],
            [
              35.795172,
              56.343085
            ],
            [
              35.791118,
              56.351747
            ],
            [
              35.791304,
              56.352581
            ],
            [
              35.791399,
              56.353132
            ],
            [
              35.79131893324943,
              56.35314585987732
            ],
            [
              35.791537,
              56.354664
            ],
            [
              35.791521,
              56.355973
            ],
            [
              35.78969728313672,
              56.35595121671525
            ],
            [
              35.789524,
              56.356464
            ],
            [
              35.789242551943516,
              56.35594578520377
            ],
            [
              35.787921,
              56.35593
            ],
            [
              35.786343,
              56.350607
            ],
            [
              35.774554,
              56.349488
            ],
            [
              35.769076,
              56.352685
            ],
            [
              35.767963,
              56.353312
            ],
            [
              35.767960336357824,
              56.35330844909454
            ],
            [
              35.767647,
              56.35349
            ],
            [
              35.764704,
              56.357398
            ],
            [
              35.761377,
              56.361479
            ],
            [
              35.755681,
              56.354988
            ],
            [
              35.751214,
              56.354788
            ],
            [
              35.74478,
              56.354499
            ],
            [
              35.736903,
              56.353918
            ],
            [
              35.728053,
              56.355403
            ],
            [
              35.718243,
              56.355127
            ],
            [
              35.717453,
              56.353279
            ],
            [
              35.71711,
              56.352441
            ],
            [
              35.720684,
              56.345736
            ],
            [
              35.717381,
              56.337985
            ],
            [
              35.71717110950143,
              56.337755135028
            ],
            [
              35.717153,
              56.337774
            ],
            [
              35.716178,
              56.336838
            ],
            [
              35.706523,
              56.330377
            ],
            [
              35.701605,
              56.328904
            ],
            [
              35.698794,
              56.326556
            ],
            [
              35.701518,
              56.324202
            ],
            [
              35.70549844997427,
              56.32274906543342
            ],
            [
              35.705457,
              56.322593
            ],
            [
              35.707178,
              56.322136
            ],
            [
              35.712733,
              56.320964
            ],
            [
              35.7207033116308,
              56.318633785976246
            ],
            [
              35.721037,
              56.318468
            ],
            [
              35.723403,
              56.317294
            ],
            [
              35.725639,
              56.31629
            ],
            [
              35.730555,
              56.314528
            ],
            [
              35.735007450386796,
              56.313452640318076
            ],
            [
              35.739761,
              56.304186
            ],
            [
              35.738367,
              56.302177
            ],
            [
              35.738388,
              56.300875
            ],
            [
              35.738506192751125,
              56.30086303298395
            ],
            [
              35.738408,
              56.300603
            ],
            [
              35.738509,
              56.296744
            ],
            [
              35.741785,
              56.295251
            ],
            [
              35.752328,
              56.291573
            ],
            [
              35.754687,
              56.288224
            ],
            [
              35.758282,
              56.284269
            ],
            [
              35.761077,
              56.281394
            ],
            [
              35.755557,
              56.272388
            ],
            [
              35.75163,
              56.270045
            ],
            [
              35.757376,
              56.267689
            ],
            [
              35.75868314652747,
              56.267507724875884
            ],
            [
              35.759405,
              56.267289
            ],
            [
              35.76337098501265,
              56.25720103812213
            ],
            [
              35.76313988892395,
              56.25700888891804
            ],
            [
              35.758571,
              56.253211
            ],
            [
              35.751857156430034,
              56.24685999987793
            ],
            [
              35.75077580287797,
              56.246464248493126
            ],
            [
              35.750075,
              56.247513
            ],
            [
              35.74899,
              56.24732
            ],
            [
              35.745725,
              56.241335
            ],
            [
              35.738039617789056,
              56.23641663478087
            ],
            [
              35.731184,
              56.235977
            ],
            [
              35.724354,
              56.235548
            ],
            [
              35.721988,
              56.235412
            ],
            [
              35.722195,
              56.231818
            ],
            [
              35.72220197689202,
              56.231818401208166
            ],
            [
              35.722202,
              56.231818
            ],
            [
              35.72456,
              56.231954
            ],
            [
              35.731409,
              56.232384
            ],
            [
              35.742402,
              56.227776
            ],
            [
              35.749098,
              56.226369
            ],
            [
              35.751769,
              56.228763
            ],
            [
              35.762067,
              56.228134
            ],
            [
              35.773042,
              56.227607
            ],
            [
              35.774213374891026,
              56.227746082165396
            ],
            [
              35.774623,
              56.227733
            ],
            [
              35.77972655827459,
              56.22719105513395
            ],
            [
              35.787769,
              56.224016
            ],
            [
              35.791362,
              56.22058
            ],
            [
              35.792611023271164,
              56.219386024337446
            ],
            [
              35.792611,
              56.219386
            ],
            [
              35.788138,
              56.213583
            ],
            [
              35.788979,
              56.210082
            ],
            [
              35.79406290647792,
              56.2113043888608
            ],
            [
              35.794063,
              56.211304
            ],
            [
              35.804348,
              56.20802
            ],
            [
              35.806344222407674,
              56.20361165634701
            ],
            [
              35.80485859254715,
              56.19679263372696
            ],
            [
              35.80352227610581,
              56.194528353496146
            ],
            [
              35.798582005039265,
              56.19440220440749
            ],
            [
              35.796483,
              56.194498
            ],
            [
              35.79648297023358,
              56.19449735127255
            ],
            [
              35.793267,
              56.194644
            ],
            [
              35.78417,
              56.19624
            ],
            [
              35.779452,
              56.197485
            ],
            [
              35.777523,
              56.194445
            ],
            [
              35.77652283707517,
              56.19031969031296
            ],
            [
              35.769074,
              56.18974
            ],
            [
              35.762816,
              56.189432
            ],
            [
              35.762993,
              56.185837
            ],
            [
              35.769251,
              56.186144
            ],
            [
              35.774123,
              56.186424
            ],
            [
              35.77716556615782,
              56.18680820990007
            ],
            [
              35.777198,
              56.186647
            ],
            [
              35.777602,
              56.186728
            ],
            [
              35.778328,
              56.186955
            ],
            [
              35.78066402645348,
              56.187919351500156
            ],
            [
              35.780665,
              56.187917
            ],
            [
              35.78274552179636,
              56.18877833341161
            ],
            [
              35.78431224806553,
              56.189121588756755
            ],
            [
              35.792684,
              56.187045
            ],
            [
              35.793726,
              56.186275
            ],
            [
              35.793833,
              56.186246
            ],
            [
              35.804583,
              56.190877
            ],
            [
              35.80506598547597,
              56.19105042007238
            ],
            [
              35.805346,
              56.191084
            ],
            [
              35.808081778190456,
              56.19213326627966
            ],
            [
              35.808237,
              56.192189
            ],
            [
              35.81123304467986,
              56.19333423720255
            ],
            [
              35.812699,
              56.193733
            ],
            [
              35.81422,
              56.194476
            ],
            [
              35.816004,
              56.19516
            ],
            [
              35.81784129532643,
              56.19676514609746
            ],
            [
              35.82935,
              56.178932
            ],
            [
              35.827775283824295,
              56.1784535883438
            ],
            [
              35.827773,
              56.178461
            ],
            [
              35.825805,
              56.177855
            ],
            [
              35.815467,
              56.171781
            ],
            [
              35.81547775265,
              56.17172195211426
            ],
            [
              35.814776,
              56.171672
            ],
            [
              35.814228,
              56.168041
            ],
            [
              35.81612206908718,
              56.168183659276195
            ],
            [
              35.817138,
              56.1626
            ],
            [
              35.813863,
              56.152524
            ],
            [
              35.8138261234938,
              56.15248486084817
            ],
            [
              35.813606,
              56.152536
            ],
            [
              35.80987,
              56.148286
            ],
            [
              35.799508,
              56.146861
            ],
            [
              35.795874,
              56.150795
            ],
            [
              35.792677,
              56.153237
            ],
            [
              35.790615,
              56.154958
            ],
            [
              35.788308,
              56.152195
            ],
            [
              35.79037,
              56.150473
            ],
            [
              35.78758981938286,
              56.139612349656836
            ],
            [
              35.783329,
              56.139771
            ],
            [
              35.779067,
              56.140421
            ],
            [
              35.774082,
              56.141202
            ],
            [
              35.770642,
              56.142016
            ],
            [
              35.766874,
              56.142579
            ],
            [
              35.759380281867834,
              56.146407788653796
            ],
            [
              35.756599,
              56.149048
            ],
            [
              35.755547,
              56.153228
            ],
            [
              35.752352,
              56.151427
            ],
            [
              35.750756153803664,
              56.146594557926754
            ],
            [
              35.74272412536394,
              56.1422204375786
            ],
            [
              35.736558019424336,
              56.14278299822784
            ],
            [
              35.73655799971643,
              56.142783000399845
            ],
            [
              35.731934,
              56.149303
            ],
            [
              35.727451,
              56.153204
            ],
            [
              35.72405,
              56.157704
            ],
            [
              35.728113770057995,
              56.167114883590955
            ],
            [
              35.729009,
              56.168233
            ],
            [
              35.72900446974123,
              56.1682484664467
            ],
            [
              35.73819,
              56.171264
            ],
            [
              35.741905,
              56.171557
            ],
            [
              35.74531212569372,
              56.172584415950155
            ],
            [
              35.745907,
              56.172252
            ],
            [
              35.746996,
              56.172476
            ],
            [
              35.747112595216734,
              56.173127346053704
            ],
            [
              35.750232,
              56.174068
            ],
            [
              35.749955,
              56.17806
            ],
            [
              35.748516,
              56.185111
            ],
            [
              35.748326,
              56.188706
            ],
            [
              35.74445249800347,
              56.19835761759067
            ],
            [
              35.745048,
              56.201465
            ],
            [
              35.739529,
              56.201401
            ],
            [
              35.732932,
              56.202703
            ],
            [
              35.727612,
              56.203645
            ],
            [
              35.723788,
              56.204347
            ],
            [
              35.71921,
              56.205736
            ],
            [
              35.714959,
              56.207197
            ],
            [
              35.711414,
              56.216432
            ],
            [
              35.710838,
              56.220096
            ],
            [
              35.706453,
              56.221365
            ],
            [
              35.702032,
              56.222517
            ],
            [
              35.69859,
              56.218695
            ],
            [
              35.694099,
              56.217217
            ],
            [
              35.694907059489054,
              56.21536648208614
            ],
            [
              35.692416,
              56.213728
            ],
            [
              35.688933,
              56.21071
            ],
            [
              35.686041,
              56.208025
            ],
            [
              35.682783,
              56.204353
            ],
            [
              35.680585,
              56.202928
            ],
            [
              35.668762,
              56.202766
            ],
            [
              35.66392158855604,
              56.20828833024122
            ],
            [
              35.663613,
              56.208952
            ],
            [
              35.665290306197775,
              56.21902885301333
            ],
            [
              35.66573938435545,
              56.2198586315319
            ],
            [
              35.669128,
              56.221567
            ],
            [
              35.677792,
              56.228689
            ],
            [
              35.682456,
              56.229887
            ],
            [
              35.685793,
              56.231238
            ],
            [
              35.687368,
              56.237416
            ],
            [
              35.694501,
              56.240017
            ],
            [
              35.691745,
              56.242539
            ],
            [
              35.689246,
              56.252666
            ],
            [
              35.691359,
              56.256139
            ],
            [
              35.695156,
              56.262131
            ],
            [
              35.701999,
              56.265419
            ],
            [
              35.70197414196871,
              56.26571721323826
            ],
            [
              35.70536723824728,
              56.26750191746542
            ],
            [
              35.70683,
              56.267622
            ],
            [
              35.7099833914617,
              56.26882183577008
            ],
            [
              35.713143,
              56.269724
            ],
            [
              35.716644,
              56.271556
            ],
            [
              35.725917,
              56.274621
            ],
            [
              35.727921,
              56.274686
            ],
            [
              35.727804,
              56.278284
            ],
            [
              35.7258,
              56.278219
            ],
            [
              35.719335,
              56.279152
            ],
            [
              35.715875,
              56.277512
            ],
            [
              35.712175,
              56.273192
            ],
            [
              35.709134,
              56.269884
            ],
            [
              35.699632,
              56.276543
            ],
            [
              35.696583,
              56.281474
            ],
            [
              35.69269798512385,
              56.28656336263709
            ],
            [
              35.69210584948251,
              56.295746347755525
            ],
            [
              35.695836,
              56.30079
            ],
            [
              35.699999,
              56.302781
            ],
            [
              35.703997,
              56.304708
            ],
            [
              35.706804,
              56.305933
            ],
            [
              35.707974,
              56.306381
            ],
            [
              35.706688,
              56.309743
            ],
            [
              35.705519,
              56.309296
            ],
            [
              35.702275,
              56.30787
            ],
            [
              35.69786519037631,
              56.30948690011069
            ],
            [
              35.698283,
              56.310019
            ],
            [
              35.698477,
              56.310267
            ],
            [
              35.695644,
              56.312488
            ],
            [
              35.68989538174152,
              56.31286397758619
            ],
            [
              35.691523,
              56.31702
            ],
            [
              35.689318,
              56.319866
            ],
            [
              35.684049,
              56.316444
            ],
            [
              35.68320419302535,
              56.31594980554445
            ],
            [
              35.6832,
              56.315955
            ],
            [
              35.680346,
              56.3148
            ],
            [
              35.676876,
              56.313842
            ],
            [
              35.6773,
              56.312305
            ],
            [
              35.67743,
              56.312001
            ],
            [
              35.6781992220427,
              56.31144320711666
            ],
            [
              35.677758,
              56.310277
            ],
            [
              35.669173,
              56.302534
            ],
            [
              35.66802448404912,
              56.30347288506636
            ],
            [
              35.668378,
              56.305414
            ],
            [
              35.664339,
              56.306054
            ],
            [
              35.660393263668425,
              56.30535600580905
            ],
            [
              35.65357891907628,
              56.306690407491594
            ],
            [
              35.64950880611551,
              56.3078156632846
            ],
            [
              35.654453,
              56.318373
            ],
            [
              35.658949,
              56.318381
            ],
            [
              35.658943,
              56.321981
            ],
            [
              35.647468,
              56.324225
            ],
            [
              35.645536,
              56.321163
            ],
            [
              35.638041053287296,
              56.323770406052596
            ],
            [
              35.635181,
              56.327693
            ],
            [
              35.635278,
              56.331431
            ],
            [
              35.635223,
              56.332112
            ],
            [
              35.635116,
              56.332698
            ],
            [
              35.634222823595024,
              56.332533792759214
            ],
            [
              35.633434,
              56.336519
            ],
            [
              35.634256,
              56.342411
            ],
            [
              35.635609,
              56.346985
            ],
            [
              35.636069,
              56.349979
            ],
            [
              35.636122,
              56.350963
            ],
            [
              35.63611826491006,
              56.35096319948185
            ],
            [
              35.636124,
              56.351008
            ],
            [
              35.63628,
              56.355962
            ],
            [
              35.63627513384048,
              56.35596215282824
            ],
            [
              35.636333,
              56.357859
            ],
            [
              35.644233,
              56.361527
            ],
            [
              35.648557,
              56.36096
            ],
            [
              35.652149,
              56.361209
            ],
            [
              35.652759,
              56.362131
            ],
            [
              35.653227,
              56.365701
            ],
            [
              35.650768,
              56.366023
            ],
            [
              35.64831,
              56.366345
            ],
            [
              35.642421,
              56.364589
            ],
            [
              35.635282,
              56.370907
            ],
            [
              35.633839,
              56.374205
            ],
            [
              35.632934,
              56.37536
            ],
            [
              35.628659,
              56.374608
            ],
            [
              35.621279,
              56.369318
            ],
            [
              35.620261,
              56.368429
            ],
            [
              35.621644,
              56.365105
            ],
            [
              35.623935,
              56.364499
            ],
            [
              35.626043,
              56.364232
            ],
            [
              35.632733,
              56.357865
            ],
            [
              35.63262,
              56.354114
            ],
            [
              35.632076,
              56.347674
            ],
            [
              35.630819,
              56.34348
            ],
            [
              35.621831,
              56.336099
            ],
            [
              35.615635,
              56.343667
            ],
            [
              35.615934,
              56.347705
            ],
            [
              35.614689,
              56.351887
            ],
            [
              35.613053,
              56.355514
            ],
            [
              35.609637,
              56.357643
            ],
            [
              35.607862,
              56.360775
            ],
            [
              35.598162,
              56.358355
            ],
            [
              35.596773,
              56.357891
            ],
            [
              35.59363,
              56.352582
            ],
            [
              35.593243,
              56.351839
            ],
            [
              35.597655,
              56.348562
            ],
            [
              35.600847,
              56.348528
            ],
            [
              35.611241,
              56.343563
            ],
            [
              35.611295980348956,
              56.343114795178074
            ],
            [
              35.611058,
              56.343211
            ],
            [
              35.605313,
              56.341149
            ],
            [
              35.604092,
              56.337763
            ],
            [
              35.604743,
              56.337528
            ],
            [
              35.608061,
              56.336839
            ],
            [
              35.616178,
              56.335463
            ],
            [
              35.619818,
              56.333009
            ],
            [
              35.622446,
              56.330548
            ],
            [
              35.631583,
              56.327813
            ],
            [
              35.63134096301204,
              56.3207762890263
            ],
            [
              35.631314,
              56.320052
            ],
            [
              35.631177,
              56.316346
            ],
            [
              35.631316,
              56.314766
            ],
            [
              35.63440223111056,
              56.312097760917254
            ],
            [
              35.634279,
              56.31014
            ],
            [
              35.634651,
              56.309907
            ],
            [
              35.64094,
              56.307076
            ],
            [
              35.64314488945522,
              56.30624731302916
            ],
            [
              35.64314,
              56.306234
            ],
            [
              35.64426796873221,
              56.30356271547458
            ],
            [
              35.64231301070446,
              56.297859493064536
            ],
            [
              35.637653,
              56.29568
            ],
            [
              35.636167,
              56.294444
            ],
            [
              35.636126,
              56.294057
            ],
            [
              35.6307658230804,
              56.285669669749524
            ],
            [
              35.629081,
              56.284212
            ],
            [
              35.621255,
              56.28295
            ],
            [
              35.613534,
              56.283399
            ],
            [
              35.606415,
              56.287826
            ],
            [
              35.602796,
              56.289045
            ],
            [
              35.598924,
              56.289734
            ],
            [
              35.591546,
              56.291827
            ],
            [
              35.586532,
              56.300313
            ],
            [
              35.584114,
              56.302981
            ],
            [
              35.582337,
              56.303112
            ],
            [
              35.582041986803155,
              56.303017661979126
            ],
            [
              35.577689,
              56.308875
            ],
            [
              35.572358,
              56.308478
            ],
            [
              35.561883528880664,
              56.30964943753833
            ],
            [
              35.560155072900486,
              56.31121924296961
            ],
            [
              35.554772,
              56.318981
            ],
            [
              35.550502,
              56.318472
            ],
            [
              35.546281,
              56.318779
            ],
            [
              35.540664,
              56.319187
            ],
            [
              35.547976,
              56.310474
            ],
            [
              35.542574,
              56.301892
            ],
            [
              35.536172,
              56.301831
            ],
            [
              35.531869,
              56.30175
            ],
            [
              35.528398049604306,
              56.30164074017866
            ],
            [
              35.528398,
              56.301643
            ],
            [
              35.52830709675065,
              56.301647773912435
            ],
            [
              35.524232,
              56.311852
            ],
            [
              35.527063,
              56.315288
            ],
            [
              35.527211,
              56.316994
            ],
            [
              35.527252,
              56.317966
            ],
            [
              35.52389780756719,
              56.31958808886709
            ],
            [
              35.524533,
              56.320829
            ],
            [
              35.523639,
              56.331141
            ],
            [
              35.526926,
              56.33494
            ],
            [
              35.530172,
              56.341432
            ],
            [
              35.530423,
              56.341785
            ],
            [
              35.528876,
              56.343877
            ],
            [
              35.527898,
              56.344027
            ],
            [
              35.526032,
              56.343995
            ],
            [
              35.520919,
              56.3335
            ],
            [
              35.517725,
              56.329817
            ],
            [
              35.509676,
              56.325444
            ],
            [
              35.50288,
              56.328449
            ],
            [
              35.496789,
              56.331777
            ],
            [
              35.492188,
              56.335379
            ],
            [
              35.488425,
              56.339133
            ],
            [
              35.487462,
              56.338615
            ],
            [
              35.485469,
              56.336709
            ],
            [
              35.478873,
              56.327308
            ],
            [
              35.478489,
              56.326715
            ],
            [
              35.48151,
              56.324757
            ],
            [
              35.488006,
              56.325826
            ],
            [
              35.492875,
              56.325034
            ],
            [
              35.496896,
              56.324705
            ],
            [
              35.500989,
              56.325385
            ],
            [
              35.508795,
              56.321953
            ],
            [
              35.510996864494814,
              56.32109752149956
            ],
            [
              35.503246,
              56.312875
            ],
            [
              35.501333,
              56.312702
            ],
            [
              35.50133973105769,
              56.312627751255995
            ],
            [
              35.500216,
              56.312482
            ],
            [
              35.49704,
              56.312081
            ],
            [
              35.49749,
              56.308509
            ],
            [
              35.491537,
              56.30092
            ],
            [
              35.487563,
              56.301512
            ],
            [
              35.4833,
              56.302155
            ],
            [
              35.475913,
              56.301153
            ],
            [
              35.474879,
              56.300923
            ],
            [
              35.474046,
              56.300682
            ],
            [
              35.474154177702125,
              56.30030941185862
            ],
            [
              35.471887,
              56.299222
            ],
            [
              35.467669,
              56.296858
            ],
            [
              35.462448,
              56.295041
            ],
            [
              35.455246,
              56.293265
            ],
            [
              35.447329,
              56.296604
            ],
            [
              35.446645,
              56.296649
            ],
            [
              35.446481,
              56.296528
            ],
            [
              35.445524,
              56.293228
            ],
            [
              35.445955,
              56.293071
            ],
            [
              35.45166,
              56.290835
            ],
            [
              35.457308,
              56.289497
            ],
            [
              35.460945,
              56.290051
            ],
            [
              35.464336,
              56.291976
            ],
            [
              35.473485,
              56.295419
            ],
            [
              35.479778,
              56.297014
            ],
            [
              35.48416893736497,
              56.29756577833881
            ],
            [
              35.4906,
              56.288894
            ],
            [
              35.490956,
              56.285312
            ],
            [
              35.485718,
              56.276979
            ],
            [
              35.492722,
              56.275124
            ],
            [
              35.496223,
              56.274814
            ],
            [
              35.499726,
              56.274033
            ],
            [
              35.504866,
              56.273698
            ],
            [
              35.513832,
              56.266487
            ],
            [
              35.521602,
              56.273378
            ],
            [
              35.52466521548029,
              56.27436629727674
            ],
            [
              35.52516,
              56.274488
            ],
            [
              35.525839,
              56.274745
            ],
            [
              35.52843660852336,
              56.27769704499345
            ],
            [
              35.528467,
              56.277676
            ],
            [
              35.53234,
              56.278425
            ],
            [
              35.533204,
              56.27899
            ],
            [
              35.536946,
              56.281664
            ],
            [
              35.545226,
              56.276847
            ],
            [
              35.54522818565799,
              56.27684280802459
            ],
            [
              35.547343,
              56.272765
            ],
            [
              35.547400329829394,
              56.27267675139835
            ],
            [
              35.5477,
              56.272102
            ],
            [
              35.547842255950684,
              56.27188674601189
            ],
            [
              35.547833,
              56.271881
            ],
            [
              35.548574,
              56.270688
            ],
            [
              35.544293,
              56.259809
            ],
            [
              35.540665,
              56.259701
            ],
            [
              35.536639,
              56.259442
            ],
            [
              35.535155,
              56.258935
            ],
            [
              35.532846,
              56.257978
            ],
            [
              35.53284657571902,
              56.25797661041983
            ],
            [
              35.532801,
              56.257959
            ],
            [
              35.521846,
              56.255952
            ],
            [
              35.520799,
              56.256096
            ],
            [
              35.519213,
              56.255283
            ],
            [
              35.51830190478962,
              56.25333446355622
            ],
            [
              35.51013571478975,
              56.25195151583579
            ],
            [
              35.503137,
              56.255642
            ],
            [
              35.49958,
              56.256195
            ],
            [
              35.49362220365514,
              56.24984770881547
            ],
            [
              35.49156,
              56.249887
            ],
            [
              35.489517,
              56.251511
            ],
            [
              35.48885,
              56.252084
            ],
            [
              35.486505,
              56.249353
            ],
            [
              35.486007,
              56.24048
            ],
            [
              35.487618,
              56.23726
            ],
            [
              35.491085,
              56.238994
            ],
            [
              35.494551,
              56.240728
            ],
            [
              35.497786,
              56.244411
            ],
            [
              35.506451,
              56.242579
            ],
            [
              35.51121864263699,
              56.244009109561176
            ],
            [
              35.515923,
              56.235075
            ],
            [
              35.513989,
              56.232038
            ],
            [
              35.518143,
              56.229394
            ],
            [
              35.519795,
              56.228316
            ],
            [
              35.522951,
              56.226013
            ],
            [
              35.525596,
              56.222815
            ],
            [
              35.52247989803551,
              56.216993853691896
            ],
            [
              35.517918,
              56.215944
            ],
            [
              35.511464,
              56.21652
            ],
            [
              35.511458570537,
              56.21651316895225
            ],
            [
              35.511099,
              56.216635
            ],
            [
              35.507724,
              56.217696
            ],
            [
              35.504079,
              56.218839
            ],
            [
              35.500891,
              56.219648
            ],
            [
              35.500017,
              56.219744
            ],
            [
              35.500016192086996,
              56.21973670022041
            ],
            [
              35.499956,
              56.21975
            ],
            [
              35.496776,
              56.220004
            ],
            [
              35.49550984380105,
              56.22010473506866
            ],
            [
              35.492538,
              56.220342
            ],
            [
              35.487468,
              56.221439
            ],
            [
              35.481599,
              56.223176
            ],
            [
              35.473376,
              56.22869
            ],
            [
              35.471696406955225,
              56.22955135415301
            ],
            [
              35.469171,
              56.232142
            ],
            [
              35.458962,
              56.229725
            ],
            [
              35.455211,
              56.22866
            ],
            [
              35.45568276006428,
              56.22683068344376
            ],
            [
              35.452344,
              56.226274
            ],
            [
              35.448689,
              56.225287
            ],
            [
              35.439957,
              56.225506
            ],
            [
              35.436387,
              56.225531
            ],
            [
              35.432061,
              56.216695
            ],
            [
              35.430103,
              56.214974
            ],
            [
              35.42996135666828,
              56.21479216949044
            ],
            [
              35.42847519831009,
              56.2134453181356
            ],
            [
              35.427773,
              56.212905
            ],
            [
              35.42781654357733,
              56.21284840326828
            ],
            [
              35.427495,
              56.212557
            ],
            [
              35.423586,
              56.210875
            ],
            [
              35.412853,
              56.210132
            ],
            [
              35.410755,
              56.213127
            ],
            [
              35.408106,
              56.216342
            ],
            [
              35.407317,
              56.216666
            ],
            [
              35.406509,
              56.21685
            ],
            [
              35.405712,
              56.213339
            ],
            [
              35.401316,
              56.204744
            ],
            [
              35.40088,
              56.20117
            ],
            [
              35.399582,
              56.189808
            ],
            [
              35.39117,
              56.192931
            ],
            [
              35.385186,
              56.198273
            ],
            [
              35.384825,
              56.198891
            ],
            [
              35.38047,
              56.198152
            ],
            [
              35.379206,
              56.197909
            ],
            [
              35.379131,
              56.196457
            ],
            [
              35.379343,
              56.192863
            ],
            [
              35.380573,
              56.192838
            ],
            [
              35.386946,
              56.190508
            ],
            [
              35.386114,
              56.181413
            ],
            [
              35.385623,
              56.177847
            ],
            [
              35.389941,
              56.173818
            ],
            [
              35.392816,
              56.171639
            ],
            [
              35.395188,
              56.174457
            ],
            [
              35.399019,
              56.178922
            ],
            [
              35.406021,
              56.183076
            ],
            [
              35.415721,
              56.181539
            ],
            [
              35.41858,
              56.179278
            ],
            [
              35.423031,
              56.175422
            ],
            [
              35.426723,
              56.173428
            ],
            [
              35.430519,
              56.171416
            ],
            [
              35.433849,
              56.170335
            ],
            [
              35.43730377407842,
              56.16959841974042
            ],
            [
              35.437306,
              56.169513
            ],
            [
              35.439768,
              56.168774
            ],
            [
              35.444706,
              56.163296
            ],
            [
              35.437495,
              56.154375
            ],
            [
              35.436137,
              56.15455
            ],
            [
              35.433716,
              56.154779
            ],
            [
              35.43351531769939,
              56.15479799018465
            ],
            [
              35.433487,
              56.154801
            ],
            [
              35.43348498432079,
              56.1548008605746
            ],
            [
              35.43280687655669,
              56.154865028623355
            ],
            [
              35.431522,
              56.154987
            ],
            [
              35.43152196381794,
              56.154986617473426
            ],
            [
              35.431296,
              56.155008
            ],
            [
              35.43126186223306,
              56.15464708626333
            ],
            [
              35.42928,
              56.15451
            ],
            [
              35.425556,
              56.155183
            ],
            [
              35.421942,
              56.155599
            ],
            [
              35.41768,
              56.155543
            ],
            [
              35.4141,
              56.155382
            ],
            [
              35.410417,
              56.156339
            ],
            [
              35.406904,
              56.156665
            ],
            [
              35.40328,
              56.156954
            ],
            [
              35.399144,
              56.157121
            ],
            [
              35.395518,
              56.157016
            ],
            [
              35.389045,
              56.158013
            ],
            [
              35.383104,
              56.161571
            ],
            [
              35.380515,
              56.164072
            ],
            [
              35.371637,
              56.160775
            ],
            [
              35.370459,
              56.16073
            ],
            [
              35.368948,
              56.157462
            ],
            [
              35.373429,
              56.152196
            ],
            [
              35.375173,
              56.142652
            ],
            [
              35.378766,
              56.142433
            ],
            [
              35.381804,
              56.148398
            ],
            [
              35.388503,
              56.150477
            ],
            [
              35.38936255995555,
              56.15083378473378
            ],
            [
              35.389675,
              56.150929
            ],
            [
              35.392276,
              56.151507
            ],
            [
              35.39288657819388,
              56.15166443400293
            ],
            [
              35.392908,
              56.15161
            ],
            [
              35.393375,
              56.151748
            ],
            [
              35.40018,
              56.153545
            ],
            [
              35.405329,
              56.153189
            ],
            [
              35.412347,
              56.143625
            ],
            [
              35.417682,
              56.145473
            ],
            [
              35.424831,
              56.143727
            ],
            [
              35.426222,
              56.147248
            ],
            [
              35.437032,
              56.150805
            ],
            [
              35.440866,
              56.150195
            ],
            [
              35.444826,
              56.149203
            ],
            [
              35.447246552806014,
              56.14857234392253
            ],
            [
              35.449976,
              56.145333
            ],
            [
              35.450628,
              56.145334
            ],
            [
              35.45884551909607,
              56.146207680526835
            ],
            [
              35.46438,
              56.136121
            ],
            [
              35.460072,
              56.128319
            ],
            [
              35.45818733945846,
              56.1278335509936
            ],
            [
              35.456927,
              56.127905
            ],
            [
              35.445779,
              56.125714
            ],
            [
              35.442317,
              56.124674
            ],
            [
              35.433539,
              56.12293
            ],
            [
              35.429395,
              56.121749
            ],
            [
              35.424473,
              56.120595
            ],
            [
              35.420353,
              56.119016
            ],
            [
              35.419452,
              56.118239
            ],
            [
              35.421803,
              56.115512
            ],
            [
              35.423048,
              56.116205
            ],
            [
              35.426781,
              56.117832
            ],
            [
              35.432215,
              56.119234
            ],
            [
              35.43589,
              56.119531
            ],
            [
              35.43593173550814,
              56.119537580777546
            ],
            [
              35.435932,
              56.119535
            ],
            [
              35.43842,
              56.11979
            ],
            [
              35.440507,
              56.120259
            ],
            [
              35.447917,
              56.111903
            ],
            [
              35.452931,
              56.108265
            ],
            [
              35.45986969779815,
              56.10616898992101
            ],
            [
              35.46147,
              56.099122
            ],
            [
              35.46147,
              56.095522
            ],
            [
              35.462217,
              56.095522
            ],
            [
              35.468287,
              56.094664
            ],
            [
              35.472485,
              56.095714
            ],
            [
              35.475407,
              56.098447
            ],
            [
              35.484241,
              56.098392
            ],
            [
              35.487451,
              56.089881
            ],
            [
              35.489069,
              56.085631
            ],
            [
              35.490683,
              56.081395
            ],
            [
              35.48211924781393,
              56.075135181143764
            ],
            [
              35.477169,
              56.076161
            ],
            [
              35.477168955696044,
              56.07616078606648
            ],
            [
              35.476391,
              56.076322
            ],
            [
              35.471309,
              56.077393
            ],
            [
              35.464315,
              56.077866
            ],
            [
              35.455489,
              56.079073
            ],
            [
              35.454645,
              56.079186
            ],
            [
              35.452442,
              56.079338
            ],
            [
              35.45024,
              56.07949
            ],
            [
              35.45023982033454,
              56.079487408920954
            ],
            [
              35.450017,
              56.079505
            ],
            [
              35.44554,
              56.080396
            ],
            [
              35.439177,
              56.081133
            ],
            [
              35.435012,
              56.081479
            ],
            [
              35.42956474368491,
              56.08174887610026
            ],
            [
              35.423222,
              56.088196
            ],
            [
              35.420383,
              56.091804
            ],
            [
              35.419247,
              56.09313
            ],
            [
              35.416513,
              56.090788
            ],
            [
              35.406254,
              56.092981
            ],
            [
              35.398848,
              56.097089
            ],
            [
              35.39788463798253,
              56.09637214924669
            ],
            [
              35.3977866752095,
              56.09640850930758
            ],
            [
              35.396906,
              56.096765
            ],
            [
              35.3969326247675,
              56.09672550039629
            ],
            [
              35.395471,
              56.097268
            ],
            [
              35.39641100689274,
              56.09527560035058
            ],
            [
              35.39596,
              56.09494
            ],
            [
              35.394939,
              56.091765
            ],
            [
              35.386432,
              56.083893
            ],
            [
              35.387121,
              56.080359
            ],
            [
              35.38334189359105,
              56.07857448361488
            ],
            [
              35.383516,
              56.082702
            ],
            [
              35.38052852114834,
              56.083833386627454
            ],
            [
              35.3804,
              56.083987
            ],
            [
              35.380101994931216,
              56.08399491615864
            ],
            [
              35.379843,
              56.084093
            ],
            [
              35.37970121443708,
              56.08400556242712
            ],
            [
              35.375732,
              56.084111
            ],
            [
              35.372543,
              56.08013
            ],
            [
              35.363211,
              56.086923
            ],
            [
              35.36586149334727,
              56.091511073183405
            ],
            [
              35.367708,
              56.093454
            ],
            [
              35.368305,
              56.095183
            ],
            [
              35.36829944821092,
              56.09528015630885
            ],
            [
              35.368349,
              56.095609
            ],
            [
              35.362455,
              56.098309
            ],
            [
              35.361519,
              56.09882
            ],
            [
              35.35963780711651,
              56.0968572459638
            ],
            [
              35.359584,
              56.096838
            ],
            [
              35.35957852550296,
              56.09679539413175
            ],
            [
              35.359028,
              56.096221
            ],
            [
              35.35937568415741,
              56.090618961225154
            ],
            [
              35.347952,
              56.08896
            ],
            [
              35.34703543277122,
              56.090093089632106
            ],
            [
              35.346701,
              56.090533
            ],
            [
              35.34644178022344,
              56.090826981839726
            ],
            [
              35.345192,
              56.092372
            ],
            [
              35.34497059409124,
              56.09249545795463
            ],
            [
              35.344188,
              56.093383
            ],
            [
              35.335109,
              56.095892
            ],
            [
              35.327547,
              56.101316
            ],
            [
              35.324792,
              56.103633
            ],
            [
              35.32275877418751,
              56.10416850366187
            ],
            [
              35.321726,
              56.105068
            ],
            [
              35.318529,
              56.10643
            ],
            [
              35.31604516874739,
              56.10695808869001
            ],
            [
              35.308,
              56.115153
            ],
            [
              35.304401,
              56.115218
            ],
            [
              35.30436,
              56.112934
            ],
            [
              35.30544579444391,
              56.11129549064678
            ],
            [
              35.305426,
              56.111274
            ],
            [
              35.30279293867753,
              56.101837080837115
            ],
            [
              35.30236,
              56.102073
            ],
            [
              35.297646,
              56.103901
            ],
            [
              35.29638,
              56.100531
            ],
            [
              35.293885816824336,
              56.09743374454188
            ],
            [
              35.288073,
              56.099419
            ],
            [
              35.286708,
              56.100232
            ],
            [
              35.285,
              56.100934
            ],
            [
              35.282887084103386,
              56.100301779296814
            ],
            [
              35.282789,
              56.100354
            ],
            [
              35.282028,
              56.100903
            ],
            [
              35.281738,
              56.100885
            ],
            [
              35.279966,
              56.098121
            ],
            [
              35.280116,
              56.09793
            ],
            [
              35.281154339667374,
              56.09701602136061
            ],
            [
              35.267204,
              56.088761
            ],
            [
              35.265201,
              56.095508
            ],
            [
              35.265691,
              56.102125
            ],
            [
              35.271645,
              56.107096
            ],
            [
              35.271596,
              56.110696
            ],
            [
              35.26707,
              56.114317
            ],
            [
              35.258838,
              56.113276
            ],
            [
              35.258830406676935,
              56.11325199642903
            ],
            [
              35.256825,
              56.113853
            ],
            [
              35.254048,
              56.110787
            ],
            [
              35.254119,
              56.104729
            ],
            [
              35.256821,
              56.099828
            ],
            [
              35.25198140948299,
              56.092978615827974
            ],
            [
              35.250023,
              56.092833
            ],
            [
              35.250016853313525,
              56.09282102225325
            ],
            [
              35.246106,
              56.093823
            ],
            [
              35.240826,
              56.103646
            ],
            [
              35.240868,
              56.107157
            ],
            [
              35.241639,
              56.113823
            ],
            [
              35.241751,
              56.118006
            ],
            [
              35.238137,
              56.123271
            ],
            [
              35.233773,
              56.12525
            ],
            [
              35.231957,
              56.122141
            ],
            [
              35.224393,
              56.116942
            ],
            [
              35.222279,
              56.113575
            ],
            [
              35.219405,
              56.110936
            ],
            [
              35.215401,
              56.106855
            ],
            [
              35.212149037627924,
              56.104463978682446
            ],
            [
              35.20831200268169,
              56.10350762302032
            ],
            [
              35.201313,
              56.110369
            ],
            [
              35.201476,
              56.11145
            ],
            [
              35.201142,
              56.112368
            ],
            [
              35.2005,
              56.112401
            ],
            [
              35.197916,
              56.111986
            ],
            [
              35.197753,
              56.110905
            ],
            [
              35.192266,
              56.1028
            ],
            [
              35.185923,
              56.102867
            ],
            [
              35.183827,
              56.102893
            ],
            [
              35.179981,
              56.102941
            ],
            [
              35.179936,
              56.099342
            ],
            [
              35.183782,
              56.099294
            ],
            [
              35.185878,
              56.099267
            ],
            [
              35.19314,
              56.090782
            ],
            [
              35.196197,
              56.081936
            ],
            [
              35.195688,
              56.077932
            ],
            [
              35.201993,
              56.069763
            ],
            [
              35.202553,
              56.066206
            ],
            [
              35.203216,
              56.066311
            ],
            [
              35.206914,
              56.066911
            ],
            [
              35.210362,
              56.068778
            ],
            [
              35.220085,
              56.06093
            ],
            [
              35.2192,
              56.058613
            ],
            [
              35.222546,
              56.057286
            ],
            [
              35.22344,
              56.059541
            ],
            [
              35.223597,
              56.060138
            ],
            [
              35.222401,
              56.065269
            ],
            [
              35.225293446924226,
              56.07072905850051
            ],
            [
              35.226023,
              56.070314
            ],
            [
              35.227689,
              56.07068
            ],
            [
              35.228073,
              56.070818
            ],
            [
              35.229088848260396,
              56.071052368968935
            ],
            [
              35.234726,
              56.071028
            ],
            [
              35.239983612440284,
              56.073242962381514
            ],
            [
              35.24257020301583,
              56.07304306856737
            ],
            [
              35.251295,
              56.069456
            ],
            [
              35.251908,
              56.068813
            ],
            [
              35.255,
              56.06988
            ],
            [
              35.255603,
              56.073429
            ],
            [
              35.254126,
              56.07368
            ],
            [
              35.252049,
              56.073835
            ],
            [
              35.25146376205736,
              56.07397782294653
            ],
            [
              35.251471,
              56.076873
            ],
            [
              35.247573,
              56.086019
            ],
            [
              35.259027,
              56.086854
            ],
            [
              35.26644,
              56.085243
            ],
            [
              35.26679405936912,
              56.085166273377695
            ],
            [
              35.266794,
              56.085166
            ],
            [
              35.273818,
              56.08364
            ],
            [
              35.281022,
              56.082083
            ],
            [
              35.285417943604344,
              56.08113469673779
            ],
            [
              35.285418,
              56.081132
            ],
            [
              35.286483,
              56.080902
            ],
            [
              35.289176,
              56.080324
            ],
            [
              35.293339,
              56.079418
            ],
            [
              35.297829,
              56.078454
            ],
            [
              35.301347,
              56.077689
            ],
            [
              35.30868852085176,
              56.0742855115314
            ],
            [
              35.308946167575776,
              56.074093350016405
            ],
            [
              35.309579,
              56.062758
            ],
            [
              35.308638,
              56.061664
            ],
            [
              35.308343,
              56.061339
            ],
            [
              35.308171,
              56.060494
            ],
            [
              35.308955,
              56.056981
            ],
            [
              35.309154,
              56.057025
            ],
            [
              35.309994,
              56.057461
            ],
            [
              35.31697428601903,
              56.05865489507401
            ],
            [
              35.325154,
              56.056766
            ],
            [
              35.33544,
              56.055152
            ],
            [
              35.336050637156674,
              56.05557643389814
            ],
            [
              35.337133,
              56.054906
            ],
            [
              35.341976,
              56.057443
            ],
            [
              35.34863,
              56.062055
            ],
            [
              35.352432,
              56.064214
            ],
            [
              35.361462,
              56.062335
            ],
            [
              35.366272,
              56.060098
            ],
            [
              35.372245,
              56.051686
            ],
            [
              35.37532512300381,
              56.05218567399828
            ],
            [
              35.375486,
              56.051835
            ],
            [
              35.382045,
              56.044241
            ],
            [
              35.3852622704887,
              56.043032922321856
            ],
            [
              35.387342,
              56.04194
            ],
            [
              35.38778385011338,
              56.042086074965084
            ],
            [
              35.388575,
              56.041789
            ],
            [
              35.38895290230763,
              56.04247256181502
            ],
            [
              35.391486,
              56.04331
            ],
            [
              35.39780693128804,
              56.04437779518261
            ],
            [
              35.406844,
              56.042041
            ],
            [
              35.412051,
              56.041501
            ],
            [
              35.417691,
              56.041131
            ],
            [
              35.42334468847765,
              56.040740713118
            ],
            [
              35.424484,
              56.040662
            ],
            [
              35.430101,
              56.040275
            ],
            [
              35.433319655875074,
              56.04005666666931
            ],
            [
              35.437421,
              56.039778
            ],
            [
              35.442895,
              56.039407
            ],
            [
              35.443227,
              56.039385
            ],
            [
              35.450626,
              56.038887
            ],
            [
              35.451675,
              56.028647
            ],
            [
              35.451167,
              56.027962
            ],
            [
              35.447408,
              56.025595
            ],
            [
              35.437889,
              56.022983
            ],
            [
              35.433088,
              56.023372
            ],
            [
              35.429916,
              56.023413
            ],
            [
              35.425811334930586,
              56.0227167541512
            ],
            [
              35.416575,
              56.02223
            ],
            [
              35.413532,
              56.024389
            ],
            [
              35.410031,
              56.025745
            ],
            [
              35.409046,
              56.026493
            ],
            [
              35.406511,
              56.023937
            ],
            [
              35.40648764205655,
              56.01791716908448
            ],
            [
              35.405573,
              56.012256
            ],
            [
              35.395247,
              56.00833
            ],
            [
              35.389182,
              56.006326
            ],
            [
              35.385531,
              56.005098
            ],
            [
              35.375258,
              56.000949
            ],
            [
              35.37017,
              55.998965
            ],
            [
              35.369782,
              55.99857
            ],
            [
              35.369467,
              55.997967
            ],
            [
              35.36976070326057,
              55.9972225490619
            ],
            [
              35.36812178107459,
              55.99616292525868
            ],
            [
              35.364017832262086,
              55.99674650220619
            ],
            [
              35.360057,
              56.000005
            ],
            [
              35.351536,
              56.003972
            ],
            [
              35.349337,
              56.000237
            ],
            [
              35.343452,
              55.991682
            ],
            [
              35.339847,
              55.991128
            ],
            [
              35.335488,
              55.991731
            ],
            [
              35.331869,
              55.993517
            ],
            [
              35.32934817700487,
              55.994620121051156
            ],
            [
              35.326444,
              55.996549
            ],
            [
              35.323904,
              55.997623
            ],
            [
              35.32347044164793,
              55.997454255251874
            ],
            [
              35.322112,
              55.998357
            ],
            [
              35.319939,
              55.997966
            ],
            [
              35.310966901798636,
              55.994431242317454
            ],
            [
              35.30701272724014,
              55.99482724927578
            ],
            [
              35.303062256604036,
              55.996953334810684
            ],
            [
              35.297042,
              56.002551
            ],
            [
              35.297001796595794,
              56.00253751248233
            ],
            [
              35.296716,
              56.00336
            ],
            [
              35.29648846831027,
              56.0060859313331
            ],
            [
              35.296415,
              56.00697
            ],
            [
              35.29641467792972,
              56.00696997307105
            ],
            [
              35.296358,
              56.007649
            ],
            [
              35.302697,
              56.016831
            ],
            [
              35.30993,
              56.02031
            ],
            [
              35.313767,
              56.020473
            ],
            [
              35.32153096651545,
              56.02091724261549
            ],
            [
              35.327745,
              56.021272
            ],
            [
              35.32774498828451,
              56.021272204395586
            ],
            [
              35.329878,
              56.021394
            ],
            [
              35.32987796122511,
              56.021394676490125
            ],
            [
              35.33109,
              56.021464
            ],
            [
              35.33559,
              56.021097
            ],
            [
              35.339925,
              56.020637
            ],
            [
              35.346394,
              56.018051
            ],
            [
              35.346773,
              56.017994
            ],
            [
              35.347303,
              56.021555
            ],
            [
              35.347703,
              56.023688
            ],
            [
              35.34402,
              56.024599
            ],
            [
              35.339997,
              56.024237
            ],
            [
              35.336066,
              56.024665
            ],
            [
              35.330982,
              56.025063
            ],
            [
              35.322247,
              56.024564
            ],
            [
              35.31927250899716,
              56.02920746847384
            ],
            [
              35.317674,
              56.032398
            ],
            [
              35.316207,
              56.035686
            ],
            [
              35.315815,
              56.035511
            ],
            [
              35.311226,
              56.029607
            ],
            [
              35.308354,
              56.027576
            ],
            [
              35.301792,
              56.024577
            ],
            [
              35.296061,
              56.024246
            ],
            [
              35.289836,
              56.029256
            ],
            [
              35.287908,
              56.033998
            ],
            [
              35.285977,
              56.038113
            ],
            [
              35.28581252805824,
              56.03832931520108
            ],
            [
              35.285603,
              56.039559
            ],
            [
              35.285565,
              56.039704
            ],
            [
              35.28553695365266,
              56.03969663803519
            ],
            [
              35.285478,
              56.040025
            ],
            [
              35.284799,
              56.042116
            ],
            [
              35.283302564576836,
              56.04163044516497
            ],
            [
              35.282887,
              56.042177
            ],
            [
              35.278195,
              56.043899
            ],
            [
              35.272824,
              56.046105
            ],
            [
              35.269062,
              56.048124
            ],
            [
              35.265313,
              56.050011
            ],
            [
              35.265172,
              56.050057
            ],
            [
              35.26402,
              56.048027
            ],
            [
              35.263025,
              56.044567
            ],
            [
              35.26426,
              56.044212
            ],
            [
              35.271331,
              56.042829
            ],
            [
              35.27768,
              56.040336
            ],
            [
              35.27952854361225,
              56.03825187331533
            ],
            [
              35.27933466902787,
              56.03796279998802
            ],
            [
              35.27221317009934,
              56.03581935217963
            ],
            [
              35.269018,
              56.035995
            ],
            [
              35.26882,
              56.0324
            ],
            [
              35.27711674628146,
              56.031943956213844
            ],
            [
              35.27749810746753,
              56.03192295199656
            ],
            [
              35.2865,
              56.027902
            ],
            [
              35.280659,
              56.018459
            ],
            [
              35.27568,
              56.017991
            ],
            [
              35.270912,
              56.01762
            ],
            [
              35.266947,
              56.016919
            ],
            [
              35.26281,
              56.014762
            ],
            [
              35.254344,
              56.008973
            ],
            [
              35.247758,
              56.008542
            ],
            [
              35.239212,
              56.00639
            ],
            [
              35.237425,
              56.003265
            ],
            [
              35.238344,
              56.002739
            ],
            [
              35.239643,
              56.002395
            ],
            [
              35.243466,
              56.001003
            ],
            [
              35.252785,
              55.995098
            ],
            [
              35.254688,
              55.992133
            ],
            [
              35.256626,
              55.989116
            ],
            [
              35.25774670824462,
              55.988159048211216
            ],
            [
              35.257706,
              55.98811
            ],
            [
              35.259341,
              55.985336
            ],
            [
              35.259376,
              55.985277
            ],
            [
              35.259691,
              55.98476
            ],
            [
              35.260354,
              55.984223
            ],
            [
              35.261855150701166,
              55.98371350304122
            ],
            [
              35.264695,
              55.977064
            ],
            [
              35.261921,
              55.973113
            ],
            [
              35.259201,
              55.967806
            ],
            [
              35.25494733614175,
              55.961854970856116
            ],
            [
              35.245259,
              55.957674
            ],
            [
              35.238993,
              55.956689
            ],
            [
              35.234602,
              55.954825
            ],
            [
              35.230324,
              55.952983
            ],
            [
              35.227047,
              55.952018
            ],
            [
              35.228063,
              55.948565
            ],
            [
              35.23134,
              55.949529
            ],
            [
              35.234618,
              55.950493
            ],
            [
              35.245816,
              55.954118
            ],
            [
              35.25172472351,
              55.9567555362048
            ],
            [
              35.252327,
              55.955181
            ],
            [
              35.254086,
              55.955854
            ],
            [
              35.25490106112808,
              55.956319168336506
            ],
            [
              35.254942,
              55.956246
            ],
            [
              35.257306,
              55.957568
            ],
            [
              35.258095,
              55.958142
            ],
            [
              35.26007,
              55.961273
            ],
            [
              35.26245,
              55.966257
            ],
            [
              35.267629,
              55.974979
            ],
            [
              35.269904,
              55.978169
            ],
            [
              35.273686,
              55.982469
            ],
            [
              35.273708905431484,
              55.98249995909195
            ],
            [
              35.273716,
              55.982498
            ],
            [
              35.274608,
              55.983365
            ],
            [
              35.278484,
              55.988954
            ],
            [
              35.280097975645546,
              55.98929278779063
            ],
            [
              35.280472,
              55.989275
            ],
            [
              35.28401,
              55.98994
            ],
            [
              35.288798,
              55.991119
            ],
            [
              35.29283497173372,
              55.99130860503299
            ],
            [
              35.292835,
              55.991308
            ],
            [
              35.29356452459518,
              55.99134211733583
            ],
            [
              35.2982198503633,
              55.991371604431805
            ],
            [
              35.306674,
              55.991166
            ],
            [
              35.31048579715441,
              55.99078330766664
            ],
            [
              35.310483,
              55.99074
            ],
            [
              35.31086003597433,
              55.99071555713694
            ],
            [
              35.31086,
              55.990715
            ],
            [
              35.31204197568699,
              55.99062189528691
            ],
            [
              35.319581,
              55.989746
            ],
            [
              35.325439,
              55.988828
            ],
            [
              35.329416,
              55.988562
            ],
            [
              35.339525,
              55.987542
            ],
            [
              35.344064,
              55.988135
            ],
            [
              35.351292,
              55.989478
            ],
            [
              35.358497,
              55.990818
            ],
            [
              35.362713,
              55.991134
            ],
            [
              35.37218,
              55.990465
            ],
            [
              35.382846,
              55.986861
            ],
            [
              35.384264,
              55.984899
            ],
            [
              35.386986,
              55.982407
            ],
            [
              35.387209107781196,
              55.982202987195606
            ],
            [
              35.388946,
              55.980613
            ],
            [
              35.388946871748125,
              55.980613952073746
            ],
            [
              35.393015,
              55.976894
            ],
            [
              35.402389,
              55.974648
            ],
            [
              35.406004,
              55.974557
            ],
            [
              35.411788,
              55.97554
            ],
            [
              35.4119744392487,
              55.9754474567599
            ],
            [
              35.411687,
              55.973732
            ],
            [
              35.414582,
              55.973248
            ],
            [
              35.417163,
              55.972872
            ],
            [
              35.419615,
              55.965222
            ],
            [
              35.42225256192202,
              55.96150929561273
            ],
            [
              35.422135,
              55.960838
            ],
            [
              35.424058,
              55.959697
            ],
            [
              35.434714,
              55.956795
            ],
            [
              35.44632,
              55.955214
            ],
            [
              35.448505,
              55.953512
            ],
            [
              35.450028,
              55.953185
            ],
            [
              35.4539445126818,
              55.95191781635849
            ],
            [
              35.457891,
              55.949885
            ],
            [
              35.46214,
              55.940979
            ],
            [
              35.461429,
              55.93745
            ],
            [
              35.461462268378824,
              55.937428157017855
            ],
            [
              35.459057,
              55.936798
            ],
            [
              35.453454,
              55.936643
            ],
            [
              35.449638,
              55.936473
            ],
            [
              35.44905559559349,
              55.93615399203081
            ],
            [
              35.448507,
              55.936093
            ],
            [
              35.447585,
              55.935437
            ],
            [
              35.44762355935618,
              55.93536960412652
            ],
            [
              35.446182,
              55.93458
            ],
            [
              35.445494,
              55.934243
            ],
            [
              35.44551486140141,
              55.93420042114218
            ],
            [
              35.441426,
              55.931874
            ],
            [
              35.438293,
              55.929752
            ],
            [
              35.43197,
              55.922747
            ],
            [
              35.429543,
              55.919671
            ],
            [
              35.436526,
              55.921983
            ],
            [
              35.439333,
              55.924585
            ],
            [
              35.442903,
              55.928591
            ],
            [
              35.451367669320405,
              55.93212906654965
            ],
            [
              35.451942,
              55.932002
            ],
            [
              35.45553,
              55.932293
            ],
            [
              35.459171,
              55.9332
            ],
            [
              35.464249,
              55.935121
            ],
            [
              35.46917,
              55.936289
            ],
            [
              35.469170076346494,
              55.93628902155911
            ],
            [
              35.4699,
              55.936221
            ],
            [
              35.471853,
              55.93604
            ],
            [
              35.481328,
              55.932997
            ],
            [
              35.48528,
              55.930648
            ],
            [
              35.49101,
              55.92886
            ],
            [
              35.496405,
              55.92848
            ],
            [
              35.500358,
              55.927903
            ],
            [
              35.504287,
              55.927159
            ],
            [
              35.507436,
              55.917182
            ],
            [
              35.497594,
              55.915255
            ],
            [
              35.498301675241954,
              55.91345064521131
            ],
            [
              35.498234,
              55.913423
            ],
            [
              35.497982,
              55.911811
            ],
            [
              35.498165373633185,
              55.910357783085026
            ],
            [
              35.48202160242605,
              55.89905442179978
            ],
            [
              35.479544,
              55.899322
            ],
            [
              35.47954262516593,
              55.899309285449235
            ],
            [
              35.477196,
              55.899551
            ],
            [
              35.471776,
              55.89954
            ],
            [
              35.467196,
              55.89895
            ],
            [
              35.462696,
              55.898114
            ],
            [
              35.460969,
              55.897455
            ],
            [
              35.459241,
              55.896797
            ],
            [
              35.459247781311625,
              55.89677920566902
            ],
            [
              35.458759,
              55.89659
            ],
            [
              35.455881,
              55.895982
            ],
            [
              35.453002,
              55.895374
            ],
            [
              35.45300235257927,
              55.895372330935224
            ],
            [
              35.452112,
              55.895184
            ],
            [
              35.447313,
              55.893771
            ],
            [
              35.440317,
              55.889925
            ],
            [
              35.436302,
              55.889014
            ],
            [
              35.430134,
              55.889501
            ],
            [
              35.425729,
              55.888485
            ],
            [
              35.421786,
              55.887589
            ],
            [
              35.41159545535906,
              55.88721349580704
            ],
            [
              35.40915826045862,
              55.88720428827046
            ],
            [
              35.407718,
              55.887199
            ],
            [
              35.40771800055222,
              55.8871988470783
            ],
            [
              35.406435,
              55.887194
            ],
            [
              35.4064350069644,
              55.8871920713968
            ],
            [
              35.40445,
              55.887184
            ],
            [
              35.400111,
              55.88672
            ],
            [
              35.396475,
              55.886305
            ],
            [
              35.395689,
              55.88625
            ],
            [
              35.388747,
              55.885972
            ],
            [
              35.388891,
              55.882375
            ],
            [
              35.392362,
              55.882514
            ],
            [
              35.395834,
              55.882653
            ],
            [
              35.396722,
              55.882714
            ],
            [
              35.400547,
              55.883146
            ],
            [
              35.411596,
              55.883613
            ],
            [
              35.418625,
              55.883639
            ],
            [
              35.422245,
              55.884018
            ],
            [
              35.426573,
              55.884985
            ],
            [
              35.430896,
              55.885983
            ],
            [
              35.448407,
              55.890342
            ],
            [
              35.452984,
              55.891692
            ],
            [
              35.45374583524002,
              55.891852779952494
            ],
            [
              35.453746,
              55.891852
            ],
            [
              35.459504,
              55.893068
            ],
            [
              35.467701,
              55.895386
            ],
            [
              35.472236,
              55.89597
            ],
            [
              35.4771,
              55.895952
            ],
            [
              35.481634,
              55.895475
            ],
            [
              35.481636253061886,
              55.89547520559341
            ],
            [
              35.48263760420371,
              55.89536709368282
            ],
            [
              35.486083,
              55.894994
            ],
            [
              35.491932089344395,
              55.894372505404114
            ],
            [
              35.492354,
              55.893569
            ],
            [
              35.495339,
              55.891561
            ],
            [
              35.49611,
              55.891618
            ],
            [
              35.49788295746401,
              55.89139802024926
            ],
            [
              35.500381,
              55.883801
            ],
            [
              35.50045957657677,
              55.88187719819341
            ],
            [
              35.500523,
              55.880315
            ],
            [
              35.50052338302289,
              55.880315015653146
            ],
            [
              35.500559,
              55.879443
            ],
            [
              35.500652,
              55.874612
            ],
            [
              35.500746,
              55.86978
            ],
            [
              35.500746106697115,
              55.86978000207466
            ],
            [
              35.500815,
              55.866225
            ],
            [
              35.500831187006355,
              55.865281003220666
            ],
            [
              35.500831,
              55.865281
            ],
            [
              35.500844191899866,
              55.864522581476265
            ],
            [
              35.500881,
              55.862376
            ],
            [
              35.500925,
              55.856955
            ],
            [
              35.50092498969403,
              55.85694849987452
            ],
            [
              35.500906,
              55.855132
            ],
            [
              35.500918,
              55.85254
            ],
            [
              35.502771,
              55.849479
            ],
            [
              35.497705,
              55.840099
            ],
            [
              35.490307,
              55.83759
            ],
            [
              35.481537,
              55.837282
            ],
            [
              35.478186,
              55.837056
            ],
            [
              35.47827605230221,
              55.83571935591091
            ],
            [
              35.476441,
              55.83484
            ],
            [
              35.477517,
              55.828161
            ],
            [
              35.477509,
              55.825425
            ],
            [
              35.481109,
              55.825414
            ],
            [
              35.481117,
              55.82815
            ],
            [
              35.490524,
              55.833996
            ],
            [
              35.498835,
              55.836681
            ],
            [
              35.50923757416623,
              55.8315457038718
            ],
            [
              35.509463,
              55.831079
            ],
            [
              35.51127,
              55.82734
            ],
            [
              35.515850001271886,
              55.817813000611636
            ],
            [
              35.51585,
              55.817813
            ],
            [
              35.518139,
              55.81305
            ],
            [
              35.51696549881947,
              55.81107651299919
            ],
            [
              35.510433,
              55.805724
            ],
            [
              35.505355506040004,
              55.80281030407428
            ],
            [
              35.503554,
              55.802729
            ],
            [
              35.496707,
              55.804146
            ],
            [
              35.494639149836196,
              55.804026951054915
            ],
            [
              35.494639,
              55.804027
            ],
            [
              35.489376,
              55.803727
            ],
            [
              35.48930291945884,
              55.80371907309068
            ],
            [
              35.486013,
              55.803529
            ],
            [
              35.482019,
              55.802929
            ],
            [
              35.473756,
              55.801677
            ],
            [
              35.46488,
              55.800341
            ],
            [
              35.458166,
              55.799408
            ],
            [
              35.454685,
              55.798954
            ],
            [
              35.450947,
              55.798365
            ],
            [
              35.447235,
              55.79778
            ],
            [
              35.442005,
              55.797033
            ],
            [
              35.434895,
              55.795954
            ],
            [
              35.427769,
              55.794872
            ],
            [
              35.426029,
              55.794592
            ],
            [
              35.42603921456596,
              55.79452862690752
            ],
            [
              35.424288,
              55.794181
            ],
            [
              35.421615,
              55.793274
            ],
            [
              35.41889,
              55.792041
            ],
            [
              35.416166,
              55.790807
            ],
            [
              35.41617383378958,
              55.790789702359575
            ],
            [
              35.411055,
              55.788432
            ],
            [
              35.402541,
              55.785339
            ],
            [
              35.399251,
              55.781546
            ],
            [
              35.401779,
              55.778982
            ],
            [
              35.407491,
              55.782806
            ],
            [
              35.412572,
              55.785168
            ],
            [
              35.42034826378032,
              55.78874889803272
            ],
            [
              35.42035799467521,
              55.78875330265585
            ],
            [
              35.424116,
              55.790388
            ],
            [
              35.428341,
              55.791318
            ],
            [
              35.42891092028533,
              55.79140452440757
            ],
            [
              35.428911,
              55.791404
            ],
            [
              35.435728,
              55.792439
            ],
            [
              35.442546,
              55.793474
            ],
            [
              35.447744,
              55.794216
            ],
            [
              35.451508,
              55.794809
            ],
            [
              35.458631,
              55.795838
            ],
            [
              35.46246108847016,
              55.79637038060187
            ],
            [
              35.462465,
              55.796344
            ],
            [
              35.46765,
              55.796121
            ],
            [
              35.46871934691519,
              55.796442077400336
            ],
            [
              35.469135,
              55.796448
            ],
            [
              35.474301,
              55.798118
            ],
            [
              35.482553,
              55.799369
            ],
            [
              35.486548,
              55.799969
            ],
            [
              35.492777,
              55.800313
            ],
            [
              35.497458,
              55.799342
            ],
            [
              35.501075,
              55.799182
            ],
            [
              35.5022932941636,
              55.79917166394771
            ],
            [
              35.503476,
              55.79913
            ],
            [
              35.50408351274744,
              55.799156475667694
            ],
            [
              35.505554,
              55.799144
            ],
            [
              35.50753925870773,
              55.79930707856791
            ],
            [
              35.508157,
              55.799334
            ],
            [
              35.509351326472434,
              55.799440679261814
            ],
            [
              35.516652,
              55.79021
            ],
            [
              35.51935327161985,
              55.79062099559006
            ],
            [
              35.520985,
              55.788413
            ],
            [
              35.529011,
              55.78266
            ],
            [
              35.530391,
              55.778347
            ],
            [
              35.522192,
              55.771472
            ],
            [
              35.51695687220249,
              55.77089114855112
            ],
            [
              35.513017,
              55.770462
            ],
            [
              35.512931876809134,
              55.77044456453323
            ],
            [
              35.510971,
              55.770227
            ],
            [
              35.506138,
              55.769053
            ],
            [
              35.502334,
              55.768007
            ],
            [
              35.495452,
              55.76585
            ],
            [
              35.491643,
              55.764029
            ],
            [
              35.48763,
              55.762094
            ],
            [
              35.48959,
              55.759074
            ],
            [
              35.493196,
              55.760781
            ],
            [
              35.497005,
              55.762602
            ],
            [
              35.503402,
              55.764569
            ],
            [
              35.507091,
              55.765581
            ],
            [
              35.51164065679223,
              55.76651792632379
            ],
            [
              35.521396,
              55.761499
            ],
            [
              35.515159,
              55.751962
            ],
            [
              35.51067765621261,
              55.751517580374966
            ],
            [
              35.508362,
              55.752057
            ],
            [
              35.50820691001604,
              55.747553619675074
            ],
            [
              35.506272,
              55.745925
            ],
            [
              35.506312,
              55.745653
            ],
            [
              35.509125,
              55.743523
            ],
            [
              35.51289273586391,
              55.735976670243765
            ],
            [
              35.510125,
              55.730587
            ],
            [
              35.50207916322894,
              55.72939924350055
            ],
            [
              35.50188119381599,
              55.72937954563138
            ],
            [
              35.495562297398514,
              55.72894096133459
            ],
            [
              35.49494,
              55.728925
            ],
            [
              35.494633,
              55.728894
            ],
            [
              35.4923784997876,
              55.72855549216549
            ],
            [
              35.48822,
              55.728013
            ],
            [
              35.48648153700728,
              55.72783676751868
            ],
            [
              35.485516,
              55.727739
            ],
            [
              35.48551601118052,
              55.72773888967317
            ],
            [
              35.482173,
              55.7274
            ],
            [
              35.47735086028949,
              55.72690621525202
            ],
            [
              35.475969,
              55.726765
            ],
            [
              35.47596902898541,
              55.7267647164023
            ],
            [
              35.47563,
              55.72673
            ],
            [
              35.471759,
              55.726348
            ],
            [
              35.467876,
              55.725964
            ],
            [
              35.462964,
              55.725446
            ],
            [
              35.458065,
              55.724929
            ],
            [
              35.45035,
              55.727009
            ],
            [
              35.445106,
              55.7273
            ],
            [
              35.437177,
              55.7332
            ],
            [
              35.432638,
              55.74354
            ],
            [
              35.43263798834601,
              55.74353999887461
            ],
            [
              35.432343,
              55.746593
            ],
            [
              35.43218502085225,
              55.74657774440828
            ],
            [
              35.431985,
              55.748173
            ],
            [
              35.430033,
              55.751168
            ],
            [
              35.429331,
              55.762178
            ],
            [
              35.426054,
              55.763668
            ],
            [
              35.418169901535165,
              55.75873745112208
            ],
            [
              35.41420524232463,
              55.75812103767591
            ],
            [
              35.408961,
              55.759122
            ],
            [
              35.408960978964714,
              55.75912188977513
            ],
            [
              35.407321,
              55.759435
            ],
            [
              35.4073209074567,
              55.7594345150731
            ],
            [
              35.403178,
              55.760225
            ],
            [
              35.399511,
              55.760885
            ],
            [
              35.39454,
              55.761569
            ],
            [
              35.390942,
              55.770126
            ],
            [
              35.390565,
              55.773706
            ],
            [
              35.380696,
              55.775272
            ],
            [
              35.379511,
              55.773103
            ],
            [
              35.380904,
              55.769756
            ],
            [
              35.38234923529676,
              55.76794620629122
            ],
            [
              35.38136,
              55.76753
            ],
            [
              35.370736,
              55.762844
            ],
            [
              35.371184,
              55.759272
            ],
            [
              35.373502829667316,
              55.75956278571391
            ],
            [
              35.375637,
              55.75983
            ],
            [
              35.378647,
              55.760208
            ],
            [
              35.384088,
              55.759349
            ],
            [
              35.394091,
              55.757997
            ],
            [
              35.398977,
              55.757325
            ],
            [
              35.40254,
              55.756682
            ],
            [
              35.406646098484366,
              55.755898516058075
            ],
            [
              35.406646,
              55.755898
            ],
            [
              35.408286012720225,
              55.75558506665399
            ],
            [
              35.408286,
              55.755585
            ],
            [
              35.409336160576196,
              55.75538468632869
            ],
            [
              35.4147062574361,
              55.75436000989901
            ],
            [
              35.41641388211429,
              55.75403414226702
            ],
            [
              35.427017,
              55.749202
            ],
            [
              35.4292973649489,
              55.74068237260213
            ],
            [
              35.429482,
              55.738769
            ],
            [
              35.42991,
              55.734344
            ],
            [
              35.426714,
              55.726498
            ],
            [
              35.418555,
              55.724436
            ],
            [
              35.41756795454173,
              55.72418639952059
            ],
            [
              35.41596,
              55.72378
            ],
            [
              35.415960050935176,
              55.72377979868202
            ],
            [
              35.410385,
              55.72237
            ],
            [
              35.409515,
              55.722361
            ],
            [
              35.40946696476517,
              55.72234147970537
            ],
            [
              35.405443557424846,
              55.72221648585066
            ],
            [
              35.403956,
              55.722227
            ],
            [
              35.40338762537374,
              55.72215261489494
            ],
            [
              35.402885,
              55.722137
            ],
            [
              35.40269146253014,
              55.72206150571472
            ],
            [
              35.400189,
              55.721734
            ],
            [
              35.3923306343571,
              55.72255266975774
            ],
            [
              35.388096164881745,
              55.7239959438018
            ],
            [
              35.387291,
              55.724764
            ],
            [
              35.387290830949766,
              55.72476382278638
            ],
            [
              35.386995,
              55.725046
            ],
            [
              35.386983096893466,
              55.72503352688555
            ],
            [
              35.385856,
              55.726097
            ],
            [
              35.383801089977965,
              55.72740748200602
            ],
            [
              35.383716,
              55.727478
            ],
            [
              35.382825065845466,
              55.72802992388882
            ],
            [
              35.38187,
              55.728639
            ],
            [
              35.381681036675324,
              55.72873863735018
            ],
            [
              35.379724,
              55.729951
            ],
            [
              35.37913908910828,
              55.730078965799166
            ],
            [
              35.378663,
              55.73033
            ],
            [
              35.370688,
              55.732183
            ],
            [
              35.366067,
              55.733449
            ],
            [
              35.360886,
              55.734849
            ],
            [
              35.357204,
              55.735345
            ],
            [
              35.351818,
              55.736136
            ],
            [
              35.349567,
              55.736731
            ],
            [
              35.348646,
              55.733251
            ],
            [
              35.350897,
              55.732655
            ],
            [
              35.353148,
              55.73206
            ],
            [
              35.354462,
              55.73184
            ],
            [
              35.360144,
              55.731326
            ],
            [
              35.365126,
              55.729974
            ],
            [
              35.369566,
              55.719772
            ],
            [
              35.37149064599968,
              55.71832753819184
            ],
            [
              35.3715,
              55.717398
            ],
            [
              35.37209101559948,
              55.717876956132685
            ],
            [
              35.37252,
              55.717555
            ],
            [
              35.37968463263181,
              55.72263200038426
            ],
            [
              35.38048388294948,
              55.722723121392136
            ],
            [
              35.384365174577184,
              55.72086073432309
            ],
            [
              35.3842992729824,
              55.72014119263037
            ],
            [
              35.382022,
              55.711073
            ],
            [
              35.378932,
              55.711017
            ],
            [
              35.37893200501321,
              55.71101672234514
            ],
            [
              35.378508,
              55.711009
            ],
            [
              35.378508032858214,
              55.71100718066559
            ],
            [
              35.378192,
              55.711032
            ],
            [
              35.377904,
              55.707444
            ],
            [
              35.378286,
              55.707413
            ],
            [
              35.378572892618635,
              55.70741594562356
            ],
            [
              35.378573,
              55.70741
            ],
            [
              35.37899698918044,
              55.70741759923709
            ],
            [
              35.378997,
              55.707417
            ],
            [
              35.3794174040357,
              55.70742461650793
            ],
            [
              35.383448,
              55.707466
            ],
            [
              35.38930327407251,
              55.70694229846804
            ],
            [
              35.389239,
              55.706413
            ],
            [
              35.39416,
              55.706442
            ],
            [
              35.40239033992694,
              55.701041714759825
            ],
            [
              35.39882620202551,
              55.6939698764984
            ],
            [
              35.393548,
              55.691514
            ],
            [
              35.386471,
              55.691483
            ],
            [
              35.38560846703935,
              55.69147909989335
            ],
            [
              35.383083,
              55.691468
            ],
            [
              35.383083001419905,
              55.69146768052076
            ],
            [
              35.379394,
              55.691451
            ],
            [
              35.379394013888856,
              55.69144787500694
            ],
            [
              35.37742,
              55.691438
            ],
            [
              35.371631,
              55.693419
            ],
            [
              35.36815,
              55.694679
            ],
            [
              35.363028,
              55.695272
            ],
            [
              35.35926,
              55.694615
            ],
            [
              35.357618,
              55.693196
            ],
            [
              35.35667809955143,
              55.69167618196092
            ],
            [
              35.355049,
              55.689237
            ],
            [
              35.35504926512742,
              55.68923682289417
            ],
            [
              35.355018,
              55.68919
            ],
            [
              35.356291166752136,
              55.68833994644706
            ],
            [
              35.356214,
              55.687691
            ],
            [
              35.356776539138465,
              55.688015878511095
            ],
            [
              35.358012,
              55.687191
            ],
            [
              35.36756,
              55.691128
            ],
            [
              35.373196,
              55.689019
            ],
            [
              35.377163,
              55.687847
            ],
            [
              35.37940998033867,
              55.68785542379858
            ],
            [
              35.37941,
              55.687851
            ],
            [
              35.385134856524026,
              55.68787688602639
            ],
            [
              35.385432,
              55.687878
            ],
            [
              35.388331543638145,
              55.6878908361499
            ],
            [
              35.399008,
              55.684673
            ],
            [
              35.400125,
              55.684727
            ],
            [
              35.404208,
              55.684811
            ],
            [
              35.413167,
              55.689761
            ],
            [
              35.421002,
              55.687986
            ],
            [
              35.427756183112194,
              55.67900719857997
            ],
            [
              35.427356,
              55.677754
            ],
            [
              35.425384,
              55.674742
            ],
            [
              35.431857,
              55.675631
            ],
            [
              35.435917,
              55.676859
            ],
            [
              35.447348,
              55.680264
            ],
            [
              35.452809,
              55.681042
            ],
            [
              35.458775383862715,
              55.68164048835026
            ],
            [
              35.464268,
              55.679446
            ],
            [
              35.46546254787321,
              55.679589720085474
            ],
            [
              35.466148,
              55.679311
            ],
            [
              35.47436696627091,
              55.68045899833854
            ],
            [
              35.47686053916803,
              55.68068179624977
            ],
            [
              35.47970977055356,
              55.680671370416164
            ],
            [
              35.47989188851961,
              55.68066622877015
            ],
            [
              35.483812,
              55.680435
            ],
            [
              35.48393077296617,
              55.680459376037675
            ],
            [
              35.485379,
              55.680359
            ],
            [
              35.488743,
              55.681447
            ],
            [
              35.490957,
              55.684247
            ],
            [
              35.495880765882845,
              55.68619124084708
            ],
            [
              35.503395,
              55.686592
            ],
            [
              35.512541,
              55.686976
            ],
            [
              35.51588254482667,
              55.68748504134075
            ],
            [
              35.515929,
              55.68735
            ],
            [
              35.51667,
              55.687605
            ],
            [
              35.525971,
              55.682796
            ],
            [
              35.526314,
              55.679212
            ],
            [
              35.526802,
              55.679259
            ],
            [
              35.530304,
              55.679807
            ],
            [
              35.53766609729817,
              55.67672989234386
            ],
            [
              35.53778363665101,
              55.675695421091994
            ],
            [
              35.535825,
              55.673305
            ],
            [
              35.53246657005133,
              55.67214195657728
            ],
            [
              35.530254,
              55.672034
            ],
            [
              35.52899072713157,
              55.67181202688907
            ],
            [
              35.527737,
              55.671711
            ],
            [
              35.52425,
              55.67112
            ],
            [
              35.5226240259324,
              55.67061774841162
            ],
            [
              35.522312,
              55.670557
            ],
            [
              35.518441,
              55.669852
            ],
            [
              35.514884,
              55.669928
            ],
            [
              35.508099,
              55.668442
            ],
            [
              35.498344,
              55.666299
            ],
            [
              35.498200632484036,
              55.66610076563754
            ],
            [
              35.493795,
              55.665167
            ],
            [
              35.49693243360332,
              55.66434722617708
            ],
            [
              35.496737,
              55.664077
            ],
            [
              35.497256,
              55.662867
            ],
            [
              35.499181,
              55.661434
            ],
            [
              35.507895560107215,
              55.659820108934106
            ],
            [
              35.510944,
              55.658132
            ],
            [
              35.512038,
              55.654778
            ],
            [
              35.51333569514085,
              55.65630301036284
            ],
            [
              35.513983,
              55.655808
            ],
            [
              35.51415779927164,
              55.65726912118449
            ],
            [
              35.515155,
              55.658441
            ],
            [
              35.521024,
              55.666255
            ],
            [
              35.524611,
              55.667445
            ],
            [
              35.5268027815786,
              55.66787342038168
            ],
            [
              35.526809,
              55.667801
            ],
            [
              35.527248,
              55.667838
            ],
            [
              35.528714,
              55.668247
            ],
            [
              35.532338,
              55.668477
            ],
            [
              35.536354,
              55.669241
            ],
            [
              35.540187,
              55.671065
            ],
            [
              35.540407677543215,
              55.67138326815523
            ],
            [
              35.540733,
              55.671529
            ],
            [
              35.54140875447471,
              55.67282705307141
            ],
            [
              35.542978117906635,
              55.67509043881103
            ],
            [
              35.544554,
              55.675225
            ],
            [
              35.547114,
              55.676138
            ],
            [
              35.55126652795742,
              55.68310816099593
            ],
            [
              35.554932402320915,
              55.68551139691375
            ],
            [
              35.554976725267586,
              55.68550401260486
            ],
            [
              35.561595,
              55.682473
            ],
            [
              35.566307,
              55.680323
            ],
            [
              35.570102,
              55.678671
            ],
            [
              35.574539,
              55.675268
            ],
            [
              35.574898,
              55.67944
            ],
            [
              35.571625,
              55.681932
            ],
            [
              35.57608855697326,
              55.69199508588848
            ],
            [
              35.578027158014706,
              55.69160544259495
            ],
            [
              35.585137,
              55.687479
            ],
            [
              35.589275,
              55.684966
            ],
            [
              35.58960264729282,
              55.685260956027065
            ],
            [
              35.593236,
              55.681622
            ],
            [
              35.598021,
              55.673814
            ],
            [
              35.597373,
              55.661841
            ],
            [
              35.60057,
              55.660186
            ],
            [
              35.601521,
              55.662023
            ],
            [
              35.604191,
              55.668089
            ],
            [
              35.6066,
              55.673854
            ],
            [
              35.613719,
              55.682043
            ],
            [
              35.613975759942015,
              55.68195235153907
            ],
            [
              35.613605,
              55.681523
            ],
            [
              35.616333,
              55.679173
            ],
            [
              35.61876723786637,
              55.679964189851226
            ],
            [
              35.627633,
              55.674599
            ],
            [
              35.62923094255266,
              55.67420396343784
            ],
            [
              35.629115,
              55.667977
            ],
            [
              35.627426,
              55.665417
            ],
            [
              35.626454,
              55.660907
            ],
            [
              35.633353,
              55.658866
            ],
            [
              35.634054819557804,
              55.65631626681098
            ],
            [
              35.633843,
              55.656277
            ],
            [
              35.633958,
              55.651678
            ],
            [
              35.639668,
              55.645895
            ],
            [
              35.643922,
              55.642527
            ],
            [
              35.640365,
              55.632232
            ],
            [
              35.634651,
              55.63048
            ],
            [
              35.626212,
              55.62883
            ],
            [
              35.620642,
              55.630724
            ],
            [
              35.616671,
              55.630924
            ],
            [
              35.609347,
              55.630935
            ],
            [
              35.606872,
              55.630779
            ],
            [
              35.602127,
              55.630372
            ],
            [
              35.59915583965249,
              55.63011709648789
            ],
            [
              35.589514,
              55.635919
            ],
            [
              35.588069,
              55.639783
            ],
            [
              35.587141,
              55.641122
            ],
            [
              35.586772,
              55.641454
            ],
            [
              35.5845127254076,
              55.64036536503633
            ],
            [
              35.577046,
              55.643312
            ],
            [
              35.574957,
              55.639814
            ],
            [
              35.579222,
              55.638564
            ],
            [
              35.57892865505933,
              55.6337142567261
            ],
            [
              35.575899,
              55.628129
            ],
            [
              35.571565,
              55.627701
            ],
            [
              35.56314,
              55.634957
            ],
            [
              35.56240318407975,
              55.63711891693913
            ],
            [
              35.561932,
              55.638502
            ],
            [
              35.561931827274186,
              55.63850194115767
            ],
            [
              35.560893,
              55.64155
            ],
            [
              35.559603,
              55.645546
            ],
            [
              35.558206,
              55.649726
            ],
            [
              35.557053,
              55.653089
            ],
            [
              35.554226,
              55.656989
            ],
            [
              35.549224,
              55.655826
            ],
            [
              35.544072,
              55.65437
            ],
            [
              35.541459,
              55.651894
            ],
            [
              35.542249,
              55.650553
            ],
            [
              35.544513,
              55.648191
            ],
            [
              35.54487547907181,
              55.648090692109726
            ],
            [
              35.54498,
              55.647648
            ],
            [
              35.54655,
              55.644408
            ],
            [
              35.557466,
              55.640447
            ],
            [
              35.55852419372336,
              55.63734106599555
            ],
            [
              35.558524,
              55.637341
            ],
            [
              35.560256,
              55.632257
            ],
            [
              35.556833,
              55.622573
            ],
            [
              35.556833,
              55.620497447537474
            ],
            [
              35.556785,
              55.620468
            ],
            [
              35.5501,
              55.620601
            ],
            [
              35.545029,
              55.620687
            ],
            [
              35.535872,
              55.620766
            ],
            [
              35.529397,
              55.620788
            ],
            [
              35.523732,
              55.6201
            ],
            [
              35.51482615557094,
              55.61873939657999
            ],
            [
              35.51281810066507,
              55.61848144697373
            ],
            [
              35.507853,
              55.61843
            ],
            [
              35.505917,
              55.618494
            ],
            [
              35.498095,
              55.618693
            ],
            [
              35.490274,
              55.618893
            ],
            [
              35.49027399239367,
              55.618892699173635
            ],
            [
              35.484343,
              55.619044
            ],
            [
              35.478412,
              55.619242
            ],
            [
              35.474621,
              55.619337
            ],
            [
              35.47391227736799,
              55.61935446074949
            ],
            [
              35.47145,
              55.619416
            ],
            [
              35.47144997809998,
              55.61941512424287
            ],
            [
              35.470765,
              55.619432
            ],
            [
              35.465784,
              55.619256
            ],
            [
              35.462107,
              55.61904
            ],
            [
              35.455903,
              55.618616
            ],
            [
              35.446594,
              55.619171
            ],
            [
              35.44312,
              55.620214
            ],
            [
              35.440299,
              55.630089
            ],
            [
              35.442508,
              55.634412
            ],
            [
              35.445036,
              55.637579
            ],
            [
              35.44570925059489,
              55.64219614461226
            ],
            [
              35.446092,
              55.644483
            ],
            [
              35.44579292902129,
              55.64453312598094
            ],
            [
              35.445844,
              55.645996
            ],
            [
              35.44427146675782,
              55.64904355004923
            ],
            [
              35.443632,
              55.650345
            ],
            [
              35.44360640634769,
              55.6503324289303
            ],
            [
              35.443409,
              55.650715
            ],
            [
              35.44038,
              55.653551
            ],
            [
              35.436365,
              55.654888
            ],
            [
              35.432381,
              55.65514
            ],
            [
              35.428353,
              55.654396
            ],
            [
              35.432181,
              55.65119
            ],
            [
              35.441380673744085,
              55.64676415695747
            ],
            [
              35.44138116747727,
              55.64676315210614
            ],
            [
              35.44216170275616,
              55.642811504868334
            ],
            [
              35.44216119842764,
              55.64280849917176
            ],
            [
              35.437861,
              55.634757
            ],
            [
              35.427301,
              55.629458
            ],
            [
              35.424792432846466,
              55.62813233759354
            ],
            [
              35.41687,
              55.627058
            ],
            [
              35.412472,
              55.627307
            ],
            [
              35.401222,
              55.628923
            ],
            [
              35.398037,
              55.634277
            ],
            [
              35.397537,
              55.634666
            ],
            [
              35.395639,
              55.633611
            ],
            [
              35.39543,
              55.633388
            ],
            [
              35.395329,
              55.631823
            ],
            [
              35.397812,
              55.627768
            ],
            [
              35.401039,
              55.622138
            ],
            [
              35.40268980149764,
              55.62180743657434
            ],
            [
              35.40307,
              55.621467
            ],
            [
              35.40469385551257,
              55.62140613638088
            ],
            [
              35.405134,
              55.621318
            ],
            [
              35.415737,
              55.617078
            ],
            [
              35.427014,
              55.617278
            ],
            [
              35.4357292739221,
              55.616829544157405
            ],
            [
              35.435729,
              55.616809
            ],
            [
              35.43627009823022,
              55.616801715333786
            ],
            [
              35.436284,
              55.616801
            ],
            [
              35.44237021172427,
              55.616650393664465
            ],
            [
              35.445533,
              55.615731
            ],
            [
              35.449735,
              55.614788
            ],
            [
              35.45008833145299,
              55.614800934954935
            ],
            [
              35.450089,
              55.614784
            ],
            [
              35.453067,
              55.614901
            ],
            [
              35.456045,
              55.615019
            ],
            [
              35.462352,
              55.615449
            ],
            [
              35.465995,
              55.615662
            ],
            [
              35.470805,
              55.615833
            ],
            [
              35.47136004621928,
              55.615818848257966
            ],
            [
              35.47136,
              55.615817
            ],
            [
              35.478322,
              55.615643
            ],
            [
              35.484223,
              55.615446
            ],
            [
              35.495031,
              55.61517
            ],
            [
              35.507690513380496,
              55.6148331570871
            ],
            [
              35.509656,
              55.614768
            ],
            [
              35.50967901819483,
              55.61476878895435
            ],
            [
              35.509679,
              55.614768
            ],
            [
              35.511053,
              55.614736
            ],
            [
              35.51198284025164,
              55.61479850060248
            ],
            [
              35.5123,
              55.614796
            ],
            [
              35.51270459966504,
              55.61484701474037
            ],
            [
              35.513478,
              55.614899
            ],
            [
              35.520506,
              55.614392
            ],
            [
              35.522453,
              55.614571
            ],
            [
              35.524175,
              55.61472
            ],
            [
              35.535859,
              55.617166
            ],
            [
              35.544954,
              55.617087
            ],
            [
              35.550039,
              55.617001
            ],
            [
              35.556653,
              55.616871
            ],
            [
              35.55967011548979,
              55.616541135490294
            ],
            [
              35.564744,
              55.612192
            ],
            [
              35.566422,
              55.609007
            ],
            [
              35.568577,
              55.606204
            ],
            [
              35.569180954205656,
              55.60515060224397
            ],
            [
              35.569003,
              55.60463
            ],
            [
              35.57047603296487,
              55.59792889343933
            ],
            [
              35.563618,
              55.5941
            ],
            [
              35.562249,
              55.59077
            ],
            [
              35.564321,
              55.589919
            ],
            [
              35.566985,
              55.588666
            ],
            [
              35.573098,
              55.588795
            ],
            [
              35.581569,
              55.580578
            ],
            [
              35.582073,
              55.575363
            ],
            [
              35.582002,
              55.569473
            ],
            [
              35.582965,
              55.565051
            ],
            [
              35.583908,
              55.56103
            ],
            [
              35.57432335164209,
              55.55452099255594
            ],
            [
              35.5715021781982,
              55.5552023343125
            ],
            [
              35.57148697084016,
              55.555206043620466
            ],
            [
              35.565131,
              55.564714
            ],
            [
              35.562269,
              55.567903
            ],
            [
              35.56098632634342,
              55.568257128217326
            ],
            [
              35.56072222062741,
              55.568418610446415
            ],
            [
              35.557038,
              55.571766
            ],
            [
              35.555608,
              55.573808
            ],
            [
              35.553835,
              55.576342
            ],
            [
              35.55366362227629,
              55.57633236775175
            ],
            [
              35.553527,
              55.578639
            ],
            [
              35.552413,
              55.583284
            ],
            [
              35.55178666485651,
              55.58253994467468
            ],
            [
              35.540676,
              55.584351
            ],
            [
              35.537808,
              55.586527
            ],
            [
              35.534728,
              55.585494
            ],
            [
              35.53231,
              55.582741
            ],
            [
              35.529219,
              55.576682
            ],
            [
              35.527413734163815,
              55.56850100552226
            ],
            [
              35.526288,
              55.568162
            ],
            [
              35.522623,
              55.567531
            ],
            [
              35.512733,
              55.568179
            ],
            [
              35.509982,
              55.575988
            ],
            [
              35.50861128798862,
              55.57652965232708
            ],
            [
              35.510069,
              55.581212
            ],
            [
              35.50942469479553,
              55.5808026136765
            ],
            [
              35.501712,
              55.578527
            ],
            [
              35.50092668666946,
              55.57530706004223
            ],
            [
              35.496740413029805,
              55.57180676740651
            ],
            [
              35.49197,
              55.571116
            ],
            [
              35.487576,
              55.571974
            ],
            [
              35.482514,
              55.576471
            ],
            [
              35.47928433916463,
              55.57928622816677
            ],
            [
              35.478045,
              55.580985
            ],
            [
              35.47687113334789,
              55.5813897694777
            ],
            [
              35.476529,
              55.581688
            ],
            [
              35.471369,
              55.583287
            ],
            [
              35.466224,
              55.584882
            ],
            [
              35.461447,
              55.586409
            ],
            [
              35.452607,
              55.592198
            ],
            [
              35.4479477156545,
              55.591962234609774
            ],
            [
              35.442797,
              55.593606
            ],
            [
              35.435204,
              55.594873
            ],
            [
              35.426768,
              55.597852
            ],
            [
              35.424562,
              55.598475
            ],
            [
              35.422356,
              55.599099
            ],
            [
              35.421377,
              55.595634
            ],
            [
              35.425763,
              55.593451
            ],
            [
              35.434611,
              55.591323
            ],
            [
              35.443957,
              55.585918
            ],
            [
              35.448449303155826,
              55.58162552721561
            ],
            [
              35.447047,
              55.579374
            ],
            [
              35.43776,
              55.574852
            ],
            [
              35.428491,
              55.57034
            ],
            [
              35.4281150067185,
              55.570150986637785
            ],
            [
              35.428115,
              55.570151
            ],
            [
              35.420745,
              55.566447
            ],
            [
              35.42018852356537,
              55.56622955750868
            ],
            [
              35.420186,
              55.566236
            ],
            [
              35.4129182794376,
              55.56338879275789
            ],
            [
              35.403959,
              55.560812
            ],
            [
              35.397943,
              55.560904
            ],
            [
              35.390762,
              55.559971
            ],
            [
              35.386172,
              55.567971
            ],
            [
              35.393744,
              55.57349
            ],
            [
              35.397424,
              55.57333
            ],
            [
              35.401522,
              55.574117
            ],
            [
              35.403664,
              55.574279
            ],
            [
              35.405805,
              55.574442
            ],
            [
              35.405533,
              55.578031
            ],
            [
              35.403391,
              55.577869
            ],
            [
              35.40125,
              55.577706
            ],
            [
              35.393398,
              55.577074
            ],
            [
              35.389525,
              55.577066
            ],
            [
              35.386394,
              55.575463
            ],
            [
              35.379404,
              55.5709
            ],
            [
              35.372088,
              55.570892
            ],
            [
              35.37232924839301,
              55.57034343725048
            ],
            [
              35.370487,
              55.570042
            ],
            [
              35.373545,
              55.567579
            ],
            [
              35.375766,
              55.564173
            ],
            [
              35.369771,
              55.555604
            ],
            [
              35.366744,
              55.553612
            ],
            [
              35.352157,
              55.546963
            ],
            [
              35.34381133255156,
              55.55014158814099
            ],
            [
              35.343803829304846,
              55.55014615735704
            ],
            [
              35.338805,
              55.559358
            ],
            [
              35.344617,
              55.565121
            ],
            [
              35.344125,
              55.576564
            ],
            [
              35.345294,
              55.580679
            ],
            [
              35.343454,
              55.583809
            ],
            [
              35.339263,
              55.59247
            ],
            [
              35.334261,
              55.601151
            ],
            [
              35.331251,
              55.605438
            ],
            [
              35.330474937710655,
              55.60573267294488
            ],
            [
              35.329331,
              55.607117
            ],
            [
              35.32315915437268,
              55.60913075773597
            ],
            [
              35.316217,
              55.613396
            ],
            [
              35.311231,
              55.615793
            ],
            [
              35.306562,
              55.618586
            ],
            [
              35.300994,
              55.621714
            ],
            [
              35.298113,
              55.623767
            ],
            [
              35.297971,
              55.623599
            ],
            [
              35.297696,
              55.623158
            ],
            [
              35.297371,
              55.622416
            ],
            [
              35.29125,
              55.612176
            ],
            [
              35.287231,
              55.613644
            ],
            [
              35.28593,
              55.614209
            ],
            [
              35.284007,
              55.611166
            ],
            [
              35.287524,
              55.606542
            ],
            [
              35.289699,
              55.603673
            ],
            [
              35.29325,
              55.604094
            ],
            [
              35.30403570169522,
              55.60521696894157
            ],
            [
              35.304080212280056,
              55.60520608218059
            ],
            [
              35.30894814254082,
              55.604003991190346
            ],
            [
              35.31082566649425,
              55.60288698869226
            ],
            [
              35.310937,
              55.591948
            ],
            [
              35.309217,
              55.587468
            ],
            [
              35.312797,
              55.588866
            ],
            [
              35.31626,
              55.590674
            ],
            [
              35.31677351673988,
              55.59098564166463
            ],
            [
              35.316806,
              55.590929
            ],
            [
              35.318582,
              55.591947
            ],
            [
              35.320564,
              55.593286
            ],
            [
              35.321357,
              55.596961
            ],
            [
              35.330963,
              55.599708
            ],
            [
              35.333486,
              55.59415
            ],
            [
              35.337503,
              55.585384
            ],
            [
              35.339231,
              55.573835
            ],
            [
              35.337909,
              55.570258
            ],
            [
              35.337870044181905,
              55.56988776306929
            ],
            [
              35.337813,
              55.569903
            ],
            [
              35.337349,
              55.568166
            ],
            [
              35.336864586533494,
              55.565456123956025
            ],
            [
              35.3346862978612,
              55.56137839989229
            ],
            [
              35.324866,
              55.558256
            ],
            [
              35.321574,
              55.561476
            ],
            [
              35.318939,
              55.564211
            ],
            [
              35.316182,
              55.566448
            ],
            [
              35.31264,
              55.568339
            ],
            [
              35.307454,
              55.572878
            ],
            [
              35.307283,
              55.573041
            ],
            [
              35.3017980913651,
              55.56855263785807
            ],
            [
              35.29678,
              55.56811
            ],
            [
              35.293608221692445,
              55.568139570509175
            ],
            [
              35.28425,
              55.571675
            ],
            [
              35.280782,
              55.572545
            ],
            [
              35.27789590400185,
              55.57284710355877
            ],
            [
              35.276611,
              55.572982
            ],
            [
              35.27661095871406,
              55.57298160585687
            ],
            [
              35.276483,
              55.572995
            ],
            [
              35.27331,
              55.573281
            ],
            [
              35.27330997040401,
              55.57328067151192
            ],
            [
              35.273273,
              55.573284
            ],
            [
              35.27299214863456,
              55.57016681069619
            ],
            [
              35.259781,
              55.573323
            ],
            [
              35.246637,
              55.573343
            ],
            [
              35.239684,
              55.573705
            ],
            [
              35.235612,
              55.57397
            ],
            [
              35.233111082655405,
              55.57413218181748
            ],
            [
              35.230249,
              55.574318
            ],
            [
              35.223066521724554,
              55.57457488405849
            ],
            [
              35.219886,
              55.574689
            ],
            [
              35.21138,
              55.574944
            ],
            [
              35.206199,
              55.575879
            ],
            [
              35.203644,
              55.576296
            ],
            [
              35.203065,
              55.572743
            ],
            [
              35.205619,
              55.572326
            ],
            [
              35.214956,
              55.568291
            ],
            [
              35.224938607451406,
              55.57090537340866
            ],
            [
              35.225227,
              55.570895
            ],
            [
              35.23012,
              55.57072
            ],
            [
              35.23086204493286,
              55.570671692698916
            ],
            [
              35.230862,
              55.570671
            ],
            [
              35.23130700560476,
              55.570642086035456
            ],
            [
              35.231307,
              55.570642
            ],
            [
              35.235379,
              55.570377
            ],
            [
              35.23945,
              55.570112
            ],
            [
              35.24645,
              55.569747
            ],
            [
              35.259775,
              55.569723
            ],
            [
              35.267175,
              55.554812
            ],
            [
              35.261628,
              55.554451
            ],
            [
              35.259729,
              55.549302
            ],
            [
              35.262177,
              55.545967
            ],
            [
              35.265354,
              55.534902
            ],
            [
              35.269045,
              55.530281
            ],
            [
              35.270588,
              55.527015
            ],
            [
              35.27394991028543,
              55.52693610758856
            ],
            [
              35.275754,
              55.523909
            ],
            [
              35.26892545229828,
              55.51768095108074
            ],
            [
              35.265893,
              55.518306
            ],
            [
              35.262431,
              55.516543
            ],
            [
              35.259042,
              55.512121
            ],
            [
              35.253421,
              55.506017
            ],
            [
              35.249634,
              55.50452
            ],
            [
              35.24759395238354,
              55.50372767660631
            ],
            [
              35.247559,
              55.503752
            ],
            [
              35.24504,
              55.502733
            ],
            [
              35.23328160915324,
              55.50119847101261
            ],
            [
              35.232556,
              55.501224
            ],
            [
              35.223887,
              55.501528
            ],
            [
              35.22388696342941,
              55.50152695570657
            ],
            [
              35.215969,
              55.501804
            ],
            [
              35.21027758710968,
              55.50165640368047
            ],
            [
              35.204475,
              55.51728
            ],
            [
              35.200976,
              55.518208
            ],
            [
              35.195595,
              55.517462
            ],
            [
              35.190936,
              55.516538
            ],
            [
              35.186725,
              55.515773
            ],
            [
              35.18608517515186,
              55.515172917269204
            ],
            [
              35.184833,
              55.515084
            ],
            [
              35.183796,
              55.514982
            ],
            [
              35.183693,
              55.514969
            ],
            [
              35.181511,
              55.514626
            ],
            [
              35.179329,
              55.514282
            ],
            [
              35.177479,
              55.505549
            ],
            [
              35.169095,
              55.503127
            ],
            [
              35.16310864353721,
              55.50461665479709
            ],
            [
              35.158605,
              55.51341
            ],
            [
              35.158642,
              55.515076
            ],
            [
              35.158588,
              55.51626
            ],
            [
              35.154991,
              55.516096
            ],
            [
              35.151626,
              55.512753
            ],
            [
              35.144345,
              55.505401
            ],
            [
              35.140464,
              55.505746
            ],
            [
              35.136839,
              55.506102
            ],
            [
              35.132534,
              55.506477
            ],
            [
              35.125929,
              55.508603
            ],
            [
              35.124664,
              55.509057
            ],
            [
              35.121564,
              55.507447
            ],
            [
              35.121276,
              55.503858
            ]
          ]
        ]
      }
    }
  ]
}
