import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import * as L from 'leaflet';
import {LeafletClickLatLng} from '../distance/distance.component';
import {MapService} from '../services/map.service';
import {ToastrService} from 'ngx-toastr';
import area from '@turf/area';
import {polygon} from '@turf/helpers';
import { saveAs } from 'file-saver';
import {icon} from 'leaflet';

export interface isochroneJsonInterface {
  lat: string
  lon: string
  avoid: any[]
  tollRoads: boolean
  maxRange: number
  interval: number
  smoothing: number
  type: string
  pointType: string
}

export interface errJsonInterface {
  address: boolean,
  max: boolean,
  step: boolean
}

export interface serverResponsePolyInterface {
  type?: string,
  properties?: {
    value?: number,
    group_index?: number,
    center?: any[]
  },
  geometry?: {
    type?: string,
    coordinates?: any[]
  }
}

export interface serverResponseInterface {
  bbox?: any[],
  features?: serverResponsePolyInterface[]
}

@Component({
  selector: 'app-isochrone',
  templateUrl: './isochrone.component.html',
  styleUrls: ['./isochrone.component.css']
})
export class IsochroneComponent implements OnInit, AfterViewInit, OnDestroy {

  private ishochroneMap;
  private markerIsochrone;
  private userLat: number = 55.73259745865431;
  private userLon: number = 37.616146011947784;

  public isLoading: boolean = false;
  public requestLoading: boolean = false;
  public address: string = '';
  public isochroneJson: isochroneJsonInterface = {
    lat: '',
    lon: '',
    avoid: [],
    tollRoads: false,
    maxRange: 10,
    interval: 3,
    smoothing: 1,
    type: 'distance',
    pointType: 'start'
  };

  public errJson: errJsonInterface = {
    address: false,
    max: false,
    step: false
  };

  public calculated: serverResponseInterface = {};
  public lPolygons = [];
  public table = [];
  public lcontrol;

  public avoidMkad: boolean = false;
  public avoidTTK: boolean = false;
  public avoidSK: boolean = false;
  public smoothing: boolean = false;

  public googleOptions = {
    types: [],
    componentRestrictions: {country: 'RU'}
  };

  constructor(
    private toastr: ToastrService,
    private readonly mapService: MapService
  ) {
  }

  ngOnInit(): void {
    L.Icon.Default.imagePath = "/leaflet/"
    // Получить координаты пользователя
    this.getUserLocation();
  }

  ngAfterViewInit(): void {
    // Инициализация карты
    this.initMap();
  }

  ngOnDestroy(): void {
  }

  // ✅ Получить геоданные пользователя
  public getUserLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.userLat = position.coords.latitude;
        this.userLon = position.coords.longitude;
        // Переопределить карту
        this.ishochroneMap.panTo(new L.LatLng(this.userLat, this.userLon), 20);
      });
    }
  }

  // ✅ Первичаная инициализация карты
  private initMap(): void {
    // Дефолтные настройки карты
    this.setMap();
    // Тайлы
    this.setTiles();
    // Прослушиватель кликов
    this.setClickListener();
  }

  // ✅ Установка click listener
  public setClickListener(): void {
    this.ishochroneMap.on('click', (e) => {
      let coords: LeafletClickLatLng = e.latlng;
      this.isochroneJson.lat = String(coords.lat);
      this.isochroneJson.lon = String(coords.lng);
      this.setMarker(coords, true);
    });
  }

  // ✅ Установить маркер
  public async setMarker(coords: LeafletClickLatLng, needGeocode: boolean): Promise<boolean> {
    this.isLoading = true;
    if (this.markerIsochrone) {
      this.ishochroneMap.removeLayer(this.markerIsochrone);
      this.isLoading = false;
    }

    // Удаляем старый полигон
    this.removeLayers();

    // Устанавливаем маркер
    this.markerIsochrone = new L.Marker([coords.lat, coords.lng], {draggable: true}).addTo(this.ishochroneMap);

    // Устанавливаем слушитель
    this.markerIsochrone.on('dragend', async (e) => {
      this.isLoading = true;
      // Проверка что точка не в полигоне
      let latLng: LeafletClickLatLng = e.target.getLatLng();
      this.isochroneJson.lat = String(latLng.lat);
      this.isochroneJson.lon = String(latLng.lng);
      await this.setMarker(latLng, true);
    });

    this.isLoading = false;
    if (needGeocode) {
      await this.reverseGeocode();
    }
    return true;
  }

  // ✅ Установка тайлов
  public setTiles(): void {
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="https://tk-akro.ru">AKRO Routing</a>'
    });
    tiles.addTo(this.ishochroneMap);
  }

  // ✅ Установка карты
  public setMap(): void {
    this.ishochroneMap = new L.Map('ishochroneMap', {
      center: [this.userLat, this.userLon,],
      zoom: 10
    });
  }

  public removeAll(): void {
    this.isLoading = true;
    this.address = '';
    this.isochroneJson.lat = '';
    this.isochroneJson.lon = '';
    this.ishochroneMap.removeLayer(this.markerIsochrone);
    this.removeLayers();
    this.isLoading = false;
  }

  // ✅ Реверсный геокодинг
  public async reverseGeocode(): Promise<any> {
    let reverseGeoCode = await this.mapService.reverseGeoCode(this.isochroneJson.lat, this.isochroneJson.lon);
    if (!reverseGeoCode) {
      this.toastr.clear();
      this.toastr.error('Невозможно получить информацию о координатах', 'Ошибка', {
        timeOut: 3000,
      });
      this.isLoading = false;
      this.address = '';
      return false;
    }
    this.address = reverseGeoCode.formattedAddress.replace('Unnamed Road, ', '').replace('Null ', '');
  }

  // ✅ Узнать координаты адреса
  public async getLatLonForPlaceRequest(place_id: any): Promise<boolean | any> {
    this.isLoading = true;
    let req = await this.mapService.getLatLonForPlace(place_id);
    if (req) {
      let coords: LeafletClickLatLng = {
        lat: req.lat,
        lng: req.lon
      };
      // Обновляем координаты
      this.isochroneJson.lat = String(coords.lat);
      this.isochroneJson.lon = String(coords.lng);
      let setMarker = await this.setMarker(coords, false);
      if (setMarker) {
        this.ishochroneMap.setView(new L.LatLng(Number(this.isochroneJson.lat), Number(this.isochroneJson.lon)), 10);
      }
    }
    this.isLoading = false;
  }

  // ✅ Функция получения данных от гугла
  public async handleAddressChange(address: Address) {
    this.isLoading = true;
    await this.getLatLonForPlaceRequest(address.place_id);
    this.isLoading = false;
  }

  // Смена модели
  public async changeModel() {

    // Сглаживание
    if (this.smoothing) {
      this.isochroneJson.smoothing = 75;
    } else {
      this.isochroneJson.smoothing = 1;
    }

    // Обьезды
    this.isochroneJson.avoid = [];
    if (this.avoidMkad) {
      this.isochroneJson.avoid.push('mkad');
    }
    if (this.avoidTTK) {
      this.isochroneJson.avoid.push('ttk');
    }
    if (this.avoidSK) {
      this.isochroneJson.avoid.push('sk');
    }

  }

  // Проверка заполнености
  public checkBeforeRequest(): boolean {
    // Проверка адреса
    let totalErr = false;
    // Установка стандартного значения ошибок
    let errJson = {
      address: false,
      max: false,
      step: false
    };
    // Проверка что адрес внесен
    if (!this.isochroneJson.lat && !this.isochroneJson.lon) {
      this.errJson.address = true;
      totalErr = true;
    } else {
      this.errJson.address = false;
    }
    // Проверка максимальной ренджи
    if (this.isochroneJson.maxRange < 0 || this.isochroneJson.maxRange > 30) {
      this.errJson.max = true;
      totalErr = true;
    } else {
      this.errJson.max = false;
    }
    // Проверка шага
    if (this.isochroneJson.interval <= 0 || this.isochroneJson.interval > 30 || this.isochroneJson.interval < 3 || this.isochroneJson.interval >= this.isochroneJson.maxRange) {
      this.errJson.step = true;
      totalErr = true;
    } else {
      this.errJson.step = false;
    }

    return totalErr;
  }

  // Отправить запрос
  public async request() {
    this.requestLoading = true;

    // Удаляем старый полигон
    this.removeLayers();

    // Проверка
    let checkBeforeRequest = this.checkBeforeRequest();
    if (checkBeforeRequest) {
      this.requestLoading = false;
      return false;
    }

    let getIsochrone = await this.mapService.getIsochrone(this.isochroneJson);
    if (getIsochrone.statusCode) {
      this.requestLoading = false;
      if (getIsochrone.statusCode == 400 && getIsochrone.message == 'point in MKAD polygon') {
        this.toastr.clear();
        this.toastr.error('Точка находится в полигоне МКАД', 'Ошибка', {
          timeOut: 3000,
        });
        return false
      }
      if (getIsochrone.statusCode == 400 && getIsochrone.message == 'point in TTK polygon') {
        this.toastr.clear();
        this.toastr.error('Точка находится в полигоне ТТК', 'Ошибка', {
          timeOut: 3000,
        });
        return false
      }
      if (getIsochrone.statusCode == 400 && getIsochrone.message == 'point in SK polygon') {
        this.toastr.clear();
        this.toastr.error('Точка находится в полигоне СК', 'Ошибка', {
          timeOut: 3000,
        });
        return false
      }
      this.toastr.clear();
      this.toastr.error('Невозможно посчитать доступность', 'Ошибка', {
        timeOut: 3000,
      });
    }
    this.calculated = getIsochrone;
    this.setPolygons(this.calculated.features);
    this.requestLoading = false;
  }

  // Удалить полигоны
  public removeLayers() {
    this.table = [];
    this.calculated = {};
    for (let key of this.lPolygons) {
      this.ishochroneMap.removeLayer(key);
    }
  }

  // Установить полигоны
  private setPolygons(array: serverResponsePolyInterface[]): boolean {

    // Удаляем старый полигон
    this.removeLayers();

    // Делаем реверс для bindPopup
    array.reverse();
    // Заводим массив полигонов
    for (let key of array) {
      let arr = [];
      let value = key.geometry.coordinates[0];
      for (let geo of value) {
        arr.push([geo[1], geo[0]]);
      }
      let popup;
      let unit = '';
      let val;
      if (this.isochroneJson.type == 'distance') {
        val = key.properties.value / 1000;
        unit = 'км';
        popup = 'Доступность: ' + val + ' ' + unit;
      }
      if (this.isochroneJson.type == 'time') {
        val = key.properties.value / 60;
        unit = 'мин';
        popup = 'Доступность: ' + val + ' ' + unit;
      }
      let pol = polygon(key.geometry.coordinates);
      let ar = area(pol) / 1000000;
      let color = '#' + Math.floor(Math.random() * 16777215).toString(16);
      let data = new L.Polygon(arr, {color: color, fillColor: color, fillOpacity: 0.1}).bindPopup(popup).addTo(this.ishochroneMap);
      this.table.push({
        area: ar.toFixed(2),
        unit: unit,
        value: val,
        color: color,
        polygon: pol,
        popup: popup
      });
      this.lPolygons.push(data);
    }
    return true;
  }

  // Скачать данные
  private downloadGeoJson(index: number) {
    let data = this.table[index];
    let blob = new Blob([JSON.stringify(data.polygon)], {type: 'application/json'});
    saveAs(blob, "polygon.geojson");
  }

}
