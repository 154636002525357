import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-matching',
  templateUrl: './matching.component.html',
  styleUrls: ['./matching.component.css']
})
export class MatchingComponent implements OnInit, AfterViewInit, OnDestroy {

  private matchingMap;
  private markerMatching;
  private userLat: number = 55.73259745865431;
  private userLon: number = 37.616146011947784;

  constructor() { }

  ngOnInit(): void {
    L.Icon.Default.imagePath = "/leaflet/"
    // Получить координаты пользователя
    this.getUserLocation();
  }

  ngAfterViewInit(): void {
    // Инициализация карты
    this.initMap();
  }

  ngOnDestroy(): void {
  }

  // ✅ Получить геоданные пользователя
  public getUserLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.userLat = position.coords.latitude;
        this.userLon = position.coords.longitude;
        // Переопределить карту
        this.matchingMap.panTo(new L.LatLng(this.userLat, this.userLon), 20);
      });
    }
  }

  // ✅ Первичаная инициализация карты
  private initMap(): void {
    // Дефолтные настройки карты
    this.setMap();
    // Тайлы
    this.setTiles();
  }

  // ✅ Установка карты
  public setMap(): void {
    this.matchingMap = new L.Map('matchingMap', {
      center: [this.userLat, this.userLon,],
      zoom: 10
    });
  }

  // ✅ Установка тайлов
  public setTiles(): void {
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="https://tk-akro.ru">AKRO Routing</a>'
    });
    tiles.addTo(this.matchingMap);
  }

}
