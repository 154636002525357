import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as L from 'leaflet';
import {LeafletClickLatLng, MapResponseDistance} from '../distance/distance.component';
import {isochroneJsonInterface} from '../isochrone/isochrone.component';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  private readonly headers = new HttpHeaders().set('token', 'asdfasdf2534-gfgdsg4v4256-dsgdf4g43tgdf-dfgs')
  public russiaPolygon = undefined

  constructor(
    private httpClient: HttpClient,
  ) { }

  // ✅ Функция проверки точки в полигоне
  public async inside(point: any, vs: any): Promise<boolean> {
    let x = point[0]
    let y = point[1]

    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      let xi = vs[i][0], yi = vs[i][1];
      let xj = vs[j][0], yj = vs[j][1];

      let intersect = ((yi > y) != (yj > y))
        && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) {
        inside = !inside
      }
    }

    return inside;
  }

  // ✅ Функция обратного геокодинга
  public async reverseGeoCode(lat: string, lon: string): Promise<boolean | any> {
    let request = await this.httpClient.post('/api/reverse-geocode/get', {lat: lat, lon: lon}, {headers: this.headers}).toPromise()
      .then((res: any) => {
        if (res?.data) {
          return res?.data
        }
        return false
      }).catch((err) => {
        return false
      })
    return request
  }

  // ✅ Узнать координаты адреса
  public async getLatLonForPlace(place_id: any): Promise<boolean | any> {
    let req = await this.httpClient.post('/api/place-geocode/get', {placeId: place_id}, {headers: this.headers}).toPromise()
      .then((res: any) => {
        if (res?.data) {
          return res?.data
        }
        return false
      }).catch((err) => {
        return false
      })
      return req
  }

  // ✅ Функция получения данных расстояния за МКАД
  public async getDistance(lat: string, lon: string, from: string, tollRoards: boolean, route: boolean): Promise<any> {
    try {
      let json = {
        "lat": lat,
        "lon": lon,
        "from": from,
        "tollRoads": tollRoards,
        "route": route
      }
      let req = await this.httpClient.post(`/api/distance/calc`, json, {headers: this.headers}).toPromise()
        .then((data) => {
          let answer: MapResponseDistance = data["data"]
          if (!answer.success) {
            return false
          }
          return answer
        })
        .catch((err) => {
          return false
        })
      return req
    } catch(err) {
      return false
    }
  }

  // ✅ Функция получения информации о полигоне
  public async getPolygonData(lat: string, lon: string): Promise<null | any> {
    let request = await this.httpClient.post('/api/polygon/calc', {lat: lat, lon: lon}, {headers: this.headers}).toPromise()
      .then((res: any) => {
        if (res?.data) {
          return res?.data
        }
        return null
      }).catch((err) => {
        return null
      })
    return request
  }

  // Функция вычисления изохрона
  public async getIsochrone(data: isochroneJsonInterface): Promise<null | any> {
    try {
      let request = await this.httpClient.post('/api/isochrone/calc', data, {headers: this.headers}).toPromise()
        .then((answer) => {
          if (answer["data"]) {
            return answer["data"]
          }
          return null
        })
        .catch((err) => {
          return err.error
        })
      return request
    } catch(err) {
      return null
    }
  }

  // Загружен ли полигон россии
  public isRussianPolygonLoaded(): boolean {
    if (!this.russiaPolygon) {
      return false
    }
    return true
  }

  // Функция получения полигона россии
  public async getRussianPolygon(): Promise<boolean> {
    let request = await this.httpClient.post('/api/get-polygon-geojson/get', {name: 'russia'}, {headers: this.headers}).toPromise()
      .then((res: any) => {
        if (res?.data) {
          this.russiaPolygon = res?.data
          return true
        }
        return false
      }).catch((err) => {
        return false
      })
    return request
  }

  // Получить ближайшую точку
  public async getNearest(tollRoad: boolean, source: boolean, destination: boolean, route: boolean, lat: string, lon: string, polygon: string): Promise<any> {
    try {
        if (tollRoad == undefined) {
          return {
            error: true,
            data: 'Не передан обязательный параметр tollRoad'
          }
        }
        if (source == undefined) {
          return {
            error: true,
            data: 'Не передан обязательный параметр source'
          }
        }
        if (destination == undefined) {
          return {
            error: true,
            data: 'Не передан обязательный параметр destination'
          }
        }
        if (!source && !destination) {
          return {
            error: true,
            data: 'Не указано направление точки'
          }
        }
        if (route == undefined) {
          return {
            error: true,
            data: 'Не передан обязательный параметр route'
          }
        }
        if (!lat && !lon) {
          return {
            error: true,
            data: 'Не передан обязательный параметр - координаты'
          }
        }
        if (!polygon) {
          return {
            error: true,
            data: 'Не передан обязательный параметр polygon'
          }
        }
        let data = {
          "lat": lat,
          "lon": lon,
          "tollRoads": tollRoad,
          "route": route,
          "source": source,
          "destination": destination,
          "type": polygon
        }
      let request = await this.httpClient.post('/api/nearest/calc', data, {headers: this.headers}).toPromise()
        .then((answer) => {
          if (answer["data"]) {
            return answer["data"]
          }
          return null
        })
        .catch((err) => {
          console.log(err)
          return {
            error: true,
            data: 'Не удалось найти ближайшую точку'
          }
        })
      return request
    } catch(err) {
      return false
    }
  }

}
