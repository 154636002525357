import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {MapService} from '../services/map.service';

@Injectable({
  providedIn: 'root'
})
export class PolygonGuard implements CanActivate {

  constructor(
    private readonly mapsService: MapService
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      let isRussianPolygonLoaded = this.mapsService.isRussianPolygonLoaded()
      if (isRussianPolygonLoaded) {
        return true
      }
      let getRussianPolygon = await this.mapsService.getRussianPolygon()
      if (!getRussianPolygon) {
        return false
      }
      return true
  }

}
