import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import * as L from "leaflet";
import { ToastrService } from 'ngx-toastr';
import { MkadAreaConstAvoid, MkadAreaConst } from "../constants/mkad.area.constant"
import { ClipboardService } from 'ngx-clipboard'
import {MapService} from "../services/map.service";
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {Mo150AreaAvoid} from '../constants/mo150.area.constant';
import {PolyResponse} from '../polygon/polygon.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { lineString } from '@turf/helpers';
import { point } from '@turf/helpers';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import lineChunk from '@turf/line-chunk';
import {ActivatedRoute} from '@angular/router';


export interface LeafletClickLatLng {
  lat: number,
  lng: number
}

export interface MapResponseDistance {
  lat?: string,
  lon?: string,
  success?: boolean,
  distanceKilometersFromCenter?: number,
  distanceMetersFromCenter?: number,
  distanceKilometersToCenter?: number,
  distanceMetersToCenter?: number,
  distanceKilometersFromPolygon?: number,
  distanceMetersFromPolygon?: number,
  distanceKilometersToPolygon?: number,
  distanceMetersToPolygon?: number,
  startLat?: string,
  startLon?: string,
  endLat?: string,
  endLon?: string,
  distanceKilometers?: number,
  distanceMeters?: number,
  route?: any[]
}

export interface ServerResponseAutocomplete {
  name: string
  place_id: string
  query?: string
}

@Component({
  selector: 'app-distance',
  templateUrl: './distance.component.html',
  styleUrls: ['./distance.component.css']
})
export class DistanceComponent implements OnInit, AfterViewInit, OnDestroy {


  @ViewChild("placesRef") placesRef : GooglePlaceDirective;

  modalRefCoords: BsModalRef | null;
  modalCoodrsText: string = ""
  modalErr: boolean = false
  modalSuccess: boolean = false

  public distanceMap;
  private marker;
  private routeFrom;
  private routeWithoutPayFrom;
  private routeTo;
  private routeWithoutPayTo;
  private userLat: number = 55.73259745865431;
  private userLon: number = 37.616146011947784;

  public mo150
  public lcontrol

  public rerunValue: string = ""
  public address: string = ""
  public lat: string = ""
  public lon: string = ""
  public isLoading: boolean = false
  public calculate: MapResponseDistance = {}
  public tollRoads: boolean = false
  public distance: number = 10
  public maxDistance: number = 150
  public polyFrom: any[] = []
  public polyTo: any[] = []
  public googleOptions = {
    types: [],
    componentRestrictions: { country: 'RU' }
  }

  public calculated: PolyResponse | null = {
    lat: '',
    lon: '',
    inSK: null,
    inTTK: null,
    inMKAD: null,
    inMO150: null,
    inRussia: null
  };


  constructor(
    private toastr: ToastrService,
    private mapService: MapService,
    private modalService: BsModalService,
    private clipboard: ClipboardService,
    private router: ActivatedRoute
  ) { }

  ngOnInit(): void {
    L.Icon.Default.imagePath = "/leaflet/"
    // Получить координаты пользователя
    this.getUserLocation();
    // Получаем lat и lon для запроса из 1С
    this.router.queryParams.subscribe(params => {
      let lat = params['lat'];
      let lon = params['lon'];
      let distance = params['distance'];
      let address = params['address'];
      let err = false
      // Проверка передаваемых координат
      if (lat && lon) {
        if (lat.split('.')[0].replace(' ', '').length <= 2 && lon.split('.')[0].replace(' ', '').length <= 2) {
          if (lat.split('.')[1].replace(' ', '').length > 0 && lon.split('.')[1].replace(' ', '').length >0 ) {
            let latOne = lat.split('.')[0].replace(' ', '')
            let latSecond = lat.split('.')[1].replace(' ', '')
            let lonOne = lon.split('.')[0].replace(' ', '')
            let lonSecond = lon.split('.')[1].replace(' ', '')
            if (Number.isInteger(Number(latOne)) && Number.isInteger(Number(latSecond)) && Number.isInteger(Number(lonOne)) && Number.isInteger(Number(lonSecond))) {

            } else {
              err = true
            }
          } else {
            err = true
          }
        } else {
          err = true
        }
      } else {
        err = true
      }
      // Проверка расстояния
      if (Number.isInteger(Number(distance)) == false) {
        err = true
      }
      // Проверка адреса
      if (address?.length < 3 && !address) {
        err = true
      }
      if (!err) {
        // Загружаем координаты
        this.lat = lat
        this.lon = lon
        this.address = address
        this.distance = distance
        this.isLoading = true
      }
    });
  }

  async ngAfterViewInit() {
    // Инициализация карты
    this.initMap();
    if (this.lat && this.lon && this.distance && this.address) {
      let coords: LeafletClickLatLng = {
        lat: Number(this.lat),
        lng: Number(this.lon)
      }
      let setMarker = await this.setMarker(coords, false)
      if (setMarker) {
        this.distanceMap.setView(new L.LatLng(Number(this.lat), Number(this.lon)),12);
      }
    }
  }

  ngOnDestroy(): void {
    this.distanceMap.remove();
    this.distanceMap = undefined
    this.marker = undefined
    this.isLoading = false
    this.routeFrom = undefined
    this.routeWithoutPayFrom = undefined
    this.routeTo = undefined
    this.routeWithoutPayTo = undefined
    this.mo150 = undefined
    this.lcontrol = undefined
    this.userLat = 55.73259745865431;
    this.userLon = 37.616146011947784;
    this.lat= ""
    this.lon = ""
    this.calculate = {}
    this.tollRoads = false
    this.distance = 10
    this.calculated = {
      lat: '',
      lon: '',
      inSK: null,
      inTTK: null,
      inMKAD: null,
      inMO150: null,
      inRussia: null
    };
  }

  // ✅ Первичаная инициализация карты
  private initMap(): void {
    // Дефолтные настройки карты
    this.setMap()
    // Установка стандартных карт
    this.setTiles()
    // Прослушиватель кликов
    this.setClickListener()
    // Установка полигона МКАД
    this.setMkadPoly()
    // Добавление полигона МО 150
    this.setMo150()
  }

  // ✅ Получить геоданные пользователя
  public getUserLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.userLat = position.coords.latitude;
        this.userLon = position.coords.longitude;
        // Переопределить карту
        this.distanceMap.panTo(new L.LatLng(this.userLat, this.userLon), 20);
      })
    }
  }

  // ✅ Установка карты
  public setMap(): void {
    this.distanceMap = new L.Map('distanceMap', {
      center: [ this.userLat, this.userLon, ],
      zoom: 10
    });
  }

  // ✅ Установка тайлов
  public setTiles(): void {
    const tiles = new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="https://tk-akro.ru">AKRO Routing</a>'
    });
    tiles.addTo(this.distanceMap);
  }

  // Установка полигона МКАД + 150 км
  public setMo150(): void {
    // МО 150
    let polyMo150 = [];
    for (let key of Mo150AreaAvoid) {
      polyMo150.push([key[1], key[0]]);
    }
    this.mo150 = new L.Polygon(polyMo150);

    let overlayMaps = {
      'Зона обслуживания МСК': this.mo150
    };
    this.lcontrol = L.control.layers({}, overlayMaps).addTo(this.distanceMap);
  }

  // ✅ Установка полигона МКАД
  public setMkadPoly(): void {
    let poly = []
    for (let key of MkadAreaConstAvoid) {
      poly.push([key[1], key[0]])
    }
    new L.Polygon(poly).bindPopup("Полигон внутри МКАД").addTo(this.distanceMap);
  }

  // При смене калькуляции дистанции
  public changeDistance(): void {
    if (this.distance == 0) {
      this.distance = 1
    }
    if (this.polyFrom.length > 0 && this.polyTo.length > 0 && Number.isInteger(Number(this.distance)) && this.distance) {
      if (this.routeFrom) {
        this.distanceMap.removeLayer(this.routeFrom)
      }
      if (this.routeWithoutPayFrom) {
        this.distanceMap.removeLayer(this.routeWithoutPayFrom)
      }
      if (this.routeTo) {
        this.distanceMap.removeLayer(this.routeTo)
      }
      if (this.routeWithoutPayTo) {
        this.distanceMap.removeLayer(this.routeWithoutPayTo)
      }

      // Ближайшая к полигону точка
      let line = lineString(MkadAreaConstAvoid);
      let pt = point([this.polyFrom[0][0], this.polyFrom[0][1],]);
      let snapped = nearestPointOnLine(line, pt, {units: 'kilometers'});
      let snappedLat = snapped.geometry.coordinates[1]
      let snappedLon = snapped.geometry.coordinates[0]

      // Стандартный маршрут
      this.routeFrom = undefined
      this.routeWithoutPayFrom = undefined
      this.routeTo = undefined
      this.routeWithoutPayTo = undefined

      this.setRouteWithOutPayFrom(this.polyFrom)
      this.setRouteWithPayFrom(this.polyFrom)
      this.setRouteWithOutPayTo(this.polyTo)
      this.setRouteWithPayTo(this.polyTo)
    }
  }

  // Установить дефолтный пробег - от МКАД
  public setRouteWithPayFrom(poly: any[]) {
    // Без оплаты
    let polywithPay = []
    for (let key of poly) {
      polywithPay.push([key[1], key[0]])
    }

    // Маршрут без оплаты
    let chunk = lineChunk(lineString(polywithPay), Number(this.distance), {units: 'kilometers'});
    let lineWitPay = []
    for (let [index, key] of chunk.features.entries()) {
      if (index !== 0) {
        for (let value of key.geometry.coordinates) {
          lineWitPay.push([value[1], value[0]])
        }
      }
    }
    this.routeFrom = new L.Polyline(lineWitPay, {color: 'red',}).bindPopup(`<b>Перепробег</b>`).addTo(this.distanceMap);

  }

  // ✅ Установить перепробег - от МКАД
  public setRouteWithOutPayFrom(poly: any[]) {

    // Для оплаты
    let polywithOutPay = []
    for (let key of poly) {
      polywithOutPay.push([key[1], key[0]])
    }

    // Маршрут без оплаты
    let chunk = lineChunk(lineString(polywithOutPay), Number(this.distance), {units: 'kilometers'});
    let lineWithoutPay = []
    for (let key of chunk.features[0].geometry.coordinates) {
      lineWithoutPay.push([key[1], key[0]])
    }

    // Маршрут без оплаты
    this.routeWithoutPayFrom = new L.Polyline(lineWithoutPay).bindPopup(`<b>Входит в ${this.distance} км зону</b>`).addTo(this.distanceMap);
  }

  // Установить дефолтный пробег - до МКАД
  public setRouteWithPayTo(poly: any[]) {
    // Без оплаты
    let polywithPay = []
    for (let key of poly) {
      polywithPay.push([key[1], key[0]])
    }

    // Маршрут без оплаты
    let chunk = lineChunk(lineString(polywithPay), Number(this.distance), {units: 'kilometers'});
    let lineWitPay = []
    for (let [index, key] of chunk.features.entries()) {
      if (index !== 0) {
        for (let value of key.geometry.coordinates) {
          lineWitPay.push([value[1], value[0]])
        }
      }
    }
    this.routeTo = new L.Polyline(lineWitPay, {color: 'red',}).bindPopup(`<b>Перепробег</b>`).addTo(this.distanceMap);

  }

  // ✅ Установить перепробег - до МКАД
  public setRouteWithOutPayTo(poly: any[]) {

    // Для оплаты
    let polywithOutPay = []
    for (let key of poly) {
      polywithOutPay.push([key[1], key[0]])
    }

    // Маршрут без оплаты
    let chunk = lineChunk(lineString(polywithOutPay), Number(this.distance), {units: 'kilometers'});
    let lineWithoutPay = []
    for (let key of chunk.features[0].geometry.coordinates) {
      lineWithoutPay.push([key[1], key[0]])
    }

    // Маршрут без оплаты
    this.routeWithoutPayTo = new L.Polyline(lineWithoutPay).bindPopup(`<b>Входит в ${this.distance} км зону</b>`).addTo(this.distanceMap);
  }

  // ✅ Установка маршрута - от МКАД
  public setRoutePolyFrom(array: any[]): void {

    if (this.routeFrom) {
      this.distanceMap.removeLayer(this.routeFrom)
    }
    if (this.routeWithoutPayFrom) {
      this.distanceMap.removeLayer(this.routeWithoutPayFrom)
    }

    // Ближайшая к полигону точка
    let line = lineString(MkadAreaConstAvoid);
    let pt = point([array[0][0], array[0][1],]);
    let snapped = nearestPointOnLine(line, pt, {units: 'kilometers'});
    let snappedLat = snapped.geometry.coordinates[1]
    let snappedLon = snapped.geometry.coordinates[0]

    // Стандартный маршрут
    let poly = []
    poly.push([snappedLat, snappedLon])
    for (let key of array) {
      poly.push([key[1], key[0]])
    }
    this.polyFrom = poly
    this.setRouteWithOutPayFrom(poly)
    this.setRouteWithPayFrom(poly)

  }

  // ✅ Установка маршрута - до МКАД
  public setRoutePolyTo(array: any[]): void {

    if (this.routeTo) {
      this.distanceMap.removeLayer(this.routeTo)
    }
    if (this.routeWithoutPayTo) {
      this.distanceMap.removeLayer(this.routeWithoutPayTo)
    }

    array = array.reverse()
    // Ближайшая к полигону точка
    let line = lineString(MkadAreaConstAvoid);
    let pt = point([array[1][0], array[1][1],]);
    let snapped = nearestPointOnLine(line, pt, {units: 'kilometers'});
    let snappedLat = snapped.geometry.coordinates[1]
    let snappedLon = snapped.geometry.coordinates[0]

    // Стандартный маршрут
    let poly = []
    poly.push([snappedLat, snappedLon])
    for (let key of array) {
      poly.push([key[1], key[0]])
    }
    this.polyTo = poly
    this.setRouteWithOutPayTo(poly)
    this.setRouteWithPayTo(poly)

  }

  // ✅ Установка click listener
  public setClickListener(): void {
    this.distanceMap.on('click', (e) => {
      let coords: LeafletClickLatLng = e.latlng
      this.setMarker(coords, true)
    });
  }

  // ✅ Получить данные от сервера
  public async getDataPoly(): Promise<any> {
    try {
      let req: PolyResponse | null = await this.mapService.getPolygonData(this.lat, this.lon);
      if (!req) {
        return false;
      }
      this.calculated = req;
      return true;
    } catch (err) {
      return false;
    }
  }

  // ✅ Функция ребилда маршрута
  private async rebuildRoute(lat: string, lon: string): Promise<boolean> {
    this.isLoading = true
    // Обновляем координаты
    this.lat = lat
    this.lon = lon

    // Узнаем полигон
    let getDataPoly = await this.getDataPoly();
    if (!getDataPoly) {
      return false;
    }

    // Делаем запрос
    let getDistance = await this.getDistanceRequest()
    if (!getDistance) {
      this.isLoading = false
      return false
    }


    // Прокладываем маршрут на карте
    let setRoutePolyFrom = this.setRoutePolyFrom(this.calculate.route[0])
    let setRoutePolyTo = this.setRoutePolyTo(this.calculate.route[1])

    this.isLoading = false
    return true
  }

  // ✅ Установить маркер
  public async setMarker(coords: LeafletClickLatLng, needGeo: boolean): Promise<boolean> {
    this.isLoading = true
    if (this.marker) {
      this.distanceMap.removeLayer(this.marker)
      this.isLoading = false
    }

    // Проверить что точка находится не в пределах мкад
    let inMkad = await this.mapService.inside([coords.lng, coords.lat], MkadAreaConst.features[0].geometry.coordinates[0])
    if (inMkad) {
      this.toastr.clear()
      this.toastr.error('Нельзя переместить точку, так как она внутри полигона', 'Невозможно', {
        timeOut: 3000,
      });
      let latLngOld: LeafletClickLatLng = {lat: Number(this.lat), lng: Number(this.lon)}
      await this.setMarker(latLngOld, true)
      this.isLoading = false
      return false
    }

    // Устанавливаем маркер
    this.marker = new L.Marker([coords.lat, coords.lng], {draggable:true}).addTo(this.distanceMap);


    // Устанавливаем слушитель
    this.marker.on('dragend', async (e) => {
      this.isLoading = true
      // Проверка что точка не в полигоне
      let latLng: LeafletClickLatLng = e.target.getLatLng()
      let point = [latLng.lng, latLng.lat]
      let inMkad = await this.mapService.inside(point, MkadAreaConstAvoid)
      if (inMkad) {
        this.toastr.clear()
        this.toastr.error('Нельзя переместить точку, так как она внутри полигона', 'Невозможно', {
          timeOut: 3000,
        });
        let latLngOld: LeafletClickLatLng = {lat: Number(this.lat), lng: Number(this.lon)}
        await this.setMarker(latLngOld, true)
        this.isLoading = false
        return false
      }
      await this.setMarker(latLng, true)
    });

    await this.rebuildRoute(String(coords.lat), String(coords.lng))
    if (needGeo) {
      await this.reverseGeocode()
    }
    return true
  }

  // ✅ Функция получения данных расстояния за МКАД
  private async getDistanceRequest(): Promise<any> {
    try {
      let getDistance = await this.mapService.getDistance(this.lat, this.lon, "mkad", this.tollRoads, true)
      if (!getDistance) {
        this.setRoutePolyFrom([])
        this.setRoutePolyTo([])
        this.toastr.clear()
        this.toastr.error('Во время запроса произошла ошибка', 'Ошибка сервиса');
        return false
      }
      this.calculate = getDistance
      return true
    } catch(err) {
      this.toastr.clear()
      this.toastr.error('Во время запроса произошла ошибка', 'Ошибка сервиса');
      this.setRoutePolyFrom([])
      this.setRoutePolyTo([])
      return false
    }
  }

  // ✅ Функция запуска получения дистанций
  public async onChange(): Promise<any> {
      if (!this.lat && !this.lon) {
        // Если пользователь не указал координаты
        return false
      }
      await this.rebuildRoute(this.lat, this.lon)
  }

  //  ✅ Получить перепробег
  public getRerunValue(): string {
    if (!this.calculate?.distanceKilometers) {
      // Если расчет еще не был произведен
      this.rerunValue = "0"
      return "0"
    }
    if (this.distance <= 0) {
      // Если дистанция меньше 0
      this.rerunValue = "0"
      return "0"
    }
    let value = this.calculate?.distanceKilometers - (this.distance * 2)
    if (value <= 0) {
      this.rerunValue = "0"
      return "0"
    }
    this.rerunValue = String(value)
    return String(value)

  }

  // Получит значение межгород это или нет
  public getMg(): boolean {
    if (this.calculated.inMO150 == true) {
      return false
    } else if (this.calculated.inMO150 == false) {
      return true
    }
  }

  // ✅ Скопировать текст
  public copyData(): boolean {
    if (!this.calculate?.success) {
      this.toastr.clear()
      this.toastr.error('Нет информации для копирования в буфер обмена', 'Не скопировано');
      return false
    }
    this.clipboard.copy(`Координата: ${this.lat}, ${this.lon}. Начало маршрута (ближайшая точка на полигоне): ${this.calculate.startLat}, ${this.calculate.startLon}. Расстояние от начала: ${this.calculate.distanceKilometers} км. Расстояние свыше которого считается перепробег - ${this.distance} км. Перепробег: ${this.getRerunValue()} км`)
    this.toastr.clear()
    this.toastr.success('Данные скопированы в буфер обмена', 'Успешно');
  }

  // ✅ Узнать координаты адреса
  public async getLatLonForPlaceRequest(place_id: any): Promise<boolean | any> {
    this.isLoading = true
    let req = await this.mapService.getLatLonForPlace(place_id)
    if (req) {
        let coords: LeafletClickLatLng = {
          lat: req.lat,
          lng: req.lon
        }
        let setMarker = await this.setMarker(coords, false)
        if (setMarker) {
          this.distanceMap.setView(new L.LatLng(Number(this.lat), Number(this.lon)),12);
        }
      }
      this.isLoading = false
  }

  //  ✅ Функция получения данных от гугла
  public async handleAddressChange(address: Address) {
    this.isLoading = true
    await this.getLatLonForPlaceRequest(address.place_id)
    this.isLoading = false
  }

  // Реверсный геокодинг
  public async reverseGeocode(): Promise<any> {
    let reverseGeoCode = await this.mapService.reverseGeoCode(this.lat, this.lon)
    if(!reverseGeoCode) {
      this.toastr.clear()
      this.toastr.error('Невозможно получить информацию о координатах', 'Ошибка', {
        timeOut: 3000,
      });
      this.isLoading = false
      this.address = ""
      return false
    }
    this.address = reverseGeoCode.formattedAddress.replace('Unnamed Road, ', '').replace('Null ', '')
  }

  // ✅ Удалить адрес и все составляющие
  public removeAll(): void {
    this.isLoading = true
    this.address = ""
    this.lat = ""
    this.lon = ""
    this.distanceMap.removeLayer(this.marker)
    this.distanceMap.removeLayer(this.routeFrom)
    this.distanceMap.removeLayer(this.routeWithoutPayFrom)
    this.distanceMap.removeLayer(this.routeTo)
    this.distanceMap.removeLayer(this.routeWithoutPayTo)
    this.calculate = {}
    this.routeFrom = []
    this.routeWithoutPayFrom = []
    this.routeTo = []
    this.routeWithoutPayTo = []
    this.isLoading = false
  }

  // Открыть модальное окно ошибки
  public openBugModal(template: TemplateRef<any>) {
    this.modalRefCoords = this.modalService.show(template, {id: 1, class: 'modal-sm modal-dialog modal-dialog-centered'});
  }

  // Смена модели из модального окна
  public changeCoordsModal() {
      let lat = this.modalCoodrsText.split(',')[0]
      let lon = this.modalCoodrsText.split(',')[1]
      let err = false
      if (lat.split('.')[0].replace(' ', '').length <= 2 && lon.split('.')[0].replace(' ', '').length <= 2) {
        if (lat.split('.')[1].replace(' ', '').length > 0 && lon.split('.')[1].replace(' ', '').length >0 ) {
            let latOne = lat.split('.')[0].replace(' ', '')
            let latSecond = lat.split('.')[1].replace(' ', '')
            let lonOne = lon.split('.')[0].replace(' ', '')
            let lonSecond = lon.split('.')[1].replace(' ', '')
            if (Number.isInteger(Number(latOne)) && Number.isInteger(Number(latSecond)) && Number.isInteger(Number(lonOne)) && Number.isInteger(Number(lonSecond))) {

            } else {
              err = true
            }
        } else {
          err = true
        }
      } else if (this.modalCoodrsText == '') {
        err = true
      } else {
        err = true
      }
      if (err) {
        this.modalSuccess = false
        this.modalErr = true
        return false
      }
      this.modalSuccess = true
      this.modalErr = false
  }

  // Установка координат из модального окна
  public async setCoordsModal() {
    if (this.modalSuccess && !this.modalErr) {
      let latLng: LeafletClickLatLng = {
        lat: Number(this.modalCoodrsText.split(',')[0].replace(' ', '')),
        lng: Number(this.modalCoodrsText.split(',')[1].replace(' ', ''))
      }
      let inMkad = await this.mapService.inside([latLng.lat, latLng.lng], MkadAreaConst.features[0].geometry.coordinates[0])
      if (inMkad) {
        this.toastr.clear()
        this.toastr.error('Нельзя переместить точку, так как она внутри полигона', 'Невозможно', {
          timeOut: 3000,
        });
        return false
      } else {
        await this.setMarker(latLng, true)
        this.lat = String(latLng.lat)
        this.lon = String(latLng.lng)
        this.distanceMap.setView(new L.LatLng(Number(this.lat), Number(this.lon)),12);
        this.modalCoodrsText = ''
        this.modalRefCoords.hide()
        this.modalSuccess = false
        this.modalErr = false
        return true
      }
    }
    return false
  }
}
