import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as EmailValidator from 'email-validator';

export interface bugJsonInterface {
  description: string
  type: bugType,
  page: string,
  name: string,
  email: string,
  fullDescription: string,
  file: string | ArrayBuffer
};

export enum bugType {
  'simple' = 'simple',
  'critical' = 'critical'
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @ViewChild('image', {static: false}) image: ElementRef<HTMLInputElement>

  modalRefDisabledComponent: BsModalRef | null;
  modalRefBug: BsModalRef | null;

  public bugJson: bugJsonInterface = {
    description: '',
    type: bugType.simple,
    page: this.getLocation(),
    name: '',
    email: '',
    fullDescription: '',
    file: ''
  };

  public bugJsonErr = {
    description: false,
    type: false,
    page: false,
    name: false,
    email: false,
    fullDescription: false,
    file: false
  };

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private toastr: ToastrService,
    private httpClient: HttpClient,
  ) {
  }

  // Открыть модальное окно ошибки
  public openBugModal(template: TemplateRef<any>) {
    this.modalRefBug = this.modalService.show(template, {id: 1, class: 'modal-lg modal-dialog-centered'});
  }

  // Открыть модальное окно неиспользуемого компонента
  public openDisabledComponentModal(template: TemplateRef<any>) {
    this.modalRefDisabledComponent = this.modalService.show(template, {id: 1, class: 'modal-lg modal-dialog-centered'});
  }

  public ngOnInit(): void {
  }

  // Привести bugJson к стандартному виду
  public defaultBugJson() {
    this.bugJson = {
      description: '',
      type: bugType.simple,
      page: this.getLocation(),
      name: '',
      email: '',
      fullDescription: '',
      file: ''
    };
    this.bugJsonErr = {
      description: false,
      type: false,
      page: false,
      name: false,
      email: false,
      fullDescription: false,
      file: false
    };
  }

  // Получить текущую страницу
  public getLocation(): string {
    return window.location.pathname;
  }

  // Установить тип бага
  public setBugType(type: string) {
    if (type == bugType.simple) {
      this.bugJson.type = bugType.simple;
    }
    if (type == bugType.critical) {
      this.bugJson.type = bugType.critical;
    }
  }

  // Функция проверки заполнености
  public checkBuhForm() {
    let err = false;
    this.bugJsonErr = {
      description: false,
      type: false,
      page: false,
      name: false,
      email: false,
      fullDescription: false,
      file: false
    };
    if (this.bugJson.description.length < 10) {
      this.bugJsonErr.description = true;
      err = true;
    }
    if (!this.bugJson.type) {
      this.bugJsonErr.type = true;
      err = true;
    }
    if (this.bugJson.page.length < 3) {
      this.bugJsonErr.page = true;
      err = true;
    }
    if (this.bugJson.name.length < 2) {
      this.bugJsonErr.name = true;
      err = true;
    }
    if (!EmailValidator.validate(this.bugJson.email)) {
      this.bugJsonErr.email = true;
      err = true;
    }
    if (this.bugJson.fullDescription.length < 50) {
      this.bugJsonErr.fullDescription = true;
      err = true;
    }
    return err;
  }

  // Отправить письмо
  public async sendBug() {
    let error = this.checkBuhForm();
    if (error) {
      return false;
    }
    let sendGuhMail = await this.sendGuhMail();
    if (sendGuhMail) {
      this.toastr.success('Данные о ошибке были отправлены', 'Успешно', {
        timeOut: 10000,
      });
      this.modalRefBug.hide();
      this.defaultBugJson()
      return true;
    }
    this.toastr.error('Во время отправки отчета произошла ошибка', 'Не отправлено', {
      timeOut: 10000,
    });
    return false;
  }

  // Отправить письмо
  public async sendGuhMail() {
    try {
      let requset = await this.httpClient.post('/api/report/send', this.bugJson, {headers: new HttpHeaders().set('token', 'asdfasdf2534-gfgdsg4v4256-dsgdf4g43tgdf-dfgs4b564gfdwdgcd')}).toPromise()
        .then((data) => {
          return  true
        })
        .catch((err) => {
          return false
        })
      return requset
    } catch(err) {
      return false
    }
  }

  // Открыть ссылку на документацию к АПИ
  public apiDocsLink(): string {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/apiDocs'])
    );
    return url;
  }

  // Удалить картинку
  public removeImage() {
    this.bugJson.file = ""
    return true
  }

  // Чекер изображения
  public handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let type = event.target.files[0].name.split('.').pop().toLowerCase();
      // Проверка на тип
      if (type !== 'jpg' && type !== 'png' && type !== 'jpeg') {
        this.toastr.error('Поддерживается jpg и png', 'Неверный формат изображения', {
          timeOut: 10000,
        });
        event.target.value = ''
        this.bugJson.file = ''
        return false
      }
      // Проверка на размер
      let fileSize = file.size / 1024 / 1024
      if (fileSize > 10 ) {
        this.toastr.error('Не поддерживаются файлы размером свыше 10 Мегабайт', 'Недопустимый размер файла', {
          timeOut: 10000,
        });
        event.target.value = ''
        this.bugJson.file = ''
        return false
      }
      // Сохраняем base64
      this.bugJson.file = reader.result
    };
  };
}
