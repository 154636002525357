import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coords',
  templateUrl: './coords.component.html',
  styleUrls: ['./coords.component.css']
})
export class CoordsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
