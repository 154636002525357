import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import * as L from 'leaflet';
import {LeafletClickLatLng} from '../distance/distance.component';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {MapService} from '../services/map.service';
import {ClipboardService} from 'ngx-clipboard';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {MkadAreaConstAvoid, MkadAreaConst} from '../constants/mkad.area.constant';
import {SkAreaForAvoid, SkAreaConst, SkAreaAdditional} from '../constants/sk.area.constant';
import {TtkAreaConstAvoid, TtkAreaConst, TtkAreaAdditional} from '../constants/ttk.area.constant';
import {Mo150AreaAvoid} from "../constants/mo150.area.constant";
import {ActivatedRoute} from '@angular/router';
import {polygon} from '@turf/helpers';
import difference from '@turf/difference';

export interface PolyResponse {
  lat: string,
  lon: string,
  inSK: boolean | null,
  inTTK: boolean | null,
  inMKAD: boolean | null,
  inMO150: boolean | null,
  inRussia: boolean | null
}

@Component({
  selector: 'app-polygon',
  templateUrl: './polygon.component.html',
  styleUrls: ['./polygon.component.css']
})
export class PolygonComponent implements OnInit, AfterViewInit, OnDestroy {

  private polygonMap;
  private markerPolygon;
  private userLat: number = 55.73259745865431;
  private userLon: number = 37.616146011947784;

  public russia
  public mo150
  public mkad
  public ttk
  public ttkAdd
  public sk
  public skAdd
  public lcontrol

  public address: string = '';
  public lat: string = '';
  public lon: string = '';
  public isLoading: boolean = false;
  public calculated: PolyResponse | null = {
    lat: '',
    lon: '',
    inSK: null,
    inTTK: null,
    inMKAD: null,
    inMO150: null,
    inRussia: null
  };

  public googleOptions = {
    types: [],
    componentRestrictions: {country: 'RU'}
  };

  constructor(
    private toastr: ToastrService,
    private httpClient: HttpClient,
    private mapService: MapService,
    private clipboard: ClipboardService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    L.Icon.Default.imagePath = "/leaflet/"
    // Получить координаты пользователя
    this.getUserLocation();
    // Получаем lat и lon для запроса из 1С
    this.route.queryParams.subscribe(params => {
      let lat = params['lat'];
      let lon = params['lon'];
      let err = false
      // Проверка передаваемых координат
      if (lat && lon) {
        if (lat.split('.')[0].replace(' ', '').length <= 2 && lon.split('.')[0].replace(' ', '').length <= 2) {
          if (lat.split('.')[1].replace(' ', '').length > 0 && lon.split('.')[1].replace(' ', '').length >0 ) {
            let latOne = lat.split('.')[0].replace(' ', '')
            let latSecond = lat.split('.')[1].replace(' ', '')
            let lonOne = lon.split('.')[0].replace(' ', '')
            let lonSecond = lon.split('.')[1].replace(' ', '')
            if (Number.isInteger(Number(latOne)) && Number.isInteger(Number(latSecond)) && Number.isInteger(Number(lonOne)) && Number.isInteger(Number(lonSecond))) {

            } else {
              err = true
            }
          } else {
            err = true
          }
        } else {
          err = true
        }
        if (!err) {
          // Загружаем координаты
          this.lat = lat
          this.lon = lon
        }
      }
    });
  }

  async ngAfterViewInit() {
    // Инициализация карты
    this.initMap();
    if (this.lat && this.lon) {
      let coords: LeafletClickLatLng = {
        lat: Number(this.lat),
        lng: Number(this.lon)
      };
      let setMarker = await this.setMarker(coords, true);
      if (setMarker) {
        this.polygonMap.setView(new L.LatLng(Number(this.lat), Number(this.lon)), 10);
      }
    }
  }

  ngOnDestroy(): void {
    this.polygonMap.remove();
    this.polygonMap = undefined;
    this.markerPolygon = undefined;
    this.isLoading = false;
    this.userLat = 55.73259745865431;
    this.userLon = 37.616146011947784;
    this.lat = '';
    this.lon = '';
  }

  // ✅ Получить геоданные пользователя
  public getUserLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.userLat = position.coords.latitude;
        this.userLon = position.coords.longitude;
        // Переопределить карту
        this.polygonMap.panTo(new L.LatLng(this.userLat, this.userLon), 20);
      });
    }
  }

  // ✅ Первичаная инициализация карты
  private initMap(): void {
    // Дефолтные настройки карты
    this.setMap();
    // Установка стандартных карт
    this.setTiles();
    // Прослушиватель кликов
    this.setClickListener();
    // Установка полигонов
    this.setPolygons();
  }

  // ✅ Установка карты
  public setMap(): void {
    this.polygonMap = new L.Map('polygonMap', {
      center: [this.userLat, this.userLon,],
      zoom: 10
    });
  }

  // ✅ Установка тайлов
  public setTiles(): void {
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="https://tk-akro.ru">AKRO Routing</a>'
    });
    tiles.addTo(this.polygonMap);
  }

  // ✅ Добавление полигонов
  public setPolygons(): void {

    // МКАД
    let polyMkad = [];
    for (let key of MkadAreaConstAvoid) {
      polyMkad.push([key[1], key[0]]);
    }
    this.mkad = new L.Polygon(polyMkad);

    // МО 150
    let polyMo150 = [];
    for (let key of Mo150AreaAvoid) {
      polyMo150.push([key[1], key[0]]);
    }
    this.mo150 = new L.Polygon(polyMo150);

    // СК
    let polySk = [];
    for (let key of SkAreaForAvoid) {
      polySk.push([key[1], key[0]]);
    }
    this.sk = new L.Polygon(polySk);

    // Исключения из правил - когда в область нельзя заехать без движения по СК или наоборот
    let skAreaSimple = polygon([SkAreaForAvoid])
    let skAreaAdditional = polygon(SkAreaAdditional.features[0].geometry.coordinates)
    let diffSk = difference(skAreaAdditional, skAreaSimple);
    let skStyle = {
      "color": "red",
      "opacity": 0.65,
      "fillOpacity": 0
    };
    this.skAdd = new L.GeoJSON(diffSk, {
      style: skStyle
    })

    // ТТК
    let polyTtk = [];
    for (let key of TtkAreaConstAvoid) {
      polyTtk.push([key[1], key[0]]);
    }
    this.ttk = new L.Polygon(polyTtk);

    // Исключения из правил - когда в область нельзя заехать без движения по ТТК или наоборот
    let ttkAreaSimple = polygon([TtkAreaConstAvoid])
    let ttkAreaAdditional = polygon(TtkAreaAdditional.features[0].geometry.coordinates)
    let diffTtk = difference(ttkAreaAdditional, ttkAreaSimple);
    let ttkStyle = {
      "color": "red",
      "opacity": 0.65,
      "fillOpacity": 0
    };
    this.ttkAdd = new L.GeoJSON(diffTtk, {
      style: ttkStyle
    })

    // Россия
    this.russia = new L.GeoJSON(this.mapService.russiaPolygon, {
    })

    let overlayMaps = {
      'Россия': this.russia,
      'Зона обслуживания МСК': this.mo150,
      'МКАД': this.mkad,
      'ТТК': this.ttk,
      'СК': this.sk,
    };
    this.lcontrol = L.control.layers({}, overlayMaps).addTo(this.polygonMap);
  }

  // ✅ Установка click listener
  public setClickListener(): void {
    this.polygonMap.on('click', (e) => {
      let coords: LeafletClickLatLng = e.latlng;
      this.lat = String(coords.lat);
      this.lon = String(coords.lng);
      this.setMarker(coords, true);
    });
  }

  // ✅ Установить маркер
  public async setMarker(coords: LeafletClickLatLng, needGeocode: boolean): Promise<boolean> {
    this.isLoading = true;
    if (this.markerPolygon) {
      this.polygonMap.removeLayer(this.markerPolygon);
      this.isLoading = false;
    }

    // Устанавливаем маркер
    this.markerPolygon = new L.Marker([coords.lat, coords.lng], {draggable: true}).addTo(this.polygonMap);

    // Устанавливаем слушитель
    this.markerPolygon.on('dragend', async (e) => {
      this.isLoading = true;
      // Проверка что точка не в полигоне
      let latLng: LeafletClickLatLng = e.target.getLatLng();
      this.lat = String(latLng.lat);
      this.lon = String(latLng.lng);
      await this.setMarker(latLng, true);
    });

    this.isLoading = false;
    if (needGeocode) {
      await this.reverseGeocode();
    }
    let getDataPoly = await this.getDataPoly();
    if (!getDataPoly) {
      return false;
    }
    return true;
  }

  // ✅ Узнать координаты адреса
  public async getLatLonForPlaceRequest(place_id: any): Promise<boolean | any> {
    this.isLoading = true;
    let req = await this.mapService.getLatLonForPlace(place_id);
    if (req) {
      let coords: LeafletClickLatLng = {
        lat: req.lat,
        lng: req.lon
      };
      // Обновляем координаты
      this.lat = String(coords.lat);
      this.lon = String(coords.lng);
      let setMarker = await this.setMarker(coords, false);
      if (setMarker) {
        this.polygonMap.setView(new L.LatLng(Number(this.lat), Number(this.lon)), 10);
      }
    }
    this.isLoading = false;
  }

  // ✅ Функция получения данных от гугла
  public async handleAddressChange(address: Address) {
    this.isLoading = true;
    await this.getLatLonForPlaceRequest(address.place_id);
    this.isLoading = false;
  }

  // ✅ Удалить адрес и все составляющие
  public removeAll(): void {
    this.isLoading = true;
    this.address = '';
    this.lat = '';
    this.lon = '';
    this.polygonMap.removeLayer(this.markerPolygon);
    this.disableAllLayers()
    this.calculated = {
      lat: '',
      lon: '',
      inSK: null,
      inTTK: null,
      inMKAD: null,
      inMO150: null,
      inRussia: null
    };
    this.isLoading = false;
  }

  // ✅ Реверсный геокодинг
  public async reverseGeocode(): Promise<any> {
    let reverseGeoCode = await this.mapService.reverseGeoCode(this.lat, this.lon);
    if (!reverseGeoCode) {
      this.toastr.clear();
      this.toastr.error('Невозможно получить информацию о координатах', 'Ошибка', {
        timeOut: 3000,
      });
      this.isLoading = false;
      this.address = '';
      return false;
    }
    this.address = reverseGeoCode.formattedAddress.replace('Unnamed Road, ', '').replace('Null ', '');
  }

  // ✅ Получить данные от сервера
  public async getDataPoly(): Promise<any> {
    try {
      let req: PolyResponse | null = await this.mapService.getPolygonData(this.lat, this.lon);
      if (!req) {
        return false;
      }
      this.disableAllLayers()
      this.calculated = req;
      return true;
    } catch (err) {
      return false;
    }
  }

  // Выключить все лаейры
  public disableAllLayers() {
    this.polygonMap.removeLayer(this.russia)
    this.polygonMap.removeLayer(this.mo150)
    this.polygonMap.removeLayer(this.mkad)
    this.polygonMap.removeLayer(this.ttk)
    this.polygonMap.removeLayer(this.ttkAdd)
    this.polygonMap.removeLayer(this.sk)
    this.polygonMap.removeLayer(this.skAdd)
  }

  // ✅ Узнать значение полигона
  public getPolyValue(): string {

    if (this.calculated.inSK == null) {
      return 'Не выявлен';
    }
    if (this.calculated.inSK) {
      this.skAdd.addTo(this.polygonMap)
      this.sk.addTo(this.polygonMap)
      return 'Садовое кольцо';
    }
    if (this.calculated.inTTK) {
      this.ttkAdd.addTo(this.polygonMap)
      this.ttk.addTo(this.polygonMap)
      return 'ТТК';
    }
    if (this.calculated.inMKAD) {
      this.mkad.addTo(this.polygonMap)
      return 'МКАД';
    }
    if (this.calculated.inMO150) {
      this.mo150.addTo(this.polygonMap)
      return 'Москва и область';
    }
    if (this.calculated.inRussia) {
      this.russia.addTo(this.polygonMap)
      return 'Межгород (РФ)';
    }
    return 'Неопределен';
  }

  // ✅ Скопировать текст
  public copyData(): boolean {
    if (!this.calculated?.lat) {
      this.toastr.clear()
      this.toastr.error('Нет информации для копирования в буфер обмена', 'Не скопировано');
      return false
    }
    this.clipboard.copy(`Координата: ${this.lat}, ${this.lon} с адресом ${this.address} располагается в "${this.getPolyValue()}"`)
    this.toastr.clear()
    this.toastr.success('Данные скопированы в буфер обмена', 'Успешно');
  }

}
